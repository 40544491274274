export const master_pincode    =[
    {
      "NUM_PINCODE": 110001,
      "TXT_PINCODE_LOCALITY": "TOLSTOY MARG",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110002,
      "TXT_PINCODE_LOCALITY": "RAJGHAT POWER HOUSE",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110003,
      "TXT_PINCODE_LOCALITY": "SAFDARJANG AIR PORT",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110004,
      "TXT_PINCODE_LOCALITY": "RASHTRAPATI BHAWAN",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110005,
      "TXT_PINCODE_LOCALITY": "INDUSTRIAL AREA",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110006,
      "TXT_PINCODE_LOCALITY": "JAMA MASJID",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110007,
      "TXT_PINCODE_LOCALITY": "WAZIR NAGAR",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110008,
      "TXT_PINCODE_LOCALITY": "DADA GHOSH BHAWAN",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110009,
      "TXT_PINCODE_LOCALITY": "GUJRAWALAN TOWN",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 441041,
      "City District Name": "NORTH WEST DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110010,
      "TXT_PINCODE_LOCALITY": "WORK SHOP",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 425472,
      "City District Name": "DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110011,
      "TXT_PINCODE_LOCALITY": "SHAHJAHAN ROAD",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110012,
      "TXT_PINCODE_LOCALITY": "RESEARCH INSTITUTE",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110013,
      "TXT_PINCODE_LOCALITY": "HAZRAT NIZAMUDDIN",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110014,
      "TXT_PINCODE_LOCALITY": "JANGPURA",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110015,
      "TXT_PINCODE_LOCALITY": "ZAKHIRA",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110016,
      "TXT_PINCODE_LOCALITY": "YUSAF SARAI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110017,
      "TXT_PINCODE_LOCALITY": "PANCHSEEL ENCLAVE",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 425472,
      "City District Name": "DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110018,
      "TXT_PINCODE_LOCALITY": "TIHAR",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110019,
      "TXT_PINCODE_LOCALITY": "DELHI CANTT.",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 2915,
      "City District Name": "DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110020,
      "TXT_PINCODE_LOCALITY": "OKHLA INDUSTRIAL ESTATE",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110021,
      "TXT_PINCODE_LOCALITY": "MALCHA MARG",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110022,
      "TXT_PINCODE_LOCALITY": "R K PURAM SECTOR IX",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110023,
      "TXT_PINCODE_LOCALITY": "NETAJI NAGAR",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110024,
      "TXT_PINCODE_LOCALITY": "LAJPAT NAGAR",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 440960,
      "City District Name": "SOUTH DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110025,
      "TXT_PINCODE_LOCALITY": "JAMIA NAGAR",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110026,
      "TXT_PINCODE_LOCALITY": "PUNJABI BAGH",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110027,
      "TXT_PINCODE_LOCALITY": "JANTA MARKET",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110028,
      "TXT_PINCODE_LOCALITY": "NARAINA VIHAR",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110029,
      "TXT_PINCODE_LOCALITY": "SAFDARJANG ENCLAVE",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110030,
      "TXT_PINCODE_LOCALITY": "T B HOSPITAL",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 425472,
      "City District Name": "DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110031,
      "TXT_PINCODE_LOCALITY": "GEETA COLONY",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 2915,
      "City District Name": "DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110032,
      "TXT_PINCODE_LOCALITY": "VIVEK VIHAR",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 2915,
      "City District Name": "DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110033,
      "TXT_PINCODE_LOCALITY": "GUJRANWALA NAGAR PART II",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 425472,
      "City District Name": "DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110034,
      "TXT_PINCODE_LOCALITY": "RISHI NAGAR",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110035,
      "TXT_PINCODE_LOCALITY": "LAWRENCE ROAD",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110036,
      "TXT_PINCODE_LOCALITY": "BAKOLI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110037,
      "TXT_PINCODE_LOCALITY": "KAPAS HERA",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110038,
      "TXT_PINCODE_LOCALITY": "RAJOKARI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110039,
      "TXT_PINCODE_LOCALITY": "NANGAL THAKRAN",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 2915,
      "City District Name": "DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110040,
      "TXT_PINCODE_LOCALITY": "NARELA",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110041,
      "TXT_PINCODE_LOCALITY": "POOTH KALAN",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 2915,
      "City District Name": "DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110042,
      "TXT_PINCODE_LOCALITY": "SIRASPUR",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110043,
      "TXT_PINCODE_LOCALITY": "DEENDARPUR",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110044,
      "TXT_PINCODE_LOCALITY": "TUGHLAKABAD A F STATION",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 425472,
      "City District Name": "DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110045,
      "TXT_PINCODE_LOCALITY": "AMBROHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 425472,
      "City District Name": "DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110046,
      "TXT_PINCODE_LOCALITY": "NANGAL RAVA S.O",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 440963,
      "City District Name": "SOUTH WEST DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110047,
      "TXT_PINCODE_LOCALITY": "JONA PUR (ARJANGARH)",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 2915,
      "City District Name": "DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110048,
      "TXT_PINCODE_LOCALITY": "GREATER KAILASH",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110049,
      "TXT_PINCODE_LOCALITY": "SOUTH EXTN PART I",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110050,
      "TXT_PINCODE_LOCALITY": "HAUZ KHAS",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 442290,
      "City District Name": "HAUZ KHAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110051,
      "TXT_PINCODE_LOCALITY": "KRISHAN NAGAR BAZAR",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110052,
      "TXT_PINCODE_LOCALITY": "BHARAT NAGAR",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110053,
      "TXT_PINCODE_LOCALITY": "BRAHAMPURI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 425472,
      "City District Name": "DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110054,
      "TXT_PINCODE_LOCALITY": "MAIDENS HOTEL",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110055,
      "TXT_PINCODE_LOCALITY": "MULTANI DHANDA",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110056,
      "TXT_PINCODE_LOCALITY": "SHAKURBASTI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110057,
      "TXT_PINCODE_LOCALITY": "VASANT ENCLAVE",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 440963,
      "City District Name": "SOUTH WEST DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110058,
      "TXT_PINCODE_LOCALITY": "JANAKPURI BLOCKC 2",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110059,
      "TXT_PINCODE_LOCALITY": "JEEWAN PARK",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 425472,
      "City District Name": "DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110060,
      "TXT_PINCODE_LOCALITY": "RAVINDER RANGSALA",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110061,
      "TXT_PINCODE_LOCALITY": "BIJWASAN",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110062,
      "TXT_PINCODE_LOCALITY": "MADANGIR",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110063,
      "TXT_PINCODE_LOCALITY": "MADIPUR",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110064,
      "TXT_PINCODE_LOCALITY": "HARI NAGAR BE BLOCK",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110065,
      "TXT_PINCODE_LOCALITY": "ISHWAR NAGAR",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110066,
      "TXT_PINCODE_LOCALITY": "R K PURAM",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110067,
      "TXT_PINCODE_LOCALITY": "D D A MUNIRKA",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110068,
      "TXT_PINCODE_LOCALITY": "NEB SARAI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110069,
      "TXT_PINCODE_LOCALITY": "UNION PUBLIC SERVICE COMMISSION S.O",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 441058,
      "City District Name": "CENTRAL DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110070,
      "TXT_PINCODE_LOCALITY": "VASANT KUNJ",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110071,
      "TXT_PINCODE_LOCALITY": "CHHAWLA",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 2915,
      "City District Name": "DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110072,
      "TXT_PINCODE_LOCALITY": "CRPF JHARODA KALAN S.O",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 440963,
      "City District Name": "SOUTH WEST DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110073,
      "TXT_PINCODE_LOCALITY": "UJWA",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 2915,
      "City District Name": "DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110074,
      "TXT_PINCODE_LOCALITY": "CHATTARPUR",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110075,
      "TXT_PINCODE_LOCALITY": "SECTOR 6",
      "NUM_STATE_CD": 192,
      "State Name": "NEW DELHI",
      "NUM_CITYDISTRICT_CD": 442118,
      "City District Name": "DWARKA S. O",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110076,
      "TXT_PINCODE_LOCALITY": "SARITA VIHAR ROAD",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 440960,
      "City District Name": "SOUTH DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110077,
      "TXT_PINCODE_LOCALITY": "BAGDOLA",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110078,
      "TXT_PINCODE_LOCALITY": "DELHI S. O",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110079,
      "TXT_PINCODE_LOCALITY": "SECTOR 4 DWARKA",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 442292,
      "City District Name": "PATEL NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110080,
      "TXT_PINCODE_LOCALITY": "SANGAM VIHAR",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 440960,
      "City District Name": "SOUTH DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110081,
      "TXT_PINCODE_LOCALITY": "KANJHAWALA - 2190",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 2915,
      "City District Name": "DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110082,
      "TXT_PINCODE_LOCALITY": "KHERA KALAN",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110083,
      "TXT_PINCODE_LOCALITY": "MANGOLPURI BLOCK S",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 425472,
      "City District Name": "DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110084,
      "TXT_PINCODE_LOCALITY": "KUTUBGARH",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 425472,
      "City District Name": "DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110085,
      "TXT_PINCODE_LOCALITY": "SECTOR 9",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 1474,
      "City District Name": "ROHINI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110086,
      "TXT_PINCODE_LOCALITY": "BEGUM PUR",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 2915,
      "City District Name": "DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110087,
      "TXT_PINCODE_LOCALITY": "MEERA BAGH",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 441041,
      "City District Name": "NORTH WEST DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110088,
      "TXT_PINCODE_LOCALITY": "SHALIMAR BAGH",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110089,
      "TXT_PINCODE_LOCALITY": "DELHI.",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 2915,
      "City District Name": "DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110090,
      "TXT_PINCODE_LOCALITY": "SONIA VIHAR",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 440964,
      "City District Name": "EAST DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110091,
      "TXT_PINCODE_LOCALITY": "KALYAN VAS",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 2915,
      "City District Name": "DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110092,
      "TXT_PINCODE_LOCALITY": "SHAKARPUR",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 2915,
      "City District Name": "DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110093,
      "TXT_PINCODE_LOCALITY": "NAND NAGRI A BLOCK",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 2915,
      "City District Name": "DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110094,
      "TXT_PINCODE_LOCALITY": "ZIAUDDIN PUR (MIRPUR TURK)",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 2915,
      "City District Name": "DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110095,
      "TXT_PINCODE_LOCALITY": "JHILMIL",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 2915,
      "City District Name": "DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110096,
      "TXT_PINCODE_LOCALITY": "GHAROLI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 2915,
      "City District Name": "DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110097,
      "TXT_PINCODE_LOCALITY": "KAPASHERA",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110098,
      "TXT_PINCODE_LOCALITY": "NEW ASHOK NAGAR",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110099,
      "TXT_PINCODE_LOCALITY": "VASUNDHRA INCLAVE",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 2915,
      "City District Name": "DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110100,
      "TXT_PINCODE_LOCALITY": "DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 441058,
      "City District Name": "CENTRAL DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110124,
      "TXT_PINCODE_LOCALITY": "CAG, B.S.Z. MARG",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110605,
      "TXT_PINCODE_LOCALITY": "R.K.PURAM",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 440963,
      "City District Name": "SOUTH WEST DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110607,
      "TXT_PINCODE_LOCALITY": "BHIKAJI CAMA PLACE",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 442293,
      "City District Name": "VASANT VIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 111201,
      "TXT_PINCODE_LOCALITY": "DUBAI..",
      "NUM_STATE_CD": 225,
      "State Name": "DUBAI",
      "NUM_CITYDISTRICT_CD": 440959,
      "City District Name": "DUBAI",
      "NUM_COUNTRY_CD": 162
    },
    {
      "NUM_PINCODE": 111787,
      "TXT_PINCODE_LOCALITY": "SURENDRANAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 112516,
      "TXT_PINCODE_LOCALITY": "KOLLAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 115490,
      "TXT_PINCODE_LOCALITY": "THANJAVUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 117487,
      "TXT_PINCODE_LOCALITY": "DUBAI INTERNET CITY",
      "NUM_STATE_CD": 225,
      "State Name": "DUBAI",
      "NUM_CITYDISTRICT_CD": 440959,
      "City District Name": "DUBAI",
      "NUM_COUNTRY_CD": 162
    },
    {
      "NUM_PINCODE": 121001,
      "TXT_PINCODE_LOCALITY": "S G M NAGAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 696,
      "City District Name": "FARIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 121002,
      "TXT_PINCODE_LOCALITY": "FARIDABAD",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 696,
      "City District Name": "FARIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 121003,
      "TXT_PINCODE_LOCALITY": "TILPAT FARIDABAD",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 696,
      "City District Name": "FARIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 121004,
      "TXT_PINCODE_LOCALITY": "SECTOR A",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 696,
      "City District Name": "FARIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 121005,
      "TXT_PINCODE_LOCALITY": "FARIDABAD SECTOR 22",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 696,
      "City District Name": "FARIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 121006,
      "TXT_PINCODE_LOCALITY": "INDUSTRIAL ESTATE",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 696,
      "City District Name": "FARIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 121007,
      "TXT_PINCODE_LOCALITY": "SECTOR 12",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 696,
      "City District Name": "FARIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 121008,
      "TXT_PINCODE_LOCALITY": "FARIDABAD SECTOR 29",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 696,
      "City District Name": "FARIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 121009,
      "TXT_PINCODE_LOCALITY": "CHARMWOOD VILLAGE",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 696,
      "City District Name": "FARIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 121010,
      "TXT_PINCODE_LOCALITY": "NHPC COLONY FARIDABAD S.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 696,
      "City District Name": "FARIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 121011,
      "TXT_PINCODE_LOCALITY": "SECTOR 42",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 696,
      "City District Name": "FARIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 121012,
      "TXT_PINCODE_LOCALITY": "SECTOR-21D S.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 696,
      "City District Name": "FARIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 121013,
      "TXT_PINCODE_LOCALITY": "SECTOR 91",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 696,
      "City District Name": "FARIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 121014,
      "TXT_PINCODE_LOCALITY": "FARIDABAD",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 696,
      "City District Name": "FARIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 121015,
      "TXT_PINCODE_LOCALITY": "FARIDABAD",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 696,
      "City District Name": "FARIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 121018,
      "TXT_PINCODE_LOCALITY": "FARIDABAD S. O.",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 696,
      "City District Name": "FARIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 121101,
      "TXT_PINCODE_LOCALITY": "TIGAON",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 696,
      "City District Name": "FARIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 121102,
      "TXT_PINCODE_LOCALITY": "PALWAL SOHNA ROAD",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 696,
      "City District Name": "FARIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 121103,
      "TXT_PINCODE_LOCALITY": "HATHIN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 696,
      "City District Name": "FARIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 121104,
      "TXT_PINCODE_LOCALITY": "PUNHANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 121105,
      "TXT_PINCODE_LOCALITY": "AURANGABAD",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 696,
      "City District Name": "FARIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 121106,
      "TXT_PINCODE_LOCALITY": "HODEL",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 696,
      "City District Name": "FARIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 121107,
      "TXT_PINCODE_LOCALITY": "HASSANPUR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 696,
      "City District Name": "FARIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 121705,
      "TXT_PINCODE_LOCALITY": "KIRATH SARAI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6531,
      "City District Name": "MAU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122000,
      "TXT_PINCODE_LOCALITY": "GURGAON KTY. S.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122001,
      "TXT_PINCODE_LOCALITY": "SOHNA ADDA GURGAON",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122002,
      "TXT_PINCODE_LOCALITY": "SECTOR 27",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122003,
      "TXT_PINCODE_LOCALITY": "ARDEE CITY",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122004,
      "TXT_PINCODE_LOCALITY": "SEC 57",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122005,
      "TXT_PINCODE_LOCALITY": "AIR FORCE GURGAON",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122006,
      "TXT_PINCODE_LOCALITY": "DABODA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122007,
      "TXT_PINCODE_LOCALITY": "INDUSTRIAL ESTATE",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122008,
      "TXT_PINCODE_LOCALITY": "DLF CYBER CITY PHASE II",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122009,
      "TXT_PINCODE_LOCALITY": "DLF CITY PHASE V",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122010,
      "TXT_PINCODE_LOCALITY": "DLF PH III S.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122011,
      "TXT_PINCODE_LOCALITY": "JALVAYU TOWERS",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122012,
      "TXT_PINCODE_LOCALITY": "SARHAUL",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122013,
      "TXT_PINCODE_LOCALITY": "NAWADA FATEHPUR(112)",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1808,
      "City District Name": "MANESAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122014,
      "TXT_PINCODE_LOCALITY": "GURGAON S. O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122015,
      "TXT_PINCODE_LOCALITY": "GURGAON PALAMPUR ROAD",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122016,
      "TXT_PINCODE_LOCALITY": "GURUGRAM S. O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 442152,
      "City District Name": "GURUGRAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122017,
      "TXT_PINCODE_LOCALITY": "PHASE 1",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122018,
      "TXT_PINCODE_LOCALITY": "SOUTH CITY",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122019,
      "TXT_PINCODE_LOCALITY": "SECTOR 48",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122021,
      "TXT_PINCODE_LOCALITY": "AMBALA HQ",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122022,
      "TXT_PINCODE_LOCALITY": "M. G. ROAD",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122050,
      "TXT_PINCODE_LOCALITY": "MANESAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122051,
      "TXT_PINCODE_LOCALITY": "NATIONAL SECURITY CAMP, MANESAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122052,
      "TXT_PINCODE_LOCALITY": "MANESAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122063,
      "TXT_PINCODE_LOCALITY": "GURUGRAM",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 442152,
      "City District Name": "GURUGRAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122098,
      "TXT_PINCODE_LOCALITY": "GURGAON S.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122100,
      "TXT_PINCODE_LOCALITY": "KAPARIWAS (290)",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 691,
      "City District Name": "REWARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122101,
      "TXT_PINCODE_LOCALITY": "BADSHAHPUR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122102,
      "TXT_PINCODE_LOCALITY": "BHUDSI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122103,
      "TXT_PINCODE_LOCALITY": "SOHNA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122104,
      "TXT_PINCODE_LOCALITY": "FEROZEPUR JHIRKA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122105,
      "TXT_PINCODE_LOCALITY": "TAURU",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122106,
      "TXT_PINCODE_LOCALITY": "DHARUHERA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 691,
      "City District Name": "REWARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122107,
      "TXT_PINCODE_LOCALITY": "NUH",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122108,
      "TXT_PINCODE_LOCALITY": "NAGINA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122109,
      "TXT_PINCODE_LOCALITY": "DAULATABAD",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122110,
      "TXT_PINCODE_LOCALITY": "INDUSTRIAL AREA OF DEHRADUN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 691,
      "City District Name": "REWARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122201,
      "TXT_PINCODE_LOCALITY": "HUGHES SOFTWARE SECTOR-18",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122203,
      "TXT_PINCODE_LOCALITY": "M/S APPOLLO TYRES SECTOR-32, GURGAON",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122207,
      "TXT_PINCODE_LOCALITY": "TCI GURGAON",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122211,
      "TXT_PINCODE_LOCALITY": "PALMAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122413,
      "TXT_PINCODE_LOCALITY": "BHORAKALAN S.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122414,
      "TXT_PINCODE_LOCALITY": "NANUKALAN S.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122502,
      "TXT_PINCODE_LOCALITY": "PAHARI B.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122503,
      "TXT_PINCODE_LOCALITY": "FAROOK NAGAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122504,
      "TXT_PINCODE_LOCALITY": "HAILLYMANDI S.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122505,
      "TXT_PINCODE_LOCALITY": "BHANGROLA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122506,
      "TXT_PINCODE_LOCALITY": "FARRUKH NAGAR S.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122508,
      "TXT_PINCODE_LOCALITY": "NUH",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 122550,
      "TXT_PINCODE_LOCALITY": "SEC-90, GURGAON",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123001,
      "TXT_PINCODE_LOCALITY": "NARNAUL CITY",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 818,
      "City District Name": "MAHENDRAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123003,
      "TXT_PINCODE_LOCALITY": "PATAUDI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123020,
      "TXT_PINCODE_LOCALITY": "SANWAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123021,
      "TXT_PINCODE_LOCALITY": "MANDI ATELI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 818,
      "City District Name": "MAHENDRAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123022,
      "TXT_PINCODE_LOCALITY": "CHIRYA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123023,
      "TXT_PINCODE_LOCALITY": "NANGAL CHAUDHARY",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 818,
      "City District Name": "MAHENDRAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123024,
      "TXT_PINCODE_LOCALITY": "NARNAUL",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 818,
      "City District Name": "MAHENDRAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123025,
      "TXT_PINCODE_LOCALITY": "BAUND",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123026,
      "TXT_PINCODE_LOCALITY": "BIRHI KALAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123027,
      "TXT_PINCODE_LOCALITY": "KANINA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 818,
      "City District Name": "MAHENDRAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123028,
      "TXT_PINCODE_LOCALITY": "NANGAL SIROHI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 818,
      "City District Name": "MAHENDRAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123029,
      "TXT_PINCODE_LOCALITY": "MOHINDERGARH",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 818,
      "City District Name": "MAHENDRAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123031,
      "TXT_PINCODE_LOCALITY": "MAHENDERGARH",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 818,
      "City District Name": "MAHENDRAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123034,
      "TXT_PINCODE_LOCALITY": "BAWANIA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 818,
      "City District Name": "MAHENDRAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123035,
      "TXT_PINCODE_LOCALITY": "PAHLAWAS",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 818,
      "City District Name": "MAHENDRAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123101,
      "TXT_PINCODE_LOCALITY": "KHORI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 691,
      "City District Name": "REWARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123102,
      "TXT_PINCODE_LOCALITY": "KUND",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 691,
      "City District Name": "REWARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123103,
      "TXT_PINCODE_LOCALITY": "KHOL",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 691,
      "City District Name": "REWARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123106,
      "TXT_PINCODE_LOCALITY": "DHARUHERA S.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 691,
      "City District Name": "REWARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123110,
      "TXT_PINCODE_LOCALITY": "INDUSTRIAL AREA DHARUHERA S.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 691,
      "City District Name": "REWARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123201,
      "TXT_PINCODE_LOCALITY": "LOHARU",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123301,
      "TXT_PINCODE_LOCALITY": "GURIANI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 691,
      "City District Name": "REWARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123302,
      "TXT_PINCODE_LOCALITY": "KULDIP NAGAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 691,
      "City District Name": "REWARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123303,
      "TXT_PINCODE_LOCALITY": "NAHAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 691,
      "City District Name": "REWARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123304,
      "TXT_PINCODE_LOCALITY": "SAHALWAS",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123305,
      "TXT_PINCODE_LOCALITY": "BAHU",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 691,
      "City District Name": "REWARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123306,
      "TXT_PINCODE_LOCALITY": "CHARKHI DADRI CITY",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123307,
      "TXT_PINCODE_LOCALITY": "ACHINA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123308,
      "TXT_PINCODE_LOCALITY": "BADHRA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123309,
      "TXT_PINCODE_LOCALITY": "MANHERU",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123310,
      "TXT_PINCODE_LOCALITY": "JHOJU KALAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123401,
      "TXT_PINCODE_LOCALITY": "MODEL TOWN REWARI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 691,
      "City District Name": "REWARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123411,
      "TXT_PINCODE_LOCALITY": "DAHINA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 691,
      "City District Name": "REWARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123412,
      "TXT_PINCODE_LOCALITY": "DHARAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 691,
      "City District Name": "REWARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123413,
      "TXT_PINCODE_LOCALITY": "BAHORE KALAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123414,
      "TXT_PINCODE_LOCALITY": "NANU KALAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123456,
      "TXT_PINCODE_LOCALITY": "SECTOR-19, VASHI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123501,
      "TXT_PINCODE_LOCALITY": "BAWAL",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 691,
      "City District Name": "REWARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123502,
      "TXT_PINCODE_LOCALITY": "KHALILPUR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 691,
      "City District Name": "REWARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123503,
      "TXT_PINCODE_LOCALITY": "PATAUDI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123504,
      "TXT_PINCODE_LOCALITY": "HAILY MANDI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123505,
      "TXT_PINCODE_LOCALITY": "GARHI HARSARU",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123506,
      "TXT_PINCODE_LOCALITY": "FARRUKH NAGAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123507,
      "TXT_PINCODE_LOCALITY": "DAULATABAD",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123508,
      "TXT_PINCODE_LOCALITY": "JATAULI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 123654,
      "TXT_PINCODE_LOCALITY": "SECTOR-12",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124001,
      "TXT_PINCODE_LOCALITY": "ROHTAK H O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124002,
      "TXT_PINCODE_LOCALITY": "BHAGWATIPUR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124003,
      "TXT_PINCODE_LOCALITY": "VAISH HIGH SCHL ROHTAK",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124004,
      "TXT_PINCODE_LOCALITY": "G K SINGHPUR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124005,
      "TXT_PINCODE_LOCALITY": "TITAULI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124006,
      "TXT_PINCODE_LOCALITY": "MAKRAULI KALAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124007,
      "TXT_PINCODE_LOCALITY": "SAMARGOPALPUR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124008,
      "TXT_PINCODE_LOCALITY": "SUNARI KALAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124010,
      "TXT_PINCODE_LOCALITY": "ROHTAK (RURAL) (PART)",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124021,
      "TXT_PINCODE_LOCALITY": "BOHAR B.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124022,
      "TXT_PINCODE_LOCALITY": "MORKHRA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124023,
      "TXT_PINCODE_LOCALITY": "BOHAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124024,
      "TXT_PINCODE_LOCALITY": "LAHLI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124025,
      "TXT_PINCODE_LOCALITY": "KHARAWAL",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124026,
      "TXT_PINCODE_LOCALITY": "KHARONTHA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124027,
      "TXT_PINCODE_LOCALITY": "KHERI SADH",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124028,
      "TXT_PINCODE_LOCALITY": "BASANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124029,
      "TXT_PINCODE_LOCALITY": "BALAND",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124030,
      "TXT_PINCODE_LOCALITY": "GHARAUTHI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124031,
      "TXT_PINCODE_LOCALITY": "MAINA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124040,
      "TXT_PINCODE_LOCALITY": "SILANA (5R)",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6405,
      "City District Name": "KHARKHODA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124101,
      "TXT_PINCODE_LOCALITY": "BARHANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124102,
      "TXT_PINCODE_LOCALITY": "DUJANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124103,
      "TXT_PINCODE_LOCALITY": "JHAJAR H O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124104,
      "TXT_PINCODE_LOCALITY": "JHAJJAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124105,
      "TXT_PINCODE_LOCALITY": "BADLI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124106,
      "TXT_PINCODE_LOCALITY": "MATANHELL",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124107,
      "TXT_PINCODE_LOCALITY": "DIGHAL",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124108,
      "TXT_PINCODE_LOCALITY": "MACHHRAULI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124109,
      "TXT_PINCODE_LOCALITY": "DHAKALA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124110,
      "TXT_PINCODE_LOCALITY": "BAHU AKBARPUR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124111,
      "TXT_PINCODE_LOCALITY": "MADINA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124112,
      "TXT_PINCODE_LOCALITY": "MEHAM",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124113,
      "TXT_PINCODE_LOCALITY": "KALANAUR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124114,
      "TXT_PINCODE_LOCALITY": "KHARAK KALAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124116,
      "TXT_PINCODE_LOCALITY": "SUBANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124117,
      "TXT_PINCODE_LOCALITY": "BIROHAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124118,
      "TXT_PINCODE_LOCALITY": "GOCHHI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124119,
      "TXT_PINCODE_LOCALITY": "KULTANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124120,
      "TXT_PINCODE_LOCALITY": "BAHLBA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124121,
      "TXT_PINCODE_LOCALITY": "KHARKHERA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124122,
      "TXT_PINCODE_LOCALITY": "BIRDHANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124123,
      "TXT_PINCODE_LOCALITY": "BHANI CHANDERPAL",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124124,
      "TXT_PINCODE_LOCALITY": "KATESRA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124125,
      "TXT_PINCODE_LOCALITY": "JHALRI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124126,
      "TXT_PINCODE_LOCALITY": "KALINGA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124127,
      "TXT_PINCODE_LOCALITY": "SISAR KHAS",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124128,
      "TXT_PINCODE_LOCALITY": "BHANI SURJAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124129,
      "TXT_PINCODE_LOCALITY": "SAMAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124130,
      "TXT_PINCODE_LOCALITY": "TALAO",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124131,
      "TXT_PINCODE_LOCALITY": "JHAZGARH",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124132,
      "TXT_PINCODE_LOCALITY": "RITAULI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124133,
      "TXT_PINCODE_LOCALITY": "M P MAJRA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124134,
      "TXT_PINCODE_LOCALITY": "KHERI KUMAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124135,
      "TXT_PINCODE_LOCALITY": "DAURA RAJA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124136,
      "TXT_PINCODE_LOCALITY": "CHIMNI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124137,
      "TXT_PINCODE_LOCALITY": "BHADANI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124138,
      "TXT_PINCODE_LOCALITY": "JAHANGIRPUR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124139,
      "TXT_PINCODE_LOCALITY": "MEHRANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124140,
      "TXT_PINCODE_LOCALITY": "SILANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124141,
      "TXT_PINCODE_LOCALITY": "KHUDAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124142,
      "TXT_PINCODE_LOCALITY": "THERMAL POWER PLANT",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124146,
      "TXT_PINCODE_LOCALITY": "MUNDAHERA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124201,
      "TXT_PINCODE_LOCALITY": "BERI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124202,
      "TXT_PINCODE_LOCALITY": "DOBALDHAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124203,
      "TXT_PINCODE_LOCALITY": "MAJRA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124204,
      "TXT_PINCODE_LOCALITY": "SEWANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124205,
      "TXT_PINCODE_LOCALITY": "PILANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124301,
      "TXT_PINCODE_LOCALITY": "GOHANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1518,
      "City District Name": "SONEPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124302,
      "TXT_PINCODE_LOCALITY": "BUTANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124303,
      "TXT_PINCODE_LOCALITY": "SANGHI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124304,
      "TXT_PINCODE_LOCALITY": "BARODA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124305,
      "TXT_PINCODE_LOCALITY": "KHANPUR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124306,
      "TXT_PINCODE_LOCALITY": "MUNDLANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124307,
      "TXT_PINCODE_LOCALITY": "MEHMOODPUR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124308,
      "TXT_PINCODE_LOCALITY": "AHULANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124309,
      "TXT_PINCODE_LOCALITY": "MADIANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124310,
      "TXT_PINCODE_LOCALITY": "BICHPARI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124311,
      "TXT_PINCODE_LOCALITY": "BAROTA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124312,
      "TXT_PINCODE_LOCALITY": "KHIDWALI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124314,
      "TXT_PINCODE_LOCALITY": "JASSIA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124315,
      "TXT_PINCODE_LOCALITY": "GANGANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124316,
      "TXT_PINCODE_LOCALITY": "GARHWAL",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124317,
      "TXT_PINCODE_LOCALITY": "GLWANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124318,
      "TXT_PINCODE_LOCALITY": "JAULI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124320,
      "TXT_PINCODE_LOCALITY": "KATHURA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124321,
      "TXT_PINCODE_LOCALITY": "LATH",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124322,
      "TXT_PINCODE_LOCALITY": "KATWAL",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124323,
      "TXT_PINCODE_LOCALITY": "KOHLA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124324,
      "TXT_PINCODE_LOCALITY": "RABHRA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124325,
      "TXT_PINCODE_LOCALITY": "BINDHANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124401,
      "TXT_PINCODE_LOCALITY": "BHALAUT",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124402,
      "TXT_PINCODE_LOCALITY": "KHARKAUDA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124403,
      "TXT_PINCODE_LOCALITY": "ROHAT",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124404,
      "TXT_PINCODE_LOCALITY": "HASSANGARH",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124405,
      "TXT_PINCODE_LOCALITY": "KILOI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124406,
      "TXT_PINCODE_LOCALITY": "KANSALA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124407,
      "TXT_PINCODE_LOCALITY": "SISANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124408,
      "TXT_PINCODE_LOCALITY": "FARMANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124409,
      "TXT_PINCODE_LOCALITY": "BHAINSWAL KALAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124410,
      "TXT_PINCODE_LOCALITY": "BIDHAL PINANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124411,
      "TXT_PINCODE_LOCALITY": "AVAL",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124412,
      "TXT_PINCODE_LOCALITY": "KAHNAUR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124413,
      "TXT_PINCODE_LOCALITY": "KHANDA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124415,
      "TXT_PINCODE_LOCALITY": "BHADLAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124416,
      "TXT_PINCODE_LOCALITY": "BALIANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124417,
      "TXT_PINCODE_LOCALITY": "PAKSMA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124418,
      "TXT_PINCODE_LOCALITY": "DOBH",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124419,
      "TXT_PINCODE_LOCALITY": "KILOI(ROHTAK)",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124420,
      "TXT_PINCODE_LOCALITY": "BHANYANI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124421,
      "TXT_PINCODE_LOCALITY": "ASAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 698,
      "City District Name": "PANIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124422,
      "TXT_PINCODE_LOCALITY": "BHOLI ANANDPUR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124423,
      "TXT_PINCODE_LOCALITY": "HAMAYUNPUR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124424,
      "TXT_PINCODE_LOCALITY": "DHAMAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124425,
      "TXT_PINCODE_LOCALITY": "SAMCHANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124426,
      "TXT_PINCODE_LOCALITY": "RURKI (ROHTAK)",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124427,
      "TXT_PINCODE_LOCALITY": "CHIRANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124428,
      "TXT_PINCODE_LOCALITY": "RUKHI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124429,
      "TXT_PINCODE_LOCALITY": "RITHAL",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124430,
      "TXT_PINCODE_LOCALITY": "SIWANA MALL",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124501,
      "TXT_PINCODE_LOCALITY": "SAMPLA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124502,
      "TXT_PINCODE_LOCALITY": "ROHAD",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124503,
      "TXT_PINCODE_LOCALITY": "BHAPRAUDA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124504,
      "TXT_PINCODE_LOCALITY": "CHHARA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124505,
      "TXT_PINCODE_LOCALITY": "ASSANDAH",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124506,
      "TXT_PINCODE_LOCALITY": "MANDOTHI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124507,
      "TXT_PINCODE_LOCALITY": "BAHADURGAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124508,
      "TXT_PINCODE_LOCALITY": "NOONA MAJRA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124509,
      "TXT_PINCODE_LOCALITY": "TANDAHERI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124510,
      "TXT_PINCODE_LOCALITY": "MATAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124511,
      "TXT_PINCODE_LOCALITY": "KHARHAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124513,
      "TXT_PINCODE_LOCALITY": "NIDANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124514,
      "TXT_PINCODE_LOCALITY": "LAKHAN MAJRA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124515,
      "TXT_PINCODE_LOCALITY": "NIGANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124516,
      "TXT_PINCODE_LOCALITY": "GORAWAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124517,
      "TXT_PINCODE_LOCALITY": "ISMAILA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124518,
      "TXT_PINCODE_LOCALITY": "BARAIN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124519,
      "TXT_PINCODE_LOCALITY": "BAINSI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124520,
      "TXT_PINCODE_LOCALITY": "FARMANA (ROHTAK)",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124521,
      "TXT_PINCODE_LOCALITY": "ATAIL",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124522,
      "TXT_PINCODE_LOCALITY": "AJAIB",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124523,
      "TXT_PINCODE_LOCALITY": "CHANDI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124524,
      "TXT_PINCODE_LOCALITY": "CHIRI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124525,
      "TXT_PINCODE_LOCALITY": "BARAHI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124526,
      "TXT_PINCODE_LOCALITY": "BHUPANI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124527,
      "TXT_PINCODE_LOCALITY": "CHAUSHALA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124528,
      "TXT_PINCODE_LOCALITY": "ESCORT NAGAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124529,
      "TXT_PINCODE_LOCALITY": "GANDHRA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124530,
      "TXT_PINCODE_LOCALITY": "KANAUNDA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124531,
      "TXT_PINCODE_LOCALITY": "NILAUTHI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124532,
      "TXT_PINCODE_LOCALITY": "ROHNA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124533,
      "TXT_PINCODE_LOCALITY": "KHARAINTI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124535,
      "TXT_PINCODE_LOCALITY": "JASAUR KHERI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 124605,
      "TXT_PINCODE_LOCALITY": "BATEHI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125001,
      "TXT_PINCODE_LOCALITY": "HISSAR KUTCHERY",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125002,
      "TXT_PINCODE_LOCALITY": "HISSAR TEXTILE MILLS",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125003,
      "TXT_PINCODE_LOCALITY": "PATEL NAGAR HISSAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125004,
      "TXT_PINCODE_LOCALITY": "HISSAR AGRICULTURAL UNIVE",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125005,
      "TXT_PINCODE_LOCALITY": "MODEL TOWN HISSAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125006,
      "TXT_PINCODE_LOCALITY": "HISAR CANTT S.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125007,
      "TXT_PINCODE_LOCALITY": "INDRA COLONY",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125011,
      "TXT_PINCODE_LOCALITY": "HISSAR B S F",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125012,
      "TXT_PINCODE_LOCALITY": "DULAT",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6386,
      "City District Name": "FATEHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125021,
      "TXT_PINCODE_LOCALITY": "BHIWANI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125022,
      "TXT_PINCODE_LOCALITY": "BHIWANI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125023,
      "TXT_PINCODE_LOCALITY": "BIRON",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125024,
      "TXT_PINCODE_LOCALITY": "DINOD",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125025,
      "TXT_PINCODE_LOCALITY": "DEVSAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125026,
      "TXT_PINCODE_LOCALITY": "BAPORA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125027,
      "TXT_PINCODE_LOCALITY": "CHANG",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125028,
      "TXT_PINCODE_LOCALITY": "BEHAL",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125029,
      "TXT_PINCODE_LOCALITY": "KAIRU",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125030,
      "TXT_PINCODE_LOCALITY": "JUI KHURD",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125031,
      "TXT_PINCODE_LOCALITY": "TIGRANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125032,
      "TXT_PINCODE_LOCALITY": "BAWANI KHERA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125033,
      "TXT_PINCODE_LOCALITY": "HANSI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125034,
      "TXT_PINCODE_LOCALITY": "ROAD HANSI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125035,
      "TXT_PINCODE_LOCALITY": "JAMALPUR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125036,
      "TXT_PINCODE_LOCALITY": "BHIWANI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125037,
      "TXT_PINCODE_LOCALITY": "NALWA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125038,
      "TXT_PINCODE_LOCALITY": "KHANDA KHURI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125039,
      "TXT_PINCODE_LOCALITY": "NARNAUND",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125040,
      "TXT_PINCODE_LOCALITY": "TOSHAM",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125041,
      "TXT_PINCODE_LOCALITY": "MUNDAL KHURD",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125042,
      "TXT_PINCODE_LOCALITY": "BASS",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125043,
      "TXT_PINCODE_LOCALITY": "RODHAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125044,
      "TXT_PINCODE_LOCALITY": "SATROD KHURD",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125045,
      "TXT_PINCODE_LOCALITY": "BARWA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125046,
      "TXT_PINCODE_LOCALITY": "SEWANI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125047,
      "TXT_PINCODE_LOCALITY": "AGROHA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125048,
      "TXT_PINCODE_LOCALITY": "BADOPAL",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6386,
      "City District Name": "FATEHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125049,
      "TXT_PINCODE_LOCALITY": "SISAI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125050,
      "TXT_PINCODE_LOCALITY": "NAI MANDI FATEHBAD",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1763,
      "City District Name": "SIRSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125051,
      "TXT_PINCODE_LOCALITY": "RATTIA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6386,
      "City District Name": "FATEHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125052,
      "TXT_PINCODE_LOCALITY": "MANDI ADAMPUR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125053,
      "TXT_PINCODE_LOCALITY": "BHATTU KALAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6386,
      "City District Name": "FATEHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125054,
      "TXT_PINCODE_LOCALITY": "BAHUDIN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 698,
      "City District Name": "PANIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125055,
      "TXT_PINCODE_LOCALITY": "SIRSA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1763,
      "City District Name": "SIRSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125056,
      "TXT_PINCODE_LOCALITY": "SIRSA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1763,
      "City District Name": "SIRSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125057,
      "TXT_PINCODE_LOCALITY": "KHAIRPUR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1763,
      "City District Name": "SIRSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125058,
      "TXT_PINCODE_LOCALITY": "DING",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1763,
      "City District Name": "SIRSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125060,
      "TXT_PINCODE_LOCALITY": "SIKANDERPUR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1763,
      "City District Name": "SIRSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125061,
      "TXT_PINCODE_LOCALITY": "BAMLA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125062,
      "TXT_PINCODE_LOCALITY": "DHANANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125063,
      "TXT_PINCODE_LOCALITY": "DHANI (MAHU)",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1763,
      "City District Name": "SIRSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125064,
      "TXT_PINCODE_LOCALITY": "DHIGWAN JATAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1763,
      "City District Name": "SIRSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125065,
      "TXT_PINCODE_LOCALITY": "LOHNI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125066,
      "TXT_PINCODE_LOCALITY": "MEHNDA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125067,
      "TXT_PINCODE_LOCALITY": "REWASA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 818,
      "City District Name": "MAHENDRAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125068,
      "TXT_PINCODE_LOCALITY": "SORKHI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125069,
      "TXT_PINCODE_LOCALITY": "BASSI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 697,
      "City District Name": "KARNAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125070,
      "TXT_PINCODE_LOCALITY": "BHATLA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125071,
      "TXT_PINCODE_LOCALITY": "DHANA KALAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125072,
      "TXT_PINCODE_LOCALITY": "KUMBHA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125073,
      "TXT_PINCODE_LOCALITY": "MIRCHPUR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125074,
      "TXT_PINCODE_LOCALITY": "PETWAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125075,
      "TXT_PINCODE_LOCALITY": "SRI JIWAN NAGAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125076,
      "TXT_PINCODE_LOCALITY": "RANIA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1763,
      "City District Name": "SIRSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125077,
      "TXT_PINCODE_LOCALITY": "ODBAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1763,
      "City District Name": "SIRSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125078,
      "TXT_PINCODE_LOCALITY": "BARAGUDAH",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1763,
      "City District Name": "SIRSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125079,
      "TXT_PINCODE_LOCALITY": "HAROLI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6386,
      "City District Name": "FATEHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125080,
      "TXT_PINCODE_LOCALITY": "BIGHAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6386,
      "City District Name": "FATEHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125081,
      "TXT_PINCODE_LOCALITY": "CHANOT",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125082,
      "TXT_PINCODE_LOCALITY": "PUTHI MANGAL KHAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125083,
      "TXT_PINCODE_LOCALITY": "UMRA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125084,
      "TXT_PINCODE_LOCALITY": "JAMAURI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125085,
      "TXT_PINCODE_LOCALITY": "KAIMARI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125086,
      "TXT_PINCODE_LOCALITY": "KHARAR AILAPUR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125087,
      "TXT_PINCODE_LOCALITY": "LADWA(HISSAR)",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125088,
      "TXT_PINCODE_LOCALITY": "MOHAMADPUR ROHI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6386,
      "City District Name": "FATEHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125101,
      "TXT_PINCODE_LOCALITY": "CHAUTALA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1763,
      "City District Name": "SIRSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125102,
      "TXT_PINCODE_LOCALITY": "ELLENABAD",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1763,
      "City District Name": "SIRSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125103,
      "TXT_PINCODE_LOCALITY": "GORIWALA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1763,
      "City District Name": "SIRSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125104,
      "TXT_PINCODE_LOCALITY": "MANDI DABWALI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1763,
      "City District Name": "SIRSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125105,
      "TXT_PINCODE_LOCALITY": "MANGALI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1763,
      "City District Name": "SIRSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125106,
      "TXT_PINCODE_LOCALITY": "DHARSUL KALAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6386,
      "City District Name": "FATEHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125107,
      "TXT_PINCODE_LOCALITY": "ABUSHAHAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1763,
      "City District Name": "SIRSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125108,
      "TXT_PINCODE_LOCALITY": "HAZARAWAN KALAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6386,
      "City District Name": "FATEHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125109,
      "TXT_PINCODE_LOCALITY": "MOHBATPUR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125110,
      "TXT_PINCODE_LOCALITY": "CHADIWAL",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1763,
      "City District Name": "SIRSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125111,
      "TXT_PINCODE_LOCALITY": "BHUNA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6386,
      "City District Name": "FATEHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125112,
      "TXT_PINCODE_LOCALITY": "SAFINDON CITY",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125113,
      "TXT_PINCODE_LOCALITY": "SANIANA B.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125119,
      "TXT_PINCODE_LOCALITY": "TOHANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6386,
      "City District Name": "FATEHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125120,
      "TXT_PINCODE_LOCALITY": "TOHANA S.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6386,
      "City District Name": "FATEHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125121,
      "TXT_PINCODE_LOCALITY": "BARWALA MANDI S.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125122,
      "TXT_PINCODE_LOCALITY": "ISHARHERI(126)",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125133,
      "TXT_PINCODE_LOCALITY": "JAKHAL MANDI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6386,
      "City District Name": "FATEHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125201,
      "TXT_PINCODE_LOCALITY": "KALANWALI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1763,
      "City District Name": "SIRSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125202,
      "TXT_PINCODE_LOCALITY": "RORI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1763,
      "City District Name": "SIRSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 125203,
      "TXT_PINCODE_LOCALITY": "ANAJ MANDI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1763,
      "City District Name": "SIRSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126001,
      "TXT_PINCODE_LOCALITY": "JAKHAL MANDI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6386,
      "City District Name": "FATEHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126006,
      "TXT_PINCODE_LOCALITY": "DAULATPUR (HISSAR)",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126007,
      "TXT_PINCODE_LOCALITY": "PABRA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126008,
      "TXT_PINCODE_LOCALITY": "BHUNA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6386,
      "City District Name": "FATEHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126009,
      "TXT_PINCODE_LOCALITY": "UKLANA MANDI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126010,
      "TXT_PINCODE_LOCALITY": "BARWALA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126011,
      "TXT_PINCODE_LOCALITY": "BARWALA MANDI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126013,
      "TXT_PINCODE_LOCALITY": "KHEDAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126014,
      "TXT_PINCODE_LOCALITY": "RAJLI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126015,
      "TXT_PINCODE_LOCALITY": "PRABHUWALA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126025,
      "TXT_PINCODE_LOCALITY": "NEHLA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126027,
      "TXT_PINCODE_LOCALITY": "HASSANGARH (HISSAR)",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126101,
      "TXT_PINCODE_LOCALITY": "JULANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126102,
      "TXT_PINCODE_LOCALITY": "JIND HO",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126103,
      "TXT_PINCODE_LOCALITY": "BIBIPUR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126104,
      "TXT_PINCODE_LOCALITY": "DEORAD",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126105,
      "TXT_PINCODE_LOCALITY": "IGRAH",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126106,
      "TXT_PINCODE_LOCALITY": "KANDELA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126107,
      "TXT_PINCODE_LOCALITY": "KILAZAFARGARH",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126108,
      "TXT_PINCODE_LOCALITY": "MALUI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126109,
      "TXT_PINCODE_LOCALITY": "PAULI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126110,
      "TXT_PINCODE_LOCALITY": "RAM RAI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126111,
      "TXT_PINCODE_LOCALITY": "SHAHPUR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126112,
      "TXT_PINCODE_LOCALITY": "SAFIDON",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126113,
      "TXT_PINCODE_LOCALITY": "PILUKHERA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126114,
      "TXT_PINCODE_LOCALITY": "SHAMLO KALAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126115,
      "TXT_PINCODE_LOCALITY": "UCHANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126116,
      "TXT_PINCODE_LOCALITY": "NARWANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126117,
      "TXT_PINCODE_LOCALITY": "KALAYAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1831,
      "City District Name": "KAITHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126118,
      "TXT_PINCODE_LOCALITY": "DHAMTA SAHIB",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126119,
      "TXT_PINCODE_LOCALITY": "TOHANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126120,
      "TXT_PINCODE_LOCALITY": "TOHANAMANDI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126121,
      "TXT_PINCODE_LOCALITY": "KALWA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126122,
      "TXT_PINCODE_LOCALITY": "KANHERI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6386,
      "City District Name": "FATEHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126123,
      "TXT_PINCODE_LOCALITY": "SAMAIN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6386,
      "City District Name": "FATEHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126124,
      "TXT_PINCODE_LOCALITY": "BARODA JIND",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126125,
      "TXT_PINCODE_LOCALITY": "NAGURAN (81)",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126126,
      "TXT_PINCODE_LOCALITY": "BALERKHA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126127,
      "TXT_PINCODE_LOCALITY": "DABLAIN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126128,
      "TXT_PINCODE_LOCALITY": "DHAKAL",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1831,
      "City District Name": "KAITHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126129,
      "TXT_PINCODE_LOCALITY": "DIDWARA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126130,
      "TXT_PINCODE_LOCALITY": "GANGOOLI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126131,
      "TXT_PINCODE_LOCALITY": "GARHWALI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126132,
      "TXT_PINCODE_LOCALITY": "GHATAULI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126133,
      "TXT_PINCODE_LOCALITY": "KALAYAT MANDI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1831,
      "City District Name": "KAITHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126134,
      "TXT_PINCODE_LOCALITY": "KHARAK PANDWAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1831,
      "City District Name": "KAITHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126135,
      "TXT_PINCODE_LOCALITY": "LAWAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126136,
      "TXT_PINCODE_LOCALITY": "KHATKAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126139,
      "TXT_PINCODE_LOCALITY": "SEEMLA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1831,
      "City District Name": "KAITHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126140,
      "TXT_PINCODE_LOCALITY": "UJHANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126141,
      "TXT_PINCODE_LOCALITY": "HAT",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126143,
      "TXT_PINCODE_LOCALITY": "DHIGANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126145,
      "TXT_PINCODE_LOCALITY": "BALU",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1831,
      "City District Name": "KAITHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126146,
      "TXT_PINCODE_LOCALITY": "BHAMBEVA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126147,
      "TXT_PINCODE_LOCALITY": "BUDHA KHERA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126148,
      "TXT_PINCODE_LOCALITY": "BITHMARA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126149,
      "TXT_PINCODE_LOCALITY": "SINGHANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126150,
      "TXT_PINCODE_LOCALITY": "SEWAHA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126151,
      "TXT_PINCODE_LOCALITY": "BADANPUR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126152,
      "TXT_PINCODE_LOCALITY": "DHANODA KALAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126153,
      "TXT_PINCODE_LOCALITY": "DANODA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 126154,
      "TXT_PINCODE_LOCALITY": "DHARAUDHI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 127020,
      "TXT_PINCODE_LOCALITY": "NETAJI COLONY",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 127021,
      "TXT_PINCODE_LOCALITY": "SECTOR 13",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 127022,
      "TXT_PINCODE_LOCALITY": "DUDHWA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 127025,
      "TXT_PINCODE_LOCALITY": "BAUND S.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 127026,
      "TXT_PINCODE_LOCALITY": "BIRHI KALAN S.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 127027,
      "TXT_PINCODE_LOCALITY": "BHIWANI S. O.",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 127028,
      "TXT_PINCODE_LOCALITY": "BEHL S.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 127029,
      "TXT_PINCODE_LOCALITY": "NIGANA KALAN (43)",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6010,
      "City District Name": "TOSHAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 127030,
      "TXT_PINCODE_LOCALITY": "ASALWAS MARHETA B.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 127031,
      "TXT_PINCODE_LOCALITY": "TIGRANA S.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 127032,
      "TXT_PINCODE_LOCALITY": "BAWANI KHERA S.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 127035,
      "TXT_PINCODE_LOCALITY": "ROHNAT (35)",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 2400,
      "City District Name": "BAWANI KHERA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 127040,
      "TXT_PINCODE_LOCALITY": "KHANAK",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 127041,
      "TXT_PINCODE_LOCALITY": "MUNDHAL KHURD (57)",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 127042,
      "TXT_PINCODE_LOCALITY": "SANWAR S.O (BHIWANI)",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 127043,
      "TXT_PINCODE_LOCALITY": "BHUSHAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 127045,
      "TXT_PINCODE_LOCALITY": "BARWA (133)",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 5727,
      "City District Name": "SIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 127046,
      "TXT_PINCODE_LOCALITY": "SIWANI S.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 127110,
      "TXT_PINCODE_LOCALITY": "RANILA (130)",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 442662,
      "City District Name": "DADRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 127111,
      "TXT_PINCODE_LOCALITY": "BAJINA B.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 127114,
      "TXT_PINCODE_LOCALITY": "KHARAK KALAN (131)",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 127201,
      "TXT_PINCODE_LOCALITY": "LOHARU",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 127306,
      "TXT_PINCODE_LOCALITY": "CHARKHI DADRI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 127307,
      "TXT_PINCODE_LOCALITY": "BHAGWI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 127308,
      "TXT_PINCODE_LOCALITY": "CHARKHI DADRI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 127309,
      "TXT_PINCODE_LOCALITY": "MANHERU",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 127310,
      "TXT_PINCODE_LOCALITY": "BADHWANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 127311,
      "TXT_PINCODE_LOCALITY": "BHIWANI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 127312,
      "TXT_PINCODE_LOCALITY": "BHIWANI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 693,
      "City District Name": "BHIWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 130107,
      "TXT_PINCODE_LOCALITY": "GAWALRA (81)",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 442663,
      "City District Name": "ISRANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 130320,
      "TXT_PINCODE_LOCALITY": "MALAPPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131001,
      "TXT_PINCODE_LOCALITY": "MODEL TOWN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1518,
      "City District Name": "SONEPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131002,
      "TXT_PINCODE_LOCALITY": "LOHARI TIBA(180)",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131004,
      "TXT_PINCODE_LOCALITY": "KUNDLI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131021,
      "TXT_PINCODE_LOCALITY": "BAHALGARH",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131022,
      "TXT_PINCODE_LOCALITY": "BHATGAON",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131023,
      "TXT_PINCODE_LOCALITY": "JAKHAULI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131024,
      "TXT_PINCODE_LOCALITY": "JUAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131025,
      "TXT_PINCODE_LOCALITY": "MOHANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131026,
      "TXT_PINCODE_LOCALITY": "JASWARKHERI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131027,
      "TXT_PINCODE_LOCALITY": "MURTHAL",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131028,
      "TXT_PINCODE_LOCALITY": "KUNDLI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131029,
      "TXT_PINCODE_LOCALITY": "P S RAI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131030,
      "TXT_PINCODE_LOCALITY": "RATHDANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131031,
      "TXT_PINCODE_LOCALITY": "RATTAN GARH",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131032,
      "TXT_PINCODE_LOCALITY": "PIPLI KHERA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131033,
      "TXT_PINCODE_LOCALITY": "BHAGAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131034,
      "TXT_PINCODE_LOCALITY": "BARABANSI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131035,
      "TXT_PINCODE_LOCALITY": "HARSANA KALAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131036,
      "TXT_PINCODE_LOCALITY": "LEHRANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131037,
      "TXT_PINCODE_LOCALITY": "KHEWRA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131038,
      "TXT_PINCODE_LOCALITY": "PIPLI ( SONEPAT)",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131039,
      "TXT_PINCODE_LOCALITY": "BHAGAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131040,
      "TXT_PINCODE_LOCALITY": "SONIPAT",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131101,
      "TXT_PINCODE_LOCALITY": "GANAUR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131102,
      "TXT_PINCODE_LOCALITY": "PURKHAS",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131103,
      "TXT_PINCODE_LOCALITY": "NAHRI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131104,
      "TXT_PINCODE_LOCALITY": "AKBARPUR BAROTA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131105,
      "TXT_PINCODE_LOCALITY": "BEGA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131106,
      "TXT_PINCODE_LOCALITY": "BHIDLAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131107,
      "TXT_PINCODE_LOCALITY": "KHANDA (SONEPAT)",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131108,
      "TXT_PINCODE_LOCALITY": "CHHETERA BHADUPUR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131109,
      "TXT_PINCODE_LOCALITY": "DATAULI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131110,
      "TXT_PINCODE_LOCALITY": "GHASAULI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131111,
      "TXT_PINCODE_LOCALITY": "KHERI GUJAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131112,
      "TXT_PINCODE_LOCALITY": "KAKAROI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131113,
      "TXT_PINCODE_LOCALITY": "GOPALPUR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131114,
      "TXT_PINCODE_LOCALITY": "MEHLANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131115,
      "TXT_PINCODE_LOCALITY": "CHULKANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1518,
      "City District Name": "SONEPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131301,
      "TXT_PINCODE_LOCALITY": "GOHANA S.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131302,
      "TXT_PINCODE_LOCALITY": "SONIPAT",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131303,
      "TXT_PINCODE_LOCALITY": "PATTI KAILANA TALUKA KHANPUR KALAN (41)",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3274,
      "City District Name": "GOHANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131304,
      "TXT_PINCODE_LOCALITY": "CHHAPRA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131305,
      "TXT_PINCODE_LOCALITY": "KASANDI (59)",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3274,
      "City District Name": "GOHANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131306,
      "TXT_PINCODE_LOCALITY": "DHURANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131310,
      "TXT_PINCODE_LOCALITY": "SONIPAT S. O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131402,
      "TXT_PINCODE_LOCALITY": "KHARKHAUDA S.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131403,
      "TXT_PINCODE_LOCALITY": "THANA KHURD (221)",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6405,
      "City District Name": "KHARKHODA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131407,
      "TXT_PINCODE_LOCALITY": "GARHI SISANA (27R)",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6405,
      "City District Name": "KHARKHODA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131408,
      "TXT_PINCODE_LOCALITY": "FARMANA S.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 131409,
      "TXT_PINCODE_LOCALITY": "AANWALI (71)",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3274,
      "City District Name": "GOHANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132001,
      "TXT_PINCODE_LOCALITY": "KARNAL RAMNAGAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 697,
      "City District Name": "KARNAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132002,
      "TXT_PINCODE_LOCALITY": "SANGHOWA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 697,
      "City District Name": "KARNAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132008,
      "TXT_PINCODE_LOCALITY": "CHANDLANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1831,
      "City District Name": "KAITHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132020,
      "TXT_PINCODE_LOCALITY": "DHAND",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 697,
      "City District Name": "KARNAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132021,
      "TXT_PINCODE_LOCALITY": "PHARAL",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1831,
      "City District Name": "KAITHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132022,
      "TXT_PINCODE_LOCALITY": "KUNJPURA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 697,
      "City District Name": "KARNAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132023,
      "TXT_PINCODE_LOCALITY": "KUNJPURA SAINIK SCHOOL",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 697,
      "City District Name": "KARNAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132024,
      "TXT_PINCODE_LOCALITY": "NISSING",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 697,
      "City District Name": "KARNAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132025,
      "TXT_PINCODE_LOCALITY": "KACHHAWA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 697,
      "City District Name": "KARNAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132026,
      "TXT_PINCODE_LOCALITY": "FATEHPUR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1831,
      "City District Name": "KAITHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132027,
      "TXT_PINCODE_LOCALITY": "KAITHAL",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1831,
      "City District Name": "KAITHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132029,
      "TXT_PINCODE_LOCALITY": "THANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1831,
      "City District Name": "KAITHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132032,
      "TXT_PINCODE_LOCALITY": "KEORAK",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1831,
      "City District Name": "KAITHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132033,
      "TXT_PINCODE_LOCALITY": "SIWAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 697,
      "City District Name": "KARNAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132034,
      "TXT_PINCODE_LOCALITY": "CHIKA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1831,
      "City District Name": "KAITHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132035,
      "TXT_PINCODE_LOCALITY": "GULA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1831,
      "City District Name": "KAITHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132036,
      "TXT_PINCODE_LOCALITY": "JUNDLA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 697,
      "City District Name": "KARNAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132037,
      "TXT_PINCODE_LOCALITY": "MADHUBAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 697,
      "City District Name": "KARNAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132038,
      "TXT_PINCODE_LOCALITY": "AMIN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 705,
      "City District Name": "KURUKSHETRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132039,
      "TXT_PINCODE_LOCALITY": "ASSANDH",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 697,
      "City District Name": "KARNAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132040,
      "TXT_PINCODE_LOCALITY": "BAL RANGAN B. O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 697,
      "City District Name": "KARNAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132041,
      "TXT_PINCODE_LOCALITY": "INDRI INDUSTRIAL AREA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 697,
      "City District Name": "KARNAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132042,
      "TXT_PINCODE_LOCALITY": "FATEHPUR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1831,
      "City District Name": "KAITHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132043,
      "TXT_PINCODE_LOCALITY": "PAI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1831,
      "City District Name": "KAITHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132044,
      "TXT_PINCODE_LOCALITY": "RAJAUND",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1831,
      "City District Name": "KAITHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132045,
      "TXT_PINCODE_LOCALITY": "MOWANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1831,
      "City District Name": "KAITHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132046,
      "TXT_PINCODE_LOCALITY": "SALWAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 697,
      "City District Name": "KARNAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132047,
      "TXT_PINCODE_LOCALITY": "JAKOLI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1831,
      "City District Name": "KAITHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132048,
      "TXT_PINCODE_LOCALITY": "PABNAWA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1831,
      "City District Name": "KAITHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132049,
      "TXT_PINCODE_LOCALITY": "MANAS",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1831,
      "City District Name": "KAITHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132050,
      "TXT_PINCODE_LOCALITY": "GHEER",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 697,
      "City District Name": "KARNAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132051,
      "TXT_PINCODE_LOCALITY": "MUNAK",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 697,
      "City District Name": "KARNAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132052,
      "TXT_PINCODE_LOCALITY": "GONDER",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 697,
      "City District Name": "KARNAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132053,
      "TXT_PINCODE_LOCALITY": "KHERI SHERIFALI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1831,
      "City District Name": "KAITHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132054,
      "TXT_PINCODE_LOCALITY": "NANGAL",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 697,
      "City District Name": "KARNAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132058,
      "TXT_PINCODE_LOCALITY": "GONDHAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 697,
      "City District Name": "KARNAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132100,
      "TXT_PINCODE_LOCALITY": "BEAS PROJECT PANIPAT",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 698,
      "City District Name": "PANIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132101,
      "TXT_PINCODE_LOCALITY": "SAMALKHA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 698,
      "City District Name": "PANIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132102,
      "TXT_PINCODE_LOCALITY": "PATTI KALAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 698,
      "City District Name": "PANIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132103,
      "TXT_PINCODE_LOCALITY": "PANIPAT N S M",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 697,
      "City District Name": "KARNAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132104,
      "TXT_PINCODE_LOCALITY": "PANIPAT THERMAL",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 698,
      "City District Name": "PANIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132105,
      "TXT_PINCODE_LOCALITY": "PROJECT PLAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 698,
      "City District Name": "PANIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132106,
      "TXT_PINCODE_LOCALITY": "PROJECT PANIPAT",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 698,
      "City District Name": "PANIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132107,
      "TXT_PINCODE_LOCALITY": "ISRANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 698,
      "City District Name": "PANIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132108,
      "TXT_PINCODE_LOCALITY": "PANIPAT",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 698,
      "City District Name": "PANIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132109,
      "TXT_PINCODE_LOCALITY": "CHULKANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 698,
      "City District Name": "PANIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132110,
      "TXT_PINCODE_LOCALITY": "SEWAH",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 698,
      "City District Name": "PANIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132111,
      "TXT_PINCODE_LOCALITY": "ALEWA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132112,
      "TXT_PINCODE_LOCALITY": "DHATRAT",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 698,
      "City District Name": "PANIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132113,
      "TXT_PINCODE_LOCALITY": "MADLANDA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 698,
      "City District Name": "PANIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132114,
      "TXT_PINCODE_LOCALITY": "ANCHLA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 697,
      "City District Name": "KARNAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132115,
      "TXT_PINCODE_LOCALITY": "HATHWALA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 698,
      "City District Name": "PANIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132116,
      "TXT_PINCODE_LOCALITY": "TARAURI MANDI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 697,
      "City District Name": "KARNAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132117,
      "TXT_PINCODE_LOCALITY": "NILOKHERI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 697,
      "City District Name": "KARNAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132118,
      "TXT_PINCODE_LOCALITY": "THANESAR TOWN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 705,
      "City District Name": "KURUKSHETRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132119,
      "TXT_PINCODE_LOCALITY": "KURUKSHETRA UNIVERSITY",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 705,
      "City District Name": "KURUKSHETRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132120,
      "TXT_PINCODE_LOCALITY": "JYOTISAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 705,
      "City District Name": "KURUKSHETRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132121,
      "TXT_PINCODE_LOCALITY": "NAGURAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132122,
      "TXT_PINCODE_LOCALITY": "ATTA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 698,
      "City District Name": "PANIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132123,
      "TXT_PINCODE_LOCALITY": "TIRAWARI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1831,
      "City District Name": "KAITHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132124,
      "TXT_PINCODE_LOCALITY": "VEHANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 697,
      "City District Name": "KARNAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132125,
      "TXT_PINCODE_LOCALITY": "SAUNKRA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 697,
      "City District Name": "KARNAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132128,
      "TXT_PINCODE_LOCALITY": "PEHOWA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 705,
      "City District Name": "KURUKSHETRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132129,
      "TXT_PINCODE_LOCALITY": "ISMAILABAD",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 705,
      "City District Name": "KURUKSHETRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132130,
      "TXT_PINCODE_LOCALITY": "BYANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 705,
      "City District Name": "KURUKSHETRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132131,
      "TXT_PINCODE_LOCALITY": "PIPLI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 705,
      "City District Name": "KURUKSHETRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132132,
      "TXT_PINCODE_LOCALITY": "LADWA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 705,
      "City District Name": "KURUKSHETRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132133,
      "TXT_PINCODE_LOCALITY": "RADAUR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 694,
      "City District Name": "YAMUNANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132134,
      "TXT_PINCODE_LOCALITY": "DAULA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 705,
      "City District Name": "KURUKSHETRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132135,
      "TXT_PINCODE_LOCALITY": "SHAHBAD MARKHANDA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 705,
      "City District Name": "KURUKSHETRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132136,
      "TXT_PINCODE_LOCALITY": "THOL",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 705,
      "City District Name": "KURUKSHETRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132137,
      "TXT_PINCODE_LOCALITY": "UMRI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 705,
      "City District Name": "KURUKSHETRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132138,
      "TXT_PINCODE_LOCALITY": "HABRI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1831,
      "City District Name": "KAITHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132139,
      "TXT_PINCODE_LOCALITY": "BURSHAM",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 698,
      "City District Name": "PANIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132140,
      "TXT_PINCODE_LOCALITY": "BIHOLI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 698,
      "City District Name": "PANIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132141,
      "TXT_PINCODE_LOCALITY": "BUANA LAKHU",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 698,
      "City District Name": "PANIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132142,
      "TXT_PINCODE_LOCALITY": "DAHAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 698,
      "City District Name": "PANIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132143,
      "TXT_PINCODE_LOCALITY": "DIKADIA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 698,
      "City District Name": "PANIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132144,
      "TXT_PINCODE_LOCALITY": "JAURASI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 705,
      "City District Name": "KURUKSHETRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132145,
      "TXT_PINCODE_LOCALITY": "NAULTHA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 698,
      "City District Name": "PANIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132146,
      "TXT_PINCODE_LOCALITY": "AHAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 698,
      "City District Name": "PANIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132147,
      "TXT_PINCODE_LOCALITY": "KURAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 698,
      "City District Name": "PANIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132148,
      "TXT_PINCODE_LOCALITY": "KOHAND",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 697,
      "City District Name": "KARNAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132149,
      "TXT_PINCODE_LOCALITY": "MAHOTI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 698,
      "City District Name": "PANIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132150,
      "TXT_PINCODE_LOCALITY": "NARAINA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 697,
      "City District Name": "KARNAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132151,
      "TXT_PINCODE_LOCALITY": "NOORWALA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 698,
      "City District Name": "PANIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132152,
      "TXT_PINCODE_LOCALITY": "RAMPURA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132153,
      "TXT_PINCODE_LOCALITY": "SONEPAT MANDI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 689,
      "City District Name": "SONIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132154,
      "TXT_PINCODE_LOCALITY": "BABAIL",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 698,
      "City District Name": "PANIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132155,
      "TXT_PINCODE_LOCALITY": "KURANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 698,
      "City District Name": "PANIPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132156,
      "TXT_PINCODE_LOCALITY": "BHAGWANPUR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 705,
      "City District Name": "KURUKSHETRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132157,
      "TXT_PINCODE_LOCALITY": "NIGDHU",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 697,
      "City District Name": "KARNAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 132410,
      "TXT_PINCODE_LOCALITY": "BIHOLI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 705,
      "City District Name": "KURUKSHETRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 133001,
      "TXT_PINCODE_LOCALITY": "AMBALA A K LINES",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6529,
      "City District Name": "AMBALA CITY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 133002,
      "TXT_PINCODE_LOCALITY": "INDER NAGAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6528,
      "City District Name": "AMBALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 133003,
      "TXT_PINCODE_LOCALITY": "PREM NAGAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6528,
      "City District Name": "AMBALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 133004,
      "TXT_PINCODE_LOCALITY": "KULDEEP NAGAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6528,
      "City District Name": "AMBALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 133005,
      "TXT_PINCODE_LOCALITY": "BABYAL S.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6528,
      "City District Name": "AMBALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 133006,
      "TXT_PINCODE_LOCALITY": "IND-ESTATE S.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6528,
      "City District Name": "AMBALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 133008,
      "TXT_PINCODE_LOCALITY": "AMBALA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6528,
      "City District Name": "AMBALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 133010,
      "TXT_PINCODE_LOCALITY": "AMBALA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6528,
      "City District Name": "AMBALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 133021,
      "TXT_PINCODE_LOCALITY": "NEW PARAS NAGAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6528,
      "City District Name": "AMBALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 133101,
      "TXT_PINCODE_LOCALITY": "BIHTA S.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6528,
      "City District Name": "AMBALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 133102,
      "TXT_PINCODE_LOCALITY": "AMBALA S.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6528,
      "City District Name": "AMBALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 133103,
      "TXT_PINCODE_LOCALITY": "BHAMBOL B.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 694,
      "City District Name": "YAMUNANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 133104,
      "TXT_PINCODE_LOCALITY": "SAHA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6528,
      "City District Name": "AMBALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 133105,
      "TXT_PINCODE_LOCALITY": "KHERI DARSHAN SINGH (354)",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1277,
      "City District Name": "JAGADHRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 133201,
      "TXT_PINCODE_LOCALITY": "SUBRI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6528,
      "City District Name": "AMBALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 133202,
      "TXT_PINCODE_LOCALITY": "DHIN S.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6528,
      "City District Name": "AMBALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 133203,
      "TXT_PINCODE_LOCALITY": "MULLANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6528,
      "City District Name": "AMBALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 133204,
      "TXT_PINCODE_LOCALITY": "SADHAURA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 694,
      "City District Name": "YAMUNANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 133205,
      "TXT_PINCODE_LOCALITY": "THUMBER",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6528,
      "City District Name": "AMBALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 133206,
      "TXT_PINCODE_LOCALITY": "UDDHAM GARH (341)",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1277,
      "City District Name": "JAGADHRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 133207,
      "TXT_PINCODE_LOCALITY": "AMBALA S. O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6528,
      "City District Name": "AMBALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 133208,
      "TXT_PINCODE_LOCALITY": "THAMBAR P. O.",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6528,
      "City District Name": "AMBALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 133301,
      "TXT_PINCODE_LOCALITY": "B C W SURAJPUR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 692,
      "City District Name": "PANCHKULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 133302,
      "TXT_PINCODE_LOCALITY": "KALKA RAILWAY STATION",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 692,
      "City District Name": "PANCHKULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 134001,
      "TXT_PINCODE_LOCALITY": "AMBALA B.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6528,
      "City District Name": "AMBALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 134002,
      "TXT_PINCODE_LOCALITY": "CIVIL LINES",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 692,
      "City District Name": "PANCHKULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 134003,
      "TXT_PINCODE_LOCALITY": "AMBALA TOPKHANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6529,
      "City District Name": "AMBALA CITY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 134004,
      "TXT_PINCODE_LOCALITY": "RAJAWALI (288)",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 4726,
      "City District Name": "NARAINGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 134005,
      "TXT_PINCODE_LOCALITY": "JANDLI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6528,
      "City District Name": "AMBALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 134007,
      "TXT_PINCODE_LOCALITY": "BALDEV NAGAR S.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6528,
      "City District Name": "AMBALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 134008,
      "TXT_PINCODE_LOCALITY": "AMBALA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6528,
      "City District Name": "AMBALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 134009,
      "TXT_PINCODE_LOCALITY": "SECTOR 6",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 692,
      "City District Name": "PANCHKULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 134011,
      "TXT_PINCODE_LOCALITY": "KHATOLI (30)",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6528,
      "City District Name": "AMBALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 134012,
      "TXT_PINCODE_LOCALITY": "RATTANGARH URF NAKATPUR (296)",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6528,
      "City District Name": "AMBALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 134101,
      "TXT_PINCODE_LOCALITY": "PINJORE H M T",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 692,
      "City District Name": "PANCHKULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 134102,
      "TXT_PINCODE_LOCALITY": "PINJORE",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 692,
      "City District Name": "PANCHKULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 134103,
      "TXT_PINCODE_LOCALITY": "I.T.B.P. BHANU",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 692,
      "City District Name": "PANCHKULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 134104,
      "TXT_PINCODE_LOCALITY": "CRPF PUNJORE",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 692,
      "City District Name": "PANCHKULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 134105,
      "TXT_PINCODE_LOCALITY": "PANCHKULA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 692,
      "City District Name": "PANCHKULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 134107,
      "TXT_PINCODE_LOCALITY": "CHANDI MANDIR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 692,
      "City District Name": "PANCHKULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 134108,
      "TXT_PINCODE_LOCALITY": "PANCHKULA GURUKUL",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 692,
      "City District Name": "PANCHKULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 134109,
      "TXT_PINCODE_LOCALITY": "PHASE 2",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6528,
      "City District Name": "AMBALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 134110,
      "TXT_PINCODE_LOCALITY": "BHABAT",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 692,
      "City District Name": "PANCHKULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 134111,
      "TXT_PINCODE_LOCALITY": "BENONDI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 692,
      "City District Name": "PANCHKULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 134112,
      "TXT_PINCODE_LOCALITY": "PANCHKULA SEC 4",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 692,
      "City District Name": "PANCHKULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 134113,
      "TXT_PINCODE_LOCALITY": "PANCHKULA SEC 15",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 692,
      "City District Name": "PANCHKULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 134114,
      "TXT_PINCODE_LOCALITY": "MANSA DEVI SEC-5 S.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 692,
      "City District Name": "PANCHKULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 134115,
      "TXT_PINCODE_LOCALITY": "PANCHKULA SECTOR 12",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 692,
      "City District Name": "PANCHKULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 134116,
      "TXT_PINCODE_LOCALITY": "PANCHKULA SECTOR 20",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 692,
      "City District Name": "PANCHKULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 134117,
      "TXT_PINCODE_LOCALITY": "SECTOR 20 PANCHKULA S.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 692,
      "City District Name": "PANCHKULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 134118,
      "TXT_PINCODE_LOCALITY": "BARWALA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 692,
      "City District Name": "PANCHKULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 134119,
      "TXT_PINCODE_LOCALITY": "SECTOR 20",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 692,
      "City District Name": "PANCHKULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 134151,
      "TXT_PINCODE_LOCALITY": "KOTIAN (196)",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3698,
      "City District Name": "KALKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 134201,
      "TXT_PINCODE_LOCALITY": "JATWAR S. O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6528,
      "City District Name": "AMBALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 134202,
      "TXT_PINCODE_LOCALITY": "SHAHZADPUR S.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6528,
      "City District Name": "AMBALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 134203,
      "TXT_PINCODE_LOCALITY": "NARAINGARH",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6528,
      "City District Name": "AMBALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 134204,
      "TXT_PINCODE_LOCALITY": "RAIPUR RANI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 692,
      "City District Name": "PANCHKULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 134205,
      "TXT_PINCODE_LOCALITY": "KHET PARALI (218)",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 692,
      "City District Name": "PANCHKULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 134206,
      "TXT_PINCODE_LOCALITY": "AMBALA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6528,
      "City District Name": "AMBALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 135001,
      "TXT_PINCODE_LOCALITY": "YAMUNA NAGAR SUGAR MLS",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 694,
      "City District Name": "YAMUNANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 135002,
      "TXT_PINCODE_LOCALITY": "JAGADHRI COURTS",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 694,
      "City District Name": "YAMUNANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 135003,
      "TXT_PINCODE_LOCALITY": "JAGADHRI TOWN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 694,
      "City District Name": "YAMUNANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 135004,
      "TXT_PINCODE_LOCALITY": "JAGADHRI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1277,
      "City District Name": "JAGADHRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 135021,
      "TXT_PINCODE_LOCALITY": "KHIZRABAD EAST",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 694,
      "City District Name": "YAMUNANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 135101,
      "TXT_PINCODE_LOCALITY": "BURIYA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 694,
      "City District Name": "YAMUNANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 135102,
      "TXT_PINCODE_LOCALITY": "BILASPUR (AMBALA)",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 694,
      "City District Name": "YAMUNANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 135103,
      "TXT_PINCODE_LOCALITY": "CHHACHHRAULI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 694,
      "City District Name": "YAMUNANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 135104,
      "TXT_PINCODE_LOCALITY": "DADUPUR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 694,
      "City District Name": "YAMUNANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 135105,
      "TXT_PINCODE_LOCALITY": "KHARWAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 694,
      "City District Name": "YAMUNANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 135106,
      "TXT_PINCODE_LOCALITY": "TAJEWALA (3)",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 2752,
      "City District Name": "CHHACHHRAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 135116,
      "TXT_PINCODE_LOCALITY": "SON KERA (51)",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 4830,
      "City District Name": "NILOKHERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 135133,
      "TXT_PINCODE_LOCALITY": "RADAUR S.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 694,
      "City District Name": "YAMUNANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 135203,
      "TXT_PINCODE_LOCALITY": "RAMPUR (75)",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 4726,
      "City District Name": "NARAINGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 136020,
      "TXT_PINCODE_LOCALITY": "KAITHAL CITY S.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1831,
      "City District Name": "KAITHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 136021,
      "TXT_PINCODE_LOCALITY": "KAUL S.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1831,
      "City District Name": "KAITHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 136026,
      "TXT_PINCODE_LOCALITY": "PUNDRI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1831,
      "City District Name": "KAITHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 136027,
      "TXT_PINCODE_LOCALITY": "BARAUT",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1831,
      "City District Name": "KAITHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 136030,
      "TXT_PINCODE_LOCALITY": "THANA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 705,
      "City District Name": "KURUKSHETRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 136033,
      "TXT_PINCODE_LOCALITY": "SIWAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1831,
      "City District Name": "KAITHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 136034,
      "TXT_PINCODE_LOCALITY": "CHIKA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1831,
      "City District Name": "KAITHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 136035,
      "TXT_PINCODE_LOCALITY": "KHARKAN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1831,
      "City District Name": "KAITHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 136038,
      "TXT_PINCODE_LOCALITY": "AMIN",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 705,
      "City District Name": "KURUKSHETRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 136042,
      "TXT_PINCODE_LOCALITY": "MUNERHERI (32)",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 442337,
      "City District Name": "FATEHPUR PUNDRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 136043,
      "TXT_PINCODE_LOCALITY": "PAI S.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 705,
      "City District Name": "KURUKSHETRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 136044,
      "TXT_PINCODE_LOCALITY": "RAJOUND S.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1831,
      "City District Name": "KAITHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 136102,
      "TXT_PINCODE_LOCALITY": "LADWA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 4200,
      "City District Name": "LADWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 136112,
      "TXT_PINCODE_LOCALITY": "LADWA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 4200,
      "City District Name": "LADWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 136116,
      "TXT_PINCODE_LOCALITY": "JIND",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1449,
      "City District Name": "JIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 136117,
      "TXT_PINCODE_LOCALITY": "BADSIKRI KALAN B.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1831,
      "City District Name": "KAITHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 136118,
      "TXT_PINCODE_LOCALITY": "KURUKSHETRA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 705,
      "City District Name": "KURUKSHETRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 136119,
      "TXT_PINCODE_LOCALITY": "KURUKSHETRA UNIVERSITY",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 705,
      "City District Name": "KURUKSHETRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 136128,
      "TXT_PINCODE_LOCALITY": "PEHOWA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 705,
      "City District Name": "KURUKSHETRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 136129,
      "TXT_PINCODE_LOCALITY": "ISMAILABAD S.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 705,
      "City District Name": "KURUKSHETRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 136130,
      "TXT_PINCODE_LOCALITY": "BHUSTHALA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 705,
      "City District Name": "KURUKSHETRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 136131,
      "TXT_PINCODE_LOCALITY": "PIPLI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 705,
      "City District Name": "KURUKSHETRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 136132,
      "TXT_PINCODE_LOCALITY": "NGM LADWA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 705,
      "City District Name": "KURUKSHETRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 136135,
      "TXT_PINCODE_LOCALITY": "SHAHBAD",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 705,
      "City District Name": "KURUKSHETRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 136136,
      "TXT_PINCODE_LOCALITY": "THOL S.O",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 705,
      "City District Name": "KURUKSHETRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 136156,
      "TXT_PINCODE_LOCALITY": "JANDOLA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 705,
      "City District Name": "KURUKSHETRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140001,
      "TXT_PINCODE_LOCALITY": "ROPAR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 240,
      "City District Name": "ROPAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140003,
      "TXT_PINCODE_LOCALITY": "KAMALPUR (60)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442946,
      "City District Name": "CHAMKAUR SAHIB",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140007,
      "TXT_PINCODE_LOCALITY": "MINDHA MAJRA (280)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442955,
      "City District Name": "SAS NAGAR (MOHALI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140010,
      "TXT_PINCODE_LOCALITY": "MIADI KALAN (144)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1956,
      "City District Name": "AJNALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140013,
      "TXT_PINCODE_LOCALITY": "DHIANPURA (115).",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442946,
      "City District Name": "CHAMKAUR SAHIB",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140022,
      "TXT_PINCODE_LOCALITY": "JALANDHAR CANTONMENT",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442949,
      "City District Name": "JALANDHAR - I",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140025,
      "TXT_PINCODE_LOCALITY": "PREM NAGAR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442946,
      "City District Name": "CHAMKAUR SAHIB",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140036,
      "TXT_PINCODE_LOCALITY": "SINGHPURA (486)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 2927,
      "City District Name": "DERA BABA NANAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140041,
      "TXT_PINCODE_LOCALITY": "RASULPUR (229)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442946,
      "City District Name": "CHAMKAUR SAHIB",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140045,
      "TXT_PINCODE_LOCALITY": "SINGHPURA (17)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442954,
      "City District Name": "RUP NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140048,
      "TXT_PINCODE_LOCALITY": "MAHESHARI (7)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 707,
      "City District Name": "MOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140054,
      "TXT_PINCODE_LOCALITY": "KOTLA BET (284)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442946,
      "City District Name": "CHAMKAUR SAHIB",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140063,
      "TXT_PINCODE_LOCALITY": "SANGATPUR (263)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442946,
      "City District Name": "CHAMKAUR SAHIB",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140073,
      "TXT_PINCODE_LOCALITY": "SHAMPURA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442954,
      "City District Name": "RUP NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140081,
      "TXT_PINCODE_LOCALITY": "SIMRAN NAGAR.",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442954,
      "City District Name": "RUP NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140084,
      "TXT_PINCODE_LOCALITY": "BARA BANDE MEHAL (173)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442954,
      "City District Name": "RUP NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140085,
      "TXT_PINCODE_LOCALITY": "MAHLAN (58)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442954,
      "City District Name": "RUP NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140087,
      "TXT_PINCODE_LOCALITY": "MANUSUHA KHURD (54)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442954,
      "City District Name": "RUP NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140094,
      "TXT_PINCODE_LOCALITY": "OIND (294)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442946,
      "City District Name": "CHAMKAUR SAHIB",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140095,
      "TXT_PINCODE_LOCALITY": "NUNOWAL (77)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442954,
      "City District Name": "RUP NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140096,
      "TXT_PINCODE_LOCALITY": "SOLKHIAN (217)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442954,
      "City District Name": "RUP NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140098,
      "TXT_PINCODE_LOCALITY": "GOBINDPUR (174)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442954,
      "City District Name": "RUP NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140101,
      "TXT_PINCODE_LOCALITY": "RUPNAGAR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 5402,
      "City District Name": "RUPNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140102,
      "TXT_PINCODE_LOCALITY": "LUTHERI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 5402,
      "City District Name": "RUPNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140103,
      "TXT_PINCODE_LOCALITY": "SECTOR 70",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 228,
      "City District Name": "MOHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140104,
      "TXT_PINCODE_LOCALITY": "SAHAURAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 5402,
      "City District Name": "RUPNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140105,
      "TXT_PINCODE_LOCALITY": "BABHAT",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 5402,
      "City District Name": "RUPNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140106,
      "TXT_PINCODE_LOCALITY": "PREET NAGAR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1301,
      "City District Name": "FATEHGARH SAHIB",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140107,
      "TXT_PINCODE_LOCALITY": "PATIALA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 227,
      "City District Name": "PATIALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140108,
      "TXT_PINCODE_LOCALITY": "MAINPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 5402,
      "City District Name": "RUPNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140109,
      "TXT_PINCODE_LOCALITY": "KHIZRABAD WEST",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 5402,
      "City District Name": "RUPNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140110,
      "TXT_PINCODE_LOCALITY": "SIALBA MAJRI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 5402,
      "City District Name": "RUPNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140111,
      "TXT_PINCODE_LOCALITY": "BELA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 5402,
      "City District Name": "RUPNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140112,
      "TXT_PINCODE_LOCALITY": "CHAMKAUR SAHIB",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 5402,
      "City District Name": "RUPNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140113,
      "TXT_PINCODE_LOCALITY": "GHANAULI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 5402,
      "City District Name": "RUPNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140114,
      "TXT_PINCODE_LOCALITY": "BHARATGARH",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 5402,
      "City District Name": "RUPNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140115,
      "TXT_PINCODE_LOCALITY": "KIRATPUR SAHIB",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 5402,
      "City District Name": "RUPNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140116,
      "TXT_PINCODE_LOCALITY": "KOTLA POWER HOUSE",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 227,
      "City District Name": "PATIALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140117,
      "TXT_PINCODE_LOCALITY": "NURPUR BEDI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 5402,
      "City District Name": "RUPNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140118,
      "TXT_PINCODE_LOCALITY": "ANANDPUR SAHIB",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 5402,
      "City District Name": "RUPNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140119,
      "TXT_PINCODE_LOCALITY": "TAKHATGARH",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 5402,
      "City District Name": "RUPNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140123,
      "TXT_PINCODE_LOCALITY": "GANGUWAL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 5402,
      "City District Name": "RUPNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140124,
      "TXT_PINCODE_LOCALITY": "NANGAL DAM",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 5402,
      "City District Name": "RUPNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140125,
      "TXT_PINCODE_LOCALITY": "AJAULI B.O",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 240,
      "City District Name": "ROPAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140126,
      "TXT_PINCODE_LOCALITY": "NANGAL FACTORY AREA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 5402,
      "City District Name": "RUPNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140128,
      "TXT_PINCODE_LOCALITY": "SAWARA (275)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 2054,
      "City District Name": "ANANDPUR SAHIB",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140129,
      "TXT_PINCODE_LOCALITY": "PURKHALI (376)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442954,
      "City District Name": "RUP NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140132,
      "TXT_PINCODE_LOCALITY": "BHANOPLI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 5402,
      "City District Name": "RUPNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140133,
      "TXT_PINCODE_LOCALITY": "BHANUPALI S.O",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 240,
      "City District Name": "ROPAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140136,
      "TXT_PINCODE_LOCALITY": "BAUPUR (205).",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140201,
      "TXT_PINCODE_LOCALITY": "MUBARIKPUR(RUPAR)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 5402,
      "City District Name": "RUPNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140202,
      "TXT_PINCODE_LOCALITY": "RAMGARH",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 5402,
      "City District Name": "RUPNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140203,
      "TXT_PINCODE_LOCALITY": "BHANKARPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 227,
      "City District Name": "PATIALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140205,
      "TXT_PINCODE_LOCALITY": "IBRAHIMPUR (514)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 2897,
      "City District Name": "DASUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140301,
      "TXT_PINCODE_LOCALITY": "NAYA NANGAL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 5402,
      "City District Name": "RUPNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140306,
      "TXT_PINCODE_LOCALITY": "MANAULI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 5402,
      "City District Name": "RUPNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140307,
      "TXT_PINCODE_LOCALITY": "LANDRAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 5402,
      "City District Name": "RUPNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140308,
      "TXT_PINCODE_LOCALITY": "SOHANA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 228,
      "City District Name": "MOHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140309,
      "TXT_PINCODE_LOCALITY": "KHIZARPURA (343)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1956,
      "City District Name": "AJNALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140329,
      "TXT_PINCODE_LOCALITY": "SARI SHATPUR (91)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140401,
      "TXT_PINCODE_LOCALITY": "RAJPURA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 227,
      "City District Name": "PATIALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140402,
      "TXT_PINCODE_LOCALITY": "DHAKANSU KALAN (50)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 239,
      "City District Name": "RAJPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140405,
      "TXT_PINCODE_LOCALITY": "SANGATPURSODHIAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 227,
      "City District Name": "PATIALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140406,
      "TXT_PINCODE_LOCALITY": "SIRHIND",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 227,
      "City District Name": "PATIALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140407,
      "TXT_PINCODE_LOCALITY": "SIRHIND CITY",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 227,
      "City District Name": "PATIALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140408,
      "TXT_PINCODE_LOCALITY": "FATEHGARH SAHIB",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1301,
      "City District Name": "FATEHGARH SAHIB",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140412,
      "TXT_PINCODE_LOCALITY": "BASSI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1301,
      "City District Name": "FATEHGARH SAHIB",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140413,
      "TXT_PINCODE_LOCALITY": "GHARUAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 5402,
      "City District Name": "RUPNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140417,
      "TXT_PINCODE_LOCALITY": "SHAMBHU",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 227,
      "City District Name": "PATIALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140500,
      "TXT_PINCODE_LOCALITY": "KURLI (158)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 2928,
      "City District Name": "DERA BASSI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140501,
      "TXT_PINCODE_LOCALITY": "DERABASSI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 228,
      "City District Name": "MOHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140502,
      "TXT_PINCODE_LOCALITY": "HANDESRA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 227,
      "City District Name": "PATIALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140506,
      "TXT_PINCODE_LOCALITY": "DAPPAR AMMUNITION DEPOT",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 227,
      "City District Name": "PATIALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140507,
      "TXT_PINCODE_LOCALITY": "S.A.S.NAGAR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 5407,
      "City District Name": "S.A.S. NAGAR (MOHALI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140601,
      "TXT_PINCODE_LOCALITY": "BANUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 227,
      "City District Name": "PATIALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140602,
      "TXT_PINCODE_LOCALITY": "MANAKPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 227,
      "City District Name": "PATIALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140603,
      "TXT_PINCODE_LOCALITY": "DERA BASSI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 227,
      "City District Name": "PATIALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140604,
      "TXT_PINCODE_LOCALITY": "PATIALA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 227,
      "City District Name": "PATIALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140701,
      "TXT_PINCODE_LOCALITY": "KAULI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 227,
      "City District Name": "PATIALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140702,
      "TXT_PINCODE_LOCALITY": "GHANAUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 227,
      "City District Name": "PATIALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140801,
      "TXT_PINCODE_LOCALITY": "KHAMANO KALAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1301,
      "City District Name": "FATEHGARH SAHIB",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140802,
      "TXT_PINCODE_LOCALITY": "SANGHOL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1301,
      "City District Name": "FATEHGARH SAHIB",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140833,
      "TXT_PINCODE_LOCALITY": "BHUTAL KHURD (92).",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 4554,
      "City District Name": "MOONAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140835,
      "TXT_PINCODE_LOCALITY": "SHAFIPUR KHURD (133)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 5790,
      "City District Name": "SUNAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140901,
      "TXT_PINCODE_LOCALITY": "MULLANPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1301,
      "City District Name": "FATEHGARH SAHIB",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141000,
      "TXT_PINCODE_LOCALITY": "LUDHIANA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141001,
      "TXT_PINCODE_LOCALITY": "LUDHIANA KUTCHERY",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141002,
      "TXT_PINCODE_LOCALITY": "COLONY ROPAR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141003,
      "TXT_PINCODE_LOCALITY": "ABDULLAPUR BASTI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141004,
      "TXT_PINCODE_LOCALITY": "P A P LINES",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141005,
      "TXT_PINCODE_LOCALITY": "SALEM TABRI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141006,
      "TXT_PINCODE_LOCALITY": "GURU NANAK ENGINEERING COLLEGE",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141007,
      "TXT_PINCODE_LOCALITY": "LUDHIANA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141008,
      "TXT_PINCODE_LOCALITY": "SAMRALA RD LUDHIANA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141009,
      "TXT_PINCODE_LOCALITY": "MOTI NAGAR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141010,
      "TXT_PINCODE_LOCALITY": "FOCAL POINT (LUDHIANA)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141011,
      "TXT_PINCODE_LOCALITY": "JUNIANA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141012,
      "TXT_PINCODE_LOCALITY": "AGGAR NAGAR S.O",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141013,
      "TXT_PINCODE_LOCALITY": "PAKHOWAL ROAD",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141014,
      "TXT_PINCODE_LOCALITY": "DHANDARI KALAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141015,
      "TXT_PINCODE_LOCALITY": "MUNDIAN KALAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141016,
      "TXT_PINCODE_LOCALITY": "LOHORA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141017,
      "TXT_PINCODE_LOCALITY": "JUGIANA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141018,
      "TXT_PINCODE_LOCALITY": "RAJPURA URF HUSSAINPURA (99)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442953,
      "City District Name": "LUDHIANA (WEST)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141020,
      "TXT_PINCODE_LOCALITY": "LUDHIANA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141034,
      "TXT_PINCODE_LOCALITY": "MAJOLI (330)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 239,
      "City District Name": "RAJPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141101,
      "TXT_PINCODE_LOCALITY": "MULLANPUR MANDI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141102,
      "TXT_PINCODE_LOCALITY": "DAKHA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141103,
      "TXT_PINCODE_LOCALITY": "MOHIE",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141104,
      "TXT_PINCODE_LOCALITY": "K C G S SUDHAR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141105,
      "TXT_PINCODE_LOCALITY": "SARABA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141106,
      "TXT_PINCODE_LOCALITY": "HALIWARA A D",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141107,
      "TXT_PINCODE_LOCALITY": "HALWARA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141108,
      "TXT_PINCODE_LOCALITY": "PAKHOWAL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141109,
      "TXT_PINCODE_LOCALITY": "RAIKOT",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141110,
      "TXT_PINCODE_LOCALITY": "PURAIN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141111,
      "TXT_PINCODE_LOCALITY": "JAMALPUR AWANA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141112,
      "TXT_PINCODE_LOCALITY": "KOHARA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141113,
      "TXT_PINCODE_LOCALITY": "KATNI KALAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141114,
      "TXT_PINCODE_LOCALITY": "SAMRALA CITY",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141115,
      "TXT_PINCODE_LOCALITY": "MACHHIWARA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141116,
      "TXT_PINCODE_LOCALITY": "GILL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141117,
      "TXT_PINCODE_LOCALITY": "SIHAR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 227,
      "City District Name": "PATIALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141118,
      "TXT_PINCODE_LOCALITY": "FRHLON",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141119,
      "TXT_PINCODE_LOCALITY": "MALAUDH",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1301,
      "City District Name": "FATEHGARH SAHIB",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141120,
      "TXT_PINCODE_LOCALITY": "SANEHWAL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141121,
      "TXT_PINCODE_LOCALITY": "BAHLOLPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141122,
      "TXT_PINCODE_LOCALITY": "JASPAL BANGAR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141123,
      "TXT_PINCODE_LOCALITY": "RAMGARH",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141124,
      "TXT_PINCODE_LOCALITY": "GHULAL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141125,
      "TXT_PINCODE_LOCALITY": "NANOWAL KALAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141126,
      "TXT_PINCODE_LOCALITY": "SRI BHAINI SAHIB",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141127,
      "TXT_PINCODE_LOCALITY": "JHUGIAN BEGA (37)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442952,
      "City District Name": "LUDHIANA (EAST)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141140,
      "TXT_PINCODE_LOCALITY": "KALSAN (29)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1138,
      "City District Name": "RAIKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141147,
      "TXT_PINCODE_LOCALITY": "LUDHIANA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141201,
      "TXT_PINCODE_LOCALITY": "KILA RAIPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141202,
      "TXT_PINCODE_LOCALITY": "GUJARWAL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141203,
      "TXT_PINCODE_LOCALITY": "NARANGWAL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141204,
      "TXT_PINCODE_LOCALITY": "GHUNGRANA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141205,
      "TXT_PINCODE_LOCALITY": "LATALA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141206,
      "TXT_PINCODE_LOCALITY": "GHAWADDI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141208,
      "TXT_PINCODE_LOCALITY": "ASSI KALAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141209,
      "TXT_PINCODE_LOCALITY": "JHAMERI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442953,
      "City District Name": "LUDHIANA (WEST)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141301,
      "TXT_PINCODE_LOCALITY": "ROORKA KALAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141302,
      "TXT_PINCODE_LOCALITY": "SAMRAI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141303,
      "TXT_PINCODE_LOCALITY": "JANDIALA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141304,
      "TXT_PINCODE_LOCALITY": "BUNDALA (KAPURTHALA)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141305,
      "TXT_PINCODE_LOCALITY": "PARTAP NAGAR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141306,
      "TXT_PINCODE_LOCALITY": "BILGA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141307,
      "TXT_PINCODE_LOCALITY": "PASLA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141308,
      "TXT_PINCODE_LOCALITY": "TALWAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141309,
      "TXT_PINCODE_LOCALITY": "NURMAHAL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141310,
      "TXT_PINCODE_LOCALITY": "NAKODAR MANDI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1817,
      "City District Name": "KAPURTHALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141311,
      "TXT_PINCODE_LOCALITY": "DHESIAN KAHNA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141315,
      "TXT_PINCODE_LOCALITY": "MAHATPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141316,
      "TXT_PINCODE_LOCALITY": "SHANKER",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141317,
      "TXT_PINCODE_LOCALITY": "SARIH",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141318,
      "TXT_PINCODE_LOCALITY": "SIDHWAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141401,
      "TXT_PINCODE_LOCALITY": "GRAIN MARKET",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 233,
      "City District Name": "KHANNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141402,
      "TXT_PINCODE_LOCALITY": "A S COLLEGE KHANNA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141403,
      "TXT_PINCODE_LOCALITY": "JANDALI (206)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 5068,
      "City District Name": "PAYAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141410,
      "TXT_PINCODE_LOCALITY": "KOTPANECH (153).",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 233,
      "City District Name": "KHANNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141411,
      "TXT_PINCODE_LOCALITY": "BHARI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141412,
      "TXT_PINCODE_LOCALITY": "DAHERU",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141413,
      "TXT_PINCODE_LOCALITY": "DHAMOT",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141414,
      "TXT_PINCODE_LOCALITY": "ISRU",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141415,
      "TXT_PINCODE_LOCALITY": "JARG",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141416,
      "TXT_PINCODE_LOCALITY": "PAYAL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141417,
      "TXT_PINCODE_LOCALITY": "RAHAWAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141418,
      "TXT_PINCODE_LOCALITY": "RAMPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141419,
      "TXT_PINCODE_LOCALITY": "GHURANI KALAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141421,
      "TXT_PINCODE_LOCALITY": "DORAHA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141422,
      "TXT_PINCODE_LOCALITY": "HISSOWAL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141423,
      "TXT_PINCODE_LOCALITY": "KULAR (107)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 838,
      "City District Name": "JAGRAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141613,
      "TXT_PINCODE_LOCALITY": "FATTOWAL (119)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141614,
      "TXT_PINCODE_LOCALITY": "SARHALA (104)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 141801,
      "TXT_PINCODE_LOCALITY": "KHAMANO KALAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1301,
      "City District Name": "FATEHGARH SAHIB",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142001,
      "TXT_PINCODE_LOCALITY": "MOGA KUTCHERY",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 707,
      "City District Name": "MOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142002,
      "TXT_PINCODE_LOCALITY": "NATHUWALA JADID (46)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 707,
      "City District Name": "MOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142003,
      "TXT_PINCODE_LOCALITY": "TALWANDI BHANGERIAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 707,
      "City District Name": "MOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142011,
      "TXT_PINCODE_LOCALITY": "DALLA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 707,
      "City District Name": "MOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142021,
      "TXT_PINCODE_LOCALITY": "BADDOWAI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142022,
      "TXT_PINCODE_LOCALITY": "LALTON KALAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142023,
      "TXT_PINCODE_LOCALITY": "MAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142024,
      "TXT_PINCODE_LOCALITY": "SIDHWAN KHURD",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142025,
      "TXT_PINCODE_LOCALITY": "SOWADDI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142026,
      "TXT_PINCODE_LOCALITY": "JAGRAON TEHSIL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142027,
      "TXT_PINCODE_LOCALITY": "AYALI KALAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142028,
      "TXT_PINCODE_LOCALITY": "SHAH ABU BUKKAR (169)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 236,
      "City District Name": "ZIRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142029,
      "TXT_PINCODE_LOCALITY": "JODHAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142030,
      "TXT_PINCODE_LOCALITY": "KAMALPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142031,
      "TXT_PINCODE_LOCALITY": "HATHUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142032,
      "TXT_PINCODE_LOCALITY": "BASSIAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142033,
      "TXT_PINCODE_LOCALITY": "SIDHWAN BET",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142034,
      "TXT_PINCODE_LOCALITY": "MANOKE(LUDHIANA)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142035,
      "TXT_PINCODE_LOCALITY": "MALLAH",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142036,
      "TXT_PINCODE_LOCALITY": "GALIB KALAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142037,
      "TXT_PINCODE_LOCALITY": "BADHNI KALAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 707,
      "City District Name": "MOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142038,
      "TXT_PINCODE_LOCALITY": "BAGHAPURANA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 707,
      "City District Name": "MOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142039,
      "TXT_PINCODE_LOCALITY": "BILASPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 707,
      "City District Name": "MOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142040,
      "TXT_PINCODE_LOCALITY": "BUTTAR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 6309,
      "City District Name": "FEROZEPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142041,
      "TXT_PINCODE_LOCALITY": "BHINDER",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 6388,
      "City District Name": "FIROZPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142042,
      "TXT_PINCODE_LOCALITY": "DHARAMKOT",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 707,
      "City District Name": "MOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142043,
      "TXT_PINCODE_LOCALITY": "KOT ISEKHAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142044,
      "TXT_PINCODE_LOCALITY": "MAKHU",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 6388,
      "City District Name": "FIROZPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142045,
      "TXT_PINCODE_LOCALITY": "MANOKE (MOGA)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 707,
      "City District Name": "MOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142046,
      "TXT_PINCODE_LOCALITY": "PATTOHIRA SINGH",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 707,
      "City District Name": "MOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142047,
      "TXT_PINCODE_LOCALITY": "ZIRA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 6388,
      "City District Name": "FIROZPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142048,
      "TXT_PINCODE_LOCALITY": "GHALL KALAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 6388,
      "City District Name": "FIROZPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142049,
      "TXT_PINCODE_LOCALITY": "GURU TEGH BAHADURGARH",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 707,
      "City District Name": "MOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142050,
      "TXT_PINCODE_LOCALITY": "TALWANDI BHAI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 6309,
      "City District Name": "FEROZEPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142051,
      "TXT_PINCODE_LOCALITY": "FATEHGARH PANJTOOR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 6388,
      "City District Name": "FIROZPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142052,
      "TXT_PINCODE_LOCALITY": "FEROZSHAH",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 6388,
      "City District Name": "FIROZPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142053,
      "TXT_PINCODE_LOCALITY": "DHUDIKE",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 707,
      "City District Name": "MOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142054,
      "TXT_PINCODE_LOCALITY": "KOKRI KALAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 707,
      "City District Name": "MOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142055,
      "TXT_PINCODE_LOCALITY": "NIHAL SINGH WALA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 707,
      "City District Name": "MOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142056,
      "TXT_PINCODE_LOCALITY": "GHOLIA KHURD",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 707,
      "City District Name": "MOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142057,
      "TXT_PINCODE_LOCALITY": "SAMADH BHAL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 707,
      "City District Name": "MOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142058,
      "TXT_PINCODE_LOCALITY": "KISHANPURA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 6388,
      "City District Name": "FIROZPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142059,
      "TXT_PINCODE_LOCALITY": "GHALL KHURD",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 6388,
      "City District Name": "FIROZPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142060,
      "TXT_PINCODE_LOCALITY": "MUDKI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 6388,
      "City District Name": "FIROZPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142061,
      "TXT_PINCODE_LOCALITY": "MANAWAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 6388,
      "City District Name": "FIROZPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142069,
      "TXT_PINCODE_LOCALITY": "MARI MUSTAFA (132).",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 2229,
      "City District Name": "BAGHA PURANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142206,
      "TXT_PINCODE_LOCALITY": "JARKHAR (269)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442952,
      "City District Name": "LUDHIANA (EAST)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142222,
      "TXT_PINCODE_LOCALITY": "BEHDARYA (574)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1140,
      "City District Name": "MUKERIAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142431,
      "TXT_PINCODE_LOCALITY": "SHERPUR TAKHTUWALA (175)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 236,
      "City District Name": "ZIRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142502,
      "TXT_PINCODE_LOCALITY": "FEROZESHAH",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 6388,
      "City District Name": "FIROZPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142544,
      "TXT_PINCODE_LOCALITY": "PAKHO CHAK (148)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 903,
      "City District Name": "PATHANKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 142580,
      "TXT_PINCODE_LOCALITY": "THULEWAL (26)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 931,
      "City District Name": "BARNALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143001,
      "TXT_PINCODE_LOCALITY": "RANI KA BAGH",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143002,
      "TXT_PINCODE_LOCALITY": "KHALSA COLLEGE",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143003,
      "TXT_PINCODE_LOCALITY": "JAWALA FR MLS AMRITSAR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143004,
      "TXT_PINCODE_LOCALITY": "KHANNA NGR AMRITSAR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143005,
      "TXT_PINCODE_LOCALITY": "GURU NANAK DEV UNIVERSITY",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143006,
      "TXT_PINCODE_LOCALITY": "MOHAN NAGAR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143007,
      "TXT_PINCODE_LOCALITY": "SARBAR PURA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442943,
      "City District Name": "AMRITSAR -I",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143008,
      "TXT_PINCODE_LOCALITY": "GUMTALA B.O",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143009,
      "TXT_PINCODE_LOCALITY": "IBBAN KALAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143010,
      "TXT_PINCODE_LOCALITY": "PRATAPNAGAR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143016,
      "TXT_PINCODE_LOCALITY": "KOT HRIDE RAM (236)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442943,
      "City District Name": "AMRITSAR -I",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143021,
      "TXT_PINCODE_LOCALITY": "SULTANWIND GATE",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143022,
      "TXT_PINCODE_LOCALITY": "TARN TARAN ROAD",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143025,
      "TXT_PINCODE_LOCALITY": "SHAHPUR (119)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143026,
      "TXT_PINCODE_LOCALITY": "VIJAYANAGAR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143035,
      "TXT_PINCODE_LOCALITY": "NANGAL BUTTAR (185)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 844,
      "City District Name": "BATALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143036,
      "TXT_PINCODE_LOCALITY": "VIJAY NAGAR AMRITSAR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143060,
      "TXT_PINCODE_LOCALITY": "MATTIA (212)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1956,
      "City District Name": "AJNALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143101,
      "TXT_PINCODE_LOCALITY": "RAJA SANSI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143102,
      "TXT_PINCODE_LOCALITY": "AJNALA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143103,
      "TXT_PINCODE_LOCALITY": "CHAMIARI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143104,
      "TXT_PINCODE_LOCALITY": "RAYON SILK MILLS",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143105,
      "TXT_PINCODE_LOCALITY": "CHHEHARTA RAILWAY STATION",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143106,
      "TXT_PINCODE_LOCALITY": "NARAINGARH",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143107,
      "TXT_PINCODE_LOCALITY": "DISTILLERY KHASA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143108,
      "TXT_PINCODE_LOCALITY": "ATTARI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143109,
      "TXT_PINCODE_LOCALITY": "CHOGAWAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143110,
      "TXT_PINCODE_LOCALITY": "PREET NAGAR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143111,
      "TXT_PINCODE_LOCALITY": "KHILCHIAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143112,
      "TXT_PINCODE_LOCALITY": "RAYA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143113,
      "TXT_PINCODE_LOCALITY": "FATEHPUR RAJPUTAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143114,
      "TXT_PINCODE_LOCALITY": "SAIDOKE",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143115,
      "TXT_PINCODE_LOCALITY": "JANDIALA GURU",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143116,
      "TXT_PINCODE_LOCALITY": "VAIROWAL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143117,
      "TXT_PINCODE_LOCALITY": "KHADUR SAHIB",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143118,
      "TXT_PINCODE_LOCALITY": "VAIROWAL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 5853,
      "City District Name": "TARN TARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143119,
      "TXT_PINCODE_LOCALITY": "JANDIALA RS",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143144,
      "TXT_PINCODE_LOCALITY": "MEHTA CHOWK",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143149,
      "TXT_PINCODE_LOCALITY": "TMMOWAL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143175,
      "TXT_PINCODE_LOCALITY": "BASHAMBARPURA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442944,
      "City District Name": "AMRITSAR- II",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143201,
      "TXT_PINCODE_LOCALITY": "BEAS",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143202,
      "TXT_PINCODE_LOCALITY": "BABA BAKALA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143203,
      "TXT_PINCODE_LOCALITY": "BUTALA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143204,
      "TXT_PINCODE_LOCALITY": "DERA BABA JAIMAL SINGH",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143205,
      "TXT_PINCODE_LOCALITY": "SATHIALA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143301,
      "TXT_PINCODE_LOCALITY": "CHABHAL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143302,
      "TXT_PINCODE_LOCALITY": "SUR SINGH",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143303,
      "TXT_PINCODE_LOCALITY": "BHIKHIWIND",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143304,
      "TXT_PINCODE_LOCALITY": "KACHA PACCA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143305,
      "TXT_PINCODE_LOCALITY": "KHALRA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143306,
      "TXT_PINCODE_LOCALITY": "NAUSHEHRA (284) (PART)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442944,
      "City District Name": "AMRITSAR- II",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143309,
      "TXT_PINCODE_LOCALITY": "RANIWALA (307)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 5853,
      "City District Name": "TARN TARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143315,
      "TXT_PINCODE_LOCALITY": "SHAFIPUR (139)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442943,
      "City District Name": "AMRITSAR -I",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143401,
      "TXT_PINCODE_LOCALITY": "CHOWK DARBAR SAHIB",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143402,
      "TXT_PINCODE_LOCALITY": "DHALA (181).",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 5853,
      "City District Name": "TARN TARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143406,
      "TXT_PINCODE_LOCALITY": "KANG",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143407,
      "TXT_PINCODE_LOCALITY": "FATEHBAD",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143408,
      "TXT_PINCODE_LOCALITY": "CHOHLA SAHIB",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143409,
      "TXT_PINCODE_LOCALITY": "NAUSHERA PANNUAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143410,
      "TXT_PINCODE_LOCALITY": "SARHALI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143411,
      "TXT_PINCODE_LOCALITY": "DHOTIAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143412,
      "TXT_PINCODE_LOCALITY": "HARIKE",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143413,
      "TXT_PINCODE_LOCALITY": "VOLTOHA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143414,
      "TXT_PINCODE_LOCALITY": "SARAI AMANAT KHAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143415,
      "TXT_PINCODE_LOCALITY": "KAIRON",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143416,
      "TXT_PINCODE_LOCALITY": "PATTI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 6425,
      "City District Name": "PATTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143417,
      "TXT_PINCODE_LOCALITY": "SABRA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143418,
      "TXT_PINCODE_LOCALITY": "AMRITSAR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143419,
      "TXT_PINCODE_LOCALITY": "KHEM KARAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143420,
      "TXT_PINCODE_LOCALITY": "MASATGARH",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143421,
      "TXT_PINCODE_LOCALITY": "GHARYALA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143422,
      "TXT_PINCODE_LOCALITY": "GOINDWAL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143431,
      "TXT_PINCODE_LOCALITY": "LODHA VERAN (254)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143501,
      "TXT_PINCODE_LOCALITY": "VERKA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143502,
      "TXT_PINCODE_LOCALITY": "KATHU NANGAL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143503,
      "TXT_PINCODE_LOCALITY": "AMRITSAR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143504,
      "TXT_PINCODE_LOCALITY": "CHAWINDA DEVIWALA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143505,
      "TXT_PINCODE_LOCALITY": "R R BATALA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143506,
      "TXT_PINCODE_LOCALITY": "BATALA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143507,
      "TXT_PINCODE_LOCALITY": "ALIWAL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143508,
      "TXT_PINCODE_LOCALITY": "GURDASPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143510,
      "TXT_PINCODE_LOCALITY": "GURDASPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143511,
      "TXT_PINCODE_LOCALITY": "BHAGOWAL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143512,
      "TXT_PINCODE_LOCALITY": "KALANAUR(GURDESPUR)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143513,
      "TXT_PINCODE_LOCALITY": "KALA AFGHANA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143514,
      "TXT_PINCODE_LOCALITY": "GHOMAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143515,
      "TXT_PINCODE_LOCALITY": "DHARIWAL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143516,
      "TXT_PINCODE_LOCALITY": "QADIAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143517,
      "TXT_PINCODE_LOCALITY": "BHATTIAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143518,
      "TXT_PINCODE_LOCALITY": "MAJA SINGH",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143519,
      "TXT_PINCODE_LOCALITY": "ZAFFARWAL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143520,
      "TXT_PINCODE_LOCALITY": "SOHAL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143521,
      "TXT_PINCODE_LOCALITY": "GURDASPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143523,
      "TXT_PINCODE_LOCALITY": "KALER KALAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143524,
      "TXT_PINCODE_LOCALITY": "VILLA TEJA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143525,
      "TXT_PINCODE_LOCALITY": "MARARA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143526,
      "TXT_PINCODE_LOCALITY": "DORANGLA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143527,
      "TXT_PINCODE_LOCALITY": "HARCHOWAL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143528,
      "TXT_PINCODE_LOCALITY": "KAHNUWAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143529,
      "TXT_PINCODE_LOCALITY": "TIBER",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143530,
      "TXT_PINCODE_LOCALITY": "TIBRI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143531,
      "TXT_PINCODE_LOCALITY": "GANDHI NAGAR DINA NAGAR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143532,
      "TXT_PINCODE_LOCALITY": "BEHRAMPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143533,
      "TXT_PINCODE_LOCALITY": "GHAROTA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143534,
      "TXT_PINCODE_LOCALITY": "TARAGARH",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143535,
      "TXT_PINCODE_LOCALITY": "MARARA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143536,
      "TXT_PINCODE_LOCALITY": "GURDASPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143537,
      "TXT_PINCODE_LOCALITY": "TALIBPUR PANDOORI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143538,
      "TXT_PINCODE_LOCALITY": "GHANI KE BANGER",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143539,
      "TXT_PINCODE_LOCALITY": "KUNDA (273)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 903,
      "City District Name": "PATHANKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143562,
      "TXT_PINCODE_LOCALITY": "BOHAR WADALA (79)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143574,
      "TXT_PINCODE_LOCALITY": "CHONE (106)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 844,
      "City District Name": "BATALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143601,
      "TXT_PINCODE_LOCALITY": "BHANGALI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143602,
      "TXT_PINCODE_LOCALITY": "FATEHGARH CHURIAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143603,
      "TXT_PINCODE_LOCALITY": "RAM DASS",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143604,
      "TXT_PINCODE_LOCALITY": "DERA BABA NANAK",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143605,
      "TXT_PINCODE_LOCALITY": "DHIANPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143606,
      "TXT_PINCODE_LOCALITY": "CHETANPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 143607,
      "TXT_PINCODE_LOCALITY": "DHARAMKOT RANDHAWA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144001,
      "TXT_PINCODE_LOCALITY": "G T ROAD",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144002,
      "TXT_PINCODE_LOCALITY": "JALANDHAR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144003,
      "TXT_PINCODE_LOCALITY": "M T JALANDHAR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144004,
      "TXT_PINCODE_LOCALITY": "PREET NAGAR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144005,
      "TXT_PINCODE_LOCALITY": "MILITARY HOSPITAL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144006,
      "TXT_PINCODE_LOCALITY": "JALANDHAR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144007,
      "TXT_PINCODE_LOCALITY": "BASTI DANISHMANDAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144008,
      "TXT_PINCODE_LOCALITY": "GRAIN MARKET",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144009,
      "TXT_PINCODE_LOCALITY": "CHOGETTI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144010,
      "TXT_PINCODE_LOCALITY": "DHANOWALI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144011,
      "TXT_PINCODE_LOCALITY": "REGIONAL ENGG COLLEGE",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144012,
      "TXT_PINCODE_LOCALITY": "NURPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144013,
      "TXT_PINCODE_LOCALITY": "MAND",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144014,
      "TXT_PINCODE_LOCALITY": "TOWER TOWN COLONY S.O",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144020,
      "TXT_PINCODE_LOCALITY": "JAMSHER",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144021,
      "TXT_PINCODE_LOCALITY": "JALANDHAR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144022,
      "TXT_PINCODE_LOCALITY": "GARHA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144023,
      "TXT_PINCODE_LOCALITY": "DAKOHA S.O",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144024,
      "TXT_PINCODE_LOCALITY": "KUKAR PIND",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144025,
      "TXT_PINCODE_LOCALITY": "JANDU SINGHA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144026,
      "TXT_PINCODE_LOCALITY": "LAMBRA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144027,
      "TXT_PINCODE_LOCALITY": "O D SURANASSI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144028,
      "TXT_PINCODE_LOCALITY": "CHITTI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1817,
      "City District Name": "KAPURTHALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144029,
      "TXT_PINCODE_LOCALITY": "SHEIKHUPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1710,
      "City District Name": "NAWANSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144030,
      "TXT_PINCODE_LOCALITY": "KALRA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144031,
      "TXT_PINCODE_LOCALITY": "RURKA KALAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144032,
      "TXT_PINCODE_LOCALITY": "KANGNIWAL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144033,
      "TXT_PINCODE_LOCALITY": "JANDIALA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1817,
      "City District Name": "KAPURTHALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144034,
      "TXT_PINCODE_LOCALITY": "SUNNER KALAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144035,
      "TXT_PINCODE_LOCALITY": "PARTAPPURA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144036,
      "TXT_PINCODE_LOCALITY": "POWADHRA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144037,
      "TXT_PINCODE_LOCALITY": "NAGRA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1817,
      "City District Name": "KAPURTHALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144038,
      "TXT_PINCODE_LOCALITY": "AKUWAL (90)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1302,
      "City District Name": "PHILLAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144039,
      "TXT_PINCODE_LOCALITY": "NOORMAHAL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144040,
      "TXT_PINCODE_LOCALITY": "D.G.NAKODER S.O",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144041,
      "TXT_PINCODE_LOCALITY": "UDHOWAL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144042,
      "TXT_PINCODE_LOCALITY": "CHAK KALAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144043,
      "TXT_PINCODE_LOCALITY": "SARIH",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144044,
      "TXT_PINCODE_LOCALITY": "SIDHWAN R.S.",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144046,
      "TXT_PINCODE_LOCALITY": "SAHAIPUR (191)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144101,
      "TXT_PINCODE_LOCALITY": "PATARA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144102,
      "TXT_PINCODE_LOCALITY": "ADAMPUR DOABA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144103,
      "TXT_PINCODE_LOCALITY": "ADAMPUR A D",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144104,
      "TXT_PINCODE_LOCALITY": "DARAULI KALAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144105,
      "TXT_PINCODE_LOCALITY": "SHAM CHAURASI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144106,
      "TXT_PINCODE_LOCALITY": "KOTLA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144110,
      "TXT_PINCODE_LOCALITY": "KANG ARAIYAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1302,
      "City District Name": "PHILLAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144116,
      "TXT_PINCODE_LOCALITY": "APRA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144200,
      "TXT_PINCODE_LOCALITY": "DOBURJI (533)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 2897,
      "City District Name": "DASUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144201,
      "TXT_PINCODE_LOCALITY": "BHOG",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144202,
      "TXT_PINCODE_LOCALITY": "MIANI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144203,
      "TXT_PINCODE_LOCALITY": "TANDA (HOSHIARPUR)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144204,
      "TXT_PINCODE_LOCALITY": "URMAR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144205,
      "TXT_PINCODE_LOCALITY": "DASUYA H O",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144206,
      "TXT_PINCODE_LOCALITY": "DHOLBAHA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144207,
      "TXT_PINCODE_LOCALITY": "GARHDIWALA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144208,
      "TXT_PINCODE_LOCALITY": "HARIANA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144209,
      "TXT_PINCODE_LOCALITY": "JANAURI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144210,
      "TXT_PINCODE_LOCALITY": "BHUNGA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1817,
      "City District Name": "KAPURTHALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144211,
      "TXT_PINCODE_LOCALITY": "BUDHI PIND",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144212,
      "TXT_PINCODE_LOCALITY": "HOSHIARPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 463,
      "City District Name": "HOSHIAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144213,
      "TXT_PINCODE_LOCALITY": "BHANOWAL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144214,
      "TXT_PINCODE_LOCALITY": "TANDA RAM SAHAI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144216,
      "TXT_PINCODE_LOCALITY": "TALWARA TOWNSHIP",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144218,
      "TXT_PINCODE_LOCALITY": "MEHANGARWAL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144221,
      "TXT_PINCODE_LOCALITY": "HAJIPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144222,
      "TXT_PINCODE_LOCALITY": "DATARPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144223,
      "TXT_PINCODE_LOCALITY": "KAMANI DEVI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144224,
      "TXT_PINCODE_LOCALITY": "BHAMBOOTARA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144227,
      "TXT_PINCODE_LOCALITY": "PASSI KARORO (371)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1140,
      "City District Name": "MUKERIAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144236,
      "TXT_PINCODE_LOCALITY": "PAJJODEOTA (123)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144241,
      "TXT_PINCODE_LOCALITY": "SAHLON",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1710,
      "City District Name": "NAWANSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144261,
      "TXT_PINCODE_LOCALITY": "AHAR (128).",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144301,
      "TXT_PINCODE_LOCALITY": "ALAWALPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144302,
      "TXT_PINCODE_LOCALITY": "BEASPIND",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144303,
      "TXT_PINCODE_LOCALITY": "KALA BAKRA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144304,
      "TXT_PINCODE_LOCALITY": "LAKHINDER",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144305,
      "TXT_PINCODE_LOCALITY": "KHUDDA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144306,
      "TXT_PINCODE_LOCALITY": "BHANGALA *",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144307,
      "TXT_PINCODE_LOCALITY": "RAJU DAWA KHRI (78)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 2897,
      "City District Name": "DASUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144310,
      "TXT_PINCODE_LOCALITY": "NAKODAR S. O",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144311,
      "TXT_PINCODE_LOCALITY": "RORKEE",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144316,
      "TXT_PINCODE_LOCALITY": "CHACK MUGHLANI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1817,
      "City District Name": "KAPURTHALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144401,
      "TXT_PINCODE_LOCALITY": "PHAGWARAA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 234,
      "City District Name": "PHAGWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144402,
      "TXT_PINCODE_LOCALITY": "SATNAMPURA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1817,
      "City District Name": "KAPURTHALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144403,
      "TXT_PINCODE_LOCALITY": "RANIPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1817,
      "City District Name": "KAPURTHALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144404,
      "TXT_PINCODE_LOCALITY": "AJNOHA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144405,
      "TXT_PINCODE_LOCALITY": "NARUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144406,
      "TXT_PINCODE_LOCALITY": "BADDON",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144407,
      "TXT_PINCODE_LOCALITY": "DOMELI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1817,
      "City District Name": "KAPURTHALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144408,
      "TXT_PINCODE_LOCALITY": "PANCHHAT",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1817,
      "City District Name": "KAPURTHALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144409,
      "TXT_PINCODE_LOCALITY": "GOVT POLYTECHNIC",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144410,
      "TXT_PINCODE_LOCALITY": "PHILLAUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144411,
      "TXT_PINCODE_LOCALITY": "UNIVERSITY CAMPUS NANAK NAGRI S.O",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1817,
      "City District Name": "KAPURTHALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144415,
      "TXT_PINCODE_LOCALITY": "LASARA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144416,
      "TXT_PINCODE_LOCALITY": "APRA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144417,
      "TXT_PINCODE_LOCALITY": "AUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1710,
      "City District Name": "NAWANSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144418,
      "TXT_PINCODE_LOCALITY": "BARAPIND",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144419,
      "TXT_PINCODE_LOCALITY": "DAYALPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144421,
      "TXT_PINCODE_LOCALITY": "SAHLON",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1710,
      "City District Name": "NAWANSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144422,
      "TXT_PINCODE_LOCALITY": "UZAPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1710,
      "City District Name": "NAWANSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144423,
      "TXT_PINCODE_LOCALITY": "CHAKDANA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1710,
      "City District Name": "NAWANSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144424,
      "TXT_PINCODE_LOCALITY": "PALLI (566)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1140,
      "City District Name": "MUKERIAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144436,
      "TXT_PINCODE_LOCALITY": "MANDHALI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1710,
      "City District Name": "NAWANSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144440,
      "TXT_PINCODE_LOCALITY": "NANGAL (5)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442948,
      "City District Name": "GARHSHANKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144452,
      "TXT_PINCODE_LOCALITY": "JANDIALA (298)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442948,
      "City District Name": "GARHSHANKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144460,
      "TXT_PINCODE_LOCALITY": "MAND BATALA (91)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1817,
      "City District Name": "KAPURTHALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144501,
      "TXT_PINCODE_LOCALITY": "KULTHAM",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1710,
      "City District Name": "NAWANSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144502,
      "TXT_PINCODE_LOCALITY": "DOSANJH KALAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144503,
      "TXT_PINCODE_LOCALITY": "PHARLA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1710,
      "City District Name": "NAWANSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144504,
      "TXT_PINCODE_LOCALITY": "BEHRAM",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1710,
      "City District Name": "NAWANSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144505,
      "TXT_PINCODE_LOCALITY": "NAWANSHAHR ROAD BANGA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1710,
      "City District Name": "NAWANSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144506,
      "TXT_PINCODE_LOCALITY": "MAHIL GAILA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1710,
      "City District Name": "NAWANSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144507,
      "TXT_PINCODE_LOCALITY": "MUKANDPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1710,
      "City District Name": "NAWANSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144508,
      "TXT_PINCODE_LOCALITY": "NAUSHEHRA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1710,
      "City District Name": "NAWANSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144509,
      "TXT_PINCODE_LOCALITY": "KHANKHANA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1710,
      "City District Name": "NAWANSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144510,
      "TXT_PINCODE_LOCALITY": "LADHANA JHIKKA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1710,
      "City District Name": "NAWANSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144511,
      "TXT_PINCODE_LOCALITY": "DOSANJH KHURD",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1710,
      "City District Name": "NAWANSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144512,
      "TXT_PINCODE_LOCALITY": "KAHMA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1710,
      "City District Name": "NAWANSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144513,
      "TXT_PINCODE_LOCALITY": "MUSAPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1710,
      "City District Name": "NAWANSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144514,
      "TXT_PINCODE_LOCALITY": "NAWANSHAHR MANDI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1710,
      "City District Name": "NAWANSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144515,
      "TXT_PINCODE_LOCALITY": "JADLA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1710,
      "City District Name": "NAWANSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144516,
      "TXT_PINCODE_LOCALITY": "LANGROYA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1710,
      "City District Name": "NAWANSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144517,
      "TXT_PINCODE_LOCALITY": "RAHON",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1710,
      "City District Name": "NAWANSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144518,
      "TXT_PINCODE_LOCALITY": "GARCHA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1710,
      "City District Name": "NAWANSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144519,
      "TXT_PINCODE_LOCALITY": "KOT FATUHI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144520,
      "TXT_PINCODE_LOCALITY": "BINJON",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144521,
      "TXT_PINCODE_LOCALITY": "BALA CHAUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1710,
      "City District Name": "NAWANSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144522,
      "TXT_PINCODE_LOCALITY": "KATHGARH",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1710,
      "City District Name": "NAWANSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144523,
      "TXT_PINCODE_LOCALITY": "BINEWAL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144524,
      "TXT_PINCODE_LOCALITY": "SAROA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144525,
      "TXT_PINCODE_LOCALITY": "SAHILBA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1710,
      "City District Name": "NAWANSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144526,
      "TXT_PINCODE_LOCALITY": "NAWANSHAHR S. O",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1710,
      "City District Name": "NAWANSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144527,
      "TXT_PINCODE_LOCALITY": "GARHSHANKAR KUTCHERY",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144528,
      "TXT_PINCODE_LOCALITY": "RAMPUR BILRON",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144529,
      "TXT_PINCODE_LOCALITY": "SAILA KHURD",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144530,
      "TXT_PINCODE_LOCALITY": "JAIJON",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144531,
      "TXT_PINCODE_LOCALITY": "MORANWALI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144532,
      "TXT_PINCODE_LOCALITY": "SAMUNDRA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144533,
      "TXT_PINCODE_LOCALITY": "ASRON",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1710,
      "City District Name": "NAWANSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144601,
      "TXT_PINCODE_LOCALITY": "KHAJURALA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1817,
      "City District Name": "KAPURTHALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144602,
      "TXT_PINCODE_LOCALITY": "RAIL COACH FACTORY",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1817,
      "City District Name": "KAPURTHALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144603,
      "TXT_PINCODE_LOCALITY": "RASULPUR BRAHMANAN (200)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1817,
      "City District Name": "KAPURTHALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144606,
      "TXT_PINCODE_LOCALITY": "CHAUDHARIAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1817,
      "City District Name": "KAPURTHALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144611,
      "TXT_PINCODE_LOCALITY": "LALOWAL (505)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144619,
      "TXT_PINCODE_LOCALITY": "RAIPUR PIRBUXWALA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1817,
      "City District Name": "KAPURTHALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144620,
      "TXT_PINCODE_LOCALITY": "SHEIKHUPURA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1817,
      "City District Name": "KAPURTHALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144621,
      "TXT_PINCODE_LOCALITY": "BEGOWAL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1817,
      "City District Name": "KAPURTHALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144622,
      "TXT_PINCODE_LOCALITY": "BHOLATH",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1817,
      "City District Name": "KAPURTHALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144623,
      "TXT_PINCODE_LOCALITY": "KALA SINGHIAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1817,
      "City District Name": "KAPURTHALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144624,
      "TXT_PINCODE_LOCALITY": "NADALA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1817,
      "City District Name": "KAPURTHALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144625,
      "TXT_PINCODE_LOCALITY": "SIDHWAN DONA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1817,
      "City District Name": "KAPURTHALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144626,
      "TXT_PINCODE_LOCALITY": "SULTANPUR LODHI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1817,
      "City District Name": "KAPURTHALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144628,
      "TXT_PINCODE_LOCALITY": "THARHA JADID",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1817,
      "City District Name": "KAPURTHALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144629,
      "TXT_PINCODE_LOCALITY": "LOHIAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144630,
      "TXT_PINCODE_LOCALITY": "BOPA RAI KALAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1817,
      "City District Name": "KAPURTHALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144631,
      "TXT_PINCODE_LOCALITY": "NANGAL LUBANA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1817,
      "City District Name": "KAPURTHALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144632,
      "TXT_PINCODE_LOCALITY": "CHACHOKI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1817,
      "City District Name": "KAPURTHALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144633,
      "TXT_PINCODE_LOCALITY": "SARHALI (KAPURTHALA)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1817,
      "City District Name": "KAPURTHALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144636,
      "TXT_PINCODE_LOCALITY": "KANGRA (7)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 844,
      "City District Name": "BATALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144700,
      "TXT_PINCODE_LOCALITY": "THUHA (255)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 239,
      "City District Name": "RAJPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144701,
      "TXT_PINCODE_LOCALITY": "MALSIAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144702,
      "TXT_PINCODE_LOCALITY": "SHAHKOT",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144703,
      "TXT_PINCODE_LOCALITY": "NANGAL AMBIA S.O",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144720,
      "TXT_PINCODE_LOCALITY": "SURAAJPUR (182).",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1179,
      "City District Name": "NABHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144801,
      "TXT_PINCODE_LOCALITY": "KARTARPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144802,
      "TXT_PINCODE_LOCALITY": "JAGATJIT NGR KAPURTHALA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1817,
      "City District Name": "KAPURTHALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144803,
      "TXT_PINCODE_LOCALITY": "DIALPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1817,
      "City District Name": "KAPURTHALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144804,
      "TXT_PINCODE_LOCALITY": "DHILWAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1817,
      "City District Name": "KAPURTHALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144805,
      "TXT_PINCODE_LOCALITY": "BHANDALBAD",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1817,
      "City District Name": "KAPURTHALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144806,
      "TXT_PINCODE_LOCALITY": "PATTAR KALAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144811,
      "TXT_PINCODE_LOCALITY": "RAIPUR DABA (92)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1710,
      "City District Name": "NAWANSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 144819,
      "TXT_PINCODE_LOCALITY": "RAIPUR PIR BUX WALA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1817,
      "City District Name": "KAPURTHALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 145001,
      "TXT_PINCODE_LOCALITY": "DHANGU ROAD",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 145002,
      "TXT_PINCODE_LOCALITY": "MAMOON",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 145003,
      "TXT_PINCODE_LOCALITY": "SIALI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 145007,
      "TXT_PINCODE_LOCALITY": "WARYAM NANGAL (224)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442943,
      "City District Name": "AMRITSAR -I",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 145021,
      "TXT_PINCODE_LOCALITY": "DHAR KALAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 145022,
      "TXT_PINCODE_LOCALITY": "DUNERA S.O.",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 903,
      "City District Name": "PATHANKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 145023,
      "TXT_PINCODE_LOCALITY": "SUJANPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 145024,
      "TXT_PINCODE_LOCALITY": "MADHOPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 145025,
      "TXT_PINCODE_LOCALITY": "MALIKPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 145026,
      "TXT_PINCODE_LOCALITY": "NAROT JAIMAL SINGH",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 145027,
      "TXT_PINCODE_LOCALITY": "HARYAL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 145029,
      "TXT_PINCODE_LOCALITY": "SHAHPUR KANDI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 145030,
      "TXT_PINCODE_LOCALITY": "THARA UPARLA (399/2)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442947,
      "City District Name": "DHAR KALAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 145061,
      "TXT_PINCODE_LOCALITY": "SANGHER (379)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 903,
      "City District Name": "PATHANKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 145101,
      "TXT_PINCODE_LOCALITY": "MIRTHAL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 145506,
      "TXT_PINCODE_LOCALITY": "LADHANA UCHA (44)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1710,
      "City District Name": "NAWANSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 146001,
      "TXT_PINCODE_LOCALITY": "KAMALPUR HOSHIARPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 146002,
      "TXT_PINCODE_LOCALITY": "NAURANGABAD (304)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 146003,
      "TXT_PINCODE_LOCALITY": "REHSIWAL (209)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 146004,
      "TXT_PINCODE_LOCALITY": "LEHLI KALAN (307)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 146006,
      "TXT_PINCODE_LOCALITY": "MONA KHURD (279)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 146007,
      "TXT_PINCODE_LOCALITY": "CHABEWAL (308)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 146008,
      "TXT_PINCODE_LOCALITY": "GURU GOBIND SINGH NAGAR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 146013,
      "TXT_PINCODE_LOCALITY": "LANGRIAN (118)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 146014,
      "TXT_PINCODE_LOCALITY": "PATHRALIAN (131)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 146019,
      "TXT_PINCODE_LOCALITY": "SINAN (297)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 146021,
      "TXT_PINCODE_LOCALITY": "SADHU ASHRAM",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 146022,
      "TXT_PINCODE_LOCALITY": "PIPLANWALA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 146023,
      "TXT_PINCODE_LOCALITY": "BAJWARA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 146024,
      "TXT_PINCODE_LOCALITY": "INDUSTRIAL AREA CHAHAL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 146031,
      "TXT_PINCODE_LOCALITY": "SAHOTA (115)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 146066,
      "TXT_PINCODE_LOCALITY": "MEHITPUR (345)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 146101,
      "TXT_PINCODE_LOCALITY": "BARIAN KALAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 146102,
      "TXT_PINCODE_LOCALITY": "BASSI KALAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 146103,
      "TXT_PINCODE_LOCALITY": "BHAM",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 146104,
      "TXT_PINCODE_LOCALITY": "BIHALA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 146105,
      "TXT_PINCODE_LOCALITY": "MAHILPUR BUS STND",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 146106,
      "TXT_PINCODE_LOCALITY": "NANGAL KALAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 146107,
      "TXT_PINCODE_LOCALITY": "PALDI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 146108,
      "TXT_PINCODE_LOCALITY": "SARHALA KALAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 146109,
      "TXT_PINCODE_LOCALITY": "TOTO MAZRA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 146110,
      "TXT_PINCODE_LOCALITY": "KOT ABDUL KHALIQ",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 146111,
      "TXT_PINCODE_LOCALITY": "PURHIRAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 146112,
      "TXT_PINCODE_LOCALITY": "RAJPUR BHAYAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 146113,
      "TXT_PINCODE_LOCALITY": "BULHOWAL S.O",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 146114,
      "TXT_PINCODE_LOCALITY": "NANDA CHAUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 146115,
      "TXT_PINCODE_LOCALITY": "PANJJODEOTA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 146116,
      "TXT_PINCODE_LOCALITY": "KANDHALA JATTAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 146130,
      "TXT_PINCODE_LOCALITY": "SANDHAR (130).",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 146208,
      "TXT_PINCODE_LOCALITY": "HUSSAINPUR GURU (138)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 146402,
      "TXT_PINCODE_LOCALITY": "BHULEWAL RATHAN (29)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442948,
      "City District Name": "GARHSHANKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 146519,
      "TXT_PINCODE_LOCALITY": "BEHBALPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 147001,
      "TXT_PINCODE_LOCALITY": "SECRETARIAT PATIALA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 227,
      "City District Name": "PATIALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 147002,
      "TXT_PINCODE_LOCALITY": "UNIV P O PATIALA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 227,
      "City District Name": "PATIALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 147003,
      "TXT_PINCODE_LOCALITY": "BHUNERHERI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 227,
      "City District Name": "PATIALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 147004,
      "TXT_PINCODE_LOCALITY": "BHADSON ROAD",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 227,
      "City District Name": "PATIALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 147005,
      "TXT_PINCODE_LOCALITY": "MAJITHIA ENCLAVE",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 227,
      "City District Name": "PATIALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 147006,
      "TXT_PINCODE_LOCALITY": "DHANAURI (350)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1179,
      "City District Name": "NABHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 147007,
      "TXT_PINCODE_LOCALITY": "TARAURA KHURD (54)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 227,
      "City District Name": "PATIALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 147021,
      "TXT_PINCODE_LOCALITY": "BAHADURGARH",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 227,
      "City District Name": "PATIALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 147031,
      "TXT_PINCODE_LOCALITY": "MEHMADPUR (144)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 239,
      "City District Name": "RAJPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 147055,
      "TXT_PINCODE_LOCALITY": "MOGA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 707,
      "City District Name": "MOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 147100,
      "TXT_PINCODE_LOCALITY": "KHANG (164)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1177,
      "City District Name": "PATRAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 147101,
      "TXT_PINCODE_LOCALITY": "SAMANA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 227,
      "City District Name": "PATIALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 147102,
      "TXT_PINCODE_LOCALITY": "GHAGGA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 227,
      "City District Name": "PATIALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 147103,
      "TXT_PINCODE_LOCALITY": "SANAUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 227,
      "City District Name": "PATIALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 147104,
      "TXT_PINCODE_LOCALITY": "CHINARTHAL KALAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1301,
      "City District Name": "FATEHGARH SAHIB",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 147105,
      "TXT_PINCODE_LOCALITY": "PATRAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 227,
      "City District Name": "PATIALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 147107,
      "TXT_PINCODE_LOCALITY": "KHUR MANIAN (364)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442944,
      "City District Name": "AMRITSAR- II",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 147111,
      "TXT_PINCODE_LOCALITY": "DEVIGARH",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 227,
      "City District Name": "PATIALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 147201,
      "TXT_PINCODE_LOCALITY": "NABHA RLY STATION",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 227,
      "City District Name": "PATIALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 147202,
      "TXT_PINCODE_LOCALITY": "BHADSON",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 227,
      "City District Name": "PATIALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 147203,
      "TXT_PINCODE_LOCALITY": "AMLOH",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1301,
      "City District Name": "FATEHGARH SAHIB",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 147204,
      "TXT_PINCODE_LOCALITY": "BAGRIAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1301,
      "City District Name": "FATEHGARH SAHIB",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 147205,
      "TXT_PINCODE_LOCALITY": "MEEMSA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 147301,
      "TXT_PINCODE_LOCALITY": "MANDI GOVINDGARH",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 227,
      "City District Name": "PATIALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 147506,
      "TXT_PINCODE_LOCALITY": "DAPPAR AD S.O",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 228,
      "City District Name": "MOHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 147701,
      "TXT_PINCODE_LOCALITY": "PANJETA (156)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 227,
      "City District Name": "PATIALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148001,
      "TXT_PINCODE_LOCALITY": "NEW GRAIN MKT",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148002,
      "TXT_PINCODE_LOCALITY": "TUNG (48)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148004,
      "TXT_PINCODE_LOCALITY": "BAHADURPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148005,
      "TXT_PINCODE_LOCALITY": "UBHEWAL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148006,
      "TXT_PINCODE_LOCALITY": "BADRU KHAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148007,
      "TXT_PINCODE_LOCALITY": "MEHLAM",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148008,
      "TXT_PINCODE_LOCALITY": "MANGWAL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148009,
      "TXT_PINCODE_LOCALITY": "LOHAT BADI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148017,
      "TXT_PINCODE_LOCALITY": "MEERHERI (102)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1322,
      "City District Name": "DHURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148018,
      "TXT_PINCODE_LOCALITY": "BHATIAN KHURD",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148019,
      "TXT_PINCODE_LOCALITY": "JITWAL KALA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148020,
      "TXT_PINCODE_LOCALITY": "SANDHAUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148021,
      "TXT_PINCODE_LOCALITY": "AHMEDGARH",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148022,
      "TXT_PINCODE_LOCALITY": "AMARGARH",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148023,
      "TXT_PINCODE_LOCALITY": "LAL BAZAR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148024,
      "TXT_PINCODE_LOCALITY": "DHURI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148025,
      "TXT_PINCODE_LOCALITY": "SHERPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148026,
      "TXT_PINCODE_LOCALITY": "BHAWANIGARH",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148027,
      "TXT_PINCODE_LOCALITY": "PAKKI KHANAURI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148028,
      "TXT_PINCODE_LOCALITY": "SUNAM CITY",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148029,
      "TXT_PINCODE_LOCALITY": "CHEEMA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148030,
      "TXT_PINCODE_LOCALITY": "CHHAJILI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148031,
      "TXT_PINCODE_LOCALITY": "LEHRAGAGA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148032,
      "TXT_PINCODE_LOCALITY": "NAGRA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148033,
      "TXT_PINCODE_LOCALITY": "AKALGARH - 63",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148034,
      "TXT_PINCODE_LOCALITY": "LADDA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148035,
      "TXT_PINCODE_LOCALITY": "DIRBA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148036,
      "TXT_PINCODE_LOCALITY": "ROGLA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148038,
      "TXT_PINCODE_LOCALITY": "FATEHGARH PANJJARIAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148039,
      "TXT_PINCODE_LOCALITY": "HAMIDI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148040,
      "TXT_PINCODE_LOCALITY": "JANAL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148041,
      "TXT_PINCODE_LOCALITY": "BHUTHAL KALAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148042,
      "TXT_PINCODE_LOCALITY": "GUJRAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148043,
      "TXT_PINCODE_LOCALITY": "BALRAM",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148044,
      "TXT_PINCODE_LOCALITY": "KOTHLA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 227,
      "City District Name": "PATIALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148045,
      "TXT_PINCODE_LOCALITY": "KHANAL KALAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148046,
      "TXT_PINCODE_LOCALITY": "BANRA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148047,
      "TXT_PINCODE_LOCALITY": "CHHAHAR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148048,
      "TXT_PINCODE_LOCALITY": "GHARANCHON",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148049,
      "TXT_PINCODE_LOCALITY": "MAURAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148050,
      "TXT_PINCODE_LOCALITY": "BATHAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148051,
      "TXT_PINCODE_LOCALITY": "BANBHAURAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148100,
      "TXT_PINCODE_LOCALITY": "TALLEWAL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148101,
      "TXT_PINCODE_LOCALITY": "BARNALA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 931,
      "City District Name": "BARNALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148102,
      "TXT_PINCODE_LOCALITY": "BHADAUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148103,
      "TXT_PINCODE_LOCALITY": "SHAINA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148104,
      "TXT_PINCODE_LOCALITY": "MAHILKALAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148105,
      "TXT_PINCODE_LOCALITY": "DHANAULA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148106,
      "TXT_PINCODE_LOCALITY": "LONGOWAL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148107,
      "TXT_PINCODE_LOCALITY": "HADIAYA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148108,
      "TXT_PINCODE_LOCALITY": "TAPA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148109,
      "TXT_PINCODE_LOCALITY": "JOGA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 979,
      "City District Name": "MANSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148110,
      "TXT_PINCODE_LOCALITY": "PAKO KALAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148111,
      "TXT_PINCODE_LOCALITY": "SHERON",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148112,
      "TXT_PINCODE_LOCALITY": "KALEKI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148113,
      "TXT_PINCODE_LOCALITY": "SANGHERA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148114,
      "TXT_PINCODE_LOCALITY": "PHARWALI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148115,
      "TXT_PINCODE_LOCALITY": "BHUTNA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148116,
      "TXT_PINCODE_LOCALITY": "JAIOKE",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 148117,
      "TXT_PINCODE_LOCALITY": "KATTU",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 149024,
      "TXT_PINCODE_LOCALITY": "KUMBARWAL (47)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1322,
      "City District Name": "DHURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 150002,
      "TXT_PINCODE_LOCALITY": "WALHOOR (128)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 6388,
      "City District Name": "FIROZPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 150021,
      "TXT_PINCODE_LOCALITY": "SUDH SINGHWALA (11)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 6388,
      "City District Name": "FIROZPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 150141,
      "TXT_PINCODE_LOCALITY": "SAN BORJA",
      "NUM_STATE_CD": 634,
      "State Name": "LIMA",
      "NUM_CITYDISTRICT_CD": 441877,
      "City District Name": "LIMA",
      "NUM_COUNTRY_CD": 415
    },
    {
      "NUM_PINCODE": 150509,
      "TXT_PINCODE_LOCALITY": "DIKH (2)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 5319,
      "City District Name": "RAMPURA PHUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151001,
      "TXT_PINCODE_LOCALITY": "BHATINDA H O",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1471,
      "City District Name": "BATHINDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151002,
      "TXT_PINCODE_LOCALITY": "G NANAK THL PLT BHATINDA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1471,
      "City District Name": "BATHINDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151003,
      "TXT_PINCODE_LOCALITY": "BHATINDA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1471,
      "City District Name": "BATHINDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151004,
      "TXT_PINCODE_LOCALITY": "BHATINDA CANTT.",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1471,
      "City District Name": "BATHINDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151005,
      "TXT_PINCODE_LOCALITY": "BHATINDA CITY",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1471,
      "City District Name": "BATHINDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151023,
      "TXT_PINCODE_LOCALITY": "KOTKAPURA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1043,
      "City District Name": "FARIDKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151101,
      "TXT_PINCODE_LOCALITY": "BHUCHU",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1471,
      "City District Name": "BATHINDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151102,
      "TXT_PINCODE_LOCALITY": "NATHANA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1471,
      "City District Name": "BATHINDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151103,
      "TXT_PINCODE_LOCALITY": "S.B.S COLONY S. O",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 230,
      "City District Name": "BHATINDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151104,
      "TXT_PINCODE_LOCALITY": "PHUL",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1471,
      "City District Name": "BATHINDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151105,
      "TXT_PINCODE_LOCALITY": "MEHRAJ",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1471,
      "City District Name": "BATHINDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151106,
      "TXT_PINCODE_LOCALITY": "BHAI RUPA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1471,
      "City District Name": "BATHINDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151107,
      "TXT_PINCODE_LOCALITY": "BALANWALI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1471,
      "City District Name": "BATHINDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151108,
      "TXT_PINCODE_LOCALITY": "SALABATPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1471,
      "City District Name": "BATHINDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151109,
      "TXT_PINCODE_LOCALITY": "BHUCHU KHURD",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1471,
      "City District Name": "BATHINDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151110,
      "TXT_PINCODE_LOCALITY": "MANDI KALAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1471,
      "City District Name": "BATHINDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151111,
      "TXT_PINCODE_LOCALITY": "GHTP LEHRA MOHABBAT",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1471,
      "City District Name": "BATHINDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151114,
      "TXT_PINCODE_LOCALITY": "KUTIANWALI (37)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 6408,
      "City District Name": "MALOUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151201,
      "TXT_PINCODE_LOCALITY": "GONIANA MANDI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1471,
      "City District Name": "BATHINDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151202,
      "TXT_PINCODE_LOCALITY": "JAITU",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1043,
      "City District Name": "FARIDKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151203,
      "TXT_PINCODE_LOCALITY": "FARIDKOT H O",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1043,
      "City District Name": "FARIDKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151204,
      "TXT_PINCODE_LOCALITY": "RLWY BAZAR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1043,
      "City District Name": "FARIDKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151205,
      "TXT_PINCODE_LOCALITY": "BAJKHANA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1043,
      "City District Name": "FARIDKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151206,
      "TXT_PINCODE_LOCALITY": "BHAGTA BHAI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1471,
      "City District Name": "BATHINDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151207,
      "TXT_PINCODE_LOCALITY": "PANJGRAIN KALAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1043,
      "City District Name": "FARIDKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151208,
      "TXT_PINCODE_LOCALITY": "BARGARI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1043,
      "City District Name": "FARIDKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151209,
      "TXT_PINCODE_LOCALITY": "SANDHAWAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1043,
      "City District Name": "FARIDKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151210,
      "TXT_PINCODE_LOCALITY": "ALAMWALA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 832,
      "City District Name": "MUKTSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151211,
      "TXT_PINCODE_LOCALITY": "BRING KHERA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 832,
      "City District Name": "MUKTSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151212,
      "TXT_PINCODE_LOCALITY": "SADIQ S.O",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1043,
      "City District Name": "FARIDKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151213,
      "TXT_PINCODE_LOCALITY": "KHILCHI (11)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1043,
      "City District Name": "FARIDKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151301,
      "TXT_PINCODE_LOCALITY": "RAMAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1471,
      "City District Name": "BATHINDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151302,
      "TXT_PINCODE_LOCALITY": "TALWASDI SABOO",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1471,
      "City District Name": "BATHINDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151314,
      "TXT_PINCODE_LOCALITY": "JANSATH",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1471,
      "City District Name": "BATHINDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151401,
      "TXT_PINCODE_LOCALITY": "SANGAT",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1471,
      "City District Name": "BATHINDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151404,
      "TXT_PINCODE_LOCALITY": "CHAK RULDU SINGHWALA (113)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1471,
      "City District Name": "BATHINDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151501,
      "TXT_PINCODE_LOCALITY": "BARETA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 979,
      "City District Name": "MANSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151502,
      "TXT_PINCODE_LOCALITY": "BUDHLADA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 979,
      "City District Name": "MANSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151503,
      "TXT_PINCODE_LOCALITY": "BOHA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 979,
      "City District Name": "MANSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151504,
      "TXT_PINCODE_LOCALITY": "BHIKHI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 979,
      "City District Name": "MANSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151505,
      "TXT_PINCODE_LOCALITY": "NAI BASTI MANSA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 979,
      "City District Name": "MANSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151506,
      "TXT_PINCODE_LOCALITY": "SIRKI BAZAR BHATINDA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1471,
      "City District Name": "BATHINDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151507,
      "TXT_PINCODE_LOCALITY": "SARDULGARH",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 979,
      "City District Name": "MANSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151508,
      "TXT_PINCODE_LOCALITY": "BHAINI BAGGA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 979,
      "City District Name": "MANSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151509,
      "TXT_PINCODE_LOCALITY": "MAUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1471,
      "City District Name": "BATHINDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151510,
      "TXT_PINCODE_LOCALITY": "KUTTIWAL KALAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1471,
      "City District Name": "BATHINDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 151511,
      "TXT_PINCODE_LOCALITY": "KAHANGARH",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 979,
      "City District Name": "MANSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 152001,
      "TXT_PINCODE_LOCALITY": "FEROZEPUR SABZI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 6309,
      "City District Name": "FEROZEPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 152002,
      "TXT_PINCODE_LOCALITY": "MOGA ROAD FEROZPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 6388,
      "City District Name": "FIROZPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 152003,
      "TXT_PINCODE_LOCALITY": "FEROZEPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 6388,
      "City District Name": "FIROZPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 152004,
      "TXT_PINCODE_LOCALITY": "KILLIANWALI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 832,
      "City District Name": "MUKTSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 152005,
      "TXT_PINCODE_LOCALITY": "KANDWALA AMARKOT",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 6388,
      "City District Name": "FIROZPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 152020,
      "TXT_PINCODE_LOCALITY": "AHMAD DHANDI (322).",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1477,
      "City District Name": "JALALABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 152021,
      "TXT_PINCODE_LOCALITY": "MALLANWALA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 6388,
      "City District Name": "FIROZPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 152022,
      "TXT_PINCODE_LOCALITY": "GURU SHAI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 6388,
      "City District Name": "FIROZPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 152023,
      "TXT_PINCODE_LOCALITY": "MAMDOT",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 6388,
      "City District Name": "FIROZPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 152024,
      "TXT_PINCODE_LOCALITY": "JALALABAD S.O",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3142,
      "City District Name": "FAZILKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 152025,
      "TXT_PINCODE_LOCALITY": "BARIWALA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 832,
      "City District Name": "MUKTSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 152026,
      "TXT_PINCODE_LOCALITY": "MUKTSAR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 832,
      "City District Name": "MUKTSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 152028,
      "TXT_PINCODE_LOCALITY": "MANAWAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 6388,
      "City District Name": "FIROZPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 152031,
      "TXT_PINCODE_LOCALITY": "DOBA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1043,
      "City District Name": "FARIDKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 152032,
      "TXT_PINCODE_LOCALITY": "RUPANA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 832,
      "City District Name": "MUKTSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 152033,
      "TXT_PINCODE_LOCALITY": "MANDI AMINGANJ",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 6388,
      "City District Name": "FIROZPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 152052,
      "TXT_PINCODE_LOCALITY": "RATTA KHERA PUNJAB SINGHWALA (204)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 6388,
      "City District Name": "FIROZPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 152101,
      "TXT_PINCODE_LOCALITY": "GIDDERBAHA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 832,
      "City District Name": "MUKTSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 152102,
      "TXT_PINCODE_LOCALITY": "GIDDERBAHA C C",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 832,
      "City District Name": "MUKTSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 152103,
      "TXT_PINCODE_LOCALITY": "JHUMBA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1471,
      "City District Name": "BATHINDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 152107,
      "TXT_PINCODE_LOCALITY": "MALOUT",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 832,
      "City District Name": "MUKTSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 152112,
      "TXT_PINCODE_LOCALITY": "RAM NAGAR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 832,
      "City District Name": "MUKTSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 152113,
      "TXT_PINCODE_LOCALITY": "LAMBI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 832,
      "City District Name": "MUKTSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 152114,
      "TXT_PINCODE_LOCALITY": "TAPPAKHERA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 832,
      "City District Name": "MUKTSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 152115,
      "TXT_PINCODE_LOCALITY": "BHAI KA KHERA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1043,
      "City District Name": "FARIDKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 152116,
      "TXT_PINCODE_LOCALITY": "KRISHANA NAGRI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 6309,
      "City District Name": "FEROZEPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 152117,
      "TXT_PINCODE_LOCALITY": "BALLUNA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 6388,
      "City District Name": "FIROZPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 152118,
      "TXT_PINCODE_LOCALITY": "RAMPURA (71)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1324,
      "City District Name": "ABOHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 152120,
      "TXT_PINCODE_LOCALITY": "RAMSARA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 6388,
      "City District Name": "FIROZPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 152121,
      "TXT_PINCODE_LOCALITY": "KHUIKHERA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 6388,
      "City District Name": "FIROZPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 152122,
      "TXT_PINCODE_LOCALITY": "NIHAL KHERA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 6388,
      "City District Name": "FIROZPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 152123,
      "TXT_PINCODE_LOCALITY": "FAZILKA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 6388,
      "City District Name": "FIROZPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 152124,
      "TXT_PINCODE_LOCALITY": "SUBAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 832,
      "City District Name": "MUKTSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 152125,
      "TXT_PINCODE_LOCALITY": "DHARANGWALI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 6388,
      "City District Name": "FIROZPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 152126,
      "TXT_PINCODE_LOCALITY": "PATERWALA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 6388,
      "City District Name": "FIROZPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 152127,
      "TXT_PINCODE_LOCALITY": "CHURIWALA DHANNA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 6388,
      "City District Name": "FIROZPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 152128,
      "TXT_PINCODE_LOCALITY": "HARIPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 6388,
      "City District Name": "FIROZPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 152129,
      "TXT_PINCODE_LOCALITY": "PANJKOSI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 6388,
      "City District Name": "FIROZPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 152130,
      "TXT_PINCODE_LOCALITY": "THUMIANWALI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 6309,
      "City District Name": "FEROZEPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 152131,
      "TXT_PINCODE_LOCALITY": "MAUZAM",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 6388,
      "City District Name": "FIROZPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 152132,
      "TXT_PINCODE_LOCALITY": "MAUZGARH",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 6388,
      "City District Name": "FIROZPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 154115,
      "TXT_PINCODE_LOCALITY": "MEHMUD KHERA (50)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 6408,
      "City District Name": "MALOUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 157207,
      "TXT_PINCODE_LOCALITY": "NATHUWALA GARBI (73)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 2229,
      "City District Name": "BAGHA PURANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160001,
      "TXT_PINCODE_LOCALITY": "CHANDIGARH",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 424328,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160002,
      "TXT_PINCODE_LOCALITY": "IND AREA CHANDIGARH S.O",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160003,
      "TXT_PINCODE_LOCALITY": "AERODROME CHANDIGARH",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 424328,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160004,
      "TXT_PINCODE_LOCALITY": "AIRFORCE HIGHGROUND S.O",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 424328,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160005,
      "TXT_PINCODE_LOCALITY": "INDUSTRIAL AREA PHASE 2",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160006,
      "TXT_PINCODE_LOCALITY": "SECTOR 6",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160007,
      "TXT_PINCODE_LOCALITY": "SECTOR 7A",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 192,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160008,
      "TXT_PINCODE_LOCALITY": "CHANDIGARH",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 424328,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160009,
      "TXT_PINCODE_LOCALITY": "SECTOR 9D",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160010,
      "TXT_PINCODE_LOCALITY": "SECTOR 10",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160011,
      "TXT_PINCODE_LOCALITY": "SECTOR 10 (CHANDIGARH) S.O",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160012,
      "TXT_PINCODE_LOCALITY": "CHANDIGARH S. O",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160014,
      "TXT_PINCODE_LOCALITY": "SECTOR 35A",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160015,
      "TXT_PINCODE_LOCALITY": "SECTOR 15 CHANDIGARH S.O",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160016,
      "TXT_PINCODE_LOCALITY": "CHANDIGARH",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160017,
      "TXT_PINCODE_LOCALITY": "SECTOR 17 A",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160018,
      "TXT_PINCODE_LOCALITY": "CHANDIGARH",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160019,
      "TXT_PINCODE_LOCALITY": "MADHYA MARG",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160020,
      "TXT_PINCODE_LOCALITY": "SECTOR 20C",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160021,
      "TXT_PINCODE_LOCALITY": "SECTOR 21A",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160022,
      "TXT_PINCODE_LOCALITY": "SECTOR 22 CHANDIGARH",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160023,
      "TXT_PINCODE_LOCALITY": "SECTOR 23 CHANDIGARH",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160024,
      "TXT_PINCODE_LOCALITY": "SECTOR 24B",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160025,
      "TXT_PINCODE_LOCALITY": "MALOYA COLONY",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160026,
      "TXT_PINCODE_LOCALITY": "SECTOR 26C",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160027,
      "TXT_PINCODE_LOCALITY": "SECTOR 27 A",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160028,
      "TXT_PINCODE_LOCALITY": "SECTOR 28B",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160029,
      "TXT_PINCODE_LOCALITY": "SECTOR 29D",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160030,
      "TXT_PINCODE_LOCALITY": "SECTOR 30(CHANDIGARH) S.O",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160031,
      "TXT_PINCODE_LOCALITY": "SECTOR 31 CHANDIGARH",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 424328,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160032,
      "TXT_PINCODE_LOCALITY": "SECTOR 32B",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160033,
      "TXT_PINCODE_LOCALITY": "SECTOR 33C",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160034,
      "TXT_PINCODE_LOCALITY": "SECTOR 34C",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160035,
      "TXT_PINCODE_LOCALITY": "SECTOR 35 (CHANDIGARH)",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160036,
      "TXT_PINCODE_LOCALITY": "SECTOR 35B",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160037,
      "TXT_PINCODE_LOCALITY": "CHANDIGARH",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 424328,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160038,
      "TXT_PINCODE_LOCALITY": "SECTOR 38A",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160039,
      "TXT_PINCODE_LOCALITY": "SECTOR 40B",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160040,
      "TXT_PINCODE_LOCALITY": "KAMBALI VILLAGE",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442955,
      "City District Name": "SAS NAGAR (MOHALI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160041,
      "TXT_PINCODE_LOCALITY": "CHANGIGARH",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160042,
      "TXT_PINCODE_LOCALITY": "SECTOR 53",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160043,
      "TXT_PINCODE_LOCALITY": "SECTOR 43",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160044,
      "TXT_PINCODE_LOCALITY": "SECTOR 44A",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160045,
      "TXT_PINCODE_LOCALITY": "SECTOR 45A",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160046,
      "TXT_PINCODE_LOCALITY": "SECTOR 46B",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160047,
      "TXT_PINCODE_LOCALITY": "CHANDIGARH S.O",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160048,
      "TXT_PINCODE_LOCALITY": "SECTOR 48",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160049,
      "TXT_PINCODE_LOCALITY": "SECTOR 49B",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160050,
      "TXT_PINCODE_LOCALITY": "SECTOR 50",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160054,
      "TXT_PINCODE_LOCALITY": "SECTOR 65 PHASE 11",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442955,
      "City District Name": "SAS NAGAR (MOHALI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160055,
      "TXT_PINCODE_LOCALITY": "MOHALI SAS NAGAR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 5402,
      "City District Name": "RUPNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160056,
      "TXT_PINCODE_LOCALITY": "SECTOR 55",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160057,
      "TXT_PINCODE_LOCALITY": "MOHALI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 228,
      "City District Name": "MOHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160058,
      "TXT_PINCODE_LOCALITY": "SECTOR 58",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442955,
      "City District Name": "SAS NAGAR (MOHALI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160059,
      "TXT_PINCODE_LOCALITY": "S.A.S.NAGAR (MOHALI)",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 228,
      "City District Name": "MOHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160060,
      "TXT_PINCODE_LOCALITY": "SECTOR 58",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442955,
      "City District Name": "SAS NAGAR (MOHALI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160061,
      "TXT_PINCODE_LOCALITY": "SECTOR 61 CHANDIGARH",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 424328,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160062,
      "TXT_PINCODE_LOCALITY": "MOHALI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 228,
      "City District Name": "MOHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160063,
      "TXT_PINCODE_LOCALITY": "SECTOR 63 PHASE 9",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442955,
      "City District Name": "SAS NAGAR (MOHALI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160064,
      "TXT_PINCODE_LOCALITY": "SECTOR 64 PHASE 10",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442955,
      "City District Name": "SAS NAGAR (MOHALI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160065,
      "TXT_PINCODE_LOCALITY": "HOUSE NO. 2113, JAL VAYU VIHAR, SECTOR 67",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 228,
      "City District Name": "MOHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160066,
      "TXT_PINCODE_LOCALITY": "CHACHU MAJRA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442955,
      "City District Name": "SAS NAGAR (MOHALI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160067,
      "TXT_PINCODE_LOCALITY": "SECTOR 67",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442955,
      "City District Name": "SAS NAGAR (MOHALI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160068,
      "TXT_PINCODE_LOCALITY": "KUMBRA VILLAGE",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442955,
      "City District Name": "SAS NAGAR (MOHALI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160069,
      "TXT_PINCODE_LOCALITY": "SECTOR 69",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442955,
      "City District Name": "SAS NAGAR (MOHALI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160070,
      "TXT_PINCODE_LOCALITY": "MOHALI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 228,
      "City District Name": "MOHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160071,
      "TXT_PINCODE_LOCALITY": "INDUSTRIAL FOCAL POINT .SAS NAGAR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 228,
      "City District Name": "MOHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160101,
      "TXT_PINCODE_LOCALITY": "KALKA ROAD",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160102,
      "TXT_PINCODE_LOCALITY": "MOULI COMPLEX",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 424328,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160103,
      "TXT_PINCODE_LOCALITY": "NAYA GAON",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 424328,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160104,
      "TXT_PINCODE_LOCALITY": "DHAKAOLI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 228,
      "City District Name": "MOHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160106,
      "TXT_PINCODE_LOCALITY": "CHANDIGARH",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160122,
      "TXT_PINCODE_LOCALITY": "SECTOR 35",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 192,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 164101,
      "TXT_PINCODE_LOCALITY": "BHABAT",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 442955,
      "City District Name": "SAS NAGAR (MOHALI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 170103,
      "TXT_PINCODE_LOCALITY": "MOLI CHAK (246)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4932,
      "City District Name": "PALAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 170134,
      "TXT_PINCODE_LOCALITY": "RAKRIAL (48/6)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 170207,
      "TXT_PINCODE_LOCALITY": "SHEORA (253)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1845,
      "City District Name": "SOLAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 170302,
      "TXT_PINCODE_LOCALITY": "MANJHU (280)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6511,
      "City District Name": "KARSOG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 170303,
      "TXT_PINCODE_LOCALITY": "D.P.F.ODA (294)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6511,
      "City District Name": "KARSOG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 170304,
      "TXT_PINCODE_LOCALITY": "BHANOL (225)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6511,
      "City District Name": "KARSOG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 170400,
      "TXT_PINCODE_LOCALITY": "TUNDWIN (514)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 3250,
      "City District Name": "GHUMARWIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 170417,
      "TXT_PINCODE_LOCALITY": "BALH FATU (45)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442683,
      "City District Name": "JHANDUTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 170428,
      "TXT_PINCODE_LOCALITY": "KOT (339)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442670,
      "City District Name": "BHARARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 170539,
      "TXT_PINCODE_LOCALITY": "DARI (175)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442673,
      "City District Name": "CHACHYOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171001,
      "TXT_PINCODE_LOCALITY": "ELYSIUM",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171002,
      "TXT_PINCODE_LOCALITY": "SIMLA EAST",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171003,
      "TXT_PINCODE_LOCALITY": "COMMAND HQ",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171004,
      "TXT_PINCODE_LOCALITY": "NABHA ESTATE",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171005,
      "TXT_PINCODE_LOCALITY": "BOTLEAUGANJ",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171006,
      "TXT_PINCODE_LOCALITY": "SANJAULI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171007,
      "TXT_PINCODE_LOCALITY": "MASHOBRA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171008,
      "TXT_PINCODE_LOCALITY": "JUTOGH",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171009,
      "TXT_PINCODE_LOCALITY": "KASUMPTI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171010,
      "TXT_PINCODE_LOCALITY": "TARA DEVI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171011,
      "TXT_PINCODE_LOCALITY": "TOTU",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171012,
      "TXT_PINCODE_LOCALITY": "DHALLI SIMLA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171013,
      "TXT_PINCODE_LOCALITY": "JUBBAR HATTI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171014,
      "TXT_PINCODE_LOCALITY": "SYRI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171015,
      "TXT_PINCODE_LOCALITY": "SHIMLA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171016,
      "TXT_PINCODE_LOCALITY": "MUNDA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171017,
      "TXT_PINCODE_LOCALITY": "GUMA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171018,
      "TXT_PINCODE_LOCALITY": "BHARARA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171019,
      "TXT_PINCODE_LOCALITY": "KUFRI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171020,
      "TXT_PINCODE_LOCALITY": "MADABAG",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171024,
      "TXT_PINCODE_LOCALITY": "LANA MARAG (158).",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442695,
      "City District Name": "PACHHAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171028,
      "TXT_PINCODE_LOCALITY": "LAROT (99)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442676,
      "City District Name": "CHIRGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171088,
      "TXT_PINCODE_LOCALITY": "BAGAIN (126)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 5584,
      "City District Name": "SEONI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171101,
      "TXT_PINCODE_LOCALITY": "GHANA KI HATTI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171102,
      "TXT_PINCODE_LOCALITY": "DARIA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171103,
      "TXT_PINCODE_LOCALITY": "DHAMI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171108,
      "TXT_PINCODE_LOCALITY": "BASANTPUR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171109,
      "TXT_PINCODE_LOCALITY": "JAISHI (46)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 5584,
      "City District Name": "SEONI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171110,
      "TXT_PINCODE_LOCALITY": "POAR (63/1).",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 5972,
      "City District Name": "TIRA SUJANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171112,
      "TXT_PINCODE_LOCALITY": "JANGAL PASHAN",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 2723,
      "City District Name": "CHAUPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171113,
      "TXT_PINCODE_LOCALITY": "JANGAL HIRI KI BESHAK",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442701,
      "City District Name": "SHIMLA ( RURAL )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171120,
      "TXT_PINCODE_LOCALITY": "KUTHARI (5)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442705,
      "City District Name": "TIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171200,
      "TXT_PINCODE_LOCALITY": "D.P.F. SHEKHAL",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 5388,
      "City District Name": "ROHRU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171201,
      "TXT_PINCODE_LOCALITY": "THEOG",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171202,
      "TXT_PINCODE_LOCALITY": "KOT KHAI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171203,
      "TXT_PINCODE_LOCALITY": "TIKKAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171204,
      "TXT_PINCODE_LOCALITY": "KIARI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171205,
      "TXT_PINCODE_LOCALITY": "JUBBAL",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171206,
      "TXT_PINCODE_LOCALITY": "HATKOTI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171207,
      "TXT_PINCODE_LOCALITY": "ROHRU",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171208,
      "TXT_PINCODE_LOCALITY": "CHIRGAON",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171209,
      "TXT_PINCODE_LOCALITY": "FAGU",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171210,
      "TXT_PINCODE_LOCALITY": "NERWA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171211,
      "TXT_PINCODE_LOCALITY": "CHOPAL",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171212,
      "TXT_PINCODE_LOCALITY": "MATIANA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171213,
      "TXT_PINCODE_LOCALITY": "NARKANDA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171214,
      "TXT_PINCODE_LOCALITY": "DEHABALSON",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171215,
      "TXT_PINCODE_LOCALITY": "ROHNI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171216,
      "TXT_PINCODE_LOCALITY": "SHILAROO",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171217,
      "TXT_PINCODE_LOCALITY": "SANDHU",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171218,
      "TXT_PINCODE_LOCALITY": "DEORI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171219,
      "TXT_PINCODE_LOCALITY": "CHEOG",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171220,
      "TXT_PINCODE_LOCALITY": "CHHAILA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171221,
      "TXT_PINCODE_LOCALITY": "PANDAR (19)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442679,
      "City District Name": "DODRA KWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171222,
      "TXT_PINCODE_LOCALITY": "SANHANA (136)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 5908,
      "City District Name": "THEOG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171223,
      "TXT_PINCODE_LOCALITY": "ARHAL",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171224,
      "TXT_PINCODE_LOCALITY": "ROHRU S. O",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171225,
      "TXT_PINCODE_LOCALITY": "REOT (25)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4091,
      "City District Name": "KOTKHAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171226,
      "TXT_PINCODE_LOCALITY": "BALAG",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171228,
      "TXT_PINCODE_LOCALITY": "UP MUHAL NAGPUR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 5908,
      "City District Name": "THEOG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171236,
      "TXT_PINCODE_LOCALITY": "JANGAL AHAR (156)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442688,
      "City District Name": "KUMHARSAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171270,
      "TXT_PINCODE_LOCALITY": "SAI (189)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 5908,
      "City District Name": "THEOG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171301,
      "TXT_PINCODE_LOCALITY": "TAPROG (152)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 5584,
      "City District Name": "SEONI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171302,
      "TXT_PINCODE_LOCALITY": "TATTA PANI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171303,
      "TXT_PINCODE_LOCALITY": "CHURAG",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171304,
      "TXT_PINCODE_LOCALITY": "KARSOG",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171305,
      "TXT_PINCODE_LOCALITY": "CHAILA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171306,
      "TXT_PINCODE_LOCALITY": "PANGNA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171307,
      "TXT_PINCODE_LOCALITY": "PURANA (516)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6511,
      "City District Name": "KARSOG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171309,
      "TXT_PINCODE_LOCALITY": "SAREHACH (68)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6511,
      "City District Name": "KARSOG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171402,
      "TXT_PINCODE_LOCALITY": "KUSRI (57)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442698,
      "City District Name": "RAMSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171417,
      "TXT_PINCODE_LOCALITY": "JOKHAR (131)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442675,
      "City District Name": "CHETA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171505,
      "TXT_PINCODE_LOCALITY": "D.P.F. SALANA DHAGOH",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6511,
      "City District Name": "KARSOG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171511,
      "TXT_PINCODE_LOCALITY": "SHAGARCHA (1/4)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442694,
      "City District Name": "NICHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171532,
      "TXT_PINCODE_LOCALITY": "NIHARA (411)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6510,
      "City District Name": "KANDAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171603,
      "TXT_PINCODE_LOCALITY": "AGLA CHERA (306)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6511,
      "City District Name": "KARSOG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171610,
      "TXT_PINCODE_LOCALITY": "KACHH (63/12)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 5972,
      "City District Name": "TIRA SUJANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171707,
      "TXT_PINCODE_LOCALITY": "NERI (281)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442701,
      "City District Name": "SHIMLA ( RURAL )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 171717,
      "TXT_PINCODE_LOCALITY": "D.P.F TOPLA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442675,
      "City District Name": "CHETA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172001,
      "TXT_PINCODE_LOCALITY": "RAMPUR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172002,
      "TXT_PINCODE_LOCALITY": "GAURA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172013,
      "TXT_PINCODE_LOCALITY": "NIGANI (31/5).",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442694,
      "City District Name": "NICHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172014,
      "TXT_PINCODE_LOCALITY": "WANGTU",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442694,
      "City District Name": "NICHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172021,
      "TXT_PINCODE_LOCALITY": "NANKHARI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172022,
      "TXT_PINCODE_LOCALITY": "NOGLI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172023,
      "TXT_PINCODE_LOCALITY": "NIRMAND",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172024,
      "TXT_PINCODE_LOCALITY": "KINGAL",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172025,
      "TXT_PINCODE_LOCALITY": "DALASH",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172026,
      "TXT_PINCODE_LOCALITY": "ANI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172027,
      "TXT_PINCODE_LOCALITY": "BARA GAON",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172028,
      "TXT_PINCODE_LOCALITY": "TIKKRI (1)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442693,
      "City District Name": "NANKHARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172029,
      "TXT_PINCODE_LOCALITY": "KUMAR SAIN",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172030,
      "TXT_PINCODE_LOCALITY": "THANEDAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6352,
      "City District Name": "KINNAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172031,
      "TXT_PINCODE_LOCALITY": "KUMHARSAIN",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172032,
      "TXT_PINCODE_LOCALITY": "CHOWAI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172033,
      "TXT_PINCODE_LOCALITY": "NITHAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172034,
      "TXT_PINCODE_LOCALITY": "BONDA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172035,
      "TXT_PINCODE_LOCALITY": "BAHLI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172036,
      "TXT_PINCODE_LOCALITY": "LUHRI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172037,
      "TXT_PINCODE_LOCALITY": "MANAN (125)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442688,
      "City District Name": "KUMHARSAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172101,
      "TXT_PINCODE_LOCALITY": "JEORI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172102,
      "TXT_PINCODE_LOCALITY": "SARAHAN BUSHEHAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172103,
      "TXT_PINCODE_LOCALITY": "NICHAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6352,
      "City District Name": "KINNAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172104,
      "TXT_PINCODE_LOCALITY": "TAPRI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6352,
      "City District Name": "KINNAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172105,
      "TXT_PINCODE_LOCALITY": "KARCHHAM",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6352,
      "City District Name": "KINNAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172106,
      "TXT_PINCODE_LOCALITY": "SANGLA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6352,
      "City District Name": "KINNAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172107,
      "TXT_PINCODE_LOCALITY": "REKONG PEO H O",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6352,
      "City District Name": "KINNAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172108,
      "TXT_PINCODE_LOCALITY": "KALPA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6352,
      "City District Name": "KINNAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172109,
      "TXT_PINCODE_LOCALITY": "MOORANG",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6352,
      "City District Name": "KINNAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172110,
      "TXT_PINCODE_LOCALITY": "SPILLO",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6352,
      "City District Name": "KINNAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172111,
      "TXT_PINCODE_LOCALITY": "POOH",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6352,
      "City District Name": "KINNAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172112,
      "TXT_PINCODE_LOCALITY": "LIYO",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6352,
      "City District Name": "KINNAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172113,
      "TXT_PINCODE_LOCALITY": "TABO",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6352,
      "City District Name": "KINNAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172114,
      "TXT_PINCODE_LOCALITY": "KAZA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6352,
      "City District Name": "KINNAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172115,
      "TXT_PINCODE_LOCALITY": "BHAWANGARH",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6352,
      "City District Name": "KINNAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172116,
      "TXT_PINCODE_LOCALITY": "RIBBA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6352,
      "City District Name": "KINNAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172117,
      "TXT_PINCODE_LOCALITY": "SAGNAM",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 424447,
      "City District Name": "LAHUL & SPITI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172118,
      "TXT_PINCODE_LOCALITY": "KATGAON",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6352,
      "City District Name": "KINNAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172120,
      "TXT_PINCODE_LOCALITY": "NIMNAM DHAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442676,
      "City District Name": "CHIRGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172167,
      "TXT_PINCODE_LOCALITY": "CHHAWANI (339).",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 2991,
      "City District Name": "DHARMSALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172201,
      "TXT_PINCODE_LOCALITY": "RAMPUR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172204,
      "TXT_PINCODE_LOCALITY": "UP MUHAL KUFRI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4091,
      "City District Name": "KOTKHAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172234,
      "TXT_PINCODE_LOCALITY": "BHODAN (87)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6510,
      "City District Name": "KANDAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 172617,
      "TXT_PINCODE_LOCALITY": "DHIAL (337)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 2991,
      "City District Name": "DHARMSALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173001,
      "TXT_PINCODE_LOCALITY": "NAHAN",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6337,
      "City District Name": "SIRMOUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173002,
      "TXT_PINCODE_LOCALITY": "NAHAN",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6337,
      "City District Name": "SIRMOUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173003,
      "TXT_PINCODE_LOCALITY": "BAGTHAN",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6337,
      "City District Name": "SIRMOUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173004,
      "TXT_PINCODE_LOCALITY": "TRILOKPUR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6337,
      "City District Name": "SIRMOUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173006,
      "TXT_PINCODE_LOCALITY": "TAKAHAN (222)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442695,
      "City District Name": "PACHHAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173012,
      "TXT_PINCODE_LOCALITY": "BAHRAL (131)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4999,
      "City District Name": "PAONTA SAHIB",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173020,
      "TXT_PINCODE_LOCALITY": "GIRI NAGAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6337,
      "City District Name": "SIRMOUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173021,
      "TXT_PINCODE_LOCALITY": "MAJRA(NAHAN)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6337,
      "City District Name": "SIRMOUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173022,
      "TXT_PINCODE_LOCALITY": "DADAHU",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6337,
      "City District Name": "SIRMOUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173023,
      "TXT_PINCODE_LOCALITY": "SANGARH",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6337,
      "City District Name": "SIRMOUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173024,
      "TXT_PINCODE_LOCALITY": "SARAHAN",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6337,
      "City District Name": "SIRMOUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173025,
      "TXT_PINCODE_LOCALITY": "POST SAMIRPUR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173026,
      "TXT_PINCODE_LOCALITY": "BADRI NGR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6337,
      "City District Name": "SIRMOUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173027,
      "TXT_PINCODE_LOCALITY": "SHILLAI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6337,
      "City District Name": "SIRMOUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173028,
      "TXT_PINCODE_LOCALITY": "RAJBAN",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6337,
      "City District Name": "SIRMOUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173029,
      "TXT_PINCODE_LOCALITY": "SATAUN",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6337,
      "City District Name": "SIRMOUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173030,
      "TXT_PINCODE_LOCALITY": "KALA AMB",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6337,
      "City District Name": "SIRMOUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173031,
      "TXT_PINCODE_LOCALITY": "BHARAPUR (157)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4999,
      "City District Name": "PAONTA SAHIB",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173032,
      "TXT_PINCODE_LOCALITY": "SANGRAH.",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6337,
      "City District Name": "SIRMOUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173036,
      "TXT_PINCODE_LOCALITY": "SHAOGA (245)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442685,
      "City District Name": "KAMRAU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173042,
      "TXT_PINCODE_LOCALITY": "DANDA (47)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4999,
      "City District Name": "PAONTA SAHIB",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173098,
      "TXT_PINCODE_LOCALITY": "SADDA (444).",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442682,
      "City District Name": "JAISINGHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173101,
      "TXT_PINCODE_LOCALITY": "RAJGARH",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6337,
      "City District Name": "SIRMOUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173102,
      "TXT_PINCODE_LOCALITY": "SANAURA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6337,
      "City District Name": "SIRMOUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173103,
      "TXT_PINCODE_LOCALITY": "SHAYACHARBROAN",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6337,
      "City District Name": "SIRMOUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173104,
      "TXT_PINCODE_LOCALITY": "NOHRA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6337,
      "City District Name": "SIRMOUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173127,
      "TXT_PINCODE_LOCALITY": "SAKORI (470)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6510,
      "City District Name": "KANDAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173201,
      "TXT_PINCODE_LOCALITY": "GARKHAL",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1845,
      "City District Name": "SOLAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173202,
      "TXT_PINCODE_LOCALITY": "SANAWAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1845,
      "City District Name": "SOLAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173203,
      "TXT_PINCODE_LOCALITY": "JAGJIT NAGAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1845,
      "City District Name": "SOLAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173204,
      "TXT_PINCODE_LOCALITY": "KASAULI RESEARCH",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1845,
      "City District Name": "SOLAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173205,
      "TXT_PINCODE_LOCALITY": "SAINI MAJRA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1845,
      "City District Name": "SOLAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173206,
      "TXT_PINCODE_LOCALITY": "SUBATHU",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1845,
      "City District Name": "SOLAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173207,
      "TXT_PINCODE_LOCALITY": "KUNIHAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1845,
      "City District Name": "SOLAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173208,
      "TXT_PINCODE_LOCALITY": "ARKI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1845,
      "City District Name": "SOLAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173209,
      "TXT_PINCODE_LOCALITY": "DHARAMPUR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1845,
      "City District Name": "SOLAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173210,
      "TXT_PINCODE_LOCALITY": "DAGSHAI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1845,
      "City District Name": "SOLAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173211,
      "TXT_PINCODE_LOCALITY": "SAPROON",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1845,
      "City District Name": "SOLAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173212,
      "TXT_PINCODE_LOCALITY": "SOLAN",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1845,
      "City District Name": "SOLAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173213,
      "TXT_PINCODE_LOCALITY": "CHAMBA GHAT",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1845,
      "City District Name": "SOLAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173214,
      "TXT_PINCODE_LOCALITY": "SOLAN BREWERY",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1845,
      "City District Name": "SOLAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173215,
      "TXT_PINCODE_LOCALITY": "RACHIYANA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1845,
      "City District Name": "SOLAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173216,
      "TXT_PINCODE_LOCALITY": "JUNGA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173217,
      "TXT_PINCODE_LOCALITY": "CHAIL",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1845,
      "City District Name": "SOLAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173218,
      "TXT_PINCODE_LOCALITY": "M S CHAIL",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1845,
      "City District Name": "SOLAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173219,
      "TXT_PINCODE_LOCALITY": "SHOGI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1845,
      "City District Name": "SOLAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173220,
      "TXT_PINCODE_LOCALITY": "VILLAGE KAMLI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 5032,
      "City District Name": "PARWANOO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173221,
      "TXT_PINCODE_LOCALITY": "DOMEHAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1845,
      "City District Name": "SOLAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173222,
      "TXT_PINCODE_LOCALITY": "SALOGRA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1845,
      "City District Name": "SOLAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173223,
      "TXT_PINCODE_LOCALITY": "OCHAGHAT",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1845,
      "City District Name": "SOLAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173224,
      "TXT_PINCODE_LOCALITY": "BALERA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1845,
      "City District Name": "SOLAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173225,
      "TXT_PINCODE_LOCALITY": "JUBBAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173226,
      "TXT_PINCODE_LOCALITY": "KUTHAI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1845,
      "City District Name": "SOLAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173227,
      "TXT_PINCODE_LOCALITY": "MAMLIGH",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1845,
      "City District Name": "SOLAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173228,
      "TXT_PINCODE_LOCALITY": "KAITHLIGHAT",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1845,
      "City District Name": "SOLAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173229,
      "TXT_PINCODE_LOCALITY": "KUMAR HATTI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1845,
      "City District Name": "SOLAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173230,
      "TXT_PINCODE_LOCALITY": "NAUNI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1845,
      "City District Name": "SOLAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173233,
      "TXT_PINCODE_LOCALITY": "PATTA S.O",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1845,
      "City District Name": "SOLAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173234,
      "TXT_PINCODE_LOCALITY": "WAKNAGHAT S.O",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1845,
      "City District Name": "SOLAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173235,
      "TXT_PINCODE_LOCALITY": "SUSAI (308)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 2111,
      "City District Name": "ARKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173236,
      "TXT_PINCODE_LOCALITY": "SOLAN",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1845,
      "City District Name": "SOLAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173299,
      "TXT_PINCODE_LOCALITY": "SIHARRI MUSHLMANA (769)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 3832,
      "City District Name": "KASAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173302,
      "TXT_PINCODE_LOCALITY": "MARIUNI (149).",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6511,
      "City District Name": "KARSOG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173304,
      "TXT_PINCODE_LOCALITY": "TEBAN (568)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6511,
      "City District Name": "KARSOG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 173316,
      "TXT_PINCODE_LOCALITY": "DHAR PUKHAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442674,
      "City District Name": "CHAURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174000,
      "TXT_PINCODE_LOCALITY": "SAMLETA (166)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442683,
      "City District Name": "JHANDUTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174001,
      "TXT_PINCODE_LOCALITY": "BILASPURI H O",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6289,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174002,
      "TXT_PINCODE_LOCALITY": "RANNI (382)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4680,
      "City District Name": "NAINA DEVI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174003,
      "TXT_PINCODE_LOCALITY": "CHALELI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6289,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174004,
      "TXT_PINCODE_LOCALITY": "KANDRAUR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6289,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174005,
      "TXT_PINCODE_LOCALITY": "RAGHUNATHPURA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6289,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174007,
      "TXT_PINCODE_LOCALITY": "TRER (159)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442672,
      "City District Name": "BILASPUR SADAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174010,
      "TXT_PINCODE_LOCALITY": "SAWHAN",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1845,
      "City District Name": "SOLAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174011,
      "TXT_PINCODE_LOCALITY": "SAWARGHAT",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6289,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174012,
      "TXT_PINCODE_LOCALITY": "PANJGAIN",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6289,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174013,
      "TXT_PINCODE_LOCALITY": "BARMANA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6289,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174014,
      "TXT_PINCODE_LOCALITY": "CHHAROL *",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6289,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174015,
      "TXT_PINCODE_LOCALITY": "BELAL *",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6289,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174017,
      "TXT_PINCODE_LOCALITY": "JEJWIN S.O",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6289,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174019,
      "TXT_PINCODE_LOCALITY": "JANGAL CHALAWA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442683,
      "City District Name": "JHANDUTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174021,
      "TXT_PINCODE_LOCALITY": "GHUMARWIN",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6289,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174022,
      "TXT_PINCODE_LOCALITY": "NASWAL",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6289,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174023,
      "TXT_PINCODE_LOCALITY": "BILASPUR S. O",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6289,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174024,
      "TXT_PINCODE_LOCALITY": "AUHAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6289,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174026,
      "TXT_PINCODE_LOCALITY": "KUTHERA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6289,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174027,
      "TXT_PINCODE_LOCALITY": "LEHRI SARAIL",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6289,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174028,
      "TXT_PINCODE_LOCALITY": "HATWAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6289,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174029,
      "TXT_PINCODE_LOCALITY": "BERTHIN",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6289,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174030,
      "TXT_PINCODE_LOCALITY": "TALLAI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6289,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174031,
      "TXT_PINCODE_LOCALITY": "JANDUTHA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6289,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174032,
      "TXT_PINCODE_LOCALITY": "NAMHOL",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6289,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174033,
      "TXT_PINCODE_LOCALITY": "JUKHALA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6289,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174034,
      "TXT_PINCODE_LOCALITY": "GHERWIN",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6289,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174035,
      "TXT_PINCODE_LOCALITY": "KALOL",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6289,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174036,
      "TXT_PINCODE_LOCALITY": "NTPC JAMTHAL S.O",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6289,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174037,
      "TXT_PINCODE_LOCALITY": "PANTEHRI (14/4)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442667,
      "City District Name": "BANGANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174082,
      "TXT_PINCODE_LOCALITY": "HATWAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6289,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174101,
      "TXT_PINCODE_LOCALITY": "NALAGARH",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1845,
      "City District Name": "SOLAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174102,
      "TXT_PINCODE_LOCALITY": "RAM SHAHAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1845,
      "City District Name": "SOLAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174103,
      "TXT_PINCODE_LOCALITY": "BAROTIWALA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1845,
      "City District Name": "SOLAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174110,
      "TXT_PINCODE_LOCALITY": "PALAHI (63/3)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 5972,
      "City District Name": "TIRA SUJANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174119,
      "TXT_PINCODE_LOCALITY": "RAEIN (35/18)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442669,
      "City District Name": "BARSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174128,
      "TXT_PINCODE_LOCALITY": "BAG (286)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442698,
      "City District Name": "RAMSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174201,
      "TXT_PINCODE_LOCALITY": "BHAKRA DAM",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6289,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174202,
      "TXT_PINCODE_LOCALITY": "SAMLOTI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4680,
      "City District Name": "NAINA DEVI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174204,
      "TXT_PINCODE_LOCALITY": "SHAMLI NICHLI (176)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 3832,
      "City District Name": "KASAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174209,
      "TXT_PINCODE_LOCALITY": "NELLA (374)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4680,
      "City District Name": "NAINA DEVI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174301,
      "TXT_PINCODE_LOCALITY": "SANTOKHGARH",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174302,
      "TXT_PINCODE_LOCALITY": "DHUSSARA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174303,
      "TXT_PINCODE_LOCALITY": "UNA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174304,
      "TXT_PINCODE_LOCALITY": "BANI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174305,
      "TXT_PINCODE_LOCALITY": "BARSAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174306,
      "TXT_PINCODE_LOCALITY": "DEHLAN",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174307,
      "TXT_PINCODE_LOCALITY": "BANGANA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174308,
      "TXT_PINCODE_LOCALITY": "LATHIANA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174309,
      "TXT_PINCODE_LOCALITY": "BAHIMA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174310,
      "TXT_PINCODE_LOCALITY": "KACHA TNK NAHAN N DEVI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6289,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174311,
      "TXT_PINCODE_LOCALITY": "SALAUNI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174312,
      "TXT_PINCODE_LOCALITY": "JAURA AMB",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174313,
      "TXT_PINCODE_LOCALITY": "MEHRE",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174314,
      "TXT_PINCODE_LOCALITY": "AMBEHRA DHIRAJ",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174315,
      "TXT_PINCODE_LOCALITY": "MEHATPUR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174316,
      "TXT_PINCODE_LOCALITY": "UNA S. O",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174317,
      "TXT_PINCODE_LOCALITY": "BHADSALA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174319,
      "TXT_PINCODE_LOCALITY": "MARWARI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174320,
      "TXT_PINCODE_LOCALITY": "TALMEHRA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174321,
      "TXT_PINCODE_LOCALITY": "THANAKALAN S.O",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174401,
      "TXT_PINCODE_LOCALITY": "SUNDERNAGAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174402,
      "TXT_PINCODE_LOCALITY": "TOWN SHIP",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174403,
      "TXT_PINCODE_LOCALITY": "SLAPPER",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174404,
      "TXT_PINCODE_LOCALITY": "PURAN NAGAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174405,
      "TXT_PINCODE_LOCALITY": "CHAMBI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6289,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174503,
      "TXT_PINCODE_LOCALITY": "KUTHAR BEET",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174505,
      "TXT_PINCODE_LOCALITY": "CHANGAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174507,
      "TXT_PINCODE_LOCALITY": "HAROLI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174515,
      "TXT_PINCODE_LOCALITY": "SER (39/18)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174565,
      "TXT_PINCODE_LOCALITY": "ULEHERA (37/1)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 174603,
      "TXT_PINCODE_LOCALITY": "HARLOG",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6289,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175001,
      "TXT_PINCODE_LOCALITY": "MANGWAIN MOHELLA PADDAL",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175002,
      "TXT_PINCODE_LOCALITY": "BHOJPUR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175003,
      "TXT_PINCODE_LOCALITY": "KOTLI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175004,
      "TXT_PINCODE_LOCALITY": "BHAMBLA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175005,
      "TXT_PINCODE_LOCALITY": "KATAULA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175006,
      "TXT_PINCODE_LOCALITY": "TIKKAR (MANDI)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175007,
      "TXT_PINCODE_LOCALITY": "PAURSA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175008,
      "TXT_PINCODE_LOCALITY": "NER CHOWK S.O",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175009,
      "TXT_PINCODE_LOCALITY": "SHAL (108).",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6511,
      "City District Name": "KARSOG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175010,
      "TXT_PINCODE_LOCALITY": "MUHALU KHAMRADHA (596)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442666,
      "City District Name": "BALI CHOWKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175011,
      "TXT_PINCODE_LOCALITY": "KARSOG S. O",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175012,
      "TXT_PINCODE_LOCALITY": "PADHAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175013,
      "TXT_PINCODE_LOCALITY": "JHUKAN (444)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442696,
      "City District Name": "PADHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175014,
      "TXT_PINCODE_LOCALITY": "DRUBBAL S.O",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175015,
      "TXT_PINCODE_LOCALITY": "JOGINDER NAGAR S.O",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175016,
      "TXT_PINCODE_LOCALITY": "MANDI S. O",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175017,
      "TXT_PINCODE_LOCALITY": "PANJOLATH (246)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 5794,
      "City District Name": "SUNDARNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175018,
      "TXT_PINCODE_LOCALITY": "SUNDER NAGAR-1 S.O",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175019,
      "TXT_PINCODE_LOCALITY": "SUNDER NAGAR TOWN SHIP H.O",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175021,
      "TXT_PINCODE_LOCALITY": "BHANGRATU",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175022,
      "TXT_PINCODE_LOCALITY": "GOPALPUR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175023,
      "TXT_PINCODE_LOCALITY": "REWALSAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175024,
      "TXT_PINCODE_LOCALITY": "SARKAGHAT",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175025,
      "TXT_PINCODE_LOCALITY": "SAJAO PIPLU",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175026,
      "TXT_PINCODE_LOCALITY": "TIHRA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175027,
      "TXT_PINCODE_LOCALITY": "BAGGI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175028,
      "TXT_PINCODE_LOCALITY": "CHACHIOT",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175029,
      "TXT_PINCODE_LOCALITY": "GOHAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175030,
      "TXT_PINCODE_LOCALITY": "DEHAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175031,
      "TXT_PINCODE_LOCALITY": "JAI DEVI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175032,
      "TXT_PINCODE_LOCALITY": "CHAUNTRA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175033,
      "TXT_PINCODE_LOCALITY": "BALDWARA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175034,
      "TXT_PINCODE_LOCALITY": "SAMAILA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175035,
      "TXT_PINCODE_LOCALITY": "BAGSIAD",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175036,
      "TXT_PINCODE_LOCALITY": "GURKOTHU",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175037,
      "TXT_PINCODE_LOCALITY": "CHORTHALA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175038,
      "TXT_PINCODE_LOCALITY": "NIHRI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175039,
      "TXT_PINCODE_LOCALITY": "JACHH",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175040,
      "TXT_PINCODE_LOCALITY": "DHARAMPUR MANDI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175041,
      "TXT_PINCODE_LOCALITY": "BIR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175042,
      "TXT_PINCODE_LOCALITY": "RAKOH",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175043,
      "TXT_PINCODE_LOCALITY": "RATTI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175044,
      "TXT_PINCODE_LOCALITY": "GHIRI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175045,
      "TXT_PINCODE_LOCALITY": "CHAIL CHOWK",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175046,
      "TXT_PINCODE_LOCALITY": "SUKIBAN",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175047,
      "TXT_PINCODE_LOCALITY": "JANHELI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175048,
      "TXT_PINCODE_LOCALITY": "KANAID",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175049,
      "TXT_PINCODE_LOCALITY": "JAMNI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175050,
      "TXT_PINCODE_LOCALITY": "MARHI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175051,
      "TXT_PINCODE_LOCALITY": "SEOH",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4138,
      "City District Name": "KULLU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175052,
      "TXT_PINCODE_LOCALITY": "SAIGLOO",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175054,
      "TXT_PINCODE_LOCALITY": "SAGALWARA (434)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442703,
      "City District Name": "THUNAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175057,
      "TXT_PINCODE_LOCALITY": "SAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442703,
      "City District Name": "THUNAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175100,
      "TXT_PINCODE_LOCALITY": "CHANDEH (318)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175101,
      "TXT_PINCODE_LOCALITY": "KULLU",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4138,
      "City District Name": "KULLU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175102,
      "TXT_PINCODE_LOCALITY": "BHUTTI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175103,
      "TXT_PINCODE_LOCALITY": "BAHANG S.O",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4138,
      "City District Name": "KULLU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175104,
      "TXT_PINCODE_LOCALITY": "KULLU S.O",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4138,
      "City District Name": "KULLU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175105,
      "TXT_PINCODE_LOCALITY": "JAREE",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4138,
      "City District Name": "KULLU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175106,
      "TXT_PINCODE_LOCALITY": "BALICHOWKI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175109,
      "TXT_PINCODE_LOCALITY": "JANGAL BARI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175114,
      "TXT_PINCODE_LOCALITY": "MANJHLI (37/93)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4138,
      "City District Name": "KULLU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175120,
      "TXT_PINCODE_LOCALITY": "TULAH (213)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442689,
      "City District Name": "LAD BHAROL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175121,
      "TXT_PINCODE_LOCALITY": "AUT",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175122,
      "TXT_PINCODE_LOCALITY": "LARJI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4138,
      "City District Name": "KULLU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175123,
      "TXT_PINCODE_LOCALITY": "BANJAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4138,
      "City District Name": "KULLU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175124,
      "TXT_PINCODE_LOCALITY": "PANDOH",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175125,
      "TXT_PINCODE_LOCALITY": "BHUNTAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4138,
      "City District Name": "KULLU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175126,
      "TXT_PINCODE_LOCALITY": "SHAMSHI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4138,
      "City District Name": "KULLU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175127,
      "TXT_PINCODE_LOCALITY": "BANDROL ORCHARDS",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4138,
      "City District Name": "KULLU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175128,
      "TXT_PINCODE_LOCALITY": "RAISON",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4138,
      "City District Name": "KULLU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175129,
      "TXT_PINCODE_LOCALITY": "KATRAIN",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4138,
      "City District Name": "KULLU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175130,
      "TXT_PINCODE_LOCALITY": "NAGAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4138,
      "City District Name": "KULLU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175131,
      "TXT_PINCODE_LOCALITY": "MANALI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4138,
      "City District Name": "KULLU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175132,
      "TXT_PINCODE_LOCALITY": "KEYLONG",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 424447,
      "City District Name": "LAHUL & SPITI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175133,
      "TXT_PINCODE_LOCALITY": "LOTE",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4138,
      "City District Name": "KULLU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175134,
      "TXT_PINCODE_LOCALITY": "SAINJ",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4138,
      "City District Name": "KULLU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175135,
      "TXT_PINCODE_LOCALITY": "BAJAURA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4138,
      "City District Name": "KULLU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175136,
      "TXT_PINCODE_LOCALITY": "HARIPUR(KULLU)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1845,
      "City District Name": "SOLAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175137,
      "TXT_PINCODE_LOCALITY": "JAGAT SUKH",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4138,
      "City District Name": "KULLU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175138,
      "TXT_PINCODE_LOCALITY": "BABELI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4138,
      "City District Name": "KULLU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175139,
      "TXT_PINCODE_LOCALITY": "LAHAUL",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442145,
      "City District Name": "LAHUL AND SPITI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175140,
      "TXT_PINCODE_LOCALITY": "GONDLA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 424447,
      "City District Name": "LAHUL & SPITI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175141,
      "TXT_PINCODE_LOCALITY": "GARSA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4138,
      "City District Name": "KULLU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175142,
      "TXT_PINCODE_LOCALITY": "UDAIPUR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4138,
      "City District Name": "KULLU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175143,
      "TXT_PINCODE_LOCALITY": "UP MUHAL SYAL",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6409,
      "City District Name": "MANALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175146,
      "TXT_PINCODE_LOCALITY": "CHHOTA DARA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442690,
      "City District Name": "LAHUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175147,
      "TXT_PINCODE_LOCALITY": "CHAKURTHA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4138,
      "City District Name": "KULLU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175154,
      "TXT_PINCODE_LOCALITY": "SHARACHI (532)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442703,
      "City District Name": "THUNAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175203,
      "TXT_PINCODE_LOCALITY": "NAND PUR (170)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 2205,
      "City District Name": "BADDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175207,
      "TXT_PINCODE_LOCALITY": "KHANORA (269)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442702,
      "City District Name": "SIHUNTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175211,
      "TXT_PINCODE_LOCALITY": "GALDING",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442694,
      "City District Name": "NICHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175219,
      "TXT_PINCODE_LOCALITY": "DHARAMSALA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175312,
      "TXT_PINCODE_LOCALITY": "PARNOHIN (296)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6473,
      "City District Name": "CHAMBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175403,
      "TXT_PINCODE_LOCALITY": "KATHIUN (17).",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442683,
      "City District Name": "JHANDUTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175612,
      "TXT_PINCODE_LOCALITY": "UP MUHAL DAMCHIN.",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4138,
      "City District Name": "KULLU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175613,
      "TXT_PINCODE_LOCALITY": "UP MUHAL RANGRI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4138,
      "City District Name": "KULLU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175616,
      "TXT_PINCODE_LOCALITY": "SHILA KADAUN",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6511,
      "City District Name": "KARSOG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 175721,
      "TXT_PINCODE_LOCALITY": "DPF MATHYANI DHAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442666,
      "City District Name": "BALI CHOWKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176000,
      "TXT_PINCODE_LOCALITY": "PUKHRU (740)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442686,
      "City District Name": "KHUNDIAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176001,
      "TXT_PINCODE_LOCALITY": "KANGRA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176002,
      "TXT_PINCODE_LOCALITY": "KANGRA TOWN",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176008,
      "TXT_PINCODE_LOCALITY": "JANS PNERA (994)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6506,
      "City District Name": "BAIJNATH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176009,
      "TXT_PINCODE_LOCALITY": "JANDRU (63/11)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 5972,
      "City District Name": "TIRA SUJANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176010,
      "TXT_PINCODE_LOCALITY": "RAUR (340)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4860,
      "City District Name": "NURPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176011,
      "TXT_PINCODE_LOCALITY": "LAMBRI (60/4).",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 5972,
      "City District Name": "TIRA SUJANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176012,
      "TXT_PINCODE_LOCALITY": "SAPOTHA (1044)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442691,
      "City District Name": "MULTHAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176018,
      "TXT_PINCODE_LOCALITY": "SALGHUN-LACHHO (54/14)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 5972,
      "City District Name": "TIRA SUJANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176021,
      "TXT_PINCODE_LOCALITY": "BHARMAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176022,
      "TXT_PINCODE_LOCALITY": "REHAN",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176023,
      "TXT_PINCODE_LOCALITY": "JAWALI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176024,
      "TXT_PINCODE_LOCALITY": "HARSAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176025,
      "TXT_PINCODE_LOCALITY": "DHAMETA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176026,
      "TXT_PINCODE_LOCALITY": "LANJ",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176027,
      "TXT_PINCODE_LOCALITY": "NAGROTA SURIAN",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176028,
      "TXT_PINCODE_LOCALITY": "BANGOLI B.O",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176029,
      "TXT_PINCODE_LOCALITY": "RANITAL",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176030,
      "TXT_PINCODE_LOCALITY": "KHUNDIAN",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176031,
      "TXT_PINCODE_LOCALITY": "JAWALA MUKHI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176032,
      "TXT_PINCODE_LOCALITY": "MAJHIN",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176033,
      "TXT_PINCODE_LOCALITY": "KOTHOG",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176034,
      "TXT_PINCODE_LOCALITY": "MASROOR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176035,
      "TXT_PINCODE_LOCALITY": "BARIAL",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6473,
      "City District Name": "CHAMBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176036,
      "TXT_PINCODE_LOCALITY": "KOHALA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176037,
      "TXT_PINCODE_LOCALITY": "SUNHI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176038,
      "TXT_PINCODE_LOCALITY": "DAULATPUR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176039,
      "TXT_PINCODE_LOCALITY": "CHAKMOU",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176040,
      "TXT_PINCODE_LOCALITY": "BIJHARI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176041,
      "TXT_PINCODE_LOCALITY": "BHOTA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176042,
      "TXT_PINCODE_LOCALITY": "MAIR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176043,
      "TXT_PINCODE_LOCALITY": "LADRAUR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176044,
      "TXT_PINCODE_LOCALITY": "MUNDKHAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176045,
      "TXT_PINCODE_LOCALITY": "BHORANJ",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176046,
      "TXT_PINCODE_LOCALITY": "TANDA(KANGRA)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176047,
      "TXT_PINCODE_LOCALITY": "NAGROTA BAGWAN",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176048,
      "TXT_PINCODE_LOCALITY": "JAHU",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176049,
      "TXT_PINCODE_LOCALITY": "MAHARAI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176050,
      "TXT_PINCODE_LOCALITY": "PATHIAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176051,
      "TXT_PINCODE_LOCALITY": "RAJA KA TALAB",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176052,
      "TXT_PINCODE_LOCALITY": "YOL CAMP",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176053,
      "TXT_PINCODE_LOCALITY": "FATEHPUR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176054,
      "TXT_PINCODE_LOCALITY": "BAROH",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176055,
      "TXT_PINCODE_LOCALITY": "THILL",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176056,
      "TXT_PINCODE_LOCALITY": "SUNEHAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176057,
      "TXT_PINCODE_LOCALITY": "DARI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176058,
      "TXT_PINCODE_LOCALITY": "DADH",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176059,
      "TXT_PINCODE_LOCALITY": "CHACHIAN",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176060,
      "TXT_PINCODE_LOCALITY": "DARANG",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176061,
      "TXT_PINCODE_LOCALITY": "PALAMPUR H O",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4932,
      "City District Name": "PALAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176062,
      "TXT_PINCODE_LOCALITY": "PALAMPUR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176063,
      "TXT_PINCODE_LOCALITY": "BANURI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176064,
      "TXT_PINCODE_LOCALITY": "RAJPUR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176065,
      "TXT_PINCODE_LOCALITY": "RAJHOON",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176066,
      "TXT_PINCODE_LOCALITY": "GALORE",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176069,
      "TXT_PINCODE_LOCALITY": "CHAKBAN BARI (808)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442668,
      "City District Name": "BAROH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176071,
      "TXT_PINCODE_LOCALITY": "NAGEHR UPARLI (1015)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6506,
      "City District Name": "BAIJNATH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176072,
      "TXT_PINCODE_LOCALITY": "KANGRA S. O",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176073,
      "TXT_PINCODE_LOCALITY": "ODRI (496).",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442682,
      "City District Name": "JAISINGHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176074,
      "TXT_PINCODE_LOCALITY": "DEGOH",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176075,
      "TXT_PINCODE_LOCALITY": "DUHAK",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176076,
      "TXT_PINCODE_LOCALITY": "BAWANA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176077,
      "TXT_PINCODE_LOCALITY": "BIR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176080,
      "TXT_PINCODE_LOCALITY": "CHHANCHHORI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176081,
      "TXT_PINCODE_LOCALITY": "ALHILAL TARAGARH",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176082,
      "TXT_PINCODE_LOCALITY": "JAGRUP NAGAR (474)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442682,
      "City District Name": "JAISINGHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176083,
      "TXT_PINCODE_LOCALITY": "BAWARNA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176084,
      "TXT_PINCODE_LOCALITY": "NAURA (KANGRA)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176085,
      "TXT_PINCODE_LOCALITY": "PALAMPUR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176086,
      "TXT_PINCODE_LOCALITY": "KHERA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176087,
      "TXT_PINCODE_LOCALITY": "PAHRA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176088,
      "TXT_PINCODE_LOCALITY": "CHADIAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176089,
      "TXT_PINCODE_LOCALITY": "MOLAG",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176090,
      "TXT_PINCODE_LOCALITY": "SANDHOLE",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176091,
      "TXT_PINCODE_LOCALITY": "SANGHOLE",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176092,
      "TXT_PINCODE_LOCALITY": "DAROH",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176093,
      "TXT_PINCODE_LOCALITY": "MALKHER",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176094,
      "TXT_PINCODE_LOCALITY": "JAILAG",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6289,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176095,
      "TXT_PINCODE_LOCALITY": "JAI SINGPUR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176096,
      "TXT_PINCODE_LOCALITY": "LAMBAGRAON",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176097,
      "TXT_PINCODE_LOCALITY": "MASHWAR (479)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442682,
      "City District Name": "JAISINGHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176098,
      "TXT_PINCODE_LOCALITY": "LAHRU",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176099,
      "TXT_PINCODE_LOCALITY": "GUJREHRA (13)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4932,
      "City District Name": "PALAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176100,
      "TXT_PINCODE_LOCALITY": "KHALTA (928)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442682,
      "City District Name": "JAISINGHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176101,
      "TXT_PINCODE_LOCALITY": "DHEERA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176102,
      "TXT_PINCODE_LOCALITY": "PALAMPUR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176103,
      "TXT_PINCODE_LOCALITY": "PANCHRUKHI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176104,
      "TXT_PINCODE_LOCALITY": "BHAS GUAR (707)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4932,
      "City District Name": "PALAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176106,
      "TXT_PINCODE_LOCALITY": "MANI (617)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442666,
      "City District Name": "BALI CHOWKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176107,
      "TXT_PINCODE_LOCALITY": "THURAI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176108,
      "TXT_PINCODE_LOCALITY": "BHALETH",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176109,
      "TXT_PINCODE_LOCALITY": "TAPAL DHAR (64/15)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 5972,
      "City District Name": "TIRA SUJANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176110,
      "TXT_PINCODE_LOCALITY": "SUJANPUR TIRA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176111,
      "TXT_PINCODE_LOCALITY": "PATLENDAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176115,
      "TXT_PINCODE_LOCALITY": "PAPROLA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176117,
      "TXT_PINCODE_LOCALITY": "TAURU BUHLA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 5972,
      "City District Name": "TIRA SUJANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176120,
      "TXT_PINCODE_LOCALITY": "JOGINDER NAGAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176121,
      "TXT_PINCODE_LOCALITY": "PADDHAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6473,
      "City District Name": "CHAMBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176122,
      "TXT_PINCODE_LOCALITY": "MANDI SADAR S. O",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176123,
      "TXT_PINCODE_LOCALITY": "DRUBBAL",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176125,
      "TXT_PINCODE_LOCALITY": "BAIJ NATH",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176126,
      "TXT_PINCODE_LOCALITY": "LADOBHAROL",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176127,
      "TXT_PINCODE_LOCALITY": "KHOPA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176128,
      "TXT_PINCODE_LOCALITY": "CHOBIN",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176129,
      "TXT_PINCODE_LOCALITY": "UP MUHAL SHIRAR - II ND",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4138,
      "City District Name": "KULLU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176132,
      "TXT_PINCODE_LOCALITY": "MEH (8/5)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442690,
      "City District Name": "LAHUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176139,
      "TXT_PINCODE_LOCALITY": "PUKHRI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6473,
      "City District Name": "CHAMBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176188,
      "TXT_PINCODE_LOCALITY": "THEHAR UPPERLI (980)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6506,
      "City District Name": "BAIJNATH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176195,
      "TXT_PINCODE_LOCALITY": "KOTLI (1032)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6506,
      "City District Name": "BAIJNATH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176200,
      "TXT_PINCODE_LOCALITY": "TOHARAR (21)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442680,
      "City District Name": "FATEHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176201,
      "TXT_PINCODE_LOCALITY": "JASSUR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176202,
      "TXT_PINCODE_LOCALITY": "NURPUR(KANGRA)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176203,
      "TXT_PINCODE_LOCALITY": "LADRORI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176204,
      "TXT_PINCODE_LOCALITY": "GANGTHA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176205,
      "TXT_PINCODE_LOCALITY": "KOTLA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176206,
      "TXT_PINCODE_LOCALITY": "SHAHPUR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176207,
      "TXT_PINCODE_LOCALITY": "SIHAUNTA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6473,
      "City District Name": "CHAMBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176208,
      "TXT_PINCODE_LOCALITY": "RAIT",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176209,
      "TXT_PINCODE_LOCALITY": "GAGGAL",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176210,
      "TXT_PINCODE_LOCALITY": "HARCHAKIAN",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176211,
      "TXT_PINCODE_LOCALITY": "SALIALI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176212,
      "TXT_PINCODE_LOCALITY": "BAGLI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176213,
      "TXT_PINCODE_LOCALITY": "RAJOL",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176214,
      "TXT_PINCODE_LOCALITY": "TEARA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176215,
      "TXT_PINCODE_LOCALITY": "DHARAMSALA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176216,
      "TXT_PINCODE_LOCALITY": "DHARAMSALA CANTT",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176217,
      "TXT_PINCODE_LOCALITY": "CHARRI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176218,
      "TXT_PINCODE_LOCALITY": "KHANYARA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176219,
      "TXT_PINCODE_LOCALITY": "MECLODGANJ",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176220,
      "TXT_PINCODE_LOCALITY": "GHANDWAL",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176221,
      "TXT_PINCODE_LOCALITY": "TIARA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176222,
      "TXT_PINCODE_LOCALITY": "REHLU",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176223,
      "TXT_PINCODE_LOCALITY": "DRAMAN",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176225,
      "TXT_PINCODE_LOCALITY": "TILOKPUR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176226,
      "TXT_PINCODE_LOCALITY": "BHALLI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176301,
      "TXT_PINCODE_LOCALITY": "BAKLOH",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6473,
      "City District Name": "CHAMBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176302,
      "TXT_PINCODE_LOCALITY": "CHOWARI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6473,
      "City District Name": "CHAMBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176303,
      "TXT_PINCODE_LOCALITY": "BANIKHET",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6473,
      "City District Name": "CHAMBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176304,
      "TXT_PINCODE_LOCALITY": "DALHOUSIE",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6473,
      "City District Name": "CHAMBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176305,
      "TXT_PINCODE_LOCALITY": "DALHOUSIE CANTT",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6473,
      "City District Name": "CHAMBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176306,
      "TXT_PINCODE_LOCALITY": "BATHARI BAZAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6473,
      "City District Name": "CHAMBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176308,
      "TXT_PINCODE_LOCALITY": "BHALAI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6473,
      "City District Name": "CHAMBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176309,
      "TXT_PINCODE_LOCALITY": "CHANHOUTA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6473,
      "City District Name": "CHAMBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176310,
      "TXT_PINCODE_LOCALITY": "CHAMBA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6473,
      "City District Name": "CHAMBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176311,
      "TXT_PINCODE_LOCALITY": "MEHLA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6473,
      "City District Name": "CHAMBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176312,
      "TXT_PINCODE_LOCALITY": "SALOONI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6473,
      "City District Name": "CHAMBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176313,
      "TXT_PINCODE_LOCALITY": "KAKIRU",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6473,
      "City District Name": "CHAMBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176314,
      "TXT_PINCODE_LOCALITY": "SULTANPUR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6473,
      "City District Name": "CHAMBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176315,
      "TXT_PINCODE_LOCALITY": "BHARMOUR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6473,
      "City District Name": "CHAMBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176316,
      "TXT_PINCODE_LOCALITY": "BHANJRAU TISSA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6473,
      "City District Name": "CHAMBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176317,
      "TXT_PINCODE_LOCALITY": "SURARGNI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6473,
      "City District Name": "CHAMBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176318,
      "TXT_PINCODE_LOCALITY": "HARDASPUR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6473,
      "City District Name": "CHAMBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176319,
      "TXT_PINCODE_LOCALITY": "RUKHRI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6473,
      "City District Name": "CHAMBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176320,
      "TXT_PINCODE_LOCALITY": "SALOONI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6473,
      "City District Name": "CHAMBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176321,
      "TXT_PINCODE_LOCALITY": "KHAJJAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6473,
      "City District Name": "CHAMBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176322,
      "TXT_PINCODE_LOCALITY": "KIANI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6473,
      "City District Name": "CHAMBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176323,
      "TXT_PINCODE_LOCALITY": "KILLAR PANGI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6473,
      "City District Name": "CHAMBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176324,
      "TXT_PINCODE_LOCALITY": "GEHRA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6473,
      "City District Name": "CHAMBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176325,
      "TXT_PINCODE_LOCALITY": "SAROL",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6473,
      "City District Name": "CHAMBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176326,
      "TXT_PINCODE_LOCALITY": "CHAMBA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6473,
      "City District Name": "CHAMBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176327,
      "TXT_PINCODE_LOCALITY": "DHAR RUNOTA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442674,
      "City District Name": "CHAURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176401,
      "TXT_PINCODE_LOCALITY": "INDORA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176402,
      "TXT_PINCODE_LOCALITY": "KANDRORI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176403,
      "TXT_PINCODE_LOCALITY": "DHAMTAL",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176404,
      "TXT_PINCODE_LOCALITY": "MOTHLI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176501,
      "TXT_PINCODE_LOCALITY": "SANSARPUR TERRACE",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176502,
      "TXT_PINCODE_LOCALITY": "GHAMROOR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176512,
      "TXT_PINCODE_LOCALITY": "TIKRU (75)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 3612,
      "City District Name": "JOGINDARNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176601,
      "TXT_PINCODE_LOCALITY": "DULEHAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176626,
      "TXT_PINCODE_LOCALITY": "BHALI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 176701,
      "TXT_PINCODE_LOCALITY": "SAMULA (97)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4860,
      "City District Name": "NURPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177000,
      "TXT_PINCODE_LOCALITY": "SASAN (49/4)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177001,
      "TXT_PINCODE_LOCALITY": "HAMIRPUR H O",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177004,
      "TXT_PINCODE_LOCALITY": "NALSUHA BHARWARIAN (142)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 2929,
      "City District Name": "DERA GOPIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177005,
      "TXT_PINCODE_LOCALITY": "GOVT DEGREE CLG HAMIRPUR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177006,
      "TXT_PINCODE_LOCALITY": "THANIARI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177007,
      "TXT_PINCODE_LOCALITY": "BEHNI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177020,
      "TXT_PINCODE_LOCALITY": "KUTHERA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177021,
      "TXT_PINCODE_LOCALITY": "BAGWARA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177022,
      "TXT_PINCODE_LOCALITY": "UHAL",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177023,
      "TXT_PINCODE_LOCALITY": "TAUNI DEVI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177024,
      "TXT_PINCODE_LOCALITY": "BHARERI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177025,
      "TXT_PINCODE_LOCALITY": "KANJIAN",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177026,
      "TXT_PINCODE_LOCALITY": "GALORE",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177027,
      "TXT_PINCODE_LOCALITY": "KOT",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177028,
      "TXT_PINCODE_LOCALITY": "KAKKAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177029,
      "TXT_PINCODE_LOCALITY": "LAMBLOO",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177030,
      "TXT_PINCODE_LOCALITY": "MOHIN",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177031,
      "TXT_PINCODE_LOCALITY": "RAIPUR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177032,
      "TXT_PINCODE_LOCALITY": "MANDLI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177033,
      "TXT_PINCODE_LOCALITY": "NADAUN",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177034,
      "TXT_PINCODE_LOCALITY": "PIRSALOHI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177035,
      "TXT_PINCODE_LOCALITY": "AWAHDEVI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177038,
      "TXT_PINCODE_LOCALITY": "SERA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177039,
      "TXT_PINCODE_LOCALITY": "TAKOLI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177040,
      "TXT_PINCODE_LOCALITY": "KANGOO",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177041,
      "TXT_PINCODE_LOCALITY": "DHANETA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177042,
      "TXT_PINCODE_LOCALITY": "JALARI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177043,
      "TXT_PINCODE_LOCALITY": "RAKKAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177044,
      "TXT_PINCODE_LOCALITY": "BARA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177045,
      "TXT_PINCODE_LOCALITY": "RAIL",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177046,
      "TXT_PINCODE_LOCALITY": "SADHIAL",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177047,
      "TXT_PINCODE_LOCALITY": "DEOTSIDH",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177048,
      "TXT_PINCODE_LOCALITY": "BARMANA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177101,
      "TXT_PINCODE_LOCALITY": "DEHRA GOPIPUR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177102,
      "TXT_PINCODE_LOCALITY": "KOHALA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177103,
      "TXT_PINCODE_LOCALITY": "DHALIARA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177104,
      "TXT_PINCODE_LOCALITY": "NEHRAN POKHER",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177105,
      "TXT_PINCODE_LOCALITY": "THORE",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177106,
      "TXT_PINCODE_LOCALITY": "DADA SIBA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177107,
      "TXT_PINCODE_LOCALITY": "PARAGPUR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177108,
      "TXT_PINCODE_LOCALITY": "GARLI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177109,
      "TXT_PINCODE_LOCALITY": "BHARWAIN",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177110,
      "TXT_PINCODE_LOCALITY": "CHINTPURNI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177111,
      "TXT_PINCODE_LOCALITY": "QASBA KOTLA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177112,
      "TXT_PINCODE_LOCALITY": "TIPRI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177113,
      "TXT_PINCODE_LOCALITY": "CHANAUR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177114,
      "TXT_PINCODE_LOCALITY": "BANKHANDI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177115,
      "TXT_PINCODE_LOCALITY": "CHANAUR *",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177116,
      "TXT_PINCODE_LOCALITY": "GUMMER",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177117,
      "TXT_PINCODE_LOCALITY": "SUNEHAT",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177118,
      "TXT_PINCODE_LOCALITY": "CHALELE",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177119,
      "TXT_PINCODE_LOCALITY": "KOTHI (35/13)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442669,
      "City District Name": "BARSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177201,
      "TXT_PINCODE_LOCALITY": "GAGRET",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177202,
      "TXT_PINCODE_LOCALITY": "MUBARIKPUR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177203,
      "TXT_PINCODE_LOCALITY": "AMB",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177204,
      "TXT_PINCODE_LOCALITY": "DAULATPUR CHOWK",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177205,
      "TXT_PINCODE_LOCALITY": "AMBOTA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177206,
      "TXT_PINCODE_LOCALITY": "OEL",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177207,
      "TXT_PINCODE_LOCALITY": "PANDOGA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177208,
      "TXT_PINCODE_LOCALITY": "PANJAWAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177209,
      "TXT_PINCODE_LOCALITY": "SALOH",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177210,
      "TXT_PINCODE_LOCALITY": "NEHRI NARANGAN",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177211,
      "TXT_PINCODE_LOCALITY": "THATHAL",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177212,
      "TXT_PINCODE_LOCALITY": "GHANAURI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177213,
      "TXT_PINCODE_LOCALITY": "GONDPUR BANERA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177214,
      "TXT_PINCODE_LOCALITY": "LOHARA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177215,
      "TXT_PINCODE_LOCALITY": "BANSAL",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177216,
      "TXT_PINCODE_LOCALITY": "KUNGRET",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177217,
      "TXT_PINCODE_LOCALITY": "PALHWAH",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177218,
      "TXT_PINCODE_LOCALITY": "BHADSALI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177219,
      "TXT_PINCODE_LOCALITY": "SUNKALAI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177220,
      "TXT_PINCODE_LOCALITY": "HAROLI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177301,
      "TXT_PINCODE_LOCALITY": "BASARAL S.O",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177401,
      "TXT_PINCODE_LOCALITY": "SAMRALA (42/48)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177405,
      "TXT_PINCODE_LOCALITY": "GANDOLI (36/38)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442681,
      "City District Name": "GALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177501,
      "TXT_PINCODE_LOCALITY": "AMAN",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177502,
      "TXT_PINCODE_LOCALITY": "THUTHWANI BRAHMNA (44/57)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442671,
      "City District Name": "BHORANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177601,
      "TXT_PINCODE_LOCALITY": "POST SAMIRPUR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177602,
      "TXT_PINCODE_LOCALITY": "NAMLAKH (43/44)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442671,
      "City District Name": "BHORANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177631,
      "TXT_PINCODE_LOCALITY": "THALLI (442)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442674,
      "City District Name": "CHAURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177714,
      "TXT_PINCODE_LOCALITY": "THANA (628)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 2929,
      "City District Name": "DERA GOPIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177734,
      "TXT_PINCODE_LOCALITY": "SUKRALA (222)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442697,
      "City District Name": "RAKKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 177743,
      "TXT_PINCODE_LOCALITY": "NAGROTA NAHAN (208)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442697,
      "City District Name": "RAKKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 178513,
      "TXT_PINCODE_LOCALITY": "UP MUHAL HAMTA 1/7",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6409,
      "City District Name": "MANALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 179098,
      "TXT_PINCODE_LOCALITY": "DHAMANDAR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442682,
      "City District Name": "JAISINGHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 179156,
      "TXT_PINCODE_LOCALITY": "BUCURESTI",
      "NUM_STATE_CD": 857,
      "State Name": "ROMANIA",
      "NUM_CITYDISTRICT_CD": 444081,
      "City District Name": "2ND DISTRICT BUCURESTI",
      "NUM_COUNTRY_CD": 131
    },
    {
      "NUM_PINCODE": 179207,
      "TXT_PINCODE_LOCALITY": "KUTHMAN (313)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6518,
      "City District Name": "SHAHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 179725,
      "TXT_PINCODE_LOCALITY": "SHAYARI (495)",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442665,
      "City District Name": "AUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 180001,
      "TXT_PINCODE_LOCALITY": "RAGHUNATH BAZAR",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 180002,
      "TXT_PINCODE_LOCALITY": "TALAB TILOO",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 180003,
      "TXT_PINCODE_LOCALITY": "JAMMU",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 180004,
      "TXT_PINCODE_LOCALITY": "GURUNANAK NAGAR (JAMMU)",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 180005,
      "TXT_PINCODE_LOCALITY": "QILLA BAHU",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 180006,
      "TXT_PINCODE_LOCALITY": "GURUNANAK NAGAR",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 180007,
      "TXT_PINCODE_LOCALITY": "JAMIPURA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 180008,
      "TXT_PINCODE_LOCALITY": "GHOMAN HASSAN",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 180009,
      "TXT_PINCODE_LOCALITY": "BOHR CAMP",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 180010,
      "TXT_PINCODE_LOCALITY": "GANGYAL",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 180011,
      "TXT_PINCODE_LOCALITY": "SHERI KASHMIR POLICE",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 180012,
      "TXT_PINCODE_LOCALITY": "TRIKUTA NAGAR",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 180013,
      "TXT_PINCODE_LOCALITY": "ROOP NAGAR",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 180015,
      "TXT_PINCODE_LOCALITY": "CHANI HIMAT",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 180016,
      "TXT_PINCODE_LOCALITY": "JAMMU",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 180017,
      "TXT_PINCODE_LOCALITY": "BAIN BAJALTA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 180018,
      "TXT_PINCODE_LOCALITY": "UDHEYWALA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 180019,
      "TXT_PINCODE_LOCALITY": "SIDHRA HOUSING COLONY",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 180020,
      "TXT_PINCODE_LOCALITY": "VILLAGE ENCLAVE",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 180120,
      "TXT_PINCODE_LOCALITY": "SRI PARTAP SINGH",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1962,
      "City District Name": "AKHNOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 180206,
      "TXT_PINCODE_LOCALITY": "DHATRIYAL",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 180221,
      "TXT_PINCODE_LOCALITY": "UDRANA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 6033,
      "City District Name": "UDHAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181008,
      "TXT_PINCODE_LOCALITY": "SAHARAN(SANARAN)",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181101,
      "TXT_PINCODE_LOCALITY": "MIRAN SAHIB",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181102,
      "TXT_PINCODE_LOCALITY": "R S PURA BUS STND",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181103,
      "TXT_PINCODE_LOCALITY": "BRAHAMANA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181104,
      "TXT_PINCODE_LOCALITY": "SIMBAL CAMP",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181105,
      "TXT_PINCODE_LOCALITY": "RATTIAN",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181111,
      "TXT_PINCODE_LOCALITY": "DABLEHAR",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181112,
      "TXT_PINCODE_LOCALITY": "RATHANA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181113,
      "TXT_PINCODE_LOCALITY": "CHAKROHI",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181114,
      "TXT_PINCODE_LOCALITY": "KHOUR DEVIANI",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181120,
      "TXT_PINCODE_LOCALITY": "NATHWAL",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5471,
      "City District Name": "SAMBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181121,
      "TXT_PINCODE_LOCALITY": "PLAURA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181122,
      "TXT_PINCODE_LOCALITY": "BHALWAL",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181123,
      "TXT_PINCODE_LOCALITY": "BANTALAB",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181124,
      "TXT_PINCODE_LOCALITY": "PLAURA B S F CAMP",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181130,
      "TXT_PINCODE_LOCALITY": "RAMPUR",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5471,
      "City District Name": "SAMBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181131,
      "TXT_PINCODE_LOCALITY": "ARNIA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181132,
      "TXT_PINCODE_LOCALITY": "BISHNAH ADDA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181133,
      "TXT_PINCODE_LOCALITY": "INDUSTRIAL COMPLEX",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181134,
      "TXT_PINCODE_LOCALITY": "JAKH",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181141,
      "TXT_PINCODE_LOCALITY": "RAMGARH",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181142,
      "TXT_PINCODE_LOCALITY": "GHO BRAHAMANA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181143,
      "TXT_PINCODE_LOCALITY": "GURAH SALATHIAN",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181144,
      "TXT_PINCODE_LOCALITY": "NAND PORA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181145,
      "TXT_PINCODE_LOCALITY": "PURMANDAL",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181151,
      "TXT_PINCODE_LOCALITY": "RAIKA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181152,
      "TXT_PINCODE_LOCALITY": "GANDHI NAGAR JT",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181163,
      "TXT_PINCODE_LOCALITY": "SYED GARH",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 442707,
      "City District Name": "BISHNAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181201,
      "TXT_PINCODE_LOCALITY": "AKHNOOR ADDA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181202,
      "TXT_PINCODE_LOCALITY": "JOURIAN",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181203,
      "TXT_PINCODE_LOCALITY": "KHOUR",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181204,
      "TXT_PINCODE_LOCALITY": "PALLANWALA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181205,
      "TXT_PINCODE_LOCALITY": "CHHAMB",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181206,
      "TXT_PINCODE_LOCALITY": "DUMANA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181207,
      "TXT_PINCODE_LOCALITY": "PRAGWAL",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181208,
      "TXT_PINCODE_LOCALITY": "HALQA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181209,
      "TXT_PINCODE_LOCALITY": "SIDHAR",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181212,
      "TXT_PINCODE_LOCALITY": "KOTHJHAJJAR",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181221,
      "TXT_PINCODE_LOCALITY": "NAGAROTA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181223,
      "TXT_PINCODE_LOCALITY": "TIKRI",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 6033,
      "City District Name": "UDHAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181224,
      "TXT_PINCODE_LOCALITY": "DANSAL",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181301,
      "TXT_PINCODE_LOCALITY": "SAMBA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5471,
      "City District Name": "SAMBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181515,
      "TXT_PINCODE_LOCALITY": "SINGH PURA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 4856,
      "City District Name": "NOWSHEHRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 181551,
      "TXT_PINCODE_LOCALITY": "DHANWAN KOT",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5276,
      "City District Name": "RAJAURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182101,
      "TXT_PINCODE_LOCALITY": "SHAKTI NAGAR",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 6033,
      "City District Name": "UDHAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182103,
      "TXT_PINCODE_LOCALITY": "P T S (UDM)",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 6033,
      "City District Name": "UDHAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182104,
      "TXT_PINCODE_LOCALITY": "UDHAMPUR",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 6033,
      "City District Name": "UDHAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182117,
      "TXT_PINCODE_LOCALITY": "PINGAR",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 6435,
      "City District Name": "RAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182121,
      "TXT_PINCODE_LOCALITY": "RAMBAL",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 6033,
      "City District Name": "UDHAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182122,
      "TXT_PINCODE_LOCALITY": "RAM NAGAR",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 6033,
      "City District Name": "UDHAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182123,
      "TXT_PINCODE_LOCALITY": "KIRMCHI",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 6033,
      "City District Name": "UDHAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182124,
      "TXT_PINCODE_LOCALITY": "JAGANOO",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 6033,
      "City District Name": "UDHAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182125,
      "TXT_PINCODE_LOCALITY": "MIR",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5202,
      "City District Name": "PULWAMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182126,
      "TXT_PINCODE_LOCALITY": "ROAN",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 6033,
      "City District Name": "UDHAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182127,
      "TXT_PINCODE_LOCALITY": "BATTAL",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 6033,
      "City District Name": "UDHAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182128,
      "TXT_PINCODE_LOCALITY": "MAJOURI",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182141,
      "TXT_PINCODE_LOCALITY": "CHENANI",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 6033,
      "City District Name": "UDHAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182142,
      "TXT_PINCODE_LOCALITY": "KUD",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 6033,
      "City District Name": "UDHAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182143,
      "TXT_PINCODE_LOCALITY": "BATOTE",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3045,
      "City District Name": "DODA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182144,
      "TXT_PINCODE_LOCALITY": "JAMMU S. O",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182145,
      "TXT_PINCODE_LOCALITY": "RAMSU",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3045,
      "City District Name": "DODA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182146,
      "TXT_PINCODE_LOCALITY": "BANIHAL",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3045,
      "City District Name": "DODA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182147,
      "TXT_PINCODE_LOCALITY": "DHANDAL",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3045,
      "City District Name": "DODA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182148,
      "TXT_PINCODE_LOCALITY": "KUNDI KAROL",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3045,
      "City District Name": "DODA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182160,
      "TXT_PINCODE_LOCALITY": "SUDH MAHADEV",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 6033,
      "City District Name": "UDHAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182161,
      "TXT_PINCODE_LOCALITY": "MANTALAI",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3045,
      "City District Name": "DODA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182200,
      "TXT_PINCODE_LOCALITY": "ASSAR",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3045,
      "City District Name": "DODA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182201,
      "TXT_PINCODE_LOCALITY": "PULDODA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3045,
      "City District Name": "DODA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182202,
      "TXT_PINCODE_LOCALITY": "JAMA MASJID",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3045,
      "City District Name": "DODA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182203,
      "TXT_PINCODE_LOCALITY": "THATRI",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3045,
      "City District Name": "DODA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182204,
      "TXT_PINCODE_LOCALITY": "KISHTWAR",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3045,
      "City District Name": "DODA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182205,
      "TXT_PINCODE_LOCALITY": "MEDICAL COLLEGE",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3045,
      "City District Name": "DODA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182206,
      "TXT_PINCODE_LOCALITY": "DUL HASTI PROJECT",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3045,
      "City District Name": "DODA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182210,
      "TXT_PINCODE_LOCALITY": "PULDODA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 6033,
      "City District Name": "UDHAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182211,
      "TXT_PINCODE_LOCALITY": "PALMAR",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3045,
      "City District Name": "DODA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182214,
      "TXT_PINCODE_LOCALITY": "DHANMASTA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2314,
      "City District Name": "BANIHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182215,
      "TXT_PINCODE_LOCALITY": "ARI",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5205,
      "City District Name": "PUNCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182220,
      "TXT_PINCODE_LOCALITY": "GHAT",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3045,
      "City District Name": "DODA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182221,
      "TXT_PINCODE_LOCALITY": "UDRANA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3045,
      "City District Name": "DODA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182222,
      "TXT_PINCODE_LOCALITY": "BHDERWAH",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3045,
      "City District Name": "DODA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182223,
      "TXT_PINCODE_LOCALITY": "THERA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182301,
      "TXT_PINCODE_LOCALITY": "KATRA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 6033,
      "City District Name": "UDHAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182302,
      "TXT_PINCODE_LOCALITY": "VAISHNO DEVI",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 6033,
      "City District Name": "UDHAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182311,
      "TXT_PINCODE_LOCALITY": "REASI",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 6033,
      "City District Name": "UDHAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182312,
      "TXT_PINCODE_LOCALITY": "JYOTIPURAM",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 6033,
      "City District Name": "UDHAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182313,
      "TXT_PINCODE_LOCALITY": "ARNAS",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 6033,
      "City District Name": "UDHAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182314,
      "TXT_PINCODE_LOCALITY": "PAVANPURAM",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 6033,
      "City District Name": "UDHAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182315,
      "TXT_PINCODE_LOCALITY": "MAHORE",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2057,
      "City District Name": "ANANTNAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182320,
      "TXT_PINCODE_LOCALITY": "TALWARA COLONEY",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 6033,
      "City District Name": "UDHAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182321,
      "TXT_PINCODE_LOCALITY": "AGARJITTO",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 6033,
      "City District Name": "UDHAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182355,
      "TXT_PINCODE_LOCALITY": "ANGRALLA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 6033,
      "City District Name": "UDHAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 182443,
      "TXT_PINCODE_LOCALITY": "PACHOT",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2734,
      "City District Name": "CHENANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 183132,
      "TXT_PINCODE_LOCALITY": "NAGROTA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5276,
      "City District Name": "RAJAURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 184101,
      "TXT_PINCODE_LOCALITY": "KRISHEN NAGAR",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3848,
      "City District Name": "KATHUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 184102,
      "TXT_PINCODE_LOCALITY": "KATHUA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3848,
      "City District Name": "KATHUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 184103,
      "TXT_PINCODE_LOCALITY": "JANGLOT",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3848,
      "City District Name": "KATHUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 184104,
      "TXT_PINCODE_LOCALITY": "KATHUA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3848,
      "City District Name": "KATHUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 184111,
      "TXT_PINCODE_LOCALITY": "PARTHAIL SALARIAN (UN-INHABITED)",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5471,
      "City District Name": "SAMBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 184120,
      "TXT_PINCODE_LOCALITY": "VIJAY PUR",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 184121,
      "TXT_PINCODE_LOCALITY": "SAMBA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 184140,
      "TXT_PINCODE_LOCALITY": "CHAKRA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3848,
      "City District Name": "KATHUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 184141,
      "TXT_PINCODE_LOCALITY": "GHAGWAL",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3848,
      "City District Name": "KATHUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 184142,
      "TXT_PINCODE_LOCALITY": "HIRA NAGAR",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3848,
      "City District Name": "KATHUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 184143,
      "TXT_PINCODE_LOCALITY": "BARWAL",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3848,
      "City District Name": "KATHUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 184144,
      "TXT_PINCODE_LOCALITY": "DIALACHAK",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3848,
      "City District Name": "KATHUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 184145,
      "TXT_PINCODE_LOCALITY": "RAJPORA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 184146,
      "TXT_PINCODE_LOCALITY": "DINGAM AMB",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3848,
      "City District Name": "KATHUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 184147,
      "TXT_PINCODE_LOCALITY": "CHHAN RORIAN",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3848,
      "City District Name": "KATHUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 184148,
      "TXT_PINCODE_LOCALITY": "SANJIMORE",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3848,
      "City District Name": "KATHUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 184151,
      "TXT_PINCODE_LOCALITY": "PAROLE",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3848,
      "City District Name": "KATHUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 184152,
      "TXT_PINCODE_LOCALITY": "LAKHANPUR",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3848,
      "City District Name": "KATHUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 184155,
      "TXT_PINCODE_LOCALITY": "QILA DARHAL",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 4856,
      "City District Name": "NOWSHEHRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 184201,
      "TXT_PINCODE_LOCALITY": "BASOLI",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3848,
      "City District Name": "KATHUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 184202,
      "TXT_PINCODE_LOCALITY": "MAHANPUR",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3848,
      "City District Name": "KATHUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 184203,
      "TXT_PINCODE_LOCALITY": "BHADDU",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3848,
      "City District Name": "KATHUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 184204,
      "TXT_PINCODE_LOCALITY": "BILLIWAR",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3848,
      "City District Name": "KATHUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 184205,
      "TXT_PINCODE_LOCALITY": "RAMKOT",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3848,
      "City District Name": "KATHUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 184206,
      "TXT_PINCODE_LOCALITY": "BANE",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3848,
      "City District Name": "KATHUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 184207,
      "TXT_PINCODE_LOCALITY": "KHOG",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3848,
      "City District Name": "KATHUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 184208,
      "TXT_PINCODE_LOCALITY": "BHOOND",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3848,
      "City District Name": "KATHUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 184210,
      "TXT_PINCODE_LOCALITY": "UDHAMPUR",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 6033,
      "City District Name": "UDHAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 184843,
      "TXT_PINCODE_LOCALITY": "TARF BALA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3848,
      "City District Name": "KATHUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 185101,
      "TXT_PINCODE_LOCALITY": "POONCH FORT",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1896,
      "City District Name": "POONCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 185102,
      "TXT_PINCODE_LOCALITY": "KALA KOT",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5205,
      "City District Name": "PUNCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 185103,
      "TXT_PINCODE_LOCALITY": "DHOKRI",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 442713,
      "City District Name": "HAVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 185105,
      "TXT_PINCODE_LOCALITY": "SALIAN",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 442713,
      "City District Name": "HAVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 185106,
      "TXT_PINCODE_LOCALITY": "DARA DULLIAN",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 442713,
      "City District Name": "HAVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 185107,
      "TXT_PINCODE_LOCALITY": "KALAI",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 442713,
      "City District Name": "HAVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 185111,
      "TXT_PINCODE_LOCALITY": "ARI",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5205,
      "City District Name": "PUNCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 185120,
      "TXT_PINCODE_LOCALITY": "MANDI",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5205,
      "City District Name": "PUNCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 185121,
      "TXT_PINCODE_LOCALITY": "SAMOTE",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5205,
      "City District Name": "PUNCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 185122,
      "TXT_PINCODE_LOCALITY": "DRABA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5205,
      "City District Name": "PUNCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 185123,
      "TXT_PINCODE_LOCALITY": "LORAN",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5276,
      "City District Name": "RAJAURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 185131,
      "TXT_PINCODE_LOCALITY": "RAJOURI H O",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5276,
      "City District Name": "RAJAURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 185132,
      "TXT_PINCODE_LOCALITY": "JAWAHIR NAGAR",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5276,
      "City District Name": "RAJAURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 185133,
      "TXT_PINCODE_LOCALITY": "BUDHAL",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5276,
      "City District Name": "RAJAURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 185134,
      "TXT_PINCODE_LOCALITY": "CHOWKICHOWRA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5276,
      "City District Name": "RAJAURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 185135,
      "TXT_PINCODE_LOCALITY": "DURHAT MALKAN",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5276,
      "City District Name": "RAJAURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 185136,
      "TXT_PINCODE_LOCALITY": "KETRANKA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5276,
      "City District Name": "RAJAURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 185151,
      "TXT_PINCODE_LOCALITY": "NAOSHEHRA(JT)",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5276,
      "City District Name": "RAJAURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 185152,
      "TXT_PINCODE_LOCALITY": "LAMBERI",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5276,
      "City District Name": "RAJAURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 185153,
      "TXT_PINCODE_LOCALITY": "SUNDERBANI",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5276,
      "City District Name": "RAJAURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 185154,
      "TXT_PINCODE_LOCALITY": "DOORI DUGAR",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 185155,
      "TXT_PINCODE_LOCALITY": "RAJOURI",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5276,
      "City District Name": "RAJAURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 185156,
      "TXT_PINCODE_LOCALITY": "KANGRI",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5276,
      "City District Name": "RAJAURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 185157,
      "TXT_PINCODE_LOCALITY": "THANDAPANI",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5276,
      "City District Name": "RAJAURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 185158,
      "TXT_PINCODE_LOCALITY": "RAJOURI",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5276,
      "City District Name": "RAJAURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 185159,
      "TXT_PINCODE_LOCALITY": "SEERI",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3045,
      "City District Name": "DODA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 185200,
      "TXT_PINCODE_LOCALITY": "PERI",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 442714,
      "City District Name": "KALAKOTE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 185201,
      "TXT_PINCODE_LOCALITY": "SOLKI",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5276,
      "City District Name": "RAJAURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 185202,
      "TXT_PINCODE_LOCALITY": "KALAKOT",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5276,
      "City District Name": "RAJAURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 185203,
      "TXT_PINCODE_LOCALITY": "PONI",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 6033,
      "City District Name": "UDHAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 185210,
      "TXT_PINCODE_LOCALITY": "DEOLI",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5795,
      "City District Name": "SUNDERBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 185211,
      "TXT_PINCODE_LOCALITY": "MENDHAR",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5205,
      "City District Name": "PUNCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 185212,
      "TXT_PINCODE_LOCALITY": "THANA MANDI",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5276,
      "City District Name": "RAJAURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 185213,
      "TXT_PINCODE_LOCALITY": "BAROTE",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3045,
      "City District Name": "DODA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 185214,
      "TXT_PINCODE_LOCALITY": "HARNI",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5205,
      "City District Name": "PUNCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 185233,
      "TXT_PINCODE_LOCALITY": "DARAJ",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5276,
      "City District Name": "RAJAURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 185234,
      "TXT_PINCODE_LOCALITY": "KOT DHRA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5276,
      "City District Name": "RAJAURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 185250,
      "TXT_PINCODE_LOCALITY": "GAHWAN",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 442714,
      "City District Name": "KALAKOTE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 190001,
      "TXT_PINCODE_LOCALITY": "T R CENTRE",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1883,
      "City District Name": "SRINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 190002,
      "TXT_PINCODE_LOCALITY": "FATEH KADAL",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1883,
      "City District Name": "SRINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 190003,
      "TXT_PINCODE_LOCALITY": "KHANYAR",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1883,
      "City District Name": "SRINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 190004,
      "TXT_PINCODE_LOCALITY": "BATWARA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1883,
      "City District Name": "SRINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 190005,
      "TXT_PINCODE_LOCALITY": "SANTNAGAR",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1883,
      "City District Name": "SRINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 190006,
      "TXT_PINCODE_LOCALITY": "NASEEM BAGH",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1883,
      "City District Name": "SRINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 190007,
      "TXT_PINCODE_LOCALITY": "SRINAGAR AIRPORT",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1883,
      "City District Name": "SRINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 190008,
      "TXT_PINCODE_LOCALITY": "SRINAGAR",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1883,
      "City District Name": "SRINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 190009,
      "TXT_PINCODE_LOCALITY": "RAMBAGH",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1883,
      "City District Name": "SRINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 190010,
      "TXT_PINCODE_LOCALITY": "KASTIGARH",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1883,
      "City District Name": "SRINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 190011,
      "TXT_PINCODE_LOCALITY": "NAOSHERA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1883,
      "City District Name": "SRINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 190012,
      "TXT_PINCODE_LOCALITY": "ZAINAKOT",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1883,
      "City District Name": "SRINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 190014,
      "TXT_PINCODE_LOCALITY": "HYDERPURA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1883,
      "City District Name": "SRINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 190015,
      "TXT_PINCODE_LOCALITY": "NATIPORA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1883,
      "City District Name": "SRINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 190016,
      "TXT_PINCODE_LOCALITY": "PANDECH",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1883,
      "City District Name": "SRINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 190017,
      "TXT_PINCODE_LOCALITY": "PARIMPORA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1883,
      "City District Name": "SRINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 190018,
      "TXT_PINCODE_LOCALITY": "BEMINA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1883,
      "City District Name": "SRINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 190019,
      "TXT_PINCODE_LOCALITY": "BAGH MEHTAB.",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1883,
      "City District Name": "SRINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 190020,
      "TXT_PINCODE_LOCALITY": "BACHPORA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1883,
      "City District Name": "SRINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 190021,
      "TXT_PINCODE_LOCALITY": "KITAR DAJI",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 442720,
      "City District Name": "RAFIABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 190023,
      "TXT_PINCODE_LOCALITY": "LAL BAZAR",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1883,
      "City District Name": "SRINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 190024,
      "TXT_PINCODE_LOCALITY": "RAKH HARRAN",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3176,
      "City District Name": "GANDERBAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 190025,
      "TXT_PINCODE_LOCALITY": "SKUAST S.O",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1883,
      "City District Name": "SRINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 191101,
      "TXT_PINCODE_LOCALITY": "PAROLE",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1883,
      "City District Name": "SRINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 191102,
      "TXT_PINCODE_LOCALITY": "WUYAN",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1883,
      "City District Name": "SRINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 191103,
      "TXT_PINCODE_LOCALITY": "KHREW",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5202,
      "City District Name": "PULWAMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 191104,
      "TXT_PINCODE_LOCALITY": "KHUNMOH",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1883,
      "City District Name": "SRINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 191111,
      "TXT_PINCODE_LOCALITY": "BADGAM",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2208,
      "City District Name": "BADGAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 191112,
      "TXT_PINCODE_LOCALITY": "CHARARI SHARIF",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2208,
      "City District Name": "BADGAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 191113,
      "TXT_PINCODE_LOCALITY": "CHADOORA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2208,
      "City District Name": "BADGAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 191114,
      "TXT_PINCODE_LOCALITY": "KRALPORA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 4164,
      "City District Name": "KUPWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 191115,
      "TXT_PINCODE_LOCALITY": "WATHORA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2208,
      "City District Name": "BADGAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 191116,
      "TXT_PINCODE_LOCALITY": "ICHGAM",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2208,
      "City District Name": "BADGAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 191121,
      "TXT_PINCODE_LOCALITY": "BREIN",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2208,
      "City District Name": "BADGAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 191123,
      "TXT_PINCODE_LOCALITY": "HARWAN",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1883,
      "City District Name": "SRINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 191131,
      "TXT_PINCODE_LOCALITY": "TULLMULLA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1883,
      "City District Name": "SRINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 191132,
      "TXT_PINCODE_LOCALITY": "WAZBAGH",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 442708,
      "City District Name": "BUDGAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 191141,
      "TXT_PINCODE_LOCALITY": "PALPORA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2208,
      "City District Name": "BADGAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 191201,
      "TXT_PINCODE_LOCALITY": "GANDERBAL",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1883,
      "City District Name": "SRINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 191202,
      "TXT_PINCODE_LOCALITY": "KANGAN",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5202,
      "City District Name": "PULWAMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 191203,
      "TXT_PINCODE_LOCALITY": "SONAWARI",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1883,
      "City District Name": "SRINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 191204,
      "TXT_PINCODE_LOCALITY": "NUNER",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1883,
      "City District Name": "SRINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 191205,
      "TXT_PINCODE_LOCALITY": "DODAR HAMA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1883,
      "City District Name": "SRINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 191515,
      "TXT_PINCODE_LOCALITY": "KHUTHI PORA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 442709,
      "City District Name": "CHADOORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192038,
      "TXT_PINCODE_LOCALITY": "SHINDOO KULPORA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 4135,
      "City District Name": "KULGAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192101,
      "TXT_PINCODE_LOCALITY": "ANANTNAG H O",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2057,
      "City District Name": "ANANTNAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192102,
      "TXT_PINCODE_LOCALITY": "KHANABAI",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2057,
      "City District Name": "ANANTNAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192120,
      "TXT_PINCODE_LOCALITY": "BONA NAMBAL",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2057,
      "City District Name": "ANANTNAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192121,
      "TXT_PINCODE_LOCALITY": "PAMPORA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5202,
      "City District Name": "PULWAMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192122,
      "TXT_PINCODE_LOCALITY": "AWANTIPORA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5202,
      "City District Name": "PULWAMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192123,
      "TXT_PINCODE_LOCALITY": "TRAL",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5202,
      "City District Name": "PULWAMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192124,
      "TXT_PINCODE_LOCALITY": "BIJBEHARA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2057,
      "City District Name": "ANANTNAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192125,
      "TXT_PINCODE_LOCALITY": "MARTAND",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2057,
      "City District Name": "ANANTNAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192126,
      "TXT_PINCODE_LOCALITY": "PANCHTARNI",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2057,
      "City District Name": "ANANTNAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192127,
      "TXT_PINCODE_LOCALITY": "AYSHMUQAM",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2057,
      "City District Name": "ANANTNAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192128,
      "TXT_PINCODE_LOCALITY": "NANIL(ANG)",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2057,
      "City District Name": "ANANTNAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192129,
      "TXT_PINCODE_LOCALITY": "SEER KANILGUND",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2057,
      "City District Name": "ANANTNAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192141,
      "TXT_PINCODE_LOCALITY": "BATAGUND",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2057,
      "City District Name": "ANANTNAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192142,
      "TXT_PINCODE_LOCALITY": "MANDURA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5202,
      "City District Name": "PULWAMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192143,
      "TXT_PINCODE_LOCALITY": "DAMHAL",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2057,
      "City District Name": "ANANTNAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192144,
      "TXT_PINCODE_LOCALITY": "GAIRO",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5202,
      "City District Name": "PULWAMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192145,
      "TXT_PINCODE_LOCALITY": "GOURIPORA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2057,
      "City District Name": "ANANTNAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192201,
      "TXT_PINCODE_LOCALITY": "ACHABAL",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2057,
      "City District Name": "ANANTNAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192202,
      "TXT_PINCODE_LOCALITY": "KOKAR NAG",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2057,
      "City District Name": "ANANTNAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192203,
      "TXT_PINCODE_LOCALITY": "SAGAM",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2057,
      "City District Name": "ANANTNAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192204,
      "TXT_PINCODE_LOCALITY": "AKINGAM",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2057,
      "City District Name": "ANANTNAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192210,
      "TXT_PINCODE_LOCALITY": "DAILGAM",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2057,
      "City District Name": "ANANTNAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192211,
      "TXT_PINCODE_LOCALITY": "DORU",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2057,
      "City District Name": "ANANTNAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192212,
      "TXT_PINCODE_LOCALITY": "VARINAG",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2057,
      "City District Name": "ANANTNAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192213,
      "TXT_PINCODE_LOCALITY": "LARKIPORA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2339,
      "City District Name": "BARAMULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192214,
      "TXT_PINCODE_LOCALITY": "FATEHPURA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2057,
      "City District Name": "ANANTNAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192219,
      "TXT_PINCODE_LOCALITY": "SHANGRIN",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 442712,
      "City District Name": "DOORU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192221,
      "TXT_PINCODE_LOCALITY": "QAZIGUND",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2057,
      "City District Name": "ANANTNAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192223,
      "TXT_PINCODE_LOCALITY": "AHEABAL",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2057,
      "City District Name": "ANANTNAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192226,
      "TXT_PINCODE_LOCALITY": "NEGRIMAL PORA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 4164,
      "City District Name": "KUPWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192230,
      "TXT_PINCODE_LOCALITY": "QAIMOH",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2057,
      "City District Name": "ANANTNAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192231,
      "TXT_PINCODE_LOCALITY": "KULGAM",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2057,
      "City District Name": "ANANTNAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192232,
      "TXT_PINCODE_LOCALITY": "YARIPORA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2057,
      "City District Name": "ANANTNAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192233,
      "TXT_PINCODE_LOCALITY": "HANJIPORA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2057,
      "City District Name": "ANANTNAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192234,
      "TXT_PINCODE_LOCALITY": "HANAD CHOWLGAM",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2057,
      "City District Name": "ANANTNAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192301,
      "TXT_PINCODE_LOCALITY": "PULWAMA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5202,
      "City District Name": "PULWAMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192302,
      "TXT_PINCODE_LOCALITY": "BALPORA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5202,
      "City District Name": "PULWAMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192303,
      "TXT_PINCODE_LOCALITY": "SHOPIAN",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5202,
      "City District Name": "PULWAMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192304,
      "TXT_PINCODE_LOCALITY": "KARALPORA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2057,
      "City District Name": "ANANTNAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192305,
      "TXT_PINCODE_LOCALITY": "ACHAN",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5202,
      "City District Name": "PULWAMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192306,
      "TXT_PINCODE_LOCALITY": "MALANG PORA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5202,
      "City District Name": "PULWAMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192307,
      "TXT_PINCODE_LOCALITY": "PINGLINA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5202,
      "City District Name": "PULWAMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192308,
      "TXT_PINCODE_LOCALITY": "SHADIPORA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2339,
      "City District Name": "BARAMULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192309,
      "TXT_PINCODE_LOCALITY": "RATNIPORA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5202,
      "City District Name": "PULWAMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192331,
      "TXT_PINCODE_LOCALITY": "SHUPIYAN.",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 442144,
      "City District Name": "SHOPIAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192401,
      "TXT_PINCODE_LOCALITY": "SRIGOFARA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2057,
      "City District Name": "ANANTNAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192402,
      "TXT_PINCODE_LOCALITY": "SALLAR",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2057,
      "City District Name": "ANANTNAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192403,
      "TXT_PINCODE_LOCALITY": "VEERI",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2339,
      "City District Name": "BARAMULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 192404,
      "TXT_PINCODE_LOCALITY": "KAMILWAN",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2057,
      "City District Name": "ANANTNAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193101,
      "TXT_PINCODE_LOCALITY": "BARAMULLA H O",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2339,
      "City District Name": "BARAMULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193102,
      "TXT_PINCODE_LOCALITY": "RAJ GHAT",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2339,
      "City District Name": "BARAMULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193103,
      "TXT_PINCODE_LOCALITY": "KHERI",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2339,
      "City District Name": "BARAMULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193105,
      "TXT_PINCODE_LOCALITY": "SINGH PORA KALAN",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2339,
      "City District Name": "BARAMULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193106,
      "TXT_PINCODE_LOCALITY": "DELINA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2339,
      "City District Name": "BARAMULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193107,
      "TXT_PINCODE_LOCALITY": "USHKARA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2339,
      "City District Name": "BARAMULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193108,
      "TXT_PINCODE_LOCALITY": "KHAN PORA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 4164,
      "City District Name": "KUPWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193109,
      "TXT_PINCODE_LOCALITY": "WAGOORA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2057,
      "City District Name": "ANANTNAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193121,
      "TXT_PINCODE_LOCALITY": "PATTAN",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2339,
      "City District Name": "BARAMULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193122,
      "TXT_PINCODE_LOCALITY": "MOHORA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2339,
      "City District Name": "BARAMULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193123,
      "TXT_PINCODE_LOCALITY": "URI",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2339,
      "City District Name": "BARAMULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193124,
      "TXT_PINCODE_LOCALITY": "GANTAMULLA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2339,
      "City District Name": "BARAMULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193125,
      "TXT_PINCODE_LOCALITY": "NOWSHERA (BLA)",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2339,
      "City District Name": "BARAMULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193130,
      "TXT_PINCODE_LOCALITY": "PALHALAN",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2339,
      "City District Name": "BARAMULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193131,
      "TXT_PINCODE_LOCALITY": "HAIGAM",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2339,
      "City District Name": "BARAMULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193132,
      "TXT_PINCODE_LOCALITY": "SANGRAMA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2339,
      "City District Name": "BARAMULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193198,
      "TXT_PINCODE_LOCALITY": "KRERI",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2339,
      "City District Name": "BARAMULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193201,
      "TXT_PINCODE_LOCALITY": "SOPORE",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2339,
      "City District Name": "BARAMULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193202,
      "TXT_PINCODE_LOCALITY": "METSOPORE",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2339,
      "City District Name": "BARAMULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193208,
      "TXT_PINCODE_LOCALITY": "HADIPORA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2339,
      "City District Name": "BARAMULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193209,
      "TXT_PINCODE_LOCALITY": "DOABGAH",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2339,
      "City District Name": "BARAMULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193211,
      "TXT_PINCODE_LOCALITY": "SEER JAGIR",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2339,
      "City District Name": "BARAMULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193212,
      "TXT_PINCODE_LOCALITY": "DANGERPORA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2339,
      "City District Name": "BARAMULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193213,
      "TXT_PINCODE_LOCALITY": "WAHI GUND",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5052,
      "City District Name": "PATTAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193220,
      "TXT_PINCODE_LOCALITY": "BOMAI",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2339,
      "City District Name": "BARAMULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193221,
      "TXT_PINCODE_LOCALITY": "HANDWARA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 4164,
      "City District Name": "KUPWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193222,
      "TXT_PINCODE_LOCALITY": "KUPWARA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 4164,
      "City District Name": "KUPWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193223,
      "TXT_PINCODE_LOCALITY": "SOGM",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2208,
      "City District Name": "BADGAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193224,
      "TXT_PINCODE_LOCALITY": "KRALAPORA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 4164,
      "City District Name": "KUPWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193225,
      "TXT_PINCODE_LOCALITY": "TANGDHAR",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2339,
      "City District Name": "BARAMULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193227,
      "TXT_PINCODE_LOCALITY": "ZACHALDARA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 4164,
      "City District Name": "KUPWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193228,
      "TXT_PINCODE_LOCALITY": "MAGAM HANDWARA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 4164,
      "City District Name": "KUPWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193229,
      "TXT_PINCODE_LOCALITY": "MAIDAN CHOWGAL",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 4164,
      "City District Name": "KUPWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193230,
      "TXT_PINCODE_LOCALITY": "MAGAM(BLA)",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2208,
      "City District Name": "BADGAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193301,
      "TXT_PINCODE_LOCALITY": "ROHAMA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2339,
      "City District Name": "BARAMULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193302,
      "TXT_PINCODE_LOCALITY": "HAWAL",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2339,
      "City District Name": "BARAMULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193303,
      "TXT_PINCODE_LOCALITY": "RESHI PORA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3360,
      "City District Name": "HANDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193306,
      "TXT_PINCODE_LOCALITY": "PULWAMA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5202,
      "City District Name": "PULWAMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193401,
      "TXT_PINCODE_LOCALITY": "MAGAM",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2339,
      "City District Name": "BARAMULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193402,
      "TXT_PINCODE_LOCALITY": "TANG MARG",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2057,
      "City District Name": "ANANTNAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193403,
      "TXT_PINCODE_LOCALITY": "GULMARG",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2339,
      "City District Name": "BARAMULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193404,
      "TXT_PINCODE_LOCALITY": "KUNZER",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2339,
      "City District Name": "BARAMULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193405,
      "TXT_PINCODE_LOCALITY": "NARBAL",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2339,
      "City District Name": "BARAMULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193411,
      "TXT_PINCODE_LOCALITY": "BEERWAH",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2208,
      "City District Name": "BADGAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193441,
      "TXT_PINCODE_LOCALITY": "SHULI GUND",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2404,
      "City District Name": "BEERWAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193501,
      "TXT_PINCODE_LOCALITY": "SUMBAL",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2339,
      "City District Name": "BARAMULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193502,
      "TXT_PINCODE_LOCALITY": "BANDIPORA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2208,
      "City District Name": "BADGAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193503,
      "TXT_PINCODE_LOCALITY": "GUREZ",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2339,
      "City District Name": "BARAMULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193504,
      "TXT_PINCODE_LOCALITY": "SAFAPORA P.O",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2305,
      "City District Name": "BANDIPORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193505,
      "TXT_PINCODE_LOCALITY": "AJAS",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2339,
      "City District Name": "BARAMULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193506,
      "TXT_PINCODE_LOCALITY": "KANDOLI",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2339,
      "City District Name": "BARAMULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193507,
      "TXT_PINCODE_LOCALITY": "NADIHAL",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2208,
      "City District Name": "BADGAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193508,
      "TXT_PINCODE_LOCALITY": "ALUSA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2339,
      "City District Name": "BARAMULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193509,
      "TXT_PINCODE_LOCALITY": "HAJAN",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2339,
      "City District Name": "BARAMULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 193510,
      "TXT_PINCODE_LOCALITY": "ASHTINGA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2339,
      "City District Name": "BARAMULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 194101,
      "TXT_PINCODE_LOCALITY": "LEH H O",
      "NUM_STATE_CD": 909,
      "State Name": "LADAKH",
      "NUM_CITYDISTRICT_CD": 4237,
      "City District Name": "LEH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 194102,
      "TXT_PINCODE_LOCALITY": "DRASS",
      "NUM_STATE_CD": 909,
      "State Name": "LADAKH",
      "NUM_CITYDISTRICT_CD": 3805,
      "City District Name": "KARGIL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 194103,
      "TXT_PINCODE_LOCALITY": "AKCHAMAL B.O",
      "NUM_STATE_CD": 909,
      "State Name": "LADAKH",
      "NUM_CITYDISTRICT_CD": 444160,
      "City District Name": "KARGIL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 194104,
      "TXT_PINCODE_LOCALITY": "CHOGLAMSAR",
      "NUM_STATE_CD": 909,
      "State Name": "LADAKH",
      "NUM_CITYDISTRICT_CD": 4237,
      "City District Name": "LEH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 194105,
      "TXT_PINCODE_LOCALITY": "BAROO",
      "NUM_STATE_CD": 909,
      "State Name": "LADAKH",
      "NUM_CITYDISTRICT_CD": 3805,
      "City District Name": "KARGIL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 194106,
      "TXT_PINCODE_LOCALITY": "ALCHI B.O",
      "NUM_STATE_CD": 909,
      "State Name": "LADAKH",
      "NUM_CITYDISTRICT_CD": 444161,
      "City District Name": "LEH.",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 194109,
      "TXT_PINCODE_LOCALITY": "MULBITH",
      "NUM_STATE_CD": 909,
      "State Name": "LADAKH",
      "NUM_CITYDISTRICT_CD": 4237,
      "City District Name": "LEH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 194111,
      "TXT_PINCODE_LOCALITY": "LADAKH",
      "NUM_STATE_CD": 909,
      "State Name": "LADAKH",
      "NUM_CITYDISTRICT_CD": 444161,
      "City District Name": "LEH.",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 194201,
      "TXT_PINCODE_LOCALITY": "THIKSAY",
      "NUM_STATE_CD": 909,
      "State Name": "LADAKH",
      "NUM_CITYDISTRICT_CD": 4237,
      "City District Name": "LEH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 194301,
      "TXT_PINCODE_LOCALITY": "SANKOO",
      "NUM_STATE_CD": 909,
      "State Name": "LADAKH",
      "NUM_CITYDISTRICT_CD": 4237,
      "City District Name": "LEH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 194302,
      "TXT_PINCODE_LOCALITY": "PADAM ZANSKAR",
      "NUM_STATE_CD": 909,
      "State Name": "LADAKH",
      "NUM_CITYDISTRICT_CD": 3805,
      "City District Name": "KARGIL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 194401,
      "TXT_PINCODE_LOCALITY": "DISKIT",
      "NUM_STATE_CD": 909,
      "State Name": "LADAKH",
      "NUM_CITYDISTRICT_CD": 4237,
      "City District Name": "LEH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 194402,
      "TXT_PINCODE_LOCALITY": "PHYANG",
      "NUM_STATE_CD": 909,
      "State Name": "LADAKH",
      "NUM_CITYDISTRICT_CD": 4237,
      "City District Name": "LEH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 194404,
      "TXT_PINCODE_LOCALITY": "SHUROK",
      "NUM_STATE_CD": 909,
      "State Name": "LADAKH",
      "NUM_CITYDISTRICT_CD": 4237,
      "City District Name": "LEH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 194703,
      "TXT_PINCODE_LOCALITY": "UMBA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 442721,
      "City District Name": "SANKU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 199533,
      "TXT_PINCODE_LOCALITY": "BANDER PORA CHERAT",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5202,
      "City District Name": "PULWAMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 200031,
      "TXT_PINCODE_LOCALITY": "NOIDA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 351,
      "City District Name": "NOIDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 200125,
      "TXT_PINCODE_LOCALITY": "KANPUR TEMPLE",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 200201,
      "TXT_PINCODE_LOCALITY": "SITAPUR S. O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1388,
      "City District Name": "SITAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 200231,
      "TXT_PINCODE_LOCALITY": "SEC-62",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 351,
      "City District Name": "NOIDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201000,
      "TXT_PINCODE_LOCALITY": "BULAND SHAHAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 425576,
      "City District Name": "BULANDSHAHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201001,
      "TXT_PINCODE_LOCALITY": "GREATER NOIDA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 425619,
      "City District Name": "GREATER NOIDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201002,
      "TXT_PINCODE_LOCALITY": "KAVINAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1861,
      "City District Name": "GHAZIABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201003,
      "TXT_PINCODE_LOCALITY": "MEERUT ROAD (GHAZIABAD)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1861,
      "City District Name": "GHAZIABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201004,
      "TXT_PINCODE_LOCALITY": "HINDON AIR FIELD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1861,
      "City District Name": "GHAZIABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201005,
      "TXT_PINCODE_LOCALITY": "SAHIBABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1861,
      "City District Name": "GHAZIABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201006,
      "TXT_PINCODE_LOCALITY": "CHIKAMBERPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1861,
      "City District Name": "GHAZIABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201007,
      "TXT_PINCODE_LOCALITY": "SAHIBABAD INDUSTRIAL AREA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1861,
      "City District Name": "GHAZIABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201008,
      "TXT_PINCODE_LOCALITY": "VIDYUT NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1086,
      "City District Name": "GAUTAM BUDH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201009,
      "TXT_PINCODE_LOCALITY": "DASNA GATE",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1861,
      "City District Name": "GHAZIABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201010,
      "TXT_PINCODE_LOCALITY": "VAISHALI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1861,
      "City District Name": "GHAZIABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201011,
      "TXT_PINCODE_LOCALITY": "GHAZIABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1861,
      "City District Name": "GHAZIABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201012,
      "TXT_PINCODE_LOCALITY": "AMRIT NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1861,
      "City District Name": "GHAZIABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201013,
      "TXT_PINCODE_LOCALITY": "GOVINDPURAM",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1861,
      "City District Name": "GHAZIABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201014,
      "TXT_PINCODE_LOCALITY": "NOIDA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1086,
      "City District Name": "GAUTAM BUDH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201015,
      "TXT_PINCODE_LOCALITY": "GHAZIABAD.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1861,
      "City District Name": "GHAZIABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201016,
      "TXT_PINCODE_LOCALITY": "CROSSING REPUBLIK",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1861,
      "City District Name": "GHAZIABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201017,
      "TXT_PINCODE_LOCALITY": "RAJ NAGAR EXTENSION",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1861,
      "City District Name": "GHAZIABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201018,
      "TXT_PINCODE_LOCALITY": "GHAZIABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1861,
      "City District Name": "GHAZIABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201019,
      "TXT_PINCODE_LOCALITY": "KAUSHAMBI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1861,
      "City District Name": "GHAZIABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201020,
      "TXT_PINCODE_LOCALITY": "GHAZIABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1861,
      "City District Name": "GHAZIABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201030,
      "TXT_PINCODE_LOCALITY": "GHAZIABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1861,
      "City District Name": "GHAZIABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201101,
      "TXT_PINCODE_LOCALITY": "VAISHALI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1861,
      "City District Name": "GHAZIABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201102,
      "TXT_PINCODE_LOCALITY": "LONI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1861,
      "City District Name": "GHAZIABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201103,
      "TXT_PINCODE_LOCALITY": "SECTOR B4",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1861,
      "City District Name": "GHAZIABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201109,
      "TXT_PINCODE_LOCALITY": "GHAZIABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1861,
      "City District Name": "GHAZIABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201165,
      "TXT_PINCODE_LOCALITY": "UNTASANI BANGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3907,
      "City District Name": "KHAIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201201,
      "TXT_PINCODE_LOCALITY": "HAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1861,
      "City District Name": "GHAZIABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201202,
      "TXT_PINCODE_LOCALITY": "RORI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1628,
      "City District Name": "MODINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201204,
      "TXT_PINCODE_LOCALITY": "MODINAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1861,
      "City District Name": "GHAZIABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201205,
      "TXT_PINCODE_LOCALITY": "MOHIUDDINPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201206,
      "TXT_PINCODE_LOCALITY": "MURADNAGAR TOWN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1861,
      "City District Name": "GHAZIABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201243,
      "TXT_PINCODE_LOCALITY": "USMANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5047,
      "City District Name": "PATIYALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201252,
      "TXT_PINCODE_LOCALITY": "SAMAIN.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2542,
      "City District Name": "BIDHUNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201301,
      "TXT_PINCODE_LOCALITY": "NOIDA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 351,
      "City District Name": "NOIDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201302,
      "TXT_PINCODE_LOCALITY": "GHAZIABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1861,
      "City District Name": "GHAZIABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201303,
      "TXT_PINCODE_LOCALITY": "SECTOR-37 NOIDA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 351,
      "City District Name": "NOIDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201304,
      "TXT_PINCODE_LOCALITY": "NOIDA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 351,
      "City District Name": "NOIDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201305,
      "TXT_PINCODE_LOCALITY": "SECTOR 20",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1086,
      "City District Name": "GAUTAM BUDH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201306,
      "TXT_PINCODE_LOCALITY": "SURAJPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1086,
      "City District Name": "GAUTAM BUDH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201307,
      "TXT_PINCODE_LOCALITY": "NOIDA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1086,
      "City District Name": "GAUTAM BUDH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201308,
      "TXT_PINCODE_LOCALITY": "GAUTAM BUDH NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1086,
      "City District Name": "GAUTAM BUDH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201309,
      "TXT_PINCODE_LOCALITY": "SIXTH AVENUE, GAUR CITY 1",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 351,
      "City District Name": "NOIDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201310,
      "TXT_PINCODE_LOCALITY": "ALPHA GREATER NOIDA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1086,
      "City District Name": "GAUTAM BUDH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201311,
      "TXT_PINCODE_LOCALITY": "CONTAINER DEPOT S.O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1086,
      "City District Name": "GAUTAM BUDH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201312,
      "TXT_PINCODE_LOCALITY": "G B NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1086,
      "City District Name": "GAUTAM BUDH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201313,
      "TXT_PINCODE_LOCALITY": "CHHAPROLA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1861,
      "City District Name": "GHAZIABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201314,
      "TXT_PINCODE_LOCALITY": "CHITHARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 951,
      "City District Name": "DADRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201315,
      "TXT_PINCODE_LOCALITY": "GAUTHAM BUDDHA NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1086,
      "City District Name": "GAUTAM BUDH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201316,
      "TXT_PINCODE_LOCALITY": "GAUTAM BUDDH NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1086,
      "City District Name": "GAUTAM BUDH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201317,
      "TXT_PINCODE_LOCALITY": "GAUTAM BUDDHA NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1086,
      "City District Name": "GAUTAM BUDH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201318,
      "TXT_PINCODE_LOCALITY": "NOIDA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1086,
      "City District Name": "GAUTAM BUDH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201505,
      "TXT_PINCODE_LOCALITY": "SAKHINI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3554,
      "City District Name": "JASRANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201545,
      "TXT_PINCODE_LOCALITY": "SHIRMAI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3554,
      "City District Name": "JASRANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201619,
      "TXT_PINCODE_LOCALITY": "GAGANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2154,
      "City District Name": "AURAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201622,
      "TXT_PINCODE_LOCALITY": "BHAGAUTIPUR AURAIYA.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2154,
      "City District Name": "AURAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201712,
      "TXT_PINCODE_LOCALITY": "TILSAI KHURD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3834,
      "City District Name": "KASGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 201861,
      "TXT_PINCODE_LOCALITY": "BHATPURWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 367,
      "City District Name": "UNNAO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202000,
      "TXT_PINCODE_LOCALITY": "ALIGARH H O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202001,
      "TXT_PINCODE_LOCALITY": "VISHNUPURI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202002,
      "TXT_PINCODE_LOCALITY": "ALIGARH MUSLIM UNIVERSITY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202031,
      "TXT_PINCODE_LOCALITY": "TODARPUR GAIR AHATMALI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2149,
      "City District Name": "ATRAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202106,
      "TXT_PINCODE_LOCALITY": "REWADI KHEDA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1628,
      "City District Name": "MODINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202116,
      "TXT_PINCODE_LOCALITY": "KHERA FIROZPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5539,
      "City District Name": "SASNI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202117,
      "TXT_PINCODE_LOCALITY": "DHANJPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1655,
      "City District Name": "BUDAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202118,
      "TXT_PINCODE_LOCALITY": "KALAI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202119,
      "TXT_PINCODE_LOCALITY": "BARLA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202120,
      "TXT_PINCODE_LOCALITY": "PILKHANA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202121,
      "TXT_PINCODE_LOCALITY": "AKRADBAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3391,
      "City District Name": "HATHRAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202122,
      "TXT_PINCODE_LOCALITY": "ALIGARH DAIRY FARM",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202123,
      "TXT_PINCODE_LOCALITY": "GONDA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202124,
      "TXT_PINCODE_LOCALITY": "IGLAS",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202125,
      "TXT_PINCODE_LOCALITY": "HARDUA GANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202126,
      "TXT_PINCODE_LOCALITY": "JAWAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202127,
      "TXT_PINCODE_LOCALITY": "KASIMPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202128,
      "TXT_PINCODE_LOCALITY": "JALALI(ALIGARH)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202129,
      "TXT_PINCODE_LOCALITY": "KAURIAGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202130,
      "TXT_PINCODE_LOCALITY": "CHHARRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202131,
      "TXT_PINCODE_LOCALITY": "BIJAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202132,
      "TXT_PINCODE_LOCALITY": "CHANDAUS",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202133,
      "TXT_PINCODE_LOCALITY": "DADON",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202134,
      "TXT_PINCODE_LOCALITY": "GANGIRI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202135,
      "TXT_PINCODE_LOCALITY": "GOMAT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202136,
      "TXT_PINCODE_LOCALITY": "GABHANA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202137,
      "TXT_PINCODE_LOCALITY": "JATTARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202138,
      "TXT_PINCODE_LOCALITY": "KHAIRMANDI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202139,
      "TXT_PINCODE_LOCALITY": "K G WORKS",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202140,
      "TXT_PINCODE_LOCALITY": "LODHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202141,
      "TXT_PINCODE_LOCALITY": "DAYAL NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202142,
      "TXT_PINCODE_LOCALITY": "BIRPURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202143,
      "TXT_PINCODE_LOCALITY": "SOMNA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202144,
      "TXT_PINCODE_LOCALITY": "NEW COLONY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202145,
      "TXT_PINCODE_LOCALITY": "BASWAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202146,
      "TXT_PINCODE_LOCALITY": "SATHINI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202150,
      "TXT_PINCODE_LOCALITY": "PALI RAZAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202151,
      "TXT_PINCODE_LOCALITY": "MEDRAK",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202154,
      "TXT_PINCODE_LOCALITY": "BESWAN *",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202155,
      "TXT_PINCODE_LOCALITY": "PISAWAH (ALIGARH)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202165,
      "TXT_PINCODE_LOCALITY": "TAPPAL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202170,
      "TXT_PINCODE_LOCALITY": "BIJAIGARH S.O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202171,
      "TXT_PINCODE_LOCALITY": "LUHSAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3391,
      "City District Name": "HATHRAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202184,
      "TXT_PINCODE_LOCALITY": "GAWAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1655,
      "City District Name": "BUDAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202193,
      "TXT_PINCODE_LOCALITY": "KANHAIRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442995,
      "City District Name": "DEBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202225,
      "TXT_PINCODE_LOCALITY": "KHERSA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2149,
      "City District Name": "ATRAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202241,
      "TXT_PINCODE_LOCALITY": "JAMALPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2558,
      "City District Name": "BILARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202244,
      "TXT_PINCODE_LOCALITY": "MUHAMMADNAGAR BAJHERA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1985,
      "City District Name": "ALIGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202280,
      "TXT_PINCODE_LOCALITY": "ATRAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202281,
      "TXT_PINCODE_LOCALITY": "DHOOM SINGH JOSHIMATH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202282,
      "TXT_PINCODE_LOCALITY": "MALVIYANAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202308,
      "TXT_PINCODE_LOCALITY": "DHOOM",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1861,
      "City District Name": "GHAZIABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202325,
      "TXT_PINCODE_LOCALITY": "HARDUAGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202359,
      "TXT_PINCODE_LOCALITY": "TEYOR BUJURAG",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5627,
      "City District Name": "SHIKARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202383,
      "TXT_PINCODE_LOCALITY": "BELON",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1086,
      "City District Name": "GAUTAM BUDH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202389,
      "TXT_PINCODE_LOCALITY": "NA P P",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 357,
      "City District Name": "BULANDSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202390,
      "TXT_PINCODE_LOCALITY": "ANUPSHAHR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 357,
      "City District Name": "BULANDSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202391,
      "TXT_PINCODE_LOCALITY": "CHHATTARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 357,
      "City District Name": "BULANDSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202392,
      "TXT_PINCODE_LOCALITY": "DANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 357,
      "City District Name": "BULANDSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202393,
      "TXT_PINCODE_LOCALITY": "DIBAI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 357,
      "City District Name": "BULANDSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202394,
      "TXT_PINCODE_LOCALITY": "JAHANGIRABAD BULANDSHAHR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 357,
      "City District Name": "BULANDSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202395,
      "TXT_PINCODE_LOCALITY": "SHIKARPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 357,
      "City District Name": "BULANDSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202396,
      "TXT_PINCODE_LOCALITY": "PAHASU",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 357,
      "City District Name": "BULANDSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202397,
      "TXT_PINCODE_LOCALITY": "NARORA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 357,
      "City District Name": "BULANDSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202398,
      "TXT_PINCODE_LOCALITY": "UNCHAGAON",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 357,
      "City District Name": "BULANDSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202399,
      "TXT_PINCODE_LOCALITY": "RAMGHAT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 357,
      "City District Name": "BULANDSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202407,
      "TXT_PINCODE_LOCALITY": "SADDIQ NAGAR.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6441,
      "City District Name": "SHAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202410,
      "TXT_PINCODE_LOCALITY": "BAHJOI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 764,
      "City District Name": "MORADABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202411,
      "TXT_PINCODE_LOCALITY": "BILARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 764,
      "City District Name": "MORADABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202412,
      "TXT_PINCODE_LOCALITY": "BARA BAZAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 764,
      "City District Name": "MORADABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202413,
      "TXT_PINCODE_LOCALITY": "KUNDARKI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 764,
      "City District Name": "MORADABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202414,
      "TXT_PINCODE_LOCALITY": "NARAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 764,
      "City District Name": "MORADABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202415,
      "TXT_PINCODE_LOCALITY": "RAJA-KA-SAHASPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 764,
      "City District Name": "MORADABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202416,
      "TXT_PINCODE_LOCALITY": "MAJHOLE",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 764,
      "City District Name": "MORADABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202417,
      "TXT_PINCODE_LOCALITY": "MOHIDDIN NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6441,
      "City District Name": "SHAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202421,
      "TXT_PINCODE_LOCALITY": "BABRALA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 440710,
      "City District Name": "BUDAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202520,
      "TXT_PINCODE_LOCALITY": "BISAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 440710,
      "City District Name": "BUDAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202521,
      "TXT_PINCODE_LOCALITY": "BABRALA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 440710,
      "City District Name": "BUDAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202522,
      "TXT_PINCODE_LOCALITY": "GUNNAUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1655,
      "City District Name": "BUDAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202523,
      "TXT_PINCODE_LOCALITY": "ISLAM NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1655,
      "City District Name": "BUDAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202524,
      "TXT_PINCODE_LOCALITY": "RUDIAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 440710,
      "City District Name": "BUDAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202525,
      "TXT_PINCODE_LOCALITY": "SAIDPUR (BADAUN)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1655,
      "City District Name": "BUDAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202526,
      "TXT_PINCODE_LOCALITY": "BUDAUN P. O.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1655,
      "City District Name": "BUDAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202527,
      "TXT_PINCODE_LOCALITY": "RAJPURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1655,
      "City District Name": "BUDAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202528,
      "TXT_PINCODE_LOCALITY": "MUNDIA DHUREKL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1655,
      "City District Name": "BUDAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202561,
      "TXT_PINCODE_LOCALITY": "PASIYA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442993,
      "City District Name": "CHAKARNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202641,
      "TXT_PINCODE_LOCALITY": "PURANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2542,
      "City District Name": "BIDHUNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202700,
      "TXT_PINCODE_LOCALITY": "SHITALPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3115,
      "City District Name": "ETAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202704,
      "TXT_PINCODE_LOCALITY": "MUJAFFARA.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443008,
      "City District Name": "KOIL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202721,
      "TXT_PINCODE_LOCALITY": "CHHIBRAMAU",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3765,
      "City District Name": "KANNAUJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 202723,
      "TXT_PINCODE_LOCALITY": "NANHEPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443033,
      "City District Name": "TIRWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203000,
      "TXT_PINCODE_LOCALITY": "BULANDSHAHAR H O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 357,
      "City District Name": "BULANDSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203001,
      "TXT_PINCODE_LOCALITY": "BULANDSHAHAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 425576,
      "City District Name": "BULANDSHAHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203002,
      "TXT_PINCODE_LOCALITY": "ZAHIDPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 365,
      "City District Name": "SIKANDRABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203094,
      "TXT_PINCODE_LOCALITY": "POOTHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2088,
      "City District Name": "ANUPSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203121,
      "TXT_PINCODE_LOCALITY": "AGRICULTURAL SCHOOL BULAND",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 357,
      "City District Name": "BULANDSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203124,
      "TXT_PINCODE_LOCALITY": "RUDYAT URF TARAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3455,
      "City District Name": "IGLAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203129,
      "TXT_PINCODE_LOCALITY": "KARORA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1086,
      "City District Name": "GAUTAM BUDH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203131,
      "TXT_PINCODE_LOCALITY": "SUBHASH ROAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 357,
      "City District Name": "BULANDSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203132,
      "TXT_PINCODE_LOCALITY": "KHURJA JN R S",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 357,
      "City District Name": "BULANDSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203133,
      "TXT_PINCODE_LOCALITY": "JEWAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1086,
      "City District Name": "GAUTAM BUDH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203135,
      "TXT_PINCODE_LOCALITY": "JEWAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1086,
      "City District Name": "GAUTAM BUDH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203141,
      "TXT_PINCODE_LOCALITY": "JAHANGIRPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1086,
      "City District Name": "GAUTAM BUDH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203145,
      "TXT_PINCODE_LOCALITY": "PANNINAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 357,
      "City District Name": "BULANDSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203150,
      "TXT_PINCODE_LOCALITY": "SAKHARI NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 357,
      "City District Name": "BULANDSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203155,
      "TXT_PINCODE_LOCALITY": "THORA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1086,
      "City District Name": "GAUTAM BUDH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203201,
      "TXT_PINCODE_LOCALITY": "DANKAUR - 1149",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1086,
      "City District Name": "GAUTAM BUDH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203202,
      "TXT_PINCODE_LOCALITY": "GURKUL SIKANDRABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 357,
      "City District Name": "BULANDSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203203,
      "TXT_PINCODE_LOCALITY": "KAKORE",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1086,
      "City District Name": "GAUTAM BUDH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203205,
      "TXT_PINCODE_LOCALITY": "KAYASTHWARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 357,
      "City District Name": "BULANDSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203206,
      "TXT_PINCODE_LOCALITY": "RAJARAMPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 365,
      "City District Name": "SIKANDRABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203207,
      "TXT_PINCODE_LOCALITY": "ACHHEJA B.O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1086,
      "City District Name": "GAUTAM BUDH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203208,
      "TXT_PINCODE_LOCALITY": "M.S.F.345",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1086,
      "City District Name": "GAUTAM BUDH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203209,
      "TXT_PINCODE_LOCALITY": "RABUPURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1086,
      "City District Name": "GAUTAM BUDH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203255,
      "TXT_PINCODE_LOCALITY": "CHANCHALI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1086,
      "City District Name": "GAUTAM BUDH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203301,
      "TXT_PINCODE_LOCALITY": "KAPNA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3989,
      "City District Name": "KHURJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203389,
      "TXT_PINCODE_LOCALITY": "NAPP",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 425576,
      "City District Name": "BULANDSHAHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203390,
      "TXT_PINCODE_LOCALITY": "ANUPSHAHR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 425576,
      "City District Name": "BULANDSHAHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203391,
      "TXT_PINCODE_LOCALITY": "GAHANA GOVARDHANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2088,
      "City District Name": "ANUPSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203392,
      "TXT_PINCODE_LOCALITY": "CHAUNDERA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 357,
      "City District Name": "BULANDSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203393,
      "TXT_PINCODE_LOCALITY": "POKHARPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442995,
      "City District Name": "DEBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203394,
      "TXT_PINCODE_LOCALITY": "JAHANGIRABAD MANDI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 425576,
      "City District Name": "BULANDSHAHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203395,
      "TXT_PINCODE_LOCALITY": "NAWADA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5627,
      "City District Name": "SHIKARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203396,
      "TXT_PINCODE_LOCALITY": "KUNWARPUR B.O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 357,
      "City District Name": "BULANDSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203397,
      "TXT_PINCODE_LOCALITY": "VIRAURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442995,
      "City District Name": "DEBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203398,
      "TXT_PINCODE_LOCALITY": "BAMANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2088,
      "City District Name": "ANUPSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203399,
      "TXT_PINCODE_LOCALITY": "JARGWAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442995,
      "City District Name": "DEBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203401,
      "TXT_PINCODE_LOCALITY": "PAVSARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 357,
      "City District Name": "BULANDSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203402,
      "TXT_PINCODE_LOCALITY": "BB NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 357,
      "City District Name": "BULANDSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203403,
      "TXT_PINCODE_LOCALITY": "MAKRI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5651,
      "City District Name": "SIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203405,
      "TXT_PINCODE_LOCALITY": "THAUNA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5651,
      "City District Name": "SIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203407,
      "TXT_PINCODE_LOCALITY": "BAHADUR MAHESPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 357,
      "City District Name": "BULANDSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203408,
      "TXT_PINCODE_LOCALITY": "BULANDSHAHR S. O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 425576,
      "City District Name": "BULANDSHAHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203409,
      "TXT_PINCODE_LOCALITY": "MANAKPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 357,
      "City District Name": "BULANDSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203411,
      "TXT_PINCODE_LOCALITY": "SHEKHPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1171,
      "City District Name": "HAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203412,
      "TXT_PINCODE_LOCALITY": "SIANA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5651,
      "City District Name": "SIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203601,
      "TXT_PINCODE_LOCALITY": "AMETHI KOHNA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442997,
      "City District Name": "FARRUKHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203636,
      "TXT_PINCODE_LOCALITY": "KACHHLA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1655,
      "City District Name": "BUDAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203839,
      "TXT_PINCODE_LOCALITY": "MIRRI KALAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5222,
      "City District Name": "PURWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 203859,
      "TXT_PINCODE_LOCALITY": "MALLA KHERA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5222,
      "City District Name": "PURWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 204100,
      "TXT_PINCODE_LOCALITY": "HATHRAS",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3391,
      "City District Name": "HATHRAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 204101,
      "TXT_PINCODE_LOCALITY": "MANDI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 204102,
      "TXT_PINCODE_LOCALITY": "HATHRAS JN R S",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3391,
      "City District Name": "HATHRAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 204103,
      "TXT_PINCODE_LOCALITY": "ARJUNPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3391,
      "City District Name": "HATHRAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 204104,
      "TXT_PINCODE_LOCALITY": "KANTH (MORADABAD) - 2220",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 764,
      "City District Name": "MORADABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 204105,
      "TXT_PINCODE_LOCALITY": "MERU",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 204106,
      "TXT_PINCODE_LOCALITY": "TOCHHIGARH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 204111,
      "TXT_PINCODE_LOCALITY": "PIPAL GAWAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5664,
      "City District Name": "SIKANDRA RAO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 204125,
      "TXT_PINCODE_LOCALITY": "RAJAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5663,
      "City District Name": "SIKANDRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 204139,
      "TXT_PINCODE_LOCALITY": "LUTSAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5539,
      "City District Name": "SASNI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 204201,
      "TXT_PINCODE_LOCALITY": "AJROI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5539,
      "City District Name": "SASNI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 204205,
      "TXT_PINCODE_LOCALITY": "PORA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3391,
      "City District Name": "HATHRAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 204206,
      "TXT_PINCODE_LOCALITY": "KANAU",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3391,
      "City District Name": "HATHRAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 204207,
      "TXT_PINCODE_LOCALITY": "JAWAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 204208,
      "TXT_PINCODE_LOCALITY": "GADOLA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 204209,
      "TXT_PINCODE_LOCALITY": "BHEKURI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 204210,
      "TXT_PINCODE_LOCALITY": "AGSOLI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3391,
      "City District Name": "HATHRAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 204211,
      "TXT_PINCODE_LOCALITY": "KACHUURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3391,
      "City District Name": "HATHRAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 204212,
      "TXT_PINCODE_LOCALITY": "HASAYAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3391,
      "City District Name": "HATHRAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 204213,
      "TXT_PINCODE_LOCALITY": "MURSAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3391,
      "City District Name": "HATHRAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 204214,
      "TXT_PINCODE_LOCALITY": "PURDILPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3391,
      "City District Name": "HATHRAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 204215,
      "TXT_PINCODE_LOCALITY": "SIKANDRARAO",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3391,
      "City District Name": "HATHRAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 204216,
      "TXT_PINCODE_LOCALITY": "SASNI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3391,
      "City District Name": "HATHRAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 204241,
      "TXT_PINCODE_LOCALITY": "NADAR MAI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5436,
      "City District Name": "SAHAWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 204244,
      "TXT_PINCODE_LOCALITY": "SUJANPUR URF KANARPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2154,
      "City District Name": "AURAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 204401,
      "TXT_PINCODE_LOCALITY": "GAJIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3228,
      "City District Name": "GHATAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 204501,
      "TXT_PINCODE_LOCALITY": "AGARWAL MANDI *",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 205000,
      "TXT_PINCODE_LOCALITY": "MAINPURI H O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4339,
      "City District Name": "MAINPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 205001,
      "TXT_PINCODE_LOCALITY": "AHEWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4339,
      "City District Name": "MAINPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 205021,
      "TXT_PINCODE_LOCALITY": "KANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 205119,
      "TXT_PINCODE_LOCALITY": "TINDAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4339,
      "City District Name": "MAINPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 205120,
      "TXT_PINCODE_LOCALITY": "MAINPURI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4339,
      "City District Name": "MAINPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 205121,
      "TXT_PINCODE_LOCALITY": "GHIROR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4339,
      "City District Name": "MAINPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 205122,
      "TXT_PINCODE_LOCALITY": "EKKA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 939,
      "City District Name": "FIROZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 205125,
      "TXT_PINCODE_LOCALITY": "JASRANA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 939,
      "City District Name": "FIROZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 205126,
      "TXT_PINCODE_LOCALITY": "JASRANA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 939,
      "City District Name": "FIROZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 205130,
      "TXT_PINCODE_LOCALITY": "PARHAM",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 939,
      "City District Name": "FIROZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 205131,
      "TXT_PINCODE_LOCALITY": "RAMNAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5628,
      "City District Name": "SHIKOHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 205135,
      "TXT_PINCODE_LOCALITY": "SHIKOHABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 939,
      "City District Name": "FIROZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 205141,
      "TXT_PINCODE_LOCALITY": "SHIKOHABAD R S",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 939,
      "City District Name": "FIROZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 205142,
      "TXT_PINCODE_LOCALITY": "PARIAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 939,
      "City District Name": "FIROZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 205143,
      "TXT_PINCODE_LOCALITY": "KATENAHARSA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3554,
      "City District Name": "JASRANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 205145,
      "TXT_PINCODE_LOCALITY": "MAKHANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 939,
      "City District Name": "FIROZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 205147,
      "TXT_PINCODE_LOCALITY": "PHARIHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 939,
      "City District Name": "FIROZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 205151,
      "TXT_PINCODE_LOCALITY": "SIRSAGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 939,
      "City District Name": "FIROZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 205152,
      "TXT_PINCODE_LOCALITY": "BHADANA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 939,
      "City District Name": "FIROZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 205154,
      "TXT_PINCODE_LOCALITY": "MALUPURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5628,
      "City District Name": "SHIKOHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 205231,
      "TXT_PINCODE_LOCALITY": "MAINPURI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4339,
      "City District Name": "MAINPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 205247,
      "TXT_PINCODE_LOCALITY": "AJITGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4339,
      "City District Name": "MAINPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 205253,
      "TXT_PINCODE_LOCALITY": "SHYAM NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3116,
      "City District Name": "ETAWAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 205261,
      "TXT_PINCODE_LOCALITY": "BARNAHAL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4339,
      "City District Name": "MAINPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 205262,
      "TXT_PINCODE_LOCALITY": "BHOGAON",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4339,
      "City District Name": "MAINPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 205263,
      "TXT_PINCODE_LOCALITY": "JEONTI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4339,
      "City District Name": "MAINPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 205264,
      "TXT_PINCODE_LOCALITY": "KARHAL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4339,
      "City District Name": "MAINPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 205265,
      "TXT_PINCODE_LOCALITY": "KURAOLI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4339,
      "City District Name": "MAINPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 205266,
      "TXT_PINCODE_LOCALITY": "MAINPURI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4339,
      "City District Name": "MAINPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 205267,
      "TXT_PINCODE_LOCALITY": "JAGATPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1330,
      "City District Name": "RAI BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 205268,
      "TXT_PINCODE_LOCALITY": "KURRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4339,
      "City District Name": "MAINPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 205301,
      "TXT_PINCODE_LOCALITY": "EATMS",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4339,
      "City District Name": "MAINPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 205302,
      "TXT_PINCODE_LOCALITY": "FARUKHABAD S. O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6565,
      "City District Name": "FARUKHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 205303,
      "TXT_PINCODE_LOCALITY": "SAMAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2517,
      "City District Name": "BHOGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 205304,
      "TXT_PINCODE_LOCALITY": "NOONARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2517,
      "City District Name": "BHOGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 205353,
      "TXT_PINCODE_LOCALITY": "BIDHOONI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3116,
      "City District Name": "ETAWAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 205626,
      "TXT_PINCODE_LOCALITY": "AMIPUR BALENI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 853,
      "City District Name": "BAGHPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 205735,
      "TXT_PINCODE_LOCALITY": "NAGLA SAIDLAL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5628,
      "City District Name": "SHIKOHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 205815,
      "TXT_PINCODE_LOCALITY": "SEHNAOLI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5628,
      "City District Name": "SHIKOHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206001,
      "TXT_PINCODE_LOCALITY": "BAZARIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3116,
      "City District Name": "ETAWAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206002,
      "TXT_PINCODE_LOCALITY": "ETAWAH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3116,
      "City District Name": "ETAWAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206003,
      "TXT_PINCODE_LOCALITY": "RAMLILA ROAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3116,
      "City District Name": "ETAWAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206013,
      "TXT_PINCODE_LOCALITY": "H A D LUCKNOW",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206049,
      "TXT_PINCODE_LOCALITY": "DONDAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2542,
      "City District Name": "BIDHUNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206119,
      "TXT_PINCODE_LOCALITY": "SARAI BIBI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2154,
      "City District Name": "AURAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206120,
      "TXT_PINCODE_LOCALITY": "AHERIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3116,
      "City District Name": "ETAWAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206121,
      "TXT_PINCODE_LOCALITY": "AJLTMAL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2154,
      "City District Name": "AURAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206122,
      "TXT_PINCODE_LOCALITY": "BELA   *",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2154,
      "City District Name": "AURAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206123,
      "TXT_PINCODE_LOCALITY": "BARLOKPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3116,
      "City District Name": "ETAWAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206124,
      "TXT_PINCODE_LOCALITY": "BAKEWAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3116,
      "City District Name": "ETAWAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206125,
      "TXT_PINCODE_LOCALITY": "CHAKRA NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3116,
      "City District Name": "ETAWAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206126,
      "TXT_PINCODE_LOCALITY": "EKDIL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3116,
      "City District Name": "ETAWAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206127,
      "TXT_PINCODE_LOCALITY": "LAKHNA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3116,
      "City District Name": "ETAWAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206128,
      "TXT_PINCODE_LOCALITY": "MANDIR MAHEWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3116,
      "City District Name": "ETAWAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206129,
      "TXT_PINCODE_LOCALITY": "MURADGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2154,
      "City District Name": "AURAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206130,
      "TXT_PINCODE_LOCALITY": "SAIFAI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3116,
      "City District Name": "ETAWAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206131,
      "TXT_PINCODE_LOCALITY": "UDI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3116,
      "City District Name": "ETAWAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206148,
      "TXT_PINCODE_LOCALITY": "BUNCHPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2542,
      "City District Name": "BIDHUNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206174,
      "TXT_PINCODE_LOCALITY": "SRINAGAR",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443102,
      "City District Name": "PAURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206180,
      "TXT_PINCODE_LOCALITY": "PARSOLI ORAMPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442993,
      "City District Name": "CHAKARNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206200,
      "TXT_PINCODE_LOCALITY": "LOKASAI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3116,
      "City District Name": "ETAWAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206202,
      "TXT_PINCODE_LOCALITY": "RAJMAU",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3555,
      "City District Name": "JASWANTNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206220,
      "TXT_PINCODE_LOCALITY": "AKBARPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2154,
      "City District Name": "AURAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206221,
      "TXT_PINCODE_LOCALITY": "SHAHPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2154,
      "City District Name": "AURAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206222,
      "TXT_PINCODE_LOCALITY": "AURAIYA.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2154,
      "City District Name": "AURAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206226,
      "TXT_PINCODE_LOCALITY": "PHOPHI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3116,
      "City District Name": "ETAWAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206240,
      "TXT_PINCODE_LOCALITY": "PATNA BELA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2542,
      "City District Name": "BIDHUNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206241,
      "TXT_PINCODE_LOCALITY": "ACHALDA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2154,
      "City District Name": "AURAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206242,
      "TXT_PINCODE_LOCALITY": "BHARTHANA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3116,
      "City District Name": "ETAWAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206243,
      "TXT_PINCODE_LOCALITY": "BIDHUNA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2154,
      "City District Name": "AURAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206244,
      "TXT_PINCODE_LOCALITY": "DIBIAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2154,
      "City District Name": "AURAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206245,
      "TXT_PINCODE_LOCALITY": "JASWANTNAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3116,
      "City District Name": "ETAWAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206246,
      "TXT_PINCODE_LOCALITY": "KANCHAUSI BAZAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2154,
      "City District Name": "AURAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206247,
      "TXT_PINCODE_LOCALITY": "PHAPHAUND",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2154,
      "City District Name": "AURAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206248,
      "TXT_PINCODE_LOCALITY": "SAHAR S.O (AURAIYA)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2154,
      "City District Name": "AURAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206249,
      "TXT_PINCODE_LOCALITY": "RURUGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206250,
      "TXT_PINCODE_LOCALITY": "HARCHANDPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2154,
      "City District Name": "AURAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206251,
      "TXT_PINCODE_LOCALITY": "BELA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3116,
      "City District Name": "ETAWAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206252,
      "TXT_PINCODE_LOCALITY": "AURAIYA S. O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2154,
      "City District Name": "AURAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206253,
      "TXT_PINCODE_LOCALITY": "BASREHAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3116,
      "City District Name": "ETAWAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206254,
      "TXT_PINCODE_LOCALITY": "JASWANTNAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3116,
      "City District Name": "ETAWAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206255,
      "TXT_PINCODE_LOCALITY": "MADAHADASPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2154,
      "City District Name": "AURAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206261,
      "TXT_PINCODE_LOCALITY": "UMMERPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2154,
      "City District Name": "AURAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206263,
      "TXT_PINCODE_LOCALITY": "ETAWAH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3116,
      "City District Name": "ETAWAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206264,
      "TXT_PINCODE_LOCALITY": "MUHAMMADPUR NAGARIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3806,
      "City District Name": "KARHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206265,
      "TXT_PINCODE_LOCALITY": "RAMPUR KUNWAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2542,
      "City District Name": "BIDHUNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206267,
      "TXT_PINCODE_LOCALITY": "BICHHAWAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2517,
      "City District Name": "BHOGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206295,
      "TXT_PINCODE_LOCALITY": "NAGALA SALEHADI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3555,
      "City District Name": "JASWANTNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206301,
      "TXT_PINCODE_LOCALITY": "MAINPURI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4339,
      "City District Name": "MAINPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206302,
      "TXT_PINCODE_LOCALITY": "KISHNI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4339,
      "City District Name": "MAINPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206303,
      "TXT_PINCODE_LOCALITY": "KUSMARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4339,
      "City District Name": "MAINPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206362,
      "TXT_PINCODE_LOCALITY": "ARSARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2154,
      "City District Name": "AURAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 206451,
      "TXT_PINCODE_LOCALITY": "MOHAMMADABAD S.O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442997,
      "City District Name": "FARRUKHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207000,
      "TXT_PINCODE_LOCALITY": "ETAH H O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3115,
      "City District Name": "ETAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207001,
      "TXT_PINCODE_LOCALITY": "GANDHI MARKET",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3115,
      "City District Name": "ETAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207002,
      "TXT_PINCODE_LOCALITY": "DINESH NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3115,
      "City District Name": "ETAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207003,
      "TXT_PINCODE_LOCALITY": "SHIVSINGHPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3115,
      "City District Name": "ETAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207021,
      "TXT_PINCODE_LOCALITY": "KASGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3834,
      "City District Name": "KASGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207048,
      "TXT_PINCODE_LOCALITY": "LALPUR JAHANGIRABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1985,
      "City District Name": "ALIGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207120,
      "TXT_PINCODE_LOCALITY": "SONGRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3115,
      "City District Name": "ETAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207121,
      "TXT_PINCODE_LOCALITY": "SAKET",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3115,
      "City District Name": "ETAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207122,
      "TXT_PINCODE_LOCALITY": "NIDHAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3115,
      "City District Name": "ETAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207123,
      "TXT_PINCODE_LOCALITY": "KASGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3115,
      "City District Name": "ETAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207124,
      "TXT_PINCODE_LOCALITY": "BILARAM",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3115,
      "City District Name": "ETAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207125,
      "TXT_PINCODE_LOCALITY": "MIRECHI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3115,
      "City District Name": "ETAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207126,
      "TXT_PINCODE_LOCALITY": "ETAH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3115,
      "City District Name": "ETAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207146,
      "TXT_PINCODE_LOCALITY": "KHIRIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5047,
      "City District Name": "PATIYALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207203,
      "TXT_PINCODE_LOCALITY": "PASIYAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3661,
      "City District Name": "KAIMGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207204,
      "TXT_PINCODE_LOCALITY": "BILSAR PATTI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1985,
      "City District Name": "ALIGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207228,
      "TXT_PINCODE_LOCALITY": "NAGLA DANUA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443033,
      "City District Name": "TIRWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207241,
      "TXT_PINCODE_LOCALITY": "AMANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3115,
      "City District Name": "ETAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207242,
      "TXT_PINCODE_LOCALITY": "KASHGUNJ S. O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3115,
      "City District Name": "ETAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207243,
      "TXT_PINCODE_LOCALITY": "PATIALI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3115,
      "City District Name": "ETAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207244,
      "TXT_PINCODE_LOCALITY": "RAJA-KA-RAMPUR - 3945",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3115,
      "City District Name": "ETAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207245,
      "TXT_PINCODE_LOCALITY": "SAHAWAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3115,
      "City District Name": "ETAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207246,
      "TXT_PINCODE_LOCALITY": "SIRHPURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3115,
      "City District Name": "ETAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207247,
      "TXT_PINCODE_LOCALITY": "ALIGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3115,
      "City District Name": "ETAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207248,
      "TXT_PINCODE_LOCALITY": "DHUMARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3115,
      "City District Name": "ETAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207249,
      "TXT_PINCODE_LOCALITY": "JAITHRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3115,
      "City District Name": "ETAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207250,
      "TXT_PINCODE_LOCALITY": "SARAI AGHAT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3115,
      "City District Name": "ETAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207278,
      "TXT_PINCODE_LOCALITY": "BAHADURNAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5047,
      "City District Name": "PATIYALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207301,
      "TXT_PINCODE_LOCALITY": "AWAGARH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3115,
      "City District Name": "ETAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207302,
      "TXT_PINCODE_LOCALITY": "JALESAR TOWN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3115,
      "City District Name": "ETAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207394,
      "TXT_PINCODE_LOCALITY": "UGEVAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2088,
      "City District Name": "ANUPSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207401,
      "TXT_PINCODE_LOCALITY": "MAREHRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3115,
      "City District Name": "ETAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207402,
      "TXT_PINCODE_LOCALITY": "NEOLI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3115,
      "City District Name": "ETAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207403,
      "TXT_PINCODE_LOCALITY": "SORAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3115,
      "City District Name": "ETAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207404,
      "TXT_PINCODE_LOCALITY": "ETAWAH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3116,
      "City District Name": "ETAWAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207446,
      "TXT_PINCODE_LOCALITY": "KHARGATIPUR.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5047,
      "City District Name": "PATIYALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207501,
      "TXT_PINCODE_LOCALITY": "FARRUKHABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442997,
      "City District Name": "FARRUKHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207502,
      "TXT_PINCODE_LOCALITY": "FANGA DARWARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6565,
      "City District Name": "FARUKHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207503,
      "TXT_PINCODE_LOCALITY": "SHAMSHABAD (FATEHGARH)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6565,
      "City District Name": "FARUKHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207504,
      "TXT_PINCODE_LOCALITY": "MANJHAMA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6565,
      "City District Name": "FARUKHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207505,
      "TXT_PINCODE_LOCALITY": "FARUKHABAD.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6565,
      "City District Name": "FARUKHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207642,
      "TXT_PINCODE_LOCALITY": "HAMIRPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5047,
      "City District Name": "PATIYALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207859,
      "TXT_PINCODE_LOCALITY": "PAHAR PUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5222,
      "City District Name": "PURWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 207861,
      "TXT_PINCODE_LOCALITY": "ARZHORA MAU",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 367,
      "City District Name": "UNNAO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 208001,
      "TXT_PINCODE_LOCALITY": "MUIR MILLS",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 208002,
      "TXT_PINCODE_LOCALITY": "NEW CIVIL LINES",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 208003,
      "TXT_PINCODE_LOCALITY": "COTTON MILLS",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 208004,
      "TXT_PINCODE_LOCALITY": "KANPUR CANTT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 208005,
      "TXT_PINCODE_LOCALITY": "H N S NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 208006,
      "TXT_PINCODE_LOCALITY": "GOVIND NAGARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 208007,
      "TXT_PINCODE_LOCALITY": "HARIJINDER NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 208008,
      "TXT_PINCODE_LOCALITY": "CHAKERI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 208009,
      "TXT_PINCODE_LOCALITY": "ARMAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 208010,
      "TXT_PINCODE_LOCALITY": "SHEWANS",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 208011,
      "TXT_PINCODE_LOCALITY": "KIDWAINAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 208012,
      "TXT_PINCODE_LOCALITY": "SISAMAU",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 208013,
      "TXT_PINCODE_LOCALITY": "C O D KANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 208014,
      "TXT_PINCODE_LOCALITY": "JUHI COLONY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 208015,
      "TXT_PINCODE_LOCALITY": "NEW P A C LINES",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 208016,
      "TXT_PINCODE_LOCALITY": "I I T KANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 208017,
      "TXT_PINCODE_LOCALITY": "N S I KANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 208018,
      "TXT_PINCODE_LOCALITY": "KALYANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 208019,
      "TXT_PINCODE_LOCALITY": "RAWATPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 208020,
      "TXT_PINCODE_LOCALITY": "PANKI POWER HOUSE",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 208021,
      "TXT_PINCODE_LOCALITY": "NAUBASTA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 208022,
      "TXT_PINCODE_LOCALITY": "UDYOGNAGAR (KANPUR)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 208023,
      "TXT_PINCODE_LOCALITY": "MUNSHIPURWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 208024,
      "TXT_PINCODE_LOCALITY": "KANPUR UNIVERSITY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 208025,
      "TXT_PINCODE_LOCALITY": "NAVIN NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 208026,
      "TXT_PINCODE_LOCALITY": "KANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 208027,
      "TXT_PINCODE_LOCALITY": "BARRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 441046,
      "City District Name": "KANPUR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 208028,
      "TXT_PINCODE_LOCALITY": "KAKADEV",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 208039,
      "TXT_PINCODE_LOCALITY": "ARMAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 208120,
      "TXT_PINCODE_LOCALITY": "BARUA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2543,
      "City District Name": "BIGHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 208225,
      "TXT_PINCODE_LOCALITY": "VIJAIGARH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 208559,
      "TXT_PINCODE_LOCALITY": "KATEHARO",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443002,
      "City District Name": "HASANGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209006,
      "TXT_PINCODE_LOCALITY": "JALAN BESAR",
      "NUM_STATE_CD": 283,
      "State Name": "JALAN BESAR",
      "NUM_CITYDISTRICT_CD": 440956,
      "City District Name": "JALAN BESAR",
      "NUM_COUNTRY_CD": 151
    },
    {
      "NUM_PINCODE": 209028,
      "TXT_PINCODE_LOCALITY": "MUSANAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209101,
      "TXT_PINCODE_LOCALITY": "AKBARPUR (KANPUR)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209102,
      "TXT_PINCODE_LOCALITY": "MUNAUWARPUR BILHAUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2566,
      "City District Name": "BILHAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209108,
      "TXT_PINCODE_LOCALITY": "DAHELI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3228,
      "City District Name": "GHATAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209111,
      "TXT_PINCODE_LOCALITY": "PUKHRAYAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209112,
      "TXT_PINCODE_LOCALITY": "AMRAUDHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209113,
      "TXT_PINCODE_LOCALITY": "GIRDAUN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442990,
      "City District Name": "BHOGNIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209115,
      "TXT_PINCODE_LOCALITY": "RAJPUR (KANPUR)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209120,
      "TXT_PINCODE_LOCALITY": "SAINSAR PUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2766,
      "City District Name": "CHHIBRAMAU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209121,
      "TXT_PINCODE_LOCALITY": "SARWAN KHERA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209125,
      "TXT_PINCODE_LOCALITY": "SIKANDRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 441042,
      "City District Name": "KANPUR DEHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209131,
      "TXT_PINCODE_LOCALITY": "DUBAKI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5663,
      "City District Name": "SIKANDRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209155,
      "TXT_PINCODE_LOCALITY": "GHANSALI",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443114,
      "City District Name": "TEHRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209192,
      "TXT_PINCODE_LOCALITY": "TEHRI GHARWAL",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443068,
      "City District Name": "DEVPRAYAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209201,
      "TXT_PINCODE_LOCALITY": "KANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209202,
      "TXT_PINCODE_LOCALITY": "BILHAUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209203,
      "TXT_PINCODE_LOCALITY": "CHABEYPUR (KANPUR)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209204,
      "TXT_PINCODE_LOCALITY": "SHEOLI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209205,
      "TXT_PINCODE_LOCALITY": "SHEORAJPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209206,
      "TXT_PINCODE_LOCALITY": "SADAR BAZAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209207,
      "TXT_PINCODE_LOCALITY": "KANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209208,
      "TXT_PINCODE_LOCALITY": "MUSANAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209209,
      "TXT_PINCODE_LOCALITY": "BHITARGAON",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209210,
      "TXT_PINCODE_LOCALITY": "SSUDHARDEVO (E.U.P -  9346)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 441042,
      "City District Name": "KANPUR DEHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209214,
      "TXT_PINCODE_LOCALITY": "CHAURAI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209217,
      "TXT_PINCODE_LOCALITY": "MANDHANA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209242,
      "TXT_PINCODE_LOCALITY": "GUDIAE",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5047,
      "City District Name": "PATIYALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209293,
      "TXT_PINCODE_LOCALITY": "SUNAURHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2566,
      "City District Name": "BILHAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209301,
      "TXT_PINCODE_LOCALITY": "DARAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6565,
      "City District Name": "FARUKHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209302,
      "TXT_PINCODE_LOCALITY": "JHINJHAK",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209303,
      "TXT_PINCODE_LOCALITY": "RURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209304,
      "TXT_PINCODE_LOCALITY": "SCHENDI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209305,
      "TXT_PINCODE_LOCALITY": "CHAKARPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209306,
      "TXT_PINCODE_LOCALITY": "RASULABAD (KANPUR)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 367,
      "City District Name": "UNNAO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209307,
      "TXT_PINCODE_LOCALITY": "BHORPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209308,
      "TXT_PINCODE_LOCALITY": "PATARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 441042,
      "City District Name": "KANPUR DEHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209310,
      "TXT_PINCODE_LOCALITY": "PROKHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 441042,
      "City District Name": "KANPUR DEHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209311,
      "TXT_PINCODE_LOCALITY": "JAINPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 441042,
      "City District Name": "KANPUR DEHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209312,
      "TXT_PINCODE_LOCALITY": "KAILAI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 441042,
      "City District Name": "KANPUR DEHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209331,
      "TXT_PINCODE_LOCALITY": "MALAON",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443002,
      "City District Name": "HASANGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209340,
      "TXT_PINCODE_LOCALITY": "AURIYA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2154,
      "City District Name": "AURAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209344,
      "TXT_PINCODE_LOCALITY": "MAHMOODPUR SINAURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3661,
      "City District Name": "KAIMGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209360,
      "TXT_PINCODE_LOCALITY": "SAJAWARPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5339,
      "City District Name": "RASULABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209401,
      "TXT_PINCODE_LOCALITY": "NARWAL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209402,
      "TXT_PINCODE_LOCALITY": "SARSAUL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209407,
      "TXT_PINCODE_LOCALITY": "SENIYA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209414,
      "TXT_PINCODE_LOCALITY": "BHIMSEN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 176,
      "City District Name": "KANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209501,
      "TXT_PINCODE_LOCALITY": "KLAULI MAHBULLA PUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442997,
      "City District Name": "FARRUKHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209502,
      "TXT_PINCODE_LOCALITY": "KAIMGANJ S.O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3135,
      "City District Name": "FATEHGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209503,
      "TXT_PINCODE_LOCALITY": "SADARIAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442997,
      "City District Name": "FARRUKHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209504,
      "TXT_PINCODE_LOCALITY": "FARRUKHABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442997,
      "City District Name": "FARRUKHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209505,
      "TXT_PINCODE_LOCALITY": "KAMPIL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6565,
      "City District Name": "FARUKHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209506,
      "TXT_PINCODE_LOCALITY": "MOHKAMPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2543,
      "City District Name": "BIGHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209601,
      "TXT_PINCODE_LOCALITY": "BARHPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6565,
      "City District Name": "FARUKHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209602,
      "TXT_PINCODE_LOCALITY": "CENTRAL JAIL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6565,
      "City District Name": "FARUKHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209621,
      "TXT_PINCODE_LOCALITY": "ALIGARH FATEHGARH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6565,
      "City District Name": "FARUKHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209622,
      "TXT_PINCODE_LOCALITY": "AMRITPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6565,
      "City District Name": "FARUKHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209625,
      "TXT_PINCODE_LOCALITY": "LINDSAYGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6565,
      "City District Name": "FARUKHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209651,
      "TXT_PINCODE_LOCALITY": "RASULPUR URF BHARATPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442997,
      "City District Name": "FARRUKHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209652,
      "TXT_PINCODE_LOCALITY": "SULTAN NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442997,
      "City District Name": "FARRUKHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209715,
      "TXT_PINCODE_LOCALITY": "MANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 441042,
      "City District Name": "KANPUR DEHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209719,
      "TXT_PINCODE_LOCALITY": "DAN MANDI.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442997,
      "City District Name": "FARRUKHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209720,
      "TXT_PINCODE_LOCALITY": "FARUKHABAD S. O.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3765,
      "City District Name": "KANNAUJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209721,
      "TXT_PINCODE_LOCALITY": "CHHIBRAMAU",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3765,
      "City District Name": "KANNAUJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209722,
      "TXT_PINCODE_LOCALITY": "GURSAHAIGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3765,
      "City District Name": "KANNAUJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209723,
      "TXT_PINCODE_LOCALITY": "INDERGARH S.O (KANNAUJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3765,
      "City District Name": "KANNAUJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209724,
      "TXT_PINCODE_LOCALITY": "KAMALGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6565,
      "City District Name": "FARUKHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209725,
      "TXT_PINCODE_LOCALITY": "BISUNGARH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3765,
      "City District Name": "KANNAUJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209726,
      "TXT_PINCODE_LOCALITY": "MAKRAND NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3765,
      "City District Name": "KANNAUJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209727,
      "TXT_PINCODE_LOCALITY": "SARAI MIRAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3765,
      "City District Name": "KANNAUJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209728,
      "TXT_PINCODE_LOCALITY": "SAURIKH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3765,
      "City District Name": "KANNAUJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209729,
      "TXT_PINCODE_LOCALITY": "SIKANDERPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6565,
      "City District Name": "FARUKHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209730,
      "TXT_PINCODE_LOCALITY": "BAISPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443033,
      "City District Name": "TIRWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209731,
      "TXT_PINCODE_LOCALITY": "TALGRAM",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3765,
      "City District Name": "KANNAUJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209732,
      "TXT_PINCODE_LOCALITY": "TIRWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3765,
      "City District Name": "KANNAUJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209733,
      "TXT_PINCODE_LOCALITY": "JALALABAD FATEGARH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3765,
      "City District Name": "KANNAUJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209734,
      "TXT_PINCODE_LOCALITY": "THATHIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3765,
      "City District Name": "KANNAUJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209735,
      "TXT_PINCODE_LOCALITY": "SARAI PRAYAG",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3765,
      "City District Name": "KANNAUJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209736,
      "TXT_PINCODE_LOCALITY": "KHAIRNAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3765,
      "City District Name": "KANNAUJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209738,
      "TXT_PINCODE_LOCALITY": "UMARDA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3765,
      "City District Name": "KANNAUJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209739,
      "TXT_PINCODE_LOCALITY": "JAHANGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6565,
      "City District Name": "FARUKHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209743,
      "TXT_PINCODE_LOCALITY": "KHIMSEPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6565,
      "City District Name": "FARUKHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209744,
      "TXT_PINCODE_LOCALITY": "ATIRAJ PUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2766,
      "City District Name": "CHHIBRAMAU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209745,
      "TXT_PINCODE_LOCALITY": "ROSHNABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6565,
      "City District Name": "FARUKHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209746,
      "TXT_PINCODE_LOCALITY": "RUDAYAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3661,
      "City District Name": "KAIMGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209747,
      "TXT_PINCODE_LOCALITY": "KANNAUJ.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3765,
      "City District Name": "KANNAUJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209748,
      "TXT_PINCODE_LOCALITY": "RUPPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2766,
      "City District Name": "CHHIBRAMAU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209749,
      "TXT_PINCODE_LOCALITY": "YAKOOT GANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6565,
      "City District Name": "FARUKHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209755,
      "TXT_PINCODE_LOCALITY": "KASAWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2766,
      "City District Name": "CHHIBRAMAU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209770,
      "TXT_PINCODE_LOCALITY": "FARRUKHABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442997,
      "City District Name": "FARRUKHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209771,
      "TXT_PINCODE_LOCALITY": "MAHOI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442997,
      "City District Name": "FARRUKHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209801,
      "TXT_PINCODE_LOCALITY": "UNNAO H O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 367,
      "City District Name": "UNNAO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209804,
      "TXT_PINCODE_LOCALITY": "LOHAR KHERA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 367,
      "City District Name": "UNNAO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209821,
      "TXT_PINCODE_LOCALITY": "AJGAIN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 367,
      "City District Name": "UNNAO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209825,
      "TXT_PINCODE_LOCALITY": "PURWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 367,
      "City District Name": "UNNAO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209827,
      "TXT_PINCODE_LOCALITY": "CHAMIANI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 367,
      "City District Name": "UNNAO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209831,
      "TXT_PINCODE_LOCALITY": "AJGAIN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 367,
      "City District Name": "UNNAO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209841,
      "TXT_PINCODE_LOCALITY": "UNNAO",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 367,
      "City District Name": "UNNAO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209857,
      "TXT_PINCODE_LOCALITY": "DARHETA MAHANT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5222,
      "City District Name": "PURWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209859,
      "TXT_PINCODE_LOCALITY": "NAWABGANJ (UNNAO)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 367,
      "City District Name": "UNNAO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209860,
      "TXT_PINCODE_LOCALITY": "UNNAO",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 367,
      "City District Name": "UNNAO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209861,
      "TXT_PINCODE_LOCALITY": "GANGAGHAT (UNNAO)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 367,
      "City District Name": "UNNAO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209862,
      "TXT_PINCODE_LOCALITY": "MANGARWARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209863,
      "TXT_PINCODE_LOCALITY": "DHANIKHERA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 367,
      "City District Name": "UNNAO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209864,
      "TXT_PINCODE_LOCALITY": "SAWAEN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 367,
      "City District Name": "UNNAO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209865,
      "TXT_PINCODE_LOCALITY": "BIGHAPUR S.O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 367,
      "City District Name": "UNNAO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209866,
      "TXT_PINCODE_LOCALITY": "ZAMIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 367,
      "City District Name": "UNNAO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209867,
      "TXT_PINCODE_LOCALITY": "INDEMAU B.O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 367,
      "City District Name": "UNNAO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209868,
      "TXT_PINCODE_LOCALITY": "RAJEPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 367,
      "City District Name": "UNNAO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209869,
      "TXT_PINCODE_LOCALITY": "RAGHURAMPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 367,
      "City District Name": "UNNAO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209870,
      "TXT_PINCODE_LOCALITY": "UDSHAH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 367,
      "City District Name": "UNNAO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209871,
      "TXT_PINCODE_LOCALITY": "MUNDHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 367,
      "City District Name": "UNNAO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 209881,
      "TXT_PINCODE_LOCALITY": "SHAHPUR TONDA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 367,
      "City District Name": "UNNAO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210001,
      "TXT_PINCODE_LOCALITY": "MATAUNDH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6326,
      "City District Name": "BANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210010,
      "TXT_PINCODE_LOCALITY": "NAINI TALUKA NAINI DADARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443005,
      "City District Name": "KARCHHANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210026,
      "TXT_PINCODE_LOCALITY": "NARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6326,
      "City District Name": "BANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210101,
      "TXT_PINCODE_LOCALITY": "STRUCTURALAINI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6326,
      "City District Name": "BANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210120,
      "TXT_PINCODE_LOCALITY": "KHURHAND R S",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6326,
      "City District Name": "BANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210121,
      "TXT_PINCODE_LOCALITY": "BABERU",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6326,
      "City District Name": "BANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210123,
      "TXT_PINCODE_LOCALITY": "CHILLATARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6326,
      "City District Name": "BANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210125,
      "TXT_PINCODE_LOCALITY": "BERRAON",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210126,
      "TXT_PINCODE_LOCALITY": "PAILANI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6326,
      "City District Name": "BANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210128,
      "TXT_PINCODE_LOCALITY": "TINDWARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6326,
      "City District Name": "BANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210129,
      "TXT_PINCODE_LOCALITY": "NARAINI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6326,
      "City District Name": "BANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210201,
      "TXT_PINCODE_LOCALITY": "ATTARRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6326,
      "City District Name": "BANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210202,
      "TXT_PINCODE_LOCALITY": "BADAUSA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6326,
      "City District Name": "BANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210203,
      "TXT_PINCODE_LOCALITY": "BISANDA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6326,
      "City District Name": "BANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210204,
      "TXT_PINCODE_LOCALITY": "CHITRAKOTE",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2816,
      "City District Name": "CHITRAKOOT DHAM (KARWI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210205,
      "TXT_PINCODE_LOCALITY": "RAJAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2816,
      "City District Name": "CHITRAKOOT DHAM (KARWI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210206,
      "TXT_PINCODE_LOCALITY": "PAHARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6326,
      "City District Name": "BANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210207,
      "TXT_PINCODE_LOCALITY": "SARDHUWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 441066,
      "City District Name": "CHITRAKOOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210208,
      "TXT_PINCODE_LOCALITY": "MANIKPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2816,
      "City District Name": "CHITRAKOOT DHAM (KARWI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210209,
      "TXT_PINCODE_LOCALITY": "MAUCHIBU",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6326,
      "City District Name": "BANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210210,
      "TXT_PINCODE_LOCALITY": "SARIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4727,
      "City District Name": "NARAINI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210216,
      "TXT_PINCODE_LOCALITY": "SONAMAU",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6326,
      "City District Name": "BANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210235,
      "TXT_PINCODE_LOCALITY": "KOTA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3829,
      "City District Name": "KARWI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210301,
      "TXT_PINCODE_LOCALITY": "SUMER PUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6340,
      "City District Name": "HAMIRPUR(U.P.)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210304,
      "TXT_PINCODE_LOCALITY": "BARDAHA SAHIJANA DANDA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6396,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210320,
      "TXT_PINCODE_LOCALITY": "KAITHI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6396,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210321,
      "TXT_PINCODE_LOCALITY": "HAMIRPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6396,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210332,
      "TXT_PINCODE_LOCALITY": "DEVKHURI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5527,
      "City District Name": "SARILA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210341,
      "TXT_PINCODE_LOCALITY": "BARWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6396,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210421,
      "TXT_PINCODE_LOCALITY": "CHARKHARI STATE",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4321,
      "City District Name": "MAHOBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210422,
      "TXT_PINCODE_LOCALITY": "JARIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6340,
      "City District Name": "HAMIRPUR(U.P.)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210423,
      "TXT_PINCODE_LOCALITY": "JAITPUR (HAMIRPUR)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6340,
      "City District Name": "HAMIRPUR(U.P.)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210424,
      "TXT_PINCODE_LOCALITY": "KABRAL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4321,
      "City District Name": "MAHOBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210425,
      "TXT_PINCODE_LOCALITY": "KHARELA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4321,
      "City District Name": "MAHOBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210426,
      "TXT_PINCODE_LOCALITY": "PANWARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4321,
      "City District Name": "MAHOBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210427,
      "TXT_PINCODE_LOCALITY": "MAHOBA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4321,
      "City District Name": "MAHOBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210428,
      "TXT_PINCODE_LOCALITY": "MAJHGAWAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6396,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210429,
      "TXT_PINCODE_LOCALITY": "PANWARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4321,
      "City District Name": "MAHOBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210430,
      "TXT_PINCODE_LOCALITY": "RAHANK",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6396,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210431,
      "TXT_PINCODE_LOCALITY": "RAATH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5343,
      "City District Name": "RATH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210432,
      "TXT_PINCODE_LOCALITY": "SARILA STATE",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6340,
      "City District Name": "HAMIRPUR(U.P.)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210433,
      "TXT_PINCODE_LOCALITY": "SRINAGAR (HAMIRPUR)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4321,
      "City District Name": "MAHOBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210434,
      "TXT_PINCODE_LOCALITY": "HAMIRPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6396,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210501,
      "TXT_PINCODE_LOCALITY": "BEWAR (HAMIRPUR)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6340,
      "City District Name": "HAMIRPUR(U.P.)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210502,
      "TXT_PINCODE_LOCALITY": "HAMIRPUR S. O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6396,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210504,
      "TXT_PINCODE_LOCALITY": "HAMIRPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6396,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210505,
      "TXT_PINCODE_LOCALITY": "KURARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6340,
      "City District Name": "HAMIRPUR(U.P.)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210506,
      "TXT_PINCODE_LOCALITY": "MUSKARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6340,
      "City District Name": "HAMIRPUR(U.P.)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210507,
      "TXT_PINCODE_LOCALITY": "RAGAUL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6340,
      "City District Name": "HAMIRPUR(U.P.)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 210523,
      "TXT_PINCODE_LOCALITY": "MEHRKA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4468,
      "City District Name": "MAUDAHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 211000,
      "TXT_PINCODE_LOCALITY": "SULTANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 211001,
      "TXT_PINCODE_LOCALITY": "M G MARG",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 211002,
      "TXT_PINCODE_LOCALITY": "RAJAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 211003,
      "TXT_PINCODE_LOCALITY": "LOWDHER ROAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 211004,
      "TXT_PINCODE_LOCALITY": "MUFORDGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 211005,
      "TXT_PINCODE_LOCALITY": "SATENLYROAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 211006,
      "TXT_PINCODE_LOCALITY": "TRANSPORTNAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 211007,
      "TXT_PINCODE_LOCALITY": "DANDI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 211008,
      "TXT_PINCODE_LOCALITY": "INDUSATRTIAL AREA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 211009,
      "TXT_PINCODE_LOCALITY": "INDUSATRTIAL AREA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 211010,
      "TXT_PINCODE_LOCALITY": "DANDI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 211011,
      "TXT_PINCODE_LOCALITY": "DHOOMANGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 211012,
      "TXT_PINCODE_LOCALITY": "BAMRAULI AIRPORT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 211013,
      "TXT_PINCODE_LOCALITY": "PHAPHAMAU",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 211014,
      "TXT_PINCODE_LOCALITY": "C D A (P)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 211015,
      "TXT_PINCODE_LOCALITY": "SUBEDARGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 211016,
      "TXT_PINCODE_LOCALITY": "PURSHOTAMNAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 211017,
      "TXT_PINCODE_LOCALITY": "PATHSHALA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 211018,
      "TXT_PINCODE_LOCALITY": "HIGH COURT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 211019,
      "TXT_PINCODE_LOCALITY": "JHUNSI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 211020,
      "TXT_PINCODE_LOCALITY": "MIRZAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 885,
      "City District Name": "MIRZAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 211021,
      "TXT_PINCODE_LOCALITY": "REWA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 211022,
      "TXT_PINCODE_LOCALITY": "ALLAHABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 211030,
      "TXT_PINCODE_LOCALITY": "MIRZAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 885,
      "City District Name": "MIRZAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 211042,
      "TXT_PINCODE_LOCALITY": "MEHJOULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5343,
      "City District Name": "RATH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 211205,
      "TXT_PINCODE_LOCALITY": "RATANSAINPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443029,
      "City District Name": "SORAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 211502,
      "TXT_PINCODE_LOCALITY": "HANDIA S. O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212104,
      "TXT_PINCODE_LOCALITY": "BHARATGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212105,
      "TXT_PINCODE_LOCALITY": "CHHEOKI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212106,
      "TXT_PINCODE_LOCALITY": "JARI BAZAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212107,
      "TXT_PINCODE_LOCALITY": "JASRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212108,
      "TXT_PINCODE_LOCALITY": "SHANKARGARH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212109,
      "TXT_PINCODE_LOCALITY": "SIKANDRA (ALLAHABAD)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212110,
      "TXT_PINCODE_LOCALITY": "MANAKWAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442986,
      "City District Name": "BARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212111,
      "TXT_PINCODE_LOCALITY": "DANDUPUR S.O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212128,
      "TXT_PINCODE_LOCALITY": "ALIGARH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212132,
      "TXT_PINCODE_LOCALITY": "PLHANA KACHHAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2655,
      "City District Name": "CHAIL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212152,
      "TXT_PINCODE_LOCALITY": "GAURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3902,
      "City District Name": "KHAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212201,
      "TXT_PINCODE_LOCALITY": "BHARWARI *",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212202,
      "TXT_PINCODE_LOCALITY": "CHAIL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6339,
      "City District Name": "KAUSHAMBI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212203,
      "TXT_PINCODE_LOCALITY": "CHARWA.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6339,
      "City District Name": "KAUSHAMBI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212204,
      "TXT_PINCODE_LOCALITY": "DARA NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212205,
      "TXT_PINCODE_LOCALITY": "KAUSHAMBI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6339,
      "City District Name": "KAUSHAMBI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212206,
      "TXT_PINCODE_LOCALITY": "CHARWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6339,
      "City District Name": "KAUSHAMBI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212207,
      "TXT_PINCODE_LOCALITY": "MANJHAMPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6339,
      "City District Name": "KAUSHAMBI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212208,
      "TXT_PINCODE_LOCALITY": "MANAURI S.O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212209,
      "TXT_PINCODE_LOCALITY": "JALALPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2655,
      "City District Name": "CHAIL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212210,
      "TXT_PINCODE_LOCALITY": "PURA MUFTI ED",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6339,
      "City District Name": "KAUSHAMBI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212212,
      "TXT_PINCODE_LOCALITY": "MANAURI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212213,
      "TXT_PINCODE_LOCALITY": "MAHGAON",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6339,
      "City District Name": "KAUSHAMBI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212214,
      "TXT_PINCODE_LOCALITY": "MALAKA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6339,
      "City District Name": "KAUSHAMBI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212215,
      "TXT_PINCODE_LOCALITY": "ALLAHABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212216,
      "TXT_PINCODE_LOCALITY": "SARAI AKIL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6339,
      "City District Name": "KAUSHAMBI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212217,
      "TXT_PINCODE_LOCALITY": "SIRATHU",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6339,
      "City District Name": "KAUSHAMBI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212218,
      "TXT_PINCODE_LOCALITY": "TILHAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6339,
      "City District Name": "KAUSHAMBI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212301,
      "TXT_PINCODE_LOCALITY": "MUNGARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212302,
      "TXT_PINCODE_LOCALITY": "MEJA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212303,
      "TXT_PINCODE_LOCALITY": "KATRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212304,
      "TXT_PINCODE_LOCALITY": "ALLAHABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212305,
      "TXT_PINCODE_LOCALITY": "SARSA H.O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212306,
      "TXT_PINCODE_LOCALITY": "SECRETARIAT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212307,
      "TXT_PINCODE_LOCALITY": "KOREON",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212308,
      "TXT_PINCODE_LOCALITY": "SARAY KALA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443014,
      "City District Name": "MEJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212321,
      "TXT_PINCODE_LOCALITY": "TARSHAURA UPERHAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5701,
      "City District Name": "SIRATHU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212401,
      "TXT_PINCODE_LOCALITY": "JANGHAI BAZAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212402,
      "TXT_PINCODE_LOCALITY": "SARSA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212403,
      "TXT_PINCODE_LOCALITY": "ALLAHABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212404,
      "TXT_PINCODE_LOCALITY": "GHIYA NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212405,
      "TXT_PINCODE_LOCALITY": "BIBIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212406,
      "TXT_PINCODE_LOCALITY": "GOSAISIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3359,
      "City District Name": "HANDIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212407,
      "TXT_PINCODE_LOCALITY": "PURAWA KHAS.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443005,
      "City District Name": "KARCHHANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212410,
      "TXT_PINCODE_LOCALITY": "ALLAHABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212413,
      "TXT_PINCODE_LOCALITY": "LALGOPALGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212420,
      "TXT_PINCODE_LOCALITY": "MATHOE",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3359,
      "City District Name": "HANDIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212427,
      "TXT_PINCODE_LOCALITY": "SHAHJAHANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212501,
      "TXT_PINCODE_LOCALITY": "ALLAHABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212502,
      "TXT_PINCODE_LOCALITY": "KEOLARIYA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212503,
      "TXT_PINCODE_LOCALITY": "HOLAGARH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212507,
      "TXT_PINCODE_LOCALITY": "MAU -AIMA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212601,
      "TXT_PINCODE_LOCALITY": "FATEHPUR H O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1313,
      "City District Name": "BARABANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212606,
      "TXT_PINCODE_LOCALITY": "RAMPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1770,
      "City District Name": "FATEHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212620,
      "TXT_PINCODE_LOCALITY": "FATEHPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1770,
      "City District Name": "FATEHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212621,
      "TXT_PINCODE_LOCALITY": "SHANKHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1770,
      "City District Name": "FATEHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212622,
      "TXT_PINCODE_LOCALITY": "BAHRAMPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1770,
      "City District Name": "FATEHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212623,
      "TXT_PINCODE_LOCALITY": "GEDURI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1770,
      "City District Name": "FATEHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212625,
      "TXT_PINCODE_LOCALITY": "NAROTTAMPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3902,
      "City District Name": "KHAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212626,
      "TXT_PINCODE_LOCALITY": "RAWATPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1770,
      "City District Name": "FATEHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212627,
      "TXT_PINCODE_LOCALITY": "GAJAIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2576,
      "City District Name": "BINDKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212631,
      "TXT_PINCODE_LOCALITY": "AMAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1770,
      "City District Name": "FATEHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212635,
      "TXT_PINCODE_LOCALITY": "BINDKI - 793",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1770,
      "City District Name": "FATEHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212641,
      "TXT_PINCODE_LOCALITY": "DHATA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1770,
      "City District Name": "FATEHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212645,
      "TXT_PINCODE_LOCALITY": "HASWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1770,
      "City District Name": "FATEHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212650,
      "TXT_PINCODE_LOCALITY": "FATEHPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1770,
      "City District Name": "FATEHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212651,
      "TXT_PINCODE_LOCALITY": "MAUJMABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1770,
      "City District Name": "FATEHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212652,
      "TXT_PINCODE_LOCALITY": "HATHGAON",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1770,
      "City District Name": "FATEHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212653,
      "TXT_PINCODE_LOCALITY": "ERAYAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1770,
      "City District Name": "FATEHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212654,
      "TXT_PINCODE_LOCALITY": "JAMRAON",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1770,
      "City District Name": "FATEHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212655,
      "TXT_PINCODE_LOCALITY": "KHAGA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1770,
      "City District Name": "FATEHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212656,
      "TXT_PINCODE_LOCALITY": "KHAKRERU",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1770,
      "City District Name": "FATEHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212657,
      "TXT_PINCODE_LOCALITY": "KHAJUHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1313,
      "City District Name": "BARABANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212658,
      "TXT_PINCODE_LOCALITY": "KISHANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1770,
      "City District Name": "FATEHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212659,
      "TXT_PINCODE_LOCALITY": "KORAJAHANABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1770,
      "City District Name": "FATEHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212660,
      "TXT_PINCODE_LOCALITY": "CHAK MUBARAKPUR GAUNTI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3902,
      "City District Name": "KHAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212661,
      "TXT_PINCODE_LOCALITY": "LALAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1770,
      "City District Name": "FATEHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212662,
      "TXT_PINCODE_LOCALITY": "LAHURAJ KHERA.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2576,
      "City District Name": "BINDKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212663,
      "TXT_PINCODE_LOCALITY": "DUGRAI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1770,
      "City District Name": "FATEHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212664,
      "TXT_PINCODE_LOCALITY": "MALWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1770,
      "City District Name": "FATEHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212665,
      "TXT_PINCODE_LOCALITY": "MAUHAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1770,
      "City District Name": "FATEHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 212666,
      "TXT_PINCODE_LOCALITY": "RASULPUR BHANDRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3902,
      "City District Name": "KHAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 213113,
      "TXT_PINCODE_LOCALITY": "FATEHPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1770,
      "City District Name": "FATEHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 213204,
      "TXT_PINCODE_LOCALITY": "ARAJI AKORA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443014,
      "City District Name": "MEJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 213210,
      "TXT_PINCODE_LOCALITY": "SEMARI LAUHARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4072,
      "City District Name": "KORAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 213306,
      "TXT_PINCODE_LOCALITY": "KARPIYA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4072,
      "City District Name": "KORAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 215126,
      "TXT_PINCODE_LOCALITY": "FIROZABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 939,
      "City District Name": "FIROZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 215653,
      "TXT_PINCODE_LOCALITY": "HISAMMUDDINPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3902,
      "City District Name": "KHAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 216221,
      "TXT_PINCODE_LOCALITY": "MOHAMMADPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1770,
      "City District Name": "FATEHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 216252,
      "TXT_PINCODE_LOCALITY": "RAGHUNATHPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3902,
      "City District Name": "KHAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 216305,
      "TXT_PINCODE_LOCALITY": "UCHADIH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443014,
      "City District Name": "MEJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 216306,
      "TXT_PINCODE_LOCALITY": "NIBI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443014,
      "City District Name": "MEJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 220022,
      "TXT_PINCODE_LOCALITY": "JAUNPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 220133,
      "TXT_PINCODE_LOCALITY": "BELAHARI *",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 220141,
      "TXT_PINCODE_LOCALITY": "AMILIA SIKARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 220155,
      "TXT_PINCODE_LOCALITY": "BHANDRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 220162,
      "TXT_PINCODE_LOCALITY": "AMLAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4360,
      "City District Name": "MALIHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 220304,
      "TXT_PINCODE_LOCALITY": "BAHADURPUR DA JAYAS",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443032,
      "City District Name": "TILOI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 220325,
      "TXT_PINCODE_LOCALITY": "HARDUAGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1770,
      "City District Name": "FATEHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 220905,
      "TXT_PINCODE_LOCALITY": "PURE BADYRAI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443032,
      "City District Name": "TILOI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221001,
      "TXT_PINCODE_LOCALITY": "SARWA SEWA SANGH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221002,
      "TXT_PINCODE_LOCALITY": "NADESAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221003,
      "TXT_PINCODE_LOCALITY": "SHEOPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221004,
      "TXT_PINCODE_LOCALITY": "D L W VARANASI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221005,
      "TXT_PINCODE_LOCALITY": "DURGA KUND",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221006,
      "TXT_PINCODE_LOCALITY": "BABATPUR AEORDROME",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221007,
      "TXT_PINCODE_LOCALITY": "SARNATH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221008,
      "TXT_PINCODE_LOCALITY": "P A C RAMNAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221009,
      "TXT_PINCODE_LOCALITY": "SAHUPURI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221010,
      "TXT_PINCODE_LOCALITY": "MAHMMORGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221011,
      "TXT_PINCODE_LOCALITY": "BACHHAON B.O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221012,
      "TXT_PINCODE_LOCALITY": "VARANASI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221062,
      "TXT_PINCODE_LOCALITY": "LUCKNOW",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221071,
      "TXT_PINCODE_LOCALITY": "LOOTH KHURD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221101,
      "TXT_PINCODE_LOCALITY": "CHOLAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221102,
      "TXT_PINCODE_LOCALITY": "KUSTA ASHRAM",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221103,
      "TXT_PINCODE_LOCALITY": "MANDAUDIH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221104,
      "TXT_PINCODE_LOCALITY": "CHAUBEYPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221105,
      "TXT_PINCODE_LOCALITY": "HARHUA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221106,
      "TXT_PINCODE_LOCALITY": "INDUSTRIAL ESTATE",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221107,
      "TXT_PINCODE_LOCALITY": "BARLA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221108,
      "TXT_PINCODE_LOCALITY": "BULLANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221109,
      "TXT_PINCODE_LOCALITY": "BAJARDHIHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221110,
      "TXT_PINCODE_LOCALITY": "BASANT NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221111,
      "TXT_PINCODE_LOCALITY": "BARTHARA KALAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2685,
      "City District Name": "CHANDAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221112,
      "TXT_PINCODE_LOCALITY": "CHIRAIGAOW",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221113,
      "TXT_PINCODE_LOCALITY": "DHORHARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221115,
      "TXT_PINCODE_LOCALITY": "MUSTAFABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221116,
      "TXT_PINCODE_LOCALITY": "TEKURI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221131,
      "TXT_PINCODE_LOCALITY": "SIKRARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221141,
      "TXT_PINCODE_LOCALITY": "AMSIN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221154,
      "TXT_PINCODE_LOCALITY": "SULEM PUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5395,
      "City District Name": "RUDAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221168,
      "TXT_PINCODE_LOCALITY": "CHUET PARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1961,
      "City District Name": "AKBARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221201,
      "TXT_PINCODE_LOCALITY": "ANAI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221202,
      "TXT_PINCODE_LOCALITY": "BABATPUR R S (BARAGAON)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221203,
      "TXT_PINCODE_LOCALITY": "MARAHMAT NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4319,
      "City District Name": "MAHMUDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221204,
      "TXT_PINCODE_LOCALITY": "BARAGAON (VARANASI)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2685,
      "City District Name": "CHANDAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221205,
      "TXT_PINCODE_LOCALITY": "PHULPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221206,
      "TXT_PINCODE_LOCALITY": "PINDRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221207,
      "TXT_PINCODE_LOCALITY": "KATHIRAON",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221208,
      "TXT_PINCODE_LOCALITY": "SIGHAURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221209,
      "TXT_PINCODE_LOCALITY": "AKORHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2685,
      "City District Name": "CHANDAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221210,
      "TXT_PINCODE_LOCALITY": "ASAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221211,
      "TXT_PINCODE_LOCALITY": "KAWAKPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221214,
      "TXT_PINCODE_LOCALITY": "MAKDUMPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1792,
      "City District Name": "BHADOHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221223,
      "TXT_PINCODE_LOCALITY": "DARBARA.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442991,
      "City District Name": "BURHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221300,
      "TXT_PINCODE_LOCALITY": "NAZARPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3341,
      "City District Name": "GYANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221301,
      "TXT_PINCODE_LOCALITY": "AURAI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2685,
      "City District Name": "CHANDAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221302,
      "TXT_PINCODE_LOCALITY": "GANGAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221303,
      "TXT_PINCODE_LOCALITY": "GOPIGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424421,
      "City District Name": "SANT RAVIDAS NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221304,
      "TXT_PINCODE_LOCALITY": "GYANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424421,
      "City District Name": "SANT RAVIDAS NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221305,
      "TXT_PINCODE_LOCALITY": "JAKHNI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221306,
      "TXT_PINCODE_LOCALITY": "KHAMARIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424421,
      "City District Name": "SANT RAVIDAS NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221307,
      "TXT_PINCODE_LOCALITY": "THATHARA B.O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221308,
      "TXT_PINCODE_LOCALITY": "OZH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221309,
      "TXT_PINCODE_LOCALITY": "KOIRANA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424421,
      "City District Name": "SANT RAVIDAS NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221310,
      "TXT_PINCODE_LOCALITY": "JANGIGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221311,
      "TXT_PINCODE_LOCALITY": "RAJA TALAB",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221312,
      "TXT_PINCODE_LOCALITY": "BICHIYA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424421,
      "City District Name": "SANT RAVIDAS NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221313,
      "TXT_PINCODE_LOCALITY": "KACHHAWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221314,
      "TXT_PINCODE_LOCALITY": "JAGATPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221401,
      "TXT_PINCODE_LOCALITY": "RAYA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3341,
      "City District Name": "GYANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221402,
      "TXT_PINCODE_LOCALITY": "PARSIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424421,
      "City District Name": "SANT RAVIDAS NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221403,
      "TXT_PINCODE_LOCALITY": "VARANASI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221404,
      "TXT_PINCODE_LOCALITY": "SURIYAWAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424421,
      "City District Name": "SANT RAVIDAS NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221405,
      "TXT_PINCODE_LOCALITY": "HATHI BAZAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221406,
      "TXT_PINCODE_LOCALITY": "GYANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424421,
      "City District Name": "SANT RAVIDAS NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221407,
      "TXT_PINCODE_LOCALITY": "BARHA BAZAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221408,
      "TXT_PINCODE_LOCALITY": "HATHI BAZAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221409,
      "TXT_PINCODE_LOCALITY": "NAI BAZAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424421,
      "City District Name": "SANT RAVIDAS NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221501,
      "TXT_PINCODE_LOCALITY": "KACHWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 409668,
      "City District Name": "MIRZAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221502,
      "TXT_PINCODE_LOCALITY": "SARIRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221503,
      "TXT_PINCODE_LOCALITY": "HANDIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221504,
      "TXT_PINCODE_LOCALITY": "ALLAHABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221505,
      "TXT_PINCODE_LOCALITY": "SARAI MAWREZ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221507,
      "TXT_PINCODE_LOCALITY": "SAHSON",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221508,
      "TXT_PINCODE_LOCALITY": "SAIDABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221509,
      "TXT_PINCODE_LOCALITY": "MALAWAN KHURD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5122,
      "City District Name": "PHULPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221601,
      "TXT_PINCODE_LOCALITY": "DUBARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6531,
      "City District Name": "MAU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221602,
      "TXT_PINCODE_LOCALITY": "MARYADPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6531,
      "City District Name": "MAU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221603,
      "TXT_PINCODE_LOCALITY": "MADHUBAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221606,
      "TXT_PINCODE_LOCALITY": "PALIYA BUZURG",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443010,
      "City District Name": "MADHUBAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221701,
      "TXT_PINCODE_LOCALITY": "CHILKAHAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221705,
      "TXT_PINCODE_LOCALITY": "HALDHARPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221706,
      "TXT_PINCODE_LOCALITY": "RATANPURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221709,
      "TXT_PINCODE_LOCALITY": "GARHAMALPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221711,
      "TXT_PINCODE_LOCALITY": "NAGRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221712,
      "TXT_PINCODE_LOCALITY": "NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221713,
      "TXT_PINCODE_LOCALITY": "CHITBARAGAON",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221714,
      "TXT_PINCODE_LOCALITY": "KARON",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221715,
      "TXT_PINCODE_LOCALITY": "BILTHRA ROAD R S",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221716,
      "TXT_PINCODE_LOCALITY": "KIDIHADAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221717,
      "TXT_PINCODE_LOCALITY": "NAVANAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221718,
      "TXT_PINCODE_LOCALITY": "KHANDWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221719,
      "TXT_PINCODE_LOCALITY": "THAKDEWRI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221720,
      "TXT_PINCODE_LOCALITY": "LBRAHIMPATTRI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221721,
      "TXT_PINCODE_LOCALITY": "SANWARH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221722,
      "TXT_PINCODE_LOCALITY": "KOTWARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221802,
      "TXT_PINCODE_LOCALITY": "BELAHI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221808,
      "TXT_PINCODE_LOCALITY": "DHANSIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3359,
      "City District Name": "HANDIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221861,
      "TXT_PINCODE_LOCALITY": "DAKAHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5602,
      "City District Name": "SHAHGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 221881,
      "TXT_PINCODE_LOCALITY": "RUPAIDIHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2241,
      "City District Name": "BAHRAICH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222001,
      "TXT_PINCODE_LOCALITY": "PUKHTA SARAI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222002,
      "TXT_PINCODE_LOCALITY": "UMARPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222003,
      "TXT_PINCODE_LOCALITY": "SONIKPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5602,
      "City District Name": "SHAHGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222005,
      "TXT_PINCODE_LOCALITY": "SOITHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4448,
      "City District Name": "MARIAHU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222101,
      "TXT_PINCODE_LOCALITY": "BELWAI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222102,
      "TXT_PINCODE_LOCALITY": "BARAGAON",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 360,
      "City District Name": "JHANSI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222103,
      "TXT_PINCODE_LOCALITY": "RAMNAGAR WITH MARWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222104,
      "TXT_PINCODE_LOCALITY": "KUDDUPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222105,
      "TXT_PINCODE_LOCALITY": "RAM DAYALGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222107,
      "TXT_PINCODE_LOCALITY": "BIRAMPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222108,
      "TXT_PINCODE_LOCALITY": "BAKSA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222109,
      "TXT_PINCODE_LOCALITY": "NAU PAWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222110,
      "TXT_PINCODE_LOCALITY": "I I T SIDDIQPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222111,
      "TXT_PINCODE_LOCALITY": "GHANSHYAMPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222112,
      "TXT_PINCODE_LOCALITY": "SARAI KHWAJA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222113,
      "TXT_PINCODE_LOCALITY": "JAUNPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222120,
      "TXT_PINCODE_LOCALITY": "SAIDUPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4448,
      "City District Name": "MARIAHU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222123,
      "TXT_PINCODE_LOCALITY": "SARAI YUSUF",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4273,
      "City District Name": "MACHHLISHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222125,
      "TXT_PINCODE_LOCALITY": "BADIAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222127,
      "TXT_PINCODE_LOCALITY": "BANRERI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222128,
      "TXT_PINCODE_LOCALITY": "NAURAHYA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222129,
      "TXT_PINCODE_LOCALITY": "CHANDWAK",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222131,
      "TXT_PINCODE_LOCALITY": "SIKRARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222132,
      "TXT_PINCODE_LOCALITY": "FATEHGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222133,
      "TXT_PINCODE_LOCALITY": "GAURABADSHAHPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222134,
      "TXT_PINCODE_LOCALITY": "PARANPUR.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4448,
      "City District Name": "MARIAHU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222135,
      "TXT_PINCODE_LOCALITY": "AHORWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222136,
      "TXT_PINCODE_LOCALITY": "TRILOCHAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222137,
      "TXT_PINCODE_LOCALITY": "JAMALPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222138,
      "TXT_PINCODE_LOCALITY": "KAJGAON",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222139,
      "TXT_PINCODE_LOCALITY": "KHETA SARAI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222141,
      "TXT_PINCODE_LOCALITY": "SARAI HARKHOO",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222142,
      "TXT_PINCODE_LOCALITY": "KERAKAT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222143,
      "TXT_PINCODE_LOCALITY": "MACHHLISHAHAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222144,
      "TXT_PINCODE_LOCALITY": "ITAHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222145,
      "TXT_PINCODE_LOCALITY": "MAHARAJGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222146,
      "TXT_PINCODE_LOCALITY": "KUTIRCHAKEY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222148,
      "TXT_PINCODE_LOCALITY": "LEORUA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222149,
      "TXT_PINCODE_LOCALITY": "S R G DEGREE COLLEGE DOBH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222156,
      "TXT_PINCODE_LOCALITY": "SIRAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4448,
      "City District Name": "MARIAHU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222161,
      "TXT_PINCODE_LOCALITY": "MARIAHU",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222162,
      "TXT_PINCODE_LOCALITY": "BARSATHI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222163,
      "TXT_PINCODE_LOCALITY": "PURA LAKSHMI NARAIN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4448,
      "City District Name": "MARIAHU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222164,
      "TXT_PINCODE_LOCALITY": "SARAUNA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4448,
      "City District Name": "MARIAHU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222165,
      "TXT_PINCODE_LOCALITY": "MIRGANJ (JAUNPUR)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222169,
      "TXT_PINCODE_LOCALITY": "KASERAWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4273,
      "City District Name": "MACHHLISHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222170,
      "TXT_PINCODE_LOCALITY": "JAUNPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222175,
      "TXT_PINCODE_LOCALITY": "SINGHRAMAU",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222180,
      "TXT_PINCODE_LOCALITY": "ZAFRABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222181,
      "TXT_PINCODE_LOCALITY": "BEHARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222188,
      "TXT_PINCODE_LOCALITY": "LACHHI PATTI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5602,
      "City District Name": "SHAHGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222200,
      "TXT_PINCODE_LOCALITY": "RIZWIKHAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222201,
      "TXT_PINCODE_LOCALITY": "BALWARGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222202,
      "TXT_PINCODE_LOCALITY": "MUNGRABADSHAHPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222203,
      "TXT_PINCODE_LOCALITY": "RAMPUR (JAUNPUR)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222204,
      "TXT_PINCODE_LOCALITY": "NEEBHAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222213,
      "TXT_PINCODE_LOCALITY": "YOUNUSPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5602,
      "City District Name": "SHAHGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222220,
      "TXT_PINCODE_LOCALITY": "HARIHAR PUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4448,
      "City District Name": "MARIAHU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222226,
      "TXT_PINCODE_LOCALITY": "PUSHPNAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222301,
      "TXT_PINCODE_LOCALITY": "KOIRIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222302,
      "TXT_PINCODE_LOCALITY": "LAMBHUA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222303,
      "TXT_PINCODE_LOCALITY": "PACCHIM",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222304,
      "TXT_PINCODE_LOCALITY": "BARK ARA (SULTANPUR)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222311,
      "TXT_PINCODE_LOCALITY": "GOPAL CHAK",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222319,
      "TXT_PINCODE_LOCALITY": "JAHIRUDDINPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5602,
      "City District Name": "SHAHGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222323,
      "TXT_PINCODE_LOCALITY": "ITAURI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442991,
      "City District Name": "BURHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222401,
      "TXT_PINCODE_LOCALITY": "MAHAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4273,
      "City District Name": "MACHHLISHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222404,
      "TXT_PINCODE_LOCALITY": "ZALIBPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5602,
      "City District Name": "SHAHGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222420,
      "TXT_PINCODE_LOCALITY": "TARIIYA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442989,
      "City District Name": "BHITI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222602,
      "TXT_PINCODE_LOCALITY": "BHARSARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222709,
      "TXT_PINCODE_LOCALITY": "ITAUJAPACHHIM",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442998,
      "City District Name": "GAURIGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222747,
      "TXT_PINCODE_LOCALITY": "VALHARE",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1961,
      "City District Name": "AKBARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222802,
      "TXT_PINCODE_LOCALITY": "BIDHAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222903,
      "TXT_PINCODE_LOCALITY": "KARAN GAON",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443032,
      "City District Name": "TILOI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 222909,
      "TXT_PINCODE_LOCALITY": "USUFNAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443032,
      "City District Name": "TILOI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 223101,
      "TXT_PINCODE_LOCALITY": "SHAHGANJ S MILL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 223102,
      "TXT_PINCODE_LOCALITY": "PATTI NARENDRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 223103,
      "TXT_PINCODE_LOCALITY": "SARAI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 223104,
      "TXT_PINCODE_LOCALITY": "MANI KALAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 223105,
      "TXT_PINCODE_LOCALITY": "SOETHA KALAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 223106,
      "TXT_PINCODE_LOCALITY": "JAUNPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 223107,
      "TXT_PINCODE_LOCALITY": "PILKICHHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 223108,
      "TXT_PINCODE_LOCALITY": "SHAHGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5602,
      "City District Name": "SHAHGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 223120,
      "TXT_PINCODE_LOCALITY": "JAINPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5602,
      "City District Name": "SHAHGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 223159,
      "TXT_PINCODE_LOCALITY": "MAJHILEGOAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443009,
      "City District Name": "LAMBHUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 223181,
      "TXT_PINCODE_LOCALITY": "AMILIYA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3649,
      "City District Name": "KADIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 223212,
      "TXT_PINCODE_LOCALITY": "SADI PATTI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442991,
      "City District Name": "BURHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 223221,
      "TXT_PINCODE_LOCALITY": "AHRAULA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 223222,
      "TXT_PINCODE_LOCALITY": "AMBARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 223223,
      "TXT_PINCODE_LOCALITY": "ATRAULIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 223224,
      "TXT_PINCODE_LOCALITY": "DIDARGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 223225,
      "TXT_PINCODE_LOCALITY": "MAHUL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 223226,
      "TXT_PINCODE_LOCALITY": "PUSHPNAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 223227,
      "TXT_PINCODE_LOCALITY": "SANJARPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 223228,
      "TXT_PINCODE_LOCALITY": "MAIN PARPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6418,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 223231,
      "TXT_PINCODE_LOCALITY": "SHAHPUR CHAGAUNA.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442991,
      "City District Name": "BURHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 223232,
      "TXT_PINCODE_LOCALITY": "FADAGUDIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5122,
      "City District Name": "PHULPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 223241,
      "TXT_PINCODE_LOCALITY": "PATHIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442991,
      "City District Name": "BURHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 223401,
      "TXT_PINCODE_LOCALITY": "SEKHUPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2868,
      "City District Name": "DALMAU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 223525,
      "TXT_PINCODE_LOCALITY": "DOST PUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5122,
      "City District Name": "PHULPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 223706,
      "TXT_PINCODE_LOCALITY": "SARPATHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5602,
      "City District Name": "SHAHGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 223924,
      "TXT_PINCODE_LOCALITY": "SISWARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5122,
      "City District Name": "PHULPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224000,
      "TXT_PINCODE_LOCALITY": "MUMTAJ NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443028,
      "City District Name": "SOHAWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224001,
      "TXT_PINCODE_LOCALITY": "SAHEBGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224101,
      "TXT_PINCODE_LOCALITY": "HANSA PUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224105,
      "TXT_PINCODE_LOCALITY": "MIRJAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3516,
      "City District Name": "JALALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224109,
      "TXT_PINCODE_LOCALITY": "KHJURE",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442989,
      "City District Name": "BHITI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224110,
      "TXT_PINCODE_LOCALITY": "VIJAY GAON",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1961,
      "City District Name": "AKBARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224116,
      "TXT_PINCODE_LOCALITY": "RAMMAU",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5395,
      "City District Name": "RUDAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224117,
      "TXT_PINCODE_LOCALITY": "SANDAVABHELU",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443022,
      "City District Name": "RAMSANEHIGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224118,
      "TXT_PINCODE_LOCALITY": "SHERPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1313,
      "City District Name": "BARABANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224119,
      "TXT_PINCODE_LOCALITY": "BELHARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443022,
      "City District Name": "RAMSANEHIGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224120,
      "TXT_PINCODE_LOCALITY": "RUDAULI S.O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224121,
      "TXT_PINCODE_LOCALITY": "AMANIGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224122,
      "TXT_PINCODE_LOCALITY": "SHAHZADPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224123,
      "TXT_PINCODE_LOCALITY": "AYODHYA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224124,
      "TXT_PINCODE_LOCALITY": "CHAKIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424422,
      "City District Name": "AMBEDKAR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224125,
      "TXT_PINCODE_LOCALITY": "BANDIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424422,
      "City District Name": "AMBEDKAR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224126,
      "TXT_PINCODE_LOCALITY": "BARAGAON (FAIZABAD)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424422,
      "City District Name": "AMBEDKAR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224127,
      "TXT_PINCODE_LOCALITY": "BARU",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224128,
      "TXT_PINCODE_LOCALITY": "KOTDEEH SARRAIYA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443028,
      "City District Name": "SOHAWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224129,
      "TXT_PINCODE_LOCALITY": "BASKHARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424422,
      "City District Name": "AMBEDKAR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224131,
      "TXT_PINCODE_LOCALITY": "PATADHAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442991,
      "City District Name": "BURHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224132,
      "TXT_PINCODE_LOCALITY": "BANGAON B.O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424422,
      "City District Name": "AMBEDKAR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224133,
      "TXT_PINCODE_LOCALITY": "DABHASOMAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224135,
      "TXT_PINCODE_LOCALITY": "DARSHAN NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224136,
      "TXT_PINCODE_LOCALITY": "SABHARPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3649,
      "City District Name": "KADIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224137,
      "TXT_PINCODE_LOCALITY": "DEORIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224138,
      "TXT_PINCODE_LOCALITY": "RAMDUTTPUR AWTARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224139,
      "TXT_PINCODE_LOCALITY": "DULHUPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424422,
      "City District Name": "AMBEDKAR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224140,
      "TXT_PINCODE_LOCALITY": "SALARPUR RAJAUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1832,
      "City District Name": "TANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224141,
      "TXT_PINCODE_LOCALITY": "ALLAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424422,
      "City District Name": "AMBEDKAR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224142,
      "TXT_PINCODE_LOCALITY": "GANNE PUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1832,
      "City District Name": "TANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224143,
      "TXT_PINCODE_LOCALITY": "HANSWAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424422,
      "City District Name": "AMBEDKAR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224145,
      "TXT_PINCODE_LOCALITY": "ILTIFATGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424422,
      "City District Name": "AMBEDKAR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224146,
      "TXT_PINCODE_LOCALITY": "JAFARGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424422,
      "City District Name": "AMBEDKAR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224147,
      "TXT_PINCODE_LOCALITY": "JAHANGIRGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424422,
      "City District Name": "AMBEDKAR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224149,
      "TXT_PINCODE_LOCALITY": "JALALPUR - 1911",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424422,
      "City District Name": "AMBEDKAR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224151,
      "TXT_PINCODE_LOCALITY": "KATEHRI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424422,
      "City District Name": "AMBEDKAR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224152,
      "TXT_PINCODE_LOCALITY": "MIJHAURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424422,
      "City District Name": "AMBEDKAR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224153,
      "TXT_PINCODE_LOCALITY": "KHANDASA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424422,
      "City District Name": "AMBEDKAR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224154,
      "TXT_PINCODE_LOCALITY": "BARISAHIJA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3649,
      "City District Name": "KADIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224155,
      "TXT_PINCODE_LOCALITY": "KICHHAUCHHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424422,
      "City District Name": "AMBEDKAR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224156,
      "TXT_PINCODE_LOCALITY": "TENDUA DIARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3516,
      "City District Name": "JALALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224157,
      "TXT_PINCODE_LOCALITY": "KHEMAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424422,
      "City District Name": "AMBEDKAR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224158,
      "TXT_PINCODE_LOCALITY": "KINHUPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224159,
      "TXT_PINCODE_LOCALITY": "MALIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424422,
      "City District Name": "AMBEDKAR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224161,
      "TXT_PINCODE_LOCALITY": "MAYA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224164,
      "TXT_PINCODE_LOCALITY": "MILKIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224166,
      "TXT_PINCODE_LOCALITY": "MITTOOPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224167,
      "TXT_PINCODE_LOCALITY": "MEHARUAGALA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224168,
      "TXT_PINCODE_LOCALITY": "PAHITIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224171,
      "TXT_PINCODE_LOCALITY": "PURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224172,
      "TXT_PINCODE_LOCALITY": "RASULABAD (FAIZABAD)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224175,
      "TXT_PINCODE_LOCALITY": "RAJESHULTANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424422,
      "City District Name": "AMBEDKAR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224176,
      "TXT_PINCODE_LOCALITY": "RAJESULTANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424422,
      "City District Name": "AMBEDKAR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224181,
      "TXT_PINCODE_LOCALITY": "RAMNAGAR (FAIZABAD)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224182,
      "TXT_PINCODE_LOCALITY": "RAUNAHI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224183,
      "TXT_PINCODE_LOCALITY": "DEORHI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424422,
      "City District Name": "AMBEDKAR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224184,
      "TXT_PINCODE_LOCALITY": "SHAHGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224186,
      "TXT_PINCODE_LOCALITY": "SIKANDERPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224187,
      "TXT_PINCODE_LOCALITY": "NAYA GAON",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1990,
      "City District Name": "ALLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224188,
      "TXT_PINCODE_LOCALITY": "SHOHAWAL R S",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224189,
      "TXT_PINCODE_LOCALITY": "DEORHI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224190,
      "TXT_PINCODE_LOCALITY": "TANDA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424422,
      "City District Name": "AMBEDKAR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224191,
      "TXT_PINCODE_LOCALITY": "MATHURAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3516,
      "City District Name": "JALALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224193,
      "TXT_PINCODE_LOCALITY": "EKULLAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3516,
      "City District Name": "JALALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224195,
      "TXT_PINCODE_LOCALITY": "TKARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224198,
      "TXT_PINCODE_LOCALITY": "INDIAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424422,
      "City District Name": "AMBEDKAR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224200,
      "TXT_PINCODE_LOCALITY": "PARA KAIL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443028,
      "City District Name": "SOHAWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224201,
      "TXT_PINCODE_LOCALITY": "MOTINAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224202,
      "TXT_PINCODE_LOCALITY": "RAITHUA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224203,
      "TXT_PINCODE_LOCALITY": "RAMPUR BHAGAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224204,
      "TXT_PINCODE_LOCALITY": "BIKAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224205,
      "TXT_PINCODE_LOCALITY": "KAPRADIH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224206,
      "TXT_PINCODE_LOCALITY": "KHAJURHAT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224207,
      "TXT_PINCODE_LOCALITY": "MAJRUDDINPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224208,
      "TXT_PINCODE_LOCALITY": "URWA BAZAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224209,
      "TXT_PINCODE_LOCALITY": "CHAUREY BAZAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224210,
      "TXT_PINCODE_LOCALITY": "BARIAYAWAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224211,
      "TXT_PINCODE_LOCALITY": "JANAURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224212,
      "TXT_PINCODE_LOCALITY": "BAWANA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424422,
      "City District Name": "AMBEDKAR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224213,
      "TXT_PINCODE_LOCALITY": "BAZAR BHIYAUN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224214,
      "TXT_PINCODE_LOCALITY": "KIDARNAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224215,
      "TXT_PINCODE_LOCALITY": "BHANKABANA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224216,
      "TXT_PINCODE_LOCALITY": "PAIKAULI BAZAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424422,
      "City District Name": "AMBEDKAR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224217,
      "TXT_PINCODE_LOCALITY": "DHAURAUWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424422,
      "City District Name": "AMBEDKAR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224218,
      "TXT_PINCODE_LOCALITY": "SHIVPAL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424422,
      "City District Name": "AMBEDKAR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224219,
      "TXT_PINCODE_LOCALITY": "KAREEK",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224220,
      "TXT_PINCODE_LOCALITY": "KHASPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424422,
      "City District Name": "AMBEDKAR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224221,
      "TXT_PINCODE_LOCALITY": "MUKHDOOM NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424422,
      "City District Name": "AMBEDKAR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224222,
      "TXT_PINCODE_LOCALITY": "KATARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224223,
      "TXT_PINCODE_LOCALITY": "LAROON",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224224,
      "TXT_PINCODE_LOCALITY": "MAKRAHI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424422,
      "City District Name": "AMBEDKAR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224225,
      "TXT_PINCODE_LOCALITY": "ACHHORA S.O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224226,
      "TXT_PINCODE_LOCALITY": "AHRIAULI  RANIMAU",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424422,
      "City District Name": "AMBEDKAR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224227,
      "TXT_PINCODE_LOCALITY": "SURAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424422,
      "City District Name": "AMBEDKAR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224228,
      "TXT_PINCODE_LOCALITY": "INAYAT NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224229,
      "TXT_PINCODE_LOCALITY": "BIRAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224230,
      "TXT_PINCODE_LOCALITY": "CHANDPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224231,
      "TXT_PINCODE_LOCALITY": "RAM NAGAR KARRI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424422,
      "City District Name": "AMBEDKAR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224232,
      "TXT_PINCODE_LOCALITY": "HAKEEM PUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424422,
      "City District Name": "AMBEDKAR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224234,
      "TXT_PINCODE_LOCALITY": "MAHUWARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224235,
      "TXT_PINCODE_LOCALITY": "SIJHAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424422,
      "City District Name": "AMBEDKAR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224236,
      "TXT_PINCODE_LOCALITY": "PIRKHAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224238,
      "TXT_PINCODE_LOCALITY": "VIDYUT NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424422,
      "City District Name": "AMBEDKAR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224247,
      "TXT_PINCODE_LOCALITY": "PETHIA.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1832,
      "City District Name": "TANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224261,
      "TXT_PINCODE_LOCALITY": "ISHA SARAY MANJHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224284,
      "TXT_PINCODE_LOCALITY": "TAKSARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224300,
      "TXT_PINCODE_LOCALITY": "JADOPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1832,
      "City District Name": "TANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224306,
      "TXT_PINCODE_LOCALITY": "KASHAU KHAS",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224330,
      "TXT_PINCODE_LOCALITY": "KALESAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1832,
      "City District Name": "TANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224331,
      "TXT_PINCODE_LOCALITY": "RAMNAGAR KARRE",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442989,
      "City District Name": "BHITI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224401,
      "TXT_PINCODE_LOCALITY": "SARAISAUF",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443022,
      "City District Name": "RAMSANEHIGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224415,
      "TXT_PINCODE_LOCALITY": "KANSPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1832,
      "City District Name": "TANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224447,
      "TXT_PINCODE_LOCALITY": "TIKORIYA BUJURG",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1990,
      "City District Name": "ALLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224449,
      "TXT_PINCODE_LOCALITY": "TAJUPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3516,
      "City District Name": "JALALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224461,
      "TXT_PINCODE_LOCALITY": "RODOOLI MAFI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3516,
      "City District Name": "JALALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224486,
      "TXT_PINCODE_LOCALITY": "MAHESHPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3516,
      "City District Name": "JALALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224501,
      "TXT_PINCODE_LOCALITY": "PASNIYAN KHERA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2543,
      "City District Name": "BIGHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224502,
      "TXT_PINCODE_LOCALITY": "SARAI NASIR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5395,
      "City District Name": "RUDAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224718,
      "TXT_PINCODE_LOCALITY": "INDAIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1990,
      "City District Name": "ALLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224727,
      "TXT_PINCODE_LOCALITY": "BHEKHU PUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1961,
      "City District Name": "AKBARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224737,
      "TXT_PINCODE_LOCALITY": "SRAY RAMMAL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1990,
      "City District Name": "ALLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224745,
      "TXT_PINCODE_LOCALITY": "SALARPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1832,
      "City District Name": "TANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224835,
      "TXT_PINCODE_LOCALITY": "NASOPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3516,
      "City District Name": "JALALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224909,
      "TXT_PINCODE_LOCALITY": "NEWNAPURAB",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2552,
      "City District Name": "BIKAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 224949,
      "TXT_PINCODE_LOCALITY": "BANGAON.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3516,
      "City District Name": "JALALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225001,
      "TXT_PINCODE_LOCALITY": "BANKI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1313,
      "City District Name": "BARABANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225002,
      "TXT_PINCODE_LOCALITY": "BARA BANKI SUGAR MILLS",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1313,
      "City District Name": "BARABANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225003,
      "TXT_PINCODE_LOCALITY": "NAWABGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1313,
      "City District Name": "BARABANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225005,
      "TXT_PINCODE_LOCALITY": "KHAPRAICHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3346,
      "City District Name": "HAIDERGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225027,
      "TXT_PINCODE_LOCALITY": "ROHILA NAGAR.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443027,
      "City District Name": "SIRAULI GAUSPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225103,
      "TXT_PINCODE_LOCALITY": "GARCHAPPA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1770,
      "City District Name": "FATEHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225108,
      "TXT_PINCODE_LOCALITY": "HUNHUNA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5395,
      "City District Name": "RUDAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225119,
      "TXT_PINCODE_LOCALITY": "KOTHI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1313,
      "City District Name": "BARABANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225120,
      "TXT_PINCODE_LOCALITY": "USMANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1313,
      "City District Name": "BARABANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225121,
      "TXT_PINCODE_LOCALITY": "HARAKH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1313,
      "City District Name": "BARABANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225122,
      "TXT_PINCODE_LOCALITY": "SATRIKH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1313,
      "City District Name": "BARABANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225123,
      "TXT_PINCODE_LOCALITY": "SOMAIYANAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1313,
      "City District Name": "BARABANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225124,
      "TXT_PINCODE_LOCALITY": "HAIDARGARH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1313,
      "City District Name": "BARABANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225125,
      "TXT_PINCODE_LOCALITY": "TIKRAHUWAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3346,
      "City District Name": "HAIDERGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225126,
      "TXT_PINCODE_LOCALITY": "MADNAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3346,
      "City District Name": "HAIDERGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225131,
      "TXT_PINCODE_LOCALITY": "KHAN PUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3346,
      "City District Name": "HAIDERGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225201,
      "TXT_PINCODE_LOCALITY": "BHARAMGHAT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1313,
      "City District Name": "BARABANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225202,
      "TXT_PINCODE_LOCALITY": "BURHWAL SUGAJ MILLS",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1313,
      "City District Name": "BARABANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225203,
      "TXT_PINCODE_LOCALITY": "JAHANGIRABAD RAJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225204,
      "TXT_PINCODE_LOCALITY": "MASAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1313,
      "City District Name": "BARABANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225205,
      "TXT_PINCODE_LOCALITY": "RAMBAGAR (BAREILLY)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1313,
      "City District Name": "BARABANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225206,
      "TXT_PINCODE_LOCALITY": "SAADATGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1313,
      "City District Name": "BARABANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225207,
      "TXT_PINCODE_LOCALITY": "MARKAMAU",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1313,
      "City District Name": "BARABANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225208,
      "TXT_PINCODE_LOCALITY": "TILOKPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1313,
      "City District Name": "BARABANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225301,
      "TXT_PINCODE_LOCALITY": "DEWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1313,
      "City District Name": "BARABANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225302,
      "TXT_PINCODE_LOCALITY": "KURSI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1313,
      "City District Name": "BARABANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225303,
      "TXT_PINCODE_LOCALITY": "RAM MANDAI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1313,
      "City District Name": "BARABANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225304,
      "TXT_PINCODE_LOCALITY": "SURATGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1313,
      "City District Name": "BARABANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225305,
      "TXT_PINCODE_LOCALITY": "TEHSIL FATEHPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1313,
      "City District Name": "BARABANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225306,
      "TXT_PINCODE_LOCALITY": "SALEMPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1313,
      "City District Name": "BARABANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225307,
      "TXT_PINCODE_LOCALITY": "CHAKDHE PUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443027,
      "City District Name": "SIRAULI GAUSPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225308,
      "TXT_PINCODE_LOCALITY": "MAILA RAIGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443027,
      "City District Name": "SIRAULI GAUSPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225401,
      "TXT_PINCODE_LOCALITY": "PATRANGA R S",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225402,
      "TXT_PINCODE_LOCALITY": "BHILSAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225403,
      "TXT_PINCODE_LOCALITY": "DARYABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1313,
      "City District Name": "BARABANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225404,
      "TXT_PINCODE_LOCALITY": "DARYABAD R S",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1313,
      "City District Name": "BARABANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225405,
      "TXT_PINCODE_LOCALITY": "HATHAUNDA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1313,
      "City District Name": "BARABANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225406,
      "TXT_PINCODE_LOCALITY": "MAWAL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1313,
      "City District Name": "BARABANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225407,
      "TXT_PINCODE_LOCALITY": "MIRMAU",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225408,
      "TXT_PINCODE_LOCALITY": "PATRANGA R.S.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1313,
      "City District Name": "BARABANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225409,
      "TXT_PINCODE_LOCALITY": "RAMSANEHI GHAT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1313,
      "City District Name": "BARABANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225411,
      "TXT_PINCODE_LOCALITY": "RADAUH STATION ROAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225412,
      "TXT_PINCODE_LOCALITY": "SAFDARGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1313,
      "City District Name": "BARABANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225413,
      "TXT_PINCODE_LOCALITY": "SIDHAUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1313,
      "City District Name": "BARABANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225414,
      "TXT_PINCODE_LOCALITY": "ZAIDPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1313,
      "City District Name": "BARABANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225415,
      "TXT_PINCODE_LOCALITY": "TIKAITNAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1313,
      "City District Name": "BARABANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225416,
      "TXT_PINCODE_LOCALITY": "SILHAUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1313,
      "City District Name": "BARABANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225420,
      "TXT_PINCODE_LOCALITY": "CHAK MADAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443027,
      "City District Name": "SIRAULI GAUSPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225425,
      "TXT_PINCODE_LOCALITY": "GODARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4781,
      "City District Name": "NAWABGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225441,
      "TXT_PINCODE_LOCALITY": "SOHIL PUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4781,
      "City District Name": "NAWABGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225503,
      "TXT_PINCODE_LOCALITY": "GHATAMPUR KALAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2543,
      "City District Name": "BIGHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 225701,
      "TXT_PINCODE_LOCALITY": "HAIDERGARH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1313,
      "City District Name": "BARABANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226001,
      "TXT_PINCODE_LOCALITY": "MAULVIGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226002,
      "TXT_PINCODE_LOCALITY": "DILKUSHA S B",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226003,
      "TXT_PINCODE_LOCALITY": "TAKMIL-UL-TIB",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226004,
      "TXT_PINCODE_LOCALITY": "VICTORIA GANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226005,
      "TXT_PINCODE_LOCALITY": "DISTRICT JAIL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226006,
      "TXT_PINCODE_LOCALITY": "MAHANAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226007,
      "TXT_PINCODE_LOCALITY": "NISHATGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226008,
      "TXT_PINCODE_LOCALITY": "SAROJINI NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226009,
      "TXT_PINCODE_LOCALITY": "AMAUSI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226010,
      "TXT_PINCODE_LOCALITY": "GHAZIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226011,
      "TXT_PINCODE_LOCALITY": "MANAKNAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226012,
      "TXT_PINCODE_LOCALITY": "INDUSTRIAL AREA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226013,
      "TXT_PINCODE_LOCALITY": "I.I.M. MUBARAKPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226014,
      "TXT_PINCODE_LOCALITY": "THIRY SECOND BN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226015,
      "TXT_PINCODE_LOCALITY": "SARVODAINAGAR LUCKNOW 15",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226016,
      "TXT_PINCODE_LOCALITY": "NAGAR LUCKNOW",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226017,
      "TXT_PINCODE_LOCALITY": "AWAS VIKAS COLONY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226018,
      "TXT_PINCODE_LOCALITY": "AMINABAD PARK",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226019,
      "TXT_PINCODE_LOCALITY": "VIKAS NAGER",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226020,
      "TXT_PINCODE_LOCALITY": "ALI GANG",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226021,
      "TXT_PINCODE_LOCALITY": "LUCKNOW",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226022,
      "TXT_PINCODE_LOCALITY": "KALYANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226023,
      "TXT_PINCODE_LOCALITY": "MANASNAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226024,
      "TXT_PINCODE_LOCALITY": "MAHANAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226025,
      "TXT_PINCODE_LOCALITY": "B R A UNIVERSITY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226026,
      "TXT_PINCODE_LOCALITY": "GURAMBA B.O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226027,
      "TXT_PINCODE_LOCALITY": "THE MALL AVENUE",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226028,
      "TXT_PINCODE_LOCALITY": "LUCKNOW",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226029,
      "TXT_PINCODE_LOCALITY": "LUCKNOW",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226030,
      "TXT_PINCODE_LOCALITY": "SUSHANT GOLF CITY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226031,
      "TXT_PINCODE_LOCALITY": "LUCKNOW.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226071,
      "TXT_PINCODE_LOCALITY": "PIPARSAND",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226101,
      "TXT_PINCODE_LOCALITY": "KAKORI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226102,
      "TXT_PINCODE_LOCALITY": "SULTANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4360,
      "City District Name": "MALIHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226103,
      "TXT_PINCODE_LOCALITY": "TERVA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4360,
      "City District Name": "MALIHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226104,
      "TXT_PINCODE_LOCALITY": "MALIHABAD S. O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226106,
      "TXT_PINCODE_LOCALITY": "CHAUKIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226142,
      "TXT_PINCODE_LOCALITY": "MATHGOVIND",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442991,
      "City District Name": "BURHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226162,
      "TXT_PINCODE_LOCALITY": "RAMPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4448,
      "City District Name": "MARIAHU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226201,
      "TXT_PINCODE_LOCALITY": "BAKSHI KA TALAB",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226202,
      "TXT_PINCODE_LOCALITY": "RAITHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226203,
      "TXT_PINCODE_LOCALITY": "LUCKNOW B. O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226261,
      "TXT_PINCODE_LOCALITY": "BEIXIN",
      "NUM_STATE_CD": 345,
      "State Name": "JIANGSU",
      "NUM_CITYDISTRICT_CD": 444177,
      "City District Name": "QIDONG",
      "NUM_COUNTRY_CD": 146
    },
    {
      "NUM_PINCODE": 226288,
      "TXT_PINCODE_LOCALITY": "PALIYA ADAIE",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5122,
      "City District Name": "PHULPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226301,
      "TXT_PINCODE_LOCALITY": "MOHAN LALGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226302,
      "TXT_PINCODE_LOCALITY": "BRAMHEDAS PUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443018,
      "City District Name": "MOHANLALGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226303,
      "TXT_PINCODE_LOCALITY": "SELHU MAU",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443018,
      "City District Name": "MOHANLALGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226306,
      "TXT_PINCODE_LOCALITY": "PAHRAWAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226401,
      "TXT_PINCODE_LOCALITY": "LUCKNOW S. O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226501,
      "TXT_PINCODE_LOCALITY": "SIDDHUPURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443018,
      "City District Name": "MOHANLALGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 226504,
      "TXT_PINCODE_LOCALITY": "JANGLI KHURD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2543,
      "City District Name": "BIGHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227003,
      "TXT_PINCODE_LOCALITY": "JADOOPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443009,
      "City District Name": "LAMBHUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227005,
      "TXT_PINCODE_LOCALITY": "AMANI GANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227101,
      "TXT_PINCODE_LOCALITY": "BANTHRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227105,
      "TXT_PINCODE_LOCALITY": "CHINAHAT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227106,
      "TXT_PINCODE_LOCALITY": "GOMTI NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227107,
      "TXT_PINCODE_LOCALITY": "KAKORI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227111,
      "TXT_PINCODE_LOCALITY": "MALIHABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227115,
      "TXT_PINCODE_LOCALITY": "MALL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227116,
      "TXT_PINCODE_LOCALITY": "RAHIMBAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227120,
      "TXT_PINCODE_LOCALITY": "AMETHI (LUCKNOW)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227125,
      "TXT_PINCODE_LOCALITY": "GOSHAINGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227131,
      "TXT_PINCODE_LOCALITY": "TRIVEDIGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1313,
      "City District Name": "BARABANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227132,
      "TXT_PINCODE_LOCALITY": "U P GOVT CAMP POST OFFICE",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227162,
      "TXT_PINCODE_LOCALITY": "POKHARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4448,
      "City District Name": "MARIAHU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227202,
      "TXT_PINCODE_LOCALITY": "F F T C INDAURABAGH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227205,
      "TXT_PINCODE_LOCALITY": "ITAUJA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227207,
      "TXT_PINCODE_LOCALITY": "HARDHAURPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442985,
      "City District Name": "BAKSHI KA TALAB",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227208,
      "TXT_PINCODE_LOCALITY": "BAKSHI KA TALAB AIRPORT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227301,
      "TXT_PINCODE_LOCALITY": "HAIDERGARH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1313,
      "City District Name": "BARABANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227302,
      "TXT_PINCODE_LOCALITY": "SUBEHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1313,
      "City District Name": "BARABANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227304,
      "TXT_PINCODE_LOCALITY": "SURYABHAN PATTI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227305,
      "TXT_PINCODE_LOCALITY": "MOHAN LALGANJ B. O.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227306,
      "TXT_PINCODE_LOCALITY": "KUTUBPUR VARENDA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227308,
      "TXT_PINCODE_LOCALITY": "NAGRAM",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227309,
      "TXT_PINCODE_LOCALITY": "NIGOHAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227404,
      "TXT_PINCODE_LOCALITY": "AMETHI S. O.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2026,
      "City District Name": "AMETHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227405,
      "TXT_PINCODE_LOCALITY": "AMETHI (SULTANPUR)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227406,
      "TXT_PINCODE_LOCALITY": "BHADAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227407,
      "TXT_PINCODE_LOCALITY": "BISESARGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227408,
      "TXT_PINCODE_LOCALITY": "DHAMMAUR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424410,
      "City District Name": "UDHAMSINGH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227409,
      "TXT_PINCODE_LOCALITY": "GAURIGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227411,
      "TXT_PINCODE_LOCALITY": "SHAHGAH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227412,
      "TXT_PINCODE_LOCALITY": "KURWA HAL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227413,
      "TXT_PINCODE_LOCALITY": "TIKARMAFI S.O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227507,
      "TXT_PINCODE_LOCALITY": "AMETHI SALEMPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227509,
      "TXT_PINCODE_LOCALITY": "MAHAUNA PASCHIM",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4629,
      "City District Name": "MUSAFIRKHANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227611,
      "TXT_PINCODE_LOCALITY": "JODIL MAU",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4629,
      "City District Name": "MUSAFIRKHANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227705,
      "TXT_PINCODE_LOCALITY": "SULTANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227716,
      "TXT_PINCODE_LOCALITY": "TIRAGAVAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4360,
      "City District Name": "MALIHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227801,
      "TXT_PINCODE_LOCALITY": "INDUSTRIAL ESTATE MUZAFFA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227802,
      "TXT_PINCODE_LOCALITY": "SHEORATANGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227804,
      "TXT_PINCODE_LOCALITY": "MUBARAKPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4629,
      "City District Name": "MUSAFIRKHANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227805,
      "TXT_PINCODE_LOCALITY": "ALIGANJ BAZAR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424410,
      "City District Name": "UDHAMSINGH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227806,
      "TXT_PINCODE_LOCALITY": "BAZAR BALDI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227807,
      "TXT_PINCODE_LOCALITY": "GAURA JAMON",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424410,
      "City District Name": "UDHAMSINGH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227808,
      "TXT_PINCODE_LOCALITY": "HASANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227809,
      "TXT_PINCODE_LOCALITY": "JAGDISHPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227811,
      "TXT_PINCODE_LOCALITY": "MUSAFIRKHANA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227812,
      "TXT_PINCODE_LOCALITY": "WALIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227813,
      "TXT_PINCODE_LOCALITY": "MUSAFIRKHANA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227814,
      "TXT_PINCODE_LOCALITY": "ATAULA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227815,
      "TXT_PINCODE_LOCALITY": "HALIAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227816,
      "TXT_PINCODE_LOCALITY": "RANIGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227817,
      "TXT_PINCODE_LOCALITY": "JAGDISHPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227875,
      "TXT_PINCODE_LOCALITY": "KOTWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4629,
      "City District Name": "MUSAFIRKHANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227881,
      "TXT_PINCODE_LOCALITY": "SEWARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4629,
      "City District Name": "MUSAFIRKHANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227888,
      "TXT_PINCODE_LOCALITY": "SULTANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 227890,
      "TXT_PINCODE_LOCALITY": "NATAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4629,
      "City District Name": "MUSAFIRKHANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 228001,
      "TXT_PINCODE_LOCALITY": "HANUMANGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 228004,
      "TXT_PINCODE_LOCALITY": "KHARGPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443003,
      "City District Name": "JAISINGHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 228115,
      "TXT_PINCODE_LOCALITY": "MIRANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443009,
      "City District Name": "LAMBHUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 228118,
      "TXT_PINCODE_LOCALITY": "K N I",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 228119,
      "TXT_PINCODE_LOCALITY": "GOSAIGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 228120,
      "TXT_PINCODE_LOCALITY": "BARAUNSA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 228121,
      "TXT_PINCODE_LOCALITY": "ATRASUMA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 228124,
      "TXT_PINCODE_LOCALITY": "KAMLA NEHRU INSTITUTE",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424410,
      "City District Name": "UDHAMSINGH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 228125,
      "TXT_PINCODE_LOCALITY": "DWARIKAGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 228128,
      "TXT_PINCODE_LOCALITY": "DODAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4448,
      "City District Name": "MARIAHU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 228131,
      "TXT_PINCODE_LOCALITY": "DOSTPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 228132,
      "TXT_PINCODE_LOCALITY": "DEARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 228133,
      "TXT_PINCODE_LOCALITY": "BELHARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 228140,
      "TXT_PINCODE_LOCALITY": "ALAVALPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443003,
      "City District Name": "JAISINGHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 228141,
      "TXT_PINCODE_LOCALITY": "JAISINGHAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 228142,
      "TXT_PINCODE_LOCALITY": "MAHAMOODPUR SEMARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 228145,
      "TXT_PINCODE_LOCALITY": "KADIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 228150,
      "TXT_PINCODE_LOCALITY": "RAMGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 228151,
      "TXT_PINCODE_LOCALITY": "KUREBHAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 228155,
      "TXT_PINCODE_LOCALITY": "KURWAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 228159,
      "TXT_PINCODE_LOCALITY": "RAMGANJ (SULTANPUR)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 228161,
      "TXT_PINCODE_LOCALITY": "TAWAKALPUR NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 228171,
      "TXT_PINCODE_LOCALITY": "AKHAND NAGAR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424410,
      "City District Name": "UDHAMSINGH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 228177,
      "TXT_PINCODE_LOCALITY": "MARUIKISHUN DASPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3649,
      "City District Name": "KADIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 228178,
      "TXT_PINCODE_LOCALITY": "SULTANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 228186,
      "TXT_PINCODE_LOCALITY": "SOHMAI MOLANA PUR.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1961,
      "City District Name": "AKBARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 228216,
      "TXT_PINCODE_LOCALITY": "SAHANIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4215,
      "City District Name": "LALGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 228225,
      "TXT_PINCODE_LOCALITY": "GRANTKURVAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 228408,
      "TXT_PINCODE_LOCALITY": "ALAPUR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 5152,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 228411,
      "TXT_PINCODE_LOCALITY": "SARAIDADAN UPRHAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443029,
      "City District Name": "SORAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 228551,
      "TXT_PINCODE_LOCALITY": "KHATIWPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3649,
      "City District Name": "KADIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 228581,
      "TXT_PINCODE_LOCALITY": "GOPALPUR NEWAJPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3649,
      "City District Name": "KADIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 228820,
      "TXT_PINCODE_LOCALITY": "BARANSA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 366,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229001,
      "TXT_PINCODE_LOCALITY": "RAE BARELI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229003,
      "TXT_PINCODE_LOCALITY": "SARWAN.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443032,
      "City District Name": "TILOI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229010,
      "TXT_PINCODE_LOCALITY": "I.T.I. RAIBAREILLY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229030,
      "TXT_PINCODE_LOCALITY": "RUKUNPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229050,
      "TXT_PINCODE_LOCALITY": "DIWARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2543,
      "City District Name": "BIGHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229093,
      "TXT_PINCODE_LOCALITY": "SAVITAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443032,
      "City District Name": "TILOI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229103,
      "TXT_PINCODE_LOCALITY": "RAMGAON",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1330,
      "City District Name": "RAI BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229120,
      "TXT_PINCODE_LOCALITY": "KHAIRAHANI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2868,
      "City District Name": "DALMAU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229121,
      "TXT_PINCODE_LOCALITY": "AIHAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229122,
      "TXT_PINCODE_LOCALITY": "GURBUXGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229123,
      "TXT_PINCODE_LOCALITY": "HALLAUR (RAE BARELI)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229124,
      "TXT_PINCODE_LOCALITY": "RAEBARELI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229125,
      "TXT_PINCODE_LOCALITY": "KATHGHAT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229126,
      "TXT_PINCODE_LOCALITY": "SEMRAUTA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229127,
      "TXT_PINCODE_LOCALITY": "SALON",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229128,
      "TXT_PINCODE_LOCALITY": "MEJAR GANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229129,
      "TXT_PINCODE_LOCALITY": "PARASADIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229130,
      "TXT_PINCODE_LOCALITY": "RAEBARELI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229135,
      "TXT_PINCODE_LOCALITY": "RAEBARELI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229145,
      "TXT_PINCODE_LOCALITY": "ISLAMPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2552,
      "City District Name": "BIKAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229201,
      "TXT_PINCODE_LOCALITY": "PAHU",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229202,
      "TXT_PINCODE_LOCALITY": "BHOJPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229203,
      "TXT_PINCODE_LOCALITY": "MODI GRAM",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229204,
      "TXT_PINCODE_LOCALITY": "GAURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229205,
      "TXT_PINCODE_LOCALITY": "RAEBARELI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229206,
      "TXT_PINCODE_LOCALITY": "LALGANJ(RAE BARELI)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229207,
      "TXT_PINCODE_LOCALITY": "MURAI KA BAGH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229208,
      "TXT_PINCODE_LOCALITY": "RAEBARELI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229209,
      "TXT_PINCODE_LOCALITY": "LALGANJ.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229210,
      "TXT_PINCODE_LOCALITY": "ATARHAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1330,
      "City District Name": "RAI BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229211,
      "TXT_PINCODE_LOCALITY": "SEMRI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229212,
      "TXT_PINCODE_LOCALITY": "DHANPULPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1330,
      "City District Name": "RAI BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229215,
      "TXT_PINCODE_LOCALITY": "MODI GRAM",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229216,
      "TXT_PINCODE_LOCALITY": "RATAPUR CHAURAHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229221,
      "TXT_PINCODE_LOCALITY": "AWASTHIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2868,
      "City District Name": "DALMAU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229223,
      "TXT_PINCODE_LOCALITY": "TEJA PUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442991,
      "City District Name": "BURHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229227,
      "TXT_PINCODE_LOCALITY": "NOORUDDINPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5464,
      "City District Name": "SALON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229301,
      "TXT_PINCODE_LOCALITY": "BACHHRAWAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229302,
      "TXT_PINCODE_LOCALITY": "FURSATGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229303,
      "TXT_PINCODE_LOCALITY": "HARCHANDPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229304,
      "TXT_PINCODE_LOCALITY": "SHAHGON",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229305,
      "TXT_PINCODE_LOCALITY": "RAE BARELI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229306,
      "TXT_PINCODE_LOCALITY": "GARHI MAJRE POKHANNI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1330,
      "City District Name": "RAI BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229307,
      "TXT_PINCODE_LOCALITY": "NASIRABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229308,
      "TXT_PINCODE_LOCALITY": "SHEOGARH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229309,
      "TXT_PINCODE_LOCALITY": "AHORAWA BHAWANI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229310,
      "TXT_PINCODE_LOCALITY": "DEEH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229311,
      "TXT_PINCODE_LOCALITY": "RAEBARELI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229315,
      "TXT_PINCODE_LOCALITY": "DANDANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4215,
      "City District Name": "LALGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229316,
      "TXT_PINCODE_LOCALITY": "RATAPUR CHAURAHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229401,
      "TXT_PINCODE_LOCALITY": "RAEBARELI.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229402,
      "TXT_PINCODE_LOCALITY": "JAGATPUR S.O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229403,
      "TXT_PINCODE_LOCALITY": "NASIRPUR DARGAHI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443029,
      "City District Name": "SORAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229404,
      "TXT_PINCODE_LOCALITY": "MUSTAFABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229405,
      "TXT_PINCODE_LOCALITY": "RAEBARELI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229406,
      "TXT_PINCODE_LOCALITY": "PARASADIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229407,
      "TXT_PINCODE_LOCALITY": "MANIKPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229408,
      "TXT_PINCODE_LOCALITY": "KALA KANKAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229410,
      "TXT_PINCODE_LOCALITY": "AGAI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229411,
      "TXT_PINCODE_LOCALITY": "MENDARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229412,
      "TXT_PINCODE_LOCALITY": "AMILIHAPAL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229413,
      "TXT_PINCODE_LOCALITY": "LALGOPALGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229414,
      "TXT_PINCODE_LOCALITY": "PRATAPGARH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229415,
      "TXT_PINCODE_LOCALITY": "PRATAPGARH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229416,
      "TXT_PINCODE_LOCALITY": "MALLAWAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229417,
      "TXT_PINCODE_LOCALITY": "PRATAPGARH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229418,
      "TXT_PINCODE_LOCALITY": "KANNAUJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3765,
      "City District Name": "KANNAUJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229419,
      "TXT_PINCODE_LOCALITY": "PARIYAWAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229427,
      "TXT_PINCODE_LOCALITY": "SAMASPUR KHALSA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5464,
      "City District Name": "SALON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229431,
      "TXT_PINCODE_LOCALITY": "SHIVRAMPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4151,
      "City District Name": "KUNDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229501,
      "TXT_PINCODE_LOCALITY": "BARA (UNNAO)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 367,
      "City District Name": "UNNAO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229502,
      "TXT_PINCODE_LOCALITY": "UNNAO",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 367,
      "City District Name": "UNNAO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229503,
      "TXT_PINCODE_LOCALITY": "UNNAO",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 367,
      "City District Name": "UNNAO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229504,
      "TXT_PINCODE_LOCALITY": "BIHAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 367,
      "City District Name": "UNNAO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229505,
      "TXT_PINCODE_LOCALITY": "HARI RAMKHERA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4215,
      "City District Name": "LALGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229508,
      "TXT_PINCODE_LOCALITY": "KATHAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2543,
      "City District Name": "BIGHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229602,
      "TXT_PINCODE_LOCALITY": "YUSUFPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4215,
      "City District Name": "LALGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229711,
      "TXT_PINCODE_LOCALITY": "KUTUBUDDINPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443029,
      "City District Name": "SORAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229801,
      "TXT_PINCODE_LOCALITY": "INHAUNA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229802,
      "TXT_PINCODE_LOCALITY": "SHEORATANGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1330,
      "City District Name": "RAI BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229820,
      "TXT_PINCODE_LOCALITY": "SULTANPUR JANAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2868,
      "City District Name": "DALMAU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229841,
      "TXT_PINCODE_LOCALITY": "SEMRAMAU",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443002,
      "City District Name": "HASANGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 229881,
      "TXT_PINCODE_LOCALITY": "SAMADPUR HARDAS",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443002,
      "City District Name": "HASANGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230001,
      "TXT_PINCODE_LOCALITY": "SADAR BAZAR STATION ROAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230002,
      "TXT_PINCODE_LOCALITY": "PRATAPGARH CITY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230003,
      "TXT_PINCODE_LOCALITY": "MANDHATA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230041,
      "TXT_PINCODE_LOCALITY": "KAPAMADHUPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5054,
      "City District Name": "PATTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230104,
      "TXT_PINCODE_LOCALITY": "SARAI RAJA.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443023,
      "City District Name": "RANIGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230112,
      "TXT_PINCODE_LOCALITY": "RAISHPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4151,
      "City District Name": "KUNDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230119,
      "TXT_PINCODE_LOCALITY": "SARAI LOHANGRAI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4215,
      "City District Name": "LALGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230121,
      "TXT_PINCODE_LOCALITY": "PURA DHAKWA CHAURAHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230122,
      "TXT_PINCODE_LOCALITY": "PRATAPGARH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230123,
      "TXT_PINCODE_LOCALITY": "DEOSASRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230124,
      "TXT_PINCODE_LOCALITY": "AMARGARH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230125,
      "TXT_PINCODE_LOCALITY": "ATEHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230126,
      "TXT_PINCODE_LOCALITY": "BABUGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230127,
      "TXT_PINCODE_LOCALITY": "DALIPPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230128,
      "TXT_PINCODE_LOCALITY": "MAHESHGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230129,
      "TXT_PINCODE_LOCALITY": "CHHEJAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230130,
      "TXT_PINCODE_LOCALITY": "LAXMANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230131,
      "TXT_PINCODE_LOCALITY": "AFIM-KI-KOTHI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230132,
      "TXT_PINCODE_LOCALITY": "LALGANJ (PRATAPGARH)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230133,
      "TXT_PINCODE_LOCALITY": "MALLANWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230134,
      "TXT_PINCODE_LOCALITY": "ORIYADEEH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230135,
      "TXT_PINCODE_LOCALITY": "PATTI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230136,
      "TXT_PINCODE_LOCALITY": "SAGRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230137,
      "TXT_PINCODE_LOCALITY": "SAHEBGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230138,
      "TXT_PINCODE_LOCALITY": "SAIFABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230139,
      "TXT_PINCODE_LOCALITY": "SANGIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230140,
      "TXT_PINCODE_LOCALITY": "PRATAPGARH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230141,
      "TXT_PINCODE_LOCALITY": "KUSHWAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230142,
      "TXT_PINCODE_LOCALITY": "SHITLAGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230143,
      "TXT_PINCODE_LOCALITY": "BALIAGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230144,
      "TXT_PINCODE_LOCALITY": "PRATAPGARH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230146,
      "TXT_PINCODE_LOCALITY": "BARANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230185,
      "TXT_PINCODE_LOCALITY": "PATHH KHAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5054,
      "City District Name": "PATTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230194,
      "TXT_PINCODE_LOCALITY": "PUREY GULAL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5054,
      "City District Name": "PATTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230201,
      "TXT_PINCODE_LOCALITY": "SAMASPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230202,
      "TXT_PINCODE_LOCALITY": "LAT TARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230203,
      "TXT_PINCODE_LOCALITY": "RETAHI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4151,
      "City District Name": "KUNDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230204,
      "TXT_PINCODE_LOCALITY": "KUNDA S.O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230205,
      "TXT_PINCODE_LOCALITY": "DHANGARH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4151,
      "City District Name": "KUNDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230294,
      "TXT_PINCODE_LOCALITY": "MALKANGIRI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230301,
      "TXT_PINCODE_LOCALITY": "GAURA R S",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230302,
      "TXT_PINCODE_LOCALITY": "JAMTALI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230303,
      "TXT_PINCODE_LOCALITY": "PRINTHIGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230304,
      "TXT_PINCODE_LOCALITY": "SANSARPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230305,
      "TXT_PINCODE_LOCALITY": "SANSARPUR (PRATAPGARH)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230306,
      "TXT_PINCODE_LOCALITY": "SUHKSA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230307,
      "TXT_PINCODE_LOCALITY": "DARIYAPUR NANDAUT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5122,
      "City District Name": "PHULPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230308,
      "TXT_PINCODE_LOCALITY": "BISUNPUR KALA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443023,
      "City District Name": "RANIGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230309,
      "TXT_PINCODE_LOCALITY": "SUVANSA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443023,
      "City District Name": "RANIGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230323,
      "TXT_PINCODE_LOCALITY": "PUPIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443023,
      "City District Name": "RANIGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230401,
      "TXT_PINCODE_LOCALITY": "KONHDAUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230402,
      "TXT_PINCODE_LOCALITY": "MANDHATA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230403,
      "TXT_PINCODE_LOCALITY": "MADHOGANJ (PRATAPGARH)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230404,
      "TXT_PINCODE_LOCALITY": "VISHWANATHGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230405,
      "TXT_PINCODE_LOCALITY": "DAREHUT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230406,
      "TXT_PINCODE_LOCALITY": "HAMERGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230445,
      "TXT_PINCODE_LOCALITY": "PURE MUSAI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5054,
      "City District Name": "PATTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230501,
      "TXT_PINCODE_LOCALITY": "ANTU",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230502,
      "TXT_PINCODE_LOCALITY": "GARWARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230503,
      "TXT_PINCODE_LOCALITY": "KISHANGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 230504,
      "TXT_PINCODE_LOCALITY": "RATTIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231001,
      "TXT_PINCODE_LOCALITY": "MIRZAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 885,
      "City District Name": "MIRZAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231002,
      "TXT_PINCODE_LOCALITY": "LALDIGI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 885,
      "City District Name": "MIRZAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231004,
      "TXT_PINCODE_LOCALITY": "PATIHATA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2835,
      "City District Name": "CHUNAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231005,
      "TXT_PINCODE_LOCALITY": "DANKINGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 885,
      "City District Name": "MIRZAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231007,
      "TXT_PINCODE_LOCALITY": "TAND",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 885,
      "City District Name": "MIRZAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231011,
      "TXT_PINCODE_LOCALITY": "SEMARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 885,
      "City District Name": "MIRZAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231039,
      "TXT_PINCODE_LOCALITY": "RAIKAL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443012,
      "City District Name": "MARIHAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231101,
      "TXT_PINCODE_LOCALITY": "CITY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 885,
      "City District Name": "MIRZAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231115,
      "TXT_PINCODE_LOCALITY": "HARIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2685,
      "City District Name": "CHANDAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231121,
      "TXT_PINCODE_LOCALITY": "SEMARIHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4215,
      "City District Name": "LALGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231202,
      "TXT_PINCODE_LOCALITY": "SHISHAWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3065,
      "City District Name": "DUDHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231204,
      "TXT_PINCODE_LOCALITY": "TAL BISAI MUT. ADALPURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2835,
      "City District Name": "CHUNAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231205,
      "TXT_PINCODE_LOCALITY": "CHOPAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 974,
      "City District Name": "SONEBHADRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231206,
      "TXT_PINCODE_LOCALITY": "CHURK",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 974,
      "City District Name": "SONEBHADRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231207,
      "TXT_PINCODE_LOCALITY": "DALLA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 974,
      "City District Name": "SONEBHADRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231208,
      "TXT_PINCODE_LOCALITY": "DUDHI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 974,
      "City District Name": "SONEBHADRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231209,
      "TXT_PINCODE_LOCALITY": "MARKUNDI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 974,
      "City District Name": "SONEBHADRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231210,
      "TXT_PINCODE_LOCALITY": "GHORAWAL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 974,
      "City District Name": "SONEBHADRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231211,
      "TXT_PINCODE_LOCALITY": "LALGANJ (MIRZAPUR) S.O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 885,
      "City District Name": "MIRZAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231212,
      "TXT_PINCODE_LOCALITY": "AMAWAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 974,
      "City District Name": "SONEBHADRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231213,
      "TXT_PINCODE_LOCALITY": "RAMGARH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 885,
      "City District Name": "MIRZAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231214,
      "TXT_PINCODE_LOCALITY": "RAGHUNATHPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5384,
      "City District Name": "ROBERTSGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231215,
      "TXT_PINCODE_LOCALITY": "RAJPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 885,
      "City District Name": "MIRZAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231216,
      "TXT_PINCODE_LOCALITY": "ROBERTS GANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 974,
      "City District Name": "SONEBHADRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231217,
      "TXT_PINCODE_LOCALITY": "RENUKOT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 974,
      "City District Name": "SONEBHADRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231218,
      "TXT_PINCODE_LOCALITY": "RENUSAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 974,
      "City District Name": "SONEBHADRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231219,
      "TXT_PINCODE_LOCALITY": "OBRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 974,
      "City District Name": "SONEBHADRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231220,
      "TXT_PINCODE_LOCALITY": "BINA PROJECT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 974,
      "City District Name": "SONEBHADRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231221,
      "TXT_PINCODE_LOCALITY": "TURRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 974,
      "City District Name": "SONEBHADRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231222,
      "TXT_PINCODE_LOCALITY": "SAKTINAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 974,
      "City District Name": "SONEBHADRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231223,
      "TXT_PINCODE_LOCALITY": "BIJPURE",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 974,
      "City District Name": "SONEBHADRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231224,
      "TXT_PINCODE_LOCALITY": "KAKARI PROJECT S.O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 974,
      "City District Name": "SONEBHADRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231225,
      "TXT_PINCODE_LOCALITY": "A T P S (MIRZAPUR)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 974,
      "City District Name": "SONEBHADRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231226,
      "TXT_PINCODE_LOCALITY": "KACHNARWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 974,
      "City District Name": "SONEBHADRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231229,
      "TXT_PINCODE_LOCALITY": "PANARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 974,
      "City District Name": "SONEBHADRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231230,
      "TXT_PINCODE_LOCALITY": "KANHRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5384,
      "City District Name": "ROBERTSGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231231,
      "TXT_PINCODE_LOCALITY": "RANADAH TOLA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3065,
      "City District Name": "DUDHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231255,
      "TXT_PINCODE_LOCALITY": "ANAPAR TPS",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 974,
      "City District Name": "SONEBHADRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231300,
      "TXT_PINCODE_LOCALITY": "DEVRI UTTAR.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443012,
      "City District Name": "MARIHAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231301,
      "TXT_PINCODE_LOCALITY": "SARIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2835,
      "City District Name": "CHUNAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231302,
      "TXT_PINCODE_LOCALITY": "ADALHAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 885,
      "City District Name": "MIRZAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231303,
      "TXT_PINCODE_LOCALITY": "BIJAIPUR S.O (MIRZAPUR)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 885,
      "City District Name": "MIRZAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231304,
      "TXT_PINCODE_LOCALITY": "CHUNAR TEHSIL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 885,
      "City District Name": "MIRZAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231305,
      "TXT_PINCODE_LOCALITY": "BANGLA DEVARIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 885,
      "City District Name": "MIRZAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231306,
      "TXT_PINCODE_LOCALITY": "LARCHHUT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2835,
      "City District Name": "CHUNAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231307,
      "TXT_PINCODE_LOCALITY": "SAMAGRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 885,
      "City District Name": "MIRZAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231308,
      "TXT_PINCODE_LOCALITY": "JOGIYA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3065,
      "City District Name": "DUDHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231309,
      "TXT_PINCODE_LOCALITY": "KUBARIPATEHRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 885,
      "City District Name": "MIRZAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231310,
      "TXT_PINCODE_LOCALITY": "KHACHAHA.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443012,
      "City District Name": "MARIHAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231311,
      "TXT_PINCODE_LOCALITY": "FACTORY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 885,
      "City District Name": "MIRZAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231312,
      "TXT_PINCODE_LOCALITY": "PUTLIGHAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 885,
      "City District Name": "MIRZAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231313,
      "TXT_PINCODE_LOCALITY": "JIGNA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 885,
      "City District Name": "MIRZAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231314,
      "TXT_PINCODE_LOCALITY": "JAMUA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 885,
      "City District Name": "MIRZAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231360,
      "TXT_PINCODE_LOCALITY": "SIKHAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2835,
      "City District Name": "CHUNAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231370,
      "TXT_PINCODE_LOCALITY": "PARSAUNA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443012,
      "City District Name": "MARIHAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231501,
      "TXT_PINCODE_LOCALITY": "KACHHWA S.O (MIRZAPUR)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 885,
      "City District Name": "MIRZAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231556,
      "TXT_PINCODE_LOCALITY": "DHANAWAL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443012,
      "City District Name": "MARIHAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 231813,
      "TXT_PINCODE_LOCALITY": "TIKAREE MU. JIGANA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 885,
      "City District Name": "MIRZAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232001,
      "TXT_PINCODE_LOCALITY": "RAMPUR 33.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443012,
      "City District Name": "MARIHAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232101,
      "TXT_PINCODE_LOCALITY": "NIYAMATABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2685,
      "City District Name": "CHANDAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232102,
      "TXT_PINCODE_LOCALITY": "BABURI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2685,
      "City District Name": "CHANDAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232103,
      "TXT_PINCODE_LOCALITY": "CHAKIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2685,
      "City District Name": "CHANDAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232104,
      "TXT_PINCODE_LOCALITY": "MUGHALSARAI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4583,
      "City District Name": "MUGHALSARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232105,
      "TXT_PINCODE_LOCALITY": "DHANAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232106,
      "TXT_PINCODE_LOCALITY": "MIRZAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 885,
      "City District Name": "MIRZAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232107,
      "TXT_PINCODE_LOCALITY": "RAMGARH (VARANASI)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2685,
      "City District Name": "CHANDAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232108,
      "TXT_PINCODE_LOCALITY": "SAKALDIHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2685,
      "City District Name": "CHANDAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232109,
      "TXT_PINCODE_LOCALITY": "SAKALDIHA BAZAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2685,
      "City District Name": "CHANDAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232110,
      "TXT_PINCODE_LOCALITY": "SAYEDRAJA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2685,
      "City District Name": "CHANDAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232111,
      "TXT_PINCODE_LOCALITY": "NOWGARH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2685,
      "City District Name": "CHANDAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232112,
      "TXT_PINCODE_LOCALITY": "ALINAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2685,
      "City District Name": "CHANDAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232113,
      "TXT_PINCODE_LOCALITY": "BARHAWI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232114,
      "TXT_PINCODE_LOCALITY": "BALUA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2685,
      "City District Name": "CHANDAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232115,
      "TXT_PINCODE_LOCALITY": "BISAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2685,
      "City District Name": "CHANDAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232116,
      "TXT_PINCODE_LOCALITY": "CHAHANIYA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2685,
      "City District Name": "CHANDAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232117,
      "TXT_PINCODE_LOCALITY": "CHANDAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2685,
      "City District Name": "CHANDAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232118,
      "TXT_PINCODE_LOCALITY": "SAHAB GANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2685,
      "City District Name": "CHANDAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232119,
      "TXT_PINCODE_LOCALITY": "SAHID GAON",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232120,
      "TXT_PINCODE_LOCALITY": "SADALPURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2685,
      "City District Name": "CHANDAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232202,
      "TXT_PINCODE_LOCALITY": "LOHANGAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4319,
      "City District Name": "MAHMUDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232221,
      "TXT_PINCODE_LOCALITY": "KISHUNPURVA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5384,
      "City District Name": "ROBERTSGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232224,
      "TXT_PINCODE_LOCALITY": "CHACHAKPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232225,
      "TXT_PINCODE_LOCALITY": "NARHATI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5384,
      "City District Name": "ROBERTSGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232237,
      "TXT_PINCODE_LOCALITY": "SUKHDELRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232321,
      "TXT_PINCODE_LOCALITY": "GARAHAWA URF PRAHLADPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6273,
      "City District Name": "ZAMANIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232322,
      "TXT_PINCODE_LOCALITY": "DHAMAWAL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5443,
      "City District Name": "SAIDPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232324,
      "TXT_PINCODE_LOCALITY": "AHIBARANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6273,
      "City District Name": "ZAMANIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232325,
      "TXT_PINCODE_LOCALITY": "BARA (GHAZIPUR)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232326,
      "TXT_PINCODE_LOCALITY": "KUSHI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232327,
      "TXT_PINCODE_LOCALITY": "GAHMAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232328,
      "TXT_PINCODE_LOCALITY": "REOTIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232329,
      "TXT_PINCODE_LOCALITY": "ZAMANIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232330,
      "TXT_PINCODE_LOCALITY": "USIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232331,
      "TXT_PINCODE_LOCALITY": "ZAMANIA R S",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232332,
      "TXT_PINCODE_LOCALITY": "TARIGHAT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232333,
      "TXT_PINCODE_LOCALITY": "NAULI (GHAZIPUR)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232334,
      "TXT_PINCODE_LOCALITY": "AMAURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232335,
      "TXT_PINCODE_LOCALITY": "BHADERA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232336,
      "TXT_PINCODE_LOCALITY": "DHARANJI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232338,
      "TXT_PINCODE_LOCALITY": "NAULI (GHAZIPUR)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232339,
      "TXT_PINCODE_LOCALITY": "DEOKALI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232340,
      "TXT_PINCODE_LOCALITY": "PHULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2685,
      "City District Name": "CHANDAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232341,
      "TXT_PINCODE_LOCALITY": "TAJPUR MANJHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 232342,
      "TXT_PINCODE_LOCALITY": "GHAZIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 233001,
      "TXT_PINCODE_LOCALITY": "PEERNAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 233002,
      "TXT_PINCODE_LOCALITY": "SULTANPUR MAPHI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5443,
      "City District Name": "SAIDPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 233003,
      "TXT_PINCODE_LOCALITY": "CHAK DAUD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443004,
      "City District Name": "JAKHANIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 233005,
      "TXT_PINCODE_LOCALITY": "SHAHPUR DAYAL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443004,
      "City District Name": "JAKHANIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 233008,
      "TXT_PINCODE_LOCALITY": "BABUA PATTI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 233132,
      "TXT_PINCODE_LOCALITY": "NIKROJPUR.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4536,
      "City District Name": "MOHAMMADABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 233201,
      "TXT_PINCODE_LOCALITY": "B G PUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 233208,
      "TXT_PINCODE_LOCALITY": "BIRNO",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 233213,
      "TXT_PINCODE_LOCALITY": "NASOPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4536,
      "City District Name": "MOHAMMADABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 233220,
      "TXT_PINCODE_LOCALITY": "NEWADA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 233221,
      "TXT_PINCODE_LOCALITY": "AURIHAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 233222,
      "TXT_PINCODE_LOCALITY": "GANGAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 233223,
      "TXT_PINCODE_LOCALITY": "RAMPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 233224,
      "TXT_PINCODE_LOCALITY": "KARANDA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 233225,
      "TXT_PINCODE_LOCALITY": "KARIMUDDINPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 233226,
      "TXT_PINCODE_LOCALITY": "MARDAH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 233227,
      "TXT_PINCODE_LOCALITY": "MOHAMMADABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 233228,
      "TXT_PINCODE_LOCALITY": "TAJPUR DEHMA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 233229,
      "TXT_PINCODE_LOCALITY": "BARA CHAWAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 233230,
      "TXT_PINCODE_LOCALITY": "KASIMABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3365,
      "City District Name": "HARDOI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 233231,
      "TXT_PINCODE_LOCALITY": "MASON",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 233232,
      "TXT_PINCODE_LOCALITY": "GANSHADEPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 233233,
      "TXT_PINCODE_LOCALITY": "KHARDIHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 233234,
      "TXT_PINCODE_LOCALITY": "KUNDESHWAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 233235,
      "TXT_PINCODE_LOCALITY": "MAINPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 233236,
      "TXT_PINCODE_LOCALITY": "MANDHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 233300,
      "TXT_PINCODE_LOCALITY": "BHARSAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 233301,
      "TXT_PINCODE_LOCALITY": "MUBARAKH PUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 233302,
      "TXT_PINCODE_LOCALITY": "NANDGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 233303,
      "TXT_PINCODE_LOCALITY": "NONAHARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6565,
      "City District Name": "FARUKHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 233304,
      "TXT_PINCODE_LOCALITY": "SAYEDPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 233305,
      "TXT_PINCODE_LOCALITY": "PAHEWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 233306,
      "TXT_PINCODE_LOCALITY": "DEOKALI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 233307,
      "TXT_PINCODE_LOCALITY": "BHIMAPAR S.O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 233308,
      "TXT_PINCODE_LOCALITY": "ARAZI ORASAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 233309,
      "TXT_PINCODE_LOCALITY": "GHAZIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 233310,
      "TXT_PINCODE_LOCALITY": "MALINPURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 233311,
      "TXT_PINCODE_LOCALITY": "N S SUGAR MILLS",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 233312,
      "TXT_PINCODE_LOCALITY": "GHAZIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 233323,
      "TXT_PINCODE_LOCALITY": "MAKHDUMPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4536,
      "City District Name": "MOHAMMADABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 233330,
      "TXT_PINCODE_LOCALITY": "BAIKUNTHPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4536,
      "City District Name": "MOHAMMADABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 233337,
      "TXT_PINCODE_LOCALITY": "GHAZIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 234126,
      "TXT_PINCODE_LOCALITY": "TALBEHAT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1801,
      "City District Name": "LALITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 234569,
      "TXT_PINCODE_LOCALITY": "SECTOR-19, VASHI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 236145,
      "TXT_PINCODE_LOCALITY": "RUDRAPUR",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 442120,
      "City District Name": "UDHAM SINGH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 236488,
      "TXT_PINCODE_LOCALITY": "BACHHUBBAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 240001,
      "TXT_PINCODE_LOCALITY": "BELA J. BANIGAWAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5178,
      "City District Name": "POWAYAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 240150,
      "TXT_PINCODE_LOCALITY": "UDESAHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5426,
      "City District Name": "SAFIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 240320,
      "TXT_PINCODE_LOCALITY": "MEMAUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 240330,
      "TXT_PINCODE_LOCALITY": "RAMPPARA BHOPAT NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2089,
      "City District Name": "AONLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 240504,
      "TXT_PINCODE_LOCALITY": "KUREMU",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443002,
      "City District Name": "HASANGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 240701,
      "TXT_PINCODE_LOCALITY": "SARAI MUBARAK",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 356,
      "City District Name": "BIJNOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 240763,
      "TXT_PINCODE_LOCALITY": "SAIDPURI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4685,
      "City District Name": "NAJIBABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241001,
      "TXT_PINCODE_LOCALITY": "BURKOT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3365,
      "City District Name": "HARDOI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241004,
      "TXT_PINCODE_LOCALITY": "GADAIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3365,
      "City District Name": "HARDOI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241053,
      "TXT_PINCODE_LOCALITY": "KHANPUR PEER ALI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443002,
      "City District Name": "HASANGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241113,
      "TXT_PINCODE_LOCALITY": "BEDGAON",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241119,
      "TXT_PINCODE_LOCALITY": "NAIL TORANU",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443068,
      "City District Name": "DEVPRAYAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241120,
      "TXT_PINCODE_LOCALITY": "PIRKAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5485,
      "City District Name": "SANDILA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241121,
      "TXT_PINCODE_LOCALITY": "AHIRORI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3365,
      "City District Name": "HARDOI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241122,
      "TXT_PINCODE_LOCALITY": "HARDOI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3365,
      "City District Name": "HARDOI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241123,
      "TXT_PINCODE_LOCALITY": "PALI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3365,
      "City District Name": "HARDOI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241124,
      "TXT_PINCODE_LOCALITY": "SHAHABAD (HARDOI)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3365,
      "City District Name": "HARDOI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241125,
      "TXT_PINCODE_LOCALITY": "BEHATA GOKUL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3365,
      "City District Name": "HARDOI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241126,
      "TXT_PINCODE_LOCALITY": "KACHHAUNA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3365,
      "City District Name": "HARDOI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241127,
      "TXT_PINCODE_LOCALITY": "INDUSTRIAL ESTATE SANDILA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3365,
      "City District Name": "HARDOI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241200,
      "TXT_PINCODE_LOCALITY": "ALLAWALPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5485,
      "City District Name": "SANDILA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241201,
      "TXT_PINCODE_LOCALITY": "BAHENDAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3365,
      "City District Name": "HARDOI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241202,
      "TXT_PINCODE_LOCALITY": "BHARAWAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3365,
      "City District Name": "HARDOI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241203,
      "TXT_PINCODE_LOCALITY": "DHIKAUNI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3365,
      "City District Name": "HARDOI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241204,
      "TXT_PINCODE_LOCALITY": "SANDILA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3365,
      "City District Name": "HARDOI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241205,
      "TXT_PINCODE_LOCALITY": "NEWADA NARPATI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5485,
      "City District Name": "SANDILA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241295,
      "TXT_PINCODE_LOCALITY": "SEM.",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443106,
      "City District Name": "PRATAPNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241301,
      "TXT_PINCODE_LOCALITY": "BILGRAM",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3365,
      "City District Name": "HARDOI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241302,
      "TXT_PINCODE_LOCALITY": "MADHOGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3365,
      "City District Name": "HARDOI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241303,
      "TXT_PINCODE_LOCALITY": "KANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1086,
      "City District Name": "GAUTAM BUDH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241304,
      "TXT_PINCODE_LOCALITY": "BENIGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3365,
      "City District Name": "HARDOI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241305,
      "TXT_PINCODE_LOCALITY": "BEHSAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3365,
      "City District Name": "HARDOI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241309,
      "TXT_PINCODE_LOCALITY": "DAKAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2564,
      "City District Name": "BILGRAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241314,
      "TXT_PINCODE_LOCALITY": "SHAHBDA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2564,
      "City District Name": "BILGRAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241401,
      "TXT_PINCODE_LOCALITY": "BARAWN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3365,
      "City District Name": "HARDOI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241402,
      "TXT_PINCODE_LOCALITY": "HARPALPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3365,
      "City District Name": "HARDOI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241403,
      "TXT_PINCODE_LOCALITY": "SANDI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3365,
      "City District Name": "HARDOI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241404,
      "TXT_PINCODE_LOCALITY": "GOPAMAU",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3365,
      "City District Name": "HARDOI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241405,
      "TXT_PINCODE_LOCALITY": "HARIWAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3365,
      "City District Name": "HARDOI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241406,
      "TXT_PINCODE_LOCALITY": "BHAGWANT NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3365,
      "City District Name": "HARDOI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241407,
      "TXT_PINCODE_LOCALITY": "SAADAT NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3365,
      "City District Name": "HARDOI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241412,
      "TXT_PINCODE_LOCALITY": "USARHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5485,
      "City District Name": "SANDILA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241501,
      "TXT_PINCODE_LOCALITY": "BANGARMAU",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 367,
      "City District Name": "UNNAO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241502,
      "TXT_PINCODE_LOCALITY": "MORADABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 367,
      "City District Name": "UNNAO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241503,
      "TXT_PINCODE_LOCALITY": "RASOOLABAD (UNNAO)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 367,
      "City District Name": "UNNAO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241516,
      "TXT_PINCODE_LOCALITY": "DHOLWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5426,
      "City District Name": "SAFIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241800,
      "TXT_PINCODE_LOCALITY": "MAINPURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2587,
      "City District Name": "BISALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241965,
      "TXT_PINCODE_LOCALITY": "KAFLNA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443106,
      "City District Name": "PRATAPNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241986,
      "TXT_PINCODE_LOCALITY": "MUNOGI",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443069,
      "City District Name": "DHANAULTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 241996,
      "TXT_PINCODE_LOCALITY": "RAUTAL",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443067,
      "City District Name": "CHINIYALISAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242000,
      "TXT_PINCODE_LOCALITY": "SHAHJAHANPUR H O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242001,
      "TXT_PINCODE_LOCALITY": "SHAHJAHANPUR CITY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242021,
      "TXT_PINCODE_LOCALITY": "I.A. BABRALA S.O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 440710,
      "City District Name": "BUDAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242042,
      "TXT_PINCODE_LOCALITY": "BHAMBHI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242101,
      "TXT_PINCODE_LOCALITY": "SHAHJAHANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242102,
      "TXT_PINCODE_LOCALITY": "SAHBAJ NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242103,
      "TXT_PINCODE_LOCALITY": "SHAHJAHANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242123,
      "TXT_PINCODE_LOCALITY": "GORADHANENEGA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242124,
      "TXT_PINCODE_LOCALITY": "UDHRANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6441,
      "City District Name": "SHAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242125,
      "TXT_PINCODE_LOCALITY": "SUJ GAON",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443112,
      "City District Name": "SRINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242127,
      "TXT_PINCODE_LOCALITY": "BHAMAURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242161,
      "TXT_PINCODE_LOCALITY": "UMATHA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443119,
      "City District Name": "YAMKESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242220,
      "TXT_PINCODE_LOCALITY": "ALLAGANJ S.O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242221,
      "TXT_PINCODE_LOCALITY": "JALALABAD SHAHJAHANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242222,
      "TXT_PINCODE_LOCALITY": "ALLAHABAD (SHAHJAHANPUR)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242223,
      "TXT_PINCODE_LOCALITY": "MIRZAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242225,
      "TXT_PINCODE_LOCALITY": "KANSKHAT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242226,
      "TXT_PINCODE_LOCALITY": "MUMUKHASHRAM",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242227,
      "TXT_PINCODE_LOCALITY": "RAFIABAD KALAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242228,
      "TXT_PINCODE_LOCALITY": "SHAHJAHANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242229,
      "TXT_PINCODE_LOCALITY": "KURSIAKALAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242230,
      "TXT_PINCODE_LOCALITY": "AHMADPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242231,
      "TXT_PINCODE_LOCALITY": "RAMATPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242232,
      "TXT_PINCODE_LOCALITY": "BAREWDA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242234,
      "TXT_PINCODE_LOCALITY": "SHAHJAHANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242301,
      "TXT_PINCODE_LOCALITY": "MIRANPUR KATRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242303,
      "TXT_PINCODE_LOCALITY": "KHAIRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242305,
      "TXT_PINCODE_LOCALITY": "LAXMIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242306,
      "TXT_PINCODE_LOCALITY": "ROSA R S",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242307,
      "TXT_PINCODE_LOCALITY": "TILHAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242308,
      "TXT_PINCODE_LOCALITY": "MILKIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242309,
      "TXT_PINCODE_LOCALITY": "SHAHPUR KHITAB",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242310,
      "TXT_PINCODE_LOCALITY": "JALAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242311,
      "TXT_PINCODE_LOCALITY": "SHAHJAHANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242312,
      "TXT_PINCODE_LOCALITY": "SECTOR 5",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242313,
      "TXT_PINCODE_LOCALITY": "SHAHJAHANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242314,
      "TXT_PINCODE_LOCALITY": "MAUZAM PUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242315,
      "TXT_PINCODE_LOCALITY": "RUDRAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242401,
      "TXT_PINCODE_LOCALITY": "PAWAYAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242402,
      "TXT_PINCODE_LOCALITY": "BANDA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242404,
      "TXT_PINCODE_LOCALITY": "JEWAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242405,
      "TXT_PINCODE_LOCALITY": "KHUTAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242406,
      "TXT_PINCODE_LOCALITY": "ROSA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242407,
      "TXT_PINCODE_LOCALITY": "NIGOHI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242408,
      "TXT_PINCODE_LOCALITY": "KUGRAJPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242409,
      "TXT_PINCODE_LOCALITY": "NAHIL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242410,
      "TXT_PINCODE_LOCALITY": "SUNARA BUJURG",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242411,
      "TXT_PINCODE_LOCALITY": "AZMATPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242413,
      "TXT_PINCODE_LOCALITY": "SERAMAU SOUTH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242415,
      "TXT_PINCODE_LOCALITY": "MIRAD PUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242416,
      "TXT_PINCODE_LOCALITY": "NAWTHA HANS",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242417,
      "TXT_PINCODE_LOCALITY": "SHAHJAHANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242418,
      "TXT_PINCODE_LOCALITY": "RAITAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242419,
      "TXT_PINCODE_LOCALITY": "AKBARIES",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242420,
      "TXT_PINCODE_LOCALITY": "DHAKHIA L AHIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242421,
      "TXT_PINCODE_LOCALITY": "SILHUYA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242429,
      "TXT_PINCODE_LOCALITY": "SUNAK",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443091,
      "City District Name": "KARNAPRAYAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242473,
      "TXT_PINCODE_LOCALITY": "DHAKHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424422,
      "City District Name": "AMBEDKAR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242501,
      "TXT_PINCODE_LOCALITY": "BICHKOT",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443103,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242507,
      "TXT_PINCODE_LOCALITY": "FARIDPUR SIMBHAVALI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3203,
      "City District Name": "GARHMUKTESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242515,
      "TXT_PINCODE_LOCALITY": "TOLWA ANTDANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3365,
      "City District Name": "HARDOI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242523,
      "TXT_PINCODE_LOCALITY": "CHANI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2573,
      "City District Name": "BILSI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242604,
      "TXT_PINCODE_LOCALITY": "UTTAMPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5966,
      "City District Name": "TILHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242605,
      "TXT_PINCODE_LOCALITY": "GAJIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5966,
      "City District Name": "TILHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242606,
      "TXT_PINCODE_LOCALITY": "BAREILLY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242621,
      "TXT_PINCODE_LOCALITY": "HAT",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443117,
      "City District Name": "UKHIMATH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242638,
      "TXT_PINCODE_LOCALITY": "KYAWALI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5434,
      "City District Name": "SAHASWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242648,
      "TXT_PINCODE_LOCALITY": "BEREYA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443111,
      "City District Name": "SITARGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242675,
      "TXT_PINCODE_LOCALITY": "LAKHAID GAON",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443115,
      "City District Name": "THALISAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242701,
      "TXT_PINCODE_LOCALITY": "SITAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5178,
      "City District Name": "POWAYAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 242726,
      "TXT_PINCODE_LOCALITY": "SARAURIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2589,
      "City District Name": "BISAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243000,
      "TXT_PINCODE_LOCALITY": "BAREILLY H O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243001,
      "TXT_PINCODE_LOCALITY": "SHIVAJI MARG",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243002,
      "TXT_PINCODE_LOCALITY": "IZAT NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243003,
      "TXT_PINCODE_LOCALITY": "SUBHAS MARKET",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243004,
      "TXT_PINCODE_LOCALITY": "ITBP BAREILLY S.O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243005,
      "TXT_PINCODE_LOCALITY": "SHYAMGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243006,
      "TXT_PINCODE_LOCALITY": "R.K.UNIVERSITY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243021,
      "TXT_PINCODE_LOCALITY": "NAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2238,
      "City District Name": "BAHERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243037,
      "TXT_PINCODE_LOCALITY": "PAKHUNNI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2238,
      "City District Name": "BAHERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243104,
      "TXT_PINCODE_LOCALITY": "MANGADPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2238,
      "City District Name": "BAHERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243121,
      "TXT_PINCODE_LOCALITY": "PHOOL BAGH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243122,
      "TXT_PINCODE_LOCALITY": "IZATNAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243123,
      "TXT_PINCODE_LOCALITY": "NAKATIA NALA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243124,
      "TXT_PINCODE_LOCALITY": "NEW RAILWAY MODEL COLONY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243125,
      "TXT_PINCODE_LOCALITY": "BITNICHANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243126,
      "TXT_PINCODE_LOCALITY": "BHATA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243127,
      "TXT_PINCODE_LOCALITY": "T M ICHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243132,
      "TXT_PINCODE_LOCALITY": "MEHTARPUR KAROR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3127,
      "City District Name": "FARIDPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243201,
      "TXT_PINCODE_LOCALITY": "BAHERI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243202,
      "TXT_PINCODE_LOCALITY": "BHOJIPURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243203,
      "TXT_PINCODE_LOCALITY": "DEORANIA (B)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243204,
      "TXT_PINCODE_LOCALITY": "BAREILLY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243205,
      "TXT_PINCODE_LOCALITY": "RICHHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243206,
      "TXT_PINCODE_LOCALITY": "GAINI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243208,
      "TXT_PINCODE_LOCALITY": "KHAIMPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243215,
      "TXT_PINCODE_LOCALITY": "KOTHA MAKKHAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4781,
      "City District Name": "NAWABGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243293,
      "TXT_PINCODE_LOCALITY": "MIGHAUNA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2587,
      "City District Name": "BISALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243301,
      "TXT_PINCODE_LOCALITY": "AONLA ESTATE",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243302,
      "TXT_PINCODE_LOCALITY": "ANIRUDHPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243303,
      "TXT_PINCODE_LOCALITY": "RAMNAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243304,
      "TXT_PINCODE_LOCALITY": "MAJHGAWAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243305,
      "TXT_PINCODE_LOCALITY": "ALIGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243324,
      "TXT_PINCODE_LOCALITY": "BUDAUN.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 440021,
      "City District Name": "BADAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243338,
      "TXT_PINCODE_LOCALITY": "HAMUPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5434,
      "City District Name": "SAHASWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243401,
      "TXT_PINCODE_LOCALITY": "BHAMORA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243402,
      "TXT_PINCODE_LOCALITY": "BHAMORE",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243403,
      "TXT_PINCODE_LOCALITY": "CHANCHALI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243404,
      "TXT_PINCODE_LOCALITY": "KARELI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243407,
      "TXT_PINCODE_LOCALITY": "HARHARPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243408,
      "TXT_PINCODE_LOCALITY": "BHITAMA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243501,
      "TXT_PINCODE_LOCALITY": "BHETAURA RUBBER FACTORY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243502,
      "TXT_PINCODE_LOCALITY": "CLUTTERBUCKGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243503,
      "TXT_PINCODE_LOCALITY": "FARIDPUR - 1435",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243504,
      "TXT_PINCODE_LOCALITY": "MIRGANJ (BAREILLY)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243505,
      "TXT_PINCODE_LOCALITY": "SHAHI - 4354",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243506,
      "TXT_PINCODE_LOCALITY": "FATEHGANJ EAST",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243507,
      "TXT_PINCODE_LOCALITY": "BAREILLY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243509,
      "TXT_PINCODE_LOCALITY": "SHARGARH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243601,
      "TXT_PINCODE_LOCALITY": "UTTARNA SHAPURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 440710,
      "City District Name": "BUDAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243604,
      "TXT_PINCODE_LOCALITY": "SETHA DANDI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2898,
      "City District Name": "DATAGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243621,
      "TXT_PINCODE_LOCALITY": "USAWAN - 4932",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1655,
      "City District Name": "BUDAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243630,
      "TXT_PINCODE_LOCALITY": "SUKHAURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 440710,
      "City District Name": "BUDAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243631,
      "TXT_PINCODE_LOCALITY": "ALLAHAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1655,
      "City District Name": "BUDAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243632,
      "TXT_PINCODE_LOCALITY": "ASAFPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 440710,
      "City District Name": "BUDAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243633,
      "TXT_PINCODE_LOCALITY": "BILSI S.O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 440710,
      "City District Name": "BUDAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243634,
      "TXT_PINCODE_LOCALITY": "BINAWAR SO",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1655,
      "City District Name": "BUDAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243635,
      "TXT_PINCODE_LOCALITY": "DATAGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 440710,
      "City District Name": "BUDAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243636,
      "TXT_PINCODE_LOCALITY": "KACHLA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 440710,
      "City District Name": "BUDAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243637,
      "TXT_PINCODE_LOCALITY": "KAKRALA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1655,
      "City District Name": "BUDAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243638,
      "TXT_PINCODE_LOCALITY": "SAHASWAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 440710,
      "City District Name": "BUDAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243639,
      "TXT_PINCODE_LOCALITY": "UJHANI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 440710,
      "City District Name": "BUDAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243640,
      "TXT_PINCODE_LOCALITY": "MOHSHAMPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2898,
      "City District Name": "DATAGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243641,
      "TXT_PINCODE_LOCALITY": "USEHAT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1655,
      "City District Name": "BUDAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243642,
      "TXT_PINCODE_LOCALITY": "SHEKHUPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1655,
      "City District Name": "BUDAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243655,
      "TXT_PINCODE_LOCALITY": "VIRAMPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2898,
      "City District Name": "DATAGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243701,
      "TXT_PINCODE_LOCALITY": "MILAK",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 469,
      "City District Name": "RAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243702,
      "TXT_PINCODE_LOCALITY": "PURAINA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4520,
      "City District Name": "MILAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243720,
      "TXT_PINCODE_LOCALITY": "BUDAUN.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2589,
      "City District Name": "BISAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243722,
      "TXT_PINCODE_LOCALITY": "SIKRAURA KHADAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3327,
      "City District Name": "GUNNAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243723,
      "TXT_PINCODE_LOCALITY": "VIKARAMPUR CHARSAURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2589,
      "City District Name": "BISAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243724,
      "TXT_PINCODE_LOCALITY": "SARERA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2573,
      "City District Name": "BILSI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243725,
      "TXT_PINCODE_LOCALITY": "SHERANDAZPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2589,
      "City District Name": "BISAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243726,
      "TXT_PINCODE_LOCALITY": "BUDAUN H. O.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442131,
      "City District Name": "BUDAUN.",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243727,
      "TXT_PINCODE_LOCALITY": "UDARANPUR KHAGI SHAIFUDDIN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3327,
      "City District Name": "GUNNAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243751,
      "TXT_PINCODE_LOCALITY": "BABRALA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 440021,
      "City District Name": "BADAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 243880,
      "TXT_PINCODE_LOCALITY": "UGANPUR MARORI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2587,
      "City District Name": "BISALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244001,
      "TXT_PINCODE_LOCALITY": "GALASAHEED",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 764,
      "City District Name": "MORADABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244002,
      "TXT_PINCODE_LOCALITY": "NARENDRAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 764,
      "City District Name": "MORADABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244009,
      "TXT_PINCODE_LOCALITY": "SARKARA KHAS",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 764,
      "City District Name": "MORADABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244101,
      "TXT_PINCODE_LOCALITY": "HASMINAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5771,
      "City District Name": "SUAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244102,
      "TXT_PINCODE_LOCALITY": "AMROHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 441039,
      "City District Name": "JYOTIBA PHULE NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244103,
      "TXT_PINCODE_LOCALITY": "MAJHOLA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 764,
      "City District Name": "MORADABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244104,
      "TXT_PINCODE_LOCALITY": "CHANDANPUR ISAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 764,
      "City District Name": "MORADABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244105,
      "TXT_PINCODE_LOCALITY": "MORADABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 764,
      "City District Name": "MORADABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244115,
      "TXT_PINCODE_LOCALITY": "MANGRON SIRAU",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443113,
      "City District Name": "SULT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244121,
      "TXT_PINCODE_LOCALITY": "AHIRORI *",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3365,
      "City District Name": "HARDOI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244124,
      "TXT_PINCODE_LOCALITY": "SHEKHUPURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5771,
      "City District Name": "SUAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244150,
      "TXT_PINCODE_LOCALITY": "PATTI KHANJRI.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443002,
      "City District Name": "HASANGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244172,
      "TXT_PINCODE_LOCALITY": "MANDUA KHERA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443085,
      "City District Name": "JASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244201,
      "TXT_PINCODE_LOCALITY": "JALALABAD MAJRA SUNPURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2045,
      "City District Name": "AMROHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244211,
      "TXT_PINCODE_LOCALITY": "MOUAMMADPUR NAWADA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2045,
      "City District Name": "AMROHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244221,
      "TXT_PINCODE_LOCALITY": "SHAHI CHABUTRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 764,
      "City District Name": "MORADABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244222,
      "TXT_PINCODE_LOCALITY": "JOYA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424352,
      "City District Name": "JOTIBARAO PHULE NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244223,
      "TXT_PINCODE_LOCALITY": "LAXMI NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424352,
      "City District Name": "JOTIBARAO PHULE NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244224,
      "TXT_PINCODE_LOCALITY": "MORADABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 764,
      "City District Name": "MORADABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244225,
      "TXT_PINCODE_LOCALITY": "AMROHA B.O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 441039,
      "City District Name": "JYOTIBA PHULE NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244226,
      "TXT_PINCODE_LOCALITY": "RAMSARAI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3777,
      "City District Name": "KANTH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244227,
      "TXT_PINCODE_LOCALITY": "KANTH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 764,
      "City District Name": "MORADABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244231,
      "TXT_PINCODE_LOCALITY": "DHANAURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2045,
      "City District Name": "AMROHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244235,
      "TXT_PINCODE_LOCALITY": "GAJRAULA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424352,
      "City District Name": "JOTIBARAO PHULE NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244236,
      "TXT_PINCODE_LOCALITY": "RAJJABPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424352,
      "City District Name": "JOTIBARAO PHULE NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244241,
      "TXT_PINCODE_LOCALITY": "HASANPUR (MORADABAD)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424352,
      "City District Name": "JOTIBARAO PHULE NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244242,
      "TXT_PINCODE_LOCALITY": "HASANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2045,
      "City District Name": "AMROHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244244,
      "TXT_PINCODE_LOCALITY": "BURAWALI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424352,
      "City District Name": "JOTIBARAO PHULE NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244245,
      "TXT_PINCODE_LOCALITY": "KUNDAN NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 441039,
      "City District Name": "JYOTIBA PHULE NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244246,
      "TXT_PINCODE_LOCALITY": "AHAMADPUR DEVIPURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2045,
      "City District Name": "AMROHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244249,
      "TXT_PINCODE_LOCALITY": "KUNDANNAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424352,
      "City District Name": "JOTIBARAO PHULE NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244251,
      "TXT_PINCODE_LOCALITY": "NOWGAON (MORADABAD)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424352,
      "City District Name": "JOTIBARAO PHULE NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244252,
      "TXT_PINCODE_LOCALITY": "CHAMRAUA.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2967,
      "City District Name": "DHANAURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244255,
      "TXT_PINCODE_LOCALITY": "HASANPUR  B.O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2045,
      "City District Name": "AMROHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244291,
      "TXT_PINCODE_LOCALITY": "PATOI GAON",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443084,
      "City District Name": "JAKHNIDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244301,
      "TXT_PINCODE_LOCALITY": "AZAMNAGAR-CHOPRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5473,
      "City District Name": "SAMBHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244302,
      "TXT_PINCODE_LOCALITY": "SAMBHAL B. O.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5473,
      "City District Name": "SAMBHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244303,
      "TXT_PINCODE_LOCALITY": "SARAI TARIN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 764,
      "City District Name": "MORADABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244304,
      "TXT_PINCODE_LOCALITY": "ASMOLI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424352,
      "City District Name": "JOTIBARAO PHULE NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244321,
      "TXT_PINCODE_LOCALITY": "ANWARIATALIBABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 469,
      "City District Name": "RAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244401,
      "TXT_PINCODE_LOCALITY": "DILARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 764,
      "City District Name": "MORADABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244402,
      "TXT_PINCODE_LOCALITY": "PIPALSANA R S",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 764,
      "City District Name": "MORADABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244409,
      "TXT_PINCODE_LOCALITY": "SAHAS PURI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5883,
      "City District Name": "THAKURDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244410,
      "TXT_PINCODE_LOCALITY": "BAHJOI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5473,
      "City District Name": "SAMBHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244411,
      "TXT_PINCODE_LOCALITY": "MORADABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 764,
      "City District Name": "MORADABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244412,
      "TXT_PINCODE_LOCALITY": "CHANDOUSI R S",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 764,
      "City District Name": "MORADABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244413,
      "TXT_PINCODE_LOCALITY": "SEKHOOPUR MAHESH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2558,
      "City District Name": "BILARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244414,
      "TXT_PINCODE_LOCALITY": "NAGALIA BALLU",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2687,
      "City District Name": "CHANDAUSI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244415,
      "TXT_PINCODE_LOCALITY": "UGANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2558,
      "City District Name": "BILARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244501,
      "TXT_PINCODE_LOCALITY": "MOHDPUR MAFI BO",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 764,
      "City District Name": "MORADABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244502,
      "TXT_PINCODE_LOCALITY": "AGHWANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 764,
      "City District Name": "MORADABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244504,
      "TXT_PINCODE_LOCALITY": "MODHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 764,
      "City District Name": "MORADABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244515,
      "TXT_PINCODE_LOCALITY": "TUKURA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443113,
      "City District Name": "SULT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244517,
      "TXT_PINCODE_LOCALITY": "SINGRO",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443113,
      "City District Name": "SULT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244567,
      "TXT_PINCODE_LOCALITY": "SALAMATPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2961,
      "City District Name": "DHAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244577,
      "TXT_PINCODE_LOCALITY": "AMDALI",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443113,
      "City District Name": "SULT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244601,
      "TXT_PINCODE_LOCALITY": "THAKURDWARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 764,
      "City District Name": "MORADABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244602,
      "TXT_PINCODE_LOCALITY": "SURJAN NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 764,
      "City District Name": "MORADABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244640,
      "TXT_PINCODE_LOCALITY": "MALGUR",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443077,
      "City District Name": "GAIRSAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244672,
      "TXT_PINCODE_LOCALITY": "POKHANI URF POKHARI",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443086,
      "City District Name": "JOSHIMATH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244676,
      "TXT_PINCODE_LOCALITY": "MIRZAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2961,
      "City District Name": "DHAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244701,
      "TXT_PINCODE_LOCALITY": "SIRRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4520,
      "City District Name": "MILAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244711,
      "TXT_PINCODE_LOCALITY": "UDHAM SINGH NAGAR",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 442120,
      "City District Name": "UDHAM SINGH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244712,
      "TXT_PINCODE_LOCALITY": "JASPUR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424410,
      "City District Name": "UDHAMSINGH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244713,
      "TXT_PINCODE_LOCALITY": "KASHIPUR S. O",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 441214,
      "City District Name": "KASHIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244714,
      "TXT_PINCODE_LOCALITY": "SUGAR MILL",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424410,
      "City District Name": "UDHAMSINGH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244715,
      "TXT_PINCODE_LOCALITY": "RAMNAGAR S.O (NAINITAL)",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 441374,
      "City District Name": "NAINITAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244716,
      "TXT_PINCODE_LOCALITY": "R.T.C. MEMPUR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 4682,
      "City District Name": "NAINITAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244717,
      "TXT_PINCODE_LOCALITY": "SUGAR FACTORY NADEHI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 4682,
      "City District Name": "NAINITAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244718,
      "TXT_PINCODE_LOCALITY": "BHAIRONGKHAL",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424410,
      "City District Name": "UDHAMSINGH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244769,
      "TXT_PINCODE_LOCALITY": "SAHARANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 352,
      "City District Name": "SAHARANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244901,
      "TXT_PINCODE_LOCALITY": "RAMPUR CITY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 469,
      "City District Name": "RAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244902,
      "TXT_PINCODE_LOCALITY": "MUNDHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 764,
      "City District Name": "MORADABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244920,
      "TXT_PINCODE_LOCALITY": "BEERPUR THAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 764,
      "City District Name": "MORADABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244921,
      "TXT_PINCODE_LOCALITY": "BILASPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 469,
      "City District Name": "RAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244922,
      "TXT_PINCODE_LOCALITY": "SHAHABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 469,
      "City District Name": "RAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244923,
      "TXT_PINCODE_LOCALITY": "BILASPUR SUGAR MILL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 469,
      "City District Name": "RAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244924,
      "TXT_PINCODE_LOCALITY": "SUAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 469,
      "City District Name": "RAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244925,
      "TXT_PINCODE_LOCALITY": "TANDA (RAMPUR)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 469,
      "City District Name": "RAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244926,
      "TXT_PINCODE_LOCALITY": "MUNDHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 764,
      "City District Name": "MORADABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244927,
      "TXT_PINCODE_LOCALITY": "SAIDNAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 764,
      "City District Name": "MORADABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 244928,
      "TXT_PINCODE_LOCALITY": "KEMRI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 469,
      "City District Name": "RAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 245002,
      "TXT_PINCODE_LOCALITY": "SHUKLA URF SUMLA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3777,
      "City District Name": "KANTH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 245100,
      "TXT_PINCODE_LOCALITY": "HAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1171,
      "City District Name": "HAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 245101,
      "TXT_PINCODE_LOCALITY": "HAPUR MANDI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1861,
      "City District Name": "GHAZIABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 245102,
      "TXT_PINCODE_LOCALITY": "INDUSTRIAL ESTATE HAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1861,
      "City District Name": "GHAZIABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 245104,
      "TXT_PINCODE_LOCALITY": "YUSUFPUR KOTA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1171,
      "City District Name": "HAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 245131,
      "TXT_PINCODE_LOCALITY": "BHALDIYANA *",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 245201,
      "TXT_PINCODE_LOCALITY": "BABUGARH - 337",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1861,
      "City District Name": "GHAZIABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 245205,
      "TXT_PINCODE_LOCALITY": "GARHMUKTEHSWAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1861,
      "City District Name": "GHAZIABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 245206,
      "TXT_PINCODE_LOCALITY": "KHARKHAUDA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 245207,
      "TXT_PINCODE_LOCALITY": "SIMBHAOLI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1861,
      "City District Name": "GHAZIABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 245208,
      "TXT_PINCODE_LOCALITY": "BAHADURGARH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1861,
      "City District Name": "GHAZIABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 245301,
      "TXT_PINCODE_LOCALITY": "DHAULANA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1861,
      "City District Name": "GHAZIABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 245303,
      "TXT_PINCODE_LOCALITY": "ATRAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1861,
      "City District Name": "GHAZIABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 245304,
      "TXT_PINCODE_LOCALITY": "PILKHUWA NEW MARKET",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1861,
      "City District Name": "GHAZIABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 245305,
      "TXT_PINCODE_LOCALITY": "BARODA HINDUVAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1171,
      "City District Name": "HAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 245401,
      "TXT_PINCODE_LOCALITY": "AURANGABAD (BULANDSHAHR)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 357,
      "City District Name": "BULANDSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 245402,
      "TXT_PINCODE_LOCALITY": "B B BAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 357,
      "City District Name": "BULANDSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 245403,
      "TXT_PINCODE_LOCALITY": "BUGRASI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 357,
      "City District Name": "BULANDSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 245405,
      "TXT_PINCODE_LOCALITY": "KHANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 357,
      "City District Name": "BULANDSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 245406,
      "TXT_PINCODE_LOCALITY": "BHATONA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1861,
      "City District Name": "GHAZIABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 245407,
      "TXT_PINCODE_LOCALITY": "LAKHAOTI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 357,
      "City District Name": "BULANDSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 245408,
      "TXT_PINCODE_LOCALITY": "GULAUTHI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 357,
      "City District Name": "BULANDSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 245409,
      "TXT_PINCODE_LOCALITY": "SARAI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 357,
      "City District Name": "BULANDSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 245411,
      "TXT_PINCODE_LOCALITY": "SAIDPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 357,
      "City District Name": "BULANDSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 245412,
      "TXT_PINCODE_LOCALITY": "SIYANA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 357,
      "City District Name": "BULANDSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 245413,
      "TXT_PINCODE_LOCALITY": "ATTA.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 357,
      "City District Name": "BULANDSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 245414,
      "TXT_PINCODE_LOCALITY": "SADHARANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 357,
      "City District Name": "BULANDSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 245641,
      "TXT_PINCODE_LOCALITY": "BACHHELI DARANAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2898,
      "City District Name": "DATAGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246001,
      "TXT_PINCODE_LOCALITY": "KHANDA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246101,
      "TXT_PINCODE_LOCALITY": "WAJALI",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443102,
      "City District Name": "PAURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246110,
      "TXT_PINCODE_LOCALITY": "SIMDI",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443115,
      "City District Name": "THALISAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246113,
      "TXT_PINCODE_LOCALITY": "KAFOL BUNGA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443102,
      "City District Name": "PAURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246121,
      "TXT_PINCODE_LOCALITY": "YAMKESHWAR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 441030,
      "City District Name": "PAURI GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246123,
      "TXT_PINCODE_LOCALITY": "CHIPALGHAT",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246124,
      "TXT_PINCODE_LOCALITY": "DADAMANDI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246125,
      "TXT_PINCODE_LOCALITY": "DEHALCHAURI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246127,
      "TXT_PINCODE_LOCALITY": "DOGADDA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246128,
      "TXT_PINCODE_LOCALITY": "CHELUSAIN",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246129,
      "TXT_PINCODE_LOCALITY": "PUCHHARI LAGGA GUDETHA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443110,
      "City District Name": "SATPULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246130,
      "TXT_PINCODE_LOCALITY": "CHAKISAIN",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246131,
      "TXT_PINCODE_LOCALITY": "EKESHWAR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246132,
      "TXT_PINCODE_LOCALITY": "BINJOLI",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443110,
      "City District Name": "SATPULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246135,
      "TXT_PINCODE_LOCALITY": "THAPLA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443066,
      "City District Name": "CHAUBATTAKHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246139,
      "TXT_PINCODE_LOCALITY": "JAHARIKHAL",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246141,
      "TXT_PINCODE_LOCALITY": "CHAMOLI.",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 442067,
      "City District Name": "RUDRAPRAYAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246142,
      "TXT_PINCODE_LOCALITY": "KALAGARH",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246143,
      "TXT_PINCODE_LOCALITY": "THAIR",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443102,
      "City District Name": "PAURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246144,
      "TXT_PINCODE_LOCALITY": "PAUKHAL",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246146,
      "TXT_PINCODE_LOCALITY": "SIMLYA LAGGA BHANDALU",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443102,
      "City District Name": "PAURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246147,
      "TXT_PINCODE_LOCALITY": "DEWALGARH",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246148,
      "TXT_PINCODE_LOCALITY": "BURANSI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246149,
      "TXT_PINCODE_LOCALITY": "KOTDWARA RLY ST",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246155,
      "TXT_PINCODE_LOCALITY": "DABRI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246159,
      "TXT_PINCODE_LOCALITY": "LWALI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246161,
      "TXT_PINCODE_LOCALITY": "NAINIDANDA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246162,
      "TXT_PINCODE_LOCALITY": "NAUGAON KHAL",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246163,
      "TXT_PINCODE_LOCALITY": "MAWADHAR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246164,
      "TXT_PINCODE_LOCALITY": "PABUA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246165,
      "TXT_PINCODE_LOCALITY": "PAIDUL",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246166,
      "TXT_PINCODE_LOCALITY": "PARSUNDAKHAL",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246167,
      "TXT_PINCODE_LOCALITY": "PATTI SAIN",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246169,
      "TXT_PINCODE_LOCALITY": "POKHRA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246170,
      "TXT_PINCODE_LOCALITY": "KARTIA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443097,
      "City District Name": "LANSDOWNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246171,
      "TXT_PINCODE_LOCALITY": "SIROBAGARH",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 442067,
      "City District Name": "RUDRAPRAYAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246172,
      "TXT_PINCODE_LOCALITY": "SATPULL",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246173,
      "TXT_PINCODE_LOCALITY": "SILOGI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246174,
      "TXT_PINCODE_LOCALITY": "SRINAGAR (PAURI)",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246175,
      "TXT_PINCODE_LOCALITY": "NAITHANA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246176,
      "TXT_PINCODE_LOCALITY": "TOLI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246177,
      "TXT_PINCODE_LOCALITY": "CHOURKHINDA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443115,
      "City District Name": "THALISAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246178,
      "TXT_PINCODE_LOCALITY": "PALLA BAZAR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424415,
      "City District Name": "RUDRAPRAYAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246179,
      "TXT_PINCODE_LOCALITY": "BUBAR KHAL",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246182,
      "TXT_PINCODE_LOCALITY": "KALIA SAUR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246185,
      "TXT_PINCODE_LOCALITY": "BUGHANI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 4682,
      "City District Name": "NAINITAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246186,
      "TXT_PINCODE_LOCALITY": "TEHRI GARHWAL",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 441254,
      "City District Name": "TEHRI GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246188,
      "TXT_PINCODE_LOCALITY": "ADARIA KHAL",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246189,
      "TXT_PINCODE_LOCALITY": "PAURI GARHWAL",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 442041,
      "City District Name": "PAURI GARHWAL.",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246191,
      "TXT_PINCODE_LOCALITY": "PAURI GARHWAL",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 442041,
      "City District Name": "PAURI GARHWAL.",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246192,
      "TXT_PINCODE_LOCALITY": "PAURI GARHWAL",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 442041,
      "City District Name": "PAURI GARHWAL.",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246194,
      "TXT_PINCODE_LOCALITY": "G.B. PANT ENGG. COLLEGE S.O",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 441030,
      "City District Name": "PAURI GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246217,
      "TXT_PINCODE_LOCALITY": "KHUTIDA BICHLA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443072,
      "City District Name": "DHOOMAKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246262,
      "TXT_PINCODE_LOCALITY": "FATAHPUR AHATMALI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4665,
      "City District Name": "NAGINA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246275,
      "TXT_PINCODE_LOCALITY": "BAIJIRAO",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246276,
      "TXT_PINCODE_LOCALITY": "CHANDOLI MALLI B.O",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 442041,
      "City District Name": "PAURI GARHWAL.",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246277,
      "TXT_PINCODE_LOCALITY": "DHUMAKOT",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246278,
      "TXT_PINCODE_LOCALITY": "SYUNSINAGAR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246279,
      "TXT_PINCODE_LOCALITY": "SALD MAHADEV",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 441030,
      "City District Name": "PAURI GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246280,
      "TXT_PINCODE_LOCALITY": "GAMBHERU BAKHAL",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443072,
      "City District Name": "DHOOMAKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246281,
      "TXT_PINCODE_LOCALITY": "HARSU",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443095,
      "City District Name": "KOTDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246282,
      "TXT_PINCODE_LOCALITY": "SIRKHET",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443072,
      "City District Name": "DHOOMAKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246285,
      "TXT_PINCODE_LOCALITY": "THALISAIN",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246375,
      "TXT_PINCODE_LOCALITY": "KHAWZA AHAMADPUR JALAL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2961,
      "City District Name": "DHAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246401,
      "TXT_PINCODE_LOCALITY": "GOPESHWAR H O",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6578,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246406,
      "TXT_PINCODE_LOCALITY": "KOLANI",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443077,
      "City District Name": "GAIRSAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246412,
      "TXT_PINCODE_LOCALITY": "KOLLU BHANNU",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 442067,
      "City District Name": "RUDRAPRAYAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246413,
      "TXT_PINCODE_LOCALITY": "RANGCHAURA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443077,
      "City District Name": "GAIRSAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246419,
      "TXT_PINCODE_LOCALITY": "BHIRI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424415,
      "City District Name": "RUDRAPRAYAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246421,
      "TXT_PINCODE_LOCALITY": "AGUST MUNI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6578,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246422,
      "TXT_PINCODE_LOCALITY": "BADRINATH",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6578,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246423,
      "TXT_PINCODE_LOCALITY": "BAMPA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6578,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246424,
      "TXT_PINCODE_LOCALITY": "CHAMOLI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6578,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246425,
      "TXT_PINCODE_LOCALITY": "CHANDRAPURI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424412,
      "City District Name": "TEHRI GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246426,
      "TXT_PINCODE_LOCALITY": "CHHINKA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424415,
      "City District Name": "RUDRAPRAYAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246427,
      "TXT_PINCODE_LOCALITY": "DEBAL",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6578,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246428,
      "TXT_PINCODE_LOCALITY": "GAIRSAIN",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6578,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246429,
      "TXT_PINCODE_LOCALITY": "GAUCHER",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246430,
      "TXT_PINCODE_LOCALITY": "GHOLNEER",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6578,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246431,
      "TXT_PINCODE_LOCALITY": "MEHALCHORI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6578,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246432,
      "TXT_PINCODE_LOCALITY": "NOTI I D S O",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6578,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246435,
      "TXT_PINCODE_LOCALITY": "GHAT",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6578,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246437,
      "TXT_PINCODE_LOCALITY": "VIDHYA PEETH",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6578,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246438,
      "TXT_PINCODE_LOCALITY": "GHOLTIR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6578,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246439,
      "TXT_PINCODE_LOCALITY": "GUPTKASHI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424415,
      "City District Name": "RUDRAPRAYAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246440,
      "TXT_PINCODE_LOCALITY": "ADIBADRI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6578,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246441,
      "TXT_PINCODE_LOCALITY": "GWALDAM",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6578,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246442,
      "TXT_PINCODE_LOCALITY": "JAKHINI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6578,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246443,
      "TXT_PINCODE_LOCALITY": "JOSHIMATH",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6578,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246444,
      "TXT_PINCODE_LOCALITY": "KARANPRAYAG",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6578,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246445,
      "TXT_PINCODE_LOCALITY": "KEDAR NATH",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424415,
      "City District Name": "RUDRAPRAYAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246446,
      "TXT_PINCODE_LOCALITY": "LANGASU",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6578,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246447,
      "TXT_PINCODE_LOCALITY": "MALARI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6578,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246448,
      "TXT_PINCODE_LOCALITY": "MAIKOTI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 5397,
      "City District Name": "RUDRA PRAYAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246449,
      "TXT_PINCODE_LOCALITY": "NAND PRAYAG",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6578,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246450,
      "TXT_PINCODE_LOCALITY": "SONLA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6578,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246451,
      "TXT_PINCODE_LOCALITY": "JAKH",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6578,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246452,
      "TXT_PINCODE_LOCALITY": "MOLI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6578,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246453,
      "TXT_PINCODE_LOCALITY": "NALGAON",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6578,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246455,
      "TXT_PINCODE_LOCALITY": "NARAIN BAZAR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6578,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246456,
      "TXT_PINCODE_LOCALITY": "CHAMOLI",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443064,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246458,
      "TXT_PINCODE_LOCALITY": "MAITHANA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6578,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246460,
      "TXT_PINCODE_LOCALITY": "CHIWAI",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 442067,
      "City District Name": "RUDRAPRAYAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246469,
      "TXT_PINCODE_LOCALITY": "OKHIMATH",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424415,
      "City District Name": "RUDRAPRAYAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246470,
      "TXT_PINCODE_LOCALITY": "PALLA BAZAR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6578,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246471,
      "TXT_PINCODE_LOCALITY": "PHATA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424415,
      "City District Name": "RUDRAPRAYAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246472,
      "TXT_PINCODE_LOCALITY": "PIPALKOTE",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6578,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246473,
      "TXT_PINCODE_LOCALITY": "POKHRINAGNATH",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6578,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246474,
      "TXT_PINCODE_LOCALITY": "SIMLI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6578,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246475,
      "TXT_PINCODE_LOCALITY": "TILWARA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424415,
      "City District Name": "RUDRAPRAYAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246476,
      "TXT_PINCODE_LOCALITY": "GAURI KUND",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424415,
      "City District Name": "RUDRAPRAYAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246477,
      "TXT_PINCODE_LOCALITY": "TRIJUGINARAIN",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424415,
      "City District Name": "RUDRAPRAYAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246478,
      "TXT_PINCODE_LOCALITY": "NARAINI KOTEE",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6578,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246479,
      "TXT_PINCODE_LOCALITY": "PANDUKESHWAR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6578,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246480,
      "TXT_PINCODE_LOCALITY": "TANGARI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6578,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246481,
      "TXT_PINCODE_LOCALITY": "THARALI.",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443064,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246482,
      "TXT_PINCODE_LOCALITY": "TALWARI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6578,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246483,
      "TXT_PINCODE_LOCALITY": "TAPOBAN",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6578,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246484,
      "TXT_PINCODE_LOCALITY": "TRIVENI (CHAMOLI)",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6578,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246485,
      "TXT_PINCODE_LOCALITY": "BADGAON",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246486,
      "TXT_PINCODE_LOCALITY": "BACHHUWABAN",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6578,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246487,
      "TXT_PINCODE_LOCALITY": "NAUTI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6578,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246488,
      "TXT_PINCODE_LOCALITY": "CHAMOLI",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443064,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246489,
      "TXT_PINCODE_LOCALITY": "THRI PARK",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6578,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246494,
      "TXT_PINCODE_LOCALITY": "BHADUDA.",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443104,
      "City District Name": "POKHARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246495,
      "TXT_PINCODE_LOCALITY": "CHAPA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424415,
      "City District Name": "RUDRAPRAYAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246535,
      "TXT_PINCODE_LOCALITY": "F-1",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443064,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246601,
      "TXT_PINCODE_LOCALITY": "MAJIYADA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443102,
      "City District Name": "PAURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246643,
      "TXT_PINCODE_LOCALITY": "TANGNI TALLI",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443086,
      "City District Name": "JOSHIMATH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246648,
      "TXT_PINCODE_LOCALITY": "NAUTI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6578,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246701,
      "TXT_PINCODE_LOCALITY": "BIJNOR H O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 356,
      "City District Name": "BIJNOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246704,
      "TXT_PINCODE_LOCALITY": "NARAINPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 356,
      "City District Name": "BIJNOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246721,
      "TXT_PINCODE_LOCALITY": "MANDAWAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 356,
      "City District Name": "BIJNOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246722,
      "TXT_PINCODE_LOCALITY": "AFZALGARH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 356,
      "City District Name": "BIJNOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246723,
      "TXT_PINCODE_LOCALITY": "KHIRNI BARKATPUR B.O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 356,
      "City District Name": "BIJNOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246724,
      "TXT_PINCODE_LOCALITY": "BARHAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 356,
      "City District Name": "BIJNOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246725,
      "TXT_PINCODE_LOCALITY": "CHANDPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 356,
      "City District Name": "BIJNOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246726,
      "TXT_PINCODE_LOCALITY": "HAIDAUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 356,
      "City District Name": "BIJNOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246727,
      "TXT_PINCODE_LOCALITY": "ASGARIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 356,
      "City District Name": "BIJNOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246728,
      "TXT_PINCODE_LOCALITY": "JHALU",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 356,
      "City District Name": "BIJNOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246729,
      "TXT_PINCODE_LOCALITY": "BIJNOR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 356,
      "City District Name": "BIJNOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246731,
      "TXT_PINCODE_LOCALITY": "KIRATPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 356,
      "City District Name": "BIJNOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246732,
      "TXT_PINCODE_LOCALITY": "NAGAL (BIJNOR)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 356,
      "City District Name": "BIJNOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246733,
      "TXT_PINCODE_LOCALITY": "NIHTAUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 356,
      "City District Name": "BIJNOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246734,
      "TXT_PINCODE_LOCALITY": "NURPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 356,
      "City District Name": "BIJNOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246735,
      "TXT_PINCODE_LOCALITY": "BHOGPUR B.O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 356,
      "City District Name": "BIJNOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246736,
      "TXT_PINCODE_LOCALITY": "BASANTPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 356,
      "City District Name": "BIJNOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246737,
      "TXT_PINCODE_LOCALITY": "RAHU NAGLI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 356,
      "City District Name": "BIJNOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246738,
      "TXT_PINCODE_LOCALITY": "AKANDHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 356,
      "City District Name": "BIJNOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246739,
      "TXT_PINCODE_LOCALITY": "KHAJURI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 198,
      "City District Name": "CHANDPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246741,
      "TXT_PINCODE_LOCALITY": "RAHTAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2961,
      "City District Name": "DHAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246745,
      "TXT_PINCODE_LOCALITY": "SAHASPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 356,
      "City District Name": "BIJNOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246746,
      "TXT_PINCODE_LOCALITY": "SEOHARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 356,
      "City District Name": "BIJNOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246747,
      "TXT_PINCODE_LOCALITY": "SHERKOT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 356,
      "City District Name": "BIJNOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246748,
      "TXT_PINCODE_LOCALITY": "ASGHARIPUR - 3397",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 356,
      "City District Name": "BIJNOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246749,
      "TXT_PINCODE_LOCALITY": "SAHANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 356,
      "City District Name": "BIJNOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246751,
      "TXT_PINCODE_LOCALITY": "QADRABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 356,
      "City District Name": "BIJNOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246760,
      "TXT_PINCODE_LOCALITY": "KAND KHERI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2961,
      "City District Name": "DHAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246761,
      "TXT_PINCODE_LOCALITY": "DHAMPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 356,
      "City District Name": "BIJNOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246762,
      "TXT_PINCODE_LOCALITY": "NAGINA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 356,
      "City District Name": "BIJNOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246763,
      "TXT_PINCODE_LOCALITY": "JALALABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 356,
      "City District Name": "BIJNOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246764,
      "TXT_PINCODE_LOCALITY": "SUNPATA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 356,
      "City District Name": "BIJNOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246765,
      "TXT_PINCODE_LOCALITY": "AKBARABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 356,
      "City District Name": "BIJNOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246767,
      "TXT_PINCODE_LOCALITY": "HASAN ALIPUR MUTHRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4665,
      "City District Name": "NAGINA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246774,
      "TXT_PINCODE_LOCALITY": "TOLIYON",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443112,
      "City District Name": "SRINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 246864,
      "TXT_PINCODE_LOCALITY": "KOT",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443077,
      "City District Name": "GAIRSAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247001,
      "TXT_PINCODE_LOCALITY": "SAHARANPUR S. O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 352,
      "City District Name": "SAHARANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247002,
      "TXT_PINCODE_LOCALITY": "MEGHCHAPAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 352,
      "City District Name": "SAHARANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247111,
      "TXT_PINCODE_LOCALITY": "LOHARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 352,
      "City District Name": "SAHARANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247120,
      "TXT_PINCODE_LOCALITY": "BUZURG",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 352,
      "City District Name": "SAHARANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247121,
      "TXT_PINCODE_LOCALITY": "BEHAT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 352,
      "City District Name": "SAHARANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247122,
      "TXT_PINCODE_LOCALITY": "BADSHAHI BAGH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 352,
      "City District Name": "SAHARANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247129,
      "TXT_PINCODE_LOCALITY": "MUZAFARABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 352,
      "City District Name": "SAHARANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247142,
      "TXT_PINCODE_LOCALITY": "DATAULI MUGHAL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 352,
      "City District Name": "SAHARANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247153,
      "TXT_PINCODE_LOCALITY": "NANHERA KALAN AHT.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5316,
      "City District Name": "RAMPUR MANIHARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247165,
      "TXT_PINCODE_LOCALITY": "SIRWANI",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443106,
      "City District Name": "PRATAPNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247231,
      "TXT_PINCODE_LOCALITY": "CHIKHANA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 352,
      "City District Name": "SAHARANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247232,
      "TXT_PINCODE_LOCALITY": "SARSAWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 352,
      "City District Name": "SAHARANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247304,
      "TXT_PINCODE_LOCALITY": "KAZIPURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4688,
      "City District Name": "NAKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247323,
      "TXT_PINCODE_LOCALITY": "SARSAWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 352,
      "City District Name": "SAHARANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247340,
      "TXT_PINCODE_LOCALITY": "AMBEHTA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 352,
      "City District Name": "SAHARANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247341,
      "TXT_PINCODE_LOCALITY": "GANGOH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 352,
      "City District Name": "SAHARANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247342,
      "TXT_PINCODE_LOCALITY": "NAKUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 352,
      "City District Name": "SAHARANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247343,
      "TXT_PINCODE_LOCALITY": "TIRRON",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 352,
      "City District Name": "SAHARANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247347,
      "TXT_PINCODE_LOCALITY": "NAKUR(DEHAT)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4688,
      "City District Name": "NAKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247402,
      "TXT_PINCODE_LOCALITY": "AHIRAULI BAGHEL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4186,
      "City District Name": "KUSHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247440,
      "TXT_PINCODE_LOCALITY": "LUNDHI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4688,
      "City District Name": "NAKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247451,
      "TXT_PINCODE_LOCALITY": "RAMPU (SAHARANPUR)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 352,
      "City District Name": "SAHARANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247452,
      "TXT_PINCODE_LOCALITY": "NANAUTA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 352,
      "City District Name": "SAHARANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247453,
      "TXT_PINCODE_LOCALITY": "JARODA PANDEY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 352,
      "City District Name": "SAHARANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247455,
      "TXT_PINCODE_LOCALITY": "HASANPUR LAUTNI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5316,
      "City District Name": "RAMPUR MANIHARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247457,
      "TXT_PINCODE_LOCALITY": "RATAN HERI AHT.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5316,
      "City District Name": "RAMPUR MANIHARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247471,
      "TXT_PINCODE_LOCALITY": "TAMDHAR",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443112,
      "City District Name": "SRINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247509,
      "TXT_PINCODE_LOCALITY": "KESHOPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2238,
      "City District Name": "BAHERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247523,
      "TXT_PINCODE_LOCALITY": "MANDURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 352,
      "City District Name": "SAHARANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247543,
      "TXT_PINCODE_LOCALITY": "MAHESHPUR MU.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5316,
      "City District Name": "RAMPUR MANIHARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247551,
      "TXT_PINCODE_LOCALITY": "NAGAL (SAHARANPUR)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 352,
      "City District Name": "SAHARANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247554,
      "TXT_PINCODE_LOCALITY": "DEOBAND",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 352,
      "City District Name": "SAHARANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247557,
      "TXT_PINCODE_LOCALITY": "KURALKI KHURD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 352,
      "City District Name": "SAHARANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247604,
      "TXT_PINCODE_LOCALITY": "RUHALKI KISHANPUR URF IKKAD",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443082,
      "City District Name": "HARDWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247656,
      "TXT_PINCODE_LOCALITY": "MANGLAURTOWN",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6311,
      "City District Name": "HARIDWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247661,
      "TXT_PINCODE_LOCALITY": "BHAGWANPUR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6311,
      "City District Name": "HARIDWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247662,
      "TXT_PINCODE_LOCALITY": "ALAWALPUR P.O",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443082,
      "City District Name": "HARDWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247663,
      "TXT_PINCODE_LOCALITY": "LAKSAR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6311,
      "City District Name": "HARIDWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247664,
      "TXT_PINCODE_LOCALITY": "LANDHAURA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6311,
      "City District Name": "HARIDWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247665,
      "TXT_PINCODE_LOCALITY": "JHABRERA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6311,
      "City District Name": "HARIDWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247666,
      "TXT_PINCODE_LOCALITY": "MILAP NAGAR S.O",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6311,
      "City District Name": "HARIDWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247667,
      "TXT_PINCODE_LOCALITY": "ROORKEE CANTT",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 3367,
      "City District Name": "HARDWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247668,
      "TXT_PINCODE_LOCALITY": "IQBALPUR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6311,
      "City District Name": "HARIDWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247669,
      "TXT_PINCODE_LOCALITY": "GREF CENTRE",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 352,
      "City District Name": "SAHARANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247670,
      "TXT_PINCODE_LOCALITY": "NARSAN KALAN",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6311,
      "City District Name": "HARIDWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247671,
      "TXT_PINCODE_LOCALITY": "RAISE",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6311,
      "City District Name": "HARIDWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247672,
      "TXT_PINCODE_LOCALITY": "ROORKEE UNIVERSITY",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6311,
      "City District Name": "HARIDWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247673,
      "TXT_PINCODE_LOCALITY": "MELAINAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 352,
      "City District Name": "SAHARANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247677,
      "TXT_PINCODE_LOCALITY": "SOHALPUR SIKRODHA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443082,
      "City District Name": "HARDWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247688,
      "TXT_PINCODE_LOCALITY": "SALHAPUR",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443109,
      "City District Name": "ROORKEE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247694,
      "TXT_PINCODE_LOCALITY": "RANIPUR RANGE",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443082,
      "City District Name": "HARDWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247715,
      "TXT_PINCODE_LOCALITY": "MADANPUR KURMI.",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443107,
      "City District Name": "RAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247745,
      "TXT_PINCODE_LOCALITY": "NANHERA KALAN MU.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5316,
      "City District Name": "RAMPUR MANIHARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247769,
      "TXT_PINCODE_LOCALITY": "GAGALHERI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 352,
      "City District Name": "SAHARANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247771,
      "TXT_PINCODE_LOCALITY": "AILUM",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4635,
      "City District Name": "MUZAFFARNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247772,
      "TXT_PINCODE_LOCALITY": "BALWA KHERI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5611,
      "City District Name": "SHAMLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247773,
      "TXT_PINCODE_LOCALITY": "JHINJHANA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4635,
      "City District Name": "MUZAFFARNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247774,
      "TXT_PINCODE_LOCALITY": "KAIRANA B. O.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5611,
      "City District Name": "SHAMLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247775,
      "TXT_PINCODE_LOCALITY": "DUNDU KHERA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5611,
      "City District Name": "SHAMLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247776,
      "TXT_PINCODE_LOCALITY": "SHAMLI ROAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4635,
      "City District Name": "MUZAFFARNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247777,
      "TXT_PINCODE_LOCALITY": "THANABHAWAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5611,
      "City District Name": "SHAMLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 247778,
      "TXT_PINCODE_LOCALITY": "UN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4635,
      "City District Name": "MUZAFFARNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248001,
      "TXT_PINCODE_LOCALITY": "MOHABBEWALA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 441220,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248002,
      "TXT_PINCODE_LOCALITY": "MOHABEWALA INDUSTRIAL AREA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248003,
      "TXT_PINCODE_LOCALITY": "DEHRADUN CANTT HO.",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248004,
      "TXT_PINCODE_LOCALITY": "IMA DEHRADUN",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248005,
      "TXT_PINCODE_LOCALITY": "NEHRUGRAM",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248006,
      "TXT_PINCODE_LOCALITY": "NIRANJANPUR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248007,
      "TXT_PINCODE_LOCALITY": "PREMNAGAR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248008,
      "TXT_PINCODE_LOCALITY": "RAIPUR O F",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248009,
      "TXT_PINCODE_LOCALITY": "RAJPUR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248010,
      "TXT_PINCODE_LOCALITY": "BIRPUR - 5032",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248011,
      "TXT_PINCODE_LOCALITY": "SELAQUI S.O",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248012,
      "TXT_PINCODE_LOCALITY": "DEFENCECOLONY  S.O",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248013,
      "TXT_PINCODE_LOCALITY": "GUJRARA MANSINGH",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 441220,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248014,
      "TXT_PINCODE_LOCALITY": "NEHRUGRAM",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 441220,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248015,
      "TXT_PINCODE_LOCALITY": "VIJEPUR GOPIWALA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 441220,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248016,
      "TXT_PINCODE_LOCALITY": "DEHRADUN",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 441220,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248018,
      "TXT_PINCODE_LOCALITY": "DEHRADUN",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 441220,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248027,
      "TXT_PINCODE_LOCALITY": "DONKWALA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443118,
      "City District Name": "VIKASNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248102,
      "TXT_PINCODE_LOCALITY": "SAHASTRADHRA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248110,
      "TXT_PINCODE_LOCALITY": "MAHEBAWALLA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248115,
      "TXT_PINCODE_LOCALITY": "DEHRADUN",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 441220,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248116,
      "TXT_PINCODE_LOCALITY": "DEHRADUN",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 441220,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248119,
      "TXT_PINCODE_LOCALITY": "DEHRADUN",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 441220,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248121,
      "TXT_PINCODE_LOCALITY": "AJABPUR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248122,
      "TXT_PINCODE_LOCALITY": "BARLOWGANJ",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248123,
      "TXT_PINCODE_LOCALITY": "CHAKRATA KAILANA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248124,
      "TXT_PINCODE_LOCALITY": "BARAUTHA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248125,
      "TXT_PINCODE_LOCALITY": "DAKPATHAR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248126,
      "TXT_PINCODE_LOCALITY": "KHAIRI KHADAR",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 441220,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248131,
      "TXT_PINCODE_LOCALITY": "NAUGAON KAPHOL",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443102,
      "City District Name": "PAURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248140,
      "TXT_PINCODE_LOCALITY": "DOIWALA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248141,
      "TXT_PINCODE_LOCALITY": "GHANGHORA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248142,
      "TXT_PINCODE_LOCALITY": "HERBERTPUR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248143,
      "TXT_PINCODE_LOCALITY": "BHOGPUR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248144,
      "TXT_PINCODE_LOCALITY": "BHANIAWALA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248145,
      "TXT_PINCODE_LOCALITY": "RANIPHOOL DEVI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248146,
      "TXT_PINCODE_LOCALITY": "SIMADWAR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248147,
      "TXT_PINCODE_LOCALITY": "DHAKRANI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248148,
      "TXT_PINCODE_LOCALITY": "KUTHAL",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248152,
      "TXT_PINCODE_LOCALITY": "DEHRADUN",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 441220,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248158,
      "TXT_PINCODE_LOCALITY": "KALSI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248159,
      "TXT_PINCODE_LOCALITY": "KALSHI GATE",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248160,
      "TXT_PINCODE_LOCALITY": "HARRAWALLA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248161,
      "TXT_PINCODE_LOCALITY": "BALAWELA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248164,
      "TXT_PINCODE_LOCALITY": "CHIBROO",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248165,
      "TXT_PINCODE_LOCALITY": "KOTI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248167,
      "TXT_PINCODE_LOCALITY": "DHAKRANI(DEHAT).",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443118,
      "City District Name": "VIKASNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248171,
      "TXT_PINCODE_LOCALITY": "MAJRA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248179,
      "TXT_PINCODE_LOCALITY": "MUSSOORIE B.O",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 441220,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248180,
      "TXT_PINCODE_LOCALITY": "THATYUR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248191,
      "TXT_PINCODE_LOCALITY": "DEHRADUN S. O",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248195,
      "TXT_PINCODE_LOCALITY": "I.P.E. DEHRADUN",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248196,
      "TXT_PINCODE_LOCALITY": "SAHIYA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248197,
      "TXT_PINCODE_LOCALITY": "SAHASPUR (D DUN)",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248198,
      "TXT_PINCODE_LOCALITY": "VIKAS NAGAR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248199,
      "TXT_PINCODE_LOCALITY": "TUINI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248201,
      "TXT_PINCODE_LOCALITY": "KOLAGHAR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248202,
      "TXT_PINCODE_LOCALITY": "DEHRADUN",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 441220,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248203,
      "TXT_PINCODE_LOCALITY": "PASHULOK",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248204,
      "TXT_PINCODE_LOCALITY": "DEHRADUN",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 441220,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248252,
      "TXT_PINCODE_LOCALITY": "KYARI",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443088,
      "City District Name": "KALSI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248458,
      "TXT_PINCODE_LOCALITY": "KOPHATI",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443088,
      "City District Name": "KALSI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248487,
      "TXT_PINCODE_LOCALITY": "KIRASAL",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443077,
      "City District Name": "GAIRSAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248528,
      "TXT_PINCODE_LOCALITY": "KALSI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248870,
      "TXT_PINCODE_LOCALITY": "NIWA DANDI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2587,
      "City District Name": "BISALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 248920,
      "TXT_PINCODE_LOCALITY": "BARHEPURA GHARAM",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2587,
      "City District Name": "BISALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249001,
      "TXT_PINCODE_LOCALITY": "TEHRI H O",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424412,
      "City District Name": "TEHRI GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249101,
      "TXT_PINCODE_LOCALITY": "SITA KOTI",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443112,
      "City District Name": "SRINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249105,
      "TXT_PINCODE_LOCALITY": "THAPALIYAL GAON",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443100,
      "City District Name": "NARENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249106,
      "TXT_PINCODE_LOCALITY": "JAMTEE",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443084,
      "City District Name": "JAKHNIDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249110,
      "TXT_PINCODE_LOCALITY": "TYUNA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443068,
      "City District Name": "DEVPRAYAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249121,
      "TXT_PINCODE_LOCALITY": "ANJANI SAIN",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424412,
      "City District Name": "TEHRI GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249122,
      "TXT_PINCODE_LOCALITY": "HINDOLAKHAL",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424412,
      "City District Name": "TEHRI GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249123,
      "TXT_PINCODE_LOCALITY": "JAKHAMINDHAR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424412,
      "City District Name": "TEHRI GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249124,
      "TXT_PINCODE_LOCALITY": "BHALLEGAON",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249125,
      "TXT_PINCODE_LOCALITY": "TOLI",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443080,
      "City District Name": "GHANSALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249126,
      "TXT_PINCODE_LOCALITY": "BHALLEGAON",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249127,
      "TXT_PINCODE_LOCALITY": "TEHRI GARHWAL",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 441254,
      "City District Name": "TEHRI GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249128,
      "TXT_PINCODE_LOCALITY": "SUPIN RANGE",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443099,
      "City District Name": "MORI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249130,
      "TXT_PINCODE_LOCALITY": "TEHRI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249131,
      "TXT_PINCODE_LOCALITY": "BHALDIANA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424412,
      "City District Name": "TEHRI GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249132,
      "TXT_PINCODE_LOCALITY": "CHHAWAN",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424412,
      "City District Name": "TEHRI GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249134,
      "TXT_PINCODE_LOCALITY": "DHARASU",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6103,
      "City District Name": "UTTARKASHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249135,
      "TXT_PINCODE_LOCALITY": "HARSIL",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424412,
      "City District Name": "TEHRI GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249136,
      "TXT_PINCODE_LOCALITY": "UTTARKASHI",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 444129,
      "City District Name": "UTTARKASHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249137,
      "TXT_PINCODE_LOCALITY": "MUNI-KI-RETI - 1242",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424412,
      "City District Name": "TEHRI GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249141,
      "TXT_PINCODE_LOCALITY": "BURKOT",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424412,
      "City District Name": "TEHRI GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249142,
      "TXT_PINCODE_LOCALITY": "FAKOTE",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249145,
      "TXT_PINCODE_LOCALITY": "CHAMOW",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424412,
      "City District Name": "TEHRI GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249146,
      "TXT_PINCODE_LOCALITY": "CHAKA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424412,
      "City District Name": "TEHRI GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249147,
      "TXT_PINCODE_LOCALITY": "KUMALI",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443100,
      "City District Name": "NARENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249149,
      "TXT_PINCODE_LOCALITY": "LANSDOWNE",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 442040,
      "City District Name": "PAURI GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249151,
      "TXT_PINCODE_LOCALITY": "DUNDA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6103,
      "City District Name": "UTTARKASHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249152,
      "TXT_PINCODE_LOCALITY": "GEONLA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6103,
      "City District Name": "UTTARKASHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249154,
      "TXT_PINCODE_LOCALITY": "RIYUNTI",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443114,
      "City District Name": "TEHRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249155,
      "TXT_PINCODE_LOCALITY": "GHANSYALI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424412,
      "City District Name": "TEHRI GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249156,
      "TXT_PINCODE_LOCALITY": "UTTARKASHI",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 444129,
      "City District Name": "UTTARKASHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249161,
      "TXT_PINCODE_LOCALITY": "DUGADDA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249162,
      "TXT_PINCODE_LOCALITY": "THARTI",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443080,
      "City District Name": "GHANSALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249165,
      "TXT_PINCODE_LOCALITY": "LAMBGAON",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249168,
      "TXT_PINCODE_LOCALITY": "NAKOT MAY BHADRISERA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443069,
      "City District Name": "DHANAULTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249171,
      "TXT_PINCODE_LOCALITY": "NAUGAON",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6103,
      "City District Name": "UTTARKASHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249175,
      "TXT_PINCODE_LOCALITY": "NARENDRA NAGAR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424412,
      "City District Name": "TEHRI GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249179,
      "TXT_PINCODE_LOCALITY": "PAPARA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443069,
      "City District Name": "DHANAULTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249180,
      "TXT_PINCODE_LOCALITY": "TONETA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443069,
      "City District Name": "DHANAULTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249181,
      "TXT_PINCODE_LOCALITY": "PILKHI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424412,
      "City District Name": "TEHRI GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249185,
      "TXT_PINCODE_LOCALITY": "PUROLA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6103,
      "City District Name": "UTTARKASHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249186,
      "TXT_PINCODE_LOCALITY": "MOGI",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 441254,
      "City District Name": "TEHRI GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249192,
      "TXT_PINCODE_LOCALITY": "SHIVANANDNAGAR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249193,
      "TXT_PINCODE_LOCALITY": "UTTARKASHI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6103,
      "City District Name": "UTTARKASHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249194,
      "TXT_PINCODE_LOCALITY": "MANAURI DAM COLONY",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6103,
      "City District Name": "UTTARKASHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249195,
      "TXT_PINCODE_LOCALITY": "MAHIDANDA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6103,
      "City District Name": "UTTARKASHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249196,
      "TXT_PINCODE_LOCALITY": "CHIMYALI SAUR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6103,
      "City District Name": "UTTARKASHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249197,
      "TXT_PINCODE_LOCALITY": "INDRIPUR.",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443118,
      "City District Name": "VIKASNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249198,
      "TXT_PINCODE_LOCALITY": "SIRAIN (TEHRI)",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424412,
      "City District Name": "TEHRI GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249199,
      "TXT_PINCODE_LOCALITY": "CHAURI TEHRI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249201,
      "TXT_PINCODE_LOCALITY": "CITY",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 5379,
      "City District Name": "RISHIKESH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249202,
      "TXT_PINCODE_LOCALITY": "VIRBHADRA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249203,
      "TXT_PINCODE_LOCALITY": "PASHULOK",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249204,
      "TXT_PINCODE_LOCALITY": "VIRBHADRA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 5379,
      "City District Name": "RISHIKESH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249205,
      "TXT_PINCODE_LOCALITY": "RAIWALA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1860,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249265,
      "TXT_PINCODE_LOCALITY": "PUJAR GAON",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443106,
      "City District Name": "PRATAPNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249300,
      "TXT_PINCODE_LOCALITY": "UMRASU",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443102,
      "City District Name": "PAURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249301,
      "TXT_PINCODE_LOCALITY": "DEOPRAYAG",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424412,
      "City District Name": "TEHRI GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249302,
      "TXT_PINCODE_LOCALITY": "LAKSHMANJHULA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424417,
      "City District Name": "GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249304,
      "TXT_PINCODE_LOCALITY": "DIULI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6103,
      "City District Name": "UTTARKASHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249306,
      "TXT_PINCODE_LOCALITY": "GANGA BHOGPUR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 441030,
      "City District Name": "PAURI GARHWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249401,
      "TXT_PINCODE_LOCALITY": "HARIDWAR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6311,
      "City District Name": "HARIDWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249402,
      "TXT_PINCODE_LOCALITY": "BAHADURABAD.",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443082,
      "City District Name": "HARDWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249403,
      "TXT_PINCODE_LOCALITY": "BHEL S.O (HARIDWAR)",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 441977,
      "City District Name": "HARIDWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249404,
      "TXT_PINCODE_LOCALITY": "GURUKULKANGRI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6311,
      "City District Name": "HARIDWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249405,
      "TXT_PINCODE_LOCALITY": "MAHAVIDYALAYA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6311,
      "City District Name": "HARIDWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249407,
      "TXT_PINCODE_LOCALITY": "ROORKEE ROAD",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6311,
      "City District Name": "HARIDWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249408,
      "TXT_PINCODE_LOCALITY": "KANKHAL S.O",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 441977,
      "City District Name": "HARIDWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249409,
      "TXT_PINCODE_LOCALITY": "RISHIKESH VIDYAPITH",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6311,
      "City District Name": "HARIDWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249410,
      "TXT_PINCODE_LOCALITY": "BHOPATWALA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6311,
      "City District Name": "HARIDWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249411,
      "TXT_PINCODE_LOCALITY": "SHANTIKUNJ S.O",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6311,
      "City District Name": "HARIDWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249575,
      "TXT_PINCODE_LOCALITY": "SUNNA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2630,
      "City District Name": "BUDHANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249603,
      "TXT_PINCODE_LOCALITY": "SECTOR-12 SIDCUL",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6311,
      "City District Name": "HARIDWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249666,
      "TXT_PINCODE_LOCALITY": "DHANDERA - 3100",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6311,
      "City District Name": "HARIDWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 249901,
      "TXT_PINCODE_LOCALITY": "MUCHYALI",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443112,
      "City District Name": "SRINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250000,
      "TXT_PINCODE_LOCALITY": "MEERUT H O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250001,
      "TXT_PINCODE_LOCALITY": "PATELPURI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250002,
      "TXT_PINCODE_LOCALITY": "MEERUT CITY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250003,
      "TXT_PINCODE_LOCALITY": "TRANSPORT NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250004,
      "TXT_PINCODE_LOCALITY": "MEERUT 4",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250005,
      "TXT_PINCODE_LOCALITY": "MEERUT UNIVERSITY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250007,
      "TXT_PINCODE_LOCALITY": "SHASTRI NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250010,
      "TXT_PINCODE_LOCALITY": "PALLAVPURAM",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250013,
      "TXT_PINCODE_LOCALITY": "INDUSTRIAL EST PRATAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250022,
      "TXT_PINCODE_LOCALITY": "SHAHJAHANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2344,
      "City District Name": "BARAUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250101,
      "TXT_PINCODE_LOCALITY": "BAGPAT S. O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 441047,
      "City District Name": "BAGPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250102,
      "TXT_PINCODE_LOCALITY": "MEDICAL COLL MEERUT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250103,
      "TXT_PINCODE_LOCALITY": "RITHANI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250104,
      "TXT_PINCODE_LOCALITY": "KITHORE",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250105,
      "TXT_PINCODE_LOCALITY": "KRISHNANAGAR (MEERUT)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250106,
      "TXT_PINCODE_LOCALITY": "MACHHRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250110,
      "TXT_PINCODE_LOCALITY": "MODIPURAM",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250123,
      "TXT_PINCODE_LOCALITY": "SHAHPUR PIRPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5523,
      "City District Name": "SARDHANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250205,
      "TXT_PINCODE_LOCALITY": "MOHIUDDINPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250206,
      "TXT_PINCODE_LOCALITY": "CHANDSARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250221,
      "TXT_PINCODE_LOCALITY": "DAURALA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250222,
      "TXT_PINCODE_LOCALITY": "LAWAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250223,
      "TXT_PINCODE_LOCALITY": "SAKHAOTI TANDA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250229,
      "TXT_PINCODE_LOCALITY": "SAHIPUR DAURALA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5523,
      "City District Name": "SARDHANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250234,
      "TXT_PINCODE_LOCALITY": "DHANWALI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5523,
      "City District Name": "SARDHANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250251,
      "TXT_PINCODE_LOCALITY": "SIWAL KHAS",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250334,
      "TXT_PINCODE_LOCALITY": "DABATHUWA GARHI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5523,
      "City District Name": "SARDHANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250341,
      "TXT_PINCODE_LOCALITY": "DABATHWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250342,
      "TXT_PINCODE_LOCALITY": "SARDHANA CHOWK",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250343,
      "TXT_PINCODE_LOCALITY": "CHIRORI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5523,
      "City District Name": "SARDHANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250344,
      "TXT_PINCODE_LOCALITY": "SARURPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250345,
      "TXT_PINCODE_LOCALITY": "BINAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250401,
      "TXT_PINCODE_LOCALITY": "MAWANA MANDI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250402,
      "TXT_PINCODE_LOCALITY": "MAWANA FACTORY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250403,
      "TXT_PINCODE_LOCALITY": "KASERU KHERA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250404,
      "TXT_PINCODE_LOCALITY": "HASTINAPUR - 1746",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250406,
      "TXT_PINCODE_LOCALITY": "PARIKSHITIGARH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250501,
      "TXT_PINCODE_LOCALITY": "JANI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250502,
      "TXT_PINCODE_LOCALITY": "RASULPUR (ROHTA)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250515,
      "TXT_PINCODE_LOCALITY": "PILANA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 441047,
      "City District Name": "BAGPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250601,
      "TXT_PINCODE_LOCALITY": "MANDI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250602,
      "TXT_PINCODE_LOCALITY": "BAMNAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250606,
      "TXT_PINCODE_LOCALITY": "AMINAGAR (SARAI)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250609,
      "TXT_PINCODE_LOCALITY": "BAGPAT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 441047,
      "City District Name": "BAGPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250611,
      "TXT_PINCODE_LOCALITY": "BARAUT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250612,
      "TXT_PINCODE_LOCALITY": "MEERUT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250615,
      "TXT_PINCODE_LOCALITY": "DHIKAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250617,
      "TXT_PINCODE_LOCALITY": "CHHAPRAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 853,
      "City District Name": "BAGHPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250619,
      "TXT_PINCODE_LOCALITY": "SARURPUR KALAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 853,
      "City District Name": "BAGHPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250620,
      "TXT_PINCODE_LOCALITY": "BAMNAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 441047,
      "City District Name": "BAGPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250621,
      "TXT_PINCODE_LOCALITY": "BAOLI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 853,
      "City District Name": "BAGHPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250622,
      "TXT_PINCODE_LOCALITY": "DO GHAT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 853,
      "City District Name": "BAGHPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250623,
      "TXT_PINCODE_LOCALITY": "KISHANPURBARAL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250625,
      "TXT_PINCODE_LOCALITY": "TIKARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250626,
      "TXT_PINCODE_LOCALITY": "BALENI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 853,
      "City District Name": "BAGHPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250645,
      "TXT_PINCODE_LOCALITY": "RAHATNA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2344,
      "City District Name": "BARAUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 250696,
      "TXT_PINCODE_LOCALITY": "MOZZAMPUR URF LADPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 853,
      "City District Name": "BAGHPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 251000,
      "TXT_PINCODE_LOCALITY": "MUZAFFARNAGAR H O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4635,
      "City District Name": "MUZAFFARNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 251001,
      "TXT_PINCODE_LOCALITY": "PREMPURI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4635,
      "City District Name": "MUZAFFARNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 251002,
      "TXT_PINCODE_LOCALITY": "MUZAFFARNAGAR CITY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4635,
      "City District Name": "MUZAFFARNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 251003,
      "TXT_PINCODE_LOCALITY": "INDUSTRIAL ESTATE MUZAFFARNAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4635,
      "City District Name": "MUZAFFARNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 251005,
      "TXT_PINCODE_LOCALITY": "MUZAFFARNAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4635,
      "City District Name": "MUZAFFARNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 251201,
      "TXT_PINCODE_LOCALITY": "KHATAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4635,
      "City District Name": "MUZAFFARNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 251202,
      "TXT_PINCODE_LOCALITY": "ROHANA MILLS",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4635,
      "City District Name": "MUZAFFARNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 251203,
      "TXT_PINCODE_LOCALITY": "MANSURPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4635,
      "City District Name": "MUZAFFARNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 251210,
      "TXT_PINCODE_LOCALITY": "BASERA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4635,
      "City District Name": "MUZAFFARNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 251216,
      "TXT_PINCODE_LOCALITY": "MUZAFFARNAGAR S. O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4635,
      "City District Name": "MUZAFFARNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 251301,
      "TXT_PINCODE_LOCALITY": "ALIPUR KHERI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4635,
      "City District Name": "MUZAFFARNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 251305,
      "TXT_PINCODE_LOCALITY": "BANTI KHERA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5611,
      "City District Name": "SHAMLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 251306,
      "TXT_PINCODE_LOCALITY": "BAGHERA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4635,
      "City District Name": "MUZAFFARNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 251307,
      "TXT_PINCODE_LOCALITY": "BARLA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4635,
      "City District Name": "MUZAFFARNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 251308,
      "TXT_PINCODE_LOCALITY": "BHOPA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4635,
      "City District Name": "MUZAFFARNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 251309,
      "TXT_PINCODE_LOCALITY": "BUDHANA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4635,
      "City District Name": "MUZAFFARNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 251310,
      "TXT_PINCODE_LOCALITY": "BASARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4635,
      "City District Name": "MUZAFFARNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 251311,
      "TXT_PINCODE_LOCALITY": "CHARTHAWAL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4635,
      "City District Name": "MUZAFFARNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 251314,
      "TXT_PINCODE_LOCALITY": "JANSATH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4635,
      "City District Name": "MUZAFFARNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 251315,
      "TXT_PINCODE_LOCALITY": "MIRANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4635,
      "City District Name": "MUZAFFARNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 251316,
      "TXT_PINCODE_LOCALITY": "MORNA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4635,
      "City District Name": "MUZAFFARNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 251317,
      "TXT_PINCODE_LOCALITY": "PURKAZI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4635,
      "City District Name": "MUZAFFARNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 251318,
      "TXT_PINCODE_LOCALITY": "SHAHPUR (M NAGAR)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4635,
      "City District Name": "MUZAFFARNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 251319,
      "TXT_PINCODE_LOCALITY": "SISAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4635,
      "City District Name": "MUZAFFARNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 251320,
      "TXT_PINCODE_LOCALITY": "SHAHPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3546,
      "City District Name": "JANSATH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 251327,
      "TXT_PINCODE_LOCALITY": "HARI NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442143,
      "City District Name": "MUZAFFARNAGAR.",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 252201,
      "TXT_PINCODE_LOCALITY": "GANGAPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 6390,
      "City District Name": "GANGAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 253501,
      "TXT_PINCODE_LOCALITY": "MEERGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 254716,
      "TXT_PINCODE_LOCALITY": "ANANDNAGAR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424410,
      "City District Name": "UDHAMSINGH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 255006,
      "TXT_PINCODE_LOCALITY": "MEERUT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 255101,
      "TXT_PINCODE_LOCALITY": "CHAMARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1861,
      "City District Name": "GHAZIABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 258321,
      "TXT_PINCODE_LOCALITY": "UDHNA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6409,
      "City District Name": "MANALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 260001,
      "TXT_PINCODE_LOCALITY": "GADDIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1388,
      "City District Name": "SITAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 261001,
      "TXT_PINCODE_LOCALITY": "SITAPUR CITY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1388,
      "City District Name": "SITAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 261026,
      "TXT_PINCODE_LOCALITY": "RAJYOURA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443103,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 261100,
      "TXT_PINCODE_LOCALITY": "KUNVARPUR GUDDI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443016,
      "City District Name": "MISRIKH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 261108,
      "TXT_PINCODE_LOCALITY": "TARPARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4202,
      "City District Name": "LAHARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 261113,
      "TXT_PINCODE_LOCALITY": "PERSEHRA KHURD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1388,
      "City District Name": "SITAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 261121,
      "TXT_PINCODE_LOCALITY": "HARGAON S M",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1388,
      "City District Name": "SITAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 261122,
      "TXT_PINCODE_LOCALITY": "MAJHARA T. GHUNCHAI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5213,
      "City District Name": "PURANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 261125,
      "TXT_PINCODE_LOCALITY": "HUSAINGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1388,
      "City District Name": "SITAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 261131,
      "TXT_PINCODE_LOCALITY": "KHAIRABAD S.O (SITAPUR)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1388,
      "City District Name": "SITAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 261135,
      "TXT_PINCODE_LOCALITY": "LAHARPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1388,
      "City District Name": "SITAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 261136,
      "TXT_PINCODE_LOCALITY": "SINGHNAPAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 939,
      "City District Name": "FIROZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 261141,
      "TXT_PINCODE_LOCALITY": "MAHOLI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1388,
      "City District Name": "SITAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 261145,
      "TXT_PINCODE_LOCALITY": "MANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1388,
      "City District Name": "SITAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 261151,
      "TXT_PINCODE_LOCALITY": "PISAWAN (SITAPUR)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1388,
      "City District Name": "SITAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 261201,
      "TXT_PINCODE_LOCALITY": "BISWAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1388,
      "City District Name": "SITAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 261202,
      "TXT_PINCODE_LOCALITY": "JAHANGIRABAD (SITAPUR)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1388,
      "City District Name": "SITAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 261203,
      "TXT_PINCODE_LOCALITY": "MAHAMUDABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1388,
      "City District Name": "SITAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 261204,
      "TXT_PINCODE_LOCALITY": "SITAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1388,
      "City District Name": "SITAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 261205,
      "TXT_PINCODE_LOCALITY": "SARAI AGHAT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6565,
      "City District Name": "FARUKHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 261206,
      "TXT_PINCODE_LOCALITY": "SULTANAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1388,
      "City District Name": "SITAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 261207,
      "TXT_PINCODE_LOCALITY": "TALGAON",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1388,
      "City District Name": "SITAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 261208,
      "TXT_PINCODE_LOCALITY": "TAMBAUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1388,
      "City District Name": "SITAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 261209,
      "TXT_PINCODE_LOCALITY": "NARAYANPURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2596,
      "City District Name": "BISWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 261220,
      "TXT_PINCODE_LOCALITY": "CHANDPUR SETH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4319,
      "City District Name": "MAHMUDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 261221,
      "TXT_PINCODE_LOCALITY": "PIPRI CHAND",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4319,
      "City District Name": "MAHMUDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 261301,
      "TXT_PINCODE_LOCALITY": "BHANDIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1388,
      "City District Name": "SITAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 261302,
      "TXT_PINCODE_LOCALITY": "DAMALPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1388,
      "City District Name": "SITAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 261303,
      "TXT_PINCODE_LOCALITY": "HUSAINGANJ (SITAPUR)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1388,
      "City District Name": "SITAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 261305,
      "TXT_PINCODE_LOCALITY": "SHEIKHWA PUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4319,
      "City District Name": "MAHMUDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 261320,
      "TXT_PINCODE_LOCALITY": "KHAM HARIYAN NANKARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4319,
      "City District Name": "MAHMUDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 261401,
      "TXT_PINCODE_LOCALITY": "MISRIKH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1388,
      "City District Name": "SITAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 261402,
      "TXT_PINCODE_LOCALITY": "NIMSAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1388,
      "City District Name": "SITAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 261403,
      "TXT_PINCODE_LOCALITY": "AURANGABAD (SITAPUR)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1388,
      "City District Name": "SITAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 261404,
      "TXT_PINCODE_LOCALITY": "PARSEHRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1388,
      "City District Name": "SITAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 261405,
      "TXT_PINCODE_LOCALITY": "MACHREHTA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1388,
      "City District Name": "SITAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 261501,
      "TXT_PINCODE_LOCALITY": "BEHJAM",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3966,
      "City District Name": "KHERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 261502,
      "TXT_PINCODE_LOCALITY": "ISANGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3966,
      "City District Name": "KHERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 261505,
      "TXT_PINCODE_LOCALITY": "MAIGALGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3966,
      "City District Name": "KHERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 261506,
      "TXT_PINCODE_LOCALITY": "MAHEWGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3966,
      "City District Name": "KHERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 261508,
      "TXT_PINCODE_LOCALITY": "LAKHIMPUR KHERI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 874,
      "City District Name": "LAKHIMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 261509,
      "TXT_PINCODE_LOCALITY": "TENDUVA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 874,
      "City District Name": "LAKHIMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 261606,
      "TXT_PINCODE_LOCALITY": "SARALYAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1388,
      "City District Name": "SITAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262000,
      "TXT_PINCODE_LOCALITY": "PILIBHIT H O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 363,
      "City District Name": "PILIBHIT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262001,
      "TXT_PINCODE_LOCALITY": "KUTCHERY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 363,
      "City District Name": "PILIBHIT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262020,
      "TXT_PINCODE_LOCALITY": "BAGAR TOLI",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443103,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262026,
      "TXT_PINCODE_LOCALITY": "JAKH PANT",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443103,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262101,
      "TXT_PINCODE_LOCALITY": "SON,PIPER",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443001,
      "City District Name": "GOLA GOKARAN NATH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262112,
      "TXT_PINCODE_LOCALITY": "SHAHBAJPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5213,
      "City District Name": "PURANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262121,
      "TXT_PINCODE_LOCALITY": "AMARIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 363,
      "City District Name": "PILIBHIT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262122,
      "TXT_PINCODE_LOCALITY": "PURANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 363,
      "City District Name": "PILIBHIT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262123,
      "TXT_PINCODE_LOCALITY": "PILIBHIT CITY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 363,
      "City District Name": "PILIBHIT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262124,
      "TXT_PINCODE_LOCALITY": "KALINAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 363,
      "City District Name": "PILIBHIT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262125,
      "TXT_PINCODE_LOCALITY": "SULTANPUR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424410,
      "City District Name": "UDHAMSINGH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262134,
      "TXT_PINCODE_LOCALITY": "SONLI",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443061,
      "City District Name": "BETALGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262139,
      "TXT_PINCODE_LOCALITY": "RAMPUR LAMACHAUR",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443081,
      "City District Name": "HALDWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262177,
      "TXT_PINCODE_LOCALITY": "PANDRIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5213,
      "City District Name": "PURANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262201,
      "TXT_PINCODE_LOCALITY": "KATRA BAZAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 363,
      "City District Name": "PILIBHIT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262202,
      "TXT_PINCODE_LOCALITY": "BILSANDA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 363,
      "City District Name": "PILIBHIT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262203,
      "TXT_PINCODE_LOCALITY": "BARKHERA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 363,
      "City District Name": "PILIBHIT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262301,
      "TXT_PINCODE_LOCALITY": "LOHIA HEAD",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424410,
      "City District Name": "UDHAMSINGH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262302,
      "TXT_PINCODE_LOCALITY": "MAJHOLA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3966,
      "City District Name": "KHERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262305,
      "TXT_PINCODE_LOCALITY": "NEORILA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 363,
      "City District Name": "PILIBHIT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262308,
      "TXT_PINCODE_LOCALITY": "LOHIA HEAD",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6347,
      "City District Name": "UDHAM SINGH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262309,
      "TXT_PINCODE_LOCALITY": "TANAKPUR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424410,
      "City District Name": "UDHAMSINGH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262310,
      "TXT_PINCODE_LOCALITY": "CHAMPAWAT S. O",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 2677,
      "City District Name": "CHAMPAWAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262311,
      "TXT_PINCODE_LOCALITY": "NANAKMATTA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424410,
      "City District Name": "UDHAMSINGH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262312,
      "TXT_PINCODE_LOCALITY": "KUTARI",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443092,
      "City District Name": "KHATIMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262401,
      "TXT_PINCODE_LOCALITY": "UDHAM SINGH NAGAR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6347,
      "City District Name": "UDHAM SINGH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262402,
      "TXT_PINCODE_LOCALITY": "LALKUAN",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 4682,
      "City District Name": "NAINITAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262403,
      "TXT_PINCODE_LOCALITY": "S A I CAMP",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 4682,
      "City District Name": "NAINITAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262404,
      "TXT_PINCODE_LOCALITY": "LALKUAN S. O",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 3354,
      "City District Name": "HALDWANI-CUM-KATHGODAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262405,
      "TXT_PINCODE_LOCALITY": "SITARGANJ",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424410,
      "City District Name": "UDHAMSINGH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262406,
      "TXT_PINCODE_LOCALITY": "NAWABGANJ (BAREILLY)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262407,
      "TXT_PINCODE_LOCALITY": "SENTHAL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262408,
      "TXT_PINCODE_LOCALITY": "GULARBHOJ",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424410,
      "City District Name": "UDHAMSINGH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262411,
      "TXT_PINCODE_LOCALITY": "BAREILLY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262500,
      "TXT_PINCODE_LOCALITY": "UPERTOLA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443103,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262501,
      "TXT_PINCODE_LOCALITY": "PITHORAGARH HO.",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 5152,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262502,
      "TXT_PINCODE_LOCALITY": "DEGREE COLLEGE",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 5152,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262512,
      "TXT_PINCODE_LOCALITY": "KHETI JARAULI.",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443060,
      "City District Name": "BERINAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262519,
      "TXT_PINCODE_LOCALITY": "MUNAKOTE",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 5152,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262520,
      "TXT_PINCODE_LOCALITY": "BHARKATIYA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 5152,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262521,
      "TXT_PINCODE_LOCALITY": "WADDA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 5152,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262522,
      "TXT_PINCODE_LOCALITY": "GANGOLIHAT",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 5152,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262523,
      "TXT_PINCODE_LOCALITY": "CHAMPAWAR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 2677,
      "City District Name": "CHAMPAWAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262524,
      "TXT_PINCODE_LOCALITY": "KANKAVLI S.O",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443098,
      "City District Name": "LOHAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262525,
      "TXT_PINCODE_LOCALITY": "ABBOT MOUNT",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 5152,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262526,
      "TXT_PINCODE_LOCALITY": "JHUAGHAT",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 5152,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262527,
      "TXT_PINCODE_LOCALITY": "BARKOTE",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 2677,
      "City District Name": "CHAMPAWAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262528,
      "TXT_PINCODE_LOCALITY": "KHETI KHAN",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 5152,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262529,
      "TXT_PINCODE_LOCALITY": "GURNA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 5152,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262530,
      "TXT_PINCODE_LOCALITY": "ANCHOL",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 5152,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262531,
      "TXT_PINCODE_LOCALITY": "BERING",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 5152,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262532,
      "TXT_PINCODE_LOCALITY": "GANOLI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 5152,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262533,
      "TXT_PINCODE_LOCALITY": "PANKHU",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 5152,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262534,
      "TXT_PINCODE_LOCALITY": "CHAUNA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 5152,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262540,
      "TXT_PINCODE_LOCALITY": "SINKHOLI",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443103,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262541,
      "TXT_PINCODE_LOCALITY": "KANALICHINA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 5152,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262542,
      "TXT_PINCODE_LOCALITY": "DEWTHAL",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 5152,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262543,
      "TXT_PINCODE_LOCALITY": "ASKOTE",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 5152,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262544,
      "TXT_PINCODE_LOCALITY": "JAULIJIBI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 5152,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262545,
      "TXT_PINCODE_LOCALITY": "DHARCHULA PO",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443103,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262546,
      "TXT_PINCODE_LOCALITY": "GARBEYANG",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 5152,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262547,
      "TXT_PINCODE_LOCALITY": "PANGU",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 5152,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262549,
      "TXT_PINCODE_LOCALITY": "MALAWAN",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 5152,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262550,
      "TXT_PINCODE_LOCALITY": "MERTHI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 5152,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262551,
      "TXT_PINCODE_LOCALITY": "DIDIHAT",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 5152,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262552,
      "TXT_PINCODE_LOCALITY": "THAL",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 5152,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262553,
      "TXT_PINCODE_LOCALITY": "QUITEE",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 5152,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262554,
      "TXT_PINCODE_LOCALITY": "MUNSIARI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 5152,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262555,
      "TXT_PINCODE_LOCALITY": "BANSBAZAR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 5152,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262561,
      "TXT_PINCODE_LOCALITY": "NAKOTI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 5152,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262571,
      "TXT_PINCODE_LOCALITY": "PITHORAGARH",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443103,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262572,
      "TXT_PINCODE_LOCALITY": "MUWANI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 5152,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262573,
      "TXT_PINCODE_LOCALITY": "PITHORAGARH",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443103,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262574,
      "TXT_PINCODE_LOCALITY": "BARABEY",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 5152,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262575,
      "TXT_PINCODE_LOCALITY": "QUITI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 5152,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262576,
      "TXT_PINCODE_LOCALITY": "BALUWAKOTE",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 5152,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262580,
      "TXT_PINCODE_LOCALITY": "DEVIDHURA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 5152,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262582,
      "TXT_PINCODE_LOCALITY": "GADURA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443071,
      "City District Name": "DHARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262608,
      "TXT_PINCODE_LOCALITY": "BISOTA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443092,
      "City District Name": "KHATIMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262622,
      "TXT_PINCODE_LOCALITY": "DOTARI",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443078,
      "City District Name": "GANGOLIHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262626,
      "TXT_PINCODE_LOCALITY": "MALLA BINAULA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443083,
      "City District Name": "JAINTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262665,
      "TXT_PINCODE_LOCALITY": "MATAKHANI",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443113,
      "City District Name": "SULT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262680,
      "TXT_PINCODE_LOCALITY": "DEVIDHURA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 2677,
      "City District Name": "CHAMPAWAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262701,
      "TXT_PINCODE_LOCALITY": "LAKHIMPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 874,
      "City District Name": "LAKHIMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262702,
      "TXT_PINCODE_LOCALITY": "KHERI TOWN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3966,
      "City District Name": "KHERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262703,
      "TXT_PINCODE_LOCALITY": "BINJAHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442996,
      "City District Name": "DHAURAHARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262704,
      "TXT_PINCODE_LOCALITY": "LAKHIMPUR KHERI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 874,
      "City District Name": "LAKHIMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262706,
      "TXT_PINCODE_LOCALITY": "LAKHIMPUR KHERI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 874,
      "City District Name": "LAKHIMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262721,
      "TXT_PINCODE_LOCALITY": "LAGHUCHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3966,
      "City District Name": "KHERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262722,
      "TXT_PINCODE_LOCALITY": "AIRA ESTATE",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3966,
      "City District Name": "KHERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262723,
      "TXT_PINCODE_LOCALITY": "DHAUHARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3966,
      "City District Name": "KHERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262724,
      "TXT_PINCODE_LOCALITY": "JATOURWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3966,
      "City District Name": "KHERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262725,
      "TXT_PINCODE_LOCALITY": "OEL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3966,
      "City District Name": "KHERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262726,
      "TXT_PINCODE_LOCALITY": "SHARDANAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3966,
      "City District Name": "KHERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262727,
      "TXT_PINCODE_LOCALITY": "MITAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3966,
      "City District Name": "KHERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262728,
      "TXT_PINCODE_LOCALITY": "ATKHONA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3966,
      "City District Name": "KHERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262732,
      "TXT_PINCODE_LOCALITY": "MOHAMMADAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442996,
      "City District Name": "DHAURAHARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262800,
      "TXT_PINCODE_LOCALITY": "SHIV,PURI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443001,
      "City District Name": "GOLA GOKARAN NATH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262801,
      "TXT_PINCODE_LOCALITY": "BANKEYGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3966,
      "City District Name": "KHERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262802,
      "TXT_PINCODE_LOCALITY": "GOLAGOKARANNATH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3966,
      "City District Name": "KHERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262803,
      "TXT_PINCODE_LOCALITY": "MAILANI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3966,
      "City District Name": "KHERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262804,
      "TXT_PINCODE_LOCALITY": "VILLAGE KUMBHI P.O. PARGANA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 874,
      "City District Name": "LAKHIMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262805,
      "TXT_PINCODE_LOCALITY": "SIKANDRABAD (KHERI)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3966,
      "City District Name": "KHERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262807,
      "TXT_PINCODE_LOCALITY": "RACHHELA WAJIDPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443017,
      "City District Name": "MOHAMMDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262808,
      "TXT_PINCODE_LOCALITY": "BAIBHA.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443017,
      "City District Name": "MOHAMMDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262809,
      "TXT_PINCODE_LOCALITY": "KAIMHARA.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443001,
      "City District Name": "GOLA GOKARAN NATH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262836,
      "TXT_PINCODE_LOCALITY": "NAUGARH",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443108,
      "City District Name": "RANIKHET",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262901,
      "TXT_PINCODE_LOCALITY": "BHIRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3966,
      "City District Name": "KHERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262902,
      "TXT_PINCODE_LOCALITY": "PALLIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3966,
      "City District Name": "KHERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262903,
      "TXT_PINCODE_LOCALITY": "NIGHASIN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3966,
      "City District Name": "KHERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262904,
      "TXT_PINCODE_LOCALITY": "SAMPURNANAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3966,
      "City District Name": "KHERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262905,
      "TXT_PINCODE_LOCALITY": "SINGHAI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3966,
      "City District Name": "KHERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262906,
      "TXT_PINCODE_LOCALITY": "TIKONIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3966,
      "City District Name": "KHERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262907,
      "TXT_PINCODE_LOCALITY": "SEWARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3966,
      "City District Name": "KHERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 262908,
      "TXT_PINCODE_LOCALITY": "PALIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3966,
      "City District Name": "KHERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263001,
      "TXT_PINCODE_LOCALITY": "DEGREE COLLEGE",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 4682,
      "City District Name": "NAINITAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263002,
      "TXT_PINCODE_LOCALITY": "MALL ROAD",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 4682,
      "City District Name": "NAINITAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263126,
      "TXT_PINCODE_LOCALITY": "KATHIATALI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 4682,
      "City District Name": "NAINITAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263127,
      "TXT_PINCODE_LOCALITY": "JEOLIKOTE",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 4682,
      "City District Name": "NAINITAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263128,
      "TXT_PINCODE_LOCALITY": "PATWA BANGER",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 4682,
      "City District Name": "NAINITAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263129,
      "TXT_PINCODE_LOCALITY": "MANORA PEK",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 4682,
      "City District Name": "NAINITAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263131,
      "TXT_PINCODE_LOCALITY": "NAINITAL",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 441374,
      "City District Name": "NAINITAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263132,
      "TXT_PINCODE_LOCALITY": "BHOWALI SANATORIUM",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 4682,
      "City District Name": "NAINITAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263133,
      "TXT_PINCODE_LOCALITY": "SORE",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443071,
      "City District Name": "DHARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263134,
      "TXT_PINCODE_LOCALITY": "BETALGHAT",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 4682,
      "City District Name": "NAINITAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263135,
      "TXT_PINCODE_LOCALITY": "GARAMPANI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 4682,
      "City District Name": "NAINITAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263136,
      "TXT_PINCODE_LOCALITY": "SATTAL",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 4682,
      "City District Name": "NAINITAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263137,
      "TXT_PINCODE_LOCALITY": "RAMGARH (NAINITAL)",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 4682,
      "City District Name": "NAINITAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263138,
      "TXT_PINCODE_LOCALITY": "MUKTESWARAM",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 4682,
      "City District Name": "NAINITAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263139,
      "TXT_PINCODE_LOCALITY": "HALDWANI PO",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 441374,
      "City District Name": "NAINITAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263140,
      "TXT_PINCODE_LOCALITY": "KALADHUNGI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 4682,
      "City District Name": "NAINITAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263141,
      "TXT_PINCODE_LOCALITY": "NAINITAL  S. O",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 4682,
      "City District Name": "NAINITAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263142,
      "TXT_PINCODE_LOCALITY": "BHOTIA PARAO",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 4682,
      "City District Name": "NAINITAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263143,
      "TXT_PINCODE_LOCALITY": "RUDRAPUR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 4682,
      "City District Name": "NAINITAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263144,
      "TXT_PINCODE_LOCALITY": "TULARAMPUR",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443096,
      "City District Name": "LALKUAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263145,
      "TXT_PINCODE_LOCALITY": "PANT NAGAR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 6347,
      "City District Name": "UDHAM SINGH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263146,
      "TXT_PINCODE_LOCALITY": "HALDI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 4682,
      "City District Name": "NAINITAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263147,
      "TXT_PINCODE_LOCALITY": "GOKUL NAGAR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 4682,
      "City District Name": "NAINITAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263148,
      "TXT_PINCODE_LOCALITY": "KICHNA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424410,
      "City District Name": "UDHAMSINGH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263149,
      "TXT_PINCODE_LOCALITY": "D F NAGLA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 4682,
      "City District Name": "NAINITAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263150,
      "TXT_PINCODE_LOCALITY": "TANDA KHEM",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443059,
      "City District Name": "BAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263151,
      "TXT_PINCODE_LOCALITY": "SHAKTI FARM",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 4682,
      "City District Name": "NAINITAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263152,
      "TXT_PINCODE_LOCALITY": "GADARPUR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424410,
      "City District Name": "UDHAMSINGH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263153,
      "TXT_PINCODE_LOCALITY": "RUDARPUR S.O",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 442120,
      "City District Name": "UDHAM SINGH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263154,
      "TXT_PINCODE_LOCALITY": "R.C. RUDARPUR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 4682,
      "City District Name": "NAINITAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263155,
      "TXT_PINCODE_LOCALITY": "NAINITAL",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 441374,
      "City District Name": "NAINITAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263156,
      "TXT_PINCODE_LOCALITY": "GHORAKHAL",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 4682,
      "City District Name": "NAINITAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263157,
      "TXT_PINCODE_LOCALITY": "OKHUL KANDA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 4682,
      "City District Name": "NAINITAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263158,
      "TXT_PINCODE_LOCALITY": "N.S.ASHRAM",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 4682,
      "City District Name": "NAINITAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263159,
      "TXT_PINCODE_LOCALITY": "KOTABAGH",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 4682,
      "City District Name": "NAINITAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263160,
      "TXT_PINCODE_LOCALITY": "DINESHPUR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 4682,
      "City District Name": "NAINITAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263161,
      "TXT_PINCODE_LOCALITY": "FATEHPUR CHAURASI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 367,
      "City District Name": "UNNAO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263162,
      "TXT_PINCODE_LOCALITY": "MUSABANGAR.",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443087,
      "City District Name": "KALADHUNGI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263163,
      "TXT_PINCODE_LOCALITY": "BANDAR JURA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443087,
      "City District Name": "KALADHUNGI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263164,
      "TXT_PINCODE_LOCALITY": "PADAMPUR DAULIA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443087,
      "City District Name": "KALADHUNGI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263301,
      "TXT_PINCODE_LOCALITY": "NAINA RANGE",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 441374,
      "City District Name": "NAINITAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263309,
      "TXT_PINCODE_LOCALITY": "SHARDA RANGE",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443105,
      "City District Name": "POORNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263312,
      "TXT_PINCODE_LOCALITY": "CHAKARPUR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424410,
      "City District Name": "UDHAMSINGH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263364,
      "TXT_PINCODE_LOCALITY": "BALGARI DHURPATT.",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443089,
      "City District Name": "KANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263402,
      "TXT_PINCODE_LOCALITY": "GOLA RANGE",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443096,
      "City District Name": "LALKUAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263408,
      "TXT_PINCODE_LOCALITY": "SHYAMNAGAR",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443076,
      "City District Name": "GADARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263436,
      "TXT_PINCODE_LOCALITY": "PANG CHORA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 441832,
      "City District Name": "BAGESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263453,
      "TXT_PINCODE_LOCALITY": "GANGAPUR PATIA.",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443093,
      "City District Name": "KICHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263531,
      "TXT_PINCODE_LOCALITY": "NIGRAR",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 441374,
      "City District Name": "NAINITAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263541,
      "TXT_PINCODE_LOCALITY": "MATENA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443079,
      "City District Name": "GARUD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263601,
      "TXT_PINCODE_LOCALITY": "POKAHRKHALI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1992,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263604,
      "TXT_PINCODE_LOCALITY": "RIKHON",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443063,
      "City District Name": "BHIKIYASAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263610,
      "TXT_PINCODE_LOCALITY": "GHINGARTOLA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1992,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263619,
      "TXT_PINCODE_LOCALITY": "GURNA KAMSYAR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 2226,
      "City District Name": "BAGESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263620,
      "TXT_PINCODE_LOCALITY": "KAUSANI STATE",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 2226,
      "City District Name": "BAGESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263621,
      "TXT_PINCODE_LOCALITY": "BAGWALI POCKHER",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1992,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263622,
      "TXT_PINCODE_LOCALITY": "BHANOLI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1992,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263623,
      "TXT_PINCODE_LOCALITY": "PANUVANOULA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1992,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263624,
      "TXT_PINCODE_LOCALITY": "BARECHHINA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1992,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263625,
      "TXT_PINCODE_LOCALITY": "JALNA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1992,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263626,
      "TXT_PINCODE_LOCALITY": "JAINTI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 2226,
      "City District Name": "BAGESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263627,
      "TXT_PINCODE_LOCALITY": "DEVIDHAURA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1992,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263628,
      "TXT_PINCODE_LOCALITY": "CHANODA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1992,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263629,
      "TXT_PINCODE_LOCALITY": "CHANODA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1992,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263630,
      "TXT_PINCODE_LOCALITY": "KAUSANI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1992,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263631,
      "TXT_PINCODE_LOCALITY": "KANDA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 2226,
      "City District Name": "BAGESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263632,
      "TXT_PINCODE_LOCALITY": "BAGESHWAR",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 441832,
      "City District Name": "BAGESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263633,
      "TXT_PINCODE_LOCALITY": "SHAMA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 2226,
      "City District Name": "BAGESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263634,
      "TXT_PINCODE_LOCALITY": "DOFAR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 2226,
      "City District Name": "BAGESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263635,
      "TXT_PINCODE_LOCALITY": "GARUR",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 441832,
      "City District Name": "BAGESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263636,
      "TXT_PINCODE_LOCALITY": "HAWALBAGH",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1992,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263637,
      "TXT_PINCODE_LOCALITY": "SOMESHWAR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1992,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263638,
      "TXT_PINCODE_LOCALITY": "MANAN",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1992,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263639,
      "TXT_PINCODE_LOCALITY": "KAUSANI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 2226,
      "City District Name": "BAGESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263640,
      "TXT_PINCODE_LOCALITY": "KAMERI DEVI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1992,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263641,
      "TXT_PINCODE_LOCALITY": "KAPKOTE",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 2226,
      "City District Name": "BAGESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263642,
      "TXT_PINCODE_LOCALITY": "BAGESHWAR",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 441832,
      "City District Name": "BAGESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263643,
      "TXT_PINCODE_LOCALITY": "KOSI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1992,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263645,
      "TXT_PINCODE_LOCALITY": "UPRARI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1992,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263646,
      "TXT_PINCODE_LOCALITY": "BHATRAUJ KHAN",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1992,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263647,
      "TXT_PINCODE_LOCALITY": "ALMORA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 444192,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263648,
      "TXT_PINCODE_LOCALITY": "ALMORA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 444192,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263649,
      "TXT_PINCODE_LOCALITY": "RIKHUDYAR",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 441832,
      "City District Name": "BAGESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263651,
      "TXT_PINCODE_LOCALITY": "CHAUBATIA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1992,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263652,
      "TXT_PINCODE_LOCALITY": "MAJKHALI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1992,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263653,
      "TXT_PINCODE_LOCALITY": "ASGALI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1992,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263654,
      "TXT_PINCODE_LOCALITY": "ASGOLI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1992,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263655,
      "TXT_PINCODE_LOCALITY": "DAULAGHAT",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1992,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263656,
      "TXT_PINCODE_LOCALITY": "GANAI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1992,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263657,
      "TXT_PINCODE_LOCALITY": "PAYALGAON",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443063,
      "City District Name": "BHIKIYASAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263658,
      "TXT_PINCODE_LOCALITY": "MASI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1992,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263659,
      "TXT_PINCODE_LOCALITY": "DEGHAT",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1992,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263660,
      "TXT_PINCODE_LOCALITY": "DAULA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1992,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263661,
      "TXT_PINCODE_LOCALITY": "TAMADHAUN",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 2226,
      "City District Name": "BAGESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263662,
      "TXT_PINCODE_LOCALITY": "JALALI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1992,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263663,
      "TXT_PINCODE_LOCALITY": "TARIKHET",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 2226,
      "City District Name": "BAGESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263664,
      "TXT_PINCODE_LOCALITY": "DEOLIKHET",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1992,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263665,
      "TXT_PINCODE_LOCALITY": "MATELA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1992,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263667,
      "TXT_PINCODE_LOCALITY": "SHARMA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1992,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263669,
      "TXT_PINCODE_LOCALITY": "ALMORA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 444192,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263670,
      "TXT_PINCODE_LOCALITY": "LAMGARA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 2226,
      "City District Name": "BAGESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263671,
      "TXT_PINCODE_LOCALITY": "ALMORA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 444192,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263675,
      "TXT_PINCODE_LOCALITY": "KAFRA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1992,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263676,
      "TXT_PINCODE_LOCALITY": "BHARARI (BAGESHWAR)",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1992,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263677,
      "TXT_PINCODE_LOCALITY": "ALMORA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 444192,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263678,
      "TXT_PINCODE_LOCALITY": "SHITTAKHET",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1992,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263679,
      "TXT_PINCODE_LOCALITY": "BHARARI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1992,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263680,
      "TXT_PINCODE_LOCALITY": "CHAUNALIA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1992,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263681,
      "TXT_PINCODE_LOCALITY": "ALMORA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 444192,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263682,
      "TXT_PINCODE_LOCALITY": "DHAULCHINA",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1992,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263683,
      "TXT_PINCODE_LOCALITY": "ALMORA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 444192,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263684,
      "TXT_PINCODE_LOCALITY": "BHANSORI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1992,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263685,
      "TXT_PINCODE_LOCALITY": "ALMORA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 444192,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263686,
      "TXT_PINCODE_LOCALITY": "UPRANI",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 1992,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263687,
      "TXT_PINCODE_LOCALITY": "ALMORA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 444192,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263688,
      "TXT_PINCODE_LOCALITY": "ALMORA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 444192,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263689,
      "TXT_PINCODE_LOCALITY": "ALMORA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 444192,
      "City District Name": "ALMORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263776,
      "TXT_PINCODE_LOCALITY": "BARHALIYA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443113,
      "City District Name": "SULT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 263831,
      "TXT_PINCODE_LOCALITY": "ROLKHET",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443094,
      "City District Name": "KOSYA KUTAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 264407,
      "TXT_PINCODE_LOCALITY": "RASOOLA QAMRUL NISAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4781,
      "City District Name": "NAWABGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 264546,
      "TXT_PINCODE_LOCALITY": "TANKUL",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443070,
      "City District Name": "DHARCHULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 264547,
      "TXT_PINCODE_LOCALITY": "SAGRI DHAKDHAUNA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443070,
      "City District Name": "DHARCHULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 264712,
      "TXT_PINCODE_LOCALITY": "JASPUR",
      "NUM_STATE_CD": 156,
      "State Name": "UTTARANCHAL",
      "NUM_CITYDISTRICT_CD": 424410,
      "City District Name": "UDHAMSINGH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 265224,
      "TXT_PINCODE_LOCALITY": "THANTA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443098,
      "City District Name": "LOHAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 265252,
      "TXT_PINCODE_LOCALITY": "SUKHA BARAN",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443098,
      "City District Name": "LOHAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 265254,
      "TXT_PINCODE_LOCALITY": "KOTSARI",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443098,
      "City District Name": "LOHAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 265441,
      "TXT_PINCODE_LOCALITY": "PITHORAGARH",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443103,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 265501,
      "TXT_PINCODE_LOCALITY": "SINCHAURA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443103,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 266153,
      "TXT_PINCODE_LOCALITY": "DHOLPUR",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 442120,
      "City District Name": "UDHAM SINGH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 266362,
      "TXT_PINCODE_LOCALITY": "TAARSAL PATIYASAR",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443090,
      "City District Name": "KAPKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 266367,
      "TXT_PINCODE_LOCALITY": "MEDARI",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443113,
      "City District Name": "SULT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 267636,
      "TXT_PINCODE_LOCALITY": "DHACH KOTE",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443113,
      "City District Name": "SULT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 267769,
      "TXT_PINCODE_LOCALITY": "SAHARANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 352,
      "City District Name": "SAHARANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 268675,
      "TXT_PINCODE_LOCALITY": "MAKRON",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443108,
      "City District Name": "RANIKHET",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 269280,
      "TXT_PINCODE_LOCALITY": "GULARIYA PARWAT NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443017,
      "City District Name": "MOHAMMDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 269521,
      "TXT_PINCODE_LOCALITY": "DUBIDHA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443103,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 269662,
      "TXT_PINCODE_LOCALITY": "RANIKHET RANGE",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443108,
      "City District Name": "RANIKHET",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700001,
      "TXT_PINCODE_LOCALITY": "TRIALOKYA MAHARAJ SARANI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700002,
      "TXT_PINCODE_LOCALITY": "K C ROAD",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700003,
      "TXT_PINCODE_LOCALITY": "BAGH BAZAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700004,
      "TXT_PINCODE_LOCALITY": "SHYAMBAZAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700005,
      "TXT_PINCODE_LOCALITY": "AHIRITOLA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700006,
      "TXT_PINCODE_LOCALITY": "BIDHAN SARANI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700007,
      "TXT_PINCODE_LOCALITY": "CHI MAIH SARANI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700008,
      "TXT_PINCODE_LOCALITY": "BARISHA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700009,
      "TXT_PINCODE_LOCALITY": "KESHAB CHANDRA SEN STREET",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700010,
      "TXT_PINCODE_LOCALITY": "CHAULPATTI ROAD",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700011,
      "TXT_PINCODE_LOCALITY": "NARKELDANGA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700012,
      "TXT_PINCODE_LOCALITY": "KOLKATA MEDICAL COLLEGE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700013,
      "TXT_PINCODE_LOCALITY": "PENINSULA CORPORATE PARK",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700014,
      "TXT_PINCODE_LOCALITY": "SURESH SARKAR ROAD",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700015,
      "TXT_PINCODE_LOCALITY": "TANGRA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700016,
      "TXT_PINCODE_LOCALITY": "KOLKATA APEEJAY HOUSE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700017,
      "TXT_PINCODE_LOCALITY": "RAWDON STREET",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700018,
      "TXT_PINCODE_LOCALITY": "RABINDRANAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700019,
      "TXT_PINCODE_LOCALITY": "BALLYGUNGE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700020,
      "TXT_PINCODE_LOCALITY": "A. J. C. BOSE ROAD",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700021,
      "TXT_PINCODE_LOCALITY": "FORT WILLIUM",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700022,
      "TXT_PINCODE_LOCALITY": "HASTINGS",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700023,
      "TXT_PINCODE_LOCALITY": "KIDDERPORE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700024,
      "TXT_PINCODE_LOCALITY": "GARDEN REACH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700025,
      "TXT_PINCODE_LOCALITY": "SARAT BOSE ROAD",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700026,
      "TXT_PINCODE_LOCALITY": "KALIGHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700027,
      "TXT_PINCODE_LOCALITY": "ALIPORE H O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700028,
      "TXT_PINCODE_LOCALITY": "NAGENDRA NATH ROAD",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700029,
      "TXT_PINCODE_LOCALITY": "DESHPRIYA PARK EAST",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700030,
      "TXT_PINCODE_LOCALITY": "GHUGHUDANGA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700031,
      "TXT_PINCODE_LOCALITY": "DHAKURIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700032,
      "TXT_PINCODE_LOCALITY": "JADAVPUR UNIVERSITY",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700033,
      "TXT_PINCODE_LOCALITY": "TALLYGUNGE H O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700034,
      "TXT_PINCODE_LOCALITY": "BEHALA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700035,
      "TXT_PINCODE_LOCALITY": "DESH BANDHU ROAD",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700036,
      "TXT_PINCODE_LOCALITY": "BARANAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700037,
      "TXT_PINCODE_LOCALITY": "BELGACHIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700038,
      "TXT_PINCODE_LOCALITY": "KOLKATA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700039,
      "TXT_PINCODE_LOCALITY": "TILJALA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700040,
      "TXT_PINCODE_LOCALITY": "NETAJINAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700041,
      "TXT_PINCODE_LOCALITY": "PASCHIM PUTUARY",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700042,
      "TXT_PINCODE_LOCALITY": "KASBA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700043,
      "TXT_PINCODE_LOCALITY": "SOUTH EASTERN RLY",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700044,
      "TXT_PINCODE_LOCALITY": "BADARTALA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700045,
      "TXT_PINCODE_LOCALITY": "LAKE GARDENS",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700046,
      "TXT_PINCODE_LOCALITY": "TILJALA ROAD",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700047,
      "TXT_PINCODE_LOCALITY": "GANGULY BAGAN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700048,
      "TXT_PINCODE_LOCALITY": "SHREE BHUMI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700049,
      "TXT_PINCODE_LOCALITY": "S N BANERJEE ROAD",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700050,
      "TXT_PINCODE_LOCALITY": "SOUTH SINTHEE ROAD",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700051,
      "TXT_PINCODE_LOCALITY": "SIDDHESWARI BAZAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700052,
      "TXT_PINCODE_LOCALITY": "VIP ROAD",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700053,
      "TXT_PINCODE_LOCALITY": "NEW ALIPORE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700054,
      "TXT_PINCODE_LOCALITY": "BAGMARI ROAD",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700055,
      "TXT_PINCODE_LOCALITY": "DUM DUM PARK",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700056,
      "TXT_PINCODE_LOCALITY": "BIDHAN NAGAR A E BLOCK",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700057,
      "TXT_PINCODE_LOCALITY": "FEEDAR ROAD",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700058,
      "TXT_PINCODE_LOCALITY": "ELIAS ROAD",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700059,
      "TXT_PINCODE_LOCALITY": "KOLKATA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700060,
      "TXT_PINCODE_LOCALITY": "PARNASRIPALLY",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700061,
      "TXT_PINCODE_LOCALITY": "KASTODANGA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700062,
      "TXT_PINCODE_LOCALITY": "W B G S CAMP",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700063,
      "TXT_PINCODE_LOCALITY": "THAKURPUKUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700064,
      "TXT_PINCODE_LOCALITY": "KOLKATA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700065,
      "TXT_PINCODE_LOCALITY": "KOLKATA.",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700066,
      "TXT_PINCODE_LOCALITY": "BIDHANGARH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700067,
      "TXT_PINCODE_LOCALITY": "ULTADANGA MAIN ROAD",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700068,
      "TXT_PINCODE_LOCALITY": "JODHPUR PARK",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700069,
      "TXT_PINCODE_LOCALITY": "ESPLANADE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700070,
      "TXT_PINCODE_LOCALITY": "BANSDRONI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700071,
      "TXT_PINCODE_LOCALITY": "CHIMINH SARANI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700072,
      "TXT_PINCODE_LOCALITY": "PRINCEEP STREET",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700073,
      "TXT_PINCODE_LOCALITY": "CALCUTTA UNIVERSITY",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700074,
      "TXT_PINCODE_LOCALITY": "MOTIJHEEL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700075,
      "TXT_PINCODE_LOCALITY": "SANTOSHPUR AVENUE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700076,
      "TXT_PINCODE_LOCALITY": "DAKSHINESWAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700077,
      "TXT_PINCODE_LOCALITY": "BEDIAPARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700078,
      "TXT_PINCODE_LOCALITY": "HALTU",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700079,
      "TXT_PINCODE_LOCALITY": "ITALGACHA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 440711,
      "City District Name": "24 PARGANA( NORTH)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700080,
      "TXT_PINCODE_LOCALITY": "JESSORE ROAD S.O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700081,
      "TXT_PINCODE_LOCALITY": "SARAT BOSE ROAD",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700082,
      "TXT_PINCODE_LOCALITY": "HARIDEVPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700083,
      "TXT_PINCODE_LOCALITY": "NANDANNAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700084,
      "TXT_PINCODE_LOCALITY": "GARIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700085,
      "TXT_PINCODE_LOCALITY": "SARANI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700086,
      "TXT_PINCODE_LOCALITY": "BAGHA JAIN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700087,
      "TXT_PINCODE_LOCALITY": "NEW MARKET",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700088,
      "TXT_PINCODE_LOCALITY": "CALCUTTA MINT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700089,
      "TXT_PINCODE_LOCALITY": "LAKE TOWN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700090,
      "TXT_PINCODE_LOCALITY": "NETAJI COLONY",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700091,
      "TXT_PINCODE_LOCALITY": "SECH BHAWAN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700092,
      "TXT_PINCODE_LOCALITY": "REGENT ESTATE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700093,
      "TXT_PINCODE_LOCALITY": "PURBA PITIARY",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700094,
      "TXT_PINCODE_LOCALITY": "E.M.BYPASS",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700095,
      "TXT_PINCODE_LOCALITY": "PRINCE GULAM MOHAMMAD SHAH ROAD",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700096,
      "TXT_PINCODE_LOCALITY": "BRHAMAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700097,
      "TXT_PINCODE_LOCALITY": "PURBACHAL S.O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 425903,
      "City District Name": "24 PARGANA( NORTH)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700098,
      "TXT_PINCODE_LOCALITY": "SALT LAKE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700099,
      "TXT_PINCODE_LOCALITY": "KALIKAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700100,
      "TXT_PINCODE_LOCALITY": "VIP NAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1754,
      "City District Name": "24 PARGANAS (S)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700101,
      "TXT_PINCODE_LOCALITY": "PRAFULLA KANAN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700102,
      "TXT_PINCODE_LOCALITY": "BROWARITALA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700103,
      "TXT_PINCODE_LOCALITY": "NARENDRAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700104,
      "TXT_PINCODE_LOCALITY": "JOKA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700105,
      "TXT_PINCODE_LOCALITY": "PATHAR PARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700106,
      "TXT_PINCODE_LOCALITY": "SALT LAKE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700107,
      "TXT_PINCODE_LOCALITY": "KASBA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700108,
      "TXT_PINCODE_LOCALITY": "ASHOKGARH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700109,
      "TXT_PINCODE_LOCALITY": "AGARPARA S.O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700110,
      "TXT_PINCODE_LOCALITY": "SODEPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700111,
      "TXT_PINCODE_LOCALITY": "SANKATA PALLY GHOLA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700112,
      "TXT_PINCODE_LOCALITY": "NORTH PANSILA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700113,
      "TXT_PINCODE_LOCALITY": "SODEPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700114,
      "TXT_PINCODE_LOCALITY": "PANIHATI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700115,
      "TXT_PINCODE_LOCALITY": "SUKCHAR GIRJA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700116,
      "TXT_PINCODE_LOCALITY": "NORTH B.D SOPAN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700117,
      "TXT_PINCODE_LOCALITY": "SURYA SEN NAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700118,
      "TXT_PINCODE_LOCALITY": "RAHARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700119,
      "TXT_PINCODE_LOCALITY": "BARRACKPOR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 425903,
      "City District Name": "24 PARGANA( NORTH)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700120,
      "TXT_PINCODE_LOCALITY": "BARRACKPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700121,
      "TXT_PINCODE_LOCALITY": "24 PARGANAS",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1353,
      "City District Name": "24 PARGANAS S",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700122,
      "TXT_PINCODE_LOCALITY": "KALYANI ROAD",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700123,
      "TXT_PINCODE_LOCALITY": "24 PARGANAS",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 440711,
      "City District Name": "24 PARGANA( NORTH)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700124,
      "TXT_PINCODE_LOCALITY": "BARASAT  NETAJI PALLY",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700125,
      "TXT_PINCODE_LOCALITY": "KAZIPARA B.O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 440711,
      "City District Name": "24 PARGANA( NORTH)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700126,
      "TXT_PINCODE_LOCALITY": "BARASAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700127,
      "TXT_PINCODE_LOCALITY": "HRIDAYPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 425903,
      "City District Name": "24 PARGANA( NORTH)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700128,
      "TXT_PINCODE_LOCALITY": "BARASAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700129,
      "TXT_PINCODE_LOCALITY": "KOLKATA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700130,
      "TXT_PINCODE_LOCALITY": "MADHYAMGRAM BAZAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700131,
      "TXT_PINCODE_LOCALITY": "NEW BARRACKPORE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700132,
      "TXT_PINCODE_LOCALITY": "24 PARGANAS",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 425903,
      "City District Name": "24 PARGANA( NORTH)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700133,
      "TXT_PINCODE_LOCALITY": "24 PARGANAS",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1353,
      "City District Name": "24 PARGANAS S",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700134,
      "TXT_PINCODE_LOCALITY": "NORTH NILACHOL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700135,
      "TXT_PINCODE_LOCALITY": "RAJARHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700136,
      "TXT_PINCODE_LOCALITY": "RAJARHAT S. O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 440711,
      "City District Name": "24 PARGANA( NORTH)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700137,
      "TXT_PINCODE_LOCALITY": "BUDGE BUDGE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1754,
      "City District Name": "24 PARGANAS (S)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700138,
      "TXT_PINCODE_LOCALITY": "BUDGE BUDGE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1754,
      "City District Name": "24 PARGANAS (S)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700139,
      "TXT_PINCODE_LOCALITY": "VIVEKANANDA PALLY",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700140,
      "TXT_PINCODE_LOCALITY": "MAHESH TALA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700141,
      "TXT_PINCODE_LOCALITY": "SOUTH JAGTALA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1754,
      "City District Name": "24 PARGANAS (S)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700142,
      "TXT_PINCODE_LOCALITY": "SOUTH SANTOSHPUR MAHESHTALA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700143,
      "TXT_PINCODE_LOCALITY": "SOUTH SARKARPOOL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700144,
      "TXT_PINCODE_LOCALITY": "DOLTALA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1754,
      "City District Name": "24 PARGANAS (S)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700145,
      "TXT_PINCODE_LOCALITY": "HARIHARPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700146,
      "TXT_PINCODE_LOCALITY": "KODALIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700147,
      "TXT_PINCODE_LOCALITY": "SOUTH SUBHASGRAM",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700148,
      "TXT_PINCODE_LOCALITY": "HARINAVI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700149,
      "TXT_PINCODE_LOCALITY": "KOLKATA.",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700150,
      "TXT_PINCODE_LOCALITY": "24 PARGANAS",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1353,
      "City District Name": "24 PARGANAS S",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700151,
      "TXT_PINCODE_LOCALITY": "SONARPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700152,
      "TXT_PINCODE_LOCALITY": "GARIA NABAGRAM",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700153,
      "TXT_PINCODE_LOCALITY": "LASKARPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1754,
      "City District Name": "24 PARGANAS (S)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700154,
      "TXT_PINCODE_LOCALITY": "BONHOOGHLY",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1754,
      "City District Name": "24 PARGANAS (S)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700155,
      "TXT_PINCODE_LOCALITY": "ABDALPUR S.O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700156,
      "TXT_PINCODE_LOCALITY": "RAJARHAT NEWTOWN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700157,
      "TXT_PINCODE_LOCALITY": "NEW TOWN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700158,
      "TXT_PINCODE_LOCALITY": "SUBHASHPALLI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 443036,
      "City District Name": "BARASAT - I",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700159,
      "TXT_PINCODE_LOCALITY": "KOLKATA S. O.",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700160,
      "TXT_PINCODE_LOCALITY": "BELEGHATA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 425903,
      "City District Name": "24 PARGANA( NORTH)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700161,
      "TXT_PINCODE_LOCALITY": "NEW TOWN.",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700162,
      "TXT_PINCODE_LOCALITY": "DESHBANDHUNAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 440711,
      "City District Name": "24 PARGANA( NORTH)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700163,
      "TXT_PINCODE_LOCALITY": "NEW TOWN ACTION AREA I",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 700164,
      "TXT_PINCODE_LOCALITY": "KOLKATA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 703015,
      "TXT_PINCODE_LOCALITY": "GORAKHPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711101,
      "TXT_PINCODE_LOCALITY": "DOBSON ROAD",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711102,
      "TXT_PINCODE_LOCALITY": "COWS GHAT ROAD",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711103,
      "TXT_PINCODE_LOCALITY": "BOTANIC GARDEN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711104,
      "TXT_PINCODE_LOCALITY": "OLABIBITALA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711105,
      "TXT_PINCODE_LOCALITY": "BELGACHIA MANSHATALA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711106,
      "TXT_PINCODE_LOCALITY": "SALKIA H O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711107,
      "TXT_PINCODE_LOCALITY": "V C MILLS",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711108,
      "TXT_PINCODE_LOCALITY": "NETAJI SUBHAS",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711109,
      "TXT_PINCODE_LOCALITY": "DANESH SK LANE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711110,
      "TXT_PINCODE_LOCALITY": "BAKSARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711111,
      "TXT_PINCODE_LOCALITY": "JAGACHA S.O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711112,
      "TXT_PINCODE_LOCALITY": "G I P COLONY",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711113,
      "TXT_PINCODE_LOCALITY": "BALITKURI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711114,
      "TXT_PINCODE_LOCALITY": "HOWRAH.",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711115,
      "TXT_PINCODE_LOCALITY": "JAGADISHPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711201,
      "TXT_PINCODE_LOCALITY": "MOHANLAL BHALWALA ROAD",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711202,
      "TXT_PINCODE_LOCALITY": "BELU BAZAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711203,
      "TXT_PINCODE_LOCALITY": "BHATTA NAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711204,
      "TXT_PINCODE_LOCALITY": "LILUAH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711205,
      "TXT_PINCODE_LOCALITY": "SAMBAY PALLAY",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711206,
      "TXT_PINCODE_LOCALITY": "MALIPACHGHARA STREET",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711224,
      "TXT_PINCODE_LOCALITY": "DHANKUNI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711225,
      "TXT_PINCODE_LOCALITY": "PANCHARULE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711226,
      "TXT_PINCODE_LOCALITY": "UDAYANARAYANPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711227,
      "TXT_PINCODE_LOCALITY": "GHOSHPARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711301,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR - 3995",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711302,
      "TXT_PINCODE_LOCALITY": "JUGALPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711303,
      "TXT_PINCODE_LOCALITY": "BAGNAN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711304,
      "TXT_PINCODE_LOCALITY": "BANIPUR HOWRAH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711305,
      "TXT_PINCODE_LOCALITY": "BAURIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711306,
      "TXT_PINCODE_LOCALITY": "BUXARAH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711307,
      "TXT_PINCODE_LOCALITY": "CHAKASHI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711308,
      "TXT_PINCODE_LOCALITY": "CHENGAIL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711309,
      "TXT_PINCODE_LOCALITY": "DELTA MILLS",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711310,
      "TXT_PINCODE_LOCALITY": "FORT GLOSTER",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711311,
      "TXT_PINCODE_LOCALITY": "OLD JAGACHA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711312,
      "TXT_PINCODE_LOCALITY": "MUGKALYAN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711313,
      "TXT_PINCODE_LOCALITY": "SANKRAIL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711314,
      "TXT_PINCODE_LOCALITY": "SHYAMPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711315,
      "TXT_PINCODE_LOCALITY": "ULUBERIA R.S.",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711316,
      "TXT_PINCODE_LOCALITY": "ULUBERIA RS",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711317,
      "TXT_PINCODE_LOCALITY": "RADHADASI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711321,
      "TXT_PINCODE_LOCALITY": "OLD JAGACHA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711322,
      "TXT_PINCODE_LOCALITY": "PANCHLA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711323,
      "TXT_PINCODE_LOCALITY": "SHIBAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711324,
      "TXT_PINCODE_LOCALITY": "SAMTA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711325,
      "TXT_PINCODE_LOCALITY": "PANITRAS",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711326,
      "TXT_PINCODE_LOCALITY": "SASOTI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711327,
      "TXT_PINCODE_LOCALITY": "HOWRAH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711328,
      "TXT_PINCODE_LOCALITY": "J.P.HAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711331,
      "TXT_PINCODE_LOCALITY": "PANIARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711401,
      "TXT_PINCODE_LOCALITY": "AMTA - 4184",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711402,
      "TXT_PINCODE_LOCALITY": "BALTIKURI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711403,
      "TXT_PINCODE_LOCALITY": "BANK",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711404,
      "TXT_PINCODE_LOCALITY": "BARGACHIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711405,
      "TXT_PINCODE_LOCALITY": "DOMJUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711406,
      "TXT_PINCODE_LOCALITY": "GARALGACHA - 1519",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711408,
      "TXT_PINCODE_LOCALITY": "JAGATBALLAVPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711409,
      "TXT_PINCODE_LOCALITY": "MAKARDAH - 2868",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711410,
      "TXT_PINCODE_LOCALITY": "MUNSHIRHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711411,
      "TXT_PINCODE_LOCALITY": "BEGRI - 658",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711412,
      "TXT_PINCODE_LOCALITY": "CHITRASENPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711413,
      "TXT_PINCODE_LOCALITY": "GAZIPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711414,
      "TXT_PINCODE_LOCALITY": "MAJU",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711415,
      "TXT_PINCODE_LOCALITY": "PANTIHAL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711416,
      "TXT_PINCODE_LOCALITY": "MANIKURA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711810,
      "TXT_PINCODE_LOCALITY": "BURIKHALI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 711820,
      "TXT_PINCODE_LOCALITY": "SAHAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712101,
      "TXT_PINCODE_LOCALITY": "PRATAPPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712102,
      "TXT_PINCODE_LOCALITY": "CHINSURA RS",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712103,
      "TXT_PINCODE_LOCALITY": "BAZAR COLONY",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712104,
      "TXT_PINCODE_LOCALITY": "KEOTA LAT BAGAN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712105,
      "TXT_PINCODE_LOCALITY": "BUROSHIBTALA S. O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712106,
      "TXT_PINCODE_LOCALITY": "KOLKATA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712121,
      "TXT_PINCODE_LOCALITY": "ADCONAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712122,
      "TXT_PINCODE_LOCALITY": "BADENGANJ",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712123,
      "TXT_PINCODE_LOCALITY": "BANDEL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712124,
      "TXT_PINCODE_LOCALITY": "BNADRESWAR STATION ROAD",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712125,
      "TXT_PINCODE_LOCALITY": "TELINIPARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712134,
      "TXT_PINCODE_LOCALITY": "BAINCHI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712135,
      "TXT_PINCODE_LOCALITY": "BALLALDIGHI B.O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712136,
      "TXT_PINCODE_LOCALITY": "CHANDAN NAGAR - 924",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712137,
      "TXT_PINCODE_LOCALITY": "GONDALPARA S. O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712138,
      "TXT_PINCODE_LOCALITY": "PALPARA B. O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712139,
      "TXT_PINCODE_LOCALITY": "MANKUNDU",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712146,
      "TXT_PINCODE_LOCALITY": "LLSOBA MONDLAI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712147,
      "TXT_PINCODE_LOCALITY": "CHATOSARSA B.O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712148,
      "TXT_PINCODE_LOCALITY": "MAGRA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712149,
      "TXT_PINCODE_LOCALITY": "PANDUA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712150,
      "TXT_PINCODE_LOCALITY": "RABINDRANAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712151,
      "TXT_PINCODE_LOCALITY": "RAIJAMNA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712152,
      "TXT_PINCODE_LOCALITY": "RAMESWARPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712153,
      "TXT_PINCODE_LOCALITY": "RAMNATHPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712154,
      "TXT_PINCODE_LOCALITY": "POLBA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712155,
      "TXT_PINCODE_LOCALITY": "AYEMANAWABPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712156,
      "TXT_PINCODE_LOCALITY": "BELOON",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712157,
      "TXT_PINCODE_LOCALITY": "KHANYAN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712158,
      "TXT_PINCODE_LOCALITY": "SIMLAGARH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712201,
      "TXT_PINCODE_LOCALITY": "SERAMPUR COURT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712202,
      "TXT_PINCODE_LOCALITY": "MAHES-I",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712203,
      "TXT_PINCODE_LOCALITY": "SERAMPORE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712204,
      "TXT_PINCODE_LOCALITY": "CHATRA HOOGHLY",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712205,
      "TXT_PINCODE_LOCALITY": "MOREPUKUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712208,
      "TXT_PINCODE_LOCALITY": "DURGAPUR ALLOY STEEL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712221,
      "TXT_PINCODE_LOCALITY": "DURGAPUR BRIEK FIELD",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712222,
      "TXT_PINCODE_LOCALITY": "BAIDYABATI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712223,
      "TXT_PINCODE_LOCALITY": "SHEORAPHULI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712224,
      "TXT_PINCODE_LOCALITY": "MRIGALA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712232,
      "TXT_PINCODE_LOCALITY": "HOOGHLY",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712233,
      "TXT_PINCODE_LOCALITY": "HIND MOTOR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712234,
      "TXT_PINCODE_LOCALITY": "KANAIPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712235,
      "TXT_PINCODE_LOCALITY": "KONNAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712245,
      "TXT_PINCODE_LOCALITY": "MAKHLA - 4377",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712246,
      "TXT_PINCODE_LOCALITY": "NABAGRAM",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712247,
      "TXT_PINCODE_LOCALITY": "RAGHUNATHPUR (HOOGHLY)",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712248,
      "TXT_PINCODE_LOCALITY": "MAHESH-II",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712249,
      "TXT_PINCODE_LOCALITY": "RISHRA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712250,
      "TXT_PINCODE_LOCALITY": "BAMUNARI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712258,
      "TXT_PINCODE_LOCALITY": "UTTARPARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712301,
      "TXT_PINCODE_LOCALITY": "BHANDERHATI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712302,
      "TXT_PINCODE_LOCALITY": "DHANIAKHALI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712303,
      "TXT_PINCODE_LOCALITY": "GURUP",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712304,
      "TXT_PINCODE_LOCALITY": "JANAI - 1041",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712305,
      "TXT_PINCODE_LOCALITY": "SINHET B. O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712306,
      "TXT_PINCODE_LOCALITY": "BEGAMPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712307,
      "TXT_PINCODE_LOCALITY": "POWNAN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712308,
      "TXT_PINCODE_LOCALITY": "KHANPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712309,
      "TXT_PINCODE_LOCALITY": "BELMURI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712310,
      "TXT_PINCODE_LOCALITY": "SANTRAPARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712311,
      "TXT_PINCODE_LOCALITY": "DANKUNI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712312,
      "TXT_PINCODE_LOCALITY": "HOOGHLY",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712331,
      "TXT_PINCODE_LOCALITY": "HOOGHLY",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712365,
      "TXT_PINCODE_LOCALITY": "CHITTARANJAN TOWNSHIP",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712401,
      "TXT_PINCODE_LOCALITY": "TARAKESHWAR ( CHAMPADANGA)",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712402,
      "TXT_PINCODE_LOCALITY": "DASGHARA S.O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712403,
      "TXT_PINCODE_LOCALITY": "HARIPAL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712404,
      "TXT_PINCODE_LOCALITY": "SERAMPORE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712405,
      "TXT_PINCODE_LOCALITY": "BAHIRKHAND B.O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712406,
      "TXT_PINCODE_LOCALITY": "KHANAKUL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712407,
      "TXT_PINCODE_LOCALITY": "LLLAHIPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712408,
      "TXT_PINCODE_LOCALITY": "RAJBALHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712409,
      "TXT_PINCODE_LOCALITY": "SINGUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712410,
      "TXT_PINCODE_LOCALITY": "TARAKESHWAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712411,
      "TXT_PINCODE_LOCALITY": "RAMNAGAR (HOOGHLY)",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712412,
      "TXT_PINCODE_LOCALITY": "HELAN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712413,
      "TXT_PINCODE_LOCALITY": "MAYAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712414,
      "TXT_PINCODE_LOCALITY": "GHARGOHAL B.O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712415,
      "TXT_PINCODE_LOCALITY": "SAIDPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712416,
      "TXT_PINCODE_LOCALITY": "NATIBPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712417,
      "TXT_PINCODE_LOCALITY": "RAJHATI BANDAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712418,
      "TXT_PINCODE_LOCALITY": "PARGOPALNAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712419,
      "TXT_PINCODE_LOCALITY": "JANGALPARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712420,
      "TXT_PINCODE_LOCALITY": "MOLCYPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712421,
      "TXT_PINCODE_LOCALITY": "MAINAN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712422,
      "TXT_PINCODE_LOCALITY": "PRATAPNAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712423,
      "TXT_PINCODE_LOCALITY": "GOPINAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712424,
      "TXT_PINCODE_LOCALITY": "ANTPAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712425,
      "TXT_PINCODE_LOCALITY": "KRISHNAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712426,
      "TXT_PINCODE_LOCALITY": "PRASADPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712428,
      "TXT_PINCODE_LOCALITY": "KESHABPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712429,
      "TXT_PINCODE_LOCALITY": "RAUTH KHANA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712431,
      "TXT_PINCODE_LOCALITY": "BAKTA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 443046,
      "City District Name": "GALSI - II",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712501,
      "TXT_PINCODE_LOCALITY": "BALAGARH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712502,
      "TXT_PINCODE_LOCALITY": "SHIBPUR BANSBERIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712503,
      "TXT_PINCODE_LOCALITY": "TRIBENI BAZAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712504,
      "TXT_PINCODE_LOCALITY": "CHANDRAHATI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712506,
      "TXT_PINCODE_LOCALITY": "MIDNAPORE S. O.",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 450,
      "City District Name": "MIDNAPORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712512,
      "TXT_PINCODE_LOCALITY": "GUPTIPARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712513,
      "TXT_PINCODE_LOCALITY": "NAYASARAI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712514,
      "TXT_PINCODE_LOCALITY": "SRIPUR BAZAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712515,
      "TXT_PINCODE_LOCALITY": "BANESWARPUR B.O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712516,
      "TXT_PINCODE_LOCALITY": "BELOON",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712601,
      "TXT_PINCODE_LOCALITY": "NUTANBAZAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712602,
      "TXT_PINCODE_LOCALITY": "BHADUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712611,
      "TXT_PINCODE_LOCALITY": "BENGAI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712612,
      "TXT_PINCODE_LOCALITY": "KAMARPUKUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712613,
      "TXT_PINCODE_LOCALITY": "GOURHATI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712614,
      "TXT_PINCODE_LOCALITY": "GOGHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712615,
      "TXT_PINCODE_LOCALITY": "BATANAL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712616,
      "TXT_PINCODE_LOCALITY": "BALIDIWANGANJ",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712617,
      "TXT_PINCODE_LOCALITY": "ARAMBAGH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712701,
      "TXT_PINCODE_LOCALITY": "MOSAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712702,
      "TXT_PINCODE_LOCALITY": "CHANDITALA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712703,
      "TXT_PINCODE_LOCALITY": "NAWABPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712704,
      "TXT_PINCODE_LOCALITY": "KUMIRMORA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712705,
      "TXT_PINCODE_LOCALITY": "KRISTORAMPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712706,
      "TXT_PINCODE_LOCALITY": "SHEEKHALA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712707,
      "TXT_PINCODE_LOCALITY": "ILLAHIPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712708,
      "TXT_PINCODE_LOCALITY": "GARALGACHA S.O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712904,
      "TXT_PINCODE_LOCALITY": "JANAI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 712906,
      "TXT_PINCODE_LOCALITY": "PAPPAKUDI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713101,
      "TXT_PINCODE_LOCALITY": "BURDWAN H O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713102,
      "TXT_PINCODE_LOCALITY": "KOTALHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713103,
      "TXT_PINCODE_LOCALITY": "SADARGHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713104,
      "TXT_PINCODE_LOCALITY": "LAKURDI T.K. ROAD",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713121,
      "TXT_PINCODE_LOCALITY": "ARUAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713122,
      "TXT_PINCODE_LOCALITY": "BAIDYAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713123,
      "TXT_PINCODE_LOCALITY": "BANWARIBAD RAJ",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713124,
      "TXT_PINCODE_LOCALITY": "BARSUL UNNAYANI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713125,
      "TXT_PINCODE_LOCALITY": "BHATAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713126,
      "TXT_PINCODE_LOCALITY": "BHEDIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713127,
      "TXT_PINCODE_LOCALITY": "BONPAS",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713128,
      "TXT_PINCODE_LOCALITY": "GHUSKARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713129,
      "TXT_PINCODE_LOCALITY": "KANDRA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713130,
      "TXT_PINCODE_LOCALITY": "KATWA H O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713131,
      "TXT_PINCODE_LOCALITY": "LAKHURIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713132,
      "TXT_PINCODE_LOCALITY": "MANGALKOTE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444122,
      "City District Name": "PASCHIM BARDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713140,
      "TXT_PINCODE_LOCALITY": "KETUGRAM",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713141,
      "TXT_PINCODE_LOCALITY": "KHANA JN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713142,
      "TXT_PINCODE_LOCALITY": "KHANDAGHOSH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713143,
      "TXT_PINCODE_LOCALITY": "KOYCHAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713144,
      "TXT_PINCODE_LOCALITY": "MANKAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713145,
      "TXT_PINCODE_LOCALITY": "MANTESWAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713146,
      "TXT_PINCODE_LOCALITY": "MEMARI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713147,
      "TXT_PINCODE_LOCALITY": "NUTANHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713148,
      "TXT_PINCODE_LOCALITY": "PANAGARH BAZAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713149,
      "TXT_PINCODE_LOCALITY": "SAKTIGARH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713150,
      "TXT_PINCODE_LOCALITY": "SRIKHANDA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713151,
      "TXT_PINCODE_LOCALITY": "RASULPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713152,
      "TXT_PINCODE_LOCALITY": "BANNABAGRAM - 2926",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713153,
      "TXT_PINCODE_LOCALITY": "BHATKUNDA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713154,
      "TXT_PINCODE_LOCALITY": "AMADPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713155,
      "TXT_PINCODE_LOCALITY": "AMARARGARH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713156,
      "TXT_PINCODE_LOCALITY": "AUSHGRAM",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713157,
      "TXT_PINCODE_LOCALITY": "AKALPOUSH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713158,
      "TXT_PINCODE_LOCALITY": "BARABELUN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713159,
      "TXT_PINCODE_LOCALITY": "GOPALMATH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 443034,
      "City District Name": "AUSGRAM - II",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713160,
      "TXT_PINCODE_LOCALITY": "GONPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713161,
      "TXT_PINCODE_LOCALITY": "GALATUN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713162,
      "TXT_PINCODE_LOCALITY": "HATKRITI NAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713163,
      "TXT_PINCODE_LOCALITY": "HARGRAM",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713164,
      "TXT_PINCODE_LOCALITY": "KHANO",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713165,
      "TXT_PINCODE_LOCALITY": "NASIGRAM",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713166,
      "TXT_PINCODE_LOCALITY": "NABAGRAM (BDN)",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713167,
      "TXT_PINCODE_LOCALITY": "ORGRAM",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713168,
      "TXT_PINCODE_LOCALITY": "HARGRAM",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713169,
      "TXT_PINCODE_LOCALITY": "SHILAMPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713170,
      "TXT_PINCODE_LOCALITY": "SINGERKONE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713171,
      "TXT_PINCODE_LOCALITY": "SUATA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713172,
      "TXT_PINCODE_LOCALITY": "TRILOKCHANDPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713173,
      "TXT_PINCODE_LOCALITY": "UNIATATARPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713174,
      "TXT_PINCODE_LOCALITY": "MANGALKOTE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713201,
      "TXT_PINCODE_LOCALITY": "STATION ROAD",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 807,
      "City District Name": "DURGAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713202,
      "TXT_PINCODE_LOCALITY": "DURGAPUR B ZONE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713203,
      "TXT_PINCODE_LOCALITY": "DURGAPUR STEEL PROJECT S. O.",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 442119,
      "City District Name": "BARDDHMAN.",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713204,
      "TXT_PINCODE_LOCALITY": "DURGAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713205,
      "TXT_PINCODE_LOCALITY": "DURGAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713206,
      "TXT_PINCODE_LOCALITY": "DURGAPUR S.O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 807,
      "City District Name": "DURGAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713207,
      "TXT_PINCODE_LOCALITY": "DURGAPUR THERMAL POWER STN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713208,
      "TXT_PINCODE_LOCALITY": "STEEL PROJECT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713209,
      "TXT_PINCODE_LOCALITY": "M G AVENUE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713210,
      "TXT_PINCODE_LOCALITY": "DURGAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 807,
      "City District Name": "DURGAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713211,
      "TXT_PINCODE_LOCALITY": "DURGAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1118,
      "City District Name": "BARDHMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713212,
      "TXT_PINCODE_LOCALITY": "BIDHAN NAGAR BDN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713213,
      "TXT_PINCODE_LOCALITY": "DURGAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713214,
      "TXT_PINCODE_LOCALITY": "AMRAVATI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713215,
      "TXT_PINCODE_LOCALITY": "ANGADPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713216,
      "TXT_PINCODE_LOCALITY": "DURGAPUR CITY CENTRE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713217,
      "TXT_PINCODE_LOCALITY": "OYARIA S.O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713218,
      "TXT_PINCODE_LOCALITY": "GHUSKARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713219,
      "TXT_PINCODE_LOCALITY": "GOPINATHPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713301,
      "TXT_PINCODE_LOCALITY": "ASANSOL H O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713302,
      "TXT_PINCODE_LOCALITY": "DAKHIN DHADKA S.O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713303,
      "TXT_PINCODE_LOCALITY": "RAMBANDHUTALA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713304,
      "TXT_PINCODE_LOCALITY": "ASANSOL COURT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713305,
      "TXT_PINCODE_LOCALITY": "RAMKRISHNA MISSION",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713308,
      "TXT_PINCODE_LOCALITY": "DURGAPUR ALLOY STEEL PROBJECT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713310,
      "TXT_PINCODE_LOCALITY": "KALLA CENTRAL HOSPITAL S.O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713315,
      "TXT_PINCODE_LOCALITY": "MADANPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 434,
      "City District Name": "BURDWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713321,
      "TXT_PINCODE_LOCALITY": "ANDAL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713322,
      "TXT_PINCODE_LOCALITY": "BAHULA - 393",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713323,
      "TXT_PINCODE_LOCALITY": "BALLAVPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713324,
      "TXT_PINCODE_LOCALITY": "BARAKAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713325,
      "TXT_PINCODE_LOCALITY": "BURNPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713326,
      "TXT_PINCODE_LOCALITY": "CHOTO DIGHARI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713327,
      "TXT_PINCODE_LOCALITY": "ASANSOL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 891,
      "City District Name": "ASANSOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713328,
      "TXT_PINCODE_LOCALITY": "ASANSOL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 891,
      "City District Name": "ASANSOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713329,
      "TXT_PINCODE_LOCALITY": "ASANSOL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 891,
      "City District Name": "ASANSOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713330,
      "TXT_PINCODE_LOCALITY": "BURDWAN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 434,
      "City District Name": "BURDWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713331,
      "TXT_PINCODE_LOCALITY": "SIMJURI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444131,
      "City District Name": "BARDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713332,
      "TXT_PINCODE_LOCALITY": "DEBCHAND NAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713333,
      "TXT_PINCODE_LOCALITY": "DISERGARH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713334,
      "TXT_PINCODE_LOCALITY": "DOMOHAN BAZAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713335,
      "TXT_PINCODE_LOCALITY": "HINDUSTAN CABLE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713336,
      "TXT_PINCODE_LOCALITY": "JAMURIAHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713337,
      "TXT_PINCODE_LOCALITY": "JAYKAYNAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713338,
      "TXT_PINCODE_LOCALITY": "KAJORAGRAM",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713339,
      "TXT_PINCODE_LOCALITY": "KALIPAHARI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713340,
      "TXT_PINCODE_LOCALITY": "KALLA CENT HOSPITAL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713341,
      "TXT_PINCODE_LOCALITY": "KANYAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713342,
      "TXT_PINCODE_LOCALITY": "KENDRA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713343,
      "TXT_PINCODE_LOCALITY": "PARK LANE E KULTI  BURDWAN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713344,
      "TXT_PINCODE_LOCALITY": "NANDI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713345,
      "TXT_PINCODE_LOCALITY": "OYARIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713346,
      "TXT_PINCODE_LOCALITY": "PANDAVESWAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713347,
      "TXT_PINCODE_LOCALITY": "RANIGANJ H O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713357,
      "TXT_PINCODE_LOCALITY": "SALAMPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713358,
      "TXT_PINCODE_LOCALITY": "NIMCHA.",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1118,
      "City District Name": "BARDHMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713359,
      "TXT_PINCODE_LOCALITY": "SITARAMPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713360,
      "TXT_PINCODE_LOCALITY": "SUNDARCHAK",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713361,
      "TXT_PINCODE_LOCALITY": "SURJANAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713362,
      "TXT_PINCODE_LOCALITY": "TOPOSI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713363,
      "TXT_PINCODE_LOCALITY": "UKHRA PURATAN HATTALA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713364,
      "TXT_PINCODE_LOCALITY": "RUPNARAYANPUR BAZAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713365,
      "TXT_PINCODE_LOCALITY": "CHITTARANJAN TOWNSHIP",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713366,
      "TXT_PINCODE_LOCALITY": "BARABANI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713367,
      "TXT_PINCODE_LOCALITY": "BARADHEMO",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713368,
      "TXT_PINCODE_LOCALITY": "CHURULIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713369,
      "TXT_PINCODE_LOCALITY": "KALYANESWARI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713370,
      "TXT_PINCODE_LOCALITY": "NENGHA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713371,
      "TXT_PINCODE_LOCALITY": "MITHANI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713372,
      "TXT_PINCODE_LOCALITY": "RADHANAGAR RLY COLONY",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713373,
      "TXT_PINCODE_LOCALITY": "SRIPUR BAZAR (BDN)",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713374,
      "TXT_PINCODE_LOCALITY": "BHADURPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713375,
      "TXT_PINCODE_LOCALITY": "BENALI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713376,
      "TXT_PINCODE_LOCALITY": "DHANDADIHI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713377,
      "TXT_PINCODE_LOCALITY": "GOUR BAZAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713378,
      "TXT_PINCODE_LOCALITY": "HARIPUR - 1733",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713379,
      "TXT_PINCODE_LOCALITY": "JEMAHARI - 1980",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713380,
      "TXT_PINCODE_LOCALITY": "KENDRA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713381,
      "TXT_PINCODE_LOCALITY": "NUTANDANGA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713382,
      "TXT_PINCODE_LOCALITY": "PARASCOLE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713383,
      "TXT_PINCODE_LOCALITY": "SATGRAM",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713384,
      "TXT_PINCODE_LOCALITY": "PARASEA - 3645",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713385,
      "TXT_PINCODE_LOCALITY": "LANDOHA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713386,
      "TXT_PINCODE_LOCALITY": "GOPINATHPUR ASANSOL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713387,
      "TXT_PINCODE_LOCALITY": "NADIHA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713401,
      "TXT_PINCODE_LOCALITY": "AJHAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713402,
      "TXT_PINCODE_LOCALITY": "ARJUNPUR BDN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713403,
      "TXT_PINCODE_LOCALITY": "BUDBUD",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713404,
      "TXT_PINCODE_LOCALITY": "CHAKDIGHI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713405,
      "TXT_PINCODE_LOCALITY": "DHATRIGRAM - 1756",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713406,
      "TXT_PINCODE_LOCALITY": "GALSI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713407,
      "TXT_PINCODE_LOCALITY": "HATGOBINDAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713408,
      "TXT_PINCODE_LOCALITY": "JAMALPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713409,
      "TXT_PINCODE_LOCALITY": "KALNA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713419,
      "TXT_PINCODE_LOCALITY": "MURARIPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713420,
      "TXT_PINCODE_LOCALITY": "PANAGARH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713421,
      "TXT_PINCODE_LOCALITY": "RAINA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713422,
      "TXT_PINCODE_LOCALITY": "SATGACHIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713423,
      "TXT_PINCODE_LOCALITY": "SEHARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713424,
      "TXT_PINCODE_LOCALITY": "SHYAMSUNDAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713425,
      "TXT_PINCODE_LOCALITY": "SIMLON",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713426,
      "TXT_PINCODE_LOCALITY": "MANDALGRAM",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713427,
      "TXT_PINCODE_LOCALITY": "UCHALON",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713428,
      "TXT_PINCODE_LOCALITY": "ADRAHATI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713429,
      "TXT_PINCODE_LOCALITY": "BARAPALASON",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713430,
      "TXT_PINCODE_LOCALITY": "BOHAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713431,
      "TXT_PINCODE_LOCALITY": "BAKTA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713432,
      "TXT_PINCODE_LOCALITY": "BHURI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713433,
      "TXT_PINCODE_LOCALITY": "URA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713434,
      "TXT_PINCODE_LOCALITY": "NIBHUJI BAZAR S.O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444131,
      "City District Name": "BARDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713442,
      "TXT_PINCODE_LOCALITY": "BARDDHAMAN.",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713501,
      "TXT_PINCODE_LOCALITY": "BAGNAPARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713502,
      "TXT_PINCODE_LOCALITY": "DAINHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713512,
      "TXT_PINCODE_LOCALITY": "NARAYANPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713513,
      "TXT_PINCODE_LOCALITY": "PURBASTHALI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713514,
      "TXT_PINCODE_LOCALITY": "SINGHI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713515,
      "TXT_PINCODE_LOCALITY": "GANGATIKURI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713516,
      "TXT_PINCODE_LOCALITY": "PANUHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713517,
      "TXT_PINCODE_LOCALITY": "SUDPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713518,
      "TXT_PINCODE_LOCALITY": "KHAJURDIHI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713519,
      "TXT_PINCODE_LOCALITY": "SAMUDRAGARH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713520,
      "TXT_PINCODE_LOCALITY": "NRISINGHAPUR S.O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1118,
      "City District Name": "BARDHMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 713614,
      "TXT_PINCODE_LOCALITY": "KHANO",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721101,
      "TXT_PINCODE_LOCALITY": "MIDNAPORE DIST",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721102,
      "TXT_PINCODE_LOCALITY": "RAKHA JUNGLE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1433,
      "City District Name": "PASCHIM MIDNAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721111,
      "TXT_PINCODE_LOCALITY": "BHANGABANDH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721121,
      "TXT_PINCODE_LOCALITY": "AMLAGORA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721122,
      "TXT_PINCODE_LOCALITY": "ANANDAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721123,
      "TXT_PINCODE_LOCALITY": "BAGRI KRISHNAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721124,
      "TXT_PINCODE_LOCALITY": "BALLICHAK",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721125,
      "TXT_PINCODE_LOCALITY": "BALIGERIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721126,
      "TXT_PINCODE_LOCALITY": "DEBRA BAZAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721127,
      "TXT_PINCODE_LOCALITY": "GARHBETA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721128,
      "TXT_PINCODE_LOCALITY": "GOALTORE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721129,
      "TXT_PINCODE_LOCALITY": "GODAPEASAL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721130,
      "TXT_PINCODE_LOCALITY": "GOPAL NAGAR TAMLUK",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721131,
      "TXT_PINCODE_LOCALITY": "HAUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721132,
      "TXT_PINCODE_LOCALITY": "SALBONI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1433,
      "City District Name": "PASCHIM MIDNAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721133,
      "TXT_PINCODE_LOCALITY": "KESHIRARY",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721134,
      "TXT_PINCODE_LOCALITY": "KOLAGHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721135,
      "TXT_PINCODE_LOCALITY": "KULTIKRI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721136,
      "TXT_PINCODE_LOCALITY": "LOWADA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721137,
      "TXT_PINCODE_LOCALITY": "MACHADA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721138,
      "TXT_PINCODE_LOCALITY": "NAYAGRAM",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721139,
      "TXT_PINCODE_LOCALITY": "BALIDANGRI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721140,
      "TXT_PINCODE_LOCALITY": "PINGLA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721141,
      "TXT_PINCODE_LOCALITY": "RAMGARH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721142,
      "TXT_PINCODE_LOCALITY": "RAMTARAKHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721143,
      "TXT_PINCODE_LOCALITY": "ANDHARI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 441223,
      "City District Name": "WEST MIDNAPORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721144,
      "TXT_PINCODE_LOCALITY": "SABONG",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721145,
      "TXT_PINCODE_LOCALITY": "SALUA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721146,
      "TXT_PINCODE_LOCALITY": "SEKANDARI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721147,
      "TXT_PINCODE_LOCALITY": "SALBANI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721148,
      "TXT_PINCODE_LOCALITY": "ROHINI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721149,
      "TXT_PINCODE_LOCALITY": "MADPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721150,
      "TXT_PINCODE_LOCALITY": "KESHPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721151,
      "TXT_PINCODE_LOCALITY": "BHOGPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721152,
      "TXT_PINCODE_LOCALITY": "PANSKURA R S",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721153,
      "TXT_PINCODE_LOCALITY": "JOTEGHANASHYAM",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721154,
      "TXT_PINCODE_LOCALITY": "PULSITA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721155,
      "TXT_PINCODE_LOCALITY": "JALCHAK",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721156,
      "TXT_PINCODE_LOCALITY": "MARHTALA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721157,
      "TXT_PINCODE_LOCALITY": "HOOMGARH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721158,
      "TXT_PINCODE_LOCALITY": "BAISHNABCHAK",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721159,
      "TXT_PINCODE_LOCALITY": "KHARIKAMATHANI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721160,
      "TXT_PINCODE_LOCALITY": "RADHAMOHANPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721161,
      "TXT_PINCODE_LOCALITY": "JAMNA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721162,
      "TXT_PINCODE_LOCALITY": "CHAKNOR SINGHA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721163,
      "TXT_PINCODE_LOCALITY": "BRAHMANSASAN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721164,
      "TXT_PINCODE_LOCALITY": "ARJUNI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721165,
      "TXT_PINCODE_LOCALITY": "KHAR KUSMA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721166,
      "TXT_PINCODE_LOCALITY": "LUTUNIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721167,
      "TXT_PINCODE_LOCALITY": "PALPARA (CONTAI)",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721168,
      "TXT_PINCODE_LOCALITY": "SIDDHA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721169,
      "TXT_PINCODE_LOCALITY": "PATNA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721170,
      "TXT_PINCODE_LOCALITY": "SONAKHALI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721171,
      "TXT_PINCODE_LOCALITY": "TOWNSHIP",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721172,
      "TXT_PINCODE_LOCALITY": "NONAKURIBAZAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 441262,
      "City District Name": "EAST MIDNAPORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721201,
      "TXT_PINCODE_LOCALITY": "CHANDRAKONA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721211,
      "TXT_PINCODE_LOCALITY": "DASPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721212,
      "TXT_PINCODE_LOCALITY": "GHATAL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721222,
      "TXT_PINCODE_LOCALITY": "KHARAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721228,
      "TXT_PINCODE_LOCALITY": "MURAKATI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 443047,
      "City District Name": "GARBETA - II",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721232,
      "TXT_PINCODE_LOCALITY": "ALUI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 441223,
      "City District Name": "WEST MIDNAPORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721242,
      "TXT_PINCODE_LOCALITY": "RAMJIBANPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721247,
      "TXT_PINCODE_LOCALITY": "DURYA RADHABALLABHPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 443048,
      "City District Name": "KESHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721252,
      "TXT_PINCODE_LOCALITY": "SANATORIUM DIGRI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721253,
      "TXT_PINCODE_LOCALITY": "SAT BANKURA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721254,
      "TXT_PINCODE_LOCALITY": "JHAKRA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721256,
      "TXT_PINCODE_LOCALITY": "BIRSINGHA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721257,
      "TXT_PINCODE_LOCALITY": "NAYABASAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721258,
      "TXT_PINCODE_LOCALITY": "GUIDAHA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721259,
      "TXT_PINCODE_LOCALITY": "NOLBONA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721260,
      "TXT_PINCODE_LOCALITY": "GARHBETA-III",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444143,
      "City District Name": "PASCHIM MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721301,
      "TXT_PINCODE_LOCALITY": "KHARAGPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 441223,
      "City District Name": "WEST MIDNAPORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721302,
      "TXT_PINCODE_LOCALITY": "KHARAGPUR TECHNILOGY",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721303,
      "TXT_PINCODE_LOCALITY": "KALAIKUNDA A F",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721304,
      "TXT_PINCODE_LOCALITY": "NIMPURA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721305,
      "TXT_PINCODE_LOCALITY": "INDA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721306,
      "TXT_PINCODE_LOCALITY": "MATHURAKATI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721307,
      "TXT_PINCODE_LOCALITY": "OLD KHARGTPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721340,
      "TXT_PINCODE_LOCALITY": "HALUDBARI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721401,
      "TXT_PINCODE_LOCALITY": "CONTAI H O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721402,
      "TXT_PINCODE_LOCALITY": "EAST MIDNAPORE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 441262,
      "City District Name": "EAST MIDNAPORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721403,
      "TXT_PINCODE_LOCALITY": "EAST MIDNAPORE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 441262,
      "City District Name": "EAST MIDNAPORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721404,
      "TXT_PINCODE_LOCALITY": "EAST MIDNAPORE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 441262,
      "City District Name": "EAST MIDNAPORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721405,
      "TXT_PINCODE_LOCALITY": "EAST MIDNAPORE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 441262,
      "City District Name": "EAST MIDNAPORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721406,
      "TXT_PINCODE_LOCALITY": "EAST MIDNAPORE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 441262,
      "City District Name": "EAST MIDNAPORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721410,
      "TXT_PINCODE_LOCALITY": "TARAKESWAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721420,
      "TXT_PINCODE_LOCALITY": "BAITA BAZAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721421,
      "TXT_PINCODE_LOCALITY": "BAITA BAZAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721422,
      "TXT_PINCODE_LOCALITY": "BALIGHAI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721423,
      "TXT_PINCODE_LOCALITY": "BILISHAI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721424,
      "TXT_PINCODE_LOCALITY": "BELDA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721425,
      "TXT_PINCODE_LOCALITY": "BHUPATI NAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721426,
      "TXT_PINCODE_LOCALITY": "DANTAN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721427,
      "TXT_PINCODE_LOCALITY": "DEULBARH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721428,
      "TXT_PINCODE_LOCALITY": "DIGHA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721429,
      "TXT_PINCODE_LOCALITY": "EGRA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 450,
      "City District Name": "MIDNAPORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721430,
      "TXT_PINCODE_LOCALITY": "AJOYA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721431,
      "TXT_PINCODE_LOCALITY": "JANKA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721432,
      "TXT_PINCODE_LOCALITY": "KALAGACHIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721433,
      "TXT_PINCODE_LOCALITY": "KHALISHA BHANGA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721434,
      "TXT_PINCODE_LOCALITY": "MANGLAMARO",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721435,
      "TXT_PINCODE_LOCALITY": "MENKAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721436,
      "TXT_PINCODE_LOCALITY": "MONHANPUR (CONTAI)",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721437,
      "TXT_PINCODE_LOCALITY": "NARAYANGARH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721438,
      "TXT_PINCODE_LOCALITY": "PANCHETGARH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721439,
      "TXT_PINCODE_LOCALITY": "PATASPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721440,
      "TXT_PINCODE_LOCALITY": "PRATAPDIGHI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721441,
      "TXT_PINCODE_LOCALITY": "RAMNAGAR (CONTAI)",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721442,
      "TXT_PINCODE_LOCALITY": "BASANTIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721443,
      "TXT_PINCODE_LOCALITY": "JAHALDA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721444,
      "TXT_PINCODE_LOCALITY": "NACGINDA BAZAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721445,
      "TXT_PINCODE_LOCALITY": "KHAKURDA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721446,
      "TXT_PINCODE_LOCALITY": "SAGARESWAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721447,
      "TXT_PINCODE_LOCALITY": "PANCHROL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721448,
      "TXT_PINCODE_LOCALITY": "PANIPARUL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721449,
      "TXT_PINCODE_LOCALITY": "MARISDA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721450,
      "TXT_PINCODE_LOCALITY": "JUNEPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721451,
      "TXT_PINCODE_LOCALITY": "NEKURSINI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721452,
      "TXT_PINCODE_LOCALITY": "SATMILE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721453,
      "TXT_PINCODE_LOCALITY": "DEPAL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721454,
      "TXT_PINCODE_LOCALITY": "AMARSHI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721455,
      "TXT_PINCODE_LOCALITY": "CHAUKOLA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721456,
      "TXT_PINCODE_LOCALITY": "ARGOAL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721457,
      "TXT_PINCODE_LOCALITY": "GOMUNDA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721458,
      "TXT_PINCODE_LOCALITY": "PALPARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721459,
      "TXT_PINCODE_LOCALITY": "SONAKANIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721460,
      "TXT_PINCODE_LOCALITY": "JAMIRAPALGARH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721461,
      "TXT_PINCODE_LOCALITY": "KALINDI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721462,
      "TXT_PINCODE_LOCALITY": "BRAJABALLAVPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721463,
      "TXT_PINCODE_LOCALITY": "DADAN PATRABARH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721464,
      "TXT_PINCODE_LOCALITY": "DAKSHIN DAUKI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721465,
      "TXT_PINCODE_LOCALITY": "PAUSI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721466,
      "TXT_PINCODE_LOCALITY": "SAURI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721467,
      "TXT_PINCODE_LOCALITY": "DALPARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721501,
      "TXT_PINCODE_LOCALITY": "BELPAHARI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721502,
      "TXT_PINCODE_LOCALITY": "BINPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721503,
      "TXT_PINCODE_LOCALITY": "CHILKIGARH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721504,
      "TXT_PINCODE_LOCALITY": "DAHIJURI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721505,
      "TXT_PINCODE_LOCALITY": "GIDNI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721506,
      "TXT_PINCODE_LOCALITY": "GOPIBALLAVPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721507,
      "TXT_PINCODE_LOCALITY": "JHARGRAM H O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721513,
      "TXT_PINCODE_LOCALITY": "MANIKPARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721514,
      "TXT_PINCODE_LOCALITY": "SEVAYATAN SO",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 450,
      "City District Name": "MIDNAPORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721515,
      "TXT_PINCODE_LOCALITY": "SILDA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721516,
      "TXT_PINCODE_LOCALITY": "LALGARH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721517,
      "TXT_PINCODE_LOCALITY": "TOPSIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 441223,
      "City District Name": "WEST MIDNAPORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721518,
      "TXT_PINCODE_LOCALITY": "GARH SALBANI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721519,
      "TXT_PINCODE_LOCALITY": "ANDHARIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721577,
      "TXT_PINCODE_LOCALITY": "TOPSIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721579,
      "TXT_PINCODE_LOCALITY": "ANDHARIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721601,
      "TXT_PINCODE_LOCALITY": "MAHAMMADPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 441262,
      "City District Name": "EAST MIDNAPORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721602,
      "TXT_PINCODE_LOCALITY": "HALDIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 441262,
      "City District Name": "EAST MIDNAPORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721603,
      "TXT_PINCODE_LOCALITY": "GEONKHALI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721604,
      "TXT_PINCODE_LOCALITY": "HALDIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 441262,
      "City District Name": "EAST MIDNAPORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721605,
      "TXT_PINCODE_LOCALITY": "HALDIA PORT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721606,
      "TXT_PINCODE_LOCALITY": "HALDIA OIL REFINERY",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721607,
      "TXT_PINCODE_LOCALITY": "HALDIA TOWNSHIP",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721624,
      "TXT_PINCODE_LOCALITY": "EAST MIDNAPORE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 441262,
      "City District Name": "EAST MIDNAPORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721625,
      "TXT_PINCODE_LOCALITY": "HANSCHORA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721626,
      "TXT_PINCODE_LOCALITY": "KAJLAGARH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721627,
      "TXT_PINCODE_LOCALITY": "KELOMAL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721628,
      "TXT_PINCODE_LOCALITY": "MAHISHADAL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721629,
      "TXT_PINCODE_LOCALITY": "MOYNA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721630,
      "TXT_PINCODE_LOCALITY": "NAIKURI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721631,
      "TXT_PINCODE_LOCALITY": "NANDIGRAM",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721632,
      "TXT_PINCODE_LOCALITY": "NANDAKUMAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721633,
      "TXT_PINCODE_LOCALITY": "NARGHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721634,
      "TXT_PINCODE_LOCALITY": "RAGHUNATHBARI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721635,
      "TXT_PINCODE_LOCALITY": "SUTAHATA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721636,
      "TXT_PINCODE_LOCALITY": "TAMLUK H O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721637,
      "TXT_PINCODE_LOCALITY": "EAST MIDNAPORE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 441262,
      "City District Name": "EAST MIDNAPORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721638,
      "TXT_PINCODE_LOCALITY": "RAGHUBIR CHAK",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 443052,
      "City District Name": "PANSKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721641,
      "TXT_PINCODE_LOCALITY": "KALINDI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721642,
      "TXT_PINCODE_LOCALITY": "ARANGKIARANA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721643,
      "TXT_PINCODE_LOCALITY": "KHANCHI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721644,
      "TXT_PINCODE_LOCALITY": "PARAMANANDAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721645,
      "TXT_PINCODE_LOCALITY": "CHAITANYAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 425786,
      "City District Name": "PURBA MEDNIPORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721646,
      "TXT_PINCODE_LOCALITY": "TEKHALI BAZAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721647,
      "TXT_PINCODE_LOCALITY": "RAMCHANDRAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721648,
      "TXT_PINCODE_LOCALITY": "BYABATTARHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721649,
      "TXT_PINCODE_LOCALITY": "MIRIKPUR - 1584",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721650,
      "TXT_PINCODE_LOCALITY": "REAPARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721651,
      "TXT_PINCODE_LOCALITY": "SRIRAMPUR MIDNAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721652,
      "TXT_PINCODE_LOCALITY": "KUMARCHAK",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721653,
      "TXT_PINCODE_LOCALITY": "CHANSERPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721654,
      "TXT_PINCODE_LOCALITY": "LAKHYA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721655,
      "TXT_PINCODE_LOCALITY": "KISMAT BAJKUL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721656,
      "TXT_PINCODE_LOCALITY": "TERAPAKHIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721657,
      "TXT_PINCODE_LOCALITY": "DEBHOG",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 441262,
      "City District Name": "EAST MIDNAPORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721658,
      "TXT_PINCODE_LOCALITY": "KUKRAHATI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721659,
      "TXT_PINCODE_LOCALITY": "MATH CHANDIPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721660,
      "TXT_PINCODE_LOCALITY": "NATSAL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721661,
      "TXT_PINCODE_LOCALITY": "GOPINATHPUR TAMLUK",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721662,
      "TXT_PINCODE_LOCALITY": "TAJNAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721663,
      "TXT_PINCODE_LOCALITY": "NORGHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721664,
      "TXT_PINCODE_LOCALITY": "UTTAR SOUTAN CHAK",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721665,
      "TXT_PINCODE_LOCALITY": "KALYANCHAK",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721666,
      "TXT_PINCODE_LOCALITY": "CHAKDWIPA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721667,
      "TXT_PINCODE_LOCALITY": "PAICHAK",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721668,
      "TXT_PINCODE_LOCALITY": "DEMARIHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721669,
      "TXT_PINCODE_LOCALITY": "NARGHAT L/S",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721670,
      "TXT_PINCODE_LOCALITY": "DEULPOTA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 721807,
      "TXT_PINCODE_LOCALITY": "HALDIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 450,
      "City District Name": "MIDNAPORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722015,
      "TXT_PINCODE_LOCALITY": "MURSHIDABAD",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722101,
      "TXT_PINCODE_LOCALITY": "LALBAZAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722102,
      "TXT_PINCODE_LOCALITY": "PURULIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722107,
      "TXT_PINCODE_LOCALITY": "LAKSHMI SAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722110,
      "TXT_PINCODE_LOCALITY": "DHANSIMLA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722111,
      "TXT_PINCODE_LOCALITY": "DIPARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722112,
      "TXT_PINCODE_LOCALITY": "HADAL NARAYANPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722121,
      "TXT_PINCODE_LOCALITY": "BHEDUASOLE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722122,
      "TXT_PINCODE_LOCALITY": "RASIKGANJ",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722132,
      "TXT_PINCODE_LOCALITY": "CHATNA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722133,
      "TXT_PINCODE_LOCALITY": "INDPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722134,
      "TXT_PINCODE_LOCALITY": "GARH RAIPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722135,
      "TXT_PINCODE_LOCALITY": "GORABARI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722136,
      "TXT_PINCODE_LOCALITY": "INDPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722137,
      "TXT_PINCODE_LOCALITY": "JHANTI PAHARI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722138,
      "TXT_PINCODE_LOCALITY": "JOYPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722139,
      "TXT_PINCODE_LOCALITY": "KENJAKURA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722140,
      "TXT_PINCODE_LOCALITY": "KHATRA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722141,
      "TXT_PINCODE_LOCALITY": "KOTALPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722142,
      "TXT_PINCODE_LOCALITY": "MALIARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722143,
      "TXT_PINCODE_LOCALITY": "MEJIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722144,
      "TXT_PINCODE_LOCALITY": "ONDA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722145,
      "TXT_PINCODE_LOCALITY": "PEARDOLA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722146,
      "TXT_PINCODE_LOCALITY": "RAJAGRAM",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722147,
      "TXT_PINCODE_LOCALITY": "NISCHINTAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722148,
      "TXT_PINCODE_LOCALITY": "RANIBANDH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722149,
      "TXT_PINCODE_LOCALITY": "SAGAR BHANGA COLONY",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722150,
      "TXT_PINCODE_LOCALITY": "SARENGA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722151,
      "TXT_PINCODE_LOCALITY": "SIMLAPAL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722152,
      "TXT_PINCODE_LOCALITY": "TALDANGRA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722153,
      "TXT_PINCODE_LOCALITY": "TILURI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722154,
      "TXT_PINCODE_LOCALITY": "GALIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722155,
      "TXT_PINCODE_LOCALITY": "KESIAKOLE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722156,
      "TXT_PINCODE_LOCALITY": "PANCHMURA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722157,
      "TXT_PINCODE_LOCALITY": "BON RADHANAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722158,
      "TXT_PINCODE_LOCALITY": "SALTORA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722159,
      "TXT_PINCODE_LOCALITY": "KAMALPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722160,
      "TXT_PINCODE_LOCALITY": "LAKSHMI SAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722161,
      "TXT_PINCODE_LOCALITY": "ANUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722162,
      "TXT_PINCODE_LOCALITY": "MELERA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722163,
      "TXT_PINCODE_LOCALITY": "BIBORDA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722164,
      "TXT_PINCODE_LOCALITY": "BANKADAHA S.O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722165,
      "TXT_PINCODE_LOCALITY": "BALIARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722166,
      "TXT_PINCODE_LOCALITY": "BANASURIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722168,
      "TXT_PINCODE_LOCALITY": "GHUTGORIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722169,
      "TXT_PINCODE_LOCALITY": "GOBINDADHAM",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722170,
      "TXT_PINCODE_LOCALITY": "HELNA SUSUNIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722171,
      "TXT_PINCODE_LOCALITY": "HIRBANDH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722172,
      "TXT_PINCODE_LOCALITY": "JAGADALLA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722173,
      "TXT_PINCODE_LOCALITY": "JORDA S.O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722174,
      "TXT_PINCODE_LOCALITY": "LODNA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722175,
      "TXT_PINCODE_LOCALITY": "MANKANALI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722176,
      "TXT_PINCODE_LOCALITY": "NARRAH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722177,
      "TXT_PINCODE_LOCALITY": "NITYANANDAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722178,
      "TXT_PINCODE_LOCALITY": "PURANDAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722179,
      "TXT_PINCODE_LOCALITY": "RATANPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722180,
      "TXT_PINCODE_LOCALITY": "SANBANDHA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722181,
      "TXT_PINCODE_LOCALITY": "WEST-SANABANDH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722182,
      "TXT_PINCODE_LOCALITY": "SUSUNIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722183,
      "TXT_PINCODE_LOCALITY": "MTPS",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722201,
      "TXT_PINCODE_LOCALITY": "AKUI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722202,
      "TXT_PINCODE_LOCALITY": "BORJORA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722203,
      "TXT_PINCODE_LOCALITY": "BELIATORE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722204,
      "TXT_PINCODE_LOCALITY": "HATASURIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722205,
      "TXT_PINCODE_LOCALITY": "INDAS",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722206,
      "TXT_PINCODE_LOCALITY": "PATRASAYER",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722207,
      "TXT_PINCODE_LOCALITY": "SONAMUKHI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722208,
      "TXT_PINCODE_LOCALITY": "PAKHANNA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722209,
      "TXT_PINCODE_LOCALITY": "BONBIRSINGHA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722212,
      "TXT_PINCODE_LOCALITY": "MURA PARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 443053,
      "City District Name": "PATRASAYER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 722213,
      "TXT_PINCODE_LOCALITY": "JINDARU",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 425485,
      "City District Name": "PURULIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723101,
      "TXT_PINCODE_LOCALITY": "PURULIA H O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723102,
      "TXT_PINCODE_LOCALITY": "DULMINADIHA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723103,
      "TXT_PINCODE_LOCALITY": "NAMOPARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723104,
      "TXT_PINCODE_LOCALITY": "SAINIK SCHOOL (PURULIYA)",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723121,
      "TXT_PINCODE_LOCALITY": "ADRA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723123,
      "TXT_PINCODE_LOCALITY": "AJODHYA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723126,
      "TXT_PINCODE_LOCALITY": "ANARA R S",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723127,
      "TXT_PINCODE_LOCALITY": "BARABHU",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723128,
      "TXT_PINCODE_LOCALITY": "BALAKDIH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723129,
      "TXT_PINCODE_LOCALITY": "BUNDWAN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723130,
      "TXT_PINCODE_LOCALITY": "HURA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723131,
      "TXT_PINCODE_LOCALITY": "MANBAZAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723132,
      "TXT_PINCODE_LOCALITY": "PANCHAKOTRAJ",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723133,
      "TXT_PINCODE_LOCALITY": "RAGHUNATHPUR (PURULIA)",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723141,
      "TXT_PINCODE_LOCALITY": "AGAR MULDA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 443042,
      "City District Name": "BHAGAWANPUR - II",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723142,
      "TXT_PINCODE_LOCALITY": "RAMKANALI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 425485,
      "City District Name": "PURULIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723143,
      "TXT_PINCODE_LOCALITY": "RANGADIH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723144,
      "TXT_PINCODE_LOCALITY": "RAMCHANDRAPUR ASRAM",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723145,
      "TXT_PINCODE_LOCALITY": "SANTALDOH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723146,
      "TXT_PINCODE_LOCALITY": "SANTALDIH THERMAL PLANT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723147,
      "TXT_PINCODE_LOCALITY": "VIVEKANDANAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723148,
      "TXT_PINCODE_LOCALITY": "HUTMURA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723149,
      "TXT_PINCODE_LOCALITY": "MANGURIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723150,
      "TXT_PINCODE_LOCALITY": "NETURIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723151,
      "TXT_PINCODE_LOCALITY": "PUNCHA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723152,
      "TXT_PINCODE_LOCALITY": "BAGHMUNDI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723153,
      "TXT_PINCODE_LOCALITY": "KANTADIH - 3889",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723154,
      "TXT_PINCODE_LOCALITY": "SIRKABAD",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723155,
      "TXT_PINCODE_LOCALITY": "PARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723156,
      "TXT_PINCODE_LOCALITY": "MURADI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723157,
      "TXT_PINCODE_LOCALITY": "SANTURI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723158,
      "TXT_PINCODE_LOCALITY": "CHELYAMA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723159,
      "TXT_PINCODE_LOCALITY": "GARAPHUSRA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723160,
      "TXT_PINCODE_LOCALITY": "RAMKRISHNA SIKSHAPITH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723161,
      "TXT_PINCODE_LOCALITY": "BARGORIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723162,
      "TXT_PINCODE_LOCALITY": "DUBRA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723163,
      "TXT_PINCODE_LOCALITY": "CHHARRAH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723164,
      "TXT_PINCODE_LOCALITY": "KALAPATHAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723165,
      "TXT_PINCODE_LOCALITY": "LAKSHANPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723166,
      "TXT_PINCODE_LOCALITY": "ARRAH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723167,
      "TXT_PINCODE_LOCALITY": "LAGDA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723168,
      "TXT_PINCODE_LOCALITY": "MANIHARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723201,
      "TXT_PINCODE_LOCALITY": "GARHJAIPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723202,
      "TXT_PINCODE_LOCALITY": "JHALDA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723212,
      "TXT_PINCODE_LOCALITY": "TULIN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723213,
      "TXT_PINCODE_LOCALITY": "JIUDARU",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723214,
      "TXT_PINCODE_LOCALITY": "BEGUNKODAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723215,
      "TXT_PINCODE_LOCALITY": "LLOO",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723216,
      "TXT_PINCODE_LOCALITY": "ARSHA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723224,
      "TXT_PINCODE_LOCALITY": "BEGUNKODAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 723315,
      "TXT_PINCODE_LOCALITY": "ILOO",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 5219,
      "City District Name": "PURULIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 725101,
      "TXT_PINCODE_LOCALITY": "BOWBAZAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 725137,
      "TXT_PINCODE_LOCALITY": "BERUBARI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 725210,
      "TXT_PINCODE_LOCALITY": "BHANDANI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731101,
      "TXT_PINCODE_LOCALITY": "SURI COLLEGE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731102,
      "TXT_PINCODE_LOCALITY": "HATJAN BAZAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731103,
      "TXT_PINCODE_LOCALITY": "BARABAGAN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731104,
      "TXT_PINCODE_LOCALITY": "SODIPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731121,
      "TXT_PINCODE_LOCALITY": "ABINASHPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731122,
      "TXT_PINCODE_LOCALITY": "BARHRA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731123,
      "TXT_PINCODE_LOCALITY": "DUBRAJPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731124,
      "TXT_PINCODE_LOCALITY": "HETAMPUR RAJBATI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731125,
      "TXT_PINCODE_LOCALITY": "KHOYRASOLE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731126,
      "TXT_PINCODE_LOCALITY": "KARIDHYA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731127,
      "TXT_PINCODE_LOCALITY": "MOHAMMAD BAZAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731128,
      "TXT_PINCODE_LOCALITY": "GIRIDANGA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731129,
      "TXT_PINCODE_LOCALITY": "PURANDARPUR (BIRBHUM)",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731130,
      "TXT_PINCODE_LOCALITY": "RAJNAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731131,
      "TXT_PINCODE_LOCALITY": "TANTIPARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731132,
      "TXT_PINCODE_LOCALITY": "TOWNSHIP",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731133,
      "TXT_PINCODE_LOCALITY": "PANCHRAHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731134,
      "TXT_PINCODE_LOCALITY": "BAKRESWAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731135,
      "TXT_PINCODE_LOCALITY": "PANURIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731136,
      "TXT_PINCODE_LOCALITY": "BAIDYANATHPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731137,
      "TXT_PINCODE_LOCALITY": "NAGARI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731138,
      "TXT_PINCODE_LOCALITY": "PAIGARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731139,
      "TXT_PINCODE_LOCALITY": "PATADANGA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731140,
      "TXT_PINCODE_LOCALITY": "PANCHHIARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731141,
      "TXT_PINCODE_LOCALITY": "NABASAN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731142,
      "TXT_PINCODE_LOCALITY": "BHABANIPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731143,
      "TXT_PINCODE_LOCALITY": "GARGARIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731144,
      "TXT_PINCODE_LOCALITY": "BARALUNDA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731145,
      "TXT_PINCODE_LOCALITY": "DEUCHA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731146,
      "TXT_PINCODE_LOCALITY": "ITAGARIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731147,
      "TXT_PINCODE_LOCALITY": "BATIKAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731201,
      "TXT_PINCODE_LOCALITY": "ISWARPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731202,
      "TXT_PINCODE_LOCALITY": "BASWA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731203,
      "TXT_PINCODE_LOCALITY": "BHADRAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731204,
      "TXT_PINCODE_LOCALITY": "BOLPUR UKILPATTY",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731210,
      "TXT_PINCODE_LOCALITY": "AHMED NAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731213,
      "TXT_PINCODE_LOCALITY": "DASPALSA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731214,
      "TXT_PINCODE_LOCALITY": "LLAM BAZAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731215,
      "TXT_PINCODE_LOCALITY": "KHUJUTIPARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731216,
      "TXT_PINCODE_LOCALITY": "MALLARPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731217,
      "TXT_PINCODE_LOCALITY": "MARGRAM",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731218,
      "TXT_PINCODE_LOCALITY": "MAYURESWAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731219,
      "TXT_PINCODE_LOCALITY": "MURARAI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731220,
      "TXT_PINCODE_LOCALITY": "NALHATI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731221,
      "TXT_PINCODE_LOCALITY": "PAIKAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731222,
      "TXT_PINCODE_LOCALITY": "RAJGAON",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731223,
      "TXT_PINCODE_LOCALITY": "MUKUNDAPALLY",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731224,
      "TXT_PINCODE_LOCALITY": "RAMPURHAT RAILPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731226,
      "TXT_PINCODE_LOCALITY": "BHABANIPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731233,
      "TXT_PINCODE_LOCALITY": "CHANDIPUR TARAPITH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731234,
      "TXT_PINCODE_LOCALITY": "SAINTHIA BAZAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731235,
      "TXT_PINCODE_LOCALITY": "SANTINIKETAN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731236,
      "TXT_PINCODE_LOCALITY": "SRINIKETAN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731237,
      "TXT_PINCODE_LOCALITY": "LOHAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731238,
      "TXT_PINCODE_LOCALITY": "CHATRA BIRBHUM",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731239,
      "TXT_PINCODE_LOCALITY": "NARAYANPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731240,
      "TXT_PINCODE_LOCALITY": "BAHIRI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731241,
      "TXT_PINCODE_LOCALITY": "CHANDPARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731242,
      "TXT_PINCODE_LOCALITY": "KURUMGRAM",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731243,
      "TXT_PINCODE_LOCALITY": "NALHATI TOWNSHIP",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731244,
      "TXT_PINCODE_LOCALITY": "BISHNUPUR BIRBHUM",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731245,
      "TXT_PINCODE_LOCALITY": "RAMPURHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731246,
      "TXT_PINCODE_LOCALITY": "KUNDALA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731247,
      "TXT_PINCODE_LOCALITY": "CHAWHATTA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731248,
      "TXT_PINCODE_LOCALITY": "BIRBHUM",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731249,
      "TXT_PINCODE_LOCALITY": "BHATRA BHABANIPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731250,
      "TXT_PINCODE_LOCALITY": "MAHULA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731251,
      "TXT_PINCODE_LOCALITY": "BAUTIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731252,
      "TXT_PINCODE_LOCALITY": "KUMURI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731253,
      "TXT_PINCODE_LOCALITY": "BAGDOLA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731254,
      "TXT_PINCODE_LOCALITY": "KOTASUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731255,
      "TXT_PINCODE_LOCALITY": "PANCHSOWA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731284,
      "TXT_PINCODE_LOCALITY": "HATIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731301,
      "TXT_PINCODE_LOCALITY": "NANOOR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731302,
      "TXT_PINCODE_LOCALITY": "KIRMAHAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731303,
      "TXT_PINCODE_LOCALITY": "LABPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731304,
      "TXT_PINCODE_LOCALITY": "BAGDOWARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 731424,
      "TXT_PINCODE_LOCALITY": "BURAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732101,
      "TXT_PINCODE_LOCALITY": "MANGALBARI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732102,
      "TXT_PINCODE_LOCALITY": "JHALJHALIA RLY COLONY",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732103,
      "TXT_PINCODE_LOCALITY": "MUKDUMPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732104,
      "TXT_PINCODE_LOCALITY": "BANSBERIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732120,
      "TXT_PINCODE_LOCALITY": "BUDHIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732121,
      "TXT_PINCODE_LOCALITY": "AIHO",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732122,
      "TXT_PINCODE_LOCALITY": "BULBULCHANDI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732123,
      "TXT_PINCODE_LOCALITY": "CHANCHAL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732124,
      "TXT_PINCODE_LOCALITY": "GAJOL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732125,
      "TXT_PINCODE_LOCALITY": "HARISHCHANDRAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732126,
      "TXT_PINCODE_LOCALITY": "KOLIGAON",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732127,
      "TXT_PINCODE_LOCALITY": "KHEJURIAGHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732128,
      "TXT_PINCODE_LOCALITY": "OLD MALDA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732138,
      "TXT_PINCODE_LOCALITY": "PAKUAHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732139,
      "TXT_PINCODE_LOCALITY": "SAMSI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732140,
      "TXT_PINCODE_LOCALITY": "TULSIHATA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732141,
      "TXT_PINCODE_LOCALITY": "NARAYANPUR (MALDA)",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732142,
      "TXT_PINCODE_LOCALITY": "MANGALBARI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732143,
      "TXT_PINCODE_LOCALITY": "ARAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732144,
      "TXT_PINCODE_LOCALITY": "KOTWALI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732145,
      "TXT_PINCODE_LOCALITY": "HABIBPUR MALDA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732146,
      "TXT_PINCODE_LOCALITY": "KUTUBASAHAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732147,
      "TXT_PINCODE_LOCALITY": "BAMANGOLA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732148,
      "TXT_PINCODE_LOCALITY": "SINGABAD",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732149,
      "TXT_PINCODE_LOCALITY": "MALATIPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732150,
      "TXT_PINCODE_LOCALITY": "KHARDAH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732151,
      "TXT_PINCODE_LOCALITY": "BARDUARY",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732152,
      "TXT_PINCODE_LOCALITY": "KUSHIDA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732153,
      "TXT_PINCODE_LOCALITY": "AGRA HARISHCHANDRAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732154,
      "TXT_PINCODE_LOCALITY": "BHATOL CHANDIPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732155,
      "TXT_PINCODE_LOCALITY": "MIAHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732156,
      "TXT_PINCODE_LOCALITY": "MILANGARH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732157,
      "TXT_PINCODE_LOCALITY": "DAULAT NAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732158,
      "TXT_PINCODE_LOCALITY": "DEBIGANJ",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732159,
      "TXT_PINCODE_LOCALITY": "NEW SADLICHAK",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732160,
      "TXT_PINCODE_LOCALITY": "SULTANNAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732161,
      "TXT_PINCODE_LOCALITY": "SINGIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732162,
      "TXT_PINCODE_LOCALITY": "PATKABARI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732163,
      "TXT_PINCODE_LOCALITY": "CHOROLMANI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732201,
      "TXT_PINCODE_LOCALITY": "KALIACHAK",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732202,
      "TXT_PINCODE_LOCALITY": "MANIKCHAK",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732203,
      "TXT_PINCODE_LOCALITY": "MATHURAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732204,
      "TXT_PINCODE_LOCALITY": "PARANPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732205,
      "TXT_PINCODE_LOCALITY": "RATUA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732206,
      "TXT_PINCODE_LOCALITY": "SUJAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732207,
      "TXT_PINCODE_LOCALITY": "MOTHABARI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732208,
      "TXT_PINCODE_LOCALITY": "AMRITY",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732209,
      "TXT_PINCODE_LOCALITY": "SOVANAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732210,
      "TXT_PINCODE_LOCALITY": "BISHNAB NAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732211,
      "TXT_PINCODE_LOCALITY": "MILKI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732212,
      "TXT_PINCODE_LOCALITY": "JADUPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732213,
      "TXT_PINCODE_LOCALITY": "PAKHURIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732214,
      "TXT_PINCODE_LOCALITY": "ARAIDANGA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732215,
      "TXT_PINCODE_LOCALITY": "PUBARUN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 732216,
      "TXT_PINCODE_LOCALITY": "AKANDABARIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733101,
      "TXT_PINCODE_LOCALITY": "BALURGHAT H O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 424310,
      "City District Name": "SOUTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733102,
      "TXT_PINCODE_LOCALITY": "CHAKVRIGU",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 424310,
      "City District Name": "SOUTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733103,
      "TXT_PINCODE_LOCALITY": "BELTALAPARK",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 424310,
      "City District Name": "SOUTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733121,
      "TXT_PINCODE_LOCALITY": "BUNIADPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 424310,
      "City District Name": "SOUTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733123,
      "TXT_PINCODE_LOCALITY": "DEBI NAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 424310,
      "City District Name": "SOUTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733124,
      "TXT_PINCODE_LOCALITY": "GANGARAMPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 424310,
      "City District Name": "SOUTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733125,
      "TXT_PINCODE_LOCALITY": "HARIRAMPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 424310,
      "City District Name": "SOUTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733126,
      "TXT_PINCODE_LOCALITY": "HILI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 424310,
      "City District Name": "SOUTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733127,
      "TXT_PINCODE_LOCALITY": "TAPAN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 424310,
      "City District Name": "SOUTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733128,
      "TXT_PINCODE_LOCALITY": "KAPASIA NOMUNIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1872,
      "City District Name": "UTTARDINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733129,
      "TXT_PINCODE_LOCALITY": "N.S. ROAD",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1872,
      "City District Name": "UTTARDINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733130,
      "TXT_PINCODE_LOCALITY": "KARANJORA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 424310,
      "City District Name": "SOUTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733131,
      "TXT_PINCODE_LOCALITY": "KUMARGANJ",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 424310,
      "City District Name": "SOUTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733132,
      "TXT_PINCODE_LOCALITY": "KUSMANDI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 424310,
      "City District Name": "SOUTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733133,
      "TXT_PINCODE_LOCALITY": "PATIRAM",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 424310,
      "City District Name": "SOUTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733134,
      "TXT_PINCODE_LOCALITY": "RAIGANJ - 3936",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 424310,
      "City District Name": "SOUTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733140,
      "TXT_PINCODE_LOCALITY": "RAMPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 424310,
      "City District Name": "SOUTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733141,
      "TXT_PINCODE_LOCALITY": "GOPALGANJ",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 424310,
      "City District Name": "SOUTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733142,
      "TXT_PINCODE_LOCALITY": "NARAYANBAZAR (BALURGHAT)",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 424310,
      "City District Name": "SOUTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733143,
      "TXT_PINCODE_LOCALITY": "BHUPALPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 424310,
      "City District Name": "SOUTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733144,
      "TXT_PINCODE_LOCALITY": "HEMMATABAD",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 424310,
      "City District Name": "SOUTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733145,
      "TXT_PINCODE_LOCALITY": "TEOR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 424310,
      "City District Name": "SOUTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733146,
      "TXT_PINCODE_LOCALITY": "DAULATPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 424310,
      "City District Name": "SOUTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733147,
      "TXT_PINCODE_LOCALITY": "BANSHIBARI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 424310,
      "City District Name": "SOUTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733148,
      "TXT_PINCODE_LOCALITY": "CHAMPATALI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 424310,
      "City District Name": "SOUTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733149,
      "TXT_PINCODE_LOCALITY": "KARDAH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 424310,
      "City District Name": "SOUTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733150,
      "TXT_PINCODE_LOCALITY": "MAJHOIR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 424310,
      "City District Name": "SOUTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733151,
      "TXT_PINCODE_LOCALITY": "THAKU PUKURHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 424310,
      "City District Name": "SOUTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733152,
      "TXT_PINCODE_LOCALITY": "KUNIOR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 424310,
      "City District Name": "SOUTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733153,
      "TXT_PINCODE_LOCALITY": "KAMARPARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 424310,
      "City District Name": "SOUTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733154,
      "TXT_PINCODE_LOCALITY": "BOLLA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 424310,
      "City District Name": "SOUTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733155,
      "TXT_PINCODE_LOCALITY": "TRIMOHINI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 424310,
      "City District Name": "SOUTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733156,
      "TXT_PINCODE_LOCALITY": "BINDOLE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 424310,
      "City District Name": "SOUTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733157,
      "TXT_PINCODE_LOCALITY": "BAHIN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 424310,
      "City District Name": "SOUTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733158,
      "TXT_PINCODE_LOCALITY": "BOLLA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 424310,
      "City District Name": "SOUTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733165,
      "TXT_PINCODE_LOCALITY": "COOCHBEHAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1395,
      "City District Name": "COOCHBEHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733167,
      "TXT_PINCODE_LOCALITY": "COOCHBEHAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1395,
      "City District Name": "COOCHBEHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733168,
      "TXT_PINCODE_LOCALITY": "COOCHBEHAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1395,
      "City District Name": "COOCHBEHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733201,
      "TXT_PINCODE_LOCALITY": "DALKHOLA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1872,
      "City District Name": "UTTARDINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733202,
      "TXT_PINCODE_LOCALITY": "ISLAMPUR COURT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 424310,
      "City District Name": "SOUTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733207,
      "TXT_PINCODE_LOCALITY": "RAMGANJ",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 424310,
      "City District Name": "SOUTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733208,
      "TXT_PINCODE_LOCALITY": "THAKURBARI B.O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1872,
      "City District Name": "UTTARDINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733209,
      "TXT_PINCODE_LOCALITY": "CHAKULIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1872,
      "City District Name": "UTTARDINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733210,
      "TXT_PINCODE_LOCALITY": "BAHAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 441045,
      "City District Name": "NORTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733211,
      "TXT_PINCODE_LOCALITY": "CHAKULIAHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 424310,
      "City District Name": "SOUTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733212,
      "TXT_PINCODE_LOCALITY": "RASHAKHWAHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 424310,
      "City District Name": "SOUTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733213,
      "TXT_PINCODE_LOCALITY": "DHANTOLA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 424310,
      "City District Name": "SOUTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733214,
      "TXT_PINCODE_LOCALITY": "SONARPURHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 424310,
      "City District Name": "SOUTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733215,
      "TXT_PINCODE_LOCALITY": "KARANDIGHI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 441045,
      "City District Name": "NORTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733216,
      "TXT_PINCODE_LOCALITY": "CHOPRA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 424310,
      "City District Name": "SOUTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733246,
      "TXT_PINCODE_LOCALITY": "RANKHANPARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 443035,
      "City District Name": "BANSIHARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 733252,
      "TXT_PINCODE_LOCALITY": "RASHIDPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3692,
      "City District Name": "KALIAGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734001,
      "TXT_PINCODE_LOCALITY": "BURDWAN ROAD",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734002,
      "TXT_PINCODE_LOCALITY": "SHALBARI CHHAT DWITIYA KHANDA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1636,
      "City District Name": "KURSEONG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734003,
      "TXT_PINCODE_LOCALITY": "SILIGURI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734004,
      "TXT_PINCODE_LOCALITY": "DARJILING",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734005,
      "TXT_PINCODE_LOCALITY": "MANGAL PANDEY ROAD",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734006,
      "TXT_PINCODE_LOCALITY": "HAIDARPARA B.O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734007,
      "TXT_PINCODE_LOCALITY": "BHAKTINAGAR S.O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734008,
      "TXT_PINCODE_LOCALITY": "SALUGARAH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734009,
      "TXT_PINCODE_LOCALITY": "SUKNA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734010,
      "TXT_PINCODE_LOCALITY": "MATIGARA S.O.",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734011,
      "TXT_PINCODE_LOCALITY": "KADAMTALA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734012,
      "TXT_PINCODE_LOCALITY": "SUSHRUT NAGAR S.O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734013,
      "TXT_PINCODE_LOCALITY": "NEW RANGIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734014,
      "TXT_PINCODE_LOCALITY": "PHANSIDEWA S.O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 443,
      "City District Name": "DARJEELING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734015,
      "TXT_PINCODE_LOCALITY": "MAHANANDA PROJECT S.O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734101,
      "TXT_PINCODE_LOCALITY": "DARJEELING HO",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734102,
      "TXT_PINCODE_LOCALITY": "GHOOM",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734103,
      "TXT_PINCODE_LOCALITY": "NORTH POINT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734104,
      "TXT_PINCODE_LOCALITY": "JALAPAHAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734105,
      "TXT_PINCODE_LOCALITY": "LEBONG",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734121,
      "TXT_PINCODE_LOCALITY": "DOOTERIAH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734122,
      "TXT_PINCODE_LOCALITY": "RANGBULL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734123,
      "TXT_PINCODE_LOCALITY": "RANGBULL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734124,
      "TXT_PINCODE_LOCALITY": "TUKVAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734201,
      "TXT_PINCODE_LOCALITY": "BIJANBARI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734203,
      "TXT_PINCODE_LOCALITY": "SILIGURI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 443,
      "City District Name": "DARJEELING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734204,
      "TXT_PINCODE_LOCALITY": "DOW HILL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734209,
      "TXT_PINCODE_LOCALITY": "SONADA S.O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734213,
      "TXT_PINCODE_LOCALITY": "LOPCHU",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734214,
      "TXT_PINCODE_LOCALITY": "MIRIK",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734215,
      "TXT_PINCODE_LOCALITY": "NAGRISPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734216,
      "TXT_PINCODE_LOCALITY": "POKHRIABONG",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734217,
      "TXT_PINCODE_LOCALITY": "PANKHABARI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734218,
      "TXT_PINCODE_LOCALITY": "PHUGURI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734219,
      "TXT_PINCODE_LOCALITY": "SONADA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734220,
      "TXT_PINCODE_LOCALITY": "ST MARYS HILL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734221,
      "TXT_PINCODE_LOCALITY": "SUKHIA POKHRI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734222,
      "TXT_PINCODE_LOCALITY": "TAKDAH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734223,
      "TXT_PINCODE_LOCALITY": "TINDHARIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734224,
      "TXT_PINCODE_LOCALITY": "TUNG",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734225,
      "TXT_PINCODE_LOCALITY": "SUKNA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734226,
      "TXT_PINCODE_LOCALITY": "RANGLI RANGLIOT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734227,
      "TXT_PINCODE_LOCALITY": "SOURENI BAZAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734301,
      "TXT_PINCODE_LOCALITY": "KALIMPONG",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734311,
      "TXT_PINCODE_LOCALITY": "PEDONG",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734312,
      "TXT_PINCODE_LOCALITY": "TISTA BRIDGE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734313,
      "TXT_PINCODE_LOCALITY": "MANGPOO",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734314,
      "TXT_PINCODE_LOCALITY": "ALGARAH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734315,
      "TXT_PINCODE_LOCALITY": "MUSSUNG",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734316,
      "TXT_PINCODE_LOCALITY": "TOPKHANA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734317,
      "TXT_PINCODE_LOCALITY": "ECHHEY",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734319,
      "TXT_PINCODE_LOCALITY": "LAVA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734320,
      "TXT_PINCODE_LOCALITY": "KALIJHORA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734321,
      "TXT_PINCODE_LOCALITY": "REANG S.O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734401,
      "TXT_PINCODE_LOCALITY": "SILIGURI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 436,
      "City District Name": "SILIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734403,
      "TXT_PINCODE_LOCALITY": "PRADHANNAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 436,
      "City District Name": "SILIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734404,
      "TXT_PINCODE_LOCALITY": "SILIGURI TOWN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734405,
      "TXT_PINCODE_LOCALITY": "SILIGURI BAZAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734406,
      "TXT_PINCODE_LOCALITY": "RABINDRA SARANI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734421,
      "TXT_PINCODE_LOCALITY": "BAGDOGRA AIR PORT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734422,
      "TXT_PINCODE_LOCALITY": "BAGDOGRA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734423,
      "TXT_PINCODE_LOCALITY": "BELGACHI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734424,
      "TXT_PINCODE_LOCALITY": "BENGDUBI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734425,
      "TXT_PINCODE_LOCALITY": "BHAKTI NAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734426,
      "TXT_PINCODE_LOCALITY": "KAMALABAGAN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734427,
      "TXT_PINCODE_LOCALITY": "KHARIBARI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734428,
      "TXT_PINCODE_LOCALITY": "MATIGARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734429,
      "TXT_PINCODE_LOCALITY": "NAKSALBARI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734430,
      "TXT_PINCODE_LOCALITY": "NORTH BENGAL UNIVERSITY",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734431,
      "TXT_PINCODE_LOCALITY": "SAHABAD",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734432,
      "TXT_PINCODE_LOCALITY": "SUSHRUTANAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734433,
      "TXT_PINCODE_LOCALITY": "KADAMTALA (DARJEELING)",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734434,
      "TXT_PINCODE_LOCALITY": "PHANSIDEWA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734435,
      "TXT_PINCODE_LOCALITY": "MAHANADA PROJECT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734436,
      "TXT_PINCODE_LOCALITY": "CHATHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734501,
      "TXT_PINCODE_LOCALITY": "BAGRAKOTE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734502,
      "TXT_PINCODE_LOCALITY": "GAIRIBAS",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 734503,
      "TXT_PINCODE_LOCALITY": "TODAYGAON",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2889,
      "City District Name": "DARJILING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735101,
      "TXT_PINCODE_LOCALITY": "RAJHARIPARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735102,
      "TXT_PINCODE_LOCALITY": "ENGINEERING COLLEGE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735121,
      "TXT_PINCODE_LOCALITY": "DANGUAJHAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735122,
      "TXT_PINCODE_LOCALITY": "HALDIBARI B. O.",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1395,
      "City District Name": "COOCHBEHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735132,
      "TXT_PINCODE_LOCALITY": "PANDAPRA KALIBARI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735133,
      "TXT_PINCODE_LOCALITY": "PRASANNANAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735134,
      "TXT_PINCODE_LOCALITY": "RAJGANJ",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735135,
      "TXT_PINCODE_LOCALITY": "KAMARVITA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735136,
      "TXT_PINCODE_LOCALITY": "KHARIKA BERUBARI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735137,
      "TXT_PINCODE_LOCALITY": "BERUBARI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735138,
      "TXT_PINCODE_LOCALITY": "CHAULHATI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735139,
      "TXT_PINCODE_LOCALITY": "MANDALGHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735140,
      "TXT_PINCODE_LOCALITY": "GORALBARI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735152,
      "TXT_PINCODE_LOCALITY": "RAIGANJ TOWN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 441014,
      "City District Name": "WEST DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735201,
      "TXT_PINCODE_LOCALITY": "AMBARI BAGAN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735202,
      "TXT_PINCODE_LOCALITY": "BANARHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735203,
      "TXT_PINCODE_LOCALITY": "BINNAGURI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735204,
      "TXT_PINCODE_LOCALITY": "BIRPARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735205,
      "TXT_PINCODE_LOCALITY": "CARRON",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735206,
      "TXT_PINCODE_LOCALITY": "CHALSA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735207,
      "TXT_PINCODE_LOCALITY": "CHAMURCHI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735208,
      "TXT_PINCODE_LOCALITY": "DALSINGPARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735209,
      "TXT_PINCODE_LOCALITY": "DAM DIM",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735210,
      "TXT_PINCODE_LOCALITY": "DHUPGRUI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735211,
      "TXT_PINCODE_LOCALITY": "ATPUKURIA NAYAHAT B.O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1395,
      "City District Name": "COOCHBEHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735212,
      "TXT_PINCODE_LOCALITY": "GAIRKATA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735213,
      "TXT_PINCODE_LOCALITY": "GOPAL BAGAN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735214,
      "TXT_PINCODE_LOCALITY": "HAMILTONGANJ",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735215,
      "TXT_PINCODE_LOCALITY": "HASIMARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735216,
      "TXT_PINCODE_LOCALITY": "JATESWAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735217,
      "TXT_PINCODE_LOCALITY": "KALCHINI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735218,
      "TXT_PINCODE_LOCALITY": "KRANTIHAT S.O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735219,
      "TXT_PINCODE_LOCALITY": "LATAGURI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735220,
      "TXT_PINCODE_LOCALITY": "MADARIHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735221,
      "TXT_PINCODE_LOCALITY": "TAIGOLE BAZAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735222,
      "TXT_PINCODE_LOCALITY": "MANABARI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735223,
      "TXT_PINCODE_LOCALITY": "MATEHI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735224,
      "TXT_PINCODE_LOCALITY": "MAYNAGURI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735225,
      "TXT_PINCODE_LOCALITY": "NAGRAKATA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735226,
      "TXT_PINCODE_LOCALITY": "PANABASTI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735227,
      "TXT_PINCODE_LOCALITY": "RAJA BHAT KHAWA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735228,
      "TXT_PINCODE_LOCALITY": "RAMJHORA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735229,
      "TXT_PINCODE_LOCALITY": "SALLIHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735230,
      "TXT_PINCODE_LOCALITY": "BARADIGHI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735231,
      "TXT_PINCODE_LOCALITY": "FAGU",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735232,
      "TXT_PINCODE_LOCALITY": "CANTONMENT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735233,
      "TXT_PINCODE_LOCALITY": "NATHUAHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735234,
      "TXT_PINCODE_LOCALITY": "GAJALDOBA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735235,
      "TXT_PINCODE_LOCALITY": "JALPESH MONDIR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735236,
      "TXT_PINCODE_LOCALITY": "RANIRHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735237,
      "TXT_PINCODE_LOCALITY": "SAJNAPARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735238,
      "TXT_PINCODE_LOCALITY": "RUNGAMATTI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735251,
      "TXT_PINCODE_LOCALITY": "SUKHANIBASTI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 443051,
      "City District Name": "NAGRAKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735301,
      "TXT_PINCODE_LOCALITY": "CHANGRABANDHA S.O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1395,
      "City District Name": "COOCHBEHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735302,
      "TXT_PINCODE_LOCALITY": "DOMOHANI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735303,
      "TXT_PINCODE_LOCALITY": "JAMALDAH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735304,
      "TXT_PINCODE_LOCALITY": "MEKLIGANJ - 3047",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4017,
      "City District Name": "KOCH BIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735305,
      "TXT_PINCODE_LOCALITY": "BHOTEPATTI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735306,
      "TXT_PINCODE_LOCALITY": "HELAPAKRI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735307,
      "TXT_PINCODE_LOCALITY": "DHARAMPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735322,
      "TXT_PINCODE_LOCALITY": "BINNAGURI CANTT.",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735501,
      "TXT_PINCODE_LOCALITY": "JALPAIGURI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 735505,
      "TXT_PINCODE_LOCALITY": "JALDHAKA HYDEL PROJECT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736101,
      "TXT_PINCODE_LOCALITY": "BONGCHATRA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1395,
      "City District Name": "COOCHBEHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736102,
      "TXT_PINCODE_LOCALITY": "COOCH BEHAR NEW TOWN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4017,
      "City District Name": "KOCH BIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736118,
      "TXT_PINCODE_LOCALITY": "KHAGRABARI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1395,
      "City District Name": "COOCHBEHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736121,
      "TXT_PINCODE_LOCALITY": "ALIPURDUAR NEW TOWN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736122,
      "TXT_PINCODE_LOCALITY": "GHAGRA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736123,
      "TXT_PINCODE_LOCALITY": "BHOLADABRI - 3669",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736125,
      "TXT_PINCODE_LOCALITY": "ALIPURDUAR JUNCTION",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4017,
      "City District Name": "KOCH BIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736130,
      "TXT_PINCODE_LOCALITY": "BAKSHADUAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4017,
      "City District Name": "KOCH BIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736131,
      "TXT_PINCODE_LOCALITY": "BAKSHIRHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1395,
      "City District Name": "COOCHBEHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736132,
      "TXT_PINCODE_LOCALITY": "BALAIRHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4017,
      "City District Name": "KOCH BIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736133,
      "TXT_PINCODE_LOCALITY": "BANESWAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1395,
      "City District Name": "COOCHBEHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736134,
      "TXT_PINCODE_LOCALITY": "DEWANHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1395,
      "City District Name": "COOCHBEHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736135,
      "TXT_PINCODE_LOCALITY": "DINHATA BAZAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1395,
      "City District Name": "COOCHBEHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736145,
      "TXT_PINCODE_LOCALITY": "GOSSANIMARI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1395,
      "City District Name": "COOCHBEHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736146,
      "TXT_PINCODE_LOCALITY": "MATHABHANGA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1395,
      "City District Name": "COOCHBEHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736150,
      "TXT_PINCODE_LOCALITY": "NIGAMNAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4017,
      "City District Name": "KOCH BIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736156,
      "TXT_PINCODE_LOCALITY": "NIMPITH RAMKRISHNA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1395,
      "City District Name": "COOCHBEHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736157,
      "TXT_PINCODE_LOCALITY": "BAIRATI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1395,
      "City District Name": "COOCHBEHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736158,
      "TXT_PINCODE_LOCALITY": "SITALKUCHI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1395,
      "City District Name": "COOCHBEHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736159,
      "TXT_PINCODE_LOCALITY": "TUFANGANJ",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1395,
      "City District Name": "COOCHBEHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736160,
      "TXT_PINCODE_LOCALITY": "TUFANGANJ NEW TOWN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1395,
      "City District Name": "COOCHBEHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736165,
      "TXT_PINCODE_LOCALITY": "PUNDIBARI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1395,
      "City District Name": "COOCHBEHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736166,
      "TXT_PINCODE_LOCALITY": "CHOWDHURI HAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4017,
      "City District Name": "KOCH BIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736167,
      "TXT_PINCODE_LOCALITY": "SITAIHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1395,
      "City District Name": "COOCHBEHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736168,
      "TXT_PINCODE_LOCALITY": "BAMANHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4017,
      "City District Name": "KOCH BIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736169,
      "TXT_PINCODE_LOCALITY": "TIADAHA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1395,
      "City District Name": "COOCHBEHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736170,
      "TXT_PINCODE_LOCALITY": "GHUGUMARI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1395,
      "City District Name": "COOCHBEHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736171,
      "TXT_PINCODE_LOCALITY": "GHOKSHADANGA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1395,
      "City District Name": "COOCHBEHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736172,
      "TXT_PINCODE_LOCALITY": "GOSSAIRHAT BANDAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4017,
      "City District Name": "KOCH BIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736173,
      "TXT_PINCODE_LOCALITY": "BALKANDI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4017,
      "City District Name": "KOCH BIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736174,
      "TXT_PINCODE_LOCALITY": "BARANICHINA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4017,
      "City District Name": "KOCH BIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736175,
      "TXT_PINCODE_LOCALITY": "GITALDAHA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1395,
      "City District Name": "COOCHBEHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736176,
      "TXT_PINCODE_LOCALITY": "CHILAKHANA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4017,
      "City District Name": "KOCH BIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736177,
      "TXT_PINCODE_LOCALITY": "CHILAKHANA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1395,
      "City District Name": "COOCHBEHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736178,
      "TXT_PINCODE_LOCALITY": "KHAGARBARI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4017,
      "City District Name": "KOCH BIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736179,
      "TXT_PINCODE_LOCALITY": "MAHISH BATHAN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1395,
      "City District Name": "COOCHBEHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736180,
      "TXT_PINCODE_LOCALITY": "TAKAGACHH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1395,
      "City District Name": "COOCHBEHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736181,
      "TXT_PINCODE_LOCALITY": "TAPURHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1395,
      "City District Name": "COOCHBEHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736182,
      "TXT_PINCODE_LOCALITY": "JAIGOAN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736201,
      "TXT_PINCODE_LOCALITY": "HATIPOTHA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736202,
      "TXT_PINCODE_LOCALITY": "KAMASKHYAGURI S.O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736203,
      "TXT_PINCODE_LOCALITY": "NEW LANDS",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4017,
      "City District Name": "KOCH BIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736204,
      "TXT_PINCODE_LOCALITY": "JOGENDRANAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736205,
      "TXT_PINCODE_LOCALITY": "KUMERGRAMDUAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736206,
      "TXT_PINCODE_LOCALITY": "SAONTALPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4017,
      "City District Name": "KOCH BIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736207,
      "TXT_PINCODE_LOCALITY": "BAROBISHA S. O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 6294,
      "City District Name": "ALIPURDUAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736208,
      "TXT_PINCODE_LOCALITY": "SALSALA BARI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 736257,
      "TXT_PINCODE_LOCALITY": "RENISHIGANJ",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4017,
      "City District Name": "KOCH BIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 737101,
      "TXT_PINCODE_LOCALITY": "GANGTOK",
      "NUM_STATE_CD": 154,
      "State Name": "SIKKIM",
      "NUM_CITYDISTRICT_CD": 441367,
      "City District Name": "EAST DISTRICT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 737102,
      "TXT_PINCODE_LOCALITY": "TODONG",
      "NUM_STATE_CD": 154,
      "State Name": "SIKKIM",
      "NUM_CITYDISTRICT_CD": 424441,
      "City District Name": "EAST SIKKIM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 737103,
      "TXT_PINCODE_LOCALITY": "RAJ BHAVAN",
      "NUM_STATE_CD": 154,
      "State Name": "SIKKIM",
      "NUM_CITYDISTRICT_CD": 424441,
      "City District Name": "EAST SIKKIM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 737106,
      "TXT_PINCODE_LOCALITY": "PAKYONG P.O",
      "NUM_STATE_CD": 154,
      "State Name": "SIKKIM",
      "NUM_CITYDISTRICT_CD": 424441,
      "City District Name": "EAST SIKKIM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 737107,
      "TXT_PINCODE_LOCALITY": "TINTEK",
      "NUM_STATE_CD": 154,
      "State Name": "SIKKIM",
      "NUM_CITYDISTRICT_CD": 3192,
      "City District Name": "GANGTOK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 737111,
      "TXT_PINCODE_LOCALITY": "GAYZING",
      "NUM_STATE_CD": 154,
      "State Name": "SIKKIM",
      "NUM_CITYDISTRICT_CD": 1888,
      "City District Name": "WEST SIKKIM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 737112,
      "TXT_PINCODE_LOCALITY": "DENTAM",
      "NUM_STATE_CD": 154,
      "State Name": "SIKKIM",
      "NUM_CITYDISTRICT_CD": 1888,
      "City District Name": "WEST SIKKIM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 737113,
      "TXT_PINCODE_LOCALITY": "PELLING",
      "NUM_STATE_CD": 154,
      "State Name": "SIKKIM",
      "NUM_CITYDISTRICT_CD": 424441,
      "City District Name": "EAST SIKKIM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 737114,
      "TXT_PINCODE_LOCALITY": "KALUK BAZAR",
      "NUM_STATE_CD": 154,
      "State Name": "SIKKIM",
      "NUM_CITYDISTRICT_CD": 424441,
      "City District Name": "EAST SIKKIM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 737115,
      "TXT_PINCODE_LOCALITY": "JORETHANG",
      "NUM_STATE_CD": 154,
      "State Name": "SIKKIM",
      "NUM_CITYDISTRICT_CD": 1888,
      "City District Name": "WEST SIKKIM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 737116,
      "TXT_PINCODE_LOCALITY": "MAGAN",
      "NUM_STATE_CD": 154,
      "State Name": "SIKKIM",
      "NUM_CITYDISTRICT_CD": 424439,
      "City District Name": "NORTH SIKKIM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 737117,
      "TXT_PINCODE_LOCALITY": "KABI",
      "NUM_STATE_CD": 154,
      "State Name": "SIKKIM",
      "NUM_CITYDISTRICT_CD": 424439,
      "City District Name": "NORTH SIKKIM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 737118,
      "TXT_PINCODE_LOCALITY": "PHENSONG",
      "NUM_STATE_CD": 154,
      "State Name": "SIKKIM",
      "NUM_CITYDISTRICT_CD": 424441,
      "City District Name": "EAST SIKKIM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 737119,
      "TXT_PINCODE_LOCALITY": "PHODONG",
      "NUM_STATE_CD": 154,
      "State Name": "SIKKIM",
      "NUM_CITYDISTRICT_CD": 424439,
      "City District Name": "NORTH SIKKIM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 737120,
      "TXT_PINCODE_LOCALITY": "YUMTHANG",
      "NUM_STATE_CD": 154,
      "State Name": "SIKKIM",
      "NUM_CITYDISTRICT_CD": 442968,
      "City District Name": "CHUNGTHANG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 737121,
      "TXT_PINCODE_LOCALITY": "NAYABAZAR (SIKKIM)",
      "NUM_STATE_CD": 154,
      "State Name": "SIKKIM",
      "NUM_CITYDISTRICT_CD": 1888,
      "City District Name": "WEST SIKKIM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 737122,
      "TXT_PINCODE_LOCALITY": "SOMBARAY",
      "NUM_STATE_CD": 154,
      "State Name": "SIKKIM",
      "NUM_CITYDISTRICT_CD": 1888,
      "City District Name": "WEST SIKKIM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 737126,
      "TXT_PINCODE_LOCALITY": "NAMCHI",
      "NUM_STATE_CD": 154,
      "State Name": "SIKKIM",
      "NUM_CITYDISTRICT_CD": 424438,
      "City District Name": "SOUTH DISTRICT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 737127,
      "TXT_PINCODE_LOCALITY": "CHAKUNG",
      "NUM_STATE_CD": 154,
      "State Name": "SIKKIM",
      "NUM_CITYDISTRICT_CD": 1888,
      "City District Name": "WEST SIKKIM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 737128,
      "TXT_PINCODE_LOCALITY": "MALLIBAZAR",
      "NUM_STATE_CD": 154,
      "State Name": "SIKKIM",
      "NUM_CITYDISTRICT_CD": 424438,
      "City District Name": "SOUTH DISTRICT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 737131,
      "TXT_PINCODE_LOCALITY": "RANGLU BAZAR",
      "NUM_STATE_CD": 154,
      "State Name": "SIKKIM",
      "NUM_CITYDISTRICT_CD": 424438,
      "City District Name": "SOUTH DISTRICT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 737132,
      "TXT_PINCODE_LOCALITY": "RANGPO",
      "NUM_STATE_CD": 154,
      "State Name": "SIKKIM",
      "NUM_CITYDISTRICT_CD": 424441,
      "City District Name": "EAST SIKKIM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 737133,
      "TXT_PINCODE_LOCALITY": "RHENOCK",
      "NUM_STATE_CD": 154,
      "State Name": "SIKKIM",
      "NUM_CITYDISTRICT_CD": 424441,
      "City District Name": "EAST SIKKIM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 737134,
      "TXT_PINCODE_LOCALITY": "SINGTAM - 4477",
      "NUM_STATE_CD": 154,
      "State Name": "SIKKIM",
      "NUM_CITYDISTRICT_CD": 424441,
      "City District Name": "EAST SIKKIM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 737135,
      "TXT_PINCODE_LOCALITY": "RANIPOOL",
      "NUM_STATE_CD": 154,
      "State Name": "SIKKIM",
      "NUM_CITYDISTRICT_CD": 424441,
      "City District Name": "EAST SIKKIM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 737136,
      "TXT_PINCODE_LOCALITY": "DAIN THONG",
      "NUM_STATE_CD": 154,
      "State Name": "SIKKIM",
      "NUM_CITYDISTRICT_CD": 424438,
      "City District Name": "SOUTH DISTRICT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 737137,
      "TXT_PINCODE_LOCALITY": "NAMTHUNG",
      "NUM_STATE_CD": 154,
      "State Name": "SIKKIM",
      "NUM_CITYDISTRICT_CD": 424441,
      "City District Name": "EAST SIKKIM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 737138,
      "TXT_PINCODE_LOCALITY": "MAKHA S.O",
      "NUM_STATE_CD": 154,
      "State Name": "SIKKIM",
      "NUM_CITYDISTRICT_CD": 424441,
      "City District Name": "EAST SIKKIM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 737139,
      "TXT_PINCODE_LOCALITY": "RAVANGLA",
      "NUM_STATE_CD": 154,
      "State Name": "SIKKIM",
      "NUM_CITYDISTRICT_CD": 425832,
      "City District Name": "SOUTH SIKKIM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 738039,
      "TXT_PINCODE_LOCALITY": "PAIKMAL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1371,
      "City District Name": "BARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741101,
      "TXT_PINCODE_LOCALITY": "GOLAPATI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 444,
      "City District Name": "HOOGHLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741102,
      "TXT_PINCODE_LOCALITY": "SAKTINAGA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741103,
      "TXT_PINCODE_LOCALITY": "GHURNI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741104,
      "TXT_PINCODE_LOCALITY": "RADHANAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741111,
      "TXT_PINCODE_LOCALITY": "CHAKDAHA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4482,
      "City District Name": "MEDINIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741117,
      "TXT_PINCODE_LOCALITY": "ALUIPARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741121,
      "TXT_PINCODE_LOCALITY": "BADKULLA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741122,
      "TXT_PINCODE_LOCALITY": "BEGCHI JAMSHORPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741123,
      "TXT_PINCODE_LOCALITY": "BANGAL JHEE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741124,
      "TXT_PINCODE_LOCALITY": "BARA ANDULIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741125,
      "TXT_PINCODE_LOCALITY": "BELPUKUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741126,
      "TXT_PINCODE_LOCALITY": "BETHUDAHARI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741127,
      "TXT_PINCODE_LOCALITY": "BIR NAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741137,
      "TXT_PINCODE_LOCALITY": "DEBAGRAM",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741138,
      "TXT_PINCODE_LOCALITY": "DHARMADA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741139,
      "TXT_PINCODE_LOCALITY": "DHUBULIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741140,
      "TXT_PINCODE_LOCALITY": "DHUBULIA T B HOSPITAL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741150,
      "TXT_PINCODE_LOCALITY": "KALIGANJ",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741151,
      "TXT_PINCODE_LOCALITY": "DAKSHINPARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741152,
      "TXT_PINCODE_LOCALITY": "KARIMPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741153,
      "TXT_PINCODE_LOCALITY": "MATIARI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741154,
      "TXT_PINCODE_LOCALITY": "MURAGACHHA S.O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741155,
      "TXT_PINCODE_LOCALITY": "PALASHIPARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741156,
      "TXT_PINCODE_LOCALITY": "PLASSEY",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741157,
      "TXT_PINCODE_LOCALITY": "PLASSEY SUGAR MILL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741158,
      "TXT_PINCODE_LOCALITY": "SHIKARPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741159,
      "TXT_PINCODE_LOCALITY": "TAHERPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741160,
      "TXT_PINCODE_LOCALITY": "TEHATTA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741161,
      "TXT_PINCODE_LOCALITY": "ASANNAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741162,
      "TXT_PINCODE_LOCALITY": "KASIADANGA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741163,
      "TXT_PINCODE_LOCALITY": "BETAI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741164,
      "TXT_PINCODE_LOCALITY": "DOYET BAZAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741165,
      "TXT_PINCODE_LOCALITY": "NAZIPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741166,
      "TXT_PINCODE_LOCALITY": "BALLADANGA FARIDPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741167,
      "TXT_PINCODE_LOCALITY": "BHIMPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741168,
      "TXT_PINCODE_LOCALITY": "BARNIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741169,
      "TXT_PINCODE_LOCALITY": "CHANDERGHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741170,
      "TXT_PINCODE_LOCALITY": "KULGACHI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741171,
      "TXT_PINCODE_LOCALITY": "DINGYE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741172,
      "TXT_PINCODE_LOCALITY": "KECHUADANGA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741173,
      "TXT_PINCODE_LOCALITY": "HANSPUKURIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741174,
      "TXT_PINCODE_LOCALITY": "JURANPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741175,
      "TXT_PINCODE_LOCALITY": "MAHATPUT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741176,
      "TXT_PINCODE_LOCALITY": "BARA ATAGI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741177,
      "TXT_PINCODE_LOCALITY": "DHAWAPARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741178,
      "TXT_PINCODE_LOCALITY": "SAPJOLA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741179,
      "TXT_PINCODE_LOCALITY": "SHYAMNAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741180,
      "TXT_PINCODE_LOCALITY": "KAMARI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741181,
      "TXT_PINCODE_LOCALITY": "PAGLACHANDI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741182,
      "TXT_PINCODE_LOCALITY": "SONADANGA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741183,
      "TXT_PINCODE_LOCALITY": "BAHADURPUR NADIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741184,
      "TXT_PINCODE_LOCALITY": "KALIRHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741185,
      "TXT_PINCODE_LOCALITY": "DOGACHI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741186,
      "TXT_PINCODE_LOCALITY": "AMIYANARAYANPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741187,
      "TXT_PINCODE_LOCALITY": "BALIADANGA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741188,
      "TXT_PINCODE_LOCALITY": "GOBRAPOTA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741189,
      "TXT_PINCODE_LOCALITY": "HARIPUR CHITKA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741190,
      "TXT_PINCODE_LOCALITY": "RANABANDH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741191,
      "TXT_PINCODE_LOCALITY": "SHIBPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741192,
      "TXT_PINCODE_LOCALITY": "DAKSHIN BAHIRGACHI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741193,
      "TXT_PINCODE_LOCALITY": "TALUKHUDA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741194,
      "TXT_PINCODE_LOCALITY": "ASACHIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741195,
      "TXT_PINCODE_LOCALITY": "ARPARA - 2613",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741196,
      "TXT_PINCODE_LOCALITY": "BARACHANDGHAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741197,
      "TXT_PINCODE_LOCALITY": "BILLAGARAM",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741198,
      "TXT_PINCODE_LOCALITY": "GOPINATHPUR NADIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741199,
      "TXT_PINCODE_LOCALITY": "HARA NAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741201,
      "TXT_PINCODE_LOCALITY": "RANAGHAT H O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741202,
      "TXT_PINCODE_LOCALITY": "NASRA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741203,
      "TXT_PINCODE_LOCALITY": "NOKARI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 443055,
      "City District Name": "RANAGHAT - II",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741221,
      "TXT_PINCODE_LOCALITY": "BARAJAGULIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741222,
      "TXT_PINCODE_LOCALITY": "CHAKADAHA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741223,
      "TXT_PINCODE_LOCALITY": "PURBABISHNUPUR S.O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741224,
      "TXT_PINCODE_LOCALITY": "PRIYANAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 443043,
      "City District Name": "CHAKDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741225,
      "TXT_PINCODE_LOCALITY": "HARINGHATA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741232,
      "TXT_PINCODE_LOCALITY": "COOPERS CAMP",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741233,
      "TXT_PINCODE_LOCALITY": "GANGNAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741234,
      "TXT_PINCODE_LOCALITY": "GAYESHPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741235,
      "TXT_PINCODE_LOCALITY": "KALYANI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741236,
      "TXT_PINCODE_LOCALITY": "KALYANI UNIVERSITY",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741238,
      "TXT_PINCODE_LOCALITY": "GANGAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741243,
      "TXT_PINCODE_LOCALITY": "GAYESHPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741245,
      "TXT_PINCODE_LOCALITY": "MADANPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741246,
      "TXT_PINCODE_LOCALITY": "MOHANPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741247,
      "TXT_PINCODE_LOCALITY": "PRITINAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741248,
      "TXT_PINCODE_LOCALITY": "SIMURALI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741249,
      "TXT_PINCODE_LOCALITY": "BALINDI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741250,
      "TXT_PINCODE_LOCALITY": "KATAGANJ",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741251,
      "TXT_PINCODE_LOCALITY": "KANCHARAPARA T B HOSP",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741252,
      "TXT_PINCODE_LOCALITY": "VIDYALAYA MOHANPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741253,
      "TXT_PINCODE_LOCALITY": "ANISHMALI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741254,
      "TXT_PINCODE_LOCALITY": "KALINARAYANPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741255,
      "TXT_PINCODE_LOCALITY": "ANULIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741256,
      "TXT_PINCODE_LOCALITY": "BEGOPARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741257,
      "TXT_PINCODE_LOCALITY": "NADIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741258,
      "TXT_PINCODE_LOCALITY": "NAWPARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741301,
      "TXT_PINCODE_LOCALITY": "CHARBRAHMA NAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741302,
      "TXT_PINCODE_LOCALITY": "NABADWIP H O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741312,
      "TXT_PINCODE_LOCALITY": "NADANGHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741313,
      "TXT_PINCODE_LOCALITY": "SREEMAYAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741314,
      "TXT_PINCODE_LOCALITY": "SAMUDRAGARH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741315,
      "TXT_PINCODE_LOCALITY": "SWARUPGANJ",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741316,
      "TXT_PINCODE_LOCALITY": "SRI RAMPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741317,
      "TXT_PINCODE_LOCALITY": "JOANIA-BHALUKA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741318,
      "TXT_PINCODE_LOCALITY": "AMGHATA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741319,
      "TXT_PINCODE_LOCALITY": "BIDYANAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2347,
      "City District Name": "BARDDHAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741401,
      "TXT_PINCODE_LOCALITY": "DIG NAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741402,
      "TXT_PINCODE_LOCALITY": "BELEMATH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741403,
      "TXT_PINCODE_LOCALITY": "HABIBPUR RANAGHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741404,
      "TXT_PINCODE_LOCALITY": "SANTIPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741405,
      "TXT_PINCODE_LOCALITY": "KADAMPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 457,
      "City District Name": "SANTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741501,
      "TXT_PINCODE_LOCALITY": "ARANGHATA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741502,
      "TXT_PINCODE_LOCALITY": "BAGULA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741503,
      "TXT_PINCODE_LOCALITY": "BANPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741504,
      "TXT_PINCODE_LOCALITY": "DUTTAPULIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741505,
      "TXT_PINCODE_LOCALITY": "HANSKHALI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741506,
      "TXT_PINCODE_LOCALITY": "KRISHNAGANJ",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741507,
      "TXT_PINCODE_LOCALITY": "MAJDIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741508,
      "TXT_PINCODE_LOCALITY": "KHALBOAHIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741509,
      "TXT_PINCODE_LOCALITY": "CHOWGACHA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741510,
      "TXT_PINCODE_LOCALITY": "TAKSHALI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 443054,
      "City District Name": "RANAGHAT - I",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741511,
      "TXT_PINCODE_LOCALITY": "SHIKRI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 443055,
      "City District Name": "RANAGHAT - II",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741512,
      "TXT_PINCODE_LOCALITY": "KANAKHOLA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 457,
      "City District Name": "SANTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741514,
      "TXT_PINCODE_LOCALITY": "NALUPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 443049,
      "City District Name": "KRISHNAGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741516,
      "TXT_PINCODE_LOCALITY": "TEGHARI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 443049,
      "City District Name": "KRISHNAGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 741555,
      "TXT_PINCODE_LOCALITY": "KRISHNANAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4111,
      "City District Name": "KRISHNANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742000,
      "TXT_PINCODE_LOCALITY": "CHAK DIHIGANESPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 443050,
      "City District Name": "LALGOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742101,
      "TXT_PINCODE_LOCALITY": "BERHAMPORE H O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742102,
      "TXT_PINCODE_LOCALITY": "COSSIM BAZAR RAJ",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742103,
      "TXT_PINCODE_LOCALITY": "KHAGRA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742104,
      "TXT_PINCODE_LOCALITY": "ETORE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742109,
      "TXT_PINCODE_LOCALITY": "BARUA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742113,
      "TXT_PINCODE_LOCALITY": "AMDAHARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742121,
      "TXT_PINCODE_LOCALITY": "AMTALA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742122,
      "TXT_PINCODE_LOCALITY": "GHORSALA - 1568",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742123,
      "TXT_PINCODE_LOCALITY": "JIAGANJ",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742124,
      "TXT_PINCODE_LOCALITY": "DEBIPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742131,
      "TXT_PINCODE_LOCALITY": "SIBPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4642,
      "City District Name": "NABAGRAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742132,
      "TXT_PINCODE_LOCALITY": "EKGHARIAKHANGUA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742133,
      "TXT_PINCODE_LOCALITY": "MIRZAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742134,
      "TXT_PINCODE_LOCALITY": "BHABTA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742135,
      "TXT_PINCODE_LOCALITY": "HABASPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742136,
      "TXT_PINCODE_LOCALITY": "HAZARPUR NABAGRAM",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742137,
      "TXT_PINCODE_LOCALITY": "KANDI H O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742138,
      "TXT_PINCODE_LOCALITY": "RASORAH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742139,
      "TXT_PINCODE_LOCALITY": "RASORAH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742140,
      "TXT_PINCODE_LOCALITY": "JEMO RAJBATI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742147,
      "TXT_PINCODE_LOCALITY": "KHARGRAM",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742148,
      "TXT_PINCODE_LOCALITY": "LALGOLA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742149,
      "TXT_PINCODE_LOCALITY": "KURMITALA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742150,
      "TXT_PINCODE_LOCALITY": "KURMITOLA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742151,
      "TXT_PINCODE_LOCALITY": "RAJARAMPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742157,
      "TXT_PINCODE_LOCALITY": "BHALKUNDDI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742159,
      "TXT_PINCODE_LOCALITY": "BHALKUNDDI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742160,
      "TXT_PINCODE_LOCALITY": "NASHIPUR RAJBATI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742161,
      "TXT_PINCODE_LOCALITY": "PANCHTHUPI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742162,
      "TXT_PINCODE_LOCALITY": "MURSHIDABAD S. O.",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742163,
      "TXT_PINCODE_LOCALITY": "SAKTIPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742164,
      "TXT_PINCODE_LOCALITY": "ROSANBAGH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742165,
      "TXT_PINCODE_LOCALITY": "BARUIPARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742166,
      "TXT_PINCODE_LOCALITY": "CHOA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742167,
      "TXT_PINCODE_LOCALITY": "GOPLILAM",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742168,
      "TXT_PINCODE_LOCALITY": "KULI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742169,
      "TXT_PINCODE_LOCALITY": "NASHIPUR BALAGACHI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742170,
      "TXT_PINCODE_LOCALITY": "SATITARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742171,
      "TXT_PINCODE_LOCALITY": "PURANDARPUR (MSD)",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742172,
      "TXT_PINCODE_LOCALITY": "MAHADEBBATI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742173,
      "TXT_PINCODE_LOCALITY": "EKGHARIA KHANGNA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742174,
      "TXT_PINCODE_LOCALITY": "SARBANGAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742175,
      "TXT_PINCODE_LOCALITY": "SABDARNAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742176,
      "TXT_PINCODE_LOCALITY": "MADDA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742177,
      "TXT_PINCODE_LOCALITY": "HAREKNAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742178,
      "TXT_PINCODE_LOCALITY": "MAHISASTHALI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742179,
      "TXT_PINCODE_LOCALITY": "HABASPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742180,
      "TXT_PINCODE_LOCALITY": "MAHALANDI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742181,
      "TXT_PINCODE_LOCALITY": "HAZARPUR NABAGRAM",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742182,
      "TXT_PINCODE_LOCALITY": "MURSHIDABAD",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742183,
      "TXT_PINCODE_LOCALITY": "NOADA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742184,
      "TXT_PINCODE_LOCALITY": "NABAGRAM (MSD)",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742185,
      "TXT_PINCODE_LOCALITY": "KRISHNAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742186,
      "TXT_PINCODE_LOCALITY": "GREAMSALIKA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742187,
      "TXT_PINCODE_LOCALITY": "GOALJAH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742188,
      "TXT_PINCODE_LOCALITY": "GOALJAN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742189,
      "TXT_PINCODE_LOCALITY": "KASHIPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742190,
      "TXT_PINCODE_LOCALITY": "MIRZAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742191,
      "TXT_PINCODE_LOCALITY": "KASHIPUR S. O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742201,
      "TXT_PINCODE_LOCALITY": "AURANGABAD",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742202,
      "TXT_PINCODE_LOCALITY": "KANKURIA (DHULIAN)",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742204,
      "TXT_PINCODE_LOCALITY": "ISLAMPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 424310,
      "City District Name": "SOUTH DINAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742212,
      "TXT_PINCODE_LOCALITY": "FARAKKA BARRAGE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742213,
      "TXT_PINCODE_LOCALITY": "KHODARAMPUR (JANGIPUR)",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742220,
      "TXT_PINCODE_LOCALITY": "TENTULIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4642,
      "City District Name": "NABAGRAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742223,
      "TXT_PINCODE_LOCALITY": "AHIRAN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742224,
      "TXT_PINCODE_LOCALITY": "JAGTAJ",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742225,
      "TXT_PINCODE_LOCALITY": "RAGHUNATHGANJ HO.",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742226,
      "TXT_PINCODE_LOCALITY": "PUNDI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742227,
      "TXT_PINCODE_LOCALITY": "GANKER",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742228,
      "TXT_PINCODE_LOCALITY": "PUNDI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742229,
      "TXT_PINCODE_LOCALITY": "GHORSALA - 1568",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742230,
      "TXT_PINCODE_LOCALITY": "GIRIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742231,
      "TXT_PINCODE_LOCALITY": "ARUNPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742232,
      "TXT_PINCODE_LOCALITY": "MAHADEBNAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742233,
      "TXT_PINCODE_LOCALITY": "KULI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742234,
      "TXT_PINCODE_LOCALITY": "AHIRAN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742235,
      "TXT_PINCODE_LOCALITY": "DAKSHINGRAM",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742236,
      "TXT_PINCODE_LOCALITY": "NABARUN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742237,
      "TXT_PINCODE_LOCALITY": "MANIGRAM",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742238,
      "TXT_PINCODE_LOCALITY": "PURBA GRAM",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4642,
      "City District Name": "NABAGRAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742286,
      "TXT_PINCODE_LOCALITY": "NASHIPUR  LALGOLA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742301,
      "TXT_PINCODE_LOCALITY": "BHARATPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742302,
      "TXT_PINCODE_LOCALITY": "DAULTABAD",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742303,
      "TXT_PINCODE_LOCALITY": "DUMKAL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742304,
      "TXT_PINCODE_LOCALITY": "ISLAMPUR MSD",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742305,
      "TXT_PINCODE_LOCALITY": "JALANGI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742306,
      "TXT_PINCODE_LOCALITY": "SAGARPARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742307,
      "TXT_PINCODE_LOCALITY": "CHHOYGHARI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742308,
      "TXT_PINCODE_LOCALITY": "RANINAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742401,
      "TXT_PINCODE_LOCALITY": "SLAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742402,
      "TXT_PINCODE_LOCALITY": "TALIBPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742403,
      "TXT_PINCODE_LOCALITY": "KAGRAM",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742404,
      "TXT_PINCODE_LOCALITY": "TENYA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742405,
      "TXT_PINCODE_LOCALITY": "SATUI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742406,
      "TXT_PINCODE_LOCALITY": "BASANTPUR S.O (MURSHIDABAD)",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742407,
      "TXT_PINCODE_LOCALITY": "CHALTIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 906,
      "City District Name": "BERHAMPORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742408,
      "TXT_PINCODE_LOCALITY": "MANKARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 906,
      "City District Name": "BERHAMPORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742409,
      "TXT_PINCODE_LOCALITY": "RANINAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742410,
      "TXT_PINCODE_LOCALITY": "MURSHIDABAD",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742463,
      "TXT_PINCODE_LOCALITY": "BERHAMPORE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 906,
      "City District Name": "BERHAMPORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 742840,
      "TXT_PINCODE_LOCALITY": "KANNAUJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3765,
      "City District Name": "KANNAUJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743101,
      "TXT_PINCODE_LOCALITY": "SHYAMNAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 440711,
      "City District Name": "24 PARGANA( NORTH)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743102,
      "TXT_PINCODE_LOCALITY": "NANACHANDANPUKUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743120,
      "TXT_PINCODE_LOCALITY": "NILGANJ BAZAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743121,
      "TXT_PINCODE_LOCALITY": "BALARAM DHARMA SOPAN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743122,
      "TXT_PINCODE_LOCALITY": "BENGAL ENAMEL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743123,
      "TXT_PINCODE_LOCALITY": "BHATOLI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743124,
      "TXT_PINCODE_LOCALITY": "E S D MACHINERY",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743125,
      "TXT_PINCODE_LOCALITY": "JAGATDAL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743126,
      "TXT_PINCODE_LOCALITY": "KUSHDANGA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743127,
      "TXT_PINCODE_LOCALITY": "SHYAMNAGAR (24PARGANAS) - 768",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743128,
      "TXT_PINCODE_LOCALITY": "ATHPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743129,
      "TXT_PINCODE_LOCALITY": "FINGAPARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743130,
      "TXT_PINCODE_LOCALITY": "DOGACHIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1754,
      "City District Name": "24 PARGANAS (S)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743133,
      "TXT_PINCODE_LOCALITY": "GURULIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743134,
      "TXT_PINCODE_LOCALITY": "HALISAHAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743135,
      "TXT_PINCODE_LOCALITY": "LALKUTHI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743136,
      "TXT_PINCODE_LOCALITY": "NABANAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 440711,
      "City District Name": "24 PARGANA( NORTH)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743144,
      "TXT_PINCODE_LOCALITY": "ICHAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743145,
      "TXT_PINCODE_LOCALITY": "KANCHRAPARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743150,
      "TXT_PINCODE_LOCALITY": "PANSILA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 4641,
      "City District Name": "NABADWIP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743155,
      "TXT_PINCODE_LOCALITY": "KHARDAH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743164,
      "TXT_PINCODE_LOCALITY": "NAIHATI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743165,
      "TXT_PINCODE_LOCALITY": "NAIHATI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743166,
      "TXT_PINCODE_LOCALITY": "RAMGHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743170,
      "TXT_PINCODE_LOCALITY": "GHOLA BAZAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743175,
      "TXT_PINCODE_LOCALITY": "NATAGARH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743176,
      "TXT_PINCODE_LOCALITY": "PANIHATI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743177,
      "TXT_PINCODE_LOCALITY": "AGARPARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743178,
      "TXT_PINCODE_LOCALITY": "RAMCHANDRAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743179,
      "TXT_PINCODE_LOCALITY": "SUKCHAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743180,
      "TXT_PINCODE_LOCALITY": "PANSILA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743186,
      "TXT_PINCODE_LOCALITY": "RAHARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743187,
      "TXT_PINCODE_LOCALITY": "TALUPUKUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743188,
      "TXT_PINCODE_LOCALITY": "TILAGARH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743193,
      "TXT_PINCODE_LOCALITY": "KAMPA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743194,
      "TXT_PINCODE_LOCALITY": "RAMLAKHI BASTI LANE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743195,
      "TXT_PINCODE_LOCALITY": "PALASHI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 443038,
      "City District Name": "BARRACKPUR - I",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743201,
      "TXT_PINCODE_LOCALITY": "BARASAT H O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743202,
      "TXT_PINCODE_LOCALITY": "NOANPARA BARASAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743203,
      "TXT_PINCODE_LOCALITY": "NAVAPALLI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743204,
      "TXT_PINCODE_LOCALITY": "HRIDAYPER",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743221,
      "TXT_PINCODE_LOCALITY": "ARKHALI AMDANGA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743222,
      "TXT_PINCODE_LOCALITY": "ASHOK NAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743223,
      "TXT_PINCODE_LOCALITY": "HARIPUR NORTH 24 PARGANAS",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 440,
      "City District Name": "BARASAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743232,
      "TXT_PINCODE_LOCALITY": "BAGDAH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743233,
      "TXT_PINCODE_LOCALITY": "BANIPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743234,
      "TXT_PINCODE_LOCALITY": "BERABALLAVPARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743235,
      "TXT_PINCODE_LOCALITY": "BONGAON",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743244,
      "TXT_PINCODE_LOCALITY": "MONDALPARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1870,
      "City District Name": "GAIGHATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743245,
      "TXT_PINCODE_LOCALITY": "CHAND PARA BAZAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743246,
      "TXT_PINCODE_LOCALITY": "DHAKURIA KALIBARI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743247,
      "TXT_PINCODE_LOCALITY": "DAKSHIN CHATRA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743248,
      "TXT_PINCODE_LOCALITY": "DUTTAPUKUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743249,
      "TXT_PINCODE_LOCALITY": "GAIGHATA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743250,
      "TXT_PINCODE_LOCALITY": "GANGANAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743251,
      "TXT_PINCODE_LOCALITY": "GANRAPOTA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743252,
      "TXT_PINCODE_LOCALITY": "GOBARDANGA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743262,
      "TXT_PINCODE_LOCALITY": "GOPAL NAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743263,
      "TXT_PINCODE_LOCALITY": "HABRA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743268,
      "TXT_PINCODE_LOCALITY": "HARBA PRAFULLA NAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743269,
      "TXT_PINCODE_LOCALITY": "HATTHUBA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743270,
      "TXT_PINCODE_LOCALITY": "HELENCHA COLONY",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743271,
      "TXT_PINCODE_LOCALITY": "HIJAL PUKURIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743272,
      "TXT_PINCODE_LOCALITY": "KALYAN DURG BAZAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743273,
      "TXT_PINCODE_LOCALITY": "KHANTURA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743274,
      "TXT_PINCODE_LOCALITY": "KUMRA SASHIPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743275,
      "TXT_PINCODE_LOCALITY": "MADHYAMGRAM",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743276,
      "TXT_PINCODE_LOCALITY": "NEW BARRACKPORE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743286,
      "TXT_PINCODE_LOCALITY": "SWARUPNAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743287,
      "TXT_PINCODE_LOCALITY": "THAKUR NAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743288,
      "TXT_PINCODE_LOCALITY": "JANAPHUL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743289,
      "TXT_PINCODE_LOCALITY": "MASLANDAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743290,
      "TXT_PINCODE_LOCALITY": "NAHTA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743291,
      "TXT_PINCODE_LOCALITY": "CHAMPAUKUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743292,
      "TXT_PINCODE_LOCALITY": "ITINDA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743293,
      "TXT_PINCODE_LOCALITY": "JADURHATI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743294,
      "TXT_PINCODE_LOCALITY": "CHOTOJAGULIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743295,
      "TXT_PINCODE_LOCALITY": "SABDALPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743296,
      "TXT_PINCODE_LOCALITY": "MECHEALNAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743297,
      "TXT_PINCODE_LOCALITY": "SINDRANI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743298,
      "TXT_PINCODE_LOCALITY": "MADHYAM GRAM BAZAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743299,
      "TXT_PINCODE_LOCALITY": "BITHARI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743301,
      "TXT_PINCODE_LOCALITY": "ACHIPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743302,
      "TXT_PINCODE_LOCALITY": "BARUIPUR H O",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743304,
      "TXT_PINCODE_LOCALITY": "PIPULAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 443044,
      "City District Name": "DIAMOND HARBOUR - II",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743312,
      "TXT_PINCODE_LOCALITY": "BASANTI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743313,
      "TXT_PINCODE_LOCALITY": "BATANAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743316,
      "TXT_PINCODE_LOCALITY": "HEROBHANGA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743318,
      "TXT_PINCODE_LOCALITY": "BIRLAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743319,
      "TXT_PINCODE_LOCALITY": "BUDGE BUDGE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743329,
      "TXT_PINCODE_LOCALITY": "CANNING TOWN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743330,
      "TXT_PINCODE_LOCALITY": "CHAMPAHATI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743331,
      "TXT_PINCODE_LOCALITY": "DIMOND HARBOUR HO.",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743332,
      "TXT_PINCODE_LOCALITY": "HATUGANJ",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743336,
      "TXT_PINCODE_LOCALITY": "GHATESWAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743337,
      "TXT_PINCODE_LOCALITY": "TELEPARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743338,
      "TXT_PINCODE_LOCALITY": "MISSION ASHRAM",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743339,
      "TXT_PINCODE_LOCALITY": "FRESER GANJ",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1754,
      "City District Name": "24 PARGANAS (S)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743343,
      "TXT_PINCODE_LOCALITY": "BEJOYGUNJ BAZAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743344,
      "TXT_PINCODE_LOCALITY": "DAKSHIN JAGADISHPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743345,
      "TXT_PINCODE_LOCALITY": "BEJOYGONJ BAZAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743347,
      "TXT_PINCODE_LOCALITY": "KAKDWIP",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743348,
      "TXT_PINCODE_LOCALITY": "KARANJALI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743349,
      "TXT_PINCODE_LOCALITY": "KASHI NAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743350,
      "TXT_PINCODE_LOCALITY": "KODALIA - 2503",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 3440,
      "City District Name": "HUGLI-CHINSURAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743351,
      "TXT_PINCODE_LOCALITY": "KULPI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743352,
      "TXT_PINCODE_LOCALITY": "MAHESTAL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743353,
      "TXT_PINCODE_LOCALITY": "MALANCHA MOHINAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743354,
      "TXT_PINCODE_LOCALITY": "MATHURAPUR RS",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743355,
      "TXT_PINCODE_LOCALITY": "MAGRAHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743356,
      "TXT_PINCODE_LOCALITY": "MUDPOINT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743357,
      "TXT_PINCODE_LOCALITY": "NAMKHANA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743358,
      "TXT_PINCODE_LOCALITY": "RAJPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743359,
      "TXT_PINCODE_LOCALITY": "HARINAVI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743360,
      "TXT_PINCODE_LOCALITY": "KULTALI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743363,
      "TXT_PINCODE_LOCALITY": "BANSRA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1754,
      "City District Name": "24 PARGANAS (S)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743368,
      "TXT_PINCODE_LOCALITY": "SARISHA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743369,
      "TXT_PINCODE_LOCALITY": "SONARPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743370,
      "TXT_PINCODE_LOCALITY": "GOSABA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743371,
      "TXT_PINCODE_LOCALITY": "PATHAR PRATIMA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743372,
      "TXT_PINCODE_LOCALITY": "DAKSHIN BARASA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743373,
      "TXT_PINCODE_LOCALITY": "RUDRANAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743374,
      "TXT_PINCODE_LOCALITY": "NISCHINTAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743375,
      "TXT_PINCODE_LOCALITY": "USTHI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743376,
      "TXT_PINCODE_LOCALITY": "TALDI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743377,
      "TXT_PINCODE_LOCALITY": "BAKREHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743378,
      "TXT_PINCODE_LOCALITY": "CHOTO MOLLA KHALI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743379,
      "TXT_PINCODE_LOCALITY": "DAKSHIN JAGATDAL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743380,
      "TXT_PINCODE_LOCALITY": "JADAVPURHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743381,
      "TXT_PINCODE_LOCALITY": "RAMKANTA NAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743382,
      "TXT_PINCODE_LOCALITY": "SARKARPOOL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743383,
      "TXT_PINCODE_LOCALITY": "RAIDIGHI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743384,
      "TXT_PINCODE_LOCALITY": "BOWALI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743385,
      "TXT_PINCODE_LOCALITY": "SARBERIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743386,
      "TXT_PINCODE_LOCALITY": "KODALIA SHIBPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743387,
      "TXT_PINCODE_LOCALITY": "PIYALI TOWN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743388,
      "TXT_PINCODE_LOCALITY": "MAYDAH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743389,
      "TXT_PINCODE_LOCALITY": "UTTAR RAIPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743390,
      "TXT_PINCODE_LOCALITY": "MANSADWIP",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743391,
      "TXT_PINCODE_LOCALITY": "GOCHARAN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743392,
      "TXT_PINCODE_LOCALITY": "MURIGANGA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743393,
      "TXT_PINCODE_LOCALITY": "FRESERGANJ",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743394,
      "TXT_PINCODE_LOCALITY": "MANDIR BAZAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743395,
      "TXT_PINCODE_LOCALITY": "SOUTH BISHNUPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743396,
      "TXT_PINCODE_LOCALITY": "DHOSE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743397,
      "TXT_PINCODE_LOCALITY": "HOTAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743398,
      "TXT_PINCODE_LOCALITY": "KANYANAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743399,
      "TXT_PINCODE_LOCALITY": "DHOLAHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743401,
      "TXT_PINCODE_LOCALITY": "BADURIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743402,
      "TXT_PINCODE_LOCALITY": "ARBELIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743405,
      "TXT_PINCODE_LOCALITY": "CHAIGHARIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1754,
      "City District Name": "24 PARGANAS (S)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743411,
      "TXT_PINCODE_LOCALITY": "BASIRIAT HO.",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743412,
      "TXT_PINCODE_LOCALITY": "BASIRIAT COLLEGE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743413,
      "TXT_PINCODE_LOCALITY": "BADARTALA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743414,
      "TXT_PINCODE_LOCALITY": "BASIRHAT R S",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743422,
      "TXT_PINCODE_LOCALITY": "HEBIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743423,
      "TXT_PINCODE_LOCALITY": "DEGANGA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743424,
      "TXT_PINCODE_LOCALITY": "DEVALAYA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743425,
      "TXT_PINCODE_LOCALITY": "HARIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743426,
      "TXT_PINCODE_LOCALITY": "HANSABAD",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743427,
      "TXT_PINCODE_LOCALITY": "KATIAHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 425903,
      "City District Name": "24 PARGANA( NORTH)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743428,
      "TXT_PINCODE_LOCALITY": "KHOLAPOTA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 440711,
      "City District Name": "24 PARGANA( NORTH)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743429,
      "TXT_PINCODE_LOCALITY": "TAKI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743434,
      "TXT_PINCODE_LOCALITY": "CHANPOR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743435,
      "TXT_PINCODE_LOCALITY": "HINDGALGANJ",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743436,
      "TXT_PINCODE_LOCALITY": "BHABLA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743437,
      "TXT_PINCODE_LOCALITY": "DHANYAKURIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743438,
      "TXT_PINCODE_LOCALITY": "KOLSUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743439,
      "TXT_PINCODE_LOCALITY": "HATGACHHA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743440,
      "TXT_PINCODE_LOCALITY": "METIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743441,
      "TXT_PINCODE_LOCALITY": "MINAKHAN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743442,
      "TXT_PINCODE_LOCALITY": "NAZATHKHOLA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743443,
      "TXT_PINCODE_LOCALITY": "HADIPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743445,
      "TXT_PINCODE_LOCALITY": "GOPALPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743446,
      "TXT_PINCODE_LOCALITY": "SANDESHKHALI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743456,
      "TXT_PINCODE_LOCALITY": "DAKSHIN BHEBIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1754,
      "City District Name": "24 PARGANAS (S)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743486,
      "TXT_PINCODE_LOCALITY": "CHAK KHAMARPARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 443041,
      "City District Name": "BASIRHAT - II",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743501,
      "TXT_PINCODE_LOCALITY": "ACHIPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743502,
      "TXT_PINCODE_LOCALITY": "BHANGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743503,
      "TXT_PINCODE_LOCALITY": "BISHNUPUR BARUIPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743504,
      "TXT_PINCODE_LOCALITY": "MOHIRAMPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1754,
      "City District Name": "24 PARGANAS (S)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743505,
      "TXT_PINCODE_LOCALITY": "BORAL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743506,
      "TXT_PINCODE_LOCALITY": "DHAPA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743507,
      "TXT_PINCODE_LOCALITY": "JAIRAMPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743508,
      "TXT_PINCODE_LOCALITY": "NARENDRAPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743509,
      "TXT_PINCODE_LOCALITY": "PURBA PUTIARY",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743510,
      "TXT_PINCODE_LOCALITY": "RAJARHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743511,
      "TXT_PINCODE_LOCALITY": "PRAFULLAKHANAN",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743512,
      "TXT_PINCODE_LOCALITY": "JOKA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743513,
      "TXT_PINCODE_LOCALITY": "FATEHEPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743514,
      "TXT_PINCODE_LOCALITY": "CHABERIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743515,
      "TXT_PINCODE_LOCALITY": "LASKARPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743516,
      "TXT_PINCODE_LOCALITY": "PANCHPOTA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743517,
      "TXT_PINCODE_LOCALITY": "BODRA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743518,
      "TXT_PINCODE_LOCALITY": "RAJARHAT GOPALPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743601,
      "TXT_PINCODE_LOCALITY": "GOKARNEE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743602,
      "TXT_PINCODE_LOCALITY": "RANGABELIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743603,
      "TXT_PINCODE_LOCALITY": "KAUTALA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743604,
      "TXT_PINCODE_LOCALITY": "GILARCHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743605,
      "TXT_PINCODE_LOCALITY": "NALNA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743606,
      "TXT_PINCODE_LOCALITY": "GANGASAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743607,
      "TXT_PINCODE_LOCALITY": "JALABERIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743608,
      "TXT_PINCODE_LOCALITY": "BAKULTALA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743609,
      "TXT_PINCODE_LOCALITY": "HANSURI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743610,
      "TXT_PINCODE_LOCALITY": "MADARHAT",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743611,
      "TXT_PINCODE_LOCALITY": "PATHAN KHALI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743612,
      "TXT_PINCODE_LOCALITY": "SANTOSHPUR (MAHESTALA)",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743613,
      "TXT_PINCODE_LOCALITY": "SOUTH GARIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743700,
      "TXT_PINCODE_LOCALITY": "MIRHATI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 440,
      "City District Name": "BARASAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743701,
      "TXT_PINCODE_LOCALITY": "PALLA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743702,
      "TXT_PINCODE_LOCALITY": "RAJIBPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743703,
      "TXT_PINCODE_LOCALITY": "TENTULIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743704,
      "TXT_PINCODE_LOCALITY": "GUMA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743705,
      "TXT_PINCODE_LOCALITY": "PETRAPOLE",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743706,
      "TXT_PINCODE_LOCALITY": "BAMONGACHI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743707,
      "TXT_PINCODE_LOCALITY": "NOAPARA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743708,
      "TXT_PINCODE_LOCALITY": "PUTURI.",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 443037,
      "City District Name": "BARASAT - II",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743710,
      "TXT_PINCODE_LOCALITY": "GARIBPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 433,
      "City District Name": "24 PARAGANA ( N )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743711,
      "TXT_PINCODE_LOCALITY": "24 PARGANAS",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1353,
      "City District Name": "24 PARGANAS S",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 743744,
      "TXT_PINCODE_LOCALITY": "PIPHA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 443040,
      "City District Name": "BASIRHAT - I",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 744019,
      "TXT_PINCODE_LOCALITY": "TIRUR (RV)",
      "NUM_STATE_CD": 165,
      "State Name": "ANDAMAN & NICOBAR ISLANDS",
      "NUM_CITYDISTRICT_CD": 442178,
      "City District Name": "FERRARGUNJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 744101,
      "TXT_PINCODE_LOCALITY": "PORT BLAIR H O",
      "NUM_STATE_CD": 165,
      "State Name": "ANDAMAN & NICOBAR ISLANDS",
      "NUM_CITYDISTRICT_CD": 424399,
      "City District Name": "ANDAMANS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 744102,
      "TXT_PINCODE_LOCALITY": "HADDO",
      "NUM_STATE_CD": 165,
      "State Name": "ANDAMAN & NICOBAR ISLANDS",
      "NUM_CITYDISTRICT_CD": 424399,
      "City District Name": "ANDAMANS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 744103,
      "TXT_PINCODE_LOCALITY": "JANGLI GHAT",
      "NUM_STATE_CD": 165,
      "State Name": "ANDAMAN & NICOBAR ISLANDS",
      "NUM_CITYDISTRICT_CD": 424399,
      "City District Name": "ANDAMANS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 744104,
      "TXT_PINCODE_LOCALITY": "NEEL ISLAND",
      "NUM_STATE_CD": 165,
      "State Name": "ANDAMAN & NICOBAR ISLANDS",
      "NUM_CITYDISTRICT_CD": 424399,
      "City District Name": "ANDAMANS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 744105,
      "TXT_PINCODE_LOCALITY": "NEW SECRETARIATE BLDG",
      "NUM_STATE_CD": 165,
      "State Name": "ANDAMAN & NICOBAR ISLANDS",
      "NUM_CITYDISTRICT_CD": 424399,
      "City District Name": "ANDAMANS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 744106,
      "TXT_PINCODE_LOCALITY": "SHADIPUR",
      "NUM_STATE_CD": 165,
      "State Name": "ANDAMAN & NICOBAR ISLANDS",
      "NUM_CITYDISTRICT_CD": 424399,
      "City District Name": "ANDAMANS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 744107,
      "TXT_PINCODE_LOCALITY": "BAMBOOFLAT",
      "NUM_STATE_CD": 165,
      "State Name": "ANDAMAN & NICOBAR ISLANDS",
      "NUM_CITYDISTRICT_CD": 424399,
      "City District Name": "ANDAMANS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 744108,
      "TXT_PINCODE_LOCALITY": "MANPUR (RV)",
      "NUM_STATE_CD": 165,
      "State Name": "ANDAMAN & NICOBAR ISLANDS",
      "NUM_CITYDISTRICT_CD": 442178,
      "City District Name": "FERRARGUNJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 744109,
      "TXT_PINCODE_LOCALITY": "TIRUR I (JPPC)",
      "NUM_STATE_CD": 165,
      "State Name": "ANDAMAN & NICOBAR ISLANDS",
      "NUM_CITYDISTRICT_CD": 442178,
      "City District Name": "FERRARGUNJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 744110,
      "TXT_PINCODE_LOCALITY": "NAYASHAHAR (RV)",
      "NUM_STATE_CD": 165,
      "State Name": "ANDAMAN & NICOBAR ISLANDS",
      "NUM_CITYDISTRICT_CD": 442178,
      "City District Name": "FERRARGUNJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 744111,
      "TXT_PINCODE_LOCALITY": "DUNDAS POINT (RV)",
      "NUM_STATE_CD": 165,
      "State Name": "ANDAMAN & NICOBAR ISLANDS",
      "NUM_CITYDISTRICT_CD": 442178,
      "City District Name": "FERRARGUNJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 744112,
      "TXT_PINCODE_LOCALITY": "PORTBLAIR",
      "NUM_STATE_CD": 165,
      "State Name": "ANDAMAN & NICOBAR ISLANDS",
      "NUM_CITYDISTRICT_CD": 442136,
      "City District Name": "PORT BLAIR S. O",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 744201,
      "TXT_PINCODE_LOCALITY": "THORAKTANG",
      "NUM_STATE_CD": 165,
      "State Name": "ANDAMAN & NICOBAR ISLANDS",
      "NUM_CITYDISTRICT_CD": 442180,
      "City District Name": "RANGAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 744202,
      "TXT_PINCODE_LOCALITY": "DIGLIPUR",
      "NUM_STATE_CD": 165,
      "State Name": "ANDAMAN & NICOBAR ISLANDS",
      "NUM_CITYDISTRICT_CD": 424399,
      "City District Name": "ANDAMANS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 744203,
      "TXT_PINCODE_LOCALITY": "PORLOB DEPOT . (FC)",
      "NUM_STATE_CD": 165,
      "State Name": "ANDAMAN & NICOBAR ISLANDS",
      "NUM_CITYDISTRICT_CD": 442180,
      "City District Name": "RANGAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 744204,
      "TXT_PINCODE_LOCALITY": "MAYABUNDER",
      "NUM_STATE_CD": 165,
      "State Name": "ANDAMAN & NICOBAR ISLANDS",
      "NUM_CITYDISTRICT_CD": 424399,
      "City District Name": "ANDAMANS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 744205,
      "TXT_PINCODE_LOCALITY": "RANGHAT",
      "NUM_STATE_CD": 165,
      "State Name": "ANDAMAN & NICOBAR ISLANDS",
      "NUM_CITYDISTRICT_CD": 424399,
      "City District Name": "ANDAMANS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 744206,
      "TXT_PINCODE_LOCALITY": "MALAPURAM (RV)",
      "NUM_STATE_CD": 165,
      "State Name": "ANDAMAN & NICOBAR ISLANDS",
      "NUM_CITYDISTRICT_CD": 442178,
      "City District Name": "FERRARGUNJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 744207,
      "TXT_PINCODE_LOCALITY": "BUTLER BAY FOREST CAMP 4-II (FDCA)",
      "NUM_STATE_CD": 165,
      "State Name": "ANDAMAN & NICOBAR ISLANDS",
      "NUM_CITYDISTRICT_CD": 442181,
      "City District Name": "LITTLE ANDAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 744208,
      "TXT_PINCODE_LOCALITY": "BAKULTALA A AND N",
      "NUM_STATE_CD": 165,
      "State Name": "ANDAMAN & NICOBAR ISLANDS",
      "NUM_CITYDISTRICT_CD": 442136,
      "City District Name": "PORT BLAIR S. O",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 744209,
      "TXT_PINCODE_LOCALITY": "KADAMTALA A & N",
      "NUM_STATE_CD": 165,
      "State Name": "ANDAMAN & NICOBAR ISLANDS",
      "NUM_CITYDISTRICT_CD": 424399,
      "City District Name": "ANDAMANS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 744210,
      "TXT_PINCODE_LOCALITY": "WRAFTERS CREEK (RV)",
      "NUM_STATE_CD": 165,
      "State Name": "ANDAMAN & NICOBAR ISLANDS",
      "NUM_CITYDISTRICT_CD": 442182,
      "City District Name": "GREAT NICOBAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 744211,
      "TXT_PINCODE_LOCALITY": "HAVELOCK",
      "NUM_STATE_CD": 165,
      "State Name": "ANDAMAN & NICOBAR ISLANDS",
      "NUM_CITYDISTRICT_CD": 424399,
      "City District Name": "ANDAMANS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 744301,
      "TXT_PINCODE_LOCALITY": "CAR NICOBAR",
      "NUM_STATE_CD": 165,
      "State Name": "ANDAMAN & NICOBAR ISLANDS",
      "NUM_CITYDISTRICT_CD": 424400,
      "City District Name": "NICOBARS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 744302,
      "TXT_PINCODE_LOCALITY": "SHOMPEN VILLAGE-B",
      "NUM_STATE_CD": 165,
      "State Name": "ANDAMAN & NICOBAR ISLANDS",
      "NUM_CITYDISTRICT_CD": 442182,
      "City District Name": "GREAT NICOBAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 744303,
      "TXT_PINCODE_LOCALITY": "NANCOWRIE",
      "NUM_STATE_CD": 165,
      "State Name": "ANDAMAN & NICOBAR ISLANDS",
      "NUM_CITYDISTRICT_CD": 424400,
      "City District Name": "NICOBARS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 744304,
      "TXT_PINCODE_LOCALITY": "TANI",
      "NUM_STATE_CD": 165,
      "State Name": "ANDAMAN & NICOBAR ISLANDS",
      "NUM_CITYDISTRICT_CD": 442184,
      "City District Name": "NANCOWRY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 746313,
      "TXT_PINCODE_LOCALITY": "24 PARGANAS S. O.",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1353,
      "City District Name": "24 PARGANAS S",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 750001,
      "TXT_PINCODE_LOCALITY": "BHUBANESHWAR S. O",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2529,
      "City District Name": "BHUBANESWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 750181,
      "TXT_PINCODE_LOCALITY": "BHADRAK.",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1459,
      "City District Name": "BHADRAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 751001,
      "TXT_PINCODE_LOCALITY": "ORISSA ASSEMBLY",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2529,
      "City District Name": "BHUBANESWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 751002,
      "TXT_PINCODE_LOCALITY": "OLD BHUBANESWAR (BBR-2)",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3980,
      "City District Name": "KHORDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 751003,
      "TXT_PINCODE_LOCALITY": "BARAMUNDA COLONY",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2529,
      "City District Name": "BHUBANESWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 751004,
      "TXT_PINCODE_LOCALITY": "UTKAL UNIVERSITY (BHUBAN4",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3980,
      "City District Name": "KHORDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 751005,
      "TXT_PINCODE_LOCALITY": "SAINIK SCHOOL(BBR-5)",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3980,
      "City District Name": "KHORDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 751006,
      "TXT_PINCODE_LOCALITY": "BUDHESWARI COLONY",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3980,
      "City District Name": "KHORDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 751007,
      "TXT_PINCODE_LOCALITY": "SATYANAGAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3980,
      "City District Name": "KHORDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 751008,
      "TXT_PINCODE_LOCALITY": "RAJBHAWAN (BBR-8)",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3980,
      "City District Name": "KHORDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 751009,
      "TXT_PINCODE_LOCALITY": "BHUBANESWAR AIR PORT",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3980,
      "City District Name": "KHORDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 751010,
      "TXT_PINCODE_LOCALITY": "RASULGARH",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2529,
      "City District Name": "BHUBANESWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 751011,
      "TXT_PINCODE_LOCALITY": "C R P LINE",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2529,
      "City District Name": "BHUBANESWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 751012,
      "TXT_PINCODE_LOCALITY": "NAYAPALLI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2529,
      "City District Name": "BHUBANESWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 751013,
      "TXT_PINCODE_LOCALITY": "LABORATORY",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 751014,
      "TXT_PINCODE_LOCALITY": "BHUBANESWAR COURT S.O",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 223,
      "City District Name": "KHORDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 751015,
      "TXT_PINCODE_LOCALITY": "I.R.C. VILLAGE BHUBANESHWAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 223,
      "City District Name": "KHORDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 751016,
      "TXT_PINCODE_LOCALITY": "CHANDRASEKHARPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6458,
      "City District Name": "KHURDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 751017,
      "TXT_PINCODE_LOCALITY": "MANCHESWAR INDUSTRIAL ESTATE",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 223,
      "City District Name": "KHORDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 751018,
      "TXT_PINCODE_LOCALITY": "BADAGARH BRIT COLONY",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 223,
      "City District Name": "KHORDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 751019,
      "TXT_PINCODE_LOCALITY": "AIGINIA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3980,
      "City District Name": "KHORDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 751020,
      "TXT_PINCODE_LOCALITY": "AIR PORT AREA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2529,
      "City District Name": "BHUBANESWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 751021,
      "TXT_PINCODE_LOCALITY": "SAILASHREE VIHAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2529,
      "City District Name": "BHUBANESWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 751022,
      "TXT_PINCODE_LOCALITY": "BHOINAGAR S.O",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 223,
      "City District Name": "KHORDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 751023,
      "TXT_PINCODE_LOCALITY": "MAIRTI VIHAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6458,
      "City District Name": "KHURDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 751024,
      "TXT_PINCODE_LOCALITY": "INFOCITY",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2529,
      "City District Name": "BHUBANESWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 751025,
      "TXT_PINCODE_LOCALITY": "GGP COLONY",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2529,
      "City District Name": "BHUBANESWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 751026,
      "TXT_PINCODE_LOCALITY": "AIRPORT COLONY",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 442915,
      "City District Name": "BHUBANESWAR (M.CORP.)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 751027,
      "TXT_PINCODE_LOCALITY": "INTERNATIONAL BUSINESS CENTRE",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2529,
      "City District Name": "BHUBANESWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 751028,
      "TXT_PINCODE_LOCALITY": "KHORDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 223,
      "City District Name": "KHORDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 751029,
      "TXT_PINCODE_LOCALITY": "BHUBANESWAR S. O",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 442039,
      "City District Name": "BHUBANESHWAR.",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 751030,
      "TXT_PINCODE_LOCALITY": "KHURDA S. O.",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2529,
      "City District Name": "BHUBANESWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 751031,
      "TXT_PINCODE_LOCALITY": "CHANDRASHEKHARPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 223,
      "City District Name": "KHORDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 751032,
      "TXT_PINCODE_LOCALITY": "PAHAL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2529,
      "City District Name": "BHUBANESWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 751033,
      "TXT_PINCODE_LOCALITY": "BARAMUNDA NDC",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2529,
      "City District Name": "BHUBANESWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 751034,
      "TXT_PINCODE_LOCALITY": "RASULGARH NDC",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2529,
      "City District Name": "BHUBANESWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 751101,
      "TXT_PINCODE_LOCALITY": "CENTRAL GOVT. COLONY",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3980,
      "City District Name": "KHORDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752000,
      "TXT_PINCODE_LOCALITY": "PURI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752001,
      "TXT_PINCODE_LOCALITY": "MARKENDESWAR SAHI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2529,
      "City District Name": "BHUBANESWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752002,
      "TXT_PINCODE_LOCALITY": "PURI STATION ROAD",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752003,
      "TXT_PINCODE_LOCALITY": "NUAGAON",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752004,
      "TXT_PINCODE_LOCALITY": "BALIGUALI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752006,
      "TXT_PINCODE_LOCALITY": "RAJSUNAKHALA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1782,
      "City District Name": "NAYAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752010,
      "TXT_PINCODE_LOCALITY": "PURI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752011,
      "TXT_PINCODE_LOCALITY": "BRAHMAGIRI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752012,
      "TXT_PINCODE_LOCALITY": "CHANDANPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752013,
      "TXT_PINCODE_LOCALITY": "BASUDEBPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752014,
      "TXT_PINCODE_LOCALITY": "SAKHIGOPAL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752015,
      "TXT_PINCODE_LOCALITY": "DELANG",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752016,
      "TXT_PINCODE_LOCALITY": "BERBOI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752017,
      "TXT_PINCODE_LOCALITY": "KANAS",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752018,
      "TXT_PINCODE_LOCALITY": "NARANGARH",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1782,
      "City District Name": "NAYAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752019,
      "TXT_PINCODE_LOCALITY": "NIRAKARPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3980,
      "City District Name": "KHORDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752020,
      "TXT_PINCODE_LOCALITY": "KHURDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 223,
      "City District Name": "KHORDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752021,
      "TXT_PINCODE_LOCALITY": "SINGIPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1782,
      "City District Name": "NAYAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752022,
      "TXT_PINCODE_LOCALITY": "KALUPURAGHAT",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3980,
      "City District Name": "KHORDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752023,
      "TXT_PINCODE_LOCALITY": "TANGI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3980,
      "City District Name": "KHORDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752024,
      "TXT_PINCODE_LOCALITY": "CHANDPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1782,
      "City District Name": "NAYAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752025,
      "TXT_PINCODE_LOCALITY": "GOPALPUR (PURI)",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1782,
      "City District Name": "NAYAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752026,
      "TXT_PINCODE_LOCALITY": "RAJRANPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1782,
      "City District Name": "NAYAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752027,
      "TXT_PINCODE_LOCALITY": "KUHUDI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752028,
      "TXT_PINCODE_LOCALITY": "NACHUNI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3980,
      "City District Name": "KHORDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752029,
      "TXT_PINCODE_LOCALITY": "NAIRI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3980,
      "City District Name": "KHORDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752030,
      "TXT_PINCODE_LOCALITY": "BALUGAON BAZAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752031,
      "TXT_PINCODE_LOCALITY": "BANPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3980,
      "City District Name": "KHORDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752032,
      "TXT_PINCODE_LOCALITY": "PAVIKUD",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752033,
      "TXT_PINCODE_LOCALITY": "CHILIKA NUAPADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3980,
      "City District Name": "KHORDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752034,
      "TXT_PINCODE_LOCALITY": "GANGADHARPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3980,
      "City District Name": "KHORDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752035,
      "TXT_PINCODE_LOCALITY": "GAMBHARIMUNDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3980,
      "City District Name": "KHORDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752036,
      "TXT_PINCODE_LOCALITY": "BHUSANDPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3980,
      "City District Name": "KHORDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752037,
      "TXT_PINCODE_LOCALITY": "N T C CHILIKA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752038,
      "TXT_PINCODE_LOCALITY": "SIKO",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3980,
      "City District Name": "KHORDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752041,
      "TXT_PINCODE_LOCALITY": "SRI RAMACHANDRAPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752042,
      "TXT_PINCODE_LOCALITY": "BIRA RAMCHANDRAPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752043,
      "TXT_PINCODE_LOCALITY": "KAPILESWARPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752044,
      "TXT_PINCODE_LOCALITY": "KALYANPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1782,
      "City District Name": "NAYAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752045,
      "TXT_PINCODE_LOCALITY": "GABAKUND",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752046,
      "TXT_PINCODE_LOCALITY": "SATSANKHA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752050,
      "TXT_PINCODE_LOCALITY": "JATANI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752054,
      "TXT_PINCODE_LOCALITY": "JANLA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 223,
      "City District Name": "KHORDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752055,
      "TXT_PINCODE_LOCALITY": "KHURDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752056,
      "TXT_PINCODE_LOCALITY": "PALLAHAT",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752057,
      "TXT_PINCODE_LOCALITY": "KHURDA COLLEGE",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3980,
      "City District Name": "KHORDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752058,
      "TXT_PINCODE_LOCALITY": "GARHSANPUT",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752059,
      "TXT_PINCODE_LOCALITY": "MENDHASAL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3980,
      "City District Name": "KHORDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752060,
      "TXT_PINCODE_LOCALITY": "BAJPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3980,
      "City District Name": "KHORDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752061,
      "TXT_PINCODE_LOCALITY": "BAGHAMARI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3980,
      "City District Name": "KHORDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752062,
      "TXT_PINCODE_LOCALITY": "BEGUNIA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3980,
      "City District Name": "KHORDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752063,
      "TXT_PINCODE_LOCALITY": "BHAPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3980,
      "City District Name": "KHORDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752064,
      "TXT_PINCODE_LOCALITY": "PICHUKULI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3980,
      "City District Name": "KHORDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752065,
      "TXT_PINCODE_LOCALITY": "RAJASUNA KHALA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1782,
      "City District Name": "NAYAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752066,
      "TXT_PINCODE_LOCALITY": "BOLGARH",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752067,
      "TXT_PINCODE_LOCALITY": "MANIKGODA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 4362,
      "City District Name": "MALKANGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752068,
      "TXT_PINCODE_LOCALITY": "ITAMATI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1782,
      "City District Name": "NAYAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752069,
      "TXT_PINCODE_LOCALITY": "NAYAGARH H O",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1782,
      "City District Name": "NAYAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752070,
      "TXT_PINCODE_LOCALITY": "NAYAGARH BAZAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752077,
      "TXT_PINCODE_LOCALITY": "KHANDAPARAGARH",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1782,
      "City District Name": "NAYAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752078,
      "TXT_PINCODE_LOCALITY": "KANTILO",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1782,
      "City District Name": "NAYAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752079,
      "TXT_PINCODE_LOCALITY": "MANDHATAPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1782,
      "City District Name": "NAYAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752080,
      "TXT_PINCODE_LOCALITY": "SARANKUL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1782,
      "City District Name": "NAYAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752081,
      "TXT_PINCODE_LOCALITY": "ODAGAON",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1782,
      "City District Name": "NAYAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752082,
      "TXT_PINCODE_LOCALITY": "BAHADAJHOLA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3980,
      "City District Name": "KHORDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752083,
      "TXT_PINCODE_LOCALITY": "NUAGAON PURI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1782,
      "City District Name": "NAYAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752084,
      "TXT_PINCODE_LOCALITY": "DASPALLA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1782,
      "City District Name": "NAYAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752085,
      "TXT_PINCODE_LOCALITY": "GANIA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1782,
      "City District Name": "NAYAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752086,
      "TXT_PINCODE_LOCALITY": "GEDIAPALLI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3980,
      "City District Name": "KHORDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752087,
      "TXT_PINCODE_LOCALITY": "HAREKRISHNAPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752088,
      "TXT_PINCODE_LOCALITY": "FATEGARH",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1782,
      "City District Name": "NAYAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752089,
      "TXT_PINCODE_LOCALITY": "BANIGOCHHA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1782,
      "City District Name": "NAYAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752090,
      "TXT_PINCODE_LOCALITY": "KURAL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1782,
      "City District Name": "NAYAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752091,
      "TXT_PINCODE_LOCALITY": "SATPATNA P.O",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1782,
      "City District Name": "NAYAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752092,
      "TXT_PINCODE_LOCALITY": "GODIPADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3980,
      "City District Name": "KHORDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752093,
      "TXT_PINCODE_LOCALITY": "MANDHYAKHAND",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1782,
      "City District Name": "NAYAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752094,
      "TXT_PINCODE_LOCALITY": "MAHIPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1782,
      "City District Name": "NAYAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752095,
      "TXT_PINCODE_LOCALITY": "BALUGAON",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752096,
      "TXT_PINCODE_LOCALITY": "KHALISAHI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1782,
      "City District Name": "NAYAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752100,
      "TXT_PINCODE_LOCALITY": "BALAKATI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6458,
      "City District Name": "KHURDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752101,
      "TXT_PINCODE_LOCALITY": "BALIANTA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3980,
      "City District Name": "KHORDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752102,
      "TXT_PINCODE_LOCALITY": "BALIPANTNA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752103,
      "TXT_PINCODE_LOCALITY": "BANMALIPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3980,
      "City District Name": "KHORDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752104,
      "TXT_PINCODE_LOCALITY": "PIPLI TAHASIL CAMPUS",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752105,
      "TXT_PINCODE_LOCALITY": "BALANGA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752106,
      "TXT_PINCODE_LOCALITY": "NIMAPARA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752107,
      "TXT_PINCODE_LOCALITY": "NAYAHAT",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3980,
      "City District Name": "KHORDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752108,
      "TXT_PINCODE_LOCALITY": "KAKATPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752109,
      "TXT_PINCODE_LOCALITY": "ASTRANG",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752110,
      "TXT_PINCODE_LOCALITY": "GOP",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752111,
      "TXT_PINCODE_LOCALITY": "KONARK",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752112,
      "TXT_PINCODE_LOCALITY": "DANDAMUKUNDPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752113,
      "TXT_PINCODE_LOCALITY": "NIMAPARA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752114,
      "TXT_PINCODE_LOCALITY": "RENCH",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3980,
      "City District Name": "KHORDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752115,
      "TXT_PINCODE_LOCALITY": "BHATAPATNA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752116,
      "TXT_PINCODE_LOCALITY": "BIRTUNG",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752117,
      "TXT_PINCODE_LOCALITY": "BHANARANIJIGARH",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752118,
      "TXT_PINCODE_LOCALITY": "BANGURIGAON",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752119,
      "TXT_PINCODE_LOCALITY": "LATAHARAN",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752120,
      "TXT_PINCODE_LOCALITY": "NUAPALAMHAT",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3980,
      "City District Name": "KHORDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752121,
      "TXT_PINCODE_LOCALITY": "BAMANAL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 752201,
      "TXT_PINCODE_LOCALITY": "HALDIA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3980,
      "City District Name": "KHORDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 753001,
      "TXT_PINCODE_LOCALITY": "NANDI SAHI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 753002,
      "TXT_PINCODE_LOCALITY": "ORISSA HIGH COURT",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 753003,
      "TXT_PINCODE_LOCALITY": "ZOBRA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 753004,
      "TXT_PINCODE_LOCALITY": "NAYABAZAR  CHA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 753005,
      "TXT_PINCODE_LOCALITY": "BARABATI STADIUM",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 753006,
      "TXT_PINCODE_LOCALITY": "CRRI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 753007,
      "TXT_PINCODE_LOCALITY": "MEDICAL COLLEGE (CK-7)",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 753008,
      "TXT_PINCODE_LOCALITY": "TULSIPUR (CUTTACK-8)",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 753009,
      "TXT_PINCODE_LOCALITY": "TELANGPETH",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 753010,
      "TXT_PINCODE_LOCALITY": "ORISSA SCHOOL OF ENG",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 753011,
      "TXT_PINCODE_LOCALITY": "GOPALPUR (CUTTACK 2)",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 753012,
      "TXT_PINCODE_LOCALITY": "ARUNODAYA MARKET",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 753013,
      "TXT_PINCODE_LOCALITY": "BAYALISH MAUZA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 753014,
      "TXT_PINCODE_LOCALITY": "CDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 753015,
      "TXT_PINCODE_LOCALITY": "SECTOR LL CDA, CUTTACK S.O",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2529,
      "City District Name": "BHUBANESWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 753016,
      "TXT_PINCODE_LOCALITY": "NDC CUTTACK",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 753020,
      "TXT_PINCODE_LOCALITY": "CHANDNI CHOWK",
      "NUM_STATE_CD": 901,
      "State Name": "ODISHA",
      "NUM_CITYDISTRICT_CD": 444150,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 753051,
      "TXT_PINCODE_LOCALITY": "TELENGAPENTHA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 753052,
      "TXT_PINCODE_LOCALITY": "SANKHATRAS",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 753100,
      "TXT_PINCODE_LOCALITY": "PHAKIRPARA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 442921,
      "City District Name": "KANDARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 753353,
      "TXT_PINCODE_LOCALITY": "MALANCHA MAHINAGAR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1726,
      "City District Name": "24 PARAGANA ( SOUTH )",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754001,
      "TXT_PINCODE_LOCALITY": "FULNAKHARA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754002,
      "TXT_PINCODE_LOCALITY": "CHASAPRA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754003,
      "TXT_PINCODE_LOCALITY": "GOVINDAPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754004,
      "TXT_PINCODE_LOCALITY": "NIALI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754005,
      "TXT_PINCODE_LOCALITY": "BARANGA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754006,
      "TXT_PINCODE_LOCALITY": "MUNDALI COLONY",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754007,
      "TXT_PINCODE_LOCALITY": "DOMPARA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754008,
      "TXT_PINCODE_LOCALITY": "BANKI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754009,
      "TXT_PINCODE_LOCALITY": "KALAPATHAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754010,
      "TXT_PINCODE_LOCALITY": "BAIROI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754011,
      "TXT_PINCODE_LOCALITY": "ADASPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754012,
      "TXT_PINCODE_LOCALITY": "TULOSIPUR (BANKI)",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754013,
      "TXT_PINCODE_LOCALITY": "NANDANKANAN",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754014,
      "TXT_PINCODE_LOCALITY": "TALABASTA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754015,
      "TXT_PINCODE_LOCALITY": "CHANDAKA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754016,
      "TXT_PINCODE_LOCALITY": "KANTAPARA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754017,
      "TXT_PINCODE_LOCALITY": "BAIDESWAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754018,
      "TXT_PINCODE_LOCALITY": "BRAHMANSAILO",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754020,
      "TXT_PINCODE_LOCALITY": "GURUDIJHATIA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754021,
      "TXT_PINCODE_LOCALITY": "CUTTACK",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754022,
      "TXT_PINCODE_LOCALITY": "KOTSAHI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3887,
      "City District Name": "KENDRAPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754023,
      "TXT_PINCODE_LOCALITY": "CHHATIA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754024,
      "TXT_PINCODE_LOCALITY": "DHANMANDAL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754025,
      "TXT_PINCODE_LOCALITY": "CHOUDWAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754026,
      "TXT_PINCODE_LOCALITY": "DAULATABAD",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754027,
      "TXT_PINCODE_LOCALITY": "CHASAPARA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754028,
      "TXT_PINCODE_LOCALITY": "CHARBATIA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754029,
      "TXT_PINCODE_LOCALITY": "ATHGARH",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754030,
      "TXT_PINCODE_LOCALITY": "TIGIRIA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754031,
      "TXT_PINCODE_LOCALITY": "BARAMBAGARH",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754032,
      "TXT_PINCODE_LOCALITY": "NARASINGHPUR S.O",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754033,
      "TXT_PINCODE_LOCALITY": "KHUNTUNI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754034,
      "TXT_PINCODE_LOCALITY": "MANIABANDHA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754035,
      "TXT_PINCODE_LOCALITY": "NUAPATNA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754036,
      "TXT_PINCODE_LOCALITY": "SUBARNAPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754037,
      "TXT_PINCODE_LOCALITY": "KANPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754070,
      "TXT_PINCODE_LOCALITY": "AGRAHAT",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754071,
      "TXT_PINCODE_LOCALITY": "KAPALESWAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754080,
      "TXT_PINCODE_LOCALITY": "BHATIMUNDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754081,
      "TXT_PINCODE_LOCALITY": "BARACHANA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754082,
      "TXT_PINCODE_LOCALITY": "BYREE",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754100,
      "TXT_PINCODE_LOCALITY": "BIRIBATI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754101,
      "TXT_PINCODE_LOCALITY": "SIDHESWARPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754102,
      "TXT_PINCODE_LOCALITY": "ANAKHIA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3490,
      "City District Name": "JAGATSINGHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754103,
      "TXT_PINCODE_LOCALITY": "S V M COLLEGE",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754104,
      "TXT_PINCODE_LOCALITY": "NALIBAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3980,
      "City District Name": "KHORDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754105,
      "TXT_PINCODE_LOCALITY": "KASARDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754106,
      "TXT_PINCODE_LOCALITY": "KADUAPARA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754107,
      "TXT_PINCODE_LOCALITY": "ALANAHAT",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754108,
      "TXT_PINCODE_LOCALITY": "BALIKUDA S.O",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3490,
      "City District Name": "JAGATSINGHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754109,
      "TXT_PINCODE_LOCALITY": "DEBIDOL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3490,
      "City District Name": "JAGATSINGHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754110,
      "TXT_PINCODE_LOCALITY": "BORIKINA S.O",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3490,
      "City District Name": "JAGATSINGHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754111,
      "TXT_PINCODE_LOCALITY": "BIRIDI ROAD",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754112,
      "TXT_PINCODE_LOCALITY": "BENTKAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754113,
      "TXT_PINCODE_LOCALITY": "NAUGAONHAT",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3490,
      "City District Name": "JAGATSINGHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754114,
      "TXT_PINCODE_LOCALITY": "MANDASAHI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3490,
      "City District Name": "JAGATSINGHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754115,
      "TXT_PINCODE_LOCALITY": "TARIKUND",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3490,
      "City District Name": "JAGATSINGHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754116,
      "TXT_PINCODE_LOCALITY": "MADHYASASAN",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3490,
      "City District Name": "JAGATSINGHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754117,
      "TXT_PINCODE_LOCALITY": "KANDARPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754118,
      "TXT_PINCODE_LOCALITY": "SARICHUAN",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754119,
      "TXT_PINCODE_LOCALITY": "MACHHAGAON",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3490,
      "City District Name": "JAGATSINGHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754120,
      "TXT_PINCODE_LOCALITY": "ATHARBANKI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1470,
      "City District Name": "JAGAT SINGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754130,
      "TXT_PINCODE_LOCALITY": "SOMEPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754131,
      "TXT_PINCODE_LOCALITY": "KISHORNAGAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754132,
      "TXT_PINCODE_LOCALITY": "JAGATSINGHPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3490,
      "City District Name": "JAGATSINGHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754133,
      "TXT_PINCODE_LOCALITY": "TARPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3490,
      "City District Name": "JAGATSINGHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754134,
      "TXT_PINCODE_LOCALITY": "TYANDAKURA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3490,
      "City District Name": "JAGATSINGHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754135,
      "TXT_PINCODE_LOCALITY": "KRISHNANDAPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3490,
      "City District Name": "JAGATSINGHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754136,
      "TXT_PINCODE_LOCALITY": "BEGUNIA S.O",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3490,
      "City District Name": "JAGATSINGHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754137,
      "TXT_PINCODE_LOCALITY": "TIRTOL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3490,
      "City District Name": "JAGATSINGHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754138,
      "TXT_PINCODE_LOCALITY": "TIRAN",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754139,
      "TXT_PINCODE_LOCALITY": "TIRAN",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754140,
      "TXT_PINCODE_LOCALITY": "RAHAMA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3490,
      "City District Name": "JAGATSINGHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754141,
      "TXT_PINCODE_LOCALITY": "JAGATSINGHAPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3490,
      "City District Name": "JAGATSINGHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754142,
      "TXT_PINCODE_LOCALITY": "PARADEEP PORT",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3490,
      "City District Name": "JAGATSINGHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754143,
      "TXT_PINCODE_LOCALITY": "SECTOR-21 PARADEEP",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3490,
      "City District Name": "JAGATSINGHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754144,
      "TXT_PINCODE_LOCALITY": "PARADEEP LOCK",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3490,
      "City District Name": "JAGATSINGHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754145,
      "TXT_PINCODE_LOCALITY": "PARADEEP",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3490,
      "City District Name": "JAGATSINGHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754153,
      "TXT_PINCODE_LOCALITY": "GARADPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3887,
      "City District Name": "KENDRAPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754154,
      "TXT_PINCODE_LOCALITY": "ANTEI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3887,
      "City District Name": "KENDRAPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754155,
      "TXT_PINCODE_LOCALITY": "SENA ADHANGA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3887,
      "City District Name": "KENDRAPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754156,
      "TXT_PINCODE_LOCALITY": "KUDANAGARI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3490,
      "City District Name": "JAGATSINGHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754157,
      "TXT_PINCODE_LOCALITY": "GODIJANG",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754158,
      "TXT_PINCODE_LOCALITY": "BODHPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754159,
      "TXT_PINCODE_LOCALITY": "GODMA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754160,
      "TXT_PINCODE_LOCALITY": "MANIJANGA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3490,
      "City District Name": "JAGATSINGHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754161,
      "TXT_PINCODE_LOCALITY": "JAIPUR CK",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754162,
      "TXT_PINCODE_LOCALITY": "KOLAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3490,
      "City District Name": "JAGATSINGHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754200,
      "TXT_PINCODE_LOCALITY": "BHUGRAM",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754201,
      "TXT_PINCODE_LOCALITY": "RAMESWAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754202,
      "TXT_PINCODE_LOCALITY": "SALIPUR COLLEGE",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754203,
      "TXT_PINCODE_LOCALITY": "KENDUPATNA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3887,
      "City District Name": "KENDRAPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754204,
      "TXT_PINCODE_LOCALITY": "SALIPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754205,
      "TXT_PINCODE_LOCALITY": "BALICHANDRAPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754206,
      "TXT_PINCODE_LOCALITY": "MAHARGA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754207,
      "TXT_PINCODE_LOCALITY": "KENDRAPARA S.O",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3887,
      "City District Name": "KENDRAPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754208,
      "TXT_PINCODE_LOCALITY": "CHANDOL S.O",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754209,
      "TXT_PINCODE_LOCALITY": "ASURESWAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754210,
      "TXT_PINCODE_LOCALITY": "DANPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3887,
      "City District Name": "KENDRAPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754211,
      "TXT_PINCODE_LOCALITY": "KESHPUR BAZAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754212,
      "TXT_PINCODE_LOCALITY": "SRIBALADEVJEE",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3887,
      "City District Name": "KENDRAPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754213,
      "TXT_PINCODE_LOCALITY": "MARSAGHAI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3887,
      "City District Name": "KENDRAPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754214,
      "TXT_PINCODE_LOCALITY": "RATNAGIRI B.O",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754215,
      "TXT_PINCODE_LOCALITY": "PATTAMUNDAI COLLEGE",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3887,
      "City District Name": "KENDRAPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754216,
      "TXT_PINCODE_LOCALITY": "JARIA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3887,
      "City District Name": "KENDRAPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754217,
      "TXT_PINCODE_LOCALITY": "ALBA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754218,
      "TXT_PINCODE_LOCALITY": "BATIPARA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754219,
      "TXT_PINCODE_LOCALITY": "AUL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754220,
      "TXT_PINCODE_LOCALITY": "RAJKANIKA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3887,
      "City District Name": "KENDRAPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754221,
      "TXT_PINCODE_LOCALITY": "SUNGRA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754222,
      "TXT_PINCODE_LOCALITY": "CHAUDKULAT",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754223,
      "TXT_PINCODE_LOCALITY": "KARIOPATNA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754224,
      "TXT_PINCODE_LOCALITY": "MAHAKALPARA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754225,
      "TXT_PINCODE_LOCALITY": "RAJNAGAR (KENDRAPARA)",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3887,
      "City District Name": "KENDRAPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754226,
      "TXT_PINCODE_LOCALITY": "DAMARPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3887,
      "City District Name": "KENDRAPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754227,
      "TXT_PINCODE_LOCALITY": "OLAVAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3887,
      "City District Name": "KENDRAPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754228,
      "TXT_PINCODE_LOCALITY": "KENDRAPARA S.O",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3887,
      "City District Name": "KENDRAPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754229,
      "TXT_PINCODE_LOCALITY": "OSTAPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3887,
      "City District Name": "KENDRAPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754230,
      "TXT_PINCODE_LOCALITY": "AYABA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3887,
      "City District Name": "KENDRAPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754231,
      "TXT_PINCODE_LOCALITY": "NAMAUZA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3887,
      "City District Name": "KENDRAPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754232,
      "TXT_PINCODE_LOCALITY": "ANGULAI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3887,
      "City District Name": "KENDRAPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754233,
      "TXT_PINCODE_LOCALITY": "ANDULI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3887,
      "City District Name": "KENDRAPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754234,
      "TXT_PINCODE_LOCALITY": "BHAGABATPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3887,
      "City District Name": "KENDRAPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754235,
      "TXT_PINCODE_LOCALITY": "BALIKANAGOPALPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754236,
      "TXT_PINCODE_LOCALITY": "RATNAGIRI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754237,
      "TXT_PINCODE_LOCALITY": "AYATAN",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3887,
      "City District Name": "KENDRAPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754238,
      "TXT_PINCODE_LOCALITY": "AMRUTA MANOHI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3887,
      "City District Name": "KENDRAPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754239,
      "TXT_PINCODE_LOCALITY": "BHUINPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3887,
      "City District Name": "KENDRAPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754240,
      "TXT_PINCODE_LOCALITY": "DANDISAHI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3887,
      "City District Name": "KENDRAPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754241,
      "TXT_PINCODE_LOCALITY": "BALIPATNA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3887,
      "City District Name": "KENDRAPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754242,
      "TXT_PINCODE_LOCALITY": "TALCHUAN",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3887,
      "City District Name": "KENDRAPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754243,
      "TXT_PINCODE_LOCALITY": "PEGARPARA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3887,
      "City District Name": "KENDRAPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754244,
      "TXT_PINCODE_LOCALITY": "JAMPAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754245,
      "TXT_PINCODE_LOCALITY": "BARPARA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754246,
      "TXT_PINCODE_LOCALITY": "MADANPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3887,
      "City District Name": "KENDRAPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754247,
      "TXT_PINCODE_LOCALITY": "BANKESWAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754248,
      "TXT_PINCODE_LOCALITY": "ISWARPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3490,
      "City District Name": "JAGATSINGHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754249,
      "TXT_PINCODE_LOCALITY": "SRIRAMPUR PALPATNA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3887,
      "City District Name": "KENDRAPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754250,
      "TXT_PINCODE_LOCALITY": "THAKURPATNA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3887,
      "City District Name": "KENDRAPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754251,
      "TXT_PINCODE_LOCALITY": "BARUA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754252,
      "TXT_PINCODE_LOCALITY": "NUAPARA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3887,
      "City District Name": "KENDRAPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754253,
      "TXT_PINCODE_LOCALITY": "BEDARI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3887,
      "City District Name": "KENDRAPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754260,
      "TXT_PINCODE_LOCALITY": "PADMAPUR (ATHGARH)",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3490,
      "City District Name": "JAGATSINGHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754261,
      "TXT_PINCODE_LOCALITY": "ROUTSAHI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 442913,
      "City District Name": "BARI - RAMACHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754278,
      "TXT_PINCODE_LOCALITY": "DOBANDHIA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 442929,
      "City District Name": "MAHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754280,
      "TXT_PINCODE_LOCALITY": "CHARIRAKABA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754281,
      "TXT_PINCODE_LOCALITY": "BADAMUNDAI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754282,
      "TXT_PINCODE_LOCALITY": "KHANDASAHI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3887,
      "City District Name": "KENDRAPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754283,
      "TXT_PINCODE_LOCALITY": "SUKARPARA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754284,
      "TXT_PINCODE_LOCALITY": "JIGNIPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754285,
      "TXT_PINCODE_LOCALITY": "KUSUPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754286,
      "TXT_PINCODE_LOCALITY": "SUKLESWAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754287,
      "TXT_PINCODE_LOCALITY": "KUMRA JAIPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754288,
      "TXT_PINCODE_LOCALITY": "LALITGIRI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754289,
      "TXT_PINCODE_LOCALITY": "DERABIS",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754290,
      "TXT_PINCODE_LOCALITY": "MAUDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754291,
      "TXT_PINCODE_LOCALITY": "JHADESWARPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754292,
      "TXT_PINCODE_LOCALITY": "CHARINANGAL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754293,
      "TXT_PINCODE_LOCALITY": "CHARIRAKABA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754294,
      "TXT_PINCODE_LOCALITY": "ALIPINGAL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754295,
      "TXT_PINCODE_LOCALITY": "SAMSARPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754296,
      "TXT_PINCODE_LOCALITY": "SUNGURA S.O (JAJAPUR)",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754297,
      "TXT_PINCODE_LOCALITY": "KHUNTUNI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754298,
      "TXT_PINCODE_LOCALITY": "KALARABANKA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754428,
      "TXT_PINCODE_LOCALITY": "SHYAMSUNDERPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 442924,
      "City District Name": "KENDRAPARA SADAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 754928,
      "TXT_PINCODE_LOCALITY": "KUANRPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 442917,
      "City District Name": "DERABISH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755001,
      "TXT_PINCODE_LOCALITY": "JAIPUR COLLEGE",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755003,
      "TXT_PINCODE_LOCALITY": "BARI CUTTACK",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755004,
      "TXT_PINCODE_LOCALITY": "BINJHARPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755005,
      "TXT_PINCODE_LOCALITY": "BRAHMABARADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755006,
      "TXT_PINCODE_LOCALITY": "DASARATHPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755007,
      "TXT_PINCODE_LOCALITY": "DEBIDWAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755008,
      "TXT_PINCODE_LOCALITY": "DHARAMSHALA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755009,
      "TXT_PINCODE_LOCALITY": "KABIRPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755010,
      "TXT_PINCODE_LOCALITY": "KAIPARA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755011,
      "TXT_PINCODE_LOCALITY": "MANGALPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755012,
      "TXT_PINCODE_LOCALITY": "MASHRA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755013,
      "TXT_PINCODE_LOCALITY": "PRITIPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755014,
      "TXT_PINCODE_LOCALITY": "RAMBAGH",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755015,
      "TXT_PINCODE_LOCALITY": "SANKHACHILLA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3887,
      "City District Name": "KENDRAPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755016,
      "TXT_PINCODE_LOCALITY": "SINGHPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755017,
      "TXT_PINCODE_LOCALITY": "SUJANPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755018,
      "TXT_PINCODE_LOCALITY": "SUKINDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755019,
      "TXT_PINCODE_LOCALITY": "JAJPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2529,
      "City District Name": "BHUBANESWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755020,
      "TXT_PINCODE_LOCALITY": "FERROCHROME PROJECT",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1007,
      "City District Name": "JAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755021,
      "TXT_PINCODE_LOCALITY": "BAINSARIA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755022,
      "TXT_PINCODE_LOCALITY": "KORAI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755023,
      "TXT_PINCODE_LOCALITY": "JENAPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755024,
      "TXT_PINCODE_LOCALITY": "HARIDASPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755025,
      "TXT_PINCODE_LOCALITY": "BARUNDAI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755026,
      "TXT_PINCODE_LOCALITY": "DUBURI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1007,
      "City District Name": "JAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755027,
      "TXT_PINCODE_LOCALITY": "AREI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755028,
      "TXT_PINCODE_LOCALITY": "KALARINGATTA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755029,
      "TXT_PINCODE_LOCALITY": "KODANDAPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755030,
      "TXT_PINCODE_LOCALITY": "KALYANPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755031,
      "TXT_PINCODE_LOCALITY": "KAMAGARH",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755032,
      "TXT_PINCODE_LOCALITY": "RAMA CHANDRAPUR (JAIPUR)",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755033,
      "TXT_PINCODE_LOCALITY": "RURHIYA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755034,
      "TXT_PINCODE_LOCALITY": "RASULPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3887,
      "City District Name": "KENDRAPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755035,
      "TXT_PINCODE_LOCALITY": "ANGALO",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755036,
      "TXT_PINCODE_LOCALITY": "AHIYAS",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755037,
      "TXT_PINCODE_LOCALITY": "KANIKAPARA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755038,
      "TXT_PINCODE_LOCALITY": "BAMDEVPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755039,
      "TXT_PINCODE_LOCALITY": "MERADAKATIA CHHAK",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755040,
      "TXT_PINCODE_LOCALITY": "NIAMATPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 442925,
      "City District Name": "KUAKHIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755041,
      "TXT_PINCODE_LOCALITY": "GARHMADHUPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755042,
      "TXT_PINCODE_LOCALITY": "RAGADI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755043,
      "TXT_PINCODE_LOCALITY": "PANIKOILI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755044,
      "TXT_PINCODE_LOCALITY": "CHANDIKHOL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755045,
      "TXT_PINCODE_LOCALITY": "KOTPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755046,
      "TXT_PINCODE_LOCALITY": "HATIBARI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1007,
      "City District Name": "JAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755047,
      "TXT_PINCODE_LOCALITY": "KALIAPANI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755048,
      "TXT_PINCODE_LOCALITY": "DALA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755049,
      "TXT_PINCODE_LOCALITY": "KANTIGARIA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755050,
      "TXT_PINCODE_LOCALITY": "JARAKA BAZAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755051,
      "TXT_PINCODE_LOCALITY": "DHARMASALA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755061,
      "TXT_PINCODE_LOCALITY": "GOVINDAPUR KATCHERY",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755062,
      "TXT_PINCODE_LOCALITY": "SASANDABINDHA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 755207,
      "TXT_PINCODE_LOCALITY": "AREI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756001,
      "TXT_PINCODE_LOCALITY": "BALASORE COURT",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2274,
      "City District Name": "BALESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756002,
      "TXT_PINCODE_LOCALITY": "SUNHAT",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2274,
      "City District Name": "BALESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756003,
      "TXT_PINCODE_LOCALITY": "MAKALPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756017,
      "TXT_PINCODE_LOCALITY": "SARGAON",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756018,
      "TXT_PINCODE_LOCALITY": "KHIRCHORA GOPINATH",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756019,
      "TXT_PINCODE_LOCALITY": "REMUNA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2274,
      "City District Name": "BALESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756020,
      "TXT_PINCODE_LOCALITY": "BALASORE S. O",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756021,
      "TXT_PINCODE_LOCALITY": "REMUNA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756022,
      "TXT_PINCODE_LOCALITY": "DARADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756023,
      "TXT_PINCODE_LOCALITY": "SINGLA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756024,
      "TXT_PINCODE_LOCALITY": "NANGALESWAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756025,
      "TXT_PINCODE_LOCALITY": "CHANDIPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756026,
      "TXT_PINCODE_LOCALITY": "BALIAPAL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756027,
      "TXT_PINCODE_LOCALITY": "HALDIPADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756028,
      "TXT_PINCODE_LOCALITY": "RUPSA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756029,
      "TXT_PINCODE_LOCALITY": "BASTA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756030,
      "TXT_PINCODE_LOCALITY": "AMARDA ROAD",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756031,
      "TXT_PINCODE_LOCALITY": "JAGANATHAPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 4825,
      "City District Name": "NILAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756032,
      "TXT_PINCODE_LOCALITY": "JALESWAR R S",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756033,
      "TXT_PINCODE_LOCALITY": "BALASORE S. O.",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756034,
      "TXT_PINCODE_LOCALITY": "NAMPO",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756035,
      "TXT_PINCODE_LOCALITY": "BALASORE.",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756036,
      "TXT_PINCODE_LOCALITY": "BALASORE B. O",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756037,
      "TXT_PINCODE_LOCALITY": "FULBANI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756038,
      "TXT_PINCODE_LOCALITY": "BHOGARAI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756039,
      "TXT_PINCODE_LOCALITY": "KAKHRA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756040,
      "TXT_PINCODE_LOCALITY": "RAJNILGIRI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756041,
      "TXT_PINCODE_LOCALITY": "BALASORE",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756042,
      "TXT_PINCODE_LOCALITY": "BAHANAGA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756043,
      "TXT_PINCODE_LOCALITY": "BALASORE.",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756044,
      "TXT_PINCODE_LOCALITY": "GOPALPUR (BIS)",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756045,
      "TXT_PINCODE_LOCALITY": "BALASORE S. O.",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756046,
      "TXT_PINCODE_LOCALITY": "BALASORE S. O",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756047,
      "TXT_PINCODE_LOCALITY": "TURIGARIA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756048,
      "TXT_PINCODE_LOCALITY": "BALASORE B. O.",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756049,
      "TXT_PINCODE_LOCALITY": "OUPADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756050,
      "TXT_PINCODE_LOCALITY": "JUGPURA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756051,
      "TXT_PINCODE_LOCALITY": "AVANA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756052,
      "TXT_PINCODE_LOCALITY": "KUDEINADI GAON",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756053,
      "TXT_PINCODE_LOCALITY": "CHAK JAGANNATHPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756054,
      "TXT_PINCODE_LOCALITY": "CHAK BARHAPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756055,
      "TXT_PINCODE_LOCALITY": "BHAUNRIABAD",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756056,
      "TXT_PINCODE_LOCALITY": "KURDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756057,
      "TXT_PINCODE_LOCALITY": "BALARAMGADI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756058,
      "TXT_PINCODE_LOCALITY": "RAJ BERHAMPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756059,
      "TXT_PINCODE_LOCALITY": "KUPARI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2274,
      "City District Name": "BALESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756060,
      "TXT_PINCODE_LOCALITY": "SERAGARH",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2274,
      "City District Name": "BALESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756071,
      "TXT_PINCODE_LOCALITY": "SADANANDAPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2274,
      "City District Name": "BALESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756072,
      "TXT_PINCODE_LOCALITY": "MANDURKULA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2274,
      "City District Name": "BALESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756073,
      "TXT_PINCODE_LOCALITY": "HALDIAPADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2274,
      "City District Name": "BALESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756074,
      "TXT_PINCODE_LOCALITY": "DANDIKA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2274,
      "City District Name": "BALESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756075,
      "TXT_PINCODE_LOCALITY": "RAIBANIA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2274,
      "City District Name": "BALESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756076,
      "TXT_PINCODE_LOCALITY": "LAKHNNATH ROAD",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2274,
      "City District Name": "BALESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756077,
      "TXT_PINCODE_LOCALITY": "SARTHA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2274,
      "City District Name": "BALESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756078,
      "TXT_PINCODE_LOCALITY": "BALESHWAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2274,
      "City District Name": "BALESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756079,
      "TXT_PINCODE_LOCALITY": "DAHAMUNDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2274,
      "City District Name": "BALESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756080,
      "TXT_PINCODE_LOCALITY": "IRDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2274,
      "City District Name": "BALESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756081,
      "TXT_PINCODE_LOCALITY": "JAMSULI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2274,
      "City District Name": "BALESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756082,
      "TXT_PINCODE_LOCALITY": "MACHHADIHA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2274,
      "City District Name": "BALESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756083,
      "TXT_PINCODE_LOCALITY": "PRATAPPUR (JALESWAR)",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2274,
      "City District Name": "BALESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756084,
      "TXT_PINCODE_LOCALITY": "D K COLLEGE",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2274,
      "City District Name": "BALESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756085,
      "TXT_PINCODE_LOCALITY": "CHANDANESWAR S.O",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2274,
      "City District Name": "BALESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756086,
      "TXT_PINCODE_LOCALITY": "JALESWAR RAILWAY STATION",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2274,
      "City District Name": "BALESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756087,
      "TXT_PINCODE_LOCALITY": "JALESWAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2274,
      "City District Name": "BALESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756088,
      "TXT_PINCODE_LOCALITY": "CHORMARA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756089,
      "TXT_PINCODE_LOCALITY": "VYASAVIHAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 220,
      "City District Name": "BALASORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756100,
      "TXT_PINCODE_LOCALITY": "BHADRAK HO.",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1459,
      "City District Name": "BHADRAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756101,
      "TXT_PINCODE_LOCALITY": "BHADRAK R S",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1459,
      "City District Name": "BHADRAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756110,
      "TXT_PINCODE_LOCALITY": "BHADRAK",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1459,
      "City District Name": "BHADRAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756111,
      "TXT_PINCODE_LOCALITY": "RANITAL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2274,
      "City District Name": "BALESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756112,
      "TXT_PINCODE_LOCALITY": "BANT",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1459,
      "City District Name": "BHADRAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756113,
      "TXT_PINCODE_LOCALITY": "BARPADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2274,
      "City District Name": "BALESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756114,
      "TXT_PINCODE_LOCALITY": "BANT",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1459,
      "City District Name": "BHADRAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756115,
      "TXT_PINCODE_LOCALITY": "BANT S.O",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1459,
      "City District Name": "BHADRAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756116,
      "TXT_PINCODE_LOCALITY": "ARNAPAL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1459,
      "City District Name": "BHADRAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756117,
      "TXT_PINCODE_LOCALITY": "DAKHINBAD",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2274,
      "City District Name": "BALESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756118,
      "TXT_PINCODE_LOCALITY": "KOTHAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1459,
      "City District Name": "BHADRAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756119,
      "TXT_PINCODE_LOCALITY": "DHUSURI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1459,
      "City District Name": "BHADRAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756120,
      "TXT_PINCODE_LOCALITY": "BHANDANIPOKHARI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1459,
      "City District Name": "BHADRAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756121,
      "TXT_PINCODE_LOCALITY": "MANJURI ROAD",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1459,
      "City District Name": "BHADRAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756122,
      "TXT_PINCODE_LOCALITY": "AKHUAPADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1459,
      "City District Name": "BHADRAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756123,
      "TXT_PINCODE_LOCALITY": "SABRANG",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1459,
      "City District Name": "BHADRAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756124,
      "TXT_PINCODE_LOCALITY": "ERTAL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1459,
      "City District Name": "BHADRAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756125,
      "TXT_PINCODE_LOCALITY": "BASUDEVPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2274,
      "City District Name": "BALESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756126,
      "TXT_PINCODE_LOCALITY": "SIMULIA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2274,
      "City District Name": "BALESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756127,
      "TXT_PINCODE_LOCALITY": "DOLASAHI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1459,
      "City District Name": "BHADRAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756128,
      "TXT_PINCODE_LOCALITY": "GUJIDARADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1459,
      "City District Name": "BHADRAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756129,
      "TXT_PINCODE_LOCALITY": "GHANTESWAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1459,
      "City District Name": "BHADRAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756130,
      "TXT_PINCODE_LOCALITY": "TIHIRI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1459,
      "City District Name": "BHADRAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756131,
      "TXT_PINCODE_LOCALITY": "PIRHATBAZAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2274,
      "City District Name": "BALESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756132,
      "TXT_PINCODE_LOCALITY": "MOTTO",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1459,
      "City District Name": "BHADRAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756133,
      "TXT_PINCODE_LOCALITY": "CHANDBALI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1459,
      "City District Name": "BHADRAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756134,
      "TXT_PINCODE_LOCALITY": "ADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2274,
      "City District Name": "BALESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756135,
      "TXT_PINCODE_LOCALITY": "RANDIAHAT",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1459,
      "City District Name": "BHADRAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756136,
      "TXT_PINCODE_LOCALITY": "BALESHWAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2274,
      "City District Name": "BALESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756137,
      "TXT_PINCODE_LOCALITY": "ASURALI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1459,
      "City District Name": "BHADRAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756138,
      "TXT_PINCODE_LOCALITY": "ARAD",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1459,
      "City District Name": "BHADRAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756139,
      "TXT_PINCODE_LOCALITY": "ARSA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2274,
      "City District Name": "BALESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756140,
      "TXT_PINCODE_LOCALITY": "ADHUAN",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1459,
      "City District Name": "BHADRAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756141,
      "TXT_PINCODE_LOCALITY": "HATIDIHI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756142,
      "TXT_PINCODE_LOCALITY": "KENDUAPADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2274,
      "City District Name": "BALESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756143,
      "TXT_PINCODE_LOCALITY": "NANDIGAON",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2274,
      "City District Name": "BALESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756144,
      "TXT_PINCODE_LOCALITY": "BASANTIA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1459,
      "City District Name": "BHADRAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756161,
      "TXT_PINCODE_LOCALITY": "BIDYADHARPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2274,
      "City District Name": "BALESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756162,
      "TXT_PINCODE_LOCALITY": "ERAM",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1459,
      "City District Name": "BHADRAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756163,
      "TXT_PINCODE_LOCALITY": "GUAMAL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1459,
      "City District Name": "BHADRAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756164,
      "TXT_PINCODE_LOCALITY": "NAIKANIDIHI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1459,
      "City District Name": "BHADRAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756165,
      "TXT_PINCODE_LOCALITY": "BRAHMANGAON",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1459,
      "City District Name": "BHADRAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756166,
      "TXT_PINCODE_LOCALITY": "BALIKHAND",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2274,
      "City District Name": "BALESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756167,
      "TXT_PINCODE_LOCALITY": "PILIABINDHA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1459,
      "City District Name": "BHADRAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756168,
      "TXT_PINCODE_LOCALITY": "BETADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2274,
      "City District Name": "BALESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756169,
      "TXT_PINCODE_LOCALITY": "DUNGURA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2274,
      "City District Name": "BALESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756171,
      "TXT_PINCODE_LOCALITY": "DHAMARA S.O",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1459,
      "City District Name": "BHADRAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756176,
      "TXT_PINCODE_LOCALITY": "RABINDRANAGAR.",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 442912,
      "City District Name": "BANSADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756181,
      "TXT_PINCODE_LOCALITY": "BAUDPUR MADHABNAGAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1459,
      "City District Name": "BHADRAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756182,
      "TXT_PINCODE_LOCALITY": "DUNGURA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2274,
      "City District Name": "BALESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 756214,
      "TXT_PINCODE_LOCALITY": "ALBAGA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1459,
      "City District Name": "BHADRAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757000,
      "TXT_PINCODE_LOCALITY": "SRIDAMCHANDRAPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757001,
      "TXT_PINCODE_LOCALITY": "TULASICHAURA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757002,
      "TXT_PINCODE_LOCALITY": "BIJAYA RAMCHANDRAPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757003,
      "TXT_PINCODE_LOCALITY": "TAKATPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757013,
      "TXT_PINCODE_LOCALITY": "MAYURBHANJ",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 425720,
      "City District Name": "MAYURBHANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757014,
      "TXT_PINCODE_LOCALITY": "CHANDUA ASHRAM",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757015,
      "TXT_PINCODE_LOCALITY": "KUJIDIHI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757016,
      "TXT_PINCODE_LOCALITY": "RASGOVINDPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757017,
      "TXT_PINCODE_LOCALITY": "MANTRI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757018,
      "TXT_PINCODE_LOCALITY": "CHITRADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757019,
      "TXT_PINCODE_LOCALITY": "KHUNTA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757020,
      "TXT_PINCODE_LOCALITY": "MORDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757021,
      "TXT_PINCODE_LOCALITY": "DEOLI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757022,
      "TXT_PINCODE_LOCALITY": "SULIAPADA S.O",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 425720,
      "City District Name": "MAYURBHANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757023,
      "TXT_PINCODE_LOCALITY": "GARIA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757024,
      "TXT_PINCODE_LOCALITY": "SANKERKO",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757025,
      "TXT_PINCODE_LOCALITY": "BETNOTI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757026,
      "TXT_PINCODE_LOCALITY": "BARSAHI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757027,
      "TXT_PINCODE_LOCALITY": "KUAMARA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757028,
      "TXT_PINCODE_LOCALITY": "BAISINGA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757029,
      "TXT_PINCODE_LOCALITY": "KRISHNA CHANDRAPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757030,
      "TXT_PINCODE_LOCALITY": "KULIANA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757031,
      "TXT_PINCODE_LOCALITY": "SHIRSA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757032,
      "TXT_PINCODE_LOCALITY": "BANGIRIPOSI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757033,
      "TXT_PINCODE_LOCALITY": "BISOI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757034,
      "TXT_PINCODE_LOCALITY": "JOSHIPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757035,
      "TXT_PINCODE_LOCALITY": "RARUAN",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757036,
      "TXT_PINCODE_LOCALITY": "TATO",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757037,
      "TXT_PINCODE_LOCALITY": "KARANJIA COURT",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757038,
      "TXT_PINCODE_LOCALITY": "THAKURMUNDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757039,
      "TXT_PINCODE_LOCALITY": "SUKRULI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757040,
      "TXT_PINCODE_LOCALITY": "KAPTIPADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757041,
      "TXT_PINCODE_LOCALITY": "UDALA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757042,
      "TXT_PINCODE_LOCALITY": "GORUMOISANI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757043,
      "TXT_PINCODE_LOCALITY": "RAIRANGPUR BAZAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757044,
      "TXT_PINCODE_LOCALITY": "MAYURBHANJ",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 425720,
      "City District Name": "MAYURBHANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757045,
      "TXT_PINCODE_LOCALITY": "JAMDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757046,
      "TXT_PINCODE_LOCALITY": "BAHALDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757047,
      "TXT_PINCODE_LOCALITY": "BADAMPAHAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757048,
      "TXT_PINCODE_LOCALITY": "BIJATALA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757049,
      "TXT_PINCODE_LOCALITY": "BARIPADA SADAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 425720,
      "City District Name": "MAYURBHANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757050,
      "TXT_PINCODE_LOCALITY": "HATBADRA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757051,
      "TXT_PINCODE_LOCALITY": "SARASKANA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757052,
      "TXT_PINCODE_LOCALITY": "KUSUMI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757053,
      "TXT_PINCODE_LOCALITY": "TIRING",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757054,
      "TXT_PINCODE_LOCALITY": "BAHALDA ROAD R S",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757055,
      "TXT_PINCODE_LOCALITY": "AMARDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757056,
      "TXT_PINCODE_LOCALITY": "TUDUKAM",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 442942,
      "City District Name": "TIRING",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757057,
      "TXT_PINCODE_LOCALITY": "MAYURBHANJ.",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 425720,
      "City District Name": "MAYURBHANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757071,
      "TXT_PINCODE_LOCALITY": "CHANDANPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757072,
      "TXT_PINCODE_LOCALITY": "PAIKSAHI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757073,
      "TXT_PINCODE_LOCALITY": "NALAGAJA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757074,
      "TXT_PINCODE_LOCALITY": "BASIPITHA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757075,
      "TXT_PINCODE_LOCALITY": "DUKURU",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757076,
      "TXT_PINCODE_LOCALITY": "KHAUNUA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757077,
      "TXT_PINCODE_LOCALITY": "NUDADIHA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757078,
      "TXT_PINCODE_LOCALITY": "BADJOD",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757079,
      "TXT_PINCODE_LOCALITY": "SARAT",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757080,
      "TXT_PINCODE_LOCALITY": "SRIDAMCHANDRAPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757081,
      "TXT_PINCODE_LOCALITY": "KUMBHAR MUNDAKATA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757082,
      "TXT_PINCODE_LOCALITY": "GARHDEULIA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757083,
      "TXT_PINCODE_LOCALITY": "BHIMDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757084,
      "TXT_PINCODE_LOCALITY": "KOSTHA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757085,
      "TXT_PINCODE_LOCALITY": "KUSALDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757086,
      "TXT_PINCODE_LOCALITY": "JHADPOKHRIA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757087,
      "TXT_PINCODE_LOCALITY": "BRUNDABANCHANDRAPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757091,
      "TXT_PINCODE_LOCALITY": "KHAIRI JASHIPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757092,
      "TXT_PINCODE_LOCALITY": "KUSUMBANDI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757093,
      "TXT_PINCODE_LOCALITY": "JOKA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757100,
      "TXT_PINCODE_LOCALITY": "BADCHHURUNI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 425720,
      "City District Name": "MAYURBHANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757101,
      "TXT_PINCODE_LOCALITY": "RADHO",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757102,
      "TXT_PINCODE_LOCALITY": "PURUNA BARIPADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757103,
      "TXT_PINCODE_LOCALITY": "PRATAPPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757104,
      "TXT_PINCODE_LOCALITY": "BAHANADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757105,
      "TXT_PINCODE_LOCALITY": "KUCHEI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757106,
      "TXT_PINCODE_LOCALITY": "SALCHUA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757107,
      "TXT_PINCODE_LOCALITY": "GARUDNESA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 425720,
      "City District Name": "MAYURBHANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 757110,
      "TXT_PINCODE_LOCALITY": "BAHANANDA SATHI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6470,
      "City District Name": "MAYURBHANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758001,
      "TXT_PINCODE_LOCALITY": "KEONJHARGARH H O",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758002,
      "TXT_PINCODE_LOCALITY": "KEONJHAT BAZAR(KEONJ)",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758013,
      "TXT_PINCODE_LOCALITY": "KEONJHAR.",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 772,
      "City District Name": "KEONJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758014,
      "TXT_PINCODE_LOCALITY": "NARANPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758015,
      "TXT_PINCODE_LOCALITY": "GHASIPURA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758016,
      "TXT_PINCODE_LOCALITY": "SAHARPADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758017,
      "TXT_PINCODE_LOCALITY": "RAJNAGAR (KEONJHAR)",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758018,
      "TXT_PINCODE_LOCALITY": "SUAKATI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758019,
      "TXT_PINCODE_LOCALITY": "TELKOI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758020,
      "TXT_PINCODE_LOCALITY": "SALAPADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3510,
      "City District Name": "JAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758021,
      "TXT_PINCODE_LOCALITY": "ANANDAPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758022,
      "TXT_PINCODE_LOCALITY": "FAKIRPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758023,
      "TXT_PINCODE_LOCALITY": "HADGARH",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758025,
      "TXT_PINCODE_LOCALITY": "KUSALESWAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758026,
      "TXT_PINCODE_LOCALITY": "TALPADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758027,
      "TXT_PINCODE_LOCALITY": "GHATGAON",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758028,
      "TXT_PINCODE_LOCALITY": "HARICHANDANPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758029,
      "TXT_PINCODE_LOCALITY": "DHENKIKOTE",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758030,
      "TXT_PINCODE_LOCALITY": "SWAM PATNA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758031,
      "TXT_PINCODE_LOCALITY": "JHUMPURA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758032,
      "TXT_PINCODE_LOCALITY": "UKHUNDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758033,
      "TXT_PINCODE_LOCALITY": "BALIPARBAT",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758034,
      "TXT_PINCODE_LOCALITY": "JODA BAZAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758035,
      "TXT_PINCODE_LOCALITY": "BARBIL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758036,
      "TXT_PINCODE_LOCALITY": "MATKAMBEDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 425482,
      "City District Name": "KENDUJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758037,
      "TXT_PINCODE_LOCALITY": "BOLANI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758038,
      "TXT_PINCODE_LOCALITY": "BANAIKELA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758040,
      "TXT_PINCODE_LOCALITY": "KIRIBUR HILL TOP",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758041,
      "TXT_PINCODE_LOCALITY": "CHAMPUA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758042,
      "TXT_PINCODE_LOCALITY": "NALDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758043,
      "TXT_PINCODE_LOCALITY": "SAINKUL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758044,
      "TXT_PINCODE_LOCALITY": "KARANJIA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758045,
      "TXT_PINCODE_LOCALITY": "UDAYAPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758046,
      "TXT_PINCODE_LOCALITY": "TURUMUNGA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758047,
      "TXT_PINCODE_LOCALITY": "REMULI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758048,
      "TXT_PINCODE_LOCALITY": "KALIKAPRASAD",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758049,
      "TXT_PINCODE_LOCALITY": "DHAKOTA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758051,
      "TXT_PINCODE_LOCALITY": "BHADRASAHI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758052,
      "TXT_PINCODE_LOCALITY": "JAJANG",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758053,
      "TXT_PINCODE_LOCALITY": "CHAMAKPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758054,
      "TXT_PINCODE_LOCALITY": "PALASPANGA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758061,
      "TXT_PINCODE_LOCALITY": "RAMACHANDRAPUR (KEONJHAR)",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758062,
      "TXT_PINCODE_LOCALITY": "PANCHAPALLY",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758063,
      "TXT_PINCODE_LOCALITY": "KHALIAMENTA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758064,
      "TXT_PINCODE_LOCALITY": "KANTIPAL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758071,
      "TXT_PINCODE_LOCALITY": "PANDAPADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758072,
      "TXT_PINCODE_LOCALITY": "BANSPAL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758073,
      "TXT_PINCODE_LOCALITY": "PADMAPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758074,
      "TXT_PINCODE_LOCALITY": "MANDUA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758075,
      "TXT_PINCODE_LOCALITY": "KHIREI TANGIRI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758076,
      "TXT_PINCODE_LOCALITY": "SIRIGIDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758077,
      "TXT_PINCODE_LOCALITY": "KESUDURAPAL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758078,
      "TXT_PINCODE_LOCALITY": "DHANURJAYAPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758079,
      "TXT_PINCODE_LOCALITY": "JANGHIRA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758080,
      "TXT_PINCODE_LOCALITY": "BHAGAMUNDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758081,
      "TXT_PINCODE_LOCALITY": "MACHHAGARH",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3890,
      "City District Name": "KEONJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758082,
      "TXT_PINCODE_LOCALITY": "BALIPARBAT",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 425482,
      "City District Name": "KENDUJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758083,
      "TXT_PINCODE_LOCALITY": "ORALI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 425482,
      "City District Name": "KENDUJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758084,
      "TXT_PINCODE_LOCALITY": "TANA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 442922,
      "City District Name": "KANJIPANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758085,
      "TXT_PINCODE_LOCALITY": "PURUNAPANI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 442939,
      "City District Name": "TELKOI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758086,
      "TXT_PINCODE_LOCALITY": "BAMEBARI S.O",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 425482,
      "City District Name": "KENDUJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 758087,
      "TXT_PINCODE_LOCALITY": "KENDUJHAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 425482,
      "City District Name": "KENDUJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759001,
      "TXT_PINCODE_LOCALITY": "BAJI CHOWK",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1802,
      "City District Name": "DHENKANAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759012,
      "TXT_PINCODE_LOCALITY": "PALIKATENI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 442932,
      "City District Name": "PARAJANG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759013,
      "TXT_PINCODE_LOCALITY": "GENGUTIA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1802,
      "City District Name": "DHENKANAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759014,
      "TXT_PINCODE_LOCALITY": "KALURIA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1802,
      "City District Name": "DHENKANAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759015,
      "TXT_PINCODE_LOCALITY": "KALANGA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1802,
      "City District Name": "DHENKANAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759016,
      "TXT_PINCODE_LOCALITY": "GORIDIAPATNA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1802,
      "City District Name": "DHENKANAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759017,
      "TXT_PINCODE_LOCALITY": "BHUBAN",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1802,
      "City District Name": "DHENKANAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759018,
      "TXT_PINCODE_LOCALITY": "KAMARDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1802,
      "City District Name": "DHENKANAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759019,
      "TXT_PINCODE_LOCALITY": "HINDOL ROAD R S",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1802,
      "City District Name": "DHENKANAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759020,
      "TXT_PINCODE_LOCALITY": "BALIMI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1802,
      "City District Name": "DHENKANAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759021,
      "TXT_PINCODE_LOCALITY": "RASOL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1802,
      "City District Name": "DHENKANAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759022,
      "TXT_PINCODE_LOCALITY": "HINDOL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1802,
      "City District Name": "DHENKANAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759023,
      "TXT_PINCODE_LOCALITY": "MARTHAPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1802,
      "City District Name": "DHENKANAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759024,
      "TXT_PINCODE_LOCALITY": "JAMUNAKOTE",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1802,
      "City District Name": "DHENKANAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759025,
      "TXT_PINCODE_LOCALITY": "GUNDICHAPADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1802,
      "City District Name": "DHENKANAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759026,
      "TXT_PINCODE_LOCALITY": "ICHHABATIPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1802,
      "City District Name": "DHENKANAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759027,
      "TXT_PINCODE_LOCALITY": "KAIMATI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1802,
      "City District Name": "DHENKANAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759028,
      "TXT_PINCODE_LOCALITY": "KANDHARA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1802,
      "City District Name": "DHENKANAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759029,
      "TXT_PINCODE_LOCALITY": "FULPADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2084,
      "City District Name": "ANUGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759030,
      "TXT_PINCODE_LOCALITY": "SADANGI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1802,
      "City District Name": "DHENKANAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759031,
      "TXT_PINCODE_LOCALITY": "BAULPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1802,
      "City District Name": "DHENKANAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759032,
      "TXT_PINCODE_LOCALITY": "DHALPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1802,
      "City District Name": "DHENKANAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759033,
      "TXT_PINCODE_LOCALITY": "KARANDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1802,
      "City District Name": "DHENKANAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759034,
      "TXT_PINCODE_LOCALITY": "BALARAMPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2084,
      "City District Name": "ANUGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759035,
      "TXT_PINCODE_LOCALITY": "KANTIOKATENI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1802,
      "City District Name": "DHENKANAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759036,
      "TXT_PINCODE_LOCALITY": "BADAKHAMAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1802,
      "City District Name": "DHENKANAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759037,
      "TXT_PINCODE_LOCALITY": "SAMAL BARRAGE",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1802,
      "City District Name": "DHENKANAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759039,
      "TXT_PINCODE_LOCALITY": "JAGANNATHPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1802,
      "City District Name": "DHENKANAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759040,
      "TXT_PINCODE_LOCALITY": "TALMUL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1423,
      "City District Name": "ANGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759100,
      "TXT_PINCODE_LOCALITY": "HATATOTA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1423,
      "City District Name": "ANGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759101,
      "TXT_PINCODE_LOCALITY": "TALCHER THERMAL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2084,
      "City District Name": "ANUGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759102,
      "TXT_PINCODE_LOCALITY": "DEULBERA COLLIERY",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2084,
      "City District Name": "ANUGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759103,
      "TXT_PINCODE_LOCALITY": "DERA COLLIARY",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2084,
      "City District Name": "ANUGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759104,
      "TXT_PINCODE_LOCALITY": "CHAINPAL COLONY *",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2084,
      "City District Name": "ANUGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759105,
      "TXT_PINCODE_LOCALITY": "RENGALI DAMSITE",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2084,
      "City District Name": "ANUGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759106,
      "TXT_PINCODE_LOCALITY": "VIKRAMPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2084,
      "City District Name": "ANUGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759107,
      "TXT_PINCODE_LOCALITY": "TALCHER TOWN",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2084,
      "City District Name": "ANUGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759110,
      "TXT_PINCODE_LOCALITY": "TALCHER",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 794,
      "City District Name": "TALCHER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759111,
      "TXT_PINCODE_LOCALITY": "CHHENDIPADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1423,
      "City District Name": "ANGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759114,
      "TXT_PINCODE_LOCALITY": "CHHENDIPADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2084,
      "City District Name": "ANUGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759116,
      "TXT_PINCODE_LOCALITY": "BALANDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2084,
      "City District Name": "ANUGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759117,
      "TXT_PINCODE_LOCALITY": "KANIHA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2084,
      "City District Name": "ANUGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759118,
      "TXT_PINCODE_LOCALITY": "ANGUL S. O",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1423,
      "City District Name": "ANGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759119,
      "TXT_PINCODE_LOCALITY": "PALLAHARA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2084,
      "City District Name": "ANUGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759120,
      "TXT_PINCODE_LOCALITY": "PARJANG",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2084,
      "City District Name": "ANUGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759121,
      "TXT_PINCODE_LOCALITY": "MERAMANDALI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1802,
      "City District Name": "DHENKANAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759122,
      "TXT_PINCODE_LOCALITY": "PANJIASAHI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1423,
      "City District Name": "ANGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759123,
      "TXT_PINCODE_LOCALITY": "P T C ANGUL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2084,
      "City District Name": "ANUGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759124,
      "TXT_PINCODE_LOCALITY": "CHHENDIPADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2084,
      "City District Name": "ANUGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759125,
      "TXT_PINCODE_LOCALITY": "ATHMALLIK",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2084,
      "City District Name": "ANUGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759126,
      "TXT_PINCODE_LOCALITY": "RAJKISHERENAGAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2084,
      "City District Name": "ANUGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759127,
      "TXT_PINCODE_LOCALITY": "KISHOREGANJ",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2084,
      "City District Name": "ANUGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759128,
      "TXT_PINCODE_LOCALITY": "BANARPAL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2084,
      "City District Name": "ANUGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759129,
      "TXT_PINCODE_LOCALITY": "BANTALA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2084,
      "City District Name": "ANUGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759130,
      "TXT_PINCODE_LOCALITY": "KOSALA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2084,
      "City District Name": "ANUGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759131,
      "TXT_PINCODE_LOCALITY": "JAGANNATHPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1802,
      "City District Name": "DHENKANAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759132,
      "TXT_PINCODE_LOCALITY": "HULURSINGA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2084,
      "City District Name": "ANUGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759133,
      "TXT_PINCODE_LOCALITY": "BAMUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2084,
      "City District Name": "ANUGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759134,
      "TXT_PINCODE_LOCALITY": "HANDAPA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2084,
      "City District Name": "ANUGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759135,
      "TXT_PINCODE_LOCALITY": "GOTAMARA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2084,
      "City District Name": "ANUGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759136,
      "TXT_PINCODE_LOCALITY": "RAIJHARAN",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2084,
      "City District Name": "ANUGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759137,
      "TXT_PINCODE_LOCALITY": "KUMURSINGA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2084,
      "City District Name": "ANUGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759138,
      "TXT_PINCODE_LOCALITY": "JARAPADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2084,
      "City District Name": "ANUGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759139,
      "TXT_PINCODE_LOCALITY": "BUDHAPANK",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1802,
      "City District Name": "DHENKANAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759140,
      "TXT_PINCODE_LOCALITY": "KUMANDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2084,
      "City District Name": "ANUGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759141,
      "TXT_PINCODE_LOCALITY": "BAGDIA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2084,
      "City District Name": "ANUGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759142,
      "TXT_PINCODE_LOCALITY": "THAKURGARH",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2084,
      "City District Name": "ANUGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759143,
      "TXT_PINCODE_LOCALITY": "HAKIMPADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2084,
      "City District Name": "ANUGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759144,
      "TXT_PINCODE_LOCALITY": "TUBEY",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2084,
      "City District Name": "ANUGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759145,
      "TXT_PINCODE_LOCALITY": "NALCO NAGAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2084,
      "City District Name": "ANUGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759146,
      "TXT_PINCODE_LOCALITY": "KULEI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1423,
      "City District Name": "ANGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759147,
      "TXT_PINCODE_LOCALITY": "DEEPSHIKHA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1423,
      "City District Name": "ANGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759148,
      "TXT_PINCODE_LOCALITY": "NS NAGAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1423,
      "City District Name": "ANGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759149,
      "TXT_PINCODE_LOCALITY": "JARAPADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1802,
      "City District Name": "DHENKANAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 759151,
      "TXT_PINCODE_LOCALITY": "PABITRANAGAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1802,
      "City District Name": "DHENKANAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 800001,
      "TXT_PINCODE_LOCALITY": "PATNA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 800002,
      "TXT_PINCODE_LOCALITY": "ANISABAD",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 800003,
      "TXT_PINCODE_LOCALITY": "KADAMKUAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 800004,
      "TXT_PINCODE_LOCALITY": "BANKIPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 800005,
      "TXT_PINCODE_LOCALITY": "PATNA UNIVERSITY",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 800006,
      "TXT_PINCODE_LOCALITY": "MUSSALLEHPUR HAT NANMUHIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 800007,
      "TXT_PINCODE_LOCALITY": "GULZARBAGH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 800008,
      "TXT_PINCODE_LOCALITY": "S M MILLS",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 800009,
      "TXT_PINCODE_LOCALITY": "BEGAMPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 800010,
      "TXT_PINCODE_LOCALITY": "SADAQUAT ASHRAM",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 800011,
      "TXT_PINCODE_LOCALITY": "SANJIWAN PRESS",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 800012,
      "TXT_PINCODE_LOCALITY": "DIGHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 800013,
      "TXT_PINCODE_LOCALITY": "PATALIPUTRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 800014,
      "TXT_PINCODE_LOCALITY": "BIHAR VETERINARY COLLEGE",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 800015,
      "TXT_PINCODE_LOCALITY": "PATNA SECRETARIAT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 800016,
      "TXT_PINCODE_LOCALITY": "KUMHRAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 800017,
      "TXT_PINCODE_LOCALITY": "BIHAR SCHOOL EXAM BOARD",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 800018,
      "TXT_PINCODE_LOCALITY": "BATAGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 800019,
      "TXT_PINCODE_LOCALITY": "ACCOUNTS PATNA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 800020,
      "TXT_PINCODE_LOCALITY": "LOHIANAGAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 800021,
      "TXT_PINCODE_LOCALITY": "VIDYUT PARISHAD",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 800022,
      "TXT_PINCODE_LOCALITY": "BIHAR GOVERNERS CAMP",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 800023,
      "TXT_PINCODE_LOCALITY": "LAL BAHADUR SHASTRI NAGAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 800024,
      "TXT_PINCODE_LOCALITY": "KESHARI NAGAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 800025,
      "TXT_PINCODE_LOCALITY": "ASHIANA DIGHA ROAD",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 800026,
      "TXT_PINCODE_LOCALITY": "MAHATMA GANDHI NAGAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 800027,
      "TXT_PINCODE_LOCALITY": "NEW JAGANPURA S.O",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 800028,
      "TXT_PINCODE_LOCALITY": "LOHIYA NAGAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 800029,
      "TXT_PINCODE_LOCALITY": "NORTH SHASTRI NAGAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442610,
      "City District Name": "PATNA RURAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 800030,
      "TXT_PINCODE_LOCALITY": "PATNA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 800104,
      "TXT_PINCODE_LOCALITY": "KHAPURI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442512,
      "City District Name": "DULHIN BAZAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 800111,
      "TXT_PINCODE_LOCALITY": "KHATUN CHAK",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442595,
      "City District Name": "NAUBATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801002,
      "TXT_PINCODE_LOCALITY": "BHARATPURA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801021,
      "TXT_PINCODE_LOCALITY": "NAUDIHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3409,
      "City District Name": "HILSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801101,
      "TXT_PINCODE_LOCALITY": "ANANDPUR HOMEGUARD CENTRE",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801102,
      "TXT_PINCODE_LOCALITY": "BELHAURI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801103,
      "TXT_PINCODE_LOCALITY": "BIHTA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801104,
      "TXT_PINCODE_LOCALITY": "BIKRAM",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801105,
      "TXT_PINCODE_LOCALITY": "KHAGAUL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801106,
      "TXT_PINCODE_LOCALITY": "PATNA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801108,
      "TXT_PINCODE_LOCALITY": "MANER",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801109,
      "TXT_PINCODE_LOCALITY": "NAUBATPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801110,
      "TXT_PINCODE_LOCALITY": "PALIGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801111,
      "TXT_PINCODE_LOCALITY": "SADISOPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801112,
      "TXT_PINCODE_LOCALITY": "LAI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801113,
      "TXT_PINCODE_LOCALITY": "NEORA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801114,
      "TXT_PINCODE_LOCALITY": "CHANDAOS",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801115,
      "TXT_PINCODE_LOCALITY": "DATIANA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801116,
      "TXT_PINCODE_LOCALITY": "KITA CHOUHATAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801117,
      "TXT_PINCODE_LOCALITY": "AMARPURA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801118,
      "TXT_PINCODE_LOCALITY": "AMHARA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801119,
      "TXT_PINCODE_LOCALITY": "KAMLA GOPALPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801123,
      "TXT_PINCODE_LOCALITY": "BERHNA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801131,
      "TXT_PINCODE_LOCALITY": "SARANGPUR TILHARI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4404,
      "City District Name": "MANER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801213,
      "TXT_PINCODE_LOCALITY": "MANGRAWAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442593,
      "City District Name": "NASRIGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801214,
      "TXT_PINCODE_LOCALITY": "BHAGVATIPURKARMAUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801221,
      "TXT_PINCODE_LOCALITY": "SUREMARPUR (DITTO)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6442,
      "City District Name": "SHAHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801301,
      "TXT_PINCODE_LOCALITY": "EKANGERSARAI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801302,
      "TXT_PINCODE_LOCALITY": "HILSA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801303,
      "TXT_PINCODE_LOCALITY": "ISLAMPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801304,
      "TXT_PINCODE_LOCALITY": "KARAIPARSARAI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801305,
      "TXT_PINCODE_LOCALITY": "NAGARNAUSA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801306,
      "TXT_PINCODE_LOCALITY": "TELHARA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801307,
      "TXT_PINCODE_LOCALITY": "BHATHAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801308,
      "TXT_PINCODE_LOCALITY": "MAI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801309,
      "TXT_PINCODE_LOCALITY": "EKANGERDIH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801310,
      "TXT_PINCODE_LOCALITY": "KHODAGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3850,
      "City District Name": "KATIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801311,
      "TXT_PINCODE_LOCALITY": "MAHMUDA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801501,
      "TXT_PINCODE_LOCALITY": "DINAPUR CANTONMENT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442509,
      "City District Name": "DINAPUR-CUM-KHAGAUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801502,
      "TXT_PINCODE_LOCALITY": "DAUDPUR (PATNA)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801503,
      "TXT_PINCODE_LOCALITY": "DALWAR CHEMICAL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801505,
      "TXT_PINCODE_LOCALITY": "PHULWARISHARIF",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801506,
      "TXT_PINCODE_LOCALITY": "SAHAYNAGAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801507,
      "TXT_PINCODE_LOCALITY": "GANGHARA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801508,
      "TXT_PINCODE_LOCALITY": "DILIPCHAK",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 801509,
      "TXT_PINCODE_LOCALITY": "SHERPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802007,
      "TXT_PINCODE_LOCALITY": "TATARPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 441204,
      "City District Name": "BHAGALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802101,
      "TXT_PINCODE_LOCALITY": "BUXAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2642,
      "City District Name": "BUXAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802102,
      "TXT_PINCODE_LOCALITY": "BUXAR JAIL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2642,
      "City District Name": "BUXAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802103,
      "TXT_PINCODE_LOCALITY": "BUXAR GAJADHARGANI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2642,
      "City District Name": "BUXAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802105,
      "TXT_PINCODE_LOCALITY": "KOIRI BIGHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442549,
      "City District Name": "KATRISARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802111,
      "TXT_PINCODE_LOCALITY": "ARAK",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2642,
      "City District Name": "BUXAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802112,
      "TXT_PINCODE_LOCALITY": "BARHAMPUR CHAUSASTA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802113,
      "TXT_PINCODE_LOCALITY": "BARKARAJPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2642,
      "City District Name": "BUXAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802114,
      "TXT_PINCODE_LOCALITY": "AKHAURIPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2642,
      "City District Name": "BUXAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802115,
      "TXT_PINCODE_LOCALITY": "CHAUGAIN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2642,
      "City District Name": "BUXAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802116,
      "TXT_PINCODE_LOCALITY": "CIVIL AERODROME",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2642,
      "City District Name": "BUXAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802117,
      "TXT_PINCODE_LOCALITY": "DHANSOI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2642,
      "City District Name": "BUXAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802118,
      "TXT_PINCODE_LOCALITY": "DULLAHPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2642,
      "City District Name": "BUXAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802119,
      "TXT_PINCODE_LOCALITY": "DUMRAOH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802120,
      "TXT_PINCODE_LOCALITY": "DUMRI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802122,
      "TXT_PINCODE_LOCALITY": "HITWARAJPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2642,
      "City District Name": "BUXAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802123,
      "TXT_PINCODE_LOCALITY": "ITARHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2642,
      "City District Name": "BUXAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802124,
      "TXT_PINCODE_LOCALITY": "KARNAMEPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802125,
      "TXT_PINCODE_LOCALITY": "KESATH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2642,
      "City District Name": "BUXAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802126,
      "TXT_PINCODE_LOCALITY": "KORANSARAI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2642,
      "City District Name": "BUXAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802127,
      "TXT_PINCODE_LOCALITY": "MURAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2642,
      "City District Name": "BUXAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802128,
      "TXT_PINCODE_LOCALITY": "MANOHARPUR (ARRAH)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802129,
      "TXT_PINCODE_LOCALITY": "NAWANAGAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2642,
      "City District Name": "BUXAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802130,
      "TXT_PINCODE_LOCALITY": "NIMEZ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2642,
      "City District Name": "BUXAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802131,
      "TXT_PINCODE_LOCALITY": "NEAJIPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2642,
      "City District Name": "BUXAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802132,
      "TXT_PINCODE_LOCALITY": "NOAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424414,
      "City District Name": "KAIMUR (BHABUA)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802133,
      "TXT_PINCODE_LOCALITY": "PURANA BHOJPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2642,
      "City District Name": "BUXAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802134,
      "TXT_PINCODE_LOCALITY": "RAGHUNATHPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802135,
      "TXT_PINCODE_LOCALITY": "SIMRI(ARRAH)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2642,
      "City District Name": "BUXAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802136,
      "TXT_PINCODE_LOCALITY": "DUMRAON TEXTILE",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2642,
      "City District Name": "BUXAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802137,
      "TXT_PINCODE_LOCALITY": "ARIAON",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2642,
      "City District Name": "BUXAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802138,
      "TXT_PINCODE_LOCALITY": "BARKA DHAKAICH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2642,
      "City District Name": "BUXAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802139,
      "TXT_PINCODE_LOCALITY": "BARUNA R S",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802140,
      "TXT_PINCODE_LOCALITY": "EKARASI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2642,
      "City District Name": "BUXAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802141,
      "TXT_PINCODE_LOCALITY": "ISSARPURA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802142,
      "TXT_PINCODE_LOCALITY": "MANGRAON SANGRAON",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2642,
      "City District Name": "BUXAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802143,
      "TXT_PINCODE_LOCALITY": "MATHILA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2642,
      "City District Name": "BUXAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802144,
      "TXT_PINCODE_LOCALITY": "NAINIJORE",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2642,
      "City District Name": "BUXAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802145,
      "TXT_PINCODE_LOCALITY": "NAYA BHOJPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2642,
      "City District Name": "BUXAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802151,
      "TXT_PINCODE_LOCALITY": "BENWALIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802152,
      "TXT_PINCODE_LOCALITY": "BIMWAN B.O",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802154,
      "TXT_PINCODE_LOCALITY": "BELAUTI B.O",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802155,
      "TXT_PINCODE_LOCALITY": "WAINA JAMUNI PUR B.O",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802156,
      "TXT_PINCODE_LOCALITY": "DARIAPUR B.O",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802157,
      "TXT_PINCODE_LOCALITY": "GAJGAJGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802158,
      "TXT_PINCODE_LOCALITY": "MASURI B.O",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802159,
      "TXT_PINCODE_LOCALITY": "JITAURA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802160,
      "TXT_PINCODE_LOCALITY": "PAREO B.O",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802161,
      "TXT_PINCODE_LOCALITY": "ALLIPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802162,
      "TXT_PINCODE_LOCALITY": "NAWADAHBEN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802163,
      "TXT_PINCODE_LOCALITY": "NAYA MOHAMMADPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802164,
      "TXT_PINCODE_LOCALITY": "SANDESH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802165,
      "TXT_PINCODE_LOCALITY": "SAHPUR PATTI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2642,
      "City District Name": "BUXAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802166,
      "TXT_PINCODE_LOCALITY": "UMRAOGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802167,
      "TXT_PINCODE_LOCALITY": "DAWAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802168,
      "TXT_PINCODE_LOCALITY": "HETAMPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2642,
      "City District Name": "BUXAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802169,
      "TXT_PINCODE_LOCALITY": "GAIGHAT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802170,
      "TXT_PINCODE_LOCALITY": "MAHUAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2642,
      "City District Name": "BUXAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802171,
      "TXT_PINCODE_LOCALITY": "SARENJA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2642,
      "City District Name": "BUXAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802172,
      "TXT_PINCODE_LOCALITY": "BABURA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802173,
      "TXT_PINCODE_LOCALITY": "JALPURA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802174,
      "TXT_PINCODE_LOCALITY": "JAMIRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802175,
      "TXT_PINCODE_LOCALITY": "BHARAULI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802176,
      "TXT_PINCODE_LOCALITY": "JAMUAON",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2642,
      "City District Name": "BUXAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802177,
      "TXT_PINCODE_LOCALITY": "KAHATHU MASURHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802178,
      "TXT_PINCODE_LOCALITY": "BHOJPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802179,
      "TXT_PINCODE_LOCALITY": "KHAJURIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802180,
      "TXT_PINCODE_LOCALITY": "MASURHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802181,
      "TXT_PINCODE_LOCALITY": "RANISAGAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802182,
      "TXT_PINCODE_LOCALITY": "TAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802183,
      "TXT_PINCODE_LOCALITY": "GAUDARH RUDRANAGAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802184,
      "TXT_PINCODE_LOCALITY": "PALIYA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3492,
      "City District Name": "JAGDISHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802201,
      "TXT_PINCODE_LOCALITY": "AGION",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802202,
      "TXT_PINCODE_LOCALITY": "AMEHATA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802203,
      "TXT_PINCODE_LOCALITY": "GARHANI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802204,
      "TXT_PINCODE_LOCALITY": "HASAN BAZAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802205,
      "TXT_PINCODE_LOCALITY": "KARATH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802206,
      "TXT_PINCODE_LOCALITY": "KASAP",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802207,
      "TXT_PINCODE_LOCALITY": "PIRU",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802208,
      "TXT_PINCODE_LOCALITY": "SAHAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802209,
      "TXT_PINCODE_LOCALITY": "TARARI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802210,
      "TXT_PINCODE_LOCALITY": "UDWANTNAGAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802211,
      "TXT_PINCODE_LOCALITY": "BANBHNAUL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802212,
      "TXT_PINCODE_LOCALITY": "TENDUNI CHOWK",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802213,
      "TXT_PINCODE_LOCALITY": "DINARA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802214,
      "TXT_PINCODE_LOCALITY": "GORARI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802215,
      "TXT_PINCODE_LOCALITY": "ROHTAS S. O",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802216,
      "TXT_PINCODE_LOCALITY": "KOATH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802217,
      "TXT_PINCODE_LOCALITY": "MEARI BAZAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802218,
      "TXT_PINCODE_LOCALITY": "NATWAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802219,
      "TXT_PINCODE_LOCALITY": "RAJPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802220,
      "TXT_PINCODE_LOCALITY": "TILAIN B.O",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802221,
      "TXT_PINCODE_LOCALITY": "SURAJPURA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802222,
      "TXT_PINCODE_LOCALITY": "KHUTAHAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802223,
      "TXT_PINCODE_LOCALITY": "CHARPOKHRI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802224,
      "TXT_PINCODE_LOCALITY": "BABHNI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2642,
      "City District Name": "BUXAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802225,
      "TXT_PINCODE_LOCALITY": "CHHATAUNA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802226,
      "TXT_PINCODE_LOCALITY": "DAWATH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802227,
      "TXT_PINCODE_LOCALITY": "DURGADIH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802228,
      "TXT_PINCODE_LOCALITY": "MANI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802229,
      "TXT_PINCODE_LOCALITY": "NONHAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2642,
      "City District Name": "BUXAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802230,
      "TXT_PINCODE_LOCALITY": "TARAR(ARRAH)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802231,
      "TXT_PINCODE_LOCALITY": "BHOJPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802232,
      "TXT_PINCODE_LOCALITY": "BALIGAON",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802233,
      "TXT_PINCODE_LOCALITY": "PARSAUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802236,
      "TXT_PINCODE_LOCALITY": "BARGAON",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802237,
      "TXT_PINCODE_LOCALITY": "BARKAGAON",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802238,
      "TXT_PINCODE_LOCALITY": "BELAUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802239,
      "TXT_PINCODE_LOCALITY": "EKWARI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802301,
      "TXT_PINCODE_LOCALITY": "ARRAH KUTCHEY",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802302,
      "TXT_PINCODE_LOCALITY": "ANAITH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802307,
      "TXT_PINCODE_LOCALITY": "CHAKIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802311,
      "TXT_PINCODE_LOCALITY": "BARHARA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802312,
      "TXT_PINCODE_LOCALITY": "CHANDWA (ARRAH)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802313,
      "TXT_PINCODE_LOCALITY": "GUNRI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802314,
      "TXT_PINCODE_LOCALITY": "KAYAMNAGAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802315,
      "TXT_PINCODE_LOCALITY": "LAUHAR FARNA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802316,
      "TXT_PINCODE_LOCALITY": "SINHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802317,
      "TXT_PINCODE_LOCALITY": "GANGHAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802318,
      "TXT_PINCODE_LOCALITY": "SARAIYA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802321,
      "TXT_PINCODE_LOCALITY": "MANGOLPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442537,
      "City District Name": "ITARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802351,
      "TXT_PINCODE_LOCALITY": "BENWALIA *",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802352,
      "TXT_PINCODE_LOCALITY": "MANPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4032,
      "City District Name": "KOILWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802383,
      "TXT_PINCODE_LOCALITY": "SUNDARPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4032,
      "City District Name": "KOILWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 802506,
      "TXT_PINCODE_LOCALITY": "BICHHIAON",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442631,
      "City District Name": "SANDESH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803004,
      "TXT_PINCODE_LOCALITY": "KIRTIPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442605,
      "City District Name": "PARBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803021,
      "TXT_PINCODE_LOCALITY": "HATHILPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442470,
      "City District Name": "BARHAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803101,
      "TXT_PINCODE_LOCALITY": "MAHALPAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803103,
      "TXT_PINCODE_LOCALITY": "PAITHANA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 425637,
      "City District Name": "ISLAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803107,
      "TXT_PINCODE_LOCALITY": "ASTHAWAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803108,
      "TXT_PINCODE_LOCALITY": "CHANDI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803109,
      "TXT_PINCODE_LOCALITY": "GIRIAK",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803110,
      "TXT_PINCODE_LOCALITY": "HARNAUT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803111,
      "TXT_PINCODE_LOCALITY": "NALANDA R S",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803112,
      "TXT_PINCODE_LOCALITY": "HATIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2259,
      "City District Name": "BAKHTIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803113,
      "TXT_PINCODE_LOCALITY": "NOORSARAI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803114,
      "TXT_PINCODE_LOCALITY": "PARBALPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803115,
      "TXT_PINCODE_LOCALITY": "PAWAPURI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803116,
      "TXT_PINCODE_LOCALITY": "RAJGIR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803117,
      "TXT_PINCODE_LOCALITY": "SILAO",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803118,
      "TXT_PINCODE_LOCALITY": "MOGHALKUAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803119,
      "TXT_PINCODE_LOCALITY": "RAHUI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803120,
      "TXT_PINCODE_LOCALITY": "ASTA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803121,
      "TXT_PINCODE_LOCALITY": "SHANKARDIH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803122,
      "TXT_PINCODE_LOCALITY": "PILLICH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803123,
      "TXT_PINCODE_LOCALITY": "BARAH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803124,
      "TXT_PINCODE_LOCALITY": "VEERAYATAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803125,
      "TXT_PINCODE_LOCALITY": "DEEP NAGAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803126,
      "TXT_PINCODE_LOCALITY": "NANAND",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803127,
      "TXT_PINCODE_LOCALITY": "KONAND",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803128,
      "TXT_PINCODE_LOCALITY": "HARGAWAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803129,
      "TXT_PINCODE_LOCALITY": "TETRAWAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803130,
      "TXT_PINCODE_LOCALITY": "TUNGI(BIHARSHARIF)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803131,
      "TXT_PINCODE_LOCALITY": "RUKHAI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803167,
      "TXT_PINCODE_LOCALITY": "WOIAO",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442456,
      "City District Name": "ASTHAWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803201,
      "TXT_PINCODE_LOCALITY": "FATWA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803202,
      "TXT_PINCODE_LOCALITY": "KHUSRUPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803203,
      "TXT_PINCODE_LOCALITY": "ALAWALPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803204,
      "TXT_PINCODE_LOCALITY": "TELMAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803205,
      "TXT_PINCODE_LOCALITY": "BAIKATHPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803206,
      "TXT_PINCODE_LOCALITY": "FATEHPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803207,
      "TXT_PINCODE_LOCALITY": "SABALPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803211,
      "TXT_PINCODE_LOCALITY": "ATHMALGOLA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803212,
      "TXT_PINCODE_LOCALITY": "BAKHTIARPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803213,
      "TXT_PINCODE_LOCALITY": "BARH COURT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803214,
      "TXT_PINCODE_LOCALITY": "BARH R S",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803215,
      "TXT_PINCODE_LOCALITY": "BADDOPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803216,
      "TXT_PINCODE_LOCALITY": "SAKSOHRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803217,
      "TXT_PINCODE_LOCALITY": "RAMNAGAR DIARA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803218,
      "TXT_PINCODE_LOCALITY": "MADHOPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803219,
      "TXT_PINCODE_LOCALITY": "SAHRI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803220,
      "TXT_PINCODE_LOCALITY": "BERHNA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803221,
      "TXT_PINCODE_LOCALITY": "PANDARAK",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803232,
      "TXT_PINCODE_LOCALITY": "MORE",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803301,
      "TXT_PINCODE_LOCALITY": "HATHIDAH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803302,
      "TXT_PINCODE_LOCALITY": "MOKAMESH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803303,
      "TXT_PINCODE_LOCALITY": "MOKAMEH GHAT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803304,
      "TXT_PINCODE_LOCALITY": "MARANCHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803305,
      "TXT_PINCODE_LOCALITY": "RAMPUR DUMRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803306,
      "TXT_PINCODE_LOCALITY": "SAMYAGARH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803307,
      "TXT_PINCODE_LOCALITY": "BHADAUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803311,
      "TXT_PINCODE_LOCALITY": "GOAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2352,
      "City District Name": "BARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803316,
      "TXT_PINCODE_LOCALITY": "SIWAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5279,
      "City District Name": "RAJGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803332,
      "TXT_PINCODE_LOCALITY": "KODVARA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 441073,
      "City District Name": "EAST CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 803443,
      "TXT_PINCODE_LOCALITY": "AT.",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442479,
      "City District Name": "BEN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804118,
      "TXT_PINCODE_LOCALITY": "AMARPUR PALI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442541,
      "City District Name": "KAKO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804151,
      "TXT_PINCODE_LOCALITY": "SADIPUR AIMA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442581,
      "City District Name": "MODANGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804216,
      "TXT_PINCODE_LOCALITY": "PARMESARPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442596,
      "City District Name": "NAWANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804232,
      "TXT_PINCODE_LOCALITY": "MANHONNA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442518,
      "City District Name": "FATEHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804305,
      "TXT_PINCODE_LOCALITY": "LODIPUR USMANPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442586,
      "City District Name": "NAGAR NAUSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804401,
      "TXT_PINCODE_LOCALITY": "ARWAL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3569,
      "City District Name": "JEHANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804402,
      "TXT_PINCODE_LOCALITY": "FATEHPUR SANDA B.O",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 441199,
      "City District Name": "ARWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804403,
      "TXT_PINCODE_LOCALITY": "BELAGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3569,
      "City District Name": "JEHANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804404,
      "TXT_PINCODE_LOCALITY": "CHAKAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3216,
      "City District Name": "GAYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804405,
      "TXT_PINCODE_LOCALITY": "DHARAUT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3569,
      "City District Name": "JEHANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804406,
      "TXT_PINCODE_LOCALITY": "GHOSI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3569,
      "City District Name": "JEHANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804407,
      "TXT_PINCODE_LOCALITY": "HULASHGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3569,
      "City District Name": "JEHANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804408,
      "TXT_PINCODE_LOCALITY": "JAHANABAD",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3569,
      "City District Name": "JEHANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804414,
      "TXT_PINCODE_LOCALITY": "NAIAWAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442581,
      "City District Name": "MODANGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804417,
      "TXT_PINCODE_LOCALITY": "JAHANABAD R S",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3569,
      "City District Name": "JEHANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804418,
      "TXT_PINCODE_LOCALITY": "KAKO",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3569,
      "City District Name": "JEHANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804419,
      "TXT_PINCODE_LOCALITY": "KEYAL B.O",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 441199,
      "City District Name": "ARWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804420,
      "TXT_PINCODE_LOCALITY": "KAZISARAI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3569,
      "City District Name": "JEHANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804421,
      "TXT_PINCODE_LOCALITY": "KURTHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3569,
      "City District Name": "JEHANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804422,
      "TXT_PINCODE_LOCALITY": "MAKHDUMPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3569,
      "City District Name": "JEHANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804423,
      "TXT_PINCODE_LOCALITY": "NAGLAKINJAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3569,
      "City District Name": "JEHANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804424,
      "TXT_PINCODE_LOCALITY": "PAIBIGHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3569,
      "City District Name": "JEHANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804425,
      "TXT_PINCODE_LOCALITY": "SAKURABAD",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3569,
      "City District Name": "JEHANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804426,
      "TXT_PINCODE_LOCALITY": "SHANKARPUR IMAMGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3216,
      "City District Name": "GAYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804427,
      "TXT_PINCODE_LOCALITY": "TEHTA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3569,
      "City District Name": "JEHANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804428,
      "TXT_PINCODE_LOCALITY": "USRI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3569,
      "City District Name": "JEHANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804429,
      "TXT_PINCODE_LOCALITY": "NEHALPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3569,
      "City District Name": "JEHANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804430,
      "TXT_PINCODE_LOCALITY": "KOIL BHUPAT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3569,
      "City District Name": "JEHANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804431,
      "TXT_PINCODE_LOCALITY": "NOWAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3569,
      "City District Name": "JEHANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804432,
      "TXT_PINCODE_LOCALITY": "BANDHUGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3569,
      "City District Name": "JEHANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804433,
      "TXT_PINCODE_LOCALITY": "PANDOOI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3569,
      "City District Name": "JEHANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804434,
      "TXT_PINCODE_LOCALITY": "LAKHAWAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3569,
      "City District Name": "JEHANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804435,
      "TXT_PINCODE_LOCALITY": "KEYAL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3569,
      "City District Name": "JEHANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804446,
      "TXT_PINCODE_LOCALITY": "BELA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4350,
      "City District Name": "MAKHDUMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804451,
      "TXT_PINCODE_LOCALITY": "HAZARATSIAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804452,
      "TXT_PINCODE_LOCALITY": "MASAURHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804453,
      "TXT_PINCODE_LOCALITY": "PUNPUN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804454,
      "TXT_PINCODE_LOCALITY": "PATARIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804455,
      "TXT_PINCODE_LOCALITY": "DHANARUA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804456,
      "TXT_PINCODE_LOCALITY": "LAKHNA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804457,
      "TXT_PINCODE_LOCALITY": "MITHANCHAK",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804458,
      "TXT_PINCODE_LOCALITY": "AKAUNA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804487,
      "TXT_PINCODE_LOCALITY": "LAKHISARAI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442522,
      "City District Name": "GHOSHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804503,
      "TXT_PINCODE_LOCALITY": "JAFRABAD",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 804952,
      "TXT_PINCODE_LOCALITY": "NARWAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442512,
      "City District Name": "DULHIN BAZAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805101,
      "TXT_PINCODE_LOCALITY": "DHAMAUL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4782,
      "City District Name": "NAWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805102,
      "TXT_PINCODE_LOCALITY": "GOBINDPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4782,
      "City District Name": "NAWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805103,
      "TXT_PINCODE_LOCALITY": "HASUA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4782,
      "City District Name": "NAWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805104,
      "TXT_PINCODE_LOCALITY": "KADIRGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4782,
      "City District Name": "NAWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805105,
      "TXT_PINCODE_LOCALITY": "KATARPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442549,
      "City District Name": "KATRISARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805106,
      "TXT_PINCODE_LOCALITY": "KAWAKOL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4782,
      "City District Name": "NAWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805107,
      "TXT_PINCODE_LOCALITY": "KOSI COLLEGE",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4782,
      "City District Name": "NAWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805108,
      "TXT_PINCODE_LOCALITY": "LALBIGHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4782,
      "City District Name": "NAWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805109,
      "TXT_PINCODE_LOCALITY": "NARDIGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4782,
      "City District Name": "NAWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805110,
      "TXT_PINCODE_LOCALITY": "NAWADAH KUTCHERY",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4782,
      "City District Name": "NAWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805111,
      "TXT_PINCODE_LOCALITY": "SAHEB CHAK .",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4782,
      "City District Name": "NAWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805112,
      "TXT_PINCODE_LOCALITY": "RUNNIPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442453,
      "City District Name": "AKBARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805113,
      "TXT_PINCODE_LOCALITY": "KATRISARAI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4782,
      "City District Name": "NAWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805114,
      "TXT_PINCODE_LOCALITY": "BIHAR KESHRI SRI KRISHN SINGH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4782,
      "City District Name": "NAWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805117,
      "TXT_PINCODE_LOCALITY": "SITARAMPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4782,
      "City District Name": "NAWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805121,
      "TXT_PINCODE_LOCALITY": "NEMDARGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4782,
      "City District Name": "NAWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805122,
      "TXT_PINCODE_LOCALITY": "NURHAT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4782,
      "City District Name": "NAWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805123,
      "TXT_PINCODE_LOCALITY": "ORHANPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4782,
      "City District Name": "NAWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805124,
      "TXT_PINCODE_LOCALITY": "PAKRIBARWAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4782,
      "City District Name": "NAWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805125,
      "TXT_PINCODE_LOCALITY": "RAJAULI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4782,
      "City District Name": "NAWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805126,
      "TXT_PINCODE_LOCALITY": "RAJHAT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4782,
      "City District Name": "NAWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805127,
      "TXT_PINCODE_LOCALITY": "SIRDALLA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4782,
      "City District Name": "NAWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805128,
      "TXT_PINCODE_LOCALITY": "TARWAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4782,
      "City District Name": "NAWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805129,
      "TXT_PINCODE_LOCALITY": "TUNGI(NAWADAH)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4782,
      "City District Name": "NAWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805130,
      "TXT_PINCODE_LOCALITY": "WARISALIGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4782,
      "City District Name": "NAWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805131,
      "TXT_PINCODE_LOCALITY": "WAZIRGANJ S.O",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3216,
      "City District Name": "GAYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805132,
      "TXT_PINCODE_LOCALITY": "SATGAWAN",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 4024,
      "City District Name": "KODARMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805133,
      "TXT_PINCODE_LOCALITY": "BAGULI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442589,
      "City District Name": "NARDIGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805134,
      "TXT_PINCODE_LOCALITY": "TETAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4782,
      "City District Name": "NAWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805135,
      "TXT_PINCODE_LOCALITY": "AYER",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3216,
      "City District Name": "GAYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805136,
      "TXT_PINCODE_LOCALITY": "JETHIAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4782,
      "City District Name": "NAWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805137,
      "TXT_PINCODE_LOCALITY": "MANJHWAY",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4782,
      "City District Name": "NAWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805138,
      "TXT_PINCODE_LOCALITY": "KATAUNA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4782,
      "City District Name": "NAWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805139,
      "TXT_PINCODE_LOCALITY": "SAMBAY",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3216,
      "City District Name": "GAYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805140,
      "TXT_PINCODE_LOCALITY": "BANSGOPAL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3216,
      "City District Name": "GAYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805141,
      "TXT_PINCODE_LOCALITY": "MARUI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4782,
      "City District Name": "NAWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805143,
      "TXT_PINCODE_LOCALITY": "ROH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4782,
      "City District Name": "NAWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805144,
      "TXT_PINCODE_LOCALITY": "KAHUARA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4782,
      "City District Name": "NAWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805145,
      "TXT_PINCODE_LOCALITY": "SARH MANJHIAWAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3216,
      "City District Name": "GAYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805172,
      "TXT_PINCODE_LOCALITY": "PATLUKA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442525,
      "City District Name": "GOBINDPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805236,
      "TXT_PINCODE_LOCALITY": "PAKRI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442584,
      "City District Name": "MUHRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805301,
      "TXT_PINCODE_LOCALITY": "ANCHAL ADAPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 441073,
      "City District Name": "EAST CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805302,
      "TXT_PINCODE_LOCALITY": "PIPRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 441073,
      "City District Name": "EAST CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805303,
      "TXT_PINCODE_LOCALITY": "CHAMPARAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 441073,
      "City District Name": "EAST CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805304,
      "TXT_PINCODE_LOCALITY": "KHARHUA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 441073,
      "City District Name": "EAST CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805315,
      "TXT_PINCODE_LOCALITY": "SHEKHTOLI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 441073,
      "City District Name": "EAST CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805401,
      "TXT_PINCODE_LOCALITY": "HANUMAN GADHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4567,
      "City District Name": "MOTIHARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805415,
      "TXT_PINCODE_LOCALITY": "NIRPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 441073,
      "City District Name": "EAST CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805418,
      "TXT_PINCODE_LOCALITY": "LAKSHMIPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 441073,
      "City District Name": "EAST CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805427,
      "TXT_PINCODE_LOCALITY": "PACHPAKRI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 441073,
      "City District Name": "EAST CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805457,
      "TXT_PINCODE_LOCALITY": "PATAHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442464,
      "City District Name": "BAKHRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805707,
      "TXT_PINCODE_LOCALITY": "SANTHE",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442627,
      "City District Name": "ROH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 805721,
      "TXT_PINCODE_LOCALITY": "NAKTI MAHUGAIN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442655,
      "City District Name": "WAZIRGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 806001,
      "TXT_PINCODE_LOCALITY": "DARBHANGA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 806010,
      "TXT_PINCODE_LOCALITY": "BAHARAGAWAN.",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442525,
      "City District Name": "GOBINDPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 807108,
      "TXT_PINCODE_LOCALITY": "BHAPTIYAHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 808105,
      "TXT_PINCODE_LOCALITY": "SULTANPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6247,
      "City District Name": "WARISALIGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 808107,
      "TXT_PINCODE_LOCALITY": "KABIRPURA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2346,
      "City District Name": "BARBIGHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 808122,
      "TXT_PINCODE_LOCALITY": "MISIR CHAK",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442590,
      "City District Name": "NARHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 808125,
      "TXT_PINCODE_LOCALITY": "RAMDASI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442620,
      "City District Name": "RAJAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 808182,
      "TXT_PINCODE_LOCALITY": "MANGITPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3492,
      "City District Name": "JAGDISHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 808214,
      "TXT_PINCODE_LOCALITY": "HASANPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2352,
      "City District Name": "BARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 808453,
      "TXT_PINCODE_LOCALITY": "BIJBANI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 441073,
      "City District Name": "EAST CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 809166,
      "TXT_PINCODE_LOCALITY": "PODDAR PARA JHARIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 809945,
      "TXT_PINCODE_LOCALITY": "BHERGAWAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3141,
      "City District Name": "FATWAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 810139,
      "TXT_PINCODE_LOCALITY": "CASTLE LANE,",
      "NUM_STATE_CD": 238,
      "State Name": "COLOMBO",
      "NUM_CITYDISTRICT_CD": 444087,
      "City District Name": "CASTLE LANE",
      "NUM_COUNTRY_CD": 137
    },
    {
      "NUM_PINCODE": 811030,
      "TXT_PINCODE_LOCALITY": "SONPAI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3539,
      "City District Name": "JAMUI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811036,
      "TXT_PINCODE_LOCALITY": "TOLA PURNI CHHIT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442552,
      "City District Name": "KHAIRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811101,
      "TXT_PINCODE_LOCALITY": "BARBIGHA COLLEGE ROADD BARBI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5615,
      "City District Name": "SHEIKHPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811102,
      "TXT_PINCODE_LOCALITY": "MEHUS",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5615,
      "City District Name": "SHEIKHPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811103,
      "TXT_PINCODE_LOCALITY": "NIMI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811104,
      "TXT_PINCODE_LOCALITY": "SARMERA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811105,
      "TXT_PINCODE_LOCALITY": "SHEIKHPURA R S",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4613,
      "City District Name": "MUNGER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811106,
      "TXT_PINCODE_LOCALITY": "SURAJGARHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2411,
      "City District Name": "BEGUSARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811107,
      "TXT_PINCODE_LOCALITY": "SIRARI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4207,
      "City District Name": "LAKHISARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811108,
      "TXT_PINCODE_LOCALITY": "BIND",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811109,
      "TXT_PINCODE_LOCALITY": "HATHIAWAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5615,
      "City District Name": "SHEIKHPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811110,
      "TXT_PINCODE_LOCALITY": "KATARI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5615,
      "City District Name": "SHEIKHPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811111,
      "TXT_PINCODE_LOCALITY": "TEUS",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5615,
      "City District Name": "SHEIKHPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811112,
      "TXT_PINCODE_LOCALITY": "KHUDIBAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442646,
      "City District Name": "SURAJGARHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811113,
      "TXT_PINCODE_LOCALITY": "SARE",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442456,
      "City District Name": "ASTHAWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811141,
      "TXT_PINCODE_LOCALITY": "PUNESRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2346,
      "City District Name": "BARBIGHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811201,
      "TXT_PINCODE_LOCALITY": "MONGHYR FORT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4613,
      "City District Name": "MUNGER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811202,
      "TXT_PINCODE_LOCALITY": "BASDEOPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4613,
      "City District Name": "MUNGER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811211,
      "TXT_PINCODE_LOCALITY": "BARIARPUR (MONGHYR)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4613,
      "City District Name": "MUNGER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811212,
      "TXT_PINCODE_LOCALITY": "DHARHARA (MONGHYR)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4613,
      "City District Name": "MUNGER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811213,
      "TXT_PINCODE_LOCALITY": "HAVELIKHARAGPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4613,
      "City District Name": "MUNGER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811214,
      "TXT_PINCODE_LOCALITY": "JAMALPUR SADAR BAZAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4613,
      "City District Name": "MUNGER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811217,
      "TXT_PINCODE_LOCALITY": "KHARIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442471,
      "City District Name": "BARIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811222,
      "TXT_PINCODE_LOCALITY": "SUJAWALPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4613,
      "City District Name": "MUNGER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811240,
      "TXT_PINCODE_LOCALITY": "KHATIB CHAK",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4613,
      "City District Name": "MUNGER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811301,
      "TXT_PINCODE_LOCALITY": "ALIGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3539,
      "City District Name": "JAMUI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811302,
      "TXT_PINCODE_LOCALITY": "BARAHIYA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4207,
      "City District Name": "LAKHISARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811303,
      "TXT_PINCODE_LOCALITY": "CHAKAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4613,
      "City District Name": "MUNGER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811304,
      "TXT_PINCODE_LOCALITY": "CHEWARA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5615,
      "City District Name": "SHEIKHPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811305,
      "TXT_PINCODE_LOCALITY": "GIDHAUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4207,
      "City District Name": "LAKHISARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811306,
      "TXT_PINCODE_LOCALITY": "KARAN NAWADA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442552,
      "City District Name": "KHAIRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811307,
      "TXT_PINCODE_LOCALITY": "JAMUI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3539,
      "City District Name": "JAMUI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811308,
      "TXT_PINCODE_LOCALITY": "JHAHA BAZAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3539,
      "City District Name": "JAMUI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811309,
      "TXT_PINCODE_LOCALITY": "KAJRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4613,
      "City District Name": "MUNGER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811310,
      "TXT_PINCODE_LOCALITY": "KIUL R S",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4207,
      "City District Name": "LAKHISARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811311,
      "TXT_PINCODE_LOCALITY": "BALIKA VIDYAPITH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4613,
      "City District Name": "MUNGER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811312,
      "TXT_PINCODE_LOCALITY": "LAKSHMIPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3539,
      "City District Name": "JAMUI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811313,
      "TXT_PINCODE_LOCALITY": "MALLEHPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3539,
      "City District Name": "JAMUI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811314,
      "TXT_PINCODE_LOCALITY": "SONO",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3539,
      "City District Name": "JAMUI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811315,
      "TXT_PINCODE_LOCALITY": "SIKANDRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3539,
      "City District Name": "JAMUI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811316,
      "TXT_PINCODE_LOCALITY": "SIMULTALA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3539,
      "City District Name": "JAMUI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811317,
      "TXT_PINCODE_LOCALITY": "KHAIRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3539,
      "City District Name": "JAMUI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811318,
      "TXT_PINCODE_LOCALITY": "HALSI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4207,
      "City District Name": "LAKHISARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811319,
      "TXT_PINCODE_LOCALITY": "KESHOPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4613,
      "City District Name": "MUNGER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811320,
      "TXT_PINCODE_LOCALITY": "MANANPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4207,
      "City District Name": "LAKHISARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811400,
      "TXT_PINCODE_LOCALITY": "BEGUSARAI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2411,
      "City District Name": "BEGUSARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 811432,
      "TXT_PINCODE_LOCALITY": "TITAI CHAK",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442494,
      "City District Name": "CHANAN*",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 812001,
      "TXT_PINCODE_LOCALITY": "BHAGALPUR DIST BD",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 812002,
      "TXT_PINCODE_LOCALITY": "TATARPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 812003,
      "TXT_PINCODE_LOCALITY": "BARARI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 812004,
      "TXT_PINCODE_LOCALITY": "CHAMPANAGAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 812005,
      "TXT_PINCODE_LOCALITY": "ALIGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4010,
      "City District Name": "KISHANGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 812006,
      "TXT_PINCODE_LOCALITY": "NATHNAGAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 812007,
      "TXT_PINCODE_LOCALITY": "T N B COLLEGE",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 812112,
      "TXT_PINCODE_LOCALITY": "MOTWOFFACHAK",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3492,
      "City District Name": "JAGDISHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813016,
      "TXT_PINCODE_LOCALITY": "TOLA TULSIDIH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442547,
      "City District Name": "KATORIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813100,
      "TXT_PINCODE_LOCALITY": "SHADPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2316,
      "City District Name": "BANKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813101,
      "TXT_PINCODE_LOCALITY": "AMARPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2316,
      "City District Name": "BANKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813102,
      "TXT_PINCODE_LOCALITY": "BANKA H O",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2316,
      "City District Name": "BANKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813103,
      "TXT_PINCODE_LOCALITY": "BARAHAT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2316,
      "City District Name": "BANKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813104,
      "TXT_PINCODE_LOCALITY": "BAUNSI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2316,
      "City District Name": "BANKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813105,
      "TXT_PINCODE_LOCALITY": "JAGDISHPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813106,
      "TXT_PINCODE_LOCALITY": "KATORIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2316,
      "City District Name": "BANKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813107,
      "TXT_PINCODE_LOCALITY": "RAJA UN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2316,
      "City District Name": "BANKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813108,
      "TXT_PINCODE_LOCALITY": "SHAHKUND",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813109,
      "TXT_PINCODE_LOCALITY": "PUNSIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2316,
      "City District Name": "BANKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813110,
      "TXT_PINCODE_LOCALITY": "PANJWARA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2316,
      "City District Name": "BANKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813111,
      "TXT_PINCODE_LOCALITY": "KHUTAHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813112,
      "TXT_PINCODE_LOCALITY": "BAIJANI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813113,
      "TXT_PINCODE_LOCALITY": "HABIDPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813114,
      "TXT_PINCODE_LOCALITY": "AURIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2316,
      "City District Name": "BANKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813115,
      "TXT_PINCODE_LOCALITY": "PATHRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2316,
      "City District Name": "BANKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813116,
      "TXT_PINCODE_LOCALITY": "KOJHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2316,
      "City District Name": "BANKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813117,
      "TXT_PINCODE_LOCALITY": "BAGDUMA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2316,
      "City District Name": "BANKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813118,
      "TXT_PINCODE_LOCALITY": "KAKWARA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2316,
      "City District Name": "BANKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813119,
      "TXT_PINCODE_LOCALITY": "BHURNA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2316,
      "City District Name": "BANKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813120,
      "TXT_PINCODE_LOCALITY": "DUMRAMAH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813121,
      "TXT_PINCODE_LOCALITY": "KHARHARA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2316,
      "City District Name": "BANKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813122,
      "TXT_PINCODE_LOCALITY": "SALEMPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813123,
      "TXT_PINCODE_LOCALITY": "SONIHA BABHANGAMA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813124,
      "TXT_PINCODE_LOCALITY": "SOBHANPUR KATORIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2316,
      "City District Name": "BANKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813125,
      "TXT_PINCODE_LOCALITY": "PAWAI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2316,
      "City District Name": "BANKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813126,
      "TXT_PINCODE_LOCALITY": "BHARKHO",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2316,
      "City District Name": "BANKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813127,
      "TXT_PINCODE_LOCALITY": "LAXMIPUR CHIRAIYA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2316,
      "City District Name": "BANKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813128,
      "TXT_PINCODE_LOCALITY": "DAHUA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2316,
      "City District Name": "BANKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813129,
      "TXT_PINCODE_LOCALITY": "SEMARIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813141,
      "TXT_PINCODE_LOCALITY": "MAHESHPATTI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813153,
      "TXT_PINCODE_LOCALITY": "BANSMATI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442782,
      "City District Name": "POREYAHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813166,
      "TXT_PINCODE_LOCALITY": "BHALUA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442611,
      "City District Name": "PHULIDUMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813201,
      "TXT_PINCODE_LOCALITY": "ASARGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4613,
      "City District Name": "MUNGER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813202,
      "TXT_PINCODE_LOCALITY": "BELHAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2316,
      "City District Name": "BANKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813203,
      "TXT_PINCODE_LOCALITY": "CONTROLLER OF DEFENCE",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813204,
      "TXT_PINCODE_LOCALITY": "EKCHARI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813205,
      "TXT_PINCODE_LOCALITY": "KAHALGOAN S. O.",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 441204,
      "City District Name": "BHAGALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813206,
      "TXT_PINCODE_LOCALITY": "ISHIPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813207,
      "TXT_PINCODE_LOCALITY": "KHESAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2316,
      "City District Name": "BANKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813208,
      "TXT_PINCODE_LOCALITY": "MIRZACHOWKI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813209,
      "TXT_PINCODE_LOCALITY": "PIRPAINTI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813210,
      "TXT_PINCODE_LOCALITY": "BHAGALPUR ENG COLLEGE",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813211,
      "TXT_PINCODE_LOCALITY": "SHAMBHUGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813212,
      "TXT_PINCODE_LOCALITY": "SANGRAMPUR(BHAGALPUR)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4207,
      "City District Name": "LAKHISARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813213,
      "TXT_PINCODE_LOCALITY": "SULTANGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813214,
      "TXT_PINCODE_LOCALITY": "S.T.P. KAHALGAON S.O",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 441204,
      "City District Name": "BHAGALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813221,
      "TXT_PINCODE_LOCALITY": "TARAPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4613,
      "City District Name": "MUNGER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813222,
      "TXT_PINCODE_LOCALITY": "MATHURAPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813223,
      "TXT_PINCODE_LOCALITY": "AKBAR NAGAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813224,
      "TXT_PINCODE_LOCALITY": "DHARIA S.O",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2316,
      "City District Name": "BANKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813225,
      "TXT_PINCODE_LOCALITY": "SANHOLA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813226,
      "TXT_PINCODE_LOCALITY": "TARAR(BHAGALPUR)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813227,
      "TXT_PINCODE_LOCALITY": "KUMAITHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813228,
      "TXT_PINCODE_LOCALITY": "TILAKPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813229,
      "TXT_PINCODE_LOCALITY": "MANIKPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813230,
      "TXT_PINCODE_LOCALITY": "BOARIJORE",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813231,
      "TXT_PINCODE_LOCALITY": "MAHESHMUNDA (BHAGALPUR)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813232,
      "TXT_PINCODE_LOCALITY": "DHANOURA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813233,
      "TXT_PINCODE_LOCALITY": "FATEHPUR (BHAGALPUR)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813234,
      "TXT_PINCODE_LOCALITY": "MIRHATTI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813236,
      "TXT_PINCODE_LOCALITY": "EKDARA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813321,
      "TXT_PINCODE_LOCALITY": "MUNGER.",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4613,
      "City District Name": "MUNGER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813401,
      "TXT_PINCODE_LOCALITY": "BELSIRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813507,
      "TXT_PINCODE_LOCALITY": "RANITIKAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442621,
      "City District Name": "RAJAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 813703,
      "TXT_PINCODE_LOCALITY": "MOHAMMADPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2841,
      "City District Name": "COLGONG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814101,
      "TXT_PINCODE_LOCALITY": "DUMKA H O",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1522,
      "City District Name": "DUMKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814102,
      "TXT_PINCODE_LOCALITY": "RAMGARH (DUMKA)",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1522,
      "City District Name": "DUMKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814103,
      "TXT_PINCODE_LOCALITY": "KATHIKUND",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1522,
      "City District Name": "DUMKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814106,
      "TXT_PINCODE_LOCALITY": "TOLA PARARIA KHAJURIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442547,
      "City District Name": "KATORIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814107,
      "TXT_PINCODE_LOCALITY": "TOLA PATARDIH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442493,
      "City District Name": "CHANAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814110,
      "TXT_PINCODE_LOCALITY": "SHIKARPUR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1522,
      "City District Name": "DUMKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814111,
      "TXT_PINCODE_LOCALITY": "AMRAPARA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1522,
      "City District Name": "DUMKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814112,
      "TXT_PINCODE_LOCALITY": "BAIDYANATH DEOGHAR H O",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 2919,
      "City District Name": "DEOGHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814113,
      "TXT_PINCODE_LOCALITY": "VIDYAPITH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 2919,
      "City District Name": "DEOGHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814114,
      "TXT_PINCODE_LOCALITY": "DEOSANG B.T",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 2919,
      "City District Name": "DEOGHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814115,
      "TXT_PINCODE_LOCALITY": "HINDIPITH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 2919,
      "City District Name": "DEOGHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814116,
      "TXT_PINCODE_LOCALITY": "SATSANG",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 2919,
      "City District Name": "DEOGHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814117,
      "TXT_PINCODE_LOCALITY": "TALISMAN BILASI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 2919,
      "City District Name": "DEOGHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814118,
      "TXT_PINCODE_LOCALITY": "BASUKINATH DHAM",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1522,
      "City District Name": "DUMKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814119,
      "TXT_PINCODE_LOCALITY": "KURWA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3205,
      "City District Name": "GARHWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814120,
      "TXT_PINCODE_LOCALITY": "JHALLAR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 2919,
      "City District Name": "DEOGHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814121,
      "TXT_PINCODE_LOCALITY": "TUMBABEL",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442770,
      "City District Name": "MOHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814122,
      "TXT_PINCODE_LOCALITY": "SATSANG NAGAR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 2919,
      "City District Name": "DEOGHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814124,
      "TXT_PINCODE_LOCALITY": "MAHULPAHARI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442789,
      "City District Name": "SHIKARIPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814131,
      "TXT_PINCODE_LOCALITY": "CHANDAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2316,
      "City District Name": "BANKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814132,
      "TXT_PINCODE_LOCALITY": "DABURGRAM",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 2919,
      "City District Name": "DEOGHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814133,
      "TXT_PINCODE_LOCALITY": "GODDA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3266,
      "City District Name": "GODDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814134,
      "TXT_PINCODE_LOCALITY": "GODDA COLLEGE",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3266,
      "City District Name": "GODDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814135,
      "TXT_PINCODE_LOCALITY": "SAGUNIBAD",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442782,
      "City District Name": "POREYAHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814139,
      "TXT_PINCODE_LOCALITY": "BASMATA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442787,
      "City District Name": "SARATH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814141,
      "TXT_PINCODE_LOCALITY": "JARMUNDI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1522,
      "City District Name": "DUMKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814142,
      "TXT_PINCODE_LOCALITY": "JASIDIH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 2919,
      "City District Name": "DEOGHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814143,
      "TXT_PINCODE_LOCALITY": "KUNDA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 2919,
      "City District Name": "DEOGHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814144,
      "TXT_PINCODE_LOCALITY": "MASSANJORE",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1522,
      "City District Name": "DUMKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814145,
      "TXT_PINCODE_LOCALITY": "NUNIHAT",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1522,
      "City District Name": "DUMKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814146,
      "TXT_PINCODE_LOCALITY": "ASNA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 2919,
      "City District Name": "DEOGHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814147,
      "TXT_PINCODE_LOCALITY": "PATHARGAMA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3266,
      "City District Name": "GODDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814148,
      "TXT_PINCODE_LOCALITY": "RAGHUNATHPUR (DUMKA)",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1522,
      "City District Name": "DUMKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814149,
      "TXT_PINCODE_LOCALITY": "PHULCHUAN",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 2919,
      "City District Name": "DEOGHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814150,
      "TXT_PINCODE_LOCALITY": "SONARAITHARI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 2919,
      "City District Name": "DEOGHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814151,
      "TXT_PINCODE_LOCALITY": "SARAIYAHAT",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1522,
      "City District Name": "DUMKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814152,
      "TXT_PINCODE_LOCALITY": "MANIYARPUR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 2919,
      "City District Name": "DEOGHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814153,
      "TXT_PINCODE_LOCALITY": "PARAIYAHAT",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3266,
      "City District Name": "GODDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814154,
      "TXT_PINCODE_LOCALITY": "MAHAGAMA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3266,
      "City District Name": "GODDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814155,
      "TXT_PINCODE_LOCALITY": "BASANTRAI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3266,
      "City District Name": "GODDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814156,
      "TXT_PINCODE_LOCALITY": "SARAUNI BAZAR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3266,
      "City District Name": "GODDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814157,
      "TXT_PINCODE_LOCALITY": "MALHARA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 2919,
      "City District Name": "DEOGHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814158,
      "TXT_PINCODE_LOCALITY": "GUHIAJORE",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1522,
      "City District Name": "DUMKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814159,
      "TXT_PINCODE_LOCALITY": "SUNDERPAHARI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3266,
      "City District Name": "GODDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814160,
      "TXT_PINCODE_LOCALITY": "MEHARMA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3266,
      "City District Name": "GODDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814161,
      "TXT_PINCODE_LOCALITY": "ASHRAM KARNIBAD",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1522,
      "City District Name": "DUMKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814162,
      "TXT_PINCODE_LOCALITY": "JAMA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1522,
      "City District Name": "DUMKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814163,
      "TXT_PINCODE_LOCALITY": "GOPIKANDAR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1522,
      "City District Name": "DUMKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814164,
      "TXT_PINCODE_LOCALITY": "MOHANPUR HAT",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1522,
      "City District Name": "DUMKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814165,
      "TXT_PINCODE_LOCALITY": "LALMATIA COLLEIRY",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 2919,
      "City District Name": "DEOGHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814166,
      "TXT_PINCODE_LOCALITY": "T.K.GRAM",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3538,
      "City District Name": "JAMTARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814167,
      "TXT_PINCODE_LOCALITY": "RANGA MASALIA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1522,
      "City District Name": "DUMKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814172,
      "TXT_PINCODE_LOCALITY": "TILAIA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 4289,
      "City District Name": "MADHUPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814414,
      "TXT_PINCODE_LOCALITY": "PAHRIDIH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442787,
      "City District Name": "SARATH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 814516,
      "TXT_PINCODE_LOCALITY": "TARABAD",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442744,
      "City District Name": "FATEHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 815101,
      "TXT_PINCODE_LOCALITY": "SURUNGA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442780,
      "City District Name": "PATHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 815109,
      "TXT_PINCODE_LOCALITY": "SABDA CHHOTA GARGRAM",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1798,
      "City District Name": "BARHARWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 815118,
      "TXT_PINCODE_LOCALITY": "CHAMARABAHIAR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442756,
      "City District Name": "JARMUNDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 815151,
      "TXT_PINCODE_LOCALITY": "PALMA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442787,
      "City District Name": "SARATH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 815301,
      "TXT_PINCODE_LOCALITY": "GIRIDIH TOWN",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3255,
      "City District Name": "GIRIDIH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 815302,
      "TXT_PINCODE_LOCALITY": "CHAITADIH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3255,
      "City District Name": "GIRIDIH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 815311,
      "TXT_PINCODE_LOCALITY": "BENIADIH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3255,
      "City District Name": "GIRIDIH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 815312,
      "TXT_PINCODE_LOCALITY": "BENGABAD",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3255,
      "City District Name": "GIRIDIH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 815313,
      "TXT_PINCODE_LOCALITY": "GANWAN",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3255,
      "City District Name": "GIRIDIH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 815314,
      "TXT_PINCODE_LOCALITY": "KHARAGDIHA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3255,
      "City District Name": "GIRIDIH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 815315,
      "TXT_PINCODE_LOCALITY": "MIRZAGANJ",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3255,
      "City District Name": "GIRIDIH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 815316,
      "TXT_PINCODE_LOCALITY": "PACHAMBA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3255,
      "City District Name": "GIRIDIH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 815317,
      "TXT_PINCODE_LOCALITY": "TISRI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3255,
      "City District Name": "GIRIDIH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 815318,
      "TXT_PINCODE_LOCALITY": "JAMUA (GIRIDH)",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3255,
      "City District Name": "GIRIDIH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 815319,
      "TXT_PINCODE_LOCALITY": "AKDONIKALA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3255,
      "City District Name": "GIRIDIH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 815320,
      "TXT_PINCODE_LOCALITY": "DARIDIH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3255,
      "City District Name": "GIRIDIH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 815321,
      "TXT_PINCODE_LOCALITY": "DEORI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 424452,
      "City District Name": "PALAMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 815322,
      "TXT_PINCODE_LOCALITY": "GANDEY",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3255,
      "City District Name": "GIRIDIH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 815323,
      "TXT_PINCODE_LOCALITY": "LATAKI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3255,
      "City District Name": "GIRIDIH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 815324,
      "TXT_PINCODE_LOCALITY": "MAHESHMUNDA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3255,
      "City District Name": "GIRIDIH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 815326,
      "TXT_PINCODE_LOCALITY": "MOTILEDA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3255,
      "City District Name": "GIRIDIH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 815327,
      "TXT_PINCODE_LOCALITY": "PIHARA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3255,
      "City District Name": "GIRIDIH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 815351,
      "TXT_PINCODE_LOCALITY": "KANKI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3538,
      "City District Name": "JAMTARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 815352,
      "TXT_PINCODE_LOCALITY": "KARMATAND",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 2919,
      "City District Name": "DEOGHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 815353,
      "TXT_PINCODE_LOCALITY": "UPPER BAHIYAR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 2919,
      "City District Name": "DEOGHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 815354,
      "TXT_PINCODE_LOCALITY": "MIHIJAM S.O",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3538,
      "City District Name": "JAMTARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 815355,
      "TXT_PINCODE_LOCALITY": "NALA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1522,
      "City District Name": "DUMKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 815356,
      "TXT_PINCODE_LOCALITY": "SARATH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 2919,
      "City District Name": "DEOGHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 815357,
      "TXT_PINCODE_LOCALITY": "MADANKATHA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 2919,
      "City District Name": "DEOGHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 815358,
      "TXT_PINCODE_LOCALITY": "NARAINPUR(DUMKA)",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1522,
      "City District Name": "DUMKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 815359,
      "TXT_PINCODE_LOCALITY": "KUNDAHIT",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3538,
      "City District Name": "JAMTARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 815383,
      "TXT_PINCODE_LOCALITY": "MUNDA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442787,
      "City District Name": "SARATH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 815531,
      "TXT_PINCODE_LOCALITY": "RAMPUR BHITRA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442758,
      "City District Name": "KARMA TANR VIDYASAGAR*",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 815552,
      "TXT_PINCODE_LOCALITY": "TONGODIH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442773,
      "City District Name": "NARAYANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 815553,
      "TXT_PINCODE_LOCALITY": "NAGADARI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442759,
      "City District Name": "KARON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 815757,
      "TXT_PINCODE_LOCALITY": "MAHULJORI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442787,
      "City District Name": "SARATH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 815831,
      "TXT_PINCODE_LOCALITY": "BHADWAKALAN",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442745,
      "City District Name": "GANDE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 815981,
      "TXT_PINCODE_LOCALITY": "TALJHARI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442787,
      "City District Name": "SARATH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 816011,
      "TXT_PINCODE_LOCALITY": "SOLLA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442776,
      "City District Name": "PAKURIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 816101,
      "TXT_PINCODE_LOCALITY": "BARHARWA (DUMKA)",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 5438,
      "City District Name": "SAHIBGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 816102,
      "TXT_PINCODE_LOCALITY": "BARHAIT",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1522,
      "City District Name": "DUMKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 816103,
      "TXT_PINCODE_LOCALITY": "BENAGARIA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1522,
      "City District Name": "DUMKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 816104,
      "TXT_PINCODE_LOCALITY": "HIRANPUR S.O",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1292,
      "City District Name": "PAKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 816105,
      "TXT_PINCODE_LOCALITY": "KOTALPAKUR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 5438,
      "City District Name": "SAHIBGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 816106,
      "TXT_PINCODE_LOCALITY": "MAHESHPUR RAJ",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 4924,
      "City District Name": "PAKAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 816107,
      "TXT_PINCODE_LOCALITY": "PAKAUR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 4924,
      "City District Name": "PAKAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 816108,
      "TXT_PINCODE_LOCALITY": "RAJMAHAL",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 5438,
      "City District Name": "SAHIBGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 816109,
      "TXT_PINCODE_LOCALITY": "MURARILAL CHIRANIA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 5438,
      "City District Name": "SAHIBGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 816110,
      "TXT_PINCODE_LOCALITY": "BANSKOLA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 5438,
      "City District Name": "SAHIBGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 816115,
      "TXT_PINCODE_LOCALITY": "SAKRI GALI GHAT",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 5438,
      "City District Name": "SAHIBGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 816116,
      "TXT_PINCODE_LOCALITY": "TINPAHAR R S",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 5438,
      "City District Name": "SAHIBGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 816117,
      "TXT_PINCODE_LOCALITY": "PAKURIA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 4924,
      "City District Name": "PAKAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 816118,
      "TXT_PINCODE_LOCALITY": "SHIKARIPARA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1522,
      "City District Name": "DUMKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 816119,
      "TXT_PINCODE_LOCALITY": "PATHNA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 5438,
      "City District Name": "SAHIBGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 816120,
      "TXT_PINCODE_LOCALITY": "BORIO",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 4924,
      "City District Name": "PAKAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 816121,
      "TXT_PINCODE_LOCALITY": "LITTIPARA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 4924,
      "City District Name": "PAKAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 816122,
      "TXT_PINCODE_LOCALITY": "JHIKARHATTI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1522,
      "City District Name": "DUMKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 816123,
      "TXT_PINCODE_LOCALITY": "ASANBANI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1522,
      "City District Name": "DUMKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 816124,
      "TXT_PINCODE_LOCALITY": "MOHULPAHARI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 4924,
      "City District Name": "PAKAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 816125,
      "TXT_PINCODE_LOCALITY": "HARIPUR BARIKADARJUA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 4924,
      "City District Name": "PAKAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 816127,
      "TXT_PINCODE_LOCALITY": "SITAPAHARI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 5438,
      "City District Name": "SAHIBGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 816128,
      "TXT_PINCODE_LOCALITY": "ILLAMI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 4924,
      "City District Name": "PAKAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 816129,
      "TXT_PINCODE_LOCALITY": "TALJHARI B.O",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 5438,
      "City District Name": "SAHIBGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 816156,
      "TXT_PINCODE_LOCALITY": "PATOMDIH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442792,
      "City District Name": "SUNDARPAHARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 818149,
      "TXT_PINCODE_LOCALITY": "HETHKARHIYA.",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442787,
      "City District Name": "SARATH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 818312,
      "TXT_PINCODE_LOCALITY": "TELJHRI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442745,
      "City District Name": "GANDE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 819119,
      "TXT_PINCODE_LOCALITY": "SITARAM CHAK",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1522,
      "City District Name": "DUMKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 820100,
      "TXT_PINCODE_LOCALITY": "KESUARI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442544,
      "City District Name": "KARAKAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 820110,
      "TXT_PINCODE_LOCALITY": "CHHOTKI KULHARIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442582,
      "City District Name": "MOHANIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 820111,
      "TXT_PINCODE_LOCALITY": "SEMARIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442582,
      "City District Name": "MOHANIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821100,
      "TXT_PINCODE_LOCALITY": "SIHORIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442495,
      "City District Name": "CHAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821101,
      "TXT_PINCODE_LOCALITY": "BHABUA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424414,
      "City District Name": "KAIMUR (BHABUA)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821102,
      "TXT_PINCODE_LOCALITY": "BHAGWANPUR (SASARAM)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424414,
      "City District Name": "KAIMUR (BHABUA)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821103,
      "TXT_PINCODE_LOCALITY": "CHAINPUR (SASARAM)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424414,
      "City District Name": "KAIMUR (BHABUA)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821104,
      "TXT_PINCODE_LOCALITY": "CHENARI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821105,
      "TXT_PINCODE_LOCALITY": "DURGAWATI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424414,
      "City District Name": "KAIMUR (BHABUA)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821106,
      "TXT_PINCODE_LOCALITY": "HATTA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821107,
      "TXT_PINCODE_LOCALITY": "KHARGARH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821108,
      "TXT_PINCODE_LOCALITY": "KUDRA BAZAR S.O",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424414,
      "City District Name": "KAIMUR (BHABUA)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821109,
      "TXT_PINCODE_LOCALITY": "MOHANIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424414,
      "City District Name": "KAIMUR (BHABUA)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821110,
      "TXT_PINCODE_LOCALITY": "RAMGARH(ARRAH)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424414,
      "City District Name": "KAIMUR (BHABUA)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821111,
      "TXT_PINCODE_LOCALITY": "SHEOSAGAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821112,
      "TXT_PINCODE_LOCALITY": "KOCHAS",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821113,
      "TXT_PINCODE_LOCALITY": "TAKIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821115,
      "TXT_PINCODE_LOCALITY": "SASARAM BAZAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821116,
      "TXT_PINCODE_LOCALITY": "ADHAURA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424414,
      "City District Name": "KAIMUR (BHABUA)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821117,
      "TXT_PINCODE_LOCALITY": "BASAHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424414,
      "City District Name": "KAIMUR (BHABUA)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821118,
      "TXT_PINCODE_LOCALITY": "DARIGAON",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821119,
      "TXT_PINCODE_LOCALITY": "DAROULI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821120,
      "TXT_PINCODE_LOCALITY": "CHAND",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424414,
      "City District Name": "KAIMUR (BHABUA)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821121,
      "TXT_PINCODE_LOCALITY": "AMAON",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821122,
      "TXT_PINCODE_LOCALITY": "TELARI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821123,
      "TXT_PINCODE_LOCALITY": "RAIPUR CHORE",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821124,
      "TXT_PINCODE_LOCALITY": "AKHLASPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424414,
      "City District Name": "KAIMUR (BHABUA)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821125,
      "TXT_PINCODE_LOCALITY": "NAUHATTA(SASARAM)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821137,
      "TXT_PINCODE_LOCALITY": "KAUWADIH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442545,
      "City District Name": "KARGAHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821144,
      "TXT_PINCODE_LOCALITY": "MARAICHA.",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442624,
      "City District Name": "RAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821178,
      "TXT_PINCODE_LOCALITY": "GHARBAIR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5537,
      "City District Name": "SASARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821211,
      "TXT_PINCODE_LOCALITY": "CHATRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821212,
      "TXT_PINCODE_LOCALITY": "BHADOKHARA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821213,
      "TXT_PINCODE_LOCALITY": "BELWAIYAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821236,
      "TXT_PINCODE_LOCALITY": "JAMUARA.",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442651,
      "City District Name": "TIKARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821301,
      "TXT_PINCODE_LOCALITY": "AKHOURI BAZAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821302,
      "TXT_PINCODE_LOCALITY": "AMJHORE",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821303,
      "TXT_PINCODE_LOCALITY": "BANJARI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821304,
      "TXT_PINCODE_LOCALITY": "BAULIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424414,
      "City District Name": "KAIMUR (BHABUA)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821305,
      "TXT_PINCODE_LOCALITY": "DEHRI R S",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821306,
      "TXT_PINCODE_LOCALITY": "DARIHAT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821307,
      "TXT_PINCODE_LOCALITY": "DEHRI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821308,
      "TXT_PINCODE_LOCALITY": "INDRAPURI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821309,
      "TXT_PINCODE_LOCALITY": "OSAON B.O",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821310,
      "TXT_PINCODE_LOCALITY": "HAIHARGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821311,
      "TXT_PINCODE_LOCALITY": "ROHTAS",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821312,
      "TXT_PINCODE_LOCALITY": "TILOUTHU",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821313,
      "TXT_PINCODE_LOCALITY": "DANWAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821314,
      "TXT_PINCODE_LOCALITY": "MANGRAON",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821315,
      "TXT_PINCODE_LOCALITY": "NAHAUNA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821316,
      "TXT_PINCODE_LOCALITY": "KARWANDIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821317,
      "TXT_PINCODE_LOCALITY": "NAUHATTA (BAULIA)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821401,
      "TXT_PINCODE_LOCALITY": "KOLHUA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442562,
      "City District Name": "MADANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 821424,
      "TXT_PINCODE_LOCALITY": "RAIKHAP",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442473,
      "City District Name": "BARUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822021,
      "TXT_PINCODE_LOCALITY": "RAPURA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442732,
      "City District Name": "BHAWNATHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822090,
      "TXT_PINCODE_LOCALITY": "PUNE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822101,
      "TXT_PINCODE_LOCALITY": "PALAMU",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 424452,
      "City District Name": "PALAMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822102,
      "TXT_PINCODE_LOCALITY": "DALTONGANJ COLLEGE",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 424452,
      "City District Name": "PALAMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822103,
      "TXT_PINCODE_LOCALITY": "CHIANKI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442769,
      "City District Name": "MEDININAGAR(DALTONGANJ)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822107,
      "TXT_PINCODE_LOCALITY": "TUMBAGARA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442730,
      "City District Name": "BALUMATH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822110,
      "TXT_PINCODE_LOCALITY": "RAJCHAINPUR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3205,
      "City District Name": "GARHWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822111,
      "TXT_PINCODE_LOCALITY": "BARWADIH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 424452,
      "City District Name": "PALAMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822112,
      "TXT_PINCODE_LOCALITY": "BHAWANATHPUR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3205,
      "City District Name": "GARHWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822113,
      "TXT_PINCODE_LOCALITY": "CHHATARPUR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3205,
      "City District Name": "GARHWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822114,
      "TXT_PINCODE_LOCALITY": "GARHWA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3205,
      "City District Name": "GARHWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822115,
      "TXT_PINCODE_LOCALITY": "HYDERNAGAR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 424452,
      "City District Name": "PALAMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822116,
      "TXT_PINCODE_LOCALITY": "JAPLA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 424452,
      "City District Name": "PALAMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822117,
      "TXT_PINCODE_LOCALITY": "FACTORY",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 424452,
      "City District Name": "PALAMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822118,
      "TXT_PINCODE_LOCALITY": "LESLIGANJ",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 424452,
      "City District Name": "PALAMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822119,
      "TXT_PINCODE_LOCALITY": "MAHUADAND",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 424452,
      "City District Name": "PALAMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822120,
      "TXT_PINCODE_LOCALITY": "MOHAMMADGANJ",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 424452,
      "City District Name": "PALAMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822121,
      "TXT_PINCODE_LOCALITY": "NAGARUNTARI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3205,
      "City District Name": "GARHWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822122,
      "TXT_PINCODE_LOCALITY": "PANKI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822123,
      "TXT_PINCODE_LOCALITY": "PATAN",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 424452,
      "City District Name": "PALAMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822124,
      "TXT_PINCODE_LOCALITY": "RAHALA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3205,
      "City District Name": "GARHWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822125,
      "TXT_PINCODE_LOCALITY": "RANKARAJ",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3205,
      "City District Name": "GARHWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822126,
      "TXT_PINCODE_LOCALITY": "SATBARWA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 424452,
      "City District Name": "PALAMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822127,
      "TXT_PINCODE_LOCALITY": "MANDAL",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3205,
      "City District Name": "GARHWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822128,
      "TXT_PINCODE_LOCALITY": "RAMNA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3205,
      "City District Name": "GARHWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822129,
      "TXT_PINCODE_LOCALITY": "BHAWANATHPUR TOWNSHIP",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3205,
      "City District Name": "GARHWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822130,
      "TXT_PINCODE_LOCALITY": "KUTUMAU",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 424452,
      "City District Name": "PALAMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822131,
      "TXT_PINCODE_LOCALITY": "PALAMU",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 424452,
      "City District Name": "PALAMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822132,
      "TXT_PINCODE_LOCALITY": "SUA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 424452,
      "City District Name": "PALAMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822133,
      "TXT_PINCODE_LOCALITY": "CHINANKI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 424452,
      "City District Name": "PALAMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822134,
      "TXT_PINCODE_LOCALITY": "BISHRAMPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2158,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822136,
      "TXT_PINCODE_LOCALITY": "KALYANPUR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3205,
      "City District Name": "GARHWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822137,
      "TXT_PINCODE_LOCALITY": "KUNDRI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 424452,
      "City District Name": "PALAMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822138,
      "TXT_PINCODE_LOCALITY": "MANJHIAON",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3205,
      "City District Name": "GARHWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822139,
      "TXT_PINCODE_LOCALITY": "MERAL",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 424452,
      "City District Name": "PALAMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822140,
      "TXT_PINCODE_LOCALITY": "OKHARGARA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3205,
      "City District Name": "GARHWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822141,
      "TXT_PINCODE_LOCALITY": "RAJHARA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 424452,
      "City District Name": "PALAMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822206,
      "TXT_PINCODE_LOCALITY": "AGRERFKALA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822213,
      "TXT_PINCODE_LOCALITY": "LANGURAHI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442749,
      "City District Name": "HARIHARGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822214,
      "TXT_PINCODE_LOCALITY": "AMAUNA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822215,
      "TXT_PINCODE_LOCALITY": "CHHARAUNA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822217,
      "TXT_PINCODE_LOCALITY": "BELWAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822218,
      "TXT_PINCODE_LOCALITY": "ARTHU",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424416,
      "City District Name": "ROHTAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822221,
      "TXT_PINCODE_LOCALITY": "JIRO",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442778,
      "City District Name": "PANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822233,
      "TXT_PINCODE_LOCALITY": "UDHOBAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442554,
      "City District Name": "KHIZIRSARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822253,
      "TXT_PINCODE_LOCALITY": "SIMRABERA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442788,
      "City District Name": "SARIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822412,
      "TXT_PINCODE_LOCALITY": "TENDUA HARKES",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442599,
      "City District Name": "OBRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 822416,
      "TXT_PINCODE_LOCALITY": "MAJHIAON",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3446,
      "City District Name": "HUSSAINABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 823001,
      "TXT_PINCODE_LOCALITY": "NEW GODOWN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3216,
      "City District Name": "GAYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 823002,
      "TXT_PINCODE_LOCALITY": "GAYA R S",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3216,
      "City District Name": "GAYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 823003,
      "TXT_PINCODE_LOCALITY": "DURGA ASTHAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3216,
      "City District Name": "GAYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 823004,
      "TXT_PINCODE_LOCALITY": "GAYA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3216,
      "City District Name": "GAYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 823005,
      "TXT_PINCODE_LOCALITY": "ASC CENTRE",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3216,
      "City District Name": "GAYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 823101,
      "TXT_PINCODE_LOCALITY": "PHULSARAI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442767,
      "City District Name": "MANDU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 823129,
      "TXT_PINCODE_LOCALITY": "BUXAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2158,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 823134,
      "TXT_PINCODE_LOCALITY": "KASHIJHARIA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 2718,
      "City District Name": "CHAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 823205,
      "TXT_PINCODE_LOCALITY": "DHANBAD",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 823224,
      "TXT_PINCODE_LOCALITY": "SAHIL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442648,
      "City District Name": "TAN KUPPA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 823307,
      "TXT_PINCODE_LOCALITY": "CHHOTAMAJHILADIH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442728,
      "City District Name": "BAGHMARA-CUM-KATRAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 823311,
      "TXT_PINCODE_LOCALITY": "THAMH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442584,
      "City District Name": "MUHRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824001,
      "TXT_PINCODE_LOCALITY": "RATRA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3263,
      "City District Name": "GOBINDPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824002,
      "TXT_PINCODE_LOCALITY": "DUADATANR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442798,
      "City District Name": "TUNDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824003,
      "TXT_PINCODE_LOCALITY": "LAKSHIPUR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442775,
      "City District Name": "NIRSA-CUM-CHIRKUNDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824006,
      "TXT_PINCODE_LOCALITY": "TOLA ASURBANDH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442784,
      "City District Name": "PURBI TUNDI*",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824019,
      "TXT_PINCODE_LOCALITY": "SIMRI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442559,
      "City District Name": "KUTUMBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824032,
      "TXT_PINCODE_LOCALITY": "SOHJANA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442518,
      "City District Name": "FATEHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824101,
      "TXT_PINCODE_LOCALITY": "AURANGABAD KUTCHERY",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2158,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824102,
      "TXT_PINCODE_LOCALITY": "AURANGABAD",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2158,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824103,
      "TXT_PINCODE_LOCALITY": "WAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2158,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824106,
      "TXT_PINCODE_LOCALITY": "POAY",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442562,
      "City District Name": "MADANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824107,
      "TXT_PINCODE_LOCALITY": "SONAURA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4646,
      "City District Name": "NABINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824110,
      "TXT_PINCODE_LOCALITY": "SANRI.",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442559,
      "City District Name": "KUTUMBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824111,
      "TXT_PINCODE_LOCALITY": "AMBA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2158,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824112,
      "TXT_PINCODE_LOCALITY": "BARUN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2158,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824113,
      "TXT_PINCODE_LOCALITY": "DAUDNAGAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2158,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824114,
      "TXT_PINCODE_LOCALITY": "DEOHARA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2158,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824115,
      "TXT_PINCODE_LOCALITY": "ANURAHI B.O",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 441199,
      "City District Name": "ARWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824116,
      "TXT_PINCODE_LOCALITY": "TINERI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442555,
      "City District Name": "KONCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824118,
      "TXT_PINCODE_LOCALITY": "GURARU MILLS",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3216,
      "City District Name": "GAYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824119,
      "TXT_PINCODE_LOCALITY": "HARIHAR GANJ",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 424452,
      "City District Name": "PALAMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824120,
      "TXT_PINCODE_LOCALITY": "HASPURA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2158,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824121,
      "TXT_PINCODE_LOCALITY": "JAMIRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2158,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824122,
      "TXT_PINCODE_LOCALITY": "JAKHIM",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2158,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824123,
      "TXT_PINCODE_LOCALITY": "KUTUMBA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2158,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824124,
      "TXT_PINCODE_LOCALITY": "OBRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2158,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824125,
      "TXT_PINCODE_LOCALITY": "RAFIGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2158,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824126,
      "TXT_PINCODE_LOCALITY": "ANTI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3216,
      "City District Name": "GAYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824127,
      "TXT_PINCODE_LOCALITY": "SOHASA B.O",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 441199,
      "City District Name": "ARWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824128,
      "TXT_PINCODE_LOCALITY": "KERKI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3216,
      "City District Name": "GAYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824129,
      "TXT_PINCODE_LOCALITY": "RUKULDI JHIKATIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2158,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824130,
      "TXT_PINCODE_LOCALITY": "DIHRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2158,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824140,
      "TXT_PINCODE_LOCALITY": "BELARHIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5248,
      "City District Name": "RAFIGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824142,
      "TXT_PINCODE_LOCALITY": "BERHNA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5248,
      "City District Name": "RAFIGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824143,
      "TXT_PINCODE_LOCALITY": "DAUDNAGAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2158,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824200,
      "TXT_PINCODE_LOCALITY": "PARAIYA KHURD",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442604,
      "City District Name": "PARAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824201,
      "TXT_PINCODE_LOCALITY": "BARACHATTI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3216,
      "City District Name": "GAYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824202,
      "TXT_PINCODE_LOCALITY": "DEO",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2158,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824203,
      "TXT_PINCODE_LOCALITY": "GOH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3216,
      "City District Name": "GAYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824204,
      "TXT_PINCODE_LOCALITY": "UMED CHAK ARAZI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442513,
      "City District Name": "DUMARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824205,
      "TXT_PINCODE_LOCALITY": "GURUA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3216,
      "City District Name": "GAYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824206,
      "TXT_PINCODE_LOCALITY": "IMAMGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3216,
      "City District Name": "GAYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824207,
      "TXT_PINCODE_LOCALITY": "KONCH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3216,
      "City District Name": "GAYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824208,
      "TXT_PINCODE_LOCALITY": "MADANPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3216,
      "City District Name": "GAYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824209,
      "TXT_PINCODE_LOCALITY": "PARAIYA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3216,
      "City District Name": "GAYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824210,
      "TXT_PINCODE_LOCALITY": "RANIGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3216,
      "City District Name": "GAYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824211,
      "TXT_PINCODE_LOCALITY": "SHERGHATI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3216,
      "City District Name": "GAYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824212,
      "TXT_PINCODE_LOCALITY": "AMAS",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3216,
      "City District Name": "GAYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824213,
      "TXT_PINCODE_LOCALITY": "UMGA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2158,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824214,
      "TXT_PINCODE_LOCALITY": "DUMARIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3216,
      "City District Name": "GAYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824215,
      "TXT_PINCODE_LOCALITY": "ARAURA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2158,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824216,
      "TXT_PINCODE_LOCALITY": "KETAKI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2158,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824217,
      "TXT_PINCODE_LOCALITY": "BANKEY BAZAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3216,
      "City District Name": "GAYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824218,
      "TXT_PINCODE_LOCALITY": "BERIKHERIAWAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2158,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824219,
      "TXT_PINCODE_LOCALITY": "ANNUA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3216,
      "City District Name": "GAYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824220,
      "TXT_PINCODE_LOCALITY": "SELARI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442469,
      "City District Name": "BARACHATTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824221,
      "TXT_PINCODE_LOCALITY": "SALWAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442535,
      "City District Name": "IMAMGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824224,
      "TXT_PINCODE_LOCALITY": "TETARIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442172,
      "City District Name": "AMAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824227,
      "TXT_PINCODE_LOCALITY": "BELAON",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3216,
      "City District Name": "GAYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824231,
      "TXT_PINCODE_LOCALITY": "BODH GAYA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3216,
      "City District Name": "GAYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824232,
      "TXT_PINCODE_LOCALITY": "FATEHPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3216,
      "City District Name": "GAYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824233,
      "TXT_PINCODE_LOCALITY": "KHIZERSARAI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3216,
      "City District Name": "GAYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824234,
      "TXT_PINCODE_LOCALITY": "MAGADH UNIVERSITY",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3216,
      "City District Name": "GAYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824235,
      "TXT_PINCODE_LOCALITY": "MOW",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3216,
      "City District Name": "GAYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824236,
      "TXT_PINCODE_LOCALITY": "TEKARI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3216,
      "City District Name": "GAYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824237,
      "TXT_PINCODE_LOCALITY": "CHERKI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3216,
      "City District Name": "GAYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824238,
      "TXT_PINCODE_LOCALITY": "LODHWAY",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3216,
      "City District Name": "GAYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824239,
      "TXT_PINCODE_LOCALITY": "SARBAHDA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3216,
      "City District Name": "GAYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824252,
      "TXT_PINCODE_LOCALITY": "GAYA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3216,
      "City District Name": "GAYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824282,
      "TXT_PINCODE_LOCALITY": "THEKAHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442648,
      "City District Name": "TAN KUPPA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824283,
      "TXT_PINCODE_LOCALITY": "DIHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442476,
      "City District Name": "BELAGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824301,
      "TXT_PINCODE_LOCALITY": "NABINAGAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2158,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824302,
      "TXT_PINCODE_LOCALITY": "TANDWA(GAYA)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3216,
      "City District Name": "GAYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824303,
      "TXT_PINCODE_LOCALITY": "NABINAGAR RD",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2158,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824304,
      "TXT_PINCODE_LOCALITY": "NPGCL ADITI NAGAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2158,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824333,
      "TXT_PINCODE_LOCALITY": "NAUDIHA GIRDHAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442554,
      "City District Name": "KHIZIRSARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824412,
      "TXT_PINCODE_LOCALITY": "POKTHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442599,
      "City District Name": "OBRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824421,
      "TXT_PINCODE_LOCALITY": "SAREA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442558,
      "City District Name": "KURTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824505,
      "TXT_PINCODE_LOCALITY": "LATHIA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 2355,
      "City District Name": "BARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824506,
      "TXT_PINCODE_LOCALITY": "JUNGLE CHARAN DASS KENDUA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442738,
      "City District Name": "CHAUPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824723,
      "TXT_PINCODE_LOCALITY": "IMADPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442648,
      "City District Name": "TAN KUPPA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 824805,
      "TXT_PINCODE_LOCALITY": "SARAI TANR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442529,
      "City District Name": "GURUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825100,
      "TXT_PINCODE_LOCALITY": "JUD. TULCEA",
      "NUM_STATE_CD": 857,
      "State Name": "ROMANIA",
      "NUM_CITYDISTRICT_CD": 444232,
      "City District Name": "BABADAG",
      "NUM_COUNTRY_CD": 131
    },
    {
      "NUM_PINCODE": 825101,
      "TXT_PINCODE_LOCALITY": "SOSO B.O",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 441205,
      "City District Name": "RAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825102,
      "TXT_PINCODE_LOCALITY": "DHORI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825103,
      "TXT_PINCODE_LOCALITY": "SIMARIA B.O",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 2721,
      "City District Name": "CHATRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825106,
      "TXT_PINCODE_LOCALITY": "DUMRI (HAZARIBAGH)",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3255,
      "City District Name": "GIRIDIH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825107,
      "TXT_PINCODE_LOCALITY": "ISHRI  BAZAR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3255,
      "City District Name": "GIRIDIH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825108,
      "TXT_PINCODE_LOCALITY": "PIRTAND",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3255,
      "City District Name": "GIRIDIH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825109,
      "TXT_PINCODE_LOCALITY": "ANGWALI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825110,
      "TXT_PINCODE_LOCALITY": "NAGRI KALAN",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825111,
      "TXT_PINCODE_LOCALITY": "PALGANJ",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3255,
      "City District Name": "GIRIDIH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825114,
      "TXT_PINCODE_LOCALITY": "BENZI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825116,
      "TXT_PINCODE_LOCALITY": "SEWARI ALIAS MANGALDIH.",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3446,
      "City District Name": "HUSSAINABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825125,
      "TXT_PINCODE_LOCALITY": "SIRU",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442742,
      "City District Name": "DULMI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825132,
      "TXT_PINCODE_LOCALITY": "SATGAWAN S.O",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 4024,
      "City District Name": "KODARMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825137,
      "TXT_PINCODE_LOCALITY": "SEMRATARI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 4024,
      "City District Name": "KODARMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825167,
      "TXT_PINCODE_LOCALITY": "GALAGI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3255,
      "City District Name": "GIRIDIH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825173,
      "TXT_PINCODE_LOCALITY": "ICHAKDIH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442767,
      "City District Name": "MANDU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825205,
      "TXT_PINCODE_LOCALITY": "PATHARKUA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442775,
      "City District Name": "NIRSA-CUM-CHIRKUNDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825213,
      "TXT_PINCODE_LOCALITY": "PONDRI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442737,
      "City District Name": "CHANHO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825214,
      "TXT_PINCODE_LOCALITY": "ARA.",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442737,
      "City District Name": "CHANHO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825220,
      "TXT_PINCODE_LOCALITY": "THANDABAHIAR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442743,
      "City District Name": "DUMRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825221,
      "TXT_PINCODE_LOCALITY": "CHENGRA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442796,
      "City District Name": "TATI JHARIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825223,
      "TXT_PINCODE_LOCALITY": "BARKATHA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825301,
      "TXT_PINCODE_LOCALITY": "HAZARIBAGH HO",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825302,
      "TXT_PINCODE_LOCALITY": "COLLEGE MORE S.O",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 738,
      "City District Name": "HAZARIBAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825303,
      "TXT_PINCODE_LOCALITY": "CHURCHU",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825304,
      "TXT_PINCODE_LOCALITY": "TELO",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 2697,
      "City District Name": "CHANDRAPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825311,
      "TXT_PINCODE_LOCALITY": "BARKAGAON",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825312,
      "TXT_PINCODE_LOCALITY": "BISHUNGARH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3255,
      "City District Name": "GIRIDIH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825313,
      "TXT_PINCODE_LOCALITY": "DAROO",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825314,
      "TXT_PINCODE_LOCALITY": "GHATOTAND",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825315,
      "TXT_PINCODE_LOCALITY": "KONARDAM",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825316,
      "TXT_PINCODE_LOCALITY": "KUJU",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825317,
      "TXT_PINCODE_LOCALITY": "MERU",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825318,
      "TXT_PINCODE_LOCALITY": "PALAUNJIA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3255,
      "City District Name": "GIRIDIH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825319,
      "TXT_PINCODE_LOCALITY": "REFORMATORY SCHOOL",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825320,
      "TXT_PINCODE_LOCALITY": "BAGODIH B.O",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3255,
      "City District Name": "GIRIDIH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825321,
      "TXT_PINCODE_LOCALITY": "TANDWA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 2721,
      "City District Name": "CHATRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825322,
      "TXT_PINCODE_LOCALITY": "BAGODAR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825323,
      "TXT_PINCODE_LOCALITY": "BARKATHA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825324,
      "TXT_PINCODE_LOCALITY": "JAI NAGAR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825325,
      "TXT_PINCODE_LOCALITY": "LAIYO",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 441205,
      "City District Name": "RAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825326,
      "TXT_PINCODE_LOCALITY": "TAPIN",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825327,
      "TXT_PINCODE_LOCALITY": "JHARPO",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825328,
      "TXT_PINCODE_LOCALITY": "KEREDARI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825329,
      "TXT_PINCODE_LOCALITY": "SHIKOHABAD",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3255,
      "City District Name": "GIRIDIH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825330,
      "TXT_PINCODE_LOCALITY": "TOPA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825331,
      "TXT_PINCODE_LOCALITY": "NAWADIH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3255,
      "City District Name": "GIRIDIH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825332,
      "TXT_PINCODE_LOCALITY": "BAGODAR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3255,
      "City District Name": "GIRIDIH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825333,
      "TXT_PINCODE_LOCALITY": "KESHWARI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 2721,
      "City District Name": "CHATRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825334,
      "TXT_PINCODE_LOCALITY": "KATKAMSSNDI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825335,
      "TXT_PINCODE_LOCALITY": "PELAWAL",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825336,
      "TXT_PINCODE_LOCALITY": "CHARHI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825337,
      "TXT_PINCODE_LOCALITY": "KULGO",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3255,
      "City District Name": "GIRIDIH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825338,
      "TXT_PINCODE_LOCALITY": "MANDU",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825339,
      "TXT_PINCODE_LOCALITY": "NAGRI CHURCHU",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825347,
      "TXT_PINCODE_LOCALITY": "GUNTUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825354,
      "TXT_PINCODE_LOCALITY": "BEKO",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442755,
      "City District Name": "JAINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825383,
      "TXT_PINCODE_LOCALITY": "LEMHOA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442795,
      "City District Name": "TANDWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825400,
      "TXT_PINCODE_LOCALITY": "MOKTAMA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442751,
      "City District Name": "ICHAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825401,
      "TXT_PINCODE_LOCALITY": "CHATRA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 2721,
      "City District Name": "CHATRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825402,
      "TXT_PINCODE_LOCALITY": "ICHAK",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825403,
      "TXT_PINCODE_LOCALITY": "JORI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 2721,
      "City District Name": "CHATRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825404,
      "TXT_PINCODE_LOCALITY": "PRATAPPUR HAZARIBAGH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825405,
      "TXT_PINCODE_LOCALITY": "BARHI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825406,
      "TXT_PINCODE_LOCALITY": "CHAUPARAN",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825407,
      "TXT_PINCODE_LOCALITY": "DOMCHANCH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 4024,
      "City District Name": "KODARMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825408,
      "TXT_PINCODE_LOCALITY": "KODERMA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 2721,
      "City District Name": "CHATRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825409,
      "TXT_PINCODE_LOCALITY": "JHUMRITELAIYA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 4024,
      "City District Name": "KODARMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825410,
      "TXT_PINCODE_LOCALITY": "KODARMA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 4024,
      "City District Name": "KODARMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825411,
      "TXT_PINCODE_LOCALITY": "PADMA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825412,
      "TXT_PINCODE_LOCALITY": "RAJDHANWAR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3255,
      "City District Name": "GIRIDIH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825413,
      "TXT_PINCODE_LOCALITY": "KODARMA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825414,
      "TXT_PINCODE_LOCALITY": "TUTKI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442767,
      "City District Name": "MANDU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825415,
      "TXT_PINCODE_LOCALITY": "MASNODIH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 4024,
      "City District Name": "KODARMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825416,
      "TXT_PINCODE_LOCALITY": "LOKAI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 4024,
      "City District Name": "KODARMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825417,
      "TXT_PINCODE_LOCALITY": "BEKOBAR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 4024,
      "City District Name": "KODARMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825418,
      "TXT_PINCODE_LOCALITY": "KUBRI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3255,
      "City District Name": "GIRIDIH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825419,
      "TXT_PINCODE_LOCALITY": "DHORAKALA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 4024,
      "City District Name": "KODARMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825420,
      "TXT_PINCODE_LOCALITY": "HUNTERGANJ",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 2721,
      "City District Name": "CHATRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825421,
      "TXT_PINCODE_LOCALITY": "CHANDWARA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825422,
      "TXT_PINCODE_LOCALITY": "GUMO BARWADIH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 4024,
      "City District Name": "KODARMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825429,
      "TXT_PINCODE_LOCALITY": "THENI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 425471,
      "City District Name": "THENI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825510,
      "TXT_PINCODE_LOCALITY": "ARGAMO",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 4024,
      "City District Name": "KODARMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825511,
      "TXT_PINCODE_LOCALITY": "ITIJ",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442762,
      "City District Name": "KEREDARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825606,
      "TXT_PINCODE_LOCALITY": "SINGHRAWAN",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442738,
      "City District Name": "CHAUPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825611,
      "TXT_PINCODE_LOCALITY": "BENGWARI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442762,
      "City District Name": "KEREDARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 825710,
      "TXT_PINCODE_LOCALITY": "BHARUCH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 826001,
      "TXT_PINCODE_LOCALITY": "DHANBAD H O",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 826003,
      "TXT_PINCODE_LOCALITY": "JAGJIWAN NAGAR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 826004,
      "TXT_PINCODE_LOCALITY": "INDIAN SCHOOL OF MINES",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 826005,
      "TXT_PINCODE_LOCALITY": "KOYLANAGAR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 826006,
      "TXT_PINCODE_LOCALITY": "DHANBAD",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 826007,
      "TXT_PINCODE_LOCALITY": "DHANBAD",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 826008,
      "TXT_PINCODE_LOCALITY": "KGASHRAM",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 826009,
      "TXT_PINCODE_LOCALITY": "DHANBAD",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 826010,
      "TXT_PINCODE_LOCALITY": "BARWADDA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 826011,
      "TXT_PINCODE_LOCALITY": "NDC DHANBADÂ",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 826106,
      "TXT_PINCODE_LOCALITY": "DHANBAD",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 826124,
      "TXT_PINCODE_LOCALITY": "A.C. C. CLOLONY",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 826325,
      "TXT_PINCODE_LOCALITY": "BARUGHUTU",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442767,
      "City District Name": "MANDU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 826406,
      "TXT_PINCODE_LOCALITY": "CHACHROKH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442738,
      "City District Name": "CHAUPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 827001,
      "TXT_PINCODE_LOCALITY": "BOKARO STEEL CITY H O",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 827003,
      "TXT_PINCODE_LOCALITY": "HERLA SECTOR III",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 827004,
      "TXT_PINCODE_LOCALITY": "SECTOR IV DHANBAD",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 827006,
      "TXT_PINCODE_LOCALITY": "BOKARO STEEL CITY",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 827009,
      "TXT_PINCODE_LOCALITY": "SECTOR IX",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 827010,
      "TXT_PINCODE_LOCALITY": "BOKARO STEEL CITY R S",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 827011,
      "TXT_PINCODE_LOCALITY": "BOKARO STEEL CITY SO",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 827012,
      "TXT_PINCODE_LOCALITY": "BOKARO STEEL CITY",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 827013,
      "TXT_PINCODE_LOCALITY": "CHAS",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 827014,
      "TXT_PINCODE_LOCALITY": "BALIDIH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 827015,
      "TXT_PINCODE_LOCALITY": "BOKARO",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 827016,
      "TXT_PINCODE_LOCALITY": "NDC B.S.CITY",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 827103,
      "TXT_PINCODE_LOCALITY": "CHAS BAZAR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 827130,
      "TXT_PINCODE_LOCALITY": "TELMATIYA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 2718,
      "City District Name": "CHAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 827302,
      "TXT_PINCODE_LOCALITY": "TELMUNGA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442761,
      "City District Name": "KASMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 827569,
      "TXT_PINCODE_LOCALITY": "SURAT",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828101,
      "TXT_PINCODE_LOCALITY": "BANSJORA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828102,
      "TXT_PINCODE_LOCALITY": "BHAGABANDH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828103,
      "TXT_PINCODE_LOCALITY": "BHELATAND",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828104,
      "TXT_PINCODE_LOCALITY": "ACC COLONY",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828105,
      "TXT_PINCODE_LOCALITY": "SHRAMIK NAGAR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828106,
      "TXT_PINCODE_LOCALITY": "DHANSAR - 1592",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828107,
      "TXT_PINCODE_LOCALITY": "DOMGARH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828108,
      "TXT_PINCODE_LOCALITY": "FUEL RESEARCH INSTITUTE",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828109,
      "TXT_PINCODE_LOCALITY": "GOBINDPUR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828110,
      "TXT_PINCODE_LOCALITY": "JEALGORA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828111,
      "TXT_PINCODE_LOCALITY": "AMLAPARA JHARIA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828112,
      "TXT_PINCODE_LOCALITY": "JAMADOBA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828113,
      "TXT_PINCODE_LOCALITY": "KATRASGARH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828114,
      "TXT_PINCODE_LOCALITY": "KATRAS BAZAR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828115,
      "TXT_PINCODE_LOCALITY": "KHASJEENAGORA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828116,
      "TXT_PINCODE_LOCALITY": "KUSUNDA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828117,
      "TXT_PINCODE_LOCALITY": "KUSTORE",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828119,
      "TXT_PINCODE_LOCALITY": "PATHARDIH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828120,
      "TXT_PINCODE_LOCALITY": "MOTI NAGAR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828121,
      "TXT_PINCODE_LOCALITY": "SIJUA - 3820",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828122,
      "TXT_PINCODE_LOCALITY": "SINDRI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828123,
      "TXT_PINCODE_LOCALITY": "SINDRI INSTITUTE",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828124,
      "TXT_PINCODE_LOCALITY": "A C C COLONY",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828125,
      "TXT_PINCODE_LOCALITY": "SONARDIH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828126,
      "TXT_PINCODE_LOCALITY": "SUDAMDIH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828127,
      "TXT_PINCODE_LOCALITY": "SARAIDHELA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828128,
      "TXT_PINCODE_LOCALITY": "TUNDU",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828129,
      "TXT_PINCODE_LOCALITY": "MUNIDIH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828130,
      "TXT_PINCODE_LOCALITY": "B.POLYTECHNIC S.O",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828131,
      "TXT_PINCODE_LOCALITY": "LODNA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828132,
      "TXT_PINCODE_LOCALITY": "PATHARDIH COAL",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828133,
      "TXT_PINCODE_LOCALITY": "BHULAN BARARI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828134,
      "TXT_PINCODE_LOCALITY": "CHANDANKIARI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828135,
      "TXT_PINCODE_LOCALITY": "CHASNALA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828136,
      "TXT_PINCODE_LOCALITY": "CHANDRA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828137,
      "TXT_PINCODE_LOCALITY": "JHALBARDA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828138,
      "TXT_PINCODE_LOCALITY": "KHAMARBENDI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828139,
      "TXT_PINCODE_LOCALITY": "PUPUNKI ASHRAM",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828140,
      "TXT_PINCODE_LOCALITY": "SATANPUR(DHANBAD)",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828141,
      "TXT_PINCODE_LOCALITY": "KANKOMATH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828142,
      "TXT_PINCODE_LOCALITY": "TUNDI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828143,
      "TXT_PINCODE_LOCALITY": "BHAGATDIH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828144,
      "TXT_PINCODE_LOCALITY": "TISRA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828145,
      "TXT_PINCODE_LOCALITY": "BARAPUTKI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828146,
      "TXT_PINCODE_LOCALITY": "CHANDORE",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828147,
      "TXT_PINCODE_LOCALITY": "KIRKEND",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828201,
      "TXT_PINCODE_LOCALITY": "DUMARKUNDA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828202,
      "TXT_PINCODE_LOCALITY": "CHIRKUNDA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828203,
      "TXT_PINCODE_LOCALITY": "KUMARDHUBI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828204,
      "TXT_PINCODE_LOCALITY": "MUGMA - 3149",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828205,
      "TXT_PINCODE_LOCALITY": "NIRSACHATTI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828206,
      "TXT_PINCODE_LOCALITY": "PANCHER DAM",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828207,
      "TXT_PINCODE_LOCALITY": "MAITHONDAM",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828301,
      "TXT_PINCODE_LOCALITY": "BHAGA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828302,
      "TXT_PINCODE_LOCALITY": "BHOWRA - 2036",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828303,
      "TXT_PINCODE_LOCALITY": "BHOJUDIH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828304,
      "TXT_PINCODE_LOCALITY": "MALKERA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828305,
      "TXT_PINCODE_LOCALITY": "MAHODA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828306,
      "TXT_PINCODE_LOCALITY": "NAWAGARH(DHANBAD)",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828307,
      "TXT_PINCODE_LOCALITY": "NUDKHURKEE",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828308,
      "TXT_PINCODE_LOCALITY": "BHATDEE",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828309,
      "TXT_PINCODE_LOCALITY": "JITPUR COLLIERY",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828310,
      "TXT_PINCODE_LOCALITY": "BAGHMARA BAZAR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828311,
      "TXT_PINCODE_LOCALITY": "AMLABAD COLLIERY",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828315,
      "TXT_PINCODE_LOCALITY": "GURUDIH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3323,
      "City District Name": "GUMIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828383,
      "TXT_PINCODE_LOCALITY": "PADUMPUR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442795,
      "City District Name": "TANDWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828401,
      "TXT_PINCODE_LOCALITY": "GOMOH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828402,
      "TXT_PINCODE_LOCALITY": "TOPCHANCHI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828403,
      "TXT_PINCODE_LOCALITY": "CHANDRAPURA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828404,
      "TXT_PINCODE_LOCALITY": "DUGDA COAL WASHERY",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828405,
      "TXT_PINCODE_LOCALITY": "TELO",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828409,
      "TXT_PINCODE_LOCALITY": "SUGA SANKH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442755,
      "City District Name": "JAINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828412,
      "TXT_PINCODE_LOCALITY": "PIPRADIH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 6008,
      "City District Name": "TOPCHANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 828504,
      "TXT_PINCODE_LOCALITY": "MUGMA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829101,
      "TXT_PINCODE_LOCALITY": "ARGADA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829102,
      "TXT_PINCODE_LOCALITY": "BARKAKANA S.O",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 441205,
      "City District Name": "RAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829103,
      "TXT_PINCODE_LOCALITY": "BARKAKANA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 5049,
      "City District Name": "PATRATU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829104,
      "TXT_PINCODE_LOCALITY": "BERMO - 624",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829105,
      "TXT_PINCODE_LOCALITY": "BHADANINADR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829106,
      "TXT_PINCODE_LOCALITY": "BHURKUNDA BAZAR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829107,
      "TXT_PINCODE_LOCALITY": "BOKARO THERMAL",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829108,
      "TXT_PINCODE_LOCALITY": "GIDDI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829109,
      "TXT_PINCODE_LOCALITY": "GIDDI COLLIERY",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829110,
      "TXT_PINCODE_LOCALITY": "SONDIMRA B.O",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 441205,
      "City District Name": "RAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829111,
      "TXT_PINCODE_LOCALITY": "HARDIAMO B.O",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829112,
      "TXT_PINCODE_LOCALITY": "INDIAN EXPLOSIVE GOMIA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3255,
      "City District Name": "GIRIDIH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829113,
      "TXT_PINCODE_LOCALITY": "JARANGDIH COLLIERY",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829114,
      "TXT_PINCODE_LOCALITY": "CHALKARI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829115,
      "TXT_PINCODE_LOCALITY": "KASMAR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829116,
      "TXT_PINCODE_LOCALITY": "KATHARA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829117,
      "TXT_PINCODE_LOCALITY": "MARAR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 441205,
      "City District Name": "RAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829118,
      "TXT_PINCODE_LOCALITY": "JAINAGAR B.O",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 441205,
      "City District Name": "RAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829119,
      "TXT_PINCODE_LOCALITY": "PATRATU THERMAL POWER",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829120,
      "TXT_PINCODE_LOCALITY": "PATRATU DIESAL COLONY",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829121,
      "TXT_PINCODE_LOCALITY": "PETERBAR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829122,
      "TXT_PINCODE_LOCALITY": "RAMGARH CANTT. HO.",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829123,
      "TXT_PINCODE_LOCALITY": "RIGHT BANK TENUGHAT DAM",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829124,
      "TXT_PINCODE_LOCALITY": "RELIGARHA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829125,
      "TXT_PINCODE_LOCALITY": "SAYAL S.O",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 441205,
      "City District Name": "RAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829126,
      "TXT_PINCODE_LOCALITY": "SOUNDA COLLIERY",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829127,
      "TXT_PINCODE_LOCALITY": "SUNDAY BAZAR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3255,
      "City District Name": "GIRIDIH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829128,
      "TXT_PINCODE_LOCALITY": "SWANG COLLIERY",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3255,
      "City District Name": "GIRIDIH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829129,
      "TXT_PINCODE_LOCALITY": "TENUGHAT DAM PROJECT",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829130,
      "TXT_PINCODE_LOCALITY": "PUNJAB REGIMENTAL CENTRE",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829131,
      "TXT_PINCODE_LOCALITY": "SRC S.O",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 441205,
      "City District Name": "RAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829132,
      "TXT_PINCODE_LOCALITY": "BHANDARA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829133,
      "TXT_PINCODE_LOCALITY": "SOUNDA \"D\" COLLIERY",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829134,
      "TXT_PINCODE_LOCALITY": "TIRLA B.O",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 441205,
      "City District Name": "RAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829135,
      "TXT_PINCODE_LOCALITY": "BHURKUNDA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 2721,
      "City District Name": "CHATRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829136,
      "TXT_PINCODE_LOCALITY": "PENK",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829137,
      "TXT_PINCODE_LOCALITY": "KARMA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829138,
      "TXT_PINCODE_LOCALITY": "TANTRI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829139,
      "TXT_PINCODE_LOCALITY": "GORATU",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442742,
      "City District Name": "DULMI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829140,
      "TXT_PINCODE_LOCALITY": "CHAINGADA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829141,
      "TXT_PINCODE_LOCALITY": "CHAMPI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829143,
      "TXT_PINCODE_LOCALITY": "BALKUDRA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 441205,
      "City District Name": "RAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829144,
      "TXT_PINCODE_LOCALITY": "PHUSRO BAZAR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829145,
      "TXT_PINCODE_LOCALITY": "SIRKA ND S.O",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 441205,
      "City District Name": "RAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829146,
      "TXT_PINCODE_LOCALITY": "BHAREET NAGAR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829147,
      "TXT_PINCODE_LOCALITY": "CHALKARI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829149,
      "TXT_PINCODE_LOCALITY": "T.T.P.S. LALPANI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829150,
      "TXT_PINCODE_LOCALITY": "SIKANI B.O",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 441205,
      "City District Name": "RAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829201,
      "TXT_PINCODE_LOCALITY": "BACHRA S.O",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 2721,
      "City District Name": "CHATRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829202,
      "TXT_PINCODE_LOCALITY": "BALUMATH S.O",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 4229,
      "City District Name": "LATEHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829203,
      "TXT_PINCODE_LOCALITY": "CHANDWA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 424452,
      "City District Name": "PALAMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829204,
      "TXT_PINCODE_LOCALITY": "CHIPADOHAR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 424452,
      "City District Name": "PALAMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829205,
      "TXT_PINCODE_LOCALITY": "KHALARI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829206,
      "TXT_PINCODE_LOCALITY": "LATEHAR S.O",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 4229,
      "City District Name": "LATEHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829207,
      "TXT_PINCODE_LOCALITY": "LATEHAR R S",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 424452,
      "City District Name": "PALAMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829208,
      "TXT_PINCODE_LOCALITY": "MECLUSKIEGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829209,
      "TXT_PINCODE_LOCALITY": "RAY",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829210,
      "TXT_PINCODE_LOCALITY": "GOLA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829211,
      "TXT_PINCODE_LOCALITY": "GOMIA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829301,
      "TXT_PINCODE_LOCALITY": "JAINA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829302,
      "TXT_PINCODE_LOCALITY": "BANDHDIH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829317,
      "TXT_PINCODE_LOCALITY": "KARMA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829399,
      "TXT_PINCODE_LOCALITY": "DARI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829402,
      "TXT_PINCODE_LOCALITY": "CHAINPUR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 829406,
      "TXT_PINCODE_LOCALITY": "SANJHA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442738,
      "City District Name": "CHAUPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 830003,
      "TXT_PINCODE_LOCALITY": "TUMBAGUTTU",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442772,
      "City District Name": "NAMKUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 830005,
      "TXT_PINCODE_LOCALITY": "KADMA JAMSHEDPUR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 743,
      "City District Name": "JAMSHEDPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 830234,
      "TXT_PINCODE_LOCALITY": "SILMA.",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442760,
      "City District Name": "KARRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 830510,
      "TXT_PINCODE_LOCALITY": "SARJAMDIH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442790,
      "City District Name": "SILLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 831001,
      "TXT_PINCODE_LOCALITY": "SAKCHI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 831002,
      "TXT_PINCODE_LOCALITY": "TATANAGAR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 831003,
      "TXT_PINCODE_LOCALITY": "GOLMURI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 831004,
      "TXT_PINCODE_LOCALITY": "JAMSHEDPUR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 441054,
      "City District Name": "EAST SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 831005,
      "TXT_PINCODE_LOCALITY": "KADMA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 831006,
      "TXT_PINCODE_LOCALITY": "JUGSALAI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 831007,
      "TXT_PINCODE_LOCALITY": "BURMA MINES",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 831008,
      "TXT_PINCODE_LOCALITY": "JAMSHEDPUR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 831009,
      "TXT_PINCODE_LOCALITY": "AGRICO",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 831010,
      "TXT_PINCODE_LOCALITY": "TELCO G MS OFFICE",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 831011,
      "TXT_PINCODE_LOCALITY": "JAMSHEDPUR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 743,
      "City District Name": "JAMSHEDPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 831012,
      "TXT_PINCODE_LOCALITY": "MANGO",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 831013,
      "TXT_PINCODE_LOCALITY": "ADITYAPUR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 831014,
      "TXT_PINCODE_LOCALITY": "R I T JAMSHEDPUR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 831015,
      "TXT_PINCODE_LOCALITY": "GOBINDPUR HOUSING COLONY",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 831016,
      "TXT_PINCODE_LOCALITY": "RAHRAGORA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 831017,
      "TXT_PINCODE_LOCALITY": "BARIDIH COLONY",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 831018,
      "TXT_PINCODE_LOCALITY": "CENTRAL PROJECTS ENGINEERING DIVISION",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 441054,
      "City District Name": "EAST SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 831019,
      "TXT_PINCODE_LOCALITY": "BIRSA NAGAR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442748,
      "City District Name": "GOLMURI-CUM-JUGSALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 831020,
      "TXT_PINCODE_LOCALITY": "KARNIDIH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 2690,
      "City District Name": "CHANDIL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 831021,
      "TXT_PINCODE_LOCALITY": "EAST SINGHBHUM",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 441054,
      "City District Name": "EAST SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 831035,
      "TXT_PINCODE_LOCALITY": "NAMDA BASTI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442748,
      "City District Name": "GOLMURI-CUM-JUGSALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 831082,
      "TXT_PINCODE_LOCALITY": "HESAL",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442747,
      "City District Name": "GOBINDPUR(RAJNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 831202,
      "TXT_PINCODE_LOCALITY": "KHURSI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442783,
      "City District Name": "POTKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 831231,
      "TXT_PINCODE_LOCALITY": "SURAT",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 832071,
      "TXT_PINCODE_LOCALITY": "HESALBIL",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442783,
      "City District Name": "POTKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 832101,
      "TXT_PINCODE_LOCALITY": "BAHARAGORA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 832102,
      "TXT_PINCODE_LOCALITY": "JADUGUDA MINES",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 832103,
      "TXT_PINCODE_LOCALITY": "MAUBHANDAR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 832104,
      "TXT_PINCODE_LOCALITY": "MOSABANI MINES",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 832105,
      "TXT_PINCODE_LOCALITY": "PATAMDA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 832106,
      "TXT_PINCODE_LOCALITY": "RAKHA COPPER PROJECT",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 832107,
      "TXT_PINCODE_LOCALITY": "SUNDERNAGAR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 832108,
      "TXT_PINCODE_LOCALITY": "INDUSTRIAL AREA RAMARIA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 5587,
      "City District Name": "SERAIKELA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 832109,
      "TXT_PINCODE_LOCALITY": "ADITYAPUR INDUSTRIAL AREA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 832110,
      "TXT_PINCODE_LOCALITY": "AZADNAGAR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 441054,
      "City District Name": "EAST SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 832111,
      "TXT_PINCODE_LOCALITY": "DUMURIA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 832112,
      "TXT_PINCODE_LOCALITY": "CHHOTA GOBINDPUR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 832113,
      "TXT_PINCODE_LOCALITY": "RAJNAGAR(SIGNHBHUM)",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 832114,
      "TXT_PINCODE_LOCALITY": "POTKA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 832119,
      "TXT_PINCODE_LOCALITY": "SENISIDMA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1639,
      "City District Name": "SARAIKELA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 832201,
      "TXT_PINCODE_LOCALITY": "ASURA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 832203,
      "TXT_PINCODE_LOCALITY": "ASNA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 832213,
      "TXT_PINCODE_LOCALITY": "MASURIBURU",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442797,
      "City District Name": "TONTO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 832244,
      "TXT_PINCODE_LOCALITY": "KUDA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442764,
      "City District Name": "KUKRU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 832301,
      "TXT_PINCODE_LOCALITY": "CHAKULIA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 832302,
      "TXT_PINCODE_LOCALITY": "DHALBHUMGARH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 832303,
      "TXT_PINCODE_LOCALITY": "GHATSHILA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 832304,
      "TXT_PINCODE_LOCALITY": "MAHULIA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 832307,
      "TXT_PINCODE_LOCALITY": "RUGRISHOL",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1452,
      "City District Name": "CHAKULIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 832401,
      "TXT_PINCODE_LOCALITY": "CHANDIL",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 832402,
      "TXT_PINCODE_LOCALITY": "KANDRA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 832403,
      "TXT_PINCODE_LOCALITY": "NIMDIH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 441314,
      "City District Name": "SERAIKELA KHARSAWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 832404,
      "TXT_PINCODE_LOCALITY": "LEPATAND",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 441314,
      "City District Name": "SERAIKELA KHARSAWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 832405,
      "TXT_PINCODE_LOCALITY": "DANDUDIH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442779,
      "City District Name": "PATAMDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 832407,
      "TXT_PINCODE_LOCALITY": "CHOTA KUNABERA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442747,
      "City District Name": "GOBINDPUR(RAJNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 833010,
      "TXT_PINCODE_LOCALITY": "PADAMPUR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1777,
      "City District Name": "KHARSAWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 833101,
      "TXT_PINCODE_LOCALITY": "AMDA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 833102,
      "TXT_PINCODE_LOCALITY": "CHAKRADHARPUR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 833103,
      "TXT_PINCODE_LOCALITY": "GOILKERA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 833104,
      "TXT_PINCODE_LOCALITY": "MANOHARPUR(CHAIBASA)",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 833105,
      "TXT_PINCODE_LOCALITY": "SONUA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 833106,
      "TXT_PINCODE_LOCALITY": "CHIRIA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 833109,
      "TXT_PINCODE_LOCALITY": "PETAR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442726,
      "City District Name": "ANANDPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 833114,
      "TXT_PINCODE_LOCALITY": "HAJIPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6395,
      "City District Name": "HAJIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 833117,
      "TXT_PINCODE_LOCALITY": "BHADAI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 833124,
      "TXT_PINCODE_LOCALITY": "RUNGI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442726,
      "City District Name": "ANANDPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 833125,
      "TXT_PINCODE_LOCALITY": "KASIA ALIAS SURJABASA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3592,
      "City District Name": "JHINKPANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 833162,
      "TXT_PINCODE_LOCALITY": "CHAKRADHARPUR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 833192,
      "TXT_PINCODE_LOCALITY": "KONTARIA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 4840,
      "City District Name": "NOAMUNDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 833201,
      "TXT_PINCODE_LOCALITY": "CHAIBAZA H O",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 833202,
      "TXT_PINCODE_LOCALITY": "TATA COLLEGE CHAIBAS",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 833203,
      "TXT_PINCODE_LOCALITY": "JAGANNATHPUR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 833204,
      "TXT_PINCODE_LOCALITY": "NARSANDA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1013,
      "City District Name": "CHAIBASA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 833208,
      "TXT_PINCODE_LOCALITY": "GHAGRA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 833210,
      "TXT_PINCODE_LOCALITY": "CHAMARU",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442725,
      "City District Name": "ADITYAPUR(GAMHARIA)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 833212,
      "TXT_PINCODE_LOCALITY": "DANGUAPOSI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 833213,
      "TXT_PINCODE_LOCALITY": "GUA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 833214,
      "TXT_PINCODE_LOCALITY": "HATGAMHARIA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 833215,
      "TXT_PINCODE_LOCALITY": "JHINKPANI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 833216,
      "TXT_PINCODE_LOCALITY": "KHARSAWAGARH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 833217,
      "TXT_PINCODE_LOCALITY": "NOAMUNDI S.O",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 441053,
      "City District Name": "WEST SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 833218,
      "TXT_PINCODE_LOCALITY": "NOAMUNDI BAZAR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 833219,
      "TXT_PINCODE_LOCALITY": "SARAIKELA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 833220,
      "TXT_PINCODE_LOCALITY": "BALIGUMA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 441314,
      "City District Name": "SERAIKELA KHARSAWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 833221,
      "TXT_PINCODE_LOCALITY": "BARAJAMDA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 441053,
      "City District Name": "WEST SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 833222,
      "TXT_PINCODE_LOCALITY": "KIRUBURU BASE CAMP",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 833223,
      "TXT_PINCODE_LOCALITY": "MEGHAHATUBURU",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 833224,
      "TXT_PINCODE_LOCALITY": "BHARBHARIA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 833233,
      "TXT_PINCODE_LOCALITY": "MEGHAHATUBURU",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 833240,
      "TXT_PINCODE_LOCALITY": "JAMBOHAL",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442725,
      "City District Name": "ADITYAPUR(GAMHARIA)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 833320,
      "TXT_PINCODE_LOCALITY": "DAKUWA JANGAL",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442754,
      "City District Name": "JAGANNATHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 833326,
      "TXT_PINCODE_LOCALITY": "TAPIN",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 833812,
      "TXT_PINCODE_LOCALITY": "RUTAGHUTU",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442797,
      "City District Name": "TONTO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 834001,
      "TXT_PINCODE_LOCALITY": "COURT ROAD",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 834002,
      "TXT_PINCODE_LOCALITY": "RANCHI AERODROME",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 834003,
      "TXT_PINCODE_LOCALITY": "HATIA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 834004,
      "TXT_PINCODE_LOCALITY": "DHURWA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 834005,
      "TXT_PINCODE_LOCALITY": "HEHAL",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 834006,
      "TXT_PINCODE_LOCALITY": "RANCHI AGRI COLLEGE",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 834007,
      "TXT_PINCODE_LOCALITY": "RANCHI VETERNARY COLLEGE",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 834008,
      "TXT_PINCODE_LOCALITY": "RANCHI MEDICAL COLLEGE C",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 834009,
      "TXT_PINCODE_LOCALITY": "RANCHI MEDICAL COLLEGE",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 834010,
      "TXT_PINCODE_LOCALITY": "NAMKUM",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 834011,
      "TXT_PINCODE_LOCALITY": "RAILWAY COLONY HATIA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 834012,
      "TXT_PINCODE_LOCALITY": "HARMU HOUSING COLONY",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 834013,
      "TXT_PINCODE_LOCALITY": "RANCHI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 834014,
      "TXT_PINCODE_LOCALITY": "SEMBO",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 834033,
      "TXT_PINCODE_LOCALITY": "RANCHI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 834601,
      "TXT_PINCODE_LOCALITY": "RANCHI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835024,
      "TXT_PINCODE_LOCALITY": "JURUNGA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442791,
      "City District Name": "SONAHATU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835101,
      "TXT_PINCODE_LOCALITY": "CHOTAMURI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835102,
      "TXT_PINCODE_LOCALITY": "SILLI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835103,
      "TXT_PINCODE_LOCALITY": "TATISILWAI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835104,
      "TXT_PINCODE_LOCALITY": "BERMO",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835107,
      "TXT_PINCODE_LOCALITY": "SOSOMULI.",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442747,
      "City District Name": "GOBINDPUR(RAJNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835109,
      "TXT_PINCODE_LOCALITY": "RAJADERA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442727,
      "City District Name": "ANGARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835125,
      "TXT_PINCODE_LOCALITY": "MARAI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 738,
      "City District Name": "HAZARIBAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835201,
      "TXT_PINCODE_LOCALITY": "ORGA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 5673,
      "City District Name": "SIMDEGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835202,
      "TXT_PINCODE_LOCALITY": "BERO",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835203,
      "TXT_PINCODE_LOCALITY": "BHARNO",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3324,
      "City District Name": "GUMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835204,
      "TXT_PINCODE_LOCALITY": "BUNDU",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835205,
      "TXT_PINCODE_LOCALITY": "BRAMBE",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835206,
      "TXT_PINCODE_LOCALITY": "CHAINPUR (RANCHI)",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835207,
      "TXT_PINCODE_LOCALITY": "GUMLA H O",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3324,
      "City District Name": "GUMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835208,
      "TXT_PINCODE_LOCALITY": "GHAGRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3216,
      "City District Name": "GAYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835209,
      "TXT_PINCODE_LOCALITY": "KARRA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835210,
      "TXT_PINCODE_LOCALITY": "KHUNTI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835211,
      "TXT_PINCODE_LOCALITY": "KOLEBIRA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3324,
      "City District Name": "GUMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835212,
      "TXT_PINCODE_LOCALITY": "KURDEG",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3324,
      "City District Name": "GUMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835213,
      "TXT_PINCODE_LOCALITY": "KURU",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 4256,
      "City District Name": "LOHARDAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835214,
      "TXT_PINCODE_LOCALITY": "MANDAR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835215,
      "TXT_PINCODE_LOCALITY": "MESRA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835216,
      "TXT_PINCODE_LOCALITY": "MURHU",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835217,
      "TXT_PINCODE_LOCALITY": "VIDYALAY",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835218,
      "TXT_PINCODE_LOCALITY": "NETARHAT",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 424452,
      "City District Name": "PALAMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835219,
      "TXT_PINCODE_LOCALITY": "ORMANJHI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835220,
      "TXT_PINCODE_LOCALITY": "PALKOT",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3324,
      "City District Name": "GUMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835221,
      "TXT_PINCODE_LOCALITY": "R K MISSION",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835222,
      "TXT_PINCODE_LOCALITY": "RATU",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835223,
      "TXT_PINCODE_LOCALITY": "SIMDEGA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 5673,
      "City District Name": "SIMDEGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835224,
      "TXT_PINCODE_LOCALITY": "SISAI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3324,
      "City District Name": "GUMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835225,
      "TXT_PINCODE_LOCALITY": "TAMAR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835226,
      "TXT_PINCODE_LOCALITY": "THETHAITANAGAR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3324,
      "City District Name": "GUMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835227,
      "TXT_PINCODE_LOCALITY": "TORPA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835228,
      "TXT_PINCODE_LOCALITY": "BIRU",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3324,
      "City District Name": "GUMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835229,
      "TXT_PINCODE_LOCALITY": "BASIA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835230,
      "TXT_PINCODE_LOCALITY": "NOADIH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835231,
      "TXT_PINCODE_LOCALITY": "BISHUNPUR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3324,
      "City District Name": "GUMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835232,
      "TXT_PINCODE_LOCALITY": "NAWAGARH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3324,
      "City District Name": "GUMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835233,
      "TXT_PINCODE_LOCALITY": "TOTO",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3324,
      "City District Name": "GUMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835234,
      "TXT_PINCODE_LOCALITY": "GOBINDPUR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835235,
      "TXT_PINCODE_LOCALITY": "GOTRA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3324,
      "City District Name": "GUMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835236,
      "TXT_PINCODE_LOCALITY": "SIKIDIRI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835237,
      "TXT_PINCODE_LOCALITY": "PHORI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3324,
      "City District Name": "GUMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835238,
      "TXT_PINCODE_LOCALITY": "IRAB",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835239,
      "TXT_PINCODE_LOCALITY": "CHANBO",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835240,
      "TXT_PINCODE_LOCALITY": "BOREYA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835241,
      "TXT_PINCODE_LOCALITY": "BURMU",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835242,
      "TXT_PINCODE_LOCALITY": "NAGAR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3324,
      "City District Name": "GUMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835243,
      "TXT_PINCODE_LOCALITY": "SONAHATU",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835244,
      "TXT_PINCODE_LOCALITY": "LAPUNJ",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1453,
      "City District Name": "HAZARIBAGH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835251,
      "TXT_PINCODE_LOCALITY": "GUTUAJOKARI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442746,
      "City District Name": "GHAGHRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835264,
      "TXT_PINCODE_LOCALITY": "UNEKEL",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442765,
      "City District Name": "LAPUNG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835297,
      "TXT_PINCODE_LOCALITY": "TENGRIYA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 424265,
      "City District Name": "BERO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835299,
      "TXT_PINCODE_LOCALITY": "BASIA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835301,
      "TXT_PINCODE_LOCALITY": "ITKI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835302,
      "TXT_PINCODE_LOCALITY": "LOHARDAGA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 4256,
      "City District Name": "LOHARDAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835303,
      "TXT_PINCODE_LOCALITY": "PISKANGRI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835304,
      "TXT_PINCODE_LOCALITY": "SENHA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 4256,
      "City District Name": "LOHARDAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835305,
      "TXT_PINCODE_LOCALITY": "KISKO",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 4256,
      "City District Name": "LOHARDAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835306,
      "TXT_PINCODE_LOCALITY": "BHANDRA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 4256,
      "City District Name": "LOHARDAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835307,
      "TXT_PINCODE_LOCALITY": "NIMTOLI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3324,
      "City District Name": "GUMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835324,
      "TXT_PINCODE_LOCALITY": "CHHARDA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3324,
      "City District Name": "GUMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835325,
      "TXT_PINCODE_LOCALITY": "NARAULI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835327,
      "TXT_PINCODE_LOCALITY": "GOPILA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442786,
      "City District Name": "RANIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835331,
      "TXT_PINCODE_LOCALITY": "MANJIRA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442733,
      "City District Name": "BISHUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835351,
      "TXT_PINCODE_LOCALITY": "BETHAT",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442733,
      "City District Name": "BISHUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 835523,
      "TXT_PINCODE_LOCALITY": "SIMDEGA S. O",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 5673,
      "City District Name": "SIMDEGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 837530,
      "TXT_PINCODE_LOCALITY": "KHELARISAI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1777,
      "City District Name": "KHARSAWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 838225,
      "TXT_PINCODE_LOCALITY": "BARSIRA.",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 2690,
      "City District Name": "CHANDIL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 839221,
      "TXT_PINCODE_LOCALITY": "TARO",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3987,
      "City District Name": "KHUNTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 840112,
      "TXT_PINCODE_LOCALITY": "MANSINGPUR RAJAULI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6395,
      "City District Name": "HAJIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 840203,
      "TXT_PINCODE_LOCALITY": "KHONTA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442168,
      "City District Name": "ALAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 840204,
      "TXT_PINCODE_LOCALITY": "MURWARA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442489,
      "City District Name": "BIRAUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 840314,
      "TXT_PINCODE_LOCALITY": "SANAHTI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442490,
      "City District Name": "BOCHAHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841039,
      "TXT_PINCODE_LOCALITY": "TEKANIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442530,
      "City District Name": "GUTHANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841101,
      "TXT_PINCODE_LOCALITY": "SONEPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841105,
      "TXT_PINCODE_LOCALITY": "TARWA MANGARPAL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841106,
      "TXT_PINCODE_LOCALITY": "JAIPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841107,
      "TXT_PINCODE_LOCALITY": "SALEHPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5744,
      "City District Name": "SONEPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841113,
      "TXT_PINCODE_LOCALITY": "AURHA MILKI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442520,
      "City District Name": "GARKHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841121,
      "TXT_PINCODE_LOCALITY": "ANWAL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841123,
      "TXT_PINCODE_LOCALITY": "PHALPURA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442533,
      "City District Name": "HASANPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841201,
      "TXT_PINCODE_LOCALITY": "BAREJA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841202,
      "TXT_PINCODE_LOCALITY": "BASANT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841203,
      "TXT_PINCODE_LOCALITY": "CHAINPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1866,
      "City District Name": "SIWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841204,
      "TXT_PINCODE_LOCALITY": "CHAINWA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841205,
      "TXT_PINCODE_LOCALITY": "DAUDPUR (CHAPRA)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1866,
      "City District Name": "SIWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841206,
      "TXT_PINCODE_LOCALITY": "DAYALPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841207,
      "TXT_PINCODE_LOCALITY": "DIGHWARA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841208,
      "TXT_PINCODE_LOCALITY": "EKMA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841209,
      "TXT_PINCODE_LOCALITY": "FULWARIA TAJPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841210,
      "TXT_PINCODE_LOCALITY": "GANGPUR SISWAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1866,
      "City District Name": "SIWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841211,
      "TXT_PINCODE_LOCALITY": "GULTENGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841212,
      "TXT_PINCODE_LOCALITY": "JOGIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841213,
      "TXT_PINCODE_LOCALITY": "KOPA SAMHOTA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841214,
      "TXT_PINCODE_LOCALITY": "KOPA BAZAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841215,
      "TXT_PINCODE_LOCALITY": "MAKER",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841216,
      "TXT_PINCODE_LOCALITY": "NARAON",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841217,
      "TXT_PINCODE_LOCALITY": "NAYAGAON",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841218,
      "TXT_PINCODE_LOCALITY": "PAIGA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841219,
      "TXT_PINCODE_LOCALITY": "PARSA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841220,
      "TXT_PINCODE_LOCALITY": "PARSAGARH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841221,
      "TXT_PINCODE_LOCALITY": "SITALPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841222,
      "TXT_PINCODE_LOCALITY": "SUTIHAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841223,
      "TXT_PINCODE_LOCALITY": "MOHAMMADPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841224,
      "TXT_PINCODE_LOCALITY": "PANDITPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1900,
      "City District Name": "GOPALGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841225,
      "TXT_PINCODE_LOCALITY": "PRATAPPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841226,
      "TXT_PINCODE_LOCALITY": "ST RD SIWAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841227,
      "TXT_PINCODE_LOCALITY": "VILL HASANPURWA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1866,
      "City District Name": "SIWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841229,
      "TXT_PINCODE_LOCALITY": "SISWA BUZURG",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4341,
      "City District Name": "MAIRWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841231,
      "TXT_PINCODE_LOCALITY": "ANDAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1866,
      "City District Name": "SIWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841232,
      "TXT_PINCODE_LOCALITY": "BARHARIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1866,
      "City District Name": "SIWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841233,
      "TXT_PINCODE_LOCALITY": "DARONDA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1866,
      "City District Name": "SIWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841234,
      "TXT_PINCODE_LOCALITY": "DAROULI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841235,
      "TXT_PINCODE_LOCALITY": "DONE",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1866,
      "City District Name": "SIWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841236,
      "TXT_PINCODE_LOCALITY": "HASANPURA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1866,
      "City District Name": "SIWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841237,
      "TXT_PINCODE_LOCALITY": "HUSSAINGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1866,
      "City District Name": "SIWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841238,
      "TXT_PINCODE_LOCALITY": "MAHARAJGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1866,
      "City District Name": "SIWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841239,
      "TXT_PINCODE_LOCALITY": "MAIRWA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1866,
      "City District Name": "SIWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841240,
      "TXT_PINCODE_LOCALITY": "MAHUAL MAHAL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1866,
      "City District Name": "SIWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841241,
      "TXT_PINCODE_LOCALITY": "PACHRUKHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1866,
      "City District Name": "SIWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841242,
      "TXT_PINCODE_LOCALITY": "PATARHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1866,
      "City District Name": "SIWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841243,
      "TXT_PINCODE_LOCALITY": "SAHPUR(SIWAN)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1866,
      "City District Name": "SIWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841244,
      "TXT_PINCODE_LOCALITY": "TAKIPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1866,
      "City District Name": "SIWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841245,
      "TXT_PINCODE_LOCALITY": "ZERADEI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1866,
      "City District Name": "SIWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841246,
      "TXT_PINCODE_LOCALITY": "BAKERGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1866,
      "City District Name": "SIWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841247,
      "TXT_PINCODE_LOCALITY": "DARIHARA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841248,
      "TXT_PINCODE_LOCALITY": "HARAJI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841249,
      "TXT_PINCODE_LOCALITY": "BASTI JALAL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841250,
      "TXT_PINCODE_LOCALITY": "KOTHEYA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1900,
      "City District Name": "GOPALGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841251,
      "TXT_PINCODE_LOCALITY": "AMI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841252,
      "TXT_PINCODE_LOCALITY": "DUMARI BUZRANG",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841253,
      "TXT_PINCODE_LOCALITY": "ENAITPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841254,
      "TXT_PINCODE_LOCALITY": "JAINTPUR BHARWALIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841255,
      "TXT_PINCODE_LOCALITY": "JALALPUR BISHUNPURA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841256,
      "TXT_PINCODE_LOCALITY": "MANEY",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841265,
      "TXT_PINCODE_LOCALITY": "BARWA BUZURG",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442571,
      "City District Name": "MANJHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841285,
      "TXT_PINCODE_LOCALITY": "SOBHAN CHAK",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442502,
      "City District Name": "DARAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841286,
      "TXT_PINCODE_LOCALITY": "ALLAPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1900,
      "City District Name": "GOPALGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841287,
      "TXT_PINCODE_LOCALITY": "ASAON",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1866,
      "City District Name": "SIWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841301,
      "TXT_PINCODE_LOCALITY": "BHAGWANBAZAR DISTT BOARD",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841302,
      "TXT_PINCODE_LOCALITY": "MALASHERPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1871,
      "City District Name": "CHAPRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841305,
      "TXT_PINCODE_LOCALITY": "REPURA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5375,
      "City District Name": "REVELGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841311,
      "TXT_PINCODE_LOCALITY": "GARKHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841312,
      "TXT_PINCODE_LOCALITY": "GARIBTOLA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841313,
      "TXT_PINCODE_LOCALITY": "MANJHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841314,
      "TXT_PINCODE_LOCALITY": "ENAI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841315,
      "TXT_PINCODE_LOCALITY": "REVELGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841316,
      "TXT_PINCODE_LOCALITY": "NAINI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841317,
      "TXT_PINCODE_LOCALITY": "FAKULI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841318,
      "TXT_PINCODE_LOCALITY": "MOHAMMADPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841328,
      "TXT_PINCODE_LOCALITY": "HEMANPUR URF HAMIDPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442580,
      "City District Name": "MINAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841331,
      "TXT_PINCODE_LOCALITY": "HAIDRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442645,
      "City District Name": "SUPPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841333,
      "TXT_PINCODE_LOCALITY": "MASHA ALAM.",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2251,
      "City District Name": "BAIRGANIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841339,
      "TXT_PINCODE_LOCALITY": "SANRAHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841342,
      "TXT_PINCODE_LOCALITY": "KHURAMPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4447,
      "City District Name": "MARHAURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841343,
      "TXT_PINCODE_LOCALITY": "DAMODARPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4447,
      "City District Name": "MARHAURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841401,
      "TXT_PINCODE_LOCALITY": "AMNOUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841402,
      "TXT_PINCODE_LOCALITY": "APHAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841403,
      "TXT_PINCODE_LOCALITY": "BANIAPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841404,
      "TXT_PINCODE_LOCALITY": "BAGAURA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1866,
      "City District Name": "SIWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841405,
      "TXT_PINCODE_LOCALITY": "BASANTPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1866,
      "City District Name": "SIWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841406,
      "TXT_PINCODE_LOCALITY": "BASANTPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1866,
      "City District Name": "SIWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841407,
      "TXT_PINCODE_LOCALITY": "BISHUNPURA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1900,
      "City District Name": "GOPALGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841408,
      "TXT_PINCODE_LOCALITY": "BHAGWANPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1871,
      "City District Name": "CHAPRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841409,
      "TXT_PINCODE_LOCALITY": "DIGHWA DUBAULI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1900,
      "City District Name": "GOPALGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841410,
      "TXT_PINCODE_LOCALITY": "DUMARSAN BANGRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1900,
      "City District Name": "GOPALGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841411,
      "TXT_PINCODE_LOCALITY": "ICHWAPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841412,
      "TXT_PINCODE_LOCALITY": "JALALPUR BAZAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1900,
      "City District Name": "GOPALGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841413,
      "TXT_PINCODE_LOCALITY": "JAMO BAZAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1866,
      "City District Name": "SIWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841414,
      "TXT_PINCODE_LOCALITY": "KHAIRA (CHAPRA)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841415,
      "TXT_PINCODE_LOCALITY": "KHODABAGH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841416,
      "TXT_PINCODE_LOCALITY": "KISHANPURA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1866,
      "City District Name": "SIWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841417,
      "TXT_PINCODE_LOCALITY": "MASHRAKH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841418,
      "TXT_PINCODE_LOCALITY": "MARHOWRAH H O",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841419,
      "TXT_PINCODE_LOCALITY": "MIRJAPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841420,
      "TXT_PINCODE_LOCALITY": "RAJAPATTI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1900,
      "City District Name": "GOPALGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841421,
      "TXT_PINCODE_LOCALITY": "SARHARWA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841422,
      "TXT_PINCODE_LOCALITY": "SAHAJITPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841423,
      "TXT_PINCODE_LOCALITY": "SIDHWALIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1900,
      "City District Name": "GOPALGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841424,
      "TXT_PINCODE_LOCALITY": "TARAIYA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841425,
      "TXT_PINCODE_LOCALITY": "BATHUA BAZAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1900,
      "City District Name": "GOPALGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841426,
      "TXT_PINCODE_LOCALITY": "BHORE",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1900,
      "City District Name": "GOPALGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841427,
      "TXT_PINCODE_LOCALITY": "GARH MANJHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1900,
      "City District Name": "GOPALGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841428,
      "TXT_PINCODE_LOCALITY": "GOPALGANJ H O",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1900,
      "City District Name": "GOPALGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841429,
      "TXT_PINCODE_LOCALITY": "CHAPPIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1900,
      "City District Name": "GOPALGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841430,
      "TXT_PINCODE_LOCALITY": "GAWANDRI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841431,
      "TXT_PINCODE_LOCALITY": "PACHRAUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841432,
      "TXT_PINCODE_LOCALITY": "JHAKHRA (CHAPRA)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841433,
      "TXT_PINCODE_LOCALITY": "RASAULI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841434,
      "TXT_PINCODE_LOCALITY": "GORIAKOTHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1866,
      "City District Name": "SIWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841435,
      "TXT_PINCODE_LOCALITY": "GUTHNI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1866,
      "City District Name": "SIWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841436,
      "TXT_PINCODE_LOCALITY": "HATHWA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1900,
      "City District Name": "GOPALGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841437,
      "TXT_PINCODE_LOCALITY": "KATEYA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1900,
      "City District Name": "GOPALGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841438,
      "TXT_PINCODE_LOCALITY": "MIRGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1900,
      "City District Name": "GOPALGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841439,
      "TXT_PINCODE_LOCALITY": "MUSTAFABAD",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1866,
      "City District Name": "SIWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841440,
      "TXT_PINCODE_LOCALITY": "THAWE",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1900,
      "City District Name": "GOPALGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841441,
      "TXT_PINCODE_LOCALITY": "POIA RUP",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442600,
      "City District Name": "PACH DEURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841442,
      "TXT_PINCODE_LOCALITY": "NAGRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841443,
      "TXT_PINCODE_LOCALITY": "GAURA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841444,
      "TXT_PINCODE_LOCALITY": "GOIYA NAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442482,
      "City District Name": "BHAGWANPUR HAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841446,
      "TXT_PINCODE_LOCALITY": "SARAN.",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841447,
      "TXT_PINCODE_LOCALITY": "LAKRINABIGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1866,
      "City District Name": "SIWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841449,
      "TXT_PINCODE_LOCALITY": "MACHHAGRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442466,
      "City District Name": "BANIAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841460,
      "TXT_PINCODE_LOCALITY": "KETUKA LACHCHHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442568,
      "City District Name": "MAKER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841481,
      "TXT_PINCODE_LOCALITY": "KHAS PATTI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442454,
      "City District Name": "AMNOUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841501,
      "TXT_PINCODE_LOCALITY": "KUCHAIKOT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1900,
      "City District Name": "GOPALGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841502,
      "TXT_PINCODE_LOCALITY": "KHUJWA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1866,
      "City District Name": "SIWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841503,
      "TXT_PINCODE_LOCALITY": "NECHUA JALALPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1900,
      "City District Name": "GOPALGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841504,
      "TXT_PINCODE_LOCALITY": "RAGHUNATHPUR (CHAPRA)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1866,
      "City District Name": "SIWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841505,
      "TXT_PINCODE_LOCALITY": "SASAMUSA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1900,
      "City District Name": "GOPALGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841506,
      "TXT_PINCODE_LOCALITY": "TARWARA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1866,
      "City District Name": "SIWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841507,
      "TXT_PINCODE_LOCALITY": "MORA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1866,
      "City District Name": "SIWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841508,
      "TXT_PINCODE_LOCALITY": "BIJAIPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1866,
      "City District Name": "SIWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841509,
      "TXT_PINCODE_LOCALITY": "GABHIRAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1866,
      "City District Name": "SIWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841545,
      "TXT_PINCODE_LOCALITY": "MANIAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442177,
      "City District Name": "ZIRADEI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841553,
      "TXT_PINCODE_LOCALITY": "SIWAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1866,
      "City District Name": "SIWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 841823,
      "TXT_PINCODE_LOCALITY": "KHEMAIPATTI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442580,
      "City District Name": "MINAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 842001,
      "TXT_PINCODE_LOCALITY": "SIKANARPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 842002,
      "TXT_PINCODE_LOCALITY": "R D S COLLEGE",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 842003,
      "TXT_PINCODE_LOCALITY": "MUZAFFARPUR INST OF TECH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 842004,
      "TXT_PINCODE_LOCALITY": "UMANAGAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 842005,
      "TXT_PINCODE_LOCALITY": "MUZAFFARPUR INDS COMPLEX",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 842006,
      "TXT_PINCODE_LOCALITY": "MUZAFFARPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 842113,
      "TXT_PINCODE_LOCALITY": "BANDHAR SHIUPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442638,
      "City District Name": "SHIVAJI NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 842120,
      "TXT_PINCODE_LOCALITY": "BHAGWANPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 842129,
      "TXT_PINCODE_LOCALITY": "HINGUA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442504,
      "City District Name": "DARIAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 842324,
      "TXT_PINCODE_LOCALITY": "LAUHARIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442607,
      "City District Name": "PARIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 842329,
      "TXT_PINCODE_LOCALITY": "WAKE RAKBE PACHRA BANSI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442650,
      "City District Name": "TARIANI CHOWK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 842421,
      "TXT_PINCODE_LOCALITY": "PAKRI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442576,
      "City District Name": "MASHRAKH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 842423,
      "TXT_PINCODE_LOCALITY": "KHIZIRPURA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442551,
      "City District Name": "KESARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843001,
      "TXT_PINCODE_LOCALITY": "RANCHI S.O",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843010,
      "TXT_PINCODE_LOCALITY": "MANDAR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843019,
      "TXT_PINCODE_LOCALITY": "JAIN KHURD",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442575,
      "City District Name": "MARWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843032,
      "TXT_PINCODE_LOCALITY": "JAGDISHPURI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442585,
      "City District Name": "MUSAHRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843100,
      "TXT_PINCODE_LOCALITY": "CHAPRA DHARAMPUR JADU",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843101,
      "TXT_PINCODE_LOCALITY": "BAKHRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843102,
      "TXT_PINCODE_LOCALITY": "BARIARPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843103,
      "TXT_PINCODE_LOCALITY": "BOCHAHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843104,
      "TXT_PINCODE_LOCALITY": "CHANDANPATTI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843105,
      "TXT_PINCODE_LOCALITY": "DHULI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843106,
      "TXT_PINCODE_LOCALITY": "GIDHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843107,
      "TXT_PINCODE_LOCALITY": "GAYASPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843108,
      "TXT_PINCODE_LOCALITY": "KALWARI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843109,
      "TXT_PINCODE_LOCALITY": "KANTI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843110,
      "TXT_PINCODE_LOCALITY": "KUMAR BAJITPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843111,
      "TXT_PINCODE_LOCALITY": "MOTIPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843112,
      "TXT_PINCODE_LOCALITY": "PARO",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843113,
      "TXT_PINCODE_LOCALITY": "PATAHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843114,
      "TXT_PINCODE_LOCALITY": "PATEPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843115,
      "TXT_PINCODE_LOCALITY": "PIAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843116,
      "TXT_PINCODE_LOCALITY": "R K ASHRAM",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843117,
      "TXT_PINCODE_LOCALITY": "RAMPURHARI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843118,
      "TXT_PINCODE_LOCALITY": "SHERFUDDINPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843119,
      "TXT_PINCODE_LOCALITY": "SILAUT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843120,
      "TXT_PINCODE_LOCALITY": "DEORIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843121,
      "TXT_PINCODE_LOCALITY": "DHULI AGRICULTURAL COLLEGE",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843122,
      "TXT_PINCODE_LOCALITY": "HARDI (MUZAFFARPUR)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843123,
      "TXT_PINCODE_LOCALITY": "JAINTPUR EST",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843124,
      "TXT_PINCODE_LOCALITY": "KATHAIYA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843125,
      "TXT_PINCODE_LOCALITY": "KURNAWL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843126,
      "TXT_PINCODE_LOCALITY": "SARAIYA FACTORY",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843127,
      "TXT_PINCODE_LOCALITY": "BIRHIMA BAZAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843128,
      "TXT_PINCODE_LOCALITY": "MINAPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843129,
      "TXT_PINCODE_LOCALITY": "NARMA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843130,
      "TXT_PINCODE_LOCALITY": "KANTI THERMAL POWER",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843131,
      "TXT_PINCODE_LOCALITY": "AHIYAPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843132,
      "TXT_PINCODE_LOCALITY": "BARURAJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843133,
      "TXT_PINCODE_LOCALITY": "BHARWARI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843134,
      "TXT_PINCODE_LOCALITY": "CHHAJAM",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843135,
      "TXT_PINCODE_LOCALITY": "CHAPRA MEGH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843136,
      "TXT_PINCODE_LOCALITY": "DUBAHA BUZRUG",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843137,
      "TXT_PINCODE_LOCALITY": "DHARFARI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843138,
      "TXT_PINCODE_LOCALITY": "GHOSAUT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843139,
      "TXT_PINCODE_LOCALITY": "BAGHNAGRI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843140,
      "TXT_PINCODE_LOCALITY": "JAMALBAD",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843141,
      "TXT_PINCODE_LOCALITY": "JASAULI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843142,
      "TXT_PINCODE_LOCALITY": "JHAPAHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843143,
      "TXT_PINCODE_LOCALITY": "KARJA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843144,
      "TXT_PINCODE_LOCALITY": "KATESAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843145,
      "TXT_PINCODE_LOCALITY": "KERMA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843146,
      "TXT_PINCODE_LOCALITY": "KHABRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843147,
      "TXT_PINCODE_LOCALITY": "MAHANTH MANIARI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843148,
      "TXT_PINCODE_LOCALITY": "MANIKA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843149,
      "TXT_PINCODE_LOCALITY": "MIRAPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843150,
      "TXT_PINCODE_LOCALITY": "MOHAMMADPUR BADAL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843151,
      "TXT_PINCODE_LOCALITY": "MOHAMMADPUR BALMI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843152,
      "TXT_PINCODE_LOCALITY": "MOMAIN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843153,
      "TXT_PINCODE_LOCALITY": "MORSANDI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843154,
      "TXT_PINCODE_LOCALITY": "MUSHAHARI FARM",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843155,
      "TXT_PINCODE_LOCALITY": "MUSTAFAGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843156,
      "TXT_PINCODE_LOCALITY": "NARAIRPUR PANAPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843157,
      "TXT_PINCODE_LOCALITY": "PATSARA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843158,
      "TXT_PINCODE_LOCALITY": "PRAHLADPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843159,
      "TXT_PINCODE_LOCALITY": "RAMPUR BISHWANTH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843160,
      "TXT_PINCODE_LOCALITY": "SANDHA JAHANGIRPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843161,
      "TXT_PINCODE_LOCALITY": "SINGAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843162,
      "TXT_PINCODE_LOCALITY": "SINGHAILA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843163,
      "TXT_PINCODE_LOCALITY": "SONKARSA BARKAGAON",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843164,
      "TXT_PINCODE_LOCALITY": "TEPRI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843165,
      "TXT_PINCODE_LOCALITY": "TURKI KHARARU",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843209,
      "TXT_PINCODE_LOCALITY": "SHIWPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442489,
      "City District Name": "BIRAUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843210,
      "TXT_PINCODE_LOCALITY": "DEORIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843221,
      "TXT_PINCODE_LOCALITY": "NAWADA URF KHANGURA KHURD",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442548,
      "City District Name": "KATRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843228,
      "TXT_PINCODE_LOCALITY": "SARAHNA MADHOPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442580,
      "City District Name": "MINAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843233,
      "TXT_PINCODE_LOCALITY": "CHHAURAHIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442475,
      "City District Name": "BATHNAHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843301,
      "TXT_PINCODE_LOCALITY": "KOTA BAZAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843302,
      "TXT_PINCODE_LOCALITY": "SITAMARHI COURT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843303,
      "TXT_PINCODE_LOCALITY": "MOHANPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3072,
      "City District Name": "DUMRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843309,
      "TXT_PINCODE_LOCALITY": "ROHUA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442514,
      "City District Name": "DUMRI KATSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843310,
      "TXT_PINCODE_LOCALITY": "NEMHUA URF BISHUNPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442615,
      "City District Name": "PUPRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843311,
      "TXT_PINCODE_LOCALITY": "ATHARI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843312,
      "TXT_PINCODE_LOCALITY": "AURAI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843313,
      "TXT_PINCODE_LOCALITY": "BAIRAGANA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843314,
      "TXT_PINCODE_LOCALITY": "BAJPATTI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843315,
      "TXT_PINCODE_LOCALITY": "BARHARWA (MUZAFFARPUR)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843316,
      "TXT_PINCODE_LOCALITY": "BELSAND",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843317,
      "TXT_PINCODE_LOCALITY": "BHUTAHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843318,
      "TXT_PINCODE_LOCALITY": "BUDHNAGRA DEORHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843319,
      "TXT_PINCODE_LOCALITY": "CHOROUT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843320,
      "TXT_PINCODE_LOCALITY": "PUPRI BAZAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843321,
      "TXT_PINCODE_LOCALITY": "KATRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843322,
      "TXT_PINCODE_LOCALITY": "MAJHAULIA ESTATE",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843323,
      "TXT_PINCODE_LOCALITY": "MANIK CHOWK",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843324,
      "TXT_PINCODE_LOCALITY": "PARIHAR S.O",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843325,
      "TXT_PINCODE_LOCALITY": "PARSAUNI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5621,
      "City District Name": "SHEOHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843326,
      "TXT_PINCODE_LOCALITY": "RAIPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843327,
      "TXT_PINCODE_LOCALITY": "RIGHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843328,
      "TXT_PINCODE_LOCALITY": "RUNISAIDPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843329,
      "TXT_PINCODE_LOCALITY": "SHEOHAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5621,
      "City District Name": "SHEOHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843330,
      "TXT_PINCODE_LOCALITY": "SONBARSA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843331,
      "TXT_PINCODE_LOCALITY": "SURSAND",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843332,
      "TXT_PINCODE_LOCALITY": "MAJORGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843333,
      "TXT_PINCODE_LOCALITY": "NANPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843334,
      "TXT_PINCODE_LOCALITY": "PIPRAHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5621,
      "City District Name": "SHEOHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843335,
      "TXT_PINCODE_LOCALITY": "GARHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843336,
      "TXT_PINCODE_LOCALITY": "RIGHA COLONY",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843337,
      "TXT_PINCODE_LOCALITY": "AMUA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843338,
      "TXT_PINCODE_LOCALITY": "BASANTPATTI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5621,
      "City District Name": "SHEOHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843339,
      "TXT_PINCODE_LOCALITY": "BABHANGAWA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843340,
      "TXT_PINCODE_LOCALITY": "RAMPUR GANGULI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843341,
      "TXT_PINCODE_LOCALITY": "RASALPUR GOSAIN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843342,
      "TXT_PINCODE_LOCALITY": "PARSHURAMPUR(SITAMARHI)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843343,
      "TXT_PINCODE_LOCALITY": "BARARI BIHTA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843344,
      "TXT_PINCODE_LOCALITY": "BHITHA DHARAMPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843346,
      "TXT_PINCODE_LOCALITY": "RASALPUR BALHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843347,
      "TXT_PINCODE_LOCALITY": "NARANGA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843348,
      "TXT_PINCODE_LOCALITY": "MADHUBAN BASAHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843349,
      "TXT_PINCODE_LOCALITY": "JANIPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843350,
      "TXT_PINCODE_LOCALITY": "MOHINI MANDAL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843351,
      "TXT_PINCODE_LOCALITY": "AKHTA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843352,
      "TXT_PINCODE_LOCALITY": "BATHNAHA (SITAMARHI)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843353,
      "TXT_PINCODE_LOCALITY": "BELAMUCHHPAKAUNI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5621,
      "City District Name": "SHEOHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843354,
      "TXT_PINCODE_LOCALITY": "BHAKURHAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843355,
      "TXT_PINCODE_LOCALITY": "CHAKMAHILA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843356,
      "TXT_PINCODE_LOCALITY": "DHANKAUL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5621,
      "City District Name": "SHEOHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843357,
      "TXT_PINCODE_LOCALITY": "DUMRI (SITAMARHI)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843358,
      "TXT_PINCODE_LOCALITY": "PHULKAHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5621,
      "City District Name": "SHEOHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843359,
      "TXT_PINCODE_LOCALITY": "HIRAUTA DUMMA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843360,
      "TXT_PINCODE_LOCALITY": "JAJUAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843361,
      "TXT_PINCODE_LOCALITY": "JAMUA (SITAMAHI)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843362,
      "TXT_PINCODE_LOCALITY": "NAYAGAON(SITAMRHI)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5621,
      "City District Name": "SHEOHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843363,
      "TXT_PINCODE_LOCALITY": "POTA TAJPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843364,
      "TXT_PINCODE_LOCALITY": "PUNAURA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843365,
      "TXT_PINCODE_LOCALITY": "REWASI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843366,
      "TXT_PINCODE_LOCALITY": "SIRAULI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843367,
      "TXT_PINCODE_LOCALITY": "SRIKHAND BHITHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843368,
      "TXT_PINCODE_LOCALITY": "TARIANI CHAPRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5621,
      "City District Name": "SHEOHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843376,
      "TXT_PINCODE_LOCALITY": "SURGAHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442650,
      "City District Name": "TARIANI CHOWK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843412,
      "TXT_PINCODE_LOCALITY": "RAMNAGAR KAKARIA DIH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442502,
      "City District Name": "DARAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843433,
      "TXT_PINCODE_LOCALITY": "BAGHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5773,
      "City District Name": "SUGAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843714,
      "TXT_PINCODE_LOCALITY": "SUKHSENWA MISIR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442546,
      "City District Name": "KATIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843732,
      "TXT_PINCODE_LOCALITY": "BARURAJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442474,
      "City District Name": "BARURAJ (MOTIPUR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843819,
      "TXT_PINCODE_LOCALITY": "JADUPATTI URF SEMRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442497,
      "City District Name": "CHARAUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 843929,
      "TXT_PINCODE_LOCALITY": "KOTHIA DHARAMPUR DHARAMAGHAT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5621,
      "City District Name": "SHEOHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844005,
      "TXT_PINCODE_LOCALITY": "BHERIAHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442550,
      "City District Name": "KEOTIRANWAY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844101,
      "TXT_PINCODE_LOCALITY": "HAJIPUR CHOWK",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844102,
      "TXT_PINCODE_LOCALITY": "HAJIPUR IND.AREA S.O",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844103,
      "TXT_PINCODE_LOCALITY": "SALEMPUR GANDAKI.",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6395,
      "City District Name": "HAJIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844110,
      "TXT_PINCODE_LOCALITY": "RAMPUR MANIRAM URF CHANDPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442528,
      "City District Name": "GORAUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844111,
      "TXT_PINCODE_LOCALITY": "ANIRUDH BELSAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844112,
      "TXT_PINCODE_LOCALITY": "BAGHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844113,
      "TXT_PINCODE_LOCALITY": "BALUKARAM",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844114,
      "TXT_PINCODE_LOCALITY": "BHAGWANPUR (HAZIPUR)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1866,
      "City District Name": "SIWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844115,
      "TXT_PINCODE_LOCALITY": "CHAKSIKANDAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844116,
      "TXT_PINCODE_LOCALITY": "DUMRI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844117,
      "TXT_PINCODE_LOCALITY": "DHARAHARA (MUZAFFARPUR)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844118,
      "TXT_PINCODE_LOCALITY": "GARAUL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844119,
      "TXT_PINCODE_LOCALITY": "GHATARO",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844120,
      "TXT_PINCODE_LOCALITY": "KURHANI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844121,
      "TXT_PINCODE_LOCALITY": "LALGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844122,
      "TXT_PINCODE_LOCALITY": "MAHUA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844123,
      "TXT_PINCODE_LOCALITY": "PARTA PTAND",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844124,
      "TXT_PINCODE_LOCALITY": "RAJAPAKAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844125,
      "TXT_PINCODE_LOCALITY": "SARAI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844126,
      "TXT_PINCODE_LOCALITY": "SINGHARA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844127,
      "TXT_PINCODE_LOCALITY": "TURKI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844128,
      "TXT_PINCODE_LOCALITY": "VAISHALI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844129,
      "TXT_PINCODE_LOCALITY": "SAHTHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844130,
      "TXT_PINCODE_LOCALITY": "KIRATPUR RAJARAM",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844131,
      "TXT_PINCODE_LOCALITY": "HANSIKEWAL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844132,
      "TXT_PINCODE_LOCALITY": "BAHILWARA GOBIND",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844133,
      "TXT_PINCODE_LOCALITY": "SAIN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844134,
      "TXT_PINCODE_LOCALITY": "SONDHO",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844135,
      "TXT_PINCODE_LOCALITY": "BIRANA LAKHANSEN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844136,
      "TXT_PINCODE_LOCALITY": "DIGHI KALAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844137,
      "TXT_PINCODE_LOCALITY": "BHAIROPOUR DEORHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844138,
      "TXT_PINCODE_LOCALITY": "JAINTIPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844139,
      "TXT_PINCODE_LOCALITY": "MIRJANAGAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844140,
      "TXT_PINCODE_LOCALITY": "RAMPUR RATNAKAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844141,
      "TXT_PINCODE_LOCALITY": "TISIAUTA DHARAMPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844151,
      "TXT_PINCODE_LOCALITY": "PAURA MADAN SINGH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6407,
      "City District Name": "LALGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844152,
      "TXT_PINCODE_LOCALITY": "VAISHALI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844212,
      "TXT_PINCODE_LOCALITY": "SAID KANHAULI CHAK",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442528,
      "City District Name": "GORAUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844304,
      "TXT_PINCODE_LOCALITY": "KUNRIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442566,
      "City District Name": "MAJHAULIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844311,
      "TXT_PINCODE_LOCALITY": "BEDAULIYA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442454,
      "City District Name": "AMNOUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844332,
      "TXT_PINCODE_LOCALITY": "RAMPUR GANGAULI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442626,
      "City District Name": "RIGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844450,
      "TXT_PINCODE_LOCALITY": "RASULPUR HABIB.",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442505,
      "City District Name": "DESRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844501,
      "TXT_PINCODE_LOCALITY": "BASDEOPUR CHANDEL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844502,
      "TXT_PINCODE_LOCALITY": "BIDHUPUR R S",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844503,
      "TXT_PINCODE_LOCALITY": "BIDHUPUR BAZAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844504,
      "TXT_PINCODE_LOCALITY": "DESRI (HAJIPUR)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844505,
      "TXT_PINCODE_LOCALITY": "JHANDABA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844506,
      "TXT_PINCODE_LOCALITY": "MAHNAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844507,
      "TXT_PINCODE_LOCALITY": "MAHNAR RD R S",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844508,
      "TXT_PINCODE_LOCALITY": "RAGHOPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844509,
      "TXT_PINCODE_LOCALITY": "SAHDEI BUZRUG",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844510,
      "TXT_PINCODE_LOCALITY": "BAIDALPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844511,
      "TXT_PINCODE_LOCALITY": "CHAMARHARA MAMSAI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844512,
      "TXT_PINCODE_LOCALITY": "CHECHAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844513,
      "TXT_PINCODE_LOCALITY": "DAYALPURGARH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844514,
      "TXT_PINCODE_LOCALITY": "DHAMAON",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844515,
      "TXT_PINCODE_LOCALITY": "HASANPUR GURDA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844516,
      "TXT_PINCODE_LOCALITY": "KHILOUT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844517,
      "TXT_PINCODE_LOCALITY": "KUTUBPUR SAIDPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844518,
      "TXT_PINCODE_LOCALITY": "LAWAPUR MAHNAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844519,
      "TXT_PINCODE_LOCALITY": "LAWAPUR NARAIN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844520,
      "TXT_PINCODE_LOCALITY": "MADHURAPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844521,
      "TXT_PINCODE_LOCALITY": "MATHURA SULTAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844522,
      "TXT_PINCODE_LOCALITY": "SALHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 844532,
      "TXT_PINCODE_LOCALITY": "SULTANPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845003,
      "TXT_PINCODE_LOCALITY": "CHAK MANGAR.",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442484,
      "City District Name": "BIDUPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845035,
      "TXT_PINCODE_LOCALITY": "CHANDULI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442625,
      "City District Name": "RAXAUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845101,
      "TXT_PINCODE_LOCALITY": "BAGAHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845102,
      "TXT_PINCODE_LOCALITY": "GAUNAHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845103,
      "TXT_PINCODE_LOCALITY": "HARI NAGAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845104,
      "TXT_PINCODE_LOCALITY": "MADHUBANI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845105,
      "TXT_PINCODE_LOCALITY": "NARAIPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845106,
      "TXT_PINCODE_LOCALITY": "RAMNAGAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845107,
      "TXT_PINCODE_LOCALITY": "VAIMIKINAGAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845108,
      "TXT_PINCODE_LOCALITY": "BAHERA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2441,
      "City District Name": "BETTIAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845109,
      "TXT_PINCODE_LOCALITY": "DAIN MARWA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845110,
      "TXT_PINCODE_LOCALITY": "DUMARIA BAZAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845112,
      "TXT_PINCODE_LOCALITY": "MAHULI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845113,
      "TXT_PINCODE_LOCALITY": "MATHIA KATHIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845114,
      "TXT_PINCODE_LOCALITY": "MALKAUNI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845115,
      "TXT_PINCODE_LOCALITY": "PATILARH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845116,
      "TXT_PINCODE_LOCALITY": "RAMPURWA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845117,
      "TXT_PINCODE_LOCALITY": "RAJWATIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845118,
      "TXT_PINCODE_LOCALITY": "SIDHAW",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845119,
      "TXT_PINCODE_LOCALITY": "TINPHERIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2441,
      "City District Name": "BETTIAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845120,
      "TXT_PINCODE_LOCALITY": "TELPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845145,
      "TXT_PINCODE_LOCALITY": "CHIRAIYA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845155,
      "TXT_PINCODE_LOCALITY": "MUJAUNA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4745,
      "City District Name": "NARKATIAGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845207,
      "TXT_PINCODE_LOCALITY": "BALTHAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442640,
      "City District Name": "SIKTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845209,
      "TXT_PINCODE_LOCALITY": "SIMRAWAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442638,
      "City District Name": "SHIVAJI NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845301,
      "TXT_PINCODE_LOCALITY": "ADAPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845302,
      "TXT_PINCODE_LOCALITY": "CHAURADANO",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845303,
      "TXT_PINCODE_LOCALITY": "GHORASAHAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845304,
      "TXT_PINCODE_LOCALITY": "CHAINPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845305,
      "TXT_PINCODE_LOCALITY": "RAXAUL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845306,
      "TXT_PINCODE_LOCALITY": "MAINATAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845307,
      "TXT_PINCODE_LOCALITY": "SIKTA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845308,
      "TXT_PINCODE_LOCALITY": "HARIPUR (BETTIAH)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845309,
      "TXT_PINCODE_LOCALITY": "SIKARPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845310,
      "TXT_PINCODE_LOCALITY": "AURAIYA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845311,
      "TXT_PINCODE_LOCALITY": "BHELAHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845312,
      "TXT_PINCODE_LOCALITY": "CHANDANBARA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845313,
      "TXT_PINCODE_LOCALITY": "JOKIARI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845314,
      "TXT_PINCODE_LOCALITY": "NONEYA DIH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845315,
      "TXT_PINCODE_LOCALITY": "MOHUAHI MARSHUDABAD",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442521,
      "City District Name": "GHORASAHAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845317,
      "TXT_PINCODE_LOCALITY": "BARSOI GHAT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3850,
      "City District Name": "KATIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845332,
      "TXT_PINCODE_LOCALITY": "NARANGA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442607,
      "City District Name": "PARIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845363,
      "TXT_PINCODE_LOCALITY": "BIRNAGAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845368,
      "TXT_PINCODE_LOCALITY": "BETTIAH CITY",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845401,
      "TXT_PINCODE_LOCALITY": "MOTIHARI COURT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845403,
      "TXT_PINCODE_LOCALITY": "MAJHAR KHAP",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442467,
      "City District Name": "BANKATWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845404,
      "TXT_PINCODE_LOCALITY": "SOHGI BARWA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845405,
      "TXT_PINCODE_LOCALITY": "MISRAULIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442639,
      "City District Name": "SIDHAW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845406,
      "TXT_PINCODE_LOCALITY": "RAJEPUR CHAMPARAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845410,
      "TXT_PINCODE_LOCALITY": "CHURAHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845411,
      "TXT_PINCODE_LOCALITY": "ARERAJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845412,
      "TXT_PINCODE_LOCALITY": "BARACHAKIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845413,
      "TXT_PINCODE_LOCALITY": "BAKARPUR JAGAT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845414,
      "TXT_PINCODE_LOCALITY": "CHAITA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845415,
      "TXT_PINCODE_LOCALITY": "AHIRAULI BHINAK",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845416,
      "TXT_PINCODE_LOCALITY": "DAMODARPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845417,
      "TXT_PINCODE_LOCALITY": "DARIAPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845418,
      "TXT_PINCODE_LOCALITY": "DHAKA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845419,
      "TXT_PINCODE_LOCALITY": "GOBINDGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845420,
      "TXT_PINCODE_LOCALITY": "TELGU",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845421,
      "TXT_PINCODE_LOCALITY": "HANUMAN SUGAR MILLS",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845422,
      "TXT_PINCODE_LOCALITY": "HARSIDHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845423,
      "TXT_PINCODE_LOCALITY": "HUSSAINI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845424,
      "TXT_PINCODE_LOCALITY": "KESARIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845425,
      "TXT_PINCODE_LOCALITY": "MALAHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845426,
      "TXT_PINCODE_LOCALITY": "MEHSI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845427,
      "TXT_PINCODE_LOCALITY": "PACHPAKRI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845428,
      "TXT_PINCODE_LOCALITY": "PAKRIDAYAL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845429,
      "TXT_PINCODE_LOCALITY": "PIPRA FACTORY",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845430,
      "TXT_PINCODE_LOCALITY": "PHENHARA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845431,
      "TXT_PINCODE_LOCALITY": "RAGHUNATHPUR BAZAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845432,
      "TXT_PINCODE_LOCALITY": "RAJPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845433,
      "TXT_PINCODE_LOCALITY": "RAMGARHWA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845434,
      "TXT_PINCODE_LOCALITY": "SANGRAMPUR(CHAMPARAN)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845435,
      "TXT_PINCODE_LOCALITY": "SEMRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845436,
      "TXT_PINCODE_LOCALITY": "TETARIA FACTORY",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845437,
      "TXT_PINCODE_LOCALITY": "TURKAULIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845438,
      "TXT_PINCODE_LOCALITY": "BETTIAH H O",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845440,
      "TXT_PINCODE_LOCALITY": "JAITPUR LANKARIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845449,
      "TXT_PINCODE_LOCALITY": "CHANPATIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845450,
      "TXT_PINCODE_LOCALITY": "CHUHARI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845451,
      "TXT_PINCODE_LOCALITY": "DIWANJIKE SHIKARPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845452,
      "TXT_PINCODE_LOCALITY": "JOGAPATTI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845453,
      "TXT_PINCODE_LOCALITY": "LAURIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845454,
      "TXT_PINCODE_LOCALITY": "MAJHAULIA R S",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845455,
      "TXT_PINCODE_LOCALITY": "NARKATIAGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845456,
      "TXT_PINCODE_LOCALITY": "SUGAULI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845457,
      "TXT_PINCODE_LOCALITY": "PATAHI(CHAMPARAN)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845458,
      "TXT_PINCODE_LOCALITY": "MANGURAHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845459,
      "TXT_PINCODE_LOCALITY": "JAGDISHPUR MOTIHARI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845460,
      "TXT_PINCODE_LOCALITY": "BETTIAH DIH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845461,
      "TXT_PINCODE_LOCALITY": "BAGAHI RATANPURA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845462,
      "TXT_PINCODE_LOCALITY": "BARWAT PASRAIN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845463,
      "TXT_PINCODE_LOCALITY": "BASWARIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845464,
      "TXT_PINCODE_LOCALITY": "BISHUNPURWA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845465,
      "TXT_PINCODE_LOCALITY": "BARWAT SENA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845466,
      "TXT_PINCODE_LOCALITY": "BAIRIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845467,
      "TXT_PINCODE_LOCALITY": "CHAUMUKHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845468,
      "TXT_PINCODE_LOCALITY": "DHUMNAGAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845469,
      "TXT_PINCODE_LOCALITY": "HARPUR TOLA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845470,
      "TXT_PINCODE_LOCALITY": "JHAKHRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845471,
      "TXT_PINCODE_LOCALITY": "KURWA MATHIE",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845472,
      "TXT_PINCODE_LOCALITY": "MALI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845473,
      "TXT_PINCODE_LOCALITY": "NAWALPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845474,
      "TXT_PINCODE_LOCALITY": "PURANIA GOSAIN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2441,
      "City District Name": "BETTIAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845475,
      "TXT_PINCODE_LOCALITY": "PARSA(BETTIAH)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845476,
      "TXT_PINCODE_LOCALITY": "PATIJIRWA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845477,
      "TXT_PINCODE_LOCALITY": "RAMPURWA MAHANWA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845478,
      "TXT_PINCODE_LOCALITY": "SUKULPAKAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845479,
      "TXT_PINCODE_LOCALITY": "SUGAON",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845480,
      "TXT_PINCODE_LOCALITY": "SRIPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845481,
      "TXT_PINCODE_LOCALITY": "TURHAPATTI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845483,
      "TXT_PINCODE_LOCALITY": "VICTORIA MISSION",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845484,
      "TXT_PINCODE_LOCALITY": "AMOLWA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845485,
      "TXT_PINCODE_LOCALITY": "AJAGARI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845486,
      "TXT_PINCODE_LOCALITY": "BARHARWA KALA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845487,
      "TXT_PINCODE_LOCALITY": "AMODAI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845488,
      "TXT_PINCODE_LOCALITY": "BARIARPUR (MOTIHARI)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845489,
      "TXT_PINCODE_LOCALITY": "BELA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845490,
      "TXT_PINCODE_LOCALITY": "BHUWAN CHAPRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845491,
      "TXT_PINCODE_LOCALITY": "BOKANEY KALA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845492,
      "TXT_PINCODE_LOCALITY": "CHAPRA BAHAS",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845494,
      "TXT_PINCODE_LOCALITY": "GAVENDRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845495,
      "TXT_PINCODE_LOCALITY": "KALYANPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845496,
      "TXT_PINCODE_LOCALITY": "KASWA MEHSI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845497,
      "TXT_PINCODE_LOCALITY": "KHARTARI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845498,
      "TXT_PINCODE_LOCALITY": "KUAWAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845499,
      "TXT_PINCODE_LOCALITY": "BARMANIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845500,
      "TXT_PINCODE_LOCALITY": "CHAMPAPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845501,
      "TXT_PINCODE_LOCALITY": "CHATIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845502,
      "TXT_PINCODE_LOCALITY": "KOILA BELWA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845503,
      "TXT_PINCODE_LOCALITY": "LAUKARIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845504,
      "TXT_PINCODE_LOCALITY": "MADHUBAN BEDIBAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845505,
      "TXT_PINCODE_LOCALITY": "MADHUBANI GHAT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845506,
      "TXT_PINCODE_LOCALITY": "MAHUAWAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845507,
      "TXT_PINCODE_LOCALITY": "MIRPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845508,
      "TXT_PINCODE_LOCALITY": "NONEYA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845509,
      "TXT_PINCODE_LOCALITY": "PALANWA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845511,
      "TXT_PINCODE_LOCALITY": "PARSAUNI TAPSI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845513,
      "TXT_PINCODE_LOCALITY": "RAMPUR(MOTIHARI)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845514,
      "TXT_PINCODE_LOCALITY": "RULANI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845515,
      "TXT_PINCODE_LOCALITY": "SAGAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845516,
      "TXT_PINCODE_LOCALITY": "SINGHASANI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845517,
      "TXT_PINCODE_LOCALITY": "SIRHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845518,
      "TXT_PINCODE_LOCALITY": "SISWA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845519,
      "TXT_PINCODE_LOCALITY": "SITAPUR(CHAMPARAN)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845520,
      "TXT_PINCODE_LOCALITY": "OLHA MADHUBANI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845521,
      "TXT_PINCODE_LOCALITY": "SAROTAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845522,
      "TXT_PINCODE_LOCALITY": "SIRAUNA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845523,
      "TXT_PINCODE_LOCALITY": "JIHULI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845524,
      "TXT_PINCODE_LOCALITY": "PURENDRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845525,
      "TXT_PINCODE_LOCALITY": "CHINTAMANPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845626,
      "TXT_PINCODE_LOCALITY": "GANGA SIRSIYA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442543,
      "City District Name": "KALYANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845706,
      "TXT_PINCODE_LOCALITY": "PIPRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5310,
      "City District Name": "RAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845901,
      "TXT_PINCODE_LOCALITY": "JAISINGHPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442652,
      "City District Name": "TURKAULIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 845938,
      "TXT_PINCODE_LOCALITY": "SEMRAHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442563,
      "City District Name": "MADHEPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 846001,
      "TXT_PINCODE_LOCALITY": "BANTA SOUTH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 846002,
      "TXT_PINCODE_LOCALITY": "SINHA HOMOE MED COLLEGE",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 846003,
      "TXT_PINCODE_LOCALITY": "DARBHANGA MEDICAL COLLEGE",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 846004,
      "TXT_PINCODE_LOCALITY": "KILAGHAT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 846005,
      "TXT_PINCODE_LOCALITY": "P TRAINING C DARBHANGA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 846006,
      "TXT_PINCODE_LOCALITY": "SUBHANKARPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 846007,
      "TXT_PINCODE_LOCALITY": "SARA MOHANPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 846008,
      "TXT_PINCODE_LOCALITY": "KAMESHWARPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 846009,
      "TXT_PINCODE_LOCALITY": "LAXMISAGAR S.O",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847032,
      "TXT_PINCODE_LOCALITY": "JALLEY",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847101,
      "TXT_PINCODE_LOCALITY": "ANANDPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847102,
      "TXT_PINCODE_LOCALITY": "BASAITH CHANPURA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847103,
      "TXT_PINCODE_LOCALITY": "BENIPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847104,
      "TXT_PINCODE_LOCALITY": "BHARWARA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847105,
      "TXT_PINCODE_LOCALITY": "BAHERI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847106,
      "TXT_PINCODE_LOCALITY": "KANSI SIMRI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847107,
      "TXT_PINCODE_LOCALITY": "KEOTSA BARUARY",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847108,
      "TXT_PINCODE_LOCALITY": "NARAHIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847109,
      "TXT_PINCODE_LOCALITY": "TULAPATGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847110,
      "TXT_PINCODE_LOCALITY": "KANSI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847111,
      "TXT_PINCODE_LOCALITY": "KORTHU",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847112,
      "TXT_PINCODE_LOCALITY": "MAHINAM",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847113,
      "TXT_PINCODE_LOCALITY": "BAHADURPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847114,
      "TXT_PINCODE_LOCALITY": "MANIKAULI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847115,
      "TXT_PINCODE_LOCALITY": "MURIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847116,
      "TXT_PINCODE_LOCALITY": "PANCHOBH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847117,
      "TXT_PINCODE_LOCALITY": "PATORE",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847118,
      "TXT_PINCODE_LOCALITY": "POHADDI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847119,
      "TXT_PINCODE_LOCALITY": "SINGHWARA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847120,
      "TXT_PINCODE_LOCALITY": "SIRNIA(DARBHANGA)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847121,
      "TXT_PINCODE_LOCALITY": "KEOTIRUNWAY",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847122,
      "TXT_PINCODE_LOCALITY": "BASAULI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847123,
      "TXT_PINCODE_LOCALITY": "BISFI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847124,
      "TXT_PINCODE_LOCALITY": "NOORCHAK",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847125,
      "TXT_PINCODE_LOCALITY": "UGHRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847126,
      "TXT_PINCODE_LOCALITY": "MASSA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847145,
      "TXT_PINCODE_LOCALITY": "MAHUA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442461,
      "City District Name": "BAHERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847201,
      "TXT_PINCODE_LOCALITY": "BAHERA(MADHUBANI)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847202,
      "TXT_PINCODE_LOCALITY": "BIKUPATTI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442481,
      "City District Name": "BENIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847203,
      "TXT_PINCODE_LOCALITY": "DARBHANGA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847204,
      "TXT_PINCODE_LOCALITY": "HABIDIH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847205,
      "TXT_PINCODE_LOCALITY": "POKHRAM BUZRUG",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847208,
      "TXT_PINCODE_LOCALITY": "BETAUNA.",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442563,
      "City District Name": "MADHEPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847211,
      "TXT_PINCODE_LOCALITY": "MADHUBANI H O",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847212,
      "TXT_PINCODE_LOCALITY": "BHOWRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847213,
      "TXT_PINCODE_LOCALITY": "MANGRAUNI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847214,
      "TXT_PINCODE_LOCALITY": "LAHERIYA GANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847215,
      "TXT_PINCODE_LOCALITY": "JAGATPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847221,
      "TXT_PINCODE_LOCALITY": "TULSIAHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442561,
      "City District Name": "LAUKAHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847222,
      "TXT_PINCODE_LOCALITY": "AREHAT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847223,
      "TXT_PINCODE_LOCALITY": "BENIPATTI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847224,
      "TXT_PINCODE_LOCALITY": "BABUBARHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847225,
      "TXT_PINCODE_LOCALITY": "CHHATOUNI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847226,
      "TXT_PINCODE_LOCALITY": "JAINAGAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847227,
      "TXT_PINCODE_LOCALITY": "KHUTANA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847228,
      "TXT_PINCODE_LOCALITY": "KHAJOULI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847229,
      "TXT_PINCODE_LOCALITY": "KALUAHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847230,
      "TXT_PINCODE_LOCALITY": "KHIRHAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847231,
      "TXT_PINCODE_LOCALITY": "LOHAT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847232,
      "TXT_PINCODE_LOCALITY": "MADHUBANI.",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847233,
      "TXT_PINCODE_LOCALITY": "NEHRU",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847234,
      "TXT_PINCODE_LOCALITY": "PANDAUL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847235,
      "TXT_PINCODE_LOCALITY": "RAJNAGAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847236,
      "TXT_PINCODE_LOCALITY": "RAMPATTI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847237,
      "TXT_PINCODE_LOCALITY": "RYAM FACTORY",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847238,
      "TXT_PINCODE_LOCALITY": "ROHIKA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847239,
      "TXT_PINCODE_LOCALITY": "SAKRI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847240,
      "TXT_PINCODE_LOCALITY": "BIRPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847241,
      "TXT_PINCODE_LOCALITY": "BHAUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847242,
      "TXT_PINCODE_LOCALITY": "DEODHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847243,
      "TXT_PINCODE_LOCALITY": "DAHAUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847244,
      "TXT_PINCODE_LOCALITY": "DULLIPATTI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847245,
      "TXT_PINCODE_LOCALITY": "HARLAKHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847246,
      "TXT_PINCODE_LOCALITY": "HARIPUR DIHTOL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847247,
      "TXT_PINCODE_LOCALITY": "KUMARKHAT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847248,
      "TXT_PINCODE_LOCALITY": "KAKRAIL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847249,
      "TXT_PINCODE_LOCALITY": "KALIKAPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847250,
      "TXT_PINCODE_LOCALITY": "KARAHIA BALHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847251,
      "TXT_PINCODE_LOCALITY": "MAKSIDA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847252,
      "TXT_PINCODE_LOCALITY": "NARAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847253,
      "TXT_PINCODE_LOCALITY": "PARIHARPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847254,
      "TXT_PINCODE_LOCALITY": "PATWARA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847255,
      "TXT_PINCODE_LOCALITY": "PARWA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847256,
      "TXT_PINCODE_LOCALITY": "RAGHOPUR DEORHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847257,
      "TXT_PINCODE_LOCALITY": "SIDHAPKALA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847273,
      "TXT_PINCODE_LOCALITY": "TEOTH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442480,
      "City District Name": "BENIPATTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847285,
      "TXT_PINCODE_LOCALITY": "SISAUNI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442574,
      "City District Name": "MARAUNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847301,
      "TXT_PINCODE_LOCALITY": "HAYAGHAT BILASPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847302,
      "TXT_PINCODE_LOCALITY": "DARBHANGA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847303,
      "TXT_PINCODE_LOCALITY": "DARBHANGA.",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847304,
      "TXT_PINCODE_LOCALITY": "KAMTAUL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847305,
      "TXT_PINCODE_LOCALITY": "MADHWARPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847306,
      "TXT_PINCODE_LOCALITY": "PINDARUCH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847307,
      "TXT_PINCODE_LOCALITY": "RATANPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847308,
      "TXT_PINCODE_LOCALITY": "SAHARGHAT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847309,
      "TXT_PINCODE_LOCALITY": "AHIARI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847310,
      "TXT_PINCODE_LOCALITY": "HARIHARPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847311,
      "TXT_PINCODE_LOCALITY": "HATHOURI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847312,
      "TXT_PINCODE_LOCALITY": "MURAITHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847313,
      "TXT_PINCODE_LOCALITY": "RARI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847322,
      "TXT_PINCODE_LOCALITY": "MAKRAMPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442603,
      "City District Name": "PANDAUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847323,
      "TXT_PINCODE_LOCALITY": "PARAUL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442480,
      "City District Name": "BENIPATTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847337,
      "TXT_PINCODE_LOCALITY": "RYAM FACTORY S.O",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847389,
      "TXT_PINCODE_LOCALITY": "MAHUA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442564,
      "City District Name": "MADHWAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847398,
      "TXT_PINCODE_LOCALITY": "GANGAUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442532,
      "City District Name": "HARLAKHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847401,
      "TXT_PINCODE_LOCALITY": "ANDHRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847402,
      "TXT_PINCODE_LOCALITY": "GHOGHARDIHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847403,
      "TXT_PINCODE_LOCALITY": "JHANJHARPUR R S",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847404,
      "TXT_PINCODE_LOCALITY": "JHANJHARPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847405,
      "TXT_PINCODE_LOCALITY": "KURSO NADIAMI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847406,
      "TXT_PINCODE_LOCALITY": "LAUKAHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847407,
      "TXT_PINCODE_LOCALITY": "LOHNA ROAD",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847408,
      "TXT_PINCODE_LOCALITY": "MADHEPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847409,
      "TXT_PINCODE_LOCALITY": "PHULPARAS",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847410,
      "TXT_PINCODE_LOCALITY": "TAMURIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847411,
      "TXT_PINCODE_LOCALITY": "HARIPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847412,
      "TXT_PINCODE_LOCALITY": "NARUAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847413,
      "TXT_PINCODE_LOCALITY": "DEEP",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847414,
      "TXT_PINCODE_LOCALITY": "JAGESHWAR ASTHAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847415,
      "TXT_PINCODE_LOCALITY": "KACHHUA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847416,
      "TXT_PINCODE_LOCALITY": "KACHWI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847417,
      "TXT_PINCODE_LOCALITY": "LAKHNAUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847418,
      "TXT_PINCODE_LOCALITY": "MAHRAIL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847419,
      "TXT_PINCODE_LOCALITY": "PARSA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847420,
      "TXT_PINCODE_LOCALITY": "RATAUL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847421,
      "TXT_PINCODE_LOCALITY": "LAUKAHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847422,
      "TXT_PINCODE_LOCALITY": "MANIGACHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847423,
      "TXT_PINCODE_LOCALITY": "PUTAI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847424,
      "TXT_PINCODE_LOCALITY": "SARSOPAHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847425,
      "TXT_PINCODE_LOCALITY": "KARMEGH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847426,
      "TXT_PINCODE_LOCALITY": "RAJE",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847427,
      "TXT_PINCODE_LOCALITY": "GHANSHAYMPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847428,
      "TXT_PINCODE_LOCALITY": "BHARATHI S.O",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847429,
      "TXT_PINCODE_LOCALITY": "KUSOTHAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442460,
      "City District Name": "BAHADURPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847437,
      "TXT_PINCODE_LOCALITY": "TETARIYA DHUP SAH.",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442600,
      "City District Name": "PACH DEURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847451,
      "TXT_PINCODE_LOCALITY": "KANHAULI BAZAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847452,
      "TXT_PINCODE_LOCALITY": "PHULPARAS",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847453,
      "TXT_PINCODE_LOCALITY": "RAJARAMPATTI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847467,
      "TXT_PINCODE_LOCALITY": "LODIAMI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442649,
      "City District Name": "TARDIH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847530,
      "TXT_PINCODE_LOCALITY": "AUSANPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442640,
      "City District Name": "SIKTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847708,
      "TXT_PINCODE_LOCALITY": "BHARGAMA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847710,
      "TXT_PINCODE_LOCALITY": "CHAK MILKI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442169,
      "City District Name": "ALINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 847806,
      "TXT_PINCODE_LOCALITY": "SARHWARA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442550,
      "City District Name": "KEOTIRANWAY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848030,
      "TXT_PINCODE_LOCALITY": "GHOR NAGAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442543,
      "City District Name": "KALYANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848101,
      "TXT_PINCODE_LOCALITY": "MAGARDAHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848102,
      "TXT_PINCODE_LOCALITY": "NAURANGA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848109,
      "TXT_PINCODE_LOCALITY": "LAGUNIA RAGHUKANTH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848110,
      "TXT_PINCODE_LOCALITY": "HARPUR AILITH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848111,
      "TXT_PINCODE_LOCALITY": "AKHTIARPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848112,
      "TXT_PINCODE_LOCALITY": "ANGARGHAT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848113,
      "TXT_PINCODE_LOCALITY": "BIROULI RURAL INSTITUTE",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848114,
      "TXT_PINCODE_LOCALITY": "DALSINGSARAI H O",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848115,
      "TXT_PINCODE_LOCALITY": "DIGHRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848116,
      "TXT_PINCODE_LOCALITY": "BANGHARA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848117,
      "TXT_PINCODE_LOCALITY": "ILMASNAGAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848118,
      "TXT_PINCODE_LOCALITY": "BELAMEGH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848119,
      "TXT_PINCODE_LOCALITY": "CHAND CHAUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848120,
      "TXT_PINCODE_LOCALITY": "CHAKRADHARPUR COLONY",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848121,
      "TXT_PINCODE_LOCALITY": "MORWA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848122,
      "TXT_PINCODE_LOCALITY": "MADHEPUR DIGHRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848123,
      "TXT_PINCODE_LOCALITY": "DHEPURA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848124,
      "TXT_PINCODE_LOCALITY": "GANGAPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848125,
      "TXT_PINCODE_LOCALITY": "PUSA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848126,
      "TXT_PINCODE_LOCALITY": "KAMRAON",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848127,
      "TXT_PINCODE_LOCALITY": "SARAIRANJAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848128,
      "TXT_PINCODE_LOCALITY": "KEOTA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848129,
      "TXT_PINCODE_LOCALITY": "SHAMBHUPATTI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848130,
      "TXT_PINCODE_LOCALITY": "TAJPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848131,
      "TXT_PINCODE_LOCALITY": "WAINI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848132,
      "TXT_PINCODE_LOCALITY": "UJAIRPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848133,
      "TXT_PINCODE_LOCALITY": "WARISNAGAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848134,
      "TXT_PINCODE_LOCALITY": "JITWARPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848135,
      "TXT_PINCODE_LOCALITY": "BHAGWATPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848136,
      "TXT_PINCODE_LOCALITY": "BATHUA BUZRUG",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848137,
      "TXT_PINCODE_LOCALITY": "BELARI(SAMASTIPUR)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848138,
      "TXT_PINCODE_LOCALITY": "BARBATTA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848139,
      "TXT_PINCODE_LOCALITY": "CHAITA (SAMASTIPUR)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848140,
      "TXT_PINCODE_LOCALITY": "GAOPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848142,
      "TXT_PINCODE_LOCALITY": "MUSAPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848144,
      "TXT_PINCODE_LOCALITY": "PATAILI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848145,
      "TXT_PINCODE_LOCALITY": "PANBASARIA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848146,
      "TXT_PINCODE_LOCALITY": "PAGRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848147,
      "TXT_PINCODE_LOCALITY": "RAMPUR JALALPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848148,
      "TXT_PINCODE_LOCALITY": "RUPOULI(SAMSTIPUR)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848149,
      "TXT_PINCODE_LOCALITY": "SATANPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848150,
      "TXT_PINCODE_LOCALITY": "BHAGWANPUR DESUA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848151,
      "TXT_PINCODE_LOCALITY": "BALL JAINARAIN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848152,
      "TXT_PINCODE_LOCALITY": "CHANDAULI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848153,
      "TXT_PINCODE_LOCALITY": "CHATAUNA (SAMASTIPUR)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848154,
      "TXT_PINCODE_LOCALITY": "FATEHPUR BELA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848155,
      "TXT_PINCODE_LOCALITY": "HARPUR REWARI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848156,
      "TXT_PINCODE_LOCALITY": "HARPUR PUSA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848157,
      "TXT_PINCODE_LOCALITY": "KARIAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848158,
      "TXT_PINCODE_LOCALITY": "PITAUNJIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848159,
      "TXT_PINCODE_LOCALITY": "BISHANPUR BATHUA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848160,
      "TXT_PINCODE_LOCALITY": "LAT BASEPURA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848161,
      "TXT_PINCODE_LOCALITY": "KERARI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848162,
      "TXT_PINCODE_LOCALITY": "MEHSI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848163,
      "TXT_PINCODE_LOCALITY": "MOHAMMADPUR SAKRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848164,
      "TXT_PINCODE_LOCALITY": "KASBA TAJPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442647,
      "City District Name": "TAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848182,
      "TXT_PINCODE_LOCALITY": "DARIAPUR PATAILI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442654,
      "City District Name": "UJIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848183,
      "TXT_PINCODE_LOCALITY": "PARORIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848201,
      "TXT_PINCODE_LOCALITY": "BAKHRI BAZAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2411,
      "City District Name": "BEGUSARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848202,
      "TXT_PINCODE_LOCALITY": "MEGHAUL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2411,
      "City District Name": "BEGUSARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848203,
      "TXT_PINCODE_LOCALITY": "SAKARPURA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848204,
      "TXT_PINCODE_LOCALITY": "GARHPURA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2411,
      "City District Name": "BEGUSARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848205,
      "TXT_PINCODE_LOCALITY": "HASANPUR SUGAR MILLS",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848206,
      "TXT_PINCODE_LOCALITY": "KUNDAL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848207,
      "TXT_PINCODE_LOCALITY": "BITHAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848208,
      "TXT_PINCODE_LOCALITY": "MANGALGARH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848209,
      "TXT_PINCODE_LOCALITY": "SINGHIA(SAMASTIPUR)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848210,
      "TXT_PINCODE_LOCALITY": "RUSERA THANA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848211,
      "TXT_PINCODE_LOCALITY": "NARAHAN R S",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848212,
      "TXT_PINCODE_LOCALITY": "SINGHIAGHAT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848213,
      "TXT_PINCODE_LOCALITY": "KUSESHWAR ASTHAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848214,
      "TXT_PINCODE_LOCALITY": "KHODAWANPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848216,
      "TXT_PINCODE_LOCALITY": "BHIRHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848217,
      "TXT_PINCODE_LOCALITY": "AURA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848218,
      "TXT_PINCODE_LOCALITY": "BHARWARI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848219,
      "TXT_PINCODE_LOCALITY": "BELSANDI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848220,
      "TXT_PINCODE_LOCALITY": "BHUSWAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848221,
      "TXT_PINCODE_LOCALITY": "CHAKTHAT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848222,
      "TXT_PINCODE_LOCALITY": "CHORATABHAKA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848223,
      "TXT_PINCODE_LOCALITY": "DESRI (SAMASTIPUR)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848224,
      "TXT_PINCODE_LOCALITY": "GAJPATTI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848225,
      "TXT_PINCODE_LOCALITY": "LILHAUL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848226,
      "TXT_PINCODE_LOCALITY": "MAHTHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848227,
      "TXT_PINCODE_LOCALITY": "NAYANAGAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848228,
      "TXT_PINCODE_LOCALITY": "PUSAHO",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3903,
      "City District Name": "KHAGARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848229,
      "TXT_PINCODE_LOCALITY": "SUMBHA DEORHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848230,
      "TXT_PINCODE_LOCALITY": "SANKHMOHAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848231,
      "TXT_PINCODE_LOCALITY": "SURAULI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848233,
      "TXT_PINCODE_LOCALITY": "SOHMA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848234,
      "TXT_PINCODE_LOCALITY": "UGAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848235,
      "TXT_PINCODE_LOCALITY": "WARI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848236,
      "TXT_PINCODE_LOCALITY": "SINGHIAGHAT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848237,
      "TXT_PINCODE_LOCALITY": "BELSANDI TARA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848238,
      "TXT_PINCODE_LOCALITY": "BHARPURA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848239,
      "TXT_PINCODE_LOCALITY": "JHAKHRA (SAMASTIPUR)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848301,
      "TXT_PINCODE_LOCALITY": "KISHANPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848302,
      "TXT_PINCODE_LOCALITY": "KALYANPUR CHOWK",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848303,
      "TXT_PINCODE_LOCALITY": "DHOBGAMA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848307,
      "TXT_PINCODE_LOCALITY": "TOLA MAJHARIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442640,
      "City District Name": "SIKTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848402,
      "TXT_PINCODE_LOCALITY": "BRAHMANDIHA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848405,
      "TXT_PINCODE_LOCALITY": "DIGHRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442618,
      "City District Name": "PUSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848407,
      "TXT_PINCODE_LOCALITY": "PET BIRAICHA.",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2342,
      "City District Name": "BARAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848433,
      "TXT_PINCODE_LOCALITY": "DUMRAWAN.",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442519,
      "City District Name": "GAIGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848501,
      "TXT_PINCODE_LOCALITY": "MOHIUDDINAGAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848502,
      "TXT_PINCODE_LOCALITY": "MOHIUDDINAGAR R S",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848503,
      "TXT_PINCODE_LOCALITY": "MOW BAZIDPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848504,
      "TXT_PINCODE_LOCALITY": "PATORY",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848505,
      "TXT_PINCODE_LOCALITY": "PURSHOTTAMPUR HALAI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848506,
      "TXT_PINCODE_LOCALITY": "BAGHRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848507,
      "TXT_PINCODE_LOCALITY": "AHMADPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848508,
      "TXT_PINCODE_LOCALITY": "BARHAUNA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848509,
      "TXT_PINCODE_LOCALITY": "BAJITPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848510,
      "TXT_PINCODE_LOCALITY": "BAJITPUR KARNAIL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848511,
      "TXT_PINCODE_LOCALITY": "GARH SISAI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848512,
      "TXT_PINCODE_LOCALITY": "JORPURA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848513,
      "TXT_PINCODE_LOCALITY": "MOW DHANESHPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848514,
      "TXT_PINCODE_LOCALITY": "RASPUR PATASIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848515,
      "TXT_PINCODE_LOCALITY": "SULTANPUR WEST",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848516,
      "TXT_PINCODE_LOCALITY": "SIMRI(SAMASTIPUR)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848517,
      "TXT_PINCODE_LOCALITY": "SARANGPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848518,
      "TXT_PINCODE_LOCALITY": "SAHPUR UNDI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848519,
      "TXT_PINCODE_LOCALITY": "SEORA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848572,
      "TXT_PINCODE_LOCALITY": "JORPURA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 848802,
      "TXT_PINCODE_LOCALITY": "SOMNAHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442543,
      "City District Name": "KALYANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 849121,
      "TXT_PINCODE_LOCALITY": "MUKUNDPUR URF PARMANANDPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6407,
      "City District Name": "LALGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 266017,
      "TXT_PINCODE_LOCALITY": "RAJAJIPURAM",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 270012,
      "TXT_PINCODE_LOCALITY": "MATHURA SADANAND",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 270016,
      "TXT_PINCODE_LOCALITY": "USAMANPUAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 270110,
      "TXT_PINCODE_LOCALITY": "MAKHDUMPUR MALIK",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3244,
      "City District Name": "GHOSI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 270129,
      "TXT_PINCODE_LOCALITY": "BABHANPURAUTTAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443019,
      "City District Name": "MUHAMMADABAD GOHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 270136,
      "TXT_PINCODE_LOCALITY": "HANSPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443025,
      "City District Name": "SAGRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 270163,
      "TXT_PINCODE_LOCALITY": "CHAUKI TEHUWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4215,
      "City District Name": "LALGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 270302,
      "TXT_PINCODE_LOCALITY": "BARWANIYA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 270400,
      "TXT_PINCODE_LOCALITY": "MAHUI.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1630,
      "City District Name": "DEORIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 270403,
      "TXT_PINCODE_LOCALITY": "TILSAWAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443019,
      "City District Name": "MUHAMMADABAD GOHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 270430,
      "TXT_PINCODE_LOCALITY": "MOJAHIDI URF ARANHWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4917,
      "City District Name": "PADRAUNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 270652,
      "TXT_PINCODE_LOCALITY": "TISAURI.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4215,
      "City District Name": "LALGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271001,
      "TXT_PINCODE_LOCALITY": "BUS STATION",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271002,
      "TXT_PINCODE_LOCALITY": "GONDA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271003,
      "TXT_PINCODE_LOCALITY": "BANKATWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271043,
      "TXT_PINCODE_LOCALITY": "INDERPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443031,
      "City District Name": "TARABGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271105,
      "TXT_PINCODE_LOCALITY": "GOGIYA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2842,
      "City District Name": "COLONELGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271112,
      "TXT_PINCODE_LOCALITY": "MAY PATHAK",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271120,
      "TXT_PINCODE_LOCALITY": "CHANDRADEEP GHAT GHAT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271122,
      "TXT_PINCODE_LOCALITY": "UTRAULA ROAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271123,
      "TXT_PINCODE_LOCALITY": "KHORASA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271124,
      "TXT_PINCODE_LOCALITY": "WAZIRGANJ (GONDA)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271125,
      "TXT_PINCODE_LOCALITY": "KATRA GULES SINGH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271126,
      "TXT_PINCODE_LOCALITY": "BB SINGH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271127,
      "TXT_PINCODE_LOCALITY": "RUPRADIH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271128,
      "TXT_PINCODE_LOCALITY": "CHETPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271129,
      "TXT_PINCODE_LOCALITY": "CHANDAPUR *",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271131,
      "TXT_PINCODE_LOCALITY": "MAHANUVA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4426,
      "City District Name": "MANKAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271142,
      "TXT_PINCODE_LOCALITY": "MATHIA JAPTI MAPHI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442991,
      "City District Name": "BURHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271200,
      "TXT_PINCODE_LOCALITY": "BHAGWANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271201,
      "TXT_PINCODE_LOCALITY": "BALRAMPUR CITY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271202,
      "TXT_PINCODE_LOCALITY": "ITYATHOK",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271203,
      "TXT_PINCODE_LOCALITY": "JARWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2241,
      "City District Name": "BAHRAICH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271204,
      "TXT_PINCODE_LOCALITY": "KHARAGPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271205,
      "TXT_PINCODE_LOCALITY": "SUGGANAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2287,
      "City District Name": "BALRAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271206,
      "TXT_PINCODE_LOCALITY": "PACHPERWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2287,
      "City District Name": "BALRAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271207,
      "TXT_PINCODE_LOCALITY": "SHEOPRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2287,
      "City District Name": "BALRAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271208,
      "TXT_PINCODE_LOCALITY": "TULSIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2287,
      "City District Name": "BALRAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271209,
      "TXT_PINCODE_LOCALITY": "NHAGWATIGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271210,
      "TXT_PINCODE_LOCALITY": "GAISARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2287,
      "City District Name": "BALRAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271211,
      "TXT_PINCODE_LOCALITY": "GUGAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271212,
      "TXT_PINCODE_LOCALITY": "KAUWAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2287,
      "City District Name": "BALRAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271213,
      "TXT_PINCODE_LOCALITY": "SRAWASTI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271214,
      "TXT_PINCODE_LOCALITY": "CHARSARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271215,
      "TXT_PINCODE_LOCALITY": "TIRLOKPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2287,
      "City District Name": "BALRAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271230,
      "TXT_PINCODE_LOCALITY": "MOHANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3379,
      "City District Name": "HARRAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271262,
      "TXT_PINCODE_LOCALITY": "REETHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442999,
      "City District Name": "GHANGHATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271301,
      "TXT_PINCODE_LOCALITY": "MOTIGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271302,
      "TXT_PINCODE_LOCALITY": "MANKAPUR BAZAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271303,
      "TXT_PINCODE_LOCALITY": "JAIPRABHA GRAM",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271304,
      "TXT_PINCODE_LOCALITY": "NAWABGANJ SUGAR FACTORY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271305,
      "TXT_PINCODE_LOCALITY": "MASKANWA R S",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271306,
      "TXT_PINCODE_LOCALITY": "REHTA BAZAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271307,
      "TXT_PINCODE_LOCALITY": "SADULLANAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2287,
      "City District Name": "BALRAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271308,
      "TXT_PINCODE_LOCALITY": "COLONELGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271309,
      "TXT_PINCODE_LOCALITY": "KATRABAZAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271310,
      "TXT_PINCODE_LOCALITY": "PARASPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2287,
      "City District Name": "BALRAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271311,
      "TXT_PINCODE_LOCALITY": "BARGADI KOT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2287,
      "City District Name": "BALRAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271312,
      "TXT_PINCODE_LOCALITY": "BABBAN JOT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271313,
      "TXT_PINCODE_LOCALITY": "CHAUHAN PURWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271314,
      "TXT_PINCODE_LOCALITY": "DEHRAS",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271315,
      "TXT_PINCODE_LOCALITY": "DHANAWAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271316,
      "TXT_PINCODE_LOCALITY": "DUBHA BAZAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271317,
      "TXT_PINCODE_LOCALITY": "KOTIA MADARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271318,
      "TXT_PINCODE_LOCALITY": "PASKA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271319,
      "TXT_PINCODE_LOCALITY": "PURE SHIV DAYAL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271320,
      "TXT_PINCODE_LOCALITY": "NAWABGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271321,
      "TXT_PINCODE_LOCALITY": "CHHAPIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271352,
      "TXT_PINCODE_LOCALITY": "KEOTALI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271401,
      "TXT_PINCODE_LOCALITY": "NELSAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271402,
      "TXT_PINCODE_LOCALITY": "BEGAMGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271403,
      "TXT_PINCODE_LOCALITY": "TARABGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271404,
      "TXT_PINCODE_LOCALITY": "ADAMPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271405,
      "TXT_PINCODE_LOCALITY": "CHAUHAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271407,
      "TXT_PINCODE_LOCALITY": "SANAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2287,
      "City District Name": "BALRAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271408,
      "TXT_PINCODE_LOCALITY": "DUJANPURGHAT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271416,
      "TXT_PINCODE_LOCALITY": "BAKSAILA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2842,
      "City District Name": "COLONELGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271501,
      "TXT_PINCODE_LOCALITY": "UTRAULA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2842,
      "City District Name": "COLONELGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271502,
      "TXT_PINCODE_LOCALITY": "COLONELGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271503,
      "TXT_PINCODE_LOCALITY": "KATRA BAZAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271504,
      "TXT_PINCODE_LOCALITY": "PARASPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2287,
      "City District Name": "BALRAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271509,
      "TXT_PINCODE_LOCALITY": "CHAUHANPURWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2287,
      "City District Name": "BALRAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271601,
      "TXT_PINCODE_LOCALITY": "BAIRIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271602,
      "TXT_PINCODE_LOCALITY": "DHANEYPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271603,
      "TXT_PINCODE_LOCALITY": "SRINAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271604,
      "TXT_PINCODE_LOCALITY": "UTRAULA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2287,
      "City District Name": "BALRAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271605,
      "TXT_PINCODE_LOCALITY": "ITEI RAMPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2287,
      "City District Name": "BALRAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271606,
      "TXT_PINCODE_LOCALITY": "GENDAS BUZURG",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2287,
      "City District Name": "BALRAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271607,
      "TXT_PINCODE_LOCALITY": "SRI DATTA GANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2287,
      "City District Name": "BALRAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271608,
      "TXT_PINCODE_LOCALITY": "SEMRA RAILWAY COLONY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271609,
      "TXT_PINCODE_LOCALITY": "CHAMROOPUR *",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2287,
      "City District Name": "BALRAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271610,
      "TXT_PINCODE_LOCALITY": "TIRLOKPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6016,
      "City District Name": "TULSIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271612,
      "TXT_PINCODE_LOCALITY": "JAMIN SAHASRAV",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443025,
      "City District Name": "SAGRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271706,
      "TXT_PINCODE_LOCALITY": "KHAKHADEYI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6016,
      "City District Name": "TULSIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271722,
      "TXT_PINCODE_LOCALITY": "MAHA PARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2842,
      "City District Name": "COLONELGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271801,
      "TXT_PINCODE_LOCALITY": "BAHRAICH CITY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2241,
      "City District Name": "BAHRAICH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271802,
      "TXT_PINCODE_LOCALITY": "KATRA BAHADURPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2241,
      "City District Name": "BAHRAICH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271803,
      "TXT_PINCODE_LOCALITY": "KANIBOJHI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 441038,
      "City District Name": "SHRAWASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271804,
      "TXT_PINCODE_LOCALITY": "LAXMAN NAGAR BAZAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 441038,
      "City District Name": "SHRAWASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271805,
      "TXT_PINCODE_LOCALITY": "PUREDEEN NAMGARH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3456,
      "City District Name": "IKAUNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271806,
      "TXT_PINCODE_LOCALITY": "BARAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271815,
      "TXT_PINCODE_LOCALITY": "GILAULA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424420,
      "City District Name": "SHRAVASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271821,
      "TXT_PINCODE_LOCALITY": "BISESHWARGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2241,
      "City District Name": "BAHRAICH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271822,
      "TXT_PINCODE_LOCALITY": "SONWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424420,
      "City District Name": "SHRAVASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271823,
      "TXT_PINCODE_LOCALITY": "CHITAURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2241,
      "City District Name": "BAHRAICH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271824,
      "TXT_PINCODE_LOCALITY": "MAHSI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2241,
      "City District Name": "BAHRAICH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271825,
      "TXT_PINCODE_LOCALITY": "BARNAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271826,
      "TXT_PINCODE_LOCALITY": "RAMPURWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2241,
      "City District Name": "BAHRAICH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271827,
      "TXT_PINCODE_LOCALITY": "BHAIGAHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2241,
      "City District Name": "BAHRAICH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271828,
      "TXT_PINCODE_LOCALITY": "HARIHARPUR RAVI (GONDA)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424420,
      "City District Name": "SHRAVASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271829,
      "TXT_PINCODE_LOCALITY": "GANGWALA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424420,
      "City District Name": "SHRAVASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271830,
      "TXT_PINCODE_LOCALITY": "SHEOPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2241,
      "City District Name": "BAHRAICH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271831,
      "TXT_PINCODE_LOCALITY": "KAISERGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1333,
      "City District Name": "BAHARAICH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271832,
      "TXT_PINCODE_LOCALITY": "SUJAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2241,
      "City District Name": "BAHRAICH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271833,
      "TXT_PINCODE_LOCALITY": "KATARNIAGHAT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424420,
      "City District Name": "SHRAVASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271834,
      "TXT_PINCODE_LOCALITY": "PATNA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424420,
      "City District Name": "SHRAVASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271835,
      "TXT_PINCODE_LOCALITY": "GILAULA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424420,
      "City District Name": "SHRAVASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271836,
      "TXT_PINCODE_LOCALITY": "CHARDA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2241,
      "City District Name": "BAHRAICH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271840,
      "TXT_PINCODE_LOCALITY": "SIRSIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2241,
      "City District Name": "BAHRAICH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271841,
      "TXT_PINCODE_LOCALITY": "GIRJAPURI COLONY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2241,
      "City District Name": "BAHRAICH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271845,
      "TXT_PINCODE_LOCALITY": "IKAUNA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 424420,
      "City District Name": "SHRAVASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271851,
      "TXT_PINCODE_LOCALITY": "KHERIGHAT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2241,
      "City District Name": "BAHRAICH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271855,
      "TXT_PINCODE_LOCALITY": "MIHINPURWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2241,
      "City District Name": "BAHRAICH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271861,
      "TXT_PINCODE_LOCALITY": "MATHURA BAZAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2287,
      "City District Name": "BALRAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271864,
      "TXT_PINCODE_LOCALITY": "KAILASHPURI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 352,
      "City District Name": "SAHARANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271865,
      "TXT_PINCODE_LOCALITY": "NANPARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2241,
      "City District Name": "BAHRAICH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271870,
      "TXT_PINCODE_LOCALITY": "AILIHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2241,
      "City District Name": "BAHRAICH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271871,
      "TXT_PINCODE_LOCALITY": "BASANERA, AILO",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1333,
      "City District Name": "BAHARAICH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271872,
      "TXT_PINCODE_LOCALITY": "HUZURPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2241,
      "City District Name": "BAHRAICH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271875,
      "TXT_PINCODE_LOCALITY": "RISIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2241,
      "City District Name": "BAHRAICH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271880,
      "TXT_PINCODE_LOCALITY": "SHIVPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2241,
      "City District Name": "BAHRAICH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271881,
      "TXT_PINCODE_LOCALITY": "RUPAIDIHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271882,
      "TXT_PINCODE_LOCALITY": "MATERA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2241,
      "City District Name": "BAHRAICH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271901,
      "TXT_PINCODE_LOCALITY": "JARWAL ROAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2241,
      "City District Name": "BAHRAICH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271902,
      "TXT_PINCODE_LOCALITY": "FAKAHRPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2241,
      "City District Name": "BAHRAICH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271903,
      "TXT_PINCODE_LOCALITY": "KAISERGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2241,
      "City District Name": "BAHRAICH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271904,
      "TXT_PINCODE_LOCALITY": "JARWAL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2241,
      "City District Name": "BAHRAICH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271905,
      "TXT_PINCODE_LOCALITY": "GAUDARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2241,
      "City District Name": "BAHRAICH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 271913,
      "TXT_PINCODE_LOCALITY": "WAZIRABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443031,
      "City District Name": "TARABGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272001,
      "TXT_PINCODE_LOCALITY": "CIVIL LINES",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272002,
      "TXT_PINCODE_LOCALITY": "PURANI BASTI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272003,
      "TXT_PINCODE_LOCALITY": "BASTI SUGAR FACTORY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272007,
      "TXT_PINCODE_LOCALITY": "MADAR JOT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272008,
      "TXT_PINCODE_LOCALITY": "PACHAURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272024,
      "TXT_PINCODE_LOCALITY": "TALBANDA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272059,
      "TXT_PINCODE_LOCALITY": "UCHHARA KHURD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3916,
      "City District Name": "KHALILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272072,
      "TXT_PINCODE_LOCALITY": "PIPRA MADHO.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272078,
      "TXT_PINCODE_LOCALITY": "RAMPUR REWATI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272100,
      "TXT_PINCODE_LOCALITY": "BIGRAMEER",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3916,
      "City District Name": "KHALILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272101,
      "TXT_PINCODE_LOCALITY": "SURAT GARHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442988,
      "City District Name": "BHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272102,
      "TXT_PINCODE_LOCALITY": "BIRDPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6490,
      "City District Name": "SIDDHARTNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272104,
      "TXT_PINCODE_LOCALITY": "PIPARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442988,
      "City District Name": "BHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272105,
      "TXT_PINCODE_LOCALITY": "BHANPUR KHAS",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6490,
      "City District Name": "SIDDHARTNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272111,
      "TXT_PINCODE_LOCALITY": "ROOPIN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442999,
      "City District Name": "GHANGHATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272112,
      "TXT_PINCODE_LOCALITY": "THALAHAPAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272118,
      "TXT_PINCODE_LOCALITY": "LOHRAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3379,
      "City District Name": "HARRAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272120,
      "TXT_PINCODE_LOCALITY": "PARASRAMPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272121,
      "TXT_PINCODE_LOCALITY": "PRATAPGARH KHURD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3379,
      "City District Name": "HARRAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272122,
      "TXT_PINCODE_LOCALITY": "RAMWAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4491,
      "City District Name": "MEHDAWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272123,
      "TXT_PINCODE_LOCALITY": "BARAHUWAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272124,
      "TXT_PINCODE_LOCALITY": "MAHSON",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272125,
      "TXT_PINCODE_LOCALITY": "DUDHARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6535,
      "City District Name": "SANT KABIR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272126,
      "TXT_PINCODE_LOCALITY": "BHAGAUSA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6490,
      "City District Name": "SIDDHARTNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272127,
      "TXT_PINCODE_LOCALITY": "AMORHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272128,
      "TXT_PINCODE_LOCALITY": "ROOPGARH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272129,
      "TXT_PINCODE_LOCALITY": "HARRAIYA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272130,
      "TXT_PINCODE_LOCALITY": "GOPINATHPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272131,
      "TXT_PINCODE_LOCALITY": "BARHNI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6490,
      "City District Name": "SIDDHARTNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272133,
      "TXT_PINCODE_LOCALITY": "DULARPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3379,
      "City District Name": "HARRAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272134,
      "TXT_PINCODE_LOCALITY": "KOILARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3379,
      "City District Name": "HARRAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272137,
      "TXT_PINCODE_LOCALITY": "RANIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3379,
      "City District Name": "HARRAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272141,
      "TXT_PINCODE_LOCALITY": "DUBAULIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272148,
      "TXT_PINCODE_LOCALITY": "PIPRA PRATHAM",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6535,
      "City District Name": "SANT KABIR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272149,
      "TXT_PINCODE_LOCALITY": "SIHARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272150,
      "TXT_PINCODE_LOCALITY": "AMBARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272151,
      "TXT_PINCODE_LOCALITY": "RUDAR NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272152,
      "TXT_PINCODE_LOCALITY": "DALDALA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272153,
      "TXT_PINCODE_LOCALITY": "BANSI (BASTI)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6490,
      "City District Name": "SIDDHARTNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272154,
      "TXT_PINCODE_LOCALITY": "BAUR BYAS",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6535,
      "City District Name": "SANT KABIR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272155,
      "TXT_PINCODE_LOCALITY": "HARRIYA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272159,
      "TXT_PINCODE_LOCALITY": "BASTI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272161,
      "TXT_PINCODE_LOCALITY": "BABHNAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272162,
      "TXT_PINCODE_LOCALITY": "DHANGHATA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272163,
      "TXT_PINCODE_LOCALITY": "GAUR RS",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272164,
      "TXT_PINCODE_LOCALITY": "HARIHARPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6535,
      "City District Name": "SANT KABIR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272165,
      "TXT_PINCODE_LOCALITY": "HAINSAR BAZAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6535,
      "City District Name": "SANT KABIR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272169,
      "TXT_PINCODE_LOCALITY": "KOPA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442988,
      "City District Name": "BHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272170,
      "TXT_PINCODE_LOCALITY": "MUNDERWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272171,
      "TXT_PINCODE_LOCALITY": "MEHDAWAL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6535,
      "City District Name": "SANT KABIR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272172,
      "TXT_PINCODE_LOCALITY": "MAHULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6535,
      "City District Name": "SANT KABIR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272173,
      "TXT_PINCODE_LOCALITY": "MAGHAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6535,
      "City District Name": "SANT KABIR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272175,
      "TXT_PINCODE_LOCALITY": "BAGHAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3365,
      "City District Name": "HARDOI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272176,
      "TXT_PINCODE_LOCALITY": "BHAINSAHI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6535,
      "City District Name": "SANT KABIR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272177,
      "TXT_PINCODE_LOCALITY": "BHARAWALIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272178,
      "TXT_PINCODE_LOCALITY": "MUNDERWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272181,
      "TXT_PINCODE_LOCALITY": "PAIKULIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272182,
      "TXT_PINCODE_LOCALITY": "WALTERGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272185,
      "TXT_PINCODE_LOCALITY": "PANARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442988,
      "City District Name": "BHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272187,
      "TXT_PINCODE_LOCALITY": "MAHAKHARPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3379,
      "City District Name": "HARRAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272188,
      "TXT_PINCODE_LOCALITY": "CHARISARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272189,
      "TXT_PINCODE_LOCALITY": "DOMARIAGANJ S. O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 441043,
      "City District Name": "SIDDHARTHNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272190,
      "TXT_PINCODE_LOCALITY": "SALTAWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272191,
      "TXT_PINCODE_LOCALITY": "HALLAUR (BASTI)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6490,
      "City District Name": "SIDDHARTNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272192,
      "TXT_PINCODE_LOCALITY": "ITWA B.O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 441043,
      "City District Name": "SIDDHARTHNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272193,
      "TXT_PINCODE_LOCALITY": "BHANWAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6535,
      "City District Name": "SANT KABIR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272194,
      "TXT_PINCODE_LOCALITY": "AMRIDIHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272195,
      "TXT_PINCODE_LOCALITY": "BHANPURKHAS",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 441043,
      "City District Name": "SIDDHARTHNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272196,
      "TXT_PINCODE_LOCALITY": "BHANPUR BABOO",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272199,
      "TXT_PINCODE_LOCALITY": "BAKHIRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6535,
      "City District Name": "SANT KABIR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272200,
      "TXT_PINCODE_LOCALITY": "TIWARI TOLA.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272201,
      "TXT_PINCODE_LOCALITY": "SIDDHARTHNAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 441043,
      "City District Name": "SIDDHARTHNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272202,
      "TXT_PINCODE_LOCALITY": "BIRDPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6490,
      "City District Name": "SIDDHARTNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272203,
      "TXT_PINCODE_LOCALITY": "NAUGARH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272204,
      "TXT_PINCODE_LOCALITY": "UDAIRAJGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6490,
      "City District Name": "SIDDHARTNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272205,
      "TXT_PINCODE_LOCALITY": "SOHRATGARH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6490,
      "City District Name": "SIDDHARTNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272206,
      "TXT_PINCODE_LOCALITY": "SHEIPATNAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272207,
      "TXT_PINCODE_LOCALITY": "TEHRI RANI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272208,
      "TXT_PINCODE_LOCALITY": "USKA BAZAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6490,
      "City District Name": "SIDDHARTNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272209,
      "TXT_PINCODE_LOCALITY": "GORAKHPUR S. O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272211,
      "TXT_PINCODE_LOCALITY": "BASTI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272212,
      "TXT_PINCODE_LOCALITY": "BASTI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272213,
      "TXT_PINCODE_LOCALITY": "PURSOTTAMPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3379,
      "City District Name": "HARRAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272264,
      "TXT_PINCODE_LOCALITY": "PARSAWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3916,
      "City District Name": "KHALILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272269,
      "TXT_PINCODE_LOCALITY": "SARANIYA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2328,
      "City District Name": "BANSI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272270,
      "TXT_PINCODE_LOCALITY": "BELHAR KALAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6535,
      "City District Name": "SANT KABIR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272271,
      "TXT_PINCODE_LOCALITY": "BELAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6535,
      "City District Name": "SANT KABIR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272301,
      "TXT_PINCODE_LOCALITY": "KALWARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272302,
      "TXT_PINCODE_LOCALITY": "NAGAR BAZAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272303,
      "TXT_PINCODE_LOCALITY": "CHAK BANJARI JOT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272325,
      "TXT_PINCODE_LOCALITY": "DIGHAWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4491,
      "City District Name": "MEHDAWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272471,
      "TXT_PINCODE_LOCALITY": "DAIJI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272476,
      "TXT_PINCODE_LOCALITY": "CHAURIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442999,
      "City District Name": "GHANGHATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272494,
      "TXT_PINCODE_LOCALITY": "SAGARA KHAS",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442988,
      "City District Name": "BHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272512,
      "TXT_PINCODE_LOCALITY": "SUKRAULI KUNWAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3379,
      "City District Name": "HARRAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272521,
      "TXT_PINCODE_LOCALITY": "MURADEHA BEG",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3916,
      "City District Name": "KHALILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272638,
      "TXT_PINCODE_LOCALITY": "NAVLI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443025,
      "City District Name": "SAGRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272721,
      "TXT_PINCODE_LOCALITY": "NAGARUPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3379,
      "City District Name": "HARRAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272763,
      "TXT_PINCODE_LOCALITY": "SHIWA GHAT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442988,
      "City District Name": "BHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272823,
      "TXT_PINCODE_LOCALITY": "PARVAR PARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3379,
      "City District Name": "HARRAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272829,
      "TXT_PINCODE_LOCALITY": "UDAIPUR KALAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3379,
      "City District Name": "HARRAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 272831,
      "TXT_PINCODE_LOCALITY": "NAIPURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3456,
      "City District Name": "IKAUNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273001,
      "TXT_PINCODE_LOCALITY": "UNIVERSITY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273002,
      "TXT_PINCODE_LOCALITY": "GORAKHPUR AIR FORCE STN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273003,
      "TXT_PINCODE_LOCALITY": "MANDIR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273004,
      "TXT_PINCODE_LOCALITY": "BASHARATPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273005,
      "TXT_PINCODE_LOCALITY": "TURKMANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273006,
      "TXT_PINCODE_LOCALITY": "GITA VATIKA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273007,
      "TXT_PINCODE_LOCALITY": "FERTILIZER FACTORY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273008,
      "TXT_PINCODE_LOCALITY": "MOHADDIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273009,
      "TXT_PINCODE_LOCALITY": "GORAKHPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273010,
      "TXT_PINCODE_LOCALITY": "MADAN MOHAN ENGG COLLEGE",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273011,
      "TXT_PINCODE_LOCALITY": "GORAKHPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273012,
      "TXT_PINCODE_LOCALITY": "RAILWAY COLONY (GORAKHPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273013,
      "TXT_PINCODE_LOCALITY": "BRD MEDICAL COLLEGE",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273014,
      "TXT_PINCODE_LOCALITY": "GORAKHPUR.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273015,
      "TXT_PINCODE_LOCALITY": "GORAKHNATH MANDIR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273016,
      "TXT_PINCODE_LOCALITY": "SHIVPURINEWCOLONY S.O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273017,
      "TXT_PINCODE_LOCALITY": "SIDDHARTH ENCLAVE",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273028,
      "TXT_PINCODE_LOCALITY": "SUGGATAREE",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273030,
      "TXT_PINCODE_LOCALITY": "SHIVPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273103,
      "TXT_PINCODE_LOCALITY": "PACH DEURI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442994,
      "City District Name": "CHAURI CHAURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273105,
      "TXT_PINCODE_LOCALITY": "KUSAHRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273118,
      "TXT_PINCODE_LOCALITY": "MADARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442992,
      "City District Name": "CAMPIERGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273121,
      "TXT_PINCODE_LOCALITY": "UMARIYA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3379,
      "City District Name": "HARRAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273125,
      "TXT_PINCODE_LOCALITY": "HEMDHAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273129,
      "TXT_PINCODE_LOCALITY": "ACHRAUL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3379,
      "City District Name": "HARRAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273131,
      "TXT_PINCODE_LOCALITY": "PARHNI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3379,
      "City District Name": "HARRAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273140,
      "TXT_PINCODE_LOCALITY": "SARAVANPUR PANDAY.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3379,
      "City District Name": "HARRAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273146,
      "TXT_PINCODE_LOCALITY": "KARMAKHOR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443007,
      "City District Name": "KHAJNI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273149,
      "TXT_PINCODE_LOCALITY": "GORAKHPUR S. O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273151,
      "TXT_PINCODE_LOCALITY": "GHUGHULI RS",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4324,
      "City District Name": "MAHRAJGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273152,
      "TXT_PINCODE_LOCALITY": "PIPRAICH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273153,
      "TXT_PINCODE_LOCALITY": "SISWABAZAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273155,
      "TXT_PINCODE_LOCALITY": "ANAND NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4324,
      "City District Name": "MAHRAJGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273157,
      "TXT_PINCODE_LOCALITY": "BRIDGEMANGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273158,
      "TXT_PINCODE_LOCALITY": "COMPIERGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273161,
      "TXT_PINCODE_LOCALITY": "DHANI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273162,
      "TXT_PINCODE_LOCALITY": "MAHARAJGANJ.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442134,
      "City District Name": "MAHARAJGANJ.",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273163,
      "TXT_PINCODE_LOCALITY": "SISWA BAZAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4324,
      "City District Name": "MAHRAJGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273164,
      "TXT_PINCODE_LOCALITY": "NAUTANWA BAZAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4324,
      "City District Name": "MAHRAJGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273165,
      "TXT_PINCODE_LOCALITY": "PEPPEGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273168,
      "TXT_PINCODE_LOCALITY": "SONA TIKER",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442992,
      "City District Name": "CAMPIERGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273200,
      "TXT_PINCODE_LOCALITY": "BARGO",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273201,
      "TXT_PINCODE_LOCALITY": "GORAKHPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273202,
      "TXT_PINCODE_LOCALITY": "SADAR NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273203,
      "TXT_PINCODE_LOCALITY": "BRAHMPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273204,
      "TXT_PINCODE_LOCALITY": "ARJE KASBA SWAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443004,
      "City District Name": "JAKHANIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273207,
      "TXT_PINCODE_LOCALITY": "JAMUI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4822,
      "City District Name": "NICHLAUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273209,
      "TXT_PINCODE_LOCALITY": "GORAKHPUR.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273211,
      "TXT_PINCODE_LOCALITY": "DHAKHWA BAZAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273212,
      "TXT_PINCODE_LOCALITY": "GORAKHPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273213,
      "TXT_PINCODE_LOCALITY": "PATAKHULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273215,
      "TXT_PINCODE_LOCALITY": "TALUABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273230,
      "TXT_PINCODE_LOCALITY": "BHURESAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273234,
      "TXT_PINCODE_LOCALITY": "KUSMAUL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273301,
      "TXT_PINCODE_LOCALITY": "PARTAWAL BAZAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273302,
      "TXT_PINCODE_LOCALITY": "BHAITAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273303,
      "TXT_PINCODE_LOCALITY": "MAHARAIGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273304,
      "TXT_PINCODE_LOCALITY": "NICHLAUL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4324,
      "City District Name": "MAHRAJGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273305,
      "TXT_PINCODE_LOCALITY": "THUTHIBARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273306,
      "TXT_PINCODE_LOCALITY": "SUMERSAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273307,
      "TXT_PINCODE_LOCALITY": "MITHAURA BAZAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4324,
      "City District Name": "MAHRAJGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273308,
      "TXT_PINCODE_LOCALITY": "BRD MEDICAL COLLEGE",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273309,
      "TXT_PINCODE_LOCALITY": "SEMRAHNI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443020,
      "City District Name": "PHARENDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273310,
      "TXT_PINCODE_LOCALITY": "PANIYARA S. O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4324,
      "City District Name": "MAHRAJGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273311,
      "TXT_PINCODE_LOCALITY": "SIRAULI.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4822,
      "City District Name": "NICHLAUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273340,
      "TXT_PINCODE_LOCALITY": "TIKARIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443000,
      "City District Name": "GOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273401,
      "TXT_PINCODE_LOCALITY": "BHAWAPAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273402,
      "TXT_PINCODE_LOCALITY": "BARHALGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273403,
      "TXT_PINCODE_LOCALITY": "BANSGAON (GORAKHPUR)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273404,
      "TXT_PINCODE_LOCALITY": "BELGAHT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273405,
      "TXT_PINCODE_LOCALITY": "BARHI (GORAKHPUR)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273406,
      "TXT_PINCODE_LOCALITY": "UNWAL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273407,
      "TXT_PINCODE_LOCALITY": "URWA BAZAR S.O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273408,
      "TXT_PINCODE_LOCALITY": "GOLA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273409,
      "TXT_PINCODE_LOCALITY": "DEIDIHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273411,
      "TXT_PINCODE_LOCALITY": "GAGAHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273412,
      "TXT_PINCODE_LOCALITY": "SHOPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273413,
      "TXT_PINCODE_LOCALITY": "KAURI RAM",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273414,
      "TXT_PINCODE_LOCALITY": "CHHITAUNI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273415,
      "TXT_PINCODE_LOCALITY": "KHADDA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4186,
      "City District Name": "KUSHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273506,
      "TXT_PINCODE_LOCALITY": "IMALIDIH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443010,
      "City District Name": "MADHUBAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273509,
      "TXT_PINCODE_LOCALITY": "DHURAHRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443000,
      "City District Name": "GOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273640,
      "TXT_PINCODE_LOCALITY": "MUHAMMADABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443019,
      "City District Name": "MUHAMMADABAD GOHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 273703,
      "TXT_PINCODE_LOCALITY": "BRAHM PUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442994,
      "City District Name": "CHAURI CHAURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274001,
      "TXT_PINCODE_LOCALITY": "NAI BAZAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1630,
      "City District Name": "DEORIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274003,
      "TXT_PINCODE_LOCALITY": "KUSHINAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4186,
      "City District Name": "KUSHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274004,
      "TXT_PINCODE_LOCALITY": "KISHUNPALI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1630,
      "City District Name": "DEORIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274005,
      "TXT_PINCODE_LOCALITY": "POUTWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5461,
      "City District Name": "SALEMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274008,
      "TXT_PINCODE_LOCALITY": "BELWA URF HASIM CHAK",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1630,
      "City District Name": "DEORIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274009,
      "TXT_PINCODE_LOCALITY": "MADHWAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5461,
      "City District Name": "SALEMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274030,
      "TXT_PINCODE_LOCALITY": "POKHAR BHINDA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3388,
      "City District Name": "HATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274114,
      "TXT_PINCODE_LOCALITY": "MATHAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3388,
      "City District Name": "HATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274121,
      "TXT_PINCODE_LOCALITY": "INDUSTRIAL ESTATE DEOR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1630,
      "City District Name": "DEORIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274122,
      "TXT_PINCODE_LOCALITY": "BHALUANI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1630,
      "City District Name": "DEORIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274132,
      "TXT_PINCODE_LOCALITY": "BHALWANI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1630,
      "City District Name": "DEORIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274140,
      "TXT_PINCODE_LOCALITY": "KUNDUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3388,
      "City District Name": "HATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274142,
      "TXT_PINCODE_LOCALITY": "PATAKHAULI.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442987,
      "City District Name": "BARHAJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274149,
      "TXT_PINCODE_LOCALITY": "BODARWAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4186,
      "City District Name": "KUSHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274182,
      "TXT_PINCODE_LOCALITY": "BHALUANI *",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1630,
      "City District Name": "DEORIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274199,
      "TXT_PINCODE_LOCALITY": "PAKARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3388,
      "City District Name": "HATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274200,
      "TXT_PINCODE_LOCALITY": "MAHUAWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1630,
      "City District Name": "DEORIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274201,
      "TXT_PINCODE_LOCALITY": "BAITALPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1630,
      "City District Name": "DEORIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274202,
      "TXT_PINCODE_LOCALITY": "GAURI BAZAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1630,
      "City District Name": "DEORIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274203,
      "TXT_PINCODE_LOCALITY": "HATA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4186,
      "City District Name": "KUSHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274204,
      "TXT_PINCODE_LOCALITY": "RUDRAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1630,
      "City District Name": "DEORIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274205,
      "TXT_PINCODE_LOCALITY": "MADANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1630,
      "City District Name": "DEORIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274206,
      "TXT_PINCODE_LOCALITY": "HETIMPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1630,
      "City District Name": "DEORIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274207,
      "TXT_PINCODE_LOCALITY": "SUKRAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4186,
      "City District Name": "KUSHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274208,
      "TXT_PINCODE_LOCALITY": "BELWA DUBAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1630,
      "City District Name": "DEORIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274216,
      "TXT_PINCODE_LOCALITY": "SUZIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3379,
      "City District Name": "HARRAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274226,
      "TXT_PINCODE_LOCALITY": "SONBARSA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1630,
      "City District Name": "DEORIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274249,
      "TXT_PINCODE_LOCALITY": "BANKATA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3388,
      "City District Name": "HATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274301,
      "TXT_PINCODE_LOCALITY": "CAPTIANGANJ (DEORIA)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4186,
      "City District Name": "KUSHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274302,
      "TXT_PINCODE_LOCALITY": "DUDHAI R S",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4186,
      "City District Name": "KUSHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274303,
      "TXT_PINCODE_LOCALITY": "KATH KULAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4186,
      "City District Name": "KUSHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274304,
      "TXT_PINCODE_LOCALITY": "PADRAUNA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4186,
      "City District Name": "KUSHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274305,
      "TXT_PINCODE_LOCALITY": "RAMKOLA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4186,
      "City District Name": "KUSHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274306,
      "TXT_PINCODE_LOCALITY": "LAXMIGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4186,
      "City District Name": "KUSHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274307,
      "TXT_PINCODE_LOCALITY": "TALA TOLA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4917,
      "City District Name": "PADRAUNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274308,
      "TXT_PINCODE_LOCALITY": "KUSHINAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4186,
      "City District Name": "KUSHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274309,
      "TXT_PINCODE_LOCALITY": "KUSHINAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4186,
      "City District Name": "KUSHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274310,
      "TXT_PINCODE_LOCALITY": "PADARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4917,
      "City District Name": "PADRAUNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274340,
      "TXT_PINCODE_LOCALITY": "PARSAUNI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3388,
      "City District Name": "HATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274400,
      "TXT_PINCODE_LOCALITY": "SEKHAUNA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1630,
      "City District Name": "DEORIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274401,
      "TXT_PINCODE_LOCALITY": "FAZIL NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4186,
      "City District Name": "KUSHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274402,
      "TXT_PINCODE_LOCALITY": "KASIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4186,
      "City District Name": "KUSHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274403,
      "TXT_PINCODE_LOCALITY": "KUSHINAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4186,
      "City District Name": "KUSHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274404,
      "TXT_PINCODE_LOCALITY": "PATHERDEWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1630,
      "City District Name": "DEORIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274405,
      "TXT_PINCODE_LOCALITY": "RAMPUR (DEORIA)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1630,
      "City District Name": "DEORIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274406,
      "TXT_PINCODE_LOCALITY": "SEORAHI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4186,
      "City District Name": "KUSHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274407,
      "TXT_PINCODE_LOCALITY": "TAMKOHI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4186,
      "City District Name": "KUSHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274408,
      "TXT_PINCODE_LOCALITY": "TARKULWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4186,
      "City District Name": "KUSHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274409,
      "TXT_PINCODE_LOCALITY": "TARAIYA SUJAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4186,
      "City District Name": "KUSHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274420,
      "TXT_PINCODE_LOCALITY": "MUKUNDPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1630,
      "City District Name": "DEORIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274436,
      "TXT_PINCODE_LOCALITY": "GARORA MAJHAURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443025,
      "City District Name": "SAGRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274501,
      "TXT_PINCODE_LOCALITY": "KHUKHUNDU",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1630,
      "City District Name": "DEORIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274502,
      "TXT_PINCODE_LOCALITY": "LAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1630,
      "City District Name": "DEORIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274504,
      "TXT_PINCODE_LOCALITY": "CHIRGODA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443006,
      "City District Name": "KASYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274505,
      "TXT_PINCODE_LOCALITY": "LAL ROAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1630,
      "City District Name": "DEORIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274506,
      "TXT_PINCODE_LOCALITY": "MAJHAULI RAJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1630,
      "City District Name": "DEORIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274507,
      "TXT_PINCODE_LOCALITY": "SIDHUWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1630,
      "City District Name": "DEORIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274508,
      "TXT_PINCODE_LOCALITY": "KHARWAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1330,
      "City District Name": "RAI BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274509,
      "TXT_PINCODE_LOCALITY": "SALEMPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1630,
      "City District Name": "DEORIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274601,
      "TXT_PINCODE_LOCALITY": "BARHAJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1630,
      "City District Name": "DEORIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274602,
      "TXT_PINCODE_LOCALITY": "BHAGALPUR (DEORIA)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1630,
      "City District Name": "DEORIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274603,
      "TXT_PINCODE_LOCALITY": "GAURAJAINAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1630,
      "City District Name": "DEORIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274604,
      "TXT_PINCODE_LOCALITY": "PAINA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1630,
      "City District Name": "DEORIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274700,
      "TXT_PINCODE_LOCALITY": "PARSIA MISKARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1630,
      "City District Name": "DEORIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274701,
      "TXT_PINCODE_LOCALITY": "BHATNI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1630,
      "City District Name": "DEORIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274702,
      "TXT_PINCODE_LOCALITY": "BHATPARRANI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1630,
      "City District Name": "DEORIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274703,
      "TXT_PINCODE_LOCALITY": "BHAGALPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1630,
      "City District Name": "DEORIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274704,
      "TXT_PINCODE_LOCALITY": "INGURI BAZAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1630,
      "City District Name": "DEORIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274705,
      "TXT_PINCODE_LOCALITY": "RAIBARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1630,
      "City District Name": "DEORIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274801,
      "TXT_PINCODE_LOCALITY": "CHHITAUNI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4186,
      "City District Name": "KUSHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274802,
      "TXT_PINCODE_LOCALITY": "DUDHARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4186,
      "City District Name": "KUSHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274803,
      "TXT_PINCODE_LOCALITY": "SISWA URF SISWANIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4917,
      "City District Name": "PADRAUNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274804,
      "TXT_PINCODE_LOCALITY": "CHHAPAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1630,
      "City District Name": "DEORIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274806,
      "TXT_PINCODE_LOCALITY": "DEORIA SADAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1630,
      "City District Name": "DEORIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274807,
      "TXT_PINCODE_LOCALITY": "POLICE LINE",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1630,
      "City District Name": "DEORIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 274808,
      "TXT_PINCODE_LOCALITY": "THOKA BANSHI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5461,
      "City District Name": "SALEMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 275015,
      "TXT_PINCODE_LOCALITY": "SHODHANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3244,
      "City District Name": "GHOSI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 275101,
      "TXT_PINCODE_LOCALITY": "MAU TOWN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6531,
      "City District Name": "MAU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 275102,
      "TXT_PINCODE_LOCALITY": "INDARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6531,
      "City District Name": "MAU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 275103,
      "TXT_PINCODE_LOCALITY": "KUSHMAUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1484,
      "City District Name": "MAUNATH BHANJAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 275104,
      "TXT_PINCODE_LOCALITY": "SARHASA ZAMIN SARHASA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3244,
      "City District Name": "GHOSI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 275105,
      "TXT_PINCODE_LOCALITY": "JAMALPUR MIRZAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6531,
      "City District Name": "MAU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 275125,
      "TXT_PINCODE_LOCALITY": "BAGHIDAND",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443025,
      "City District Name": "SAGRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 275159,
      "TXT_PINCODE_LOCALITY": "DHODHAYA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3916,
      "City District Name": "KHALILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 275201,
      "TXT_PINCODE_LOCALITY": "BAHADURGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 275202,
      "TXT_PINCODE_LOCALITY": "DULLAHPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 275203,
      "TXT_PINCODE_LOCALITY": "JAKHANIA GHAZIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 275204,
      "TXT_PINCODE_LOCALITY": "SADAT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 275205,
      "TXT_PINCODE_LOCALITY": "SADIABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 275206,
      "TXT_PINCODE_LOCALITY": "BHIMPURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 275208,
      "TXT_PINCODE_LOCALITY": "GHAZIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 275212,
      "TXT_PINCODE_LOCALITY": "DARIYAUNA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3379,
      "City District Name": "HARRAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 275218,
      "TXT_PINCODE_LOCALITY": "SEMARI MALMALA.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4724,
      "City District Name": "NANPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 275220,
      "TXT_PINCODE_LOCALITY": "BHAGIRATH PUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443004,
      "City District Name": "JAKHANIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 275301,
      "TXT_PINCODE_LOCALITY": "AMILA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6531,
      "City District Name": "MAU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 275302,
      "TXT_PINCODE_LOCALITY": "BHOPURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6531,
      "City District Name": "MAU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 275303,
      "TXT_PINCODE_LOCALITY": "DOHRI GHAT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 275304,
      "TXT_PINCODE_LOCALITY": "GHOSI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6531,
      "City District Name": "MAU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 275305,
      "TXT_PINCODE_LOCALITY": "MAU SADAR P. O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6531,
      "City District Name": "MAU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 275306,
      "TXT_PINCODE_LOCALITY": "SURAJPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6531,
      "City District Name": "MAU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 275307,
      "TXT_PINCODE_LOCALITY": "SEMRI JAMALPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6531,
      "City District Name": "MAU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 275411,
      "TXT_PINCODE_LOCALITY": "DHAKHWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2326,
      "City District Name": "BANSGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 275501,
      "TXT_PINCODE_LOCALITY": "KORANTADIH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 275578,
      "TXT_PINCODE_LOCALITY": "JANGAL GULARIHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4724,
      "City District Name": "NANPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 275602,
      "TXT_PINCODE_LOCALITY": "BELAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6531,
      "City District Name": "MAU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 275701,
      "TXT_PINCODE_LOCALITY": "SHAHZADPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1484,
      "City District Name": "MAUNATH BHANJAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276001,
      "TXT_PINCODE_LOCALITY": "AZAMGARH CITY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276004,
      "TXT_PINCODE_LOCALITY": "JAI CHANDPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4495,
      "City District Name": "MEHNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276008,
      "TXT_PINCODE_LOCALITY": "CHANDA BHARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6418,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276020,
      "TXT_PINCODE_LOCALITY": "HARIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4495,
      "City District Name": "MEHNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276038,
      "TXT_PINCODE_LOCALITY": "HASAN PATTI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443025,
      "City District Name": "SAGRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276061,
      "TXT_PINCODE_LOCALITY": "KHANKAH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443025,
      "City District Name": "SAGRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276104,
      "TXT_PINCODE_LOCALITY": "NAIZARPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276121,
      "TXT_PINCODE_LOCALITY": "BILARIYAGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276122,
      "TXT_PINCODE_LOCALITY": "RAUNAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276123,
      "TXT_PINCODE_LOCALITY": "TARWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276124,
      "TXT_PINCODE_LOCALITY": "AZAMGARH ESTATE",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276125,
      "TXT_PINCODE_LOCALITY": "BANKAT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276126,
      "TXT_PINCODE_LOCALITY": "BANSGAON AZAMGARGH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276127,
      "TXT_PINCODE_LOCALITY": "BAZAR GOSHIAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276128,
      "TXT_PINCODE_LOCALITY": "CHANDESAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276129,
      "TXT_PINCODE_LOCALITY": "CHIRAIYAKOTE",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276131,
      "TXT_PINCODE_LOCALITY": "JAHANGAGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6531,
      "City District Name": "MAU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276132,
      "TXT_PINCODE_LOCALITY": "LAT GHAT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443025,
      "City District Name": "SAGRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276134,
      "TXT_PINCODE_LOCALITY": "NAGVA MAIDO",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443025,
      "City District Name": "SAGRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276135,
      "TXT_PINCODE_LOCALITY": "KANNDHRAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276136,
      "TXT_PINCODE_LOCALITY": "LAL GHAT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276137,
      "TXT_PINCODE_LOCALITY": "MAHARAJGANJ (AZAMGARH)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276138,
      "TXT_PINCODE_LOCALITY": "SAGRI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276139,
      "TXT_PINCODE_LOCALITY": "SARDAHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276140,
      "TXT_PINCODE_LOCALITY": "JIYANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276141,
      "TXT_PINCODE_LOCALITY": "TERHI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276142,
      "TXT_PINCODE_LOCALITY": "KOILS",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276143,
      "TXT_PINCODE_LOCALITY": "MITTUPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276155,
      "TXT_PINCODE_LOCALITY": "KISHUNPUR HIRANAND",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443025,
      "City District Name": "SAGRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276161,
      "TXT_PINCODE_LOCALITY": "JALALABAD.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3379,
      "City District Name": "HARRAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276162,
      "TXT_PINCODE_LOCALITY": "SHAHDIH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443025,
      "City District Name": "SAGRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276165,
      "TXT_PINCODE_LOCALITY": "NEWAS",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442992,
      "City District Name": "CAMPIERGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276174,
      "TXT_PINCODE_LOCALITY": "HARNAI.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443025,
      "City District Name": "SAGRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276201,
      "TXT_PINCODE_LOCALITY": "KANJAHIT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276202,
      "TXT_PINCODE_LOCALITY": "LALGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276203,
      "TXT_PINCODE_LOCALITY": "MEHATAJPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276204,
      "TXT_PINCODE_LOCALITY": "MEHNAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276205,
      "TXT_PINCODE_LOCALITY": "KHALEY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276206,
      "TXT_PINCODE_LOCALITY": "SHIBLI ACADEMY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276207,
      "TXT_PINCODE_LOCALITY": "RANIKI SARAI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276208,
      "TXT_PINCODE_LOCALITY": "DEOGAON",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276220,
      "TXT_PINCODE_LOCALITY": "KHAWAJAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6418,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276227,
      "TXT_PINCODE_LOCALITY": "BAZARGOSAIN*",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276228,
      "TXT_PINCODE_LOCALITY": "GADDOPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5122,
      "City District Name": "PHULPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276255,
      "TXT_PINCODE_LOCALITY": "MALDEYPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5122,
      "City District Name": "PHULPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276288,
      "TXT_PINCODE_LOCALITY": "BHANIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276301,
      "TXT_PINCODE_LOCALITY": "BARDAH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276302,
      "TXT_PINCODE_LOCALITY": "GAMBHIRPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276303,
      "TXT_PINCODE_LOCALITY": "THEKMA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276304,
      "TXT_PINCODE_LOCALITY": "PHULPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276305,
      "TXT_PINCODE_LOCALITY": "SARAI MIR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276306,
      "TXT_PINCODE_LOCALITY": "SURAJPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276309,
      "TXT_PINCODE_LOCALITY": "CHITARA MAHMOODPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5122,
      "City District Name": "PHULPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276320,
      "TXT_PINCODE_LOCALITY": "PAKARPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5122,
      "City District Name": "PHULPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276321,
      "TXT_PINCODE_LOCALITY": "NATHPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4215,
      "City District Name": "LALGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276325,
      "TXT_PINCODE_LOCALITY": "KARI GHATAMPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5122,
      "City District Name": "PHULPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276339,
      "TXT_PINCODE_LOCALITY": "KARAILA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443025,
      "City District Name": "SAGRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276401,
      "TXT_PINCODE_LOCALITY": "AMILO",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276402,
      "TXT_PINCODE_LOCALITY": "KARHAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276403,
      "TXT_PINCODE_LOCALITY": "MOHAMMADABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2685,
      "City District Name": "CHANDAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276404,
      "TXT_PINCODE_LOCALITY": "MUBARAKPUR (AZAMGARH)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276405,
      "TXT_PINCODE_LOCALITY": "WALIDPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6531,
      "City District Name": "MAU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276406,
      "TXT_PINCODE_LOCALITY": "SUTARAHI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276409,
      "TXT_PINCODE_LOCALITY": "AMILA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276603,
      "TXT_PINCODE_LOCALITY": "BHAIROPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6531,
      "City District Name": "MAU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276604,
      "TXT_PINCODE_LOCALITY": "PAKARIA RANIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4495,
      "City District Name": "MEHNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276706,
      "TXT_PINCODE_LOCALITY": "BHIKHAMPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 276902,
      "TXT_PINCODE_LOCALITY": "SAIDPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4495,
      "City District Name": "MEHNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277001,
      "TXT_PINCODE_LOCALITY": "ASHARAM",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277101,
      "TXT_PINCODE_LOCALITY": "BANTHARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277107,
      "TXT_PINCODE_LOCALITY": "KADORI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277121,
      "TXT_PINCODE_LOCALITY": "KUREJI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277122,
      "TXT_PINCODE_LOCALITY": "BALLIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277123,
      "TXT_PINCODE_LOCALITY": "RATSAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277124,
      "TXT_PINCODE_LOCALITY": "CHAK USARAILA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277127,
      "TXT_PINCODE_LOCALITY": "JAI PRAKASH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277201,
      "TXT_PINCODE_LOCALITY": "BAIRIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277202,
      "TXT_PINCODE_LOCALITY": "BANSDIH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277203,
      "TXT_PINCODE_LOCALITY": "BANSDIH ROAD R S",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277204,
      "TXT_PINCODE_LOCALITY": "DALLANCHAPR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277205,
      "TXT_PINCODE_LOCALITY": "DUBEY CHAPRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277206,
      "TXT_PINCODE_LOCALITY": "GAIGHAT (BALLIA)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277207,
      "TXT_PINCODE_LOCALITY": "MANIHAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277208,
      "TXT_PINCODE_LOCALITY": "RANIGANJ BAZAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277209,
      "TXT_PINCODE_LOCALITY": "GAIGHAT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277210,
      "TXT_PINCODE_LOCALITY": "CHAUTA (BALLIS)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277211,
      "TXT_PINCODE_LOCALITY": "SAHATWAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277212,
      "TXT_PINCODE_LOCALITY": "SONWANI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277213,
      "TXT_PINCODE_LOCALITY": "NARAYANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277214,
      "TXT_PINCODE_LOCALITY": "KARANCHIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277215,
      "TXT_PINCODE_LOCALITY": "BALLIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277216,
      "TXT_PINCODE_LOCALITY": "LALGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277218,
      "TXT_PINCODE_LOCALITY": "RAJPUR (BALLIA)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277219,
      "TXT_PINCODE_LOCALITY": "KHEOSAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277301,
      "TXT_PINCODE_LOCALITY": "BASANTPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277302,
      "TXT_PINCODE_LOCALITY": "KHARSARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277303,
      "TXT_PINCODE_LOCALITY": "PANDAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277304,
      "TXT_PINCODE_LOCALITY": "BERUABAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277305,
      "TXT_PINCODE_LOCALITY": "BALLIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277306,
      "TXT_PINCODE_LOCALITY": "BALLIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277307,
      "TXT_PINCODE_LOCALITY": "BALLIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277401,
      "TXT_PINCODE_LOCALITY": "BHARSAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277402,
      "TXT_PINCODE_LOCALITY": "SONWANI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277403,
      "TXT_PINCODE_LOCALITY": "MAJHAUWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277404,
      "TXT_PINCODE_LOCALITY": "BEHAMARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277405,
      "TXT_PINCODE_LOCALITY": "DUBAHAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277501,
      "TXT_PINCODE_LOCALITY": "TUTUWARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277502,
      "TXT_PINCODE_LOCALITY": "NARHI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277503,
      "TXT_PINCODE_LOCALITY": "PHEPHNA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277504,
      "TXT_PINCODE_LOCALITY": "SOHAON",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277506,
      "TXT_PINCODE_LOCALITY": "SAGARPALI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277612,
      "TXT_PINCODE_LOCALITY": "TOHFAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443025,
      "City District Name": "SAGRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 277704,
      "TXT_PINCODE_LOCALITY": "SHITLAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4822,
      "City District Name": "NICHLAUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 278002,
      "TXT_PINCODE_LOCALITY": "SURWAR KALAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443024,
      "City District Name": "RUDHAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 278402,
      "TXT_PINCODE_LOCALITY": "UDHO CHHAPRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4917,
      "City District Name": "PADRAUNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 279181,
      "TXT_PINCODE_LOCALITY": "UTTARKASHI",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 444129,
      "City District Name": "UTTARKASHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 279403,
      "TXT_PINCODE_LOCALITY": "SAHUPAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443007,
      "City District Name": "KHAJNI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 280001,
      "TXT_PINCODE_LOCALITY": "SAKANA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 361,
      "City District Name": "MATHURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 280002,
      "TXT_PINCODE_LOCALITY": "MAHROLI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 361,
      "City District Name": "MATHURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 280004,
      "TXT_PINCODE_LOCALITY": "SGRA COIM",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 329,
      "City District Name": "AGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 280203,
      "TXT_PINCODE_LOCALITY": "KHUSHAK PURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3554,
      "City District Name": "JASRANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 280568,
      "TXT_PINCODE_LOCALITY": "DANG GATAIYA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4055,
      "City District Name": "KONCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 281000,
      "TXT_PINCODE_LOCALITY": "MATHURA H O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 361,
      "City District Name": "MATHURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 281001,
      "TXT_PINCODE_LOCALITY": "SUKH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 361,
      "City District Name": "MATHURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 281002,
      "TXT_PINCODE_LOCALITY": "MATHRA CANTT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 361,
      "City District Name": "MATHURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 281003,
      "TXT_PINCODE_LOCALITY": "GAYATRI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 361,
      "City District Name": "MATHURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 281004,
      "TXT_PINCODE_LOCALITY": "KRISHNANAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 361,
      "City District Name": "MATHURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 281005,
      "TXT_PINCODE_LOCALITY": "REFINARY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 361,
      "City District Name": "MATHURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 281006,
      "TXT_PINCODE_LOCALITY": "MATHURA.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 361,
      "City District Name": "MATHURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 281008,
      "TXT_PINCODE_LOCALITY": "SONOTH SHUMALI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 361,
      "City District Name": "MATHURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 281015,
      "TXT_PINCODE_LOCALITY": "NAGLA KASI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 361,
      "City District Name": "MATHURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 281026,
      "TXT_PINCODE_LOCALITY": "SHAHZOOPUR GUJAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 361,
      "City District Name": "MATHURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 281041,
      "TXT_PINCODE_LOCALITY": "GAUHARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2758,
      "City District Name": "CHHATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 281104,
      "TXT_PINCODE_LOCALITY": "UNCHAGAON",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3391,
      "City District Name": "HATHRAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 281112,
      "TXT_PINCODE_LOCALITY": "MATHURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 361,
      "City District Name": "MATHURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 281121,
      "TXT_PINCODE_LOCALITY": "VRINDABAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 361,
      "City District Name": "MATHURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 281122,
      "TXT_PINCODE_LOCALITY": "FARAH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 361,
      "City District Name": "MATHURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 281123,
      "TXT_PINCODE_LOCALITY": "SENHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 361,
      "City District Name": "MATHURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 281201,
      "TXT_PINCODE_LOCALITY": "BAJNA S.O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 361,
      "City District Name": "MATHURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 281202,
      "TXT_PINCODE_LOCALITY": "BHEEM BANGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443013,
      "City District Name": "MAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 281203,
      "TXT_PINCODE_LOCALITY": "TOLI KHADER",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443013,
      "City District Name": "MAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 281204,
      "TXT_PINCODE_LOCALITY": "RAYA S.O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 361,
      "City District Name": "MATHURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 281205,
      "TXT_PINCODE_LOCALITY": "SURIR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 361,
      "City District Name": "MATHURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 281206,
      "TXT_PINCODE_LOCALITY": "MANT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 361,
      "City District Name": "MATHURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 281301,
      "TXT_PINCODE_LOCALITY": "MATHURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 361,
      "City District Name": "MATHURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 281302,
      "TXT_PINCODE_LOCALITY": "BISAWAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6486,
      "City District Name": "MAHAMAYA NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 281303,
      "TXT_PINCODE_LOCALITY": "GOKUL.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443011,
      "City District Name": "MAHAVAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 281304,
      "TXT_PINCODE_LOCALITY": "RAMRAIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3520,
      "City District Name": "JALESAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 281305,
      "TXT_PINCODE_LOCALITY": "MAHABAN S.O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 361,
      "City District Name": "MATHURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 281306,
      "TXT_PINCODE_LOCALITY": "SADABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3391,
      "City District Name": "HATHRAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 281307,
      "TXT_PINCODE_LOCALITY": "SAHPAU",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3391,
      "City District Name": "HATHRAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 281308,
      "TXT_PINCODE_LOCALITY": "PACHAWAR B.O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 361,
      "City District Name": "MATHURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 281400,
      "TXT_PINCODE_LOCALITY": "TUMAULA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2758,
      "City District Name": "CHHATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 281401,
      "TXT_PINCODE_LOCALITY": "DAUTANA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 361,
      "City District Name": "MATHURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 281402,
      "TXT_PINCODE_LOCALITY": "JAIT S. O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 361,
      "City District Name": "MATHURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 281403,
      "TXT_PINCODE_LOCALITY": "KOSI KALAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 361,
      "City District Name": "MATHURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 281404,
      "TXT_PINCODE_LOCALITY": "SHERGARH.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 361,
      "City District Name": "MATHURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 281405,
      "TXT_PINCODE_LOCALITY": "BARSANA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 361,
      "City District Name": "MATHURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 281406,
      "TXT_PINCODE_LOCALITY": "CHAUMUHAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 361,
      "City District Name": "MATHURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 281501,
      "TXT_PINCODE_LOCALITY": "SHAHPUR JARAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 361,
      "City District Name": "MATHURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 281502,
      "TXT_PINCODE_LOCALITY": "GOVERDHAN S.O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 361,
      "City District Name": "MATHURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 281503,
      "TXT_PINCODE_LOCALITY": "LATIPURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 361,
      "City District Name": "MATHURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 281504,
      "TXT_PINCODE_LOCALITY": "RADHAKUND",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 361,
      "City District Name": "MATHURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 282001,
      "TXT_PINCODE_LOCALITY": "WORKSHOP",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 329,
      "City District Name": "AGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 282002,
      "TXT_PINCODE_LOCALITY": "NAI-KI-NANDI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 329,
      "City District Name": "AGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 282003,
      "TXT_PINCODE_LOCALITY": "B P OIL MILLS",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 329,
      "City District Name": "AGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 282004,
      "TXT_PINCODE_LOCALITY": "JOHNS MILL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 329,
      "City District Name": "AGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 282005,
      "TXT_PINCODE_LOCALITY": "LAWYERS COLONY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 329,
      "City District Name": "AGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 282006,
      "TXT_PINCODE_LOCALITY": "YAMUNA BRIDGE (AGRA)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 329,
      "City District Name": "AGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 282007,
      "TXT_PINCODE_LOCALITY": "ARTONI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 329,
      "City District Name": "AGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 282008,
      "TXT_PINCODE_LOCALITY": "I A F KHERIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 329,
      "City District Name": "AGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 282009,
      "TXT_PINCODE_LOCALITY": "C O D AGRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 329,
      "City District Name": "AGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 282010,
      "TXT_PINCODE_LOCALITY": "BHOGIPURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 329,
      "City District Name": "AGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 282152,
      "TXT_PINCODE_LOCALITY": "SALAHNAGAR BARBAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3964,
      "City District Name": "KHERAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 282205,
      "TXT_PINCODE_LOCALITY": "BHAGUPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 329,
      "City District Name": "AGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 282431,
      "TXT_PINCODE_LOCALITY": "BASAI KHERAGARH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3964,
      "City District Name": "KHERAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 282531,
      "TXT_PINCODE_LOCALITY": "SUDHRAI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1364,
      "City District Name": "FATEHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 282641,
      "TXT_PINCODE_LOCALITY": "DAULTA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5824,
      "City District Name": "TALBEHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 283001,
      "TXT_PINCODE_LOCALITY": "NAGLA MANGOLI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4004,
      "City District Name": "KIRAOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 283101,
      "TXT_PINCODE_LOCALITY": "ACHNERA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 329,
      "City District Name": "AGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 283102,
      "TXT_PINCODE_LOCALITY": "AKOLA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 329,
      "City District Name": "AGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 283103,
      "TXT_PINCODE_LOCALITY": "HAZRATPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 939,
      "City District Name": "FIROZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 283104,
      "TXT_PINCODE_LOCALITY": "BAH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 329,
      "City District Name": "AGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 283105,
      "TXT_PINCODE_LOCALITY": "BICHPURI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 329,
      "City District Name": "AGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 283107,
      "TXT_PINCODE_LOCALITY": "FIROZABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 939,
      "City District Name": "FIROZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 283110,
      "TXT_PINCODE_LOCALITY": "FATEHPUR SIKRI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 329,
      "City District Name": "AGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 283111,
      "TXT_PINCODE_LOCALITY": "FATEHABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 329,
      "City District Name": "AGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 283112,
      "TXT_PINCODE_LOCALITY": "IRADAT NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 329,
      "City District Name": "AGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 283113,
      "TXT_PINCODE_LOCALITY": "HOLIPURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 329,
      "City District Name": "AGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 283114,
      "TXT_PINCODE_LOCALITY": "JAITPUR AGRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 329,
      "City District Name": "AGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 283115,
      "TXT_PINCODE_LOCALITY": "JAGANAIR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 329,
      "City District Name": "AGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 283119,
      "TXT_PINCODE_LOCALITY": "KAGAROL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 329,
      "City District Name": "AGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 283121,
      "TXT_PINCODE_LOCALITY": "KHAIRAGARH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 329,
      "City District Name": "AGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 283122,
      "TXT_PINCODE_LOCALITY": "KITAOLI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 329,
      "City District Name": "AGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 283123,
      "TXT_PINCODE_LOCALITY": "PINAHAT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 329,
      "City District Name": "AGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 283124,
      "TXT_PINCODE_LOCALITY": "SAIYAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 329,
      "City District Name": "AGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 283125,
      "TXT_PINCODE_LOCALITY": "SHAMSHABAD (AGRA)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6565,
      "City District Name": "FARUKHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 283126,
      "TXT_PINCODE_LOCALITY": "HASANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 329,
      "City District Name": "AGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 283127,
      "TXT_PINCODE_LOCALITY": "BELANGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 329,
      "City District Name": "AGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 283130,
      "TXT_PINCODE_LOCALITY": "FIROZABAD.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 939,
      "City District Name": "FIROZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 283135,
      "TXT_PINCODE_LOCALITY": "BARA BAZAR SHIKOHABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5628,
      "City District Name": "SHIKOHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 283136,
      "TXT_PINCODE_LOCALITY": "JASRANA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3554,
      "City District Name": "JASRANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 283141,
      "TXT_PINCODE_LOCALITY": "KARANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5628,
      "City District Name": "SHIKOHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 283142,
      "TXT_PINCODE_LOCALITY": "FIROZABAD S. O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 939,
      "City District Name": "FIROZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 283145,
      "TXT_PINCODE_LOCALITY": "JAIMAI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5628,
      "City District Name": "SHIKOHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 283151,
      "TXT_PINCODE_LOCALITY": "SIRSAGANJ",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 939,
      "City District Name": "FIROZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 283152,
      "TXT_PINCODE_LOCALITY": "RUDRAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3554,
      "City District Name": "JASRANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 283200,
      "TXT_PINCODE_LOCALITY": "BARHAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 329,
      "City District Name": "AGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 283201,
      "TXT_PINCODE_LOCALITY": "BARHAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 329,
      "City District Name": "AGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 283202,
      "TXT_PINCODE_LOCALITY": "ETAMADPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 329,
      "City District Name": "AGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 283203,
      "TXT_PINCODE_LOCALITY": "R R FIROZABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 939,
      "City District Name": "FIROZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 283204,
      "TXT_PINCODE_LOCALITY": "TUNDLA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 939,
      "City District Name": "FIROZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 283205,
      "TXT_PINCODE_LOCALITY": "COLLECTORATE FIROZABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4339,
      "City District Name": "MAINPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 283206,
      "TXT_PINCODE_LOCALITY": "BARTARA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 939,
      "City District Name": "FIROZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 283207,
      "TXT_PINCODE_LOCALITY": "SHEKHANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3554,
      "City District Name": "JASRANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 283208,
      "TXT_PINCODE_LOCALITY": "DATAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 939,
      "City District Name": "FIROZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284001,
      "TXT_PINCODE_LOCALITY": "ORCHHA GATE",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 360,
      "City District Name": "JHANSI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284002,
      "TXT_PINCODE_LOCALITY": "JHANSI CITY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 360,
      "City District Name": "JHANSI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284003,
      "TXT_PINCODE_LOCALITY": "SIPRI BAZAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 360,
      "City District Name": "JHANSI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284004,
      "TXT_PINCODE_LOCALITY": "MATHANIYA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3198,
      "City District Name": "GARAUTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284028,
      "TXT_PINCODE_LOCALITY": "VEERANGNA NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 360,
      "City District Name": "JHANSI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284120,
      "TXT_PINCODE_LOCALITY": "CHAMRAUWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 360,
      "City District Name": "JHANSI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284121,
      "TXT_PINCODE_LOCALITY": "BARAGAON (JHANSI)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 360,
      "City District Name": "JHANSI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284122,
      "TXT_PINCODE_LOCALITY": "BANSI (JHANSI)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1801,
      "City District Name": "LALITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284123,
      "TXT_PINCODE_LOCALITY": "BARH (JHANSI)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1801,
      "City District Name": "LALITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284124,
      "TXT_PINCODE_LOCALITY": "JAKHAURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1801,
      "City District Name": "LALITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284125,
      "TXT_PINCODE_LOCALITY": "LAKEVIEW C P O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1801,
      "City District Name": "LALITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284126,
      "TXT_PINCODE_LOCALITY": "TALBEHAT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1801,
      "City District Name": "LALITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284127,
      "TXT_PINCODE_LOCALITY": "BHATTAGAON",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 360,
      "City District Name": "JHANSI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284128,
      "TXT_PINCODE_LOCALITY": "M L B COLLEGE (JHANSI)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 360,
      "City District Name": "JHANSI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284129,
      "TXT_PINCODE_LOCALITY": "BHEL (JHANSI)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 360,
      "City District Name": "JHANSI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284130,
      "TXT_PINCODE_LOCALITY": "BAGHAURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5824,
      "City District Name": "TALBEHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284135,
      "TXT_PINCODE_LOCALITY": "HASARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 360,
      "City District Name": "JHANSI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284136,
      "TXT_PINCODE_LOCALITY": "BANGAWAN KALAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1801,
      "City District Name": "LALITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284200,
      "TXT_PINCODE_LOCALITY": "BAGHAIRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443030,
      "City District Name": "TAHRAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284201,
      "TXT_PINCODE_LOCALITY": "BARWA SAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 360,
      "City District Name": "JHANSI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284202,
      "TXT_PINCODE_LOCALITY": "GURSARAI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 360,
      "City District Name": "JHANSI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284203,
      "TXT_PINCODE_LOCALITY": "GARAUTHA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 360,
      "City District Name": "JHANSI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284204,
      "TXT_PINCODE_LOCALITY": "MAHEWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3518,
      "City District Name": "JALAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284205,
      "TXT_PINCODE_LOCALITY": "RANIPUR (JHANSI)",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 360,
      "City District Name": "JHANSI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284206,
      "TXT_PINCODE_LOCALITY": "TODI FATEHPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 360,
      "City District Name": "JHANSI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284221,
      "TXT_PINCODE_LOCALITY": "TAJPURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 443030,
      "City District Name": "TAHRAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284301,
      "TXT_PINCODE_LOCALITY": "KARIYANPURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 360,
      "City District Name": "JHANSI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284302,
      "TXT_PINCODE_LOCALITY": "ERICH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 360,
      "City District Name": "JHANSI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284303,
      "TXT_PINCODE_LOCALITY": "MOTH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 360,
      "City District Name": "JHANSI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284304,
      "TXT_PINCODE_LOCALITY": "SAMTHAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 360,
      "City District Name": "JHANSI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284305,
      "TXT_PINCODE_LOCALITY": "PARICHHA THERMAL POWER",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 360,
      "City District Name": "JHANSI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284306,
      "TXT_PINCODE_LOCALITY": "AMGABN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 360,
      "City District Name": "JHANSI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284309,
      "TXT_PINCODE_LOCALITY": "KANDAUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4566,
      "City District Name": "MOTH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284401,
      "TXT_PINCODE_LOCALITY": "BAHINA CANTT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 360,
      "City District Name": "JHANSI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284402,
      "TXT_PINCODE_LOCALITY": "BANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1801,
      "City District Name": "LALITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284403,
      "TXT_PINCODE_LOCALITY": "LALITPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1801,
      "City District Name": "LALITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284404,
      "TXT_PINCODE_LOCALITY": "MARAURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1801,
      "City District Name": "LALITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284405,
      "TXT_PINCODE_LOCALITY": "MAHARONI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1801,
      "City District Name": "LALITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284406,
      "TXT_PINCODE_LOCALITY": "NARHAT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1801,
      "City District Name": "LALITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284407,
      "TXT_PINCODE_LOCALITY": "JAKHLOAUN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1801,
      "City District Name": "LALITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284408,
      "TXT_PINCODE_LOCALITY": "MANIKPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4325,
      "City District Name": "MAHRONI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284415,
      "TXT_PINCODE_LOCALITY": "CHHAYAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4325,
      "City District Name": "MAHRONI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284419,
      "TXT_PINCODE_LOCALITY": "RAKSA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 360,
      "City District Name": "JHANSI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284440,
      "TXT_PINCODE_LOCALITY": "MAHOLI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1801,
      "City District Name": "LALITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284501,
      "TXT_PINCODE_LOCALITY": "RAJGHAT DAM PROJECT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1801,
      "City District Name": "LALITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 284801,
      "TXT_PINCODE_LOCALITY": "CHIRGAON",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 360,
      "City District Name": "JHANSI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285001,
      "TXT_PINCODE_LOCALITY": "PIARIYA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3518,
      "City District Name": "JALAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285002,
      "TXT_PINCODE_LOCALITY": "KISHUNPURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4055,
      "City District Name": "KONCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285102,
      "TXT_PINCODE_LOCALITY": "JALAUN.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3518,
      "City District Name": "JALAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285120,
      "TXT_PINCODE_LOCALITY": "CHHIRAOLI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4055,
      "City District Name": "KONCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285121,
      "TXT_PINCODE_LOCALITY": "BANGRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3518,
      "City District Name": "JALAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285122,
      "TXT_PINCODE_LOCALITY": "DAKORE",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3518,
      "City District Name": "JALAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285123,
      "TXT_PINCODE_LOCALITY": "JALAUN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3518,
      "City District Name": "JALAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285124,
      "TXT_PINCODE_LOCALITY": "JAGMANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3518,
      "City District Name": "JALAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285125,
      "TXT_PINCODE_LOCALITY": "KUTHOND",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3518,
      "City District Name": "JALAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285126,
      "TXT_PINCODE_LOCALITY": "MADHOGARH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3518,
      "City District Name": "JALAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285127,
      "TXT_PINCODE_LOCALITY": "RAMPURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3518,
      "City District Name": "JALAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285128,
      "TXT_PINCODE_LOCALITY": "HARDRUKH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3518,
      "City District Name": "JALAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285129,
      "TXT_PINCODE_LOCALITY": "UMRI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3518,
      "City District Name": "JALAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285130,
      "TXT_PINCODE_LOCALITY": "MADARIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3518,
      "City District Name": "JALAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285131,
      "TXT_PINCODE_LOCALITY": "HARDOI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3518,
      "City District Name": "JALAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285132,
      "TXT_PINCODE_LOCALITY": "JALAUN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3518,
      "City District Name": "JALAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285133,
      "TXT_PINCODE_LOCALITY": "JALAUN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3518,
      "City District Name": "JALAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285134,
      "TXT_PINCODE_LOCALITY": "RUPAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4286,
      "City District Name": "MADHOGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285136,
      "TXT_PINCODE_LOCALITY": "JALAUN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3518,
      "City District Name": "JALAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285153,
      "TXT_PINCODE_LOCALITY": "KAITHWA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3518,
      "City District Name": "JALAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285187,
      "TXT_PINCODE_LOCALITY": "JALAUN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3518,
      "City District Name": "JALAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285196,
      "TXT_PINCODE_LOCALITY": "SULTANPUR UBARI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3707,
      "City District Name": "KALPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285200,
      "TXT_PINCODE_LOCALITY": "JATORA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3707,
      "City District Name": "KALPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285201,
      "TXT_PINCODE_LOCALITY": "SATOH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3518,
      "City District Name": "JALAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285202,
      "TXT_PINCODE_LOCALITY": "ATA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3518,
      "City District Name": "JALAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285203,
      "TXT_PINCODE_LOCALITY": "MAWAI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1770,
      "City District Name": "FATEHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285204,
      "TXT_PINCODE_LOCALITY": "KALPI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3518,
      "City District Name": "JALAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285205,
      "TXT_PINCODE_LOCALITY": "KONCH BAZARIYA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3518,
      "City District Name": "JALAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285206,
      "TXT_PINCODE_LOCALITY": "NADIGAON",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3518,
      "City District Name": "JALAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285208,
      "TXT_PINCODE_LOCALITY": "BARHAL",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4055,
      "City District Name": "KONCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285211,
      "TXT_PINCODE_LOCALITY": "JALAUN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3518,
      "City District Name": "JALAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285221,
      "TXT_PINCODE_LOCALITY": "JALAUN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3518,
      "City District Name": "JALAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285222,
      "TXT_PINCODE_LOCALITY": "JALAUN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3518,
      "City District Name": "JALAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285223,
      "TXT_PINCODE_LOCALITY": "KOPRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3518,
      "City District Name": "JALAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285224,
      "TXT_PINCODE_LOCALITY": "JALAUN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3518,
      "City District Name": "JALAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285231,
      "TXT_PINCODE_LOCALITY": "NIVARI.",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3707,
      "City District Name": "KALPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285232,
      "TXT_PINCODE_LOCALITY": "JALAUN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3518,
      "City District Name": "JALAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285233,
      "TXT_PINCODE_LOCALITY": "JALAUN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3518,
      "City District Name": "JALAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285241,
      "TXT_PINCODE_LOCALITY": "BABINA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 360,
      "City District Name": "JHANSI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285242,
      "TXT_PINCODE_LOCALITY": "JALAUN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3518,
      "City District Name": "JALAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285243,
      "TXT_PINCODE_LOCALITY": "JALAUN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3518,
      "City District Name": "JALAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285251,
      "TXT_PINCODE_LOCALITY": "KAILIYA S.O",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3518,
      "City District Name": "JALAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285252,
      "TXT_PINCODE_LOCALITY": "JALAUN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3518,
      "City District Name": "JALAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285323,
      "TXT_PINCODE_LOCALITY": "MIRZAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4286,
      "City District Name": "MADHOGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 285428,
      "TXT_PINCODE_LOCALITY": "TARSAUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3518,
      "City District Name": "JALAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 286206,
      "TXT_PINCODE_LOCALITY": "THIPRAI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4566,
      "City District Name": "MOTH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 286424,
      "TXT_PINCODE_LOCALITY": "KANAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4286,
      "City District Name": "MADHOGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 286508,
      "TXT_PINCODE_LOCALITY": "DANG KHAJURI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4055,
      "City District Name": "KONCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 288114,
      "TXT_PINCODE_LOCALITY": "NAWALI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 650,
      "City District Name": "BAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 288123,
      "TXT_PINCODE_LOCALITY": "KHANKRI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3707,
      "City District Name": "KALPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 288201,
      "TXT_PINCODE_LOCALITY": "SIHORGARH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3118,
      "City District Name": "ETMADPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 289440,
      "TXT_PINCODE_LOCALITY": "NADIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 289505,
      "TXT_PINCODE_LOCALITY": "NADIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 289518,
      "TXT_PINCODE_LOCALITY": "24 PARGANAS",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1353,
      "City District Name": "24 PARGANAS S",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 300019,
      "TXT_PINCODE_LOCALITY": "BHIWADI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301001,
      "TXT_PINCODE_LOCALITY": "CHANDOLI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301002,
      "TXT_PINCODE_LOCALITY": "ALWAR B.O",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301011,
      "TXT_PINCODE_LOCALITY": "BHIWADI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301015,
      "TXT_PINCODE_LOCALITY": "BHIWADI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301018,
      "TXT_PINCODE_LOCALITY": "SILARPUR B.O",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301019,
      "TXT_PINCODE_LOCALITY": "BHIWARI INDUSTRIAL AREA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301020,
      "TXT_PINCODE_LOCALITY": "BARROD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301021,
      "TXT_PINCODE_LOCALITY": "BARODAMEO",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301022,
      "TXT_PINCODE_LOCALITY": "GHAZI KA THANA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301023,
      "TXT_PINCODE_LOCALITY": "ITARNA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301024,
      "TXT_PINCODE_LOCALITY": "NARAINPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301025,
      "TXT_PINCODE_LOCALITY": "NOWGAON",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301026,
      "TXT_PINCODE_LOCALITY": "RAMGARH BUS STAND",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301027,
      "TXT_PINCODE_LOCALITY": "AJABGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301028,
      "TXT_PINCODE_LOCALITY": "BAHADARPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301029,
      "TXT_PINCODE_LOCALITY": "DARIBA PROJECT ALWAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301030,
      "TXT_PINCODE_LOCALITY": "DABUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301031,
      "TXT_PINCODE_LOCALITY": "ALWAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301032,
      "TXT_PINCODE_LOCALITY": "ALWAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301033,
      "TXT_PINCODE_LOCALITY": "ALWAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301034,
      "TXT_PINCODE_LOCALITY": "ALWAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301035,
      "TXT_PINCODE_LOCALITY": "RONIJA THAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 858,
      "City District Name": "DAUSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301036,
      "TXT_PINCODE_LOCALITY": "ALWAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301037,
      "TXT_PINCODE_LOCALITY": "ALWAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301042,
      "TXT_PINCODE_LOCALITY": "ALWAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301043,
      "TXT_PINCODE_LOCALITY": "ALWAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301044,
      "TXT_PINCODE_LOCALITY": "ALWAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301401,
      "TXT_PINCODE_LOCALITY": "AJERKA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301402,
      "TXT_PINCODE_LOCALITY": "BANSUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301403,
      "TXT_PINCODE_LOCALITY": "HARSOLI ALWAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301404,
      "TXT_PINCODE_LOCALITY": "KHAIRTHAL MANDI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301405,
      "TXT_PINCODE_LOCALITY": "KISHANGARH BAS",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301406,
      "TXT_PINCODE_LOCALITY": "MALAKHERA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301407,
      "TXT_PINCODE_LOCALITY": "MANDAWAR ALWAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 858,
      "City District Name": "DAUSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301408,
      "TXT_PINCODE_LOCALITY": "RAJGARH R S",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301409,
      "TXT_PINCODE_LOCALITY": "BHUDA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301410,
      "TXT_PINCODE_LOCALITY": "TEHLA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301411,
      "TXT_PINCODE_LOCALITY": "TIJARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301412,
      "TXT_PINCODE_LOCALITY": "HARSORA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301413,
      "TXT_PINCODE_LOCALITY": "GARHI SAWAIRAM",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301414,
      "TXT_PINCODE_LOCALITY": "DHIGAWADA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301415,
      "TXT_PINCODE_LOCALITY": "ALWAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301416,
      "TXT_PINCODE_LOCALITY": "SAKET",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301417,
      "TXT_PINCODE_LOCALITY": "ALWAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301418,
      "TXT_PINCODE_LOCALITY": "RAMPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301419,
      "TXT_PINCODE_LOCALITY": "MACHHERI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301420,
      "TXT_PINCODE_LOCALITY": "RAJPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301421,
      "TXT_PINCODE_LOCALITY": "ISMAILPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301422,
      "TXT_PINCODE_LOCALITY": "SHAHBAD ALWAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301423,
      "TXT_PINCODE_LOCALITY": "ALWAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301424,
      "TXT_PINCODE_LOCALITY": "TATARPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301425,
      "TXT_PINCODE_LOCALITY": "KHERA MANGALSINGH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301426,
      "TXT_PINCODE_LOCALITY": "HAMIRPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301427,
      "TXT_PINCODE_LOCALITY": "KARNIKOT",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301604,
      "TXT_PINCODE_LOCALITY": "GOVIND GARH ALWAR S.O",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301701,
      "TXT_PINCODE_LOCALITY": "BAHROR BUS STAND",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301702,
      "TXT_PINCODE_LOCALITY": "KOT KASIM",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301703,
      "TXT_PINCODE_LOCALITY": "MAJRI KALAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301704,
      "TXT_PINCODE_LOCALITY": "MANDHAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301705,
      "TXT_PINCODE_LOCALITY": "NEEMRAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301706,
      "TXT_PINCODE_LOCALITY": "SHAHAHANPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301707,
      "TXT_PINCODE_LOCALITY": "BHONKAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301708,
      "TXT_PINCODE_LOCALITY": "KUTINA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301709,
      "TXT_PINCODE_LOCALITY": "GANDALA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301710,
      "TXT_PINCODE_LOCALITY": "PARTAPUR ALWAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301711,
      "TXT_PINCODE_LOCALITY": "ALWAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301712,
      "TXT_PINCODE_LOCALITY": "BAGHERI KALAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301713,
      "TXT_PINCODE_LOCALITY": "JAKHARANA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 301714,
      "TXT_PINCODE_LOCALITY": "BAHROR JAT",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302001,
      "TXT_PINCODE_LOCALITY": "KHASA KITHI CROSSING",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302002,
      "TXT_PINCODE_LOCALITY": "MOTI KATLA BAZAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302003,
      "TXT_PINCODE_LOCALITY": "SABJI MANDI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302004,
      "TXT_PINCODE_LOCALITY": "S M S HOSPITAL",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302005,
      "TXT_PINCODE_LOCALITY": "RAMBAGH PALACE",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302006,
      "TXT_PINCODE_LOCALITY": "COLLECTORATE",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302007,
      "TXT_PINCODE_LOCALITY": "P M GS OFFICE",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302008,
      "TXT_PINCODE_LOCALITY": "JAIPUR B.O",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302009,
      "TXT_PINCODE_LOCALITY": "JAIPUR 9 D E TS OFFICE",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302010,
      "TXT_PINCODE_LOCALITY": "JAIPUR B.O",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302011,
      "TXT_PINCODE_LOCALITY": "SANGANER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302012,
      "TXT_PINCODE_LOCALITY": "JHOTWARA INDUSTRIAL AREA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302013,
      "TXT_PINCODE_LOCALITY": "VKI AREA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302014,
      "TXT_PINCODE_LOCALITY": "JAIPUR 14 DDAPOSTAL JAI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302015,
      "TXT_PINCODE_LOCALITY": "S D M HOSPITAL",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302016,
      "TXT_PINCODE_LOCALITY": "DWARKA PURI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302017,
      "TXT_PINCODE_LOCALITY": "JAIPUR 17 BAJAJ NAGAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302018,
      "TXT_PINCODE_LOCALITY": "TONK ROAD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302019,
      "TXT_PINCODE_LOCALITY": "CIVIL LINES",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302020,
      "TXT_PINCODE_LOCALITY": "MANSAROVER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302021,
      "TXT_PINCODE_LOCALITY": "BEDHA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302022,
      "TXT_PINCODE_LOCALITY": "SITAPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302023,
      "TXT_PINCODE_LOCALITY": "VISHWAKARMA INDUSTRIAL ESTATE",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302024,
      "TXT_PINCODE_LOCALITY": "KESHOPURA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302025,
      "TXT_PINCODE_LOCALITY": "JAGATPURA S.O",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302026,
      "TXT_PINCODE_LOCALITY": "AJMER ROAD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302027,
      "TXT_PINCODE_LOCALITY": "CRPF CAMPUS LALWAS S.O",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302028,
      "TXT_PINCODE_LOCALITY": "KUKAS",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302029,
      "TXT_PINCODE_LOCALITY": "SANGANER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302030,
      "TXT_PINCODE_LOCALITY": "JAIPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302031,
      "TXT_PINCODE_LOCALITY": "GULAB VIHAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302032,
      "TXT_PINCODE_LOCALITY": "JAIPUR.",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302033,
      "TXT_PINCODE_LOCALITY": "PRATAP NAGAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302034,
      "TXT_PINCODE_LOCALITY": "PANCHYAWALA S.O",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302035,
      "TXT_PINCODE_LOCALITY": "NARAYAN VIHAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302036,
      "TXT_PINCODE_LOCALITY": "JAISINGHPURA KHORE S.O",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302037,
      "TXT_PINCODE_LOCALITY": "MAHINDRA WORLD CITY (JAIPUR)",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302038,
      "TXT_PINCODE_LOCALITY": "JAIPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302039,
      "TXT_PINCODE_LOCALITY": "JAIPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302040,
      "TXT_PINCODE_LOCALITY": "PAWANPURI COLONY",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302041,
      "TXT_PINCODE_LOCALITY": "MUNDIA RAMPURA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302042,
      "TXT_PINCODE_LOCALITY": "BAD KE BALAJI S.O",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302043,
      "TXT_PINCODE_LOCALITY": "JAIPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302205,
      "TXT_PINCODE_LOCALITY": "JHALANA DONGARI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 442965,
      "City District Name": "SANGANER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302411,
      "TXT_PINCODE_LOCALITY": "MORADABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 764,
      "City District Name": "MORADABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302622,
      "TXT_PINCODE_LOCALITY": "CHAUPANERI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302902,
      "TXT_PINCODE_LOCALITY": "SANGANER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 442965,
      "City District Name": "SANGANER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303001,
      "TXT_PINCODE_LOCALITY": "ANDHI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303002,
      "TXT_PINCODE_LOCALITY": "ACHROL",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303003,
      "TXT_PINCODE_LOCALITY": "MAID",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303004,
      "TXT_PINCODE_LOCALITY": "LAWAIN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303005,
      "TXT_PINCODE_LOCALITY": "PHAGI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303006,
      "TXT_PINCODE_LOCALITY": "MADHORAJPURA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303007,
      "TXT_PINCODE_LOCALITY": "BAGRU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303008,
      "TXT_PINCODE_LOCALITY": "DUDU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303009,
      "TXT_PINCODE_LOCALITY": "MOZAMABAD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303010,
      "TXT_PINCODE_LOCALITY": "BICHOON",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303011,
      "TXT_PINCODE_LOCALITY": "BHANKROTA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 858,
      "City District Name": "DAUSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303012,
      "TXT_PINCODE_LOCALITY": "BURTHAL",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303101,
      "TXT_PINCODE_LOCALITY": "AMER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303102,
      "TXT_PINCODE_LOCALITY": "VIRATNAGAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303103,
      "TXT_PINCODE_LOCALITY": "JAIPUR H O",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303104,
      "TXT_PINCODE_LOCALITY": "MANOHARPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303105,
      "TXT_PINCODE_LOCALITY": "NAREHEDA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303106,
      "TXT_PINCODE_LOCALITY": "PAOTA JAIPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303107,
      "TXT_PINCODE_LOCALITY": "PRAGPURA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303108,
      "TXT_PINCODE_LOCALITY": "KOTPUTLI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303109,
      "TXT_PINCODE_LOCALITY": "BANDHA JAMWARAMGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303110,
      "TXT_PINCODE_LOCALITY": "RAJNOTA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303111,
      "TXT_PINCODE_LOCALITY": "ANTELA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303112,
      "TXT_PINCODE_LOCALITY": "SAIWAD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303113,
      "TXT_PINCODE_LOCALITY": "RAMGARH ROAD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303114,
      "TXT_PINCODE_LOCALITY": "KANSLI JAIPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303115,
      "TXT_PINCODE_LOCALITY": "GORDHANPURA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303116,
      "TXT_PINCODE_LOCALITY": "KALYANPURA KHURD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303117,
      "TXT_PINCODE_LOCALITY": "KHORI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303118,
      "TXT_PINCODE_LOCALITY": "JAISINGHPURA KHOR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303119,
      "TXT_PINCODE_LOCALITY": "BHABHRU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303120,
      "TXT_PINCODE_LOCALITY": "BEELWARI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303121,
      "TXT_PINCODE_LOCALITY": "NIMS UNIVERSITY",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303122,
      "TXT_PINCODE_LOCALITY": "DEWALA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 442963,
      "City District Name": "MAUZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303123,
      "TXT_PINCODE_LOCALITY": "JAIPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303125,
      "TXT_PINCODE_LOCALITY": "ALWAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303209,
      "TXT_PINCODE_LOCALITY": "RABUPURA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303301,
      "TXT_PINCODE_LOCALITY": "CHANWANDIA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303302,
      "TXT_PINCODE_LOCALITY": "TUNGA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303303,
      "TXT_PINCODE_LOCALITY": "DAUSA MANDI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303304,
      "TXT_PINCODE_LOCALITY": "DUBBI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 858,
      "City District Name": "DAUSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303305,
      "TXT_PINCODE_LOCALITY": "BANSKHO",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 858,
      "City District Name": "DAUSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303306,
      "TXT_PINCODE_LOCALITY": "BHANKROTA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303307,
      "TXT_PINCODE_LOCALITY": "NANGAL BERSI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 858,
      "City District Name": "DAUSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303308,
      "TXT_PINCODE_LOCALITY": "ALOODA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 858,
      "City District Name": "DAUSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303309,
      "TXT_PINCODE_LOCALITY": "DHAGARIYA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 858,
      "City District Name": "DAUSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303310,
      "TXT_PINCODE_LOCALITY": "KOLWA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 858,
      "City District Name": "DAUSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303313,
      "TXT_PINCODE_LOCALITY": "DAUSA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303314,
      "TXT_PINCODE_LOCALITY": "SAYALAWAS KALAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 858,
      "City District Name": "DAUSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303315,
      "TXT_PINCODE_LOCALITY": "BEWAI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 858,
      "City District Name": "DAUSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303319,
      "TXT_PINCODE_LOCALITY": "BHABROO *",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303323,
      "TXT_PINCODE_LOCALITY": "GUDHA KATLA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 858,
      "City District Name": "DAUSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303324,
      "TXT_PINCODE_LOCALITY": "GUDHLIA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303325,
      "TXT_PINCODE_LOCALITY": "KUNDAL",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 858,
      "City District Name": "DAUSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303326,
      "TXT_PINCODE_LOCALITY": "SIKANDRA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 858,
      "City District Name": "DAUSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303327,
      "TXT_PINCODE_LOCALITY": "BASWA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 858,
      "City District Name": "DAUSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303328,
      "TXT_PINCODE_LOCALITY": "BHADWA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303329,
      "TXT_PINCODE_LOCALITY": "AGRI COLLEGE JOBNER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303330,
      "TXT_PINCODE_LOCALITY": "DHANI BORAJ",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303331,
      "TXT_PINCODE_LOCALITY": "ASALPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303338,
      "TXT_PINCODE_LOCALITY": "PHALERA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303339,
      "TXT_PINCODE_LOCALITY": "MANDHA BHIMSINGH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303340,
      "TXT_PINCODE_LOCALITY": "BHAINSLANA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303341,
      "TXT_PINCODE_LOCALITY": "KACHRODA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303348,
      "TXT_PINCODE_LOCALITY": "NARAINA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303349,
      "TXT_PINCODE_LOCALITY": "SAKHUN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303501,
      "TXT_PINCODE_LOCALITY": "BHANDAREJ",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 858,
      "City District Name": "DAUSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303502,
      "TXT_PINCODE_LOCALITY": "GEEJGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 858,
      "City District Name": "DAUSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303503,
      "TXT_PINCODE_LOCALITY": "LALSOT",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 858,
      "City District Name": "DAUSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303504,
      "TXT_PINCODE_LOCALITY": "BINORI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303505,
      "TXT_PINCODE_LOCALITY": "NANGAL RAJWATAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 858,
      "City District Name": "DAUSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303506,
      "TXT_PINCODE_LOCALITY": "PAPARDA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 858,
      "City District Name": "DAUSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303507,
      "TXT_PINCODE_LOCALITY": "BAPI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303508,
      "TXT_PINCODE_LOCALITY": "SIKRAI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 858,
      "City District Name": "DAUSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303509,
      "TXT_PINCODE_LOCALITY": "MANPUR SIKRAI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 858,
      "City District Name": "DAUSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303510,
      "TXT_PINCODE_LOCALITY": "RAMGARH PACHWARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 858,
      "City District Name": "DAUSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303511,
      "TXT_PINCODE_LOCALITY": "DIDWANA DAUSA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 858,
      "City District Name": "DAUSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303601,
      "TXT_PINCODE_LOCALITY": "AMARSAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303602,
      "TXT_PINCODE_LOCALITY": "LUNIYAWAS",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303603,
      "TXT_PINCODE_LOCALITY": "BAGAWAS",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303604,
      "TXT_PINCODE_LOCALITY": "SAMBHAR LAKE",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303606,
      "TXT_PINCODE_LOCALITY": "KARANSAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303607,
      "TXT_PINCODE_LOCALITY": "SURMALIKPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303608,
      "TXT_PINCODE_LOCALITY": "JAIPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303609,
      "TXT_PINCODE_LOCALITY": "DHAMANA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303610,
      "TXT_PINCODE_LOCALITY": "NAGAUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303611,
      "TXT_PINCODE_LOCALITY": "JAIPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303701,
      "TXT_PINCODE_LOCALITY": "JAHOTA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303702,
      "TXT_PINCODE_LOCALITY": "CHOMU TRIPOLIA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303703,
      "TXT_PINCODE_LOCALITY": "TANKARDA S.O",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303704,
      "TXT_PINCODE_LOCALITY": "ANANTPURA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303705,
      "TXT_PINCODE_LOCALITY": "HADOTA S. O",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 858,
      "City District Name": "DAUSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303706,
      "TXT_PINCODE_LOCALITY": "BHAMORI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3800,
      "City District Name": "KARAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303712,
      "TXT_PINCODE_LOCALITY": "GOVINDGARH MALIKPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303801,
      "TXT_PINCODE_LOCALITY": "KALADERA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303802,
      "TXT_PINCODE_LOCALITY": "KHADIBAG",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303803,
      "TXT_PINCODE_LOCALITY": "KHEROLI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303804,
      "TXT_PINCODE_LOCALITY": "JAIPUR B.O",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303805,
      "TXT_PINCODE_LOCALITY": "MORIZA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303806,
      "TXT_PINCODE_LOCALITY": "BANSA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303807,
      "TXT_PINCODE_LOCALITY": "UDAIPURIA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 858,
      "City District Name": "DAUSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303901,
      "TXT_PINCODE_LOCALITY": "AKODIYA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303902,
      "TXT_PINCODE_LOCALITY": "SANGANER BAZAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303903,
      "TXT_PINCODE_LOCALITY": "SHEODASPURA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303904,
      "TXT_PINCODE_LOCALITY": "CHITTORA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303905,
      "TXT_PINCODE_LOCALITY": "WATIKA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303906,
      "TXT_PINCODE_LOCALITY": "BADA PADAMPURA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 858,
      "City District Name": "DAUSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303907,
      "TXT_PINCODE_LOCALITY": "CHANDLAI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303908,
      "TXT_PINCODE_LOCALITY": "ROOPAHEDI KHURD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 304001,
      "TXT_PINCODE_LOCALITY": "C T TONK",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 850,
      "City District Name": "TONK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 304020,
      "TXT_PINCODE_LOCALITY": "NEWAI R S",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 850,
      "City District Name": "TONK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 304021,
      "TXT_PINCODE_LOCALITY": "NEWAI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 850,
      "City District Name": "TONK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 304022,
      "TXT_PINCODE_LOCALITY": "BANASTHALI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 850,
      "City District Name": "TONK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 304023,
      "TXT_PINCODE_LOCALITY": "ALIGARH TONK",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 850,
      "City District Name": "TONK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 304024,
      "TXT_PINCODE_LOCALITY": "UNIARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 850,
      "City District Name": "TONK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 304025,
      "TXT_PINCODE_LOCALITY": "JHILAI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 850,
      "City District Name": "TONK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 304026,
      "TXT_PINCODE_LOCALITY": "SIRAS TONK",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 850,
      "City District Name": "TONK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 304230,
      "TXT_PINCODE_LOCALITY": "SURAT",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 304501,
      "TXT_PINCODE_LOCALITY": "AVIKA NAGAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 850,
      "City District Name": "TONK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 304502,
      "TXT_PINCODE_LOCALITY": "MALPURA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 850,
      "City District Name": "TONK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 304503,
      "TXT_PINCODE_LOCALITY": "LAMBA HARI SINGH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 850,
      "City District Name": "TONK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 304504,
      "TXT_PINCODE_LOCALITY": "LAWA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 850,
      "City District Name": "TONK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 304505,
      "TXT_PINCODE_LOCALITY": "TODA RAISINGH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 850,
      "City District Name": "TONK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 304506,
      "TXT_PINCODE_LOCALITY": "TONK",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 850,
      "City District Name": "TONK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 304507,
      "TXT_PINCODE_LOCALITY": "NASIRDA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 850,
      "City District Name": "TONK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 304508,
      "TXT_PINCODE_LOCALITY": "CHANDSEN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 850,
      "City District Name": "TONK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 304509,
      "TXT_PINCODE_LOCALITY": "PACHEWAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 850,
      "City District Name": "TONK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 304510,
      "TXT_PINCODE_LOCALITY": "PANWALIA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 850,
      "City District Name": "TONK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 304511,
      "TXT_PINCODE_LOCALITY": "TORDISAGAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 850,
      "City District Name": "TONK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 304801,
      "TXT_PINCODE_LOCALITY": "PEEPLU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 850,
      "City District Name": "TONK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 304802,
      "TXT_PINCODE_LOCALITY": "DUNI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 850,
      "City District Name": "TONK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 304803,
      "TXT_PINCODE_LOCALITY": "PANWAR TONK",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 850,
      "City District Name": "TONK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 304804,
      "TXT_PINCODE_LOCALITY": "DEOLI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 850,
      "City District Name": "TONK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 304805,
      "TXT_PINCODE_LOCALITY": "MEHANDWAS",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 850,
      "City District Name": "TONK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 304806,
      "TXT_PINCODE_LOCALITY": "DHUNWA KALLAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 850,
      "City District Name": "TONK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 304807,
      "TXT_PINCODE_LOCALITY": "RAJMAHAL",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 850,
      "City District Name": "TONK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305001,
      "TXT_PINCODE_LOCALITY": "SHASTRINAGAR AJMER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305002,
      "TXT_PINCODE_LOCALITY": "I E MAKHUPURA AJMER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305003,
      "TXT_PINCODE_LOCALITY": "H M T AJMER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305004,
      "TXT_PINCODE_LOCALITY": "REGIONAL COLLEGE AJMER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305005,
      "TXT_PINCODE_LOCALITY": "CRPF AJMER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305006,
      "TXT_PINCODE_LOCALITY": "ASHOK MARG AJMER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305007,
      "TXT_PINCODE_LOCALITY": "G C ROAD AJMER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305008,
      "TXT_PINCODE_LOCALITY": "MSS HATUNDI AJMER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305009,
      "TXT_PINCODE_LOCALITY": "MDS UNIVERSITY AJMER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305011,
      "TXT_PINCODE_LOCALITY": "SAMBHOOGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305012,
      "TXT_PINCODE_LOCALITY": "AJMER M.S.S. HATUNDI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305020,
      "TXT_PINCODE_LOCALITY": "RAJASTHAN UNIVERSITY.",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305021,
      "TXT_PINCODE_LOCALITY": "KAREL",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305022,
      "TXT_PINCODE_LOCALITY": "PUSHKAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305023,
      "TXT_PINCODE_LOCALITY": "GAGWANA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305024,
      "TXT_PINCODE_LOCALITY": "MADAR AJMER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305025,
      "TXT_PINCODE_LOCALITY": "SRINAGAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305026,
      "TXT_PINCODE_LOCALITY": "THANWLA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305201,
      "TXT_PINCODE_LOCALITY": "GOVINDGARH AJMER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305202,
      "TXT_PINCODE_LOCALITY": "KHARWA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305203,
      "TXT_PINCODE_LOCALITY": "MANGLIAWAS",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305204,
      "TXT_PINCODE_LOCALITY": "PISANGAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305205,
      "TXT_PINCODE_LOCALITY": "RAJGARH AJMER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305206,
      "TXT_PINCODE_LOCALITY": "SARADHANA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305207,
      "TXT_PINCODE_LOCALITY": "JETHANA AJMER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305401,
      "TXT_PINCODE_LOCALITY": "BHAWANI KHERA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305402,
      "TXT_PINCODE_LOCALITY": "RAMSAR AJMER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305403,
      "TXT_PINCODE_LOCALITY": "SARWAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305404,
      "TXT_PINCODE_LOCALITY": "KEKRI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305405,
      "TXT_PINCODE_LOCALITY": "KADERA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305406,
      "TXT_PINCODE_LOCALITY": "JUNIA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305407,
      "TXT_PINCODE_LOCALITY": "SAWAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305408,
      "TXT_PINCODE_LOCALITY": "KALERA KRISHAN GOPAL",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305409,
      "TXT_PINCODE_LOCALITY": "JAHAZPUR MANDI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305410,
      "TXT_PINCODE_LOCALITY": "PANDER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305411,
      "TXT_PINCODE_LOCALITY": "PIPLUND",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305412,
      "TXT_PINCODE_LOCALITY": "FATEHGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305413,
      "TXT_PINCODE_LOCALITY": "BHILWARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305414,
      "TXT_PINCODE_LOCALITY": "SHAKARGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305415,
      "TXT_PINCODE_LOCALITY": "BAGHERA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305416,
      "TXT_PINCODE_LOCALITY": "ROPAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305601,
      "TXT_PINCODE_LOCALITY": "NASIRABAD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305621,
      "TXT_PINCODE_LOCALITY": "BANDANWARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305622,
      "TXT_PINCODE_LOCALITY": "BHINAI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305623,
      "TXT_PINCODE_LOCALITY": "DHOLA BHATA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305624,
      "TXT_PINCODE_LOCALITY": "BIJAINAGAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305625,
      "TXT_PINCODE_LOCALITY": "JALIA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305626,
      "TXT_PINCODE_LOCALITY": "RUPAHELI KALAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305627,
      "TXT_PINCODE_LOCALITY": "TANTOTI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305628,
      "TXT_PINCODE_LOCALITY": "CHMAPANERI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305629,
      "TXT_PINCODE_LOCALITY": "CHAMPANERI S.O",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305630,
      "TXT_PINCODE_LOCALITY": "SANTHALIYA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 442962,
      "City District Name": "MASUDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305631,
      "TXT_PINCODE_LOCALITY": "AJMER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305801,
      "TXT_PINCODE_LOCALITY": "MADANGANJ BAZAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305802,
      "TXT_PINCODE_LOCALITY": "KISHANGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305804,
      "TXT_PINCODE_LOCALITY": "NAWA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305811,
      "TXT_PINCODE_LOCALITY": "KUCHIL",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305812,
      "TXT_PINCODE_LOCALITY": "HARMARA AJMER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305813,
      "TXT_PINCODE_LOCALITY": "ARAIN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305814,
      "TXT_PINCODE_LOCALITY": "RUPNAGAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305815,
      "TXT_PINCODE_LOCALITY": "SALEMBAD AJMER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305816,
      "TXT_PINCODE_LOCALITY": "TILONIA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305817,
      "TXT_PINCODE_LOCALITY": "AJMER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305818,
      "TXT_PINCODE_LOCALITY": "BABAICHA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305819,
      "TXT_PINCODE_LOCALITY": "KARKERI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305820,
      "TXT_PINCODE_LOCALITY": "SURSURA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305901,
      "TXT_PINCODE_LOCALITY": "BEAWAR SHAHPURA MOHALLAH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305912,
      "TXT_PINCODE_LOCALITY": "TAGATGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305921,
      "TXT_PINCODE_LOCALITY": "BHIM",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 896,
      "City District Name": "RAJSAMAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305922,
      "TXT_PINCODE_LOCALITY": "JAWAJA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305923,
      "TXT_PINCODE_LOCALITY": "LOTIYANA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305924,
      "TXT_PINCODE_LOCALITY": "TODGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305925,
      "TXT_PINCODE_LOCALITY": "RAJIAWAS",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305926,
      "TXT_PINCODE_LOCALITY": "BARAKHERA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305927,
      "TXT_PINCODE_LOCALITY": "KABRA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 305928,
      "TXT_PINCODE_LOCALITY": "BALIJASSA KHERA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306001,
      "TXT_PINCODE_LOCALITY": "MARWAR JN H O",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306002,
      "TXT_PINCODE_LOCALITY": "BHAGORA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306021,
      "TXT_PINCODE_LOCALITY": "AUWA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306022,
      "TXT_PINCODE_LOCALITY": "JOJAWAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306023,
      "TXT_PINCODE_LOCALITY": "RANAWAS",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306024,
      "TXT_PINCODE_LOCALITY": "DEOLI AUWA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306025,
      "TXT_PINCODE_LOCALITY": "DHANLA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306026,
      "TXT_PINCODE_LOCALITY": "ISALI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306027,
      "TXT_PINCODE_LOCALITY": "SIRIYARI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306028,
      "TXT_PINCODE_LOCALITY": "RAMSINGHJI KA GUDA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306101,
      "TXT_PINCODE_LOCALITY": "BABRA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306102,
      "TXT_PINCODE_LOCALITY": "SENDRA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306103,
      "TXT_PINCODE_LOCALITY": "SOJAT ROAD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306104,
      "TXT_PINCODE_LOCALITY": "SOJAT INDUSTRIAL ESTATE",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306105,
      "TXT_PINCODE_LOCALITY": "BAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306106,
      "TXT_PINCODE_LOCALITY": "KANTALIA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306107,
      "TXT_PINCODE_LOCALITY": "RAS",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306108,
      "TXT_PINCODE_LOCALITY": "SUMEL",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306114,
      "TXT_PINCODE_LOCALITY": "BAGRINAGAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306115,
      "TXT_PINCODE_LOCALITY": "RANI SADAR BAZAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306116,
      "TXT_PINCODE_LOCALITY": "FALNA INDUSTRIAL ESTATE",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306117,
      "TXT_PINCODE_LOCALITY": "JAWALI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306118,
      "TXT_PINCODE_LOCALITY": "DHANI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306119,
      "TXT_PINCODE_LOCALITY": "KHOD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306126,
      "TXT_PINCODE_LOCALITY": "JAWAI BANDH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306127,
      "TXT_PINCODE_LOCALITY": "BISHALPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306128,
      "TXT_PINCODE_LOCALITY": "BEDA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306301,
      "TXT_PINCODE_LOCALITY": "ANANDPUR KALU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306302,
      "TXT_PINCODE_LOCALITY": "JAITARAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306303,
      "TXT_PINCODE_LOCALITY": "NIMAJ",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306304,
      "TXT_PINCODE_LOCALITY": "MADHOPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306305,
      "TXT_PINCODE_LOCALITY": "KUSHALPURA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306306,
      "TXT_PINCODE_LOCALITY": "CHANDAWAL",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306307,
      "TXT_PINCODE_LOCALITY": "PIPLIA KALAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306308,
      "TXT_PINCODE_LOCALITY": "BALOONDA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306309,
      "TXT_PINCODE_LOCALITY": "DEOLI KALLAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306310,
      "TXT_PINCODE_LOCALITY": "JHUNTA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306401,
      "TXT_PINCODE_LOCALITY": "UDYOGNAGAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306421,
      "TXT_PINCODE_LOCALITY": "ROHAT",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306422,
      "TXT_PINCODE_LOCALITY": "GUNDOJ",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306501,
      "TXT_PINCODE_LOCALITY": "DHAMLI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306502,
      "TXT_PINCODE_LOCALITY": "KHINWARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306503,
      "TXT_PINCODE_LOCALITY": "BHADARLAU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306504,
      "TXT_PINCODE_LOCALITY": "NANA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306505,
      "TXT_PINCODE_LOCALITY": "PALI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306601,
      "TXT_PINCODE_LOCALITY": "BIJOWA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306602,
      "TXT_PINCODE_LOCALITY": "CHANAUD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306603,
      "TXT_PINCODE_LOCALITY": "NADOL",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306701,
      "TXT_PINCODE_LOCALITY": "BALI KACHERI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306702,
      "TXT_PINCODE_LOCALITY": "SADRI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306703,
      "TXT_PINCODE_LOCALITY": "DESURI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306704,
      "TXT_PINCODE_LOCALITY": "GHANERAO",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306705,
      "TXT_PINCODE_LOCALITY": "MUNDARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306706,
      "TXT_PINCODE_LOCALITY": "LUNAWA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306707,
      "TXT_PINCODE_LOCALITY": "SEWARI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306708,
      "TXT_PINCODE_LOCALITY": "SANDERAO",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306709,
      "TXT_PINCODE_LOCALITY": "RANAKPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306710,
      "TXT_PINCODE_LOCALITY": "PALI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306901,
      "TXT_PINCODE_LOCALITY": "BANKLI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306902,
      "TXT_PINCODE_LOCALITY": "UPAJ MANDI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306912,
      "TXT_PINCODE_LOCALITY": "TAGATGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1809,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 306913,
      "TXT_PINCODE_LOCALITY": "CHANDRAI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3525,
      "City District Name": "JALOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 307001,
      "TXT_PINCODE_LOCALITY": "SIROHI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 933,
      "City District Name": "SIROHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 307019,
      "TXT_PINCODE_LOCALITY": "JAYKAYPURAM",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 933,
      "City District Name": "SIROHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 307020,
      "TXT_PINCODE_LOCALITY": "SIROHI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 933,
      "City District Name": "SIROHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 307021,
      "TXT_PINCODE_LOCALITY": "SIROHI ROAD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 933,
      "City District Name": "SIROHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 307022,
      "TXT_PINCODE_LOCALITY": "JHADOLI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 933,
      "City District Name": "SIROHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 307023,
      "TXT_PINCODE_LOCALITY": "SARUPGANJ",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 933,
      "City District Name": "SIROHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 307024,
      "TXT_PINCODE_LOCALITY": "ROHIDA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 933,
      "City District Name": "SIROHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 307025,
      "TXT_PINCODE_LOCALITY": "KOTRA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 307026,
      "TXT_PINCODE_LOCALITY": "ABU ROAD GANDHINAGAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 933,
      "City District Name": "SIROHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 307027,
      "TXT_PINCODE_LOCALITY": "SABZI MANDI SHEOGANJ",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 933,
      "City District Name": "SIROHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 307028,
      "TXT_PINCODE_LOCALITY": "POSALIA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 933,
      "City District Name": "SIROHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 307029,
      "TXT_PINCODE_LOCALITY": "BHADRAJUN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3525,
      "City District Name": "JALOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 307030,
      "TXT_PINCODE_LOCALITY": "GUDA BALOTRA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3525,
      "City District Name": "JALOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 307031,
      "TXT_PINCODE_LOCALITY": "PINDWARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 933,
      "City District Name": "SIROHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 307032,
      "TXT_PINCODE_LOCALITY": "BHAINSWARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3525,
      "City District Name": "JALOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 307033,
      "TXT_PINCODE_LOCALITY": "JALORE",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1229,
      "City District Name": "JALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 307034,
      "TXT_PINCODE_LOCALITY": "JALORE",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1229,
      "City District Name": "JALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 307035,
      "TXT_PINCODE_LOCALITY": "JALORE",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1229,
      "City District Name": "JALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 307036,
      "TXT_PINCODE_LOCALITY": "JALORE",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1229,
      "City District Name": "JALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 307038,
      "TXT_PINCODE_LOCALITY": "MERPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 933,
      "City District Name": "SIROHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 307039,
      "TXT_PINCODE_LOCALITY": "BEKARIA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 933,
      "City District Name": "SIROHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 307040,
      "TXT_PINCODE_LOCALITY": "JALORE",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1229,
      "City District Name": "JALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 307041,
      "TXT_PINCODE_LOCALITY": "BHANWARI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 933,
      "City District Name": "SIROHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 307042,
      "TXT_PINCODE_LOCALITY": "NANDIA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 933,
      "City District Name": "SIROHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 307043,
      "TXT_PINCODE_LOCALITY": "PALRI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 933,
      "City District Name": "SIROHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 307044,
      "TXT_PINCODE_LOCALITY": "SIROHI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 933,
      "City District Name": "SIROHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 307045,
      "TXT_PINCODE_LOCALITY": "HARJI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 933,
      "City District Name": "SIROHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 307501,
      "TXT_PINCODE_LOCALITY": "ABU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 933,
      "City District Name": "SIROHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 307510,
      "TXT_PINCODE_LOCALITY": "SHANTIVAN S.O",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 933,
      "City District Name": "SIROHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 307511,
      "TXT_PINCODE_LOCALITY": "SIROHI ROAD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 933,
      "City District Name": "SIROHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 307512,
      "TXT_PINCODE_LOCALITY": "DANTRAI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 933,
      "City District Name": "SIROHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 307513,
      "TXT_PINCODE_LOCALITY": "MANDAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 933,
      "City District Name": "SIROHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 307514,
      "TXT_PINCODE_LOCALITY": "REODAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 933,
      "City District Name": "SIROHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 307515,
      "TXT_PINCODE_LOCALITY": "JASWANTPURA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3525,
      "City District Name": "JALOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 307801,
      "TXT_PINCODE_LOCALITY": "JAWAL",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 933,
      "City District Name": "SIROHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 307802,
      "TXT_PINCODE_LOCALITY": "KALANDARI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 933,
      "City District Name": "SIROHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 307803,
      "TXT_PINCODE_LOCALITY": "RAMSEN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3525,
      "City District Name": "JALOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 307804,
      "TXT_PINCODE_LOCALITY": "SIROHI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 933,
      "City District Name": "SIROHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 310029,
      "TXT_PINCODE_LOCALITY": "BHARAI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 442964,
      "City District Name": "RAILMAGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311001,
      "TXT_PINCODE_LOCALITY": "JUNAWAS BHILWARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311011,
      "TXT_PINCODE_LOCALITY": "SUWANA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311012,
      "TXT_PINCODE_LOCALITY": "BHILWARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311013,
      "TXT_PINCODE_LOCALITY": "SANGANER BHILWARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311021,
      "TXT_PINCODE_LOCALITY": "GULABPURA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311022,
      "TXT_PINCODE_LOCALITY": "HURDA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311023,
      "TXT_PINCODE_LOCALITY": "KHAMORE",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311024,
      "TXT_PINCODE_LOCALITY": "RAILAGAON",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311025,
      "TXT_PINCODE_LOCALITY": "HAMIRGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311026,
      "TXT_PINCODE_LOCALITY": "MOR KA NIMBAHERA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311027,
      "TXT_PINCODE_LOCALITY": "BARLIYAS",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311028,
      "TXT_PINCODE_LOCALITY": "MANGROP",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311029,
      "TXT_PINCODE_LOCALITY": "AGUCHA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311030,
      "TXT_PINCODE_LOCALITY": "DHANOP",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311031,
      "TXT_PINCODE_LOCALITY": "BHILWARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311032,
      "TXT_PINCODE_LOCALITY": "BHAGWANPURA BHILWARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311201,
      "TXT_PINCODE_LOCALITY": "UNCHA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311202,
      "TXT_PINCODE_LOCALITY": "PAROLI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311203,
      "TXT_PINCODE_LOCALITY": "SHAKKARGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311204,
      "TXT_PINCODE_LOCALITY": "JAGPURA B.O",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311301,
      "TXT_PINCODE_LOCALITY": "ASIND",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311302,
      "TXT_PINCODE_LOCALITY": "BADNORE",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311303,
      "TXT_PINCODE_LOCALITY": "DAULATGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311401,
      "TXT_PINCODE_LOCALITY": "BANERA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311402,
      "TXT_PINCODE_LOCALITY": "BAGORE",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311403,
      "TXT_PINCODE_LOCALITY": "MANDAL",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311404,
      "TXT_PINCODE_LOCALITY": "SHAHPURA KUND GATE",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311405,
      "TXT_PINCODE_LOCALITY": "AMMUNITION",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311406,
      "TXT_PINCODE_LOCALITY": "KACHAN KALAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311407,
      "TXT_PINCODE_LOCALITY": "PHULIA KALAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311408,
      "TXT_PINCODE_LOCALITY": "UPREDA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311601,
      "TXT_PINCODE_LOCALITY": "BIGOD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311602,
      "TXT_PINCODE_LOCALITY": "BIJOLIAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311603,
      "TXT_PINCODE_LOCALITY": "KOTRI BHILWARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311604,
      "TXT_PINCODE_LOCALITY": "MANDALGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311605,
      "TXT_PINCODE_LOCALITY": "KACHHOLA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311606,
      "TXT_PINCODE_LOCALITY": "LADPURA BHILWARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311607,
      "TXT_PINCODE_LOCALITY": "AKOLA BHILWARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311608,
      "TXT_PINCODE_LOCALITY": "NANDRAI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311609,
      "TXT_PINCODE_LOCALITY": "KHATWARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311801,
      "TXT_PINCODE_LOCALITY": "SHASTRINAGAR GANGAPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311802,
      "TXT_PINCODE_LOCALITY": "GURLAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311803,
      "TXT_PINCODE_LOCALITY": "RAIPUR BHILWARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311804,
      "TXT_PINCODE_LOCALITY": "RAJAJI KA KARERA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311805,
      "TXT_PINCODE_LOCALITY": "KOSHITHAL",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311806,
      "TXT_PINCODE_LOCALITY": "SAHADA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311807,
      "TXT_PINCODE_LOCALITY": "LAKHOLA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311808,
      "TXT_PINCODE_LOCALITY": "KAROI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311809,
      "TXT_PINCODE_LOCALITY": "BEMALI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 311810,
      "TXT_PINCODE_LOCALITY": "POTLAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312001,
      "TXT_PINCODE_LOCALITY": "CHITTORGARH RANA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 253,
      "City District Name": "CHITTORGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312021,
      "TXT_PINCODE_LOCALITY": "CHITTORGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2818,
      "City District Name": "CHITTAURGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312022,
      "TXT_PINCODE_LOCALITY": "BASSI COR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2818,
      "City District Name": "CHITTAURGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312023,
      "TXT_PINCODE_LOCALITY": "BEGUN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2818,
      "City District Name": "CHITTAURGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312024,
      "TXT_PINCODE_LOCALITY": "BHADSAURA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2818,
      "City District Name": "CHITTAURGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312025,
      "TXT_PINCODE_LOCALITY": "SENTHI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2818,
      "City District Name": "CHITTAURGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312026,
      "TXT_PINCODE_LOCALITY": "BIJAIPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2818,
      "City District Name": "CHITTAURGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312027,
      "TXT_PINCODE_LOCALITY": "CHIKARADA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 253,
      "City District Name": "CHITTORGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312201,
      "TXT_PINCODE_LOCALITY": "GOSUNDA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2818,
      "City District Name": "CHITTAURGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312202,
      "TXT_PINCODE_LOCALITY": "KAPASAN TOWN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 253,
      "City District Name": "CHITTORGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312203,
      "TXT_PINCODE_LOCALITY": "RASHMI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2818,
      "City District Name": "CHITTAURGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312204,
      "TXT_PINCODE_LOCALITY": "BHUPALSAGAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2818,
      "City District Name": "CHITTAURGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312205,
      "TXT_PINCODE_LOCALITY": "AKOLA CHITTORGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2818,
      "City District Name": "CHITTAURGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312206,
      "TXT_PINCODE_LOCALITY": "PAHUNA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2818,
      "City District Name": "CHITTAURGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312207,
      "TXT_PINCODE_LOCALITY": "SINGHPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2818,
      "City District Name": "CHITTAURGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312208,
      "TXT_PINCODE_LOCALITY": "KANKARWA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2818,
      "City District Name": "CHITTAURGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312401,
      "TXT_PINCODE_LOCALITY": "CHITTORGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312402,
      "TXT_PINCODE_LOCALITY": "DOONGLA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2818,
      "City District Name": "CHITTAURGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312403,
      "TXT_PINCODE_LOCALITY": "BARI SADRI R S",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 253,
      "City District Name": "CHITTORGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312404,
      "TXT_PINCODE_LOCALITY": "CHITTORGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 253,
      "City District Name": "CHITTORGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312601,
      "TXT_PINCODE_LOCALITY": "NIMBAHERA R S",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 253,
      "City District Name": "CHITTORGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312602,
      "TXT_PINCODE_LOCALITY": "BHADESAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2818,
      "City District Name": "CHITTAURGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312603,
      "TXT_PINCODE_LOCALITY": "NIKUMBH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2818,
      "City District Name": "CHITTAURGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312604,
      "TXT_PINCODE_LOCALITY": "CHHOTI SADRI CITY",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 253,
      "City District Name": "CHITTORGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312605,
      "TXT_PINCODE_LOCALITY": "PRATABGARH SURAJPOLE",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 253,
      "City District Name": "CHITTORGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312606,
      "TXT_PINCODE_LOCALITY": "KANERA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2818,
      "City District Name": "CHITTAURGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312607,
      "TXT_PINCODE_LOCALITY": "BORAO",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2818,
      "City District Name": "CHITTAURGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312608,
      "TXT_PINCODE_LOCALITY": "KARUNDA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2818,
      "City District Name": "CHITTAURGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312609,
      "TXT_PINCODE_LOCALITY": "LASDAWAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2818,
      "City District Name": "CHITTAURGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312610,
      "TXT_PINCODE_LOCALITY": "DHOLAPANI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2818,
      "City District Name": "CHITTAURGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312611,
      "TXT_PINCODE_LOCALITY": "RANI KHERA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2818,
      "City District Name": "CHITTAURGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312612,
      "TXT_PINCODE_LOCALITY": "SHAMBHUPURA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2818,
      "City District Name": "CHITTAURGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312613,
      "TXT_PINCODE_LOCALITY": "SAWA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2818,
      "City District Name": "CHITTAURGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312614,
      "TXT_PINCODE_LOCALITY": "BINOTA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2818,
      "City District Name": "CHITTAURGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312615,
      "TXT_PINCODE_LOCALITY": "ARNOD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2818,
      "City District Name": "CHITTAURGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312616,
      "TXT_PINCODE_LOCALITY": "DHAMOTAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2818,
      "City District Name": "CHITTAURGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312617,
      "TXT_PINCODE_LOCALITY": "KAILASH NAGAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2818,
      "City District Name": "CHITTAURGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312618,
      "TXT_PINCODE_LOCALITY": "SALAMGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2818,
      "City District Name": "CHITTAURGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312619,
      "TXT_PINCODE_LOCALITY": "DALOT",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2818,
      "City District Name": "CHITTAURGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312620,
      "TXT_PINCODE_LOCALITY": "MANGROL CHITTORGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2818,
      "City District Name": "CHITTAURGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312621,
      "TXT_PINCODE_LOCALITY": "ANUPPURA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2818,
      "City District Name": "CHITTAURGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312622,
      "TXT_PINCODE_LOCALITY": "ADITYAPURAM",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 253,
      "City District Name": "CHITTORGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312623,
      "TXT_PINCODE_LOCALITY": "RATHAJANA S.O",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 253,
      "City District Name": "CHITTORGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312624,
      "TXT_PINCODE_LOCALITY": "CHITTORGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 253,
      "City District Name": "CHITTORGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312625,
      "TXT_PINCODE_LOCALITY": "CHITTORGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 253,
      "City District Name": "CHITTORGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312626,
      "TXT_PINCODE_LOCALITY": "CHITTORGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 253,
      "City District Name": "CHITTORGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312627,
      "TXT_PINCODE_LOCALITY": "CHITTORGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 253,
      "City District Name": "CHITTORGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312901,
      "TXT_PINCODE_LOCALITY": "GANGRAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2818,
      "City District Name": "CHITTAURGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 312902,
      "TXT_PINCODE_LOCALITY": "GANGRAR R S",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2818,
      "City District Name": "CHITTAURGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313001,
      "TXT_PINCODE_LOCALITY": "VIDYA BHAWAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313002,
      "TXT_PINCODE_LOCALITY": "HIRAN MAGRI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313003,
      "TXT_PINCODE_LOCALITY": "MADRI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313004,
      "TXT_PINCODE_LOCALITY": "UDAIPUR H.O",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313011,
      "TXT_PINCODE_LOCALITY": "BADGAON",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313012,
      "TXT_PINCODE_LOCALITY": "LAKADWAS",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313013,
      "TXT_PINCODE_LOCALITY": "SAVEENA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313014,
      "TXT_PINCODE_LOCALITY": "KANPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313015,
      "TXT_PINCODE_LOCALITY": "JHAMAR KOTRA MINES",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313016,
      "TXT_PINCODE_LOCALITY": "UDAIPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313021,
      "TXT_PINCODE_LOCALITY": "BAJAJ NAGAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2818,
      "City District Name": "CHITTAURGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313022,
      "TXT_PINCODE_LOCALITY": "DABOK",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313023,
      "TXT_PINCODE_LOCALITY": "DABOK AIR PORT",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313024,
      "TXT_PINCODE_LOCALITY": "ZINK SMELTER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313025,
      "TXT_PINCODE_LOCALITY": "BARI T B SANATORIUM",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313026,
      "TXT_PINCODE_LOCALITY": "INTALIKHERA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313027,
      "TXT_PINCODE_LOCALITY": "SALUMBAR H O",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313028,
      "TXT_PINCODE_LOCALITY": "UDAIPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313029,
      "TXT_PINCODE_LOCALITY": "DEBARI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313030,
      "TXT_PINCODE_LOCALITY": "BHALERANA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313031,
      "TXT_PINCODE_LOCALITY": "NAI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313032,
      "TXT_PINCODE_LOCALITY": "DAROLI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313033,
      "TXT_PINCODE_LOCALITY": "DAL",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313034,
      "TXT_PINCODE_LOCALITY": "TOKAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313038,
      "TXT_PINCODE_LOCALITY": "JHALLARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313201,
      "TXT_PINCODE_LOCALITY": "KHEMLI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313202,
      "TXT_PINCODE_LOCALITY": "DELWARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 896,
      "City District Name": "RAJSAMAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313203,
      "TXT_PINCODE_LOCALITY": "MAVLI JN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313204,
      "TXT_PINCODE_LOCALITY": "PALANA KHURD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313205,
      "TXT_PINCODE_LOCALITY": "FATEL NAGAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313206,
      "TXT_PINCODE_LOCALITY": "SANWAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313207,
      "TXT_PINCODE_LOCALITY": "GILUND",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 896,
      "City District Name": "RAJSAMAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313209,
      "TXT_PINCODE_LOCALITY": "UDAIPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313210,
      "TXT_PINCODE_LOCALITY": "GHASA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313211,
      "TXT_PINCODE_LOCALITY": "DARIBA UDAIPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 896,
      "City District Name": "RAJSAMAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313212,
      "TXT_PINCODE_LOCALITY": "SAKRODA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 896,
      "City District Name": "RAJSAMAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313301,
      "TXT_PINCODE_LOCALITY": "NATHDWARA CHOPATI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 896,
      "City District Name": "RAJSAMAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313303,
      "TXT_PINCODE_LOCALITY": "SHISHODA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 896,
      "City District Name": "RAJSAMAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313304,
      "TXT_PINCODE_LOCALITY": "BOKHADA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313321,
      "TXT_PINCODE_LOCALITY": "GAON GUDA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 896,
      "City District Name": "RAJSAMAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313322,
      "TXT_PINCODE_LOCALITY": "KHAMNORE",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 896,
      "City District Name": "RAJSAMAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313323,
      "TXT_PINCODE_LOCALITY": "KOTHARIA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 896,
      "City District Name": "RAJSAMAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313324,
      "TXT_PINCODE_LOCALITY": "KANKROLI H O",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 896,
      "City District Name": "RAJSAMAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313325,
      "TXT_PINCODE_LOCALITY": "KELWARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 896,
      "City District Name": "RAJSAMAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313326,
      "TXT_PINCODE_LOCALITY": "RAJSAMAND",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 896,
      "City District Name": "RAJSAMAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313327,
      "TXT_PINCODE_LOCALITY": "KOARIA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313328,
      "TXT_PINCODE_LOCALITY": "KURAJ",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 896,
      "City District Name": "RAJSAMAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313329,
      "TXT_PINCODE_LOCALITY": "RAILMAGRA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 896,
      "City District Name": "RAJSAMAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313330,
      "TXT_PINCODE_LOCALITY": "LAWA SARDARGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 896,
      "City District Name": "RAJSAMAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313331,
      "TXT_PINCODE_LOCALITY": "CHHAPLIAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 896,
      "City District Name": "RAJSAMAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313332,
      "TXT_PINCODE_LOCALITY": "CHARBHUJA ROAD AMET",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 896,
      "City District Name": "RAJSAMAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313333,
      "TXT_PINCODE_LOCALITY": "CHARBHUJA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 896,
      "City District Name": "RAJSAMAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313334,
      "TXT_PINCODE_LOCALITY": "KELWA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 896,
      "City District Name": "RAJSAMAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313336,
      "TXT_PINCODE_LOCALITY": "JHALON KI MADAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 896,
      "City District Name": "RAJSAMAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313337,
      "TXT_PINCODE_LOCALITY": "RINCHHED",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 896,
      "City District Name": "RAJSAMAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313338,
      "TXT_PINCODE_LOCALITY": "MOHI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 896,
      "City District Name": "RAJSAMAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313340,
      "TXT_PINCODE_LOCALITY": "UDAIPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313341,
      "TXT_PINCODE_LOCALITY": "BARAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 896,
      "City District Name": "RAJSAMAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313342,
      "TXT_PINCODE_LOCALITY": "KANKROLI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 896,
      "City District Name": "RAJSAMAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313601,
      "TXT_PINCODE_LOCALITY": "VALLABHNAGAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313602,
      "TXT_PINCODE_LOCALITY": "KHERODA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313603,
      "TXT_PINCODE_LOCALITY": "BHINDER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313604,
      "TXT_PINCODE_LOCALITY": "KUN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313605,
      "TXT_PINCODE_LOCALITY": "KESHARIAWAD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313606,
      "TXT_PINCODE_LOCALITY": "UDAIPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313607,
      "TXT_PINCODE_LOCALITY": "UDAIPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313608,
      "TXT_PINCODE_LOCALITY": "UDAIPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313609,
      "TXT_PINCODE_LOCALITY": "UDAIPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313610,
      "TXT_PINCODE_LOCALITY": "UDAIPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313611,
      "TXT_PINCODE_LOCALITY": "BORIA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 819,
      "City District Name": "BANSWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313612,
      "TXT_PINCODE_LOCALITY": "UDAIPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313613,
      "TXT_PINCODE_LOCALITY": "UDAIPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313701,
      "TXT_PINCODE_LOCALITY": "PHALASIA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313702,
      "TXT_PINCODE_LOCALITY": "JHADOL PHALASIA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313703,
      "TXT_PINCODE_LOCALITY": "KURAWAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313704,
      "TXT_PINCODE_LOCALITY": "SAYRA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313705,
      "TXT_PINCODE_LOCALITY": "GOGUNDA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313706,
      "TXT_PINCODE_LOCALITY": "BAMBORA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313707,
      "TXT_PINCODE_LOCALITY": "UDAIPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313708,
      "TXT_PINCODE_LOCALITY": "JASWANTGARH UDAIPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313709,
      "TXT_PINCODE_LOCALITY": "RAJSAMAND",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 896,
      "City District Name": "RAJSAMAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313801,
      "TXT_PINCODE_LOCALITY": "PARSAD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313802,
      "TXT_PINCODE_LOCALITY": "RISHABHDEO",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313803,
      "TXT_PINCODE_LOCALITY": "KHERWARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313804,
      "TXT_PINCODE_LOCALITY": "CHHANI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313805,
      "TXT_PINCODE_LOCALITY": "BARAPAL",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313806,
      "TXT_PINCODE_LOCALITY": "UDAIPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313901,
      "TXT_PINCODE_LOCALITY": "ZAWAR MINES",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313902,
      "TXT_PINCODE_LOCALITY": "SARARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313903,
      "TXT_PINCODE_LOCALITY": "SEMARI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313904,
      "TXT_PINCODE_LOCALITY": "CHAWAND",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313905,
      "TXT_PINCODE_LOCALITY": "JHADOL SARARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313906,
      "TXT_PINCODE_LOCALITY": "SAGTARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313907,
      "TXT_PINCODE_LOCALITY": "KALYANPUR UDAIPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 313921,
      "TXT_PINCODE_LOCALITY": "AJITGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 896,
      "City District Name": "RAJSAMAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 314001,
      "TXT_PINCODE_LOCALITY": "DUNGARPUR CITY",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 825,
      "City District Name": "DUNGARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 314011,
      "TXT_PINCODE_LOCALITY": "PALDEWAL",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 825,
      "City District Name": "DUNGARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 314012,
      "TXT_PINCODE_LOCALITY": "BILDI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 825,
      "City District Name": "DUNGARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 314013,
      "TXT_PINCODE_LOCALITY": "DUNGARPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 825,
      "City District Name": "DUNGARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 314021,
      "TXT_PINCODE_LOCALITY": "ASPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 825,
      "City District Name": "DUNGARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 314022,
      "TXT_PINCODE_LOCALITY": "SABLA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 825,
      "City District Name": "DUNGARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 314023,
      "TXT_PINCODE_LOCALITY": "BANKODA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 825,
      "City District Name": "DUNGARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 314024,
      "TXT_PINCODE_LOCALITY": "PADWA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 825,
      "City District Name": "DUNGARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 314025,
      "TXT_PINCODE_LOCALITY": "SAGWARA BAZAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 825,
      "City District Name": "DUNGARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 314026,
      "TXT_PINCODE_LOCALITY": "GALIAKOT",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 825,
      "City District Name": "DUNGARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 314027,
      "TXT_PINCODE_LOCALITY": "KHADAGDA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 825,
      "City District Name": "DUNGARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 314028,
      "TXT_PINCODE_LOCALITY": "PUNALI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 825,
      "City District Name": "DUNGARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 314029,
      "TXT_PINCODE_LOCALITY": "THAKARDA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 825,
      "City District Name": "DUNGARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 314030,
      "TXT_PINCODE_LOCALITY": "CHIKHLI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 825,
      "City District Name": "DUNGARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 314031,
      "TXT_PINCODE_LOCALITY": "JETHANA DUNGARPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 825,
      "City District Name": "DUNGARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 314032,
      "TXT_PINCODE_LOCALITY": "SARODA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 825,
      "City District Name": "DUNGARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 314033,
      "TXT_PINCODE_LOCALITY": "DUNGARPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 825,
      "City District Name": "DUNGARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 314034,
      "TXT_PINCODE_LOCALITY": "RAMGRH DUNGARPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 825,
      "City District Name": "DUNGARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 314035,
      "TXT_PINCODE_LOCALITY": "CHITRI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 825,
      "City District Name": "DUNGARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 314036,
      "TXT_PINCODE_LOCALITY": "DAMDI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 825,
      "City District Name": "DUNGARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 314037,
      "TXT_PINCODE_LOCALITY": "ANTRI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 825,
      "City District Name": "DUNGARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 314038,
      "TXT_PINCODE_LOCALITY": "PUNJPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 825,
      "City District Name": "DUNGARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 314401,
      "TXT_PINCODE_LOCALITY": "OBRI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 825,
      "City District Name": "DUNGARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 314402,
      "TXT_PINCODE_LOCALITY": "DHAMBOLA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 825,
      "City District Name": "DUNGARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 314403,
      "TXT_PINCODE_LOCALITY": "SIMALWARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 825,
      "City District Name": "DUNGARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 314404,
      "TXT_PINCODE_LOCALITY": "VIKASNAGAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 825,
      "City District Name": "DUNGARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 314405,
      "TXT_PINCODE_LOCALITY": "DUNGARPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 825,
      "City District Name": "DUNGARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 314406,
      "TXT_PINCODE_LOCALITY": "PEETH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 825,
      "City District Name": "DUNGARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 314407,
      "TXT_PINCODE_LOCALITY": "KARAWADA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 825,
      "City District Name": "DUNGARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 314801,
      "TXT_PINCODE_LOCALITY": "BICHHIWARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 825,
      "City District Name": "DUNGARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 314802,
      "TXT_PINCODE_LOCALITY": "BOKHLA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 825,
      "City District Name": "DUNGARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 314803,
      "TXT_PINCODE_LOCALITY": "BALWARE",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 825,
      "City District Name": "DUNGARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 314804,
      "TXT_PINCODE_LOCALITY": "KANBA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 825,
      "City District Name": "DUNGARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 314806,
      "TXT_PINCODE_LOCALITY": "KANBA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 825,
      "City District Name": "DUNGARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321001,
      "TXT_PINCODE_LOCALITY": "BASAN DARWAZA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321002,
      "TXT_PINCODE_LOCALITY": "ANADDADRI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321006,
      "TXT_PINCODE_LOCALITY": "DOROLI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 442958,
      "City District Name": "KATHUMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321021,
      "TXT_PINCODE_LOCALITY": "JAGHINA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321022,
      "TXT_PINCODE_LOCALITY": "GOPINATH MOHALLA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321023,
      "TXT_PINCODE_LOCALITY": "JURHERA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321024,
      "TXT_PINCODE_LOCALITY": "SIKRI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321025,
      "TXT_PINCODE_LOCALITY": "RAREH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321026,
      "TXT_PINCODE_LOCALITY": "DEPOT",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321027,
      "TXT_PINCODE_LOCALITY": "MARENA BHARATPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321028,
      "TXT_PINCODE_LOCALITY": "KHERLI GADASIA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321029,
      "TXT_PINCODE_LOCALITY": "BHARATPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321030,
      "TXT_PINCODE_LOCALITY": "PINGHERA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321031,
      "TXT_PINCODE_LOCALITY": "GULPARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321032,
      "TXT_PINCODE_LOCALITY": "KAITHWARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321033,
      "TXT_PINCODE_LOCALITY": "MAI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321034,
      "TXT_PINCODE_LOCALITY": "DEHRA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321035,
      "TXT_PINCODE_LOCALITY": "ISAPUR KATARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321036,
      "TXT_PINCODE_LOCALITY": "BARKHERA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321201,
      "TXT_PINCODE_LOCALITY": "KUMHER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321202,
      "TXT_PINCODE_LOCALITY": "PENGHORE",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321203,
      "TXT_PINCODE_LOCALITY": "PURANI DEEG",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321204,
      "TXT_PINCODE_LOCALITY": "PAHARI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321205,
      "TXT_PINCODE_LOCALITY": "NAGAR BHARATPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321206,
      "TXT_PINCODE_LOCALITY": "KHOH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321207,
      "TXT_PINCODE_LOCALITY": "SAWAI KHEDA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321208,
      "TXT_PINCODE_LOCALITY": "BHARATPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321209,
      "TXT_PINCODE_LOCALITY": "BAHTANA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321210,
      "TXT_PINCODE_LOCALITY": "THOON",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321211,
      "TXT_PINCODE_LOCALITY": "GOPALGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321301,
      "TXT_PINCODE_LOCALITY": "PICHUNA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321302,
      "TXT_PINCODE_LOCALITY": "UCHAIN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321303,
      "TXT_PINCODE_LOCALITY": "SEWAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321304,
      "TXT_PINCODE_LOCALITY": "BHARATPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321305,
      "TXT_PINCODE_LOCALITY": "LUDHAWAI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321401,
      "TXT_PINCODE_LOCALITY": "BAYANA H O",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321402,
      "TXT_PINCODE_LOCALITY": "RUDAWAL",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321403,
      "TXT_PINCODE_LOCALITY": "BANSI PAHARPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321404,
      "TXT_PINCODE_LOCALITY": "RUPBAS TOWN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321405,
      "TXT_PINCODE_LOCALITY": "GARHI BAJNA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321406,
      "TXT_PINCODE_LOCALITY": "BHUSAWAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321407,
      "TXT_PINCODE_LOCALITY": "CHHONKERWARA KALAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321408,
      "TXT_PINCODE_LOCALITY": "WEIR TOWN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321409,
      "TXT_PINCODE_LOCALITY": "MAJAJPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321410,
      "TXT_PINCODE_LOCALITY": "BRAHAMBAD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321411,
      "TXT_PINCODE_LOCALITY": "KHARERI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321412,
      "TXT_PINCODE_LOCALITY": "BHARATPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321414,
      "TXT_PINCODE_LOCALITY": "NITHAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321415,
      "TXT_PINCODE_LOCALITY": "BHARATPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321416,
      "TXT_PINCODE_LOCALITY": "BAROLI BHARATPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321417,
      "TXT_PINCODE_LOCALITY": "ITAMDA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321418,
      "TXT_PINCODE_LOCALITY": "NARHARPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321419,
      "TXT_PINCODE_LOCALITY": "NAYAGAON KHALSA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321420,
      "TXT_PINCODE_LOCALITY": "KHANWA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321421,
      "TXT_PINCODE_LOCALITY": "SALEMPUR KHURD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321422,
      "TXT_PINCODE_LOCALITY": "SAMRAYA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321423,
      "TXT_PINCODE_LOCALITY": "BHARATPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321424,
      "TXT_PINCODE_LOCALITY": "MAHALPUR CHURA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321425,
      "TXT_PINCODE_LOCALITY": "BHARATPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321426,
      "TXT_PINCODE_LOCALITY": "JAGJIWANPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321427,
      "TXT_PINCODE_LOCALITY": "DHANA KHERLI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321428,
      "TXT_PINCODE_LOCALITY": "KHAN SURAJPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321429,
      "TXT_PINCODE_LOCALITY": "BHARATPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321430,
      "TXT_PINCODE_LOCALITY": "KHANKHERA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321506,
      "TXT_PINCODE_LOCALITY": "SALASAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321601,
      "TXT_PINCODE_LOCALITY": "HALENA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321602,
      "TXT_PINCODE_LOCALITY": "NADBAI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321603,
      "TXT_PINCODE_LOCALITY": "GADOLI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321604,
      "TXT_PINCODE_LOCALITY": "GOVINDGARH BHARATPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321605,
      "TXT_PINCODE_LOCALITY": "KATHUMAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321606,
      "TXT_PINCODE_LOCALITY": "KHERLI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321607,
      "TXT_PINCODE_LOCALITY": "JAOLI ALWAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321608,
      "TXT_PINCODE_LOCALITY": "MAHWA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 858,
      "City District Name": "DAUSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321609,
      "TXT_PINCODE_LOCALITY": "GARH HIMMATSINGH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321610,
      "TXT_PINCODE_LOCALITY": "GHATA BALAJI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3800,
      "City District Name": "KARAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321611,
      "TXT_PINCODE_LOCALITY": "TODA BHIM",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3800,
      "City District Name": "KARAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321612,
      "TXT_PINCODE_LOCALITY": "PAOTA BHARATPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3800,
      "City District Name": "KARAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321613,
      "TXT_PINCODE_LOCALITY": "RASHIDPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3800,
      "City District Name": "KARAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321614,
      "TXT_PINCODE_LOCALITY": "AKHAIGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321615,
      "TXT_PINCODE_LOCALITY": "PATHENA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321616,
      "TXT_PINCODE_LOCALITY": "BHADIRA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321617,
      "TXT_PINCODE_LOCALITY": "ALIPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321618,
      "TXT_PINCODE_LOCALITY": "BHARATPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321619,
      "TXT_PINCODE_LOCALITY": "BARAULI CHHAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321620,
      "TXT_PINCODE_LOCALITY": "BALAHERI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3800,
      "City District Name": "KARAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321621,
      "TXT_PINCODE_LOCALITY": "RAUTHADIA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321622,
      "TXT_PINCODE_LOCALITY": "BHARATPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321623,
      "TXT_PINCODE_LOCALITY": "KOTA 1",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321625,
      "TXT_PINCODE_LOCALITY": "SANTHA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321626,
      "TXT_PINCODE_LOCALITY": "UKROND",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321628,
      "TXT_PINCODE_LOCALITY": "SONKHRI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321629,
      "TXT_PINCODE_LOCALITY": "BHARATPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321630,
      "TXT_PINCODE_LOCALITY": "KHONKHAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321631,
      "TXT_PINCODE_LOCALITY": "KALWARI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321632,
      "TXT_PINCODE_LOCALITY": "SAMUCHI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321633,
      "TXT_PINCODE_LOCALITY": "BERLA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321634,
      "TXT_PINCODE_LOCALITY": "MUNDIA LALITA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321636,
      "TXT_PINCODE_LOCALITY": "PAHARSAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321637,
      "TXT_PINCODE_LOCALITY": "ALWAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321638,
      "TXT_PINCODE_LOCALITY": "HANTRA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321639,
      "TXT_PINCODE_LOCALITY": "SABORA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321640,
      "TXT_PINCODE_LOCALITY": "DANTIA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321641,
      "TXT_PINCODE_LOCALITY": "GARU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 321642,
      "TXT_PINCODE_LOCALITY": "PEELI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 4651,
      "City District Name": "NADBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322001,
      "TXT_PINCODE_LOCALITY": "CHHAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 5562,
      "City District Name": "SAWAI MADHOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322021,
      "TXT_PINCODE_LOCALITY": "TOWN BAZAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 5562,
      "City District Name": "SAWAI MADHOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322022,
      "TXT_PINCODE_LOCALITY": "SAHUNAGAR ADARSHNAGAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 5562,
      "City District Name": "SAWAI MADHOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322023,
      "TXT_PINCODE_LOCALITY": "MALARNA R S",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 5562,
      "City District Name": "SAWAI MADHOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322024,
      "TXT_PINCODE_LOCALITY": "KHIRNI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 5562,
      "City District Name": "SAWAI MADHOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322025,
      "TXT_PINCODE_LOCALITY": "KHANDAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 5562,
      "City District Name": "SAWAI MADHOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322026,
      "TXT_PINCODE_LOCALITY": "PHALODI QUARRY",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 5562,
      "City District Name": "SAWAI MADHOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322027,
      "TXT_PINCODE_LOCALITY": "SOORWAL",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 5562,
      "City District Name": "SAWAI MADHOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322028,
      "TXT_PINCODE_LOCALITY": "MALARNA DOONGAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 5562,
      "City District Name": "SAWAI MADHOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322029,
      "TXT_PINCODE_LOCALITY": "KUNDERA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 5562,
      "City District Name": "SAWAI MADHOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322030,
      "TXT_PINCODE_LOCALITY": "MALARNA CHOUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 5562,
      "City District Name": "SAWAI MADHOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322031,
      "TXT_PINCODE_LOCALITY": "RAWANJNA DOONGAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 5562,
      "City District Name": "SAWAI MADHOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322032,
      "TXT_PINCODE_LOCALITY": "BAHRAONDA KHURD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 5562,
      "City District Name": "SAWAI MADHOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322033,
      "TXT_PINCODE_LOCALITY": "MAKSUDANPURA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 5562,
      "City District Name": "SAWAI MADHOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322034,
      "TXT_PINCODE_LOCALITY": "CHAKERI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 5562,
      "City District Name": "SAWAI MADHOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322035,
      "TXT_PINCODE_LOCALITY": "BHAROTI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 5562,
      "City District Name": "SAWAI MADHOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322036,
      "TXT_PINCODE_LOCALITY": "HADOTI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3800,
      "City District Name": "KARAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322201,
      "TXT_PINCODE_LOCALITY": "GANGAPUR R S H O",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 5562,
      "City District Name": "SAWAI MADHOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322202,
      "TXT_PINCODE_LOCALITY": "MAHU KALAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 5562,
      "City District Name": "SAWAI MADHOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322203,
      "TXT_PINCODE_LOCALITY": "NARAULI DANG",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3800,
      "City District Name": "KARAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322204,
      "TXT_PINCODE_LOCALITY": "SHAHAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3800,
      "City District Name": "KARAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322205,
      "TXT_PINCODE_LOCALITY": "PILODA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 5562,
      "City District Name": "SAWAI MADHOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322206,
      "TXT_PINCODE_LOCALITY": "UDAI KALAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 5562,
      "City District Name": "SAWAI MADHOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322209,
      "TXT_PINCODE_LOCALITY": "SANKARWADA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3800,
      "City District Name": "KARAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322211,
      "TXT_PINCODE_LOCALITY": "BAMANWAS",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 5562,
      "City District Name": "SAWAI MADHOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322212,
      "TXT_PINCODE_LOCALITY": "GARH MORAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3800,
      "City District Name": "KARAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322213,
      "TXT_PINCODE_LOCALITY": "GUDA CHANDERJI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3800,
      "City District Name": "KARAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322214,
      "TXT_PINCODE_LOCALITY": "PIPLAI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 5562,
      "City District Name": "SAWAI MADHOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322215,
      "TXT_PINCODE_LOCALITY": "NADOTI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3800,
      "City District Name": "KARAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322216,
      "TXT_PINCODE_LOCALITY": "KEMRI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 5562,
      "City District Name": "SAWAI MADHOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322217,
      "TXT_PINCODE_LOCALITY": "LIWALI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 5562,
      "City District Name": "SAWAI MADHOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322218,
      "TXT_PINCODE_LOCALITY": "SAPOTRA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3800,
      "City District Name": "KARAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322219,
      "TXT_PINCODE_LOCALITY": "WAZIRPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 5562,
      "City District Name": "SAWAI MADHOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322220,
      "TXT_PINCODE_LOCALITY": "SRI MAHAVIRJI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3800,
      "City District Name": "KARAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322221,
      "TXT_PINCODE_LOCALITY": "SHANTIVIRNAGAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3800,
      "City District Name": "KARAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322222,
      "TXT_PINCODE_LOCALITY": "SEWA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 5562,
      "City District Name": "SAWAI MADHOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322223,
      "TXT_PINCODE_LOCALITY": "RAIPUR SAWAI MADHOPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 5562,
      "City District Name": "SAWAI MADHOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322224,
      "TXT_PINCODE_LOCALITY": "MIRZAPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3800,
      "City District Name": "KARAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322230,
      "TXT_PINCODE_LOCALITY": "HINDAUN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3800,
      "City District Name": "KARAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322231,
      "TXT_PINCODE_LOCALITY": "KARAULI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3800,
      "City District Name": "KARAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322232,
      "TXT_PINCODE_LOCALITY": "KACHROLI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3800,
      "City District Name": "KARAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322233,
      "TXT_PINCODE_LOCALITY": "SIKRODA MINA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3800,
      "City District Name": "KARAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322234,
      "TXT_PINCODE_LOCALITY": "KHEDA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 858,
      "City District Name": "DAUSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322235,
      "TXT_PINCODE_LOCALITY": "MANDAWAR BHARATPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 858,
      "City District Name": "DAUSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322236,
      "TXT_PINCODE_LOCALITY": "DHINDORA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3800,
      "City District Name": "KARAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322238,
      "TXT_PINCODE_LOCALITY": "BHANDARI ANDRUNI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3800,
      "City District Name": "KARAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322240,
      "TXT_PINCODE_LOCALITY": "KHERLA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 5562,
      "City District Name": "SAWAI MADHOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322241,
      "TXT_PINCODE_LOCALITY": "PHOOTA KOT KARAULI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3800,
      "City District Name": "KARAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322242,
      "TXT_PINCODE_LOCALITY": "THEKRA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3800,
      "City District Name": "KARAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322243,
      "TXT_PINCODE_LOCALITY": "KAILADEVI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3184,
      "City District Name": "GANGANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322244,
      "TXT_PINCODE_LOCALITY": "KARANPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3800,
      "City District Name": "KARAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322245,
      "TXT_PINCODE_LOCALITY": "SUNDERPURA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 5562,
      "City District Name": "SAWAI MADHOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322246,
      "TXT_PINCODE_LOCALITY": "TALCHIRI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 858,
      "City District Name": "DAUSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322247,
      "TXT_PINCODE_LOCALITY": "CHAINPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3800,
      "City District Name": "KARAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322248,
      "TXT_PINCODE_LOCALITY": "SALEMPUR CHOWKI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 5562,
      "City District Name": "SAWAI MADHOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322249,
      "TXT_PINCODE_LOCALITY": "LANGRA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3800,
      "City District Name": "KARAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322251,
      "TXT_PINCODE_LOCALITY": "MANDRAIL",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3800,
      "City District Name": "KARAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322252,
      "TXT_PINCODE_LOCALITY": "SUROTH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3800,
      "City District Name": "KARAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322253,
      "TXT_PINCODE_LOCALITY": "ANDAWRA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322254,
      "TXT_PINCODE_LOCALITY": "GHONSLA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3800,
      "City District Name": "KARAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322255,
      "TXT_PINCODE_LOCALITY": "KURGAON",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3800,
      "City District Name": "KARAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322327,
      "TXT_PINCODE_LOCALITY": "JATNAGLA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3800,
      "City District Name": "KARAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322701,
      "TXT_PINCODE_LOCALITY": "BHAGWATGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 5562,
      "City District Name": "SAWAI MADHOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322702,
      "TXT_PINCODE_LOCALITY": "CHAUTH KA BARWARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 5562,
      "City District Name": "SAWAI MADHOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322703,
      "TXT_PINCODE_LOCALITY": "ISHARDA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 850,
      "City District Name": "TONK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 322704,
      "TXT_PINCODE_LOCALITY": "SIWAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 5562,
      "City District Name": "SAWAI MADHOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 323001,
      "TXT_PINCODE_LOCALITY": "KUBER INDUSTRIES AREA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 154,
      "City District Name": "KOTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 323021,
      "TXT_PINCODE_LOCALITY": "TALERA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 984,
      "City District Name": "BUNDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 323022,
      "TXT_PINCODE_LOCALITY": "NAMANA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 984,
      "City District Name": "BUNDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 323023,
      "TXT_PINCODE_LOCALITY": "HINDOLI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 984,
      "City District Name": "BUNDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 323024,
      "TXT_PINCODE_LOCALITY": "BADA NAYA GAON",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 984,
      "City District Name": "BUNDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 323025,
      "TXT_PINCODE_LOCALITY": "UMAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 984,
      "City District Name": "BUNDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 323026,
      "TXT_PINCODE_LOCALITY": "BUNDI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 984,
      "City District Name": "BUNDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 323031,
      "TXT_PINCODE_LOCALITY": "ANLBARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 984,
      "City District Name": "BUNDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 323301,
      "TXT_PINCODE_LOCALITY": "KAPREN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 984,
      "City District Name": "BUNDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 323302,
      "TXT_PINCODE_LOCALITY": "JAWAHARSAGAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 984,
      "City District Name": "BUNDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 323303,
      "TXT_PINCODE_LOCALITY": "ANUSHAKTI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2818,
      "City District Name": "CHITTAURGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 323304,
      "TXT_PINCODE_LOCALITY": "BHENSROADGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2818,
      "City District Name": "CHITTAURGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 323305,
      "TXT_PINCODE_LOCALITY": "RAWAT BHATA BAZAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 253,
      "City District Name": "CHITTORGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 323306,
      "TXT_PINCODE_LOCALITY": "VIKRAMNAGAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2818,
      "City District Name": "CHITTAURGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 323307,
      "TXT_PINCODE_LOCALITY": "BHABHANAGAR *",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2818,
      "City District Name": "CHITTAURGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 323601,
      "TXT_PINCODE_LOCALITY": "KESHORAIPATAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 984,
      "City District Name": "BUNDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 323602,
      "TXT_PINCODE_LOCALITY": "KESHORAIPATAN SUGAR FACT",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 984,
      "City District Name": "BUNDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 323603,
      "TXT_PINCODE_LOCALITY": "LAKHERI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 984,
      "City District Name": "BUNDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 323604,
      "TXT_PINCODE_LOCALITY": "KHERLI KALAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3468,
      "City District Name": "INDRAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 323606,
      "TXT_PINCODE_LOCALITY": "AJENDA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 984,
      "City District Name": "BUNDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 323613,
      "TXT_PINCODE_LOCALITY": "INDERGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 984,
      "City District Name": "BUNDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 323614,
      "TXT_PINCODE_LOCALITY": "SUMERGANJ MANDI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 984,
      "City District Name": "BUNDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 323615,
      "TXT_PINCODE_LOCALITY": "LAKHERI GAON",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 984,
      "City District Name": "BUNDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 323616,
      "TXT_PINCODE_LOCALITY": "TALWAS",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 984,
      "City District Name": "BUNDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 323801,
      "TXT_PINCODE_LOCALITY": "NAINWAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 984,
      "City District Name": "BUNDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 323802,
      "TXT_PINCODE_LOCALITY": "DEL",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 984,
      "City District Name": "BUNDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 323803,
      "TXT_PINCODE_LOCALITY": "KHATKAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 984,
      "City District Name": "BUNDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 324001,
      "TXT_PINCODE_LOCALITY": "GOVERNMENT COLLEGE",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 154,
      "City District Name": "KOTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 324002,
      "TXT_PINCODE_LOCALITY": "DADWARA COLONY",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 154,
      "City District Name": "KOTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 324003,
      "TXT_PINCODE_LOCALITY": "INDUSTRIAL ESTATE KOTA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 154,
      "City District Name": "KOTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 324004,
      "TXT_PINCODE_LOCALITY": "UDYOGPURI INDIRA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 154,
      "City District Name": "KOTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 324005,
      "TXT_PINCODE_LOCALITY": "TALWANDI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 154,
      "City District Name": "KOTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 324006,
      "TXT_PINCODE_LOCALITY": "SURAJPOLE KOTA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 154,
      "City District Name": "KOTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 324007,
      "TXT_PINCODE_LOCALITY": "BARAN RAOD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 154,
      "City District Name": "KOTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 324008,
      "TXT_PINCODE_LOCALITY": "NANTA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 154,
      "City District Name": "KOTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 324009,
      "TXT_PINCODE_LOCALITY": "CHAMBAL VIKAS",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 154,
      "City District Name": "KOTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 324010,
      "TXT_PINCODE_LOCALITY": "ENGINEERING COLLEGE KOTA S.O",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 154,
      "City District Name": "KOTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 325001,
      "TXT_PINCODE_LOCALITY": "KAITHUN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 154,
      "City District Name": "KOTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 325003,
      "TXT_PINCODE_LOCALITY": "CEMENT FACTORY AREA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 154,
      "City District Name": "KOTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 325004,
      "TXT_PINCODE_LOCALITY": "ITAWA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 154,
      "City District Name": "KOTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 325006,
      "TXT_PINCODE_LOCALITY": "MANDANA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 154,
      "City District Name": "KOTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 325007,
      "TXT_PINCODE_LOCALITY": "KOTA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 154,
      "City District Name": "KOTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 325008,
      "TXT_PINCODE_LOCALITY": "PIPALDA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1158,
      "City District Name": "BARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 325009,
      "TXT_PINCODE_LOCALITY": "KHATOLI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1158,
      "City District Name": "BARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 325201,
      "TXT_PINCODE_LOCALITY": "DIGOD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 154,
      "City District Name": "KOTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 325202,
      "TXT_PINCODE_LOCALITY": "ANTA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1158,
      "City District Name": "BARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 325203,
      "TXT_PINCODE_LOCALITY": "SIMALIA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1158,
      "City District Name": "BARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 325204,
      "TXT_PINCODE_LOCALITY": "SULTANPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 154,
      "City District Name": "KOTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 325205,
      "TXT_PINCODE_LOCALITY": "BARAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1158,
      "City District Name": "BARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 325206,
      "TXT_PINCODE_LOCALITY": "SISWALI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 154,
      "City District Name": "KOTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 325207,
      "TXT_PINCODE_LOCALITY": "BAPAWAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 154,
      "City District Name": "KOTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 325208,
      "TXT_PINCODE_LOCALITY": "FATEHPUR KOTA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 154,
      "City District Name": "KOTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 325209,
      "TXT_PINCODE_LOCALITY": "ANTA GAS POWER PROJECT ANTA S.O",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1158,
      "City District Name": "BARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 325214,
      "TXT_PINCODE_LOCALITY": "AYANA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 154,
      "City District Name": "KOTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 325215,
      "TXT_PINCODE_LOCALITY": "MANGROL KOTA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1158,
      "City District Name": "BARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 325216,
      "TXT_PINCODE_LOCALITY": "KISHANGANJ",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 813,
      "City District Name": "JHALAWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 325217,
      "TXT_PINCODE_LOCALITY": "SHAHBAD KOTA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1158,
      "City District Name": "BARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 325218,
      "TXT_PINCODE_LOCALITY": "ATRU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1158,
      "City District Name": "BARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 325219,
      "TXT_PINCODE_LOCALITY": "SALPURA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1158,
      "City District Name": "BARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 325220,
      "TXT_PINCODE_LOCALITY": "CHHABRA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1158,
      "City District Name": "BARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 325221,
      "TXT_PINCODE_LOCALITY": "CHHIPA BAROD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1158,
      "City District Name": "BARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 325222,
      "TXT_PINCODE_LOCALITY": "BOHAT",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1158,
      "City District Name": "BARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 325223,
      "TXT_PINCODE_LOCALITY": "ATONE",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1158,
      "City District Name": "BARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 325224,
      "TXT_PINCODE_LOCALITY": "GARDA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 154,
      "City District Name": "KOTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 325225,
      "TXT_PINCODE_LOCALITY": "HARNAWDA SHAHJI KA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1158,
      "City District Name": "BARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 325226,
      "TXT_PINCODE_LOCALITY": "JAIPLA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1158,
      "City District Name": "BARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 325227,
      "TXT_PINCODE_LOCALITY": "SAMRANIA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1158,
      "City District Name": "BARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 325304,
      "TXT_PINCODE_LOCALITY": "AMARPURA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1158,
      "City District Name": "BARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 325515,
      "TXT_PINCODE_LOCALITY": "CHAUMEHALA *",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 813,
      "City District Name": "JHALAWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 325601,
      "TXT_PINCODE_LOCALITY": "SANGOD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 154,
      "City District Name": "KOTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 325602,
      "TXT_PINCODE_LOCALITY": "KANWAS",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 154,
      "City District Name": "KOTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 326001,
      "TXT_PINCODE_LOCALITY": "JHALAWAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 813,
      "City District Name": "JHALAWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 326003,
      "TXT_PINCODE_LOCALITY": "THANAWAD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1965,
      "City District Name": "AKLERA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 326021,
      "TXT_PINCODE_LOCALITY": "ASNAWAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 813,
      "City District Name": "JHALAWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 326022,
      "TXT_PINCODE_LOCALITY": "BAKANI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 813,
      "City District Name": "JHALAWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 326023,
      "TXT_PINCODE_LOCALITY": "JHALRA PATAN CITY",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 813,
      "City District Name": "JHALAWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 326024,
      "TXT_PINCODE_LOCALITY": "RATLAI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 813,
      "City District Name": "JHALAWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 326025,
      "TXT_PINCODE_LOCALITY": "AKAWAD KHURD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 813,
      "City District Name": "JHALAWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 326030,
      "TXT_PINCODE_LOCALITY": "SEWANI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 442959,
      "City District Name": "KHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 326031,
      "TXT_PINCODE_LOCALITY": "CHANDIPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 813,
      "City District Name": "JHALAWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 326033,
      "TXT_PINCODE_LOCALITY": "AKLERA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 813,
      "City District Name": "JHALAWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 326034,
      "TXT_PINCODE_LOCALITY": "PIRAWA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 813,
      "City District Name": "JHALAWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 326035,
      "TXT_PINCODE_LOCALITY": "SAROLA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 813,
      "City District Name": "JHALAWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 326036,
      "TXT_PINCODE_LOCALITY": "RAIPUR JHALAWAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 813,
      "City District Name": "JHALAWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 326037,
      "TXT_PINCODE_LOCALITY": "MANOHAR THANA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 813,
      "City District Name": "JHALAWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 326038,
      "TXT_PINCODE_LOCALITY": "KHANPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 813,
      "City District Name": "JHALAWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 326039,
      "TXT_PINCODE_LOCALITY": "GHATOLI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 813,
      "City District Name": "JHALAWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 326040,
      "TXT_PINCODE_LOCALITY": "HARIGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 813,
      "City District Name": "JHALAWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 326041,
      "TXT_PINCODE_LOCALITY": "PANWAR JHALAWAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 813,
      "City District Name": "JHALAWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 326501,
      "TXT_PINCODE_LOCALITY": "SRI CHHATARPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 813,
      "City District Name": "JHALAWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 326502,
      "TXT_PINCODE_LOCALITY": "BHAWANI MANDI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 813,
      "City District Name": "JHALAWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 326503,
      "TXT_PINCODE_LOCALITY": "GANESHPURA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 813,
      "City District Name": "JHALAWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 326510,
      "TXT_PINCODE_LOCALITY": "KHERLI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1308,
      "City District Name": "RAMGANJ MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 326512,
      "TXT_PINCODE_LOCALITY": "PACHPAHAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 813,
      "City District Name": "JHALAWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 326513,
      "TXT_PINCODE_LOCALITY": "SUNEL",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 813,
      "City District Name": "JHALAWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 326514,
      "TXT_PINCODE_LOCALITY": "DAG",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 813,
      "City District Name": "JHALAWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 326515,
      "TXT_PINCODE_LOCALITY": "CHAUMEHLA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 813,
      "City District Name": "JHALAWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 326516,
      "TXT_PINCODE_LOCALITY": "GANGDHAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 813,
      "City District Name": "JHALAWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 326517,
      "TXT_PINCODE_LOCALITY": "MORAK",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 154,
      "City District Name": "KOTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 326518,
      "TXT_PINCODE_LOCALITY": "CHECHAT",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 154,
      "City District Name": "KOTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 326519,
      "TXT_PINCODE_LOCALITY": "SHAHID  PANNALAL CHORAHA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 154,
      "City District Name": "KOTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 326520,
      "TXT_PINCODE_LOCALITY": "UNDWA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 154,
      "City District Name": "KOTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 326521,
      "TXT_PINCODE_LOCALITY": "SALOTIA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 813,
      "City District Name": "JHALAWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 326529,
      "TXT_PINCODE_LOCALITY": "KHAIRABAD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 154,
      "City District Name": "KOTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 326530,
      "TXT_PINCODE_LOCALITY": "SUKET",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 154,
      "City District Name": "KOTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 327001,
      "TXT_PINCODE_LOCALITY": "BANSWARA MAHI COLONY",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 819,
      "City District Name": "BANSWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 327021,
      "TXT_PINCODE_LOCALITY": "GANODA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 819,
      "City District Name": "BANSWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 327022,
      "TXT_PINCODE_LOCALITY": "GARHI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 819,
      "City District Name": "BANSWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 327023,
      "TXT_PINCODE_LOCALITY": "PIPALKHUNT",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 819,
      "City District Name": "BANSWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 327024,
      "TXT_PINCODE_LOCALITY": "PARTAPUR BANSWARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 819,
      "City District Name": "BANSWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 327025,
      "TXT_PINCODE_LOCALITY": "TALWARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 819,
      "City District Name": "BANSWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 327026,
      "TXT_PINCODE_LOCALITY": "MAHI DAM",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 819,
      "City District Name": "BANSWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 327027,
      "TXT_PINCODE_LOCALITY": "KHAMERA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 819,
      "City District Name": "BANSWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 327028,
      "TXT_PINCODE_LOCALITY": "BANSWARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 819,
      "City District Name": "BANSWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 327029,
      "TXT_PINCODE_LOCALITY": "BANSWARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 819,
      "City District Name": "BANSWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 327030,
      "TXT_PINCODE_LOCALITY": "BANSWARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 819,
      "City District Name": "BANSWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 327031,
      "TXT_PINCODE_LOCALITY": "BHUKHIA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 819,
      "City District Name": "BANSWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 327032,
      "TXT_PINCODE_LOCALITY": "ARTHUNA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 819,
      "City District Name": "BANSWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 327033,
      "TXT_PINCODE_LOCALITY": "BANSWARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 819,
      "City District Name": "BANSWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 327034,
      "TXT_PINCODE_LOCALITY": "AMJA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 819,
      "City District Name": "BANSWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 327601,
      "TXT_PINCODE_LOCALITY": "BAGIDORA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 819,
      "City District Name": "BANSWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 327602,
      "TXT_PINCODE_LOCALITY": "SAJJANGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 819,
      "City District Name": "BANSWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 327603,
      "TXT_PINCODE_LOCALITY": "CHINCH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 819,
      "City District Name": "BANSWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 327604,
      "TXT_PINCODE_LOCALITY": "BODIGAMA *",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 819,
      "City District Name": "BANSWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 327605,
      "TXT_PINCODE_LOCALITY": "LOHARIA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 819,
      "City District Name": "BANSWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 327606,
      "TXT_PINCODE_LOCALITY": "KALINJARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 819,
      "City District Name": "BANSWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 327608,
      "TXT_PINCODE_LOCALITY": "BANSWARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 819,
      "City District Name": "BANSWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 327801,
      "TXT_PINCODE_LOCALITY": "KUSHALGARH BUS STAND",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1488,
      "City District Name": "DHOLPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 328001,
      "TXT_PINCODE_LOCALITY": "UDAIBHANGANJ DHOLPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1488,
      "City District Name": "DHOLPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 328021,
      "TXT_PINCODE_LOCALITY": "BARI BAZAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1488,
      "City District Name": "DHOLPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 328022,
      "TXT_PINCODE_LOCALITY": "PIPRON",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2996,
      "City District Name": "DHAULPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 328023,
      "TXT_PINCODE_LOCALITY": "KOLARI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2996,
      "City District Name": "DHAULPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 328024,
      "TXT_PINCODE_LOCALITY": "MANIA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2996,
      "City District Name": "DHAULPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 328025,
      "TXT_PINCODE_LOCALITY": "RAJAKHERA TOWN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1488,
      "City District Name": "DHOLPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 328026,
      "TXT_PINCODE_LOCALITY": "SARMATHURA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2996,
      "City District Name": "DHAULPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 328027,
      "TXT_PINCODE_LOCALITY": "SAIPAU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2996,
      "City District Name": "DHAULPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 328028,
      "TXT_PINCODE_LOCALITY": "KAISAR BAGH DHOLPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2996,
      "City District Name": "DHAULPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 328029,
      "TXT_PINCODE_LOCALITY": "MARENA DHOLPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2996,
      "City District Name": "DHAULPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 328030,
      "TXT_PINCODE_LOCALITY": "PACHGAON",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2996,
      "City District Name": "DHAULPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 328031,
      "TXT_PINCODE_LOCALITY": "BARAULI DHOLPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2996,
      "City District Name": "DHAULPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 328032,
      "TXT_PINCODE_LOCALITY": "DHOLPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1488,
      "City District Name": "DHOLPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 328033,
      "TXT_PINCODE_LOCALITY": "DHOLPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1488,
      "City District Name": "DHOLPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 328034,
      "TXT_PINCODE_LOCALITY": "BARAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1158,
      "City District Name": "BARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 328035,
      "TXT_PINCODE_LOCALITY": "DHOLPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1488,
      "City District Name": "DHOLPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 328036,
      "TXT_PINCODE_LOCALITY": "DHOLPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1488,
      "City District Name": "DHOLPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 328037,
      "TXT_PINCODE_LOCALITY": "AMBERPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2996,
      "City District Name": "DHAULPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 328038,
      "TXT_PINCODE_LOCALITY": "DHOLPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1488,
      "City District Name": "DHOLPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 328039,
      "TXT_PINCODE_LOCALITY": "SALEMBAD DHOLPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2996,
      "City District Name": "DHAULPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 328040,
      "TXT_PINCODE_LOCALITY": "DHOLPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1488,
      "City District Name": "DHOLPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 328041,
      "TXT_PINCODE_LOCALITY": "KANCHANPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2996,
      "City District Name": "DHAULPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 900082,
      "TXT_PINCODE_LOCALITY": "KOREA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 5298,
      "City District Name": "RAMANUJGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 900850,
      "TXT_PINCODE_LOCALITY": "KOREA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424465,
      "City District Name": "KOREA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 911001,
      "TXT_PINCODE_LOCALITY": "AMBALA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 6528,
      "City District Name": "AMBALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 911003,
      "TXT_PINCODE_LOCALITY": "FARIDABAD",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 696,
      "City District Name": "FARIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 911005,
      "TXT_PINCODE_LOCALITY": "GURGAON",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 690,
      "City District Name": "GURGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 911006,
      "TXT_PINCODE_LOCALITY": "HISAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 3423,
      "City District Name": "HISAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 911007,
      "TXT_PINCODE_LOCALITY": "JHAJJAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 703,
      "City District Name": "JHAJJAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 911009,
      "TXT_PINCODE_LOCALITY": "KAITHAL",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1831,
      "City District Name": "KAITHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 911010,
      "TXT_PINCODE_LOCALITY": "KARNAL",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 697,
      "City District Name": "KARNAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 911011,
      "TXT_PINCODE_LOCALITY": "KURUKSHETRA",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 705,
      "City District Name": "KURUKSHETRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 911015,
      "TXT_PINCODE_LOCALITY": "REWARI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 691,
      "City District Name": "REWARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 911016,
      "TXT_PINCODE_LOCALITY": "ROHTAK",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 1815,
      "City District Name": "ROHTAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 911019,
      "TXT_PINCODE_LOCALITY": "YAMUNANAGAR",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 694,
      "City District Name": "YAMUNANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 912001,
      "TXT_PINCODE_LOCALITY": "BILASPUR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 912002,
      "TXT_PINCODE_LOCALITY": "CHAMBA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6473,
      "City District Name": "CHAMBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 912003,
      "TXT_PINCODE_LOCALITY": "HAMIRPUR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 912004,
      "TXT_PINCODE_LOCALITY": "KANGRA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1847,
      "City District Name": "KANGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 912005,
      "TXT_PINCODE_LOCALITY": "KINNAUR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6352,
      "City District Name": "KINNAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 912006,
      "TXT_PINCODE_LOCALITY": "KULLU",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4138,
      "City District Name": "KULLU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 912008,
      "TXT_PINCODE_LOCALITY": "MANDI",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 4398,
      "City District Name": "MANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 912009,
      "TXT_PINCODE_LOCALITY": "SHIMLA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1857,
      "City District Name": "SHIMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 912011,
      "TXT_PINCODE_LOCALITY": "SOLAN",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1845,
      "City District Name": "SOLAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 912012,
      "TXT_PINCODE_LOCALITY": "UNA",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1729,
      "City District Name": "UNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 912115,
      "TXT_PINCODE_LOCALITY": "SAMBA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5471,
      "City District Name": "SAMBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 913001,
      "TXT_PINCODE_LOCALITY": "ANANTNAG",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2057,
      "City District Name": "ANANTNAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 913002,
      "TXT_PINCODE_LOCALITY": "BADGAM",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2208,
      "City District Name": "BADGAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 913004,
      "TXT_PINCODE_LOCALITY": "DODA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3045,
      "City District Name": "DODA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 913005,
      "TXT_PINCODE_LOCALITY": "JAMMU",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1897,
      "City District Name": "JAMMU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 913007,
      "TXT_PINCODE_LOCALITY": "KATHUA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 3848,
      "City District Name": "KATHUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 913008,
      "TXT_PINCODE_LOCALITY": "KUPWARA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 4164,
      "City District Name": "KUPWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 913010,
      "TXT_PINCODE_LOCALITY": "PULWAMA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 5202,
      "City District Name": "PULWAMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 913013,
      "TXT_PINCODE_LOCALITY": "SRINAGAR",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 1883,
      "City District Name": "SRINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 913014,
      "TXT_PINCODE_LOCALITY": "UDHAMPUR",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 6033,
      "City District Name": "UDHAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 913417,
      "TXT_PINCODE_LOCALITY": "MULEMANCHI HAMA",
      "NUM_STATE_CD": 149,
      "State Name": "JAMMU & KASHMIR",
      "NUM_CITYDISTRICT_CD": 2404,
      "City District Name": "BEERWAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 913859,
      "TXT_PINCODE_LOCALITY": "WEST KAMENG",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 424409,
      "City District Name": "WEST KAMENG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 914001,
      "TXT_PINCODE_LOCALITY": "AMRITSAR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 229,
      "City District Name": "AMRITSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 914003,
      "TXT_PINCODE_LOCALITY": "FARIDKOT",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1043,
      "City District Name": "FARIDKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 914004,
      "TXT_PINCODE_LOCALITY": "FATEHGARH SAHIB",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1301,
      "City District Name": "FATEHGARH SAHIB",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 914006,
      "TXT_PINCODE_LOCALITY": "GURDASPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3330,
      "City District Name": "GURDASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 914007,
      "TXT_PINCODE_LOCALITY": "HOSHIARPUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 359,
      "City District Name": "HOSHIARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 914008,
      "TXT_PINCODE_LOCALITY": "JALANDHAR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 231,
      "City District Name": "JALANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 914009,
      "TXT_PINCODE_LOCALITY": "KAPURTHALA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1817,
      "City District Name": "KAPURTHALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 914010,
      "TXT_PINCODE_LOCALITY": "LUDHIANA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 201,
      "City District Name": "LUDHIANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 914011,
      "TXT_PINCODE_LOCALITY": "MANSA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 979,
      "City District Name": "MANSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 914012,
      "TXT_PINCODE_LOCALITY": "MOGA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 707,
      "City District Name": "MOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 914013,
      "TXT_PINCODE_LOCALITY": "MUKTSAR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 832,
      "City District Name": "MUKTSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 914014,
      "TXT_PINCODE_LOCALITY": "NAWANSHAHR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1710,
      "City District Name": "NAWANSHAHR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 914015,
      "TXT_PINCODE_LOCALITY": "PATIALA",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 227,
      "City District Name": "PATIALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 914016,
      "TXT_PINCODE_LOCALITY": "RUPNAGAR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 5402,
      "City District Name": "RUPNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 914017,
      "TXT_PINCODE_LOCALITY": "SANGRUR",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 1218,
      "City District Name": "SANGRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 915001,
      "TXT_PINCODE_LOCALITY": "AJMER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 250,
      "City District Name": "AJMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 915002,
      "TXT_PINCODE_LOCALITY": "ALWAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 251,
      "City District Name": "ALWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 915003,
      "TXT_PINCODE_LOCALITY": "BANSWARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 819,
      "City District Name": "BANSWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 915004,
      "TXT_PINCODE_LOCALITY": "BARAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1158,
      "City District Name": "BARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 915005,
      "TXT_PINCODE_LOCALITY": "BARMER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 915006,
      "TXT_PINCODE_LOCALITY": "BHARATPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 244,
      "City District Name": "BHARATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 915007,
      "TXT_PINCODE_LOCALITY": "BHILWARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 915008,
      "TXT_PINCODE_LOCALITY": "BIKANER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 245,
      "City District Name": "BIKANER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 915009,
      "TXT_PINCODE_LOCALITY": "BUNDI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 984,
      "City District Name": "BUNDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 915010,
      "TXT_PINCODE_LOCALITY": "CHITTAURGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 2818,
      "City District Name": "CHITTAURGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 915011,
      "TXT_PINCODE_LOCALITY": "CHURU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 246,
      "City District Name": "CHURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 915012,
      "TXT_PINCODE_LOCALITY": "DAUSA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 858,
      "City District Name": "DAUSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 915013,
      "TXT_PINCODE_LOCALITY": "DHOLPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1488,
      "City District Name": "DHOLPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 915014,
      "TXT_PINCODE_LOCALITY": "DUNGARPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 825,
      "City District Name": "DUNGARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 915015,
      "TXT_PINCODE_LOCALITY": "GANGANAGAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3184,
      "City District Name": "GANGANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 915016,
      "TXT_PINCODE_LOCALITY": "HANUMANGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1584,
      "City District Name": "HANUMANGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 915017,
      "TXT_PINCODE_LOCALITY": "JAIPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 915018,
      "TXT_PINCODE_LOCALITY": "JAISALMER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1591,
      "City District Name": "JAISALMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 915020,
      "TXT_PINCODE_LOCALITY": "JHALAWAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 813,
      "City District Name": "JHALAWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 915022,
      "TXT_PINCODE_LOCALITY": "JODHPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 915023,
      "TXT_PINCODE_LOCALITY": "KARAULI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3800,
      "City District Name": "KARAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 915024,
      "TXT_PINCODE_LOCALITY": "KOTA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 154,
      "City District Name": "KOTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 915025,
      "TXT_PINCODE_LOCALITY": "NAGAUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 915026,
      "TXT_PINCODE_LOCALITY": "PALI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 6421,
      "City District Name": "PALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 915027,
      "TXT_PINCODE_LOCALITY": "RAJSAMAND",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 896,
      "City District Name": "RAJSAMAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 915029,
      "TXT_PINCODE_LOCALITY": "SIKAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 915030,
      "TXT_PINCODE_LOCALITY": "SIROHI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 933,
      "City District Name": "SIROHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 915031,
      "TXT_PINCODE_LOCALITY": "TONK",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 850,
      "City District Name": "TONK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 915032,
      "TXT_PINCODE_LOCALITY": "UDAIPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916001,
      "TXT_PINCODE_LOCALITY": "AGRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 329,
      "City District Name": "AGRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916002,
      "TXT_PINCODE_LOCALITY": "ALIGARH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 354,
      "City District Name": "ALIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916003,
      "TXT_PINCODE_LOCALITY": "ALLAHABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 355,
      "City District Name": "ALLAHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916005,
      "TXT_PINCODE_LOCALITY": "AURAIYA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2154,
      "City District Name": "AURAIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916006,
      "TXT_PINCODE_LOCALITY": "AZAMGARH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1312,
      "City District Name": "AZAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916007,
      "TXT_PINCODE_LOCALITY": "BAGHPAT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 853,
      "City District Name": "BAGHPAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916008,
      "TXT_PINCODE_LOCALITY": "BAHRAICH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2241,
      "City District Name": "BAHRAICH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916009,
      "TXT_PINCODE_LOCALITY": "BALLIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2282,
      "City District Name": "BALLIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916010,
      "TXT_PINCODE_LOCALITY": "BALRAMPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2287,
      "City District Name": "BALRAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916011,
      "TXT_PINCODE_LOCALITY": "BANDA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6326,
      "City District Name": "BANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916012,
      "TXT_PINCODE_LOCALITY": "BARABANKI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1313,
      "City District Name": "BARABANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916013,
      "TXT_PINCODE_LOCALITY": "BAREILLY",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 330,
      "City District Name": "BAREILLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916014,
      "TXT_PINCODE_LOCALITY": "BASTI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2391,
      "City District Name": "BASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916015,
      "TXT_PINCODE_LOCALITY": "BIJNOR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 356,
      "City District Name": "BIJNOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916016,
      "TXT_PINCODE_LOCALITY": "BUDAUN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 440710,
      "City District Name": "BUDAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916018,
      "TXT_PINCODE_LOCALITY": "CHANDAULI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 2685,
      "City District Name": "CHANDAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916020,
      "TXT_PINCODE_LOCALITY": "DEORIA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1630,
      "City District Name": "DEORIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916021,
      "TXT_PINCODE_LOCALITY": "ETAH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3115,
      "City District Name": "ETAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916022,
      "TXT_PINCODE_LOCALITY": "ETAWAH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3116,
      "City District Name": "ETAWAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916023,
      "TXT_PINCODE_LOCALITY": "FAIZABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 842,
      "City District Name": "FAIZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916024,
      "TXT_PINCODE_LOCALITY": "FARRUKHABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 442997,
      "City District Name": "FARRUKHABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916025,
      "TXT_PINCODE_LOCALITY": "FATEHPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1770,
      "City District Name": "FATEHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916026,
      "TXT_PINCODE_LOCALITY": "FIROZABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 939,
      "City District Name": "FIROZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916027,
      "TXT_PINCODE_LOCALITY": "GAUTAM BUDDHA NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1086,
      "City District Name": "GAUTAM BUDH NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916028,
      "TXT_PINCODE_LOCALITY": "GHAZIABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1861,
      "City District Name": "GHAZIABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916029,
      "TXT_PINCODE_LOCALITY": "GHAZIPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3233,
      "City District Name": "GHAZIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916030,
      "TXT_PINCODE_LOCALITY": "GONDA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1314,
      "City District Name": "GONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916031,
      "TXT_PINCODE_LOCALITY": "GORAKHPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 358,
      "City District Name": "GORAKHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916032,
      "TXT_PINCODE_LOCALITY": "HAMIRPUR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 1846,
      "City District Name": "HAMIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916033,
      "TXT_PINCODE_LOCALITY": "HARDOI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3365,
      "City District Name": "HARDOI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916035,
      "TXT_PINCODE_LOCALITY": "JALAUN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 3518,
      "City District Name": "JALAUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916036,
      "TXT_PINCODE_LOCALITY": "JAUNPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1355,
      "City District Name": "JAUNPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916037,
      "TXT_PINCODE_LOCALITY": "JHANSI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 360,
      "City District Name": "JHANSI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916042,
      "TXT_PINCODE_LOCALITY": "KAUSHAMBI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6339,
      "City District Name": "KAUSHAMBI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916043,
      "TXT_PINCODE_LOCALITY": "KUSHINAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4186,
      "City District Name": "KUSHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916044,
      "TXT_PINCODE_LOCALITY": "LAKHIMPUR KHERI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 874,
      "City District Name": "LAKHIMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916045,
      "TXT_PINCODE_LOCALITY": "LALITPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1801,
      "City District Name": "LALITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916046,
      "TXT_PINCODE_LOCALITY": "LUCKNOW",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 200,
      "City District Name": "LUCKNOW",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916047,
      "TXT_PINCODE_LOCALITY": "MAHARAJGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4305,
      "City District Name": "MAHARAJGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916048,
      "TXT_PINCODE_LOCALITY": "MAHOBA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4321,
      "City District Name": "MAHOBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916049,
      "TXT_PINCODE_LOCALITY": "MAINPURI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4339,
      "City District Name": "MAINPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916050,
      "TXT_PINCODE_LOCALITY": "MATHURA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 361,
      "City District Name": "MATHURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916051,
      "TXT_PINCODE_LOCALITY": "MAUNATH BHANJAN",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1484,
      "City District Name": "MAUNATH BHANJAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916052,
      "TXT_PINCODE_LOCALITY": "MEERUT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 350,
      "City District Name": "MEERUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916053,
      "TXT_PINCODE_LOCALITY": "MIRZAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 885,
      "City District Name": "MIRZAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916054,
      "TXT_PINCODE_LOCALITY": "MORADABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 764,
      "City District Name": "MORADABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916055,
      "TXT_PINCODE_LOCALITY": "MUZAFFARNAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4635,
      "City District Name": "MUZAFFARNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916056,
      "TXT_PINCODE_LOCALITY": "PILIBHIT",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 363,
      "City District Name": "PILIBHIT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916057,
      "TXT_PINCODE_LOCALITY": "PRATAPGARH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1020,
      "City District Name": "PRATAPGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916058,
      "TXT_PINCODE_LOCALITY": "RAE BARELI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 5247,
      "City District Name": "RAE BARELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916059,
      "TXT_PINCODE_LOCALITY": "RAMPUR",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6437,
      "City District Name": "RAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916060,
      "TXT_PINCODE_LOCALITY": "SAHARANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 352,
      "City District Name": "SAHARANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916061,
      "TXT_PINCODE_LOCALITY": "SANT KABIR NAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6535,
      "City District Name": "SANT KABIR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916063,
      "TXT_PINCODE_LOCALITY": "SHAHJAHANPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 6315,
      "City District Name": "SHAHJAHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916065,
      "TXT_PINCODE_LOCALITY": "SIDDHARTHNAGAR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 441043,
      "City District Name": "SIDDHARTHNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916066,
      "TXT_PINCODE_LOCALITY": "SITAPUR",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 1388,
      "City District Name": "SITAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916067,
      "TXT_PINCODE_LOCALITY": "SONBHADRA",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 974,
      "City District Name": "SONEBHADRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916068,
      "TXT_PINCODE_LOCALITY": "SULTANPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 425836,
      "City District Name": "SULTANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916069,
      "TXT_PINCODE_LOCALITY": "UNNAO",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 367,
      "City District Name": "UNNAO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 916070,
      "TXT_PINCODE_LOCALITY": "VARANASI",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 368,
      "City District Name": "VARANASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 917001,
      "TXT_PINCODE_LOCALITY": "ALMORA",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 442073,
      "City District Name": "ALMORA H. O",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 917002,
      "TXT_PINCODE_LOCALITY": "BAGESHWAR",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 441832,
      "City District Name": "BAGESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 917003,
      "TXT_PINCODE_LOCALITY": "CHAMOLI",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443064,
      "City District Name": "CHAMOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 917004,
      "TXT_PINCODE_LOCALITY": "CHAMPAWAT",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443065,
      "City District Name": "CHAMPAWAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 917005,
      "TXT_PINCODE_LOCALITY": "DEHRADUN",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 441220,
      "City District Name": "DEHRADUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 917006,
      "TXT_PINCODE_LOCALITY": "PAURI",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443102,
      "City District Name": "PAURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 917007,
      "TXT_PINCODE_LOCALITY": "HARIDWAR",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 441977,
      "City District Name": "HARIDWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 917008,
      "TXT_PINCODE_LOCALITY": "NAINITAL",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 441374,
      "City District Name": "NAINITAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 917009,
      "TXT_PINCODE_LOCALITY": "PITHORAGARH",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 443103,
      "City District Name": "PITHORAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 917013,
      "TXT_PINCODE_LOCALITY": "UTTARKASHI",
      "NUM_STATE_CD": 342,
      "State Name": "UTTARAKHAND",
      "NUM_CITYDISTRICT_CD": 444129,
      "City District Name": "UTTARKASHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 920001,
      "TXT_PINCODE_LOCALITY": "BASTAR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424423,
      "City District Name": "BASTAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 920002,
      "TXT_PINCODE_LOCALITY": "BILASPUR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 920003,
      "TXT_PINCODE_LOCALITY": "DANTEWADA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2880,
      "City District Name": "DANTEWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 920005,
      "TXT_PINCODE_LOCALITY": "DURG",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 920006,
      "TXT_PINCODE_LOCALITY": "JANJGIR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 441173,
      "City District Name": "JANJGIR - CHAMPA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 920007,
      "TXT_PINCODE_LOCALITY": "JASHPUR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3551,
      "City District Name": "JASHPUR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 920013,
      "TXT_PINCODE_LOCALITY": "RAIGARH",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6350,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 920014,
      "TXT_PINCODE_LOCALITY": "RAIPUR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 920015,
      "TXT_PINCODE_LOCALITY": "RAJNANDGAON",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 5283,
      "City District Name": "RAJNANDGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 922001,
      "TXT_PINCODE_LOCALITY": "AHMADABAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 442294,
      "City District Name": "AHMADABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 922002,
      "TXT_PINCODE_LOCALITY": "AMRELI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 922003,
      "TXT_PINCODE_LOCALITY": "ANAND",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 922005,
      "TXT_PINCODE_LOCALITY": "BHARUCH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 922006,
      "TXT_PINCODE_LOCALITY": "BHAVNAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 922008,
      "TXT_PINCODE_LOCALITY": "GANDHINAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 922009,
      "TXT_PINCODE_LOCALITY": "JAMNAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 922010,
      "TXT_PINCODE_LOCALITY": "JUNAGADH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 922012,
      "TXT_PINCODE_LOCALITY": "KHEDA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 922013,
      "TXT_PINCODE_LOCALITY": "MEHSANA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 681,
      "City District Name": "MEHSANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 922014,
      "TXT_PINCODE_LOCALITY": "NARMADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424442,
      "City District Name": "NARMADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 922017,
      "TXT_PINCODE_LOCALITY": "PATAN",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 425772,
      "City District Name": "PATAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 922019,
      "TXT_PINCODE_LOCALITY": "RAJKOT",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 922021,
      "TXT_PINCODE_LOCALITY": "SURAT",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 922022,
      "TXT_PINCODE_LOCALITY": "SURENDRANAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 922023,
      "TXT_PINCODE_LOCALITY": "THE DANGS",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424445,
      "City District Name": "THE DANGS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 922024,
      "TXT_PINCODE_LOCALITY": "VADODARA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 922025,
      "TXT_PINCODE_LOCALITY": "VALSAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 922119,
      "TXT_PINCODE_LOCALITY": "TISIA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 442766,
      "City District Name": "MAHUADANR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 923001,
      "TXT_PINCODE_LOCALITY": "BARWANI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1780,
      "City District Name": "BARWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 923002,
      "TXT_PINCODE_LOCALITY": "BALAGHAT",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1550,
      "City District Name": "BALAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 923003,
      "TXT_PINCODE_LOCALITY": "BETUL",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 596,
      "City District Name": "BETUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 923004,
      "TXT_PINCODE_LOCALITY": "BHIND",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 976,
      "City District Name": "BHIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 923005,
      "TXT_PINCODE_LOCALITY": "BHOPAL",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 923006,
      "TXT_PINCODE_LOCALITY": "CHHATARPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2760,
      "City District Name": "CHHATARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 923007,
      "TXT_PINCODE_LOCALITY": "CHHINDWARA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 923008,
      "TXT_PINCODE_LOCALITY": "DAMOH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2874,
      "City District Name": "DAMOH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 923009,
      "TXT_PINCODE_LOCALITY": "DATIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1386,
      "City District Name": "DATIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 923010,
      "TXT_PINCODE_LOCALITY": "DEWAS",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 923011,
      "TXT_PINCODE_LOCALITY": "DHAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 599,
      "City District Name": "DHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 923012,
      "TXT_PINCODE_LOCALITY": "DINDORI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 3036,
      "City District Name": "DINDORI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 923013,
      "TXT_PINCODE_LOCALITY": "GUNA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1825,
      "City District Name": "GUNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 923014,
      "TXT_PINCODE_LOCALITY": "GWALIOR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 600,
      "City District Name": "GWALIOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 923015,
      "TXT_PINCODE_LOCALITY": "HARDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 910,
      "City District Name": "HARDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 923016,
      "TXT_PINCODE_LOCALITY": "HOSHANGABAD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 602,
      "City District Name": "HOSHANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 923017,
      "TXT_PINCODE_LOCALITY": "INDORE",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 923018,
      "TXT_PINCODE_LOCALITY": "JABALPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 464,
      "City District Name": "JABALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 923019,
      "TXT_PINCODE_LOCALITY": "JHABUA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1243,
      "City District Name": "JHABUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 923020,
      "TXT_PINCODE_LOCALITY": "KATNI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1361,
      "City District Name": "KATNI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 923021,
      "TXT_PINCODE_LOCALITY": "MANDLA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4400,
      "City District Name": "MANDLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 923022,
      "TXT_PINCODE_LOCALITY": "MANDSAUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 923023,
      "TXT_PINCODE_LOCALITY": "MORENA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 923028,
      "TXT_PINCODE_LOCALITY": "PANNA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4989,
      "City District Name": "PANNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 923029,
      "TXT_PINCODE_LOCALITY": "RAISEN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 871,
      "City District Name": "RAISEN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 923030,
      "TXT_PINCODE_LOCALITY": "RAJGARH",
      "NUM_STATE_CD": 148,
      "State Name": "HIMACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6430,
      "City District Name": "RAJGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 923031,
      "TXT_PINCODE_LOCALITY": "RATLAM",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 470,
      "City District Name": "RATLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 923032,
      "TXT_PINCODE_LOCALITY": "REWA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 923033,
      "TXT_PINCODE_LOCALITY": "SAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 6440,
      "City District Name": "SAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 923034,
      "TXT_PINCODE_LOCALITY": "SATNA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1166,
      "City District Name": "SATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 923035,
      "TXT_PINCODE_LOCALITY": "SEHORE",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 474,
      "City District Name": "SEHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 923036,
      "TXT_PINCODE_LOCALITY": "SEONI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 6351,
      "City District Name": "SEONI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 923037,
      "TXT_PINCODE_LOCALITY": "SHAHDOL",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 475,
      "City District Name": "SHAHDOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 923038,
      "TXT_PINCODE_LOCALITY": "SHAJAPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 836,
      "City District Name": "SHAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 923039,
      "TXT_PINCODE_LOCALITY": "SHEOPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5622,
      "City District Name": "SHEOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 923040,
      "TXT_PINCODE_LOCALITY": "SHIVPURI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1048,
      "City District Name": "SHIVPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 923041,
      "TXT_PINCODE_LOCALITY": "SIDHI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 476,
      "City District Name": "SIDHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 923042,
      "TXT_PINCODE_LOCALITY": "TIKAMGARH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5963,
      "City District Name": "TIKAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 923043,
      "TXT_PINCODE_LOCALITY": "UJJAIN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 477,
      "City District Name": "UJJAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 923045,
      "TXT_PINCODE_LOCALITY": "VIDISHA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 478,
      "City District Name": "VIDISHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 924002,
      "TXT_PINCODE_LOCALITY": "AKOLA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 528,
      "City District Name": "AKOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 924003,
      "TXT_PINCODE_LOCALITY": "AMRAVATI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 924004,
      "TXT_PINCODE_LOCALITY": "AURANGABAD",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2158,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 924005,
      "TXT_PINCODE_LOCALITY": "BHANDARA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 535,
      "City District Name": "BHANDARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 924008,
      "TXT_PINCODE_LOCALITY": "CHANDRAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 924010,
      "TXT_PINCODE_LOCALITY": "DHULE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 924011,
      "TXT_PINCODE_LOCALITY": "GADCHIROLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1786,
      "City District Name": "GADCHIROLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 924012,
      "TXT_PINCODE_LOCALITY": "GONDIYA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 3287,
      "City District Name": "GONDIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 924014,
      "TXT_PINCODE_LOCALITY": "HINGOLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1050,
      "City District Name": "HINGOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 924015,
      "TXT_PINCODE_LOCALITY": "JALGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 924016,
      "TXT_PINCODE_LOCALITY": "JALNA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 526,
      "City District Name": "JALNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 924017,
      "TXT_PINCODE_LOCALITY": "KOLHAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 924018,
      "TXT_PINCODE_LOCALITY": "MUMBAI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 924019,
      "TXT_PINCODE_LOCALITY": "LATUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 553,
      "City District Name": "LATUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 924020,
      "TXT_PINCODE_LOCALITY": "NAGPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 924021,
      "TXT_PINCODE_LOCALITY": "NANDED",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 924022,
      "TXT_PINCODE_LOCALITY": "NANDURBAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 815,
      "City District Name": "NANDURBAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 924023,
      "TXT_PINCODE_LOCALITY": "NASHIK",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 924024,
      "TXT_PINCODE_LOCALITY": "PARBHANI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 561,
      "City District Name": "PARBHANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 924025,
      "TXT_PINCODE_LOCALITY": "PUNE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 924026,
      "TXT_PINCODE_LOCALITY": "RAIGARH",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6350,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 924027,
      "TXT_PINCODE_LOCALITY": "RATNAGIRI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 924028,
      "TXT_PINCODE_LOCALITY": "SANGLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 924029,
      "TXT_PINCODE_LOCALITY": "SATARA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 924030,
      "TXT_PINCODE_LOCALITY": "SINDHUDURG",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1702,
      "City District Name": "SINDHUDURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 924031,
      "TXT_PINCODE_LOCALITY": "SOLAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 924032,
      "TXT_PINCODE_LOCALITY": "THANE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 924033,
      "TXT_PINCODE_LOCALITY": "WARDHA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 580,
      "City District Name": "WARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 924034,
      "TXT_PINCODE_LOCALITY": "WASHIM",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1700,
      "City District Name": "WASHIM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 924035,
      "TXT_PINCODE_LOCALITY": "YAVATMAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 928617,
      "TXT_PINCODE_LOCALITY": "VADODARA S.O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 930001,
      "TXT_PINCODE_LOCALITY": "CHANGLANG",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 2701,
      "City District Name": "CHANGLANG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 931001,
      "TXT_PINCODE_LOCALITY": "BARPETA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 425554,
      "City District Name": "BARPPETA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 931002,
      "TXT_PINCODE_LOCALITY": "BONGAIGAON",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 2615,
      "City District Name": "BONGAIGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 931005,
      "TXT_PINCODE_LOCALITY": "DARRANG",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 770,
      "City District Name": "DARRANG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 931006,
      "TXT_PINCODE_LOCALITY": "DHEMAJI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3001,
      "City District Name": "DHEMAJI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 931007,
      "TXT_PINCODE_LOCALITY": "DHUBRI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3009,
      "City District Name": "DHUBRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 931008,
      "TXT_PINCODE_LOCALITY": "DIBRUGARH",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 931009,
      "TXT_PINCODE_LOCALITY": "GOALPARA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3258,
      "City District Name": "GOALPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 931010,
      "TXT_PINCODE_LOCALITY": "GOLAGHAT",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3283,
      "City District Name": "GOLAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 931011,
      "TXT_PINCODE_LOCALITY": "HAILAKANDI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3347,
      "City District Name": "HAILAKANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 931012,
      "TXT_PINCODE_LOCALITY": "JORHAT",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 146,
      "City District Name": "JORHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 931013,
      "TXT_PINCODE_LOCALITY": "KAMRUP",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 931015,
      "TXT_PINCODE_LOCALITY": "KARIMGANJ",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3810,
      "City District Name": "KARIMGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 931016,
      "TXT_PINCODE_LOCALITY": "KOKRAJHAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4034,
      "City District Name": "KOKRAJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 931018,
      "TXT_PINCODE_LOCALITY": "MARIGAON",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4450,
      "City District Name": "MARIGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 931019,
      "TXT_PINCODE_LOCALITY": "NAGAON",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 1778,
      "City District Name": "NAGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 931020,
      "TXT_PINCODE_LOCALITY": "NALBARI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4691,
      "City District Name": "NALBARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 931021,
      "TXT_PINCODE_LOCALITY": "SIBSAGAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 620,
      "City District Name": "SIBSAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 931022,
      "TXT_PINCODE_LOCALITY": "SONITPUR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 931023,
      "TXT_PINCODE_LOCALITY": "TINSUKIA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 622,
      "City District Name": "TINSUKIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 932001,
      "TXT_PINCODE_LOCALITY": "ARARIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1904,
      "City District Name": "ARARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 932002,
      "TXT_PINCODE_LOCALITY": "AURANGABAD",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2158,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 932003,
      "TXT_PINCODE_LOCALITY": "BANKA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2316,
      "City District Name": "BANKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 932004,
      "TXT_PINCODE_LOCALITY": "BEGUSARAI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2411,
      "City District Name": "BEGUSARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 932005,
      "TXT_PINCODE_LOCALITY": "BHABUA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2446,
      "City District Name": "BHABUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 932006,
      "TXT_PINCODE_LOCALITY": "BHAGALPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 441204,
      "City District Name": "BHAGALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 932007,
      "TXT_PINCODE_LOCALITY": "BHOJPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424413,
      "City District Name": "BHOJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 932008,
      "TXT_PINCODE_LOCALITY": "BUXAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2642,
      "City District Name": "BUXAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 932009,
      "TXT_PINCODE_LOCALITY": "CHAMPARAN - EAST",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 932010,
      "TXT_PINCODE_LOCALITY": "CHAMPARAN - WEST",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 932011,
      "TXT_PINCODE_LOCALITY": "DARBHANGA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2884,
      "City District Name": "DARBHANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 932012,
      "TXT_PINCODE_LOCALITY": "GAYA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3216,
      "City District Name": "GAYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 932013,
      "TXT_PINCODE_LOCALITY": "GOPALGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1900,
      "City District Name": "GOPALGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 932014,
      "TXT_PINCODE_LOCALITY": "JAMUI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3539,
      "City District Name": "JAMUI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 932016,
      "TXT_PINCODE_LOCALITY": "KATIHAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3850,
      "City District Name": "KATIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 932017,
      "TXT_PINCODE_LOCALITY": "KHAGARIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3903,
      "City District Name": "KHAGARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 932018,
      "TXT_PINCODE_LOCALITY": "KISHANGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4010,
      "City District Name": "KISHANGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 932020,
      "TXT_PINCODE_LOCALITY": "MADHEPURA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4284,
      "City District Name": "MADHEPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 932021,
      "TXT_PINCODE_LOCALITY": "MADHUBANI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 932022,
      "TXT_PINCODE_LOCALITY": "MUNGER",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4613,
      "City District Name": "MUNGER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 932023,
      "TXT_PINCODE_LOCALITY": "MUZAFFARPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4636,
      "City District Name": "MUZAFFARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 932024,
      "TXT_PINCODE_LOCALITY": "NALANDA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4842,
      "City District Name": "NALANDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 932025,
      "TXT_PINCODE_LOCALITY": "NAWADA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4782,
      "City District Name": "NAWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 932026,
      "TXT_PINCODE_LOCALITY": "PATNA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1858,
      "City District Name": "PATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 932027,
      "TXT_PINCODE_LOCALITY": "PURNIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 932029,
      "TXT_PINCODE_LOCALITY": "SAHARSA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5432,
      "City District Name": "SAHARSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 932030,
      "TXT_PINCODE_LOCALITY": "SAMASTIPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5469,
      "City District Name": "SAMASTIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 932031,
      "TXT_PINCODE_LOCALITY": "SARAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424418,
      "City District Name": "SARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 932032,
      "TXT_PINCODE_LOCALITY": "SHEIKHPURA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5615,
      "City District Name": "SHEIKHPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 932034,
      "TXT_PINCODE_LOCALITY": "SITAMARHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1894,
      "City District Name": "SITAMARHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 932035,
      "TXT_PINCODE_LOCALITY": "SIWAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1866,
      "City District Name": "SIWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 932036,
      "TXT_PINCODE_LOCALITY": "SUPAUL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5796,
      "City District Name": "SUPAUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 932037,
      "TXT_PINCODE_LOCALITY": "VAISHALI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 424419,
      "City District Name": "VAISHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 932313,
      "TXT_PINCODE_LOCALITY": "INFOL",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 1884,
      "City District Name": "IMPHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 933001,
      "TXT_PINCODE_LOCALITY": "BISHNUPUR",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 2593,
      "City District Name": "BISHNUPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 933002,
      "TXT_PINCODE_LOCALITY": "CHANDEL",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 424463,
      "City District Name": "CHANDEL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 933003,
      "TXT_PINCODE_LOCALITY": "CHURACHANDPUR",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 424464,
      "City District Name": "CHURACHANDPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 933004,
      "TXT_PINCODE_LOCALITY": "IMPHAL",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 1884,
      "City District Name": "IMPHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 933005,
      "TXT_PINCODE_LOCALITY": "SENAPATI",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 424461,
      "City District Name": "SENAPATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 933006,
      "TXT_PINCODE_LOCALITY": "TAMENGLONG",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 424459,
      "City District Name": "TAMENGLONG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 933007,
      "TXT_PINCODE_LOCALITY": "THOUBAL",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 5952,
      "City District Name": "THOUBAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 933008,
      "TXT_PINCODE_LOCALITY": "UKHRUL",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 424458,
      "City District Name": "UKHRUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 935001,
      "TXT_PINCODE_LOCALITY": "AIZAWL",
      "NUM_STATE_CD": 152,
      "State Name": "MIZORAM",
      "NUM_CITYDISTRICT_CD": 1953,
      "City District Name": "AIZAWL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 935003,
      "TXT_PINCODE_LOCALITY": "LUNGLEI",
      "NUM_STATE_CD": 152,
      "State Name": "MIZORAM",
      "NUM_CITYDISTRICT_CD": 4270,
      "City District Name": "LUNGLEI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 936001,
      "TXT_PINCODE_LOCALITY": "KOHIMA",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 1887,
      "City District Name": "KOHIMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 936002,
      "TXT_PINCODE_LOCALITY": "MOKOKCHUNG",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 4549,
      "City District Name": "MOKOKCHUNG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 936003,
      "TXT_PINCODE_LOCALITY": "MON",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 637,
      "City District Name": "MON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 936004,
      "TXT_PINCODE_LOCALITY": "PHEK",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 5118,
      "City District Name": "PHEK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 936006,
      "TXT_PINCODE_LOCALITY": "WOKHA",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 6253,
      "City District Name": "WOKHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 937001,
      "TXT_PINCODE_LOCALITY": "ANGUL S.O",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1423,
      "City District Name": "ANGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 937002,
      "TXT_PINCODE_LOCALITY": "BALANGIR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2265,
      "City District Name": "BALANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 937003,
      "TXT_PINCODE_LOCALITY": "BALESHWAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2274,
      "City District Name": "BALESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 937004,
      "TXT_PINCODE_LOCALITY": "BARGARH",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1371,
      "City District Name": "BARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 937006,
      "TXT_PINCODE_LOCALITY": "BHADRAK",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1459,
      "City District Name": "BHADRAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 937007,
      "TXT_PINCODE_LOCALITY": "CUTTACK S.O",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 937009,
      "TXT_PINCODE_LOCALITY": "DHENKANAL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1802,
      "City District Name": "DHENKANAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 937011,
      "TXT_PINCODE_LOCALITY": "GANJAM",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 937013,
      "TXT_PINCODE_LOCALITY": "JAJPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1007,
      "City District Name": "JAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 937014,
      "TXT_PINCODE_LOCALITY": "JHARSUGUDA S.O",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1775,
      "City District Name": "JHARSUGUDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 937015,
      "TXT_PINCODE_LOCALITY": "KALAHANDI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 222,
      "City District Name": "KALAHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 937016,
      "TXT_PINCODE_LOCALITY": "KENDRAPARA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3887,
      "City District Name": "KENDRAPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 937017,
      "TXT_PINCODE_LOCALITY": "KENDUJHAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 425482,
      "City District Name": "KENDUJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 937018,
      "TXT_PINCODE_LOCALITY": "KHORDHA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3980,
      "City District Name": "KHORDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 937019,
      "TXT_PINCODE_LOCALITY": "KORAPUT",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 937020,
      "TXT_PINCODE_LOCALITY": "MALKANGIRI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 4362,
      "City District Name": "MALKANGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 937021,
      "TXT_PINCODE_LOCALITY": "MAYURBHANJ",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 425720,
      "City District Name": "MAYURBHANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 937023,
      "TXT_PINCODE_LOCALITY": "NAYAGARH",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1782,
      "City District Name": "NAYAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 937026,
      "TXT_PINCODE_LOCALITY": "PURI S.O",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 224,
      "City District Name": "PURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 937027,
      "TXT_PINCODE_LOCALITY": "RAYAGADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5357,
      "City District Name": "RAYAGADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 937028,
      "TXT_PINCODE_LOCALITY": "SAMBALPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 937029,
      "TXT_PINCODE_LOCALITY": "SONAPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 937030,
      "TXT_PINCODE_LOCALITY": "SUNDARGARH",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 939001,
      "TXT_PINCODE_LOCALITY": "BANKURA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 439,
      "City District Name": "BANKURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 939003,
      "TXT_PINCODE_LOCALITY": "BIRBHUM",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1276,
      "City District Name": "BIRBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 939007,
      "TXT_PINCODE_LOCALITY": "HOWRAH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 432,
      "City District Name": "HOWRAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 939009,
      "TXT_PINCODE_LOCALITY": "JALPAIGURI",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 446,
      "City District Name": "JALPAIGURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 939011,
      "TXT_PINCODE_LOCALITY": "KOLKATA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 199,
      "City District Name": "KOLKATA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 939012,
      "TXT_PINCODE_LOCALITY": "MALDAH",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 449,
      "City District Name": "MALDAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 939014,
      "TXT_PINCODE_LOCALITY": "MURSHIDABAD",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 1771,
      "City District Name": "MURSHIDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 939015,
      "TXT_PINCODE_LOCALITY": "NADIA",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 870,
      "City District Name": "NADIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 940001,
      "TXT_PINCODE_LOCALITY": "ADILABAD",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 940002,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 940003,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 940004,
      "TXT_PINCODE_LOCALITY": "CUDDAPAH",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 940007,
      "TXT_PINCODE_LOCALITY": "GUNTUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 940008,
      "TXT_PINCODE_LOCALITY": "HYDERABAD",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 940009,
      "TXT_PINCODE_LOCALITY": "KARIMNAGAR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 940010,
      "TXT_PINCODE_LOCALITY": "KHAMMAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 940011,
      "TXT_PINCODE_LOCALITY": "KRISHNA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 940012,
      "TXT_PINCODE_LOCALITY": "KURNOOL",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 940014,
      "TXT_PINCODE_LOCALITY": "MEDAK",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 940015,
      "TXT_PINCODE_LOCALITY": "NALGONDA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 940016,
      "TXT_PINCODE_LOCALITY": "NELLORE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 940017,
      "TXT_PINCODE_LOCALITY": "NIZAMABAD",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 940018,
      "TXT_PINCODE_LOCALITY": "PRAKASAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 940020,
      "TXT_PINCODE_LOCALITY": "SRIKAKULAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 940021,
      "TXT_PINCODE_LOCALITY": "VISAKHAPATNAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442245,
      "City District Name": "VISAKHAPATNAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 940022,
      "TXT_PINCODE_LOCALITY": "VIZIANAGARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 940023,
      "TXT_PINCODE_LOCALITY": "WARANGAL",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 941001,
      "TXT_PINCODE_LOCALITY": "BAGALKOT",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 941002,
      "TXT_PINCODE_LOCALITY": "BANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 941004,
      "TXT_PINCODE_LOCALITY": "BELGAUM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 941005,
      "TXT_PINCODE_LOCALITY": "BELLARY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 941006,
      "TXT_PINCODE_LOCALITY": "BIDAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 941007,
      "TXT_PINCODE_LOCALITY": "BIJAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 941008,
      "TXT_PINCODE_LOCALITY": "CHAMRAJNAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2682,
      "City District Name": "CHAMRAJNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 941009,
      "TXT_PINCODE_LOCALITY": "CHIKMAGALUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 941010,
      "TXT_PINCODE_LOCALITY": "CHITRADURGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 941012,
      "TXT_PINCODE_LOCALITY": "DHARWAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 941013,
      "TXT_PINCODE_LOCALITY": "GADAG",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 941014,
      "TXT_PINCODE_LOCALITY": "GULBARGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 941015,
      "TXT_PINCODE_LOCALITY": "HASSAN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 941016,
      "TXT_PINCODE_LOCALITY": "HAVERI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 941019,
      "TXT_PINCODE_LOCALITY": "KODAGU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 941020,
      "TXT_PINCODE_LOCALITY": "KOLAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 941021,
      "TXT_PINCODE_LOCALITY": "KOPPAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 941022,
      "TXT_PINCODE_LOCALITY": "MANDYA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 941023,
      "TXT_PINCODE_LOCALITY": "MYSORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 941024,
      "TXT_PINCODE_LOCALITY": "RAICHUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 941025,
      "TXT_PINCODE_LOCALITY": "SHIMOGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 941026,
      "TXT_PINCODE_LOCALITY": "TUMKUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 941027,
      "TXT_PINCODE_LOCALITY": "UDUPI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 942001,
      "TXT_PINCODE_LOCALITY": "ALAPPUZHA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 942002,
      "TXT_PINCODE_LOCALITY": "ERNAKULAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 942003,
      "TXT_PINCODE_LOCALITY": "IDUKKI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 942004,
      "TXT_PINCODE_LOCALITY": "KANNUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 942005,
      "TXT_PINCODE_LOCALITY": "KASARAGOD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 3831,
      "City District Name": "KASARAGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 942006,
      "TXT_PINCODE_LOCALITY": "KOLLAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 942007,
      "TXT_PINCODE_LOCALITY": "KOTTAYAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 942008,
      "TXT_PINCODE_LOCALITY": "KOZHIKODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 942009,
      "TXT_PINCODE_LOCALITY": "MALAPPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 942010,
      "TXT_PINCODE_LOCALITY": "PALAKKAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 942011,
      "TXT_PINCODE_LOCALITY": "PATHANAMTHITTA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 942012,
      "TXT_PINCODE_LOCALITY": "THIRUVANANTHAPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 942013,
      "TXT_PINCODE_LOCALITY": "THRISSUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 942014,
      "TXT_PINCODE_LOCALITY": "WAYANAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1905,
      "City District Name": "WAYANAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 943001,
      "TXT_PINCODE_LOCALITY": "ARIYALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2107,
      "City District Name": "ARIYALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 943002,
      "TXT_PINCODE_LOCALITY": "CHENNAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 943003,
      "TXT_PINCODE_LOCALITY": "COIMBATORE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 943004,
      "TXT_PINCODE_LOCALITY": "CUDDALORE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 943005,
      "TXT_PINCODE_LOCALITY": "DHARMAPURI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 943006,
      "TXT_PINCODE_LOCALITY": "DINDIGUL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 943007,
      "TXT_PINCODE_LOCALITY": "ERODE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 293,
      "City District Name": "ERODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 943008,
      "TXT_PINCODE_LOCALITY": "KANCHIPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 441239,
      "City District Name": "KANCHIPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 943010,
      "TXT_PINCODE_LOCALITY": "KARUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 943011,
      "TXT_PINCODE_LOCALITY": "MADURAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 943012,
      "TXT_PINCODE_LOCALITY": "NAGAPATTINAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 943013,
      "TXT_PINCODE_LOCALITY": "NAMAKKAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 943015,
      "TXT_PINCODE_LOCALITY": "PERAMBALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5085,
      "City District Name": "PERAMBALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 943016,
      "TXT_PINCODE_LOCALITY": "PUDUKKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 943017,
      "TXT_PINCODE_LOCALITY": "RAMANATHAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 943018,
      "TXT_PINCODE_LOCALITY": "SALEM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 302,
      "City District Name": "SALEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 943019,
      "TXT_PINCODE_LOCALITY": "SIVAGANGA",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 943021,
      "TXT_PINCODE_LOCALITY": "THANJAVUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 943022,
      "TXT_PINCODE_LOCALITY": "THIRUVARUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 943024,
      "TXT_PINCODE_LOCALITY": "TIRUNELVELI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 943025,
      "TXT_PINCODE_LOCALITY": "TIRUVALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6320,
      "City District Name": "TIRUVALLUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 943026,
      "TXT_PINCODE_LOCALITY": "TIRUVANNAMALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 943027,
      "TXT_PINCODE_LOCALITY": "TUTICORIN",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1893,
      "City District Name": "TUTICORIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 943028,
      "TXT_PINCODE_LOCALITY": "VELLORE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 943029,
      "TXT_PINCODE_LOCALITY": "VILUPPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 442979,
      "City District Name": "VILUPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 955018,
      "TXT_PINCODE_LOCALITY": "JAJPUR ROAD",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1007,
      "City District Name": "JAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 570001,
      "TXT_PINCODE_LOCALITY": "DEVARAJA MOHALL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 570002,
      "TXT_PINCODE_LOCALITY": "SRI KRISHNARAJENDRA MILLS",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 570003,
      "TXT_PINCODE_LOCALITY": "S.K.R. MILLS",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 570004,
      "TXT_PINCODE_LOCALITY": "NANJUNDAL CIRCLE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 570005,
      "TXT_PINCODE_LOCALITY": "MYSORE VANI VILAS MOHALLA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 570006,
      "TXT_PINCODE_LOCALITY": "UNIVERSITY CAMPUS",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 570007,
      "TXT_PINCODE_LOCALITY": "RAJENDRANAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 570008,
      "TXT_PINCODE_LOCALITY": "ASOKAPURAM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 570009,
      "TXT_PINCODE_LOCALITY": "TONACHIKOPPAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 570010,
      "TXT_PINCODE_LOCALITY": "ITTIGEGUD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 570011,
      "TXT_PINCODE_LOCALITY": "JYOTHINAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 570012,
      "TXT_PINCODE_LOCALITY": "PURAM EXTENSION",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 570013,
      "TXT_PINCODE_LOCALITY": "FOOD TECHNOLOGY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 570014,
      "TXT_PINCODE_LOCALITY": "JAYANAGAR EXTN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 570015,
      "TXT_PINCODE_LOCALITY": "NEW BANIMANTAP EXTN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 570016,
      "TXT_PINCODE_LOCALITY": "METGALLI EXTN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 570017,
      "TXT_PINCODE_LOCALITY": "HINKAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 570018,
      "TXT_PINCODE_LOCALITY": "CHAMUNDI BETTA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 570019,
      "TXT_PINCODE_LOCALITY": "UDAYAGIRI EXTN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 570020,
      "TXT_PINCODE_LOCALITY": "RAMAKRISHNA VIDYA SHALA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 570021,
      "TXT_PINCODE_LOCALITY": "MANDI MOHALLA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 570022,
      "TXT_PINCODE_LOCALITY": "NIMISHAMBA NAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 570023,
      "TXT_PINCODE_LOCALITY": "KUVEMPUNAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 570024,
      "TXT_PINCODE_LOCALITY": "M.R.MOHALLA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 570025,
      "TXT_PINCODE_LOCALITY": "J.S. ASHRAM MARG",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 570026,
      "TXT_PINCODE_LOCALITY": "BOGADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 570027,
      "TXT_PINCODE_LOCALITY": "INFOSYS CAMPUS S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 570028,
      "TXT_PINCODE_LOCALITY": "ALANAHALLI S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 570029,
      "TXT_PINCODE_LOCALITY": "SHAKTHINAGAR S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 570030,
      "TXT_PINCODE_LOCALITY": "VIJAYANAGAR III STAGE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 570031,
      "TXT_PINCODE_LOCALITY": "J.P.NAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 570032,
      "TXT_PINCODE_LOCALITY": "MYSURU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 570033,
      "TXT_PINCODE_LOCALITY": "SALUNDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 444198,
      "City District Name": "MYSURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 570034,
      "TXT_PINCODE_LOCALITY": "SRI RAMPURA 2ND STAGE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 444198,
      "City District Name": "MYSURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 570123,
      "TXT_PINCODE_LOCALITY": "YELLANDU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6262,
      "City District Name": "YELLANDU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571001,
      "TXT_PINCODE_LOCALITY": "SHAMSHIPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 6478,
      "City District Name": "HARIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571006,
      "TXT_PINCODE_LOCALITY": "BHOGADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571018,
      "TXT_PINCODE_LOCALITY": "VIJAYANAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571040,
      "TXT_PINCODE_LOCALITY": "MAYAPPANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571060,
      "TXT_PINCODE_LOCALITY": "PURA (S)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 4116,
      "City District Name": "KRISHNARAJPET",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571101,
      "TXT_PINCODE_LOCALITY": "BANNUR S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571102,
      "TXT_PINCODE_LOCALITY": "BETTADAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571103,
      "TXT_PINCODE_LOCALITY": "BILIKERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571104,
      "TXT_PINCODE_LOCALITY": "BYALAKUPPE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571105,
      "TXT_PINCODE_LOCALITY": "HUNSUR BRIDGE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571106,
      "TXT_PINCODE_LOCALITY": "HUNSUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1265,
      "City District Name": "HUNSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571107,
      "TXT_PINCODE_LOCALITY": "PERIYAPATNA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571108,
      "TXT_PINCODE_LOCALITY": "RAVANDUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571109,
      "TXT_PINCODE_LOCALITY": "BEGUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571110,
      "TXT_PINCODE_LOCALITY": "GARGESHWARI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571111,
      "TXT_PINCODE_LOCALITY": "GUNDLUPET BAZAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571114,
      "TXT_PINCODE_LOCALITY": "H D KOTE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571115,
      "TXT_PINCODE_LOCALITY": "SANTEMARAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571116,
      "TXT_PINCODE_LOCALITY": "KABINI COLONY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571117,
      "TXT_PINCODE_LOCALITY": "KAGALYADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571118,
      "TXT_PINCODE_LOCALITY": "KALALE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571119,
      "TXT_PINCODE_LOCALITY": "TAGADUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571120,
      "TXT_PINCODE_LOCALITY": "OLD SOSALE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571121,
      "TXT_PINCODE_LOCALITY": "SARAGUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571122,
      "TXT_PINCODE_LOCALITY": "TALKOD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571123,
      "TXT_PINCODE_LOCALITY": "TIRUKANAMBI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571124,
      "TXT_PINCODE_LOCALITY": "T NARSIPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571125,
      "TXT_PINCODE_LOCALITY": "HAMAPAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571126,
      "TXT_PINCODE_LOCALITY": "HANGALA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571127,
      "TXT_PINCODE_LOCALITY": "HARDANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571128,
      "TXT_PINCODE_LOCALITY": "HARVE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571129,
      "TXT_PINCODE_LOCALITY": "NAGARLE S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571130,
      "TXT_PINCODE_LOCALITY": "MYSORE S. O.",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571131,
      "TXT_PINCODE_LOCALITY": "RANGAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571132,
      "TXT_PINCODE_LOCALITY": "BANNIKUPPE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571133,
      "TXT_PINCODE_LOCALITY": "CHILAKUNDA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571134,
      "TXT_PINCODE_LOCALITY": "KATTEMALAVADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571135,
      "TXT_PINCODE_LOCALITY": "MURKAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571136,
      "TXT_PINCODE_LOCALITY": "KAMPLA PURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571137,
      "TXT_PINCODE_LOCALITY": "TURAGANUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571138,
      "TXT_PINCODE_LOCALITY": "MAKODE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571139,
      "TXT_PINCODE_LOCALITY": "KITTOOR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571140,
      "TXT_PINCODE_LOCALITY": "DHANAGALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571141,
      "TXT_PINCODE_LOCALITY": "KOTEKERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571142,
      "TXT_PINCODE_LOCALITY": "THONDAVADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571143,
      "TXT_PINCODE_LOCALITY": "VARAKODU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571144,
      "TXT_PINCODE_LOCALITY": "SHIVAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571145,
      "TXT_PINCODE_LOCALITY": "AMACHAVADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571146,
      "TXT_PINCODE_LOCALITY": "BISALAVADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571147,
      "TXT_PINCODE_LOCALITY": "VENKATAINACHATRA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571148,
      "TXT_PINCODE_LOCALITY": "HEBBALAGUPPE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571149,
      "TXT_PINCODE_LOCALITY": "NAGANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571150,
      "TXT_PINCODE_LOCALITY": "THUMBASOGE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571151,
      "TXT_PINCODE_LOCALITY": "ALUR (CNR)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571152,
      "TXT_PINCODE_LOCALITY": "HEBBASURU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571153,
      "TXT_PINCODE_LOCALITY": "JOTHIGOWDANAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571154,
      "TXT_PINCODE_LOCALITY": "KUDLUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571155,
      "TXT_PINCODE_LOCALITY": "NAGAVALLI (NJD)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571156,
      "TXT_PINCODE_LOCALITY": "HONGANUR NANJANGUD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571157,
      "TXT_PINCODE_LOCALITY": "KOTTALAVADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571158,
      "TXT_PINCODE_LOCALITY": "BILUGALI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571159,
      "TXT_PINCODE_LOCALITY": "SUTTUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571160,
      "TXT_PINCODE_LOCALITY": "BINAKANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571161,
      "TXT_PINCODE_LOCALITY": "DODDAHIBHAGLLU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571162,
      "TXT_PINCODE_LOCALITY": "HORALAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571163,
      "TXT_PINCODE_LOCALITY": "HUNDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571164,
      "TXT_PINCODE_LOCALITY": "SOMANATHAPURA (NJD)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571165,
      "TXT_PINCODE_LOCALITY": "MANGALA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571166,
      "TXT_PINCODE_LOCALITY": "KESTUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571167,
      "TXT_PINCODE_LOCALITY": "DEVANUR (NANJANGUD)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571168,
      "TXT_PINCODE_LOCALITY": "HOSA ALAGODU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571169,
      "TXT_PINCODE_LOCALITY": "MUGURU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571170,
      "TXT_PINCODE_LOCALITY": "KEMPANAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571171,
      "TXT_PINCODE_LOCALITY": "SARAGUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571172,
      "TXT_PINCODE_LOCALITY": "RAMANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571173,
      "TXT_PINCODE_LOCALITY": "KODAGALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571174,
      "TXT_PINCODE_LOCALITY": "GANAGANUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571175,
      "TXT_PINCODE_LOCALITY": "KESTUR (HUNSUR)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571176,
      "TXT_PINCODE_LOCALITY": "SEEHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571177,
      "TXT_PINCODE_LOCALITY": "BHEEMNABEEDU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571178,
      "TXT_PINCODE_LOCALITY": "CHANDAKAVADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571179,
      "TXT_PINCODE_LOCALITY": "NAGARLE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 928,
      "City District Name": "NANJANGUD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571180,
      "TXT_PINCODE_LOCALITY": "HEGGUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571181,
      "TXT_PINCODE_LOCALITY": "BALANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571182,
      "TXT_PINCODE_LOCALITY": "COIMBATORE COLONY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571183,
      "TXT_PINCODE_LOCALITY": "HAROHALLI (MYSORE)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571184,
      "TXT_PINCODE_LOCALITY": "KAVERIPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571185,
      "TXT_PINCODE_LOCALITY": "BYRAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571186,
      "TXT_PINCODE_LOCALITY": "BELAVADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571187,
      "TXT_PINCODE_LOCALITY": "KANAGAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571188,
      "TXT_PINCODE_LOCALITY": "GURUPUPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571189,
      "TXT_PINCODE_LOCALITY": "RATNAPURI COLONY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571201,
      "TXT_PINCODE_LOCALITY": "MADIKERI H O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571211,
      "TXT_PINCODE_LOCALITY": "AMMATHI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571212,
      "TXT_PINCODE_LOCALITY": "CHEYANDANE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571213,
      "TXT_PINCODE_LOCALITY": "GONIKOPPAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571214,
      "TXT_PINCODE_LOCALITY": "NAPOKLU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571215,
      "TXT_PINCODE_LOCALITY": "POLLIBETTA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571216,
      "TXT_PINCODE_LOCALITY": "PONAMPET",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571217,
      "TXT_PINCODE_LOCALITY": "SRIMANGALA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571218,
      "TXT_PINCODE_LOCALITY": "VIRAJPET",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571219,
      "TXT_PINCODE_LOCALITY": "BALELE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571220,
      "TXT_PINCODE_LOCALITY": "DEVARAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571221,
      "TXT_PINCODE_LOCALITY": "MAKUT",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571222,
      "TXT_PINCODE_LOCALITY": "VIRAJPET",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 6218,
      "City District Name": "VIRAJPET",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571223,
      "TXT_PINCODE_LOCALITY": "HARANGI PROJECT",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571224,
      "TXT_PINCODE_LOCALITY": "MYSORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571231,
      "TXT_PINCODE_LOCALITY": "KODLIPETE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571232,
      "TXT_PINCODE_LOCALITY": "KUDIGE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571234,
      "TXT_PINCODE_LOCALITY": "KUSHALNAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571235,
      "TXT_PINCODE_LOCALITY": "KODAGU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 5738,
      "City District Name": "SOMVARPET",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571236,
      "TXT_PINCODE_LOCALITY": "SOMWARPET",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571237,
      "TXT_PINCODE_LOCALITY": "SUNTIKOPPA.",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571238,
      "TXT_PINCODE_LOCALITY": "ATTUR NALLUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571239,
      "TXT_PINCODE_LOCALITY": "HARDUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571240,
      "TXT_PINCODE_LOCALITY": "HEBBALE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571241,
      "TXT_PINCODE_LOCALITY": "KRISHNARAJPET",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 4116,
      "City District Name": "KRISHNARAJPET",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571242,
      "TXT_PINCODE_LOCALITY": "MANDYA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 444169,
      "City District Name": "KODURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571243,
      "TXT_PINCODE_LOCALITY": "KODAGARAHALLI SO",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 444198,
      "City District Name": "MYSURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571244,
      "TXT_PINCODE_LOCALITY": "SIRANGALA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571245,
      "TXT_PINCODE_LOCALITY": "GUDDEHOSUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 444169,
      "City District Name": "KODURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571246,
      "TXT_PINCODE_LOCALITY": "MYSORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571247,
      "TXT_PINCODE_LOCALITY": "BHAGAMANDALA S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571248,
      "TXT_PINCODE_LOCALITY": "CHETTALLI (KDG)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571249,
      "TXT_PINCODE_LOCALITY": "HUDIKERI S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571250,
      "TXT_PINCODE_LOCALITY": "KUTTA S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571251,
      "TXT_PINCODE_LOCALITY": "SOMWARPET",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571252,
      "TXT_PINCODE_LOCALITY": "MURNAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571253,
      "TXT_PINCODE_LOCALITY": "SIDAPUR (KODAGU)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571254,
      "TXT_PINCODE_LOCALITY": "NOKYA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 6218,
      "City District Name": "VIRAJPET",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571255,
      "TXT_PINCODE_LOCALITY": "BETTAGERI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571256,
      "TXT_PINCODE_LOCALITY": "BOIKERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 444169,
      "City District Name": "KODURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571257,
      "TXT_PINCODE_LOCALITY": "SINGATHURU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1406,
      "City District Name": "MADIKERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571258,
      "TXT_PINCODE_LOCALITY": "KODURU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 444169,
      "City District Name": "KODURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571259,
      "TXT_PINCODE_LOCALITY": "KUDUMANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 5738,
      "City District Name": "SOMVARPET",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571260,
      "TXT_PINCODE_LOCALITY": "KOYNAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571261,
      "TXT_PINCODE_LOCALITY": "MARAGODU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571262,
      "TXT_PINCODE_LOCALITY": "BIRUNANI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 444169,
      "City District Name": "KODURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571263,
      "TXT_PINCODE_LOCALITY": "SOMVARPET",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 5738,
      "City District Name": "SOMVARPET",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571264,
      "TXT_PINCODE_LOCALITY": "VIRAJPET",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 6218,
      "City District Name": "VIRAJPET",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571265,
      "TXT_PINCODE_LOCALITY": "BENBALUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571266,
      "TXT_PINCODE_LOCALITY": "HEGGADADEVANKOTE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 3402,
      "City District Name": "HEGGADADEVANAKOTE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571267,
      "TXT_PINCODE_LOCALITY": "HOSUR (VIRAJPET)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571268,
      "TXT_PINCODE_LOCALITY": "NOKYA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571269,
      "TXT_PINCODE_LOCALITY": "ULUGULI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571270,
      "TXT_PINCODE_LOCALITY": "SOMVARPET",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 5738,
      "City District Name": "SOMVARPET",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571271,
      "TXT_PINCODE_LOCALITY": "MEKUR HOSKERI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571272,
      "TXT_PINCODE_LOCALITY": "CHANNARAYAPATNA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571273,
      "TXT_PINCODE_LOCALITY": "VIRAJPET",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 6218,
      "City District Name": "VIRAJPET",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571301,
      "TXT_PINCODE_LOCALITY": "SUJATHA PURAM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571302,
      "TXT_PINCODE_LOCALITY": "NANJANGUD BAZAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571311,
      "TXT_PINCODE_LOCALITY": "KIADB INDUSTRIAL AREA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571312,
      "TXT_PINCODE_LOCALITY": "BADANAVALU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571313,
      "TXT_PINCODE_LOCALITY": "CHAMARAJA NAGAR H O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571314,
      "TXT_PINCODE_LOCALITY": "HULLAHALLI S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571315,
      "TXT_PINCODE_LOCALITY": "HURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571316,
      "TXT_PINCODE_LOCALITY": "KUDERU.",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 950,
      "City District Name": "CHAMANRAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571317,
      "TXT_PINCODE_LOCALITY": "HILLS",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571318,
      "TXT_PINCODE_LOCALITY": "GUNDLUPET",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 3326,
      "City District Name": "GUNDLUPET",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571319,
      "TXT_PINCODE_LOCALITY": "KABBAHALI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571320,
      "TXT_PINCODE_LOCALITY": "COWDALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571321,
      "TXT_PINCODE_LOCALITY": "MYSORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571322,
      "TXT_PINCODE_LOCALITY": "MYSORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571323,
      "TXT_PINCODE_LOCALITY": "KEMPISIDDANAHUNDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571324,
      "TXT_PINCODE_LOCALITY": "SARVAMANYAHOSAKOTE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571325,
      "TXT_PINCODE_LOCALITY": "TANDAVAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571326,
      "TXT_PINCODE_LOCALITY": "VIDYA PEETHA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571327,
      "TXT_PINCODE_LOCALITY": "BADANAGUPPE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571328,
      "TXT_PINCODE_LOCALITY": "SOMWARPET",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571329,
      "TXT_PINCODE_LOCALITY": "UDIGALA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571330,
      "TXT_PINCODE_LOCALITY": "RANGASAMUDRA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571331,
      "TXT_PINCODE_LOCALITY": "HEDATHALE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571332,
      "TXT_PINCODE_LOCALITY": "NERALE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571333,
      "TXT_PINCODE_LOCALITY": "UMMATTUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571334,
      "TXT_PINCODE_LOCALITY": "HADINARU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571335,
      "TXT_PINCODE_LOCALITY": "YEDAKOLA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571336,
      "TXT_PINCODE_LOCALITY": "HEGGADAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571337,
      "TXT_PINCODE_LOCALITY": "HEMMARAGALA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571339,
      "TXT_PINCODE_LOCALITY": "RAMAPURA (NJD)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571340,
      "TXT_PINCODE_LOCALITY": "KOTEGEYEDATHORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571341,
      "TXT_PINCODE_LOCALITY": "DODDARAYAPETE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571342,
      "TXT_PINCODE_LOCALITY": "RAMASAMUDRA S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2682,
      "City District Name": "CHAMRAJNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571344,
      "TXT_PINCODE_LOCALITY": "KOLLEGAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 4046,
      "City District Name": "KOLLEGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571346,
      "TXT_PINCODE_LOCALITY": "YEDATHORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571401,
      "TXT_PINCODE_LOCALITY": "MANDYA AZADNAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571402,
      "TXT_PINCODE_LOCALITY": "MANDYA SUGAR TOWN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571403,
      "TXT_PINCODE_LOCALITY": "GUTTALU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571404,
      "TXT_PINCODE_LOCALITY": "ACETATE TOWN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571405,
      "TXT_PINCODE_LOCALITY": "V C FARM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571406,
      "TXT_PINCODE_LOCALITY": "THAGGAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571412,
      "TXT_PINCODE_LOCALITY": "HUNUSANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 4116,
      "City District Name": "KRISHNARAJPET",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571415,
      "TXT_PINCODE_LOCALITY": "MANDYA S. O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571416,
      "TXT_PINCODE_LOCALITY": "BASARALU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571417,
      "TXT_PINCODE_LOCALITY": "BELAKAVADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571418,
      "TXT_PINCODE_LOCALITY": "BELLUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571419,
      "TXT_PINCODE_LOCALITY": "BESGERAHALLI B.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571420,
      "TXT_PINCODE_LOCALITY": "CHIKKAYELACHAGERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1168,
      "City District Name": "MALAVALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571421,
      "TXT_PINCODE_LOCALITY": "HALAGUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571422,
      "TXT_PINCODE_LOCALITY": "KALAMUDDANA DODDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571423,
      "TXT_PINCODE_LOCALITY": "KIKKERI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571424,
      "TXT_PINCODE_LOCALITY": "KIRUGAVALU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571425,
      "TXT_PINCODE_LOCALITY": "KOPPA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571426,
      "TXT_PINCODE_LOCALITY": "KRISHNARAJAPETE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571427,
      "TXT_PINCODE_LOCALITY": "KYATARHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571428,
      "TXT_PINCODE_LOCALITY": "MADDUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571429,
      "TXT_PINCODE_LOCALITY": "SHIVAPURA MADDUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571430,
      "TXT_PINCODE_LOCALITY": "MALAVALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571431,
      "TXT_PINCODE_LOCALITY": "MELKOTE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571432,
      "TXT_PINCODE_LOCALITY": "NAGAMANGALA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571433,
      "TXT_PINCODE_LOCALITY": "NIDIGHATTA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571434,
      "TXT_PINCODE_LOCALITY": "BEERASETTIHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571435,
      "TXT_PINCODE_LOCALITY": "PANDAVAPURA R S",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571436,
      "TXT_PINCODE_LOCALITY": "SANTEBACHAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571437,
      "TXT_PINCODE_LOCALITY": "SHIVASAMUDRAM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571438,
      "TXT_PINCODE_LOCALITY": "SRIRANGAPATNA H O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571439,
      "TXT_PINCODE_LOCALITY": "HANUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571440,
      "TXT_PINCODE_LOCALITY": "KOLLEGAL SOUTHERN EXTN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571441,
      "TXT_PINCODE_LOCALITY": "YELANDUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571442,
      "TXT_PINCODE_LOCALITY": "MAMBALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571443,
      "TXT_PINCODE_LOCALITY": "KAMAKERAI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571444,
      "TXT_PINCODE_LOCALITY": "RAMAPURAM GHAT",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571445,
      "TXT_PINCODE_LOCALITY": "DEVALAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571446,
      "TXT_PINCODE_LOCALITY": "KERAGODU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571448,
      "TXT_PINCODE_LOCALITY": "KUNAGAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571449,
      "TXT_PINCODE_LOCALITY": "KESTURU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571450,
      "TXT_PINCODE_LOCALITY": "KEELARA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571451,
      "TXT_PINCODE_LOCALITY": "BUDANURU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571452,
      "TXT_PINCODE_LOCALITY": "KODIYALA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571453,
      "TXT_PINCODE_LOCALITY": "KALKUNI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571454,
      "TXT_PINCODE_LOCALITY": "SEELENRE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571455,
      "TXT_PINCODE_LOCALITY": "CHINKURLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571456,
      "TXT_PINCODE_LOCALITY": "NAGARAKERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571457,
      "TXT_PINCODE_LOCALITY": "TIBETIAN SETTLEMENT",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571458,
      "TXT_PINCODE_LOCALITY": "RAGIBOMMANHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571459,
      "TXT_PINCODE_LOCALITY": "SHIVARAGUDDA VIDYAPEETHA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571460,
      "TXT_PINCODE_LOCALITY": "THAGGAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571461,
      "TXT_PINCODE_LOCALITY": "AGASANPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571462,
      "TXT_PINCODE_LOCALITY": "DHANGUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571463,
      "TXT_PINCODE_LOCALITY": "PURIGALE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571464,
      "TXT_PINCODE_LOCALITY": "SATHANUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571465,
      "TXT_PINCODE_LOCALITY": "SINDHAGHATTA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571466,
      "TXT_PINCODE_LOCALITY": "CHOTTANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571467,
      "TXT_PINCODE_LOCALITY": "MANDAGERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571468,
      "TXT_PINCODE_LOCALITY": "SHIVALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571469,
      "TXT_PINCODE_LOCALITY": "HULIKERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571470,
      "TXT_PINCODE_LOCALITY": "GOPALAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571471,
      "TXT_PINCODE_LOCALITY": "KOTTATTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571472,
      "TXT_PINCODE_LOCALITY": "GANDHAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571473,
      "TXT_PINCODE_LOCALITY": "UMMADAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571474,
      "TXT_PINCODE_LOCALITY": "YELIYUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571475,
      "TXT_PINCODE_LOCALITY": "AJJIPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571476,
      "TXT_PINCODE_LOCALITY": "BANDALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571477,
      "TXT_PINCODE_LOCALITY": "SAGGLAM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571478,
      "TXT_PINCODE_LOCALITY": "DODDAINDUVADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571479,
      "TXT_PINCODE_LOCALITY": "LOKKANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571480,
      "TXT_PINCODE_LOCALITY": "SIGANELLUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571481,
      "TXT_PINCODE_LOCALITY": "KALIYUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571482,
      "TXT_PINCODE_LOCALITY": "PONNACHI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571483,
      "TXT_PINCODE_LOCALITY": "MULLUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571484,
      "TXT_PINCODE_LOCALITY": "BEERSETTIHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571485,
      "TXT_PINCODE_LOCALITY": "AGARA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571486,
      "TXT_PINCODE_LOCALITY": "CHILUKAVADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571487,
      "TXT_PINCODE_LOCALITY": "KINKANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571488,
      "TXT_PINCODE_LOCALITY": "TELLANUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571489,
      "TXT_PINCODE_LOCALITY": "KUNTUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571490,
      "TXT_PINCODE_LOCALITY": "MAHADESHWARA BETTA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571491,
      "TXT_PINCODE_LOCALITY": "MARTHAVI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571492,
      "TXT_PINCODE_LOCALITY": "AMBALE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571493,
      "TXT_PINCODE_LOCALITY": "HONNUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571494,
      "TXT_PINCODE_LOCALITY": "KESTUR (YELANDUR)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571495,
      "TXT_PINCODE_LOCALITY": "MADDUR (YELANDUR)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571496,
      "TXT_PINCODE_LOCALITY": "YERIYUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571497,
      "TXT_PINCODE_LOCALITY": "YERAGAMBALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571498,
      "TXT_PINCODE_LOCALITY": "SATHEGALA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571499,
      "TXT_PINCODE_LOCALITY": "SIDDAINAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571500,
      "TXT_PINCODE_LOCALITY": "SILERIPALAYAM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571501,
      "TXT_PINCODE_LOCALITY": "SHETTIHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 5294,
      "City District Name": "RAMANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571502,
      "TXT_PINCODE_LOCALITY": "SILKFARM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571511,
      "TXT_PINCODE_LOCALITY": "RAMANAGARAM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571512,
      "TXT_PINCODE_LOCALITY": "MALUR (CPT)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571601,
      "TXT_PINCODE_LOCALITY": "HAMPAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571602,
      "TXT_PINCODE_LOCALITY": "HOSUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571603,
      "TXT_PINCODE_LOCALITY": "MIRLE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571604,
      "TXT_PINCODE_LOCALITY": "SALIGRAM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571605,
      "TXT_PINCODE_LOCALITY": "AKKIHEBBAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571606,
      "TXT_PINCODE_LOCALITY": "BELAGULA RS",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571607,
      "TXT_PINCODE_LOCALITY": "KRISHNARAJASAGARA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571608,
      "TXT_PINCODE_LOCALITY": "BHERIYA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571609,
      "TXT_PINCODE_LOCALITY": "HOSAGRAHARA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571610,
      "TXT_PINCODE_LOCALITY": "GAVADAGERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571611,
      "TXT_PINCODE_LOCALITY": "HOSKOTE (MYSORE)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571612,
      "TXT_PINCODE_LOCALITY": "HOSUR (MYSORE)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571613,
      "TXT_PINCODE_LOCALITY": "TIPPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571614,
      "TXT_PINCODE_LOCALITY": "HALEYUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571615,
      "TXT_PINCODE_LOCALITY": "BYADARAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571616,
      "TXT_PINCODE_LOCALITY": "HEBBALU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571617,
      "TXT_PINCODE_LOCALITY": "CHUNCHANAKATTE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571702,
      "TXT_PINCODE_LOCALITY": "MALLENAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 4656,
      "City District Name": "NAGAMANGALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571703,
      "TXT_PINCODE_LOCALITY": "MANDYA (RURAL)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571801,
      "TXT_PINCODE_LOCALITY": "MADHUVANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571802,
      "TXT_PINCODE_LOCALITY": "BINDIGANAVALE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571803,
      "TXT_PINCODE_LOCALITY": "PARASEGOWDANA PALYA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571804,
      "TXT_PINCODE_LOCALITY": "KANNUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571805,
      "TXT_PINCODE_LOCALITY": "NAGOONAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571806,
      "TXT_PINCODE_LOCALITY": "KUMARARPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571807,
      "TXT_PINCODE_LOCALITY": "KIRANGOOR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571808,
      "TXT_PINCODE_LOCALITY": "HOOGIAM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571809,
      "TXT_PINCODE_LOCALITY": "MAHADEVAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571810,
      "TXT_PINCODE_LOCALITY": "CHINYA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571811,
      "TXT_PINCODE_LOCALITY": "NAGAMANGALA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571812,
      "TXT_PINCODE_LOCALITY": "BUKKINAKERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571814,
      "TXT_PINCODE_LOCALITY": "CHOKANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 4116,
      "City District Name": "KRISHNARAJPET",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 571872,
      "TXT_PINCODE_LOCALITY": "NATANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 4116,
      "City District Name": "KRISHNARAJPET",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572001,
      "TXT_PINCODE_LOCALITY": "TUMKUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572014,
      "TXT_PINCODE_LOCALITY": "KOTHIGEGOLLAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572016,
      "TXT_PINCODE_LOCALITY": "YELLAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572101,
      "TXT_PINCODE_LOCALITY": "SIDDAGANGA EXTN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572102,
      "TXT_PINCODE_LOCALITY": "TUMKUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572103,
      "TXT_PINCODE_LOCALITY": "DEVARAYA PATNA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572104,
      "TXT_PINCODE_LOCALITY": "SUKRAVARSANTHE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572105,
      "TXT_PINCODE_LOCALITY": "BAGESHAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572106,
      "TXT_PINCODE_LOCALITY": "NORTHERN EXTENSION",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572107,
      "TXT_PINCODE_LOCALITY": "M C CAMPUS TUMKUR S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572108,
      "TXT_PINCODE_LOCALITY": "PANNASANDRA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572111,
      "TXT_PINCODE_LOCALITY": "MALA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572112,
      "TXT_PINCODE_LOCALITY": "BADAVANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572113,
      "TXT_PINCODE_LOCALITY": "TUMKUR S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572114,
      "TXT_PINCODE_LOCALITY": "BILIGIRI RANGASWAMY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572115,
      "TXT_PINCODE_LOCALITY": "GANADAHUNSE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572116,
      "TXT_PINCODE_LOCALITY": "CHANNAKESAVAPUDA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572117,
      "TXT_PINCODE_LOCALITY": "KANTHAVARA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572118,
      "TXT_PINCODE_LOCALITY": "GULUR TUMKUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572119,
      "TXT_PINCODE_LOCALITY": "BARAGUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572120,
      "TXT_PINCODE_LOCALITY": "KANDALI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572121,
      "TXT_PINCODE_LOCALITY": "RAMPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572122,
      "TXT_PINCODE_LOCALITY": "HONNUDIKE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572123,
      "TXT_PINCODE_LOCALITY": "BADAGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572124,
      "TXT_PINCODE_LOCALITY": "ITAKADIBBANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572125,
      "TXT_PINCODE_LOCALITY": "KALLAMBELLA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572126,
      "TXT_PINCODE_LOCALITY": "KOWSIKA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572127,
      "TXT_PINCODE_LOCALITY": "UDYAVARA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572128,
      "TXT_PINCODE_LOCALITY": "KORA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572129,
      "TXT_PINCODE_LOCALITY": "ALUR (HASSAN)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572130,
      "TXT_PINCODE_LOCALITY": "DIBBURAHALLI (BNG)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572132,
      "TXT_PINCODE_LOCALITY": "P W D COLONY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572133,
      "TXT_PINCODE_LOCALITY": "MEDIGESI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572134,
      "TXT_PINCODE_LOCALITY": "NAGASANDRA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572135,
      "TXT_PINCODE_LOCALITY": "ALEVUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572136,
      "TXT_PINCODE_LOCALITY": "PALAVALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572137,
      "TXT_PINCODE_LOCALITY": "SIRA BAZAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572138,
      "TXT_PINCODE_LOCALITY": "TOVINKERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572139,
      "TXT_PINCODE_LOCALITY": "TAVAREKERE (TMR)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572140,
      "TXT_PINCODE_LOCALITY": "NITTE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572141,
      "TXT_PINCODE_LOCALITY": "SIDDAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 5065,
      "City District Name": "PAVAGADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572142,
      "TXT_PINCODE_LOCALITY": "YEDIYUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572143,
      "TXT_PINCODE_LOCALITY": "HULIYAR ROAD HIRIYUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572144,
      "TXT_PINCODE_LOCALITY": "HIRIYUR SUGAR FACTORY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572145,
      "TXT_PINCODE_LOCALITY": "NAGAVALLI (TMR)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572146,
      "TXT_PINCODE_LOCALITY": "TUMKUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572147,
      "TXT_PINCODE_LOCALITY": "THUMBADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572148,
      "TXT_PINCODE_LOCALITY": "KOTHURU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572149,
      "TXT_PINCODE_LOCALITY": "MADHUGIRI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 4288,
      "City District Name": "MADHUGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572150,
      "TXT_PINCODE_LOCALITY": "DOD BALLAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 3044,
      "City District Name": "DOD BALLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572151,
      "TXT_PINCODE_LOCALITY": "ANEKAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2064,
      "City District Name": "ANEKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572152,
      "TXT_PINCODE_LOCALITY": "SIRA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 5698,
      "City District Name": "SIRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572153,
      "TXT_PINCODE_LOCALITY": "BELLAVI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572154,
      "TXT_PINCODE_LOCALITY": "TEETHA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572155,
      "TXT_PINCODE_LOCALITY": "KUNIGAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 4157,
      "City District Name": "KUNIGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572156,
      "TXT_PINCODE_LOCALITY": "KONDETHIMMANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572157,
      "TXT_PINCODE_LOCALITY": "KOTHEGALA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572158,
      "TXT_PINCODE_LOCALITY": "BIRAVALU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572159,
      "TXT_PINCODE_LOCALITY": "BADDUR FARM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572160,
      "TXT_PINCODE_LOCALITY": "CHITRADURGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572161,
      "TXT_PINCODE_LOCALITY": "VANIVILASAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572162,
      "TXT_PINCODE_LOCALITY": "YEDAVANNE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572163,
      "TXT_PINCODE_LOCALITY": "BYALYA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572164,
      "TXT_PINCODE_LOCALITY": "RAMPURA (TMR)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572165,
      "TXT_PINCODE_LOCALITY": "CHANNARAYAPATNA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2704,
      "City District Name": "CHANNARAYAPATTANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572166,
      "TXT_PINCODE_LOCALITY": "SHIKARPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 6446,
      "City District Name": "SHIKARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572167,
      "TXT_PINCODE_LOCALITY": "KUNIGAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 4157,
      "City District Name": "KUNIGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572168,
      "TXT_PINCODE_LOCALITY": "HIREHALI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572169,
      "TXT_PINCODE_LOCALITY": "SIRA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 5698,
      "City District Name": "SIRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572170,
      "TXT_PINCODE_LOCALITY": "SIRA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 5698,
      "City District Name": "SIRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572171,
      "TXT_PINCODE_LOCALITY": "UJJINE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572172,
      "TXT_PINCODE_LOCALITY": "TUMKUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572173,
      "TXT_PINCODE_LOCALITY": "IIRKASANDRA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572174,
      "TXT_PINCODE_LOCALITY": "KOTEGARIHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572175,
      "TXT_PINCODE_LOCALITY": "PURAVARA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572188,
      "TXT_PINCODE_LOCALITY": "LAKSHMANASANDRA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572192,
      "TXT_PINCODE_LOCALITY": "THIPPAGONDANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 4288,
      "City District Name": "MADHUGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572201,
      "TXT_PINCODE_LOCALITY": "TIPTUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572202,
      "TXT_PINCODE_LOCALITY": "KANDIKERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572211,
      "TXT_PINCODE_LOCALITY": "AMMASANDRA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572212,
      "TXT_PINCODE_LOCALITY": "BANASANDRA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572213,
      "TXT_PINCODE_LOCALITY": "CHANDRASEKHARAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572214,
      "TXT_PINCODE_LOCALITY": "CHIKKANAYAKANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572215,
      "TXT_PINCODE_LOCALITY": "ATHIKARIBETTU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572216,
      "TXT_PINCODE_LOCALITY": "GUBBI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572217,
      "TXT_PINCODE_LOCALITY": "KURNAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572218,
      "TXT_PINCODE_LOCALITY": "MAGADI (CPT)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572219,
      "TXT_PINCODE_LOCALITY": "KABADA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572220,
      "TXT_PINCODE_LOCALITY": "KALLUR (TUMKUR)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572221,
      "TXT_PINCODE_LOCALITY": "ULLAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572222,
      "TXT_PINCODE_LOCALITY": "MUGANAYAKANAKOTE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572223,
      "TXT_PINCODE_LOCALITY": "KUDERU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572224,
      "TXT_PINCODE_LOCALITY": "VIJAYAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572225,
      "TXT_PINCODE_LOCALITY": "BANDIPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 841,
      "City District Name": "CHAMARAJANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572226,
      "TXT_PINCODE_LOCALITY": "SETTIKERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572227,
      "TXT_PINCODE_LOCALITY": "TURUVEKERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572228,
      "TXT_PINCODE_LOCALITY": "NANDAGUDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572229,
      "TXT_PINCODE_LOCALITY": "ARSIKERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2120,
      "City District Name": "ARSIKERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572230,
      "TXT_PINCODE_LOCALITY": "ARALGUPPE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572231,
      "TXT_PINCODE_LOCALITY": "KENKERE (TUMKUR)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572232,
      "TXT_PINCODE_LOCALITY": "YELANADU (TMR)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572233,
      "TXT_PINCODE_LOCALITY": "THIMMANHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572234,
      "TXT_PINCODE_LOCALITY": "TUMKUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572235,
      "TXT_PINCODE_LOCALITY": "TIPTUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 5971,
      "City District Name": "TIPTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572236,
      "TXT_PINCODE_LOCALITY": "RANGAMPUTA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572239,
      "TXT_PINCODE_LOCALITY": "HAGALVADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572240,
      "TXT_PINCODE_LOCALITY": "MALUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 4372,
      "City District Name": "MALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572241,
      "TXT_PINCODE_LOCALITY": "AREMALLENAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 6021,
      "City District Name": "TURUVEKERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572262,
      "TXT_PINCODE_LOCALITY": "SINGENAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 5971,
      "City District Name": "TIPTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572312,
      "TXT_PINCODE_LOCALITY": "HEBBAKA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572414,
      "TXT_PINCODE_LOCALITY": "SEEBIDASARAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572437,
      "TXT_PINCODE_LOCALITY": "MANGANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 5698,
      "City District Name": "SIRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 572738,
      "TXT_PINCODE_LOCALITY": "SHAMBONAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 4073,
      "City District Name": "KORATAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573101,
      "TXT_PINCODE_LOCALITY": "AREHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573102,
      "TXT_PINCODE_LOCALITY": "ARKALGUD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573103,
      "TXT_PINCODE_LOCALITY": "ARSIKERE COURT",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573104,
      "TXT_PINCODE_LOCALITY": "ARSIKERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573105,
      "TXT_PINCODE_LOCALITY": "ARSIKERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2120,
      "City District Name": "ARSIKERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573111,
      "TXT_PINCODE_LOCALITY": "BAGUR (HASSAN)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573112,
      "TXT_PINCODE_LOCALITY": "BANAVARA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573113,
      "TXT_PINCODE_LOCALITY": "BASAVAPATNA (HASSAN)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573114,
      "TXT_PINCODE_LOCALITY": "BELAGUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573115,
      "TXT_PINCODE_LOCALITY": "BELUR HASSAN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573116,
      "TXT_PINCODE_LOCALITY": "CHANNARAYAPATNA (HASSAN)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573117,
      "TXT_PINCODE_LOCALITY": "DODDAMETIKURKE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573118,
      "TXT_PINCODE_LOCALITY": "DUDDA R S",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573119,
      "TXT_PINCODE_LOCALITY": "GANDASI S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573120,
      "TXT_PINCODE_LOCALITY": "GORUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573121,
      "TXT_PINCODE_LOCALITY": "HALEBEEDU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573122,
      "TXT_PINCODE_LOCALITY": "HARANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573123,
      "TXT_PINCODE_LOCALITY": "HETHUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573124,
      "TXT_PINCODE_LOCALITY": "HIRISAVE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573125,
      "TXT_PINCODE_LOCALITY": "JAVAGAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573126,
      "TXT_PINCODE_LOCALITY": "HASSAN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573127,
      "TXT_PINCODE_LOCALITY": "SAKLESHPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 5454,
      "City District Name": "SAKLESHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573128,
      "TXT_PINCODE_LOCALITY": "KATTAYA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573129,
      "TXT_PINCODE_LOCALITY": "K HOSKOTE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573130,
      "TXT_PINCODE_LOCALITY": "KONANUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573131,
      "TXT_PINCODE_LOCALITY": "NUGGIHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573132,
      "TXT_PINCODE_LOCALITY": "PANCHANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573133,
      "TXT_PINCODE_LOCALITY": "RAMNATHAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573134,
      "TXT_PINCODE_LOCALITY": "SAKLASHPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573135,
      "TXT_PINCODE_LOCALITY": "SHRAVANABELAGOLA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573136,
      "TXT_PINCODE_LOCALITY": "KERALAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573137,
      "TXT_PINCODE_LOCALITY": "YELSUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573138,
      "TXT_PINCODE_LOCALITY": "CHEEKANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573139,
      "TXT_PINCODE_LOCALITY": "RAYARAKOPPAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573140,
      "TXT_PINCODE_LOCALITY": "AGRAHARA BELAGULI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573141,
      "TXT_PINCODE_LOCALITY": "DIDAGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573142,
      "TXT_PINCODE_LOCALITY": "DOODA MAGGE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573143,
      "TXT_PINCODE_LOCALITY": "GENDAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573144,
      "TXT_PINCODE_LOCALITY": "KANAKATTE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573145,
      "TXT_PINCODE_LOCALITY": "HEGGADDE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573146,
      "TXT_PINCODE_LOCALITY": "MAGGE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573147,
      "TXT_PINCODE_LOCALITY": "ARKALGUD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2110,
      "City District Name": "ARKALGUD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573148,
      "TXT_PINCODE_LOCALITY": "MULASAVARA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573149,
      "TXT_PINCODE_LOCALITY": "NORWAY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573150,
      "TXT_PINCODE_LOCALITY": "RUNDRAPATNA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573151,
      "TXT_PINCODE_LOCALITY": "JAMBUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573152,
      "TXT_PINCODE_LOCALITY": "HASSAN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573153,
      "TXT_PINCODE_LOCALITY": "JODIHANDINAKERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573154,
      "TXT_PINCODE_LOCALITY": "ARKALGUD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2110,
      "City District Name": "ARKALGUD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573155,
      "TXT_PINCODE_LOCALITY": "SUNDEKERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573156,
      "TXT_PINCODE_LOCALITY": "THUMBEDEVANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573157,
      "TXT_PINCODE_LOCALITY": "UDEVARA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573158,
      "TXT_PINCODE_LOCALITY": "KENKERE (HASSAN)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573159,
      "TXT_PINCODE_LOCALITY": "CHANNARAYAPATNA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 442803,
      "City District Name": "CHANNARAYAPATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573160,
      "TXT_PINCODE_LOCALITY": "AGGUNDA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573161,
      "TXT_PINCODE_LOCALITY": "KODURU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 444169,
      "City District Name": "KODURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573162,
      "TXT_PINCODE_LOCALITY": "HASSAN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573163,
      "TXT_PINCODE_LOCALITY": "BANAVARA R S",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573164,
      "TXT_PINCODE_LOCALITY": "MANGALAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2120,
      "City District Name": "ARSIKERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573165,
      "TXT_PINCODE_LOCALITY": "HANBALU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573180,
      "TXT_PINCODE_LOCALITY": "VADERAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2120,
      "City District Name": "ARSIKERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573201,
      "TXT_PINCODE_LOCALITY": "HASSAN DIST OFFICE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573202,
      "TXT_PINCODE_LOCALITY": "VIDYANAGAR (HASSAN) S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573210,
      "TXT_PINCODE_LOCALITY": "HALLI MYSORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573211,
      "TXT_PINCODE_LOCALITY": "NAGARAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573212,
      "TXT_PINCODE_LOCALITY": "MOSALEHOSAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573213,
      "TXT_PINCODE_LOCALITY": "ALUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573214,
      "TXT_PINCODE_LOCALITY": "BALLUPET",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573215,
      "TXT_PINCODE_LOCALITY": "BIKKODU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573216,
      "TXT_PINCODE_LOCALITY": "HASSAN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573217,
      "TXT_PINCODE_LOCALITY": "HASSAN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573218,
      "TXT_PINCODE_LOCALITY": "HASSAN S. O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573219,
      "TXT_PINCODE_LOCALITY": "SALAGAME",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573220,
      "TXT_PINCODE_LOCALITY": "BALDARE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573221,
      "TXT_PINCODE_LOCALITY": "SAKLESHPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 5454,
      "City District Name": "SAKLESHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573223,
      "TXT_PINCODE_LOCALITY": "HASSAN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573224,
      "TXT_PINCODE_LOCALITY": "KESAGODU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573225,
      "TXT_PINCODE_LOCALITY": "NELLAKKIHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573226,
      "TXT_PINCODE_LOCALITY": "HASSAN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573233,
      "TXT_PINCODE_LOCALITY": "UDUPI S. O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 4153,
      "City District Name": "KUNDAPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573423,
      "TXT_PINCODE_LOCALITY": "HADYA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 5454,
      "City District Name": "SAKLESHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 573431,
      "TXT_PINCODE_LOCALITY": "BYADANE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2429,
      "City District Name": "BELUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574015,
      "TXT_PINCODE_LOCALITY": "BEVAKAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574030,
      "TXT_PINCODE_LOCALITY": "KILENJUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574101,
      "TXT_PINCODE_LOCALITY": "AJEKAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574102,
      "TXT_PINCODE_LOCALITY": "BAILUR (PUTTUR)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574103,
      "TXT_PINCODE_LOCALITY": "HEJAMADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574104,
      "TXT_PINCODE_LOCALITY": "KARKALA H O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574105,
      "TXT_PINCODE_LOCALITY": "PERDUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574106,
      "TXT_PINCODE_LOCALITY": "MALLUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574107,
      "TXT_PINCODE_LOCALITY": "BELGAUM UDYAMBAG",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574108,
      "TXT_PINCODE_LOCALITY": "MUNIYAL S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574109,
      "TXT_PINCODE_LOCALITY": "IDU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574110,
      "TXT_PINCODE_LOCALITY": "ATHRADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574111,
      "TXT_PINCODE_LOCALITY": "BELGAUM HINDWADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574112,
      "TXT_PINCODE_LOCALITY": "PALIMAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574113,
      "TXT_PINCODE_LOCALITY": "KURNI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574114,
      "TXT_PINCODE_LOCALITY": "SANUR S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574115,
      "TXT_PINCODE_LOCALITY": "ULLARGOLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574116,
      "TXT_PINCODE_LOCALITY": "BADAGA BETTU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574117,
      "TXT_PINCODE_LOCALITY": "SIRUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574118,
      "TXT_PINCODE_LOCALITY": "MATTU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574119,
      "TXT_PINCODE_LOCALITY": "BM DHARWAD ROAD *",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574120,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574121,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574122,
      "TXT_PINCODE_LOCALITY": "NALLUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574123,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574124,
      "TXT_PINCODE_LOCALITY": "NELLIKAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574125,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574126,
      "TXT_PINCODE_LOCALITY": "KALLYA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574127,
      "TXT_PINCODE_LOCALITY": "HIRGAN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574128,
      "TXT_PINCODE_LOCALITY": "IRVATHUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574129,
      "TXT_PINCODE_LOCALITY": "HIDKAL DAM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574130,
      "TXT_PINCODE_LOCALITY": "RENJALA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574131,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574132,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574133,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574134,
      "TXT_PINCODE_LOCALITY": "KUTHPADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574136,
      "TXT_PINCODE_LOCALITY": "AKUNJARGIRI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574137,
      "TXT_PINCODE_LOCALITY": "SUBASHNAGAR (D K)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574138,
      "TXT_PINCODE_LOCALITY": "BOLA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574139,
      "TXT_PINCODE_LOCALITY": "SANTUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574140,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574141,
      "TXT_PINCODE_LOCALITY": "DAKSHINA KANNADA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 441341,
      "City District Name": "DAKSHINA KANNADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574142,
      "TXT_PINCODE_LOCALITY": "SOUTH CANARA S. O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574143,
      "TXT_PINCODE_LOCALITY": "THUMBE.",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 442802,
      "City District Name": "BANTVAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574144,
      "TXT_PINCODE_LOCALITY": "GANJIMAT.",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574145,
      "TXT_PINCODE_LOCALITY": "ULAIBETTU B.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574146,
      "TXT_PINCODE_LOCALITY": "HALEANGADY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574147,
      "TXT_PINCODE_LOCALITY": "JOKATTE (PUTTUR)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574148,
      "TXT_PINCODE_LOCALITY": "KATEEL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574149,
      "TXT_PINCODE_LOCALITY": "DAKSHINA KANNADA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 441341,
      "City District Name": "DAKSHINA KANNADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574150,
      "TXT_PINCODE_LOCALITY": "KINNIGOLI S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574151,
      "TXT_PINCODE_LOCALITY": "KANDAVARA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 441341,
      "City District Name": "DAKSHINA KANNADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574152,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574153,
      "TXT_PINCODE_LOCALITY": "KURNAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574154,
      "TXT_PINCODE_LOCALITY": "MULKI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574156,
      "TXT_PINCODE_LOCALITY": "SOMESHWAR UCHIL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574157,
      "TXT_PINCODE_LOCALITY": "SRINIVASANAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574158,
      "TXT_PINCODE_LOCALITY": "SURANTHKAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574160,
      "TXT_PINCODE_LOCALITY": "BELMA VILLAGE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574161,
      "TXT_PINCODE_LOCALITY": "KONAJE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574162,
      "TXT_PINCODE_LOCALITY": "MUTHURU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574163,
      "TXT_PINCODE_LOCALITY": "NEERUDE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574164,
      "TXT_PINCODE_LOCALITY": "ATTAVARA.",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574165,
      "TXT_PINCODE_LOCALITY": "TANKAULIPADY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574166,
      "TXT_PINCODE_LOCALITY": "MUDUPERAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574167,
      "TXT_PINCODE_LOCALITY": "MANGALANTHI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574168,
      "TXT_PINCODE_LOCALITY": "NARINGANA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574169,
      "TXT_PINCODE_LOCALITY": "PUDU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574171,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574172,
      "TXT_PINCODE_LOCALITY": "BALKUNJE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574173,
      "TXT_PINCODE_LOCALITY": "JOKATTE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574174,
      "TXT_PINCODE_LOCALITY": "KALAVARA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574175,
      "TXT_PINCODE_LOCALITY": "PEJAVARA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574176,
      "TXT_PINCODE_LOCALITY": "KULAI HOSABETTU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574177,
      "TXT_PINCODE_LOCALITY": "MUKKA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574178,
      "TXT_PINCODE_LOCALITY": "NADUGALLU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574179,
      "TXT_PINCODE_LOCALITY": "PUNARUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574180,
      "TXT_PINCODE_LOCALITY": "SASIHITHLU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574181,
      "TXT_PINCODE_LOCALITY": "HAREKALA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574182,
      "TXT_PINCODE_LOCALITY": "PAVUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574183,
      "TXT_PINCODE_LOCALITY": "PERAMANNUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574184,
      "TXT_PINCODE_LOCALITY": "TALAPADY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574185,
      "TXT_PINCODE_LOCALITY": "AMBLAMOGARU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574186,
      "TXT_PINCODE_LOCALITY": "BOLIYAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574187,
      "TXT_PINCODE_LOCALITY": "MERMAJAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574188,
      "TXT_PINCODE_LOCALITY": "PHAJIRU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574189,
      "TXT_PINCODE_LOCALITY": "KOLAMBE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574190,
      "TXT_PINCODE_LOCALITY": "KUTHETHUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574191,
      "TXT_PINCODE_LOCALITY": "BONDANTHILA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574192,
      "TXT_PINCODE_LOCALITY": "JEEPINAMOGRU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574193,
      "TXT_PINCODE_LOCALITY": "KANNUR (D K)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574194,
      "TXT_PINCODE_LOCALITY": "KAVUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574195,
      "TXT_PINCODE_LOCALITY": "PANJINADKA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574196,
      "TXT_PINCODE_LOCALITY": "KULAI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574197,
      "TXT_PINCODE_LOCALITY": "KODANGALLU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574198,
      "TXT_PINCODE_LOCALITY": "KOKKADA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574199,
      "TXT_PINCODE_LOCALITY": "BANTVAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 441341,
      "City District Name": "DAKSHINA KANNADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574201,
      "TXT_PINCODE_LOCALITY": "PUTTUR H O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574202,
      "TXT_PINCODE_LOCALITY": "PUTTUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574203,
      "TXT_PINCODE_LOCALITY": "NEHRUNAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574208,
      "TXT_PINCODE_LOCALITY": "KALLADKA BANTWAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574210,
      "TXT_PINCODE_LOCALITY": "ARYAPU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574211,
      "TXT_PINCODE_LOCALITY": "BANTWAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574212,
      "TXT_PINCODE_LOCALITY": "BELLARE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574213,
      "TXT_PINCODE_LOCALITY": "BELVAI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574214,
      "TXT_PINCODE_LOCALITY": "BELTHANGADY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574216,
      "TXT_PINCODE_LOCALITY": "DHARMASTHALA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574217,
      "TXT_PINCODE_LOCALITY": "GURURAYANKERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574218,
      "TXT_PINCODE_LOCALITY": "GUTHIGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574219,
      "TXT_PINCODE_LOCALITY": "JODUMARGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574220,
      "TXT_PINCODE_LOCALITY": "KABAKA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574221,
      "TXT_PINCODE_LOCALITY": "KADABA (PUTTUR)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574222,
      "TXT_PINCODE_LOCALITY": "KALLADAKA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574223,
      "TXT_PINCODE_LOCALITY": "KAV",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574224,
      "TXT_PINCODE_LOCALITY": "MADANTHYAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574225,
      "TXT_PINCODE_LOCALITY": "MIJAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 441341,
      "City District Name": "DAKSHINA KANNADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574226,
      "TXT_PINCODE_LOCALITY": "MITHABAIL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574227,
      "TXT_PINCODE_LOCALITY": "ASWATHAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 441341,
      "City District Name": "DAKSHINA KANNADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574228,
      "TXT_PINCODE_LOCALITY": "MUNDAJE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574229,
      "TXT_PINCODE_LOCALITY": "GOLITHOTHOTTU JUNCTION.",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 6427,
      "City District Name": "PUTTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574230,
      "TXT_PINCODE_LOCALITY": "NETTANA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574231,
      "TXT_PINCODE_LOCALITY": "PANE MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574232,
      "TXT_PINCODE_LOCALITY": "PANJA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574233,
      "TXT_PINCODE_LOCALITY": "PUNJALKATTE S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574234,
      "TXT_PINCODE_LOCALITY": "SAMPAJE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574235,
      "TXT_PINCODE_LOCALITY": "SATHYA SAIVIHAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574236,
      "TXT_PINCODE_LOCALITY": "SHIRADY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574237,
      "TXT_PINCODE_LOCALITY": "SIDDAKATTE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574238,
      "TXT_PINCODE_LOCALITY": "SUBRAMANYA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574239,
      "TXT_PINCODE_LOCALITY": "SOMANGIRI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 441341,
      "City District Name": "DAKSHINA KANNADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574240,
      "TXT_PINCODE_LOCALITY": "S D M COLLEGE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574241,
      "TXT_PINCODE_LOCALITY": "ELANTHILA S. O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2427,
      "City District Name": "BELTANGADI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574242,
      "TXT_PINCODE_LOCALITY": "VENUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574243,
      "TXT_PINCODE_LOCALITY": "VITTAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574244,
      "TXT_PINCODE_LOCALITY": "PALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574245,
      "TXT_PINCODE_LOCALITY": "AIYARNAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574246,
      "TXT_PINCODE_LOCALITY": "AJJAVARA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574247,
      "TXT_PINCODE_LOCALITY": "ALETTY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574248,
      "TXT_PINCODE_LOCALITY": "DODDATHOTA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574249,
      "TXT_PINCODE_LOCALITY": "SONANGERI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574250,
      "TXT_PINCODE_LOCALITY": "ANANTHADY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574251,
      "TXT_PINCODE_LOCALITY": "BORIMARU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574252,
      "TXT_PINCODE_LOCALITY": "IDIKIKU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574253,
      "TXT_PINCODE_LOCALITY": "BANTWAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 441341,
      "City District Name": "DAKSHINA KANNADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574254,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574255,
      "TXT_PINCODE_LOCALITY": "JALSUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574256,
      "TXT_PINCODE_LOCALITY": "MANDEKOLU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574257,
      "TXT_PINCODE_LOCALITY": "KALMADKA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574258,
      "TXT_PINCODE_LOCALITY": "KUMBRA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574259,
      "TXT_PINCODE_LOCALITY": "PANJEE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574260,
      "TXT_PINCODE_LOCALITY": "ADYANDKA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574261,
      "TXT_PINCODE_LOCALITY": "VITTALMUDNUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574262,
      "TXT_PINCODE_LOCALITY": "AMTADY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574263,
      "TXT_PINCODE_LOCALITY": "NAVARU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574264,
      "TXT_PINCODE_LOCALITY": "SARAPADY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574265,
      "TXT_PINCODE_LOCALITY": "VOGGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574266,
      "TXT_PINCODE_LOCALITY": "BADAGUMIJARU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574267,
      "TXT_PINCODE_LOCALITY": "BADAGA YEDAPADAVU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574268,
      "TXT_PINCODE_LOCALITY": "BALTHILA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574269,
      "TXT_PINCODE_LOCALITY": "KODAPADAVU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574270,
      "TXT_PINCODE_LOCALITY": "KOLNADU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574272,
      "TXT_PINCODE_LOCALITY": "KADANDALE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574273,
      "TXT_PINCODE_LOCALITY": "KALLABETTU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574274,
      "TXT_PINCODE_LOCALITY": "KALLAMUNDKUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574275,
      "TXT_PINCODE_LOCALITY": "SAMPIGE (D K)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574276,
      "TXT_PINCODE_LOCALITY": "VEERAKAMBA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574277,
      "TXT_PINCODE_LOCALITY": "KADANDALE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574278,
      "TXT_PINCODE_LOCALITY": "PALAOKA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574279,
      "TXT_PINCODE_LOCALITY": "KANYANA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574280,
      "TXT_PINCODE_LOCALITY": "KAROPADY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574281,
      "TXT_PINCODE_LOCALITY": "PUNCHA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574282,
      "TXT_PINCODE_LOCALITY": "NARIKOMBU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574283,
      "TXT_PINCODE_LOCALITY": "SAJI PAMUNNUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574284,
      "TXT_PINCODE_LOCALITY": "POLALIPANCHAYATH",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574285,
      "TXT_PINCODE_LOCALITY": "ALANKAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574286,
      "TXT_PINCODE_LOCALITY": "BAJATHUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574287,
      "TXT_PINCODE_LOCALITY": "KODIMBADY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574288,
      "TXT_PINCODE_LOCALITY": "KOILACATTLE FARM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574289,
      "TXT_PINCODE_LOCALITY": "PARAMOGRU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574290,
      "TXT_PINCODE_LOCALITY": "CHARMADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574291,
      "TXT_PINCODE_LOCALITY": "KAKKINJE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574292,
      "TXT_PINCODE_LOCALITY": "NERIA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574293,
      "TXT_PINCODE_LOCALITY": "GERUKATTE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574294,
      "TXT_PINCODE_LOCALITY": "LAILA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574295,
      "TXT_PINCODE_LOCALITY": "MACHINA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574296,
      "TXT_PINCODE_LOCALITY": "PANAKAJE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574297,
      "TXT_PINCODE_LOCALITY": "KALLIGE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574298,
      "TXT_PINCODE_LOCALITY": "MANINALKUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574299,
      "TXT_PINCODE_LOCALITY": "AMMUNJE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574300,
      "TXT_PINCODE_LOCALITY": "BADAGA BELLOOR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574301,
      "TXT_PINCODE_LOCALITY": "IRA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574302,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574303,
      "TXT_PINCODE_LOCALITY": "BELAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574304,
      "TXT_PINCODE_LOCALITY": "IIANTHILA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574305,
      "TXT_PINCODE_LOCALITY": "KOYYUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574306,
      "TXT_PINCODE_LOCALITY": "HIREBADADY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574307,
      "TXT_PINCODE_LOCALITY": "PUTTUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 6427,
      "City District Name": "PUTTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574309,
      "TXT_PINCODE_LOCALITY": "KEDILA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574310,
      "TXT_PINCODE_LOCALITY": "BETTAMPADY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574311,
      "TXT_PINCODE_LOCALITY": "MANIKAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574312,
      "TXT_PINCODE_LOCALITY": "NARIMOGRU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574313,
      "TXT_PINCODE_LOCALITY": "NETTANIGE MUDNUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574314,
      "TXT_PINCODE_LOCALITY": "ARANTHODU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574315,
      "TXT_PINCODE_LOCALITY": "NADUGALLU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574316,
      "TXT_PINCODE_LOCALITY": "SATARI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574317,
      "TXT_PINCODE_LOCALITY": "GUNDYADKA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574318,
      "TXT_PINCODE_LOCALITY": "MADAVU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574319,
      "TXT_PINCODE_LOCALITY": "SAJIPAMUDU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574320,
      "TXT_PINCODE_LOCALITY": "UDUPI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574321,
      "TXT_PINCODE_LOCALITY": "BALNAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574323,
      "TXT_PINCODE_LOCALITY": "MANCHI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574324,
      "TXT_PINCODE_LOCALITY": "BANTVAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 441341,
      "City District Name": "DAKSHINA KANNADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574325,
      "TXT_PINCODE_LOCALITY": "NEKKILADY(34) S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 441341,
      "City District Name": "DAKSHINA KANNADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574326,
      "TXT_PINCODE_LOCALITY": "KALLERI S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574327,
      "TXT_PINCODE_LOCALITY": "KURUNJIBAG S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 441341,
      "City District Name": "DAKSHINA KANNADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574328,
      "TXT_PINCODE_LOCALITY": "KANIYOOR.",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 6427,
      "City District Name": "PUTTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574333,
      "TXT_PINCODE_LOCALITY": "SHIBAJE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574334,
      "TXT_PINCODE_LOCALITY": "BADAGANNUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574335,
      "TXT_PINCODE_LOCALITY": "KAVALKUTTE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574336,
      "TXT_PINCODE_LOCALITY": "BOLILA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574337,
      "TXT_PINCODE_LOCALITY": "CHIKAMUDNUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574353,
      "TXT_PINCODE_LOCALITY": "IDKIDU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 442802,
      "City District Name": "BANTVAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574371,
      "TXT_PINCODE_LOCALITY": "MANCHI (DKA)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574501,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574502,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574503,
      "TXT_PINCODE_LOCALITY": "YERLAPADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574504,
      "TXT_PINCODE_LOCALITY": "MELAVANKI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574505,
      "TXT_PINCODE_LOCALITY": "KORANGRAPDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574507,
      "TXT_PINCODE_LOCALITY": "BAJAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574508,
      "TXT_PINCODE_LOCALITY": "VAMANJOOR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574509,
      "TXT_PINCODE_LOCALITY": "PERMUDE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574518,
      "TXT_PINCODE_LOCALITY": "GUTHIGARU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 5787,
      "City District Name": "SULYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574519,
      "TXT_PINCODE_LOCALITY": "KARIYANGALA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 442802,
      "City District Name": "BANTVAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 574538,
      "TXT_PINCODE_LOCALITY": "YENEKALLU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 5787,
      "City District Name": "SULYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 575001,
      "TXT_PINCODE_LOCALITY": "BHAVANTHI STREET",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 575002,
      "TXT_PINCODE_LOCALITY": "KANKANADY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 575003,
      "TXT_PINCODE_LOCALITY": "LORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 575004,
      "TXT_PINCODE_LOCALITY": "KODIALBAIL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 575005,
      "TXT_PINCODE_LOCALITY": "BIKKERNAKATTA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 575006,
      "TXT_PINCODE_LOCALITY": "ASHOK NAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 575007,
      "TXT_PINCODE_LOCALITY": "PADIL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 575008,
      "TXT_PINCODE_LOCALITY": "KONCHADY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 575009,
      "TXT_PINCODE_LOCALITY": "JEEPINAMOGRU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 441341,
      "City District Name": "DAKSHINA KANNADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 575010,
      "TXT_PINCODE_LOCALITY": "PANAMBUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 575011,
      "TXT_PINCODE_LOCALITY": "MANGALORE.",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 441341,
      "City District Name": "DAKSHINA KANNADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 575012,
      "TXT_PINCODE_LOCALITY": "NIRMARGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 575013,
      "TXT_PINCODE_LOCALITY": "KULUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 575014,
      "TXT_PINCODE_LOCALITY": "SURATHKAL S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 425594,
      "City District Name": "DAKSHINI KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 575015,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 441341,
      "City District Name": "DAKSHINA KANNADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 575016,
      "TXT_PINCODE_LOCALITY": "SHAKTINAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 575017,
      "TXT_PINCODE_LOCALITY": "PERMANNUR S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 575018,
      "TXT_PINCODE_LOCALITY": "DERLAKATTE S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 441341,
      "City District Name": "DAKSHINA KANNADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 575019,
      "TXT_PINCODE_LOCALITY": "KULAI HOSABETTU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 441341,
      "City District Name": "DAKSHINA KANNADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 575020,
      "TXT_PINCODE_LOCALITY": "ULLAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 575021,
      "TXT_PINCODE_LOCALITY": "SURATHKAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 575022,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 441341,
      "City District Name": "DAKSHINA KANNADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 575023,
      "TXT_PINCODE_LOCALITY": "SOMESHWAR UCHIL S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 575025,
      "TXT_PINCODE_LOCALITY": "SRINIVASNAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 575026,
      "TXT_PINCODE_LOCALITY": "KULAI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 575027,
      "TXT_PINCODE_LOCALITY": "PADIL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 575028,
      "TXT_PINCODE_LOCALITY": "VAMANJOOR S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 575029,
      "TXT_PINCODE_LOCALITY": "PEDAMALE S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 575030,
      "TXT_PINCODE_LOCALITY": "KUTHETHOOR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 575031,
      "TXT_PINCODE_LOCALITY": "ADYAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1937,
      "City District Name": "ADYAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 575069,
      "TXT_PINCODE_LOCALITY": "ADYAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1937,
      "City District Name": "ADYAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 575328,
      "TXT_PINCODE_LOCALITY": "MURULYA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 5787,
      "City District Name": "SULYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576101,
      "TXT_PINCODE_LOCALITY": "UDIPI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 425911,
      "City District Name": "UDIPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576102,
      "TXT_PINCODE_LOCALITY": "SHIVALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576103,
      "TXT_PINCODE_LOCALITY": "KIDIYUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576104,
      "TXT_PINCODE_LOCALITY": "KODIKAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576105,
      "TXT_PINCODE_LOCALITY": "SOODA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576106,
      "TXT_PINCODE_LOCALITY": "KRODASHRAMA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576107,
      "TXT_PINCODE_LOCALITY": "MOODUBELLE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576108,
      "TXT_PINCODE_LOCALITY": "SHAMNEWADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576111,
      "TXT_PINCODE_LOCALITY": "YELIMANOLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576112,
      "TXT_PINCODE_LOCALITY": "MUDRADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576113,
      "TXT_PINCODE_LOCALITY": "HIRIADKA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576114,
      "TXT_PINCODE_LOCALITY": "KALLIANPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576115,
      "TXT_PINCODE_LOCALITY": "KEMMANNU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 425594,
      "City District Name": "DAKSHINI KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576116,
      "TXT_PINCODE_LOCALITY": "KODAVOOR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576117,
      "TXT_PINCODE_LOCALITY": "SAUNDATTI (GOKAK)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576118,
      "TXT_PINCODE_LOCALITY": "MALPE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576119,
      "TXT_PINCODE_LOCALITY": "MANIPAL H O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576120,
      "TXT_PINCODE_LOCALITY": "SHINDIKURBET",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576121,
      "TXT_PINCODE_LOCALITY": "INNA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576122,
      "TXT_PINCODE_LOCALITY": "MANI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576123,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576124,
      "TXT_PINCODE_LOCALITY": "ULLAGADDI (KHANAPUR)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576126,
      "TXT_PINCODE_LOCALITY": "AMBALPADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576127,
      "TXT_PINCODE_LOCALITY": "WADERHATTI (BELGAUM)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576128,
      "TXT_PINCODE_LOCALITY": "SACHERIGUDDE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576129,
      "TXT_PINCODE_LOCALITY": "SOMESHWARA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576130,
      "TXT_PINCODE_LOCALITY": "AREKANDIGE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576131,
      "TXT_PINCODE_LOCALITY": "HAVANJE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576132,
      "TXT_PINCODE_LOCALITY": "KUKKEHALLI PERDOOR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576133,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576134,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576135,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576136,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576137,
      "TXT_PINCODE_LOCALITY": "HOODE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576138,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576139,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576141,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576142,
      "TXT_PINCODE_LOCALITY": "TENKABETTU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576143,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576144,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576145,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576146,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576148,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576150,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576153,
      "TXT_PINCODE_LOCALITY": "HIREBETTU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576156,
      "TXT_PINCODE_LOCALITY": "KAUP",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576201,
      "TXT_PINCODE_LOCALITY": "TULSIGERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576210,
      "TXT_PINCODE_LOCALITY": "BARKUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576211,
      "TXT_PINCODE_LOCALITY": "AMPAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576212,
      "TXT_PINCODE_LOCALITY": "PARAMPALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576213,
      "TXT_PINCODE_LOCALITY": "KODI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576214,
      "TXT_PINCODE_LOCALITY": "HARADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576215,
      "TXT_PINCODE_LOCALITY": "CHERKADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576216,
      "TXT_PINCODE_LOCALITY": "GANGULI S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576217,
      "TXT_PINCODE_LOCALITY": "CHACHADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576218,
      "TXT_PINCODE_LOCALITY": "SULEBHAVI (BELGAUM)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576219,
      "TXT_PINCODE_LOCALITY": "HUNGARCUTTA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576220,
      "TXT_PINCODE_LOCALITY": "KOLLUR (KUNDAPUR)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576221,
      "TXT_PINCODE_LOCALITY": "KOTA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576222,
      "TXT_PINCODE_LOCALITY": "AROOR (D K)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576223,
      "TXT_PINCODE_LOCALITY": "ANAKALGI (BAILHONGAL)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576224,
      "TXT_PINCODE_LOCALITY": "NAVUNDA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576225,
      "TXT_PINCODE_LOCALITY": "SALIGRAMA (D K)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576226,
      "TXT_PINCODE_LOCALITY": "SASTAN S. O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576227,
      "TXT_PINCODE_LOCALITY": "SASTAN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576228,
      "TXT_PINCODE_LOCALITY": "SHIRUR (DK)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576229,
      "TXT_PINCODE_LOCALITY": "SIDDAPURA (UDUPI) S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576230,
      "TXT_PINCODE_LOCALITY": "KAIKERI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424371,
      "City District Name": "KODAGU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576231,
      "TXT_PINCODE_LOCALITY": "DESHNUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576232,
      "TXT_PINCODE_LOCALITY": "BAILUR (BELGAUM)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576233,
      "TXT_PINCODE_LOCALITY": "BASTWAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576234,
      "TXT_PINCODE_LOCALITY": "VANDSE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576235,
      "TXT_PINCODE_LOCALITY": "KOKKARNE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576236,
      "TXT_PINCODE_LOCALITY": "AIRODY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576237,
      "TXT_PINCODE_LOCALITY": "GUNDMI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576238,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576239,
      "TXT_PINCODE_LOCALITY": "GULVADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576240,
      "TXT_PINCODE_LOCALITY": "KANDLUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576241,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576242,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576243,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576244,
      "TXT_PINCODE_LOCALITY": "BIJUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576245,
      "TXT_PINCODE_LOCALITY": "ARDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576246,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576247,
      "TXT_PINCODE_LOCALITY": "UDUPI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576248,
      "TXT_PINCODE_LOCALITY": "HAKLADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576249,
      "TXT_PINCODE_LOCALITY": "HEMMADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576250,
      "TXT_PINCODE_LOCALITY": "HALADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576251,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576252,
      "TXT_PINCODE_LOCALITY": "HALLIHOLE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576253,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576254,
      "TXT_PINCODE_LOCALITY": "UPPINANGADY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424454,
      "City District Name": "DAKSHIN KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576255,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576256,
      "TXT_PINCODE_LOCALITY": "KODIBENGRE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576257,
      "TXT_PINCODE_LOCALITY": "KUMBHASHI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576258,
      "TXT_PINCODE_LOCALITY": "KUNJAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576259,
      "TXT_PINCODE_LOCALITY": "NEELAVARA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576260,
      "TXT_PINCODE_LOCALITY": "HARADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576261,
      "TXT_PINCODE_LOCALITY": "MARVANTHE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576262,
      "TXT_PINCODE_LOCALITY": "NADA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576263,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576264,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576265,
      "TXT_PINCODE_LOCALITY": "MANWI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576266,
      "TXT_PINCODE_LOCALITY": "SAIBRAKATTE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576267,
      "TXT_PINCODE_LOCALITY": "KOTATHATTU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576269,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576270,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576271,
      "TXT_PINCODE_LOCALITY": "BAIDABETTU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576272,
      "TXT_PINCODE_LOCALITY": "BEEJADY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576273,
      "TXT_PINCODE_LOCALITY": "KAVRADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576274,
      "TXT_PINCODE_LOCALITY": "MOLAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576275,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576276,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576277,
      "TXT_PINCODE_LOCALITY": "KANGAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576278,
      "TXT_PINCODE_LOCALITY": "HANEHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576279,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576280,
      "TXT_PINCODE_LOCALITY": "MANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576281,
      "TXT_PINCODE_LOCALITY": "MANSA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576282,
      "TXT_PINCODE_LOCALITY": "HOSANGADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576283,
      "TXT_PINCODE_LOCALITY": "KENCHANUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576410,
      "TXT_PINCODE_LOCALITY": "UDUPI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 485,
      "City District Name": "UDUPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 576427,
      "TXT_PINCODE_LOCALITY": "AMASEBAILU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 4153,
      "City District Name": "KUNDAPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577001,
      "TXT_PINCODE_LOCALITY": "DAVANAGERE S. O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577002,
      "TXT_PINCODE_LOCALITY": "DAVANGERE K B EXTN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577003,
      "TXT_PINCODE_LOCALITY": "DAVANGERE MILLS",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577004,
      "TXT_PINCODE_LOCALITY": "KASTURBA EXTN S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577005,
      "TXT_PINCODE_LOCALITY": "BAPUJI VIDYANAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 744,
      "City District Name": "DAVANGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577006,
      "TXT_PINCODE_LOCALITY": "VINOBANAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 744,
      "City District Name": "DAVANGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577007,
      "TXT_PINCODE_LOCALITY": "DAVANAGERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577101,
      "TXT_PINCODE_LOCALITY": "CHIKMAGALURE H O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 601,
      "City District Name": "MANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577102,
      "TXT_PINCODE_LOCALITY": "JYOTHINAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577111,
      "TXT_PINCODE_LOCALITY": "ALDUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577112,
      "TXT_PINCODE_LOCALITY": "BALEHONNUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577113,
      "TXT_PINCODE_LOCALITY": "BANAKAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577114,
      "TXT_PINCODE_LOCALITY": "ATTIKUDIGE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 4069,
      "City District Name": "KOPPA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577115,
      "TXT_PINCODE_LOCALITY": "BHADRA RESERVIOR PROJECT",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577116,
      "TXT_PINCODE_LOCALITY": "BIRUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577117,
      "TXT_PINCODE_LOCALITY": "C R STATION",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577118,
      "TXT_PINCODE_LOCALITY": "DURGADABETTA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577119,
      "TXT_PINCODE_LOCALITY": "HANBALU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577120,
      "TXT_PINCODE_LOCALITY": "HARIHARAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577121,
      "TXT_PINCODE_LOCALITY": "HIREBYLE S. O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577122,
      "TXT_PINCODE_LOCALITY": "JAVALI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577123,
      "TXT_PINCODE_LOCALITY": "KOPPA S. O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577124,
      "TXT_PINCODE_LOCALITY": "KALASA S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577125,
      "TXT_PINCODE_LOCALITY": "KAMMARADI S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577126,
      "TXT_PINCODE_LOCALITY": "KOPPA H O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577127,
      "TXT_PINCODE_LOCALITY": "KUDREMUKH",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577128,
      "TXT_PINCODE_LOCALITY": "CHIKMAGALUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577129,
      "TXT_PINCODE_LOCALITY": "LINGADAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577130,
      "TXT_PINCODE_LOCALITY": "MALLANDUR S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577131,
      "TXT_PINCODE_LOCALITY": "MALLENAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577132,
      "TXT_PINCODE_LOCALITY": "MUDIGERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577133,
      "TXT_PINCODE_LOCALITY": "MUGTIHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577134,
      "TXT_PINCODE_LOCALITY": "NARASIMHARAJAPURA S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577135,
      "TXT_PINCODE_LOCALITY": "CHICKMAGALUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577136,
      "TXT_PINCODE_LOCALITY": "CHIKAMAGALUR S. O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577137,
      "TXT_PINCODE_LOCALITY": "SANTAVERI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577138,
      "TXT_PINCODE_LOCALITY": "CHIKMAGALUR.",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577139,
      "TXT_PINCODE_LOCALITY": "SRINGERI (KODUR)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577140,
      "TXT_PINCODE_LOCALITY": "YADGATI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577141,
      "TXT_PINCODE_LOCALITY": "CHIKMAGALUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577142,
      "TXT_PINCODE_LOCALITY": "CHIKMAGALUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577143,
      "TXT_PINCODE_LOCALITY": "R.D. KAVALU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577144,
      "TXT_PINCODE_LOCALITY": "RANGENAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577145,
      "TXT_PINCODE_LOCALITY": "TARIKERE S. O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577146,
      "TXT_PINCODE_LOCALITY": "KALASAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577147,
      "TXT_PINCODE_LOCALITY": "SAKLESHPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 5454,
      "City District Name": "SAKLESHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577148,
      "TXT_PINCODE_LOCALITY": "ALAGESWARA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577149,
      "TXT_PINCODE_LOCALITY": "AVATHI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577150,
      "TXT_PINCODE_LOCALITY": "BHANDIGODI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577151,
      "TXT_PINCODE_LOCALITY": "GONIBEED",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577152,
      "TXT_PINCODE_LOCALITY": "GUDDETHOTA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577153,
      "TXT_PINCODE_LOCALITY": "HOSAKOPPA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577154,
      "TXT_PINCODE_LOCALITY": "JANNAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577155,
      "TXT_PINCODE_LOCALITY": "K BIDRE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577156,
      "TXT_PINCODE_LOCALITY": "KAIMARA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577157,
      "TXT_PINCODE_LOCALITY": "KUDLUR (CHIKMAGALUR)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577158,
      "TXT_PINCODE_LOCALITY": "KUDUVALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577159,
      "TXT_PINCODE_LOCALITY": "LOKANATHAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577160,
      "TXT_PINCODE_LOCALITY": "MAGUNDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577161,
      "TXT_PINCODE_LOCALITY": "MELPAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577162,
      "TXT_PINCODE_LOCALITY": "SHANTHIGRAMA (CHM)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577163,
      "TXT_PINCODE_LOCALITY": "TOGARIHANKAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577164,
      "TXT_PINCODE_LOCALITY": "KURUBARABUDIHALU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577165,
      "TXT_PINCODE_LOCALITY": "CHIKMAGALUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577167,
      "TXT_PINCODE_LOCALITY": "PILENAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577168,
      "TXT_PINCODE_LOCALITY": "UDDEBORANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577169,
      "TXT_PINCODE_LOCALITY": "SAMPIGE KHAN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577170,
      "TXT_PINCODE_LOCALITY": "ANUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577171,
      "TXT_PINCODE_LOCALITY": "KOTTIGEHARA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 344,
      "City District Name": "HASSAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577172,
      "TXT_PINCODE_LOCALITY": "BISALARE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577173,
      "TXT_PINCODE_LOCALITY": "HANTHUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577174,
      "TXT_PINCODE_LOCALITY": "VASTARA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577175,
      "TXT_PINCODE_LOCALITY": "DEVANUR (KADUR)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577177,
      "TXT_PINCODE_LOCALITY": "ADDAGADDE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577178,
      "TXT_PINCODE_LOCALITY": "GULLANPET",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577179,
      "TXT_PINCODE_LOCALITY": "BALEHOLE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577180,
      "TXT_PINCODE_LOCALITY": "VOKKALAGERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 3654,
      "City District Name": "KADUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577181,
      "TXT_PINCODE_LOCALITY": "HORNADU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 4579,
      "City District Name": "MUDIGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577182,
      "TXT_PINCODE_LOCALITY": "GANGENAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577201,
      "TXT_PINCODE_LOCALITY": "RAVINDRANAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577202,
      "TXT_PINCODE_LOCALITY": "SHIMOGA VIDYANAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577203,
      "TXT_PINCODE_LOCALITY": "MATHUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577204,
      "TXT_PINCODE_LOCALITY": "KOTE GANGUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577205,
      "TXT_PINCODE_LOCALITY": "GOPALA EXTENSION S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577210,
      "TXT_PINCODE_LOCALITY": "THARAGANAHALLI.",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 3432,
      "City District Name": "HONNALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577211,
      "TXT_PINCODE_LOCALITY": "AYANUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577212,
      "TXT_PINCODE_LOCALITY": "SHIMOGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577213,
      "TXT_PINCODE_LOCALITY": "CHANNAGERI TOWN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577214,
      "TXT_PINCODE_LOCALITY": "ISSUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577215,
      "TXT_PINCODE_LOCALITY": "HODIKERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577216,
      "TXT_PINCODE_LOCALITY": "HOLALUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577217,
      "TXT_PINCODE_LOCALITY": "DAVANAGERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577218,
      "TXT_PINCODE_LOCALITY": "KEREBILICHI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577219,
      "TXT_PINCODE_LOCALITY": "KUNDUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577220,
      "TXT_PINCODE_LOCALITY": "MANDAGADDE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577221,
      "TXT_PINCODE_LOCALITY": "DAVANGERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577222,
      "TXT_PINCODE_LOCALITY": "HONNALI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577223,
      "TXT_PINCODE_LOCALITY": "NYAMATI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577224,
      "TXT_PINCODE_LOCALITY": "SASVEHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577225,
      "TXT_PINCODE_LOCALITY": "SAULANGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577226,
      "TXT_PINCODE_LOCALITY": "TUDUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577227,
      "TXT_PINCODE_LOCALITY": "KUDLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577228,
      "TXT_PINCODE_LOCALITY": "TARIKERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577229,
      "TXT_PINCODE_LOCALITY": "KAVALGUNDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577230,
      "TXT_PINCODE_LOCALITY": "DAVANGERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577231,
      "TXT_PINCODE_LOCALITY": "KANIVEBILICHI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577232,
      "TXT_PINCODE_LOCALITY": "BEJJAVALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577233,
      "TXT_PINCODE_LOCALITY": "KUDDIGERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577234,
      "TXT_PINCODE_LOCALITY": "DEVARAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577235,
      "TXT_PINCODE_LOCALITY": "HOSADURGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 3435,
      "City District Name": "HOSANAGARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577236,
      "TXT_PINCODE_LOCALITY": "SHIMOGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577237,
      "TXT_PINCODE_LOCALITY": "SHIMOGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577238,
      "TXT_PINCODE_LOCALITY": "KUMBLUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577239,
      "TXT_PINCODE_LOCALITY": "KULAMBI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577240,
      "TXT_PINCODE_LOCALITY": "MALUR (SHIMOGA)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577241,
      "TXT_PINCODE_LOCALITY": "PILLANGERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577242,
      "TXT_PINCODE_LOCALITY": "SUGUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577243,
      "TXT_PINCODE_LOCALITY": "ANAVERI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577244,
      "TXT_PINCODE_LOCALITY": "SHIMOGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577245,
      "TXT_PINCODE_LOCALITY": "BARANDUR S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577246,
      "TXT_PINCODE_LOCALITY": "SOMVARPET",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 5738,
      "City District Name": "SOMVARPET",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577247,
      "TXT_PINCODE_LOCALITY": "BANDIGUDDE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577273,
      "TXT_PINCODE_LOCALITY": "GANJEENAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 3432,
      "City District Name": "HONNALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577301,
      "TXT_PINCODE_LOCALITY": "BHADRAVATHI H O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577302,
      "TXT_PINCODE_LOCALITY": "BHADRAVATI PAPER TOWN *",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577303,
      "TXT_PINCODE_LOCALITY": "BHADRAVATHI R S",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577316,
      "TXT_PINCODE_LOCALITY": "NANDIGAVI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 6478,
      "City District Name": "HARIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577321,
      "TXT_PINCODE_LOCALITY": "ARBILICHI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577355,
      "TXT_PINCODE_LOCALITY": "HIREGUNTANUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577401,
      "TXT_PINCODE_LOCALITY": "SAGAR BAZAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577411,
      "TXT_PINCODE_LOCALITY": "AGUMBE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577412,
      "TXT_PINCODE_LOCALITY": "ANANDAPURAM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577413,
      "TXT_PINCODE_LOCALITY": "MOODY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577414,
      "TXT_PINCODE_LOCALITY": "ARGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577415,
      "TXT_PINCODE_LOCALITY": "DEVANGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577416,
      "TXT_PINCODE_LOCALITY": "HAVNAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577417,
      "TXT_PINCODE_LOCALITY": "HONNESARA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577418,
      "TXT_PINCODE_LOCALITY": "HOSA NAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577419,
      "TXT_PINCODE_LOCALITY": "JADE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577421,
      "TXT_PINCODE_LOCALITY": "KARGAL COLONY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577422,
      "TXT_PINCODE_LOCALITY": "KONANDUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577423,
      "TXT_PINCODE_LOCALITY": "KUMSI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577424,
      "TXT_PINCODE_LOCALITY": "ANAVATTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577425,
      "TXT_PINCODE_LOCALITY": "NAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577426,
      "TXT_PINCODE_LOCALITY": "RIPPONPETE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577427,
      "TXT_PINCODE_LOCALITY": "SHIKARIPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577428,
      "TXT_PINCODE_LOCALITY": "SHIRALKOPPA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577429,
      "TXT_PINCODE_LOCALITY": "SORAB",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577430,
      "TXT_PINCODE_LOCALITY": "TALAGUPPA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577431,
      "TXT_PINCODE_LOCALITY": "THAGARTHI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577432,
      "TXT_PINCODE_LOCALITY": "THIRTHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577433,
      "TXT_PINCODE_LOCALITY": "HARIGE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577434,
      "TXT_PINCODE_LOCALITY": "ULABI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577435,
      "TXT_PINCODE_LOCALITY": "S V P COLONY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577436,
      "TXT_PINCODE_LOCALITY": "HUMCHA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577437,
      "TXT_PINCODE_LOCALITY": "CHAKRA NAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577438,
      "TXT_PINCODE_LOCALITY": "ARASALU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577439,
      "TXT_PINCODE_LOCALITY": "AVINAHLLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577440,
      "TXT_PINCODE_LOCALITY": "BASAVANI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577441,
      "TXT_PINCODE_LOCALITY": "CHITRADURGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577442,
      "TXT_PINCODE_LOCALITY": "KOPPA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 4069,
      "City District Name": "KOPPA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577443,
      "TXT_PINCODE_LOCALITY": "SAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 6440,
      "City District Name": "SAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577444,
      "TXT_PINCODE_LOCALITY": "LINGANAMAKKI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577445,
      "TXT_PINCODE_LOCALITY": "KODUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577446,
      "TXT_PINCODE_LOCALITY": "KUPPADAGDDE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577447,
      "TXT_PINCODE_LOCALITY": "UDUGANI S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577448,
      "TXT_PINCODE_LOCALITY": "YEDUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577449,
      "TXT_PINCODE_LOCALITY": "SHIMOGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577450,
      "TXT_PINCODE_LOCALITY": "BELAGAAVI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577451,
      "TXT_PINCODE_LOCALITY": "KVV SHANKARAGHATTTA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577452,
      "TXT_PINCODE_LOCALITY": "NITTUR S.O (SHIMOGA)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577453,
      "TXT_PINCODE_LOCALITY": "SAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577500,
      "TXT_PINCODE_LOCALITY": "GUDDADA THUMMINAKATTE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 6478,
      "City District Name": "HARIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577501,
      "TXT_PINCODE_LOCALITY": "JAYACHANRAJENDRA EXTN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577502,
      "TXT_PINCODE_LOCALITY": "REGULATED MARKET YARD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577503,
      "TXT_PINCODE_LOCALITY": "HOLALKERE ROAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577511,
      "TXT_PINCODE_LOCALITY": "ADIVALA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577512,
      "TXT_PINCODE_LOCALITY": "ANAJI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577513,
      "TXT_PINCODE_LOCALITY": "ASAGODU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577514,
      "TXT_PINCODE_LOCALITY": "BADA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577515,
      "TXT_PINCODE_LOCALITY": "BAGUR (CHITRADURGA)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577516,
      "TXT_PINCODE_LOCALITY": "BHANUVALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577517,
      "TXT_PINCODE_LOCALITY": "TURUVANUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577518,
      "TXT_PINCODE_LOCALITY": "BHARAMANAYAKANA DURGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577519,
      "TXT_PINCODE_LOCALITY": "BHARAMA SAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577520,
      "TXT_PINCODE_LOCALITY": "BHEEMASAMUDRA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577521,
      "TXT_PINCODE_LOCALITY": "BIDAREKERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577522,
      "TXT_PINCODE_LOCALITY": "CHALLAKERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577523,
      "TXT_PINCODE_LOCALITY": "CHIKJAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577524,
      "TXT_PINCODE_LOCALITY": "DODDASIDDAVANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577525,
      "TXT_PINCODE_LOCALITY": "HADADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577526,
      "TXT_PINCODE_LOCALITY": "HOLALKERE OLD TOWN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577527,
      "TXT_PINCODE_LOCALITY": "HOSADURGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577528,
      "TXT_PINCODE_LOCALITY": "JAGALUR NEHRU ROAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577529,
      "TXT_PINCODE_LOCALITY": "KONDIAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577530,
      "TXT_PINCODE_LOCALITY": "MALEBENNUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577531,
      "TXT_PINCODE_LOCALITY": "MALLADIHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577532,
      "TXT_PINCODE_LOCALITY": "MARADIHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577533,
      "TXT_PINCODE_LOCALITY": "MATHODU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577534,
      "TXT_PINCODE_LOCALITY": "MAYAKONDA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577535,
      "TXT_PINCODE_LOCALITY": "MOKALMURU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577536,
      "TXT_PINCODE_LOCALITY": "NAYAKANAHATTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577537,
      "TXT_PINCODE_LOCALITY": "OBALA PURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577538,
      "TXT_PINCODE_LOCALITY": "PARQASURAMPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577539,
      "TXT_PINCODE_LOCALITY": "RAMAGIRI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577540,
      "TXT_PINCODE_LOCALITY": "RAMAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577541,
      "TXT_PINCODE_LOCALITY": "SIRIGERE (CHITRADURGA)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577542,
      "TXT_PINCODE_LOCALITY": "SRIRAMPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577543,
      "TXT_PINCODE_LOCALITY": "TALLAK",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577544,
      "TXT_PINCODE_LOCALITY": "THYAVANIGE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577545,
      "TXT_PINCODE_LOCALITY": "YERABALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577546,
      "TXT_PINCODE_LOCALITY": "HARIABBE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577547,
      "TXT_PINCODE_LOCALITY": "AJJAMPURA BAZAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577548,
      "TXT_PINCODE_LOCALITY": "KADUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577549,
      "TXT_PINCODE_LOCALITY": "SIVANE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577550,
      "TXT_PINCODE_LOCALITY": "SOLLAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577551,
      "TXT_PINCODE_LOCALITY": "BASAVAPATNA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 348,
      "City District Name": "SHIMOGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577552,
      "TXT_PINCODE_LOCALITY": "SANTABENNUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577553,
      "TXT_PINCODE_LOCALITY": "BILICHODU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577554,
      "TXT_PINCODE_LOCALITY": "HOSADURGA R S",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577555,
      "TXT_PINCODE_LOCALITY": "GODABANAHAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577556,
      "TXT_PINCODE_LOCALITY": "DAVANGERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577557,
      "TXT_PINCODE_LOCALITY": "HORAKERE DEVARAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577558,
      "TXT_PINCODE_LOCALITY": "AIMANGALA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577559,
      "TXT_PINCODE_LOCALITY": "ASANDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577560,
      "TXT_PINCODE_LOCALITY": "YEILAMBALASE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577561,
      "TXT_PINCODE_LOCALITY": "GIRIAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577562,
      "TXT_PINCODE_LOCALITY": "HIRENALLUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577563,
      "TXT_PINCODE_LOCALITY": "CHITRADURGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577564,
      "TXT_PINCODE_LOCALITY": "CHANNAGIRI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2702,
      "City District Name": "CHANNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577565,
      "TXT_PINCODE_LOCALITY": "NALKUDRE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577566,
      "TXT_PINCODE_LOCALITY": "BHADRA SUGAR FACTORY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577567,
      "TXT_PINCODE_LOCALITY": "CHANNAGIRI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2702,
      "City District Name": "CHANNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577568,
      "TXT_PINCODE_LOCALITY": "BELGHATTA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577569,
      "TXT_PINCODE_LOCALITY": "CHITRADURGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577570,
      "TXT_PINCODE_LOCALITY": "MEERSABIDHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577571,
      "TXT_PINCODE_LOCALITY": "N MAHADEVAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577572,
      "TXT_PINCODE_LOCALITY": "NAGASAMUDRA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577573,
      "TXT_PINCODE_LOCALITY": "ALUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577574,
      "TXT_PINCODE_LOCALITY": "ATTIGERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577576,
      "TXT_PINCODE_LOCALITY": "CHITRADURGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577577,
      "TXT_PINCODE_LOCALITY": "JIGALI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577579,
      "TXT_PINCODE_LOCALITY": "KUKKUVADA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577580,
      "TXT_PINCODE_LOCALITY": "MALALKERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577581,
      "TXT_PINCODE_LOCALITY": "MUTTUGUDUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577582,
      "TXT_PINCODE_LOCALITY": "NARAYANAGONDANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577583,
      "TXT_PINCODE_LOCALITY": "HEBBALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577584,
      "TXT_PINCODE_LOCALITY": "AURAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2153,
      "City District Name": "AURAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577585,
      "TXT_PINCODE_LOCALITY": "ABBINAHOLE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577586,
      "TXT_PINCODE_LOCALITY": "HARTHIKOTE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577587,
      "TXT_PINCODE_LOCALITY": "GANNAIKANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577588,
      "TXT_PINCODE_LOCALITY": "BELLUDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577589,
      "TXT_PINCODE_LOCALITY": "KONDAJJI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577590,
      "TXT_PINCODE_LOCALITY": "CHITRADURGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577592,
      "TXT_PINCODE_LOCALITY": "SIRGERI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577593,
      "TXT_PINCODE_LOCALITY": "CHITRADURGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577595,
      "TXT_PINCODE_LOCALITY": "BOMMAGONAKERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577596,
      "TXT_PINCODE_LOCALITY": "HALAGALADDI.",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577597,
      "TXT_PINCODE_LOCALITY": "CHITHRADURGA S. O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577598,
      "TXT_PINCODE_LOCALITY": "HIRIYUR S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577599,
      "TXT_PINCODE_LOCALITY": "HIRIYUR.",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577601,
      "TXT_PINCODE_LOCALITY": "HARIHARA S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577602,
      "TXT_PINCODE_LOCALITY": "YANTRAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577711,
      "TXT_PINCODE_LOCALITY": "NANDIHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 3422,
      "City District Name": "HIRIYUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577715,
      "TXT_PINCODE_LOCALITY": "HALLADAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 3654,
      "City District Name": "KADUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 577829,
      "TXT_PINCODE_LOCALITY": "CHIKKAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2672,
      "City District Name": "CHALLAKERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600001,
      "TXT_PINCODE_LOCALITY": "CHENNAI G.P.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600002,
      "TXT_PINCODE_LOCALITY": "PUDUPET",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600003,
      "TXT_PINCODE_LOCALITY": "MADRAS MEDICAL COLLEGE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600004,
      "TXT_PINCODE_LOCALITY": "RADHAKRISHNAN SALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600005,
      "TXT_PINCODE_LOCALITY": "TRIPLICANE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600006,
      "TXT_PINCODE_LOCALITY": "OLD COLLEGE BUILDINGS",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600007,
      "TXT_PINCODE_LOCALITY": "PURASAIWALKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600008,
      "TXT_PINCODE_LOCALITY": "EGMORE HIGH ROAD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600009,
      "TXT_PINCODE_LOCALITY": "FORT ST GEORGE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600010,
      "TXT_PINCODE_LOCALITY": "KILPAUK",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600011,
      "TXT_PINCODE_LOCALITY": "SEMBIAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600012,
      "TXT_PINCODE_LOCALITY": "SYRAHANS ROAD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600013,
      "TXT_PINCODE_LOCALITY": "ROYAPETAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600014,
      "TXT_PINCODE_LOCALITY": "TRIPLICANE SOUTH",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600015,
      "TXT_PINCODE_LOCALITY": "GUNIDY NORTH",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600016,
      "TXT_PINCODE_LOCALITY": "TIRUVALUVAR NAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600017,
      "TXT_PINCODE_LOCALITY": "HABIBULLAH ROAD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600018,
      "TXT_PINCODE_LOCALITY": "TEYNAMPET SOUTH",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600019,
      "TXT_PINCODE_LOCALITY": "TIRUVOTTIUR CENTRAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600020,
      "TXT_PINCODE_LOCALITY": "CENTRAL LEATHER RESEARCH",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600021,
      "TXT_PINCODE_LOCALITY": "WASHERMANPETA EAST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600022,
      "TXT_PINCODE_LOCALITY": "RAJ BHAVAN",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600023,
      "TXT_PINCODE_LOCALITY": "AYANAVARAM RLY COLONY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600024,
      "TXT_PINCODE_LOCALITY": "ARCOT ROAD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600025,
      "TXT_PINCODE_LOCALITY": "GUINDY ENGINEERING COLLEGE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600026,
      "TXT_PINCODE_LOCALITY": "VADAPALANI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600027,
      "TXT_PINCODE_LOCALITY": "MADRAS AIR PORT",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600028,
      "TXT_PINCODE_LOCALITY": "FORE SHORE ESTATE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600029,
      "TXT_PINCODE_LOCALITY": "AMINIJIKARAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600030,
      "TXT_PINCODE_LOCALITY": "PANCHIAPPAS COLLEGE HOSTE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600031,
      "TXT_PINCODE_LOCALITY": "WORLD UNIVERSITY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600032,
      "TXT_PINCODE_LOCALITY": "GUINDY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600033,
      "TXT_PINCODE_LOCALITY": "KUMARAN NAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600034,
      "TXT_PINCODE_LOCALITY": "NUNGAMBAKKAM BAZAAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600035,
      "TXT_PINCODE_LOCALITY": "NANDANAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600036,
      "TXT_PINCODE_LOCALITY": "INDIAN INST OF TECHNOLOGY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600037,
      "TXT_PINCODE_LOCALITY": "OFFIERS TRG SCHOOL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600038,
      "TXT_PINCODE_LOCALITY": "I C F COLONY - 4319",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600039,
      "TXT_PINCODE_LOCALITY": "VYSARPADY COOP IND ESTATE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600040,
      "TXT_PINCODE_LOCALITY": "ANNA NAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600041,
      "TXT_PINCODE_LOCALITY": "CHENNAI.",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600042,
      "TXT_PINCODE_LOCALITY": "VELACHERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600043,
      "TXT_PINCODE_LOCALITY": "RAJAINAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600044,
      "TXT_PINCODE_LOCALITY": "CIDCO ESTATE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600045,
      "TXT_PINCODE_LOCALITY": "TAMBARAM WEST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600046,
      "TXT_PINCODE_LOCALITY": "GROUND TRAINING SCHOOL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600047,
      "TXT_PINCODE_LOCALITY": "SANATORIUM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600048,
      "TXT_PINCODE_LOCALITY": "VANDALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600049,
      "TXT_PINCODE_LOCALITY": "VILLIVAKKAM SOUTH",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600050,
      "TXT_PINCODE_LOCALITY": "PADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600051,
      "TXT_PINCODE_LOCALITY": "MADHAVARAM MILK COLONY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600052,
      "TXT_PINCODE_LOCALITY": "PADIANALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600053,
      "TXT_PINCODE_LOCALITY": "ORGADAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600054,
      "TXT_PINCODE_LOCALITY": "CENTRAL VEHICLE RESEARCH",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600055,
      "TXT_PINCODE_LOCALITY": "AVADI AIR FORCE QUARTERS",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600056,
      "TXT_PINCODE_LOCALITY": "POONAMALLEE EAST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600057,
      "TXT_PINCODE_LOCALITY": "ENNORE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600058,
      "TXT_PINCODE_LOCALITY": "AMBATTUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600059,
      "TXT_PINCODE_LOCALITY": "GANAPATHIPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600060,
      "TXT_PINCODE_LOCALITY": "POZHAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600061,
      "TXT_PINCODE_LOCALITY": "PALVANTHANGAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600062,
      "TXT_PINCODE_LOCALITY": "SENTHILNAGAR S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6320,
      "City District Name": "TIRUVALLUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600063,
      "TXT_PINCODE_LOCALITY": "SRINIVASA NAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600064,
      "TXT_PINCODE_LOCALITY": "CHITLAPAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600065,
      "TXT_PINCODE_LOCALITY": "AVADI C R P F",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600066,
      "TXT_PINCODE_LOCALITY": "PUZHAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600067,
      "TXT_PINCODE_LOCALITY": "MUTHIAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1893,
      "City District Name": "TUTICORIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600068,
      "TXT_PINCODE_LOCALITY": "MANALI (MADRAS)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600069,
      "TXT_PINCODE_LOCALITY": "CHENNAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600070,
      "TXT_PINCODE_LOCALITY": "ANAKAPUTHUR - 2652",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600071,
      "TXT_PINCODE_LOCALITY": "KAMARAJ NAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600072,
      "TXT_PINCODE_LOCALITY": "PATTABIRAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600073,
      "TXT_PINCODE_LOCALITY": "SELAIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600074,
      "TXT_PINCODE_LOCALITY": "SITHATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600075,
      "TXT_PINCODE_LOCALITY": "PAMMAL EAST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600076,
      "TXT_PINCODE_LOCALITY": "KORATTUR R S",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600077,
      "TXT_PINCODE_LOCALITY": "CHENNAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600078,
      "TXT_PINCODE_LOCALITY": "KALIGNAR KARUNANIDHINAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600079,
      "TXT_PINCODE_LOCALITY": "BULLION MARKET",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600080,
      "TXT_PINCODE_LOCALITY": "KORATTUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600081,
      "TXT_PINCODE_LOCALITY": "MPT STAFF COLONY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600082,
      "TXT_PINCODE_LOCALITY": "JAWAHAR NAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600083,
      "TXT_PINCODE_LOCALITY": "ASHOK NAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600084,
      "TXT_PINCODE_LOCALITY": "GAUGADEESWARAN KOIL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600085,
      "TXT_PINCODE_LOCALITY": "KOTTUR MADRAS",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600086,
      "TXT_PINCODE_LOCALITY": "GOPALA PURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600087,
      "TXT_PINCODE_LOCALITY": "ALWAR TIRUNAGAR - 3992",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600088,
      "TXT_PINCODE_LOCALITY": "ADAMBAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600089,
      "TXT_PINCODE_LOCALITY": "NANDAMBAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600090,
      "TXT_PINCODE_LOCALITY": "BESANT NAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600091,
      "TXT_PINCODE_LOCALITY": "MADIPAKKAM S. O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600092,
      "TXT_PINCODE_LOCALITY": "VIRUGAMBAKKAM BAZAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600093,
      "TXT_PINCODE_LOCALITY": "SALIGRAMAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600094,
      "TXT_PINCODE_LOCALITY": "CHOOLAIMEDU MDO",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600095,
      "TXT_PINCODE_LOCALITY": "ADAYALAMPATTU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6320,
      "City District Name": "TIRUVALLUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600096,
      "TXT_PINCODE_LOCALITY": "PERUNGUDI (CHENNAI)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600097,
      "TXT_PINCODE_LOCALITY": "DEFENCE OFFICERS COLONY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600098,
      "TXT_PINCODE_LOCALITY": "AMBATTUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6320,
      "City District Name": "TIRUVALLUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600099,
      "TXT_PINCODE_LOCALITY": "KOLATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600100,
      "TXT_PINCODE_LOCALITY": "TIRUSULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600101,
      "TXT_PINCODE_LOCALITY": "ANNA NAGAR WEST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600102,
      "TXT_PINCODE_LOCALITY": "ANNA NAGAR EAST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600103,
      "TXT_PINCODE_LOCALITY": "VELLUVOYAL CHAVADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6320,
      "City District Name": "TIRUVALLUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600104,
      "TXT_PINCODE_LOCALITY": "CHENNAI HIGH COURT",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600105,
      "TXT_PINCODE_LOCALITY": "C-IN-C ROAD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600106,
      "TXT_PINCODE_LOCALITY": "ARUMBAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600107,
      "TXT_PINCODE_LOCALITY": "KOYAMBEDU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600108,
      "TXT_PINCODE_LOCALITY": "BROAD WAY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600109,
      "TXT_PINCODE_LOCALITY": "RLWY CAR SHED COMPLEX AVADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600110,
      "TXT_PINCODE_LOCALITY": "PONNIAMMANMEDU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600111,
      "TXT_PINCODE_LOCALITY": "SRI AYYAPPANAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600112,
      "TXT_PINCODE_LOCALITY": "CHOOLAI - 2594",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600113,
      "TXT_PINCODE_LOCALITY": "ROYAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600114,
      "TXT_PINCODE_LOCALITY": "PAZHAVANTHANGAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600115,
      "TXT_PINCODE_LOCALITY": "INJAMBAKKAM S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 441239,
      "City District Name": "KANCHIPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600116,
      "TXT_PINCODE_LOCALITY": "MANPAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600117,
      "TXT_PINCODE_LOCALITY": "OLD PALLAVARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600118,
      "TXT_PINCODE_LOCALITY": "KODUNGAIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600119,
      "TXT_PINCODE_LOCALITY": "SHOLINGANALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600120,
      "TXT_PINCODE_LOCALITY": "CHENNAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600121,
      "TXT_PINCODE_LOCALITY": "KOSAPPUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600122,
      "TXT_PINCODE_LOCALITY": "PARANIPUTHUR S. O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600123,
      "TXT_PINCODE_LOCALITY": "NAZARETHPETTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6320,
      "City District Name": "TIRUVALLUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600124,
      "TXT_PINCODE_LOCALITY": "TIRUVALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6320,
      "City District Name": "TIRUVALLUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600125,
      "TXT_PINCODE_LOCALITY": "MUGALIVAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600126,
      "TXT_PINCODE_LOCALITY": "MADANANDAPURAM.",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600127,
      "TXT_PINCODE_LOCALITY": "MELAKKOTTAIYUR S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 441239,
      "City District Name": "KANCHIPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600128,
      "TXT_PINCODE_LOCALITY": "THANDALAM B.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 441239,
      "City District Name": "KANCHIPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600129,
      "TXT_PINCODE_LOCALITY": "KOVILAMBAKKAM S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 441239,
      "City District Name": "KANCHIPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600130,
      "TXT_PINCODE_LOCALITY": "THAZAMBUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600131,
      "TXT_PINCODE_LOCALITY": "TAMBARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 441239,
      "City District Name": "KANCHIPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600132,
      "TXT_PINCODE_LOCALITY": "KANCHIPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 441239,
      "City District Name": "KANCHIPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600201,
      "TXT_PINCODE_LOCALITY": "PRINCIPAL CHIEF POSTMASTER GENERAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600202,
      "TXT_PINCODE_LOCALITY": "CHIEF GENERAL MANAGER BSNL CHENNAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600211,
      "TXT_PINCODE_LOCALITY": "I.G. OF POLICE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600301,
      "TXT_PINCODE_LOCALITY": "PAMBAYAMBATTU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4295,
      "City District Name": "MADURANTHAKAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600302,
      "TXT_PINCODE_LOCALITY": "VALLAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600403,
      "TXT_PINCODE_LOCALITY": "VELUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5164,
      "City District Name": "PONNERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600601,
      "TXT_PINCODE_LOCALITY": "THORAVELLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6220,
      "City District Name": "VIRUDHACHALAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 600773,
      "TXT_PINCODE_LOCALITY": "MADAMBAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 601044,
      "TXT_PINCODE_LOCALITY": "COIMBATORE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 601101,
      "TXT_PINCODE_LOCALITY": "ARNI CPT",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 601102,
      "TXT_PINCODE_LOCALITY": "ARANI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6320,
      "City District Name": "TIRUVALLUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 601103,
      "TXT_PINCODE_LOCALITY": "VENGAL C P T",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 601104,
      "TXT_PINCODE_LOCALITY": "KANNIGAIPAIR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 601105,
      "TXT_PINCODE_LOCALITY": "VADAMADURAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6320,
      "City District Name": "TIRUVALLUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 601201,
      "TXT_PINCODE_LOCALITY": "CHENNAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 601202,
      "TXT_PINCODE_LOCALITY": "MADARPAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 601203,
      "TXT_PINCODE_LOCALITY": "THIRUVELLAVOYAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6320,
      "City District Name": "TIRUVALLUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 601204,
      "TXT_PINCODE_LOCALITY": "PONNERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 601205,
      "TXT_PINCODE_LOCALITY": "PULICAT",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 601206,
      "TXT_PINCODE_LOCALITY": "KAVARAPETTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 601207,
      "TXT_PINCODE_LOCALITY": "ARAMBAKKAM R S",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 601208,
      "TXT_PINCODE_LOCALITY": "TIRUPALAIVANAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 601209,
      "TXT_PINCODE_LOCALITY": "TIRUVELLAVOYAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6320,
      "City District Name": "TIRUVALLUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 601210,
      "TXT_PINCODE_LOCALITY": "PERUMBEDU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 601211,
      "TXT_PINCODE_LOCALITY": "ELAVUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 601212,
      "TXT_PINCODE_LOCALITY": "IGUVARPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 601213,
      "TXT_PINCODE_LOCALITY": "JOTHINAGAR (CPT)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 601214,
      "TXT_PINCODE_LOCALITY": "DEVAMPATTU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 601295,
      "TXT_PINCODE_LOCALITY": "PULICAT",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 601301,
      "TXT_PINCODE_LOCALITY": "MARAIMALAI NAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 601302,
      "TXT_PINCODE_LOCALITY": "GOWRIVAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 601303,
      "TXT_PINCODE_LOCALITY": "OLD PALLAVARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 601304,
      "TXT_PINCODE_LOCALITY": "MANIMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 602001,
      "TXT_PINCODE_LOCALITY": "TIRUVALLUR H O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6320,
      "City District Name": "TIRUVALLUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 602002,
      "TXT_PINCODE_LOCALITY": "MANAVALANAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6320,
      "City District Name": "TIRUVALLUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 602003,
      "TXT_PINCODE_LOCALITY": "KAKALUR IND ESTATE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 602004,
      "TXT_PINCODE_LOCALITY": "MELNALLATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 602007,
      "TXT_PINCODE_LOCALITY": "SRIPERUMBUDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5762,
      "City District Name": "SRIPERUMBUDUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 602015,
      "TXT_PINCODE_LOCALITY": "MEVALUEKUPPAM P.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 602021,
      "TXT_PINCODE_LOCALITY": "IKKADU S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5933,
      "City District Name": "THIRUVALLUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 602022,
      "TXT_PINCODE_LOCALITY": "PENNALURPET",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 602023,
      "TXT_PINCODE_LOCALITY": "POONDI TIRUVALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 602024,
      "TXT_PINCODE_LOCALITY": "TIRUNINRAVUR R S",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 602025,
      "TXT_PINCODE_LOCALITY": "TIRUR CPT",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 602026,
      "TXT_PINCODE_LOCALITY": "UTTUKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 602027,
      "TXT_PINCODE_LOCALITY": "MANJAKARANAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5933,
      "City District Name": "THIRUVALLUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 602028,
      "TXT_PINCODE_LOCALITY": "PALAVAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 602029,
      "TXT_PINCODE_LOCALITY": "SEETHANJERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 602030,
      "TXT_PINCODE_LOCALITY": "POLIVAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 602031,
      "TXT_PINCODE_LOCALITY": "KALAMBAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 602055,
      "TXT_PINCODE_LOCALITY": "VANLANCHOTTRAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5933,
      "City District Name": "THIRUVALLUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 602062,
      "TXT_PINCODE_LOCALITY": "THIRUVALLUR S. O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5933,
      "City District Name": "THIRUVALLUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 602101,
      "TXT_PINCODE_LOCALITY": "MOONDRAMKATTALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 602102,
      "TXT_PINCODE_LOCALITY": "MADURAVOYAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 602103,
      "TXT_PINCODE_LOCALITY": "NAZARETHPETTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 602104,
      "TXT_PINCODE_LOCALITY": "PORUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 602105,
      "TXT_PINCODE_LOCALITY": "SRIPERUMBUDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 602106,
      "TXT_PINCODE_LOCALITY": "SUNDEKUPPAN",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6320,
      "City District Name": "TIRUVALLUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 602107,
      "TXT_PINCODE_LOCALITY": "THIRUMAZHISAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5919,
      "City District Name": "THIRUMAZHISAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 602108,
      "TXT_PINCODE_LOCALITY": "PANNUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 602109,
      "TXT_PINCODE_LOCALITY": "SOMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 602111,
      "TXT_PINCODE_LOCALITY": "VAVALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 602112,
      "TXT_PINCODE_LOCALITY": "MATHUR KANCHIPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6320,
      "City District Name": "TIRUVALLUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 602113,
      "TXT_PINCODE_LOCALITY": "VALARPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 602114,
      "TXT_PINCODE_LOCALITY": "MADURAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 602115,
      "TXT_PINCODE_LOCALITY": "KANDOOR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 602116,
      "TXT_PINCODE_LOCALITY": "SENDAMANGALAM KANCHIPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 602117,
      "TXT_PINCODE_LOCALITY": "IRUNGATTUKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 441239,
      "City District Name": "KANCHIPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 602122,
      "TXT_PINCODE_LOCALITY": "MANGADU S. O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 602201,
      "TXT_PINCODE_LOCALITY": "PERIA SOLIAMBAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3325,
      "City District Name": "GUMMIDIPOONDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 602205,
      "TXT_PINCODE_LOCALITY": "VEPPAMBAATTU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5933,
      "City District Name": "THIRUVALLUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603001,
      "TXT_PINCODE_LOCALITY": "CHENGALPATTU R S",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 441239,
      "City District Name": "KANCHIPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603002,
      "TXT_PINCODE_LOCALITY": "NENMELI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 441239,
      "City District Name": "KANCHIPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603003,
      "TXT_PINCODE_LOCALITY": "VALLAM S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 441239,
      "City District Name": "KANCHIPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603004,
      "TXT_PINCODE_LOCALITY": "MAHINDRA WORLD CITY S.O (KANCHIPURAM)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 441239,
      "City District Name": "KANCHIPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603011,
      "TXT_PINCODE_LOCALITY": "PULIPAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603013,
      "TXT_PINCODE_LOCALITY": "NAVALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603101,
      "TXT_PINCODE_LOCALITY": "ATHUR CPT",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 441239,
      "City District Name": "KANCHIPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603102,
      "TXT_PINCODE_LOCALITY": "PUDUPATTINAM COLONY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6320,
      "City District Name": "TIRUVALLUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603103,
      "TXT_PINCODE_LOCALITY": "PADUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 441239,
      "City District Name": "KANCHIPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603104,
      "TXT_PINCODE_LOCALITY": "MAMALLAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603105,
      "TXT_PINCODE_LOCALITY": "MANAMBATHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603106,
      "TXT_PINCODE_LOCALITY": "ORAKKATUPETTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603107,
      "TXT_PINCODE_LOCALITY": "SALAVAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603108,
      "TXT_PINCODE_LOCALITY": "SEMBAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603109,
      "TXT_PINCODE_LOCALITY": "TIRUKALIKUNDARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603110,
      "TXT_PINCODE_LOCALITY": "TIRUPORUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603111,
      "TXT_PINCODE_LOCALITY": "VEMBAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603112,
      "TXT_PINCODE_LOCALITY": "KOVALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603113,
      "TXT_PINCODE_LOCALITY": "KUZHIPANTHANDALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603114,
      "TXT_PINCODE_LOCALITY": "NEMELI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603115,
      "TXT_PINCODE_LOCALITY": "NELVOY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603116,
      "TXT_PINCODE_LOCALITY": "BUKKATHURAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603117,
      "TXT_PINCODE_LOCALITY": "THIMMAVARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603118,
      "TXT_PINCODE_LOCALITY": "PALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603119,
      "TXT_PINCODE_LOCALITY": "CHENGALPATTU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603120,
      "TXT_PINCODE_LOCALITY": "OZHALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603121,
      "TXT_PINCODE_LOCALITY": "MEYYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603122,
      "TXT_PINCODE_LOCALITY": "ANAMBAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603123,
      "TXT_PINCODE_LOCALITY": "ZAMIN PAYYANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603124,
      "TXT_PINCODE_LOCALITY": "KADAMBADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603126,
      "TXT_PINCODE_LOCALITY": "SEETHANJERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603127,
      "TXT_PINCODE_LOCALITY": "ANUPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 441239,
      "City District Name": "KANCHIPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603201,
      "TXT_PINCODE_LOCALITY": "ELAPAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603202,
      "TXT_PINCODE_LOCALITY": "GUDUVANCHERY S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 441239,
      "City District Name": "KANCHIPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603203,
      "TXT_PINCODE_LOCALITY": "KATTANKOLATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603204,
      "TXT_PINCODE_LOCALITY": "SINGAPERUMAL KOIL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603205,
      "TXT_PINCODE_LOCALITY": "RAMAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603206,
      "TXT_PINCODE_LOCALITY": "KAYARAMBED",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603207,
      "TXT_PINCODE_LOCALITY": "ELUTCHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603208,
      "TXT_PINCODE_LOCALITY": "VADAKKUPATTU C P T",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603209,
      "TXT_PINCODE_LOCALITY": "MARAIMALAINAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603210,
      "TXT_PINCODE_LOCALITY": "URAPAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603211,
      "TXT_PINCODE_LOCALITY": "ANNAI ANJUGAM NAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603301,
      "TXT_PINCODE_LOCALITY": "ACHARAPAKKAM WEST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6320,
      "City District Name": "TIRUVALLUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603302,
      "TXT_PINCODE_LOCALITY": "CHEYUR CPT",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603303,
      "TXT_PINCODE_LOCALITY": "MADURANTAKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603304,
      "TXT_PINCODE_LOCALITY": "KUDDAPAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603305,
      "TXT_PINCODE_LOCALITY": "KUVATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 441239,
      "City District Name": "KANCHIPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603306,
      "TXT_PINCODE_LOCALITY": "SENGUNTHARPETTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6320,
      "City District Name": "TIRUVALLUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603307,
      "TXT_PINCODE_LOCALITY": "ORATHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603308,
      "TXT_PINCODE_LOCALITY": "PADALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603309,
      "TXT_PINCODE_LOCALITY": "POLAMBAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603310,
      "TXT_PINCODE_LOCALITY": "THOZHUPEDU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603311,
      "TXT_PINCODE_LOCALITY": "ZAMIN ENDATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603312,
      "TXT_PINCODE_LOCALITY": "PAVUNJUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603313,
      "TXT_PINCODE_LOCALITY": "CHITHAMUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603314,
      "TXT_PINCODE_LOCALITY": "VEDANTHANGAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603315,
      "TXT_PINCODE_LOCALITY": "KOYAPAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6320,
      "City District Name": "TIRUVALLUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603316,
      "TXT_PINCODE_LOCALITY": "MURUGAMBAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603317,
      "TXT_PINCODE_LOCALITY": "VILAMBUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603318,
      "TXT_PINCODE_LOCALITY": "SEEVADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603319,
      "TXT_PINCODE_LOCALITY": "VEERANAKUNNAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603320,
      "TXT_PINCODE_LOCALITY": "ANANTHAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603401,
      "TXT_PINCODE_LOCALITY": "CHUNAMPET",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603402,
      "TXT_PINCODE_LOCALITY": "KALIYAMPUNDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603403,
      "TXT_PINCODE_LOCALITY": "MANAMPATHY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603404,
      "TXT_PINCODE_LOCALITY": "PERUNAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603405,
      "TXT_PINCODE_LOCALITY": "PONVILIANHAKALATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603406,
      "TXT_PINCODE_LOCALITY": "UTHIRAMERUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603407,
      "TXT_PINCODE_LOCALITY": "KAMMALAMPOONDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603408,
      "TXT_PINCODE_LOCALITY": "L ENDATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 603912,
      "TXT_PINCODE_LOCALITY": "ELAYAPERUMALNALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6030,
      "City District Name": "UDAYARPALAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604001,
      "TXT_PINCODE_LOCALITY": "TINDIVANAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604002,
      "TXT_PINCODE_LOCALITY": "TINDIVANAM EAST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604101,
      "TXT_PINCODE_LOCALITY": "AKSHIPAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604102,
      "TXT_PINCODE_LOCALITY": "KILIYANUR S A",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604103,
      "TXT_PINCODE_LOCALITY": "THAILAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604104,
      "TXT_PINCODE_LOCALITY": "PINJANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6220,
      "City District Name": "VIRUDHACHALAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604150,
      "TXT_PINCODE_LOCALITY": "NALLAMPILLAIPETRAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3254,
      "City District Name": "GINGEE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604151,
      "TXT_PINCODE_LOCALITY": "ALAMPOONDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604152,
      "TXT_PINCODE_LOCALITY": "NALLANPILLAIPETRAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604153,
      "TXT_PINCODE_LOCALITY": "SATYAMANGALAM SA",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604154,
      "TXT_PINCODE_LOCALITY": "VELUR S A",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604155,
      "TXT_PINCODE_LOCALITY": "KANAKKANKUPPAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604156,
      "TXT_PINCODE_LOCALITY": "DEVADANAMPETTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604201,
      "TXT_PINCODE_LOCALITY": "AVALURPET",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604202,
      "TXT_PINCODE_LOCALITY": "BAZAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604203,
      "TXT_PINCODE_LOCALITY": "MELOLAKKUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604204,
      "TXT_PINCODE_LOCALITY": "MELMALAIYANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604205,
      "TXT_PINCODE_LOCALITY": "MINAMBUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604206,
      "TXT_PINCODE_LOCALITY": "VALLAM SA",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604207,
      "TXT_PINCODE_LOCALITY": "VALLIMEDUPETTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604208,
      "TXT_PINCODE_LOCALITY": "VALATHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604210,
      "TXT_PINCODE_LOCALITY": "ILLODU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604211,
      "TXT_PINCODE_LOCALITY": "DADAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604212,
      "TXT_PINCODE_LOCALITY": "MODAIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604213,
      "TXT_PINCODE_LOCALITY": "NAGANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604301,
      "TXT_PINCODE_LOCALITY": "BRAHMADESAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604302,
      "TXT_PINCODE_LOCALITY": "KUTTERIPATTU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604303,
      "TXT_PINCODE_LOCALITY": "MARKANAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604304,
      "TXT_PINCODE_LOCALITY": "MAILAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604305,
      "TXT_PINCODE_LOCALITY": "OLAKKUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604306,
      "TXT_PINCODE_LOCALITY": "RETTANAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604307,
      "TXT_PINCODE_LOCALITY": "SARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604308,
      "TXT_PINCODE_LOCALITY": "MUNNUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604401,
      "TXT_PINCODE_LOCALITY": "ANUKKAVUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604402,
      "TXT_PINCODE_LOCALITY": "HASANAMAPETTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604403,
      "TXT_PINCODE_LOCALITY": "CHEYYAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604404,
      "TXT_PINCODE_LOCALITY": "KILKOVALAVEDU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604405,
      "TXT_PINCODE_LOCALITY": "CHEYYAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604406,
      "TXT_PINCODE_LOCALITY": "VANDAVASI S. O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604407,
      "TXT_PINCODE_LOCALITY": "TIRUVETIPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604408,
      "TXT_PINCODE_LOCALITY": "VANDAVASI CUTCHERRY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604409,
      "TXT_PINCODE_LOCALITY": "VALIKADAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604410,
      "TXT_PINCODE_LOCALITY": "CHEYYAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604411,
      "TXT_PINCODE_LOCALITY": "PURISAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604412,
      "TXT_PINCODE_LOCALITY": "KILNARMA",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604413,
      "TXT_PINCODE_LOCALITY": "KORAKKAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604414,
      "TXT_PINCODE_LOCALITY": "PONNUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604415,
      "TXT_PINCODE_LOCALITY": "ELANKADU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604501,
      "TXT_PINCODE_LOCALITY": "DESUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604502,
      "TXT_PINCODE_LOCALITY": "MALAIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604503,
      "TXT_PINCODE_LOCALITY": "PERNAMALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604504,
      "TXT_PINCODE_LOCALITY": "TIRUMANI ARNI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604505,
      "TXT_PINCODE_LOCALITY": "VALLAM ARNI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604507,
      "TXT_PINCODE_LOCALITY": "SEEYAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6138,
      "City District Name": "VANDAVASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604601,
      "TXT_PINCODE_LOCALITY": "KILPENNATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 604708,
      "TXT_PINCODE_LOCALITY": "KOTTUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605001,
      "TXT_PINCODE_LOCALITY": "PONDICHERRY R S",
      "NUM_STATE_CD": 164,
      "State Name": "PONDICHERRY",
      "NUM_CITYDISTRICT_CD": 424444,
      "City District Name": "PONDICHERRY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605002,
      "TXT_PINCODE_LOCALITY": "SRI ARABINDO ASHRAM",
      "NUM_STATE_CD": 164,
      "State Name": "PONDICHERRY",
      "NUM_CITYDISTRICT_CD": 424444,
      "City District Name": "PONDICHERRY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605003,
      "TXT_PINCODE_LOCALITY": "MUTHIALPET",
      "NUM_STATE_CD": 164,
      "State Name": "PONDICHERRY",
      "NUM_CITYDISTRICT_CD": 424444,
      "City District Name": "PONDICHERRY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605004,
      "TXT_PINCODE_LOCALITY": "MUDALIARPET",
      "NUM_STATE_CD": 164,
      "State Name": "PONDICHERRY",
      "NUM_CITYDISTRICT_CD": 424444,
      "City District Name": "PONDICHERRY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605005,
      "TXT_PINCODE_LOCALITY": "NELLITHOPE",
      "NUM_STATE_CD": 164,
      "State Name": "PONDICHERRY",
      "NUM_CITYDISTRICT_CD": 424444,
      "City District Name": "PONDICHERRY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605006,
      "TXT_PINCODE_LOCALITY": "DHANVANTRI NAGAR",
      "NUM_STATE_CD": 164,
      "State Name": "PONDICHERRY",
      "NUM_CITYDISTRICT_CD": 424444,
      "City District Name": "PONDICHERRY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605007,
      "TXT_PINCODE_LOCALITY": "ARIYANKUPPAM",
      "NUM_STATE_CD": 164,
      "State Name": "PONDICHERRY",
      "NUM_CITYDISTRICT_CD": 424444,
      "City District Name": "PONDICHERRY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605008,
      "TXT_PINCODE_LOCALITY": "LAWSPET",
      "NUM_STATE_CD": 164,
      "State Name": "PONDICHERRY",
      "NUM_CITYDISTRICT_CD": 424444,
      "City District Name": "PONDICHERRY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605009,
      "TXT_PINCODE_LOCALITY": "VAZUDAVOOR ROAD",
      "NUM_STATE_CD": 164,
      "State Name": "PONDICHERRY",
      "NUM_CITYDISTRICT_CD": 424444,
      "City District Name": "PONDICHERRY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605010,
      "TXT_PINCODE_LOCALITY": "REDDIYARPALAYAM S.O",
      "NUM_STATE_CD": 164,
      "State Name": "PONDICHERRY",
      "NUM_CITYDISTRICT_CD": 424444,
      "City District Name": "PONDICHERRY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605011,
      "TXT_PINCODE_LOCALITY": "VENKATANAGAR",
      "NUM_STATE_CD": 164,
      "State Name": "PONDICHERRY",
      "NUM_CITYDISTRICT_CD": 424444,
      "City District Name": "PONDICHERRY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605012,
      "TXT_PINCODE_LOCALITY": "PADMININAGAR",
      "NUM_STATE_CD": 164,
      "State Name": "PONDICHERRY",
      "NUM_CITYDISTRICT_CD": 424444,
      "City District Name": "PONDICHERRY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605013,
      "TXT_PINCODE_LOCALITY": "SARAM PONDY",
      "NUM_STATE_CD": 164,
      "State Name": "PONDICHERRY",
      "NUM_CITYDISTRICT_CD": 424444,
      "City District Name": "PONDICHERRY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605014,
      "TXT_PINCODE_LOCALITY": "METTU STREET",
      "NUM_STATE_CD": 164,
      "State Name": "PONDICHERRY",
      "NUM_CITYDISTRICT_CD": 424444,
      "City District Name": "PONDICHERRY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605051,
      "TXT_PINCODE_LOCALITY": "OLUGARAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605101,
      "TXT_PINCODE_LOCALITY": "AROVILLE",
      "NUM_STATE_CD": 164,
      "State Name": "PONDICHERRY",
      "NUM_CITYDISTRICT_CD": 424444,
      "City District Name": "PONDICHERRY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605102,
      "TXT_PINCODE_LOCALITY": "THIRUVANDAR KOIL POST",
      "NUM_STATE_CD": 164,
      "State Name": "PONDICHERRY",
      "NUM_CITYDISTRICT_CD": 424444,
      "City District Name": "PONDICHERRY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605103,
      "TXT_PINCODE_LOCALITY": "KOLIYANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605104,
      "TXT_PINCODE_LOCALITY": "VANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605105,
      "TXT_PINCODE_LOCALITY": "MOKSHAKULAM",
      "NUM_STATE_CD": 164,
      "State Name": "PONDICHERRY",
      "NUM_CITYDISTRICT_CD": 424444,
      "City District Name": "PONDICHERRY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605106,
      "TXT_PINCODE_LOCALITY": "NETTAPAKKAM",
      "NUM_STATE_CD": 164,
      "State Name": "PONDICHERRY",
      "NUM_CITYDISTRICT_CD": 424444,
      "City District Name": "PONDICHERRY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605107,
      "TXT_PINCODE_LOCALITY": "PALLINELIYANUR S.O",
      "NUM_STATE_CD": 164,
      "State Name": "PONDICHERRY",
      "NUM_CITYDISTRICT_CD": 424444,
      "City District Name": "PONDICHERRY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605108,
      "TXT_PINCODE_LOCALITY": "VALAVONUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605109,
      "TXT_PINCODE_LOCALITY": "VANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605110,
      "TXT_PINCODE_LOCALITY": "VILLYANPUR",
      "NUM_STATE_CD": 164,
      "State Name": "PONDICHERRY",
      "NUM_CITYDISTRICT_CD": 424444,
      "City District Name": "PONDICHERRY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605111,
      "TXT_PINCODE_LOCALITY": "SEDARAPET",
      "NUM_STATE_CD": 164,
      "State Name": "PONDICHERRY",
      "NUM_CITYDISTRICT_CD": 424444,
      "City District Name": "PONDICHERRY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605112,
      "TXT_PINCODE_LOCALITY": "NAVAMALKAPPER.",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 442979,
      "City District Name": "VILUPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605113,
      "TXT_PINCODE_LOCALITY": "KARIYAMANICKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605114,
      "TXT_PINCODE_LOCALITY": "BOMMAYAPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605115,
      "TXT_PINCODE_LOCALITY": "VENKATADRI AGARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605117,
      "TXT_PINCODE_LOCALITY": "PAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605118,
      "TXT_PINCODE_LOCALITY": "V MATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605119,
      "TXT_PINCODE_LOCALITY": "POOTHURAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605120,
      "TXT_PINCODE_LOCALITY": "MADUCORE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605121,
      "TXT_PINCODE_LOCALITY": "NALLATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605201,
      "TXT_PINCODE_LOCALITY": "ANANTAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605202,
      "TXT_PINCODE_LOCALITY": "ANNIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605203,
      "TXT_PINCODE_LOCALITY": "KANJANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605301,
      "TXT_PINCODE_LOCALITY": "KANAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605302,
      "TXT_PINCODE_LOCALITY": "MAMBALAPATTU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605303,
      "TXT_PINCODE_LOCALITY": "KALPATTU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605304,
      "TXT_PINCODE_LOCALITY": "KAPPUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605401,
      "TXT_PINCODE_LOCALITY": "KANDAMANADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605402,
      "TXT_PINCODE_LOCALITY": "KEDAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605403,
      "TXT_PINCODE_LOCALITY": "ADANUR (S A)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605501,
      "TXT_PINCODE_LOCALITY": "TIRUKKANUR",
      "NUM_STATE_CD": 164,
      "State Name": "PONDICHERRY",
      "NUM_CITYDISTRICT_CD": 424444,
      "City District Name": "PONDICHERRY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605502,
      "TXT_PINCODE_LOCALITY": "VALUDAVUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605503,
      "TXT_PINCODE_LOCALITY": "KATTERIKUPPAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605504,
      "TXT_PINCODE_LOCALITY": "THONDAMANATHAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605506,
      "TXT_PINCODE_LOCALITY": "SUTHEKENU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605601,
      "TXT_PINCODE_LOCALITY": "MUNDIYAMBAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605602,
      "TXT_PINCODE_LOCALITY": "VILLUPURAM WEST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605603,
      "TXT_PINCODE_LOCALITY": "PANAYAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605604,
      "TXT_PINCODE_LOCALITY": "VILLUPURAM SOUTH",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605605,
      "TXT_PINCODE_LOCALITY": "SOUTH ARCOT",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605606,
      "TXT_PINCODE_LOCALITY": "VILLUPURAM JN",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605651,
      "TXT_PINCODE_LOCALITY": "PERIA THATCHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605652,
      "TXT_PINCODE_LOCALITY": "VIKRAVANDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605653,
      "TXT_PINCODE_LOCALITY": "VIDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605654,
      "TXT_PINCODE_LOCALITY": "T PUDUPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605655,
      "TXT_PINCODE_LOCALITY": "POMBUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605701,
      "TXT_PINCODE_LOCALITY": "KANDACHIPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605702,
      "TXT_PINCODE_LOCALITY": "MOONGILTHURAIPATTU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605710,
      "TXT_PINCODE_LOCALITY": "ALAPUTHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2737,
      "City District Name": "CHENGAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605751,
      "TXT_PINCODE_LOCALITY": "ARIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605752,
      "TXT_PINCODE_LOCALITY": "ARAKANDANALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605753,
      "TXT_PINCODE_LOCALITY": "KILAYUR S A",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605754,
      "TXT_PINCODE_LOCALITY": "MANALURPET",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605755,
      "TXT_PINCODE_LOCALITY": "MEGAIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605756,
      "TXT_PINCODE_LOCALITY": "THAPOVANAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605757,
      "TXT_PINCODE_LOCALITY": "TIRUKKOYILUR BUS STAND",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605758,
      "TXT_PINCODE_LOCALITY": "VIRAPANDI S A",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605759,
      "TXT_PINCODE_LOCALITY": "MANAMPOONDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605760,
      "TXT_PINCODE_LOCALITY": "VILANDAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605761,
      "TXT_PINCODE_LOCALITY": "T ATHIPAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605762,
      "TXT_PINCODE_LOCALITY": "SENNAKUNAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605765,
      "TXT_PINCODE_LOCALITY": "ARCADU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605766,
      "TXT_PINCODE_LOCALITY": "THAGADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605801,
      "TXT_PINCODE_LOCALITY": "ARIYALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605802,
      "TXT_PINCODE_LOCALITY": "KADAMBUR S A",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605803,
      "TXT_PINCODE_LOCALITY": "SITHALINGAMADAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605804,
      "TXT_PINCODE_LOCALITY": "SIRPANANDAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605805,
      "TXT_PINCODE_LOCALITY": "TIRUVARANGAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605806,
      "TXT_PINCODE_LOCALITY": "ENDAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605807,
      "TXT_PINCODE_LOCALITY": "EDUTHANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605808,
      "TXT_PINCODE_LOCALITY": "C MEYYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 605809,
      "TXT_PINCODE_LOCALITY": "VRIDHACHALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606001,
      "TXT_PINCODE_LOCALITY": "VRIDDHACHALAM H O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606002,
      "TXT_PINCODE_LOCALITY": "VRIDHACHALAM R S",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606003,
      "TXT_PINCODE_LOCALITY": "BUDAMUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606004,
      "TXT_PINCODE_LOCALITY": "CUDDALORE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1903,
      "City District Name": "CHIDAMBARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606007,
      "TXT_PINCODE_LOCALITY": "KO.ADHANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6220,
      "City District Name": "VIRUDHACHALAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606093,
      "TXT_PINCODE_LOCALITY": "TIRUVATTINANDAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5158,
      "City District Name": "POLUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606102,
      "TXT_PINCODE_LOCALITY": "KALAMARUDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606103,
      "TXT_PINCODE_LOCALITY": "KAMMAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606104,
      "TXT_PINCODE_LOCALITY": "MANGALAM S A",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606105,
      "TXT_PINCODE_LOCALITY": "PENNADAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606106,
      "TXT_PINCODE_LOCALITY": "TITTAGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606107,
      "TXT_PINCODE_LOCALITY": "ULUNDERPET BUS STAND",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606108,
      "TXT_PINCODE_LOCALITY": "MANGALUR S A",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606109,
      "TXT_PINCODE_LOCALITY": "RETTAKURICHI B.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606110,
      "TXT_PINCODE_LOCALITY": "KARUVEPPILANKURICHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606111,
      "TXT_PINCODE_LOCALITY": "PANNADAM R S",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606112,
      "TXT_PINCODE_LOCALITY": "AVINANGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606113,
      "TXT_PINCODE_LOCALITY": "GUDALUR S A",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606114,
      "TXT_PINCODE_LOCALITY": "MATHUR VDC",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606115,
      "TXT_PINCODE_LOCALITY": "PADUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606116,
      "TXT_PINCODE_LOCALITY": "SEMMANANGUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606117,
      "TXT_PINCODE_LOCALITY": "SENKURICHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606118,
      "TXT_PINCODE_LOCALITY": "SATHANATHAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606119,
      "TXT_PINCODE_LOCALITY": "PARIKKAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606120,
      "TXT_PINCODE_LOCALITY": "MUKHASAPARUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606121,
      "TXT_PINCODE_LOCALITY": "ALADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606122,
      "TXT_PINCODE_LOCALITY": "MANAVALANAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606123,
      "TXT_PINCODE_LOCALITY": "SIRUPAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606201,
      "TXT_PINCODE_LOCALITY": "CHINNASALEM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606202,
      "TXT_PINCODE_LOCALITY": "KALLAKURICHI BAZAAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606203,
      "TXT_PINCODE_LOCALITY": "NAGALUR S A",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606204,
      "TXT_PINCODE_LOCALITY": "PUKKIRAVARI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606205,
      "TXT_PINCODE_LOCALITY": "RISHIVANDIYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606206,
      "TXT_PINCODE_LOCALITY": "TIYAGADRUG",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606207,
      "TXT_PINCODE_LOCALITY": "VADAKKANENDAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606208,
      "TXT_PINCODE_LOCALITY": "MURARBAD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606209,
      "TXT_PINCODE_LOCALITY": "K ALAMBALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606210,
      "TXT_PINCODE_LOCALITY": "SOMANDARGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606211,
      "TXT_PINCODE_LOCALITY": "ALATHUR S A",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606212,
      "TXT_PINCODE_LOCALITY": "MATHUR VDC",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606213,
      "TXT_PINCODE_LOCALITY": "VILAMBAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606214,
      "TXT_PINCODE_LOCALITY": "THOTTIAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606215,
      "TXT_PINCODE_LOCALITY": "MELNARAIYAPPANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606216,
      "TXT_PINCODE_LOCALITY": "VIRACHOLAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606217,
      "TXT_PINCODE_LOCALITY": "KUDIRAICHANDAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606218,
      "TXT_PINCODE_LOCALITY": "OLAGANKATHAN",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606219,
      "TXT_PINCODE_LOCALITY": "VARANJARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606220,
      "TXT_PINCODE_LOCALITY": "VIRUGAVUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606221,
      "TXT_PINCODE_LOCALITY": "CHOLAMPATTU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606222,
      "TXT_PINCODE_LOCALITY": "MUDIYANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606223,
      "TXT_PINCODE_LOCALITY": "VILAMBUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606263,
      "TXT_PINCODE_LOCALITY": "AIVADUGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6220,
      "City District Name": "VIRUDHACHALAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606301,
      "TXT_PINCODE_LOCALITY": "NAINARPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606302,
      "TXT_PINCODE_LOCALITY": "NALLUR S A",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606303,
      "TXT_PINCODE_LOCALITY": "TOLUDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606304,
      "TXT_PINCODE_LOCALITY": "VEPPUR S A",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606305,
      "TXT_PINCODE_LOCALITY": "ASANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606306,
      "TXT_PINCODE_LOCALITY": "KUGAIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606307,
      "TXT_PINCODE_LOCALITY": "KOOTHAKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606401,
      "TXT_PINCODE_LOCALITY": "SANKARAPURAM (SA)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606402,
      "TXT_PINCODE_LOCALITY": "PANDALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606403,
      "TXT_PINCODE_LOCALITY": "ARASAMPATTU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606404,
      "TXT_PINCODE_LOCALITY": "PUDUPALAPATTU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606405,
      "TXT_PINCODE_LOCALITY": "MOTTAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606601,
      "TXT_PINCODE_LOCALITY": "TIRUVANNAMALAI BAZAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606602,
      "TXT_PINCODE_LOCALITY": "TIRUVANNAMALAI R S",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606603,
      "TXT_PINCODE_LOCALITY": "SRI RAMANASHRAMAN",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606604,
      "TXT_PINCODE_LOCALITY": "VENGIKAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606608,
      "TXT_PINCODE_LOCALITY": "PANAIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606611,
      "TXT_PINCODE_LOCALITY": "SOMASOPADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606701,
      "TXT_PINCODE_LOCALITY": "CHENGAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606702,
      "TXT_PINCODE_LOCALITY": "KANJI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606703,
      "TXT_PINCODE_LOCALITY": "MELPALLIPATTU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606704,
      "TXT_PINCODE_LOCALITY": "PACHAL (NA)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606705,
      "TXT_PINCODE_LOCALITY": "PUDUPALAYAM NA",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606706,
      "TXT_PINCODE_LOCALITY": "TIRUVANNAMALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606707,
      "TXT_PINCODE_LOCALITY": "TANDARAMPATTU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606708,
      "TXT_PINCODE_LOCALITY": "TANIPADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606709,
      "TXT_PINCODE_LOCALITY": "THUKKAPETTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606710,
      "TXT_PINCODE_LOCALITY": "PARAMANDAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606711,
      "TXT_PINCODE_LOCALITY": "MELCHENGAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606712,
      "TXT_PINCODE_LOCALITY": "KILSIRUPAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606713,
      "TXT_PINCODE_LOCALITY": "MELVANAKKAMBADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606714,
      "TXT_PINCODE_LOCALITY": "THENMUDIYANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606715,
      "TXT_PINCODE_LOCALITY": "S I SORPANANDAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606716,
      "TXT_PINCODE_LOCALITY": "TARADAPATTU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606717,
      "TXT_PINCODE_LOCALITY": "KILVANAKKAMBADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606718,
      "TXT_PINCODE_LOCALITY": "SATHANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606751,
      "TXT_PINCODE_LOCALITY": "TIRUVANNAMALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606752,
      "TXT_PINCODE_LOCALITY": "TIRUVANNAMALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606753,
      "TXT_PINCODE_LOCALITY": "VANAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606754,
      "TXT_PINCODE_LOCALITY": "VETTAVALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606755,
      "TXT_PINCODE_LOCALITY": "AVUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606756,
      "TXT_PINCODE_LOCALITY": "TIRUVANNAMALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606801,
      "TXT_PINCODE_LOCALITY": "CHETTUPATTU POLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606802,
      "TXT_PINCODE_LOCALITY": "NAYUDUMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606803,
      "TXT_PINCODE_LOCALITY": "POLUR TOWN",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606804,
      "TXT_PINCODE_LOCALITY": "TANDARAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606805,
      "TXT_PINCODE_LOCALITY": "TURINJAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606806,
      "TXT_PINCODE_LOCALITY": "PAVITRAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606807,
      "TXT_PINCODE_LOCALITY": "NEDUNGUNAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606808,
      "TXT_PINCODE_LOCALITY": "KATTAMPOONDI S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606809,
      "TXT_PINCODE_LOCALITY": "NARTHAMPOONDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606810,
      "TXT_PINCODE_LOCALITY": "ANDAMPALLAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606811,
      "TXT_PINCODE_LOCALITY": "THACHAMPATTU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606854,
      "TXT_PINCODE_LOCALITY": "VIRUVILANGINAN",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606901,
      "TXT_PINCODE_LOCALITY": "KALASAPAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606902,
      "TXT_PINCODE_LOCALITY": "KALASAPAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606903,
      "TXT_PINCODE_LOCALITY": "KALAMBUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606904,
      "TXT_PINCODE_LOCALITY": "MANDAKOLATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606905,
      "TXT_PINCODE_LOCALITY": "SANTHAVASAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606906,
      "TXT_PINCODE_LOCALITY": "VILVARANI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606907,
      "TXT_PINCODE_LOCALITY": "VADAMATHIMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606908,
      "TXT_PINCODE_LOCALITY": "KADALADI N A",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606909,
      "TXT_PINCODE_LOCALITY": "KASTHAMPADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606910,
      "TXT_PINCODE_LOCALITY": "KETTAVARAMPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606911,
      "TXT_PINCODE_LOCALITY": "GANGAVARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606912,
      "TXT_PINCODE_LOCALITY": "MANSURABAD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606913,
      "TXT_PINCODE_LOCALITY": "A K PADAVEDU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606914,
      "TXT_PINCODE_LOCALITY": "VELLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 606915,
      "TXT_PINCODE_LOCALITY": "SIRUVALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607001,
      "TXT_PINCODE_LOCALITY": "MANJAKUPPAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607002,
      "TXT_PINCODE_LOCALITY": "TIRUPPADIRAIPULIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607003,
      "TXT_PINCODE_LOCALITY": "SEEMANTHOTTAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607004,
      "TXT_PINCODE_LOCALITY": "VANDIPALIYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607005,
      "TXT_PINCODE_LOCALITY": "CAPPERQUARRY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607006,
      "TXT_PINCODE_LOCALITY": "KONDUR S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607051,
      "TXT_PINCODE_LOCALITY": "PACHAYANKUPPAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607052,
      "TXT_PINCODE_LOCALITY": "ANNAVALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607101,
      "TXT_PINCODE_LOCALITY": "ANATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607102,
      "TXT_PINCODE_LOCALITY": "CHINNAPPANAICKENPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607103,
      "TXT_PINCODE_LOCALITY": "KADAMBULIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607104,
      "TXT_PINCODE_LOCALITY": "MELPATTAMBAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607105,
      "TXT_PINCODE_LOCALITY": "NELLIKUPPAM BAZAAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607106,
      "TXT_PINCODE_LOCALITY": "PANRUTI WEST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607107,
      "TXT_PINCODE_LOCALITY": "PERANGIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607108,
      "TXT_PINCODE_LOCALITY": "PUTHUPET (SA)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607109,
      "TXT_PINCODE_LOCALITY": "VARKALPATTU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607110,
      "TXT_PINCODE_LOCALITY": "ANGUCHETTIPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607111,
      "TXT_PINCODE_LOCALITY": "ELAIYAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607112,
      "TXT_PINCODE_LOCALITY": "KILAKAVARAPATTU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607113,
      "TXT_PINCODE_LOCALITY": "PALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607114,
      "TXT_PINCODE_LOCALITY": "MARUNGUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607115,
      "TXT_PINCODE_LOCALITY": "NATHAM S A",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607116,
      "TXT_PINCODE_LOCALITY": "ORAIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607117,
      "TXT_PINCODE_LOCALITY": "KARAYAMPUTHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607118,
      "TXT_PINCODE_LOCALITY": "KILIRIPU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607201,
      "TXT_PINCODE_LOCALITY": "ERAIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607202,
      "TXT_PINCODE_LOCALITY": "ELAVANASUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607203,
      "TXT_PINCODE_LOCALITY": "TIRUVENNAINALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607204,
      "TXT_PINCODE_LOCALITY": "TIRUNALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607205,
      "TXT_PINCODE_LOCALITY": "TIRUTHURAIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607206,
      "TXT_PINCODE_LOCALITY": "KANDRAKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607207,
      "TXT_PINCODE_LOCALITY": "MADAPATTU",
      "NUM_STATE_CD": 164,
      "State Name": "PONDICHERRY",
      "NUM_CITYDISTRICT_CD": 424444,
      "City District Name": "PONDICHERRY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607208,
      "TXT_PINCODE_LOCALITY": "SENDAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607209,
      "TXT_PINCODE_LOCALITY": "PAVANDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607301,
      "TXT_PINCODE_LOCALITY": "KULLANCHAVADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607302,
      "TXT_PINCODE_LOCALITY": "KURINJIPADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607303,
      "TXT_PINCODE_LOCALITY": "VADALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607304,
      "TXT_PINCODE_LOCALITY": "KARUNGULI S A",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607305,
      "TXT_PINCODE_LOCALITY": "THONDAMANATHAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607306,
      "TXT_PINCODE_LOCALITY": "KUNIAMALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607307,
      "TXT_PINCODE_LOCALITY": "VAHAKOLLAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607308,
      "TXT_PINCODE_LOCALITY": "GANDHI NAGAR S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607401,
      "TXT_PINCODE_LOCALITY": "TIRUVENDIPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607402,
      "TXT_PINCODE_LOCALITY": "MANAPET",
      "NUM_STATE_CD": 164,
      "State Name": "PONDICHERRY",
      "NUM_CITYDISTRICT_CD": 424444,
      "City District Name": "PONDICHERRY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607403,
      "TXT_PINCODE_LOCALITY": "REDDICHAVADI B.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607404,
      "TXT_PINCODE_LOCALITY": "KIRUMAMBAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607801,
      "TXT_PINCODE_LOCALITY": "NEYVELI BLOCK 24",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607802,
      "TXT_PINCODE_LOCALITY": "NEYVELI R S",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607803,
      "TXT_PINCODE_LOCALITY": "NEYVELI THERMAL BUS STAND",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607804,
      "TXT_PINCODE_LOCALITY": "AZIZ NAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607805,
      "TXT_PINCODE_LOCALITY": "PERPERIYANKUPPAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607806,
      "TXT_PINCODE_LOCALITY": "AMMERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 607807,
      "TXT_PINCODE_LOCALITY": "NEYVELI BLOCK 29",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 608001,
      "TXT_PINCODE_LOCALITY": "CUTCHERRY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 608002,
      "TXT_PINCODE_LOCALITY": "ANNAMALAI UNIVERSITY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 608102,
      "TXT_PINCODE_LOCALITY": "KILLAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 608103,
      "TXT_PINCODE_LOCALITY": "PINNATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 608104,
      "TXT_PINCODE_LOCALITY": "GAVARAPATTU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 608201,
      "TXT_PINCODE_LOCALITY": "ORATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 608301,
      "TXT_PINCODE_LOCALITY": "KATTUMANNARKOIL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 608302,
      "TXT_PINCODE_LOCALITY": "KOMARATCHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 608303,
      "TXT_PINCODE_LOCALITY": "LALPET",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 608304,
      "TXT_PINCODE_LOCALITY": "REDDIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 608305,
      "TXT_PINCODE_LOCALITY": "T NEDUNJERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 608306,
      "TXT_PINCODE_LOCALITY": "AYANGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 608401,
      "TXT_PINCODE_LOCALITY": "VALLAMPADUGAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 608408,
      "TXT_PINCODE_LOCALITY": "VAIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1903,
      "City District Name": "CHIDAMBARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 608501,
      "TXT_PINCODE_LOCALITY": "B MUTLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 608502,
      "TXT_PINCODE_LOCALITY": "SMALL BAZAAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 608503,
      "TXT_PINCODE_LOCALITY": "KOTHATTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 608504,
      "TXT_PINCODE_LOCALITY": "VILLAYANALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 608601,
      "TXT_PINCODE_LOCALITY": "BHUVANAGIRI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 608602,
      "TXT_PINCODE_LOCALITY": "KEERAPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 608603,
      "TXT_PINCODE_LOCALITY": "KRISHNAPURAM S A",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 608701,
      "TXT_PINCODE_LOCALITY": "PALAYAMKOTTAI S A",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 608702,
      "TXT_PINCODE_LOCALITY": "SETTIATOPE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 608703,
      "TXT_PINCODE_LOCALITY": "SRIMUSHNAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 608704,
      "TXT_PINCODE_LOCALITY": "PINNALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 608705,
      "TXT_PINCODE_LOCALITY": "VADATHALAIKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 608801,
      "TXT_PINCODE_LOCALITY": "ALAPAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 608802,
      "TXT_PINCODE_LOCALITY": "ANDARMULLIPALLAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 608803,
      "TXT_PINCODE_LOCALITY": "THAIGAVALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 608804,
      "TXT_PINCODE_LOCALITY": "KUDIAKADU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 608901,
      "TXT_PINCODE_LOCALITY": "KAVARAPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609001,
      "TXT_PINCODE_LOCALITY": "MAYILADUTHURAI CUTCHERRY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609002,
      "TXT_PINCODE_LOCALITY": "KORANAD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609003,
      "TXT_PINCODE_LOCALITY": "MAYILADUTHURAI R S",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609101,
      "TXT_PINCODE_LOCALITY": "PUDUPATTINAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609102,
      "TXT_PINCODE_LOCALITY": "KILIYANUR THANJAVUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609103,
      "TXT_PINCODE_LOCALITY": "ANANDATANDAVAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609104,
      "TXT_PINCODE_LOCALITY": "KADAVASAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609105,
      "TXT_PINCODE_LOCALITY": "KAVERIPATTINAM THANJAVUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609106,
      "TXT_PINCODE_LOCALITY": "MANGAMADAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609107,
      "TXT_PINCODE_LOCALITY": "MELAIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609108,
      "TXT_PINCODE_LOCALITY": "PUTHUR (SRK) S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609109,
      "TXT_PINCODE_LOCALITY": "SATTANATHAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609110,
      "TXT_PINCODE_LOCALITY": "SIRKALI H O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609111,
      "TXT_PINCODE_LOCALITY": "THENPATHI (S)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609112,
      "TXT_PINCODE_LOCALITY": "TIRUPANGUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609113,
      "TXT_PINCODE_LOCALITY": "TIRUMULLAVASAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609114,
      "TXT_PINCODE_LOCALITY": "TIRUVENGADU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609115,
      "TXT_PINCODE_LOCALITY": "VARSAIPATTU VADAGAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609116,
      "TXT_PINCODE_LOCALITY": "VALLUVAKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609117,
      "TXT_PINCODE_LOCALITY": "VAITHEESWARAN KOIL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609118,
      "TXT_PINCODE_LOCALITY": "SOLASAKKARANALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609119,
      "TXT_PINCODE_LOCALITY": "KUNNAM MAYILADUTWAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609120,
      "TXT_PINCODE_LOCALITY": "PAGASALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609121,
      "TXT_PINCODE_LOCALITY": "MADANAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609122,
      "TXT_PINCODE_LOCALITY": "TIRUNAGARI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609123,
      "TXT_PINCODE_LOCALITY": "VANAGIRI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609124,
      "TXT_PINCODE_LOCALITY": "KARAIMEDU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609125,
      "TXT_PINCODE_LOCALITY": "ANNAPERUMAL KOIL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609126,
      "TXT_PINCODE_LOCALITY": "VETTANGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609127,
      "TXT_PINCODE_LOCALITY": "MADERVELUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609128,
      "TXT_PINCODE_LOCALITY": "ARAPALLAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609129,
      "TXT_PINCODE_LOCALITY": "ALAKUDI (MAYURAM)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609130,
      "TXT_PINCODE_LOCALITY": "PALAYAPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609131,
      "TXT_PINCODE_LOCALITY": "EDAMANAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609201,
      "TXT_PINCODE_LOCALITY": "ILANTHOPE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609202,
      "TXT_PINCODE_LOCALITY": "MANALMEDU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609203,
      "TXT_PINCODE_LOCALITY": "NIDUR S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609204,
      "TXT_PINCODE_LOCALITY": "TIRUMENIR KOIL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609205,
      "TXT_PINCODE_LOCALITY": "TIRUVALAPUTHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609301,
      "TXT_PINCODE_LOCALITY": "AKKUR (THANJAVUR)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609302,
      "TXT_PINCODE_LOCALITY": "ARANGAKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609303,
      "TXT_PINCODE_LOCALITY": "AYAPPADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609304,
      "TXT_PINCODE_LOCALITY": "KILAIYUR MAYILADUTHUAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609305,
      "TXT_PINCODE_LOCALITY": "MANNAMPANDAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609306,
      "TXT_PINCODE_LOCALITY": "NALLADAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609307,
      "TXT_PINCODE_LOCALITY": "PORAYAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609308,
      "TXT_PINCODE_LOCALITY": "SANKARANPANDAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609309,
      "TXT_PINCODE_LOCALITY": "SEMBANARKOIL S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609310,
      "TXT_PINCODE_LOCALITY": "THILLAYADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609311,
      "TXT_PINCODE_LOCALITY": "TIRUCADAIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609312,
      "TXT_PINCODE_LOCALITY": "TIRUKKALACHERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609313,
      "TXT_PINCODE_LOCALITY": "TRANUEBAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609314,
      "TXT_PINCODE_LOCALITY": "VADAKARAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609401,
      "TXT_PINCODE_LOCALITY": "ELANTHANGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609402,
      "TXT_PINCODE_LOCALITY": "KILIYANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609403,
      "TXT_PINCODE_LOCALITY": "KOLLUMANGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609404,
      "TXT_PINCODE_LOCALITY": "MANGANALLUR BAZAAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609405,
      "TXT_PINCODE_LOCALITY": "PERALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609406,
      "TXT_PINCODE_LOCALITY": "PERAMBUR MAYILADUTHURAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609501,
      "TXT_PINCODE_LOCALITY": "ERAVANCHERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609502,
      "TXT_PINCODE_LOCALITY": "MUDIKONDAN",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609503,
      "TXT_PINCODE_LOCALITY": "PUNTHOTTAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609504,
      "TXT_PINCODE_LOCALITY": "SANNANALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609505,
      "TXT_PINCODE_LOCALITY": "TIRUVIZHIMAZHALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609506,
      "TXT_PINCODE_LOCALITY": "VISHNUPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609507,
      "TXT_PINCODE_LOCALITY": "MANANDAKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609508,
      "TXT_PINCODE_LOCALITY": "MARUDAVANCHERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609601,
      "TXT_PINCODE_LOCALITY": "AMBAGARATHUR",
      "NUM_STATE_CD": 164,
      "State Name": "PONDICHERRY",
      "NUM_CITYDISTRICT_CD": 3790,
      "City District Name": "KARAIKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609602,
      "TXT_PINCODE_LOCALITY": "KARAIKAL",
      "NUM_STATE_CD": 164,
      "State Name": "PONDICHERRY",
      "NUM_CITYDISTRICT_CD": 3790,
      "City District Name": "KARAIKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609603,
      "TXT_PINCODE_LOCALITY": "NEDUNGADU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609604,
      "TXT_PINCODE_LOCALITY": "NERAVY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609605,
      "TXT_PINCODE_LOCALITY": "THALATHERU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 441067,
      "City District Name": "KARAIKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609606,
      "TXT_PINCODE_LOCALITY": "TIRUMALRAYANPATTINAM",
      "NUM_STATE_CD": 164,
      "State Name": "PONDICHERRY",
      "NUM_CITYDISTRICT_CD": 3790,
      "City District Name": "KARAIKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609607,
      "TXT_PINCODE_LOCALITY": "TIRUNALLAR",
      "NUM_STATE_CD": 164,
      "State Name": "PONDICHERRY",
      "NUM_CITYDISTRICT_CD": 3790,
      "City District Name": "KARAIKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609608,
      "TXT_PINCODE_LOCALITY": "KOLLAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609609,
      "TXT_PINCODE_LOCALITY": "KARAIKAL",
      "NUM_STATE_CD": 164,
      "State Name": "PONDICHERRY",
      "NUM_CITYDISTRICT_CD": 424444,
      "City District Name": "PONDICHERRY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609610,
      "TXT_PINCODE_LOCALITY": "VARICHIKUDI",
      "NUM_STATE_CD": 164,
      "State Name": "PONDICHERRY",
      "NUM_CITYDISTRICT_CD": 3790,
      "City District Name": "KARAIKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609701,
      "TXT_PINCODE_LOCALITY": "ENANGUDDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609702,
      "TXT_PINCODE_LOCALITY": "TIRUMARUGAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609703,
      "TXT_PINCODE_LOCALITY": "TITTACHERRY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609704,
      "TXT_PINCODE_LOCALITY": "TIRUKANNAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609705,
      "TXT_PINCODE_LOCALITY": "SESHAMOOLAI",
      "NUM_STATE_CD": 164,
      "State Name": "PONDICHERRY",
      "NUM_CITYDISTRICT_CD": 3790,
      "City District Name": "KARAIKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609801,
      "TXT_PINCODE_LOCALITY": "KUTTALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609802,
      "TXT_PINCODE_LOCALITY": "NARASINGAMETTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609803,
      "TXT_PINCODE_LOCALITY": "TIRUVADUTHURAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609804,
      "TXT_PINCODE_LOCALITY": "TUHILI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609805,
      "TXT_PINCODE_LOCALITY": "KOMAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609806,
      "TXT_PINCODE_LOCALITY": "MALLIAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609807,
      "TXT_PINCODE_LOCALITY": "PANDANALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609808,
      "TXT_PINCODE_LOCALITY": "TEIZHANDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609809,
      "TXT_PINCODE_LOCALITY": "KAMAKSHIPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609810,
      "TXT_PINCODE_LOCALITY": "TIRUVALGADU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609811,
      "TXT_PINCODE_LOCALITY": "KALI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609812,
      "TXT_PINCODE_LOCALITY": "VANDAHIRAJAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609813,
      "TXT_PINCODE_LOCALITY": "TIRUMANCHERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609814,
      "TXT_PINCODE_LOCALITY": "KONULAMPALLAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 609916,
      "TXT_PINCODE_LOCALITY": "THALACHANKADU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5898,
      "City District Name": "THARANGAMBADI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 610001,
      "TXT_PINCODE_LOCALITY": "THIRUVARUR R S",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 610002,
      "TXT_PINCODE_LOCALITY": "DAKSHINAMURTHY MUTT",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 610003,
      "TXT_PINCODE_LOCALITY": "KADARANKONDAN",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 610004,
      "TXT_PINCODE_LOCALITY": "TIRUVARUR COLLECTORATE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 425863,
      "City District Name": "TIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 610005,
      "TXT_PINCODE_LOCALITY": "NAGAPATTINAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 610051,
      "TXT_PINCODE_LOCALITY": "ELAVANGARKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 610101,
      "TXT_PINCODE_LOCALITY": "KANGALANCHERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 610102,
      "TXT_PINCODE_LOCALITY": "KAMALAPURAM (TJ)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 610103,
      "TXT_PINCODE_LOCALITY": "MANGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 610104,
      "TXT_PINCODE_LOCALITY": "MANAKKAL AYYAMPET",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 610105,
      "TXT_PINCODE_LOCALITY": "NANNILAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 610106,
      "TXT_PINCODE_LOCALITY": "TAPPALAMPULIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 610107,
      "TXT_PINCODE_LOCALITY": "TIRUVIDACHERRY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 610109,
      "TXT_PINCODE_LOCALITY": "PULIVALAM THANJAVUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 610110,
      "TXT_PINCODE_LOCALITY": "SRIVANJIAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 610111,
      "TXT_PINCODE_LOCALITY": "SURANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 610112,
      "TXT_PINCODE_LOCALITY": "THIRUKKANNAMANGAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 610113,
      "TXT_PINCODE_LOCALITY": "KANKODUTHAVANITHAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 610114,
      "TXT_PINCODE_LOCALITY": "BUDAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 610115,
      "TXT_PINCODE_LOCALITY": "ANAIKUPPAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 610116,
      "TXT_PINCODE_LOCALITY": "VADAGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 610201,
      "TXT_PINCODE_LOCALITY": "KACHANAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 610202,
      "TXT_PINCODE_LOCALITY": "MAVOOR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 610203,
      "TXT_PINCODE_LOCALITY": "PONNIRAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 610204,
      "TXT_PINCODE_LOCALITY": "TIRUKUVALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 610205,
      "TXT_PINCODE_LOCALITY": "TIRUNEVELI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 610206,
      "TXT_PINCODE_LOCALITY": "VADAPATHIMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 610207,
      "TXT_PINCODE_LOCALITY": "VALIVALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 610208,
      "TXT_PINCODE_LOCALITY": "KOLAPADU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 610209,
      "TXT_PINCODE_LOCALITY": "PULLAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 610210,
      "TXT_PINCODE_LOCALITY": "MANALI TTP",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 610211,
      "TXT_PINCODE_LOCALITY": "ALATHAMPADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 610212,
      "TXT_PINCODE_LOCALITY": "ETTUKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 611001,
      "TXT_PINCODE_LOCALITY": "MELAKOTTAIVASAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 611002,
      "TXT_PINCODE_LOCALITY": "DURGA SHERIFF",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 611003,
      "TXT_PINCODE_LOCALITY": "NAGAPATTINAM COLLECTORATE S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 611101,
      "TXT_PINCODE_LOCALITY": "ADAIKKAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 611102,
      "TXT_PINCODE_LOCALITY": "ANDANAPETTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 611103,
      "TXT_PINCODE_LOCALITY": "KILAYUR NAGAPATTINAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 611104,
      "TXT_PINCODE_LOCALITY": "KILVELUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 611105,
      "TXT_PINCODE_LOCALITY": "KUTHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 611106,
      "TXT_PINCODE_LOCALITY": "MANJAKOLLAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 611108,
      "TXT_PINCODE_LOCALITY": "PARAVACHARY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 611109,
      "TXT_PINCODE_LOCALITY": "THEVUR THANJAVUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 611110,
      "TXT_PINCODE_LOCALITY": "TIRUPANDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 611111,
      "TXT_PINCODE_LOCALITY": "VAILANKANNI WEST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 611112,
      "TXT_PINCODE_LOCALITY": "VETTAIKARANIRUPPU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 611113,
      "TXT_PINCODE_LOCALITY": "PRATHAPARAMAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 611114,
      "TXT_PINCODE_LOCALITY": "THERKU POGAINALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 611115,
      "TXT_PINCODE_LOCALITY": "VADAKKU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 611116,
      "TXT_PINCODE_LOCALITY": "ATHIPULIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 611117,
      "TXT_PINCODE_LOCALITY": "ALIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 611118,
      "TXT_PINCODE_LOCALITY": "NAGAPATTINAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 611119,
      "TXT_PINCODE_LOCALITY": "VILUNDAMVADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612001,
      "TXT_PINCODE_LOCALITY": "KOMBAKONAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612002,
      "TXT_PINCODE_LOCALITY": "MELAKAVERI H O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612101,
      "TXT_PINCODE_LOCALITY": "ADUTHURAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612102,
      "TXT_PINCODE_LOCALITY": "TIRUMANGALAKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612103,
      "TXT_PINCODE_LOCALITY": "THANJAVUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612104,
      "TXT_PINCODE_LOCALITY": "TIRUVIDAIMARUDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612105,
      "TXT_PINCODE_LOCALITY": "VEPPATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612106,
      "TXT_PINCODE_LOCALITY": "KADIRAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612107,
      "TXT_PINCODE_LOCALITY": "AVANIAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612108,
      "TXT_PINCODE_LOCALITY": "TIRUNILAKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612109,
      "TXT_PINCODE_LOCALITY": "PALAKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612110,
      "TXT_PINCODE_LOCALITY": "TIRUVESALOOR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612125,
      "TXT_PINCODE_LOCALITY": "POTTAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612201,
      "TXT_PINCODE_LOCALITY": "KONERIRAJAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612202,
      "TXT_PINCODE_LOCALITY": "MURUGANGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612203,
      "TXT_PINCODE_LOCALITY": "PALAIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612204,
      "TXT_PINCODE_LOCALITY": "TIRUNAGESWARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612205,
      "TXT_PINCODE_LOCALITY": "SENGARAMKUDIPUDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612206,
      "TXT_PINCODE_LOCALITY": "TEPPERUMANALLUR - 4750",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612301,
      "TXT_PINCODE_LOCALITY": "PULLABUDANGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612302,
      "TXT_PINCODE_LOCALITY": "SWAMMIMALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612303,
      "TXT_PINCODE_LOCALITY": "TIRUPPIRAMBIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612304,
      "TXT_PINCODE_LOCALITY": "TIRUVAIKAVUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612401,
      "TXT_PINCODE_LOCALITY": "KUMBAKONAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612402,
      "TXT_PINCODE_LOCALITY": "TIPPIRAJAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612501,
      "TXT_PINCODE_LOCALITY": "KORANATTUKARUPPUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612502,
      "TXT_PINCODE_LOCALITY": "LOWER ANAICUT",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612503,
      "TXT_PINCODE_LOCALITY": "SHOLAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612504,
      "TXT_PINCODE_LOCALITY": "TIRUPANANDAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612505,
      "TXT_PINCODE_LOCALITY": "DEVANACHERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612506,
      "TXT_PINCODE_LOCALITY": "NIRAIHANALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612601,
      "TXT_PINCODE_LOCALITY": "KODAVASAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612602,
      "TXT_PINCODE_LOCALITY": "NACHIARKOIL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612603,
      "TXT_PINCODE_LOCALITY": "SEMBANGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612604,
      "TXT_PINCODE_LOCALITY": "SENGALIPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612605,
      "TXT_PINCODE_LOCALITY": "TIRUCHIRAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612606,
      "TXT_PINCODE_LOCALITY": "VIDAYAL KARUPPUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612607,
      "TXT_PINCODE_LOCALITY": "VADAVER",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612608,
      "TXT_PINCODE_LOCALITY": "PUDUKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612609,
      "TXT_PINCODE_LOCALITY": "KANDRAMANICKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612610,
      "TXT_PINCODE_LOCALITY": "PUDUKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 425863,
      "City District Name": "TIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612614,
      "TXT_PINCODE_LOCALITY": "MUDUKADU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5087,
      "City District Name": "PERAVURANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612701,
      "TXT_PINCODE_LOCALITY": "AVOOR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612702,
      "TXT_PINCODE_LOCALITY": "DARASURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612703,
      "TXT_PINCODE_LOCALITY": "PATTEESWARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612704,
      "TXT_PINCODE_LOCALITY": "GOVINDAKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612719,
      "TXT_PINCODE_LOCALITY": "PALAIYUR MANNARGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612801,
      "TXT_PINCODE_LOCALITY": "ALANGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612802,
      "TXT_PINCODE_LOCALITY": "HARIDWARAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612803,
      "TXT_PINCODE_LOCALITY": "PUVANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612804,
      "TXT_PINCODE_LOCALITY": "VALANGAMAN",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612805,
      "TXT_PINCODE_LOCALITY": "CHANDRASEKARAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612806,
      "TXT_PINCODE_LOCALITY": "ODAYALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612807,
      "TXT_PINCODE_LOCALITY": "RAYAPURAM TNJ",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612901,
      "TXT_PINCODE_LOCALITY": "GANGAIKONDAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612902,
      "TXT_PINCODE_LOCALITY": "KODALIKARUPPUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612903,
      "TXT_PINCODE_LOCALITY": "UDAIYARPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5085,
      "City District Name": "PERAMBALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612904,
      "TXT_PINCODE_LOCALITY": "THATTANPETTAIPALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 612905,
      "TXT_PINCODE_LOCALITY": "ULKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613001,
      "TXT_PINCODE_LOCALITY": "THANJAVUR EAST GATE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613002,
      "TXT_PINCODE_LOCALITY": "KARUNTHATTANGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613003,
      "TXT_PINCODE_LOCALITY": "VANNAR BANK",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613004,
      "TXT_PINCODE_LOCALITY": "THANJAVUR MEDICAL COLLEGE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613005,
      "TXT_PINCODE_LOCALITY": "RAJA SERFOJI GOVT COLLEGE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613006,
      "TXT_PINCODE_LOCALITY": "THANJAVUR P&T COLONY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613007,
      "TXT_PINCODE_LOCALITY": "THANJAVUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613008,
      "TXT_PINCODE_LOCALITY": "THANJAVUR NORTH GATE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613009,
      "TXT_PINCODE_LOCALITY": "CO OPERATIVE BUILDINGS",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613010,
      "TXT_PINCODE_LOCALITY": "TAMIL UNIVERSITY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613051,
      "TXT_PINCODE_LOCALITY": "RAMANATHAPURAM TNJ",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613052,
      "TXT_PINCODE_LOCALITY": "VILAR - 4889",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613053,
      "TXT_PINCODE_LOCALITY": "PALLIAGRAHARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613054,
      "TXT_PINCODE_LOCALITY": "INAYUTHUKKANPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613101,
      "TXT_PINCODE_LOCALITY": "NADUKAVERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613102,
      "TXT_PINCODE_LOCALITY": "THOGUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613103,
      "TXT_PINCODE_LOCALITY": "TIRUPANTHURUTHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613104,
      "TXT_PINCODE_LOCALITY": "TIRUKATTUPALLI EAST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613105,
      "TXT_PINCODE_LOCALITY": "POONDI TIRUKATTUPALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613106,
      "TXT_PINCODE_LOCALITY": "KOVILADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613107,
      "TXT_PINCODE_LOCALITY": "RENGANATHAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613108,
      "TXT_PINCODE_LOCALITY": "KONERIRAJAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613109,
      "TXT_PINCODE_LOCALITY": "VARAGUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613110,
      "TXT_PINCODE_LOCALITY": "KACHAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613201,
      "TXT_PINCODE_LOCALITY": "KALYANAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613202,
      "TXT_PINCODE_LOCALITY": "KANDIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613203,
      "TXT_PINCODE_LOCALITY": "THILLAISTHANAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613204,
      "TXT_PINCODE_LOCALITY": "TIRUVAIYURA NORTH",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613205,
      "TXT_PINCODE_LOCALITY": "VEDIAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613206,
      "TXT_PINCODE_LOCALITY": "MAHARAJAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613207,
      "TXT_PINCODE_LOCALITY": "PONAVASAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613301,
      "TXT_PINCODE_LOCALITY": "GHANDARVAKOTTAI S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613302,
      "TXT_PINCODE_LOCALITY": "KOVILUR THANJAVUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613303,
      "TXT_PINCODE_LOCALITY": "KURUNGULAM MELPATHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613304,
      "TXT_PINCODE_LOCALITY": "RAGHUNATHAPURAM TNJ",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613305,
      "TXT_PINCODE_LOCALITY": "KADAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613306,
      "TXT_PINCODE_LOCALITY": "KURUNGULAM KEELPATHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613307,
      "TXT_PINCODE_LOCALITY": "TIRUKANUR PATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613308,
      "TXT_PINCODE_LOCALITY": "KURUNGULAM MELPATHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613401,
      "TXT_PINCODE_LOCALITY": "M G M SANATORIUM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613402,
      "TXT_PINCODE_LOCALITY": "SENGIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613403,
      "TXT_PINCODE_LOCALITY": "VALLAM BAZAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613501,
      "TXT_PINCODE_LOCALITY": "MARIAMMANKOIL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613502,
      "TXT_PINCODE_LOCALITY": "PUNDI THANJAVUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613503,
      "TXT_PINCODE_LOCALITY": "SIR PUSHPAM COLLEGE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613504,
      "TXT_PINCODE_LOCALITY": "SALIMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613505,
      "TXT_PINCODE_LOCALITY": "IRUMBURTHALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613506,
      "TXT_PINCODE_LOCALITY": "KALANCHERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613601,
      "TXT_PINCODE_LOCALITY": "ALAKUDI (THANJAVUR)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613602,
      "TXT_PINCODE_LOCALITY": "BUDALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613603,
      "TXT_PINCODE_LOCALITY": "KALLAPERAMBUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613701,
      "TXT_PINCODE_LOCALITY": "KOLLUMANGUDI S. O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 425863,
      "City District Name": "TIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613702,
      "TXT_PINCODE_LOCALITY": "ATHIKADAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613703,
      "TXT_PINCODE_LOCALITY": "KORADACHERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613704,
      "TXT_PINCODE_LOCALITY": "KULIKARAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613705,
      "TXT_PINCODE_LOCALITY": "SELLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613706,
      "TXT_PINCODE_LOCALITY": "ABIVIETHISWARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613707,
      "TXT_PINCODE_LOCALITY": "BARAKADABAD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 613902,
      "TXT_PINCODE_LOCALITY": "PUDUKKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614001,
      "TXT_PINCODE_LOCALITY": "MANNARGUID CUTCHERRY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614012,
      "TXT_PINCODE_LOCALITY": "HANDRANADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614013,
      "TXT_PINCODE_LOCALITY": "IDANGANKOTTAI MELAIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614014,
      "TXT_PINCODE_LOCALITY": "PAMANI (MANNARGUDI)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614015,
      "TXT_PINCODE_LOCALITY": "PARAVAKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 425863,
      "City District Name": "TIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614016,
      "TXT_PINCODE_LOCALITY": "SERANGOIAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614017,
      "TXT_PINCODE_LOCALITY": "TIRUMAKKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614018,
      "TXT_PINCODE_LOCALITY": "ULLIKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614019,
      "TXT_PINCODE_LOCALITY": "THANJAVUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614020,
      "TXT_PINCODE_LOCALITY": "PAINGANADU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614021,
      "TXT_PINCODE_LOCALITY": "KARUVAKURICHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614022,
      "TXT_PINCODE_LOCALITY": "KOOPACHIKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614023,
      "TXT_PINCODE_LOCALITY": "MUKKULAM SATHANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614024,
      "TXT_PINCODE_LOCALITY": "SERIMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614025,
      "TXT_PINCODE_LOCALITY": "SOLAPANDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614026,
      "TXT_PINCODE_LOCALITY": "VANDAYANIRUPPU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614027,
      "TXT_PINCODE_LOCALITY": "PERAIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614028,
      "TXT_PINCODE_LOCALITY": "TIRUPALAKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614029,
      "TXT_PINCODE_LOCALITY": "MAHADEVAPATTINAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614030,
      "TXT_PINCODE_LOCALITY": "KILAKURICHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614031,
      "TXT_PINCODE_LOCALITY": "VALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614045,
      "TXT_PINCODE_LOCALITY": "SANBRIX ELVIRA",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614101,
      "TXT_PINCODE_LOCALITY": "KOOTHANALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614102,
      "TXT_PINCODE_LOCALITY": "LAKSHMANGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1877,
      "City District Name": "TANJORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614103,
      "TXT_PINCODE_LOCALITY": "PODAKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614139,
      "TXT_PINCODE_LOCALITY": "CHOLAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614201,
      "TXT_PINCODE_LOCALITY": "AYYAMPET (THANJAVUR)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614202,
      "TXT_PINCODE_LOCALITY": "ILUPPAKORAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614203,
      "TXT_PINCODE_LOCALITY": "KABISTHALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614204,
      "TXT_PINCODE_LOCALITY": "PANDARAVADAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614205,
      "TXT_PINCODE_LOCALITY": "PAPANASAM BAZAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614206,
      "TXT_PINCODE_LOCALITY": "PASUPATHIKOIL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614207,
      "TXT_PINCODE_LOCALITY": "RAJAGHIRI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614208,
      "TXT_PINCODE_LOCALITY": "SUNDERAPERUMALKOIL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614209,
      "TXT_PINCODE_LOCALITY": "UMAYALPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614210,
      "TXT_PINCODE_LOCALITY": "VALOOTHOOR T J",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614211,
      "TXT_PINCODE_LOCALITY": "CHAKRAPALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614212,
      "TXT_PINCODE_LOCALITY": "ULLIKADAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614213,
      "TXT_PINCODE_LOCALITY": "UTHAMADANAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614214,
      "TXT_PINCODE_LOCALITY": "CHAKKARAPALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5001,
      "City District Name": "PAPANASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614301,
      "TXT_PINCODE_LOCALITY": "MELATTUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614302,
      "TXT_PINCODE_LOCALITY": "TIRUKARAGAVUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614303,
      "TXT_PINCODE_LOCALITY": "VADAKKU MANGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614401,
      "TXT_PINCODE_LOCALITY": "AMMAPET",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614402,
      "TXT_PINCODE_LOCALITY": "KOKKERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614403,
      "TXT_PINCODE_LOCALITY": "KOVILVENNI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614404,
      "TXT_PINCODE_LOCALITY": "NIDAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614405,
      "TXT_PINCODE_LOCALITY": "ARUNDAVAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614406,
      "TXT_PINCODE_LOCALITY": "PERAMBURNIDAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614407,
      "TXT_PINCODE_LOCALITY": "MUNNAVALKOLLAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614408,
      "TXT_PINCODE_LOCALITY": "KALLURANIKKADU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5087,
      "City District Name": "PERAVURANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614412,
      "TXT_PINCODE_LOCALITY": "KRISHNAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4144,
      "City District Name": "KUMBAKONAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614601,
      "TXT_PINCODE_LOCALITY": "THANGAVELNAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614602,
      "TXT_PINCODE_LOCALITY": "NADIMUTHUNAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614612,
      "TXT_PINCODE_LOCALITY": "PERIMAGALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614613,
      "TXT_PINCODE_LOCALITY": "TAMARANKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614614,
      "TXT_PINCODE_LOCALITY": "TIRUVONAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614615,
      "TXT_PINCODE_LOCALITY": "ANAIKKADU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614616,
      "TXT_PINCODE_LOCALITY": "ARANTHANGI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614617,
      "TXT_PINCODE_LOCALITY": "AMMAPATTINAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614618,
      "TXT_PINCODE_LOCALITY": "AVADAYARKOIL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614619,
      "TXT_PINCODE_LOCALITY": "KOTTAIPATTINAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614620,
      "TXT_PINCODE_LOCALITY": "MANAMELKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614621,
      "TXT_PINCODE_LOCALITY": "MIMISAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614622,
      "TXT_PINCODE_LOCALITY": "SILATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614623,
      "TXT_PINCODE_LOCALITY": "AVANAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614624,
      "TXT_PINCODE_LOCALITY": "KEERAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614625,
      "TXT_PINCODE_LOCALITY": "ORATTANAD SOUTH",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614626,
      "TXT_PINCODE_LOCALITY": "PAPPANAD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614627,
      "TXT_PINCODE_LOCALITY": "TIRUMANGALAKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614628,
      "TXT_PINCODE_LOCALITY": "TIRUCHITRAMBALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614629,
      "TXT_PINCODE_LOCALITY": "PONBETHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614630,
      "TXT_PINCODE_LOCALITY": "ATTANI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614631,
      "TXT_PINCODE_LOCALITY": "URANIPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614632,
      "TXT_PINCODE_LOCALITY": "OKKANAD KILAIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614633,
      "TXT_PINCODE_LOCALITY": "CHIDAMBARAVIDUTHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614634,
      "TXT_PINCODE_LOCALITY": "EDAYATHIMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614635,
      "TXT_PINCODE_LOCALITY": "JAGADAPATTINAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614636,
      "TXT_PINCODE_LOCALITY": "VENKARAI EAST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614637,
      "TXT_PINCODE_LOCALITY": "KALLAKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614638,
      "TXT_PINCODE_LOCALITY": "PATTUKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614639,
      "TXT_PINCODE_LOCALITY": "ADAMBAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614640,
      "TXT_PINCODE_LOCALITY": "KRISHNARAJIPATTINAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614641,
      "TXT_PINCODE_LOCALITY": "BRAHMANAVAYAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614642,
      "TXT_PINCODE_LOCALITY": "TIRUPUNAVASAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614643,
      "TXT_PINCODE_LOCALITY": "SINGAVANAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614644,
      "TXT_PINCODE_LOCALITY": "PERUMARUDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614645,
      "TXT_PINCODE_LOCALITY": "THIYATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614647,
      "TXT_PINCODE_LOCALITY": "ARANTANGI FORT",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614701,
      "TXT_PINCODE_LOCALITY": "ADIRAMPATTINAM TOWN",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614702,
      "TXT_PINCODE_LOCALITY": "IDAIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614703,
      "TXT_PINCODE_LOCALITY": "KUNNALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614704,
      "TXT_PINCODE_LOCALITY": "MUTTUPET-TOWN",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614705,
      "TXT_PINCODE_LOCALITY": "PERUVAZHANDAN",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614706,
      "TXT_PINCODE_LOCALITY": "UDAYAMARTHANDAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614707,
      "TXT_PINCODE_LOCALITY": "SETHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614708,
      "TXT_PINCODE_LOCALITY": "KOTTUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614709,
      "TXT_PINCODE_LOCALITY": "MANAKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614710,
      "TXT_PINCODE_LOCALITY": "NADUVAKALAPPAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614711,
      "TXT_PINCODE_LOCALITY": "TIRUTURAIPOONDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614712,
      "TXT_PINCODE_LOCALITY": "TALANAGAR AGRAHARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614713,
      "TXT_PINCODE_LOCALITY": "TIRUTHURAIPUNNDI R S",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614714,
      "TXT_PINCODE_LOCALITY": "TAKATTUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614715,
      "TXT_PINCODE_LOCALITY": "VILAKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614716,
      "TXT_PINCODE_LOCALITY": "KATTIMODU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614717,
      "TXT_PINCODE_LOCALITY": "ADICHAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614719,
      "TXT_PINCODE_LOCALITY": "MANNARAGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614720,
      "TXT_PINCODE_LOCALITY": "TIRUKALAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614721,
      "TXT_PINCODE_LOCALITY": "MARAVAKADU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614722,
      "TXT_PINCODE_LOCALITY": "RAJAMADAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614723,
      "TXT_PINCODE_LOCALITY": "MALLIPATTINAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614724,
      "TXT_PINCODE_LOCALITY": "VELUR T T PUNDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614725,
      "TXT_PINCODE_LOCALITY": "KODINAVAYAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614726,
      "TXT_PINCODE_LOCALITY": "VADUVANCHERY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614727,
      "TXT_PINCODE_LOCALITY": "THULAJAPATTINAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614728,
      "TXT_PINCODE_LOCALITY": "SITHAMALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614729,
      "TXT_PINCODE_LOCALITY": "THILLAI VILAGAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614730,
      "TXT_PINCODE_LOCALITY": "T NELAKADU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614731,
      "TXT_PINCODE_LOCALITY": "KILAKADU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614732,
      "TXT_PINCODE_LOCALITY": "KARUPPANPULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614733,
      "TXT_PINCODE_LOCALITY": "KORUKKAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614734,
      "TXT_PINCODE_LOCALITY": "ADANUR PATTUKKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614735,
      "TXT_PINCODE_LOCALITY": "IDUMBAVANAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614736,
      "TXT_PINCODE_LOCALITY": "KEERAKALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614737,
      "TXT_PINCODE_LOCALITY": "TAMBIKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614738,
      "TXT_PINCODE_LOCALITY": "JAMBAVANODAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614780,
      "TXT_PINCODE_LOCALITY": "KATHARIPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6162,
      "City District Name": "VEDARANYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614801,
      "TXT_PINCODE_LOCALITY": "ARASARKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614802,
      "TXT_PINCODE_LOCALITY": "KURUVIKARAMBAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614803,
      "TXT_PINCODE_LOCALITY": "OTTANGADU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614804,
      "TXT_PINCODE_LOCALITY": "PERAVURNI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614805,
      "TXT_PINCODE_LOCALITY": "SUBRAMANIAPURAM TNJ",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614806,
      "TXT_PINCODE_LOCALITY": "KARIYAPATTINAM S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614807,
      "TXT_PINCODE_LOCALITY": "KODIKARAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614808,
      "TXT_PINCODE_LOCALITY": "KURUVAPULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614809,
      "TXT_PINCODE_LOCALITY": "VEDARANYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614810,
      "TXT_PINCODE_LOCALITY": "VEDARNIYAM EAST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614811,
      "TXT_PINCODE_LOCALITY": "SETHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614812,
      "TXT_PINCODE_LOCALITY": "KATIRPULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614813,
      "TXT_PINCODE_LOCALITY": "ARKATTUTHURAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614814,
      "TXT_PINCODE_LOCALITY": "POONAIKUTHIKADU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614815,
      "TXT_PINCODE_LOCALITY": "CHETTIPULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614816,
      "TXT_PINCODE_LOCALITY": "KALATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614817,
      "TXT_PINCODE_LOCALITY": "SETHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614818,
      "TXT_PINCODE_LOCALITY": "SEMBAGARAYANALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614820,
      "TXT_PINCODE_LOCALITY": "SEMBODAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614821,
      "TXT_PINCODE_LOCALITY": "KUDIAKKADU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614822,
      "TXT_PINCODE_LOCALITY": "PUSHPAVANAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614890,
      "TXT_PINCODE_LOCALITY": "KANNATHANGUDI EAST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 442975,
      "City District Name": "ORATHANADU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614901,
      "TXT_PINCODE_LOCALITY": "ALATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614902,
      "TXT_PINCODE_LOCALITY": "KOTTAITHERU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614903,
      "TXT_PINCODE_LOCALITY": "MADUKKUR BAZAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614904,
      "TXT_PINCODE_LOCALITY": "MELAULUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614905,
      "TXT_PINCODE_LOCALITY": "VADASSERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614906,
      "TXT_PINCODE_LOCALITY": "NATTUCHALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614908,
      "TXT_PINCODE_LOCALITY": "KILAIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614909,
      "TXT_PINCODE_LOCALITY": "MANNANAGADU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614910,
      "TXT_PINCODE_LOCALITY": "KASANKADU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614911,
      "TXT_PINCODE_LOCALITY": "KASANADUPUDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614912,
      "TXT_PINCODE_LOCALITY": "POYYANDANKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614913,
      "TXT_PINCODE_LOCALITY": "PINNAIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614914,
      "TXT_PINCODE_LOCALITY": "KALYANAODAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 614915,
      "TXT_PINCODE_LOCALITY": "SIRAMELKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 615503,
      "TXT_PINCODE_LOCALITY": "KADICHAMBADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4144,
      "City District Name": "KUMBAKONAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 616260,
      "TXT_PINCODE_LOCALITY": "NAGARASAMPETTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4144,
      "City District Name": "KUMBAKONAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 616630,
      "TXT_PINCODE_LOCALITY": "TALIKKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 442974,
      "City District Name": "MANAMELKUDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 619106,
      "TXT_PINCODE_LOCALITY": "TAPPALAMPULIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5934,
      "City District Name": "THIRUVARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 620001,
      "TXT_PINCODE_LOCALITY": "COLLECTORATE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5978,
      "City District Name": "TIRUCHIRAPPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 620002,
      "TXT_PINCODE_LOCALITY": "TIRUCHIRAPALLI TOWN HALL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 620003,
      "TXT_PINCODE_LOCALITY": "WORIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 620004,
      "TXT_PINCODE_LOCALITY": "GOLDEN ROCK",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 620005,
      "TXT_PINCODE_LOCALITY": "KONDAYANPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5978,
      "City District Name": "TIRUCHIRAPPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 620006,
      "TXT_PINCODE_LOCALITY": "SRIRANGAM NORTH",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5978,
      "City District Name": "TIRUCHIRAPPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 620007,
      "TXT_PINCODE_LOCALITY": "TIRUCHI AIR PORT",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 620008,
      "TXT_PINCODE_LOCALITY": "CLOCK TOWER",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5978,
      "City District Name": "TIRUCHIRAPPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 620009,
      "TXT_PINCODE_LOCALITY": "RAMJEENAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 620010,
      "TXT_PINCODE_LOCALITY": "WORAIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1852,
      "City District Name": "TRICHY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 620011,
      "TXT_PINCODE_LOCALITY": "MELAKALKANDARKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 620012,
      "TXT_PINCODE_LOCALITY": "NAGAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1852,
      "City District Name": "TRICHY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 620013,
      "TXT_PINCODE_LOCALITY": "TIRUVERAMBUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 620014,
      "TXT_PINCODE_LOCALITY": "BOILER PROJECT",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5978,
      "City District Name": "TIRUCHIRAPPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 620015,
      "TXT_PINCODE_LOCALITY": "TRICHY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1852,
      "City District Name": "TRICHY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 620016,
      "TXT_PINCODE_LOCALITY": "ORADANCE ESTATE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 620017,
      "TXT_PINCODE_LOCALITY": "TENNUR EAST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5978,
      "City District Name": "TIRUCHIRAPPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 620018,
      "TXT_PINCODE_LOCALITY": "TRICHY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1852,
      "City District Name": "TRICHY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 620019,
      "TXT_PINCODE_LOCALITY": "PAPPAKURICHI KATTUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 620020,
      "TXT_PINCODE_LOCALITY": "SUBRAMANIAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5978,
      "City District Name": "TIRUCHIRAPPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 620021,
      "TXT_PINCODE_LOCALITY": "K K NAGAR METRE FACTORY R",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 620022,
      "TXT_PINCODE_LOCALITY": "THUVAGUDIMALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 620023,
      "TXT_PINCODE_LOCALITY": "KHAJANAGAR S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5978,
      "City District Name": "TIRUCHIRAPPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 620024,
      "TXT_PINCODE_LOCALITY": "BHARATHIDASAN UNIVERSITY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 620025,
      "TXT_PINCODE_LOCALITY": "HAPF TOWNSHIP",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5978,
      "City District Name": "TIRUCHIRAPPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 620026,
      "TXT_PINCODE_LOCALITY": "ANNANAGAR S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5978,
      "City District Name": "TIRUCHIRAPPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 620027,
      "TXT_PINCODE_LOCALITY": "TAMILNADU NATIONAL LAW SCHOOL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 620051,
      "TXT_PINCODE_LOCALITY": "MANIKANDAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 620052,
      "TXT_PINCODE_LOCALITY": "ASOOR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 620101,
      "TXT_PINCODE_LOCALITY": "MUTHARASANALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 620102,
      "TXT_PINCODE_LOCALITY": "SOMARASAMPETTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621001,
      "TXT_PINCODE_LOCALITY": "ERAKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621002,
      "TXT_PINCODE_LOCALITY": "KIRAMBUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621003,
      "TXT_PINCODE_LOCALITY": "KOTTAPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621004,
      "TXT_PINCODE_LOCALITY": "KOTTATUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621005,
      "TXT_PINCODE_LOCALITY": "MANACHANALLUR NORTH",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5978,
      "City District Name": "TIRUCHIRAPPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621006,
      "TXT_PINCODE_LOCALITY": "PULIVALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621007,
      "TXT_PINCODE_LOCALITY": "PUTHANAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621008,
      "TXT_PINCODE_LOCALITY": "SIKKAL THAMBUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621009,
      "TXT_PINCODE_LOCALITY": "THIRUVELLARI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621010,
      "TXT_PINCODE_LOCALITY": "TURAIYUR H O TRICHY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621011,
      "TXT_PINCODE_LOCALITY": "UPPIDAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621012,
      "TXT_PINCODE_LOCALITY": "VYRICHETTIPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621014,
      "TXT_PINCODE_LOCALITY": "PAGALAVADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621015,
      "TXT_PINCODE_LOCALITY": "PANCHAPERUMALPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621016,
      "TXT_PINCODE_LOCALITY": "PERUMALPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621017,
      "TXT_PINCODE_LOCALITY": "SENGATTAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5978,
      "City District Name": "TIRUCHIRAPPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621018,
      "TXT_PINCODE_LOCALITY": "SOBANAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621019,
      "TXT_PINCODE_LOCALITY": "T PUDUPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621020,
      "TXT_PINCODE_LOCALITY": "MURUNGAIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621021,
      "TXT_PINCODE_LOCALITY": "THINNANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621022,
      "TXT_PINCODE_LOCALITY": "OKKARAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621023,
      "TXT_PINCODE_LOCALITY": "NAGALAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621100,
      "TXT_PINCODE_LOCALITY": "ARUMBAVUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5085,
      "City District Name": "PERAMBALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621101,
      "TXT_PINCODE_LOCALITY": "AMMAPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5978,
      "City District Name": "TIRUCHIRAPPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621102,
      "TXT_PINCODE_LOCALITY": "ANNAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2107,
      "City District Name": "ARIYALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621103,
      "TXT_PINCODE_LOCALITY": "ARUMBAVUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621104,
      "TXT_PINCODE_LOCALITY": "ALATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5085,
      "City District Name": "PERAMBALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621105,
      "TXT_PINCODE_LOCALITY": "IRUNGALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621106,
      "TXT_PINCODE_LOCALITY": "KOLAKALNATHAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621107,
      "TXT_PINCODE_LOCALITY": "KURUMBALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5085,
      "City District Name": "PERAMBALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621108,
      "TXT_PINCODE_LOCALITY": "LABBAIKUDIKADU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5085,
      "City District Name": "PERAMBALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621109,
      "TXT_PINCODE_LOCALITY": "PADALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621110,
      "TXT_PINCODE_LOCALITY": "POOLAMBADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5085,
      "City District Name": "PERAMBALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621111,
      "TXT_PINCODE_LOCALITY": "PURATHAGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621112,
      "TXT_PINCODE_LOCALITY": "SAMAYAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621113,
      "TXT_PINCODE_LOCALITY": "SIRUVACHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5085,
      "City District Name": "PERAMBALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621114,
      "TXT_PINCODE_LOCALITY": "T KALATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621115,
      "TXT_PINCODE_LOCALITY": "VALIKONDAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621116,
      "TXT_PINCODE_LOCALITY": "VEPPANTHATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5085,
      "City District Name": "PERAMBALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621117,
      "TXT_PINCODE_LOCALITY": "V KALATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5085,
      "City District Name": "PERAMBALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621118,
      "TXT_PINCODE_LOCALITY": "NAKKALALEM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5978,
      "City District Name": "TIRUCHIRAPPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621120,
      "TXT_PINCODE_LOCALITY": "BOMMANAPADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5085,
      "City District Name": "PERAMBALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621121,
      "TXT_PINCODE_LOCALITY": "LADAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5085,
      "City District Name": "PERAMBALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621122,
      "TXT_PINCODE_LOCALITY": "RANJANGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5978,
      "City District Name": "TIRUCHIRAPPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621123,
      "TXT_PINCODE_LOCALITY": "SIRUGANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621124,
      "TXT_PINCODE_LOCALITY": "KAIKALATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621125,
      "TXT_PINCODE_LOCALITY": "PASUMBALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621126,
      "TXT_PINCODE_LOCALITY": "NARANMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621127,
      "TXT_PINCODE_LOCALITY": "NATHAM BALASMUDRAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621128,
      "TXT_PINCODE_LOCALITY": "KARAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5978,
      "City District Name": "TIRUCHIRAPPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621129,
      "TXT_PINCODE_LOCALITY": "THEVAIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5085,
      "City District Name": "PERAMBALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621130,
      "TXT_PINCODE_LOCALITY": "AYILUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5978,
      "City District Name": "TIRUCHIRAPPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621131,
      "TXT_PINCODE_LOCALITY": "VELUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621132,
      "TXT_PINCODE_LOCALITY": "KONALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621133,
      "TXT_PINCODE_LOCALITY": "ERAIYUR S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5085,
      "City District Name": "PERAMBALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621201,
      "TXT_PINCODE_LOCALITY": "ARTS COLLEGE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621202,
      "TXT_PINCODE_LOCALITY": "AYYAMPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621203,
      "TXT_PINCODE_LOCALITY": "BALASAMUDRAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621204,
      "TXT_PINCODE_LOCALITY": "GUNASILAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621205,
      "TXT_PINCODE_LOCALITY": "JAMBUNATHAPURAM S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621206,
      "TXT_PINCODE_LOCALITY": "KANNANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621207,
      "TXT_PINCODE_LOCALITY": "KATTUPUTHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621208,
      "TXT_PINCODE_LOCALITY": "KOLAKUDI MANMEDU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621209,
      "TXT_PINCODE_LOCALITY": "MANAMEDU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621210,
      "TXT_PINCODE_LOCALITY": "METTUPALAYAM TRICHY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5978,
      "City District Name": "TIRUCHIRAPPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621211,
      "TXT_PINCODE_LOCALITY": "MUSIRI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621212,
      "TXT_PINCODE_LOCALITY": "PERAMBALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5085,
      "City District Name": "PERAMBALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621213,
      "TXT_PINCODE_LOCALITY": "SIRUGAMBUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621214,
      "TXT_PINCODE_LOCALITY": "THATHIENGARPET",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621215,
      "TXT_PINCODE_LOCALITY": "THOTTAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621216,
      "TXT_PINCODE_LOCALITY": "BIKSHANDARKOIL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5978,
      "City District Name": "TIRUCHIRAPPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621217,
      "TXT_PINCODE_LOCALITY": "THANDALAIPUTTUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621218,
      "TXT_PINCODE_LOCALITY": "VALADY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621219,
      "TXT_PINCODE_LOCALITY": "YESANAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621220,
      "TXT_PINCODE_LOCALITY": "THORAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5085,
      "City District Name": "PERAMBALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621221,
      "TXT_PINCODE_LOCALITY": "AMUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621222,
      "TXT_PINCODE_LOCALITY": "SERGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621223,
      "TXT_PINCODE_LOCALITY": "THUMBALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621224,
      "TXT_PINCODE_LOCALITY": "URAKKARAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621225,
      "TXT_PINCODE_LOCALITY": "VADAMALAIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621226,
      "TXT_PINCODE_LOCALITY": "UNNIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621228,
      "TXT_PINCODE_LOCALITY": "YELURPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621229,
      "TXT_PINCODE_LOCALITY": "NATHAM (SRIRANGAM)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621230,
      "TXT_PINCODE_LOCALITY": "VALAVANDHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621231,
      "TXT_PINCODE_LOCALITY": "NAGAYANALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621232,
      "TXT_PINCODE_LOCALITY": "MURUNGAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621240,
      "TXT_PINCODE_LOCALITY": "KARUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621301,
      "TXT_PINCODE_LOCALITY": "CHINTAMANIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621302,
      "TXT_PINCODE_LOCALITY": "ELANGAKURICHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 425848,
      "City District Name": "THIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621303,
      "TXT_PINCODE_LOCALITY": "INAMKOLATHUR R S",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621304,
      "TXT_PINCODE_LOCALITY": "KALLUPATTI MURUNGAPURI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621305,
      "TXT_PINCODE_LOCALITY": "KOVILPATTI S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621306,
      "TXT_PINCODE_LOCALITY": "MANAPARAI R S",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621307,
      "TXT_PINCODE_LOCALITY": "MANJAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621308,
      "TXT_PINCODE_LOCALITY": "PALAIKURICHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621309,
      "TXT_PINCODE_LOCALITY": "PULUDIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5978,
      "City District Name": "TIRUCHIRAPPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621310,
      "TXT_PINCODE_LOCALITY": "PUTHANATHAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621311,
      "TXT_PINCODE_LOCALITY": "THARANGAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621312,
      "TXT_PINCODE_LOCALITY": "THAIGESAR ALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5978,
      "City District Name": "TIRUCHIRAPPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621313,
      "TXT_PINCODE_LOCALITY": "THOGAMALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621314,
      "TXT_PINCODE_LOCALITY": "THUVARANKURICHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621315,
      "TXT_PINCODE_LOCALITY": "VAIYAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621316,
      "TXT_PINCODE_LOCALITY": "VIRALIMALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621317,
      "TXT_PINCODE_LOCALITY": "VALANDU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621319,
      "TXT_PINCODE_LOCALITY": "PANNAIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621320,
      "TXT_PINCODE_LOCALITY": "MOHAVANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621321,
      "TXT_PINCODE_LOCALITY": "PALAVIDUTHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621322,
      "TXT_PINCODE_LOCALITY": "NADUPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621323,
      "TXT_PINCODE_LOCALITY": "V PERIYAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4657,
      "City District Name": "NAGAPATTINAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621601,
      "TXT_PINCODE_LOCALITY": "LALGUDI H O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621604,
      "TXT_PINCODE_LOCALITY": "GUNASILAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621651,
      "TXT_PINCODE_LOCALITY": "KALLAKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621652,
      "TXT_PINCODE_LOCALITY": "KALLAKUDI NOTH",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621653,
      "TXT_PINCODE_LOCALITY": "KALLAGAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621701,
      "TXT_PINCODE_LOCALITY": "AMBAPUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621702,
      "TXT_PINCODE_LOCALITY": "AMBIL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621703,
      "TXT_PINCODE_LOCALITY": "ANGARAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621704,
      "TXT_PINCODE_LOCALITY": "ARIYALUR CEMENT FACTORY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5978,
      "City District Name": "TIRUCHIRAPPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621705,
      "TXT_PINCODE_LOCALITY": "KALLANKURICHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5978,
      "City District Name": "TIRUCHIRAPPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621706,
      "TXT_PINCODE_LOCALITY": "SIRUMAYANKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5978,
      "City District Name": "TIRUCHIRAPPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621707,
      "TXT_PINCODE_LOCALITY": "KILAPALUVUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621708,
      "TXT_PINCODE_LOCALITY": "KUNNAM TRICHY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5085,
      "City District Name": "PERAMBALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621709,
      "TXT_PINCODE_LOCALITY": "MATHUR-TRICHY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5978,
      "City District Name": "TIRUCHIRAPPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621710,
      "TXT_PINCODE_LOCALITY": "PONPARAPPI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2107,
      "City District Name": "ARIYALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621711,
      "TXT_PINCODE_LOCALITY": "PULLAMBADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621712,
      "TXT_PINCODE_LOCALITY": "PUVALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621713,
      "TXT_PINCODE_LOCALITY": "ARIYALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5085,
      "City District Name": "PERAMBALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621714,
      "TXT_PINCODE_LOCALITY": "SENDURAI (TIRUCHY)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2107,
      "City District Name": "ARIYALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621715,
      "TXT_PINCODE_LOCALITY": "TIRUMANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5978,
      "City District Name": "TIRUCHIRAPPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621716,
      "TXT_PINCODE_LOCALITY": "TUNGAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621717,
      "TXT_PINCODE_LOCALITY": "VEPPUR TRICHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621718,
      "TXT_PINCODE_LOCALITY": "RAYAMBARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621719,
      "TXT_PINCODE_LOCALITY": "ASAVEERANKUDIKADU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2107,
      "City District Name": "ARIYALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621720,
      "TXT_PINCODE_LOCALITY": "OLAIPADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5085,
      "City District Name": "PERAMBALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621721,
      "TXT_PINCODE_LOCALITY": "PUDURPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621722,
      "TXT_PINCODE_LOCALITY": "VIRAGALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621723,
      "TXT_PINCODE_LOCALITY": "KULAMANICKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621724,
      "TXT_PINCODE_LOCALITY": "ALAMBAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621725,
      "TXT_PINCODE_LOCALITY": "PUNJAISENGANTHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621726,
      "TXT_PINCODE_LOCALITY": "KOVANDAKURICHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621727,
      "TXT_PINCODE_LOCALITY": "KILAPERAMBUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621728,
      "TXT_PINCODE_LOCALITY": "VAYALAPADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5085,
      "City District Name": "PERAMBALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621729,
      "TXT_PINCODE_LOCALITY": "LALGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621730,
      "TXT_PINCODE_LOCALITY": "DALAVOI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2107,
      "City District Name": "ARIYALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621731,
      "TXT_PINCODE_LOCALITY": "REDDIPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621801,
      "TXT_PINCODE_LOCALITY": "ANDIMADAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2107,
      "City District Name": "ARIYALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621802,
      "TXT_PINCODE_LOCALITY": "JEYANKONDAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621803,
      "TXT_PINCODE_LOCALITY": "KALLATHUR THANDALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621804,
      "TXT_PINCODE_LOCALITY": "UDAYARPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2107,
      "City District Name": "ARIYALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621805,
      "TXT_PINCODE_LOCALITY": "VARDAJAMPETTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2107,
      "City District Name": "ARIYALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621806,
      "TXT_PINCODE_LOCALITY": "VARIYANKAVAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2107,
      "City District Name": "ARIYALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621807,
      "TXT_PINCODE_LOCALITY": "TATTANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621851,
      "TXT_PINCODE_LOCALITY": "TIRUMALAIVADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5978,
      "City District Name": "TIRUCHIRAPPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621852,
      "TXT_PINCODE_LOCALITY": "KANDIRTHIRTHAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621853,
      "TXT_PINCODE_LOCALITY": "ILANDAIKUDAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621901,
      "TXT_PINCODE_LOCALITY": "VEERACHOLA PURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6030,
      "City District Name": "UDAYARPALAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 621904,
      "TXT_PINCODE_LOCALITY": "SUTHAMALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6030,
      "City District Name": "UDAYARPALAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622001,
      "TXT_PINCODE_LOCALITY": "PODUKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622002,
      "TXT_PINCODE_LOCALITY": "TIRUGOKARNAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622003,
      "TXT_PINCODE_LOCALITY": "TIRUVAPPUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622004,
      "TXT_PINCODE_LOCALITY": "PUDUKKOTTAI IND ESTATE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622005,
      "TXT_PINCODE_LOCALITY": "COLLECTORATE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622009,
      "TXT_PINCODE_LOCALITY": "TIRUMAYANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622052,
      "TXT_PINCODE_LOCALITY": "VADAVALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622053,
      "TXT_PINCODE_LOCALITY": "NAMANASAMUDRAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622054,
      "TXT_PINCODE_LOCALITY": "KARAIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622055,
      "TXT_PINCODE_LOCALITY": "MARAVAMADURAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622056,
      "TXT_PINCODE_LOCALITY": "MELATHANJAIR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622101,
      "TXT_PINCODE_LOCALITY": "ANNAVASAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622102,
      "TXT_PINCODE_LOCALITY": "ILLUPUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622103,
      "TXT_PINCODE_LOCALITY": "MUCKANAMALAIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622104,
      "TXT_PINCODE_LOCALITY": "VAYALOGAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622105,
      "TXT_PINCODE_LOCALITY": "NARTHAMALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622106,
      "TXT_PINCODE_LOCALITY": "KUDIMIAMALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622107,
      "TXT_PINCODE_LOCALITY": "PERAMBUR (PDCT)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622108,
      "TXT_PINCODE_LOCALITY": "VIRAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622201,
      "TXT_PINCODE_LOCALITY": "ARIMALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622202,
      "TXT_PINCODE_LOCALITY": "KILANILAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622203,
      "TXT_PINCODE_LOCALITY": "PERUNGALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622204,
      "TXT_PINCODE_LOCALITY": "YEMBAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622205,
      "TXT_PINCODE_LOCALITY": "VENNAVALKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622206,
      "TXT_PINCODE_LOCALITY": "ODAYALIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622207,
      "TXT_PINCODE_LOCALITY": "ANDAKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622208,
      "TXT_PINCODE_LOCALITY": "KUMMANGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622209,
      "TXT_PINCODE_LOCALITY": "NEDUNGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622210,
      "TXT_PINCODE_LOCALITY": "ADDANAKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622211,
      "TXT_PINCODE_LOCALITY": "OKKUR PERUNDAMARAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622212,
      "TXT_PINCODE_LOCALITY": "KULAVAIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622213,
      "TXT_PINCODE_LOCALITY": "CHOKKANATHAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622230,
      "TXT_PINCODE_LOCALITY": "ELAIKADIVIDUTHY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 442972,
      "City District Name": "KARAMBAKUDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622231,
      "TXT_PINCODE_LOCALITY": "M.THERIKUTHERU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 442972,
      "City District Name": "KARAMBAKUDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622301,
      "TXT_PINCODE_LOCALITY": "ALANGUDI (PDK)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622302,
      "TXT_PINCODE_LOCALITY": "KARAMBAKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622303,
      "TXT_PINCODE_LOCALITY": "TIRUVARANKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622304,
      "TXT_PINCODE_LOCALITY": "VADAKADU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622305,
      "TXT_PINCODE_LOCALITY": "VALLATHIRKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622306,
      "TXT_PINCODE_LOCALITY": "MALAIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622307,
      "TXT_PINCODE_LOCALITY": "PONNANVIDUTHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622308,
      "TXT_PINCODE_LOCALITY": "KOTTAIKADU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622309,
      "TXT_PINCODE_LOCALITY": "VAMBAM COLONY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622310,
      "TXT_PINCODE_LOCALITY": "VENKATAKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622311,
      "TXT_PINCODE_LOCALITY": "KOTHAKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622312,
      "TXT_PINCODE_LOCALITY": "VAMBAN",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622313,
      "TXT_PINCODE_LOCALITY": "MANGOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622314,
      "TXT_PINCODE_LOCALITY": "PALLAVARAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622315,
      "TXT_PINCODE_LOCALITY": "SOORANVIDUTHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622316,
      "TXT_PINCODE_LOCALITY": "PUVARASAKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622317,
      "TXT_PINCODE_LOCALITY": "KAIKURICHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622401,
      "TXT_PINCODE_LOCALITY": "KOPPANAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622402,
      "TXT_PINCODE_LOCALITY": "KULIPIRAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622403,
      "TXT_PINCODE_LOCALITY": "MELASIVAPURI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622404,
      "TXT_PINCODE_LOCALITY": "NACHANDUPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622405,
      "TXT_PINCODE_LOCALITY": "NERKUPPAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622406,
      "TXT_PINCODE_LOCALITY": "PANAYAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622407,
      "TXT_PINCODE_LOCALITY": "PONNAMARAVATH",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622408,
      "TXT_PINCODE_LOCALITY": "PUDUPATTI PUDUKKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622409,
      "TXT_PINCODE_LOCALITY": "RANGIEM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622410,
      "TXT_PINCODE_LOCALITY": "ULAGAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622411,
      "TXT_PINCODE_LOCALITY": "VALAYAPATTI PDK",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622412,
      "TXT_PINCODE_LOCALITY": "VIRACHILAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622413,
      "TXT_PINCODE_LOCALITY": "PULANKURICHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622414,
      "TXT_PINCODE_LOCALITY": "TIRUKALAMBUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622415,
      "TXT_PINCODE_LOCALITY": "KURUVIKONDANPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622416,
      "TXT_PINCODE_LOCALITY": "VARAPURPUDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622417,
      "TXT_PINCODE_LOCALITY": "SEVVUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622419,
      "TXT_PINCODE_LOCALITY": "VENDANPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622420,
      "TXT_PINCODE_LOCALITY": "MELAPANAIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622421,
      "TXT_PINCODE_LOCALITY": "LAMBALAKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622422,
      "TXT_PINCODE_LOCALITY": "NAMANASAMUDRAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622501,
      "TXT_PINCODE_LOCALITY": "CAUVERYNAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622502,
      "TXT_PINCODE_LOCALITY": "KEERANUR PUDUKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622503,
      "TXT_PINCODE_LOCALITY": "KONAPET",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622504,
      "TXT_PINCODE_LOCALITY": "KULATHUR PUDUKKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622505,
      "TXT_PINCODE_LOCALITY": "RAMACHANDRAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622506,
      "TXT_PINCODE_LOCALITY": "RAYAVARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622507,
      "TXT_PINCODE_LOCALITY": "TIRUMAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622508,
      "TXT_PINCODE_LOCALITY": "KUNNANDARKOIL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622509,
      "TXT_PINCODE_LOCALITY": "TEKKATTUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622510,
      "TXT_PINCODE_LOCALITY": "MATHUR KEERANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622511,
      "TXT_PINCODE_LOCALITY": "PUTHAMBUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622512,
      "TXT_PINCODE_LOCALITY": "VELLANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622513,
      "TXT_PINCODE_LOCALITY": "MANDAIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622514,
      "TXT_PINCODE_LOCALITY": "HIRADAYAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622515,
      "TXT_PINCODE_LOCALITY": "MATHUR IND EST PUDUKKOTTA",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 622605,
      "TXT_PINCODE_LOCALITY": "MELAMATTUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6221,
      "City District Name": "VIRUDHUNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623001,
      "TXT_PINCODE_LOCALITY": "MUTHUPATTINAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623002,
      "TXT_PINCODE_LOCALITY": "SEKKALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623003,
      "TXT_PINCODE_LOCALITY": "ALAGAPPAPURAM H O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623004,
      "TXT_PINCODE_LOCALITY": "ALAGAPPA ENGG COLLEGE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623005,
      "TXT_PINCODE_LOCALITY": "DEVAKOTTAI ROAD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623006,
      "TXT_PINCODE_LOCALITY": "CECRI CAMPUS",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623009,
      "TXT_PINCODE_LOCALITY": "CECRI CAMPUS",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623101,
      "TXT_PINCODE_LOCALITY": "ATHANGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623102,
      "TXT_PINCODE_LOCALITY": "CHETTINAD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623103,
      "TXT_PINCODE_LOCALITY": "KANADUKATHAN",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623104,
      "TXT_PINCODE_LOCALITY": "KADANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623105,
      "TXT_PINCODE_LOCALITY": "KOTHAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623106,
      "TXT_PINCODE_LOCALITY": "KOTTAIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623107,
      "TXT_PINCODE_LOCALITY": "PALLATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623108,
      "TXT_PINCODE_LOCALITY": "PODUVAYAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623109,
      "TXT_PINCODE_LOCALITY": "PEERKALAIKADU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623110,
      "TXT_PINCODE_LOCALITY": "MITRAVAYAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623111,
      "TXT_PINCODE_LOCALITY": "NEMTHANPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623115,
      "TXT_PINCODE_LOCALITY": "THUTHINATHAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623120,
      "TXT_PINCODE_LOCALITY": "KURUVADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623135,
      "TXT_PINCODE_LOCALITY": "S.THARAIKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623201,
      "TXT_PINCODE_LOCALITY": "ATHIKADU THEKKUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623202,
      "TXT_PINCODE_LOCALITY": "ARIYAKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623203,
      "TXT_PINCODE_LOCALITY": "KANDAVARAYANPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623204,
      "TXT_PINCODE_LOCALITY": "KANDRAMANICKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623205,
      "TXT_PINCODE_LOCALITY": "KILASEVALPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623206,
      "TXT_PINCODE_LOCALITY": "KUNNAKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623207,
      "TXT_PINCODE_LOCALITY": "NACHIAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623208,
      "TXT_PINCODE_LOCALITY": "O SIRUVAYAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623209,
      "TXT_PINCODE_LOCALITY": "S M HOSPITAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623210,
      "TXT_PINCODE_LOCALITY": "TIRUKKOSHIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623211,
      "TXT_PINCODE_LOCALITY": "TIRUPATTUR BUS STAND",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623212,
      "TXT_PINCODE_LOCALITY": "VIRAMATHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623213,
      "TXT_PINCODE_LOCALITY": "SATHURUSANKARAKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623214,
      "TXT_PINCODE_LOCALITY": "SIRAVAYAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623215,
      "TXT_PINCODE_LOCALITY": "SIRUKUNDALPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623216,
      "TXT_PINCODE_LOCALITY": "KOTTAIYIRUPPU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623217,
      "TXT_PINCODE_LOCALITY": "MAHIBALANPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623225,
      "TXT_PINCODE_LOCALITY": "ARIYANKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5007,
      "City District Name": "PARAMAKUDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623301,
      "TXT_PINCODE_LOCALITY": "AMARAVATHI PUDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623302,
      "TXT_PINCODE_LOCALITY": "SILAMBANI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623303,
      "TXT_PINCODE_LOCALITY": "DEVAKOTTAI EXTN",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623304,
      "TXT_PINCODE_LOCALITY": "ERIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623305,
      "TXT_PINCODE_LOCALITY": "KALLAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623306,
      "TXT_PINCODE_LOCALITY": "KALLUPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623307,
      "TXT_PINCODE_LOCALITY": "MANAGIRI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623308,
      "TXT_PINCODE_LOCALITY": "MANGALAKUDI S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623309,
      "TXT_PINCODE_LOCALITY": "ORIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623310,
      "TXT_PINCODE_LOCALITY": "PATTAMANGALAM R",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623311,
      "TXT_PINCODE_LOCALITY": "PERIUAKARAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623312,
      "TXT_PINCODE_LOCALITY": "PULIYAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623313,
      "TXT_PINCODE_LOCALITY": "SAMBANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623314,
      "TXT_PINCODE_LOCALITY": "SHANMUGANATHAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623315,
      "TXT_PINCODE_LOCALITY": "VELLAYAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623317,
      "TXT_PINCODE_LOCALITY": "HANUMANTHAKUDDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623318,
      "TXT_PINCODE_LOCALITY": "KANNANGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623319,
      "TXT_PINCODE_LOCALITY": "KOVILUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623320,
      "TXT_PINCODE_LOCALITY": "KUTHALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623321,
      "TXT_PINCODE_LOCALITY": "NESAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623401,
      "TXT_PINCODE_LOCALITY": "ANANDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623402,
      "TXT_PINCODE_LOCALITY": "CHINNAKEERAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623403,
      "TXT_PINCODE_LOCALITY": "NADAMBAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623404,
      "TXT_PINCODE_LOCALITY": "PANICKANADAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623405,
      "TXT_PINCODE_LOCALITY": "SARAKENI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623406,
      "TXT_PINCODE_LOCALITY": "SUNDARAPANDIAPATINAM RMD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623407,
      "TXT_PINCODE_LOCALITY": "TIRUVADANAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623408,
      "TXT_PINCODE_LOCALITY": "TIRUVEGAMPET",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623409,
      "TXT_PINCODE_LOCALITY": "TONDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623410,
      "TXT_PINCODE_LOCALITY": "ANJUKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623501,
      "TXT_PINCODE_LOCALITY": "RAMANATHAPURAM WEST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623502,
      "TXT_PINCODE_LOCALITY": "ACHUNTHANVAYAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623503,
      "TXT_PINCODE_LOCALITY": "RAMANATHAPURAM COLLECTORATE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623504,
      "TXT_PINCODE_LOCALITY": "DEVIPATTINAM ROAD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623512,
      "TXT_PINCODE_LOCALITY": "ALANGANKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623513,
      "TXT_PINCODE_LOCALITY": "CHITTARKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623514,
      "TXT_PINCODE_LOCALITY": "DEVIPATTINAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623515,
      "TXT_PINCODE_LOCALITY": "ERUVADI RAMNAD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623516,
      "TXT_PINCODE_LOCALITY": "IRUMENI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623517,
      "TXT_PINCODE_LOCALITY": "KILAKARAI WEST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623518,
      "TXT_PINCODE_LOCALITY": "MANDAPAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623519,
      "TXT_PINCODE_LOCALITY": "MANDAPAM CAMP",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623520,
      "TXT_PINCODE_LOCALITY": "MARINE FISHERIES",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623521,
      "TXT_PINCODE_LOCALITY": "PAMBAN",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623522,
      "TXT_PINCODE_LOCALITY": "PANAIKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623523,
      "TXT_PINCODE_LOCALITY": "PERIYAPATTINAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623524,
      "TXT_PINCODE_LOCALITY": "PUDUMADAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623525,
      "TXT_PINCODE_LOCALITY": "RAJASINGAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623526,
      "TXT_PINCODE_LOCALITY": "RAMESWARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623527,
      "TXT_PINCODE_LOCALITY": "CHATTIRAKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623528,
      "TXT_PINCODE_LOCALITY": "SIKKAL RAMNAD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623529,
      "TXT_PINCODE_LOCALITY": "THANGACHIMADAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623530,
      "TXT_PINCODE_LOCALITY": "TORUVALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623531,
      "TXT_PINCODE_LOCALITY": "TIRUPALAKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623532,
      "TXT_PINCODE_LOCALITY": "TIRUPPAPULLANI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623533,
      "TXT_PINCODE_LOCALITY": "TIRUTTARAKOSAMANGAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623534,
      "TXT_PINCODE_LOCALITY": "UCHIPULLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623535,
      "TXT_PINCODE_LOCALITY": "VELIPATNAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623536,
      "TXT_PINCODE_LOCALITY": "PERUNGULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623537,
      "TXT_PINCODE_LOCALITY": "POTTAGAVAYAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623538,
      "TXT_PINCODE_LOCALITY": "SANAVELI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623539,
      "TXT_PINCODE_LOCALITY": "ATHIYUTHU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623540,
      "TXT_PINCODE_LOCALITY": "KANJIRAKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623541,
      "TXT_PINCODE_LOCALITY": "MAYAKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623542,
      "TXT_PINCODE_LOCALITY": "SAKKARAKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623543,
      "TXT_PINCODE_LOCALITY": "VALANTHARAVAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623544,
      "TXT_PINCODE_LOCALITY": "RETTAIYURANI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623545,
      "TXT_PINCODE_LOCALITY": "THERKU THARAVAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623546,
      "TXT_PINCODE_LOCALITY": "SOORANKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623547,
      "TXT_PINCODE_LOCALITY": "THINAIKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623548,
      "TXT_PINCODE_LOCALITY": "MELAKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623550,
      "TXT_PINCODE_LOCALITY": "KADUKKAVALASAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623551,
      "TXT_PINCODE_LOCALITY": "KALIYARKOIL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623552,
      "TXT_PINCODE_LOCALITY": "KILAPUNGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623553,
      "TXT_PINCODE_LOCALITY": "MADAGUPATTU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623554,
      "TXT_PINCODE_LOCALITY": "MANGALAM-RAMNAD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623555,
      "TXT_PINCODE_LOCALITY": "NATARAJAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623556,
      "TXT_PINCODE_LOCALITY": "NATTARASANKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623557,
      "TXT_PINCODE_LOCALITY": "OKKUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623558,
      "TXT_PINCODE_LOCALITY": "PAGANERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623559,
      "TXT_PINCODE_LOCALITY": "PERIYAKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623560,
      "TXT_PINCODE_LOCALITY": "SIVAGANGA COURTS",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623561,
      "TXT_PINCODE_LOCALITY": "BOSENAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623562,
      "TXT_PINCODE_LOCALITY": "TAMARAKKI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623565,
      "TXT_PINCODE_LOCALITY": "VEMBARPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623566,
      "TXT_PINCODE_LOCALITY": "ERVADI DURGA",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623575,
      "TXT_PINCODE_LOCALITY": "IDAMBADAL.",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 442971,
      "City District Name": "KADALADI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623601,
      "TXT_PINCODE_LOCALITY": "ABIRAMAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623602,
      "TXT_PINCODE_LOCALITY": "IDAIKAATUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623603,
      "TXT_PINCODE_LOCALITY": "KAMUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623604,
      "TXT_PINCODE_LOCALITY": "KOTTAIMEDU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623605,
      "TXT_PINCODE_LOCALITY": "MANDALAMANICKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623606,
      "TXT_PINCODE_LOCALITY": "MANAMADURAI TOWN",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623607,
      "TXT_PINCODE_LOCALITY": "NARIKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623608,
      "TXT_PINCODE_LOCALITY": "PARTHIBANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623609,
      "TXT_PINCODE_LOCALITY": "RAJAGAMBIRAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623610,
      "TXT_PINCODE_LOCALITY": "TIRUPPACHETTY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623611,
      "TXT_PINCODE_LOCALITY": "TIRUPUVANAM (RAMNAD)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623612,
      "TXT_PINCODE_LOCALITY": "VIRACHOLAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623613,
      "TXT_PINCODE_LOCALITY": "MANAMADURAI R S",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623617,
      "TXT_PINCODE_LOCALITY": "KILAPERUNGARAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623618,
      "TXT_PINCODE_LOCALITY": "KOVILANGULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623631,
      "TXT_PINCODE_LOCALITY": "OCHERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623701,
      "TXT_PINCODE_LOCALITY": "EMANESWARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623702,
      "TXT_PINCODE_LOCALITY": "ILAYANGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623703,
      "TXT_PINCODE_LOCALITY": "KADALADI (RAMNAD)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623704,
      "TXT_PINCODE_LOCALITY": "MUDUMALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623705,
      "TXT_PINCODE_LOCALITY": "NAINARKOIL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623706,
      "TXT_PINCODE_LOCALITY": "PAMBUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623707,
      "TXT_PINCODE_LOCALITY": "KATTUPARAMAKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623708,
      "TXT_PINCODE_LOCALITY": "PERAIYUR RAMNAD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623709,
      "TXT_PINCODE_LOCALITY": "PUDUR RAMNAD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623710,
      "TXT_PINCODE_LOCALITY": "SALAIGRAMAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623711,
      "TXT_PINCODE_LOCALITY": "TERIRUVELI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623712,
      "TXT_PINCODE_LOCALITY": "RAMNAD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623713,
      "TXT_PINCODE_LOCALITY": "SURANAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623714,
      "TXT_PINCODE_LOCALITY": "APPANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623715,
      "TXT_PINCODE_LOCALITY": "ILANJAMBUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623716,
      "TXT_PINCODE_LOCALITY": "MELASIRUPODU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623717,
      "TXT_PINCODE_LOCALITY": "POTHIKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623718,
      "TXT_PINCODE_LOCALITY": "KEELATHEOVAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623719,
      "TXT_PINCODE_LOCALITY": "KAMUDAKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623720,
      "TXT_PINCODE_LOCALITY": "SEMBANGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623721,
      "TXT_PINCODE_LOCALITY": "KARUNCHUTTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623722,
      "TXT_PINCODE_LOCALITY": "MARIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623723,
      "TXT_PINCODE_LOCALITY": "MELAYAKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623801,
      "TXT_PINCODE_LOCALITY": "PUDUVALASAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623802,
      "TXT_PINCODE_LOCALITY": "RAGHUNATHAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623804,
      "TXT_PINCODE_LOCALITY": "VADALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623806,
      "TXT_PINCODE_LOCALITY": "SATHAK SALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 623807,
      "TXT_PINCODE_LOCALITY": "EKKAKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624001,
      "TXT_PINCODE_LOCALITY": "MANGLES ROAD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624002,
      "TXT_PINCODE_LOCALITY": "BEGAMPUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624003,
      "TXT_PINCODE_LOCALITY": "BHARATHIPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624004,
      "TXT_PINCODE_LOCALITY": "BESCHI COLLEGE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624005,
      "TXT_PINCODE_LOCALITY": "BALAKRISHNAPURAMPUDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624006,
      "TXT_PINCODE_LOCALITY": "DINDIGUL EAST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624007,
      "TXT_PINCODE_LOCALITY": "PANDIANAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624008,
      "TXT_PINCODE_LOCALITY": "ANGU NAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624009,
      "TXT_PINCODE_LOCALITY": "N G O COLONY DINDIGUL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624010,
      "TXT_PINCODE_LOCALITY": "PAMBARPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624051,
      "TXT_PINCODE_LOCALITY": "KOOVANUTHU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624052,
      "TXT_PINCODE_LOCALITY": "PADIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624053,
      "TXT_PINCODE_LOCALITY": "KULATHUR DINDIGUL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624054,
      "TXT_PINCODE_LOCALITY": "HANUMANTHARAYANKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624101,
      "TXT_PINCODE_LOCALITY": "KODAIKANAL LAK VIEW",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624102,
      "TXT_PINCODE_LOCALITY": "ANANDAGIRI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624103,
      "TXT_PINCODE_LOCALITY": "KODAIKANAL OBSERVATORY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624104,
      "TXT_PINCODE_LOCALITY": "SHENBAGANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624107,
      "TXT_PINCODE_LOCALITY": "VILPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624108,
      "TXT_PINCODE_LOCALITY": "PERUMALMALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624123,
      "TXT_PINCODE_LOCALITY": "SAMATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624130,
      "TXT_PINCODE_LOCALITY": "PARASAKTHI COLONY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5725,
      "City District Name": "SIVAKASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624201,
      "TXT_PINCODE_LOCALITY": "AMMAYANAYAKKANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624202,
      "TXT_PINCODE_LOCALITY": "BATLAGUNDU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624203,
      "TXT_PINCODE_LOCALITY": "GENGUVARPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624204,
      "TXT_PINCODE_LOCALITY": "IYYAMPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624205,
      "TXT_PINCODE_LOCALITY": "KARUPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624206,
      "TXT_PINCODE_LOCALITY": "ORUTHATTU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624207,
      "TXT_PINCODE_LOCALITY": "MULLIPALLAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624208,
      "TXT_PINCODE_LOCALITY": "NILAKOTTAI BAZAAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624209,
      "TXT_PINCODE_LOCALITY": "PANDIARAJAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624210,
      "TXT_PINCODE_LOCALITY": "PANNAIKADU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624211,
      "TXT_PINCODE_LOCALITY": "PATTIVEERAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624212,
      "TXT_PINCODE_LOCALITY": "PERUMBARAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624213,
      "TXT_PINCODE_LOCALITY": "RAMARAJAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624214,
      "TXT_PINCODE_LOCALITY": "SHOLAVANDAN H O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624215,
      "TXT_PINCODE_LOCALITY": "SIUKUVARPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624216,
      "TXT_PINCODE_LOCALITY": "TANDIGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624217,
      "TXT_PINCODE_LOCALITY": "TIRUVEDAGAM WEST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624218,
      "TXT_PINCODE_LOCALITY": "VADIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624219,
      "TXT_PINCODE_LOCALITY": "VILAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624220,
      "TXT_PINCODE_LOCALITY": "VIRUVEDU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624221,
      "TXT_PINCODE_LOCALITY": "TANICHIYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624222,
      "TXT_PINCODE_LOCALITY": "THUVARIMAN",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624223,
      "TXT_PINCODE_LOCALITY": "MANNADIMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624224,
      "TXT_PINCODE_LOCALITY": "SEVUGAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624225,
      "TXT_PINCODE_LOCALITY": "ANDIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624226,
      "TXT_PINCODE_LOCALITY": "TENKARAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624227,
      "TXT_PINCODE_LOCALITY": "NACHIKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624228,
      "TXT_PINCODE_LOCALITY": "SITHAREVU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624230,
      "TXT_PINCODE_LOCALITY": "K C PATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624231,
      "TXT_PINCODE_LOCALITY": "KULLALAGUNDU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624232,
      "TXT_PINCODE_LOCALITY": "VIKRAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624233,
      "TXT_PINCODE_LOCALITY": "PALLAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624234,
      "TXT_PINCODE_LOCALITY": "TIRUVEDAGAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624235,
      "TXT_PINCODE_LOCALITY": "KUPPAMMALPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624301,
      "TXT_PINCODE_LOCALITY": "CHINNALAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624302,
      "TXT_PINCODE_LOCALITY": "GANDHI GRAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624303,
      "TXT_PINCODE_LOCALITY": "N PANJAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624304,
      "TXT_PINCODE_LOCALITY": "SANARPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624305,
      "TXT_PINCODE_LOCALITY": "SETTINAYAKKANPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624306,
      "TXT_PINCODE_LOCALITY": "SILUVATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624307,
      "TXT_PINCODE_LOCALITY": "VELLODU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624308,
      "TXT_PINCODE_LOCALITY": "VEMBARPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624309,
      "TXT_PINCODE_LOCALITY": "AMBATHURAI R S",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624310,
      "TXT_PINCODE_LOCALITY": "SILAIPADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624311,
      "TXT_PINCODE_LOCALITY": "AMBATHURAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624312,
      "TXT_PINCODE_LOCALITY": "SAMIYARPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624313,
      "TXT_PINCODE_LOCALITY": "KAMBILIANPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624314,
      "TXT_PINCODE_LOCALITY": "CHETTIAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624315,
      "TXT_PINCODE_LOCALITY": "PERUMALKOVILPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624316,
      "TXT_PINCODE_LOCALITY": "RAJAKKAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624317,
      "TXT_PINCODE_LOCALITY": "PERIYAKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624318,
      "TXT_PINCODE_LOCALITY": "RAGALAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624401,
      "TXT_PINCODE_LOCALITY": "NATTAM EAST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624402,
      "TXT_PINCODE_LOCALITY": "SAMUDRAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624403,
      "TXT_PINCODE_LOCALITY": "SENDURAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2107,
      "City District Name": "ARIYALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624404,
      "TXT_PINCODE_LOCALITY": "SIRUGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624405,
      "TXT_PINCODE_LOCALITY": "VEMPARALI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4761,
      "City District Name": "NATHAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624410,
      "TXT_PINCODE_LOCALITY": "VEDASANDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6163,
      "City District Name": "VEDASANDUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624501,
      "TXT_PINCODE_LOCALITY": "VEDASANDUR TALUK",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624502,
      "TXT_PINCODE_LOCALITY": "SINGAMPUNERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624503,
      "TXT_PINCODE_LOCALITY": "PRIANMALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624504,
      "TXT_PINCODE_LOCALITY": "KIRUNGAKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624505,
      "TXT_PINCODE_LOCALITY": "MURAIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624506,
      "TXT_PINCODE_LOCALITY": "SURAIKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624507,
      "TXT_PINCODE_LOCALITY": "KALLAPPUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624601,
      "TXT_PINCODE_LOCALITY": "PALANI WEST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624602,
      "TXT_PINCODE_LOCALITY": "PALANI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624610,
      "TXT_PINCODE_LOCALITY": "BALASAMUDRAM (DDL)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624611,
      "TXT_PINCODE_LOCALITY": "THOPPAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624612,
      "TXT_PINCODE_LOCALITY": "AMBILIKAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624613,
      "TXT_PINCODE_LOCALITY": "AYAKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624614,
      "TXT_PINCODE_LOCALITY": "CHATRAPATTI PALANI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624615,
      "TXT_PINCODE_LOCALITY": "KALAYAMPUTHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624616,
      "TXT_PINCODE_LOCALITY": "KALLIMANDIYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624617,
      "TXT_PINCODE_LOCALITY": "KEERANUR MADURAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624618,
      "TXT_PINCODE_LOCALITY": "NARIKALPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624619,
      "TXT_PINCODE_LOCALITY": "ODDANCHATRAM NORTH",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624620,
      "TXT_PINCODE_LOCALITY": "PALAYAM-PALANI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624621,
      "TXT_PINCODE_LOCALITY": "PAPPAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624622,
      "TXT_PINCODE_LOCALITY": "REDDIARCHATRAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624623,
      "TXT_PINCODE_LOCALITY": "MANJANAICKENPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624624,
      "TXT_PINCODE_LOCALITY": "PALAKKANUTHU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624625,
      "TXT_PINCODE_LOCALITY": "K KEERANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624626,
      "TXT_PINCODE_LOCALITY": "PERICHIPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624627,
      "TXT_PINCODE_LOCALITY": "AMARAPUNDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624628,
      "TXT_PINCODE_LOCALITY": "VIRUPATCHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624701,
      "TXT_PINCODE_LOCALITY": "AHTOOR (MADURAI)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624702,
      "TXT_PINCODE_LOCALITY": "ERIYODU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624703,
      "TXT_PINCODE_LOCALITY": "GUZILIAMPARAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624704,
      "TXT_PINCODE_LOCALITY": "IDAYAKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624705,
      "TXT_PINCODE_LOCALITY": "KANNIVADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624706,
      "TXT_PINCODE_LOCALITY": "KOVILUR MADURAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624707,
      "TXT_PINCODE_LOCALITY": "SEMBATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624708,
      "TXT_PINCODE_LOCALITY": "SITHAYANKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624709,
      "TXT_PINCODE_LOCALITY": "TADICOMBU - 38",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624710,
      "TXT_PINCODE_LOCALITY": "VEDASANDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624711,
      "TXT_PINCODE_LOCALITY": "KASIPALAYAM MADURAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624712,
      "TXT_PINCODE_LOCALITY": "MARKAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624713,
      "TXT_PINCODE_LOCALITY": "TETTUPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624714,
      "TXT_PINCODE_LOCALITY": "DHARMATHUPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624801,
      "TXT_PINCODE_LOCALITY": "AYYALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624802,
      "TXT_PINCODE_LOCALITY": "VADAMADURAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624803,
      "TXT_PINCODE_LOCALITY": "VELAVARKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 624804,
      "TXT_PINCODE_LOCALITY": "SENGURICHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625001,
      "TXT_PINCODE_LOCALITY": "SOUTH GATE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625002,
      "TXT_PINCODE_LOCALITY": "MADURAI RACE COURSE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625003,
      "TXT_PINCODE_LOCALITY": "MADURAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625004,
      "TXT_PINCODE_LOCALITY": "PASUMALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625005,
      "TXT_PINCODE_LOCALITY": "TIRUPARANKUNDRAM - 1741",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625006,
      "TXT_PINCODE_LOCALITY": "TIRUNAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625007,
      "TXT_PINCODE_LOCALITY": "PUDUR BAZAAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625008,
      "TXT_PINCODE_LOCALITY": "DE NOBILI PRESS",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625009,
      "TXT_PINCODE_LOCALITY": "MADURAI EAST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625010,
      "TXT_PINCODE_LOCALITY": "OPP. HOTEL GERMANUS",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625011,
      "TXT_PINCODE_LOCALITY": "JEEVA NAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625012,
      "TXT_PINCODE_LOCALITY": "AVANIAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625013,
      "TXT_PINCODE_LOCALITY": "THENI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625014,
      "TXT_PINCODE_LOCALITY": "MADURAI RESERVE LINES",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625015,
      "TXT_PINCODE_LOCALITY": "THAIGARAJAR ENGG COLLEGE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625016,
      "TXT_PINCODE_LOCALITY": "STATE BANK COLONY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625017,
      "TXT_PINCODE_LOCALITY": "PAPNASAM PYKARA COLONY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625018,
      "TXT_PINCODE_LOCALITY": "THATHANERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625019,
      "TXT_PINCODE_LOCALITY": "NAGAMALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625020,
      "TXT_PINCODE_LOCALITY": "GANDHI NAGAR MADURAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625021,
      "TXT_PINCODE_LOCALITY": "PALKALAINAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625022,
      "TXT_PINCODE_LOCALITY": "MADURAI TRAINING CENTRE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625023,
      "TXT_PINCODE_LOCALITY": "MADRAS HIGH COURT MADURAI BENCH",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625024,
      "TXT_PINCODE_LOCALITY": "MADURAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625051,
      "TXT_PINCODE_LOCALITY": "POTHUMBU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625052,
      "TXT_PINCODE_LOCALITY": "CHATRAPATTI MDU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625054,
      "TXT_PINCODE_LOCALITY": "HANUMANTHARAYAN KOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625101,
      "TXT_PINCODE_LOCALITY": "KARUNGALAKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625102,
      "TXT_PINCODE_LOCALITY": "KILAVALAVU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625103,
      "TXT_PINCODE_LOCALITY": "KOTTAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625104,
      "TXT_PINCODE_LOCALITY": "MADURAI AGRICULTURAL COLLEGE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625105,
      "TXT_PINCODE_LOCALITY": "MELAVALUVU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625106,
      "TXT_PINCODE_LOCALITY": "MELUR COURTS",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625107,
      "TXT_PINCODE_LOCALITY": "OTHAKADAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625108,
      "TXT_PINCODE_LOCALITY": "THUMBAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625109,
      "TXT_PINCODE_LOCALITY": "VELLALUR S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625110,
      "TXT_PINCODE_LOCALITY": "TIRUVADUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625111,
      "TXT_PINCODE_LOCALITY": "CHITTAMPATT",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625112,
      "TXT_PINCODE_LOCALITY": "NARASINGAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625113,
      "TXT_PINCODE_LOCALITY": "NAVINPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625114,
      "TXT_PINCODE_LOCALITY": "KAMBUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625115,
      "TXT_PINCODE_LOCALITY": "CHOCKALINGAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625116,
      "TXT_PINCODE_LOCALITY": "VANJNAGARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625117,
      "TXT_PINCODE_LOCALITY": "PALLAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625118,
      "TXT_PINCODE_LOCALITY": "KILAYUR MADURAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625119,
      "TXT_PINCODE_LOCALITY": "URANGANPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625120,
      "TXT_PINCODE_LOCALITY": "SARUGU VALAYAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625121,
      "TXT_PINCODE_LOCALITY": "TANIYAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625122,
      "TXT_PINCODE_LOCALITY": "THERKUTHERU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625123,
      "TXT_PINCODE_LOCALITY": "AMBALAKARANPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625124,
      "TXT_PINCODE_LOCALITY": "KOTTAGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625125,
      "TXT_PINCODE_LOCALITY": "POTTAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625126,
      "TXT_PINCODE_LOCALITY": "KACHIRAYANPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625127,
      "TXT_PINCODE_LOCALITY": "PATTUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625201,
      "TXT_PINCODE_LOCALITY": "SILAIMAN",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625202,
      "TXT_PINCODE_LOCALITY": "SAKKIMANGALAM (PART)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 442973,
      "City District Name": "MADURAI NORTH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625203,
      "TXT_PINCODE_LOCALITY": "G.KALLUPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 425471,
      "City District Name": "THENI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625205,
      "TXT_PINCODE_LOCALITY": "NACHIKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625206,
      "TXT_PINCODE_LOCALITY": "KODAI ROAD R S",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625207,
      "TXT_PINCODE_LOCALITY": "THENKARAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625209,
      "TXT_PINCODE_LOCALITY": "PANDIARAJAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625213,
      "TXT_PINCODE_LOCALITY": "THIRUMALNATTAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6116,
      "City District Name": "VADIPATTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625214,
      "TXT_PINCODE_LOCALITY": "SHOLAVANDAN BAZAAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625217,
      "TXT_PINCODE_LOCALITY": "MADURAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625218,
      "TXT_PINCODE_LOCALITY": "VADIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625221,
      "TXT_PINCODE_LOCALITY": "MARIAMMALKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625234,
      "TXT_PINCODE_LOCALITY": "TIRUVEDAGAM WEST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625301,
      "TXT_PINCODE_LOCALITY": "ALAGARKOIL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625302,
      "TXT_PINCODE_LOCALITY": "MANGULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625303,
      "TXT_PINCODE_LOCALITY": "VELLALAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625304,
      "TXT_PINCODE_LOCALITY": "KIDARIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625305,
      "TXT_PINCODE_LOCALITY": "KALLANDIRI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625401,
      "TXT_PINCODE_LOCALITY": "VISALAKSHINAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625402,
      "TXT_PINCODE_LOCALITY": "SAMAYANALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625403,
      "TXT_PINCODE_LOCALITY": "THENUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5188,
      "City District Name": "PUDUKKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625404,
      "TXT_PINCODE_LOCALITY": "PARAVAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625501,
      "TXT_PINCODE_LOCALITY": "ALANGANALLUR (KANNADENDAL)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625502,
      "TXT_PINCODE_LOCALITY": "METTUPATTI MADURAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625503,
      "TXT_PINCODE_LOCALITY": "PALAMEDU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625504,
      "TXT_PINCODE_LOCALITY": "VAVIMARUDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625512,
      "TXT_PINCODE_LOCALITY": "AUNDIPATTI S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 425471,
      "City District Name": "THENI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625513,
      "TXT_PINCODE_LOCALITY": "BODINAICKNOOR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 425471,
      "City District Name": "THENI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625514,
      "TXT_PINCODE_LOCALITY": "CHECKANURANI S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625515,
      "TXT_PINCODE_LOCALITY": "CHINNAMANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 425471,
      "City District Name": "THENI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625516,
      "TXT_PINCODE_LOCALITY": "KARUNAGAMUTHANPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 425471,
      "City District Name": "THENI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625517,
      "TXT_PINCODE_LOCALITY": "GANDAMANAYAKANUR S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 425471,
      "City District Name": "THENI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625518,
      "TXT_PINCODE_LOCALITY": "GUDALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 425471,
      "City District Name": "THENI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625519,
      "TXT_PINCODE_LOCALITY": "HIGHWAVYS",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 425471,
      "City District Name": "THENI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625520,
      "TXT_PINCODE_LOCALITY": "JANGALPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 425471,
      "City District Name": "THENI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625521,
      "TXT_PINCODE_LOCALITY": "KAMAYAGOUNDANPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625522,
      "TXT_PINCODE_LOCALITY": "KOMBAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 425471,
      "City District Name": "THENI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625523,
      "TXT_PINCODE_LOCALITY": "LAKSHMIPURAM S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 425471,
      "City District Name": "THENI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625524,
      "TXT_PINCODE_LOCALITY": "PULIKUTHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 425471,
      "City District Name": "THENI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625525,
      "TXT_PINCODE_LOCALITY": "PERIYAR PROJECT",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 425471,
      "City District Name": "THENI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625526,
      "TXT_PINCODE_LOCALITY": "GOKILAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 425471,
      "City District Name": "THENI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625527,
      "TXT_PINCODE_LOCALITY": "SEDAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625528,
      "TXT_PINCODE_LOCALITY": "SILLAMARATHUPATTI S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 425471,
      "City District Name": "THENI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625529,
      "TXT_PINCODE_LOCALITY": "VEPPANUTHU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625530,
      "TXT_PINCODE_LOCALITY": "THEVARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 425572,
      "City District Name": "BODINAICKANUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625531,
      "TXT_PINCODE_LOCALITY": "N R T MAIN ROAD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 425471,
      "City District Name": "THENI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625532,
      "TXT_PINCODE_LOCALITY": "USILAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625533,
      "TXT_PINCODE_LOCALITY": "UTHAMAPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625534,
      "TXT_PINCODE_LOCALITY": "VEERAPANDI (PERIYAKULAM)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625535,
      "TXT_PINCODE_LOCALITY": "YELUMALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625536,
      "TXT_PINCODE_LOCALITY": "T.SUBBULAPURAM S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 425471,
      "City District Name": "THENI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625537,
      "TXT_PINCODE_LOCALITY": "UTHAPPANAYAKANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625538,
      "TXT_PINCODE_LOCALITY": "VENNIAR ESTATE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 425471,
      "City District Name": "THENI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625540,
      "TXT_PINCODE_LOCALITY": "TAMIL NADU P.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 425471,
      "City District Name": "THENI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625547,
      "TXT_PINCODE_LOCALITY": "KODANGIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 442969,
      "City District Name": "BODINAYAKANUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625552,
      "TXT_PINCODE_LOCALITY": "MARKEYANKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6084,
      "City District Name": "UTHAMAPALAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625556,
      "TXT_PINCODE_LOCALITY": "C.PUDUPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 425471,
      "City District Name": "THENI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625562,
      "TXT_PINCODE_LOCALITY": "VARADHARAJNAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 425471,
      "City District Name": "THENI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625566,
      "TXT_PINCODE_LOCALITY": "MADURAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625572,
      "TXT_PINCODE_LOCALITY": "THAPPUKUNDU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 425471,
      "City District Name": "THENI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625577,
      "TXT_PINCODE_LOCALITY": "SEELAYAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625579,
      "TXT_PINCODE_LOCALITY": "AUNDIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 425471,
      "City District Name": "THENI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625582,
      "TXT_PINCODE_LOCALITY": "CENTRAL STATION",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 425471,
      "City District Name": "THENI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625601,
      "TXT_PINCODE_LOCALITY": "PERIYAKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625602,
      "TXT_PINCODE_LOCALITY": "PALANI R S",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625603,
      "TXT_PINCODE_LOCALITY": "VADUGAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 425471,
      "City District Name": "THENI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625604,
      "TXT_PINCODE_LOCALITY": "NALLAKARUPPANPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 425471,
      "City District Name": "THENI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625605,
      "TXT_PINCODE_LOCALITY": "TAMARAKULAM S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 425471,
      "City District Name": "THENI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625606,
      "TXT_PINCODE_LOCALITY": "THENI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 425471,
      "City District Name": "THENI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625701,
      "TXT_PINCODE_LOCALITY": "KALLIGUDI S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625702,
      "TXT_PINCODE_LOCALITY": "T.KALLUPATTI S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625703,
      "TXT_PINCODE_LOCALITY": "PERAIYUR (MADURAI)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625704,
      "TXT_PINCODE_LOCALITY": "KILAVANERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625705,
      "TXT_PINCODE_LOCALITY": "ATHIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625706,
      "TXT_PINCODE_LOCALITY": "JAWAHAR NAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625707,
      "TXT_PINCODE_LOCALITY": "M.PULIYANKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625708,
      "TXT_PINCODE_LOCALITY": "T.KUNNATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 625852,
      "TXT_PINCODE_LOCALITY": "NATRAMAPALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626001,
      "TXT_PINCODE_LOCALITY": "VIRUDHUNAGAR WEST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6221,
      "City District Name": "VIRUDHUNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626002,
      "TXT_PINCODE_LOCALITY": "VIRUDHUNAGAR COLLECTORATE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424378,
      "City District Name": "VIRDHUNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626003,
      "TXT_PINCODE_LOCALITY": "VIRUDHUNAGAR IND ESTATE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626004,
      "TXT_PINCODE_LOCALITY": "PALAVANATHAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626005,
      "TXT_PINCODE_LOCALITY": "AMATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626051,
      "TXT_PINCODE_LOCALITY": "MULLIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626052,
      "TXT_PINCODE_LOCALITY": "PATTAMBUDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626053,
      "TXT_PINCODE_LOCALITY": "KULLURSANDAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626054,
      "TXT_PINCODE_LOCALITY": "MALAIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6221,
      "City District Name": "VIRUDHUNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626055,
      "TXT_PINCODE_LOCALITY": "KODKALANCHERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6221,
      "City District Name": "VIRUDHUNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626056,
      "TXT_PINCODE_LOCALITY": "ALAGIANALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626057,
      "TXT_PINCODE_LOCALITY": "VELLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626058,
      "TXT_PINCODE_LOCALITY": "MESSALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6221,
      "City District Name": "VIRUDHUNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626059,
      "TXT_PINCODE_LOCALITY": "AVALSURAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626101,
      "TXT_PINCODE_LOCALITY": "ARUPPUKOTTAI BAZAAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6221,
      "City District Name": "VIRUDHUNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626102,
      "TXT_PINCODE_LOCALITY": "VIRUDHUNAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6221,
      "City District Name": "VIRUDHUNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626103,
      "TXT_PINCODE_LOCALITY": "ERICHINATHAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626104,
      "TXT_PINCODE_LOCALITY": "KALKURCHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626105,
      "TXT_PINCODE_LOCALITY": "KALLURANI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626106,
      "TXT_PINCODE_LOCALITY": "KANAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626107,
      "TXT_PINCODE_LOCALITY": "KOVILANGULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626108,
      "TXT_PINCODE_LOCALITY": "KUMARASAMIRAJANAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626109,
      "TXT_PINCODE_LOCALITY": "VELLAUR MDU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6221,
      "City District Name": "VIRUDHUNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626110,
      "TXT_PINCODE_LOCALITY": "SAHIBPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6221,
      "City District Name": "VIRUDHUNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626111,
      "TXT_PINCODE_LOCALITY": "MUHAVUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626112,
      "TXT_PINCODE_LOCALITY": "PALAYAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626113,
      "TXT_PINCODE_LOCALITY": "PANTHALKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626114,
      "TXT_PINCODE_LOCALITY": "PARALACHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626115,
      "TXT_PINCODE_LOCALITY": "PERUNALI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626116,
      "TXT_PINCODE_LOCALITY": "SRIVILLIPUTHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424378,
      "City District Name": "VIRDHUNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626117,
      "TXT_PINCODE_LOCALITY": "RAJAPALAYM H O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626118,
      "TXT_PINCODE_LOCALITY": "ARUPPUKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424378,
      "City District Name": "VIRDHUNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626119,
      "TXT_PINCODE_LOCALITY": "SANKARLINGAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626120,
      "TXT_PINCODE_LOCALITY": "SAYALGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5295,
      "City District Name": "RAMANATHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626121,
      "TXT_PINCODE_LOCALITY": "SEITHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626122,
      "TXT_PINCODE_LOCALITY": "SETTIYARPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6221,
      "City District Name": "VIRUDHUNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626123,
      "TXT_PINCODE_LOCALITY": "SIVAKASI BAZAAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6221,
      "City District Name": "VIRUDHUNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626124,
      "TXT_PINCODE_LOCALITY": "VIRUDHUNAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6221,
      "City District Name": "VIRUDHUNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626125,
      "TXT_PINCODE_LOCALITY": "SRIVILLIPUTTUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5273,
      "City District Name": "RAJAPALAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626126,
      "TXT_PINCODE_LOCALITY": "SUNDARAPANDIAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626127,
      "TXT_PINCODE_LOCALITY": "SIVAKASI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5725,
      "City District Name": "SIVAKASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626128,
      "TXT_PINCODE_LOCALITY": "THAYILPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626129,
      "TXT_PINCODE_LOCALITY": "TIRUCHULI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626130,
      "TXT_PINCODE_LOCALITY": "THIRUTHANGAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5725,
      "City District Name": "SIVAKASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626131,
      "TXT_PINCODE_LOCALITY": "VEMBAKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626132,
      "TXT_PINCODE_LOCALITY": "WATRAP",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626133,
      "TXT_PINCODE_LOCALITY": "KUMMAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626134,
      "TXT_PINCODE_LOCALITY": "CHETTIKURICHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626135,
      "TXT_PINCODE_LOCALITY": "KANNIRAJAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626136,
      "TXT_PINCODE_LOCALITY": "KILARAJAKULARAMAN",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626137,
      "TXT_PINCODE_LOCALITY": "RAJAPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5273,
      "City District Name": "RAJAPALAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626138,
      "TXT_PINCODE_LOCALITY": "KUNNUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626139,
      "TXT_PINCODE_LOCALITY": "CHOLAPURAM SOUTH",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626140,
      "TXT_PINCODE_LOCALITY": "SEVALPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626141,
      "TXT_PINCODE_LOCALITY": "MALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626142,
      "TXT_PINCODE_LOCALITY": "SUNDARANCHIAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6221,
      "City District Name": "VIRUDHUNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626143,
      "TXT_PINCODE_LOCALITY": "SOLAICHERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5273,
      "City District Name": "RAJAPALAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626144,
      "TXT_PINCODE_LOCALITY": "ALANGULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626145,
      "TXT_PINCODE_LOCALITY": "DEVADANAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626146,
      "TXT_PINCODE_LOCALITY": "ILANDAIKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626147,
      "TXT_PINCODE_LOCALITY": "MELPATTAMKARISALKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6221,
      "City District Name": "VIRUDHUNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626148,
      "TXT_PINCODE_LOCALITY": "MEENKASHIPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626149,
      "TXT_PINCODE_LOCALITY": "MAHARAJAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626150,
      "TXT_PINCODE_LOCALITY": "NACHIARKOIL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626151,
      "TXT_PINCODE_LOCALITY": "NATTAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626152,
      "TXT_PINCODE_LOCALITY": "KOPPUSITHAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626153,
      "TXT_PINCODE_LOCALITY": "SIVALAINGAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626154,
      "TXT_PINCODE_LOCALITY": "VAITHILINGAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626155,
      "TXT_PINCODE_LOCALITY": "NEERAVIKARISALKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626156,
      "TXT_PINCODE_LOCALITY": "S THARAIKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626157,
      "TXT_PINCODE_LOCALITY": "VELLAYAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6221,
      "City District Name": "VIRUDHUNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626158,
      "TXT_PINCODE_LOCALITY": "VILAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626159,
      "TXT_PINCODE_LOCALITY": "RAMASAMINAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6221,
      "City District Name": "VIRUDHUNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626160,
      "TXT_PINCODE_LOCALITY": "AVIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626161,
      "TXT_PINCODE_LOCALITY": "PULIYURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626162,
      "TXT_PINCODE_LOCALITY": "NARIPAIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6221,
      "City District Name": "VIRUDHUNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626163,
      "TXT_PINCODE_LOCALITY": "ZAMIN KOLLANGONDAN",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626164,
      "TXT_PINCODE_LOCALITY": "SETHUNARAYANAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626165,
      "TXT_PINCODE_LOCALITY": "ANUPPANKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626166,
      "TXT_PINCODE_LOCALITY": "VARAGANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6221,
      "City District Name": "VIRUDHUNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626167,
      "TXT_PINCODE_LOCALITY": "KOTTAIYUR GURUSWAMY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626168,
      "TXT_PINCODE_LOCALITY": "TERKU VENGANALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626169,
      "TXT_PINCODE_LOCALITY": "PUTHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626170,
      "TXT_PINCODE_LOCALITY": "KANSAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626171,
      "TXT_PINCODE_LOCALITY": "M PUDUPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626172,
      "TXT_PINCODE_LOCALITY": "MUDALIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626173,
      "TXT_PINCODE_LOCALITY": "VETRILAIYURANI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626175,
      "TXT_PINCODE_LOCALITY": "NALLAMANAICKENPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626176,
      "TXT_PINCODE_LOCALITY": "VISWANATHAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626177,
      "TXT_PINCODE_LOCALITY": "KAMARAJ DT",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5273,
      "City District Name": "RAJAPALAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626178,
      "TXT_PINCODE_LOCALITY": "KARISALKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626179,
      "TXT_PINCODE_LOCALITY": "MARANERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626180,
      "TXT_PINCODE_LOCALITY": "ANAIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626181,
      "TXT_PINCODE_LOCALITY": "SENGUNDAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626182,
      "TXT_PINCODE_LOCALITY": "PUDUKKOTTAI VNR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6221,
      "City District Name": "VIRUDHUNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626183,
      "TXT_PINCODE_LOCALITY": "VADHUVARPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6221,
      "City District Name": "VIRUDHUNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626184,
      "TXT_PINCODE_LOCALITY": "NARANAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626186,
      "TXT_PINCODE_LOCALITY": "VILLUPPANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626187,
      "TXT_PINCODE_LOCALITY": "IDIVILAGI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6221,
      "City District Name": "VIRUDHUNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626188,
      "TXT_PINCODE_LOCALITY": "DALAVAIPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626189,
      "TXT_PINCODE_LOCALITY": "SIVAKASI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5725,
      "City District Name": "SIVAKASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626190,
      "TXT_PINCODE_LOCALITY": "TACHCHAKKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5768,
      "City District Name": "SRIVILLIPUTHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626195,
      "TXT_PINCODE_LOCALITY": "MUSITTAKURICHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626201,
      "TXT_PINCODE_LOCALITY": "ELAYIRAMPANNAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626202,
      "TXT_PINCODE_LOCALITY": "NENMENI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626203,
      "TXT_PINCODE_LOCALITY": "SATTUR WEST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6221,
      "City District Name": "VIRUDHUNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626204,
      "TXT_PINCODE_LOCALITY": "RAMASAMYRAJANAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626205,
      "TXT_PINCODE_LOCALITY": "UPPATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626206,
      "TXT_PINCODE_LOCALITY": "IRUKKANGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626207,
      "TXT_PINCODE_LOCALITY": "KOIL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6221,
      "City District Name": "VIRUDHUNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626208,
      "TXT_PINCODE_LOCALITY": "THULAKKAPATTI R S",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626209,
      "TXT_PINCODE_LOCALITY": "AVADAYAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626210,
      "TXT_PINCODE_LOCALITY": "T SEDAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626211,
      "TXT_PINCODE_LOCALITY": "SURANGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626212,
      "TXT_PINCODE_LOCALITY": "PANAYADIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626213,
      "TXT_PINCODE_LOCALITY": "APPANAYAKKANPATTI (MDU)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626303,
      "TXT_PINCODE_LOCALITY": "VADAMALAIPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5553,
      "City District Name": "SATTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626501,
      "TXT_PINCODE_LOCALITY": "PERIYAKULAM EAST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626512,
      "TXT_PINCODE_LOCALITY": "AUNDIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626513,
      "TXT_PINCODE_LOCALITY": "BODINAYAKKANUR H O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626514,
      "TXT_PINCODE_LOCALITY": "CHECKANURANI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626515,
      "TXT_PINCODE_LOCALITY": "CHINNAMANUR EAST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626516,
      "TXT_PINCODE_LOCALITY": "GANDHI NAGAR CUMBUM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626517,
      "TXT_PINCODE_LOCALITY": "GANDAMANAYAKANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626518,
      "TXT_PINCODE_LOCALITY": "GUDALUR MADURAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626519,
      "TXT_PINCODE_LOCALITY": "HIGHWAYS",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626520,
      "TXT_PINCODE_LOCALITY": "KAMACHIPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626521,
      "TXT_PINCODE_LOCALITY": "KAMAYAGOUNDANPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626522,
      "TXT_PINCODE_LOCALITY": "KOMBAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626523,
      "TXT_PINCODE_LOCALITY": "PERIAKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626524,
      "TXT_PINCODE_LOCALITY": "PANNAIPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626525,
      "TXT_PINCODE_LOCALITY": "PERIYAR PROJECT",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626526,
      "TXT_PINCODE_LOCALITY": "ROYAPPANPATTHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626527,
      "TXT_PINCODE_LOCALITY": "SEDAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626528,
      "TXT_PINCODE_LOCALITY": "SILAMARATHUPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626529,
      "TXT_PINCODE_LOCALITY": "SINDUPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626530,
      "TXT_PINCODE_LOCALITY": "THAVARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626531,
      "TXT_PINCODE_LOCALITY": "THIAGARAJA NAGAR THENI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626532,
      "TXT_PINCODE_LOCALITY": "USLAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626533,
      "TXT_PINCODE_LOCALITY": "UTHAMAPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626534,
      "TXT_PINCODE_LOCALITY": "PERIYAKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626535,
      "TXT_PINCODE_LOCALITY": "YELUMALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626536,
      "TXT_PINCODE_LOCALITY": "T B SANATORIUM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626537,
      "TXT_PINCODE_LOCALITY": "UTHAPPANAYAKANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626538,
      "TXT_PINCODE_LOCALITY": "VENNIAR ESTATE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626539,
      "TXT_PINCODE_LOCALITY": "SURULIAR LOWER CAMP",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626540,
      "TXT_PINCODE_LOCALITY": "ODIAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626541,
      "TXT_PINCODE_LOCALITY": "U AMMAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626543,
      "TXT_PINCODE_LOCALITY": "BUDIPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626544,
      "TXT_PINCODE_LOCALITY": "CHINNAOVALAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626545,
      "TXT_PINCODE_LOCALITY": "DOMBUCHERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626546,
      "TXT_PINCODE_LOCALITY": "ERASAKKANAYAKKANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626547,
      "TXT_PINCODE_LOCALITY": "KODANGIPATTU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626548,
      "TXT_PINCODE_LOCALITY": "KODUVILARPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626549,
      "TXT_PINCODE_LOCALITY": "KOVILPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626550,
      "TXT_PINCODE_LOCALITY": "KUPPINAYAKKANPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626552,
      "TXT_PINCODE_LOCALITY": "MARKAYANKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626553,
      "TXT_PINCODE_LOCALITY": "PALANICHETTIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626554,
      "TXT_PINCODE_LOCALITY": "POOMALAIKUNDU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626555,
      "TXT_PINCODE_LOCALITY": "POTTIPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626556,
      "TXT_PINCODE_LOCALITY": "PUDUPATTI (CUMBUM)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626557,
      "TXT_PINCODE_LOCALITY": "RASINGAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626558,
      "TXT_PINCODE_LOCALITY": "SEEPALAKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626559,
      "TXT_PINCODE_LOCALITY": "SINDALACHERRY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626560,
      "TXT_PINCODE_LOCALITY": "SUKKANGALAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626561,
      "TXT_PINCODE_LOCALITY": "SURULIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626562,
      "TXT_PINCODE_LOCALITY": "TAMARAIKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626563,
      "TXT_PINCODE_LOCALITY": "THUMMAGUNDU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626564,
      "TXT_PINCODE_LOCALITY": "VALANADUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626565,
      "TXT_PINCODE_LOCALITY": "VELLAYAMMALPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626566,
      "TXT_PINCODE_LOCALITY": "CHELLANPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626567,
      "TXT_PINCODE_LOCALITY": "TADICHERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626568,
      "TXT_PINCODE_LOCALITY": "DODDAPPANAYAKKANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626569,
      "TXT_PINCODE_LOCALITY": "KOTTUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626571,
      "TXT_PINCODE_LOCALITY": "UPPUKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626572,
      "TXT_PINCODE_LOCALITY": "VENKATACHALAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626573,
      "TXT_PINCODE_LOCALITY": "KARUMATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626574,
      "TXT_PINCODE_LOCALITY": "JAGALPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626576,
      "TXT_PINCODE_LOCALITY": "MEGAMALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626578,
      "TXT_PINCODE_LOCALITY": "MYLADUMBARAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626579,
      "TXT_PINCODE_LOCALITY": "NARASINGAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626580,
      "TXT_PINCODE_LOCALITY": "SRIRANGAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626582,
      "TXT_PINCODE_LOCALITY": "ANAIKARAIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626601,
      "TXT_PINCODE_LOCALITY": "DEVADANAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626602,
      "TXT_PINCODE_LOCALITY": "VADUGAPATTI (RAMNAD)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626603,
      "TXT_PINCODE_LOCALITY": "VIRALIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626604,
      "TXT_PINCODE_LOCALITY": "YELUVARAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626605,
      "TXT_PINCODE_LOCALITY": "MELMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626606,
      "TXT_PINCODE_LOCALITY": "JEYAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626607,
      "TXT_PINCODE_LOCALITY": "GULLAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626612,
      "TXT_PINCODE_LOCALITY": "PERVELANGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6221,
      "City District Name": "VIRUDHUNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626701,
      "TXT_PINCODE_LOCALITY": "KALLIGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626702,
      "TXT_PINCODE_LOCALITY": "KALLUPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626703,
      "TXT_PINCODE_LOCALITY": "PERAIYUR MADURAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626704,
      "TXT_PINCODE_LOCALITY": "T PUDUPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626705,
      "TXT_PINCODE_LOCALITY": "SAPTUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626706,
      "TXT_PINCODE_LOCALITY": "TIRUMANGALAM SOUTH",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626707,
      "TXT_PINCODE_LOCALITY": "VILLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626708,
      "TXT_PINCODE_LOCALITY": "KUNNATHUR MADURAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626709,
      "TXT_PINCODE_LOCALITY": "PUDUNAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626710,
      "TXT_PINCODE_LOCALITY": "VELLAKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626711,
      "TXT_PINCODE_LOCALITY": "ATHIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626712,
      "TXT_PINCODE_LOCALITY": "TIRALI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626713,
      "TXT_PINCODE_LOCALITY": "SANDAIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626715,
      "TXT_PINCODE_LOCALITY": "KAPPALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626716,
      "TXT_PINCODE_LOCALITY": "M PULIYANGULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626717,
      "TXT_PINCODE_LOCALITY": "SENGAPADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626718,
      "TXT_PINCODE_LOCALITY": "KURAIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626719,
      "TXT_PINCODE_LOCALITY": "SATHANGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626720,
      "TXT_PINCODE_LOCALITY": "SILARPATTY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626721,
      "TXT_PINCODE_LOCALITY": "KUDISERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626722,
      "TXT_PINCODE_LOCALITY": "ALAPPACHERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626723,
      "TXT_PINCODE_LOCALITY": "MADIAPPANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 626724,
      "TXT_PINCODE_LOCALITY": "KOOTHIARGUNDU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627001,
      "TXT_PINCODE_LOCALITY": "VIRAGHAVAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627002,
      "TXT_PINCODE_LOCALITY": "PALAYANKOTTAI VADAKKU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627003,
      "TXT_PINCODE_LOCALITY": "VANNERPETTAI BAZAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627004,
      "TXT_PINCODE_LOCALITY": "TIRUNELVELI PETTAI EAST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627005,
      "TXT_PINCODE_LOCALITY": "MELAPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627006,
      "TXT_PINCODE_LOCALITY": "TIRUNEVELI BAZAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627007,
      "TXT_PINCODE_LOCALITY": "TIRUNELVELI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627008,
      "TXT_PINCODE_LOCALITY": "GANDHI NAGAR TIRUNELVELI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627009,
      "TXT_PINCODE_LOCALITY": "COLLECTORATE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627010,
      "TXT_PINCODE_LOCALITY": "INDUSTRIAL COLONY PETTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627011,
      "TXT_PINCODE_LOCALITY": "MAHARAJANAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627012,
      "TXT_PINCODE_LOCALITY": "MANONMANIYAM SUNDARNAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627051,
      "TXT_PINCODE_LOCALITY": "REDDIARPATTTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627101,
      "TXT_PINCODE_LOCALITY": "DHALAPATHISAMUDRAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627102,
      "TXT_PINCODE_LOCALITY": "DOHNAVUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627103,
      "TXT_PINCODE_LOCALITY": "ERUVADI TIRUNELVELI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627104,
      "TXT_PINCODE_LOCALITY": "IDINTHAKARAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627105,
      "TXT_PINCODE_LOCALITY": "PERUNGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627106,
      "TXT_PINCODE_LOCALITY": "KUNDANKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627107,
      "TXT_PINCODE_LOCALITY": "MAVADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627108,
      "TXT_PINCODE_LOCALITY": "NANGUNERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627109,
      "TXT_PINCODE_LOCALITY": "PANAGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627110,
      "TXT_PINCODE_LOCALITY": "PARAPPADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627111,
      "TXT_PINCODE_LOCALITY": "RADHAPURAM PANCHAYATH UNION",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 425850,
      "City District Name": "THIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627112,
      "TXT_PINCODE_LOCALITY": "SAMUGARENGAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627113,
      "TXT_PINCODE_LOCALITY": "TERKU KALLIKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627114,
      "TXT_PINCODE_LOCALITY": "TERKU KARUNGULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627115,
      "TXT_PINCODE_LOCALITY": "TIRUKARANGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627116,
      "TXT_PINCODE_LOCALITY": "VADAKKANKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627117,
      "TXT_PINCODE_LOCALITY": "VALLIOOR PERUNDU NILAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627118,
      "TXT_PINCODE_LOCALITY": "VIJAYARAYANAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627119,
      "TXT_PINCODE_LOCALITY": "VIJAYANARAYANAM NAVAL BASE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627120,
      "TXT_PINCODE_LOCALITY": "KOLIYANKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627121,
      "TXT_PINCODE_LOCALITY": "PALAVOOR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627122,
      "TXT_PINCODE_LOCALITY": "VIJAYAPATHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627123,
      "TXT_PINCODE_LOCALITY": "KOTTAIKARUNGULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627124,
      "TXT_PINCODE_LOCALITY": "SETTIKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627125,
      "TXT_PINCODE_LOCALITY": "ANAIKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627126,
      "TXT_PINCODE_LOCALITY": "KANNANALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627127,
      "TXT_PINCODE_LOCALITY": "KUTTAPULI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627128,
      "TXT_PINCODE_LOCALITY": "RAJAKKAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627129,
      "TXT_PINCODE_LOCALITY": "RAMAKRISHNAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627130,
      "TXT_PINCODE_LOCALITY": "TIRUVAMBLAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627131,
      "TXT_PINCODE_LOCALITY": "VEPPILANKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627132,
      "TXT_PINCODE_LOCALITY": "KOOTHANKUL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627133,
      "TXT_PINCODE_LOCALITY": "MAHENDRAGIRI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627140,
      "TXT_PINCODE_LOCALITY": "VARAGANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5495,
      "City District Name": "SANKARANKOIL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627151,
      "TXT_PINCODE_LOCALITY": "MARUTHAKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627152,
      "TXT_PINCODE_LOCALITY": "MUNRADAIPPU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627153,
      "TXT_PINCODE_LOCALITY": "KARANDANERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627154,
      "TXT_PINCODE_LOCALITY": "PANANKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627201,
      "TXT_PINCODE_LOCALITY": "MANUR TIRUNEVELI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627202,
      "TXT_PINCODE_LOCALITY": "UKKIRAMKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627210,
      "TXT_PINCODE_LOCALITY": "TULUKKARKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627214,
      "TXT_PINCODE_LOCALITY": "AYIRAPERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5872,
      "City District Name": "TENKASI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627251,
      "TXT_PINCODE_LOCALITY": "ELLANAICKENPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627252,
      "TXT_PINCODE_LOCALITY": "VALLANAD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627253,
      "TXT_PINCODE_LOCALITY": "KALIAVOOR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627254,
      "TXT_PINCODE_LOCALITY": "MURAPPANAD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627255,
      "TXT_PINCODE_LOCALITY": "SINGATHAKURICHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627301,
      "TXT_PINCODE_LOCALITY": "SEYDUNGANALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627302,
      "TXT_PINCODE_LOCALITY": "ANAVARADANALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627303,
      "TXT_PINCODE_LOCALITY": "VASAVAPPAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627304,
      "TXT_PINCODE_LOCALITY": "VITTILAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627305,
      "TXT_PINCODE_LOCALITY": "SIVANDIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627351,
      "TXT_PINCODE_LOCALITY": "BURKITMANAGARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627352,
      "TXT_PINCODE_LOCALITY": "GANGAIKONDAN",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627353,
      "TXT_PINCODE_LOCALITY": "KIZHANATHAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627354,
      "TXT_PINCODE_LOCALITY": "MULAKARIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627355,
      "TXT_PINCODE_LOCALITY": "MUNANJIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627356,
      "TXT_PINCODE_LOCALITY": "MUNNIRPALLAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627357,
      "TXT_PINCODE_LOCALITY": "TIRUNELVELI- SHANKAR-NAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627358,
      "TXT_PINCODE_LOCALITY": "TACHANALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627359,
      "TXT_PINCODE_LOCALITY": "RAJAVALLIPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627360,
      "TXT_PINCODE_LOCALITY": "TARUVAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627361,
      "TXT_PINCODE_LOCALITY": "SIVALAPERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627401,
      "TXT_PINCODE_LOCALITY": "AMBASAMUDRAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627412,
      "TXT_PINCODE_LOCALITY": "ALWAR KURICHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627413,
      "TXT_PINCODE_LOCALITY": "BRAHMADESAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627414,
      "TXT_PINCODE_LOCALITY": "CHERANMAHADEVI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627415,
      "TXT_PINCODE_LOCALITY": "KADAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627416,
      "TXT_PINCODE_LOCALITY": "KALLADAIKURICHI MARKET",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627417,
      "TXT_PINCODE_LOCALITY": "KARAIKURICHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627418,
      "TXT_PINCODE_LOCALITY": "KILAMBUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627419,
      "TXT_PINCODE_LOCALITY": "KODAYAR MELTHANGAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627420,
      "TXT_PINCODE_LOCALITY": "MANJOLAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627421,
      "TXT_PINCODE_LOCALITY": "MANIMUTHAR PROJECT",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627422,
      "TXT_PINCODE_LOCALITY": "PAPAVINASAM MILLS",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627423,
      "TXT_PINCODE_LOCALITY": "POTTALPUDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627424,
      "TXT_PINCODE_LOCALITY": "RAVANASAMUDRAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627425,
      "TXT_PINCODE_LOCALITY": "VIKRAMASINGAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627426,
      "TXT_PINCODE_LOCALITY": "VIRAVANALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627427,
      "TXT_PINCODE_LOCALITY": "NALUMUKKU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627428,
      "TXT_PINCODE_LOCALITY": "SIVASAILAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627430,
      "TXT_PINCODE_LOCALITY": "VELLANKULI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627431,
      "TXT_PINCODE_LOCALITY": "AVUDAYANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627432,
      "TXT_PINCODE_LOCALITY": "PAPPANKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627433,
      "TXT_PINCODE_LOCALITY": "SIVANDIPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627434,
      "TXT_PINCODE_LOCALITY": "ALADIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627435,
      "TXT_PINCODE_LOCALITY": "PODUKUDU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627436,
      "TXT_PINCODE_LOCALITY": "METTUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627451,
      "TXT_PINCODE_LOCALITY": "GOAPALASAMUDRAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627452,
      "TXT_PINCODE_LOCALITY": "MELASEVAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627453,
      "TXT_PINCODE_LOCALITY": "PATTUMADAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627476,
      "TXT_PINCODE_LOCALITY": "MANAPAARANALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2015,
      "City District Name": "AMBASAMUDRAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627501,
      "TXT_PINCODE_LOCALITY": "KALAKAD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627502,
      "TXT_PINCODE_LOCALITY": "DEVANALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627515,
      "TXT_PINCODE_LOCALITY": "TENNIRKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4721,
      "City District Name": "NANGUNERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627551,
      "TXT_PINCODE_LOCALITY": "PAPANASAM PYKARA COLONY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627554,
      "TXT_PINCODE_LOCALITY": "VANNICONENDAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5495,
      "City District Name": "SANKARANKOIL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627601,
      "TXT_PINCODE_LOCALITY": "MUKKUDAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627602,
      "TXT_PINCODE_LOCALITY": "PAPAGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627603,
      "TXT_PINCODE_LOCALITY": "VADAKKU ARIYANAGI PURAMPETTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627604,
      "TXT_PINCODE_LOCALITY": "SUTTAMALLY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627651,
      "TXT_PINCODE_LOCALITY": "KOODUTHALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627652,
      "TXT_PINCODE_LOCALITY": "VIJAYA ACHAMPADU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627654,
      "TXT_PINCODE_LOCALITY": "PETTAIKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627657,
      "TXT_PINCODE_LOCALITY": "TISAYANVILLAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627659,
      "TXT_PINCODE_LOCALITY": "KARAICHUTHUPUDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627701,
      "TXT_PINCODE_LOCALITY": "KOVILPATTI TOWN",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627702,
      "TXT_PINCODE_LOCALITY": "PERUNDUNILAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627706,
      "TXT_PINCODE_LOCALITY": "KATHUR PUVALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627712,
      "TXT_PINCODE_LOCALITY": "EPPODUMVENDRAN",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627713,
      "TXT_PINCODE_LOCALITY": "ILAYASASANENDAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627714,
      "TXT_PINCODE_LOCALITY": "KADAMBUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2107,
      "City District Name": "ARIYALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627716,
      "TXT_PINCODE_LOCALITY": "NALLATINPUTHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627717,
      "TXT_PINCODE_LOCALITY": "PARIVALLIKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627718,
      "TXT_PINCODE_LOCALITY": "PASUVANTHANAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627719,
      "TXT_PINCODE_LOCALITY": "TIRUVENGADAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627720,
      "TXT_PINCODE_LOCALITY": "TURAIYUR KOITPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627721,
      "TXT_PINCODE_LOCALITY": "VANARMUTTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627722,
      "TXT_PINCODE_LOCALITY": "KURUKKUCHALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627723,
      "TXT_PINCODE_LOCALITY": "ILAVENGAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627724,
      "TXT_PINCODE_LOCALITY": "KALINGAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627725,
      "TXT_PINCODE_LOCALITY": "KADALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627726,
      "TXT_PINCODE_LOCALITY": "SIVAGNANAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627727,
      "TXT_PINCODE_LOCALITY": "EAST PANDAVARMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627738,
      "TXT_PINCODE_LOCALITY": "MARUDANKINAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5495,
      "City District Name": "SANKARANKOIL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627750,
      "TXT_PINCODE_LOCALITY": "VADIKKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5495,
      "City District Name": "SANKARANKOIL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627751,
      "TXT_PINCODE_LOCALITY": "KADAYANALLUR PETTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627752,
      "TXT_PINCODE_LOCALITY": "KALUGUMALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627753,
      "TXT_PINCODE_LOCALITY": "KARIVALAM VANDANALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627754,
      "TXT_PINCODE_LOCALITY": "KURUVIKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627755,
      "TXT_PINCODE_LOCALITY": "MULLIKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627756,
      "TXT_PINCODE_LOCALITY": "SANKARANKOIL EAST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627757,
      "TXT_PINCODE_LOCALITY": "SIVAGIRI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5724,
      "City District Name": "SIVAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627758,
      "TXT_PINCODE_LOCALITY": "VASUDEVANALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627759,
      "TXT_PINCODE_LOCALITY": "KRISHNAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627760,
      "TXT_PINCODE_LOCALITY": "GUDALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627761,
      "TXT_PINCODE_LOCALITY": "PANNAIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627762,
      "TXT_PINCODE_LOCALITY": "VADAMALAIPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627763,
      "TXT_PINCODE_LOCALITY": "VISWANATHAPURI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627764,
      "TXT_PINCODE_LOCALITY": "ROYAGIRI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627765,
      "TXT_PINCODE_LOCALITY": "CHOKKAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627766,
      "TXT_PINCODE_LOCALITY": "PERUNGOTTUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627767,
      "TXT_PINCODE_LOCALITY": "SOKKANATHAMPUDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627768,
      "TXT_PINCODE_LOCALITY": "NELLAKATTUMSEVAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627769,
      "TXT_PINCODE_LOCALITY": "PERUMBATTUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627770,
      "TXT_PINCODE_LOCALITY": "TENMALALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627771,
      "TXT_PINCODE_LOCALITY": "MANALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627773,
      "TXT_PINCODE_LOCALITY": "THAIAIVANKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627774,
      "TXT_PINCODE_LOCALITY": "SAYAMALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627801,
      "TXT_PINCODE_LOCALITY": "ACHANPUTHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627802,
      "TXT_PINCODE_LOCALITY": "KASIMAJORPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627803,
      "TXT_PINCODE_LOCALITY": "ELATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627804,
      "TXT_PINCODE_LOCALITY": "IDAIKAL (TENKASI)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627805,
      "TXT_PINCODE_LOCALITY": "ILANJI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627806,
      "TXT_PINCODE_LOCALITY": "KILAPAVUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627807,
      "TXT_PINCODE_LOCALITY": "PANPULLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627808,
      "TXT_PINCODE_LOCALITY": "PAVOORCHATRAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627809,
      "TXT_PINCODE_LOCALITY": "SHENCOTTAH",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627810,
      "TXT_PINCODE_LOCALITY": "SHENCOTTAH R S",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627811,
      "TXT_PINCODE_LOCALITY": "TENKASI BUS STAND",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627812,
      "TXT_PINCODE_LOCALITY": "VADAKARAI KOILPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627813,
      "TXT_PINCODE_LOCALITY": "PULIYARA",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627814,
      "TXT_PINCODE_LOCALITY": "KIZHAPULIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627815,
      "TXT_PINCODE_LOCALITY": "SIVANADANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627816,
      "TXT_PINCODE_LOCALITY": "KANAKKAPILLAIVALASI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627817,
      "TXT_PINCODE_LOCALITY": "VALLAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627818,
      "TXT_PINCODE_LOCALITY": "MELAGARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627819,
      "TXT_PINCODE_LOCALITY": "PEDDANADARPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627820,
      "TXT_PINCODE_LOCALITY": "NAINARAGARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627821,
      "TXT_PINCODE_LOCALITY": "IDAIKAL AMBASAMUDRAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627822,
      "TXT_PINCODE_LOCALITY": "RAJAPANDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627823,
      "TXT_PINCODE_LOCALITY": "VELLAKKAL - 1",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627851,
      "TXT_PINCODE_LOCALITY": "ALANGULAM (TVL)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627852,
      "TXT_PINCODE_LOCALITY": "AYIKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627853,
      "TXT_PINCODE_LOCALITY": "NALLUR TENKASI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627854,
      "TXT_PINCODE_LOCALITY": "NETTUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627855,
      "TXT_PINCODE_LOCALITY": "CHINTAMANI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627856,
      "TXT_PINCODE_LOCALITY": "SAMBURVADAKARAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627857,
      "TXT_PINCODE_LOCALITY": "SERNDAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627858,
      "TXT_PINCODE_LOCALITY": "SUNDARAPANDIAPURAM (TVL)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627859,
      "TXT_PINCODE_LOCALITY": "SURANDAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627860,
      "TXT_PINCODE_LOCALITY": "UTHUMALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627861,
      "TXT_PINCODE_LOCALITY": "KEELAVEERANAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627862,
      "TXT_PINCODE_LOCALITY": "VIRASIGAMANI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627863,
      "TXT_PINCODE_LOCALITY": "PAMBAKOVIL SHANDY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627864,
      "TXT_PINCODE_LOCALITY": "PUNNIAHPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627866,
      "TXT_PINCODE_LOCALITY": "VEERAPANDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627867,
      "TXT_PINCODE_LOCALITY": "VENKATESWARAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627901,
      "TXT_PINCODE_LOCALITY": "ARIYANAYAGIPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627902,
      "TXT_PINCODE_LOCALITY": "NADUVISIPATLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627903,
      "TXT_PINCODE_LOCALITY": "KULATHUR TIRUNELVELI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627904,
      "TXT_PINCODE_LOCALITY": "NAGALAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627905,
      "TXT_PINCODE_LOCALITY": "PUDUR TIRUNELVELI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627906,
      "TXT_PINCODE_LOCALITY": "VEMBATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627907,
      "TXT_PINCODE_LOCALITY": "VILATHIKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627908,
      "TXT_PINCODE_LOCALITY": "KILA IRAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627909,
      "TXT_PINCODE_LOCALITY": "VEPPALODAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627910,
      "TXT_PINCODE_LOCALITY": "PILLAYARANATHAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627911,
      "TXT_PINCODE_LOCALITY": "PERILOVANPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627912,
      "TXT_PINCODE_LOCALITY": "RAMACHANDRAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627951,
      "TXT_PINCODE_LOCALITY": "DEVARKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627952,
      "TXT_PINCODE_LOCALITY": "KAYATAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627953,
      "TXT_PINCODE_LOCALITY": "MELANILIDANALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627954,
      "TXT_PINCODE_LOCALITY": "VANNIKONENDAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627955,
      "TXT_PINCODE_LOCALITY": "KURUKKALPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627956,
      "TXT_PINCODE_LOCALITY": "PANAVADALICHATRAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 627957,
      "TXT_PINCODE_LOCALITY": "KILNILIDANALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628001,
      "TXT_PINCODE_LOCALITY": "TUTICORIN KELUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628002,
      "TXT_PINCODE_LOCALITY": "GREAT COTTON ROAD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628003,
      "TXT_PINCODE_LOCALITY": "TUTICORIN",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1893,
      "City District Name": "TUTICORIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628004,
      "TXT_PINCODE_LOCALITY": "TUTICORIN",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1893,
      "City District Name": "TUTICORIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628005,
      "TXT_PINCODE_LOCALITY": "MUTHIAHPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1893,
      "City District Name": "TUTICORIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628006,
      "TXT_PINCODE_LOCALITY": "THERMALNGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628007,
      "TXT_PINCODE_LOCALITY": "HEAVY WATER PROJECT COLONY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628008,
      "TXT_PINCODE_LOCALITY": "TUTICORIN",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1893,
      "City District Name": "TUTICORIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628051,
      "TXT_PINCODE_LOCALITY": "ATHIMARATHUPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628101,
      "TXT_PINCODE_LOCALITY": "KORAMPALLAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628102,
      "TXT_PINCODE_LOCALITY": "MUDITTANENDAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628103,
      "TXT_PINCODE_LOCALITY": "PUDUKKOTTAI TVL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628104,
      "TXT_PINCODE_LOCALITY": "SEEKARAKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628105,
      "TXT_PINCODE_LOCALITY": "TARUVAIKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1893,
      "City District Name": "TUTICORIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628151,
      "TXT_PINCODE_LOCALITY": "TUTICORIN",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1893,
      "City District Name": "TUTICORIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628152,
      "TXT_PINCODE_LOCALITY": "PAZHAYAKAYAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628153,
      "TXT_PINCODE_LOCALITY": "MELAUTOOR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628154,
      "TXT_PINCODE_LOCALITY": "MUKKANI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628155,
      "TXT_PINCODE_LOCALITY": "PUNNAKAYAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628201,
      "TXT_PINCODE_LOCALITY": "AMMANPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628202,
      "TXT_PINCODE_LOCALITY": "ARUMUGANERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628203,
      "TXT_PINCODE_LOCALITY": "CHRISTIANAGARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628204,
      "TXT_PINCODE_LOCALITY": "KAYALPATNAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628205,
      "TXT_PINCODE_LOCALITY": "KAYAMOLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628206,
      "TXT_PINCODE_LOCALITY": "KULASEKHARAPATNAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628207,
      "TXT_PINCODE_LOCALITY": "KURUMBUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628208,
      "TXT_PINCODE_LOCALITY": "MANADU THANDUPATTU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628209,
      "TXT_PINCODE_LOCALITY": "MANAPPAD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628210,
      "TXT_PINCODE_LOCALITY": "MEGNANAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628211,
      "TXT_PINCODE_LOCALITY": "NALUMAVADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628212,
      "TXT_PINCODE_LOCALITY": "PANICKANADARKUDIYIRUPPU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1893,
      "City District Name": "TUTICORIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628213,
      "TXT_PINCODE_LOCALITY": "PARAMANKURICHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628214,
      "TXT_PINCODE_LOCALITY": "PURAIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628215,
      "TXT_PINCODE_LOCALITY": "TIRUCHENDUR H O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628216,
      "TXT_PINCODE_LOCALITY": "VIRAPANDIANPATTINAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628217,
      "TXT_PINCODE_LOCALITY": "ADAIKALAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628218,
      "TXT_PINCODE_LOCALITY": "MANNANVILAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628219,
      "TXT_PINCODE_LOCALITY": "VELLALANVILAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628220,
      "TXT_PINCODE_LOCALITY": "NATHANKINAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628221,
      "TXT_PINCODE_LOCALITY": "ALANTALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628222,
      "TXT_PINCODE_LOCALITY": "MELATIRUCHENDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628223,
      "TXT_PINCODE_LOCALITY": "PEYANVILAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628224,
      "TXT_PINCODE_LOCALITY": "SONAGANVILAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628225,
      "TXT_PINCODE_LOCALITY": "KANAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628226,
      "TXT_PINCODE_LOCALITY": "PALLIPATTU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628227,
      "TXT_PINCODE_LOCALITY": "VIRAMANICKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628228,
      "TXT_PINCODE_LOCALITY": "THERI KUDIYIRUPPU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628229,
      "TXT_PINCODE_LOCALITY": "SAHUPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1893,
      "City District Name": "TUTICORIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628251,
      "TXT_PINCODE_LOCALITY": "SAWYERPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628252,
      "TXT_PINCODE_LOCALITY": "SUBRAMANIAPURAM TVL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628253,
      "TXT_PINCODE_LOCALITY": "KALIYAVUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5767,
      "City District Name": "SRIVAIKUNTAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628301,
      "TXT_PINCODE_LOCALITY": "KALISAPURAM (TUTICORIN)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628302,
      "TXT_PINCODE_LOCALITY": "OTTANATHAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628303,
      "TXT_PINCODE_LOCALITY": "NARAIKINAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628304,
      "TXT_PINCODE_LOCALITY": "TATTAPARAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628305,
      "TXT_PINCODE_LOCALITY": "MANIYACHI R S",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628306,
      "TXT_PINCODE_LOCALITY": "PULIAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628307,
      "TXT_PINCODE_LOCALITY": "MARUDANVALAVOO",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628308,
      "TXT_PINCODE_LOCALITY": "PUVANI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628309,
      "TXT_PINCODE_LOCALITY": "AKKANAICKENPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628401,
      "TXT_PINCODE_LOCALITY": "OTTAPIDARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628402,
      "TXT_PINCODE_LOCALITY": "PUDIYAMPUTHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628501,
      "TXT_PINCODE_LOCALITY": "KOVILPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1893,
      "City District Name": "TUTICORIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628502,
      "TXT_PINCODE_LOCALITY": "LAKSHMIPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1893,
      "City District Name": "TUTICORIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628503,
      "TXT_PINCODE_LOCALITY": "PADANTHAPULI 2",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1893,
      "City District Name": "TUTICORIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628507,
      "TXT_PINCODE_LOCALITY": "MADURAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628509,
      "TXT_PINCODE_LOCALITY": "M.SHANMUGAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6212,
      "City District Name": "VILATHIKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628513,
      "TXT_PINCODE_LOCALITY": "MURLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628552,
      "TXT_PINCODE_LOCALITY": "TIRUNELVELI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628601,
      "TXT_PINCODE_LOCALITY": "SHRIVAIKUNTAM H O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628612,
      "TXT_PINCODE_LOCALITY": "ALWAR TINUNAGARI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628613,
      "TXT_PINCODE_LOCALITY": "TUTICORIN",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1893,
      "City District Name": "TUTICORIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628614,
      "TXT_PINCODE_LOCALITY": "CAMPELLABAD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628615,
      "TXT_PINCODE_LOCALITY": "KARUNKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628616,
      "TXT_PINCODE_LOCALITY": "MUKUPERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628617,
      "TXT_PINCODE_LOCALITY": "NAZERATH",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1893,
      "City District Name": "TUTICORIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628618,
      "TXT_PINCODE_LOCALITY": "NEIVILAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628619,
      "TXT_PINCODE_LOCALITY": "PADMANABHAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628620,
      "TXT_PINCODE_LOCALITY": "PATEMANGARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628621,
      "TXT_PINCODE_LOCALITY": "PUTHUKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628622,
      "TXT_PINCODE_LOCALITY": "THTHANKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628623,
      "TXT_PINCODE_LOCALITY": "THENTHIRUPERAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628624,
      "TXT_PINCODE_LOCALITY": "KONGARAYAKURICHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628625,
      "TXT_PINCODE_LOCALITY": "ARAMPANNAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628626,
      "TXT_PINCODE_LOCALITY": "KATCHANVILAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628627,
      "TXT_PINCODE_LOCALITY": "CHERAKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628628,
      "TXT_PINCODE_LOCALITY": "PATTAKARAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628629,
      "TXT_PINCODE_LOCALITY": "KARUNKADAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628630,
      "TXT_PINCODE_LOCALITY": "MIRANKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628631,
      "TXT_PINCODE_LOCALITY": "VALLAKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628651,
      "TXT_PINCODE_LOCALITY": "IDAIYANKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628652,
      "TXT_PINCODE_LOCALITY": "ITTAMOZHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628653,
      "TXT_PINCODE_LOCALITY": "KOMMADIKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628654,
      "TXT_PINCODE_LOCALITY": "PETTAIKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628655,
      "TXT_PINCODE_LOCALITY": "PUCHIKADU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628656,
      "TXT_PINCODE_LOCALITY": "PUTHANHARUVAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628657,
      "TXT_PINCODE_LOCALITY": "TISAIYANVILAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628658,
      "TXT_PINCODE_LOCALITY": "UVARI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628659,
      "TXT_PINCODE_LOCALITY": "KARAICHITTUPUDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628660,
      "TXT_PINCODE_LOCALITY": "KUTTAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628661,
      "TXT_PINCODE_LOCALITY": "POLAYARPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628662,
      "TXT_PINCODE_LOCALITY": "THATTARMADAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628663,
      "TXT_PINCODE_LOCALITY": "NADUVAKURICHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628701,
      "TXT_PINCODE_LOCALITY": "ANANDAPURAM (TVL)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628702,
      "TXT_PINCODE_LOCALITY": "MUDALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628703,
      "TXT_PINCODE_LOCALITY": "PADUKAPATTU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628704,
      "TXT_PINCODE_LOCALITY": "SATTANKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628705,
      "TXT_PINCODE_LOCALITY": "PEROUA THALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628706,
      "TXT_PINCODE_LOCALITY": "KATTARIMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628707,
      "TXT_PINCODE_LOCALITY": "AMBINAGARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628712,
      "TXT_PINCODE_LOCALITY": "SAKKAMMALPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1893,
      "City District Name": "TUTICORIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628714,
      "TXT_PINCODE_LOCALITY": "KADAMBUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1893,
      "City District Name": "TUTICORIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628716,
      "TXT_PINCODE_LOCALITY": "VILLISERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1893,
      "City District Name": "TUTICORIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628717,
      "TXT_PINCODE_LOCALITY": "KOTTALI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 442976,
      "City District Name": "OTTAPIDARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628718,
      "TXT_PINCODE_LOCALITY": "PASUVANDANAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1893,
      "City District Name": "TUTICORIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628720,
      "TXT_PINCODE_LOCALITY": "SEMMAPUDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1893,
      "City District Name": "TUTICORIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628721,
      "TXT_PINCODE_LOCALITY": "ZAMINDEVARKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1893,
      "City District Name": "TUTICORIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628722,
      "TXT_PINCODE_LOCALITY": "MELASEITHALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1893,
      "City District Name": "TUTICORIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628723,
      "TXT_PINCODE_LOCALITY": "ONAMAKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 442976,
      "City District Name": "OTTAPIDARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628725,
      "TXT_PINCODE_LOCALITY": "URULAIKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3119,
      "City District Name": "ETTAYAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628726,
      "TXT_PINCODE_LOCALITY": "SIVAGNANAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6212,
      "City District Name": "VILATHIKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628751,
      "TXT_PINCODE_LOCALITY": "PANNAIVILAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628752,
      "TXT_PINCODE_LOCALITY": "PERUNGULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628753,
      "TXT_PINCODE_LOCALITY": "SIVAGALALI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628801,
      "TXT_PINCODE_LOCALITY": "ERAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628802,
      "TXT_PINCODE_LOCALITY": "SIRUTHONDANALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628803,
      "TXT_PINCODE_LOCALITY": "VALAVALLAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628806,
      "TXT_PINCODE_LOCALITY": "VASAVAPPAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5767,
      "City District Name": "SRIVAIKUNTAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628809,
      "TXT_PINCODE_LOCALITY": "VITTILAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1893,
      "City District Name": "TUTICORIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628851,
      "TXT_PINCODE_LOCALITY": "ELLANAYAKANPATTY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1893,
      "City District Name": "TUTICORIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628852,
      "TXT_PINCODE_LOCALITY": "ALIKKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5767,
      "City District Name": "SRIVAIKUNTAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628858,
      "TXT_PINCODE_LOCALITY": "VADAKKUKARISERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5767,
      "City District Name": "SRIVAIKUNTAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628901,
      "TXT_PINCODE_LOCALITY": "ARIYANAYAGIPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1893,
      "City District Name": "TUTICORIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628902,
      "TXT_PINCODE_LOCALITY": "ETTAIYAPURAM S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1893,
      "City District Name": "TUTICORIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628903,
      "TXT_PINCODE_LOCALITY": "SIPPIKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1893,
      "City District Name": "TUTICORIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628904,
      "TXT_PINCODE_LOCALITY": "NAGALAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1893,
      "City District Name": "TUTICORIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628905,
      "TXT_PINCODE_LOCALITY": "PUDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1893,
      "City District Name": "TUTICORIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628906,
      "TXT_PINCODE_LOCALITY": "VEMBAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1893,
      "City District Name": "TUTICORIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628907,
      "TXT_PINCODE_LOCALITY": "MEENAKSHIPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1893,
      "City District Name": "TUTICORIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628908,
      "TXT_PINCODE_LOCALITY": "SHOLPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1893,
      "City District Name": "TUTICORIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628911,
      "TXT_PINCODE_LOCALITY": "V PUDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6212,
      "City District Name": "VILATHIKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628951,
      "TXT_PINCODE_LOCALITY": "USILANKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4107,
      "City District Name": "KOVILPATTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 628952,
      "TXT_PINCODE_LOCALITY": "KAYATHAR S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1893,
      "City District Name": "TUTICORIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629001,
      "TXT_PINCODE_LOCALITY": "NAGERCOIL TOWN",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1880,
      "City District Name": "KANYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629002,
      "TXT_PINCODE_LOCALITY": "VADIVEESWARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1880,
      "City District Name": "KANYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629003,
      "TXT_PINCODE_LOCALITY": "VETTURANIMADAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629004,
      "TXT_PINCODE_LOCALITY": "NAGERCOIL IND ESTATE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629051,
      "TXT_PINCODE_LOCALITY": "EDALAKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629101,
      "TXT_PINCODE_LOCALITY": "KALIYAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629102,
      "TXT_PINCODE_LOCALITY": "KODAYAR KILTHANGAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629103,
      "TXT_PINCODE_LOCALITY": "ALANCHOLAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1890,
      "City District Name": "TIRUNELVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629151,
      "TXT_PINCODE_LOCALITY": "ARUMANA - 2066",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629152,
      "TXT_PINCODE_LOCALITY": "IDAIKODE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629153,
      "TXT_PINCODE_LOCALITY": "KALIAKKAVILAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629154,
      "TXT_PINCODE_LOCALITY": "KANJAMPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629155,
      "TXT_PINCODE_LOCALITY": "KANJIRACODE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629156,
      "TXT_PINCODE_LOCALITY": "KAPPIYARA",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629157,
      "TXT_PINCODE_LOCALITY": "KARINKAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629158,
      "TXT_PINCODE_LOCALITY": "KATTATHURAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629159,
      "TXT_PINCODE_LOCALITY": "KIZIHMIDALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1880,
      "City District Name": "KANYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629160,
      "TXT_PINCODE_LOCALITY": "MARTHANDOM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1880,
      "City District Name": "KANYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629161,
      "TXT_PINCODE_LOCALITY": "KULASEKHARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1880,
      "City District Name": "KANYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629162,
      "TXT_PINCODE_LOCALITY": "KUNNATHUR NAGERCOIL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629163,
      "TXT_PINCODE_LOCALITY": "KUZHITHURA",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629164,
      "TXT_PINCODE_LOCALITY": "KANYAKUMARI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1880,
      "City District Name": "KANYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629165,
      "TXT_PINCODE_LOCALITY": "MARTHANDAM BRIDGE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1880,
      "City District Name": "KANYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629166,
      "TXT_PINCODE_LOCALITY": "MEKKAMANDAPAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629167,
      "TXT_PINCODE_LOCALITY": "ALAGIMANDAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629168,
      "TXT_PINCODE_LOCALITY": "PACODE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629169,
      "TXT_PINCODE_LOCALITY": "PALLIYADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629170,
      "TXT_PINCODE_LOCALITY": "PAZHAVOOR VILLAGE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1880,
      "City District Name": "KANYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629171,
      "TXT_PINCODE_LOCALITY": "PUDUKADA - 3853",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629172,
      "TXT_PINCODE_LOCALITY": "S T MANGAD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629173,
      "TXT_PINCODE_LOCALITY": "THENGAPATTINAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629174,
      "TXT_PINCODE_LOCALITY": "TIRUVITHAMCODE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629175,
      "TXT_PINCODE_LOCALITY": "THUCKALAY H O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629176,
      "TXT_PINCODE_LOCALITY": "THUTHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629177,
      "TXT_PINCODE_LOCALITY": "TIRUVATTOR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629178,
      "TXT_PINCODE_LOCALITY": "UDAYAMARTHANDAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629179,
      "TXT_PINCODE_LOCALITY": "UNNAMALAIKADA",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629180,
      "TXT_PINCODE_LOCALITY": "VILLUKURI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629181,
      "TXT_PINCODE_LOCALITY": "KIRATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629182,
      "TXT_PINCODE_LOCALITY": "PONMANA",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629183,
      "TXT_PINCODE_LOCALITY": "THIRUVARAMBU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629185,
      "TXT_PINCODE_LOCALITY": "MARTHANDAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6310,
      "City District Name": "MARTHANDAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629186,
      "TXT_PINCODE_LOCALITY": "MARTHANDAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6310,
      "City District Name": "MARTHANDAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629187,
      "TXT_PINCODE_LOCALITY": "KILLIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629188,
      "TXT_PINCODE_LOCALITY": "PAINKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629189,
      "TXT_PINCODE_LOCALITY": "KUMARAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629190,
      "TXT_PINCODE_LOCALITY": "SOORIACODE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629191,
      "TXT_PINCODE_LOCALITY": "ATUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1880,
      "City District Name": "KANYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629192,
      "TXT_PINCODE_LOCALITY": "VALLAVILAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1880,
      "City District Name": "KANYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629193,
      "TXT_PINCODE_LOCALITY": "KIZHKULLAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1880,
      "City District Name": "KANYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629194,
      "TXT_PINCODE_LOCALITY": "MARUTHANCODE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 442978,
      "City District Name": "VILAVANCODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629195,
      "TXT_PINCODE_LOCALITY": "NATTALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629196,
      "TXT_PINCODE_LOCALITY": "MANGARAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629197,
      "TXT_PINCODE_LOCALITY": "IRENIPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1880,
      "City District Name": "KANYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629199,
      "TXT_PINCODE_LOCALITY": "KALIYAKKAVILAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1880,
      "City District Name": "KANYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629201,
      "TXT_PINCODE_LOCALITY": "ASARIPALLAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629202,
      "TXT_PINCODE_LOCALITY": "MOTTOM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629203,
      "TXT_PINCODE_LOCALITY": "FRIDAY MARKET",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629204,
      "TXT_PINCODE_LOCALITY": "AMMANDIVILAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629205,
      "TXT_PINCODE_LOCALITY": "SANTHAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629251,
      "TXT_PINCODE_LOCALITY": "COLACHAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629252,
      "TXT_PINCODE_LOCALITY": "MANAVALAKURICHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629253,
      "TXT_PINCODE_LOCALITY": "KADIYAPATTINAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629301,
      "TXT_PINCODE_LOCALITY": "ARAMBOLY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629302,
      "TXT_PINCODE_LOCALITY": "THOVALAI S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1880,
      "City District Name": "KANYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629303,
      "TXT_PINCODE_LOCALITY": "RAMAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629304,
      "TXT_PINCODE_LOCALITY": "CHENBAGARAMANPUDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1880,
      "City District Name": "KANYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629305,
      "TXT_PINCODE_LOCALITY": "VELLAMADAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629401,
      "TXT_PINCODE_LOCALITY": "AZHAGAPPAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629402,
      "TXT_PINCODE_LOCALITY": "MARUNKUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629403,
      "TXT_PINCODE_LOCALITY": "MYLADY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629404,
      "TXT_PINCODE_LOCALITY": "VARIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629406,
      "TXT_PINCODE_LOCALITY": "KULASEKARAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629501,
      "TXT_PINCODE_LOCALITY": "EATHAMOZHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629502,
      "TXT_PINCODE_LOCALITY": "GANAPATHIPURAM NAGERCOIL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629503,
      "TXT_PINCODE_LOCALITY": "RAJAKKAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629504,
      "TXT_PINCODE_LOCALITY": "JAMES TOWN",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629509,
      "TXT_PINCODE_LOCALITY": "KESAVAPUTHANTHURAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629517,
      "TXT_PINCODE_LOCALITY": "GANAPATHIPURAM (NGL)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629601,
      "TXT_PINCODE_LOCALITY": "PARAKKAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629602,
      "TXT_PINCODE_LOCALITY": "PUTHALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629603,
      "TXT_PINCODE_LOCALITY": "THENGAMPUTHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629664,
      "TXT_PINCODE_LOCALITY": "IDAIVCHIVALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629701,
      "TXT_PINCODE_LOCALITY": "AGASTEESWARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629702,
      "TXT_PINCODE_LOCALITY": "VIVEKANANDAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1880,
      "City District Name": "KANYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629703,
      "TXT_PINCODE_LOCALITY": "KOTTARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629704,
      "TXT_PINCODE_LOCALITY": "VALAKKAMPARAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1880,
      "City District Name": "KANYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629706,
      "TXT_PINCODE_LOCALITY": "MAHADANAPURAM K K",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629707,
      "TXT_PINCODE_LOCALITY": "THAMARAIKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629708,
      "TXT_PINCODE_LOCALITY": "SOUTH THAMARAIKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1880,
      "City District Name": "KANYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629801,
      "TXT_PINCODE_LOCALITY": "ALOOR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629802,
      "TXT_PINCODE_LOCALITY": "NEYYOOR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629803,
      "TXT_PINCODE_LOCALITY": "NEYYOOR WEST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629804,
      "TXT_PINCODE_LOCALITY": "THIKKANAMCODE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1880,
      "City District Name": "KANYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629805,
      "TXT_PINCODE_LOCALITY": "KURUNTHENCODE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629806,
      "TXT_PINCODE_LOCALITY": "KUTTIMANGODE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1880,
      "City District Name": "KANYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629807,
      "TXT_PINCODE_LOCALITY": "CHUNKANKADAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1880,
      "City District Name": "KANYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629808,
      "TXT_PINCODE_LOCALITY": "KALLUKATTAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629809,
      "TXT_PINCODE_LOCALITY": "KARANKAD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629810,
      "TXT_PINCODE_LOCALITY": "KANDANVILAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1880,
      "City District Name": "KANYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629851,
      "TXT_PINCODE_LOCALITY": "AZHAKIAPANDIAPURAM DARSAN",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629852,
      "TXT_PINCODE_LOCALITY": "BHOOTHAPANDY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629853,
      "TXT_PINCODE_LOCALITY": "ARUMANALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629854,
      "TXT_PINCODE_LOCALITY": "KIRIPARAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1880,
      "City District Name": "KANYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629855,
      "TXT_PINCODE_LOCALITY": "KADUKKARAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629901,
      "TXT_PINCODE_LOCALITY": "THAZHAKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 629902,
      "TXT_PINCODE_LOCALITY": "ERACHAKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630001,
      "TXT_PINCODE_LOCALITY": "KARAIKUDI BUS STAND",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630002,
      "TXT_PINCODE_LOCALITY": "KARAIKUDI SOUTHSTREET S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630003,
      "TXT_PINCODE_LOCALITY": "KARAIKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630004,
      "TXT_PINCODE_LOCALITY": "AE.COLLEGE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630005,
      "TXT_PINCODE_LOCALITY": "DEVAKOTTAI ROAD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630006,
      "TXT_PINCODE_LOCALITY": "CECRI CAMPUS S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630101,
      "TXT_PINCODE_LOCALITY": "ATTANGUDI S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630102,
      "TXT_PINCODE_LOCALITY": "CHETTINAD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630103,
      "TXT_PINCODE_LOCALITY": "KANADUKATHAN S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630104,
      "TXT_PINCODE_LOCALITY": "KANDANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630105,
      "TXT_PINCODE_LOCALITY": "KOTHAMANGALAM S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630106,
      "TXT_PINCODE_LOCALITY": "KOTTAIYUR S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630107,
      "TXT_PINCODE_LOCALITY": "AC.SCHOOL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630108,
      "TXT_PINCODE_LOCALITY": "PUDUVAYAL S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630201,
      "TXT_PINCODE_LOCALITY": "A.THEKKUR S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630202,
      "TXT_PINCODE_LOCALITY": "ILLUPPAKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630203,
      "TXT_PINCODE_LOCALITY": "KANDAVARAYANPATTI S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630204,
      "TXT_PINCODE_LOCALITY": "PATTAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630205,
      "TXT_PINCODE_LOCALITY": "KILASEVALPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630206,
      "TXT_PINCODE_LOCALITY": "KORATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630207,
      "TXT_PINCODE_LOCALITY": "ATHIKARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630208,
      "TXT_PINCODE_LOCALITY": "O.SIRUVAYAL S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630210,
      "TXT_PINCODE_LOCALITY": "SIVAGANGAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1899,
      "City District Name": "SIVAGANGAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630211,
      "TXT_PINCODE_LOCALITY": "TIRUPATTUR S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630212,
      "TXT_PINCODE_LOCALITY": "VIRAMATHI S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630214,
      "TXT_PINCODE_LOCALITY": "SIRAVAYAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5983,
      "City District Name": "TIRUPATHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630215,
      "TXT_PINCODE_LOCALITY": "ATHANGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5983,
      "City District Name": "TIRUPATHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630301,
      "TXT_PINCODE_LOCALITY": "AMARAVATHIPUDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630302,
      "TXT_PINCODE_LOCALITY": "DEVAKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630303,
      "TXT_PINCODE_LOCALITY": "DEVAKOTTAI EXTENSION S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630305,
      "TXT_PINCODE_LOCALITY": "KALLAL S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630306,
      "TXT_PINCODE_LOCALITY": "VISALAYANKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630307,
      "TXT_PINCODE_LOCALITY": "MANAGIRI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630308,
      "TXT_PINCODE_LOCALITY": "KALATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2940,
      "City District Name": "DEVAKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630309,
      "TXT_PINCODE_LOCALITY": "PULIDIPATTI S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630310,
      "TXT_PINCODE_LOCALITY": "KALLIPPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5983,
      "City District Name": "TIRUPATHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630311,
      "TXT_PINCODE_LOCALITY": "PAVANAKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630312,
      "TXT_PINCODE_LOCALITY": "PULIYAL S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630313,
      "TXT_PINCODE_LOCALITY": "SEMBANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630314,
      "TXT_PINCODE_LOCALITY": "SHANMUGANATHAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630318,
      "TXT_PINCODE_LOCALITY": "KAPPALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2940,
      "City District Name": "DEVAKOTTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630321,
      "TXT_PINCODE_LOCALITY": "VETHIYUR S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630405,
      "TXT_PINCODE_LOCALITY": "SEVVUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630408,
      "TXT_PINCODE_LOCALITY": "VILANGATTUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630410,
      "TXT_PINCODE_LOCALITY": "MANDAKUDIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630411,
      "TXT_PINCODE_LOCALITY": "SARAKENI S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630413,
      "TXT_PINCODE_LOCALITY": "R.PALAKURICHY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5161,
      "City District Name": "PONNAMARAVATHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630501,
      "TXT_PINCODE_LOCALITY": "CVMANGALAM S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630502,
      "TXT_PINCODE_LOCALITY": "SINGAMPUNARI S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630505,
      "TXT_PINCODE_LOCALITY": "MARUTHIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5983,
      "City District Name": "TIRUPATHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630551,
      "TXT_PINCODE_LOCALITY": "MARAKATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630552,
      "TXT_PINCODE_LOCALITY": "TIRUMALAIKONERIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630553,
      "TXT_PINCODE_LOCALITY": "MADAGUPATTI S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630554,
      "TXT_PINCODE_LOCALITY": "SIRAMAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630555,
      "TXT_PINCODE_LOCALITY": "NATARAJAPURAM S.O (SIVAGANGA)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630556,
      "TXT_PINCODE_LOCALITY": "NATTARASANKOTTAI S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630557,
      "TXT_PINCODE_LOCALITY": "NALUKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630558,
      "TXT_PINCODE_LOCALITY": "NAGARAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630559,
      "TXT_PINCODE_LOCALITY": "VEMBATTUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630560,
      "TXT_PINCODE_LOCALITY": "THADIYAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630561,
      "TXT_PINCODE_LOCALITY": "SIVAGANGAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1899,
      "City District Name": "SIVAGANGAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630562,
      "TXT_PINCODE_LOCALITY": "SIVAGANGA COLLECTORATE S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630565,
      "TXT_PINCODE_LOCALITY": "VEMBATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630566,
      "TXT_PINCODE_LOCALITY": "ERIYUR S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630601,
      "TXT_PINCODE_LOCALITY": "MANAMADURAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630602,
      "TXT_PINCODE_LOCALITY": "IDAIKATTUR S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630606,
      "TXT_PINCODE_LOCALITY": "MANAMADURAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630609,
      "TXT_PINCODE_LOCALITY": "MILAGANOOR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630610,
      "TXT_PINCODE_LOCALITY": "TIRUPPACHETTY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630611,
      "TXT_PINCODE_LOCALITY": "TIRUPPUVANAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630612,
      "TXT_PINCODE_LOCALITY": "MANAMADURAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4382,
      "City District Name": "MANAMADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630702,
      "TXT_PINCODE_LOCALITY": "NETTUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630709,
      "TXT_PINCODE_LOCALITY": "THAYAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630710,
      "TXT_PINCODE_LOCALITY": "SALAIGRAMAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630713,
      "TXT_PINCODE_LOCALITY": "VISWANOOR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 630719,
      "TXT_PINCODE_LOCALITY": "KOLANTHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5723,
      "City District Name": "SIVAGANGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631001,
      "TXT_PINCODE_LOCALITY": "ARAKKONAM H O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631002,
      "TXT_PINCODE_LOCALITY": "ARAKKONAM S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631003,
      "TXT_PINCODE_LOCALITY": "JOTHINAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631004,
      "TXT_PINCODE_LOCALITY": "EKHUNAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631005,
      "TXT_PINCODE_LOCALITY": "WINTERPET S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631006,
      "TXT_PINCODE_LOCALITY": "RAJALI ARAKONAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631011,
      "TXT_PINCODE_LOCALITY": "KADAPPANTHANGAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 442980,
      "City District Name": "WALLAJAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631027,
      "TXT_PINCODE_LOCALITY": "PALLIPET",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631051,
      "TXT_PINCODE_LOCALITY": "NAMALI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631052,
      "TXT_PINCODE_LOCALITY": "SIRUVALAYAM VILLAGE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631053,
      "TXT_PINCODE_LOCALITY": "TIRUMALPUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631054,
      "TXT_PINCODE_LOCALITY": "PALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631055,
      "TXT_PINCODE_LOCALITY": "GANAPATHIPURAM ARAKKONAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631056,
      "TXT_PINCODE_LOCALITY": "KUMPINIPET",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631057,
      "TXT_PINCODE_LOCALITY": "VALAIKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631058,
      "TXT_PINCODE_LOCALITY": "MOSUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631059,
      "TXT_PINCODE_LOCALITY": "NAGAVEDU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631060,
      "TXT_PINCODE_LOCALITY": "ICHIPUTHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631061,
      "TXT_PINCODE_LOCALITY": "THANIGAIPOLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631062,
      "TXT_PINCODE_LOCALITY": "SIRUNAMALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631063,
      "TXT_PINCODE_LOCALITY": "SAYANAVARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631101,
      "TXT_PINCODE_LOCALITY": "GURUVARAJUPETTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631102,
      "TXT_PINCODE_LOCALITY": "SHOLINGHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631103,
      "TXT_PINCODE_LOCALITY": "RENDADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631104,
      "TXT_PINCODE_LOCALITY": "JAMBUKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631105,
      "TXT_PINCODE_LOCALITY": "KODAKKAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631151,
      "TXT_PINCODE_LOCALITY": "TAKKOLAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631152,
      "TXT_PINCODE_LOCALITY": "SURAKSHA CISF CAMPUS",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631201,
      "TXT_PINCODE_LOCALITY": "ARUNGOLAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6320,
      "City District Name": "TIRUVALLUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631202,
      "TXT_PINCODE_LOCALITY": "ATHIMANJEERIPET",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631203,
      "TXT_PINCODE_LOCALITY": "PODATURPET",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6320,
      "City District Name": "TIRUVALLUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631204,
      "TXT_PINCODE_LOCALITY": "KANAKAMMACHATRAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631205,
      "TXT_PINCODE_LOCALITY": "K G KANDIGAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631206,
      "TXT_PINCODE_LOCALITY": "MADDURU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631207,
      "TXT_PINCODE_LOCALITY": "PALLIPAT BAZAAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6320,
      "City District Name": "TIRUVALLUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631208,
      "TXT_PINCODE_LOCALITY": "BOMMARAJUPETA",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631209,
      "TXT_PINCODE_LOCALITY": "TIRUTTANI SOUTH",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631210,
      "TXT_PINCODE_LOCALITY": "TIRUVELANGADU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631211,
      "TXT_PINCODE_LOCALITY": "SARASWATHINAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631212,
      "TXT_PINCODE_LOCALITY": "POONIMANGADU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631213,
      "TXT_PINCODE_LOCALITY": "PONPADI R S",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631214,
      "TXT_PINCODE_LOCALITY": "TIRUPASUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631215,
      "TXT_PINCODE_LOCALITY": "KOLATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631216,
      "TXT_PINCODE_LOCALITY": "MANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631301,
      "TXT_PINCODE_LOCALITY": "AMMAVARIKUPPAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631302,
      "TXT_PINCODE_LOCALITY": "ERUMBI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631303,
      "TXT_PINCODE_LOCALITY": "RAMAKRISHNARAJUPETA",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631304,
      "TXT_PINCODE_LOCALITY": "VANGANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631401,
      "TXT_PINCODE_LOCALITY": "KILACHERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631402,
      "TXT_PINCODE_LOCALITY": "PERAMBAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6320,
      "City District Name": "TIRUVALLUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631404,
      "TXT_PINCODE_LOCALITY": "ULANDAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631501,
      "TXT_PINCODE_LOCALITY": "THOOPUL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6320,
      "City District Name": "TIRUVALLUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631502,
      "TXT_PINCODE_LOCALITY": "KANCHPURAM R S",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631503,
      "TXT_PINCODE_LOCALITY": "TIRUKATCHINAMBISTREET",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6320,
      "City District Name": "TIRUVALLUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631520,
      "TXT_PINCODE_LOCALITY": "CHENGALNATHAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631523,
      "TXT_PINCODE_LOCALITY": "THANDALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631551,
      "TXT_PINCODE_LOCALITY": "BALCHETTICHATRAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631552,
      "TXT_PINCODE_LOCALITY": "KARAIPETTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631553,
      "TXT_PINCODE_LOCALITY": "EDAYARPAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631554,
      "TXT_PINCODE_LOCALITY": "MUSARAVAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631556,
      "TXT_PINCODE_LOCALITY": "SIRUKAVERIPAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631559,
      "TXT_PINCODE_LOCALITY": "SEVILIMEDU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631560,
      "TXT_PINCODE_LOCALITY": "SINGIDIVAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631561,
      "TXT_PINCODE_LOCALITY": "NEERVALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631562,
      "TXT_PINCODE_LOCALITY": "VADAVERIPATTU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631601,
      "TXT_PINCODE_LOCALITY": "AYYAMPATTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631602,
      "TXT_PINCODE_LOCALITY": "DAMAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631603,
      "TXT_PINCODE_LOCALITY": "MAGARAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631604,
      "TXT_PINCODE_LOCALITY": "THENNERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631605,
      "TXT_PINCODE_LOCALITY": "MASILAMANI NAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6320,
      "City District Name": "TIRUVALLUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631606,
      "TXT_PINCODE_LOCALITY": "PALAYASEEVARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631607,
      "TXT_PINCODE_LOCALITY": "THIMMARAJANPETTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631608,
      "TXT_PINCODE_LOCALITY": "KAVANTHANDALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631609,
      "TXT_PINCODE_LOCALITY": "ARPAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3735,
      "City District Name": "KANCHEEPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631701,
      "TXT_PINCODE_LOCALITY": "TIRUVANNAMALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631702,
      "TXT_PINCODE_LOCALITY": "MAMANDUR-KCM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2736,
      "City District Name": "CHENGALPATTU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631703,
      "TXT_PINCODE_LOCALITY": "DUSI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 631710,
      "TXT_PINCODE_LOCALITY": "KIZHNETHAPAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 425587,
      "City District Name": "CHEYYAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632001,
      "TXT_PINCODE_LOCALITY": "KRISHNA NAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632002,
      "TXT_PINCODE_LOCALITY": "THORAPADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632003,
      "TXT_PINCODE_LOCALITY": "VELLORE KASBA",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632004,
      "TXT_PINCODE_LOCALITY": "VELLORE CANTOMENT",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632005,
      "TXT_PINCODE_LOCALITY": "VELLORE COLLECTORATE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632006,
      "TXT_PINCODE_LOCALITY": "VIRUDHAMPET",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632007,
      "TXT_PINCODE_LOCALITY": "KATPADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632008,
      "TXT_PINCODE_LOCALITY": "SHENBAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632009,
      "TXT_PINCODE_LOCALITY": "SATHUVACHARI EXTENSION",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632010,
      "TXT_PINCODE_LOCALITY": "ABDULLAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632011,
      "TXT_PINCODE_LOCALITY": "SATHUMADURAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632012,
      "TXT_PINCODE_LOCALITY": "SAIDAPET VELLORE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632013,
      "TXT_PINCODE_LOCALITY": "KONAVATTAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632014,
      "TXT_PINCODE_LOCALITY": "BRAMHAPURAM B.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632051,
      "TXT_PINCODE_LOCALITY": "PALAMATHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632052,
      "TXT_PINCODE_LOCALITY": "EDAYANSATHU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632053,
      "TXT_PINCODE_LOCALITY": "BRAHMAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632054,
      "TXT_PINCODE_LOCALITY": "PERUMUGAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632055,
      "TXT_PINCODE_LOCALITY": "ARIYUR (VELLORE)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632056,
      "TXT_PINCODE_LOCALITY": "VIRUPAKSHIPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632057,
      "TXT_PINCODE_LOCALITY": "MUNJURUPATTU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632058,
      "TXT_PINCODE_LOCALITY": "PENNATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632059,
      "TXT_PINCODE_LOCALITY": "TEL S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632101,
      "TXT_PINCODE_LOCALITY": "ANAIKATTU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632102,
      "TXT_PINCODE_LOCALITY": "KANIYAMBADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632103,
      "TXT_PINCODE_LOCALITY": "ODUKKATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632104,
      "TXT_PINCODE_LOCALITY": "VIRNJIPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632105,
      "TXT_PINCODE_LOCALITY": "USSOOR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632106,
      "TXT_PINCODE_LOCALITY": "S L R SANITORIUM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632107,
      "TXT_PINCODE_LOCALITY": "GURUVARAJAPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632108,
      "TXT_PINCODE_LOCALITY": "SEVUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632109,
      "TXT_PINCODE_LOCALITY": "VEPPANKUPPAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632110,
      "TXT_PINCODE_LOCALITY": "PINNATHURAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632112,
      "TXT_PINCODE_LOCALITY": "ERIPUDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632113,
      "TXT_PINCODE_LOCALITY": "VEPPAMPATTU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632114,
      "TXT_PINCODE_LOCALITY": "POIGAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632115,
      "TXT_PINCODE_LOCALITY": "KATPADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3853,
      "City District Name": "KATPADI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632201,
      "TXT_PINCODE_LOCALITY": "KILVAITHINANKUPPAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632202,
      "TXT_PINCODE_LOCALITY": "LATTERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632203,
      "TXT_PINCODE_LOCALITY": "MELMAYIL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632204,
      "TXT_PINCODE_LOCALITY": "VADUGAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632205,
      "TXT_PINCODE_LOCALITY": "MACHANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632206,
      "TXT_PINCODE_LOCALITY": "KANGUPPAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632207,
      "TXT_PINCODE_LOCALITY": "MELKAVANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632208,
      "TXT_PINCODE_LOCALITY": "RAMALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632209,
      "TXT_PINCODE_LOCALITY": "RUHSA CAMPUS",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632211,
      "TXT_PINCODE_LOCALITY": "KILKOTHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632218,
      "TXT_PINCODE_LOCALITY": "VALAPANDAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632289,
      "TXT_PINCODE_LOCALITY": "KANGUPPAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3853,
      "City District Name": "KATPADI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632301,
      "TXT_PINCODE_LOCALITY": "ARNI H O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632302,
      "TXT_PINCODE_LOCALITY": "GUDIYATTAM HO.",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632311,
      "TXT_PINCODE_LOCALITY": "KANNAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632312,
      "TXT_PINCODE_LOCALITY": "ARANI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632313,
      "TXT_PINCODE_LOCALITY": "ARANI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632314,
      "TXT_PINCODE_LOCALITY": "ARANI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632315,
      "TXT_PINCODE_LOCALITY": "ARANI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632316,
      "TXT_PINCODE_LOCALITY": "CHEVUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632317,
      "TXT_PINCODE_LOCALITY": "ARANI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632318,
      "TXT_PINCODE_LOCALITY": "VALAPANDAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632319,
      "TXT_PINCODE_LOCALITY": "KAMMAVANPETTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632320,
      "TXT_PINCODE_LOCALITY": "SEDARAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632321,
      "TXT_PINCODE_LOCALITY": "NESAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632322,
      "TXT_PINCODE_LOCALITY": "MUNUGAPATTU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632323,
      "TXT_PINCODE_LOCALITY": "MELSISHAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632324,
      "TXT_PINCODE_LOCALITY": "NANJUKONDAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2847,
      "City District Name": "CUDDALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632325,
      "TXT_PINCODE_LOCALITY": "SIRUMUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632326,
      "TXT_PINCODE_LOCALITY": "SUSAINAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632401,
      "TXT_PINCODE_LOCALITY": "RANIPET BAZAAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632402,
      "TXT_PINCODE_LOCALITY": "RANIPET",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632403,
      "TXT_PINCODE_LOCALITY": "RANIPET",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632404,
      "TXT_PINCODE_LOCALITY": "KARAI S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632405,
      "TXT_PINCODE_LOCALITY": "RANIPET",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632406,
      "TXT_PINCODE_LOCALITY": "BHEL RANIPET",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632412,
      "TXT_PINCODE_LOCALITY": "MOLAPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5337,
      "City District Name": "RASIPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632501,
      "TXT_PINCODE_LOCALITY": "AMMOOR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632502,
      "TXT_PINCODE_LOCALITY": "ANAVARDHIKHANPETTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632503,
      "TXT_PINCODE_LOCALITY": "ARCOT WEST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632504,
      "TXT_PINCODE_LOCALITY": "DAMARAPAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632505,
      "TXT_PINCODE_LOCALITY": "BANAVARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632506,
      "TXT_PINCODE_LOCALITY": "KALAVAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632507,
      "TXT_PINCODE_LOCALITY": "KAVANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632508,
      "TXT_PINCODE_LOCALITY": "WALAJAPET",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632509,
      "TXT_PINCODE_LOCALITY": "MELVISHRAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632510,
      "TXT_PINCODE_LOCALITY": "MINNAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632511,
      "TXT_PINCODE_LOCALITY": "CHEYYAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632512,
      "TXT_PINCODE_LOCALITY": "TIMIRI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632513,
      "TXT_PINCODE_LOCALITY": "WALAJAPET BAZAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632514,
      "TXT_PINCODE_LOCALITY": "PONNAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632515,
      "TXT_PINCODE_LOCALITY": "TIRUVALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632516,
      "TXT_PINCODE_LOCALITY": "VENNAMPALLE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632517,
      "TXT_PINCODE_LOCALITY": "RATNAGIRI KILMINNAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632518,
      "TXT_PINCODE_LOCALITY": "PENNAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632519,
      "TXT_PINCODE_LOCALITY": "VELLORE SUGAR MILLS",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632520,
      "TXT_PINCODE_LOCALITY": "MELPADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632521,
      "TXT_PINCODE_LOCALITY": "VILAPAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632522,
      "TXT_PINCODE_LOCALITY": "OZHUGUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632523,
      "TXT_PINCODE_LOCALITY": "SATTUR VELLORE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424435,
      "City District Name": "KAMARAJAR(VIRUDHUNAGAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632524,
      "TXT_PINCODE_LOCALITY": "VALLUVAMBAKKAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632525,
      "TXT_PINCODE_LOCALITY": "MORANAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632526,
      "TXT_PINCODE_LOCALITY": "KADAPERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632527,
      "TXT_PINCODE_LOCALITY": "CHENNASAMUDRAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632528,
      "TXT_PINCODE_LOCALITY": "BRAHMADESAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632529,
      "TXT_PINCODE_LOCALITY": "KEERASATHU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632530,
      "TXT_PINCODE_LOCALITY": "PUTTUTHAKKU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632531,
      "TXT_PINCODE_LOCALITY": "OCHERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632568,
      "TXT_PINCODE_LOCALITY": "MANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2094,
      "City District Name": "ARAKONAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632601,
      "TXT_PINCODE_LOCALITY": "DHARNAMPET",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632602,
      "TXT_PINCODE_LOCALITY": "GUDIYATTAM SOUTH",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632603,
      "TXT_PINCODE_LOCALITY": "PARADARAMI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632604,
      "TXT_PINCODE_LOCALITY": "GANDHI NAGAR GUDIYATHAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632605,
      "TXT_PINCODE_LOCALITY": "SEMPALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632606,
      "TXT_PINCODE_LOCALITY": "KALLAPADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632607,
      "TXT_PINCODE_LOCALITY": "VEERISETTIPALL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632608,
      "TXT_PINCODE_LOCALITY": "KIZHALATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632616,
      "TXT_PINCODE_LOCALITY": "CHEYYAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632624,
      "TXT_PINCODE_LOCALITY": "ADIKARAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632650,
      "TXT_PINCODE_LOCALITY": "ADASAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632701,
      "TXT_PINCODE_LOCALITY": "ARCOT",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632751,
      "TXT_PINCODE_LOCALITY": "T. PARANGANI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424428,
      "City District Name": "VILLUPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632802,
      "TXT_PINCODE_LOCALITY": "AMBUR BAZAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632805,
      "TXT_PINCODE_LOCALITY": "VELLORE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632806,
      "TXT_PINCODE_LOCALITY": "CHEDUKKARAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3313,
      "City District Name": "GUDIYATHAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 632813,
      "TXT_PINCODE_LOCALITY": "POGALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3313,
      "City District Name": "GUDIYATHAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 633804,
      "TXT_PINCODE_LOCALITY": "PATTU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3313,
      "City District Name": "GUDIYATHAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 634228,
      "TXT_PINCODE_LOCALITY": "SITHAREVU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 634243,
      "TXT_PINCODE_LOCALITY": "NEEDLE INDUSTRIES",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635001,
      "TXT_PINCODE_LOCALITY": "KRISHNAGIRI H O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635002,
      "TXT_PINCODE_LOCALITY": "KRISHNAGIRI OLDPET",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635101,
      "TXT_PINCODE_LOCALITY": "KRISHNAGIRI PROJECT",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635102,
      "TXT_PINCODE_LOCALITY": "ANCHETTY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1849,
      "City District Name": "KRISHNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635103,
      "TXT_PINCODE_LOCALITY": "BAGALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1849,
      "City District Name": "KRISHNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635104,
      "TXT_PINCODE_LOCALITY": "BARUGUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1849,
      "City District Name": "KRISHNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635105,
      "TXT_PINCODE_LOCALITY": "BERIGAI S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1849,
      "City District Name": "KRISHNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635106,
      "TXT_PINCODE_LOCALITY": "CHAPARTHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635107,
      "TXT_PINCODE_LOCALITY": "DENKANIKOTA",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1849,
      "City District Name": "KRISHNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635108,
      "TXT_PINCODE_LOCALITY": "ELATHAGIRI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1849,
      "City District Name": "KRISHNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635109,
      "TXT_PINCODE_LOCALITY": "HOSUR EAST S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1849,
      "City District Name": "KRISHNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635110,
      "TXT_PINCODE_LOCALITY": "HOSUR CATTLE FARM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635111,
      "TXT_PINCODE_LOCALITY": "KARIMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635112,
      "TXT_PINCODE_LOCALITY": "KAVERIPATTINAM DHARMAPURI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635113,
      "TXT_PINCODE_LOCALITY": "KELAMANGLAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635114,
      "TXT_PINCODE_LOCALITY": "SARAKAPALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1849,
      "City District Name": "KRISHNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635115,
      "TXT_PINCODE_LOCALITY": "KRISHNAGIRI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1849,
      "City District Name": "KRISHNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635116,
      "TXT_PINCODE_LOCALITY": "ODAYANDAHALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1849,
      "City District Name": "KRISHNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635117,
      "TXT_PINCODE_LOCALITY": "SHOOLAGIRI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635118,
      "TXT_PINCODE_LOCALITY": "THALLY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635119,
      "TXT_PINCODE_LOCALITY": "UDDANPALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635120,
      "TXT_PINCODE_LOCALITY": "VARATTANPALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635121,
      "TXT_PINCODE_LOCALITY": "VEPPANAPALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635122,
      "TXT_PINCODE_LOCALITY": "ALAPPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635123,
      "TXT_PINCODE_LOCALITY": "PANNANDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635124,
      "TXT_PINCODE_LOCALITY": "HOSUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1849,
      "City District Name": "KRISHNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635125,
      "TXT_PINCODE_LOCALITY": "HOSUR EAST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635126,
      "TXT_PINCODE_LOCALITY": "SIPCOT INDL. COMPLEX",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1849,
      "City District Name": "KRISHNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635127,
      "TXT_PINCODE_LOCALITY": "MAHARAJAKADAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635128,
      "TXT_PINCODE_LOCALITY": "MEKALACHINNAMPALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635129,
      "TXT_PINCODE_LOCALITY": "AGRAHARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635130,
      "TXT_PINCODE_LOCALITY": "SUNDEKUPPAN",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635131,
      "TXT_PINCODE_LOCALITY": "KAMMAMPALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635132,
      "TXT_PINCODE_LOCALITY": "ATHIMUGAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635133,
      "TXT_PINCODE_LOCALITY": "CHIGARLAPALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635134,
      "TXT_PINCODE_LOCALITY": "KAMANDODDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635135,
      "TXT_PINCODE_LOCALITY": "JAGADAB",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635136,
      "TXT_PINCODE_LOCALITY": "DAMODARAHALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635137,
      "TXT_PINCODE_LOCALITY": "PERANDAPALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635138,
      "TXT_PINCODE_LOCALITY": "ANDEVANAPALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635139,
      "TXT_PINCODE_LOCALITY": "ERUDUKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635140,
      "TXT_PINCODE_LOCALITY": "SANDANAHALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635141,
      "TXT_PINCODE_LOCALITY": "SALIVARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635151,
      "TXT_PINCODE_LOCALITY": "EBBIRI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1849,
      "City District Name": "KRISHNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635201,
      "TXT_PINCODE_LOCALITY": "BARUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635202,
      "TXT_PINCODE_LOCALITY": "KAMBAINALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635203,
      "TXT_PINCODE_LOCALITY": "MATHUR-DHAMAPURI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635204,
      "TXT_PINCODE_LOCALITY": "NAGARASAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635205,
      "TXT_PINCODE_LOCALITY": "PERIYANAHALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635206,
      "TXT_PINCODE_LOCALITY": "POCHAMPALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635207,
      "TXT_PINCODE_LOCALITY": "UTTANGARAI S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1849,
      "City District Name": "KRISHNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635208,
      "TXT_PINCODE_LOCALITY": "ARASAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635209,
      "TXT_PINCODE_LOCALITY": "SAITHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635210,
      "TXT_PINCODE_LOCALITY": "JAGADEVI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635211,
      "TXT_PINCODE_LOCALITY": "KANNADAHALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635212,
      "TXT_PINCODE_LOCALITY": "IRUMATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635251,
      "TXT_PINCODE_LOCALITY": "CHENNAMPET",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 383377,
      "City District Name": "VANIYAMBADI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635301,
      "TXT_PINCODE_LOCALITY": "BOMMIDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635302,
      "TXT_PINCODE_LOCALITY": "BUDDIREDDIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635303,
      "TXT_PINCODE_LOCALITY": "KADATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635304,
      "TXT_PINCODE_LOCALITY": "KALLAVI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635305,
      "TXT_PINCODE_LOCALITY": "MORAPPUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635306,
      "TXT_PINCODE_LOCALITY": "UTTANGARAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1849,
      "City District Name": "KRISHNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635307,
      "TXT_PINCODE_LOCALITY": "SINGARAPETTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635308,
      "TXT_PINCODE_LOCALITY": "BAIRANATHAM PALLIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635309,
      "TXT_PINCODE_LOCALITY": "PERIATHALLAPADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635310,
      "TXT_PINCODE_LOCALITY": "THALANATHAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635311,
      "TXT_PINCODE_LOCALITY": "RAMANAHALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635312,
      "TXT_PINCODE_LOCALITY": "MOTTANKURICHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635313,
      "TXT_PINCODE_LOCALITY": "CHINTALAPADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635315,
      "TXT_PINCODE_LOCALITY": "VENAKKAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3381,
      "City District Name": "HARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635601,
      "TXT_PINCODE_LOCALITY": "GANDHIPET",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635602,
      "TXT_PINCODE_LOCALITY": "TIRUPPATUR BAZAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635611,
      "TXT_PINCODE_LOCALITY": "KORATHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635651,
      "TXT_PINCODE_LOCALITY": "PUDUPETTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635652,
      "TXT_PINCODE_LOCALITY": "VISHAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635653,
      "TXT_PINCODE_LOCALITY": "VENGALAPURM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635654,
      "TXT_PINCODE_LOCALITY": "SUNDARAMPALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635655,
      "TXT_PINCODE_LOCALITY": "ADIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635656,
      "TXT_PINCODE_LOCALITY": "PANIYANDAPALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635657,
      "TXT_PINCODE_LOCALITY": "JAYAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635658,
      "TXT_PINCODE_LOCALITY": "MADAVALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635659,
      "TXT_PINCODE_LOCALITY": "PUTHUGARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635660,
      "TXT_PINCODE_LOCALITY": "BOMMIKUPPAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635661,
      "TXT_PINCODE_LOCALITY": "KURUMBERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635662,
      "TXT_PINCODE_LOCALITY": "SEVATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635684,
      "TXT_PINCODE_LOCALITY": "PUDUPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5983,
      "City District Name": "TIRUPATHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635701,
      "TXT_PINCODE_LOCALITY": "ALANGAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635702,
      "TXT_PINCODE_LOCALITY": "ANDIAPPANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635703,
      "TXT_PINCODE_LOCALITY": "POLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5990,
      "City District Name": "TIRUVANNAMALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635704,
      "TXT_PINCODE_LOCALITY": "KAVALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635705,
      "TXT_PINCODE_LOCALITY": "IRUNAPATTU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635706,
      "TXT_PINCODE_LOCALITY": "KURUSILAPATTU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635707,
      "TXT_PINCODE_LOCALITY": "POONGULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635708,
      "TXT_PINCODE_LOCALITY": "NACHIARKUPPAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635709,
      "TXT_PINCODE_LOCALITY": "PERUMAPATTU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635710,
      "TXT_PINCODE_LOCALITY": "POONGULAM B.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635751,
      "TXT_PINCODE_LOCALITY": "VANIMBADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635752,
      "TXT_PINCODE_LOCALITY": "VANIYAMBADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635753,
      "TXT_PINCODE_LOCALITY": "KHADERPET",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635754,
      "TXT_PINCODE_LOCALITY": "UDAYENDIRAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635755,
      "TXT_PINCODE_LOCALITY": "UDAYANDIRAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635756,
      "TXT_PINCODE_LOCALITY": "VELLAKUTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635757,
      "TXT_PINCODE_LOCALITY": "KOTHAKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635758,
      "TXT_PINCODE_LOCALITY": "KALENDRA",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635801,
      "TXT_PINCODE_LOCALITY": "AMBALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635802,
      "TXT_PINCODE_LOCALITY": "AMBUR R S",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635803,
      "TXT_PINCODE_LOCALITY": "GUDIYATTAM R S",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635804,
      "TXT_PINCODE_LOCALITY": "VELLORE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635805,
      "TXT_PINCODE_LOCALITY": "MAILPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635806,
      "TXT_PINCODE_LOCALITY": "MELALATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635807,
      "TXT_PINCODE_LOCALITY": "MINNUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635808,
      "TXT_PINCODE_LOCALITY": "OOMERABAD S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635809,
      "TXT_PINCODE_LOCALITY": "PALLIKONDA S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635810,
      "TXT_PINCODE_LOCALITY": "PERAMBUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635811,
      "TXT_PINCODE_LOCALITY": "THUTHIPATTU S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635812,
      "TXT_PINCODE_LOCALITY": "VADAPUDPET",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635813,
      "TXT_PINCODE_LOCALITY": "GUDIYATHAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635814,
      "TXT_PINCODE_LOCALITY": "SHANAKUPPAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635815,
      "TXT_PINCODE_LOCALITY": "KETHANDAPATTI SUGAR MILLS",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635816,
      "TXT_PINCODE_LOCALITY": "AGARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635817,
      "TXT_PINCODE_LOCALITY": "VALAYAKARAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635818,
      "TXT_PINCODE_LOCALITY": "KOTTUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635819,
      "TXT_PINCODE_LOCALITY": "NARIYAMBATTU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635820,
      "TXT_PINCODE_LOCALITY": "KETHANDAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635821,
      "TXT_PINCODE_LOCALITY": "VINNAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635822,
      "TXT_PINCODE_LOCALITY": "VADAKKUPATTU NA",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635823,
      "TXT_PINCODE_LOCALITY": "ALASANDAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635824,
      "TXT_PINCODE_LOCALITY": "RAMANAICKENPETTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635825,
      "TXT_PINCODE_LOCALITY": "KILMURUNGAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635826,
      "TXT_PINCODE_LOCALITY": "AGARAMCHERRY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635851,
      "TXT_PINCODE_LOCALITY": "TIRUPATTUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635852,
      "TXT_PINCODE_LOCALITY": "ATHURKUPPAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635853,
      "TXT_PINCODE_LOCALITY": "PAKKIRTHAKKA",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635854,
      "TXT_PINCODE_LOCALITY": "PACHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635855,
      "TXT_PINCODE_LOCALITY": "YELAGIRI HILLS",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424434,
      "City District Name": "PASUMPON M. THEVAR(SIVAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635856,
      "TXT_PINCODE_LOCALITY": "MANDALAVADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635857,
      "TXT_PINCODE_LOCALITY": "ATHURUKUPPAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 635901,
      "TXT_PINCODE_LOCALITY": "CHINNAKANDILI B.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1865,
      "City District Name": "VELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636001,
      "TXT_PINCODE_LOCALITY": "DEVENDRAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 302,
      "City District Name": "SALEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636002,
      "TXT_PINCODE_LOCALITY": "SHEVAPET BAZAAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 302,
      "City District Name": "SALEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636003,
      "TXT_PINCODE_LOCALITY": "AMMAPETTAI SALEM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 302,
      "City District Name": "SALEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636004,
      "TXT_PINCODE_LOCALITY": "ALAGAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 302,
      "City District Name": "SALEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636005,
      "TXT_PINCODE_LOCALITY": "SURAMANGALAM H O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636006,
      "TXT_PINCODE_LOCALITY": "SALEM SOUTH",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636007,
      "TXT_PINCODE_LOCALITY": "MARAVANERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 302,
      "City District Name": "SALEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636008,
      "TXT_PINCODE_LOCALITY": "AYYANTHIRUMALIGAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 302,
      "City District Name": "SALEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636009,
      "TXT_PINCODE_LOCALITY": "ARISIPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636010,
      "TXT_PINCODE_LOCALITY": "KONDALAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636011,
      "TXT_PINCODE_LOCALITY": "GOVT COLLEGE OF ENGG SALEM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636012,
      "TXT_PINCODE_LOCALITY": "KARUPPUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636013,
      "TXT_PINCODE_LOCALITY": "SALEM STEEL PLANT",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636014,
      "TXT_PINCODE_LOCALITY": "PERIYAR VILLAGE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 302,
      "City District Name": "SALEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636015,
      "TXT_PINCODE_LOCALITY": "KALARAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 302,
      "City District Name": "SALEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636016,
      "TXT_PINCODE_LOCALITY": "FAIRLANDS",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 302,
      "City District Name": "SALEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636017,
      "TXT_PINCODE_LOCALITY": "SALEM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 302,
      "City District Name": "SALEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636021,
      "TXT_PINCODE_LOCALITY": "SALEM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 302,
      "City District Name": "SALEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636030,
      "TXT_PINCODE_LOCALITY": "MARAMANGALATHUPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 302,
      "City District Name": "SALEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636039,
      "TXT_PINCODE_LOCALITY": "KAMALAPURAM SOUTH",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636051,
      "TXT_PINCODE_LOCALITY": "KARUKKALVADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636090,
      "TXT_PINCODE_LOCALITY": "SUNDHAR NAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 302,
      "City District Name": "SALEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636101,
      "TXT_PINCODE_LOCALITY": "ARAGALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636102,
      "TXT_PINCODE_LOCALITY": "PUDUPET ATTUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 302,
      "City District Name": "SALEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636103,
      "TXT_PINCODE_LOCALITY": "AYODHYAPATTINAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636104,
      "TXT_PINCODE_LOCALITY": "BELUR SALEM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636105,
      "TXT_PINCODE_LOCALITY": "GANGAVALLI - 1509",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636106,
      "TXT_PINCODE_LOCALITY": "KARMAPURAM VILLAGE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 302,
      "City District Name": "SALEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636107,
      "TXT_PINCODE_LOCALITY": "MALLIKARAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636108,
      "TXT_PINCODE_LOCALITY": "NARASINGAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636109,
      "TXT_PINCODE_LOCALITY": "PETHANMICKENPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636110,
      "TXT_PINCODE_LOCALITY": "SENDARAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636111,
      "TXT_PINCODE_LOCALITY": "SESHANCHAVADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636112,
      "TXT_PINCODE_LOCALITY": "TALAIVASAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636113,
      "TXT_PINCODE_LOCALITY": "THAMMAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636114,
      "TXT_PINCODE_LOCALITY": "THUMBAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636115,
      "TXT_PINCODE_LOCALITY": "VALAPADY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636116,
      "TXT_PINCODE_LOCALITY": "VIRAGALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636117,
      "TXT_PINCODE_LOCALITY": "YETHAPUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636118,
      "TXT_PINCODE_LOCALITY": "ULIPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636119,
      "TXT_PINCODE_LOCALITY": "PUTHIRAGOUNDENPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636121,
      "TXT_PINCODE_LOCALITY": "VADACHENNIMALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636122,
      "TXT_PINCODE_LOCALITY": "SUKKAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636123,
      "TXT_PINCODE_LOCALITY": "KURICHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636124,
      "TXT_PINCODE_LOCALITY": "KADAMBUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2107,
      "City District Name": "ARIYALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636125,
      "TXT_PINCODE_LOCALITY": "SHEEPFARM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636126,
      "TXT_PINCODE_LOCALITY": "SIRUVAGHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636127,
      "TXT_PINCODE_LOCALITY": "KIRIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636128,
      "TXT_PINCODE_LOCALITY": "PAPPINAICKENPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636129,
      "TXT_PINCODE_LOCALITY": "PAITHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636130,
      "TXT_PINCODE_LOCALITY": "KONDAYAMPALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636131,
      "TXT_PINCODE_LOCALITY": "NADUVALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636132,
      "TXT_PINCODE_LOCALITY": "KUDUMALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636133,
      "TXT_PINCODE_LOCALITY": "PERIA KRISHNAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636135,
      "TXT_PINCODE_LOCALITY": "KANJANAICKENPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636136,
      "TXT_PINCODE_LOCALITY": "MANIVILUNDAN",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636137,
      "TXT_PINCODE_LOCALITY": "THADAVUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636138,
      "TXT_PINCODE_LOCALITY": "KARUMANTHURAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 302,
      "City District Name": "SALEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636139,
      "TXT_PINCODE_LOCALITY": "SALEM H O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636140,
      "TXT_PINCODE_LOCALITY": "ODAYAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636141,
      "TXT_PINCODE_LOCALITY": "PUDUPET",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636142,
      "TXT_PINCODE_LOCALITY": "MULLUKURICHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636201,
      "TXT_PINCODE_LOCALITY": "NILAVARAPATTY VILLAGE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 302,
      "City District Name": "SALEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636202,
      "TXT_PINCODE_LOCALITY": "JALLUTHUPPATTI R.F.",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 302,
      "City District Name": "SALEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636203,
      "TXT_PINCODE_LOCALITY": "MULLUR-SALEM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636204,
      "TXT_PINCODE_LOCALITY": "PANAMARATHUPATTI - 3591",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636206,
      "TXT_PINCODE_LOCALITY": "NALLIKALPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636301,
      "TXT_PINCODE_LOCALITY": "ATHANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636302,
      "TXT_PINCODE_LOCALITY": "JAGIR AMMAPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 302,
      "City District Name": "SALEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636303,
      "TXT_PINCODE_LOCALITY": "KOLATHUR-SALEM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636304,
      "TXT_PINCODE_LOCALITY": "MUTHUNAICKENPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 302,
      "City District Name": "SALEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636305,
      "TXT_PINCODE_LOCALITY": "PANNAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636306,
      "TXT_PINCODE_LOCALITY": "SINNAPPAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636307,
      "TXT_PINCODE_LOCALITY": "SIVATHAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636308,
      "TXT_PINCODE_LOCALITY": "AKKARAPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 302,
      "City District Name": "SALEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636309,
      "TXT_PINCODE_LOCALITY": "KAMALAPURAM (SOUTH)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636313,
      "TXT_PINCODE_LOCALITY": "KAVERIPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636314,
      "TXT_PINCODE_LOCALITY": "CHINNASEERAGAPADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636315,
      "TXT_PINCODE_LOCALITY": "THUMBIVADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636316,
      "TXT_PINCODE_LOCALITY": "KONGUPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636317,
      "TXT_PINCODE_LOCALITY": "DASAVILAKKU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636318,
      "TXT_PINCODE_LOCALITY": "THENALPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 302,
      "City District Name": "SALEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636319,
      "TXT_PINCODE_LOCALITY": "KARAVALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636320,
      "TXT_PINCODE_LOCALITY": "PANNAVADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636321,
      "TXT_PINCODE_LOCALITY": "THARKADU SUNDARAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636322,
      "TXT_PINCODE_LOCALITY": "KURUMBANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636323,
      "TXT_PINCODE_LOCALITY": "SAMUDRAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636351,
      "TXT_PINCODE_LOCALITY": "KADYAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636352,
      "TXT_PINCODE_LOCALITY": "TOPPUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 302,
      "City District Name": "SALEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636353,
      "TXT_PINCODE_LOCALITY": "VELLAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636354,
      "TXT_PINCODE_LOCALITY": "DANISHPET",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636355,
      "TXT_PINCODE_LOCALITY": "KAMMAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636401,
      "TXT_PINCODE_LOCALITY": "METTUR DAM SOUTH",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 302,
      "City District Name": "SALEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636402,
      "TXT_PINCODE_LOCALITY": "METTUR DAM R S",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636403,
      "TXT_PINCODE_LOCALITY": "RAMANNAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636404,
      "TXT_PINCODE_LOCALITY": "GONUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636406,
      "TXT_PINCODE_LOCALITY": "METTUR DAM THERMAL PROJECT",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636407,
      "TXT_PINCODE_LOCALITY": "MELUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5337,
      "City District Name": "RASIPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636408,
      "TXT_PINCODE_LOCALITY": "VARAGOORKOMBAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5337,
      "City District Name": "RASIPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636433,
      "TXT_PINCODE_LOCALITY": "M PALAKKANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4513,
      "City District Name": "METTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636451,
      "TXT_PINCODE_LOCALITY": "CHAMARAJPET",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636452,
      "TXT_PINCODE_LOCALITY": "MADAYANKUTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 302,
      "City District Name": "SALEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636453,
      "TXT_PINCODE_LOCALITY": "MECHERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 302,
      "City District Name": "SALEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636454,
      "TXT_PINCODE_LOCALITY": "NANGAVALLI - 3307",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636455,
      "TXT_PINCODE_LOCALITY": "OMALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636456,
      "TXT_PINCODE_LOCALITY": "SALEM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 302,
      "City District Name": "SALEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636457,
      "TXT_PINCODE_LOCALITY": "VANAVASI SLM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636458,
      "TXT_PINCODE_LOCALITY": "MALLIKUNDAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636459,
      "TXT_PINCODE_LOCALITY": "GUTTAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636460,
      "TXT_PINCODE_LOCALITY": "METTUPATTI OMALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636461,
      "TXT_PINCODE_LOCALITY": "SANARPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636462,
      "TXT_PINCODE_LOCALITY": "NAVAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636463,
      "TXT_PINCODE_LOCALITY": "VIRUDASAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636464,
      "TXT_PINCODE_LOCALITY": "BUKKAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636465,
      "TXT_PINCODE_LOCALITY": "ARAGANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636501,
      "TXT_PINCODE_LOCALITY": "JALAKANTAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636502,
      "TXT_PINCODE_LOCALITY": "THARAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636503,
      "TXT_PINCODE_LOCALITY": "THOLASAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636504,
      "TXT_PINCODE_LOCALITY": "PAKKANADU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636505,
      "TXT_PINCODE_LOCALITY": "ADAYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636506,
      "TXT_PINCODE_LOCALITY": "MUTHAM-PATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636507,
      "TXT_PINCODE_LOCALITY": "VINNAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2024,
      "City District Name": "AMBUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636601,
      "TXT_PINCODE_LOCALITY": "YERCAUD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636602,
      "TXT_PINCODE_LOCALITY": "ONDIKADAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 193,
      "City District Name": "CHENNAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636603,
      "TXT_PINCODE_LOCALITY": "MANJAKUTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636604,
      "TXT_PINCODE_LOCALITY": "NAGALUR-SALEM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636609,
      "TXT_PINCODE_LOCALITY": "VEDAKADAMADUVU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3381,
      "City District Name": "HARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636610,
      "TXT_PINCODE_LOCALITY": "ARIYAPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 425538,
      "City District Name": "ATTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636654,
      "TXT_PINCODE_LOCALITY": "DANISHPET",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636701,
      "TXT_PINCODE_LOCALITY": "DHARMAPURI BUS STAND",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636702,
      "TXT_PINCODE_LOCALITY": "DHARMAPURI SOUTH",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636703,
      "TXT_PINCODE_LOCALITY": "KOMARASAMIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636704,
      "TXT_PINCODE_LOCALITY": "ANNASAGARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636705,
      "TXT_PINCODE_LOCALITY": "DHARMAPURI COLLECTORATE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636751,
      "TXT_PINCODE_LOCALITY": "VENKATANAHALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636753,
      "TXT_PINCODE_LOCALITY": "MITTANOOLAHALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636754,
      "TXT_PINCODE_LOCALITY": "PULIKARAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636801,
      "TXT_PINCODE_LOCALITY": "AMMANIMALLAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636802,
      "TXT_PINCODE_LOCALITY": "HANUMANTHAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636803,
      "TXT_PINCODE_LOCALITY": "INDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636804,
      "TXT_PINCODE_LOCALITY": "LALIGAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636805,
      "TXT_PINCODE_LOCALITY": "MALLUPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636806,
      "TXT_PINCODE_LOCALITY": "MARANDAHALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636807,
      "TXT_PINCODE_LOCALITY": "NALLAMPALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636808,
      "TXT_PINCODE_LOCALITY": "PALACODE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636809,
      "TXT_PINCODE_LOCALITY": "PAUPARAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636810,
      "TXT_PINCODE_LOCALITY": "PENNAGARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636811,
      "TXT_PINCODE_LOCALITY": "PERUMBALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636812,
      "TXT_PINCODE_LOCALITY": "PANJAPALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636813,
      "TXT_PINCODE_LOCALITY": "BILIYANUR AGRAHARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636814,
      "TXT_PINCODE_LOCALITY": "ADIYAMANKOTTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636815,
      "TXT_PINCODE_LOCALITY": "NAGAMALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636816,
      "TXT_PINCODE_LOCALITY": "SUNCHALNATHAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636817,
      "TXT_PINCODE_LOCALITY": "HOGENAKKAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636818,
      "TXT_PINCODE_LOCALITY": "ADAGAPADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636819,
      "TXT_PINCODE_LOCALITY": "PAGALAHALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636820,
      "TXT_PINCODE_LOCALITY": "PALAYAM DHARMAPURI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636821,
      "TXT_PINCODE_LOCALITY": "MANYADAHALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636822,
      "TXT_PINCODE_LOCALITY": "AJJANAHALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636823,
      "TXT_PINCODE_LOCALITY": "ANJEHALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636901,
      "TXT_PINCODE_LOCALITY": "A PALLIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636902,
      "TXT_PINCODE_LOCALITY": "HARUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 425599,
      "City District Name": "DHARAMPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636903,
      "TXT_PINCODE_LOCALITY": "HARUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636904,
      "TXT_PINCODE_LOCALITY": "MENISI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636905,
      "TXT_PINCODE_LOCALITY": "PAPPIREDDIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636906,
      "TXT_PINCODE_LOCALITY": "TIRTHAMALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424429,
      "City District Name": "TIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636908,
      "TXT_PINCODE_LOCALITY": "HARUR BAZAAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636909,
      "TXT_PINCODE_LOCALITY": "HANUMANTHEERTHAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636910,
      "TXT_PINCODE_LOCALITY": "BAIRANATHAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636911,
      "TXT_PINCODE_LOCALITY": "KOTTAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636912,
      "TXT_PINCODE_LOCALITY": "BAIRANAICKENPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636913,
      "TXT_PINCODE_LOCALITY": "PUDUPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5903,
      "City District Name": "THENI ALLINAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636914,
      "TXT_PINCODE_LOCALITY": "PETTADAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1892,
      "City District Name": "DHARMAPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 636915,
      "TXT_PINCODE_LOCALITY": "THENKARAI KOTTAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637001,
      "TXT_PINCODE_LOCALITY": "NAMAKKAL H O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637002,
      "TXT_PINCODE_LOCALITY": "THILLAIPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637003,
      "TXT_PINCODE_LOCALITY": "NALLIPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637013,
      "TXT_PINCODE_LOCALITY": "ERANIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637014,
      "TXT_PINCODE_LOCALITY": "KARAKURICHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637015,
      "TXT_PINCODE_LOCALITY": "MOHANUR NORTH",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 302,
      "City District Name": "SALEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637017,
      "TXT_PINCODE_LOCALITY": "ANIYAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637018,
      "TXT_PINCODE_LOCALITY": "PUDUCHATRAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637019,
      "TXT_PINCODE_LOCALITY": "SELLAPPAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637020,
      "TXT_PINCODE_LOCALITY": "VALAYAPATTI SLEM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637021,
      "TXT_PINCODE_LOCALITY": "VARAGUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637022,
      "TXT_PINCODE_LOCALITY": "LADDIVADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637023,
      "TXT_PINCODE_LOCALITY": "PACHAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637024,
      "TXT_PINCODE_LOCALITY": "POTTIREDDIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637025,
      "TXT_PINCODE_LOCALITY": "KALYANI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637026,
      "TXT_PINCODE_LOCALITY": "N PUNDUPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637101,
      "TXT_PINCODE_LOCALITY": "IDAPPADI BAZAAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 302,
      "City District Name": "SALEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637102,
      "TXT_PINCODE_LOCALITY": "KONGANAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637103,
      "TXT_PINCODE_LOCALITY": "MC DONALDS CHOULTRY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637104,
      "TXT_PINCODE_LOCALITY": "THEVUR SALEM - 4733",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637105,
      "TXT_PINCODE_LOCALITY": "VELLANDAIVALASAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637107,
      "TXT_PINCODE_LOCALITY": "POOLAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637108,
      "TXT_PINCODE_LOCALITY": "VELLARIVALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637109,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637110,
      "TXT_PINCODE_LOCALITY": "IRUPALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637111,
      "TXT_PINCODE_LOCALITY": "KULLAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637112,
      "TXT_PINCODE_LOCALITY": "VAIKUNDAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637113,
      "TXT_PINCODE_LOCALITY": "BODINAICKENPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637201,
      "TXT_PINCODE_LOCALITY": "CHITTALANDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637202,
      "TXT_PINCODE_LOCALITY": "TIRUCHENGODE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637203,
      "TXT_PINCODE_LOCALITY": "TIRUCHENGODE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637204,
      "TXT_PINCODE_LOCALITY": "TIRUCHENGODE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637205,
      "TXT_PINCODE_LOCALITY": "KUMARAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637206,
      "TXT_PINCODE_LOCALITY": "TIRUCHENGODE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637207,
      "TXT_PINCODE_LOCALITY": "PARAMATHI (SALEM)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637208,
      "TXT_PINCODE_LOCALITY": "PAUNDAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637209,
      "TXT_PINCODE_LOCALITY": "TIRUCHENGODE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637210,
      "TXT_PINCODE_LOCALITY": "THIRUCHENGODE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637211,
      "TXT_PINCODE_LOCALITY": "TIRUCHENGODU WEST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 302,
      "City District Name": "SALEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637212,
      "TXT_PINCODE_LOCALITY": "VELAGOUNDANPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637213,
      "TXT_PINCODE_LOCALITY": "JADARPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 302,
      "City District Name": "SALEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637214,
      "TXT_PINCODE_LOCALITY": "EMAPALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637215,
      "TXT_PINCODE_LOCALITY": "TIRUCHENGODE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637216,
      "TXT_PINCODE_LOCALITY": "THOKKAVADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637217,
      "TXT_PINCODE_LOCALITY": "VATTUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637265,
      "TXT_PINCODE_LOCALITY": "KUPPANDAPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5977,
      "City District Name": "TIRUCHENGODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637301,
      "TXT_PINCODE_LOCALITY": "SANKARIDURG",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637302,
      "TXT_PINCODE_LOCALITY": "SANKARIDRUG R S",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637303,
      "TXT_PINCODE_LOCALITY": "SANKARI WEST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 302,
      "City District Name": "SALEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637304,
      "TXT_PINCODE_LOCALITY": "THANNIRPANDALPAYALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637305,
      "TXT_PINCODE_LOCALITY": "ANANGUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637306,
      "TXT_PINCODE_LOCALITY": "PADAIVEEDU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637307,
      "TXT_PINCODE_LOCALITY": "MORRU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637401,
      "TXT_PINCODE_LOCALITY": "ANDAGALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637402,
      "TXT_PINCODE_LOCALITY": "BELUKURICHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637403,
      "TXT_PINCODE_LOCALITY": "GURUSWAMIPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637404,
      "TXT_PINCODE_LOCALITY": "KALAPPANAICKENPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637405,
      "TXT_PINCODE_LOCALITY": "MUTHUGAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637406,
      "TXT_PINCODE_LOCALITY": "NAMAGIRIBETTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637407,
      "TXT_PINCODE_LOCALITY": "PUDUPATTI SALEM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637408,
      "TXT_PINCODE_LOCALITY": "RASIPURAM BAZAAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 302,
      "City District Name": "SALEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637409,
      "TXT_PINCODE_LOCALITY": "SENDAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637410,
      "TXT_PINCODE_LOCALITY": "TIRUCHENGODE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637411,
      "TXT_PINCODE_LOCALITY": "SEMMEDU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637412,
      "TXT_PINCODE_LOCALITY": "SINGALANDAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637413,
      "TXT_PINCODE_LOCALITY": "PATTANAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637414,
      "TXT_PINCODE_LOCALITY": "SIRAPALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637415,
      "TXT_PINCODE_LOCALITY": "KAKKAVERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637416,
      "TXT_PINCODE_LOCALITY": "TIRUMALAIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 302,
      "City District Name": "SALEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637417,
      "TXT_PINCODE_LOCALITY": "AKKAIMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637418,
      "TXT_PINCODE_LOCALITY": "PERIA MANALI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 302,
      "City District Name": "SALEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637419,
      "TXT_PINCODE_LOCALITY": "PALAYAPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637420,
      "TXT_PINCODE_LOCALITY": "T JEDARPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637421,
      "TXT_PINCODE_LOCALITY": "POTTANAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637423,
      "TXT_PINCODE_LOCALITY": "ARIYAGOUNDANPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637501,
      "TXT_PINCODE_LOCALITY": "ATTAYAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637502,
      "TXT_PINCODE_LOCALITY": "ELAMPILLAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637503,
      "TXT_PINCODE_LOCALITY": "MALLASAMUDRAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637504,
      "TXT_PINCODE_LOCALITY": "VEMBADITHALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 302,
      "City District Name": "SALEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637505,
      "TXT_PINCODE_LOCALITY": "VENNANDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637506,
      "TXT_PINCODE_LOCALITY": "KAKKAPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637507,
      "TXT_PINCODE_LOCALITY": "MANGALAM SALEM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637508,
      "TXT_PINCODE_LOCALITY": "EDANGANSALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 637610,
      "TXT_PINCODE_LOCALITY": "3 TUAS SOUTH AVENUE 4",
      "NUM_STATE_CD": 292,
      "State Name": "SINGAPORE",
      "NUM_CITYDISTRICT_CD": 444245,
      "City District Name": "SOUTH AVENUE",
      "NUM_COUNTRY_CD": 151
    },
    {
      "NUM_PINCODE": 638001,
      "TXT_PINCODE_LOCALITY": "NAMAKKAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638002,
      "TXT_PINCODE_LOCALITY": "ERODE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 293,
      "City District Name": "ERODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638003,
      "TXT_PINCODE_LOCALITY": "TIRUNAGAR COLONY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638004,
      "TXT_PINCODE_LOCALITY": "CHIKKIAH NAICKER COLLEGE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638005,
      "TXT_PINCODE_LOCALITY": "PERIA AGRAHARAM - 1",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638006,
      "TXT_PINCODE_LOCALITY": "PALLIPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638007,
      "TXT_PINCODE_LOCALITY": "CUVVERY R S",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638008,
      "TXT_PINCODE_LOCALITY": "PUDUPALAIYAM AGHRAHARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638009,
      "TXT_PINCODE_LOCALITY": "SURAMPATTI S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 293,
      "City District Name": "ERODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638010,
      "TXT_PINCODE_LOCALITY": "BOARDS COLONY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638011,
      "TXT_PINCODE_LOCALITY": "MANAL MEDU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 293,
      "City District Name": "ERODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638012,
      "TXT_PINCODE_LOCALITY": "THINDAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 293,
      "City District Name": "ERODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638051,
      "TXT_PINCODE_LOCALITY": "MELAPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638052,
      "TXT_PINCODE_LOCALITY": "PERUNDURAI - 3751",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638053,
      "TXT_PINCODE_LOCALITY": "SANATORIUM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638054,
      "TXT_PINCODE_LOCALITY": "SIRUVALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638055,
      "TXT_PINCODE_LOCALITY": "TINGALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638056,
      "TXT_PINCODE_LOCALITY": "VIJAYAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638057,
      "TXT_PINCODE_LOCALITY": "TUDIPATHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638058,
      "TXT_PINCODE_LOCALITY": "INGUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638059,
      "TXT_PINCODE_LOCALITY": "VELLANKOIL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638060,
      "TXT_PINCODE_LOCALITY": "KANDAPPAGOUNDENLAVASU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638101,
      "TXT_PINCODE_LOCALITY": "ARCHALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638102,
      "TXT_PINCODE_LOCALITY": "CHITTODE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638103,
      "TXT_PINCODE_LOCALITY": "KUNNATHUR ERODE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638104,
      "TXT_PINCODE_LOCALITY": "MODAKURICHI - 4941",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638105,
      "TXT_PINCODE_LOCALITY": "MUTHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638106,
      "TXT_PINCODE_LOCALITY": "MULANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638107,
      "TXT_PINCODE_LOCALITY": "NASIYANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638108,
      "TXT_PINCODE_LOCALITY": "NATHAKADAYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638109,
      "TXT_PINCODE_LOCALITY": "SIVAGIRI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638110,
      "TXT_PINCODE_LOCALITY": "GETTICHEYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638111,
      "TXT_PINCODE_LOCALITY": "VELLAKOIL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638112,
      "TXT_PINCODE_LOCALITY": "VADAMUGAM VELLODU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638115,
      "TXT_PINCODE_LOCALITY": "AVALPUNDURAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638116,
      "TXT_PINCODE_LOCALITY": "KANJIKOIL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638117,
      "TXT_PINCODE_LOCALITY": "KANAGAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638118,
      "TXT_PINCODE_LOCALITY": "THANDAMPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638121,
      "TXT_PINCODE_LOCALITY": "MULIAMPOONDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638122,
      "TXT_PINCODE_LOCALITY": "LAKKAMANAICKENPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638124,
      "TXT_PINCODE_LOCALITY": "GANGAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638125,
      "TXT_PINCODE_LOCALITY": "MINNAPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638126,
      "TXT_PINCODE_LOCALITY": "SINNIAMPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638127,
      "TXT_PINCODE_LOCALITY": "ELUMATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638128,
      "TXT_PINCODE_LOCALITY": "KAVINDAPADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638129,
      "TXT_PINCODE_LOCALITY": "VADUGAPATTI (ERODE)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638130,
      "TXT_PINCODE_LOCALITY": "CHINNAMARUDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638131,
      "TXT_PINCODE_LOCALITY": "KILANKUNDAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638135,
      "TXT_PINCODE_LOCALITY": "SOKKANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638148,
      "TXT_PINCODE_LOCALITY": "KARUPPUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424431,
      "City District Name": "SALEM(VILLUPURAM)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638151,
      "TXT_PINCODE_LOCALITY": "KODUMUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638152,
      "TXT_PINCODE_LOCALITY": "UNJALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638153,
      "TXT_PINCODE_LOCALITY": "GANAPATHIPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638154,
      "TXT_PINCODE_LOCALITY": "PASUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638155,
      "TXT_PINCODE_LOCALITY": "KOLANALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638156,
      "TXT_PINCODE_LOCALITY": "MALAYAMAPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638157,
      "TXT_PINCODE_LOCALITY": "VELLOTTAMPARAPPU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638158,
      "TXT_PINCODE_LOCALITY": "SALAIPUDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638181,
      "TXT_PINCODE_LOCALITY": "POTHANUR SALEM - 5047",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638182,
      "TXT_PINCODE_LOCALITY": "VELUR SLM BAZAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638183,
      "TXT_PINCODE_LOCALITY": "TIRUCHENGODE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 298,
      "City District Name": "NAMAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638184,
      "TXT_PINCODE_LOCALITY": "KOKKARAYANPETTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638201,
      "TXT_PINCODE_LOCALITY": "GUDIMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638202,
      "TXT_PINCODE_LOCALITY": "JAKKARPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638203,
      "TXT_PINCODE_LOCALITY": "KANIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638204,
      "TXT_PINCODE_LOCALITY": "KOMARALINGAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638205,
      "TXT_PINCODE_LOCALITY": "PEDDAPPAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638206,
      "TXT_PINCODE_LOCALITY": "PULAVADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638207,
      "TXT_PINCODE_LOCALITY": "PUNGAMUTHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638208,
      "TXT_PINCODE_LOCALITY": "KADATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638209,
      "TXT_PINCODE_LOCALITY": "KARATHOLUVU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638210,
      "TXT_PINCODE_LOCALITY": "VEDAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638211,
      "TXT_PINCODE_LOCALITY": "DEVANURPUDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638212,
      "TXT_PINCODE_LOCALITY": "KOLUMAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638214,
      "TXT_PINCODE_LOCALITY": "PUDUPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638215,
      "TXT_PINCODE_LOCALITY": "THUNGAVI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638216,
      "TXT_PINCODE_LOCALITY": "ERIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638217,
      "TXT_PINCODE_LOCALITY": "PAPPANKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638301,
      "TXT_PINCODE_LOCALITY": "BHAVANI H.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 293,
      "City District Name": "ERODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638302,
      "TXT_PINCODE_LOCALITY": "BHAVANI CUTCHERY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638303,
      "TXT_PINCODE_LOCALITY": "VARADANALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638311,
      "TXT_PINCODE_LOCALITY": "AMMAPETTAI BHAVANI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638312,
      "TXT_PINCODE_LOCALITY": "PALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638313,
      "TXT_PINCODE_LOCALITY": "KUGALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638314,
      "TXT_PINCODE_LOCALITY": "OLAGADAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638315,
      "TXT_PINCODE_LOCALITY": "SAKTHINAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638316,
      "TXT_PINCODE_LOCALITY": "VASAVI COLLEGE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638317,
      "TXT_PINCODE_LOCALITY": "BUDAPADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638318,
      "TXT_PINCODE_LOCALITY": "KUTTIPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638319,
      "TXT_PINCODE_LOCALITY": "PERIYAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638320,
      "TXT_PINCODE_LOCALITY": "MATHUR ERODE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638321,
      "TXT_PINCODE_LOCALITY": "MEVANI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638322,
      "TXT_PINCODE_LOCALITY": "METTUPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638323,
      "TXT_PINCODE_LOCALITY": "PERUNDALAIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638324,
      "TXT_PINCODE_LOCALITY": "SINGAMPETTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638325,
      "TXT_PINCODE_LOCALITY": "VELLI TIRUPUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638326,
      "TXT_PINCODE_LOCALITY": "NERINJIPETTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638327,
      "TXT_PINCODE_LOCALITY": "DALAVOIPETTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638328,
      "TXT_PINCODE_LOCALITY": "CHINNAPALLAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638401,
      "TXT_PINCODE_LOCALITY": "SATYAMANGALAM CBE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638402,
      "TXT_PINCODE_LOCALITY": "UKKARAM S. O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 293,
      "City District Name": "ERODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638403,
      "TXT_PINCODE_LOCALITY": "YAVILIPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638404,
      "TXT_PINCODE_LOCALITY": "UKKARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638405,
      "TXT_PINCODE_LOCALITY": "RAJANAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 293,
      "City District Name": "ERODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638406,
      "TXT_PINCODE_LOCALITY": "CHIKKARASAMPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638451,
      "TXT_PINCODE_LOCALITY": "BHAVANISAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638452,
      "TXT_PINCODE_LOCALITY": "GOBICHETTIPALAYAM H O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638453,
      "TXT_PINCODE_LOCALITY": "KARATTADIPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638454,
      "TXT_PINCODE_LOCALITY": "KASIPALAYAM - 2293",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638455,
      "TXT_PINCODE_LOCALITY": "KAVINDAPADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638456,
      "TXT_PINCODE_LOCALITY": "KOLAPPALURE CBE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638457,
      "TXT_PINCODE_LOCALITY": "KURUMANDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638458,
      "TXT_PINCODE_LOCALITY": "NAMBIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638459,
      "TXT_PINCODE_LOCALITY": "PULIMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 293,
      "City District Name": "ERODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638460,
      "TXT_PINCODE_LOCALITY": "SAVAKATTUPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638461,
      "TXT_PINCODE_LOCALITY": "TALAVADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638462,
      "TXT_PINCODE_LOCALITY": "VEMANDANPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638463,
      "TXT_PINCODE_LOCALITY": "AYYAMPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1906,
      "City District Name": "THANJAVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638464,
      "TXT_PINCODE_LOCALITY": "ARASUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638465,
      "TXT_PINCODE_LOCALITY": "ELATHUR CHETTIPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638466,
      "TXT_PINCODE_LOCALITY": "KADATHUR GOBI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638467,
      "TXT_PINCODE_LOCALITY": "EMMANPUNDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638468,
      "TXT_PINCODE_LOCALITY": "ODATHURAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638469,
      "TXT_PINCODE_LOCALITY": "PARIYUR VELLAPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638470,
      "TXT_PINCODE_LOCALITY": "PERIAPULIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638471,
      "TXT_PINCODE_LOCALITY": "SELANGAPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638472,
      "TXT_PINCODE_LOCALITY": "IRUMBORAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638474,
      "TXT_PINCODE_LOCALITY": "AKKARAIKODIVERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638475,
      "TXT_PINCODE_LOCALITY": "PATTIMANIYAKARAPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638476,
      "TXT_PINCODE_LOCALITY": "GOBIOHETTI PALAYAM SOUTH",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638501,
      "TXT_PINCODE_LOCALITY": "ANTHIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638502,
      "TXT_PINCODE_LOCALITY": "ATHANI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638503,
      "TXT_PINCODE_LOCALITY": "DASAPPAGOUNDANPUDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638504,
      "TXT_PINCODE_LOCALITY": "GURUVAREDDIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638505,
      "TXT_PINCODE_LOCALITY": "KALLIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3035,
      "City District Name": "DINDIGUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638506,
      "TXT_PINCODE_LOCALITY": "THUCKAICKANPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638507,
      "TXT_PINCODE_LOCALITY": "KODIVERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638509,
      "TXT_PINCODE_LOCALITY": "KEMPANAICKENPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638510,
      "TXT_PINCODE_LOCALITY": "ENNAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638511,
      "TXT_PINCODE_LOCALITY": "BRAHMADESAM (ED)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638512,
      "TXT_PINCODE_LOCALITY": "PUNJAITHURAIYAMPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638514,
      "TXT_PINCODE_LOCALITY": "KANAKKAMPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638601,
      "TXT_PINCODE_LOCALITY": "TIRUPUR H O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638602,
      "TXT_PINCODE_LOCALITY": "TIRUPUVANAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638603,
      "TXT_PINCODE_LOCALITY": "KUMARANAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638604,
      "TXT_PINCODE_LOCALITY": "ARASAMPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638605,
      "TXT_PINCODE_LOCALITY": "VEERAPANDI TIRUPUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638606,
      "TXT_PINCODE_LOCALITY": "VIJAYAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638607,
      "TXT_PINCODE_LOCALITY": "TIRUPUR EAST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638612,
      "TXT_PINCODE_LOCALITY": "MANNARAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638651,
      "TXT_PINCODE_LOCALITY": "ALANGIAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638652,
      "TXT_PINCODE_LOCALITY": "ANUPPAPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638653,
      "TXT_PINCODE_LOCALITY": "ANNUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638654,
      "TXT_PINCODE_LOCALITY": "AVANASHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638655,
      "TXT_PINCODE_LOCALITY": "CHEYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638656,
      "TXT_PINCODE_LOCALITY": "CHOLAKADAI STREET",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638657,
      "TXT_PINCODE_LOCALITY": "ELLISNAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638658,
      "TXT_PINCODE_LOCALITY": "KARADIPAVI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638659,
      "TXT_PINCODE_LOCALITY": "KARUMATHAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638660,
      "TXT_PINCODE_LOCALITY": "TIRUPUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638661,
      "TXT_PINCODE_LOCALITY": "KOLATHUPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638662,
      "TXT_PINCODE_LOCALITY": "KUPPUSWAMYNAIDUPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638663,
      "TXT_PINCODE_LOCALITY": "MANGALAM-CBE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638664,
      "TXT_PINCODE_LOCALITY": "PALLADAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638665,
      "TXT_PINCODE_LOCALITY": "PALLIKALIPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638666,
      "TXT_PINCODE_LOCALITY": "PERUMANALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638667,
      "TXT_PINCODE_LOCALITY": "PONGALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638668,
      "TXT_PINCODE_LOCALITY": "SOMANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638669,
      "TXT_PINCODE_LOCALITY": "SULESWARANPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638670,
      "TXT_PINCODE_LOCALITY": "KARAVALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638671,
      "TXT_PINCODE_LOCALITY": "KETTANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638672,
      "TXT_PINCODE_LOCALITY": "DALVOIPATNAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638673,
      "TXT_PINCODE_LOCALITY": "KOLINJIVADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638675,
      "TXT_PINCODE_LOCALITY": "JALAKARISAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638676,
      "TXT_PINCODE_LOCALITY": "KANAKKAMPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638677,
      "TXT_PINCODE_LOCALITY": "KANDIANKOLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638678,
      "TXT_PINCODE_LOCALITY": "MANDIRIPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638679,
      "TXT_PINCODE_LOCALITY": "PONNIVADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638680,
      "TXT_PINCODE_LOCALITY": "SAMALAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638681,
      "TXT_PINCODE_LOCALITY": "SELAMPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638682,
      "TXT_PINCODE_LOCALITY": "SENJERIPUTHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638683,
      "TXT_PINCODE_LOCALITY": "VIRAJIMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638684,
      "TXT_PINCODE_LOCALITY": "KARAIPUDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638685,
      "TXT_PINCODE_LOCALITY": "VAHARAYANPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638687,
      "TXT_PINCODE_LOCALITY": "IDUVAMPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638689,
      "TXT_PINCODE_LOCALITY": "MALAYADIPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638690,
      "TXT_PINCODE_LOCALITY": "MANAKKADAVU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638691,
      "TXT_PINCODE_LOCALITY": "KANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638692,
      "TXT_PINCODE_LOCALITY": "GETHELREV",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638693,
      "TXT_PINCODE_LOCALITY": "ELAVANTHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638694,
      "TXT_PINCODE_LOCALITY": "J KRISHNAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638695,
      "TXT_PINCODE_LOCALITY": "KATTUR PALLADAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638696,
      "TXT_PINCODE_LOCALITY": "THOPPANPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638697,
      "TXT_PINCODE_LOCALITY": "POGALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638698,
      "TXT_PINCODE_LOCALITY": "PONNAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638699,
      "TXT_PINCODE_LOCALITY": "MURIYANDAMPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638701,
      "TXT_PINCODE_LOCALITY": "KANGAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638702,
      "TXT_PINCODE_LOCALITY": "KUNDADAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638703,
      "TXT_PINCODE_LOCALITY": "UDIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638704,
      "TXT_PINCODE_LOCALITY": "PAPPINIPACHAPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638705,
      "TXT_PINCODE_LOCALITY": "PARANJERVALI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638706,
      "TXT_PINCODE_LOCALITY": "SANKARANDAMPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638707,
      "TXT_PINCODE_LOCALITY": "KIRANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638708,
      "TXT_PINCODE_LOCALITY": "SIRUKINAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638709,
      "TXT_PINCODE_LOCALITY": "SADAYAPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638710,
      "TXT_PINCODE_LOCALITY": "TAYAMPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638711,
      "TXT_PINCODE_LOCALITY": "KADAYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638712,
      "TXT_PINCODE_LOCALITY": "BELLAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638751,
      "TXT_PINCODE_LOCALITY": "UTTUKULI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638752,
      "TXT_PINCODE_LOCALITY": "UTTUKULI R S",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638754,
      "TXT_PINCODE_LOCALITY": "PUNJAIPALATHOLUVU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638801,
      "TXT_PINCODE_LOCALITY": "KODANGIPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638802,
      "TXT_PINCODE_LOCALITY": "MUPERIPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1844,
      "City District Name": "MADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638803,
      "TXT_PINCODE_LOCALITY": "KAMANAICKENPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638804,
      "TXT_PINCODE_LOCALITY": "KARAIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638805,
      "TXT_PINCODE_LOCALITY": "VANJIPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638806,
      "TXT_PINCODE_LOCALITY": "VADAMBACHERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638807,
      "TXT_PINCODE_LOCALITY": "VELLAVARIPUDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638808,
      "TXT_PINCODE_LOCALITY": "VIRUMANDAMPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638809,
      "TXT_PINCODE_LOCALITY": "KALLIVALASU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638810,
      "TXT_PINCODE_LOCALITY": "PALLADAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638812,
      "TXT_PINCODE_LOCALITY": "CHENGAPALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424433,
      "City District Name": "PERIYAR(ERODE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 638852,
      "TXT_PINCODE_LOCALITY": "NATRAMPALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2103,
      "City District Name": "ARCOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639001,
      "TXT_PINCODE_LOCALITY": "RAMAKRISHNAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639002,
      "TXT_PINCODE_LOCALITY": "SENGUNDAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639003,
      "TXT_PINCODE_LOCALITY": "TIRUMANILAYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639004,
      "TXT_PINCODE_LOCALITY": "PASUPATHIPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639005,
      "TXT_PINCODE_LOCALITY": "THANTHONIMALAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639006,
      "TXT_PINCODE_LOCALITY": "VENGAMEDUE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639007,
      "TXT_PINCODE_LOCALITY": "KARUR COLLECTORATE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639008,
      "TXT_PINCODE_LOCALITY": "ANDANKOIL S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639009,
      "TXT_PINCODE_LOCALITY": "KARUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639051,
      "TXT_PINCODE_LOCALITY": "NERUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639052,
      "TXT_PINCODE_LOCALITY": "PAVITRAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639053,
      "TXT_PINCODE_LOCALITY": "PUNJAIKALAKURICHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639054,
      "TXT_PINCODE_LOCALITY": "PANJAMADEVI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639101,
      "TXT_PINCODE_LOCALITY": "ANDANALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639102,
      "TXT_PINCODE_LOCALITY": "KRISHNARAYAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639103,
      "TXT_PINCODE_LOCALITY": "KULUMANI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5978,
      "City District Name": "TIRUCHIRAPPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639104,
      "TXT_PINCODE_LOCALITY": "KULITTALAI H O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639105,
      "TXT_PINCODE_LOCALITY": "LALAPET",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639106,
      "TXT_PINCODE_LOCALITY": "MAHADANAPURAM KARUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639107,
      "TXT_PINCODE_LOCALITY": "MARUDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639108,
      "TXT_PINCODE_LOCALITY": "MAYANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639109,
      "TXT_PINCODE_LOCALITY": "KARUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639110,
      "TXT_PINCODE_LOCALITY": "NUNGAVARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639111,
      "TXT_PINCODE_LOCALITY": "PARAMATHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639112,
      "TXT_PINCODE_LOCALITY": "PETTAIVAITHALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639113,
      "TXT_PINCODE_LOCALITY": "PUGALUR SUGAR FACTORY - 3879",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639114,
      "TXT_PINCODE_LOCALITY": "PULIYUR CEMENT FACTORY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639115,
      "TXT_PINCODE_LOCALITY": "TIRUCHIRAPALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 425848,
      "City District Name": "THIRUCHIRAPALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639116,
      "TXT_PINCODE_LOCALITY": "VANGAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639117,
      "TXT_PINCODE_LOCALITY": "VELAYUTHANPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639118,
      "TXT_PINCODE_LOCALITY": "VELLIYANAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639119,
      "TXT_PINCODE_LOCALITY": "PANJAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639120,
      "TXT_PINCODE_LOCALITY": "VAIGALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639121,
      "TXT_PINCODE_LOCALITY": "VANGAL EAST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639122,
      "TXT_PINCODE_LOCALITY": "MELAPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639123,
      "TXT_PINCODE_LOCALITY": "PANIKKAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639124,
      "TXT_PINCODE_LOCALITY": "SIVAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639125,
      "TXT_PINCODE_LOCALITY": "TIRUKKANPULIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639126,
      "TXT_PINCODE_LOCALITY": "S VELLALAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639128,
      "TXT_PINCODE_LOCALITY": "KATTUMUNNUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639129,
      "TXT_PINCODE_LOCALITY": "CHINTALAVADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639130,
      "TXT_PINCODE_LOCALITY": "INNUNGUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639131,
      "TXT_PINCODE_LOCALITY": "METTUMAHADANAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639132,
      "TXT_PINCODE_LOCALITY": "VENJAMANGUDALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639133,
      "TXT_PINCODE_LOCALITY": "KUPPACHIPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639134,
      "TXT_PINCODE_LOCALITY": "MANMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639135,
      "TXT_PINCODE_LOCALITY": "VETTAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639136,
      "TXT_PINCODE_LOCALITY": "NANNIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639137,
      "TXT_PINCODE_LOCALITY": "SOMUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639138,
      "TXT_PINCODE_LOCALITY": "SENGAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639139,
      "TXT_PINCODE_LOCALITY": "PAPPAKKAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639140,
      "TXT_PINCODE_LOCALITY": "KUPPAMPARAMATHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639141,
      "TXT_PINCODE_LOCALITY": "KOYAMPALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639142,
      "TXT_PINCODE_LOCALITY": "MINNAMPALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639143,
      "TXT_PINCODE_LOCALITY": "MOOLIMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639144,
      "TXT_PINCODE_LOCALITY": "NANJAIPUGALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639145,
      "TXT_PINCODE_LOCALITY": "HIRANYAMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639146,
      "TXT_PINCODE_LOCALITY": "KARUPATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639147,
      "TXT_PINCODE_LOCALITY": "TIMMACHIPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639149,
      "TXT_PINCODE_LOCALITY": "POTHARAVUTHAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639201,
      "TXT_PINCODE_LOCALITY": "ARAVAKURICHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639202,
      "TXT_PINCODE_LOCALITY": "CHINNADHARAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639203,
      "TXT_PINCODE_LOCALITY": "ISANATHAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639204,
      "TXT_PINCODE_LOCALITY": "NANJAITHALAIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639205,
      "TXT_PINCODE_LOCALITY": "PALLAPATTI KARUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639206,
      "TXT_PINCODE_LOCALITY": "TENNILAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639207,
      "TXT_PINCODE_LOCALITY": "PALLAPATTI BAZAAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639208,
      "TXT_PINCODE_LOCALITY": "RAJAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639209,
      "TXT_PINCODE_LOCALITY": "GUDALUR TENNILAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639210,
      "TXT_PINCODE_LOCALITY": "SENDAMANGALAM (KARUR)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639211,
      "TXT_PINCODE_LOCALITY": "MONJANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639212,
      "TXT_PINCODE_LOCALITY": "KANNIVEDI (KARUR)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639213,
      "TXT_PINCODE_LOCALITY": "MALAMEDU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 232,
      "City District Name": "KARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639611,
      "TXT_PINCODE_LOCALITY": "MUKKUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4382,
      "City District Name": "MANAMADURAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 639705,
      "TXT_PINCODE_LOCALITY": "CHINTHALAVADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4117,
      "City District Name": "KRISHNARAYAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641001,
      "TXT_PINCODE_LOCALITY": "SUKRAVARPETTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641002,
      "TXT_PINCODE_LOCALITY": "COIMBATORE NORTH",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641003,
      "TXT_PINCODE_LOCALITY": "LALWLEY ROAD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641004,
      "TXT_PINCODE_LOCALITY": "PEELAMEDU EAST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641005,
      "TXT_PINCODE_LOCALITY": "SINGANALLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641006,
      "TXT_PINCODE_LOCALITY": "MUTHUGOUNDENPUDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641007,
      "TXT_PINCODE_LOCALITY": "INSTITUTE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641008,
      "TXT_PINCODE_LOCALITY": "KUNIYAMUTHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641009,
      "TXT_PINCODE_LOCALITY": "COIMBATORE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641010,
      "TXT_PINCODE_LOCALITY": "PERUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641011,
      "TXT_PINCODE_LOCALITY": "SAIBABA MISSION",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641012,
      "TXT_PINCODE_LOCALITY": "TATABAD EAST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641013,
      "TXT_PINCODE_LOCALITY": "GOVT COLLEGE OF TECHNOLOGY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641014,
      "TXT_PINCODE_LOCALITY": "COIMBATORE AERODROME",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641015,
      "TXT_PINCODE_LOCALITY": "UPPILPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641016,
      "TXT_PINCODE_LOCALITY": "ONDIPUDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641017,
      "TXT_PINCODE_LOCALITY": "KURUDAMPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641018,
      "TXT_PINCODE_LOCALITY": "COIMBATORE R S",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641019,
      "TXT_PINCODE_LOCALITY": "COIMBATORE PRESS COLONY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641020,
      "TXT_PINCODE_LOCALITY": "SRI RAMAKRISHNA VIDYALAYA",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641021,
      "TXT_PINCODE_LOCALITY": "COIMBATORE IND ESTATE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641022,
      "TXT_PINCODE_LOCALITY": "NGGO COLONY CBE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641023,
      "TXT_PINCODE_LOCALITY": "KONAVAIKALPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641024,
      "TXT_PINCODE_LOCALITY": "SUNDARAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641025,
      "TXT_PINCODE_LOCALITY": "VELANDIPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641026,
      "TXT_PINCODE_LOCALITY": "KOMARAPALAYAM-CBE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641027,
      "TXT_PINCODE_LOCALITY": "RATNAPURI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641028,
      "TXT_PINCODE_LOCALITY": "SOWRIPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641029,
      "TXT_PINCODE_LOCALITY": "CHERAN NAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641030,
      "TXT_PINCODE_LOCALITY": "KAVINDAMPALAVAM COLONY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641031,
      "TXT_PINCODE_LOCALITY": "NARASIMHANAICKENPALYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641032,
      "TXT_PINCODE_LOCALITY": "OTHAKALMANDAPAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641033,
      "TXT_PINCODE_LOCALITY": "NEELIKONAMPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641034,
      "TXT_PINCODE_LOCALITY": "TUDIYALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641035,
      "TXT_PINCODE_LOCALITY": "SARAVANAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641036,
      "TXT_PINCODE_LOCALITY": "NANJUNDAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641037,
      "TXT_PINCODE_LOCALITY": "PAPPANAICKENPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641038,
      "TXT_PINCODE_LOCALITY": "KUPPAKONANPUDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641039,
      "TXT_PINCODE_LOCALITY": "TELUNGAPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641040,
      "TXT_PINCODE_LOCALITY": "SUBRAMANIAPURAM MADURAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641041,
      "TXT_PINCODE_LOCALITY": "PAPPANAICKENPUDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641042,
      "TXT_PINCODE_LOCALITY": "KOVAIPUDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641043,
      "TXT_PINCODE_LOCALITY": "METTUPALAYAM ROAD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641044,
      "TXT_PINCODE_LOCALITY": "SIDDAPUDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641045,
      "TXT_PINCODE_LOCALITY": "RAMANTHAPURAM CBE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641046,
      "TXT_PINCODE_LOCALITY": "MARUTHAMALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641047,
      "TXT_PINCODE_LOCALITY": "JOTHIPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641048,
      "TXT_PINCODE_LOCALITY": "VELLANAIPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641049,
      "TXT_PINCODE_LOCALITY": "CHINNAVEDAMPATTI S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641050,
      "TXT_PINCODE_LOCALITY": "MALUMICHAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641051,
      "TXT_PINCODE_LOCALITY": "COIMBATORE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641052,
      "TXT_PINCODE_LOCALITY": "COIMBATORE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641053,
      "TXT_PINCODE_LOCALITY": "COIMBATORE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641060,
      "TXT_PINCODE_LOCALITY": "COIMBATORE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641061,
      "TXT_PINCODE_LOCALITY": "KALAPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641062,
      "TXT_PINCODE_LOCALITY": "SINNIMPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641063,
      "TXT_PINCODE_LOCALITY": "COIMBATORE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641064,
      "TXT_PINCODE_LOCALITY": "SUNDAKAMUTHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641065,
      "TXT_PINCODE_LOCALITY": "NILAMBUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641066,
      "TXT_PINCODE_LOCALITY": "COIMBATORE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641101,
      "TXT_PINCODE_LOCALITY": "ALANDURAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641102,
      "TXT_PINCODE_LOCALITY": "ATHIKADAVU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641103,
      "TXT_PINCODE_LOCALITY": "IRUGUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641104,
      "TXT_PINCODE_LOCALITY": "KARAMADAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641105,
      "TXT_PINCODE_LOCALITY": "ETTIMADAI - 1422",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641106,
      "TXT_PINCODE_LOCALITY": "PILLUR DAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641107,
      "TXT_PINCODE_LOCALITY": "SARKAR SAMAKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641108,
      "TXT_PINCODE_LOCALITY": "TADAGAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641109,
      "TXT_PINCODE_LOCALITY": "THODAMUTHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641110,
      "TXT_PINCODE_LOCALITY": "VAIYAMPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641111,
      "TXT_PINCODE_LOCALITY": "VELLALUR C B E",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641112,
      "TXT_PINCODE_LOCALITY": "PARLI POWER HOUSE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641113,
      "TXT_PINCODE_LOCALITY": "SEELAIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641114,
      "TXT_PINCODE_LOCALITY": "THOLAMPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641115,
      "TXT_PINCODE_LOCALITY": "VALLIAMKADU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641116,
      "TXT_PINCODE_LOCALITY": "BHUJANGANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641117,
      "TXT_PINCODE_LOCALITY": "MARUDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641118,
      "TXT_PINCODE_LOCALITY": "THEKKAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641120,
      "TXT_PINCODE_LOCALITY": "NEGAMAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641201,
      "TXT_PINCODE_LOCALITY": "CHETTIPALAYAM - 999",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641202,
      "TXT_PINCODE_LOCALITY": "GANAPATHI AGRAHARAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641301,
      "TXT_PINCODE_LOCALITY": "METTUPALAYAM EAST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641302,
      "TXT_PINCODE_LOCALITY": "SIRUMUGAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641303,
      "TXT_PINCODE_LOCALITY": "NELLITHURAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641304,
      "TXT_PINCODE_LOCALITY": "AKKARAISENGAPALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641305,
      "TXT_PINCODE_LOCALITY": "METTUPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641401,
      "TXT_PINCODE_LOCALITY": "KANGAYAMPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641402,
      "TXT_PINCODE_LOCALITY": "SLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641404,
      "TXT_PINCODE_LOCALITY": "KALINGAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641405,
      "TXT_PINCODE_LOCALITY": "KANNAMPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641406,
      "TXT_PINCODE_LOCALITY": "MUTHUGOUNDENPUDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641407,
      "TXT_PINCODE_LOCALITY": "ARASUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641408,
      "TXT_PINCODE_LOCALITY": "APPANAICKENPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641601,
      "TXT_PINCODE_LOCALITY": "JPB GARDEN",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641602,
      "TXT_PINCODE_LOCALITY": "TIRUPPUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5987,
      "City District Name": "TIRUPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641603,
      "TXT_PINCODE_LOCALITY": "TIRUPUR B. O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5987,
      "City District Name": "TIRUPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641604,
      "TXT_PINCODE_LOCALITY": "TIRUPPUR BAZAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641605,
      "TXT_PINCODE_LOCALITY": "TIRUPUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641606,
      "TXT_PINCODE_LOCALITY": "MANIYARAMPALAYAM ROAD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641607,
      "TXT_PINCODE_LOCALITY": "APPACHI NAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641608,
      "TXT_PINCODE_LOCALITY": "TIRUPUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641609,
      "TXT_PINCODE_LOCALITY": "COIMBATORE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641610,
      "TXT_PINCODE_LOCALITY": "TIRUPUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5987,
      "City District Name": "TIRUPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641622,
      "TXT_PINCODE_LOCALITY": "SEMMIPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4949,
      "City District Name": "PALLADAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641632,
      "TXT_PINCODE_LOCALITY": "THAMBAGOUNDENPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 442970,
      "City District Name": "COIMBATORE SOUTH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641652,
      "TXT_PINCODE_LOCALITY": "ANUPPAPALAYAM S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5987,
      "City District Name": "TIRUPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641653,
      "TXT_PINCODE_LOCALITY": "ANNUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641654,
      "TXT_PINCODE_LOCALITY": "AVANASH S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5987,
      "City District Name": "TIRUPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641655,
      "TXT_PINCODE_LOCALITY": "ALATHUR B.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641658,
      "TXT_PINCODE_LOCALITY": "KARADIVAVI ROAD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641659,
      "TXT_PINCODE_LOCALITY": "KADUVETTIPALAYAM POST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641660,
      "TXT_PINCODE_LOCALITY": "ERODE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 293,
      "City District Name": "ERODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641662,
      "TXT_PINCODE_LOCALITY": "SUKKAMPALAYAM VILLAGE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641663,
      "TXT_PINCODE_LOCALITY": "AVINASHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5987,
      "City District Name": "TIRUPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641664,
      "TXT_PINCODE_LOCALITY": "TIRUPPUR S. O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5987,
      "City District Name": "TIRUPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641665,
      "TXT_PINCODE_LOCALITY": "PALLIKALIPALAYAM S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641666,
      "TXT_PINCODE_LOCALITY": "EETTIVEERAMPALAYAM POST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641667,
      "TXT_PINCODE_LOCALITY": "PONGALUR S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641668,
      "TXT_PINCODE_LOCALITY": "SOMANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641669,
      "TXT_PINCODE_LOCALITY": "SULTANPET S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641670,
      "TXT_PINCODE_LOCALITY": "UPPILIPALAYAM B.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5987,
      "City District Name": "TIRUPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641671,
      "TXT_PINCODE_LOCALITY": "PALLADAM TK",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641687,
      "TXT_PINCODE_LOCALITY": "TIRUPUR S. O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5987,
      "City District Name": "TIRUPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641697,
      "TXT_PINCODE_LOCALITY": "ANNUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 641701,
      "TXT_PINCODE_LOCALITY": "SHIVANMALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3745,
      "City District Name": "KANGEYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642001,
      "TXT_PINCODE_LOCALITY": "POLLACHI H O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642002,
      "TXT_PINCODE_LOCALITY": "T KOTTAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642003,
      "TXT_PINCODE_LOCALITY": "MAKKINAICKENPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642004,
      "TXT_PINCODE_LOCALITY": "UTTUKULI ZAMIN",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642005,
      "TXT_PINCODE_LOCALITY": "POLLACHI.",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642006,
      "TXT_PINCODE_LOCALITY": "SULESWARANPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642007,
      "TXT_PINCODE_LOCALITY": "ANGALAKURICHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642051,
      "TXT_PINCODE_LOCALITY": "DHALIYUR (JALLIPATTI)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642052,
      "TXT_PINCODE_LOCALITY": "AKILANDAPURAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 424437,
      "City District Name": "CHIDAMBARANAR(TOOTHUKUDI)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642053,
      "TXT_PINCODE_LOCALITY": "RAMAPATTINAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642054,
      "TXT_PINCODE_LOCALITY": "KANJAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642055,
      "TXT_PINCODE_LOCALITY": "MANNUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642101,
      "TXT_PINCODE_LOCALITY": "ALIYARNAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642102,
      "TXT_PINCODE_LOCALITY": "AMARAVATHI NAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642103,
      "TXT_PINCODE_LOCALITY": "AMBARAMPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642104,
      "TXT_PINCODE_LOCALITY": "ANAMALAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642105,
      "TXT_PINCODE_LOCALITY": "WATERFALLS ESTATE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642106,
      "TXT_PINCODE_LOCALITY": "CHINCHONA",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642107,
      "TXT_PINCODE_LOCALITY": "GOMANGALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642108,
      "TXT_PINCODE_LOCALITY": "IYERPADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642109,
      "TXT_PINCODE_LOCALITY": "KINATHUKADAVU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642110,
      "TXT_PINCODE_LOCALITY": "KOVILPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642111,
      "TXT_PINCODE_LOCALITY": "KRISHNAPURAM CBE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642112,
      "TXT_PINCODE_LOCALITY": "UDUMALPET",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642113,
      "TXT_PINCODE_LOCALITY": "MADATHUKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642114,
      "TXT_PINCODE_LOCALITY": "MALAYANDIPATTINAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642115,
      "TXT_PINCODE_LOCALITY": "MANAMBOLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642116,
      "TXT_PINCODE_LOCALITY": "MINPARAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642117,
      "TXT_PINCODE_LOCALITY": "MUDIS",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642118,
      "TXT_PINCODE_LOCALITY": "MURUGALI BAZAAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642119,
      "TXT_PINCODE_LOCALITY": "NAVAMALAI CAMP",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642120,
      "TXT_PINCODE_LOCALITY": "MURUGANPALAYAM (ANDIPALAYAM)",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642122,
      "TXT_PINCODE_LOCALITY": "PULANKINAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642123,
      "TXT_PINCODE_LOCALITY": "SAMATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642124,
      "TXT_PINCODE_LOCALITY": "SHOLAYAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642125,
      "TXT_PINCODE_LOCALITY": "SHOLAYARNAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642126,
      "TXT_PINCODE_LOCALITY": "ERIPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642127,
      "TXT_PINCODE_LOCALITY": "VAZHAITHOTTAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642128,
      "TXT_PINCODE_LOCALITY": "VENKATESA MILLS",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642129,
      "TXT_PINCODE_LOCALITY": "VETTAIKARANPUDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642130,
      "TXT_PINCODE_LOCALITY": "KARAMALAI BAZAAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642131,
      "TXT_PINCODE_LOCALITY": "PANNIMADE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642132,
      "TXT_PINCODE_LOCALITY": "VALAVADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642133,
      "TXT_PINCODE_LOCALITY": "SETHURMADAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642134,
      "TXT_PINCODE_LOCALITY": "SOMANDURAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642135,
      "TXT_PINCODE_LOCALITY": "KADAMPARAI DAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642136,
      "TXT_PINCODE_LOCALITY": "RAMANAMUDALIPUDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642137,
      "TXT_PINCODE_LOCALITY": "CHINNANEGAMAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642138,
      "TXT_PINCODE_LOCALITY": "DHULLY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642139,
      "TXT_PINCODE_LOCALITY": "THIRUMURTHINAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642140,
      "TXT_PINCODE_LOCALITY": "ODAYAKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642141,
      "TXT_PINCODE_LOCALITY": "TOPSLIP",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642142,
      "TXT_PINCODE_LOCALITY": "VADAKKIPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642143,
      "TXT_PINCODE_LOCALITY": "SULAKKAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642144,
      "TXT_PINCODE_LOCALITY": "PORAVIPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642145,
      "TXT_PINCODE_LOCALITY": "SOKKANUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642146,
      "TXT_PINCODE_LOCALITY": "THAMARAIKULAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6221,
      "City District Name": "VIRUDHUNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642147,
      "TXT_PINCODE_LOCALITY": "METTUPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642148,
      "TXT_PINCODE_LOCALITY": "DIVANSAPUDUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642149,
      "TXT_PINCODE_LOCALITY": "PERIAPODU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642152,
      "TXT_PINCODE_LOCALITY": "KADAMPARAI POWER HOUSE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642153,
      "TXT_PINCODE_LOCALITY": "SIRUKUNDRA",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642154,
      "TXT_PINCODE_LOCALITY": "UDUMALAIPETTAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 444126,
      "City District Name": "TIRUPPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642195,
      "TXT_PINCODE_LOCALITY": "THIRUMANGALAKURUCHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5947,
      "City District Name": "THOOTHUKKUDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642201,
      "TXT_PINCODE_LOCALITY": "METREATHI VILLAGE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642202,
      "TXT_PINCODE_LOCALITY": "KATTAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642203,
      "TXT_PINCODE_LOCALITY": "KANIYUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642204,
      "TXT_PINCODE_LOCALITY": "KOMARALINGAM S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642205,
      "TXT_PINCODE_LOCALITY": "PEDDAPPAMPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642206,
      "TXT_PINCODE_LOCALITY": "PULAVADI S.O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642207,
      "TXT_PINCODE_LOCALITY": "UDUKKAMPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 1843,
      "City District Name": "COIMBATORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642621,
      "TXT_PINCODE_LOCALITY": "AYYAMPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 282,
      "City District Name": "PALANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 642701,
      "TXT_PINCODE_LOCALITY": "PERIYAKUMARAPALAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 2981,
      "City District Name": "DHARAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643001,
      "TXT_PINCODE_LOCALITY": "UDAGAMANDALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 441279,
      "City District Name": "NILGIRIS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643002,
      "TXT_PINCODE_LOCALITY": "STONE HOUSE HILL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643003,
      "TXT_PINCODE_LOCALITY": "LOVEDALE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643004,
      "TXT_PINCODE_LOCALITY": "FERN HILL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643005,
      "TXT_PINCODE_LOCALITY": "OOTY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 425902,
      "City District Name": "NILGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643006,
      "TXT_PINCODE_LOCALITY": "FINGERPOST",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643007,
      "TXT_PINCODE_LOCALITY": "DR BASUVAIAH NAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643051,
      "TXT_PINCODE_LOCALITY": "THUNERI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643052,
      "TXT_PINCODE_LOCALITY": "HULLATHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643053,
      "TXT_PINCODE_LOCALITY": "MUTHORAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643054,
      "TXT_PINCODE_LOCALITY": "M PALADA",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643055,
      "TXT_PINCODE_LOCALITY": "IDUPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643101,
      "TXT_PINCODE_LOCALITY": "COONOOR H O",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643102,
      "TXT_PINCODE_LOCALITY": "COONOOR R S",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 441279,
      "City District Name": "NILGIRIS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643103,
      "TXT_PINCODE_LOCALITY": "PASTEUR INSTITUTE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643104,
      "TXT_PINCODE_LOCALITY": "SPRINGFIELD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643105,
      "TXT_PINCODE_LOCALITY": "OTTUPATTARAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 441279,
      "City District Name": "NILGIRIS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643106,
      "TXT_PINCODE_LOCALITY": "BURLIAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643124,
      "TXT_PINCODE_LOCALITY": "THENSITHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 300,
      "City District Name": "POLLACHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643201,
      "TXT_PINCODE_LOCALITY": "ARAVENU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643202,
      "TXT_PINCODE_LOCALITY": "CORDITE FACTORY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643203,
      "TXT_PINCODE_LOCALITY": "BOLACOLA",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643204,
      "TXT_PINCODE_LOCALITY": "CHAMRAJ ESTATE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643205,
      "TXT_PINCODE_LOCALITY": "CHERAMBADI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643206,
      "TXT_PINCODE_LOCALITY": "DENADCOMBAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643207,
      "TXT_PINCODE_LOCALITY": "DEVARSHOLA",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643209,
      "TXT_PINCODE_LOCALITY": "EMARALD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643210,
      "TXT_PINCODE_LOCALITY": "GLENMORGAN",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643211,
      "TXT_PINCODE_LOCALITY": "GUDALUR NILGIRIS",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643212,
      "TXT_PINCODE_LOCALITY": "GUDALUR BAZAAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643213,
      "TXT_PINCODE_LOCALITY": "KATARY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643214,
      "TXT_PINCODE_LOCALITY": "KATTABETTU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643215,
      "TXT_PINCODE_LOCALITY": "KETTI SANTHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643216,
      "TXT_PINCODE_LOCALITY": "KIKOTAGIRI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643217,
      "TXT_PINCODE_LOCALITY": "KOTAGIRI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643218,
      "TXT_PINCODE_LOCALITY": "KULLAKUMBY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643219,
      "TXT_PINCODE_LOCALITY": "KUNDAH BRIDGE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643220,
      "TXT_PINCODE_LOCALITY": "MANGO RANGE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643221,
      "TXT_PINCODE_LOCALITY": "MELUR NILGIRIS",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643222,
      "TXT_PINCODE_LOCALITY": "MOYAR CAMP",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643223,
      "TXT_PINCODE_LOCALITY": "MUSINAGUDI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643224,
      "TXT_PINCODE_LOCALITY": "NADUVATTAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643225,
      "TXT_PINCODE_LOCALITY": "NELLAKOTTA",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643226,
      "TXT_PINCODE_LOCALITY": "NEW TOWN",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643228,
      "TXT_PINCODE_LOCALITY": "SEAFORTH",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643229,
      "TXT_PINCODE_LOCALITY": "SINGARA",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643230,
      "TXT_PINCODE_LOCALITY": "THIASHOLA",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643231,
      "TXT_PINCODE_LOCALITY": "WELLINGTON",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643232,
      "TXT_PINCODE_LOCALITY": "WELLINGTON BAZAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643233,
      "TXT_PINCODE_LOCALITY": "PANDALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643234,
      "TXT_PINCODE_LOCALITY": "HULICAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643235,
      "TXT_PINCODE_LOCALITY": "PEGUMBAHALLA CAMP",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643236,
      "TXT_PINCODE_LOCALITY": "KOTADA ESTATE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643237,
      "TXT_PINCODE_LOCALITY": "SNDYANALLAH",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643238,
      "TXT_PINCODE_LOCALITY": "NONSUCH",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643239,
      "TXT_PINCODE_LOCALITY": "KONACHAL",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 3767,
      "City District Name": "KANNIYAKUMARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643240,
      "TXT_PINCODE_LOCALITY": "BITHERKAD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643241,
      "TXT_PINCODE_LOCALITY": "UPPATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643242,
      "TXT_PINCODE_LOCALITY": "TUTTAPALAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643243,
      "TXT_PINCODE_LOCALITY": "NEEDLE INDUSTRIES",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643244,
      "TXT_PINCODE_LOCALITY": "JAGATHALA",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643245,
      "TXT_PINCODE_LOCALITY": "KANNERIMOOKKU",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643246,
      "TXT_PINCODE_LOCALITY": "KENGARAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643247,
      "TXT_PINCODE_LOCALITY": "NIHUNG",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643248,
      "TXT_PINCODE_LOCALITY": "BIKKATTY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643249,
      "TXT_PINCODE_LOCALITY": "KAGGUCHI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643250,
      "TXT_PINCODE_LOCALITY": "MARTHOMANAGAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643251,
      "TXT_PINCODE_LOCALITY": "PADANTHORAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643252,
      "TXT_PINCODE_LOCALITY": "K PALADA",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643253,
      "TXT_PINCODE_LOCALITY": "KOLAPALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643254,
      "TXT_PINCODE_LOCALITY": "T R BAZAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643255,
      "TXT_PINCODE_LOCALITY": "PERIASHOLA",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643256,
      "TXT_PINCODE_LOCALITY": "ITHALUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643257,
      "TXT_PINCODE_LOCALITY": "NANJANAD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643258,
      "TXT_PINCODE_LOCALITY": "KALHATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643259,
      "TXT_PINCODE_LOCALITY": "SHOLUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643260,
      "TXT_PINCODE_LOCALITY": "THUMMANATHY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643261,
      "TXT_PINCODE_LOCALITY": "DHAVANI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643263,
      "TXT_PINCODE_LOCALITY": "CHERANGODE - 3471",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643264,
      "TXT_PINCODE_LOCALITY": "KODANAD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643265,
      "TXT_PINCODE_LOCALITY": "NEDUGULA",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643266,
      "TXT_PINCODE_LOCALITY": "BILLICOMBAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643267,
      "TXT_PINCODE_LOCALITY": "SANCTUARY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643268,
      "TXT_PINCODE_LOCALITY": "KORAKUNDAH",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643269,
      "TXT_PINCODE_LOCALITY": "SHANTHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643270,
      "TXT_PINCODE_LOCALITY": "DEVALA",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643271,
      "TXT_PINCODE_LOCALITY": "KILKOTAGIRI BAZAAR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643272,
      "TXT_PINCODE_LOCALITY": "THORAIHATTI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643273,
      "TXT_PINCODE_LOCALITY": "EBBANAD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643276,
      "TXT_PINCODE_LOCALITY": "KONAVAKARAI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643277,
      "TXT_PINCODE_LOCALITY": "NUNTHALA",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643278,
      "TXT_PINCODE_LOCALITY": "YEDAPPALLI",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643279,
      "TXT_PINCODE_LOCALITY": "THALAKUNDAH",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643280,
      "TXT_PINCODE_LOCALITY": "AVALANCHI POWER HOUSE",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643282,
      "TXT_PINCODE_LOCALITY": "YEDAKKAD",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 643518,
      "TXT_PINCODE_LOCALITY": "VATAYAM",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 4792,
      "City District Name": "NEELAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770001,
      "TXT_PINCODE_LOCALITY": "REGENT MARKET",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770002,
      "TXT_PINCODE_LOCALITY": "RANGADHIPA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770011,
      "TXT_PINCODE_LOCALITY": "UJAPLUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770012,
      "TXT_PINCODE_LOCALITY": "LEPHRIPARA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770013,
      "TXT_PINCODE_LOCALITY": "HEMGIR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770014,
      "TXT_PINCODE_LOCALITY": "SUNDERGARH",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770015,
      "TXT_PINCODE_LOCALITY": "BALISANKARA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770016,
      "TXT_PINCODE_LOCALITY": "BARGAON",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770017,
      "TXT_PINCODE_LOCALITY": "RANIBANDHA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770018,
      "TXT_PINCODE_LOCALITY": "KUTRA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770019,
      "TXT_PINCODE_LOCALITY": "KUNDUKELA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770020,
      "TXT_PINCODE_LOCALITY": "SANKARA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770021,
      "TXT_PINCODE_LOCALITY": "SARGIPALLI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770022,
      "TXT_PINCODE_LOCALITY": "GADIAJORE",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 441048,
      "City District Name": "SUNDERGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770023,
      "TXT_PINCODE_LOCALITY": "LANJIBERNA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770024,
      "TXT_PINCODE_LOCALITY": "GOPALPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770025,
      "TXT_PINCODE_LOCALITY": "BHASMA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770026,
      "TXT_PINCODE_LOCALITY": "BHEDABAHAL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770027,
      "TXT_PINCODE_LOCALITY": "TANGARPALI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770028,
      "TXT_PINCODE_LOCALITY": "KIRALAGA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770029,
      "TXT_PINCODE_LOCALITY": "RAIDIHI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770031,
      "TXT_PINCODE_LOCALITY": "KALUNGA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770032,
      "TXT_PINCODE_LOCALITY": "BONDAMUNDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770033,
      "TXT_PINCODE_LOCALITY": "C T D BUREAU",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770034,
      "TXT_PINCODE_LOCALITY": "KANSBAHAL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770035,
      "TXT_PINCODE_LOCALITY": "PURNAPANI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770036,
      "TXT_PINCODE_LOCALITY": "BISRA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770037,
      "TXT_PINCODE_LOCALITY": "LATHIKATA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770038,
      "TXT_PINCODE_LOCALITY": "BONAIGARTH",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770039,
      "TXT_PINCODE_LOCALITY": "KUARMUNDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770040,
      "TXT_PINCODE_LOCALITY": "LAHUNIPARA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770041,
      "TXT_PINCODE_LOCALITY": "BARSUAN",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770042,
      "TXT_PINCODE_LOCALITY": "TENSA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770043,
      "TXT_PINCODE_LOCALITY": "GURUNDIA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770044,
      "TXT_PINCODE_LOCALITY": "KHUNTAGAON",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770045,
      "TXT_PINCODE_LOCALITY": "SARASARA BALANG",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770046,
      "TXT_PINCODE_LOCALITY": "BANKI BAZAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770047,
      "TXT_PINCODE_LOCALITY": "HATIBARI (ROURKELA)",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770048,
      "TXT_PINCODE_LOCALITY": "SUNDERGARH.",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 441048,
      "City District Name": "SUNDERGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770049,
      "TXT_PINCODE_LOCALITY": "KAMARPOSH BALANG",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770050,
      "TXT_PINCODE_LOCALITY": "NUAGAN (RKL)",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770051,
      "TXT_PINCODE_LOCALITY": "KENABETA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770052,
      "TXT_PINCODE_LOCALITY": "KALTA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770057,
      "TXT_PINCODE_LOCALITY": "MANKO",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 442916,
      "City District Name": "BISRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770070,
      "TXT_PINCODE_LOCALITY": "TUNMURA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770071,
      "TXT_PINCODE_LOCALITY": "KALUNGA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770072,
      "TXT_PINCODE_LOCALITY": "ZINK NAGAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770073,
      "TXT_PINCODE_LOCALITY": "SUNDERGARH",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 441048,
      "City District Name": "SUNDERGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770074,
      "TXT_PINCODE_LOCALITY": "TALSARA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770075,
      "TXT_PINCODE_LOCALITY": "HEMGIR ROAD",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770076,
      "TXT_PINCODE_LOCALITY": "SAHAJBAHAL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 770077,
      "TXT_PINCODE_LOCALITY": "JHAGARPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500001,
      "TXT_PINCODE_LOCALITY": "KING KOTI COMPLEX",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500002,
      "TXT_PINCODE_LOCALITY": "TAGARIKANAKA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500003,
      "TXT_PINCODE_LOCALITY": "YELLAMMAGUTTA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500004,
      "TXT_PINCODE_LOCALITY": "A C GUARDS",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500005,
      "TXT_PINCODE_LOCALITY": "MAMIDI PALLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500006,
      "TXT_PINCODE_LOCALITY": "MANGALHAT",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500007,
      "TXT_PINCODE_LOCALITY": "NGRI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500008,
      "TXT_PINCODE_LOCALITY": "NANAKRAMGUDA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500009,
      "TXT_PINCODE_LOCALITY": "MANOVIKAS NAGAR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500010,
      "TXT_PINCODE_LOCALITY": "ALWAL",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500011,
      "TXT_PINCODE_LOCALITY": "BOWENPALLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500012,
      "TXT_PINCODE_LOCALITY": "HYDERABAD CITY BUS STATION.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500013,
      "TXT_PINCODE_LOCALITY": "HYDERABAD PUBLIC SCHOOL",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500014,
      "TXT_PINCODE_LOCALITY": "KOTTUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500015,
      "TXT_PINCODE_LOCALITY": "TRIMULCHERRY",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500016,
      "TXT_PINCODE_LOCALITY": "BEGUMPET",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500017,
      "TXT_PINCODE_LOCALITY": "METTUGUDA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500018,
      "TXT_PINCODE_LOCALITY": "HYDERABAD",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500019,
      "TXT_PINCODE_LOCALITY": "SERILINGAMPALLY",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500020,
      "TXT_PINCODE_LOCALITY": "MUSHEERABAD DSO",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500021,
      "TXT_PINCODE_LOCALITY": "E.M.E. RECORDS.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500022,
      "TXT_PINCODE_LOCALITY": "CENTRAL SECRETARIAT (HYD)",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500023,
      "TXT_PINCODE_LOCALITY": "REINBAZAR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500024,
      "TXT_PINCODE_LOCALITY": "KATTAL GUDA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500025,
      "TXT_PINCODE_LOCALITY": "SECUNDERABAD.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500026,
      "TXT_PINCODE_LOCALITY": "WEST MAREDPALLY",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 442065,
      "City District Name": "SECUNDRABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500027,
      "TXT_PINCODE_LOCALITY": "STN KACHEGUDA H O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500028,
      "TXT_PINCODE_LOCALITY": "SHANTINAGAR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500029,
      "TXT_PINCODE_LOCALITY": "HIMAYAT NAGAR EAST",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500030,
      "TXT_PINCODE_LOCALITY": "KRISHI RAKSHA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500031,
      "TXT_PINCODE_LOCALITY": "UPPAL",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500032,
      "TXT_PINCODE_LOCALITY": "RAMA CHANDRA PURAM H E",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500033,
      "TXT_PINCODE_LOCALITY": "SOUTH BANJARA HILLS",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500034,
      "TXT_PINCODE_LOCALITY": "BANJARA HILLS.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500035,
      "TXT_PINCODE_LOCALITY": "RAMAKRISHANAPURAM S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500036,
      "TXT_PINCODE_LOCALITY": "HYDERABAD RACE CLUB.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500037,
      "TXT_PINCODE_LOCALITY": "TOWNSHIP",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500038,
      "TXT_PINCODE_LOCALITY": "B K GUDA.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500039,
      "TXT_PINCODE_LOCALITY": "PEERZADIGUDA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500040,
      "TXT_PINCODE_LOCALITY": "APHB COLONY MOULALI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500041,
      "TXT_PINCODE_LOCALITY": "RAJ BHAVAN",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500042,
      "TXT_PINCODE_LOCALITY": "H.A.L",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500043,
      "TXT_PINCODE_LOCALITY": "AIR FORCE ACADEMY",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500044,
      "TXT_PINCODE_LOCALITY": "AMBERNAGAR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500045,
      "TXT_PINCODE_LOCALITY": "PADMAVATINAGAR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500046,
      "TXT_PINCODE_LOCALITY": "CUC",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500047,
      "TXT_PINCODE_LOCALITY": "MALKAJGIRI.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500048,
      "TXT_PINCODE_LOCALITY": "RAJAENDRA NAGAR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500049,
      "TXT_PINCODE_LOCALITY": "SERILINGAMPALLY",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500050,
      "TXT_PINCODE_LOCALITY": "CHANDANAGAR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500051,
      "TXT_PINCODE_LOCALITY": "HINDUSTAN MACHINE.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500052,
      "TXT_PINCODE_LOCALITY": "SHIVARAMPALLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500053,
      "TXT_PINCODE_LOCALITY": "UPPUGUDA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500054,
      "TXT_PINCODE_LOCALITY": "QUTHBULLAPUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500055,
      "TXT_PINCODE_LOCALITY": "JEEDIMETLA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500056,
      "TXT_PINCODE_LOCALITY": "NEREDMET",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500057,
      "TXT_PINCODE_LOCALITY": "VIJAY NAGAR COLONY",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500058,
      "TXT_PINCODE_LOCALITY": "BADANGPET B.O.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500059,
      "TXT_PINCODE_LOCALITY": "SANTOSHNAGAR COLONY",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500060,
      "TXT_PINCODE_LOCALITY": "DILSUKHNAGAR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500061,
      "TXT_PINCODE_LOCALITY": "SITAPHALMANDI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500062,
      "TXT_PINCODE_LOCALITY": "KYASARAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500063,
      "TXT_PINCODE_LOCALITY": "LIC DIVISION",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500064,
      "TXT_PINCODE_LOCALITY": "TADBUN",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500065,
      "TXT_PINCODE_LOCALITY": "SHAHALIBANDA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500066,
      "TXT_PINCODE_LOCALITY": "HIGH COURT S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500067,
      "TXT_PINCODE_LOCALITY": "SUCHITRA CIRCLE",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500068,
      "TXT_PINCODE_LOCALITY": "MANSOORABAD",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500069,
      "TXT_PINCODE_LOCALITY": "R.C.IMARAT",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500070,
      "TXT_PINCODE_LOCALITY": "VANASTALIPURAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500071,
      "TXT_PINCODE_LOCALITY": "RAIL NILAYAM S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500072,
      "TXT_PINCODE_LOCALITY": "KUKATPALLY S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500073,
      "TXT_PINCODE_LOCALITY": "SRINAGAR COLONY",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500074,
      "TXT_PINCODE_LOCALITY": "RANGAREDDY DT COURTS",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500075,
      "TXT_PINCODE_LOCALITY": "SHAIKPET",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500076,
      "TXT_PINCODE_LOCALITY": "I.E.NACHARAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500077,
      "TXT_PINCODE_LOCALITY": "KATTEDAN IE",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500078,
      "TXT_PINCODE_LOCALITY": "SHAMIRPET B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500079,
      "TXT_PINCODE_LOCALITY": "KARMANGHAT B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500080,
      "TXT_PINCODE_LOCALITY": "GANDHI NAGAR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500081,
      "TXT_PINCODE_LOCALITY": "MADHAPUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500082,
      "TXT_PINCODE_LOCALITY": "SOMAJIGUDA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500083,
      "TXT_PINCODE_LOCALITY": "NAGARAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500084,
      "TXT_PINCODE_LOCALITY": "KONDAPUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500085,
      "TXT_PINCODE_LOCALITY": "JNTU KUKAT PALLY",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500086,
      "TXT_PINCODE_LOCALITY": "BANDLAGUDA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500087,
      "TXT_PINCODE_LOCALITY": "YAPRAL",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500088,
      "TXT_PINCODE_LOCALITY": "KORREMAL",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500089,
      "TXT_PINCODE_LOCALITY": "MANCHEREVULA B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441982,
      "City District Name": "K.V.RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500090,
      "TXT_PINCODE_LOCALITY": "BACHPALLY",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500091,
      "TXT_PINCODE_LOCALITY": "HYDERSHAHKOTE S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500092,
      "TXT_PINCODE_LOCALITY": "HYDRABAD.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500093,
      "TXT_PINCODE_LOCALITY": "VIKAS NAGAR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500094,
      "TXT_PINCODE_LOCALITY": "SAINIKPURI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500095,
      "TXT_PINCODE_LOCALITY": "PUTLIBOWLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500096,
      "TXT_PINCODE_LOCALITY": "FILM NAGAR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500097,
      "TXT_PINCODE_LOCALITY": "SAROORNAGAR S. O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500098,
      "TXT_PINCODE_LOCALITY": "MEDIPALLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500099,
      "TXT_PINCODE_LOCALITY": "SIDDIAMBER BAZAR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443164,
      "City District Name": "NAMPALLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500100,
      "TXT_PINCODE_LOCALITY": "POCHAMPALLY B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500101,
      "TXT_PINCODE_LOCALITY": "SHAMIRPET MANDAL.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443178,
      "City District Name": "SHAMIRPET",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500102,
      "TXT_PINCODE_LOCALITY": "KACHIGUDA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500103,
      "TXT_PINCODE_LOCALITY": "SANGANER",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500104,
      "TXT_PINCODE_LOCALITY": "CHITRAPURI COLONY S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441982,
      "City District Name": "K.V.RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500105,
      "TXT_PINCODE_LOCALITY": "KHAIRATABAD",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500107,
      "TXT_PINCODE_LOCALITY": "RANGAREDDY  S. O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500108,
      "TXT_PINCODE_LOCALITY": "SHAMSHABAD SO",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500109,
      "TXT_PINCODE_LOCALITY": "SANGANAR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500110,
      "TXT_PINCODE_LOCALITY": "PRASHASAN NAGAR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500111,
      "TXT_PINCODE_LOCALITY": "RANGAREDDY",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441982,
      "City District Name": "K.V.RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500112,
      "TXT_PINCODE_LOCALITY": "ALMASGUDA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 442094,
      "City District Name": "RANGA REDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500113,
      "TXT_PINCODE_LOCALITY": "JAITWARAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500114,
      "TXT_PINCODE_LOCALITY": "MOTINAGAR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500115,
      "TXT_PINCODE_LOCALITY": "AUTONAGAR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500123,
      "TXT_PINCODE_LOCALITY": "THIPPAIGUDA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443157,
      "City District Name": "MANCHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500133,
      "TXT_PINCODE_LOCALITY": "LINGAM PALLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500134,
      "TXT_PINCODE_LOCALITY": "CENTRAL UNIVERSITY CAMPUS.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500171,
      "TXT_PINCODE_LOCALITY": "CHAITANYA BHARATI (HYD).",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500195,
      "TXT_PINCODE_LOCALITY": "KOTI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443164,
      "City District Name": "NAMPALLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500226,
      "TXT_PINCODE_LOCALITY": "SANGAREDDY",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500252,
      "TXT_PINCODE_LOCALITY": "ACADEMY.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500253,
      "TXT_PINCODE_LOCALITY": "UPPUGUDA S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500258,
      "TXT_PINCODE_LOCALITY": "KANCHAN BAGH",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500264,
      "TXT_PINCODE_LOCALITY": "BAHADUR PURRA.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500265,
      "TXT_PINCODE_LOCALITY": "SULTAN SHAHI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500266,
      "TXT_PINCODE_LOCALITY": "HIGH COURT HYDERABAD.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500267,
      "TXT_PINCODE_LOCALITY": "KULSUMPURA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500323,
      "TXT_PINCODE_LOCALITY": "KATEDAN INDUSTRIAL AREA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443175,
      "City District Name": "RAJENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500361,
      "TXT_PINCODE_LOCALITY": "BOUDHA NAGAR.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500380,
      "TXT_PINCODE_LOCALITY": "GANDHI NAGAR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500409,
      "TXT_PINCODE_LOCALITY": "SHAMSHABAD",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500457,
      "TXT_PINCODE_LOCALITY": "VIJAYANAGAR COLONY",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500463,
      "TXT_PINCODE_LOCALITY": "ADARSH NAGAR.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500482,
      "TXT_PINCODE_LOCALITY": "SOMAJI GUDA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500484,
      "TXT_PINCODE_LOCALITY": "NEW MLA QUARTERS.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500486,
      "TXT_PINCODE_LOCALITY": "VIDHANA S-BHA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500556,
      "TXT_PINCODE_LOCALITY": "RAMA KRISHNAPURAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500575,
      "TXT_PINCODE_LOCALITY": "MAHESHWARAM-RR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500587,
      "TXT_PINCODE_LOCALITY": "JAI JAWAHAR NAGAR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500593,
      "TXT_PINCODE_LOCALITY": "VIKASNAGAR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500594,
      "TXT_PINCODE_LOCALITY": "SAINIKPURI.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500605,
      "TXT_PINCODE_LOCALITY": "OSMANIA UNIVERSITY CAMPUS",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443161,
      "City District Name": "MUSHEERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500659,
      "TXT_PINCODE_LOCALITY": "STATE BANK COLONY",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500660,
      "TXT_PINCODE_LOCALITY": "VIVEKANANDNAGAR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500661,
      "TXT_PINCODE_LOCALITY": "VANAVOLU",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500762,
      "TXT_PINCODE_LOCALITY": "DR A S RAONAGAR COLONY.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500768,
      "TXT_PINCODE_LOCALITY": "CENTRAL TRAINING INSTITUTE B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500855,
      "TXT_PINCODE_LOCALITY": "IND DEV ARE JEED HYD.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500872,
      "TXT_PINCODE_LOCALITY": "KUKATPALLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500873,
      "TXT_PINCODE_LOCALITY": "SRINAGAR COLONY.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500890,
      "TXT_PINCODE_LOCALITY": "VENGALRA0 NAGAR HYD",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 500963,
      "TXT_PINCODE_LOCALITY": "LAL BAHADUR NAGAR B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501101,
      "TXT_PINCODE_LOCALITY": "VIKARABAD H O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501102,
      "TXT_PINCODE_LOCALITY": "GODAMGUDA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501103,
      "TXT_PINCODE_LOCALITY": "EKMAMIDI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501104,
      "TXT_PINCODE_LOCALITY": "PORUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501105,
      "TXT_PINCODE_LOCALITY": "ANANTHA GIRIPALLE",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501106,
      "TXT_PINCODE_LOCALITY": "RAVULAPALLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501108,
      "TXT_PINCODE_LOCALITY": "KOTHAGADI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501109,
      "TXT_PINCODE_LOCALITY": "MANNEGUDA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501110,
      "TXT_PINCODE_LOCALITY": "VELCHAL S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501111,
      "TXT_PINCODE_LOCALITY": "PULMAMIDI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501112,
      "TXT_PINCODE_LOCALITY": "PHULAMAMDI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501113,
      "TXT_PINCODE_LOCALITY": "CHITTI GADDA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501114,
      "TXT_PINCODE_LOCALITY": "MADIREDDI PALLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501115,
      "TXT_PINCODE_LOCALITY": "RAMNADGUDPALLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501121,
      "TXT_PINCODE_LOCALITY": "NAGAWARAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501141,
      "TXT_PINCODE_LOCALITY": "ALLAPUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501142,
      "TXT_PINCODE_LOCALITY": "MOMBAPUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501143,
      "TXT_PINCODE_LOCALITY": "BASHEERABAD",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501144,
      "TXT_PINCODE_LOCALITY": "BENNUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501145,
      "TXT_PINCODE_LOCALITY": "NAVALGA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501146,
      "TXT_PINCODE_LOCALITY": "AGNOOR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501147,
      "TXT_PINCODE_LOCALITY": "KANDANHALLI S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501148,
      "TXT_PINCODE_LOCALITY": "MAILWAR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501149,
      "TXT_PINCODE_LOCALITY": "MANTHANGOD",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501150,
      "TXT_PINCODE_LOCALITY": "YEKMAI S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501151,
      "TXT_PINCODE_LOCALITY": "GINGURTHI S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501152,
      "TXT_PINCODE_LOCALITY": "INDOOR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501153,
      "TXT_PINCODE_LOCALITY": "MUMBAPUR B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501154,
      "TXT_PINCODE_LOCALITY": "JUNTA PALLI S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501155,
      "TXT_PINCODE_LOCALITY": "PAGDIAL S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501156,
      "TXT_PINCODE_LOCALITY": "TURMAMIDI S,O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501157,
      "TXT_PINCODE_LOCALITY": "MALKAPUR (TANDUR)",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501158,
      "TXT_PINCODE_LOCALITY": "KARANKOT",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501201,
      "TXT_PINCODE_LOCALITY": "KOTHULAPUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501202,
      "TXT_PINCODE_LOCALITY": "VELCHAL",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501203,
      "TXT_PINCODE_LOCALITY": "TANGUTOOR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501204,
      "TXT_PINCODE_LOCALITY": "PATLUR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501205,
      "TXT_PINCODE_LOCALITY": "TEKULAPALLI S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501206,
      "TXT_PINCODE_LOCALITY": "YENKATLA S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501218,
      "TXT_PINCODE_LOCALITY": "NARKHODA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501262,
      "TXT_PINCODE_LOCALITY": "TENNERU.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501301,
      "TXT_PINCODE_LOCALITY": "THIMMAIPALLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501355,
      "TXT_PINCODE_LOCALITY": "KULAKCHERLA B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501359,
      "TXT_PINCODE_LOCALITY": "NEDUNUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501401,
      "TXT_PINCODE_LOCALITY": "MEDCHAL",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501403,
      "TXT_PINCODE_LOCALITY": "GOWDA VALLI S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501405,
      "TXT_PINCODE_LOCALITY": "DABIRPUR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501410,
      "TXT_PINCODE_LOCALITY": "KOMPALLI B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501501,
      "TXT_PINCODE_LOCALITY": "RIAPOL",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501502,
      "TXT_PINCODE_LOCALITY": "BANDELKACHERLA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501503,
      "TXT_PINCODE_LOCALITY": "GUNDAL",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501504,
      "TXT_PINCODE_LOCALITY": "SURANGAL",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501505,
      "TXT_PINCODE_LOCALITY": "QUTUBULLAPUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501506,
      "TXT_PINCODE_LOCALITY": "GUMMADAVALLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501507,
      "TXT_PINCODE_LOCALITY": "NACHARAM INDUSTRIAL ESTATE S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501508,
      "TXT_PINCODE_LOCALITY": "RAIPOLE",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501509,
      "TXT_PINCODE_LOCALITY": "MANTHANGOWD GOWRELLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501510,
      "TXT_PINCODE_LOCALITY": "HAYATHNAGAR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501511,
      "TXT_PINCODE_LOCALITY": "GUNDLA POCHAMPALLY",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501512,
      "TXT_PINCODE_LOCALITY": "CHINA MANGALARAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501513,
      "TXT_PINCODE_LOCALITY": "PEDDA MANGALARAM S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501514,
      "TXT_PINCODE_LOCALITY": "ALOOR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501515,
      "TXT_PINCODE_LOCALITY": "GUNDAL S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501516,
      "TXT_PINCODE_LOCALITY": "CHELKUR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501518,
      "TXT_PINCODE_LOCALITY": "MOINABAD S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501520,
      "TXT_PINCODE_LOCALITY": "SERI GUDA S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501521,
      "TXT_PINCODE_LOCALITY": "GUNGAL B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501522,
      "TXT_PINCODE_LOCALITY": "AINAPUR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501523,
      "TXT_PINCODE_LOCALITY": "SURANGAL S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501525,
      "TXT_PINCODE_LOCALITY": "DOMA S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501526,
      "TXT_PINCODE_LOCALITY": "REGAD GHANPUR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501527,
      "TXT_PINCODE_LOCALITY": "TATI PARTHY",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501528,
      "TXT_PINCODE_LOCALITY": "BATASINGARAM S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501531,
      "TXT_PINCODE_LOCALITY": "NANDI VANAPARTHI S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501533,
      "TXT_PINCODE_LOCALITY": "MEDPALLY",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501534,
      "TXT_PINCODE_LOCALITY": "MAILARAM S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501535,
      "TXT_PINCODE_LOCALITY": "VENKATAPUR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501536,
      "TXT_PINCODE_LOCALITY": "KAWAD PALLI S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 501537,
      "TXT_PINCODE_LOCALITY": "TURKAYAMJAL S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502001,
      "TXT_PINCODE_LOCALITY": "SANGAREDDY.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502032,
      "TXT_PINCODE_LOCALITY": "RAMACHANDRAPURARN H.E. - 3981",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502090,
      "TXT_PINCODE_LOCALITY": "MEDAK",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502101,
      "TXT_PINCODE_LOCALITY": "RAMAYAMPET",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502102,
      "TXT_PINCODE_LOCALITY": "SIDDIPET S. O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502103,
      "TXT_PINCODE_LOCALITY": "SIDDIPET.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502104,
      "TXT_PINCODE_LOCALITY": "TOGATTA S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502107,
      "TXT_PINCODE_LOCALITY": "PULLUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502108,
      "TXT_PINCODE_LOCALITY": "DUBBAK",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502109,
      "TXT_PINCODE_LOCALITY": "MACHAVARAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502110,
      "TXT_PINCODE_LOCALITY": "MIYAPUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502113,
      "TXT_PINCODE_LOCALITY": "HAVELI GHANPUR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502114,
      "TXT_PINCODE_LOCALITY": "IRKODE",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502115,
      "TXT_PINCODE_LOCALITY": "MEDAK S. O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502117,
      "TXT_PINCODE_LOCALITY": "RAJPALLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502119,
      "TXT_PINCODE_LOCALITY": "GAVALPALLE",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443179,
      "City District Name": "SHANKARAMPET (R)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502125,
      "TXT_PINCODE_LOCALITY": "PODICHANPALLY S. O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502130,
      "TXT_PINCODE_LOCALITY": "KUCHANPALLY",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502156,
      "TXT_PINCODE_LOCALITY": "MURTHUJAPUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443170,
      "City District Name": "NYALKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502178,
      "TXT_PINCODE_LOCALITY": "POTHARAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443135,
      "City District Name": "DUBBAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502201,
      "TXT_PINCODE_LOCALITY": "SHANKAR PALLI.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502205,
      "TXT_PINCODE_LOCALITY": "EDDUMAILARAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502210,
      "TXT_PINCODE_LOCALITY": "SANGAREDDY  S. O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502211,
      "TXT_PINCODE_LOCALITY": "GURAZAWADA.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502212,
      "TXT_PINCODE_LOCALITY": "PICHARGAD.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502213,
      "TXT_PINCODE_LOCALITY": "MANIAR PALLI S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502216,
      "TXT_PINCODE_LOCALITY": "MOHAMMADABAD @ JANAKAMPET",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443166,
      "City District Name": "NARSAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502218,
      "TXT_PINCODE_LOCALITY": "PASTAPUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443193,
      "City District Name": "ZAHIRABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502220,
      "TXT_PINCODE_LOCALITY": "ARYA NAGAR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502221,
      "TXT_PINCODE_LOCALITY": "CHARAK PALLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502222,
      "TXT_PINCODE_LOCALITY": "GOPAM PALLI.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502223,
      "TXT_PINCODE_LOCALITY": "MADGI.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502224,
      "TXT_PINCODE_LOCALITY": "SATWAR.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502225,
      "TXT_PINCODE_LOCALITY": "MALCHELMA.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502226,
      "TXT_PINCODE_LOCALITY": "MOGDAMPALLI.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502227,
      "TXT_PINCODE_LOCALITY": "DHANASIRI S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502228,
      "TXT_PINCODE_LOCALITY": "DIDGI B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502231,
      "TXT_PINCODE_LOCALITY": "MACHAPUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443131,
      "City District Name": "CHINNAKODUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502235,
      "TXT_PINCODE_LOCALITY": "MASAIPET.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502246,
      "TXT_PINCODE_LOCALITY": "ZARASANGAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502247,
      "TXT_PINCODE_LOCALITY": "MAKKARAJPET",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502248,
      "TXT_PINCODE_LOCALITY": "SHANKARAMPET S. O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502249,
      "TXT_PINCODE_LOCALITY": "MIRZAPUR (SANGAREDDY)",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502251,
      "TXT_PINCODE_LOCALITY": "HANDNOOR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502255,
      "TXT_PINCODE_LOCALITY": "CHEGUNTA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502256,
      "TXT_PINCODE_LOCALITY": "MEDAK S. O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502257,
      "TXT_PINCODE_LOCALITY": "RAI KODE",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502267,
      "TXT_PINCODE_LOCALITY": "IBRAHIM NAGAR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502269,
      "TXT_PINCODE_LOCALITY": "ALLA DURG",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502270,
      "TXT_PINCODE_LOCALITY": "JOGIPET",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502271,
      "TXT_PINCODE_LOCALITY": "SHANKARAMPET BAZAR B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502273,
      "TXT_PINCODE_LOCALITY": "ANDOLE",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502276,
      "TXT_PINCODE_LOCALITY": "CHINNA KODURU B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502277,
      "TXT_PINCODE_LOCALITY": "SIDDIPET",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 442105,
      "City District Name": "SIDDIPET",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502278,
      "TXT_PINCODE_LOCALITY": "GUJVAIL OLD TOWN",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502279,
      "TXT_PINCODE_LOCALITY": "BHAILAMPUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502280,
      "TXT_PINCODE_LOCALITY": "AKKENAPALLY",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502281,
      "TXT_PINCODE_LOCALITY": "JAGADEVPUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502284,
      "TXT_PINCODE_LOCALITY": "RAJ GOPAL PET S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502285,
      "TXT_PINCODE_LOCALITY": "MEDAK B. O.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502286,
      "TXT_PINCODE_LOCALITY": "MANUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502287,
      "TXT_PINCODE_LOCALITY": "SIRGAPUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502288,
      "TXT_PINCODE_LOCALITY": "TOGARPALLI S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502289,
      "TXT_PINCODE_LOCALITY": "KONDAPUR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502290,
      "TXT_PINCODE_LOCALITY": "REGODE MANDAL",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502291,
      "TXT_PINCODE_LOCALITY": "SADASHIVPET",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502292,
      "TXT_PINCODE_LOCALITY": "ATMAKUR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502293,
      "TXT_PINCODE_LOCALITY": "SINGUR PROJECT",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502294,
      "TXT_PINCODE_LOCALITY": "MEDAK S. O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502295,
      "TXT_PINCODE_LOCALITY": "TERPOLE. B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502296,
      "TXT_PINCODE_LOCALITY": "ISMAIL KHAN PET",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502297,
      "TXT_PINCODE_LOCALITY": "HATNURA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502299,
      "TXT_PINCODE_LOCALITY": "CHINTALCHERU S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502300,
      "TXT_PINCODE_LOCALITY": "BHANUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502301,
      "TXT_PINCODE_LOCALITY": "KUKNOORPALLY S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502302,
      "TXT_PINCODE_LOCALITY": "TEKMAL",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502303,
      "TXT_PINCODE_LOCALITY": "PAPANNAPET",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502305,
      "TXT_PINCODE_LOCALITY": "BDL BHANUR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502306,
      "TXT_PINCODE_LOCALITY": "NANDIKANDI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502307,
      "TXT_PINCODE_LOCALITY": "MEDAK",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502308,
      "TXT_PINCODE_LOCALITY": "NAGI REDDI PET S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502310,
      "TXT_PINCODE_LOCALITY": "ALLIPUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502311,
      "TXT_PINCODE_LOCALITY": "MEDAK B. O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502312,
      "TXT_PINCODE_LOCALITY": "KODAKANDLA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502313,
      "TXT_PINCODE_LOCALITY": "ANNARAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502314,
      "TXT_PINCODE_LOCALITY": "LINGAPUR B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502316,
      "TXT_PINCODE_LOCALITY": "NARSAPUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502317,
      "TXT_PINCODE_LOCALITY": "KHAJIPET (SGD) S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502318,
      "TXT_PINCODE_LOCALITY": "ZAHEERABAD.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502319,
      "TXT_PINCODE_LOCALITY": "PATANCHERU.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502321,
      "TXT_PINCODE_LOCALITY": "DIGWAL S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502322,
      "TXT_PINCODE_LOCALITY": "KRISHNAPUR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502323,
      "TXT_PINCODE_LOCALITY": "YELMAKANNA S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502324,
      "TXT_PINCODE_LOCALITY": "ICRISAT S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502325,
      "TXT_PINCODE_LOCALITY": "BOLLARAM VILLAGE",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502326,
      "TXT_PINCODE_LOCALITY": "MEDAK B. O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502329,
      "TXT_PINCODE_LOCALITY": "RUDRARAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502330,
      "TXT_PINCODE_LOCALITY": "KOLLUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502331,
      "TXT_PINCODE_LOCALITY": "YOUSUFPET",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502334,
      "TXT_PINCODE_LOCALITY": "TOOPRAN S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502335,
      "TXT_PINCODE_LOCALITY": "MASAIPET",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502336,
      "TXT_PINCODE_LOCALITY": "KADLAKAL S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502345,
      "TXT_PINCODE_LOCALITY": "KAMAKOLE S. O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502371,
      "TXT_PINCODE_LOCALITY": "NIZAMPET (S)",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502372,
      "TXT_PINCODE_LOCALITY": "KONDAPAK",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502374,
      "TXT_PINCODE_LOCALITY": "CHIKUL S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502375,
      "TXT_PINCODE_LOCALITY": "SIDDIPET S. O.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502376,
      "TXT_PINCODE_LOCALITY": "CHANDUR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502378,
      "TXT_PINCODE_LOCALITY": "CHOUT KORE",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502379,
      "TXT_PINCODE_LOCALITY": "KUSANGI S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502380,
      "TXT_PINCODE_LOCALITY": "PEDDAPUR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502381,
      "TXT_PINCODE_LOCALITY": "KULCHARAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502401,
      "TXT_PINCODE_LOCALITY": "YADARAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 442095,
      "City District Name": "MEDCHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 502991,
      "TXT_PINCODE_LOCALITY": "NANDIKANDI.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443176,
      "City District Name": "SADASIVPET",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503001,
      "TXT_PINCODE_LOCALITY": "SHRADDAMANDGUNJ",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503002,
      "TXT_PINCODE_LOCALITY": "SUBHASHNAGAR (NZB)",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503003,
      "TXT_PINCODE_LOCALITY": "VALMIKINAGAR.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503032,
      "TXT_PINCODE_LOCALITY": "PATANCHERU",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441348,
      "City District Name": "MEDAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503101,
      "TXT_PINCODE_LOCALITY": "RAMESHWARAPALLI B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503102,
      "TXT_PINCODE_LOCALITY": "TALMADLA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503103,
      "TXT_PINCODE_LOCALITY": "TIPPAPUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503105,
      "TXT_PINCODE_LOCALITY": "MALLUPALLE",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443125,
      "City District Name": "BHIKNOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503106,
      "TXT_PINCODE_LOCALITY": "GOLILINGAL",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443162,
      "City District Name": "NAGAREDDIPET",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503108,
      "TXT_PINCODE_LOCALITY": "NAGIREDDYPET S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503110,
      "TXT_PINCODE_LOCALITY": "RAJAMPET",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503111,
      "TXT_PINCODE_LOCALITY": "SUBHASH ROAD",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503112,
      "TXT_PINCODE_LOCALITY": "KAMAREDDY",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 442107,
      "City District Name": "KAMAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503114,
      "TXT_PINCODE_LOCALITY": "GANDHARI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503115,
      "TXT_PINCODE_LOCALITY": "BHAWANPET",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503116,
      "TXT_PINCODE_LOCALITY": "PALWANCHA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503120,
      "TXT_PINCODE_LOCALITY": "TADWAI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503122,
      "TXT_PINCODE_LOCALITY": "YELLA REDDY",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503123,
      "TXT_PINCODE_LOCALITY": "NIZAMABAD S. O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503124,
      "TXT_PINCODE_LOCALITY": "LINGAMPET",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503125,
      "TXT_PINCODE_LOCALITY": "BIBIPET",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503144,
      "TXT_PINCODE_LOCALITY": "RAMA REDDY",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503145,
      "TXT_PINCODE_LOCALITY": "DHARAMRAOPET",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503164,
      "TXT_PINCODE_LOCALITY": "INDALWAI R S",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503165,
      "TXT_PINCODE_LOCALITY": "DARPALLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503166,
      "TXT_PINCODE_LOCALITY": "TOOMPALLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503174,
      "TXT_PINCODE_LOCALITY": "NIZAMABAD S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503175,
      "TXT_PINCODE_LOCALITY": "DICHPALLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503176,
      "TXT_PINCODE_LOCALITY": "NADEPALLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503177,
      "TXT_PINCODE_LOCALITY": "MULLANGI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503178,
      "TXT_PINCODE_LOCALITY": "PADKAL",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503180,
      "TXT_PINCODE_LOCALITY": "SHAKARNAGAR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503185,
      "TXT_PINCODE_LOCALITY": "BODHAN R S",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503186,
      "TXT_PINCODE_LOCALITY": "SARANGAPUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503187,
      "TXT_PINCODE_LOCALITY": "BANSWADA S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503188,
      "TXT_PINCODE_LOCALITY": "RUDRUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503192,
      "TXT_PINCODE_LOCALITY": "PENTA KHURD B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503193,
      "TXT_PINCODE_LOCALITY": "JANKAMPET R S B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503201,
      "TXT_PINCODE_LOCALITY": "VARNI S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503202,
      "TXT_PINCODE_LOCALITY": "THANKALAM B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503203,
      "TXT_PINCODE_LOCALITY": "THANKALAM S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503206,
      "TXT_PINCODE_LOCALITY": "MOSRA S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503207,
      "TXT_PINCODE_LOCALITY": "KOTGIR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503212,
      "TXT_PINCODE_LOCALITY": "NANDI PET S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503213,
      "TXT_PINCODE_LOCALITY": "MAKLOOR B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503214,
      "TXT_PINCODE_LOCALITY": "ALLOR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503215,
      "TXT_PINCODE_LOCALITY": "ANDHRA NAGAR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503217,
      "TXT_PINCODE_LOCALITY": "BALKONDA.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503218,
      "TXT_PINCODE_LOCALITY": "KISSAN NAGAR B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503219,
      "TXT_PINCODE_LOCALITY": "POCHAMPAD PROJECT.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503220,
      "TXT_PINCODE_LOCALITY": "FATHEPUR..",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503221,
      "TXT_PINCODE_LOCALITY": "SUBRIYAL S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503222,
      "TXT_PINCODE_LOCALITY": "MUPKAL",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503223,
      "TXT_PINCODE_LOCALITY": "ARMOOR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503224,
      "TXT_PINCODE_LOCALITY": "ARMOOR.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503225,
      "TXT_PINCODE_LOCALITY": "PALEM S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503226,
      "TXT_PINCODE_LOCALITY": "MANTHANI S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503227,
      "TXT_PINCODE_LOCALITY": "TIMMAPUR B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503228,
      "TXT_PINCODE_LOCALITY": "CHEPUR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503230,
      "TXT_PINCODE_LOCALITY": "MUPKAL.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503231,
      "TXT_PINCODE_LOCALITY": "BARDIPUR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503234,
      "TXT_PINCODE_LOCALITY": "NANDIPET",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 444208,
      "City District Name": "NANDIPET",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503235,
      "TXT_PINCODE_LOCALITY": "PENTA KHURD S. O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503236,
      "TXT_PINCODE_LOCALITY": "RANJAL S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503245,
      "TXT_PINCODE_LOCALITY": "NAVIPET B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503246,
      "TXT_PINCODE_LOCALITY": "JANNEPALLI S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503301,
      "TXT_PINCODE_LOCALITY": "MIRZAPUR  S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503302,
      "TXT_PINCODE_LOCALITY": "NIZAMSAGAR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503305,
      "TXT_PINCODE_LOCALITY": "JUKAL B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503306,
      "TXT_PINCODE_LOCALITY": "BICHUKONDA S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503307,
      "TXT_PINCODE_LOCALITY": "BHIMGAL BUS STAND S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503308,
      "TXT_PINCODE_LOCALITY": "KUMAR PALLI S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503309,
      "TXT_PINCODE_LOCALITY": "MADNOOR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503310,
      "TXT_PINCODE_LOCALITY": "PITLAM B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503311,
      "TXT_PINCODE_LOCALITY": "MORTHAD",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503313,
      "TXT_PINCODE_LOCALITY": "VANNEL B S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503314,
      "TXT_PINCODE_LOCALITY": "DONKAL S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503315,
      "TXT_PINCODE_LOCALITY": "PADGAL S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503321,
      "TXT_PINCODE_LOCALITY": "BIRKUR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503322,
      "TXT_PINCODE_LOCALITY": "NIZAMABAD",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441350,
      "City District Name": "NIZAMABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 503325,
      "TXT_PINCODE_LOCALITY": "THATPALLE",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443180,
      "City District Name": "SIRKONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504001,
      "TXT_PINCODE_LOCALITY": "ADILABAD",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504002,
      "TXT_PINCODE_LOCALITY": "ADILABAD COLLECOTORATE  S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504003,
      "TXT_PINCODE_LOCALITY": "TOWNSHIP S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504100,
      "TXT_PINCODE_LOCALITY": "BURUGUPALLE (G)",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443154,
      "City District Name": "KUNTALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504101,
      "TXT_PINCODE_LOCALITY": "BASAR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504102,
      "TXT_PINCODE_LOCALITY": "TANUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504103,
      "TXT_PINCODE_LOCALITY": "KUBBER",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504104,
      "TXT_PINCODE_LOCALITY": "LOWKESHWARAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504105,
      "TXT_PINCODE_LOCALITY": "SOAN",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504106,
      "TXT_PINCODE_LOCALITY": "NIRMAL.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504107,
      "TXT_PINCODE_LOCALITY": "RGU IIIT CAMPUS BASAR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504108,
      "TXT_PINCODE_LOCALITY": "DEHGAON.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504109,
      "TXT_PINCODE_LOCALITY": "KUNTALA S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504110,
      "TXT_PINCODE_LOCALITY": "SARANGPUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504115,
      "TXT_PINCODE_LOCALITY": "KOBER.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504116,
      "TXT_PINCODE_LOCALITY": "OLA S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504118,
      "TXT_PINCODE_LOCALITY": "KADTHOL.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504201,
      "TXT_PINCODE_LOCALITY": "CHENNUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504202,
      "TXT_PINCODE_LOCALITY": "KADDAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504203,
      "TXT_PINCODE_LOCALITY": "KHANAPUR.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504204,
      "TXT_PINCODE_LOCALITY": "BHEEMARAN",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504205,
      "TXT_PINCODE_LOCALITY": "JANNARAM S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504206,
      "TXT_PINCODE_LOCALITY": "DANDEPALLY.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504207,
      "TXT_PINCODE_LOCALITY": "DONA BANDA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504208,
      "TXT_PINCODE_LOCALITY": "MANCHERIAL.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 442113,
      "City District Name": "MANCHERIAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504209,
      "TXT_PINCODE_LOCALITY": "ADILABAD S. O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504211,
      "TXT_PINCODE_LOCALITY": "DWARAKA.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504214,
      "TXT_PINCODE_LOCALITY": "JANGAON",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504215,
      "TXT_PINCODE_LOCALITY": "LUXTIPET",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504216,
      "TXT_PINCODE_LOCALITY": "JAIPUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504217,
      "TXT_PINCODE_LOCALITY": "SHET PALLI S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504218,
      "TXT_PINCODE_LOCALITY": "ADILABAD",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504219,
      "TXT_PINCODE_LOCALITY": "BHIMNI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504220,
      "TXT_PINCODE_LOCALITY": "MADARAM TOWN SHOP",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504231,
      "TXT_PINCODE_LOCALITY": "KAZIPET",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504232,
      "TXT_PINCODE_LOCALITY": "KASIPET.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504251,
      "TXT_PINCODE_LOCALITY": "BELLAMPALLY",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504271,
      "TXT_PINCODE_LOCALITY": "WADAL",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443126,
      "City District Name": "BHIMINI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504272,
      "TXT_PINCODE_LOCALITY": "MANCHERIAL S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 442116,
      "City District Name": "MANCHERIAL.",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504273,
      "TXT_PINCODE_LOCALITY": "TANDOOR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504292,
      "TXT_PINCODE_LOCALITY": "ADILABAD S. O.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504293,
      "TXT_PINCODE_LOCALITY": "KOMARAM BHEEM ASIFABAD",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 444133,
      "City District Name": "KOMARAM BHEEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504294,
      "TXT_PINCODE_LOCALITY": "TIRYANI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504295,
      "TXT_PINCODE_LOCALITY": "WANKIDI.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504296,
      "TXT_PINCODE_LOCALITY": "SIRPURKAGAZNAGAR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504297,
      "TXT_PINCODE_LOCALITY": "DONDLA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443186,
      "City District Name": "TIRYANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504299,
      "TXT_PINCODE_LOCALITY": "SIRPUR T",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504301,
      "TXT_PINCODE_LOCALITY": "MANDAMARRI S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504302,
      "TXT_PINCODE_LOCALITY": "NASPUR B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504303,
      "TXT_PINCODE_LOCALITY": "SRIRAMPUR COLONY",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504304,
      "TXT_PINCODE_LOCALITY": "BOATH.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504305,
      "TXT_PINCODE_LOCALITY": "HATNOOR.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504306,
      "TXT_PINCODE_LOCALITY": "DILAWARPUR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504307,
      "TXT_PINCODE_LOCALITY": "BAZARHATHNOOR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504308,
      "TXT_PINCODE_LOCALITY": "GUDIHATHNOOR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504309,
      "TXT_PINCODE_LOCALITY": "JAINATH",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504310,
      "TXT_PINCODE_LOCALITY": "MAMDA.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504311,
      "TXT_PINCODE_LOCALITY": "UTNOOR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504312,
      "TXT_PINCODE_LOCALITY": "TALAAMADUGU",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504313,
      "TXT_PINCODE_LOCALITY": "JAINOOR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504318,
      "TXT_PINCODE_LOCALITY": "KHODAD.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504319,
      "TXT_PINCODE_LOCALITY": "PONNARI B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504321,
      "TXT_PINCODE_LOCALITY": "WANKDI.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504322,
      "TXT_PINCODE_LOCALITY": "GURAJ MANNUR.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504323,
      "TXT_PINCODE_LOCALITY": "NAREDIGONDA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504324,
      "TXT_PINCODE_LOCALITY": "BELA S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504325,
      "TXT_PINCODE_LOCALITY": "JAINAD",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504327,
      "TXT_PINCODE_LOCALITY": "SUNKIDI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504328,
      "TXT_PINCODE_LOCALITY": "CHICHPALLE",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443191,
      "City District Name": "WANKDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504330,
      "TXT_PINCODE_LOCALITY": "PIPRI.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504340,
      "TXT_PINCODE_LOCALITY": "NACHAN YELLAPUR.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504342,
      "TXT_PINCODE_LOCALITY": "INDARPALLI.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504343,
      "TXT_PINCODE_LOCALITY": "MANIDI PALLI.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504344,
      "TXT_PINCODE_LOCALITY": "HAJIPUR.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504346,
      "TXT_PINCODE_LOCALITY": "INDRAVELLY.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504391,
      "TXT_PINCODE_LOCALITY": "PONNARI.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 504926,
      "TXT_PINCODE_LOCALITY": "MEDPALLE.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443181,
      "City District Name": "SIRPUR (T)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505001,
      "TXT_PINCODE_LOCALITY": "KARIMNAGAR H.O.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505002,
      "TXT_PINCODE_LOCALITY": "DIST COURT BLDGS.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505017,
      "TXT_PINCODE_LOCALITY": "ELBOTHARAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443144,
      "City District Name": "KARIMNAGAR RURAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505101,
      "TXT_PINCODE_LOCALITY": "UPPAL (KNR)",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505102,
      "TXT_PINCODE_LOCALITY": "VAGAPALLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505122,
      "TXT_PINCODE_LOCALITY": "JAMMIKUNTA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505129,
      "TXT_PINCODE_LOCALITY": "BEDGAL",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505152,
      "TXT_PINCODE_LOCALITY": "KANAGARTHI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505153,
      "TXT_PINCODE_LOCALITY": "SRIRAMPUR.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505162,
      "TXT_PINCODE_LOCALITY": "KOLANUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505172,
      "TXT_PINCODE_LOCALITY": "PEDDAPALLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505173,
      "TXT_PINCODE_LOCALITY": "ASAPPANNAPET S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505174,
      "TXT_PINCODE_LOCALITY": "PEDDAPALLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505180,
      "TXT_PINCODE_LOCALITY": "CHAMANPALLI.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505184,
      "TXT_PINCODE_LOCALITY": "JALLARAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505185,
      "TXT_PINCODE_LOCALITY": "NEERUKULLA.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505186,
      "TXT_PINCODE_LOCALITY": "CHERLABUTHKUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505187,
      "TXT_PINCODE_LOCALITY": "BASANT NAGAR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505188,
      "TXT_PINCODE_LOCALITY": "KARIMNAGAR S. O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505208,
      "TXT_PINCODE_LOCALITY": "KOTAPALLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505209,
      "TXT_PINCODE_LOCALITY": "GODAWARIKHANI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505210,
      "TXT_PINCODE_LOCALITY": "FERTILIZER CITY",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505211,
      "TXT_PINCODE_LOCALITY": "GV8INCLINE S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505212,
      "TXT_PINCODE_LOCALITY": "KAMANPUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505214,
      "TXT_PINCODE_LOCALITY": "PEDDAPALLI S. O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505215,
      "TXT_PINCODE_LOCALITY": "JYOTHI NAGAR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505236,
      "TXT_PINCODE_LOCALITY": "KORATLA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505254,
      "TXT_PINCODE_LOCALITY": "RANGASAGAR.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443177,
      "City District Name": "SARANGAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505258,
      "TXT_PINCODE_LOCALITY": "MUTHANNAPET",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443123,
      "City District Name": "BEJJANKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505301,
      "TXT_PINCODE_LOCALITY": "SIRSILLA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505302,
      "TXT_PINCODE_LOCALITY": "VEMULA VADA SANKEPALLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505303,
      "TXT_PINCODE_LOCALITY": "KISHANDASPET S. O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505304,
      "TXT_PINCODE_LOCALITY": "GUMBHIRAO PET",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505305,
      "TXT_PINCODE_LOCALITY": "GOLLAPALLI.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505306,
      "TXT_PINCODE_LOCALITY": "AMBARIPET S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505307,
      "TXT_PINCODE_LOCALITY": "RUDRANGI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505308,
      "TXT_PINCODE_LOCALITY": "NARMALA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443137,
      "City District Name": "GAMBHIRAOPET",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505309,
      "TXT_PINCODE_LOCALITY": "KONARAO PET.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505311,
      "TXT_PINCODE_LOCALITY": "NIZAMABAD (SRS) S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505321,
      "TXT_PINCODE_LOCALITY": "RAMLACHAKKAPETA.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443158,
      "City District Name": "METPALLE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505325,
      "TXT_PINCODE_LOCALITY": "METPALLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505326,
      "TXT_PINCODE_LOCALITY": "KORATLA B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505327,
      "TXT_PINCODE_LOCALITY": "JAGTIAL H O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505330,
      "TXT_PINCODE_LOCALITY": "MADHAPUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505331,
      "TXT_PINCODE_LOCALITY": "SATARAM.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505401,
      "TXT_PINCODE_LOCALITY": "KARIMNAGAR S. O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505402,
      "TXT_PINCODE_LOCALITY": "SIRSILLA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505403,
      "TXT_PINCODE_LOCALITY": "RAJANA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 442110,
      "City District Name": "SIRCILLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505404,
      "TXT_PINCODE_LOCALITY": "MUSTABAD S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505405,
      "TXT_PINCODE_LOCALITY": "TADOOR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505407,
      "TXT_PINCODE_LOCALITY": "BANDANKAL.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505408,
      "TXT_PINCODE_LOCALITY": "POTHUGAL.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505410,
      "TXT_PINCODE_LOCALITY": "JILLELLA.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505412,
      "TXT_PINCODE_LOCALITY": "CHEERLAVANCHA.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505413,
      "TXT_PINCODE_LOCALITY": "VABLAPUR.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505415,
      "TXT_PINCODE_LOCALITY": "CHOPPADANDI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505416,
      "TXT_PINCODE_LOCALITY": "MYDARAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505417,
      "TXT_PINCODE_LOCALITY": "NAGNOOR.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505421,
      "TXT_PINCODE_LOCALITY": "MULKANOOR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443124,
      "City District Name": "BHEEMADEVARPALLE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505425,
      "TXT_PINCODE_LOCALITY": "DHARAMAPURI.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505445,
      "TXT_PINCODE_LOCALITY": "GANGADHARA S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505450,
      "TXT_PINCODE_LOCALITY": "IBRAHIMPATNAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505451,
      "TXT_PINCODE_LOCALITY": "KOTHAPALLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505452,
      "TXT_PINCODE_LOCALITY": "MALLIAL S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505453,
      "TXT_PINCODE_LOCALITY": "MEDPALLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505454,
      "TXT_PINCODE_LOCALITY": "KARIMNAGAR S. O.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505455,
      "TXT_PINCODE_LOCALITY": "ANTHERGAON.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505459,
      "TXT_PINCODE_LOCALITY": "VENDIRA.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505460,
      "TXT_PINCODE_LOCALITY": "RAIKAL",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505462,
      "TXT_PINCODE_LOCALITY": "SIRIKONDA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505466,
      "TXT_PINCODE_LOCALITY": "ANTHAKPET S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505467,
      "TXT_PINCODE_LOCALITY": "HUSNABAD S.O (KARIM NAGAR)",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505468,
      "TXT_PINCODE_LOCALITY": "HUZURABAD",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505469,
      "TXT_PINCODE_LOCALITY": "THIMMAPUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505470,
      "TXT_PINCODE_LOCALITY": "MOLANGUR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505471,
      "TXT_PINCODE_LOCALITY": "BHEMADEVERAPALLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505472,
      "TXT_PINCODE_LOCALITY": "BOMMANAPALLY S. O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505473,
      "TXT_PINCODE_LOCALITY": "SHANIGRAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505474,
      "TXT_PINCODE_LOCALITY": "KONDAPALKALA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505475,
      "TXT_PINCODE_LOCALITY": "RACHAPALLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505476,
      "TXT_PINCODE_LOCALITY": "YELKADURTHI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505480,
      "TXT_PINCODE_LOCALITY": "VANGARA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505481,
      "TXT_PINCODE_LOCALITY": "THIMMAPUR.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505482,
      "TXT_PINCODE_LOCALITY": "KAMANPUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443153,
      "City District Name": "KOTHAPALLE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505490,
      "TXT_PINCODE_LOCALITY": "KESAVAPATNAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505497,
      "TXT_PINCODE_LOCALITY": "KOTHAPALLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505498,
      "TXT_PINCODE_LOCALITY": "K C CAMPUS (HUZURABAD)",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505501,
      "TXT_PINCODE_LOCALITY": "KODMIAL S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505502,
      "TXT_PINCODE_LOCALITY": "VEENAVANKA.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505503,
      "TXT_PINCODE_LOCALITY": "KATARAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505504,
      "TXT_PINCODE_LOCALITY": "MAHADEVPUR.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505505,
      "TXT_PINCODE_LOCALITY": "PEDDAPALLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505514,
      "TXT_PINCODE_LOCALITY": "ANTHERGAN TEXTILES S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505524,
      "TXT_PINCODE_LOCALITY": "ANANTHPALLY",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505525,
      "TXT_PINCODE_LOCALITY": "DULIKATTA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505526,
      "TXT_PINCODE_LOCALITY": "VELGATOOR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505527,
      "TXT_PINCODE_LOCALITY": "ALGUNUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505528,
      "TXT_PINCODE_LOCALITY": "BEJJANKI S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505529,
      "TXT_PINCODE_LOCALITY": "IBRAHIMNAGAR B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505530,
      "TXT_PINCODE_LOCALITY": "THOTAPALLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505531,
      "TXT_PINCODE_LOCALITY": "RAMADUGU.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505532,
      "TXT_PINCODE_LOCALITY": "GOLLAPALLI S.O (KARIM NAGAR)",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505533,
      "TXT_PINCODE_LOCALITY": "ALGUNUR B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505535,
      "TXT_PINCODE_LOCALITY": "CHILWA KADUR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505545,
      "TXT_PINCODE_LOCALITY": "NAWABPET S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505547,
      "TXT_PINCODE_LOCALITY": "JANGAON S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505548,
      "TXT_PINCODE_LOCALITY": "MALLAM PALLI S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 505567,
      "TXT_PINCODE_LOCALITY": "PANDILLA S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441345,
      "City District Name": "KARIMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506001,
      "TXT_PINCODE_LOCALITY": "HANAMKONDA H.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506002,
      "TXT_PINCODE_LOCALITY": "S V N ROAD",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506003,
      "TXT_PINCODE_LOCALITY": "BAPUJI NAGAR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506004,
      "TXT_PINCODE_LOCALITY": "R E COLLEGE (WRNGL)",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506005,
      "TXT_PINCODE_LOCALITY": "RANGSHAIPET S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506006,
      "TXT_PINCODE_LOCALITY": "DESHAIPET B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506007,
      "TXT_PINCODE_LOCALITY": "MULUGU",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506008,
      "TXT_PINCODE_LOCALITY": "FORT WARANGAL",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506009,
      "TXT_PINCODE_LOCALITY": "HANMAKONDA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506010,
      "TXT_PINCODE_LOCALITY": "SUBEDARI (WGL)",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506011,
      "TXT_PINCODE_LOCALITY": "DEVAL HAZAR SUTOON",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506012,
      "TXT_PINCODE_LOCALITY": "M G ROAD",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506013,
      "TXT_PINCODE_LOCALITY": "LAXMIPUR.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506015,
      "TXT_PINCODE_LOCALITY": "K C COLONY S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506063,
      "TXT_PINCODE_LOCALITY": "KOTHUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443174,
      "City District Name": "RAIPARTHY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506101,
      "TXT_PINCODE_LOCALITY": "MAHABUBABAD.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506102,
      "TXT_PINCODE_LOCALITY": "AMMANGAL",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 442106,
      "City District Name": "MAHABUBABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506103,
      "TXT_PINCODE_LOCALITY": "MAHABUBABAD",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 442106,
      "City District Name": "MAHABUBABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506104,
      "TXT_PINCODE_LOCALITY": "NERADA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506105,
      "TXT_PINCODE_LOCALITY": "KURVI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506107,
      "TXT_PINCODE_LOCALITY": "HANMANTHPUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443165,
      "City District Name": "NARMETTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506112,
      "TXT_PINCODE_LOCALITY": "KESAMUDRAM S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506122,
      "TXT_PINCODE_LOCALITY": "NEKKONDA S. O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506128,
      "TXT_PINCODE_LOCALITY": "CHANDRUGONDA S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506132,
      "TXT_PINCODE_LOCALITY": "BHANJIPET B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506133,
      "TXT_PINCODE_LOCALITY": "ASHOK NAGAR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506134,
      "TXT_PINCODE_LOCALITY": "DAMERAVANCHA B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506135,
      "TXT_PINCODE_LOCALITY": "KOTHAGUDA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506142,
      "TXT_PINCODE_LOCALITY": "MADIKONDA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506143,
      "TXT_PINCODE_LOCALITY": "ZAFARGADDAH",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506144,
      "TXT_PINCODE_LOCALITY": "PALLAGUTTA S. O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506145,
      "TXT_PINCODE_LOCALITY": "MALKAPUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506146,
      "TXT_PINCODE_LOCALITY": "PALAKURTHY",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506151,
      "TXT_PINCODE_LOCALITY": "DHARMASAGAR S. O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506163,
      "TXT_PINCODE_LOCALITY": "TORRUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506164,
      "TXT_PINCODE_LOCALITY": "TEMPLE STREET",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506165,
      "TXT_PINCODE_LOCALITY": "PALAMPET",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506166,
      "TXT_PINCODE_LOCALITY": "WARANGAL S. O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506167,
      "TXT_PINCODE_LOCALITY": "JANGAON HO",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506168,
      "TXT_PINCODE_LOCALITY": "GHANPUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506169,
      "TXT_PINCODE_LOCALITY": "WARANGAL",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506170,
      "TXT_PINCODE_LOCALITY": "KTPP S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506172,
      "TXT_PINCODE_LOCALITY": "MANGAPET",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506175,
      "TXT_PINCODE_LOCALITY": "NARMITTA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443165,
      "City District Name": "NARMETTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506201,
      "TXT_PINCODE_LOCALITY": "PEMBARTI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506221,
      "TXT_PINCODE_LOCALITY": "BACHANNAPET S. O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506222,
      "TXT_PINCODE_LOCALITY": "KODAKONDLA S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506223,
      "TXT_PINCODE_LOCALITY": "CHIRYAL S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506224,
      "TXT_PINCODE_LOCALITY": "NARMETTA S. O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506230,
      "TXT_PINCODE_LOCALITY": "AKNUR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506234,
      "TXT_PINCODE_LOCALITY": "PENCHIKALPET",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443121,
      "City District Name": "ATMAKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506242,
      "TXT_PINCODE_LOCALITY": "SOLIPURAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443165,
      "City District Name": "NARMETTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506244,
      "TXT_PINCODE_LOCALITY": "RAGHUNATH PALLY S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506252,
      "TXT_PINCODE_LOCALITY": "IPPAGUDA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506278,
      "TXT_PINCODE_LOCALITY": "ANNARAM B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506301,
      "TXT_PINCODE_LOCALITY": "CHAGAL",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506302,
      "TXT_PINCODE_LOCALITY": "DEVARUPPULA S. O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506303,
      "TXT_PINCODE_LOCALITY": "JANGAON.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506304,
      "TXT_PINCODE_LOCALITY": "CHITYAL",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443185,
      "City District Name": "THORRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506310,
      "TXT_PINCODE_LOCALITY": "AANOLE B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506313,
      "TXT_PINCODE_LOCALITY": "WARDHANNAPET.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506314,
      "TXT_PINCODE_LOCALITY": "RAIPARHTY",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506315,
      "TXT_PINCODE_LOCALITY": "MAHBUBABAD.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506316,
      "TXT_PINCODE_LOCALITY": "ZAFFARGADH",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506317,
      "TXT_PINCODE_LOCALITY": "PEDDA VANGARA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506318,
      "TXT_PINCODE_LOCALITY": "WADDEKOTHAPALLE",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506319,
      "TXT_PINCODE_LOCALITY": "SHAYAMPET S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506320,
      "TXT_PINCODE_LOCALITY": "POCHANPALLE.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443149,
      "City District Name": "KODAKANDLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506324,
      "TXT_PINCODE_LOCALITY": "BODLADA B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506329,
      "TXT_PINCODE_LOCALITY": "SANGAM.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506330,
      "TXT_PINCODE_LOCALITY": "SANGEM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506331,
      "TXT_PINCODE_LOCALITY": "TOGARRAI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506332,
      "TXT_PINCODE_LOCALITY": "CHENNARAYUNI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506333,
      "TXT_PINCODE_LOCALITY": "MACHAPUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506335,
      "TXT_PINCODE_LOCALITY": "GURAVANNAPET",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443130,
      "City District Name": "CHERIAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506342,
      "TXT_PINCODE_LOCALITY": "ATMAKUR S. O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506343,
      "TXT_PINCODE_LOCALITY": "MULUG S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506344,
      "TXT_PINCODE_LOCALITY": "GOVINDA RAO PET",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506345,
      "TXT_PINCODE_LOCALITY": "GHANPUR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506347,
      "TXT_PINCODE_LOCALITY": "PASARA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506348,
      "TXT_PINCODE_LOCALITY": "REGONDA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506349,
      "TXT_PINCODE_LOCALITY": "MALLAMPALLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506352,
      "TXT_PINCODE_LOCALITY": "JANGALAPALLI.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506353,
      "TXT_PINCODE_LOCALITY": "KASIMDEVIPET",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443160,
      "City District Name": "MULUG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506355,
      "TXT_PINCODE_LOCALITY": "CHITYAL.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506356,
      "TXT_PINCODE_LOCALITY": "CHITYAL",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506358,
      "TXT_PINCODE_LOCALITY": "CHITYAL (WARANGAL).",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506359,
      "TXT_PINCODE_LOCALITY": "SURIPALLE",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443129,
      "City District Name": "CHENNARAOPET",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506360,
      "TXT_PINCODE_LOCALITY": "GATTIKAL",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443174,
      "City District Name": "RAIPARTHY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506365,
      "TXT_PINCODE_LOCALITY": "PARWATHAGIRI S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506366,
      "TXT_PINCODE_LOCALITY": "MOGALLAPALLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506367,
      "TXT_PINCODE_LOCALITY": "MUDDUR (WGL)",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506368,
      "TXT_PINCODE_LOCALITY": "MALLIKADDUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506369,
      "TXT_PINCODE_LOCALITY": "REDLAWADA S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506370,
      "TXT_PINCODE_LOCALITY": "HANMAKONDA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506371,
      "TXT_PINCODE_LOCALITY": "HASANPARTHI S. O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506374,
      "TXT_PINCODE_LOCALITY": "BOMMAKAL.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506381,
      "TXT_PINCODE_LOCALITY": "DORNAKAL",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506382,
      "TXT_PINCODE_LOCALITY": "DORNAKAL S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506391,
      "TXT_PINCODE_LOCALITY": "NADIKUDA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 506638,
      "TXT_PINCODE_LOCALITY": "WARANGAL.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507001,
      "TXT_PINCODE_LOCALITY": "WYRA ROAD",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507002,
      "TXT_PINCODE_LOCALITY": "KHAMMAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507003,
      "TXT_PINCODE_LOCALITY": "DABALA BAZAR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507004,
      "TXT_PINCODE_LOCALITY": "NSC CAMPUS S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507101,
      "TXT_PINCODE_LOCALITY": "KOTHAGUDEM COLLS",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507103,
      "TXT_PINCODE_LOCALITY": "VENKATESWRAPURAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507105,
      "TXT_PINCODE_LOCALITY": "PALVANCHA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4961,
      "City District Name": "PALWANCHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507106,
      "TXT_PINCODE_LOCALITY": "LAXMIDEVI PALLI S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507111,
      "TXT_PINCODE_LOCALITY": "BHADRACHALAM H.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507112,
      "TXT_PINCODE_LOCALITY": "BHUPATHIRAO COLONY S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507113,
      "TXT_PINCODE_LOCALITY": "MOTHUGUDEM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507114,
      "TXT_PINCODE_LOCALITY": "KOTHAGUDEM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507115,
      "TXT_PINCODE_LOCALITY": "PALONCHA S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507116,
      "TXT_PINCODE_LOCALITY": "ASHWAPUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507117,
      "TXT_PINCODE_LOCALITY": "MANUGURU",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507118,
      "TXT_PINCODE_LOCALITY": "KOTHAGUDEM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507119,
      "TXT_PINCODE_LOCALITY": "RUDRAMPUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507120,
      "TXT_PINCODE_LOCALITY": "SHUJATNAGAR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507121,
      "TXT_PINCODE_LOCALITY": "KUNAVARAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507122,
      "TXT_PINCODE_LOCALITY": "KAMEPALLI B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507123,
      "TXT_PINCODE_LOCALITY": "YELLANDU.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507124,
      "TXT_PINCODE_LOCALITY": "YELLAANDU COLLERIES",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507125,
      "TXT_PINCODE_LOCALITY": "PRAKASAVANA COLONY - 2578",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507127,
      "TXT_PINCODE_LOCALITY": "BAYARAM S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507128,
      "TXT_PINCODE_LOCALITY": "SARAPAKA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507129,
      "TXT_PINCODE_LOCALITY": "NELLI PAKA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507130,
      "TXT_PINCODE_LOCALITY": "MORAMPALLI S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507131,
      "TXT_PINCODE_LOCALITY": "KINNERASANI PROJECT S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507132,
      "TXT_PINCODE_LOCALITY": "SINGABHUPALEM S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507133,
      "TXT_PINCODE_LOCALITY": "CHARLA S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507134,
      "TXT_PINCODE_LOCALITY": "GOURI DEVI PETA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507135,
      "TXT_PINCODE_LOCALITY": "CHANDRAPURAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507136,
      "TXT_PINCODE_LOCALITY": "VENKATAPURAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507137,
      "TXT_PINCODE_LOCALITY": "DUMMAGUDEM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507138,
      "TXT_PINCODE_LOCALITY": "GOWTAMPUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507139,
      "TXT_PINCODE_LOCALITY": "PARNASALA B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507140,
      "TXT_PINCODE_LOCALITY": "SATYANARAYANAPURAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507141,
      "TXT_PINCODE_LOCALITY": "VELAIR PADU S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507151,
      "TXT_PINCODE_LOCALITY": "MALLARAM.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507152,
      "TXT_PINCODE_LOCALITY": "JANAMPET.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507153,
      "TXT_PINCODE_LOCALITY": "NELLI PAKA (BRG).",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507154,
      "TXT_PINCODE_LOCALITY": "CAMPUS",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507156,
      "TXT_PINCODE_LOCALITY": "NARSAPURAM.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507157,
      "TXT_PINCODE_LOCALITY": "KUSUMANCHI (MD)",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507158,
      "TXT_PINCODE_LOCALITY": "GOKANAPALLE",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507159,
      "TXT_PINCODE_LOCALITY": "KUSHMANCHI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507160,
      "TXT_PINCODE_LOCALITY": "NELAKONDAPALLI S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507161,
      "TXT_PINCODE_LOCALITY": "SUBLED S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507162,
      "TXT_PINCODE_LOCALITY": "MUDIGONDA.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507163,
      "TXT_PINCODE_LOCALITY": "TIRUMALAYAPALEM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507164,
      "TXT_PINCODE_LOCALITY": "VEMSUR MANDAL",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507165,
      "TXT_PINCODE_LOCALITY": "WYRA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507166,
      "TXT_PINCODE_LOCALITY": "SOMAVARAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507167,
      "TXT_PINCODE_LOCALITY": "KHAMMAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507168,
      "TXT_PINCODE_LOCALITY": "ENKUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507169,
      "TXT_PINCODE_LOCALITY": "MALLARAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507170,
      "TXT_PINCODE_LOCALITY": "TALLAM PAHAD",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507172,
      "TXT_PINCODE_LOCALITY": "NOOTHANKAL.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507173,
      "TXT_PINCODE_LOCALITY": "REBBAVARAM.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507174,
      "TXT_PINCODE_LOCALITY": "GOLLA PUDI.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507175,
      "TXT_PINCODE_LOCALITY": "MUTHUGUDEM.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507176,
      "TXT_PINCODE_LOCALITY": "VENKATAPURAM S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507177,
      "TXT_PINCODE_LOCALITY": "JEELA CHERUVU.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507182,
      "TXT_PINCODE_LOCALITY": "KOMMINAPALLI S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507183,
      "TXT_PINCODE_LOCALITY": "PINDIPOLU S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507201,
      "TXT_PINCODE_LOCALITY": "VENKATAPURAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507202,
      "TXT_PINCODE_LOCALITY": "BANIGANDLA PAHAD",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507203,
      "TXT_PINCODE_LOCALITY": "MADHIRA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507204,
      "TXT_PINCODE_LOCALITY": "KHAMMAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507206,
      "TXT_PINCODE_LOCALITY": "DENDUKUR.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507207,
      "TXT_PINCODE_LOCALITY": "MEENAVOLU S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507208,
      "TXT_PINCODE_LOCALITY": "PANDILLA PALLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507209,
      "TXT_PINCODE_LOCALITY": "KALLUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507210,
      "TXT_PINCODE_LOCALITY": "GARLA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507211,
      "TXT_PINCODE_LOCALITY": "BAYYARAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507212,
      "TXT_PINCODE_LOCALITY": "PANDILLA PALLI.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507213,
      "TXT_PINCODE_LOCALITY": "NAGULA VANCHA S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507214,
      "TXT_PINCODE_LOCALITY": "GOPATHI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507216,
      "TXT_PINCODE_LOCALITY": "TIMMINENI PALEM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507301,
      "TXT_PINCODE_LOCALITY": "ASWARAOPET",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507302,
      "TXT_PINCODE_LOCALITY": "PENUBALLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507303,
      "TXT_PINCODE_LOCALITY": "SATTUPALLI BUS STATION",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507304,
      "TXT_PINCODE_LOCALITY": "KALA KOTA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507305,
      "TXT_PINCODE_LOCALITY": "TANIKELLA S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507306,
      "TXT_PINCODE_LOCALITY": "DAMMAPETA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507311,
      "TXT_PINCODE_LOCALITY": "PALADUGU B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507312,
      "TXT_PINCODE_LOCALITY": "KONJERLA S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507313,
      "TXT_PINCODE_LOCALITY": "KALA KOTA S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507316,
      "TXT_PINCODE_LOCALITY": "PEDDI REDDI GUDEM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507318,
      "TXT_PINCODE_LOCALITY": "VVPALEM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 507570,
      "TXT_PINCODE_LOCALITY": "MEENAVOLU B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508001,
      "TXT_PINCODE_LOCALITY": "RASHTRAPATI ROAD",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508002,
      "TXT_PINCODE_LOCALITY": "KANAGAL S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508004,
      "TXT_PINCODE_LOCALITY": "S.L.B.C. CAMP COLONY S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508005,
      "TXT_PINCODE_LOCALITY": "SERILINGOTAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443143,
      "City District Name": "KANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508006,
      "TXT_PINCODE_LOCALITY": "JANGAMAI GUDA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443143,
      "City District Name": "KANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508013,
      "TXT_PINCODE_LOCALITY": "CHERLA PALLI B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508101,
      "TXT_PINCODE_LOCALITY": "KOLLUR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508102,
      "TXT_PINCODE_LOCALITY": "KOLANPAKA S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508105,
      "TXT_PINCODE_LOCALITY": "RAJAPETA B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508111,
      "TXT_PINCODE_LOCALITY": "KURELLA S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508112,
      "TXT_PINCODE_LOCALITY": "NALGONDA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508113,
      "TXT_PINCODE_LOCALITY": "RAMANNAPETA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508114,
      "TXT_PINCODE_LOCALITY": "CHITYAL",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508115,
      "TXT_PINCODE_LOCALITY": "YADGAR PALLI S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508116,
      "TXT_PINCODE_LOCALITY": "BHONGIR.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508117,
      "TXT_PINCODE_LOCALITY": "MASAIPET S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508121,
      "TXT_PINCODE_LOCALITY": "MARYALA S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508123,
      "TXT_PINCODE_LOCALITY": "RAIGIRI B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508125,
      "TXT_PINCODE_LOCALITY": "PEDA KAPARTHY S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508126,
      "TXT_PINCODE_LOCALITY": "BIBINAGAR.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508127,
      "TXT_PINCODE_LOCALITY": "GUDUR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508142,
      "TXT_PINCODE_LOCALITY": "TUMMALA GUDA S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508144,
      "TXT_PINCODE_LOCALITY": "AIPUR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508201,
      "TXT_PINCODE_LOCALITY": "GAREDEPALLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508202,
      "TXT_PINCODE_LOCALITY": "NAGARJUNASAGAR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508203,
      "TXT_PINCODE_LOCALITY": "VIJAYAPURI NORTH",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508204,
      "TXT_PINCODE_LOCALITY": "NALGONDA.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508205,
      "TXT_PINCODE_LOCALITY": "KATTANGUR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508206,
      "TXT_PINCODE_LOCALITY": "KODADA MANDAL",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508207,
      "TXT_PINCODE_LOCALITY": "SANJAYA NAGAR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508208,
      "TXT_PINCODE_LOCALITY": "KATTANGOOR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443146,
      "City District Name": "KATTANGOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508210,
      "TXT_PINCODE_LOCALITY": "MUSI PROJECT",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508211,
      "TXT_PINCODE_LOCALITY": "NAKREKAL S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508212,
      "TXT_PINCODE_LOCALITY": "SIRI KONDA S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508213,
      "TXT_PINCODE_LOCALITY": "BANK STREET S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508214,
      "TXT_PINCODE_LOCALITY": "NACHARAM INDUSTRIAL ESTAT S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508215,
      "TXT_PINCODE_LOCALITY": "NOMULA B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508216,
      "TXT_PINCODE_LOCALITY": "AITIPAMULA S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508217,
      "TXT_PINCODE_LOCALITY": "VEMULA PALLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508218,
      "TXT_PINCODE_LOCALITY": "NEREDICHERLA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508219,
      "TXT_PINCODE_LOCALITY": "AMRUT NAGAR (MIRYALAGUDE) S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508220,
      "TXT_PINCODE_LOCALITY": "KUDAKUDA ROAD S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508221,
      "TXT_PINCODE_LOCALITY": "MIRYALAGUDA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508222,
      "TXT_PINCODE_LOCALITY": "TIMMAPUR B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508223,
      "TXT_PINCODE_LOCALITY": "TATIPAMULA S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508224,
      "TXT_PINCODE_LOCALITY": "BHRUGU GADDA S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508225,
      "TXT_PINCODE_LOCALITY": "METTAMPALLI S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508227,
      "TXT_PINCODE_LOCALITY": "ANNANTA GIRI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508228,
      "TXT_PINCODE_LOCALITY": "PERUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443120,
      "City District Name": "ANUMULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508229,
      "TXT_PINCODE_LOCALITY": "SHANTI NAGAR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508230,
      "TXT_PINCODE_LOCALITY": "CHILKOOR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508233,
      "TXT_PINCODE_LOCALITY": "HUZURNAGAR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508234,
      "TXT_PINCODE_LOCALITY": "SURYAPET B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508238,
      "TXT_PINCODE_LOCALITY": "GUDI BANDA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508242,
      "TXT_PINCODE_LOCALITY": "GOLLAGUDA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443122,
      "City District Name": "ATMAKUR (S)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508243,
      "TXT_PINCODE_LOCALITY": "PENDLIPAKALA S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508244,
      "TXT_PINCODE_LOCALITY": "MUNUGODE S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508245,
      "TXT_PINCODE_LOCALITY": "MARRIGUDA S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508246,
      "TXT_PINCODE_LOCALITY": "MEDLACHERUVU",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508247,
      "TXT_PINCODE_LOCALITY": "INDLOOR B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508248,
      "TXT_PINCODE_LOCALITY": "K BHEEMUNIPALLE",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508249,
      "TXT_PINCODE_LOCALITY": "CHANDAMPET S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508250,
      "TXT_PINCODE_LOCALITY": "VENKATESHWARANAGAR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508252,
      "TXT_PINCODE_LOCALITY": "CHOUT UPPAL",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508253,
      "TXT_PINCODE_LOCALITY": "NARAYANPUR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508254,
      "TXT_PINCODE_LOCALITY": "NARKET PALLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508255,
      "TXT_PINCODE_LOCALITY": "KASTHALA CHANDUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508256,
      "TXT_PINCODE_LOCALITY": "KOPPOLE S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508257,
      "TXT_PINCODE_LOCALITY": "GUMMADAVALLI S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508258,
      "TXT_PINCODE_LOCALITY": "CHERUKUPALLI S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508259,
      "TXT_PINCODE_LOCALITY": "CHANDAMPET B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508262,
      "TXT_PINCODE_LOCALITY": "JUNUTHALA S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508265,
      "TXT_PINCODE_LOCALITY": "KANDI BANDA S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508266,
      "TXT_PINCODE_LOCALITY": "PEDDA VOORA B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508267,
      "TXT_PINCODE_LOCALITY": "CHENNAIPALEM B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441342,
      "City District Name": "ADILABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508268,
      "TXT_PINCODE_LOCALITY": "KOKKIRENI.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508270,
      "TXT_PINCODE_LOCALITY": "GANAPAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442267,
      "City District Name": "GANAPAVARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508272,
      "TXT_PINCODE_LOCALITY": "DONA PODU.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508277,
      "TXT_PINCODE_LOCALITY": "MOTHCORE S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508278,
      "TXT_PINCODE_LOCALITY": "MIRYALGUDA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508279,
      "TXT_PINCODE_LOCALITY": "PANIGIRI.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508280,
      "TXT_PINCODE_LOCALITY": "THUNGA THURTHI.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508281,
      "TXT_PINCODE_LOCALITY": "KANCHAN PALLY.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508282,
      "TXT_PINCODE_LOCALITY": "KONDAGADAPA.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508283,
      "TXT_PINCODE_LOCALITY": "PATIMANTLA.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508284,
      "TXT_PINCODE_LOCALITY": "POCHAMPALLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508285,
      "TXT_PINCODE_LOCALITY": "VALIGONDA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508286,
      "TXT_PINCODE_LOCALITY": "WANGOPALLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508287,
      "TXT_PINCODE_LOCALITY": "CHINA KANDUKURU S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508288,
      "TXT_PINCODE_LOCALITY": "INKRIYALA.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508289,
      "TXT_PINCODE_LOCALITY": "JULUR.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508290,
      "TXT_PINCODE_LOCALITY": "ANJAPUR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508291,
      "TXT_PINCODE_LOCALITY": "NERADUGOMMU",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443128,
      "City District Name": "CHANDAM PET",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508293,
      "TXT_PINCODE_LOCALITY": "KUNOOR.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508296,
      "TXT_PINCODE_LOCALITY": "RAGHAVAPURAM.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508299,
      "TXT_PINCODE_LOCALITY": "ETOOR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443163,
      "City District Name": "NAGATAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508301,
      "TXT_PINCODE_LOCALITY": "PARSAI PALLI.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508332,
      "TXT_PINCODE_LOCALITY": "PALAKAVEEDU.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508333,
      "TXT_PINCODE_LOCALITY": "DIRSIH CHERLA.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508335,
      "TXT_PINCODE_LOCALITY": "JANPAHAD S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508336,
      "TXT_PINCODE_LOCALITY": "PENCHIKAL DINNE.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508337,
      "TXT_PINCODE_LOCALITY": "MOTHE.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508346,
      "TXT_PINCODE_LOCALITY": "INDLOOR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508347,
      "TXT_PINCODE_LOCALITY": "KALMAL CHERUVU.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508349,
      "TXT_PINCODE_LOCALITY": "PEDU VEEDU.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508350,
      "TXT_PINCODE_LOCALITY": "CHALAKURTHI.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508351,
      "TXT_PINCODE_LOCALITY": "TOGARRAI.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508352,
      "TXT_PINCODE_LOCALITY": "KAPUGAL S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508353,
      "TXT_PINCODE_LOCALITY": "NALLABANDA GUDEM S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508355,
      "TXT_PINCODE_LOCALITY": "NEREDUCHERLA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508357,
      "TXT_PINCODE_LOCALITY": "KONDRAPOLE.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508358,
      "TXT_PINCODE_LOCALITY": "TRIPURARAM.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508364,
      "TXT_PINCODE_LOCALITY": "MARRUR.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508365,
      "TXT_PINCODE_LOCALITY": "TATIPAMULA.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508367,
      "TXT_PINCODE_LOCALITY": "ADSARLA PALLY.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508372,
      "TXT_PINCODE_LOCALITY": "AZAMPUR.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508373,
      "TXT_PINCODE_LOCALITY": "NAMPALLI S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508374,
      "TXT_PINCODE_LOCALITY": "MADUGULAPALLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508375,
      "TXT_PINCODE_LOCALITY": "DAMARCHERLA.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508376,
      "TXT_PINCODE_LOCALITY": "REKUMATLA.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508377,
      "TXT_PINCODE_LOCALITY": "HALIA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508381,
      "TXT_PINCODE_LOCALITY": "GONDRIAL.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508383,
      "TXT_PINCODE_LOCALITY": "THUNGA PAHAD.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508385,
      "TXT_PINCODE_LOCALITY": "PILLAMARRI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441349,
      "City District Name": "NALGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508524,
      "TXT_PINCODE_LOCALITY": "TIRUMALAGIRI (PATTI DUGYAL)",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443171,
      "City District Name": "PEDDA ADISERLA PALLE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508582,
      "TXT_PINCODE_LOCALITY": "PAHILWANPUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443188,
      "City District Name": "VALIGONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 508724,
      "TXT_PINCODE_LOCALITY": "IRUGANTI PALLE",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443143,
      "City District Name": "KANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509001,
      "TXT_PINCODE_LOCALITY": "MAHABUBNAGAR H O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509002,
      "TXT_PINCODE_LOCALITY": "MAHBUBNAGAR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509101,
      "TXT_PINCODE_LOCALITY": "MAHBUBNAGAR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509102,
      "TXT_PINCODE_LOCALITY": "ADILABAD",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509103,
      "TXT_PINCODE_LOCALITY": "WANAPARTHY.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509104,
      "TXT_PINCODE_LOCALITY": "PEBBAIR.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509105,
      "TXT_PINCODE_LOCALITY": "PENTA VALLI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509106,
      "TXT_PINCODE_LOCALITY": "RANGAPUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509107,
      "TXT_PINCODE_LOCALITY": "SINGOTAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509108,
      "TXT_PINCODE_LOCALITY": "KUDIKILLA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509109,
      "TXT_PINCODE_LOCALITY": "CHITYALA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509110,
      "TXT_PINCODE_LOCALITY": "MADANAPURAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509111,
      "TXT_PINCODE_LOCALITY": "CHINTAKUNTA KHURD",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509112,
      "TXT_PINCODE_LOCALITY": "PANGAL S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509113,
      "TXT_PINCODE_LOCALITY": "VARNA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509115,
      "TXT_PINCODE_LOCALITY": "CHANDAPUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509116,
      "TXT_PINCODE_LOCALITY": "RAJAPET",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509117,
      "TXT_PINCODE_LOCALITY": "KALWAKOLE.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509118,
      "TXT_PINCODE_LOCALITY": "MALLEPALLE",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443187,
      "City District Name": "UTKOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509119,
      "TXT_PINCODE_LOCALITY": "ALIPUR.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509120,
      "TXT_PINCODE_LOCALITY": "SRIRANGAPUR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509125,
      "TXT_PINCODE_LOCALITY": "GADWAL.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509126,
      "TXT_PINCODE_LOCALITY": "RAJOLI S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509127,
      "TXT_PINCODE_LOCALITY": "MEDIKONDA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509128,
      "TXT_PINCODE_LOCALITY": "ITKYALA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509129,
      "TXT_PINCODE_LOCALITY": "MACHERLA S. O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509130,
      "TXT_PINCODE_LOCALITY": "AMARCHINTA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509131,
      "TXT_PINCODE_LOCALITY": "ATMAKUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509132,
      "TXT_PINCODE_LOCALITY": "MULDAKAL S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509133,
      "TXT_PINCODE_LOCALITY": "DHARUR.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509134,
      "TXT_PINCODE_LOCALITY": "POODUR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509135,
      "TXT_PINCODE_LOCALITY": "UPPAL CAMP.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509137,
      "TXT_PINCODE_LOCALITY": "UTNOOR.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509138,
      "TXT_PINCODE_LOCALITY": "TATIKUNTA.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509139,
      "TXT_PINCODE_LOCALITY": "NANDINNE.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509140,
      "TXT_PINCODE_LOCALITY": "JULAIKAL.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509142,
      "TXT_PINCODE_LOCALITY": "AMARVOI.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509143,
      "TXT_PINCODE_LOCALITY": "YELKUR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509145,
      "TXT_PINCODE_LOCALITY": "BIJWAR.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509146,
      "TXT_PINCODE_LOCALITY": "MULAMALLE",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509147,
      "TXT_PINCODE_LOCALITY": "PALAVOI.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509149,
      "TXT_PINCODE_LOCALITY": "ALWAL PAHAD.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509150,
      "TXT_PINCODE_LOCALITY": "ALLURU S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509151,
      "TXT_PINCODE_LOCALITY": "VALLUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509152,
      "TXT_PINCODE_LOCALITY": "ALAMPUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509153,
      "TXT_PINCODE_LOCALITY": "UNDEVALLY",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509154,
      "TXT_PINCODE_LOCALITY": "KYATHUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509155,
      "TXT_PINCODE_LOCALITY": "TAKSILA S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509171,
      "TXT_PINCODE_LOCALITY": "TANGAL",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509172,
      "TXT_PINCODE_LOCALITY": "PATHAPALEM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509173,
      "TXT_PINCODE_LOCALITY": "GHATT",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509201,
      "TXT_PINCODE_LOCALITY": "AMARABAD",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509202,
      "TXT_PINCODE_LOCALITY": "FAROOQNAGAR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509203,
      "TXT_PINCODE_LOCALITY": "BIJNAPALLY S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509204,
      "TXT_PINCODE_LOCALITY": "DEVARKADRA.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509205,
      "TXT_PINCODE_LOCALITY": "NARAYANPET S. O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509206,
      "TXT_PINCODE_LOCALITY": "PEDDAMANDADI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 442097,
      "City District Name": "WANAPARTHY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509207,
      "TXT_PINCODE_LOCALITY": "KONDURG",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509208,
      "TXT_PINCODE_LOCALITY": "MAKHTAL.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509209,
      "TXT_PINCODE_LOCALITY": "NAGAR KURNOOL",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509210,
      "TXT_PINCODE_LOCALITY": "SHATAVAHANA COLONY.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509211,
      "TXT_PINCODE_LOCALITY": "JAJAPUR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509213,
      "TXT_PINCODE_LOCALITY": "BUDHARAM B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509214,
      "TXT_PINCODE_LOCALITY": "RANGA REDDI GUDA S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509215,
      "TXT_PINCODE_LOCALITY": "TANDORE S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509216,
      "TXT_PINCODE_LOCALITY": "MALLAPUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509217,
      "TXT_PINCODE_LOCALITY": "SHAHABAD",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509218,
      "TXT_PINCODE_LOCALITY": "NAGARAM S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509219,
      "TXT_PINCODE_LOCALITY": "KAUKUNTLA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509220,
      "TXT_PINCODE_LOCALITY": "PERUR B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509221,
      "TXT_PINCODE_LOCALITY": "GUDLA NERVA S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509223,
      "TXT_PINCODE_LOCALITY": "NANDIGAON B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509224,
      "TXT_PINCODE_LOCALITY": "KOLLAM PALLY S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509225,
      "TXT_PINCODE_LOCALITY": "KOTA KONDA S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509226,
      "TXT_PINCODE_LOCALITY": "INDRAKAL B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509227,
      "TXT_PINCODE_LOCALITY": "MEHBOOBNAGAR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 444148,
      "City District Name": "MEHABOOBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509228,
      "TXT_PINCODE_LOCALITY": "KOTHUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441343,
      "City District Name": "HYDERABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509235,
      "TXT_PINCODE_LOCALITY": "MAHBUBNAGAR S. O.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509237,
      "TXT_PINCODE_LOCALITY": "BRAHUMANAPALLY B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509238,
      "TXT_PINCODE_LOCALITY": "TALAKONDAPALLI B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509241,
      "TXT_PINCODE_LOCALITY": "MANNANUR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509242,
      "TXT_PINCODE_LOCALITY": "RAJAPUR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509243,
      "TXT_PINCODE_LOCALITY": "CHINA JATRAM B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509301,
      "TXT_PINCODE_LOCALITY": "STN JADCHERLA HO",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509302,
      "TXT_PINCODE_LOCALITY": "TOWNJADCHERLA S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509303,
      "TXT_PINCODE_LOCALITY": "GOLLA PALLI B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509305,
      "TXT_PINCODE_LOCALITY": "KODGAL S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509306,
      "TXT_PINCODE_LOCALITY": "GANGAPUR B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509307,
      "TXT_PINCODE_LOCALITY": "EDGANPALLI S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509308,
      "TXT_PINCODE_LOCALITY": "SHAPUR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509309,
      "TXT_PINCODE_LOCALITY": "ANNARAM",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443189,
      "City District Name": "VANGOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509311,
      "TXT_PINCODE_LOCALITY": "UTKUR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509320,
      "TXT_PINCODE_LOCALITY": "MANCHAL S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509321,
      "TXT_PINCODE_LOCALITY": "AMANGAL",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509323,
      "TXT_PINCODE_LOCALITY": "GAGAN PAHAD",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 425503,
      "City District Name": "MAHABUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509324,
      "TXT_PINCODE_LOCALITY": "KALWAKURTHY S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509325,
      "TXT_PINCODE_LOCALITY": "PALMAKOLE",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509326,
      "TXT_PINCODE_LOCALITY": "SRI SAILAM DAM WEST",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509327,
      "TXT_PINCODE_LOCALITY": "MADGUL S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509328,
      "TXT_PINCODE_LOCALITY": "TALA KONDA PALLI.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509329,
      "TXT_PINCODE_LOCALITY": "PEDDAPUR B.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509331,
      "TXT_PINCODE_LOCALITY": "SHAHAPUR.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509332,
      "TXT_PINCODE_LOCALITY": "CHEKUR S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509333,
      "TXT_PINCODE_LOCALITY": "CHEGUR.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509334,
      "TXT_PINCODE_LOCALITY": "HUNWADA S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509335,
      "TXT_PINCODE_LOCALITY": "KULAK CHERLA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509336,
      "TXT_PINCODE_LOCALITY": "RAWALA PALLI S. O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509337,
      "TXT_PINCODE_LOCALITY": "GANDEED",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509338,
      "TXT_PINCODE_LOCALITY": "KONDANGAL.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509339,
      "TXT_PINCODE_LOCALITY": "KOSGI.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509340,
      "TXT_PINCODE_LOCALITY": "NAWABPET.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509341,
      "TXT_PINCODE_LOCALITY": "GUNDVEED.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509342,
      "TXT_PINCODE_LOCALITY": "GUNDMAL.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509343,
      "TXT_PINCODE_LOCALITY": "A RAICHUR.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509344,
      "TXT_PINCODE_LOCALITY": "NANCHERLA.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509345,
      "TXT_PINCODE_LOCALITY": "BOMRASPET.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509346,
      "TXT_PINCODE_LOCALITY": "TUMKI METLA.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509347,
      "TXT_PINCODE_LOCALITY": "IBRAHIMBAD.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441351,
      "City District Name": "RANGAREDDY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509348,
      "TXT_PINCODE_LOCALITY": "KOMMIREDDI PALLI S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509349,
      "TXT_PINCODE_LOCALITY": "VANGOOR.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509350,
      "TXT_PINCODE_LOCALITY": "HUSNABAD",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441352,
      "City District Name": "WARANGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509351,
      "TXT_PINCODE_LOCALITY": "MARRIKAL",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509352,
      "TXT_PINCODE_LOCALITY": "MAHABUBNAGAR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509353,
      "TXT_PINCODE_LOCALITY": "MAHBUBNAGAR.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509357,
      "TXT_PINCODE_LOCALITY": "MIDJIL",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509358,
      "TXT_PINCODE_LOCALITY": "KADTHAL S. O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509359,
      "TXT_PINCODE_LOCALITY": "KANDUKUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509360,
      "TXT_PINCODE_LOCALITY": "VELDANDA.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509361,
      "TXT_PINCODE_LOCALITY": "KARKAL PAHAD.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509362,
      "TXT_PINCODE_LOCALITY": "AKUTHOTAPALLY.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509363,
      "TXT_PINCODE_LOCALITY": "AURA PALLI.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509364,
      "TXT_PINCODE_LOCALITY": "MUDHIVIN",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 425503,
      "City District Name": "MAHABUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509365,
      "TXT_PINCODE_LOCALITY": "MAISARAM.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509371,
      "TXT_PINCODE_LOCALITY": "KOILKONDA.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509375,
      "TXT_PINCODE_LOCALITY": "ACHAMPET S. O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509376,
      "TXT_PINCODE_LOCALITY": "UPPNUNTHALA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509380,
      "TXT_PINCODE_LOCALITY": "GARLABANDA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509381,
      "TXT_PINCODE_LOCALITY": "KOTHAKOTA.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509382,
      "TXT_PINCODE_LOCALITY": "ADDAKAL",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509383,
      "TXT_PINCODE_LOCALITY": "KADU KUNTLA.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509384,
      "TXT_PINCODE_LOCALITY": "SHAKAPUR.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509385,
      "TXT_PINCODE_LOCALITY": "THELKAPALLY S.O",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509388,
      "TXT_PINCODE_LOCALITY": "AMADA BAKULA (KOTHAKOTA).",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509389,
      "TXT_PINCODE_LOCALITY": "RAYANPET",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509390,
      "TXT_PINCODE_LOCALITY": "MIRASI PALLI.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509391,
      "TXT_PINCODE_LOCALITY": "CHERLAPALLE",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443152,
      "City District Name": "KOTHAKOTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509401,
      "TXT_PINCODE_LOCALITY": "LINGAL.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509406,
      "TXT_PINCODE_LOCALITY": "TIMMAJI PET",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509407,
      "TXT_PINCODE_LOCALITY": "DAMARAGIDDA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509408,
      "TXT_PINCODE_LOCALITY": "JADCHERLA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509409,
      "TXT_PINCODE_LOCALITY": "NELLIOKONDI",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509410,
      "TXT_PINCODE_LOCALITY": "MOGALGIDDA",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509411,
      "TXT_PINCODE_LOCALITY": "MUDDUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509412,
      "TXT_PINCODE_LOCALITY": "TELKAPALLE.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509413,
      "TXT_PINCODE_LOCALITY": "PADKAL.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509414,
      "TXT_PINCODE_LOCALITY": "RAVICHED.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509416,
      "TXT_PINCODE_LOCALITY": "V MARKAL.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509431,
      "TXT_PINCODE_LOCALITY": "YELLIKAL.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509432,
      "TXT_PINCODE_LOCALITY": "KULKULPALLY.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509433,
      "TXT_PINCODE_LOCALITY": "VEERLAPALLI.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441347,
      "City District Name": "MAHBUBNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509485,
      "TXT_PINCODE_LOCALITY": "VATTIPALLE",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443184,
      "City District Name": "TELKAPALLE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509502,
      "TXT_PINCODE_LOCALITY": "YATMATAPUR",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443182,
      "City District Name": "TADOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 509702,
      "TXT_PINCODE_LOCALITY": "MAILARAM.",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 443148,
      "City District Name": "KODAIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 510013,
      "TXT_PINCODE_LOCALITY": "SANGAIAH",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6204,
      "City District Name": "VIJAYAWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 510027,
      "TXT_PINCODE_LOCALITY": "BUTCHIRAJUPALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 425910,
      "City District Name": "VISHAKHAPATNAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 510044,
      "TXT_PINCODE_LOCALITY": "NADUPURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 425910,
      "City District Name": "VISHAKHAPATNAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 510046,
      "TXT_PINCODE_LOCALITY": "GAJUWAKA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 425910,
      "City District Name": "VISHAKHAPATNAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 512149,
      "TXT_PINCODE_LOCALITY": "SRIKAKULAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 513102,
      "TXT_PINCODE_LOCALITY": "PALACHARLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 440928,
      "City District Name": "RAJAHMUNDRY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 513103,
      "TXT_PINCODE_LOCALITY": "DANAVAIPETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 513107,
      "TXT_PINCODE_LOCALITY": "RAJAHMUNDRY",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 513289,
      "TXT_PINCODE_LOCALITY": "RAMALAYAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 514002,
      "TXT_PINCODE_LOCALITY": "BALAJI NAGAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 514432,
      "TXT_PINCODE_LOCALITY": "NAGIREDDYPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442188,
      "City District Name": "PEDDAMUDIUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 514511,
      "TXT_PINCODE_LOCALITY": "TADIPATRI MANDAL",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515001,
      "TXT_PINCODE_LOCALITY": "KAMALANAGAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515002,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR ENGG COLLEGE S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515003,
      "TXT_PINCODE_LOCALITY": "DASTAGIRI PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515004,
      "TXT_PINCODE_LOCALITY": "MOTILAL ST",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515005,
      "TXT_PINCODE_LOCALITY": "GANDHI BAZAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515020,
      "TXT_PINCODE_LOCALITY": "PEDDAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515101,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR S. O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515102,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515110,
      "TXT_PINCODE_LOCALITY": "PENUKONDA EXTEN",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515111,
      "TXT_PINCODE_LOCALITY": "KUDAIR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442187,
      "City District Name": "KUDAIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515122,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR S. O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515123,
      "TXT_PINCODE_LOCALITY": "RODDAM CITY",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515124,
      "TXT_PINCODE_LOCALITY": "PEDDAMANTHUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442185,
      "City District Name": "RODDAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515133,
      "TXT_PINCODE_LOCALITY": "KOTHACHERUVU S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515134,
      "TXT_PINCODE_LOCALITY": "PUTTAPARTHI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515144,
      "TXT_PINCODE_LOCALITY": "BUKKARAYA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515154,
      "TXT_PINCODE_LOCALITY": "PAMUDURTHI S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515159,
      "TXT_PINCODE_LOCALITY": "MARALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515161,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515164,
      "TXT_PINCODE_LOCALITY": "GUTTURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442186,
      "City District Name": "PENUKONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515183,
      "TXT_PINCODE_LOCALITY": "RAMALAYAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 425910,
      "City District Name": "VISHAKHAPATNAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515191,
      "TXT_PINCODE_LOCALITY": "KADIRI BRAHMANAPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 3650,
      "City District Name": "KADIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515201,
      "TXT_PINCODE_LOCALITY": "HINDUPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515202,
      "TXT_PINCODE_LOCALITY": "MUDDIREDDIPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442190,
      "City District Name": "CHILAMATHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515211,
      "TXT_PINCODE_LOCALITY": "SADLAPALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 3414,
      "City District Name": "HINDUPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515212,
      "TXT_PINCODE_LOCALITY": "HINDUPUR SEVAMANDIR S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515213,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515214,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515221,
      "TXT_PINCODE_LOCALITY": "GOWRIBIDANUR INDUSTRIAL AREA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 3414,
      "City District Name": "HINDUPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515231,
      "TXT_PINCODE_LOCALITY": "GORANTLA S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515232,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515233,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515237,
      "TXT_PINCODE_LOCALITY": "BUDILI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442191,
      "City District Name": "GORANTLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515238,
      "TXT_PINCODE_LOCALITY": "POTTIPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442192,
      "City District Name": "VAJRAKARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515241,
      "TXT_PINCODE_LOCALITY": "BUDILI S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515261,
      "TXT_PINCODE_LOCALITY": "PYDETI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442193,
      "City District Name": "PARIGI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515271,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515281,
      "TXT_PINCODE_LOCALITY": "AMARAPURAM S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515282,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515286,
      "TXT_PINCODE_LOCALITY": "HEMAVATHI S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515291,
      "TXT_PINCODE_LOCALITY": "GOVINDAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442195,
      "City District Name": "MADAKASIRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515292,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515301,
      "TXT_PINCODE_LOCALITY": "MADAKASIRA BAZAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515302,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515303,
      "TXT_PINCODE_LOCALITY": "MADAKASIRA S. O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515305,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR.",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515306,
      "TXT_PINCODE_LOCALITY": "BASAVANAHALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 79511,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515307,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR SO",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515311,
      "TXT_PINCODE_LOCALITY": "AGALI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515321,
      "TXT_PINCODE_LOCALITY": "GANGAMPALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515322,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515331,
      "TXT_PINCODE_LOCALITY": "LEPAKSHI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515332,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515341,
      "TXT_PINCODE_LOCALITY": "CHILAMATHUR S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515345,
      "TXT_PINCODE_LOCALITY": "VIRUPAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442194,
      "City District Name": "PEDDAVADUGUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515351,
      "TXT_PINCODE_LOCALITY": "VEERAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442190,
      "City District Name": "CHILAMATHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515352,
      "TXT_PINCODE_LOCALITY": "ASANSOL",
      "NUM_STATE_CD": 72,
      "State Name": "WEST BENGAL",
      "NUM_CITYDISTRICT_CD": 891,
      "City District Name": "ASANSOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515361,
      "TXT_PINCODE_LOCALITY": "DADITHOTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442216,
      "City District Name": "TADIMARRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515371,
      "TXT_PINCODE_LOCALITY": "N.THIMMAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442215,
      "City District Name": "VIDAPANAKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515401,
      "TXT_PINCODE_LOCALITY": "GOOTY BAZAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515402,
      "TXT_PINCODE_LOCALITY": "GOOTY R.S. S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515404,
      "TXT_PINCODE_LOCALITY": "NAGASAMUDRAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 79511,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515405,
      "TXT_PINCODE_LOCALITY": "PEDDA VADAGUR S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515408,
      "TXT_PINCODE_LOCALITY": "YADIKI S. O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515409,
      "TXT_PINCODE_LOCALITY": "NITTUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 79511,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515411,
      "TXT_PINCODE_LOCALITY": "TADPATRI EXTN",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515412,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515413,
      "TXT_PINCODE_LOCALITY": "BHOGASAMUDRAM PROJECT S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515414,
      "TXT_PINCODE_LOCALITY": "ELLUTLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442196,
      "City District Name": "PUTLUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515415,
      "TXT_PINCODE_LOCALITY": "CHUKKALUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515416,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515419,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515421,
      "TXT_PINCODE_LOCALITY": "PEDDA POLAMADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 79511,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515422,
      "TXT_PINCODE_LOCALITY": "CHINNA POLA MADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 79511,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515425,
      "TXT_PINCODE_LOCALITY": "NARAPLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515426,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515427,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515435,
      "TXT_PINCODE_LOCALITY": "SALAKAMCHERUVU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442214,
      "City District Name": "SINGANAMALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515441,
      "TXT_PINCODE_LOCALITY": "PEDDAPAPPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442213,
      "City District Name": "PEDDAPAPPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515445,
      "TXT_PINCODE_LOCALITY": "AMMALADINNE S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515446,
      "TXT_PINCODE_LOCALITY": "MUCHUKOTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 79511,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515455,
      "TXT_PINCODE_LOCALITY": "APPECHERLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515456,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515457,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515458,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515465,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR S. O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515466,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515501,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR.",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515502,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515504,
      "TXT_PINCODE_LOCALITY": "NAGALAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442194,
      "City District Name": "PEDDAVADUGUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515510,
      "TXT_PINCODE_LOCALITY": "SETTIPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442186,
      "City District Name": "PENUKONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515511,
      "TXT_PINCODE_LOCALITY": "MUDIGUBBA S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515521,
      "TXT_PINCODE_LOCALITY": "GANDLAPENTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515522,
      "TXT_PINCODE_LOCALITY": "BANDAMEEDAPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442210,
      "City District Name": "RAPTADU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515531,
      "TXT_PINCODE_LOCALITY": "KONDAKAMARLA S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515541,
      "TXT_PINCODE_LOCALITY": "KUTUGULLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515551,
      "TXT_PINCODE_LOCALITY": "NALLACHERUVU S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515556,
      "TXT_PINCODE_LOCALITY": "AMADAGUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442171,
      "City District Name": "AMADAGUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515561,
      "TXT_PINCODE_LOCALITY": "OBULA DEVARA CHERUVU S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515566,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515571,
      "TXT_PINCODE_LOCALITY": "TANAKALLU S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515572,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515581,
      "TXT_PINCODE_LOCALITY": "TALUPULA S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515583,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515591,
      "TXT_PINCODE_LOCALITY": "ANANTHAPUR S. O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515601,
      "TXT_PINCODE_LOCALITY": "MARRIMAKULAPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442190,
      "City District Name": "CHILAMATHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515611,
      "TXT_PINCODE_LOCALITY": "ULLIKALLU B.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515616,
      "TXT_PINCODE_LOCALITY": "BATHALAPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442209,
      "City District Name": "BATHALAPALLE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515621,
      "TXT_PINCODE_LOCALITY": "ACHAMPALLE B.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515631,
      "TXT_PINCODE_LOCALITY": "TADIMARRI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515641,
      "TXT_PINCODE_LOCALITY": "KANAGANAPALLI S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515651,
      "TXT_PINCODE_LOCALITY": "VELDURTHI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442208,
      "City District Name": "CHENNEKOTHAPALLE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515661,
      "TXT_PINCODE_LOCALITY": "BATHALAPALLI S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515662,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515671,
      "TXT_PINCODE_LOCALITY": "DHARMAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515672,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515701,
      "TXT_PINCODE_LOCALITY": "SAMUDRAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515711,
      "TXT_PINCODE_LOCALITY": "JALLI PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515712,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515721,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515722,
      "TXT_PINCODE_LOCALITY": "JAYALAKSHMIPURAM S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515731,
      "TXT_PINCODE_LOCALITY": "GARLADINNE S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515736,
      "TXT_PINCODE_LOCALITY": "MPR SITE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515741,
      "TXT_PINCODE_LOCALITY": "BELGUPPA S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515751,
      "TXT_PINCODE_LOCALITY": "ATMAKUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515752,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515761,
      "TXT_PINCODE_LOCALITY": "KALYANDRUG BAZAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515762,
      "TXT_PINCODE_LOCALITY": "KURUBARAHALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 3711,
      "City District Name": "KALYANDURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515763,
      "TXT_PINCODE_LOCALITY": "SETTUR S. O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515765,
      "TXT_PINCODE_LOCALITY": "KAMBADUR S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515766,
      "TXT_PINCODE_LOCALITY": "KUNDURPI.",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515767,
      "TXT_PINCODE_LOCALITY": "SHETTUR S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515774,
      "TXT_PINCODE_LOCALITY": "KALLUR R.S. S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515775,
      "TXT_PINCODE_LOCALITY": "PAMIDI BUS STAND",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515781,
      "TXT_PINCODE_LOCALITY": "THUMBIGANUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442205,
      "City District Name": "KANEKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515787,
      "TXT_PINCODE_LOCALITY": "NUTHIMADUGU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515801,
      "TXT_PINCODE_LOCALITY": "TIMMANACHERLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515803,
      "TXT_PINCODE_LOCALITY": "OLD GUNTAKAL S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515808,
      "TXT_PINCODE_LOCALITY": "RAJENDRANAGAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 79511,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515811,
      "TXT_PINCODE_LOCALITY": "DONEKAL",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442215,
      "City District Name": "VIDAPANAKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515812,
      "TXT_PINCODE_LOCALITY": "KOTHAPETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515813,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515814,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515822,
      "TXT_PINCODE_LOCALITY": "RAKETLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515823,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515824,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515831,
      "TXT_PINCODE_LOCALITY": "POLIKI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442215,
      "City District Name": "VIDAPANAKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515832,
      "TXT_PINCODE_LOCALITY": "VAJRAKARUR S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515833,
      "TXT_PINCODE_LOCALITY": "GANJIKUNTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442192,
      "City District Name": "VAJRAKARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515834,
      "TXT_PINCODE_LOCALITY": "CHABALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442192,
      "City District Name": "VAJRAKARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515835,
      "TXT_PINCODE_LOCALITY": "PEDDA CHINNAPYAPILI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442192,
      "City District Name": "VAJRAKARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515836,
      "TXT_PINCODE_LOCALITY": "THATRAKAL",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442192,
      "City District Name": "VAJRAKARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515837,
      "TXT_PINCODE_LOCALITY": "PANDIKUNTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442192,
      "City District Name": "VAJRAKARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515841,
      "TXT_PINCODE_LOCALITY": "JARUTLA RAMPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442192,
      "City District Name": "VAJRAKARUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515842,
      "TXT_PINCODE_LOCALITY": "KONAKONDLA S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515843,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515844,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515862,
      "TXT_PINCODE_LOCALITY": "NARINJAGUNDLAPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442204,
      "City District Name": "BELUGUPPA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515863,
      "TXT_PINCODE_LOCALITY": "RANGASAMUDRAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442202,
      "City District Name": "GUMMAGATTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515865,
      "TXT_PINCODE_LOCALITY": "RAYADRUG BAZAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515866,
      "TXT_PINCODE_LOCALITY": "ANANTAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515867,
      "TXT_PINCODE_LOCALITY": "UDEGOLAM S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515870,
      "TXT_PINCODE_LOCALITY": "VIDAPANAKAL S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515871,
      "TXT_PINCODE_LOCALITY": "BOMMANAHAL B.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 441200,
      "City District Name": "ANANTHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515872,
      "TXT_PINCODE_LOCALITY": "HARIAL",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2055,
      "City District Name": "ANANTAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 515922,
      "TXT_PINCODE_LOCALITY": "GANGULAKUNTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442210,
      "City District Name": "RAPTADU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516001,
      "TXT_PINCODE_LOCALITY": "CUDDAPAH COOPT SUGARS",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516002,
      "TXT_PINCODE_LOCALITY": "MACHUPALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516003,
      "TXT_PINCODE_LOCALITY": "UTUKURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516004,
      "TXT_PINCODE_LOCALITY": "YERRA MUKKAPALLI CUDDAPAH",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 425650,
      "City District Name": "KADAPA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516005,
      "TXT_PINCODE_LOCALITY": "MACHUPALLI (CUDDAPAH)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516007,
      "TXT_PINCODE_LOCALITY": "PENAGALUR KODUR S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6378,
      "City District Name": "CUDDAPAH.",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516101,
      "TXT_PINCODE_LOCALITY": "KODUR (CUDDAPAH)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516103,
      "TXT_PINCODE_LOCALITY": "KONETIRAJU KONDRIGA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516104,
      "TXT_PINCODE_LOCALITY": "CHITVEL",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516105,
      "TXT_PINCODE_LOCALITY": "ANANTARAJUPETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516107,
      "TXT_PINCODE_LOCALITY": "PULLAM PET",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516108,
      "TXT_PINCODE_LOCALITY": "OBULAVARI PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516109,
      "TXT_PINCODE_LOCALITY": "CHINAORAM PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516110,
      "TXT_PINCODE_LOCALITY": "PEDDA RACHA PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516112,
      "TXT_PINCODE_LOCALITY": "MUKKAVARI PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516113,
      "TXT_PINCODE_LOCALITY": "T KAMMA PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516114,
      "TXT_PINCODE_LOCALITY": "YARRAGUNTLA KOTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516115,
      "TXT_PINCODE_LOCALITY": "RAJAMPET H O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516116,
      "TXT_PINCODE_LOCALITY": "ANANTHAIAHGIRI PALLY",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516117,
      "TXT_PINCODE_LOCALITY": "NARRAYANA NELLORE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516118,
      "TXT_PINCODE_LOCALITY": "CUDDAPAH",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6378,
      "City District Name": "CUDDAPAH.",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516120,
      "TXT_PINCODE_LOCALITY": "GALIVARI PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516125,
      "TXT_PINCODE_LOCALITY": "MANERAMPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442201,
      "City District Name": "DUVVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516126,
      "TXT_PINCODE_LOCALITY": "TALLAPAKA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516127,
      "TXT_PINCODE_LOCALITY": "PENAGALUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516128,
      "TXT_PINCODE_LOCALITY": "NAYUNIPET",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516129,
      "TXT_PINCODE_LOCALITY": "MADITHADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516130,
      "TXT_PINCODE_LOCALITY": "THIMMAYYAGARI PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516131,
      "TXT_PINCODE_LOCALITY": "CUDDAPAH",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6378,
      "City District Name": "CUDDAPAH.",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516132,
      "TXT_PINCODE_LOCALITY": "CUDDAPAH",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6378,
      "City District Name": "CUDDAPAH.",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516133,
      "TXT_PINCODE_LOCALITY": "CUDDAPAH",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6378,
      "City District Name": "CUDDAPAH.",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516150,
      "TXT_PINCODE_LOCALITY": "NANDALUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516151,
      "TXT_PINCODE_LOCALITY": "CUDDAPAH",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6378,
      "City District Name": "CUDDAPAH.",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516152,
      "TXT_PINCODE_LOCALITY": "MANTAPAM PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516153,
      "TXT_PINCODE_LOCALITY": "CUDDAPAH",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6378,
      "City District Name": "CUDDAPAH.",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516162,
      "TXT_PINCODE_LOCALITY": "CHENNUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516163,
      "TXT_PINCODE_LOCALITY": "CUDDAPAH CO-OP SUGARS",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516164,
      "TXT_PINCODE_LOCALITY": "BAYANAPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516165,
      "TXT_PINCODE_LOCALITY": "CUDDAPAH",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6378,
      "City District Name": "CUDDAPAH.",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516172,
      "TXT_PINCODE_LOCALITY": "MYDEKUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516173,
      "TXT_PINCODE_LOCALITY": "ONIPENTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516175,
      "TXT_PINCODE_LOCALITY": "KAMANUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516193,
      "TXT_PINCODE_LOCALITY": "PORUMA MILLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516203,
      "TXT_PINCODE_LOCALITY": "RAVULA PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516213,
      "TXT_PINCODE_LOCALITY": "VONTI MITTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516214,
      "TXT_PINCODE_LOCALITY": "CHINNA MANDEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516215,
      "TXT_PINCODE_LOCALITY": "SAMBE PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516216,
      "TXT_PINCODE_LOCALITY": "PENDI MARRI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516217,
      "TXT_PINCODE_LOCALITY": "KALAS PAD",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516218,
      "TXT_PINCODE_LOCALITY": "NANDIMANDALAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516219,
      "TXT_PINCODE_LOCALITY": "VELLATUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516227,
      "TXT_PINCODE_LOCALITY": "BADVEL",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516228,
      "TXT_PINCODE_LOCALITY": "ANANTARAJUPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516233,
      "TXT_PINCODE_LOCALITY": "GOPAVARAM PROJECT SITE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516237,
      "TXT_PINCODE_LOCALITY": "SIDDAVATAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516238,
      "TXT_PINCODE_LOCALITY": "CUDDAPAH",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6378,
      "City District Name": "CUDDAPAH.",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516247,
      "TXT_PINCODE_LOCALITY": "PEDDA PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516248,
      "TXT_PINCODE_LOCALITY": "CUDDAPAH",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6378,
      "City District Name": "CUDDAPAH.",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516257,
      "TXT_PINCODE_LOCALITY": "LAKKI REDDI PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516259,
      "TXT_PINCODE_LOCALITY": "KONDAMACHUPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516260,
      "TXT_PINCODE_LOCALITY": "CHAKRAYAPETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516267,
      "TXT_PINCODE_LOCALITY": "GALIVEDU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516268,
      "TXT_PINCODE_LOCALITY": "VEERA BALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516269,
      "TXT_PINCODE_LOCALITY": "EGUVAGOTTIVEEDU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516270,
      "TXT_PINCODE_LOCALITY": "MASAPET BAZAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516271,
      "TXT_PINCODE_LOCALITY": "CUDDAPAH",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6378,
      "City District Name": "CUDDAPAH.",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516272,
      "TXT_PINCODE_LOCALITY": "CUDDAPAH",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6378,
      "City District Name": "CUDDAPAH.",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516289,
      "TXT_PINCODE_LOCALITY": "PEDDACHAPALLO",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516290,
      "TXT_PINCODE_LOCALITY": "CUDDAPAH",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6378,
      "City District Name": "CUDDAPAH.",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516291,
      "TXT_PINCODE_LOCALITY": "PANDILLA PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516292,
      "TXT_PINCODE_LOCALITY": "CUDDAPAH",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6378,
      "City District Name": "CUDDAPAH.",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516293,
      "TXT_PINCODE_LOCALITY": "VALLURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516309,
      "TXT_PINCODE_LOCALITY": "T SUNDUPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516310,
      "TXT_PINCODE_LOCALITY": "CHILAMAKUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516311,
      "TXT_PINCODE_LOCALITY": "CUDDAPAH",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1863,
      "City District Name": "CUDDAPPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516312,
      "TXT_PINCODE_LOCALITY": "VV REDDY NAGAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1863,
      "City District Name": "CUDDAPPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516319,
      "TXT_PINCODE_LOCALITY": "CUDDAPAH",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6378,
      "City District Name": "CUDDAPAH.",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516321,
      "TXT_PINCODE_LOCALITY": "VEERAPPANAYUNI PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516329,
      "TXT_PINCODE_LOCALITY": "VAIM PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516330,
      "TXT_PINCODE_LOCALITY": "CUDDAPAH",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6378,
      "City District Name": "CUDDAPAH.",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516339,
      "TXT_PINCODE_LOCALITY": "URUTUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516340,
      "TXT_PINCODE_LOCALITY": "PERNAPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442200,
      "City District Name": "VEMULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516349,
      "TXT_PINCODE_LOCALITY": "VEMULA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516350,
      "TXT_PINCODE_LOCALITY": "TALLA PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516355,
      "TXT_PINCODE_LOCALITY": "KUTCHUPAPA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442199,
      "City District Name": "CHAPAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516356,
      "TXT_PINCODE_LOCALITY": "SEETHARAMPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516357,
      "TXT_PINCODE_LOCALITY": "ANANTAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516359,
      "TXT_PINCODE_LOCALITY": "KORRA PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516360,
      "TXT_PINCODE_LOCALITY": "HOLMESPET",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516361,
      "TXT_PINCODE_LOCALITY": "MODAMEEDIPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516362,
      "TXT_PINCODE_LOCALITY": "UPPARAPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516363,
      "TXT_PINCODE_LOCALITY": "RAJU PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516364,
      "TXT_PINCODE_LOCALITY": "CUDDAPAH",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6378,
      "City District Name": "CUDDAPAH.",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516365,
      "TXT_PINCODE_LOCALITY": "CUDDAPAH",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6378,
      "City District Name": "CUDDAPAH.",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516366,
      "TXT_PINCODE_LOCALITY": "CUDDAPAH",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6378,
      "City District Name": "CUDDAPAH.",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516380,
      "TXT_PINCODE_LOCALITY": "MUDDANUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516390,
      "TXT_PINCODE_LOCALITY": "PULVENDLA NORTH",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516391,
      "TXT_PINCODE_LOCALITY": "PEDDARANGAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442197,
      "City District Name": "PULIVENDLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516392,
      "TXT_PINCODE_LOCALITY": "CUDDAPAH",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6378,
      "City District Name": "CUDDAPAH.",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516396,
      "TXT_PINCODE_LOCALITY": "VELIDANDLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 425650,
      "City District Name": "KADAPA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516401,
      "TXT_PINCODE_LOCALITY": "TONDUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516411,
      "TXT_PINCODE_LOCALITY": "PEDDA PASUPULA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516412,
      "TXT_PINCODE_LOCALITY": "CUDDAPAH",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6378,
      "City District Name": "CUDDAPAH.",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516413,
      "TXT_PINCODE_LOCALITY": "PALUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516421,
      "TXT_PINCODE_LOCALITY": "AGADUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516431,
      "TXT_PINCODE_LOCALITY": "GUNDLAGUNTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516432,
      "TXT_PINCODE_LOCALITY": "VADDI RALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516433,
      "TXT_PINCODE_LOCALITY": "CHINNA KOMARLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516434,
      "TXT_PINCODE_LOCALITY": "JAMMALAMUDUGU NORTH",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516437,
      "TXT_PINCODE_LOCALITY": "CUDDAPAH",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6378,
      "City District Name": "CUDDAPAH.",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516438,
      "TXT_PINCODE_LOCALITY": "CUDDAPAH",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6378,
      "City District Name": "CUDDAPAH.",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516439,
      "TXT_PINCODE_LOCALITY": "MYLAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516440,
      "TXT_PINCODE_LOCALITY": "CUDDAPAH",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6378,
      "City District Name": "CUDDAPAH.",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516441,
      "TXT_PINCODE_LOCALITY": "CUDDAPAH",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6378,
      "City District Name": "CUDDAPAH.",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516443,
      "TXT_PINCODE_LOCALITY": "GOLLAPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442183,
      "City District Name": "MYLAVARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516444,
      "TXT_PINCODE_LOCALITY": "KONDAPAURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516454,
      "TXT_PINCODE_LOCALITY": "SIMHADRI PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516462,
      "TXT_PINCODE_LOCALITY": "BAYANAPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516464,
      "TXT_PINCODE_LOCALITY": "LAVANUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516469,
      "TXT_PINCODE_LOCALITY": "THUMUKUNTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442218,
      "City District Name": "GALIVEEDU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516474,
      "TXT_PINCODE_LOCALITY": "TALLA PRODDATUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516476,
      "TXT_PINCODE_LOCALITY": "YERRAGUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442219,
      "City District Name": "KONDAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516484,
      "TXT_PINCODE_LOCALITY": "BALAPANUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516486,
      "TXT_PINCODE_LOCALITY": "CUDDAPAH",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6378,
      "City District Name": "CUDDAPAH.",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516501,
      "TXT_PINCODE_LOCALITY": "ATLUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516502,
      "TXT_PINCODE_LOCALITY": "CHENNAM PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516503,
      "TXT_PINCODE_LOCALITY": "KANDIMALLAYAPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516504,
      "TXT_PINCODE_LOCALITY": "NEELAKANTA RAO PET",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516505,
      "TXT_PINCODE_LOCALITY": "TEKURPET",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2848,
      "City District Name": "CUDDAPAH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 516506,
      "TXT_PINCODE_LOCALITY": "KURNOOL",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517001,
      "TXT_PINCODE_LOCALITY": "LALUGARDENS",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517002,
      "TXT_PINCODE_LOCALITY": "C T R SOUTH",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517003,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517004,
      "TXT_PINCODE_LOCALITY": "CHITTOOR NORTH",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517101,
      "TXT_PINCODE_LOCALITY": "CHANDRAGIRI FORT",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517102,
      "TXT_PINCODE_LOCALITY": "DORNAKAMBALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517103,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517104,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517105,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517106,
      "TXT_PINCODE_LOCALITY": "BHIMAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517107,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517108,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517109,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517110,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517111,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517112,
      "TXT_PINCODE_LOCALITY": "PAKALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517113,
      "TXT_PINCODE_LOCALITY": "KALLUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517114,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517115,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517116,
      "TXT_PINCODE_LOCALITY": "PEDDAGORAPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517117,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517118,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517123,
      "TXT_PINCODE_LOCALITY": "REDDI VARI PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517124,
      "TXT_PINCODE_LOCALITY": "PUTHALA PATTU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517125,
      "TXT_PINCODE_LOCALITY": "GANGADHARA NELLORE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517126,
      "TXT_PINCODE_LOCALITY": "PENUMUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517127,
      "TXT_PINCODE_LOCALITY": "CHITTOOR SUGAR FACTORY",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517128,
      "TXT_PINCODE_LOCALITY": "IRUVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517129,
      "TXT_PINCODE_LOCALITY": "KATTAMANCHI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517130,
      "TXT_PINCODE_LOCALITY": "IRAL",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517131,
      "TXT_PINCODE_LOCALITY": "THAVANAMPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517132,
      "TXT_PINCODE_LOCALITY": "BOMMA SAMUDRAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517133,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517134,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517135,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517136,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517137,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517138,
      "TXT_PINCODE_LOCALITY": "VELKUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517139,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517140,
      "TXT_PINCODE_LOCALITY": "MAHASAMUDRAN",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517141,
      "TXT_PINCODE_LOCALITY": "GAJULA PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517142,
      "TXT_PINCODE_LOCALITY": "PATNAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517143,
      "TXT_PINCODE_LOCALITY": "THALAMBEDU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517144,
      "TXT_PINCODE_LOCALITY": "POLAKALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517145,
      "TXT_PINCODE_LOCALITY": "THATIGUNTA PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517146,
      "TXT_PINCODE_LOCALITY": "BOORAGAMMANDA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517147,
      "TXT_PINCODE_LOCALITY": "GOLLA PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517148,
      "TXT_PINCODE_LOCALITY": "IIAVARIPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517149,
      "TXT_PINCODE_LOCALITY": "KOTRAKONA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517150,
      "TXT_PINCODE_LOCALITY": "MATYAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517151,
      "TXT_PINCODE_LOCALITY": "KALVA GUNTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517152,
      "TXT_PINCODE_LOCALITY": "DAMAL CHERUVU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517153,
      "TXT_PINCODE_LOCALITY": "KRISHNAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517154,
      "TXT_PINCODE_LOCALITY": "MOGARALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517160,
      "TXT_PINCODE_LOCALITY": "ODDE PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517161,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517162,
      "TXT_PINCODE_LOCALITY": "MUDIGOLAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517163,
      "TXT_PINCODE_LOCALITY": "PALAKUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517164,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517165,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517166,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517167,
      "TXT_PINCODE_LOCALITY": "KOTHAPALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517172,
      "TXT_PINCODE_LOCALITY": "MANGALAM PET",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517173,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517176,
      "TXT_PINCODE_LOCALITY": "PULIKALLU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442220,
      "City District Name": "PENUMURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517192,
      "TXT_PINCODE_LOCALITY": "ROMPI CHERLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517193,
      "TXT_PINCODE_LOCALITY": "CHINNA GOTTIGALLU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517194,
      "TXT_PINCODE_LOCALITY": "NERABAILU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517195,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517196,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517197,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517198,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517199,
      "TXT_PINCODE_LOCALITY": "TETTU PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517213,
      "TXT_PINCODE_LOCALITY": "GYARANPALLE KOTHAPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517214,
      "TXT_PINCODE_LOCALITY": "NEHRU BAZAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517215,
      "TXT_PINCODE_LOCALITY": "TALUPULA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517216,
      "TXT_PINCODE_LOCALITY": "KAMBHAM VARI PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517217,
      "TXT_PINCODE_LOCALITY": "JANDLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517218,
      "TXT_PINCODE_LOCALITY": "SORAKAYALAPET",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517219,
      "TXT_PINCODE_LOCALITY": "KUKKARAJUPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442226,
      "City District Name": "THAMBALLAPALLE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517227,
      "TXT_PINCODE_LOCALITY": "CHINTALAPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517234,
      "TXT_PINCODE_LOCALITY": "KALIKIRI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517235,
      "TXT_PINCODE_LOCALITY": "MAHAL",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517236,
      "TXT_PINCODE_LOCALITY": "KALAKADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517237,
      "TXT_PINCODE_LOCALITY": "MARRATIPALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517238,
      "TXT_PINCODE_LOCALITY": "KONA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517239,
      "TXT_PINCODE_LOCALITY": "PALAMANDA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517240,
      "TXT_PINCODE_LOCALITY": "ERRA KOTA PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517241,
      "TXT_PINCODE_LOCALITY": "KANDURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517242,
      "TXT_PINCODE_LOCALITY": "REGALLU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517247,
      "TXT_PINCODE_LOCALITY": "RUNGANUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517248,
      "TXT_PINCODE_LOCALITY": "MUTHUKUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517249,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517250,
      "TXT_PINCODE_LOCALITY": "VANAMALADINNE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517257,
      "TXT_PINCODE_LOCALITY": "CHOWDEPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517258,
      "TXT_PINCODE_LOCALITY": "SOMALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517259,
      "TXT_PINCODE_LOCALITY": "SURAYYAGARI PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517260,
      "TXT_PINCODE_LOCALITY": "CHARALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517277,
      "TXT_PINCODE_LOCALITY": "CHINTA PARTI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517278,
      "TXT_PINCODE_LOCALITY": "AYYAVARIPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517280,
      "TXT_PINCODE_LOCALITY": "NIMMANA PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517291,
      "TXT_PINCODE_LOCALITY": "TARI GONDA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517292,
      "TXT_PINCODE_LOCALITY": "MARRIPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517297,
      "TXT_PINCODE_LOCALITY": "VELI GALLU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517298,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517299,
      "TXT_PINCODE_LOCALITY": "VAYALPAD",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517301,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517302,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517305,
      "TXT_PINCODE_LOCALITY": "CHERUKU GANUMA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517319,
      "TXT_PINCODE_LOCALITY": "CHITTOOR S. O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517320,
      "TXT_PINCODE_LOCALITY": "PITCHALAVANDLA PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442227,
      "City District Name": "KURABALAKOTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517325,
      "TXT_PINCODE_LOCALITY": "MADANAPALLE BAZAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517326,
      "TXT_PINCODE_LOCALITY": "SOCIETY COLONY",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517327,
      "TXT_PINCODE_LOCALITY": "MADANAPALLE COLLEGE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517330,
      "TXT_PINCODE_LOCALITY": "AROGYAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517350,
      "TXT_PINCODE_LOCALITY": "KURABALAKOTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517351,
      "TXT_PINCODE_LOCALITY": "MADDINAVANI PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517352,
      "TXT_PINCODE_LOCALITY": "RUSHI VALLY",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517370,
      "TXT_PINCODE_LOCALITY": "BADIKAYALA PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517390,
      "TXT_PINCODE_LOCALITY": "SOMPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517391,
      "TXT_PINCODE_LOCALITY": "PEDDA TIPPA SAMUDRAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517401,
      "TXT_PINCODE_LOCALITY": "RAMA KUPPAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517403,
      "TXT_PINCODE_LOCALITY": "RAMA PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517408,
      "TXT_PINCODE_LOCALITY": "PATHAPET",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517414,
      "TXT_PINCODE_LOCALITY": "ROYAL PET",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517415,
      "TXT_PINCODE_LOCALITY": "THOTA KANUMA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517416,
      "TXT_PINCODE_LOCALITY": "BANGARU PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517417,
      "TXT_PINCODE_LOCALITY": "CHEMBAKUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517418,
      "TXT_PINCODE_LOCALITY": "THAMBALLAPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517419,
      "TXT_PINCODE_LOCALITY": "NARASINGARAYA PETTAH",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517421,
      "TXT_PINCODE_LOCALITY": "THUNGUNDRAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517422,
      "TXT_PINCODE_LOCALITY": "KAMMAPALI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517423,
      "TXT_PINCODE_LOCALITY": "RALLABADUGUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517424,
      "TXT_PINCODE_LOCALITY": "VENKATAGIRI KOTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517425,
      "TXT_PINCODE_LOCALITY": "KUPPAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517426,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517427,
      "TXT_PINCODE_LOCALITY": "CHEELAPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442228,
      "City District Name": "GUDIPALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517429,
      "TXT_PINCODE_LOCALITY": "MITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517431,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517432,
      "TXT_PINCODE_LOCALITY": "BELU PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517442,
      "TXT_PINCODE_LOCALITY": "NELA PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517466,
      "TXT_PINCODE_LOCALITY": "BERIPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442229,
      "City District Name": "BANGARUPALEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517496,
      "TXT_PINCODE_LOCALITY": "PEDDAPARTHIKUNTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442230,
      "City District Name": "GUDUPALLE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517501,
      "TXT_PINCODE_LOCALITY": "TIRUPATI H O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517502,
      "TXT_PINCODE_LOCALITY": "S V NAGAR (TRP)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517503,
      "TXT_PINCODE_LOCALITY": "PADI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517504,
      "TXT_PINCODE_LOCALITY": "TIRUMALA NORTH",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517505,
      "TXT_PINCODE_LOCALITY": "PERU MALLA PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517506,
      "TXT_PINCODE_LOCALITY": "SETTI PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517507,
      "TXT_PINCODE_LOCALITY": "R.S. GARDENTS (TRP)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517514,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517515,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517516,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517517,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517518,
      "TXT_PINCODE_LOCALITY": "THANDLAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517520,
      "TXT_PINCODE_LOCALITY": "RAILWAY COLONY",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517526,
      "TXT_PINCODE_LOCALITY": "PAPANAIDUPET",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517527,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517536,
      "TXT_PINCODE_LOCALITY": "AKKURTI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517541,
      "TXT_PINCODE_LOCALITY": "VARADIA PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517544,
      "TXT_PINCODE_LOCALITY": "THONDUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442232,
      "City District Name": "VARADAIAHPALEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517551,
      "TXT_PINCODE_LOCALITY": "PUDI R S",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517561,
      "TXT_PINCODE_LOCALITY": "KUPPAM BADUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517562,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517564,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517565,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517566,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517567,
      "TXT_PINCODE_LOCALITY": "CHITTOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517569,
      "TXT_PINCODE_LOCALITY": "PACHIKAPALLAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517571,
      "TXT_PINCODE_LOCALITY": "SRI BOMMA RAJA PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517581,
      "TXT_PINCODE_LOCALITY": "THUMBUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517582,
      "TXT_PINCODE_LOCALITY": "KARVETI NAGAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517583,
      "TXT_PINCODE_LOCALITY": "PUTTUR SOUTH",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517584,
      "TXT_PINCODE_LOCALITY": "VEPAKUNTALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517585,
      "TXT_PINCODE_LOCALITY": "SHAMSHEER BAHADURPETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442234,
      "City District Name": "PICHATUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517586,
      "TXT_PINCODE_LOCALITY": "PANNUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517587,
      "TXT_PINCODE_LOCALITY": "PISATTUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517588,
      "TXT_PINCODE_LOCALITY": "SATTIVEDU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517589,
      "TXT_PINCODE_LOCALITY": "NAGALAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517590,
      "TXT_PINCODE_LOCALITY": "NAGARI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517591,
      "TXT_PINCODE_LOCALITY": "NINDRA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517592,
      "TXT_PINCODE_LOCALITY": "SATRAVADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517593,
      "TXT_PINCODE_LOCALITY": "GUNDRAJKUPPAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517596,
      "TXT_PINCODE_LOCALITY": "RAJANAGARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517599,
      "TXT_PINCODE_LOCALITY": "VEMGAL RAJA KUPPAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517604,
      "TXT_PINCODE_LOCALITY": "NARAYANA PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517606,
      "TXT_PINCODE_LOCALITY": "TERANI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517608,
      "TXT_PINCODE_LOCALITY": "VENGALATUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517609,
      "TXT_PINCODE_LOCALITY": "SRI RAMPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517610,
      "TXT_PINCODE_LOCALITY": "JAGANNADHAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517619,
      "TXT_PINCODE_LOCALITY": "YERPEDU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517620,
      "TXT_PINCODE_LOCALITY": "PALLAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517621,
      "TXT_PINCODE_LOCALITY": "VYASASRAMAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517622,
      "TXT_PINCODE_LOCALITY": "ANJIMEDU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517623,
      "TXT_PINCODE_LOCALITY": "KRISHNAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517640,
      "TXT_PINCODE_LOCALITY": "PANAGAL",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517641,
      "TXT_PINCODE_LOCALITY": "THONDAMANADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517642,
      "TXT_PINCODE_LOCALITY": "PEDDAKANNALI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517643,
      "TXT_PINCODE_LOCALITY": "KOVANUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517644,
      "TXT_PINCODE_LOCALITY": "SRI KALAHASHTHI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517645,
      "TXT_PINCODE_LOCALITY": "PULLA REDDI KANDRIGA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517646,
      "TXT_PINCODE_LOCALITY": "SRICITY",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517649,
      "TXT_PINCODE_LOCALITY": "GAJULA PELLUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517652,
      "TXT_PINCODE_LOCALITY": "BRAHMANA PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517653,
      "TXT_PINCODE_LOCALITY": "KOTA MANGAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517654,
      "TXT_PINCODE_LOCALITY": "PUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 517655,
      "TXT_PINCODE_LOCALITY": "ALATHUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2819,
      "City District Name": "CHITTOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518000,
      "TXT_PINCODE_LOCALITY": "QUEENS ROAD",
      "NUM_STATE_CD": 350,
      "State Name": "CENTRAL HONGKONG",
      "NUM_CITYDISTRICT_CD": 441228,
      "City District Name": "CENTRAL HONGKONG",
      "NUM_COUNTRY_CD": 154
    },
    {
      "NUM_PINCODE": 518001,
      "TXT_PINCODE_LOCALITY": "KNL BAZAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518002,
      "TXT_PINCODE_LOCALITY": "KURNOOL MEDICAL COLLEGE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518003,
      "TXT_PINCODE_LOCALITY": "KURNOOL SAP CAMP",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518004,
      "TXT_PINCODE_LOCALITY": "KOTHAPETA KNL",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518005,
      "TXT_PINCODE_LOCALITY": "ASHOK NAGAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518006,
      "TXT_PINCODE_LOCALITY": "STANTANPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518007,
      "TXT_PINCODE_LOCALITY": "GPRE COLLEGE S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518010,
      "TXT_PINCODE_LOCALITY": "ORVAKALLU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518011,
      "TXT_PINCODE_LOCALITY": "HUSSAIN PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518015,
      "TXT_PINCODE_LOCALITY": "PEDDA PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518016,
      "TXT_PINCODE_LOCALITY": "JOHRAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518018,
      "TXT_PINCODE_LOCALITY": "ULCHAL",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518019,
      "TXT_PINCODE_LOCALITY": "NIDUZURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518020,
      "TXT_PINCODE_LOCALITY": "MAMIDALA PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518021,
      "TXT_PINCODE_LOCALITY": "DINNE DEVARA PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518022,
      "TXT_PINCODE_LOCALITY": "MEEDIVEMULA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518023,
      "TXT_PINCODE_LOCALITY": "NARNUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518101,
      "TXT_PINCODE_LOCALITY": "SRI SAILAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518102,
      "TXT_PINCODE_LOCALITY": "SRI SAILAM DAM EAST",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518112,
      "TXT_PINCODE_LOCALITY": "PANEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518113,
      "TXT_PINCODE_LOCALITY": "KAVULUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518114,
      "TXT_PINCODE_LOCALITY": "KONIDEDU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518115,
      "TXT_PINCODE_LOCALITY": "ALAMUR (KNL)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518116,
      "TXT_PINCODE_LOCALITY": "BALAPANUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518120,
      "TXT_PINCODE_LOCALITY": "GULAMALIABAD",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442235,
      "City District Name": "BANAGANAPALLE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518122,
      "TXT_PINCODE_LOCALITY": "OWK",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518123,
      "TXT_PINCODE_LOCALITY": "KOLIMI GUNDLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518124,
      "TXT_PINCODE_LOCALITY": "BANAGANAPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518125,
      "TXT_PINCODE_LOCALITY": "TANGATUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518126,
      "TXT_PINCODE_LOCALITY": "YANGANTI PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518134,
      "TXT_PINCODE_LOCALITY": "KOILKUNTLA BUS STAND",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518135,
      "TXT_PINCODE_LOCALITY": "DORNI PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518136,
      "TXT_PINCODE_LOCALITY": "BHIMAN PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518137,
      "TXT_PINCODE_LOCALITY": "REVANUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518138,
      "TXT_PINCODE_LOCALITY": "GOLLADURTHY",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518139,
      "TXT_PINCODE_LOCALITY": "HARIVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518141,
      "TXT_PINCODE_LOCALITY": "KALU GOTLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518142,
      "TXT_PINCODE_LOCALITY": "JOLLADARSI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518143,
      "TXT_PINCODE_LOCALITY": "CHAKARAJUVEMULA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518145,
      "TXT_PINCODE_LOCALITY": "NOSSAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518146,
      "TXT_PINCODE_LOCALITY": "BODEMMANUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518150,
      "TXT_PINCODE_LOCALITY": "GUNDUPAPALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518155,
      "TXT_PINCODE_LOCALITY": "VUYYALAWADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518156,
      "TXT_PINCODE_LOCALITY": "MAYALUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518157,
      "TXT_PINCODE_LOCALITY": "INJEDU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518158,
      "TXT_PINCODE_LOCALITY": "SUDDA MALLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518165,
      "TXT_PINCODE_LOCALITY": "SANJAMALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518166,
      "TXT_PINCODE_LOCALITY": "PERUSOMALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518176,
      "TXT_PINCODE_LOCALITY": "NANDI VARGAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518177,
      "TXT_PINCODE_LOCALITY": "NANDAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518178,
      "TXT_PINCODE_LOCALITY": "PALUKUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518179,
      "TXT_PINCODE_LOCALITY": "VENKATAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518186,
      "TXT_PINCODE_LOCALITY": "ILLURI KOTHAPETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518196,
      "TXT_PINCODE_LOCALITY": "AKUMALLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518202,
      "TXT_PINCODE_LOCALITY": "CNANAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518206,
      "TXT_PINCODE_LOCALITY": "CEMENT NAGAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518215,
      "TXT_PINCODE_LOCALITY": "ULINANDA KONDA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518216,
      "TXT_PINCODE_LOCALITY": "VELDURTHI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518217,
      "TXT_PINCODE_LOCALITY": "RAMALLA KOTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518218,
      "TXT_PINCODE_LOCALITY": "ULINADAKONDA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518219,
      "TXT_PINCODE_LOCALITY": "MALLEPALLI (KNL)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518220,
      "TXT_PINCODE_LOCALITY": "JALADURGAN",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518221,
      "TXT_PINCODE_LOCALITY": "PEAPULLY",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518222,
      "TXT_PINCODE_LOCALITY": "DHONE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518224,
      "TXT_PINCODE_LOCALITY": "S BAYANAPALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518225,
      "TXT_PINCODE_LOCALITY": "YERUKALA CHERUVU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518263,
      "TXT_PINCODE_LOCALITY": "DUDADUDI KOTAKONDA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442236,
      "City District Name": "ASPARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518300,
      "TXT_PINCODE_LOCALITY": "CHINNAPENDEKAL",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1934,
      "City District Name": "ADONI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518301,
      "TXT_PINCODE_LOCALITY": "ADONI TOWN",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518302,
      "TXT_PINCODE_LOCALITY": "ADONI ARTS COLLEGE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518308,
      "TXT_PINCODE_LOCALITY": "INGALDAHAL",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518309,
      "TXT_PINCODE_LOCALITY": "HARIVANAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518310,
      "TXT_PINCODE_LOCALITY": "HEBBATTAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518311,
      "TXT_PINCODE_LOCALITY": "SANTHEKUDULUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518313,
      "TXT_PINCODE_LOCALITY": "KOSIGI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518317,
      "TXT_PINCODE_LOCALITY": "MADHIRE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518318,
      "TXT_PINCODE_LOCALITY": "N N HALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518319,
      "TXT_PINCODE_LOCALITY": "PEDDA TUMBALAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518323,
      "TXT_PINCODE_LOCALITY": "PEDDA KADUBUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518324,
      "TXT_PINCODE_LOCALITY": "KAMBADAHAL",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518325,
      "TXT_PINCODE_LOCALITY": "KAMBALA DINNE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518333,
      "TXT_PINCODE_LOCALITY": "HALVI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518336,
      "TXT_PINCODE_LOCALITY": "BELDONA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518338,
      "TXT_PINCODE_LOCALITY": "YERIGERI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518343,
      "TXT_PINCODE_LOCALITY": "NANDAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518344,
      "TXT_PINCODE_LOCALITY": "KAUTALAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518345,
      "TXT_PINCODE_LOCALITY": "MANTRALAYA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518346,
      "TXT_PINCODE_LOCALITY": "HOLALA GONDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518347,
      "TXT_PINCODE_LOCALITY": "ASPARI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518348,
      "TXT_PINCODE_LOCALITY": "HALA HARVI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518349,
      "TXT_PINCODE_LOCALITY": "MADHAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518350,
      "TXT_PINCODE_LOCALITY": "KADIVELLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518352,
      "TXT_PINCODE_LOCALITY": "CHINTA KUNTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518353,
      "TXT_PINCODE_LOCALITY": "JOHRAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518354,
      "TXT_PINCODE_LOCALITY": "KALLU DEVA KUNTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518358,
      "TXT_PINCODE_LOCALITY": "NADIKHAIRWADI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518359,
      "TXT_PINCODE_LOCALITY": "IBHARAMPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518360,
      "TXT_PINCODE_LOCALITY": "YEMMIGANUR BAZAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518363,
      "TXT_PINCODE_LOCALITY": "SIRVEL",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518364,
      "TXT_PINCODE_LOCALITY": "GUDIKAL",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518365,
      "TXT_PINCODE_LOCALITY": "HALAL HARVI (YGR)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518366,
      "TXT_PINCODE_LOCALITY": "KOTEKALLU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518367,
      "TXT_PINCODE_LOCALITY": "MUGATHI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518368,
      "TXT_PINCODE_LOCALITY": "PARLA PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518369,
      "TXT_PINCODE_LOCALITY": "KALU GOTLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518370,
      "TXT_PINCODE_LOCALITY": "KAIMETLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518371,
      "TXT_PINCODE_LOCALITY": "CHILAKALA DONA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518380,
      "TXT_PINCODE_LOCALITY": "PATTI KONDA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518382,
      "TXT_PINCODE_LOCALITY": "HOSUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518385,
      "TXT_PINCODE_LOCALITY": "MADDIKERE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518386,
      "TXT_PINCODE_LOCALITY": "NANCHERLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518390,
      "TXT_PINCODE_LOCALITY": "TUGGALI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518391,
      "TXT_PINCODE_LOCALITY": "PERAVALI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518395,
      "TXT_PINCODE_LOCALITY": "ALUR (KNL)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518396,
      "TXT_PINCODE_LOCALITY": "NEMAKALLU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518397,
      "TXT_PINCODE_LOCALITY": "THUNGA BHADRA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518398,
      "TXT_PINCODE_LOCALITY": "MOLAGAVALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518399,
      "TXT_PINCODE_LOCALITY": "YERUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518401,
      "TXT_PINCODE_LOCALITY": "NANDIKOTKUR BAZAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518402,
      "TXT_PINCODE_LOCALITY": "KURNOOL",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518403,
      "TXT_PINCODE_LOCALITY": "BANNUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518404,
      "TXT_PINCODE_LOCALITY": "PAGIDALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518405,
      "TXT_PINCODE_LOCALITY": "MIDTHUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518406,
      "TXT_PINCODE_LOCALITY": "MANDLEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518407,
      "TXT_PINCODE_LOCALITY": "PARUMANCHALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518408,
      "TXT_PINCODE_LOCALITY": "VANALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442238,
      "City District Name": "PAMULAPADU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518411,
      "TXT_PINCODE_LOCALITY": "KONI DELA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518412,
      "TXT_PINCODE_LOCALITY": "PRATHAKOTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518413,
      "TXT_PINCODE_LOCALITY": "MUTSUMARRI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518422,
      "TXT_PINCODE_LOCALITY": "ATMAKUR BUS STAND",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518424,
      "TXT_PINCODE_LOCALITY": "DUDYALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518425,
      "TXT_PINCODE_LOCALITY": "KURUKUNDA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518426,
      "TXT_PINCODE_LOCALITY": "GUWALA KUNTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518427,
      "TXT_PINCODE_LOCALITY": "PINNAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518428,
      "TXT_PINCODE_LOCALITY": "NALLAKALVA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518432,
      "TXT_PINCODE_LOCALITY": "BRAHMANA KOTKUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518433,
      "TXT_PINCODE_LOCALITY": "KUNTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442223,
      "City District Name": "VELGODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518438,
      "TXT_PINCODE_LOCALITY": "J.HOSAHALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442222,
      "City District Name": "HALAHARVI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518442,
      "TXT_PINCODE_LOCALITY": "PAMULA PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518443,
      "TXT_PINCODE_LOCALITY": "KARIVENA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518444,
      "TXT_PINCODE_LOCALITY": "BHANU MUKKALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518452,
      "TXT_PINCODE_LOCALITY": "GARGEYAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518462,
      "TXT_PINCODE_LOCALITY": "BELALGAL",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518463,
      "TXT_PINCODE_LOCALITY": "GONE GONDLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518464,
      "TXT_PINCODE_LOCALITY": "KODUMURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518465,
      "TXT_PINCODE_LOCALITY": "DEVANAKONDA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518466,
      "TXT_PINCODE_LOCALITY": "GUDUR (KNL)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518467,
      "TXT_PINCODE_LOCALITY": "KURUVANGALA PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518468,
      "TXT_PINCODE_LOCALITY": "PENDEKALLU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518469,
      "TXT_PINCODE_LOCALITY": "TERNIKALU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518470,
      "TXT_PINCODE_LOCALITY": "HANDRI KHAIRWADI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518472,
      "TXT_PINCODE_LOCALITY": "JULE KALLU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518473,
      "TXT_PINCODE_LOCALITY": "POLU KALLU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518474,
      "TXT_PINCODE_LOCALITY": "PULA KURTHI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518477,
      "TXT_PINCODE_LOCALITY": "LADDA GIRI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518478,
      "TXT_PINCODE_LOCALITY": "PARLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518479,
      "TXT_PINCODE_LOCALITY": "CHANUGONDLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518501,
      "TXT_PINCODE_LOCALITY": "TEKKE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518502,
      "TXT_PINCODE_LOCALITY": "CHALAMA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518503,
      "TXT_PINCODE_LOCALITY": "NOONEPALLI (NANDYAL)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518508,
      "TXT_PINCODE_LOCALITY": "GADI VEMULA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518509,
      "TXT_PINCODE_LOCALITY": "VANALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518510,
      "TXT_PINCODE_LOCALITY": "YERRAGUNTLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518511,
      "TXT_PINCODE_LOCALITY": "POLURU VENGANNAGARI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518512,
      "TXT_PINCODE_LOCALITY": "THIMMAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518513,
      "TXT_PINCODE_LOCALITY": "SANTHA JUTUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518515,
      "TXT_PINCODE_LOCALITY": "KOTTALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518516,
      "TXT_PINCODE_LOCALITY": "CHINDUKUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518517,
      "TXT_PINCODE_LOCALITY": "BOLLAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518518,
      "TXT_PINCODE_LOCALITY": "DURVESI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518519,
      "TXT_PINCODE_LOCALITY": "KORATA MADDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518521,
      "TXT_PINCODE_LOCALITY": "PEAPALLY",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442251,
      "City District Name": "PEAPALLY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518523,
      "TXT_PINCODE_LOCALITY": "BANDI ATMAKUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518524,
      "TXT_PINCODE_LOCALITY": "AYYAVARI KODUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518526,
      "TXT_PINCODE_LOCALITY": "PARNA PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518527,
      "TXT_PINCODE_LOCALITY": "NANDYAL (R)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4715,
      "City District Name": "NANDYAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518533,
      "TXT_PINCODE_LOCALITY": "VELGODE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518534,
      "TXT_PINCODE_LOCALITY": "ABDULLAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518535,
      "TXT_PINCODE_LOCALITY": "VELPANUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518537,
      "TXT_PINCODE_LOCALITY": "REGADI GUDUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518543,
      "TXT_PINCODE_LOCALITY": "MALLAREDDY VEDANAGAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518545,
      "TXT_PINCODE_LOCALITY": "AHOBILAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518546,
      "TXT_PINCODE_LOCALITY": "KONDU PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518547,
      "TXT_PINCODE_LOCALITY": "PEDDA CHINTA KUNTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518548,
      "TXT_PINCODE_LOCALITY": "MUTHALUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518549,
      "TXT_PINCODE_LOCALITY": "PEDDA BADANAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518550,
      "TXT_PINCODE_LOCALITY": "KOTAKANDUKURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518551,
      "TXT_PINCODE_LOCALITY": "CHINTA KOMMU DINNE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518552,
      "TXT_PINCODE_LOCALITY": "PADAKANDLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518553,
      "TXT_PINCODE_LOCALITY": "CHAGALAMARRI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518554,
      "TXT_PINCODE_LOCALITY": "MADDUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518555,
      "TXT_PINCODE_LOCALITY": "MUTYALAPAD",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518563,
      "TXT_PINCODE_LOCALITY": "KOTTALAPALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518564,
      "TXT_PINCODE_LOCALITY": "JILLELLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518573,
      "TXT_PINCODE_LOCALITY": "YALLUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518575,
      "TXT_PINCODE_LOCALITY": "KOTAPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518576,
      "TXT_PINCODE_LOCALITY": "VEERA REDDI PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518583,
      "TXT_PINCODE_LOCALITY": "GAJULA PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518584,
      "TXT_PINCODE_LOCALITY": "MAHADEVAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518585,
      "TXT_PINCODE_LOCALITY": "BOYALAKUNTLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518593,
      "TXT_PINCODE_LOCALITY": "KANALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518594,
      "TXT_PINCODE_LOCALITY": "RUDRAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518595,
      "TXT_PINCODE_LOCALITY": "GOSPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518596,
      "TXT_PINCODE_LOCALITY": "JULE PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518598,
      "TXT_PINCODE_LOCALITY": "RANGAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518599,
      "TXT_PINCODE_LOCALITY": "BETAMCHERLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518601,
      "TXT_PINCODE_LOCALITY": "MALKAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518606,
      "TXT_PINCODE_LOCALITY": "CHINNA KAMBALUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518607,
      "TXT_PINCODE_LOCALITY": "PEDDAKAMBALUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518620,
      "TXT_PINCODE_LOCALITY": "BAICHI GERI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518621,
      "TXT_PINCODE_LOCALITY": "NAGARUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518649,
      "TXT_PINCODE_LOCALITY": "CHIPPAGIRI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518651,
      "TXT_PINCODE_LOCALITY": "CHINNA HOTHUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518652,
      "TXT_PINCODE_LOCALITY": "HATI BELAGAL",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518653,
      "TXT_PINCODE_LOCALITY": "PEDDAHOTTUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518654,
      "TXT_PINCODE_LOCALITY": "MARAKATTU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518669,
      "TXT_PINCODE_LOCALITY": "DEEBAGUNTLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518672,
      "TXT_PINCODE_LOCALITY": "AYALUR NANDYAL",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518673,
      "TXT_PINCODE_LOCALITY": "MAHANANDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518674,
      "TXT_PINCODE_LOCALITY": "CHELAMA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518675,
      "TXT_PINCODE_LOCALITY": "CHAPI REVULA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518682,
      "TXT_PINCODE_LOCALITY": "VENKATAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518683,
      "TXT_PINCODE_LOCALITY": "YERRAGUDIDINNE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518684,
      "TXT_PINCODE_LOCALITY": "BATHALUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 518685,
      "TXT_PINCODE_LOCALITY": "NALLA GATLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4173,
      "City District Name": "KURNOOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370001,
      "TXT_PINCODE_LOCALITY": "DARBARGADH CHOWK",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370002,
      "TXT_PINCODE_LOCALITY": "SHIV NAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 674,
      "City District Name": "BHUJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370006,
      "TXT_PINCODE_LOCALITY": "SUMRASAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370007,
      "TXT_PINCODE_LOCALITY": "LORIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370010,
      "TXT_PINCODE_LOCALITY": "ANJAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 441069,
      "City District Name": "KACHCHH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370015,
      "TXT_PINCODE_LOCALITY": "B S F BHUJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370020,
      "TXT_PINCODE_LOCALITY": "KANIAYABE VILLAGE",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370030,
      "TXT_PINCODE_LOCALITY": "MANKUVA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370040,
      "TXT_PINCODE_LOCALITY": "SUKHPAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370061,
      "TXT_PINCODE_LOCALITY": "NIRONA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370105,
      "TXT_PINCODE_LOCALITY": "KUKMA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370110,
      "TXT_PINCODE_LOCALITY": "ANJAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370115,
      "TXT_PINCODE_LOCALITY": "BHUJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 441069,
      "City District Name": "KACHCHH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370130,
      "TXT_PINCODE_LOCALITY": "KHEDOI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370132,
      "TXT_PINCODE_LOCALITY": "NAGALPUR K",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370135,
      "TXT_PINCODE_LOCALITY": "ADHOI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370140,
      "TXT_PINCODE_LOCALITY": "BHACHAU",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370141,
      "TXT_PINCODE_LOCALITY": "CHOBARI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370145,
      "TXT_PINCODE_LOCALITY": "LAKADIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370146,
      "TXT_PINCODE_LOCALITY": "GAGODAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370147,
      "TXT_PINCODE_LOCALITY": "KIDIANAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370150,
      "TXT_PINCODE_LOCALITY": "SAMAKHIALI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370155,
      "TXT_PINCODE_LOCALITY": "ADESAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370156,
      "TXT_PINCODE_LOCALITY": "FATEHGADH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370157,
      "TXT_PINCODE_LOCALITY": "PALANSVA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370160,
      "TXT_PINCODE_LOCALITY": "BHIMASAR B",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370165,
      "TXT_PINCODE_LOCALITY": "R BAZAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370168,
      "TXT_PINCODE_LOCALITY": "RAV",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370169,
      "TXT_PINCODE_LOCALITY": "GEDI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370170,
      "TXT_PINCODE_LOCALITY": "GADHDA KHADIR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370201,
      "TXT_PINCODE_LOCALITY": "LILSHAH NAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1066,
      "City District Name": "GANDHIDHAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370202,
      "TXT_PINCODE_LOCALITY": "NAVINAL ISLAND",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 441069,
      "City District Name": "KACHCHH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370203,
      "TXT_PINCODE_LOCALITY": "UDAYANAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370205,
      "TXT_PINCODE_LOCALITY": "ADIPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370210,
      "TXT_PINCODE_LOCALITY": "OLD KANDLA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370220,
      "TXT_PINCODE_LOCALITY": "KUTCH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 680,
      "City District Name": "KUTCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370230,
      "TXT_PINCODE_LOCALITY": "KANDLA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 749,
      "City District Name": "KANDLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370240,
      "TXT_PINCODE_LOCALITY": "GOPALPURI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 441069,
      "City District Name": "KACHCHH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370250,
      "TXT_PINCODE_LOCALITY": "GANDHIDHAM S. O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1066,
      "City District Name": "GANDHIDHAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370365,
      "TXT_PINCODE_LOCALITY": "BHARAPAR (B)",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370405,
      "TXT_PINCODE_LOCALITY": "BHUJPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370406,
      "TXT_PINCODE_LOCALITY": "ZARPARA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370408,
      "TXT_PINCODE_LOCALITY": "NAVA VAS",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 674,
      "City District Name": "BHUJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370410,
      "TXT_PINCODE_LOCALITY": "KUTCH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 680,
      "City District Name": "KUTCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370411,
      "TXT_PINCODE_LOCALITY": "BHADRESWAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370415,
      "TXT_PINCODE_LOCALITY": "KAPAYA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370421,
      "TXT_PINCODE_LOCALITY": "MUNDRA.",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 441069,
      "City District Name": "KACHCHH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370425,
      "TXT_PINCODE_LOCALITY": "PATRI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370427,
      "TXT_PINCODE_LOCALITY": "BALADIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370429,
      "TXT_PINCODE_LOCALITY": "NARANPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370430,
      "TXT_PINCODE_LOCALITY": "KERA KACHCHH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370435,
      "TXT_PINCODE_LOCALITY": "BIDADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370440,
      "TXT_PINCODE_LOCALITY": "DURGAPUR (KUCHCHH)",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370445,
      "TXT_PINCODE_LOCALITY": "GADHSISA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370450,
      "TXT_PINCODE_LOCALITY": "GODHRA KACHCHH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370455,
      "TXT_PINCODE_LOCALITY": "GUNDIALI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370460,
      "TXT_PINCODE_LOCALITY": "KODAY",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370465,
      "TXT_PINCODE_LOCALITY": "SANJIPADI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370471,
      "TXT_PINCODE_LOCALITY": "MASKA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370472,
      "TXT_PINCODE_LOCALITY": "KANDAGARA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370475,
      "TXT_PINCODE_LOCALITY": "LAIJA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370485,
      "TXT_PINCODE_LOCALITY": "DAHISARA (KUTCH)",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370490,
      "TXT_PINCODE_LOCALITY": "DUMRA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370510,
      "TXT_PINCODE_LOCALITY": "RATADIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 441069,
      "City District Name": "KACHCHH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370511,
      "TXT_PINCODE_LOCALITY": "SANGHIPURAM S.O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 441069,
      "City District Name": "KACHCHH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370515,
      "TXT_PINCODE_LOCALITY": "KHAVDA B S F",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370601,
      "TXT_PINCODE_LOCALITY": "SHYAMJI KRISHNA VARMA NAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 441069,
      "City District Name": "KACHCHH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370602,
      "TXT_PINCODE_LOCALITY": "ATPS NANI CHHER S.O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 680,
      "City District Name": "KUTCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370605,
      "TXT_PINCODE_LOCALITY": "KOTDA N",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370610,
      "TXT_PINCODE_LOCALITY": "MANJAL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370615,
      "TXT_PINCODE_LOCALITY": "NAKHTRANA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370620,
      "TXT_PINCODE_LOCALITY": "NETRA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370625,
      "TXT_PINCODE_LOCALITY": "RAVAPAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370627,
      "TXT_PINCODE_LOCALITY": "GADULI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370630,
      "TXT_PINCODE_LOCALITY": "LAKHPAT",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370635,
      "TXT_PINCODE_LOCALITY": "BITTA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370640,
      "TXT_PINCODE_LOCALITY": "JAKHAU",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370641,
      "TXT_PINCODE_LOCALITY": "MOTI SINDHODI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 680,
      "City District Name": "KUTCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370645,
      "TXT_PINCODE_LOCALITY": "KOTHARA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370650,
      "TXT_PINCODE_LOCALITY": "MOTHARA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370655,
      "TXT_PINCODE_LOCALITY": "NALIYA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370660,
      "TXT_PINCODE_LOCALITY": "TERA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370665,
      "TXT_PINCODE_LOCALITY": "VIRANI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370670,
      "TXT_PINCODE_LOCALITY": "KHOMBHADI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 370675,
      "TXT_PINCODE_LOCALITY": "VITHON",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470001,
      "TXT_PINCODE_LOCALITY": "SAGAR CAVALARY HILLS",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 472,
      "City District Name": "SAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470002,
      "TXT_PINCODE_LOCALITY": "KERWANA SAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 472,
      "City District Name": "SAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470003,
      "TXT_PINCODE_LOCALITY": "SAGAR UNIVERSITY",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 472,
      "City District Name": "SAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470004,
      "TXT_PINCODE_LOCALITY": "SAGAR MAKRONIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 472,
      "City District Name": "SAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470005,
      "TXT_PINCODE_LOCALITY": "SAGAR TILLI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 472,
      "City District Name": "SAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470007,
      "TXT_PINCODE_LOCALITY": "SAGAR MAKRONIA CAMP",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 472,
      "City District Name": "SAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470021,
      "TXT_PINCODE_LOCALITY": "RICHHAWAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 472,
      "City District Name": "SAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470051,
      "TXT_PINCODE_LOCALITY": "BILHERA RAJA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 472,
      "City District Name": "SAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470111,
      "TXT_PINCODE_LOCALITY": "BAMORA R S",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 472,
      "City District Name": "SAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470113,
      "TXT_PINCODE_LOCALITY": "BINA ETAWAH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 472,
      "City District Name": "SAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470114,
      "TXT_PINCODE_LOCALITY": "AGASOD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 472,
      "City District Name": "SAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470115,
      "TXT_PINCODE_LOCALITY": "JARUAKEHDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 472,
      "City District Name": "SAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470116,
      "TXT_PINCODE_LOCALITY": "BINA W COLONY",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 472,
      "City District Name": "SAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470117,
      "TXT_PINCODE_LOCALITY": "KHURAI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 472,
      "City District Name": "SAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470118,
      "TXT_PINCODE_LOCALITY": "KHIMLASA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 472,
      "City District Name": "SAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470119,
      "TXT_PINCODE_LOCALITY": "RAHATGARH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 472,
      "City District Name": "SAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470120,
      "TXT_PINCODE_LOCALITY": "SIHORA (SAGAR)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 472,
      "City District Name": "SAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470121,
      "TXT_PINCODE_LOCALITY": "ISHURWARA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 472,
      "City District Name": "SAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470122,
      "TXT_PINCODE_LOCALITY": "NARYAOLI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 472,
      "City District Name": "SAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470124,
      "TXT_PINCODE_LOCALITY": "BINA BORL",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 472,
      "City District Name": "SAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470125,
      "TXT_PINCODE_LOCALITY": "JAISINGH NAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 472,
      "City District Name": "SAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470221,
      "TXT_PINCODE_LOCALITY": "SUKHI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 472,
      "City District Name": "SAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470223,
      "TXT_PINCODE_LOCALITY": "GARU JHAMAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 472,
      "City District Name": "SAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470226,
      "TXT_PINCODE_LOCALITY": "DEORI(SAGAR)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 472,
      "City District Name": "SAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470227,
      "TXT_PINCODE_LOCALITY": "REHLI (SAGAR)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 472,
      "City District Name": "SAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470228,
      "TXT_PINCODE_LOCALITY": "DNANA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 472,
      "City District Name": "SAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470229,
      "TXT_PINCODE_LOCALITY": "GARAHKOTA (SAGAR)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 472,
      "City District Name": "SAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470230,
      "TXT_PINCODE_LOCALITY": "SAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 472,
      "City District Name": "SAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470232,
      "TXT_PINCODE_LOCALITY": "GARAHKOTA NADIPAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 472,
      "City District Name": "SAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470235,
      "TXT_PINCODE_LOCALITY": "KESLI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 472,
      "City District Name": "SAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470335,
      "TXT_PINCODE_LOCALITY": "BANDA BELAI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 472,
      "City District Name": "SAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470337,
      "TXT_PINCODE_LOCALITY": "KARRAPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 472,
      "City District Name": "SAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470339,
      "TXT_PINCODE_LOCALITY": "SHAHGARH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 472,
      "City District Name": "SAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470340,
      "TXT_PINCODE_LOCALITY": "AMARMAU",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 472,
      "City District Name": "SAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470341,
      "TXT_PINCODE_LOCALITY": "DALPATPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 472,
      "City District Name": "SAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470441,
      "TXT_PINCODE_LOCALITY": "MALTHONE",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 472,
      "City District Name": "SAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470442,
      "TXT_PINCODE_LOCALITY": "BANDRI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 472,
      "City District Name": "SAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470661,
      "TXT_PINCODE_LOCALITY": "DAMOH H O",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2874,
      "City District Name": "DAMOH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470662,
      "TXT_PINCODE_LOCALITY": "ABHANA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2874,
      "City District Name": "DAMOH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470663,
      "TXT_PINCODE_LOCALITY": "ROAD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2874,
      "City District Name": "DAMOH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470664,
      "TXT_PINCODE_LOCALITY": "BANDAKPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2874,
      "City District Name": "DAMOH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470666,
      "TXT_PINCODE_LOCALITY": "PATHARIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2874,
      "City District Name": "DAMOH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470667,
      "TXT_PINCODE_LOCALITY": "SANODHI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 472,
      "City District Name": "SAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470668,
      "TXT_PINCODE_LOCALITY": "BORTRAI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2874,
      "City District Name": "DAMOH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470669,
      "TXT_PINCODE_LOCALITY": "SHAHPURA MAGRONE",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2874,
      "City District Name": "DAMOH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470670,
      "TXT_PINCODE_LOCALITY": "NANDRAI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2874,
      "City District Name": "DAMOH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470671,
      "TXT_PINCODE_LOCALITY": "BANSAKALAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2874,
      "City District Name": "DAMOH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470672,
      "TXT_PINCODE_LOCALITY": "BANSATAR KHEDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2874,
      "City District Name": "DAMOH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470673,
      "TXT_PINCODE_LOCALITY": "BATAIGARH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2874,
      "City District Name": "DAMOH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470675,
      "TXT_PINCODE_LOCALITY": "NARSINGHGARH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2874,
      "City District Name": "DAMOH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470771,
      "TXT_PINCODE_LOCALITY": "HINDORIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2874,
      "City District Name": "DAMOH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470772,
      "TXT_PINCODE_LOCALITY": "PATERA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2874,
      "City District Name": "DAMOH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470773,
      "TXT_PINCODE_LOCALITY": "KUNDALPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2874,
      "City District Name": "DAMOH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470775,
      "TXT_PINCODE_LOCALITY": "HATTA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2874,
      "City District Name": "DAMOH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470777,
      "TXT_PINCODE_LOCALITY": "RANEH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2874,
      "City District Name": "DAMOH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470880,
      "TXT_PINCODE_LOCALITY": "TENDUKHEDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2874,
      "City District Name": "DAMOH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 470881,
      "TXT_PINCODE_LOCALITY": "JABERA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2874,
      "City District Name": "DAMOH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 471001,
      "TXT_PINCODE_LOCALITY": "CHHATRPUR CITY H O",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2760,
      "City District Name": "CHHATARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 471020,
      "TXT_PINCODE_LOCALITY": "SIROL",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 442819,
      "City District Name": "GWALIOR (GIRD)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 471101,
      "TXT_PINCODE_LOCALITY": "CHANDRA NAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2760,
      "City District Name": "CHHATARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 471105,
      "TXT_PINCODE_LOCALITY": "BAMITHA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2760,
      "City District Name": "CHHATARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 471110,
      "TXT_PINCODE_LOCALITY": "ALIPURA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2760,
      "City District Name": "CHHATARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 471111,
      "TXT_PINCODE_LOCALITY": "HARPALPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2760,
      "City District Name": "CHHATARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 471201,
      "TXT_PINCODE_LOCALITY": "NOWGANJ POLYTECHIC COLLEG",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2760,
      "City District Name": "CHHATARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 471202,
      "TXT_PINCODE_LOCALITY": "MAUSAHANIYA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2760,
      "City District Name": "CHHATARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 471203,
      "TXT_PINCODE_LOCALITY": "MANSAHAMIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2760,
      "City District Name": "CHHATARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 471215,
      "TXT_PINCODE_LOCALITY": "ISHA NAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2760,
      "City District Name": "CHHATARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 471301,
      "TXT_PINCODE_LOCALITY": "GULGANJ",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2760,
      "City District Name": "CHHATARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 471304,
      "TXT_PINCODE_LOCALITY": "ANGORE",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2760,
      "City District Name": "CHHATARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 471311,
      "TXT_PINCODE_LOCALITY": "MALHERA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2760,
      "City District Name": "CHHATARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 471312,
      "TXT_PINCODE_LOCALITY": "BHAGWAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2760,
      "City District Name": "CHHATARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 471313,
      "TXT_PINCODE_LOCALITY": "GHAURA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2760,
      "City District Name": "CHHATARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 471315,
      "TXT_PINCODE_LOCALITY": "ISHANAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2760,
      "City District Name": "CHHATARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 471318,
      "TXT_PINCODE_LOCALITY": "GRAM BAMHORI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 6338,
      "City District Name": "CHATTARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 471401,
      "TXT_PINCODE_LOCALITY": "CHHATARPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2760,
      "City District Name": "CHHATARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 471405,
      "TXT_PINCODE_LOCALITY": "BIJAWAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2760,
      "City District Name": "CHHATARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 471406,
      "TXT_PINCODE_LOCALITY": "BIJAWAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2760,
      "City District Name": "CHHATARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 471408,
      "TXT_PINCODE_LOCALITY": "SATAI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2760,
      "City District Name": "CHHATARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 471411,
      "TXT_PINCODE_LOCALITY": "GARHI MALHERA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2760,
      "City District Name": "CHHATARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 471416,
      "TXT_PINCODE_LOCALITY": "RAGAULI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2760,
      "City District Name": "CHHATARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 471501,
      "TXT_PINCODE_LOCALITY": "MAHARAJPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2760,
      "City District Name": "CHHATARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 471505,
      "TXT_PINCODE_LOCALITY": "CHHATARPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2760,
      "City District Name": "CHHATARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 471510,
      "TXT_PINCODE_LOCALITY": "JUJHAR NAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2760,
      "City District Name": "CHHATARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 471515,
      "TXT_PINCODE_LOCALITY": "LAUNDI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2760,
      "City District Name": "CHHATARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 471516,
      "TXT_PINCODE_LOCALITY": "NIDHAULI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 442817,
      "City District Name": "GAURIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 471525,
      "TXT_PINCODE_LOCALITY": "CHANDLA - 935",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2760,
      "City District Name": "CHHATARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 471606,
      "TXT_PINCODE_LOCALITY": "KHAJURAHO",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2760,
      "City District Name": "CHHATARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 471625,
      "TXT_PINCODE_LOCALITY": "RAJNAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2760,
      "City District Name": "CHHATARPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 472001,
      "TXT_PINCODE_LOCALITY": "TIKAMGARH H O",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5963,
      "City District Name": "TIKAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 472002,
      "TXT_PINCODE_LOCALITY": "DANGIYAPURA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 442819,
      "City District Name": "GWALIOR (GIRD)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 472005,
      "TXT_PINCODE_LOCALITY": "KUNDLESHWAR (TIKAMGARH)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5963,
      "City District Name": "TIKAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 472010,
      "TXT_PINCODE_LOCALITY": "BADAGAON - 342",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5963,
      "City District Name": "TIKAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 472016,
      "TXT_PINCODE_LOCALITY": "AJNORE",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5963,
      "City District Name": "TIKAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 472101,
      "TXT_PINCODE_LOCALITY": "MOHANGARH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5963,
      "City District Name": "TIKAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 472105,
      "TXT_PINCODE_LOCALITY": "MAWAI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5963,
      "City District Name": "TIKAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 472111,
      "TXT_PINCODE_LOCALITY": "BALDEOGARH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5963,
      "City District Name": "TIKAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 472112,
      "TXT_PINCODE_LOCALITY": "BHELSI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5963,
      "City District Name": "TIKAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 472115,
      "TXT_PINCODE_LOCALITY": "KHARAGAPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5963,
      "City District Name": "TIKAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 472117,
      "TXT_PINCODE_LOCALITY": "KUDILA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5963,
      "City District Name": "TIKAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 472118,
      "TXT_PINCODE_LOCALITY": "JATARA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5963,
      "City District Name": "TIKAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 472122,
      "TXT_PINCODE_LOCALITY": "MOHRA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5963,
      "City District Name": "TIKAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 472221,
      "TXT_PINCODE_LOCALITY": "PALERA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5963,
      "City District Name": "TIKAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 472246,
      "TXT_PINCODE_LOCALITY": "ORCHHA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5963,
      "City District Name": "TIKAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 472327,
      "TXT_PINCODE_LOCALITY": "JERON KHALSA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5963,
      "City District Name": "TIKAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 472331,
      "TXT_PINCODE_LOCALITY": "LIDHORA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5963,
      "City District Name": "TIKAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 472336,
      "TXT_PINCODE_LOCALITY": "PRITHVIPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5963,
      "City District Name": "TIKAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 472337,
      "TXT_PINCODE_LOCALITY": "JERAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5963,
      "City District Name": "TIKAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 472338,
      "TXT_PINCODE_LOCALITY": "MADIYA KHAS",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5184,
      "City District Name": "PRITHVIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 472339,
      "TXT_PINCODE_LOCALITY": "DIGODA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5963,
      "City District Name": "TIKAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 472344,
      "TXT_PINCODE_LOCALITY": "KARIKHAS",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5963,
      "City District Name": "TIKAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 472441,
      "TXT_PINCODE_LOCALITY": "ASTARI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5963,
      "City District Name": "TIKAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 472442,
      "TXT_PINCODE_LOCALITY": "NIWARI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5963,
      "City District Name": "TIKAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 472445,
      "TXT_PINCODE_LOCALITY": "TARICHOR KALAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5963,
      "City District Name": "TIKAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 472446,
      "TXT_PINCODE_LOCALITY": "SENDRI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5963,
      "City District Name": "TIKAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 472447,
      "TXT_PINCODE_LOCALITY": "TEHARAKA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5963,
      "City District Name": "TIKAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 472567,
      "TXT_PINCODE_LOCALITY": "SIHUDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5963,
      "City District Name": "TIKAMGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 472665,
      "TXT_PINCODE_LOCALITY": "TALBHEW",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4754,
      "City District Name": "NARWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473001,
      "TXT_PINCODE_LOCALITY": "GUNA H O",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1825,
      "City District Name": "GUNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473002,
      "TXT_PINCODE_LOCALITY": "GUNA CANT",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1825,
      "City District Name": "GUNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473101,
      "TXT_PINCODE_LOCALITY": "ARONE",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1825,
      "City District Name": "GUNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473105,
      "TXT_PINCODE_LOCALITY": "BAMORI(GUNA)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1825,
      "City District Name": "GUNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473108,
      "TXT_PINCODE_LOCALITY": "AWAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1825,
      "City District Name": "GUNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473110,
      "TXT_PINCODE_LOCALITY": "RUTHIYARI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1825,
      "City District Name": "GUNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473111,
      "TXT_PINCODE_LOCALITY": "BHANAWAD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1825,
      "City District Name": "GUNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473112,
      "TXT_PINCODE_LOCALITY": "VIJAYPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1825,
      "City District Name": "GUNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473113,
      "TXT_PINCODE_LOCALITY": "MYANA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1825,
      "City District Name": "GUNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473115,
      "TXT_PINCODE_LOCALITY": "BINAGANJ",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1825,
      "City District Name": "GUNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473118,
      "TXT_PINCODE_LOCALITY": "SEMLI BHOJ",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 442814,
      "City District Name": "CHACHAURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473222,
      "TXT_PINCODE_LOCALITY": "KUMBHRAJ",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1825,
      "City District Name": "GUNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473226,
      "TXT_PINCODE_LOCALITY": "RAGHOGARH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1825,
      "City District Name": "GUNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473229,
      "TXT_PINCODE_LOCALITY": "RAOSAR (JAGIR)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1825,
      "City District Name": "GUNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473233,
      "TXT_PINCODE_LOCALITY": "MYANA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1825,
      "City District Name": "GUNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473249,
      "TXT_PINCODE_LOCALITY": "BAJARANGAPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1825,
      "City District Name": "GUNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473281,
      "TXT_PINCODE_LOCALITY": "DURGAPUR KHOD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5128,
      "City District Name": "PICHHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473287,
      "TXT_PINCODE_LOCALITY": "MAKSOODANGARH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1825,
      "City District Name": "GUNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473322,
      "TXT_PINCODE_LOCALITY": "KUMBHARAJ",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1825,
      "City District Name": "GUNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473330,
      "TXT_PINCODE_LOCALITY": "SADHORA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1825,
      "City District Name": "GUNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473331,
      "TXT_PINCODE_LOCALITY": "ASHOK NAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1825,
      "City District Name": "GUNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473332,
      "TXT_PINCODE_LOCALITY": "NAISARAI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2128,
      "City District Name": "ASHOK NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473333,
      "TXT_PINCODE_LOCALITY": "SAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1825,
      "City District Name": "GUNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473335,
      "TXT_PINCODE_LOCALITY": "ESAGARH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1825,
      "City District Name": "GUNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473338,
      "TXT_PINCODE_LOCALITY": "SHIVPUR ANANDPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1825,
      "City District Name": "GUNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473440,
      "TXT_PINCODE_LOCALITY": "PIPRAI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1825,
      "City District Name": "GUNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473443,
      "TXT_PINCODE_LOCALITY": "MUNGELI BAZAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1825,
      "City District Name": "GUNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473444,
      "TXT_PINCODE_LOCALITY": "SEHRAI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2128,
      "City District Name": "ASHOK NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473445,
      "TXT_PINCODE_LOCALITY": "ASHOK NAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2128,
      "City District Name": "ASHOK NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473446,
      "TXT_PINCODE_LOCALITY": "CHANDERI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1825,
      "City District Name": "GUNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473505,
      "TXT_PINCODE_LOCALITY": "KALI PAHADI CHANDERI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 3930,
      "City District Name": "KHANIYADHANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473551,
      "TXT_PINCODE_LOCALITY": "SHIVPURI CITY",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1048,
      "City District Name": "SHIVPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473583,
      "TXT_PINCODE_LOCALITY": "EJWARA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2203,
      "City District Name": "BADARWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473585,
      "TXT_PINCODE_LOCALITY": "SULAR KALAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 3930,
      "City District Name": "KHANIYADHANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473588,
      "TXT_PINCODE_LOCALITY": "RIJODI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2203,
      "City District Name": "BADARWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473616,
      "TXT_PINCODE_LOCALITY": "CHORPURA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 719,
      "City District Name": "RAJGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473637,
      "TXT_PINCODE_LOCALITY": "VIJAYPUR ALIAS KILHAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5128,
      "City District Name": "PICHHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473638,
      "TXT_PINCODE_LOCALITY": "KATTHAMIL SHIVPURI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1048,
      "City District Name": "SHIVPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473660,
      "TXT_PINCODE_LOCALITY": "KARERA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1048,
      "City District Name": "SHIVPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473662,
      "TXT_PINCODE_LOCALITY": "RONIJA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4754,
      "City District Name": "NARWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473664,
      "TXT_PINCODE_LOCALITY": "SEHORE",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 474,
      "City District Name": "SEHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473665,
      "TXT_PINCODE_LOCALITY": "SHIVPURI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1048,
      "City District Name": "SHIVPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473670,
      "TXT_PINCODE_LOCALITY": "MANPURA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1048,
      "City District Name": "SHIVPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473725,
      "TXT_PINCODE_LOCALITY": "SARWANI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 442824,
      "City District Name": "POHRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473770,
      "TXT_PINCODE_LOCALITY": "KOLARAS",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1048,
      "City District Name": "SHIVPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473774,
      "TXT_PINCODE_LOCALITY": "TORIYA KHEDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4038,
      "City District Name": "KOLARAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473775,
      "TXT_PINCODE_LOCALITY": "TIMAUNI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 442824,
      "City District Name": "POHRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473777,
      "TXT_PINCODE_LOCALITY": "TILA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4038,
      "City District Name": "KOLARAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473780,
      "TXT_PINCODE_LOCALITY": "SADBODH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2203,
      "City District Name": "BADARWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473781,
      "TXT_PINCODE_LOCALITY": "TILA KHURD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2203,
      "City District Name": "BADARWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473785,
      "TXT_PINCODE_LOCALITY": "MAL BARBA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 442824,
      "City District Name": "POHRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473793,
      "TXT_PINCODE_LOCALITY": "BERAD S.O",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1048,
      "City District Name": "SHIVPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473858,
      "TXT_PINCODE_LOCALITY": "BAHADURA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2203,
      "City District Name": "BADARWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473865,
      "TXT_PINCODE_LOCALITY": "MOHNI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4754,
      "City District Name": "NARWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473880,
      "TXT_PINCODE_LOCALITY": "NARWAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1048,
      "City District Name": "SHIVPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473881,
      "TXT_PINCODE_LOCALITY": "AMHERA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1825,
      "City District Name": "GUNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473885,
      "TXT_PINCODE_LOCALITY": "BADARWAS",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1048,
      "City District Name": "SHIVPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473945,
      "TXT_PINCODE_LOCALITY": "KOTRA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5128,
      "City District Name": "PICHHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473970,
      "TXT_PINCODE_LOCALITY": "DAVIYA KALAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5128,
      "City District Name": "PICHHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473990,
      "TXT_PINCODE_LOCALITY": "KHANIYADHANA S.O",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473993,
      "TXT_PINCODE_LOCALITY": "HIRAPUR ALIAS BARKHEDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5128,
      "City District Name": "PICHHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 473995,
      "TXT_PINCODE_LOCALITY": "PICHORE",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1048,
      "City District Name": "SHIVPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 474001,
      "TXT_PINCODE_LOCALITY": "SIKANDAR KAMPOO",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 600,
      "City District Name": "GWALIOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 474002,
      "TXT_PINCODE_LOCALITY": "K P COLONY",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 600,
      "City District Name": "GWALIOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 474003,
      "TXT_PINCODE_LOCALITY": "HAZIRA CHAUK",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 600,
      "City District Name": "GWALIOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 474004,
      "TXT_PINCODE_LOCALITY": "GWALIOR BIRLANAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 600,
      "City District Name": "GWALIOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 474005,
      "TXT_PINCODE_LOCALITY": "DARPAN COLONY",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 600,
      "City District Name": "GWALIOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 474006,
      "TXT_PINCODE_LOCALITY": "R K PURI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 600,
      "City District Name": "GWALIOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 474007,
      "TXT_PINCODE_LOCALITY": "GWALIOR MOTIMAHAL",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 600,
      "City District Name": "GWALIOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 474008,
      "TXT_PINCODE_LOCALITY": "GWALIOR FORT",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 600,
      "City District Name": "GWALIOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 474009,
      "TXT_PINCODE_LOCALITY": "USHA COLONY",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 600,
      "City District Name": "GWALIOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 474010,
      "TXT_PINCODE_LOCALITY": "GWALIOR MOTIJHEEL",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 600,
      "City District Name": "GWALIOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 474011,
      "TXT_PINCODE_LOCALITY": "GWALIOR R.K.PURI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 600,
      "City District Name": "GWALIOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 474012,
      "TXT_PINCODE_LOCALITY": "GWALIOR S.P. ASHRAM",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 600,
      "City District Name": "GWALIOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 474013,
      "TXT_PINCODE_LOCALITY": "PREM NAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 442819,
      "City District Name": "GWALIOR (GIRD)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 474015,
      "TXT_PINCODE_LOCALITY": "AB VIII TM GWALIOR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 600,
      "City District Name": "GWALIOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 474020,
      "TXT_PINCODE_LOCALITY": "GWALIOR MAHARAJPUR A F",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 600,
      "City District Name": "GWALIOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 474040,
      "TXT_PINCODE_LOCALITY": "GWALIOR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 600,
      "City District Name": "GWALIOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 474041,
      "TXT_PINCODE_LOCALITY": "BARAI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 600,
      "City District Name": "GWALIOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 474042,
      "TXT_PINCODE_LOCALITY": "GWALIOR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 600,
      "City District Name": "GWALIOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 474043,
      "TXT_PINCODE_LOCALITY": "TIGHRA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 600,
      "City District Name": "GWALIOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 474050,
      "TXT_PINCODE_LOCALITY": "BIJAULI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 600,
      "City District Name": "GWALIOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 474055,
      "TXT_PINCODE_LOCALITY": "GWALIER UTILA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 600,
      "City District Name": "GWALIOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 474110,
      "TXT_PINCODE_LOCALITY": "DHAMNIKA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 442816,
      "City District Name": "DABRA (PICHHORE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 474400,
      "TXT_PINCODE_LOCALITY": "LALTIPARA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 442819,
      "City District Name": "GWALIOR (GIRD)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 474770,
      "TXT_PINCODE_LOCALITY": "MAJHARI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2203,
      "City District Name": "BADARWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 474901,
      "TXT_PINCODE_LOCALITY": "CHAK GIRVAI NO. 1",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 442819,
      "City District Name": "GWALIOR (GIRD)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475001,
      "TXT_PINCODE_LOCALITY": "ANTRI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 600,
      "City District Name": "GWALIOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475002,
      "TXT_PINCODE_LOCALITY": "BEHAT",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 600,
      "City District Name": "GWALIOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475003,
      "TXT_PINCODE_LOCALITY": "BILLAUA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 600,
      "City District Name": "GWALIOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475005,
      "TXT_PINCODE_LOCALITY": "TEKANPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 600,
      "City District Name": "GWALIOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475009,
      "TXT_PINCODE_LOCALITY": "THAKURPURA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1386,
      "City District Name": "DATIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475010,
      "TXT_PINCODE_LOCALITY": "NIMBAJI KI KHOH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 442819,
      "City District Name": "GWALIOR (GIRD)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475110,
      "TXT_PINCODE_LOCALITY": "DABRA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 600,
      "City District Name": "GWALIOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475111,
      "TXT_PINCODE_LOCALITY": "CHINORE",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 600,
      "City District Name": "GWALIOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475112,
      "TXT_PINCODE_LOCALITY": "SIMARIA TAL",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 600,
      "City District Name": "GWALIOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475115,
      "TXT_PINCODE_LOCALITY": "PICHHORE",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 600,
      "City District Name": "GWALIOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475118,
      "TXT_PINCODE_LOCALITY": "SARNAGAT",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 442816,
      "City District Name": "DABRA (PICHHORE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475120,
      "TXT_PINCODE_LOCALITY": "LADERA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 442816,
      "City District Name": "DABRA (PICHHORE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475170,
      "TXT_PINCODE_LOCALITY": "GAJAPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 442816,
      "City District Name": "DABRA (PICHHORE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475220,
      "TXT_PINCODE_LOCALITY": "BHITARWAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 600,
      "City District Name": "GWALIOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475301,
      "TXT_PINCODE_LOCALITY": "KUWRPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 442819,
      "City District Name": "GWALIOR (GIRD)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475330,
      "TXT_PINCODE_LOCALITY": "MOHNA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 600,
      "City District Name": "GWALIOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475331,
      "TXT_PINCODE_LOCALITY": "GHATIGAON",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 600,
      "City District Name": "GWALIOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475332,
      "TXT_PINCODE_LOCALITY": "RENHAT",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 600,
      "City District Name": "GWALIOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475333,
      "TXT_PINCODE_LOCALITY": "MURIYA (MURIA-81)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2466,
      "City District Name": "BHANDER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475335,
      "TXT_PINCODE_LOCALITY": "BHANDER",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 600,
      "City District Name": "GWALIOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475336,
      "TXT_PINCODE_LOCALITY": "SALON B",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 600,
      "City District Name": "GWALIOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475337,
      "TXT_PINCODE_LOCALITY": "PANDOKHAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 600,
      "City District Name": "GWALIOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475475,
      "TXT_PINCODE_LOCALITY": "UNCHIYA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 3465,
      "City District Name": "INDERGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475522,
      "TXT_PINCODE_LOCALITY": "SARWA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 442816,
      "City District Name": "DABRA (PICHHORE)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475533,
      "TXT_PINCODE_LOCALITY": "MOHNA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 600,
      "City District Name": "GWALIOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475551,
      "TXT_PINCODE_LOCALITY": "POORB DHANA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1048,
      "City District Name": "SHIVPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475652,
      "TXT_PINCODE_LOCALITY": "VIJAYPURA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5583,
      "City District Name": "SEONDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475661,
      "TXT_PINCODE_LOCALITY": "DATIA RAJGARH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1386,
      "City District Name": "DATIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475665,
      "TXT_PINCODE_LOCALITY": "DURSADA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1386,
      "City District Name": "DATIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475668,
      "TXT_PINCODE_LOCALITY": "BARONI KALAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 600,
      "City District Name": "GWALIOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475669,
      "TXT_PINCODE_LOCALITY": "SONAGIR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 600,
      "City District Name": "GWALIOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475671,
      "TXT_PINCODE_LOCALITY": "UNNAO (DATIA)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1386,
      "City District Name": "DATIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475673,
      "TXT_PINCODE_LOCALITY": "THARET",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1386,
      "City District Name": "DATIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475674,
      "TXT_PINCODE_LOCALITY": "JASAWALI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5583,
      "City District Name": "SEONDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475675,
      "TXT_PINCODE_LOCALITY": "INDARGARH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1386,
      "City District Name": "DATIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475676,
      "TXT_PINCODE_LOCALITY": "SYAWARI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 3465,
      "City District Name": "INDERGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475677,
      "TXT_PINCODE_LOCALITY": "SEMAI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1386,
      "City District Name": "DATIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475682,
      "TXT_PINCODE_LOCALITY": "SEONDHA (GWAHOT)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1386,
      "City District Name": "DATIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475683,
      "TXT_PINCODE_LOCALITY": "DIROLIPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1386,
      "City District Name": "DATIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475684,
      "TXT_PINCODE_LOCALITY": "DATIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1386,
      "City District Name": "DATIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475685,
      "TXT_PINCODE_LOCALITY": "UPRAI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1386,
      "City District Name": "DATIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475686,
      "TXT_PINCODE_LOCALITY": "BARONI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 600,
      "City District Name": "GWALIOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475693,
      "TXT_PINCODE_LOCALITY": "SITAPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1386,
      "City District Name": "DATIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 475956,
      "TXT_PINCODE_LOCALITY": "RAMPURA DANG",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1386,
      "City District Name": "DATIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476001,
      "TXT_PINCODE_LOCALITY": "MORENA GOPALPURA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476004,
      "TXT_PINCODE_LOCALITY": "JHUNDPURA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476026,
      "TXT_PINCODE_LOCALITY": "LALORE KALAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476027,
      "TXT_PINCODE_LOCALITY": "MORENA GAON",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476031,
      "TXT_PINCODE_LOCALITY": "DHANELA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476032,
      "TXT_PINCODE_LOCALITY": "NOORABAD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476111,
      "TXT_PINCODE_LOCALITY": "AMBAH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476113,
      "TXT_PINCODE_LOCALITY": "KAMATARI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476115,
      "TXT_PINCODE_LOCALITY": "PORSA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476117,
      "TXT_PINCODE_LOCALITY": "THARA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476119,
      "TXT_PINCODE_LOCALITY": "RAJODHA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476120,
      "TXT_PINCODE_LOCALITY": "RUAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476121,
      "TXT_PINCODE_LOCALITY": "KIRRAICH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476124,
      "TXT_PINCODE_LOCALITY": "LUDHDOLI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476127,
      "TXT_PINCODE_LOCALITY": "USAITH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476131,
      "TXT_PINCODE_LOCALITY": "JIGNI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5622,
      "City District Name": "SHEOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476132,
      "TXT_PINCODE_LOCALITY": "BAREH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476133,
      "TXT_PINCODE_LOCALITY": "NAOLI BADAGAON",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476134,
      "TXT_PINCODE_LOCALITY": "DIMNI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476135,
      "TXT_PINCODE_LOCALITY": "JOHAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476139,
      "TXT_PINCODE_LOCALITY": "AISA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476150,
      "TXT_PINCODE_LOCALITY": "RITHORA KALAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476211,
      "TXT_PINCODE_LOCALITY": "CHAND KA PURA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476214,
      "TXT_PINCODE_LOCALITY": "GALETHA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476216,
      "TXT_PINCODE_LOCALITY": "KUMHERI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476219,
      "TXT_PINCODE_LOCALITY": "BAGCHINI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476221,
      "TXT_PINCODE_LOCALITY": "JOURA CITY",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476223,
      "TXT_PINCODE_LOCALITY": "SUMAOLI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476224,
      "TXT_PINCODE_LOCALITY": "MORENA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476225,
      "TXT_PINCODE_LOCALITY": "SUJARMA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476226,
      "TXT_PINCODE_LOCALITY": "JHANDPURA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5622,
      "City District Name": "SHEOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476227,
      "TXT_PINCODE_LOCALITY": "ALIPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5622,
      "City District Name": "SHEOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476228,
      "TXT_PINCODE_LOCALITY": "PAHARGARH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476229,
      "TXT_PINCODE_LOCALITY": "SABALGARH MANDI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476230,
      "TXT_PINCODE_LOCALITY": "MANGROL",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476232,
      "TXT_PINCODE_LOCALITY": "TENTRA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476233,
      "TXT_PINCODE_LOCALITY": "BAMSAULI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476235,
      "TXT_PINCODE_LOCALITY": "RAMPUR KALAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476242,
      "TXT_PINCODE_LOCALITY": "CHINNONI CHAMBOL",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476322,
      "TXT_PINCODE_LOCALITY": "SILPARA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 442827,
      "City District Name": "VIJAYPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476330,
      "TXT_PINCODE_LOCALITY": "ANTRI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 442819,
      "City District Name": "GWALIOR (GIRD)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476332,
      "TXT_PINCODE_LOCALITY": "BIJARPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5622,
      "City District Name": "SHEOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476333,
      "TXT_PINCODE_LOCALITY": "GASWANI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5622,
      "City District Name": "SHEOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476334,
      "TXT_PINCODE_LOCALITY": "IKLOD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5622,
      "City District Name": "SHEOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476335,
      "TXT_PINCODE_LOCALITY": "BEERPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476336,
      "TXT_PINCODE_LOCALITY": "DHONDHER",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5622,
      "City District Name": "SHEOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476337,
      "TXT_PINCODE_LOCALITY": "MORENA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476339,
      "TXT_PINCODE_LOCALITY": "MORENA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476340,
      "TXT_PINCODE_LOCALITY": "AWDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5622,
      "City District Name": "SHEOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476341,
      "TXT_PINCODE_LOCALITY": "PANDOLA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5622,
      "City District Name": "SHEOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476343,
      "TXT_PINCODE_LOCALITY": "SOIKALAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5622,
      "City District Name": "SHEOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476345,
      "TXT_PINCODE_LOCALITY": "MORENA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476355,
      "TXT_PINCODE_LOCALITY": "KARAHAL",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5622,
      "City District Name": "SHEOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476371,
      "TXT_PINCODE_LOCALITY": "SIRSOD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5622,
      "City District Name": "SHEOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476444,
      "TXT_PINCODE_LOCALITY": "BANMORE",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476554,
      "TXT_PINCODE_LOCALITY": "KHADIAHAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476557,
      "TXT_PINCODE_LOCALITY": "SIHONIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476573,
      "TXT_PINCODE_LOCALITY": "TODAPAHAD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5583,
      "City District Name": "SEONDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476673,
      "TXT_PINCODE_LOCALITY": "RAMPURAKHURD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5583,
      "City District Name": "SEONDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 476675,
      "TXT_PINCODE_LOCALITY": "CHIMGHAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 3465,
      "City District Name": "INDERGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477001,
      "TXT_PINCODE_LOCALITY": "BHIND",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 976,
      "City District Name": "BHIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477006,
      "TXT_PINCODE_LOCALITY": "CHANDUPURA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 442819,
      "City District Name": "GWALIOR (GIRD)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477076,
      "TXT_PINCODE_LOCALITY": "JAWASA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 976,
      "City District Name": "BHIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477103,
      "TXT_PINCODE_LOCALITY": "CHARTHAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 976,
      "City District Name": "BHIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477105,
      "TXT_PINCODE_LOCALITY": "NAIZAMIN (BHIND)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 976,
      "City District Name": "BHIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477111,
      "TXT_PINCODE_LOCALITY": "ATTAIR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 976,
      "City District Name": "BHIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477113,
      "TXT_PINCODE_LOCALITY": "PARAGAON",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 976,
      "City District Name": "BHIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477116,
      "TXT_PINCODE_LOCALITY": "GOHAD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 976,
      "City District Name": "BHIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477117,
      "TXT_PINCODE_LOCALITY": "MALANPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 976,
      "City District Name": "BHIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477201,
      "TXT_PINCODE_LOCALITY": "BHAROLI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 976,
      "City District Name": "BHIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477204,
      "TXT_PINCODE_LOCALITY": "NUNHATA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 976,
      "City District Name": "BHIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477222,
      "TXT_PINCODE_LOCALITY": "MAU (BHIND)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 976,
      "City District Name": "BHIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477227,
      "TXT_PINCODE_LOCALITY": "AMAYAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 976,
      "City District Name": "BHIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477236,
      "TXT_PINCODE_LOCALITY": "GAHELI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 976,
      "City District Name": "BHIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477246,
      "TXT_PINCODE_LOCALITY": "ASWAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 976,
      "City District Name": "BHIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477301,
      "TXT_PINCODE_LOCALITY": "SONI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 976,
      "City District Name": "BHIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477331,
      "TXT_PINCODE_LOCALITY": "UMRI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 976,
      "City District Name": "BHIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477332,
      "TXT_PINCODE_LOCALITY": "AKODA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 976,
      "City District Name": "BHIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477333,
      "TXT_PINCODE_LOCALITY": "SAGRA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 976,
      "City District Name": "BHIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477335,
      "TXT_PINCODE_LOCALITY": "RAWN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 976,
      "City District Name": "BHIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477338,
      "TXT_PINCODE_LOCALITY": "KANAWAD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 976,
      "City District Name": "BHIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477417,
      "TXT_PINCODE_LOCALITY": "AMHA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 976,
      "City District Name": "BHIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477427,
      "TXT_PINCODE_LOCALITY": "MACHHAND",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 976,
      "City District Name": "BHIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477430,
      "TXT_PINCODE_LOCALITY": "KATHA BOHARA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 976,
      "City District Name": "BHIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477433,
      "TXT_PINCODE_LOCALITY": "ACHALPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 976,
      "City District Name": "BHIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477440,
      "TXT_PINCODE_LOCALITY": "ALAMPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 976,
      "City District Name": "BHIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477441,
      "TXT_PINCODE_LOCALITY": "MIHONA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 976,
      "City District Name": "BHIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477445,
      "TXT_PINCODE_LOCALITY": "LAHAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 976,
      "City District Name": "BHIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477446,
      "TXT_PINCODE_LOCALITY": "ASWAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 976,
      "City District Name": "BHIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477447,
      "TXT_PINCODE_LOCALITY": "DABOH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 976,
      "City District Name": "BHIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477449,
      "TXT_PINCODE_LOCALITY": "ALAMPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 976,
      "City District Name": "BHIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477450,
      "TXT_PINCODE_LOCALITY": "RAWATPURA SARKAR ASHRAM",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477455,
      "TXT_PINCODE_LOCALITY": "AHAINTI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 976,
      "City District Name": "BHIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477534,
      "TXT_PINCODE_LOCALITY": "BARHAD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 976,
      "City District Name": "BHIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477555,
      "TXT_PINCODE_LOCALITY": "PHOOP",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 976,
      "City District Name": "BHIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477556,
      "TXT_PINCODE_LOCALITY": "BARHI (BHINDI)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 976,
      "City District Name": "BHIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477557,
      "TXT_PINCODE_LOCALITY": "MEHGAON (BHIND)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 976,
      "City District Name": "BHIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477560,
      "TXT_PINCODE_LOCALITY": "BHADAKHUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 976,
      "City District Name": "BHIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477564,
      "TXT_PINCODE_LOCALITY": "KACHONGARA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 976,
      "City District Name": "BHIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477566,
      "TXT_PINCODE_LOCALITY": "BADEPURA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 976,
      "City District Name": "BHIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477577,
      "TXT_PINCODE_LOCALITY": "BARASON",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 976,
      "City District Name": "BHIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477622,
      "TXT_PINCODE_LOCALITY": "SIMRODA KIRAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5411,
      "City District Name": "SABALGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477660,
      "TXT_PINCODE_LOCALITY": "GORMI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 976,
      "City District Name": "BHIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 477673,
      "TXT_PINCODE_LOCALITY": "PACHORAKHURD.",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 3465,
      "City District Name": "INDERGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 478558,
      "TXT_PINCODE_LOCALITY": "BIJORI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 976,
      "City District Name": "BHIND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480000,
      "TXT_PINCODE_LOCALITY": "MARRAM",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1392,
      "City District Name": "SAUSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 283205,
      "TXT_PINCODE_LOCALITY": "COLLECTORATE - FIROZABAD",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 939,
      "City District Name": "FIROZABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 290281,
      "TXT_PINCODE_LOCALITY": "DHARWAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 290363,
      "TXT_PINCODE_LOCALITY": "KOPPAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 291291,
      "TXT_PINCODE_LOCALITY": "BELLARY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 293834,
      "TXT_PINCODE_LOCALITY": "DUBAI HEALTHCARE CITY",
      "NUM_STATE_CD": 927,
      "State Name": "DUBAI",
      "NUM_CITYDISTRICT_CD": 444189,
      "City District Name": "DUBAI",
      "NUM_COUNTRY_CD": 142
    },
    {
      "NUM_PINCODE": 380001,
      "TXT_PINCODE_LOCALITY": "PATNA BHAL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380002,
      "TXT_PINCODE_LOCALITY": "N C MARKET",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380003,
      "TXT_PINCODE_LOCALITY": "MEM NAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380004,
      "TXT_PINCODE_LOCALITY": "SHAHPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380005,
      "TXT_PINCODE_LOCALITY": "O N G C",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380006,
      "TXT_PINCODE_LOCALITY": "ELLIS BRIDGE",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380007,
      "TXT_PINCODE_LOCALITY": "NARAYANNAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380008,
      "TXT_PINCODE_LOCALITY": "JAWAHAR CHOWK",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380009,
      "TXT_PINCODE_LOCALITY": "C.G.ROAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380010,
      "TXT_PINCODE_LOCALITY": "GIRDHARNAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380012,
      "TXT_PINCODE_LOCALITY": "AHMEDABAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380013,
      "TXT_PINCODE_LOCALITY": "VADAJ AHMEDABAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380014,
      "TXT_PINCODE_LOCALITY": "NAVJIVAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380015,
      "TXT_PINCODE_LOCALITY": "PREMCHAND HOUSE",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380016,
      "TXT_PINCODE_LOCALITY": "ASARWA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380017,
      "TXT_PINCODE_LOCALITY": "BAPUNAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 442658,
      "City District Name": "AHMADABAD CITY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380018,
      "TXT_PINCODE_LOCALITY": "AHMEDABAD SARASPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380019,
      "TXT_PINCODE_LOCALITY": "AHMEDABAD RAILWAY COLONY",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380020,
      "TXT_PINCODE_LOCALITY": "AHMADABAD CITY",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380021,
      "TXT_PINCODE_LOCALITY": "SONARIA BLOCK",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380022,
      "TXT_PINCODE_LOCALITY": "RAIPUR KANKARIA ROAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380023,
      "TXT_PINCODE_LOCALITY": "AHMEDABAD RAKHIALUDYOG VI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380024,
      "TXT_PINCODE_LOCALITY": "BAPU NAGAR I E",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380025,
      "TXT_PINCODE_LOCALITY": "ANIL MARG",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380026,
      "TXT_PINCODE_LOCALITY": "AHMEDABAD AMRAIWADI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380027,
      "TXT_PINCODE_LOCALITY": "GANDHI ASHRAM AHMEDABAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380028,
      "TXT_PINCODE_LOCALITY": "BHAIRAVNATH ROAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380031,
      "TXT_PINCODE_LOCALITY": "SHIV GANGA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380038,
      "TXT_PINCODE_LOCALITY": "KRISHNANAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380043,
      "TXT_PINCODE_LOCALITY": "ISANPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380045,
      "TXT_PINCODE_LOCALITY": "PIPLEJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380049,
      "TXT_PINCODE_LOCALITY": "AHMEDABAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380050,
      "TXT_PINCODE_LOCALITY": "SANT PUNIT MARG",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380051,
      "TXT_PINCODE_LOCALITY": "AHMEDABAD JIVARAJ PARK (VEJALPUR)",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380052,
      "TXT_PINCODE_LOCALITY": "MEMNAGAR AHMEDABAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380053,
      "TXT_PINCODE_LOCALITY": "APPLICATION CENTRE",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380054,
      "TXT_PINCODE_LOCALITY": "AHMEDABAD THALTEJ ROAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380055,
      "TXT_PINCODE_LOCALITY": "AHMEDABAD JUHAPURA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380058,
      "TXT_PINCODE_LOCALITY": "BOPAL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380059,
      "TXT_PINCODE_LOCALITY": "S.G. ROAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380060,
      "TXT_PINCODE_LOCALITY": "SCIENCE CITY ROAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 188,
      "City District Name": "AHMEDABAD.",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380061,
      "TXT_PINCODE_LOCALITY": "GHATLODIA AHMEDABAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380062,
      "TXT_PINCODE_LOCALITY": "SOLA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380063,
      "TXT_PINCODE_LOCALITY": "SOLA HOUSING BOARD COLONY",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 380081,
      "TXT_PINCODE_LOCALITY": "NIRNAYNAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 381110,
      "TXT_PINCODE_LOCALITY": "ALIABADA *",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382001,
      "TXT_PINCODE_LOCALITY": "KALOL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382002,
      "TXT_PINCODE_LOCALITY": "SECTOR 2D",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382003,
      "TXT_PINCODE_LOCALITY": "SECTOR 3",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382004,
      "TXT_PINCODE_LOCALITY": "SARGAASAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382005,
      "TXT_PINCODE_LOCALITY": "AHMEDABAD S. O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382006,
      "TXT_PINCODE_LOCALITY": "GANDHINAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382007,
      "TXT_PINCODE_LOCALITY": "ADALAJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382008,
      "TXT_PINCODE_LOCALITY": "SAAMCHAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382009,
      "TXT_PINCODE_LOCALITY": "GANDHI NAGAR SECTOR 9",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382010,
      "TXT_PINCODE_LOCALITY": "GANDHI NAGAR VIDHANSABHA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382011,
      "TXT_PINCODE_LOCALITY": "GANDHINAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382012,
      "TXT_PINCODE_LOCALITY": "SECTOR 12",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382013,
      "TXT_PINCODE_LOCALITY": "SECTOR-13/B",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382014,
      "TXT_PINCODE_LOCALITY": "GANDHINAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382015,
      "TXT_PINCODE_LOCALITY": "FATEHPURA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382016,
      "TXT_PINCODE_LOCALITY": "GHANDHINAGAR SECTOR 16",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382017,
      "TXT_PINCODE_LOCALITY": "GANDHI NAGAR SECTOR 17",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382018,
      "TXT_PINCODE_LOCALITY": "SECTOR 9",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382019,
      "TXT_PINCODE_LOCALITY": "GANDHI NAGAR SECTOR 19",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382020,
      "TXT_PINCODE_LOCALITY": "GANDHI NAGAR SECTOR 20",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382021,
      "TXT_PINCODE_LOCALITY": "GANDHI NAGAR SECTOR 21",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382022,
      "TXT_PINCODE_LOCALITY": "GANDHI NAGAR SECTOR 22",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382023,
      "TXT_PINCODE_LOCALITY": "GANDHI NAGAR SECTOR 23",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382024,
      "TXT_PINCODE_LOCALITY": "SECTOR 23 S.O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382025,
      "TXT_PINCODE_LOCALITY": "SECTOR 25",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382026,
      "TXT_PINCODE_LOCALITY": "KISHAN NAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382027,
      "TXT_PINCODE_LOCALITY": "GANDHI NAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382028,
      "TXT_PINCODE_LOCALITY": "GANDHI NAGAR SECTOR 28",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382029,
      "TXT_PINCODE_LOCALITY": "GANDHI NAGAR SECTOR 29",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382030,
      "TXT_PINCODE_LOCALITY": "GANDHI NAGAR SECTOR 30",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382040,
      "TXT_PINCODE_LOCALITY": "SANAND",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382041,
      "TXT_PINCODE_LOCALITY": "GANDHI NAGAR T P S",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382042,
      "TXT_PINCODE_LOCALITY": "GANDHI NAGAR C R P F CAMP",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382043,
      "TXT_PINCODE_LOCALITY": "K P T",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382044,
      "TXT_PINCODE_LOCALITY": "ELECTRONIC ESTATE GANDHI NAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382045,
      "TXT_PINCODE_LOCALITY": "GANDHINAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382051,
      "TXT_PINCODE_LOCALITY": "KOLAVADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382110,
      "TXT_PINCODE_LOCALITY": "SANAND SOCIETY AREA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382113,
      "TXT_PINCODE_LOCALITY": "AHMEDABAD ZODIAC S. O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382115,
      "TXT_PINCODE_LOCALITY": "GODHAVI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382120,
      "TXT_PINCODE_LOCALITY": "DETROJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382122,
      "TXT_PINCODE_LOCALITY": "DANTALI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382130,
      "TXT_PINCODE_LOCALITY": "MANDAL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382139,
      "TXT_PINCODE_LOCALITY": "AMBAVADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382140,
      "TXT_PINCODE_LOCALITY": "RAMPURA BHANKODA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382145,
      "TXT_PINCODE_LOCALITY": "KATOSAN ROAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382150,
      "TXT_PINCODE_LOCALITY": "BHOJVA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382155,
      "TXT_PINCODE_LOCALITY": "AHMEDABAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382160,
      "TXT_PINCODE_LOCALITY": "AHMEDABAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382165,
      "TXT_PINCODE_LOCALITY": "KALYANPURA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382166,
      "TXT_PINCODE_LOCALITY": "AHMEDABAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382170,
      "TXT_PINCODE_LOCALITY": "JHOLAPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382210,
      "TXT_PINCODE_LOCALITY": "SARKHEJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382213,
      "TXT_PINCODE_LOCALITY": "MATODA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382220,
      "TXT_PINCODE_LOCALITY": "BAVLA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382225,
      "TXT_PINCODE_LOCALITY": "DHOLKA KHARAKUVA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382230,
      "TXT_PINCODE_LOCALITY": "LOTHAL BHURKHI R S",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382240,
      "TXT_PINCODE_LOCALITY": "KOTH GANGAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382245,
      "TXT_PINCODE_LOCALITY": "RANPUR S.O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382250,
      "TXT_PINCODE_LOCALITY": "POLARPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382255,
      "TXT_PINCODE_LOCALITY": "JALILA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382260,
      "TXT_PINCODE_LOCALITY": "CHALODA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382265,
      "TXT_PINCODE_LOCALITY": "KAUKA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382270,
      "TXT_PINCODE_LOCALITY": "AHMEDABAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382276,
      "TXT_PINCODE_LOCALITY": "BADARKHA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3005,
      "City District Name": "DHOLKA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382300,
      "TXT_PINCODE_LOCALITY": "AHMEDABAD.",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382305,
      "TXT_PINCODE_LOCALITY": "DAHEGAM",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382308,
      "TXT_PINCODE_LOCALITY": "BAHIYAL S.O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382310,
      "TXT_PINCODE_LOCALITY": "NANDOL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382315,
      "TXT_PINCODE_LOCALITY": "RAKHIAL R S",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382320,
      "TXT_PINCODE_LOCALITY": "SADRA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382321,
      "TXT_PINCODE_LOCALITY": "CHHALA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382325,
      "TXT_PINCODE_LOCALITY": "NARODA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382327,
      "TXT_PINCODE_LOCALITY": "AHMEDABAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382330,
      "TXT_PINCODE_LOCALITY": "AHMEDABAD AIR PORT",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382335,
      "TXT_PINCODE_LOCALITY": "KOTARPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382340,
      "TXT_PINCODE_LOCALITY": "SARDARNAGAR KUBERNAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382345,
      "TXT_PINCODE_LOCALITY": "N C MILLS",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382346,
      "TXT_PINCODE_LOCALITY": "KRISHNA NAGAR.",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382350,
      "TXT_PINCODE_LOCALITY": "AHMEDABAD.",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382352,
      "TXT_PINCODE_LOCALITY": "KHODIYARNAGAR S. O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382355,
      "TXT_PINCODE_LOCALITY": "DABHODA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382360,
      "TXT_PINCODE_LOCALITY": "VALAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382400,
      "TXT_PINCODE_LOCALITY": "AHMEDABAD B. O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382401,
      "TXT_PINCODE_LOCALITY": "GANDHINAGAR S. O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382405,
      "TXT_PINCODE_LOCALITY": "NAROL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382410,
      "TXT_PINCODE_LOCALITY": "ODHAV",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382414,
      "TXT_PINCODE_LOCALITY": "GANDHINAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382415,
      "TXT_PINCODE_LOCALITY": "ODHAV I E",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382418,
      "TXT_PINCODE_LOCALITY": "VASTRAL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382420,
      "TXT_PINCODE_LOCALITY": "NAYIVAS TINTODA GANDHINAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382421,
      "TXT_PINCODE_LOCALITY": "ZUNDAL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382422,
      "TXT_PINCODE_LOCALITY": "UVARSAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382423,
      "TXT_PINCODE_LOCALITY": "KASTURINAGAR SERTHA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382424,
      "TXT_PINCODE_LOCALITY": "CHANDKHEDA B.O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382425,
      "TXT_PINCODE_LOCALITY": "BAREJA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382426,
      "TXT_PINCODE_LOCALITY": "JETALPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382427,
      "TXT_PINCODE_LOCALITY": "JETALPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382428,
      "TXT_PINCODE_LOCALITY": "DASCROI S.O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382430,
      "TXT_PINCODE_LOCALITY": "KATHWADA M P",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382433,
      "TXT_PINCODE_LOCALITY": "UNDREL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382434,
      "TXT_PINCODE_LOCALITY": "AHMEDABAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382435,
      "TXT_PINCODE_LOCALITY": "NANDEJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382440,
      "TXT_PINCODE_LOCALITY": "AHMEDABAD S. O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382443,
      "TXT_PINCODE_LOCALITY": "ISANPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382445,
      "TXT_PINCODE_LOCALITY": "VATVA JASODANAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382446,
      "TXT_PINCODE_LOCALITY": "PRATHAM RESIDENCY VATAVA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382449,
      "TXT_PINCODE_LOCALITY": "JANTA NAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382450,
      "TXT_PINCODE_LOCALITY": "BARWALA GHELASA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382451,
      "TXT_PINCODE_LOCALITY": "AHMEDABAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382455,
      "TXT_PINCODE_LOCALITY": "DHOLERA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382460,
      "TXT_PINCODE_LOCALITY": "DHANDHUKA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382463,
      "TXT_PINCODE_LOCALITY": "BHADIAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382465,
      "TXT_PINCODE_LOCALITY": "FEDRA FERTILIZEMAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382470,
      "TXT_PINCODE_LOCALITY": "DIGVIJAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382475,
      "TXT_PINCODE_LOCALITY": "SARDARNAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382480,
      "TXT_PINCODE_LOCALITY": "RANIP",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382481,
      "TXT_PINCODE_LOCALITY": "CHANDLODIA - 936",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382610,
      "TXT_PINCODE_LOCALITY": "PETHAPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382620,
      "TXT_PINCODE_LOCALITY": "RANDHEJA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382630,
      "TXT_PINCODE_LOCALITY": "RUPAL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382640,
      "TXT_PINCODE_LOCALITY": "SARDHEV",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382650,
      "TXT_PINCODE_LOCALITY": "UNAVA BALVA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382655,
      "TXT_PINCODE_LOCALITY": "BALVA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382704,
      "TXT_PINCODE_LOCALITY": "CHARADU",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382705,
      "TXT_PINCODE_LOCALITY": "DANGARVA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382706,
      "TXT_PINCODE_LOCALITY": "NANDASAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382707,
      "TXT_PINCODE_LOCALITY": "KARJISAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382708,
      "TXT_PINCODE_LOCALITY": "VADASMA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382709,
      "TXT_PINCODE_LOCALITY": "VADU MEHSANA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382710,
      "TXT_PINCODE_LOCALITY": "JAGIDAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382711,
      "TXT_PINCODE_LOCALITY": "KHERVA JAGUDAM",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382712,
      "TXT_PINCODE_LOCALITY": "BORIAVI (JAGUDAN)",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382713,
      "TXT_PINCODE_LOCALITY": "AKHAJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382715,
      "TXT_PINCODE_LOCALITY": "KADI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382716,
      "TXT_PINCODE_LOCALITY": "SERISA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382717,
      "TXT_PINCODE_LOCALITY": "SURAJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382718,
      "TXT_PINCODE_LOCALITY": "GHUMANSAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382719,
      "TXT_PINCODE_LOCALITY": "JORNAJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382721,
      "TXT_PINCODE_LOCALITY": "MANSA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382722,
      "TXT_PINCODE_LOCALITY": "CHHATRAL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382725,
      "TXT_PINCODE_LOCALITY": "KALOLI E",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382726,
      "TXT_PINCODE_LOCALITY": "ADRAJ MERDA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382727,
      "TXT_PINCODE_LOCALITY": "KARANNAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382728,
      "TXT_PINCODE_LOCALITY": "THOL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382729,
      "TXT_PINCODE_LOCALITY": "CIMATRAL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382730,
      "TXT_PINCODE_LOCALITY": "LANGHNEJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382731,
      "TXT_PINCODE_LOCALITY": "VEDA PALIAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382732,
      "TXT_PINCODE_LOCALITY": "AMBALIAYASAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382733,
      "TXT_PINCODE_LOCALITY": "MEHSANA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 681,
      "City District Name": "MEHSANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382734,
      "TXT_PINCODE_LOCALITY": "VAMAJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382735,
      "TXT_PINCODE_LOCALITY": "NARDIPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382736,
      "TXT_PINCODE_LOCALITY": "JAMLA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382737,
      "TXT_PINCODE_LOCALITY": "PALIAD VEDA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382739,
      "TXT_PINCODE_LOCALITY": "LIMBODARA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382740,
      "TXT_PINCODE_LOCALITY": "PANSAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382741,
      "TXT_PINCODE_LOCALITY": "JHULASAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382742,
      "TXT_PINCODE_LOCALITY": "DINGUCHA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382743,
      "TXT_PINCODE_LOCALITY": "ISAND",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382745,
      "TXT_PINCODE_LOCALITY": "BAJANA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382750,
      "TXT_PINCODE_LOCALITY": "DASADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382751,
      "TXT_PINCODE_LOCALITY": "JAINABAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382755,
      "TXT_PINCODE_LOCALITY": "ZINZUWADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382760,
      "TXT_PINCODE_LOCALITY": "KHARAGHODA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382765,
      "TXT_PINCODE_LOCALITY": "PATDI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382770,
      "TXT_PINCODE_LOCALITY": "VANOD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382775,
      "TXT_PINCODE_LOCALITY": "LAKHTAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382780,
      "TXT_PINCODE_LOCALITY": "ADARIYANA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382781,
      "TXT_PINCODE_LOCALITY": "FATEPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382785,
      "TXT_PINCODE_LOCALITY": "MOTI BHOYAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382805,
      "TXT_PINCODE_LOCALITY": "AGLOD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382806,
      "TXT_PINCODE_LOCALITY": "FALU",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382810,
      "TXT_PINCODE_LOCALITY": "CHARADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382812,
      "TXT_PINCODE_LOCALITY": "DELVADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382815,
      "TXT_PINCODE_LOCALITY": "GAVADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382816,
      "TXT_PINCODE_LOCALITY": "MALOSAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382820,
      "TXT_PINCODE_LOCALITY": "GERITA KOLVADA R S",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382823,
      "TXT_PINCODE_LOCALITY": "SAMAU",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382824,
      "TXT_PINCODE_LOCALITY": "THALTEJ-DASKROI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382825,
      "TXT_PINCODE_LOCALITY": "GOZARIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382826,
      "TXT_PINCODE_LOCALITY": "BORU",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382830,
      "TXT_PINCODE_LOCALITY": "KUKARWADA S.O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382831,
      "TXT_PINCODE_LOCALITY": "BILODARA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382832,
      "TXT_PINCODE_LOCALITY": "VIHAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382833,
      "TXT_PINCODE_LOCALITY": "VADASAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382835,
      "TXT_PINCODE_LOCALITY": "MANSA.",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382836,
      "TXT_PINCODE_LOCALITY": "RINDROL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382840,
      "TXT_PINCODE_LOCALITY": "LADOL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382845,
      "TXT_PINCODE_LOCALITY": "MANSA BAZAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382850,
      "TXT_PINCODE_LOCALITY": "PILVAL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382851,
      "TXT_PINCODE_LOCALITY": "VEDA PILWAI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382855,
      "TXT_PINCODE_LOCALITY": "PILWAI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382856,
      "TXT_PINCODE_LOCALITY": "ANODIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382857,
      "TXT_PINCODE_LOCALITY": "MAHUDI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382860,
      "TXT_PINCODE_LOCALITY": "SARDARPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382861,
      "TXT_PINCODE_LOCALITY": "JANTRAL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382862,
      "TXT_PINCODE_LOCALITY": "SUNDARPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382863,
      "TXT_PINCODE_LOCALITY": "FUDEDA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382865,
      "TXT_PINCODE_LOCALITY": "VASAI DABHLA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382867,
      "TXT_PINCODE_LOCALITY": "DABHLA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382868,
      "TXT_PINCODE_LOCALITY": "TINTODAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382870,
      "TXT_PINCODE_LOCALITY": "VIJAPUR H O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382871,
      "TXT_PINCODE_LOCALITY": "GHANTU DHANPURA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382875,
      "TXT_PINCODE_LOCALITY": "MEHSANA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 681,
      "City District Name": "MEHSANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 382880,
      "TXT_PINCODE_LOCALITY": "RANASAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383001,
      "TXT_PINCODE_LOCALITY": "HAJIPURA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383002,
      "TXT_PINCODE_LOCALITY": "GIDC ANKLESHWAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383006,
      "TXT_PINCODE_LOCALITY": "BORIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383010,
      "TXT_PINCODE_LOCALITY": "VIRPUR S. O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383011,
      "TXT_PINCODE_LOCALITY": "JAMLA S K",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383012,
      "TXT_PINCODE_LOCALITY": "KANIOL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383015,
      "TXT_PINCODE_LOCALITY": "KANKROL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383030,
      "TXT_PINCODE_LOCALITY": "GAMBHOI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383107,
      "TXT_PINCODE_LOCALITY": "VAGHPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383110,
      "TXT_PINCODE_LOCALITY": "JADAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383111,
      "TXT_PINCODE_LOCALITY": "BHADRESAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383115,
      "TXT_PINCODE_LOCALITY": "ARODA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383120,
      "TXT_PINCODE_LOCALITY": "SALAL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383125,
      "TXT_PINCODE_LOCALITY": "MOYAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383200,
      "TXT_PINCODE_LOCALITY": "CHAPI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383205,
      "TXT_PINCODE_LOCALITY": "PRANTIJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383206,
      "TXT_PINCODE_LOCALITY": "BALISANA (S K)",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383210,
      "TXT_PINCODE_LOCALITY": "RAMPUR S. O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383215,
      "TXT_PINCODE_LOCALITY": "TALOD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383216,
      "TXT_PINCODE_LOCALITY": "GHADI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383220,
      "TXT_PINCODE_LOCALITY": "SAVGADH S. O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383225,
      "TXT_PINCODE_LOCALITY": "ARSODIA B.O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383230,
      "TXT_PINCODE_LOCALITY": "UMEDGADH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383235,
      "TXT_PINCODE_LOCALITY": "VADALI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383240,
      "TXT_PINCODE_LOCALITY": "HIMMATPUR S. O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383245,
      "TXT_PINCODE_LOCALITY": "BAZAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383246,
      "TXT_PINCODE_LOCALITY": "TAKATUKA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383247,
      "TXT_PINCODE_LOCALITY": "TORDA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383250,
      "TXT_PINCODE_LOCALITY": "TINTOI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383251,
      "TXT_PINCODE_LOCALITY": "DAHEGAMDA S. O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383255,
      "TXT_PINCODE_LOCALITY": "R S",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383260,
      "TXT_PINCODE_LOCALITY": "RAMPURA KAMPA S. O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383265,
      "TXT_PINCODE_LOCALITY": "AMBALIARA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383270,
      "TXT_PINCODE_LOCALITY": "RADHIVAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383271,
      "TXT_PINCODE_LOCALITY": "AGLA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383275,
      "TXT_PINCODE_LOCALITY": "LAXMIPURA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383276,
      "TXT_PINCODE_LOCALITY": "RAIGADH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383277,
      "TXT_PINCODE_LOCALITY": "MEDHASAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383280,
      "TXT_PINCODE_LOCALITY": "RUPAL HIMATNAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383305,
      "TXT_PINCODE_LOCALITY": "HARSOL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383306,
      "TXT_PINCODE_LOCALITY": "ANTROLI S K",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383307,
      "TXT_PINCODE_LOCALITY": "VADAGAM SK",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383308,
      "TXT_PINCODE_LOCALITY": "ANIOD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383310,
      "TXT_PINCODE_LOCALITY": "DHANSURA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383311,
      "TXT_PINCODE_LOCALITY": "SHINOL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383312,
      "TXT_PINCODE_LOCALITY": "SIKA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383315,
      "TXT_PINCODE_LOCALITY": "SOCIETY AREA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383316,
      "TXT_PINCODE_LOCALITY": "LIMBHOI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383317,
      "TXT_PINCODE_LOCALITY": "VARTHU S. O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383318,
      "TXT_PINCODE_LOCALITY": "DUGARWADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383320,
      "TXT_PINCODE_LOCALITY": "SARDOI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383325,
      "TXT_PINCODE_LOCALITY": "BAYAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383326,
      "TXT_PINCODE_LOCALITY": "VANTDA BAYAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383327,
      "TXT_PINCODE_LOCALITY": "CHOILA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383330,
      "TXT_PINCODE_LOCALITY": "DEMAI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383331,
      "TXT_PINCODE_LOCALITY": "AMODARA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383332,
      "TXT_PINCODE_LOCALITY": "DEROLI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383335,
      "TXT_PINCODE_LOCALITY": "VANKANEDA S. O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383340,
      "TXT_PINCODE_LOCALITY": "SATHAMBA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383341,
      "TXT_PINCODE_LOCALITY": "INDRAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383345,
      "TXT_PINCODE_LOCALITY": "MALPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383350,
      "TXT_PINCODE_LOCALITY": "MEGHRAJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383352,
      "TXT_PINCODE_LOCALITY": "PANCHAL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383355,
      "TXT_PINCODE_LOCALITY": "SHAMLAJI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383366,
      "TXT_PINCODE_LOCALITY": "CHITHODA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383367,
      "TXT_PINCODE_LOCALITY": "KUNOL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383368,
      "TXT_PINCODE_LOCALITY": "VANIYAWADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383410,
      "TXT_PINCODE_LOCALITY": "BADOLI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383411,
      "TXT_PINCODE_LOCALITY": "GORAL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383421,
      "TXT_PINCODE_LOCALITY": "CHITHODA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383422,
      "TXT_PINCODE_LOCALITY": "POSHINA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383423,
      "TXT_PINCODE_LOCALITY": "CHAHCAHNA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383430,
      "TXT_PINCODE_LOCALITY": "MARKET YARD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383434,
      "TXT_PINCODE_LOCALITY": "VERABAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383440,
      "TXT_PINCODE_LOCALITY": "CHORIWAD S. O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383450,
      "TXT_PINCODE_LOCALITY": "KISHANGADH S. O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383460,
      "TXT_PINCODE_LOCALITY": "ATARSUMBA B.O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383461,
      "TXT_PINCODE_LOCALITY": "CHITARIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383462,
      "TXT_PINCODE_LOCALITY": "KADIAWADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383544,
      "TXT_PINCODE_LOCALITY": "ASARDA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383750,
      "TXT_PINCODE_LOCALITY": "SURENDRA NAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383781,
      "TXT_PINCODE_LOCALITY": "FATEPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383810,
      "TXT_PINCODE_LOCALITY": "AJOL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 383825,
      "TXT_PINCODE_LOCALITY": "GOZARIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 681,
      "City District Name": "MEHSANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384001,
      "TXT_PINCODE_LOCALITY": "STATION ROAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384002,
      "TXT_PINCODE_LOCALITY": "RAILWAY COLONY",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384003,
      "TXT_PINCODE_LOCALITY": "MEHSANA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384005,
      "TXT_PINCODE_LOCALITY": "ALODA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384011,
      "TXT_PINCODE_LOCALITY": "GAMBHU",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384012,
      "TXT_PINCODE_LOCALITY": "RAMPURA (KUKAS)",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384110,
      "TXT_PINCODE_LOCALITY": "BALISANA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 888,
      "City District Name": "PATAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384111,
      "TXT_PINCODE_LOCALITY": "AMBASAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384112,
      "TXT_PINCODE_LOCALITY": "DER",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 888,
      "City District Name": "PATAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384120,
      "TXT_PINCODE_LOCALITY": "BHANDU",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384121,
      "TXT_PINCODE_LOCALITY": "DAUMOTI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384122,
      "TXT_PINCODE_LOCALITY": "JETALVASNA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384130,
      "TXT_PINCODE_LOCALITY": "KAHODA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384131,
      "TXT_PINCODE_LOCALITY": "BHUNAV",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384132,
      "TXT_PINCODE_LOCALITY": "LUNVA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384133,
      "TXT_PINCODE_LOCALITY": "MANDALI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384135,
      "TXT_PINCODE_LOCALITY": "VISHOL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384140,
      "TXT_PINCODE_LOCALITY": "KAMLI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384145,
      "TXT_PINCODE_LOCALITY": "ANVARPURA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 888,
      "City District Name": "PATAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384151,
      "TXT_PINCODE_LOCALITY": "JAM CHAKLA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384152,
      "TXT_PINCODE_LOCALITY": "DETHLI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384153,
      "TXT_PINCODE_LOCALITY": "MELOJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384154,
      "TXT_PINCODE_LOCALITY": "BILIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384160,
      "TXT_PINCODE_LOCALITY": "UNAVA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384165,
      "TXT_PINCODE_LOCALITY": "TUNDAV",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384170,
      "TXT_PINCODE_LOCALITY": "UNJHA H O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384171,
      "TXT_PINCODE_LOCALITY": "KARLI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384172,
      "TXT_PINCODE_LOCALITY": "DASAJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384174,
      "TXT_PINCODE_LOCALITY": "BHANKHER",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384175,
      "TXT_PINCODE_LOCALITY": "AITHOR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384176,
      "TXT_PINCODE_LOCALITY": "SIHI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384177,
      "TXT_PINCODE_LOCALITY": "UPERA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384178,
      "TXT_PINCODE_LOCALITY": "MAKTUPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384205,
      "TXT_PINCODE_LOCALITY": "PANCHOT",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384206,
      "TXT_PINCODE_LOCALITY": "PALODAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384207,
      "TXT_PINCODE_LOCALITY": "CHHATIARDA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384210,
      "TXT_PINCODE_LOCALITY": "DELMAL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384211,
      "TXT_PINCODE_LOCALITY": "SANKHALPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384212,
      "TXT_PINCODE_LOCALITY": "MODHERA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384213,
      "TXT_PINCODE_LOCALITY": "KALRI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384215,
      "TXT_PINCODE_LOCALITY": "BRAHMANWADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384220,
      "TXT_PINCODE_LOCALITY": "CHANASMA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 888,
      "City District Name": "PATAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384221,
      "TXT_PINCODE_LOCALITY": "VADAVLI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384222,
      "TXT_PINCODE_LOCALITY": "BRAHMANWADA (CHANASAMA)",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384225,
      "TXT_PINCODE_LOCALITY": "DHINOJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384226,
      "TXT_PINCODE_LOCALITY": "PINDHARPURA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384227,
      "TXT_PINCODE_LOCALITY": "SUNSAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384228,
      "TXT_PINCODE_LOCALITY": "GORAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384229,
      "TXT_PINCODE_LOCALITY": "LANVA S>O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 888,
      "City District Name": "PATAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384230,
      "TXT_PINCODE_LOCALITY": "KAMBOI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384231,
      "TXT_PINCODE_LOCALITY": "KALYANA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384235,
      "TXT_PINCODE_LOCALITY": "PALASAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384240,
      "TXT_PINCODE_LOCALITY": "HARIJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 888,
      "City District Name": "PATAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384241,
      "TXT_PINCODE_LOCALITY": "MUJPUR HARJI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 888,
      "City District Name": "PATAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384242,
      "TXT_PINCODE_LOCALITY": "LOLADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 888,
      "City District Name": "PATAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384245,
      "TXT_PINCODE_LOCALITY": "SAMI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 888,
      "City District Name": "PATAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384246,
      "TXT_PINCODE_LOCALITY": "SANKHESWAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 888,
      "City District Name": "PATAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384247,
      "TXT_PINCODE_LOCALITY": "DUDKHA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 888,
      "City District Name": "PATAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384250,
      "TXT_PINCODE_LOCALITY": "KANSA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 888,
      "City District Name": "PATAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384255,
      "TXT_PINCODE_LOCALITY": "KUNGHER",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 888,
      "City District Name": "PATAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384256,
      "TXT_PINCODE_LOCALITY": "CHANDRUMANA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 888,
      "City District Name": "PATAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384257,
      "TXT_PINCODE_LOCALITY": "ADIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 888,
      "City District Name": "PATAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384260,
      "TXT_PINCODE_LOCALITY": "MANUND",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 888,
      "City District Name": "PATAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384261,
      "TXT_PINCODE_LOCALITY": "MUDERDA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384265,
      "TXT_PINCODE_LOCALITY": "SIDHRAJ ROAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384270,
      "TXT_PINCODE_LOCALITY": "KUVARA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 888,
      "City District Name": "PATAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384271,
      "TXT_PINCODE_LOCALITY": "AGHAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 888,
      "City District Name": "PATAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384272,
      "TXT_PINCODE_LOCALITY": "SARIAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 888,
      "City District Name": "PATAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384273,
      "TXT_PINCODE_LOCALITY": "MESAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 888,
      "City District Name": "PATAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384274,
      "TXT_PINCODE_LOCALITY": "WAMAIYA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 888,
      "City District Name": "PATAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384275,
      "TXT_PINCODE_LOCALITY": "RANUJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 888,
      "City District Name": "PATAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384276,
      "TXT_PINCODE_LOCALITY": "SANDER",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 888,
      "City District Name": "PATAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384277,
      "TXT_PINCODE_LOCALITY": "SANKHARI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384278,
      "TXT_PINCODE_LOCALITY": "NAYTA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 888,
      "City District Name": "PATAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384280,
      "TXT_PINCODE_LOCALITY": "KANTHARAVI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384285,
      "TXT_PINCODE_LOCALITY": "WAGROD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384290,
      "TXT_PINCODE_LOCALITY": "SIDHPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 888,
      "City District Name": "PATAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384291,
      "TXT_PINCODE_LOCALITY": "DINDROI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384292,
      "TXT_PINCODE_LOCALITY": "METHAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384305,
      "TXT_PINCODE_LOCALITY": "KADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384310,
      "TXT_PINCODE_LOCALITY": "VALAM",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384311,
      "TXT_PINCODE_LOCALITY": "KHANDOSAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384312,
      "TXT_PINCODE_LOCALITY": "TARABH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384315,
      "TXT_PINCODE_LOCALITY": "VISNAGAR MARKET YARD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384318,
      "TXT_PINCODE_LOCALITY": "GOTHVA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384319,
      "TXT_PINCODE_LOCALITY": "BHALAK",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384320,
      "TXT_PINCODE_LOCALITY": "UMTA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384321,
      "TXT_PINCODE_LOCALITY": "JASKA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 888,
      "City District Name": "PATAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384323,
      "TXT_PINCODE_LOCALITY": "DENAP",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384325,
      "TXT_PINCODE_LOCALITY": "KHERALU",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384327,
      "TXT_PINCODE_LOCALITY": "DABHAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384328,
      "TXT_PINCODE_LOCALITY": "NO DELIVERY",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384329,
      "TXT_PINCODE_LOCALITY": "KODRAM",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384330,
      "TXT_PINCODE_LOCALITY": "SATLASANA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384335,
      "TXT_PINCODE_LOCALITY": "SIPOR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384340,
      "TXT_PINCODE_LOCALITY": "SUDASANA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384345,
      "TXT_PINCODE_LOCALITY": "SUNDHIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384350,
      "TXT_PINCODE_LOCALITY": "TARANGA HILL R S",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 888,
      "City District Name": "PATAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384355,
      "TXT_PINCODE_LOCALITY": "VAGNAGAR BAZAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384360,
      "TXT_PINCODE_LOCALITY": "DHAROI D S C",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384365,
      "TXT_PINCODE_LOCALITY": "CHHABLIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384370,
      "TXT_PINCODE_LOCALITY": "KAHIPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384375,
      "TXT_PINCODE_LOCALITY": "KAMANA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384380,
      "TXT_PINCODE_LOCALITY": "PILUDRA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384385,
      "TXT_PINCODE_LOCALITY": "GUNJA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384391,
      "TXT_PINCODE_LOCALITY": "KANSA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 888,
      "City District Name": "PATAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384410,
      "TXT_PINCODE_LOCALITY": "BALOL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384411,
      "TXT_PINCODE_LOCALITY": "MAGUNA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384412,
      "TXT_PINCODE_LOCALITY": "GAMBHU",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384415,
      "TXT_PINCODE_LOCALITY": "DODIWADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384421,
      "TXT_PINCODE_LOCALITY": "JHOTANA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384430,
      "TXT_PINCODE_LOCALITY": "SANTHAL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384433,
      "TXT_PINCODE_LOCALITY": "AMBASAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384435,
      "TXT_PINCODE_LOCALITY": "LINCH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384440,
      "TXT_PINCODE_LOCALITY": "LAKHVAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384441,
      "TXT_PINCODE_LOCALITY": "BORISANA KADI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384445,
      "TXT_PINCODE_LOCALITY": "DARAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384450,
      "TXT_PINCODE_LOCALITY": "BHATASAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384455,
      "TXT_PINCODE_LOCALITY": "JORNANG",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384460,
      "TXT_PINCODE_LOCALITY": "DEVRASAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384465,
      "TXT_PINCODE_LOCALITY": "MEU",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384470,
      "TXT_PINCODE_LOCALITY": "GOZARIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384513,
      "TXT_PINCODE_LOCALITY": "DABHI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384515,
      "TXT_PINCODE_LOCALITY": "MALOSAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384520,
      "TXT_PINCODE_LOCALITY": "PAMOL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384530,
      "TXT_PINCODE_LOCALITY": "DAGAVADIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384540,
      "TXT_PINCODE_LOCALITY": "KHAROD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384550,
      "TXT_PINCODE_LOCALITY": "KHANUSA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384560,
      "TXT_PINCODE_LOCALITY": "SUNDARPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384565,
      "TXT_PINCODE_LOCALITY": "DABHALA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384570,
      "TXT_PINCODE_LOCALITY": "BHAVSOR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 384585,
      "TXT_PINCODE_LOCALITY": "CHARUP",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 888,
      "City District Name": "PATAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385001,
      "TXT_PINCODE_LOCALITY": "PALANPUR JORAVAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1085,
      "City District Name": "BANASKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385002,
      "TXT_PINCODE_LOCALITY": "PALACE",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385010,
      "TXT_PINCODE_LOCALITY": "CHITRASANI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385011,
      "TXT_PINCODE_LOCALITY": "JAGANA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385012,
      "TXT_PINCODE_LOCALITY": "VIRAMPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385110,
      "TXT_PINCODE_LOCALITY": "AMBAJI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385111,
      "TXT_PINCODE_LOCALITY": "CHADOTAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385112,
      "TXT_PINCODE_LOCALITY": "MALAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385120,
      "TXT_PINCODE_LOCALITY": "DANTA BHAVANGADH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385130,
      "TXT_PINCODE_LOCALITY": "SHRI AMIRGADH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385135,
      "TXT_PINCODE_LOCALITY": "KAPASIYA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1085,
      "City District Name": "BANASKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385152,
      "TXT_PINCODE_LOCALITY": "BAMANVA S. O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385191,
      "TXT_PINCODE_LOCALITY": "MOTA PONDHA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385210,
      "TXT_PINCODE_LOCALITY": "CHHAPI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1073,
      "City District Name": "SABARKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385215,
      "TXT_PINCODE_LOCALITY": "MAJADAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385310,
      "TXT_PINCODE_LOCALITY": "DHANERA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385315,
      "TXT_PINCODE_LOCALITY": "RAMSAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385320,
      "TXT_PINCODE_LOCALITY": "BHABHAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385325,
      "TXT_PINCODE_LOCALITY": "TERVADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385326,
      "TXT_PINCODE_LOCALITY": "MORVADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385330,
      "TXT_PINCODE_LOCALITY": "DIYODAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385340,
      "TXT_PINCODE_LOCALITY": "RANGADHI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1085,
      "City District Name": "BANASKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385350,
      "TXT_PINCODE_LOCALITY": "SANTALPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 888,
      "City District Name": "PATAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385360,
      "TXT_PINCODE_LOCALITY": "VARAHI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 888,
      "City District Name": "PATAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385410,
      "TXT_PINCODE_LOCALITY": "VADGAM",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385420,
      "TXT_PINCODE_LOCALITY": "AHMEDABAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385421,
      "TXT_PINCODE_LOCALITY": "MAMADPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1085,
      "City District Name": "BANASKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385505,
      "TXT_PINCODE_LOCALITY": "DANTIWADA COLONY",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385506,
      "TXT_PINCODE_LOCALITY": "SARDAR KRISHINAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1085,
      "City District Name": "BANASKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385510,
      "TXT_PINCODE_LOCALITY": "CHANDISAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385515,
      "TXT_PINCODE_LOCALITY": "GADH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385516,
      "TXT_PINCODE_LOCALITY": "SAMDHI RANAJIVAS",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385517,
      "TXT_PINCODE_LOCALITY": "SASAM",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385518,
      "TXT_PINCODE_LOCALITY": "KUMBHASAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385519,
      "TXT_PINCODE_LOCALITY": "MADANA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385520,
      "TXT_PINCODE_LOCALITY": "KANODAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385521,
      "TXT_PINCODE_LOCALITY": "VEDANCHA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385522,
      "TXT_PINCODE_LOCALITY": "RANER",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385525,
      "TXT_PINCODE_LOCALITY": "META",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385526,
      "TXT_PINCODE_LOCALITY": "BASU",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385530,
      "TXT_PINCODE_LOCALITY": "BHILDI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385531,
      "TXT_PINCODE_LOCALITY": "MADETHA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1085,
      "City District Name": "BANASKANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385533,
      "TXT_PINCODE_LOCALITY": "KAMBOI BK",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385535,
      "TXT_PINCODE_LOCALITY": "DISA H O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385540,
      "TXT_PINCODE_LOCALITY": "JUNA DISA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385541,
      "TXT_PINCODE_LOCALITY": "LAKHANI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385542,
      "TXT_PINCODE_LOCALITY": "DHUVA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385543,
      "TXT_PINCODE_LOCALITY": "BHADATH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385545,
      "TXT_PINCODE_LOCALITY": "KHIMAT",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385546,
      "TXT_PINCODE_LOCALITY": "PANTHAVADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385550,
      "TXT_PINCODE_LOCALITY": "SIHORI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385551,
      "TXT_PINCODE_LOCALITY": "UMBARI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385554,
      "TXT_PINCODE_LOCALITY": "KHIMANA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385555,
      "TXT_PINCODE_LOCALITY": "THARA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385560,
      "TXT_PINCODE_LOCALITY": "UN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385565,
      "TXT_PINCODE_LOCALITY": "THARAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385566,
      "TXT_PINCODE_LOCALITY": "DHIMA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385570,
      "TXT_PINCODE_LOCALITY": "SURGAM",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385571,
      "TXT_PINCODE_LOCALITY": "BENAP",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385575,
      "TXT_PINCODE_LOCALITY": "VAV",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385580,
      "TXT_PINCODE_LOCALITY": "JHERDA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385585,
      "TXT_PINCODE_LOCALITY": "MALGADH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385586,
      "TXT_PINCODE_LOCALITY": "AGATHALA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385590,
      "TXT_PINCODE_LOCALITY": "VADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385595,
      "TXT_PINCODE_LOCALITY": "CHEKHLA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 385765,
      "TXT_PINCODE_LOCALITY": "SURENDRANAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 386070,
      "TXT_PINCODE_LOCALITY": "AHMEDABAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 386216,
      "TXT_PINCODE_LOCALITY": "AHMEDABAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387001,
      "TXT_PINCODE_LOCALITY": "ASHRAM ROAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387002,
      "TXT_PINCODE_LOCALITY": "VAISHALI CINEMA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387003,
      "TXT_PINCODE_LOCALITY": "KHEDA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387110,
      "TXT_PINCODE_LOCALITY": "GHODASAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387111,
      "TXT_PINCODE_LOCALITY": "JARAVAT",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387113,
      "TXT_PINCODE_LOCALITY": "KAILASH HINDU LODGE",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387115,
      "TXT_PINCODE_LOCALITY": "ALINDRA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387120,
      "TXT_PINCODE_LOCALITY": "KANIJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387130,
      "TXT_PINCODE_LOCALITY": "KHATRAJGATE",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387135,
      "TXT_PINCODE_LOCALITY": "MODAJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387140,
      "TXT_PINCODE_LOCALITY": "HALDARWAS",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387210,
      "TXT_PINCODE_LOCALITY": "DABHOW",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387220,
      "TXT_PINCODE_LOCALITY": "MALATAJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387230,
      "TXT_PINCODE_LOCALITY": "PIJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387240,
      "TXT_PINCODE_LOCALITY": "SOJITRA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387250,
      "TXT_PINCODE_LOCALITY": "SHEKHUPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 425719,
      "City District Name": "MATAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387305,
      "TXT_PINCODE_LOCALITY": "ALINA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387310,
      "TXT_PINCODE_LOCALITY": "BORIYAVI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387315,
      "TXT_PINCODE_LOCALITY": "CHAKLASI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387320,
      "TXT_PINCODE_LOCALITY": "DABHAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387325,
      "TXT_PINCODE_LOCALITY": "KANJRI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387330,
      "TXT_PINCODE_LOCALITY": "MOHLEL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387331,
      "TXT_PINCODE_LOCALITY": "SODPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387335,
      "TXT_PINCODE_LOCALITY": "MAHUDHA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387340,
      "TXT_PINCODE_LOCALITY": "MAHISA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387345,
      "TXT_PINCODE_LOCALITY": "NARSANDA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387350,
      "TXT_PINCODE_LOCALITY": "PALANA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387355,
      "TXT_PINCODE_LOCALITY": "PIPLAG",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387360,
      "TXT_PINCODE_LOCALITY": "SALUN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387365,
      "TXT_PINCODE_LOCALITY": "TORNA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387366,
      "TXT_PINCODE_LOCALITY": "ANTROLI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387370,
      "TXT_PINCODE_LOCALITY": "UTTARSANDA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387375,
      "TXT_PINCODE_LOCALITY": "PETLAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387380,
      "TXT_PINCODE_LOCALITY": "VASO",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387382,
      "TXT_PINCODE_LOCALITY": "NADIAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4652,
      "City District Name": "NADIAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387385,
      "TXT_PINCODE_LOCALITY": "RUDAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387411,
      "TXT_PINCODE_LOCALITY": "KHEDA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387421,
      "TXT_PINCODE_LOCALITY": "KHEDA CAMP",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387430,
      "TXT_PINCODE_LOCALITY": "SINHUJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387435,
      "TXT_PINCODE_LOCALITY": "VANTHVALI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387501,
      "TXT_PINCODE_LOCALITY": "ATTARSUMBA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387510,
      "TXT_PINCODE_LOCALITY": "ALINDRA (MATAR)",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387515,
      "TXT_PINCODE_LOCALITY": "VANSOL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387520,
      "TXT_PINCODE_LOCALITY": "LIMBASI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387530,
      "TXT_PINCODE_LOCALITY": "MATAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387531,
      "TXT_PINCODE_LOCALITY": "TRAJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387534,
      "TXT_PINCODE_LOCALITY": "TRAJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 425719,
      "City District Name": "MATAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387540,
      "TXT_PINCODE_LOCALITY": "NAVAGAM",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387550,
      "TXT_PINCODE_LOCALITY": "NAYKA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 888,
      "City District Name": "PATAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387560,
      "TXT_PINCODE_LOCALITY": "RADHU",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387570,
      "TXT_PINCODE_LOCALITY": "RATANPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387610,
      "TXT_PINCODE_LOCALITY": "ATTARSUMBA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387620,
      "TXT_PINCODE_LOCALITY": "KAPADWANJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387630,
      "TXT_PINCODE_LOCALITY": "KATHLAL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387635,
      "TXT_PINCODE_LOCALITY": "CHHIPADI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387640,
      "TXT_PINCODE_LOCALITY": "LASUNDRA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387650,
      "TXT_PINCODE_LOCALITY": "NIRMALI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387651,
      "TXT_PINCODE_LOCALITY": "JHER",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387655,
      "TXT_PINCODE_LOCALITY": "ANTISAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387660,
      "TXT_PINCODE_LOCALITY": "BETWADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387710,
      "TXT_PINCODE_LOCALITY": "BORSAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 387810,
      "TXT_PINCODE_LOCALITY": "DHOLKA KHARAKUVA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388001,
      "TXT_PINCODE_LOCALITY": "RAMNAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388105,
      "TXT_PINCODE_LOCALITY": "PORDA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388110,
      "TXT_PINCODE_LOCALITY": "AGRI INST ANAND",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388120,
      "TXT_PINCODE_LOCALITY": "V V NAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388121,
      "TXT_PINCODE_LOCALITY": "VITHAL UDYOGNAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388122,
      "TXT_PINCODE_LOCALITY": "NADIAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4652,
      "City District Name": "NADIAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388130,
      "TXT_PINCODE_LOCALITY": "DHUNDAKUVA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388131,
      "TXT_PINCODE_LOCALITY": "ANAND",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388132,
      "TXT_PINCODE_LOCALITY": "SANDESAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388140,
      "TXT_PINCODE_LOCALITY": "BOCHASAN R S",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388150,
      "TXT_PINCODE_LOCALITY": "NAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388160,
      "TXT_PINCODE_LOCALITY": "PANDOLI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388170,
      "TXT_PINCODE_LOCALITY": "SAYMA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388180,
      "TXT_PINCODE_LOCALITY": "TARAPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388205,
      "TXT_PINCODE_LOCALITY": "BHALEJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388210,
      "TXT_PINCODE_LOCALITY": "ODE",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388211,
      "TXT_PINCODE_LOCALITY": "ANAND",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388212,
      "TXT_PINCODE_LOCALITY": "SUNDALPURA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388213,
      "TXT_PINCODE_LOCALITY": "SHILI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388215,
      "TXT_PINCODE_LOCALITY": "THAMNA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388216,
      "TXT_PINCODE_LOCALITY": "KHANKUVA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388217,
      "TXT_PINCODE_LOCALITY": "PADAL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388220,
      "TXT_PINCODE_LOCALITY": "UMRETH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388225,
      "TXT_PINCODE_LOCALITY": "TOWN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388226,
      "TXT_PINCODE_LOCALITY": "VALASAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388230,
      "TXT_PINCODE_LOCALITY": "KALSAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388235,
      "TXT_PINCODE_LOCALITY": "MENPURA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388236,
      "TXT_PINCODE_LOCALITY": "WANAKBORI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388238,
      "TXT_PINCODE_LOCALITY": "WANGHROLI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388239,
      "TXT_PINCODE_LOCALITY": "T P S",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388240,
      "TXT_PINCODE_LOCALITY": "SAVALIA C W",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388245,
      "TXT_PINCODE_LOCALITY": "SEVALIA R S",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388250,
      "TXT_PINCODE_LOCALITY": "THASRA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388254,
      "TXT_PINCODE_LOCALITY": "NEPALPURA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388255,
      "TXT_PINCODE_LOCALITY": "VADASINOR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388256,
      "TXT_PINCODE_LOCALITY": "SALIAWADI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388257,
      "TXT_PINCODE_LOCALITY": "OTHWAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388258,
      "TXT_PINCODE_LOCALITY": "VADDLA VADASINOR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388259,
      "TXT_PINCODE_LOCALITY": "BHANTHLA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388260,
      "TXT_PINCODE_LOCALITY": "BIRPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388261,
      "TXT_PINCODE_LOCALITY": "DEBHARI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388262,
      "TXT_PINCODE_LOCALITY": "SARADIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388265,
      "TXT_PINCODE_LOCALITY": "PANDVA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388266,
      "TXT_PINCODE_LOCALITY": "JETHOLI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388267,
      "TXT_PINCODE_LOCALITY": "BAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388270,
      "TXT_PINCODE_LOCALITY": "VARDHARI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388275,
      "TXT_PINCODE_LOCALITY": "VADADALA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1445,
      "City District Name": "PETLAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388305,
      "TXT_PINCODE_LOCALITY": "ADAS",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388306,
      "TXT_PINCODE_LOCALITY": "VASAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388307,
      "TXT_PINCODE_LOCALITY": "KANTHARIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388308,
      "TXT_PINCODE_LOCALITY": "ANAND",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388310,
      "TXT_PINCODE_LOCALITY": "AJARPURA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388315,
      "TXT_PINCODE_LOCALITY": "BAKROL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388320,
      "TXT_PINCODE_LOCALITY": "CHIKHODRA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388325,
      "TXT_PINCODE_LOCALITY": "KHEDA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388327,
      "TXT_PINCODE_LOCALITY": "PORDA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388330,
      "TXT_PINCODE_LOCALITY": "KHAMBHOLAJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388335,
      "TXT_PINCODE_LOCALITY": "KUNJEAV",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388336,
      "TXT_PINCODE_LOCALITY": "RASNOL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388340,
      "TXT_PINCODE_LOCALITY": "MOGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388345,
      "TXT_PINCODE_LOCALITY": "MOGRI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388350,
      "TXT_PINCODE_LOCALITY": "NAPAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388355,
      "TXT_PINCODE_LOCALITY": "NAVLI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388360,
      "TXT_PINCODE_LOCALITY": "SAMARKHA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388365,
      "TXT_PINCODE_LOCALITY": "SARSA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388370,
      "TXT_PINCODE_LOCALITY": "VADOD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388390,
      "TXT_PINCODE_LOCALITY": "JHAROLA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388410,
      "TXT_PINCODE_LOCALITY": "BANDHANI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388421,
      "TXT_PINCODE_LOCALITY": "CHANGA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388425,
      "TXT_PINCODE_LOCALITY": "ANAND",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388430,
      "TXT_PINCODE_LOCALITY": "DHARMAJI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388435,
      "TXT_PINCODE_LOCALITY": "VADADLA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388440,
      "TXT_PINCODE_LOCALITY": "MEHLAV",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388445,
      "TXT_PINCODE_LOCALITY": "MOGRI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388450,
      "TXT_PINCODE_LOCALITY": "ZANDA BAZAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388460,
      "TXT_PINCODE_LOCALITY": "PIPLAV",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388461,
      "TXT_PINCODE_LOCALITY": "KASOR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388465,
      "TXT_PINCODE_LOCALITY": "PALAJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388466,
      "TXT_PINCODE_LOCALITY": "CHAROTAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388470,
      "TXT_PINCODE_LOCALITY": "SUNAV",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388480,
      "TXT_PINCODE_LOCALITY": "SUNDARNA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388485,
      "TXT_PINCODE_LOCALITY": "SIMARDA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388510,
      "TXT_PINCODE_LOCALITY": "ANKLAVE",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388515,
      "TXT_PINCODE_LOCALITY": "UMETA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388520,
      "TXT_PINCODE_LOCALITY": "BAMANGAM",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388530,
      "TXT_PINCODE_LOCALITY": "BHADRAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388531,
      "TXT_PINCODE_LOCALITY": "SAROL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388535,
      "TXT_PINCODE_LOCALITY": "BHADATH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388540,
      "TXT_PINCODE_LOCALITY": "GANDHI GANJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388543,
      "TXT_PINCODE_LOCALITY": "ALARSA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388544,
      "TXT_PINCODE_LOCALITY": "ANAND",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388545,
      "TXT_PINCODE_LOCALITY": "KAVITHA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388550,
      "TXT_PINCODE_LOCALITY": "KATHANA R S",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388555,
      "TXT_PINCODE_LOCALITY": "ANAND",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388556,
      "TXT_PINCODE_LOCALITY": "DEHVAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388560,
      "TXT_PINCODE_LOCALITY": "NAPA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388570,
      "TXT_PINCODE_LOCALITY": "RAS",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388580,
      "TXT_PINCODE_LOCALITY": "VIRSAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388585,
      "TXT_PINCODE_LOCALITY": "VATADRA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388590,
      "TXT_PINCODE_LOCALITY": "CHUVA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1379,
      "City District Name": "BORSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388610,
      "TXT_PINCODE_LOCALITY": "DHUVARAN R S",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388620,
      "TXT_PINCODE_LOCALITY": "KHAMBHAT",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388625,
      "TXT_PINCODE_LOCALITY": "GUDEL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388630,
      "TXT_PINCODE_LOCALITY": "KANSARI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388640,
      "TXT_PINCODE_LOCALITY": "KALAMSAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388641,
      "TXT_PINCODE_LOCALITY": "ANAND",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388710,
      "TXT_PINCODE_LOCALITY": "GOTHADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388711,
      "TXT_PINCODE_LOCALITY": "TIMBA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388712,
      "TXT_PINCODE_LOCALITY": "NADISAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388713,
      "TXT_PINCODE_LOCALITY": "KAKANPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388714,
      "TXT_PINCODE_LOCALITY": "MORVA RENA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388715,
      "TXT_PINCODE_LOCALITY": "RATANPUR KANTDI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 174,
      "City District Name": "ANAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 388716,
      "TXT_PINCODE_LOCALITY": "MOTI KANTDI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389001,
      "TXT_PINCODE_LOCALITY": "VORVAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389002,
      "TXT_PINCODE_LOCALITY": "GODHRA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389110,
      "TXT_PINCODE_LOCALITY": "MORA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389111,
      "TXT_PINCODE_LOCALITY": "LIMDI DOLI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389115,
      "TXT_PINCODE_LOCALITY": "MORVA HADAF S.O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389120,
      "TXT_PINCODE_LOCALITY": "SANT ROAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389121,
      "TXT_PINCODE_LOCALITY": "NATAPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389122,
      "TXT_PINCODE_LOCALITY": "ORVADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389123,
      "TXT_PINCODE_LOCALITY": "MOJRI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389130,
      "TXT_PINCODE_LOCALITY": "PIPLOD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389135,
      "TXT_PINCODE_LOCALITY": "MOTI ZARI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389140,
      "TXT_PINCODE_LOCALITY": "LIMKHEDA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3048,
      "City District Name": "DOHAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389145,
      "TXT_PINCODE_LOCALITY": "KADWAL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3048,
      "City District Name": "DOHAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389146,
      "TXT_PINCODE_LOCALITY": "DUDHIA S.O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 675,
      "City District Name": "DAHOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389151,
      "TXT_PINCODE_LOCALITY": "DAHOD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 190,
      "City District Name": "BARODA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389152,
      "TXT_PINCODE_LOCALITY": "RAMDUNGARA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3048,
      "City District Name": "DOHAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389154,
      "TXT_PINCODE_LOCALITY": "MOTI LACHHELI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3048,
      "City District Name": "DOHAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389155,
      "TXT_PINCODE_LOCALITY": "GARBADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3048,
      "City District Name": "DOHAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389156,
      "TXT_PINCODE_LOCALITY": "ABHLOD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3048,
      "City District Name": "DOHAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389157,
      "TXT_PINCODE_LOCALITY": "BAVKA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3048,
      "City District Name": "DOHAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389158,
      "TXT_PINCODE_LOCALITY": "MOTI KHARAJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3048,
      "City District Name": "DOHAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389160,
      "TXT_PINCODE_LOCALITY": "FREELAND GANJ DAHOD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3048,
      "City District Name": "DOHAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389161,
      "TXT_PINCODE_LOCALITY": "BORIALA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3048,
      "City District Name": "DOHAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389170,
      "TXT_PINCODE_LOCALITY": "JHALOD - 5153",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3048,
      "City District Name": "DOHAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389172,
      "TXT_PINCODE_LOCALITY": "FATEPURA (PM)",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389173,
      "TXT_PINCODE_LOCALITY": "GARADU",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3048,
      "City District Name": "DOHAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389175,
      "TXT_PINCODE_LOCALITY": "SANJELI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3048,
      "City District Name": "DOHAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389176,
      "TXT_PINCODE_LOCALITY": "HIROLA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3048,
      "City District Name": "DOHAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389180,
      "TXT_PINCODE_LOCALITY": "LIMDI PM",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3048,
      "City District Name": "DOHAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389181,
      "TXT_PINCODE_LOCALITY": "KARATH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3048,
      "City District Name": "DOHAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389190,
      "TXT_PINCODE_LOCALITY": "SUKHSAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389210,
      "TXT_PINCODE_LOCALITY": "SHEHERA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389211,
      "TXT_PINCODE_LOCALITY": "MANGALIANA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389212,
      "TXT_PINCODE_LOCALITY": "SURELI SHEHERU",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389213,
      "TXT_PINCODE_LOCALITY": "PANCHMAHAL.",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389220,
      "TXT_PINCODE_LOCALITY": "LADVEL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389221,
      "TXT_PINCODE_LOCALITY": "KHAROL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389230,
      "TXT_PINCODE_LOCALITY": "LUNAWADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389232,
      "TXT_PINCODE_LOCALITY": "BAKOR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389235,
      "TXT_PINCODE_LOCALITY": "LIMADIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389240,
      "TXT_PINCODE_LOCALITY": "KADANA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389245,
      "TXT_PINCODE_LOCALITY": "VADAGAM PM",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389250,
      "TXT_PINCODE_LOCALITY": "DIWADA COLONY",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389260,
      "TXT_PINCODE_LOCALITY": "SANTRAMPUR B.O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 442070,
      "City District Name": "MAHISAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389265,
      "TXT_PINCODE_LOCALITY": "MALVAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389270,
      "TXT_PINCODE_LOCALITY": "VIRANIA S. O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389310,
      "TXT_PINCODE_LOCALITY": "DEIOL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389311,
      "TXT_PINCODE_LOCALITY": "BEDHIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389312,
      "TXT_PINCODE_LOCALITY": "MALAV",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389313,
      "TXT_PINCODE_LOCALITY": "ALWA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389314,
      "TXT_PINCODE_LOCALITY": "ADADRA *",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389315,
      "TXT_PINCODE_LOCALITY": "GOLLAV",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389320,
      "TXT_PINCODE_LOCALITY": "DEROL R S",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389321,
      "TXT_PINCODE_LOCALITY": "SANSOLI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389322,
      "TXT_PINCODE_LOCALITY": "DEROL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389323,
      "TXT_PINCODE_LOCALITY": "PINGLI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389325,
      "TXT_PINCODE_LOCALITY": "JANTRAL PM",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389330,
      "TXT_PINCODE_LOCALITY": "INDUSTRIAL ESTATE",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389340,
      "TXT_PINCODE_LOCALITY": "VEJALPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389341,
      "TXT_PINCODE_LOCALITY": "ADADRA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389342,
      "TXT_PINCODE_LOCALITY": "ERAL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389343,
      "TXT_PINCODE_LOCALITY": "MEHLOL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389344,
      "TXT_PINCODE_LOCALITY": "KAROLI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389345,
      "TXT_PINCODE_LOCALITY": "BORU BAKROL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389346,
      "TXT_PINCODE_LOCALITY": "VYASDA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389347,
      "TXT_PINCODE_LOCALITY": "SURELI VEJALPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389348,
      "TXT_PINCODE_LOCALITY": "AMBALI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389350,
      "TXT_PINCODE_LOCALITY": "PANCHAMAHALS",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 677,
      "City District Name": "HALOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389351,
      "TXT_PINCODE_LOCALITY": "KANJRI PM",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389352,
      "TXT_PINCODE_LOCALITY": "BASKA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389355,
      "TXT_PINCODE_LOCALITY": "RANJITNAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389360,
      "TXT_PINCODE_LOCALITY": "PAVAGADH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3048,
      "City District Name": "DOHAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389365,
      "TXT_PINCODE_LOCALITY": "GHOGAMBA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389370,
      "TXT_PINCODE_LOCALITY": "SHIVRAJPUR MINES",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389380,
      "TXT_PINCODE_LOCALITY": "DEVGADH BARIYA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3048,
      "City District Name": "DOHAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389382,
      "TXT_PINCODE_LOCALITY": "DHANPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3048,
      "City District Name": "DOHAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389390,
      "TXT_PINCODE_LOCALITY": "JAMBUGHODA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389395,
      "TXT_PINCODE_LOCALITY": "ANTELA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389710,
      "TXT_PINCODE_LOCALITY": "JUNIVADI S. O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 389752,
      "TXT_PINCODE_LOCALITY": "DAHOD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 675,
      "City District Name": "DAHOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 390001,
      "TXT_PINCODE_LOCALITY": "MANDVI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 390002,
      "TXT_PINCODE_LOCALITY": "FATEGANJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 390003,
      "TXT_PINCODE_LOCALITY": "VADODARA CHEMICAL INDUSTR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 390004,
      "TXT_PINCODE_LOCALITY": "VADODARA PRATAPNAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 390005,
      "TXT_PINCODE_LOCALITY": "ALKAPURI GHENDA CIRCLE",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 390006,
      "TXT_PINCODE_LOCALITY": "HARNI AERODRAME",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 390007,
      "TXT_PINCODE_LOCALITY": "VADODARA RACE COURSE",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 390008,
      "TXT_PINCODE_LOCALITY": "VADODARA E M E SCHOOL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 390009,
      "TXT_PINCODE_LOCALITY": "BARODA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 190,
      "City District Name": "BARODA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 390010,
      "TXT_PINCODE_LOCALITY": "NANDESARI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 390011,
      "TXT_PINCODE_LOCALITY": "VADODARA MANJALPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 390012,
      "TXT_PINCODE_LOCALITY": "VADODARA ATLARDRA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 390013,
      "TXT_PINCODE_LOCALITY": "VADODARA MANEJA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 390014,
      "TXT_PINCODE_LOCALITY": "VADODARA MAKARPURA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 390015,
      "TXT_PINCODE_LOCALITY": "VADODARA VIDYUTNAGAR COLO",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 390016,
      "TXT_PINCODE_LOCALITY": "GORVA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 190,
      "City District Name": "BARODA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 390017,
      "TXT_PINCODE_LOCALITY": "VADODARA WADI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 390018,
      "TXT_PINCODE_LOCALITY": "I D HOSPITAL ROAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 390019,
      "TXT_PINCODE_LOCALITY": "VADODARA AJWA ROAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 390020,
      "TXT_PINCODE_LOCALITY": "TANDALJA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 390021,
      "TXT_PINCODE_LOCALITY": "BARODA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 190,
      "City District Name": "BARODA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 390022,
      "TXT_PINCODE_LOCALITY": "CIVIL AIRPORT HARVI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 390023,
      "TXT_PINCODE_LOCALITY": "SUBHANPURA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 390024,
      "TXT_PINCODE_LOCALITY": "CHHANI RD S.O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 390025,
      "TXT_PINCODE_LOCALITY": "SOMA TALAV S.O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 390026,
      "TXT_PINCODE_LOCALITY": "VADODARA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 390044,
      "TXT_PINCODE_LOCALITY": "HARNI ROAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 390051,
      "TXT_PINCODE_LOCALITY": "NINAT",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391005,
      "TXT_PINCODE_LOCALITY": "CHANDOD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391101,
      "TXT_PINCODE_LOCALITY": "SEWASI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391105,
      "TXT_PINCODE_LOCALITY": "CHANDKHEDA SOCIETY",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391107,
      "TXT_PINCODE_LOCALITY": "THUVAVI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391108,
      "TXT_PINCODE_LOCALITY": "FURTIKUI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391110,
      "TXT_PINCODE_LOCALITY": "KAUMUDI SOCIETY",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391111,
      "TXT_PINCODE_LOCALITY": "SATHOD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391115,
      "TXT_PINCODE_LOCALITY": "SINOR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391116,
      "TXT_PINCODE_LOCALITY": "SISODRA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424442,
      "City District Name": "NARMADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391120,
      "TXT_PINCODE_LOCALITY": "TILAKWADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424442,
      "City District Name": "NARMADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391121,
      "TXT_PINCODE_LOCALITY": "VYDHAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424442,
      "City District Name": "NARMADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391125,
      "TXT_PINCODE_LOCALITY": "BHADARPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424442,
      "City District Name": "NARMADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391126,
      "TXT_PINCODE_LOCALITY": "MANKNI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424442,
      "City District Name": "NARMADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391130,
      "TXT_PINCODE_LOCALITY": "BHATPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391134,
      "TXT_PINCODE_LOCALITY": "VADODARA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391135,
      "TXT_PINCODE_LOCALITY": "BODELI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391136,
      "TXT_PINCODE_LOCALITY": "KARALI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391137,
      "TXT_PINCODE_LOCALITY": "NANA AMODRA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391140,
      "TXT_PINCODE_LOCALITY": "KOSINDRA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391141,
      "TXT_PINCODE_LOCALITY": "CHALAMALI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391142,
      "TXT_PINCODE_LOCALITY": "KADACHHALA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391145,
      "TXT_PINCODE_LOCALITY": "SANKHEDA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391146,
      "TXT_PINCODE_LOCALITY": "PARVATA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391150,
      "TXT_PINCODE_LOCALITY": "NASWADI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391151,
      "TXT_PINCODE_LOCALITY": "TANAKHALA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391152,
      "TXT_PINCODE_LOCALITY": "GAD BORIAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391155,
      "TXT_PINCODE_LOCALITY": "JABUGAM",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391156,
      "TXT_PINCODE_LOCALITY": "TEJGADH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391157,
      "TXT_PINCODE_LOCALITY": "MOTI AMROLI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391158,
      "TXT_PINCODE_LOCALITY": "ALWA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391160,
      "TXT_PINCODE_LOCALITY": "PAVIJETPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391165,
      "TXT_PINCODE_LOCALITY": "MAHISAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 2771,
      "City District Name": "CHHOTA UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391168,
      "TXT_PINCODE_LOCALITY": "PANVAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391169,
      "TXT_PINCODE_LOCALITY": "RANGPUR Z",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391170,
      "TXT_PINCODE_LOCALITY": "KAWANT S.O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 2771,
      "City District Name": "CHHOTA UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391175,
      "TXT_PINCODE_LOCALITY": "KADIPANI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391210,
      "TXT_PINCODE_LOCALITY": "KANDARI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391220,
      "TXT_PINCODE_LOCALITY": "KAYAVAROHAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391230,
      "TXT_PINCODE_LOCALITY": "MANDALA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391240,
      "TXT_PINCODE_LOCALITY": "MIYAGAM KARJAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391241,
      "TXT_PINCODE_LOCALITY": "SARAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391242,
      "TXT_PINCODE_LOCALITY": "VARNAMA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391243,
      "TXT_PINCODE_LOCALITY": "POR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391244,
      "TXT_PINCODE_LOCALITY": "CHORANDA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391245,
      "TXT_PINCODE_LOCALITY": "MIYAGAM",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391247,
      "TXT_PINCODE_LOCALITY": "RANOLI VILLAGE",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391250,
      "TXT_PINCODE_LOCALITY": "SADHLI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391260,
      "TXT_PINCODE_LOCALITY": "VAGHODIA INA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391310,
      "TXT_PINCODE_LOCALITY": "BAJWA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391320,
      "TXT_PINCODE_LOCALITY": "SOUTHERN TOWNSHIP",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391330,
      "TXT_PINCODE_LOCALITY": "KOYLI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391335,
      "TXT_PINCODE_LOCALITY": "ANGADH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391340,
      "TXT_PINCODE_LOCALITY": "NANDESSRI - 1643",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391345,
      "TXT_PINCODE_LOCALITY": "PETROCHEMICAL TOWNSHIP",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391346,
      "TXT_PINCODE_LOCALITY": "PETROCHEMICALS",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391347,
      "TXT_PINCODE_LOCALITY": "PETROFILS",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391350,
      "TXT_PINCODE_LOCALITY": "RANOLI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391357,
      "TXT_PINCODE_LOCALITY": "DASHRATH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391410,
      "TXT_PINCODE_LOCALITY": "BHAILI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391421,
      "TXT_PINCODE_LOCALITY": "PADRA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391430,
      "TXT_PINCODE_LOCALITY": "MOBHU ROAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391440,
      "TXT_PINCODE_LOCALITY": "PADRA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391441,
      "TXT_PINCODE_LOCALITY": "DABKA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391442,
      "TXT_PINCODE_LOCALITY": "MUJPUR PADRA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391443,
      "TXT_PINCODE_LOCALITY": "CHOKARI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391445,
      "TXT_PINCODE_LOCALITY": "RANU",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391450,
      "TXT_PINCODE_LOCALITY": "KARAKHADI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391460,
      "TXT_PINCODE_LOCALITY": "VADU",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391510,
      "TXT_PINCODE_LOCALITY": "JAROD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391511,
      "TXT_PINCODE_LOCALITY": "VYARA ANTOLI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391512,
      "TXT_PINCODE_LOCALITY": "KOTAMBI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391520,
      "TXT_PINCODE_LOCALITY": "SAMLAYA R S",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391523,
      "TXT_PINCODE_LOCALITY": "KHEDA S. O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 679,
      "City District Name": "KHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391530,
      "TXT_PINCODE_LOCALITY": "SANDASAL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391531,
      "TXT_PINCODE_LOCALITY": "RAJUPURA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391535,
      "TXT_PINCODE_LOCALITY": "VEJPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391540,
      "TXT_PINCODE_LOCALITY": "PANDU",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391740,
      "TXT_PINCODE_LOCALITY": "CHHANI S.O (VADODARA)",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391745,
      "TXT_PINCODE_LOCALITY": "SOKHADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391750,
      "TXT_PINCODE_LOCALITY": "COLONY",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391760,
      "TXT_PINCODE_LOCALITY": "LIMDA B.O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391761,
      "TXT_PINCODE_LOCALITY": "CHHOTAUDEPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391764,
      "TXT_PINCODE_LOCALITY": "RUSTAMAPURA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391765,
      "TXT_PINCODE_LOCALITY": "GORAJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1867,
      "City District Name": "PANCHMAHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391770,
      "TXT_PINCODE_LOCALITY": "SAVLI TOWN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391773,
      "TXT_PINCODE_LOCALITY": "GOTHDA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391774,
      "TXT_PINCODE_LOCALITY": "DESAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391775,
      "TXT_PINCODE_LOCALITY": "TUNDAV SAVLI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391776,
      "TXT_PINCODE_LOCALITY": "SAVLI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391780,
      "TXT_PINCODE_LOCALITY": "BHADARWA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391810,
      "TXT_PINCODE_LOCALITY": "GAJERA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391815,
      "TXT_PINCODE_LOCALITY": "KAHANVA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391816,
      "TXT_PINCODE_LOCALITY": "PILUDRA GAJERA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391817,
      "TXT_PINCODE_LOCALITY": "VADACH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 391818,
      "TXT_PINCODE_LOCALITY": "KARELI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424442,
      "City District Name": "NARMADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 392001,
      "TXT_PINCODE_LOCALITY": "R S",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 392002,
      "TXT_PINCODE_LOCALITY": "BHARUCH SOCIETY AREA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 392011,
      "TXT_PINCODE_LOCALITY": "JHADESHVAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 392012,
      "TXT_PINCODE_LOCALITY": "MUKTAMPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 392013,
      "TXT_PINCODE_LOCALITY": "TAVRA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 392014,
      "TXT_PINCODE_LOCALITY": "JHANOR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 392015,
      "TXT_PINCODE_LOCALITY": "NARMADANAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 392020,
      "TXT_PINCODE_LOCALITY": "DAYADRA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 392021,
      "TXT_PINCODE_LOCALITY": "PARIEJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 392025,
      "TXT_PINCODE_LOCALITY": "SAMNI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 392026,
      "TXT_PINCODE_LOCALITY": "BUVA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 392030,
      "TXT_PINCODE_LOCALITY": "SHUKALTIRTH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 392031,
      "TXT_PINCODE_LOCALITY": "NIKORA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 392035,
      "TXT_PINCODE_LOCALITY": "SARBHAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 392040,
      "TXT_PINCODE_LOCALITY": "TANKAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424442,
      "City District Name": "NARMADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 392110,
      "TXT_PINCODE_LOCALITY": "AMOD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 392111,
      "TXT_PINCODE_LOCALITY": "ACHHOD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 392115,
      "TXT_PINCODE_LOCALITY": "MIA MATAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 392130,
      "TXT_PINCODE_LOCALITY": "DAHEJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 392135,
      "TXT_PINCODE_LOCALITY": "VAGRA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 392140,
      "TXT_PINCODE_LOCALITY": "VAGRA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 392150,
      "TXT_PINCODE_LOCALITY": "JAMBUSAR BAZAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 392153,
      "TXT_PINCODE_LOCALITY": "UBER",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 392155,
      "TXT_PINCODE_LOCALITY": "KORA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 392160,
      "TXT_PINCODE_LOCALITY": "KARMAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 392161,
      "TXT_PINCODE_LOCALITY": "MANUBAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 392162,
      "TXT_PINCODE_LOCALITY": "KANTHARIA BHARUCH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 392163,
      "TXT_PINCODE_LOCALITY": "THAM",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 392165,
      "TXT_PINCODE_LOCALITY": "PAKHAJAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 392170,
      "TXT_PINCODE_LOCALITY": "KAVI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 392180,
      "TXT_PINCODE_LOCALITY": "SAROD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 392201,
      "TXT_PINCODE_LOCALITY": "BHARUCH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 392210,
      "TXT_PINCODE_LOCALITY": "NABIPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 392215,
      "TXT_PINCODE_LOCALITY": "URJA NAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 392220,
      "TXT_PINCODE_LOCALITY": "PALEJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 392230,
      "TXT_PINCODE_LOCALITY": "IKHAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 392240,
      "TXT_PINCODE_LOCALITY": "TANKARIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 392290,
      "TXT_PINCODE_LOCALITY": "SARIGAM",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 392310,
      "TXT_PINCODE_LOCALITY": "VALAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 392423,
      "TXT_PINCODE_LOCALITY": "GANDHI NAGAR S. O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 676,
      "City District Name": "GANDHINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 392530,
      "TXT_PINCODE_LOCALITY": "NAVAGAM (JAVLI)",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 442331,
      "City District Name": "SAGBARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 393001,
      "TXT_PINCODE_LOCALITY": "GOYA BAZAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 393002,
      "TXT_PINCODE_LOCALITY": "ANKLESHWAR I E",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 393003,
      "TXT_PINCODE_LOCALITY": "ANKLESHWAR ONGC COLONY",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 393010,
      "TXT_PINCODE_LOCALITY": "O N G C ANKLESHWAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 393016,
      "TXT_PINCODE_LOCALITY": "ANDADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 393017,
      "TXT_PINCODE_LOCALITY": "AVIDHA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 393018,
      "TXT_PINCODE_LOCALITY": "DIVA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 393020,
      "TXT_PINCODE_LOCALITY": "SAJOD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 393021,
      "TXT_PINCODE_LOCALITY": "MATIED",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 393025,
      "TXT_PINCODE_LOCALITY": "SELAMBA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424442,
      "City District Name": "NARMADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 393030,
      "TXT_PINCODE_LOCALITY": "GIDC ESTATE",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 393040,
      "TXT_PINCODE_LOCALITY": "DEDIAPADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424442,
      "City District Name": "NARMADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 393041,
      "TXT_PINCODE_LOCALITY": "AMBAWADI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424442,
      "City District Name": "NARMADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 393050,
      "TXT_PINCODE_LOCALITY": "SAGBARA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424442,
      "City District Name": "NARMADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 393105,
      "TXT_PINCODE_LOCALITY": "BHALOD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 393107,
      "TXT_PINCODE_LOCALITY": "SAYAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 393110,
      "TXT_PINCODE_LOCALITY": "JHAGADIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 393111,
      "TXT_PINCODE_LOCALITY": "DHAROLI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 393115,
      "TXT_PINCODE_LOCALITY": "RAJPARDI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 393120,
      "TXT_PINCODE_LOCALITY": "UMALLA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 393125,
      "TXT_PINCODE_LOCALITY": "DEHELI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 393130,
      "TXT_PINCODE_LOCALITY": "NETRANG",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 393135,
      "TXT_PINCODE_LOCALITY": "VALIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 393140,
      "TXT_PINCODE_LOCALITY": "PRATAPNAGAR NANDOD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424442,
      "City District Name": "NARMADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 393145,
      "TXT_PINCODE_LOCALITY": "RAJPIPLA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424442,
      "City District Name": "NARMADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 393150,
      "TXT_PINCODE_LOCALITY": "LACHHRAS",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424442,
      "City District Name": "NARMADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 393151,
      "TXT_PINCODE_LOCALITY": "KEVADIA COLONY",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424442,
      "City District Name": "NARMADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 393155,
      "TXT_PINCODE_LOCALITY": "GORA COLONY",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424442,
      "City District Name": "NARMADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 393819,
      "TXT_PINCODE_LOCALITY": "GUJRAT",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394001,
      "TXT_PINCODE_LOCALITY": "ATHWA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394101,
      "TXT_PINCODE_LOCALITY": "MOTA VARACHCHHA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394105,
      "TXT_PINCODE_LOCALITY": "UTRAN P H C",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394106,
      "TXT_PINCODE_LOCALITY": "AMROLI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394107,
      "TXT_PINCODE_LOCALITY": "AMROLI S.O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394110,
      "TXT_PINCODE_LOCALITY": "KIM",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394111,
      "TXT_PINCODE_LOCALITY": "KIM CHAR RASTA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394112,
      "TXT_PINCODE_LOCALITY": "DUNGRI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394114,
      "TXT_PINCODE_LOCALITY": "NANI NAROLI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394115,
      "TXT_PINCODE_LOCALITY": "PANOL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394116,
      "TXT_PINCODE_LOCALITY": "PANOLI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394120,
      "TXT_PINCODE_LOCALITY": "TARSADI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394125,
      "TXT_PINCODE_LOCALITY": "HATHURAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394130,
      "TXT_PINCODE_LOCALITY": "SAYAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394140,
      "TXT_PINCODE_LOCALITY": "BODHAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394150,
      "TXT_PINCODE_LOCALITY": "SONIFALIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394155,
      "TXT_PINCODE_LOCALITY": "GHALA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394160,
      "TXT_PINCODE_LOCALITY": "MANDVI SURAT",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394163,
      "TXT_PINCODE_LOCALITY": "GODSAMBA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394170,
      "TXT_PINCODE_LOCALITY": "TADKESHWAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394180,
      "TXT_PINCODE_LOCALITY": "KAMREJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394185,
      "TXT_PINCODE_LOCALITY": "KAMREJ CHAR RASTA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394190,
      "TXT_PINCODE_LOCALITY": "KHOLVAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394210,
      "TXT_PINCODE_LOCALITY": "UDHNA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394211,
      "TXT_PINCODE_LOCALITY": "DHRUVPARK",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394215,
      "TXT_PINCODE_LOCALITY": "AMROLI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394216,
      "TXT_PINCODE_LOCALITY": "SURAT",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394220,
      "TXT_PINCODE_LOCALITY": "BARODA RAYON",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394221,
      "TXT_PINCODE_LOCALITY": "PANDESARA I E",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394230,
      "TXT_PINCODE_LOCALITY": "SACHIN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394235,
      "TXT_PINCODE_LOCALITY": "LAJPOR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394240,
      "TXT_PINCODE_LOCALITY": "KARCHELIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394241,
      "TXT_PINCODE_LOCALITY": "NALDHARA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394242,
      "TXT_PINCODE_LOCALITY": "VAHEVAL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394243,
      "TXT_PINCODE_LOCALITY": "UMRA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394244,
      "TXT_PINCODE_LOCALITY": "MAHUVARIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394245,
      "TXT_PINCODE_LOCALITY": "ZERVAVRA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394246,
      "TXT_PINCODE_LOCALITY": "VADIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394248,
      "TXT_PINCODE_LOCALITY": "VALVADA S.O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394250,
      "TXT_PINCODE_LOCALITY": "MAHUVA N",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394251,
      "TXT_PINCODE_LOCALITY": "AMROLI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4331,
      "City District Name": "MAHUVA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394270,
      "TXT_PINCODE_LOCALITY": "HAJIRA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394276,
      "TXT_PINCODE_LOCALITY": "UCHCHHAL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394296,
      "TXT_PINCODE_LOCALITY": "SUGAR FACTORY BAMANIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394305,
      "TXT_PINCODE_LOCALITY": "CHALTHAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394310,
      "TXT_PINCODE_LOCALITY": "GANGADHRA R S",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394315,
      "TXT_PINCODE_LOCALITY": "PALSANA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394317,
      "TXT_PINCODE_LOCALITY": "BALESHWAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394320,
      "TXT_PINCODE_LOCALITY": "SEVNI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394325,
      "TXT_PINCODE_LOCALITY": "UMBHEL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394326,
      "TXT_PINCODE_LOCALITY": "VAV KATHODRA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394327,
      "TXT_PINCODE_LOCALITY": "KADODARA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394330,
      "TXT_PINCODE_LOCALITY": "ORNA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394335,
      "TXT_PINCODE_LOCALITY": "KADOD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394340,
      "TXT_PINCODE_LOCALITY": "MADHI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394345,
      "TXT_PINCODE_LOCALITY": "UMARAKH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394350,
      "TXT_PINCODE_LOCALITY": "SARBHON",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394352,
      "TXT_PINCODE_LOCALITY": "PUNI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394355,
      "TXT_PINCODE_LOCALITY": "VARAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394360,
      "TXT_PINCODE_LOCALITY": "KAKRAPAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394363,
      "TXT_PINCODE_LOCALITY": "BEDKUVADOOR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394364,
      "TXT_PINCODE_LOCALITY": "UNCHAMALA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394365,
      "TXT_PINCODE_LOCALITY": "RANIAMBA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394370,
      "TXT_PINCODE_LOCALITY": "NIJHAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394375,
      "TXT_PINCODE_LOCALITY": "UCHHAL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394376,
      "TXT_PINCODE_LOCALITY": "UCHCHHAL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394378,
      "TXT_PINCODE_LOCALITY": "BHADBHUJA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394380,
      "TXT_PINCODE_LOCALITY": "KUKARMUNDA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394405,
      "TXT_PINCODE_LOCALITY": "VELACHHA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394410,
      "TXT_PINCODE_LOCALITY": "MOTA MIYA MANGROL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394421,
      "TXT_PINCODE_LOCALITY": "MOSALI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394430,
      "TXT_PINCODE_LOCALITY": "VANKAL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394440,
      "TXT_PINCODE_LOCALITY": "ZANKHAVAV",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394445,
      "TXT_PINCODE_LOCALITY": "UMARPADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394500,
      "TXT_PINCODE_LOCALITY": "SURAT",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394510,
      "TXT_PINCODE_LOCALITY": "BHATHA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394515,
      "TXT_PINCODE_LOCALITY": "FERTILIZERNAGAR TRIBHCO S",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394516,
      "TXT_PINCODE_LOCALITY": "CHORASI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394517,
      "TXT_PINCODE_LOCALITY": "SUVALI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394518,
      "TXT_PINCODE_LOCALITY": "ONGC BHATPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394520,
      "TXT_PINCODE_LOCALITY": "VARIAV",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394530,
      "TXT_PINCODE_LOCALITY": "MOR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394540,
      "TXT_PINCODE_LOCALITY": "OLPAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394541,
      "TXT_PINCODE_LOCALITY": "SURAT",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394550,
      "TXT_PINCODE_LOCALITY": "DUMAS",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394601,
      "TXT_PINCODE_LOCALITY": "SARDAR BAG",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394607,
      "TXT_PINCODE_LOCALITY": "SURAT",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394620,
      "TXT_PINCODE_LOCALITY": "SIKER",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394630,
      "TXT_PINCODE_LOCALITY": "BUHARI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394631,
      "TXT_PINCODE_LOCALITY": "GADAT (BIHAR)",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394632,
      "TXT_PINCODE_LOCALITY": "PATI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394633,
      "TXT_PINCODE_LOCALITY": "AMBIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394635,
      "TXT_PINCODE_LOCALITY": "DOLVAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394640,
      "TXT_PINCODE_LOCALITY": "VALOD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394641,
      "TXT_PINCODE_LOCALITY": "VEDACHHI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394642,
      "TXT_PINCODE_LOCALITY": "BARDOLI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1072,
      "City District Name": "BARDOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394650,
      "TXT_PINCODE_LOCALITY": "VYARA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394651,
      "TXT_PINCODE_LOCALITY": "POST ANUMALA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 441213,
      "City District Name": "TAPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394652,
      "TXT_PINCODE_LOCALITY": "TAPI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 441213,
      "City District Name": "TAPI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394655,
      "TXT_PINCODE_LOCALITY": "KAPURA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394660,
      "TXT_PINCODE_LOCALITY": "CENTRAL PULP MILLS",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394670,
      "TXT_PINCODE_LOCALITY": "FORT SONGADH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394680,
      "TXT_PINCODE_LOCALITY": "W C COLONY",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394690,
      "TXT_PINCODE_LOCALITY": "BAJIPURA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394691,
      "TXT_PINCODE_LOCALITY": "BORKHADI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394695,
      "TXT_PINCODE_LOCALITY": "DEGAMA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394710,
      "TXT_PINCODE_LOCALITY": "AHWA DANGS",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424445,
      "City District Name": "THE DANGS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394715,
      "TXT_PINCODE_LOCALITY": "KOSIMDA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394716,
      "TXT_PINCODE_LOCALITY": "SUBIR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424445,
      "City District Name": "THE DANGS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394720,
      "TXT_PINCODE_LOCALITY": "AHWA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424445,
      "City District Name": "THE DANGS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394730,
      "TXT_PINCODE_LOCALITY": "WAGHAI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424445,
      "City District Name": "THE DANGS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 394810,
      "TXT_PINCODE_LOCALITY": "ILLAV",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 670,
      "City District Name": "BHARUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 395001,
      "TXT_PINCODE_LOCALITY": "ATHWALINES",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 395002,
      "TXT_PINCODE_LOCALITY": "INDERPURA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 395003,
      "TXT_PINCODE_LOCALITY": "PEGAMPURA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 395004,
      "TXT_PINCODE_LOCALITY": "SURAT KATARGAM",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 395005,
      "TXT_PINCODE_LOCALITY": "SURAT RANDER",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 395006,
      "TXT_PINCODE_LOCALITY": "SURAT VARACHHA ROAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 395007,
      "TXT_PINCODE_LOCALITY": "ICCHANATH ROAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 395008,
      "TXT_PINCODE_LOCALITY": "VASTADEVDI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 395009,
      "TXT_PINCODE_LOCALITY": "UDHNA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 395010,
      "TXT_PINCODE_LOCALITY": "SURAT BOMBAY MARKET YARD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 395011,
      "TXT_PINCODE_LOCALITY": "PUNA KUMBHARIA S.O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 395012,
      "TXT_PINCODE_LOCALITY": "GODADARA B.O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 395013,
      "TXT_PINCODE_LOCALITY": "CHORYASI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 395015,
      "TXT_PINCODE_LOCALITY": "VYARA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 6230,
      "City District Name": "VYARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 395016,
      "TXT_PINCODE_LOCALITY": "SURAT S. O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 395017,
      "TXT_PINCODE_LOCALITY": "ALTHAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 395023,
      "TXT_PINCODE_LOCALITY": "BHESTAN - 2756",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 395155,
      "TXT_PINCODE_LOCALITY": "VALSAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 395305,
      "TXT_PINCODE_LOCALITY": "BHADRAK S.O",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1459,
      "City District Name": "BHADRAK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 395345,
      "TXT_PINCODE_LOCALITY": "MOVACHHI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1072,
      "City District Name": "BARDOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 395620,
      "TXT_PINCODE_LOCALITY": "KHAMBHLA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 442661,
      "City District Name": "VALOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396001,
      "TXT_PINCODE_LOCALITY": "VALSAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396002,
      "TXT_PINCODE_LOCALITY": "VALSAD ABRAMA S.O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396006,
      "TXT_PINCODE_LOCALITY": "TITHAL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396007,
      "TXT_PINCODE_LOCALITY": "VALSAD SUGAR FACTORY",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396020,
      "TXT_PINCODE_LOCALITY": "ATUL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396025,
      "TXT_PINCODE_LOCALITY": "TISKARI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396030,
      "TXT_PINCODE_LOCALITY": "HANUMAN BHAGDA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396031,
      "TXT_PINCODE_LOCALITY": "BHADELI JAGALALA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396035,
      "TXT_PINCODE_LOCALITY": "VALSAD I E",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396040,
      "TXT_PINCODE_LOCALITY": "KHERGAM",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396041,
      "TXT_PINCODE_LOCALITY": "ACHHAVANI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396042,
      "TXT_PINCODE_LOCALITY": "VAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396045,
      "TXT_PINCODE_LOCALITY": "ATGAM",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396050,
      "TXT_PINCODE_LOCALITY": "DHARAMPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396051,
      "TXT_PINCODE_LOCALITY": "AMBATALAT",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396055,
      "TXT_PINCODE_LOCALITY": "RONVEL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396060,
      "TXT_PINCODE_LOCALITY": "RUMLA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396065,
      "TXT_PINCODE_LOCALITY": "NANI VAHIAL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396067,
      "TXT_PINCODE_LOCALITY": "VALSAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396068,
      "TXT_PINCODE_LOCALITY": "BILPUDI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396075,
      "TXT_PINCODE_LOCALITY": "ASLONA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396105,
      "TXT_PINCODE_LOCALITY": "BHILAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396106,
      "TXT_PINCODE_LOCALITY": "MOHAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396108,
      "TXT_PINCODE_LOCALITY": "VALVADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396110,
      "TXT_PINCODE_LOCALITY": "BIGRI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396112,
      "TXT_PINCODE_LOCALITY": "ZAROLI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396115,
      "TXT_PINCODE_LOCALITY": "KOLAK",
      "NUM_STATE_CD": 161,
      "State Name": "DAMAN & DIU",
      "NUM_CITYDISTRICT_CD": 524,
      "City District Name": "DAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396117,
      "TXT_PINCODE_LOCALITY": "ZAROLI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 809,
      "City District Name": "UMBERGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396120,
      "TXT_PINCODE_LOCALITY": "KHATALWADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396125,
      "TXT_PINCODE_LOCALITY": "DAMNIZUMPA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396126,
      "TXT_PINCODE_LOCALITY": "NANA PONDHA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396130,
      "TXT_PINCODE_LOCALITY": "MROLI SANJAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396135,
      "TXT_PINCODE_LOCALITY": "NARGOL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396140,
      "TXT_PINCODE_LOCALITY": "PHANSA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396142,
      "TXT_PINCODE_LOCALITY": "KALGAM",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396145,
      "TXT_PINCODE_LOCALITY": "PARDI PARIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396150,
      "TXT_PINCODE_LOCALITY": "SNJAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396155,
      "TXT_PINCODE_LOCALITY": "SARIGAM",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396156,
      "TXT_PINCODE_LOCALITY": "MANDA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396165,
      "TXT_PINCODE_LOCALITY": "SOLSUMBA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396170,
      "TXT_PINCODE_LOCALITY": "UMARGAM",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396171,
      "TXT_PINCODE_LOCALITY": "UMARGAM I E",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396175,
      "TXT_PINCODE_LOCALITY": "UMARSADI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396180,
      "TXT_PINCODE_LOCALITY": "UDVADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396185,
      "TXT_PINCODE_LOCALITY": "UDVADA R S",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396191,
      "TXT_PINCODE_LOCALITY": "CHALA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396193,
      "TXT_PINCODE_LOCALITY": "VAPI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396195,
      "TXT_PINCODE_LOCALITY": "VAPI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396201,
      "TXT_PINCODE_LOCALITY": "MOTA PONDHA",
      "NUM_STATE_CD": 162,
      "State Name": "DADRA & NAGAR HAVELI",
      "NUM_CITYDISTRICT_CD": 424436,
      "City District Name": "DADRA AND NAGAR HAVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396202,
      "TXT_PINCODE_LOCALITY": "AMBHETI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396210,
      "TXT_PINCODE_LOCALITY": "KADAIYA",
      "NUM_STATE_CD": 161,
      "State Name": "DAMAN & DIU",
      "NUM_CITYDISTRICT_CD": 524,
      "City District Name": "DAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396215,
      "TXT_PINCODE_LOCALITY": "KUNTA",
      "NUM_STATE_CD": 161,
      "State Name": "DAMAN & DIU",
      "NUM_CITYDISTRICT_CD": 524,
      "City District Name": "DAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396217,
      "TXT_PINCODE_LOCALITY": "VARKUND",
      "NUM_STATE_CD": 161,
      "State Name": "DAMAN & DIU",
      "NUM_CITYDISTRICT_CD": 524,
      "City District Name": "DAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396220,
      "TXT_PINCODE_LOCALITY": "MOTI DAMAN",
      "NUM_STATE_CD": 161,
      "State Name": "DAMAN & DIU",
      "NUM_CITYDISTRICT_CD": 524,
      "City District Name": "DAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396223,
      "TXT_PINCODE_LOCALITY": "MAGARWADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396230,
      "TXT_PINCODE_LOCALITY": "AMLI B.O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 442066,
      "City District Name": "DADRA NAGAR HAVELI.",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396233,
      "TXT_PINCODE_LOCALITY": "VALSAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396235,
      "TXT_PINCODE_LOCALITY": "NAROLI",
      "NUM_STATE_CD": 162,
      "State Name": "DADRA & NAGAR HAVELI",
      "NUM_CITYDISTRICT_CD": 424436,
      "City District Name": "DADRA AND NAGAR HAVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396236,
      "TXT_PINCODE_LOCALITY": "ANKLAS",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396240,
      "TXT_PINCODE_LOCALITY": "KARAD D G P",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396245,
      "TXT_PINCODE_LOCALITY": "AMLI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396310,
      "TXT_PINCODE_LOCALITY": "AMALSAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396320,
      "TXT_PINCODE_LOCALITY": "SILVASSA S.O",
      "NUM_STATE_CD": 162,
      "State Name": "DADRA & NAGAR HAVELI",
      "NUM_CITYDISTRICT_CD": 686,
      "City District Name": "SILVASSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396321,
      "TXT_PINCODE_LOCALITY": "DESRA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396325,
      "TXT_PINCODE_LOCALITY": "ANTALIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396331,
      "TXT_PINCODE_LOCALITY": "KUKERI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396340,
      "TXT_PINCODE_LOCALITY": "ENDHAL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396345,
      "TXT_PINCODE_LOCALITY": "CHARI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396350,
      "TXT_PINCODE_LOCALITY": "GADAT",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396360,
      "TXT_PINCODE_LOCALITY": "GANDEVI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396370,
      "TXT_PINCODE_LOCALITY": "KACHCHHA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396375,
      "TXT_PINCODE_LOCALITY": "DUNGRI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396380,
      "TXT_PINCODE_LOCALITY": "DEVSAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396385,
      "TXT_PINCODE_LOCALITY": "UNTDI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396403,
      "TXT_PINCODE_LOCALITY": "AAT",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396406,
      "TXT_PINCODE_LOCALITY": "ABRAMA - 12",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396409,
      "TXT_PINCODE_LOCALITY": "ALIPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396412,
      "TXT_PINCODE_LOCALITY": "BORIFALIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396415,
      "TXT_PINCODE_LOCALITY": "DABHEL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396418,
      "TXT_PINCODE_LOCALITY": "GURUKULSUPA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396421,
      "TXT_PINCODE_LOCALITY": "JALALPORE",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396424,
      "TXT_PINCODE_LOCALITY": "PANCH HATDI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396427,
      "TXT_PINCODE_LOCALITY": "KALIAVADI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396430,
      "TXT_PINCODE_LOCALITY": "KHAREE",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396431,
      "TXT_PINCODE_LOCALITY": "NOGAMA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396433,
      "TXT_PINCODE_LOCALITY": "ASTAGAM",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396436,
      "TXT_PINCODE_LOCALITY": "NAVSARI S. O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396439,
      "TXT_PINCODE_LOCALITY": "MATWAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396440,
      "TXT_PINCODE_LOCALITY": "KARADI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396443,
      "TXT_PINCODE_LOCALITY": "KHADSUPA BOARDING",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396445,
      "TXT_PINCODE_LOCALITY": "UDYOGNAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396450,
      "TXT_PINCODE_LOCALITY": "ERU AGRI COLLEGE",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396452,
      "TXT_PINCODE_LOCALITY": "SADADVEL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396460,
      "TXT_PINCODE_LOCALITY": "PANAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396463,
      "TXT_PINCODE_LOCALITY": "SISODRA NAVSARI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396466,
      "TXT_PINCODE_LOCALITY": "SATEM",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396469,
      "TXT_PINCODE_LOCALITY": "UGAT",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396472,
      "TXT_PINCODE_LOCALITY": "VEDACHHA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396475,
      "TXT_PINCODE_LOCALITY": "VESMA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396480,
      "TXT_PINCODE_LOCALITY": "VEJALPORE",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396489,
      "TXT_PINCODE_LOCALITY": "AMBADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396491,
      "TXT_PINCODE_LOCALITY": "AMBHETI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396510,
      "TXT_PINCODE_LOCALITY": "ANAVAL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396516,
      "TXT_PINCODE_LOCALITY": "SADAKPORE",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396521,
      "TXT_PINCODE_LOCALITY": "CHIKHLI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396523,
      "TXT_PINCODE_LOCALITY": "PIPALGABHAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396525,
      "TXT_PINCODE_LOCALITY": "AMADHARA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396526,
      "TXT_PINCODE_LOCALITY": "OND",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396528,
      "TXT_PINCODE_LOCALITY": "TALAVCHORA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396530,
      "TXT_PINCODE_LOCALITY": "DEGAM",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396540,
      "TXT_PINCODE_LOCALITY": "FADVEL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396560,
      "TXT_PINCODE_LOCALITY": "RANKUVA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396562,
      "TXT_PINCODE_LOCALITY": "VANZNA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396570,
      "TXT_PINCODE_LOCALITY": "DIGENDRA NAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396571,
      "TXT_PINCODE_LOCALITY": "CHAPALDHARA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396575,
      "TXT_PINCODE_LOCALITY": "KHAROLI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396580,
      "TXT_PINCODE_LOCALITY": "VANSADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 672,
      "City District Name": "VALSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396582,
      "TXT_PINCODE_LOCALITY": "KHATAMBA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396585,
      "TXT_PINCODE_LOCALITY": "LIMZER",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396590,
      "TXT_PINCODE_LOCALITY": "UNAI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396591,
      "TXT_PINCODE_LOCALITY": "BHINAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 396592,
      "TXT_PINCODE_LOCALITY": "SINDHAJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 399022,
      "TXT_PINCODE_LOCALITY": "TEKRAVALU FALIYU KOTLI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 183,
      "City District Name": "VADODARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400000,
      "TXT_PINCODE_LOCALITY": "VISHRAMBAG",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670001,
      "TXT_PINCODE_LOCALITY": "KANNUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670002,
      "TXT_PINCODE_LOCALITY": "KANNUR CIVIL STATION",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670003,
      "TXT_PINCODE_LOCALITY": "KANNUR CITY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670004,
      "TXT_PINCODE_LOCALITY": "PALLIKUNNU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670005,
      "TXT_PINCODE_LOCALITY": "KAKKAT",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670006,
      "TXT_PINCODE_LOCALITY": "ATTTADAPPA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670007,
      "TXT_PINCODE_LOCALITY": "KIZHUNNA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670008,
      "TXT_PINCODE_LOCALITY": "ALAVIL - 1",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670009,
      "TXT_PINCODE_LOCALITY": "AZHIKKAL B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670010,
      "TXT_PINCODE_LOCALITY": "VALAPATTANAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670011,
      "TXT_PINCODE_LOCALITY": "CHIRAKKAL R.S.B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670012,
      "TXT_PINCODE_LOCALITY": "KANNOOKARA KANNUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670013,
      "TXT_PINCODE_LOCALITY": "BURNACHERRY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670014,
      "TXT_PINCODE_LOCALITY": "CHALAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670015,
      "TXT_PINCODE_LOCALITY": "KATTAMPALLY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670016,
      "TXT_PINCODE_LOCALITY": "KOTTALI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670017,
      "TXT_PINCODE_LOCALITY": "KANNUR DIST.HOSPITAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670018,
      "TXT_PINCODE_LOCALITY": "THAZHE CHOVVA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670022,
      "TXT_PINCODE_LOCALITY": "CHIRAKKAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 2804,
      "City District Name": "CHIRAKKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670051,
      "TXT_PINCODE_LOCALITY": "KANNUR NDC",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1533,
      "City District Name": "CANNANORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670101,
      "TXT_PINCODE_LOCALITY": "THALASSERY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670102,
      "TXT_PINCODE_LOCALITY": "VAYALALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670103,
      "TXT_PINCODE_LOCALITY": "TRIUVANGAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670104,
      "TXT_PINCODE_LOCALITY": "CHERAKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670105,
      "TXT_PINCODE_LOCALITY": "NETTUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670106,
      "TXT_PINCODE_LOCALITY": "DHARMADOM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670107,
      "TXT_PINCODE_LOCALITY": "ERANHOLI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670109,
      "TXT_PINCODE_LOCALITY": "VADAKKUMBAD EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670110,
      "TXT_PINCODE_LOCALITY": "KAVUMBHAGOM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670111,
      "TXT_PINCODE_LOCALITY": "MOOZHIKARA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670121,
      "TXT_PINCODE_LOCALITY": "KASARAGOD H O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 3831,
      "City District Name": "KASARAGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670124,
      "TXT_PINCODE_LOCALITY": "MOGRAL PUTHUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 3831,
      "City District Name": "KASARAGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670141,
      "TXT_PINCODE_LOCALITY": "TRICHAMBARAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670142,
      "TXT_PINCODE_LOCALITY": "PALLIVAYAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670143,
      "TXT_PINCODE_LOCALITY": "ARIL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670144,
      "TXT_PINCODE_LOCALITY": "KUPPAM TALIPARAMBA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670301,
      "TXT_PINCODE_LOCALITY": "KEEZHARA B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670302,
      "TXT_PINCODE_LOCALITY": "MATTUL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670303,
      "TXT_PINCODE_LOCALITY": "SREETHA B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670304,
      "TXT_PINCODE_LOCALITY": "MADAYI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670305,
      "TXT_PINCODE_LOCALITY": "PALAKKOD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670306,
      "TXT_PINCODE_LOCALITY": "MATHAMANGALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670307,
      "TXT_PINCODE_LOCALITY": "PAYYANNUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670308,
      "TXT_PINCODE_LOCALITY": "KARANTHAT",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670309,
      "TXT_PINCODE_LOCALITY": "KOVVAPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670310,
      "TXT_PINCODE_LOCALITY": "TRIKARPUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 3831,
      "City District Name": "KASARAGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670313,
      "TXT_PINCODE_LOCALITY": "OLAVILAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670315,
      "TXT_PINCODE_LOCALITY": "KOTTACHERRY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 3831,
      "City District Name": "KASARAGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670316,
      "TXT_PINCODE_LOCALITY": "KARIYAD SOUTH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670319,
      "TXT_PINCODE_LOCALITY": "UDMA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 3831,
      "City District Name": "KASARAGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670325,
      "TXT_PINCODE_LOCALITY": "MATTUL NORTH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670327,
      "TXT_PINCODE_LOCALITY": "EDAT",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670330,
      "TXT_PINCODE_LOCALITY": "VADAKKUMBAD-RAMANTHALI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670331,
      "TXT_PINCODE_LOCALITY": "MOTTAMMAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670332,
      "TXT_PINCODE_LOCALITY": "ANNUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670333,
      "TXT_PINCODE_LOCALITY": "KANDANKALI EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670334,
      "TXT_PINCODE_LOCALITY": "KOTTILA B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670337,
      "TXT_PINCODE_LOCALITY": "KANKOL EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670338,
      "TXT_PINCODE_LOCALITY": "PALACHODE P.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 3831,
      "City District Name": "KASARAGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670339,
      "TXT_PINCODE_LOCALITY": "KAVVAYI EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670343,
      "TXT_PINCODE_LOCALITY": "MATHIL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670346,
      "TXT_PINCODE_LOCALITY": "VELLUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670352,
      "TXT_PINCODE_LOCALITY": "TIMIRI-CHERVATHUR",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 5968,
      "City District Name": "TIMIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670353,
      "TXT_PINCODE_LOCALITY": "PERINGOME",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1533,
      "City District Name": "CANNANORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670354,
      "TXT_PINCODE_LOCALITY": "PERINGOME EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670358,
      "TXT_PINCODE_LOCALITY": "PAYANGADI R.S",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670359,
      "TXT_PINCODE_LOCALITY": "ANCHAMPEEDIKA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670501,
      "TXT_PINCODE_LOCALITY": "PILATHARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670502,
      "TXT_PINCODE_LOCALITY": "CHITHAPILAPOYIL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670503,
      "TXT_PINCODE_LOCALITY": "KADANNAPALLI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670504,
      "TXT_PINCODE_LOCALITY": "TALIPARAMBA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 5830,
      "City District Name": "TALIPARAMBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670511,
      "TXT_PINCODE_LOCALITY": "PALAVAYAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670521,
      "TXT_PINCODE_LOCALITY": "KARIVELLUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670522,
      "TXT_PINCODE_LOCALITY": "KOZHUMMAL EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670561,
      "TXT_PINCODE_LOCALITY": "PAPPINISSERI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670562,
      "TXT_PINCODE_LOCALITY": "KALLIASSERI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670563,
      "TXT_PINCODE_LOCALITY": "PARASSINIKADAVU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670564,
      "TXT_PINCODE_LOCALITY": "KANUL EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670565,
      "TXT_PINCODE_LOCALITY": "PAPPINISSERI WEST",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670566,
      "TXT_PINCODE_LOCALITY": "AROLI EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670567,
      "TXT_PINCODE_LOCALITY": "KANNUR UNIVERSITY CAMPUS",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670571,
      "TXT_PINCODE_LOCALITY": "VIJAYAGIRI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670581,
      "TXT_PINCODE_LOCALITY": "CHEPPARAPADAVA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670582,
      "TXT_PINCODE_LOCALITY": "PULKURUMBA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670583,
      "TXT_PINCODE_LOCALITY": "VAYATTUPARAMBA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670591,
      "TXT_PINCODE_LOCALITY": "MUNDERI EACHUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670592,
      "TXT_PINCODE_LOCALITY": "KANHIRODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670593,
      "TXT_PINCODE_LOCALITY": "KUSAVANVAYAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670594,
      "TXT_PINCODE_LOCALITY": "VARAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670595,
      "TXT_PINCODE_LOCALITY": "ELAMPARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670597,
      "TXT_PINCODE_LOCALITY": "MUNDAYAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670601,
      "TXT_PINCODE_LOCALITY": "VELLALASSERI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670602,
      "TXT_PINCODE_LOCALITY": "MAYYIL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670603,
      "TXT_PINCODE_LOCALITY": "NARATH EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670604,
      "TXT_PINCODE_LOCALITY": "KANNADIPARAMBA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670605,
      "TXT_PINCODE_LOCALITY": "CHELERI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670611,
      "TXT_PINCODE_LOCALITY": "MUZHAPPALA B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670612,
      "TXT_PINCODE_LOCALITY": "ANJARAKANDY - 189",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670613,
      "TXT_PINCODE_LOCALITY": "MOWANCHERRI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670614,
      "TXT_PINCODE_LOCALITY": "IRIVERI EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670615,
      "TXT_PINCODE_LOCALITY": "MUZHAPPALE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670621,
      "TXT_PINCODE_LOCALITY": "PODUVACHERI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670622,
      "TXT_PINCODE_LOCALITY": "MUNDALUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670631,
      "TXT_PINCODE_LOCALITY": "CHULALI EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670632,
      "TXT_PINCODE_LOCALITY": "NELLIKUTTY B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670633,
      "TXT_PINCODE_LOCALITY": "PAZHASSIKKARI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670641,
      "TXT_PINCODE_LOCALITY": "PONNIAM WEST",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670642,
      "TXT_PINCODE_LOCALITY": "KADIRUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670643,
      "TXT_PINCODE_LOCALITY": "KOTTAYAM MALABAR B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670644,
      "TXT_PINCODE_LOCALITY": "VALLAT",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670645,
      "TXT_PINCODE_LOCALITY": "VALERY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1905,
      "City District Name": "WAYANAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670646,
      "TXT_PINCODE_LOCALITY": "VAVALI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1905,
      "City District Name": "WAYANAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670647,
      "TXT_PINCODE_LOCALITY": "ERUVATTI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1216,
      "City District Name": "THALASSERY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670648,
      "TXT_PINCODE_LOCALITY": "KOLAYAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670649,
      "TXT_PINCODE_LOCALITY": "UMMANCHIRA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670650,
      "TXT_PINCODE_LOCALITY": "NEDUMPOIL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670651,
      "TXT_PINCODE_LOCALITY": "KOTTIYUR S.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670656,
      "TXT_PINCODE_LOCALITY": "PAPPINISSERI WEST",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670661,
      "TXT_PINCODE_LOCALITY": "PALAYAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670662,
      "TXT_PINCODE_LOCALITY": "MULAPPILANGD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670663,
      "TXT_PINCODE_LOCALITY": "KADAMBUR WARD II",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670671,
      "TXT_PINCODE_LOCALITY": "PARAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670672,
      "TXT_PINCODE_LOCALITY": "ANIYARAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670673,
      "TXT_PINCODE_LOCALITY": "PERAVOOR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670674,
      "TXT_PINCODE_LOCALITY": "KOTTIYUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670675,
      "TXT_PINCODE_LOCALITY": "PULIYANAMBRAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670676,
      "TXT_PINCODE_LOCALITY": "KADAVATHUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670677,
      "TXT_PINCODE_LOCALITY": "MANATHANA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670678,
      "TXT_PINCODE_LOCALITY": "PULLOKKARA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670679,
      "TXT_PINCODE_LOCALITY": "PANNIYANNUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670691,
      "TXT_PINCODE_LOCALITY": "KOTTAYAMPOIL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670692,
      "TXT_PINCODE_LOCALITY": "ELANGOT",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670693,
      "TXT_PINCODE_LOCALITY": "POYILUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670694,
      "TXT_PINCODE_LOCALITY": "KOORARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670701,
      "TXT_PINCODE_LOCALITY": "NEERVELI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670702,
      "TXT_PINCODE_LOCALITY": "URUVACHAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670703,
      "TXT_PINCODE_LOCALITY": "VILAKODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670704,
      "TXT_PINCODE_LOCALITY": "VELIMANAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670705,
      "TXT_PINCODE_LOCALITY": "PARIKKALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670706,
      "TXT_PINCODE_LOCALITY": "ANGADIKADAVU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670708,
      "TXT_PINCODE_LOCALITY": "PAZHASSI RAJA NAGAR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670709,
      "TXT_PINCODE_LOCALITY": "ULIYIL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670721,
      "TXT_PINCODE_LOCALITY": "PANAMARAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1905,
      "City District Name": "WAYANAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670731,
      "TXT_PINCODE_LOCALITY": "VANNODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670732,
      "TXT_PINCODE_LOCALITY": "THARUVNA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1905,
      "City District Name": "WAYANAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 670741,
      "TXT_PINCODE_LOCALITY": "MAMBRAM B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671121,
      "TXT_PINCODE_LOCALITY": "KASARAGOD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 3831,
      "City District Name": "KASARAGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671122,
      "TXT_PINCODE_LOCALITY": "TALANGARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 3831,
      "City District Name": "KASARAGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671123,
      "TXT_PINCODE_LOCALITY": "VIDHYANAGAR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 3831,
      "City District Name": "KASARAGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671124,
      "TXT_PINCODE_LOCALITY": "KUDLU - 2622",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 3831,
      "City District Name": "KASARAGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671125,
      "TXT_PINCODE_LOCALITY": "RAMDAS NAGAR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 3831,
      "City District Name": "KASARAGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671128,
      "TXT_PINCODE_LOCALITY": "MOGRAL PUTHUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 3831,
      "City District Name": "KASARAGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671310,
      "TXT_PINCODE_LOCALITY": "EDACHAKAI B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 3831,
      "City District Name": "KASARAGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671311,
      "TXT_PINCODE_LOCALITY": "TRIKARPUR SOUTH B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671312,
      "TXT_PINCODE_LOCALITY": "KASARAGOD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 3831,
      "City District Name": "KASARAGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671313,
      "TXT_PINCODE_LOCALITY": "CHERUVATHUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671314,
      "TXT_PINCODE_LOCALITY": "NILESHWAR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 3831,
      "City District Name": "KASARAGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671315,
      "TXT_PINCODE_LOCALITY": "KANHANGAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 3831,
      "City District Name": "KASARAGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671316,
      "TXT_PINCODE_LOCALITY": "PALLIKERE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 3831,
      "City District Name": "KASARAGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671317,
      "TXT_PINCODE_LOCALITY": "CHEMNAD.",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1096,
      "City District Name": "KASARGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671318,
      "TXT_PINCODE_LOCALITY": "BEKAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671319,
      "TXT_PINCODE_LOCALITY": "UDMA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671320,
      "TXT_PINCODE_LOCALITY": "CHAMNAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671321,
      "TXT_PINCODE_LOCALITY": "BELA B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1096,
      "City District Name": "KASARGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671322,
      "TXT_PINCODE_LOCALITY": "UPPALA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671323,
      "TXT_PINCODE_LOCALITY": "HOSABETTU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 3831,
      "City District Name": "KASARAGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671324,
      "TXT_PINCODE_LOCALITY": "MANGALPADY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671326,
      "TXT_PINCODE_LOCALITY": "CHITTARIKKAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671328,
      "TXT_PINCODE_LOCALITY": "PADNNAKKAD EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671329,
      "TXT_PINCODE_LOCALITY": "MANIKOTH EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671331,
      "TXT_PINCODE_LOCALITY": "SHIRIYA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 3831,
      "City District Name": "KASARAGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671341,
      "TXT_PINCODE_LOCALITY": "KOTTAPURAM KANHANGAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 3831,
      "City District Name": "KASARAGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671343,
      "TXT_PINCODE_LOCALITY": "IZZATH NAGAR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 3831,
      "City District Name": "KASARAGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671344,
      "TXT_PINCODE_LOCALITY": "MAVILAKADAPURAM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671345,
      "TXT_PINCODE_LOCALITY": "PADNE KADAPURAM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671348,
      "TXT_PINCODE_LOCALITY": "PAIVALIKE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 3831,
      "City District Name": "KASARAGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671349,
      "TXT_PINCODE_LOCALITY": "UDINUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 3831,
      "City District Name": "KASARAGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671350,
      "TXT_PINCODE_LOCALITY": "UDUMBANTHALA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 3831,
      "City District Name": "KASARAGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671351,
      "TXT_PINCODE_LOCALITY": "THURUTHI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 3831,
      "City District Name": "KASARAGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671353,
      "TXT_PINCODE_LOCALITY": "PILICODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 3831,
      "City District Name": "KASARAGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671357,
      "TXT_PINCODE_LOCALITY": "KASARGODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1096,
      "City District Name": "KASARGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671531,
      "TXT_PINCODE_LOCALITY": "ANANDASRAMA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 3831,
      "City District Name": "KASARAGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671532,
      "TXT_PINCODE_LOCALITY": "RAJAPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 3831,
      "City District Name": "KASARAGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671533,
      "TXT_PINCODE_LOCALITY": "PARAPPA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 3831,
      "City District Name": "KASARAGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671534,
      "TXT_PINCODE_LOCALITY": "KASARGOD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 3831,
      "City District Name": "KASARAGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671535,
      "TXT_PINCODE_LOCALITY": "PUNNAKUNNU B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 3831,
      "City District Name": "KASARAGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671541,
      "TXT_PINCODE_LOCALITY": "BEDADKA B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 3831,
      "City District Name": "KASARAGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671542,
      "TXT_PINCODE_LOCALITY": "MULIYAR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 3831,
      "City District Name": "KASARAGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671543,
      "TXT_PINCODE_LOCALITY": "MULLERIA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 3831,
      "City District Name": "KASARAGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671544,
      "TXT_PINCODE_LOCALITY": "NEKRAJE EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 3831,
      "City District Name": "KASARAGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671545,
      "TXT_PINCODE_LOCALITY": "THEKKIL FERRY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 3831,
      "City District Name": "KASARAGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671551,
      "TXT_PINCODE_LOCALITY": "KUMBLA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 3831,
      "City District Name": "KASARAGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 671552,
      "TXT_PINCODE_LOCALITY": "PERLA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 3831,
      "City District Name": "KASARAGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673001,
      "TXT_PINCODE_LOCALITY": "ARAFA COMPLEX",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673002,
      "TXT_PINCODE_LOCALITY": "KALLAI ROAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673003,
      "TXT_PINCODE_LOCALITY": "KALLAI KOZHIKODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673004,
      "TXT_PINCODE_LOCALITY": "RAJAJI ROAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673005,
      "TXT_PINCODE_LOCALITY": "KANNUR ROAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673006,
      "TXT_PINCODE_LOCALITY": "PAVANGADE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673007,
      "TXT_PINCODE_LOCALITY": "MANKAVU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673008,
      "TXT_PINCODE_LOCALITY": "CALICUT MEDICAL COLLEGE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673009,
      "TXT_PINCODE_LOCALITY": "MALAPARAMBA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673010,
      "TXT_PINCODE_LOCALITY": "VENGERI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673011,
      "TXT_PINCODE_LOCALITY": "NADAKAVU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673012,
      "TXT_PINCODE_LOCALITY": "PARAMBIL B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673013,
      "TXT_PINCODE_LOCALITY": "POKKUNNU EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673014,
      "TXT_PINCODE_LOCALITY": "GURUVAYURAPPAN COLLEGE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673015,
      "TXT_PINCODE_LOCALITY": "BEYPURE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 490,
      "City District Name": "CALICUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673016,
      "TXT_PINCODE_LOCALITY": "PALAZHI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673017,
      "TXT_PINCODE_LOCALITY": "CHEVAYUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673018,
      "TXT_PINCODE_LOCALITY": "CALICUT ART & SCIENCE COLLEGE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673019,
      "TXT_PINCODE_LOCALITY": "VELLAYICODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673020,
      "TXT_PINCODE_LOCALITY": "P.O. MANK",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673021,
      "TXT_PINCODE_LOCALITY": "PUTHIYANGADI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673023,
      "TXT_PINCODE_LOCALITY": "KYTHAPOIL EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673024,
      "TXT_PINCODE_LOCALITY": "PERUVAYAL EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673025,
      "TXT_PINCODE_LOCALITY": "OLAVANNA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673026,
      "TXT_PINCODE_LOCALITY": "PERUMANNA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673027,
      "TXT_PINCODE_LOCALITY": "NALLALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673028,
      "TXT_PINCODE_LOCALITY": "ARAKKINAR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673029,
      "TXT_PINCODE_LOCALITY": "TIRUVANNURNADA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673031,
      "TXT_PINCODE_LOCALITY": "PAYYANAKKAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673032,
      "TXT_PINCODE_LOCALITY": "CALICUT BEACH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673051,
      "TXT_PINCODE_LOCALITY": "KOZHIKODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673082,
      "TXT_PINCODE_LOCALITY": "ARAKINAR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673101,
      "TXT_PINCODE_LOCALITY": "BADAGARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673102,
      "TXT_PINCODE_LOCALITY": "MADAPPALLY COLLEGE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673103,
      "TXT_PINCODE_LOCALITY": "VADAKARA BEACH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673104,
      "TXT_PINCODE_LOCALITY": "VAYIKKILLASSERI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673105,
      "TXT_PINCODE_LOCALITY": "MANDARATHUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673106,
      "TXT_PINCODE_LOCALITY": "CHORODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673107,
      "TXT_PINCODE_LOCALITY": "MAYYANNUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673108,
      "TXT_PINCODE_LOCALITY": "TODANUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673109,
      "TXT_PINCODE_LOCALITY": "MEPPAYIL EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673110,
      "TXT_PINCODE_LOCALITY": "YMCA CROSS ROAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 490,
      "City District Name": "CALICUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673111,
      "TXT_PINCODE_LOCALITY": "PATHIYARAKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673112,
      "TXT_PINCODE_LOCALITY": "NADAKKUTHALA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673121,
      "TXT_PINCODE_LOCALITY": "KAVUMANNAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1905,
      "City District Name": "WAYANAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673122,
      "TXT_PINCODE_LOCALITY": "VAZHAVATTA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1905,
      "City District Name": "WAYANAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673123,
      "TXT_PINCODE_LOCALITY": "KUNNAMBETTA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1905,
      "City District Name": "WAYANAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673124,
      "TXT_PINCODE_LOCALITY": "KANIYAMBETTA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1905,
      "City District Name": "WAYANAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673125,
      "TXT_PINCODE_LOCALITY": "CHENNALODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1905,
      "City District Name": "WAYANAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673301,
      "TXT_PINCODE_LOCALITY": "CHALIYAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673302,
      "TXT_PINCODE_LOCALITY": "KADALUNDI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673303,
      "TXT_PINCODE_LOCALITY": "VENGALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673304,
      "TXT_PINCODE_LOCALITY": "CHEMANCHERI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673305,
      "TXT_PINCODE_LOCALITY": "QUILANDY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673306,
      "TXT_PINCODE_LOCALITY": "CHELIYA B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673307,
      "TXT_PINCODE_LOCALITY": "MUCHUKUNNU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673308,
      "TXT_PINCODE_LOCALITY": "ONCHIYAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673309,
      "TXT_PINCODE_LOCALITY": "KOROTH ROAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673310,
      "TXT_PINCODE_LOCALITY": "MAHE P.O",
      "NUM_STATE_CD": 164,
      "State Name": "PONDICHERRY",
      "NUM_CITYDISTRICT_CD": 4310,
      "City District Name": "MAHE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673311,
      "TXT_PINCODE_LOCALITY": "NEW MAHE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673312,
      "TXT_PINCODE_LOCALITY": "PERINGADI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673313,
      "TXT_PINCODE_LOCALITY": "OLAVILAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673314,
      "TXT_PINCODE_LOCALITY": "VALLIKUNNU B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673315,
      "TXT_PINCODE_LOCALITY": "CHEEKILODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673316,
      "TXT_PINCODE_LOCALITY": "KARIYAD SOUTH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673317,
      "TXT_PINCODE_LOCALITY": "TALAKADATHUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673318,
      "TXT_PINCODE_LOCALITY": "TIRUVANGOOR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673319,
      "TXT_PINCODE_LOCALITY": "MELUR QUILANDY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673320,
      "TXT_PINCODE_LOCALITY": "NALUTHARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673321,
      "TXT_PINCODE_LOCALITY": "MODAKALLUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673322,
      "TXT_PINCODE_LOCALITY": "ARIKULAM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673323,
      "TXT_PINCODE_LOCALITY": "ULLIYERI EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673324,
      "TXT_PINCODE_LOCALITY": "KEEZHARIYUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673325,
      "TXT_PINCODE_LOCALITY": "MUDADI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673326,
      "TXT_PINCODE_LOCALITY": "NADERI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673327,
      "TXT_PINCODE_LOCALITY": "KUNNATHARA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673328,
      "TXT_PINCODE_LOCALITY": "MANNUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673330,
      "TXT_PINCODE_LOCALITY": "NADUVATHUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673333,
      "TXT_PINCODE_LOCALITY": "PALLOR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673338,
      "TXT_PINCODE_LOCALITY": "KOLATHUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673351,
      "TXT_PINCODE_LOCALITY": "CHEEKILODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673389,
      "TXT_PINCODE_LOCALITY": "THARIOTE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1905,
      "City District Name": "WAYANAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673501,
      "TXT_PINCODE_LOCALITY": "ERAMALA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673502,
      "TXT_PINCODE_LOCALITY": "KACHCHERI B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673503,
      "TXT_PINCODE_LOCALITY": "KUNINGAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673504,
      "TXT_PINCODE_LOCALITY": "PERODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673505,
      "TXT_PINCODE_LOCALITY": "TUNERI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673506,
      "TXT_PINCODE_LOCALITY": "INDIRA NAGAR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673507,
      "TXT_PINCODE_LOCALITY": "MULLAMBATH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673508,
      "TXT_PINCODE_LOCALITY": "KURICHAGAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673509,
      "TXT_PINCODE_LOCALITY": "THANKOTTUR B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673510,
      "TXT_PINCODE_LOCALITY": "VATTOLI EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673511,
      "TXT_PINCODE_LOCALITY": "CHEEKUNNUMMAL EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673512,
      "TXT_PINCODE_LOCALITY": "CHERAPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673513,
      "TXT_PINCODE_LOCALITY": "PASUKKADAVU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673514,
      "TXT_PINCODE_LOCALITY": "IRINGANNUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673515,
      "TXT_PINCODE_LOCALITY": "KODIYURA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673516,
      "TXT_PINCODE_LOCALITY": "VALAYAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1538,
      "City District Name": "VADAKARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673517,
      "TXT_PINCODE_LOCALITY": "KALLUNIRA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673518,
      "TXT_PINCODE_LOCALITY": "VATAYAM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673519,
      "TXT_PINCODE_LOCALITY": "KAYAKKODI EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673520,
      "TXT_PINCODE_LOCALITY": "KURUVANGAD B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673521,
      "TXT_PINCODE_LOCALITY": "AYINIKKAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673522,
      "TXT_PINCODE_LOCALITY": "PURAKAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673523,
      "TXT_PINCODE_LOCALITY": "MUDUVANA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673524,
      "TXT_PINCODE_LOCALITY": "MELADY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673525,
      "TXT_PINCODE_LOCALITY": "PERAMPRA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673526,
      "TXT_PINCODE_LOCALITY": "CHAKKITTAPARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673527,
      "TXT_PINCODE_LOCALITY": "CHERUKKAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673528,
      "TXT_PINCODE_LOCALITY": "POOZHITHODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673529,
      "TXT_PINCODE_LOCALITY": "TIKKOTI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673530,
      "TXT_PINCODE_LOCALITY": "PALAYADNADA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673531,
      "TXT_PINCODE_LOCALITY": "KATALUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673532,
      "TXT_PINCODE_LOCALITY": "KOOTHALI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673533,
      "TXT_PINCODE_LOCALITY": "MENNANYAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673534,
      "TXT_PINCODE_LOCALITY": "MANIPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673535,
      "TXT_PINCODE_LOCALITY": "PALERI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673541,
      "TXT_PINCODE_LOCALITY": "THAROPYIL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673542,
      "TXT_PINCODE_LOCALITY": "KARTHIKAPALLY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673544,
      "TXT_PINCODE_LOCALITY": "AYANCHERI EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 500,
      "City District Name": "KANNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673563,
      "TXT_PINCODE_LOCALITY": "PALLIKKAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673569,
      "TXT_PINCODE_LOCALITY": "ATHIRATTUKUNNU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1905,
      "City District Name": "WAYANAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673570,
      "TXT_PINCODE_LOCALITY": "IIM KOZHIKODE CAMPUS",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673571,
      "TXT_PINCODE_LOCALITY": "KUNNAMANAGALAM CALICUT",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673572,
      "TXT_PINCODE_LOCALITY": "VAVAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673573,
      "TXT_PINCODE_LOCALITY": "TAMARACHERRY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673574,
      "TXT_PINCODE_LOCALITY": "UNNIKULAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673575,
      "TXT_PINCODE_LOCALITY": "VARAMBATTA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1905,
      "City District Name": "WAYANAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673576,
      "TXT_PINCODE_LOCALITY": "VYTHIRI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673577,
      "TXT_PINCODE_LOCALITY": "MEPPADI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1905,
      "City District Name": "WAYANAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673578,
      "TXT_PINCODE_LOCALITY": "VELLARMALA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1905,
      "City District Name": "WAYANAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673579,
      "TXT_PINCODE_LOCALITY": "KABANIGIRI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1905,
      "City District Name": "WAYANAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673580,
      "TXT_PINCODE_LOCALITY": "KUPPAYAKODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673581,
      "TXT_PINCODE_LOCALITY": "VATUVANCHAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673582,
      "TXT_PINCODE_LOCALITY": "KARUVAMPOYIL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673583,
      "TXT_PINCODE_LOCALITY": "MADAVUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673584,
      "TXT_PINCODE_LOCALITY": "MANIPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673585,
      "TXT_PINCODE_LOCALITY": "PUNNASSERI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673586,
      "TXT_PINCODE_LOCALITY": "KAKKAD PUTHUPADI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673587,
      "TXT_PINCODE_LOCALITY": "IYYAD EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673589,
      "TXT_PINCODE_LOCALITY": "THARIOTE EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1905,
      "City District Name": "WAYANAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673591,
      "TXT_PINCODE_LOCALITY": "KARANI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673592,
      "TXT_PINCODE_LOCALITY": "PAMBRA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1905,
      "City District Name": "WAYANAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673593,
      "TXT_PINCODE_LOCALITY": "CHEENGERI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673594,
      "TXT_PINCODE_LOCALITY": "NULPUZHA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1905,
      "City District Name": "WAYANAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673595,
      "TXT_PINCODE_LOCALITY": "PUTHENKUNNU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1905,
      "City District Name": "WAYANAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673596,
      "TXT_PINCODE_LOCALITY": "ATHIRATTUKUNNU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1905,
      "City District Name": "WAYANAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673601,
      "TXT_PINCODE_LOCALITY": "NAYARKUZHI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673602,
      "TXT_PINCODE_LOCALITY": "MUKKAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673603,
      "TXT_PINCODE_LOCALITY": "KALLURUTTY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1905,
      "City District Name": "WAYANAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673604,
      "TXT_PINCODE_LOCALITY": "KULIRAMUTTY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673606,
      "TXT_PINCODE_LOCALITY": "COCHIN S. O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 194,
      "City District Name": "COCHIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673611,
      "TXT_PINCODE_LOCALITY": "KARUVATUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673612,
      "TXT_PINCODE_LOCALITY": "KARIYATHANNAVU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673613,
      "TXT_PINCODE_LOCALITY": "PAVANDUR B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673614,
      "TXT_PINCODE_LOCALITY": "KOTTUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673615,
      "TXT_PINCODE_LOCALITY": "KALLANODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673616,
      "TXT_PINCODE_LOCALITY": "IRIVALLUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673617,
      "TXT_PINCODE_LOCALITY": "MAKKADA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673618,
      "TXT_PINCODE_LOCALITY": "AVITANALLUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673619,
      "TXT_PINCODE_LOCALITY": "KAKKUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673620,
      "TXT_PINCODE_LOCALITY": "QUILANDY BAZAR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673621,
      "TXT_PINCODE_LOCALITY": "KINALUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673622,
      "TXT_PINCODE_LOCALITY": "KURUVATTAR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673623,
      "TXT_PINCODE_LOCALITY": "VATTOLI BAZAR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673624,
      "TXT_PINCODE_LOCALITY": "NOCHAT EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673628,
      "TXT_PINCODE_LOCALITY": "PERUVALLUR B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673631,
      "TXT_PINCODE_LOCALITY": "FAROKE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673632,
      "TXT_PINCODE_LOCALITY": "PETTA VIA.",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 490,
      "City District Name": "CALICUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673633,
      "TXT_PINCODE_LOCALITY": "VAZHAYUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673634,
      "TXT_PINCODE_LOCALITY": "CHELAMBRA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673635,
      "TXT_PINCODE_LOCALITY": "CALICUT UNIVERSITY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673636,
      "TXT_PINCODE_LOCALITY": "PUTHUR PALLIKKAL B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673637,
      "TXT_PINCODE_LOCALITY": "PULIKKAL MALAPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673638,
      "TXT_PINCODE_LOCALITY": "KONDOTTI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673639,
      "TXT_PINCODE_LOCALITY": "VETTILAPPARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673640,
      "TXT_PINCODE_LOCALITY": "VAZHKKAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673641,
      "TXT_PINCODE_LOCALITY": "PALAKKAD MALAPPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673642,
      "TXT_PINCODE_LOCALITY": "MONGAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673643,
      "TXT_PINCODE_LOCALITY": "KARUVANTHURUTHY EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673644,
      "TXT_PINCODE_LOCALITY": "KAVANUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673645,
      "TXT_PINCODE_LOCALITY": "CHERUVAYUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673646,
      "TXT_PINCODE_LOCALITY": "ARIMBRA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673647,
      "TXT_PINCODE_LOCALITY": "KARIPPUR AIR PORT",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673648,
      "TXT_PINCODE_LOCALITY": "MELANGADI EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673649,
      "TXT_PINCODE_LOCALITY": "MORAYUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673650,
      "TXT_PINCODE_LOCALITY": "MUTHUVALLUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673651,
      "TXT_PINCODE_LOCALITY": "VALLUVAMBRAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673652,
      "TXT_PINCODE_LOCALITY": "VILAYIL EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673653,
      "TXT_PINCODE_LOCALITY": "UNNIYALUNGAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 442811,
      "City District Name": "TIRURANGADI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673654,
      "TXT_PINCODE_LOCALITY": "AZHINILAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673655,
      "TXT_PINCODE_LOCALITY": "KOLATHARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673661,
      "TXT_PINCODE_LOCALITY": "MAVOOR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673662,
      "TXT_PINCODE_LOCALITY": "CHERUVADI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 673673,
      "TXT_PINCODE_LOCALITY": "VATTOLI BAZAR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676019,
      "TXT_PINCODE_LOCALITY": "MALAPPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676101,
      "TXT_PINCODE_LOCALITY": "TIRUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676102,
      "TXT_PINCODE_LOCALITY": "PURATHUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676103,
      "TXT_PINCODE_LOCALITY": "KUTIPPALA P O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 425708,
      "City District Name": "MALAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676104,
      "TXT_PINCODE_LOCALITY": "TRIKANDIYUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676105,
      "TXT_PINCODE_LOCALITY": "PACHATTIRI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676106,
      "TXT_PINCODE_LOCALITY": "PONMUNDAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676107,
      "TXT_PINCODE_LOCALITY": "POOKAYIL BAZAR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676108,
      "TXT_PINCODE_LOCALITY": "TRIPRANGODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676109,
      "TXT_PINCODE_LOCALITY": "NIRAMARUDUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676110,
      "TXT_PINCODE_LOCALITY": "CHAMRAVATTOM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676111,
      "TXT_PINCODE_LOCALITY": "CHERIYAMUNDAM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676121,
      "TXT_PINCODE_LOCALITY": "MANJERI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676122,
      "TXT_PINCODE_LOCALITY": "VETTEKODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676123,
      "TXT_PINCODE_LOCALITY": "KARUVAMBRAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676124,
      "TXT_PINCODE_LOCALITY": "PAYYANAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676125,
      "TXT_PINCODE_LOCALITY": "PATHAPIRIYARAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676126,
      "TXT_PINCODE_LOCALITY": "PULPETTA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676127,
      "TXT_PINCODE_LOCALITY": "TRIKKALANGODE EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676176,
      "TXT_PINCODE_LOCALITY": "PULPETTA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676301,
      "TXT_PINCODE_LOCALITY": "ANANTHAVOOR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676302,
      "TXT_PINCODE_LOCALITY": "TANUR MALABAR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676303,
      "TXT_PINCODE_LOCALITY": "PARAPPANANGADI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676304,
      "TXT_PINCODE_LOCALITY": "VENGARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676305,
      "TXT_PINCODE_LOCALITY": "KANNAMANGALAM WEST B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676306,
      "TXT_PINCODE_LOCALITY": "TIRURANGADI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676307,
      "TXT_PINCODE_LOCALITY": "TANALUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676308,
      "TXT_PINCODE_LOCALITY": "VALLYORA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676309,
      "TXT_PINCODE_LOCALITY": "KODINHI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676310,
      "TXT_PINCODE_LOCALITY": "ATHAVANAD EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676311,
      "TXT_PINCODE_LOCALITY": "MOONIYUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676312,
      "TXT_PINCODE_LOCALITY": "ARIYALLUR MBR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676313,
      "TXT_PINCODE_LOCALITY": "OZHUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676314,
      "TXT_PINCODE_LOCALITY": "MOONNIYUR SOUTH B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676315,
      "TXT_PINCODE_LOCALITY": "VARAPPARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 442811,
      "City District Name": "TIRURANGADI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676317,
      "TXT_PINCODE_LOCALITY": "VELIMUKKU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676318,
      "TXT_PINCODE_LOCALITY": "KANNAMANGALAM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676319,
      "TXT_PINCODE_LOCALITY": "CHETTIPADI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676320,
      "TXT_PINCODE_LOCALITY": "TAYYALINGAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676324,
      "TXT_PINCODE_LOCALITY": "VADAKKANGARA B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676329,
      "TXT_PINCODE_LOCALITY": "ARUVACODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676501,
      "TXT_PINCODE_LOCALITY": "EDARIKODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676502,
      "TXT_PINCODE_LOCALITY": "PARAVANNA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676503,
      "TXT_PINCODE_LOCALITY": "KOTTAKKAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676504,
      "TXT_PINCODE_LOCALITY": "KODUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676505,
      "TXT_PINCODE_LOCALITY": "MALAPPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676506,
      "TXT_PINCODE_LOCALITY": "KOOTTILANGADI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676507,
      "TXT_PINCODE_LOCALITY": "MAKKARAPARAMBA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676508,
      "TXT_PINCODE_LOCALITY": "VALAKOLAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676509,
      "TXT_PINCODE_LOCALITY": "MUNDUPARAMBA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676510,
      "TXT_PINCODE_LOCALITY": "RANDATHANI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676511,
      "TXT_PINCODE_LOCALITY": "THENNALA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676512,
      "TXT_PINCODE_LOCALITY": "MATTATHUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676513,
      "TXT_PINCODE_LOCALITY": "IRIMBUZHI EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676514,
      "TXT_PINCODE_LOCALITY": "MELMURI EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676515,
      "TXT_PINCODE_LOCALITY": "OORAKAM KEEZHMURI EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676516,
      "TXT_PINCODE_LOCALITY": "PANAYI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676517,
      "TXT_PINCODE_LOCALITY": "POOKKOTTUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676518,
      "TXT_PINCODE_LOCALITY": "MARAKARA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676519,
      "TXT_PINCODE_LOCALITY": "HAJIARPALLI B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676521,
      "TXT_PINCODE_LOCALITY": "PANTHALLOOR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676522,
      "TXT_PINCODE_LOCALITY": "EDAYUR NORTH B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676523,
      "TXT_PINCODE_LOCALITY": "KARUVARAKUNDU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676525,
      "TXT_PINCODE_LOCALITY": "KALIKAVU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676527,
      "TXT_PINCODE_LOCALITY": "ANCHACHAVIDI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676528,
      "TXT_PINCODE_LOCALITY": "OTHUKUNGAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676541,
      "TXT_PINCODE_LOCALITY": "PANNIPARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676542,
      "TXT_PINCODE_LOCALITY": "MAMPAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676543,
      "TXT_PINCODE_LOCALITY": "KOTTAPALLI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676551,
      "TXT_PINCODE_LOCALITY": "VALAVANNUR B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676552,
      "TXT_PINCODE_LOCALITY": "VALANCHERY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676553,
      "TXT_PINCODE_LOCALITY": "KADAMPUZHA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676554,
      "TXT_PINCODE_LOCALITY": "EDAYUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676555,
      "TXT_PINCODE_LOCALITY": "VALAVANNUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676556,
      "TXT_PINCODE_LOCALITY": "KANMANAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676557,
      "TXT_PINCODE_LOCALITY": "TOLAVANNUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676561,
      "TXT_PINCODE_LOCALITY": "MANGALAM MBR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676562,
      "TXT_PINCODE_LOCALITY": "KUTTAYI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 676808,
      "TXT_PINCODE_LOCALITY": "VALAKOLAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678001,
      "TXT_PINCODE_LOCALITY": "KOTAMBU S.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678002,
      "TXT_PINCODE_LOCALITY": "OLAVAKOT",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678003,
      "TXT_PINCODE_LOCALITY": "KALPATHI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678004,
      "TXT_PINCODE_LOCALITY": "NURANI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678005,
      "TXT_PINCODE_LOCALITY": "CHOKKANATHAPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678006,
      "TXT_PINCODE_LOCALITY": "PALLIPURAM PALAKKAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678007,
      "TXT_PINCODE_LOCALITY": "PUDUSSERI B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678008,
      "TXT_PINCODE_LOCALITY": "PALAKKAD INDUSTRIAL ESTATE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678009,
      "TXT_PINCODE_LOCALITY": "DHONI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678010,
      "TXT_PINCODE_LOCALITY": "SEKAHRIPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678011,
      "TXT_PINCODE_LOCALITY": "AMBIKAPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678012,
      "TXT_PINCODE_LOCALITY": "VADAKKANTHARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678013,
      "TXT_PINCODE_LOCALITY": "KUNNATHUTMEDU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678014,
      "TXT_PINCODE_LOCALITY": "PALAKKAD CITY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678015,
      "TXT_PINCODE_LOCALITY": "KALLEKAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678016,
      "TXT_PINCODE_LOCALITY": "DHONI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678017,
      "TXT_PINCODE_LOCALITY": "KAVALPAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678018,
      "TXT_PINCODE_LOCALITY": "KALLEPULLY EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678019,
      "TXT_PINCODE_LOCALITY": "PIRAYIRI EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678020,
      "TXT_PINCODE_LOCALITY": "TIRUNILAYI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678051,
      "TXT_PINCODE_LOCALITY": "PALAKKAD NDC",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 509,
      "City District Name": "PALGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678101,
      "TXT_PINCODE_LOCALITY": "KUTTIPALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678102,
      "TXT_PINCODE_LOCALITY": "TATTAMANGALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678103,
      "TXT_PINCODE_LOCALITY": "VILAYODI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678104,
      "TXT_PINCODE_LOCALITY": "CHITTUR COLLEGE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678303,
      "TXT_PINCODE_LOCALITY": "AMAYUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678304,
      "TXT_PINCODE_LOCALITY": "CHERUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678501,
      "TXT_PINCODE_LOCALITY": "THANNISSERRI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678502,
      "TXT_PINCODE_LOCALITY": "MANNALUR B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678503,
      "TXT_PINCODE_LOCALITY": "KARIPODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678504,
      "TXT_PINCODE_LOCALITY": "VADAVANUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678505,
      "TXT_PINCODE_LOCALITY": "PALLASSANA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678506,
      "TXT_PINCODE_LOCALITY": "KOLLENGODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678507,
      "TXT_PINCODE_LOCALITY": "GOVINDAPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678508,
      "TXT_PINCODE_LOCALITY": "ELAVANCHERI EDSO NEMMARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678509,
      "TXT_PINCODE_LOCALITY": "PADAGIRI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678510,
      "TXT_PINCODE_LOCALITY": "THIRUVALIYAD B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678511,
      "TXT_PINCODE_LOCALITY": "NELLIAMPATHY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678512,
      "TXT_PINCODE_LOCALITY": "KAKKYUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678513,
      "TXT_PINCODE_LOCALITY": "MANNALUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678514,
      "TXT_PINCODE_LOCALITY": "PANANGATTERI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678531,
      "TXT_PINCODE_LOCALITY": "PERUVAMBA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678532,
      "TXT_PINCODE_LOCALITY": "PATTANCHERRY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678533,
      "TXT_PINCODE_LOCALITY": "MEENAKSHIPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678534,
      "TXT_PINCODE_LOCALITY": "VANDITHAVALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678541,
      "TXT_PINCODE_LOCALITY": "ALATHUR MBR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678542,
      "TXT_PINCODE_LOCALITY": "PERINKULAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678543,
      "TXT_PINCODE_LOCALITY": "VAVULLIAPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678544,
      "TXT_PINCODE_LOCALITY": "NECHUR B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678545,
      "TXT_PINCODE_LOCALITY": "PULLODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678546,
      "TXT_PINCODE_LOCALITY": "ERIMAYUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678547,
      "TXT_PINCODE_LOCALITY": "TARUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678548,
      "TXT_PINCODE_LOCALITY": "PADUR KAVASSERY EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678551,
      "TXT_PINCODE_LOCALITY": "KOTAMBU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678552,
      "TXT_PINCODE_LOCALITY": "PANAYUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678553,
      "TXT_PINCODE_LOCALITY": "THEKKE DESAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678554,
      "TXT_PINCODE_LOCALITY": "NATTUKAL CHITTUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678555,
      "TXT_PINCODE_LOCALITY": "RAMAVARMAPURAM PUDUR.",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678556,
      "TXT_PINCODE_LOCALITY": "PARASSIKAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678557,
      "TXT_PINCODE_LOCALITY": "OZHALAPATHY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678558,
      "TXT_PINCODE_LOCALITY": "VELANTHAVALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678559,
      "TXT_PINCODE_LOCALITY": "KARINGARAPULLY EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678571,
      "TXT_PINCODE_LOCALITY": "MATHUR PALGHAT",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678572,
      "TXT_PINCODE_LOCALITY": "VARODE KOTTAYI EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678573,
      "TXT_PINCODE_LOCALITY": "CHENNANGAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678574,
      "TXT_PINCODE_LOCALITY": "PERINGOTTUKURISSI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678575,
      "TXT_PINCODE_LOCALITY": "PALLANCHATHANNUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678578,
      "TXT_PINCODE_LOCALITY": "AMPARANIRAPEL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678581,
      "TXT_PINCODE_LOCALITY": "MATTATHUKAD B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678582,
      "TXT_PINCODE_LOCALITY": "CHENDAKKI B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678583,
      "TXT_PINCODE_LOCALITY": "NATTUKAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678589,
      "TXT_PINCODE_LOCALITY": "THAVALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678591,
      "TXT_PINCODE_LOCALITY": "PALAKAYAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678592,
      "TXT_PINCODE_LOCALITY": "NOCHCHIPULLI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678593,
      "TXT_PINCODE_LOCALITY": "MUDUKURISSI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678594,
      "TXT_PINCODE_LOCALITY": "MUTTIKULANGARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678595,
      "TXT_PINCODE_LOCALITY": "VAKKADAPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678596,
      "TXT_PINCODE_LOCALITY": "KALLADIKODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678597,
      "TXT_PINCODE_LOCALITY": "KARIMBA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678598,
      "TXT_PINCODE_LOCALITY": "POTTASSERI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678599,
      "TXT_PINCODE_LOCALITY": "KUAMARANPUTHUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678600,
      "TXT_PINCODE_LOCALITY": "ELAKKAD EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678601,
      "TXT_PINCODE_LOCALITY": "VATTAMANNAPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678602,
      "TXT_PINCODE_LOCALITY": "EDATHANATTUKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678603,
      "TXT_PINCODE_LOCALITY": "BHIMANAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678605,
      "TXT_PINCODE_LOCALITY": "KARIKITAMKUNNU EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678606,
      "TXT_PINCODE_LOCALITY": "TIRUVALAMKUNNU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678609,
      "TXT_PINCODE_LOCALITY": "CHETHALLUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678611,
      "TXT_PINCODE_LOCALITY": "EDATHARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678612,
      "TXT_PINCODE_LOCALITY": "PARLI PALGHAT",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678613,
      "TXT_PINCODE_LOCALITY": "MANKARAI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678614,
      "TXT_PINCODE_LOCALITY": "MANKARAI R.S.EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678615,
      "TXT_PINCODE_LOCALITY": "KINAVALLUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678616,
      "TXT_PINCODE_LOCALITY": "TENUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678621,
      "TXT_PINCODE_LOCALITY": "KANJIKODE S.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678622,
      "TXT_PINCODE_LOCALITY": "TENARI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678623,
      "TXT_PINCODE_LOCALITY": "KANJIKODE WEST",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678624,
      "TXT_PINCODE_LOCALITY": "WALAYAR DAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678625,
      "TXT_PINCODE_LOCALITY": "PAMPAMPALLAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678631,
      "TXT_PINCODE_LOCALITY": "PARASSERI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678632,
      "TXT_PINCODE_LOCALITY": "KONIKKAZHI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678633,
      "TXT_PINCODE_LOCALITY": "VETTEKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678634,
      "TXT_PINCODE_LOCALITY": "PUNCHAPADAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678635,
      "TXT_PINCODE_LOCALITY": "CHERAYA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678637,
      "TXT_PINCODE_LOCALITY": "ALANGAD EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678640,
      "TXT_PINCODE_LOCALITY": "KERALASSERY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678641,
      "TXT_PINCODE_LOCALITY": "KALLUR OPM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678642,
      "TXT_PINCODE_LOCALITY": "MANNUR WEST",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678651,
      "TXT_PINCODE_LOCALITY": "ANAKKAL MALAMPUZHA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678661,
      "TXT_PINCODE_LOCALITY": "TUNAKADAVU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678671,
      "TXT_PINCODE_LOCALITY": "VILAYANNUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678681,
      "TXT_PINCODE_LOCALITY": "KOOTTALA KUNISSERI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678682,
      "TXT_PINCODE_LOCALITY": "THANNILAPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678683,
      "TXT_PINCODE_LOCALITY": "VADAKANCHERRY MALABAR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678684,
      "TXT_PINCODE_LOCALITY": "KORANCHIRA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678685,
      "TXT_PINCODE_LOCALITY": "MANNAPRA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678686,
      "TXT_PINCODE_LOCALITY": "KANNAMBRA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678687,
      "TXT_PINCODE_LOCALITY": "THEKKEPOTTA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4110,
      "City District Name": "KOZHIKODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678688,
      "TXT_PINCODE_LOCALITY": "PALLAVUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678689,
      "TXT_PINCODE_LOCALITY": "MANAPADAM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678701,
      "TXT_PINCODE_LOCALITY": "KANNADI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678702,
      "TXT_PINCODE_LOCALITY": "CHITHALI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678703,
      "TXT_PINCODE_LOCALITY": "MELARCODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678704,
      "TXT_PINCODE_LOCALITY": "CHITTALANCHERY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678705,
      "TXT_PINCODE_LOCALITY": "MUDAPPALUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678706,
      "TXT_PINCODE_LOCALITY": "OLINKADAVU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678707,
      "TXT_PINCODE_LOCALITY": "KINASSERI EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678708,
      "TXT_PINCODE_LOCALITY": "MANGALAM DAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678709,
      "TXT_PINCODE_LOCALITY": "ELAVAMPADAM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678710,
      "TXT_PINCODE_LOCALITY": "KANNANUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678721,
      "TXT_PINCODE_LOCALITY": "KUTTANUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678722,
      "TXT_PINCODE_LOCALITY": "TOLANUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678731,
      "TXT_PINCODE_LOCALITY": "PALGHAT INDUSTRIAL ESTATE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678732,
      "TXT_PINCODE_LOCALITY": "KOTTEKAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678733,
      "TXT_PINCODE_LOCALITY": "PUTHUPARIYARAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678752,
      "TXT_PINCODE_LOCALITY": "ELAMBUASSERI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678761,
      "TXT_PINCODE_LOCALITY": "THENGARA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678762,
      "TXT_PINCODE_LOCALITY": "PERIMBADARI EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 678953,
      "TXT_PINCODE_LOCALITY": "PATTANCHERY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 442806,
      "City District Name": "CHITTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679101,
      "TXT_PINCODE_LOCALITY": "OTTAPALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679102,
      "TXT_PINCODE_LOCALITY": "THOTTAKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679103,
      "TXT_PINCODE_LOCALITY": "PALAPPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679104,
      "TXT_PINCODE_LOCALITY": "KANNIYAMPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679105,
      "TXT_PINCODE_LOCALITY": "MAYANNUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679106,
      "TXT_PINCODE_LOCALITY": "SOUTH KONDAZHI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679121,
      "TXT_PINCODE_LOCALITY": "SHORNAUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679122,
      "TXT_PINCODE_LOCALITY": "MUNDAKKOTTUKURISSI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679123,
      "TXT_PINCODE_LOCALITY": "GANESHGIRI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679124,
      "TXT_PINCODE_LOCALITY": "VADANAMKURISSI EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679125,
      "TXT_PINCODE_LOCALITY": "KANAYAM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679301,
      "TXT_PINCODE_LOCALITY": "MANGALAM OTTAPALAM B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679302,
      "TXT_PINCODE_LOCALITY": "PERUR OTTAPPALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679303,
      "TXT_PINCODE_LOCALITY": "PERUMUDIYUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679304,
      "TXT_PINCODE_LOCALITY": "VILATHUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679305,
      "TXT_PINCODE_LOCALITY": "PALLIPURAM PATTAMBI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679306,
      "TXT_PINCODE_LOCALITY": "MELEPATTAMBI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679307,
      "TXT_PINCODE_LOCALITY": "MELMURI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679308,
      "TXT_PINCODE_LOCALITY": "EDAPALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679309,
      "TXT_PINCODE_LOCALITY": "VILAYUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679310,
      "TXT_PINCODE_LOCALITY": "AMAYUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679311,
      "TXT_PINCODE_LOCALITY": "NHANGATTIRI EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679312,
      "TXT_PINCODE_LOCALITY": "PARUDUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679313,
      "TXT_PINCODE_LOCALITY": "KALLADIPETTA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679314,
      "TXT_PINCODE_LOCALITY": "MUDUTHALA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679321,
      "TXT_PINCODE_LOCALITY": "ANGADIPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679322,
      "TXT_PINCODE_LOCALITY": "PERINTALMANNA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679323,
      "TXT_PINCODE_LOCALITY": "PULAMANTHOLE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679324,
      "TXT_PINCODE_LOCALITY": "MANKADA PALLIPURAM B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679325,
      "TXT_PINCODE_LOCALITY": "PATTIKAD MALAPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679326,
      "TXT_PINCODE_LOCALITY": "MELATTUR MALAPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679327,
      "TXT_PINCODE_LOCALITY": "TUVVUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679328,
      "TXT_PINCODE_LOCALITY": "KAPPIL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679329,
      "TXT_PINCODE_LOCALITY": "NILAMBUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679330,
      "TXT_PINCODE_LOCALITY": "NILAMBUR R.S",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679331,
      "TXT_PINCODE_LOCALITY": "EDAKKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679332,
      "TXT_PINCODE_LOCALITY": "AMARAMBALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679333,
      "TXT_PINCODE_LOCALITY": "MANIMOOLY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679334,
      "TXT_PINCODE_LOCALITY": "PATHIRIPADAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679335,
      "TXT_PINCODE_LOCALITY": "NELLAYA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679336,
      "TXT_PINCODE_LOCALITY": "KURUVATTUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679337,
      "TXT_PINCODE_LOCALITY": "KULUKKALLUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679338,
      "TXT_PINCODE_LOCALITY": "KOLATHUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679339,
      "TXT_PINCODE_LOCALITY": "VANIYAMBALM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679340,
      "TXT_PINCODE_LOCALITY": "CHERUKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679341,
      "TXT_PINCODE_LOCALITY": "PERINTALMANNA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 5089,
      "City District Name": "PERINTHALMANNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679342,
      "TXT_PINCODE_LOCALITY": "CHANDAKUNNU EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679343,
      "TXT_PINCODE_LOCALITY": "ERANHIMANGAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679344,
      "TXT_PINCODE_LOCALITY": "KARULAI EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679345,
      "TXT_PINCODE_LOCALITY": "PALEMAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679346,
      "TXT_PINCODE_LOCALITY": "PALLIKUTH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679347,
      "TXT_PINCODE_LOCALITY": "PORUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679348,
      "TXT_PINCODE_LOCALITY": "THIRUVALI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4827,
      "City District Name": "NILAMBUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679350,
      "TXT_PINCODE_LOCALITY": "RAMAPURAM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679351,
      "TXT_PINCODE_LOCALITY": "TIRURKAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679352,
      "TXT_PINCODE_LOCALITY": "TALEKODE WEST EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679353,
      "TXT_PINCODE_LOCALITY": "VELLAYUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679354,
      "TXT_PINCODE_LOCALITY": "UPPADA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679355,
      "TXT_PINCODE_LOCALITY": "CHOKKILAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 4827,
      "City District Name": "NILAMBUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679356,
      "TXT_PINCODE_LOCALITY": "MARAYAMANGALAM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679357,
      "TXT_PINCODE_LOCALITY": "ANAMANGAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679501,
      "TXT_PINCODE_LOCALITY": "PANAMANNA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679502,
      "TXT_PINCODE_LOCALITY": "TRIKATIRI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679503,
      "TXT_PINCODE_LOCALITY": "CHERPALCHERI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679504,
      "TXT_PINCODE_LOCALITY": "VELLINALI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679505,
      "TXT_PINCODE_LOCALITY": "CHALAVARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679506,
      "TXT_PINCODE_LOCALITY": "THEKKUMMURI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679507,
      "TXT_PINCODE_LOCALITY": "MANGOD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679508,
      "TXT_PINCODE_LOCALITY": "PANNIYAMKURISSI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679511,
      "TXT_PINCODE_LOCALITY": "MULANNUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679512,
      "TXT_PINCODE_LOCALITY": "CHERUMUNDASSERI B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679513,
      "TXT_PINCODE_LOCALITY": "SRIKRISHNAPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679514,
      "TXT_PINCODE_LOCALITY": "TIRUNARAYANAPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679515,
      "TXT_PINCODE_LOCALITY": "KADAMBUR PGT",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679516,
      "TXT_PINCODE_LOCALITY": "VENGASSERI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679517,
      "TXT_PINCODE_LOCALITY": "MANNAMPETTA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679518,
      "TXT_PINCODE_LOCALITY": "KOTTAPURAM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679521,
      "TXT_PINCODE_LOCALITY": "MANISSERI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679522,
      "TXT_PINCODE_LOCALITY": "VANIAMKULAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679523,
      "TXT_PINCODE_LOCALITY": "MANNANUR R.S.B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679531,
      "TXT_PINCODE_LOCALITY": "VETTIKATTIRI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679532,
      "TXT_PINCODE_LOCALITY": "PALLUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679533,
      "TXT_PINCODE_LOCALITY": "KUTTANAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679534,
      "TXT_PINCODE_LOCALITY": "OTALUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679535,
      "TXT_PINCODE_LOCALITY": "CHALIYATTIRI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679536,
      "TXT_PINCODE_LOCALITY": "KAVUKODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679537,
      "TXT_PINCODE_LOCALITY": "CHATHANNUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679538,
      "TXT_PINCODE_LOCALITY": "THALAKKASSERRI EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679551,
      "TXT_PINCODE_LOCALITY": "ANAKKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679552,
      "TXT_PINCODE_LOCALITY": "KALLADATHUR, KUMRANALLUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679553,
      "TXT_PINCODE_LOCALITY": "MALAMALKAVU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679554,
      "TXT_PINCODE_LOCALITY": "MALMAKAVU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679556,
      "TXT_PINCODE_LOCALITY": "KANMANAM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679561,
      "TXT_PINCODE_LOCALITY": "PUNNAYURKULAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679562,
      "TXT_PINCODE_LOCALITY": "VADAKKEKAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679563,
      "TXT_PINCODE_LOCALITY": "VAYILATHUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679564,
      "TXT_PINCODE_LOCALITY": "ANDATHODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679565,
      "TXT_PINCODE_LOCALITY": "CHGALIYATTIRI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679571,
      "TXT_PINCODE_LOCALITY": "KUTTIPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679572,
      "TXT_PINCODE_LOCALITY": "IRIMBILIYAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1542,
      "City District Name": "TIRUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679573,
      "TXT_PINCODE_LOCALITY": "TRIKKANAPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679574,
      "TXT_PINCODE_LOCALITY": "PIDAVANNUR B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679575,
      "TXT_PINCODE_LOCALITY": "NANNAMUKKU SOUTH B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679576,
      "TXT_PINCODE_LOCALITY": "PERUMPARAMBA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679577,
      "TXT_PINCODE_LOCALITY": "PONANI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679578,
      "TXT_PINCODE_LOCALITY": "VATTAMKULAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679579,
      "TXT_PINCODE_LOCALITY": "PAZHANJI MALAPPURAM B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679580,
      "TXT_PINCODE_LOCALITY": "PERUMABADAPPU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679581,
      "TXT_PINCODE_LOCALITY": "MARANCHERRI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679582,
      "TXT_PINCODE_LOCALITY": "KADANCHERRI EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679583,
      "TXT_PINCODE_LOCALITY": "PONANI NAGARAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679584,
      "TXT_PINCODE_LOCALITY": "KANHIRAMUKKU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679585,
      "TXT_PINCODE_LOCALITY": "ALANKODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679586,
      "TXT_PINCODE_LOCALITY": "PONANI SOUTH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679587,
      "TXT_PINCODE_LOCALITY": "ERAMANGALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679588,
      "TXT_PINCODE_LOCALITY": "PERASANNUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679589,
      "TXT_PINCODE_LOCALITY": "BIYYAM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679590,
      "TXT_PINCODE_LOCALITY": "PAYINKANNIYUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679591,
      "TXT_PINCODE_LOCALITY": "KOKKUR MALAPPURM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679592,
      "TXT_PINCODE_LOCALITY": "MOOLANKAV B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679593,
      "TXT_PINCODE_LOCALITY": "GRAMAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679594,
      "TXT_PINCODE_LOCALITY": "MOOKUTHALA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 505,
      "City District Name": "MALAPPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679603,
      "TXT_PINCODE_LOCALITY": "VILAYUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 679633,
      "TXT_PINCODE_LOCALITY": "CHAMMANNUR B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480001,
      "TXT_PINCODE_LOCALITY": "SEONI ROAD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480002,
      "TXT_PINCODE_LOCALITY": "CHHINDWARA GULABRA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480003,
      "TXT_PINCODE_LOCALITY": "CHHINDWARA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480024,
      "TXT_PINCODE_LOCALITY": "BATIKAKHAPA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480101,
      "TXT_PINCODE_LOCALITY": "LINGA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480102,
      "TXT_PINCODE_LOCALITY": "RAMAKONA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480103,
      "TXT_PINCODE_LOCALITY": "GANGIWARA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480104,
      "TXT_PINCODE_LOCALITY": "CHANDANGAON",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480105,
      "TXT_PINCODE_LOCALITY": "MOHGAON",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480106,
      "TXT_PINCODE_LOCALITY": "SAUSAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480107,
      "TXT_PINCODE_LOCALITY": "UMARNALA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480108,
      "TXT_PINCODE_LOCALITY": "LODHIKHERA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480109,
      "TXT_PINCODE_LOCALITY": "PIPLA NARAINWAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480110,
      "TXT_PINCODE_LOCALITY": "CHAND",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480111,
      "TXT_PINCODE_LOCALITY": "BICHHUA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480112,
      "TXT_PINCODE_LOCALITY": "MONKHED",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480113,
      "TXT_PINCODE_LOCALITY": "PANDARKHEDI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480114,
      "TXT_PINCODE_LOCALITY": "KHAMRA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480115,
      "TXT_PINCODE_LOCALITY": "CHAURANI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480116,
      "TXT_PINCODE_LOCALITY": "KUNDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480117,
      "TXT_PINCODE_LOCALITY": "KAPURDHA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480121,
      "TXT_PINCODE_LOCALITY": "BISAPUR KALAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 6351,
      "City District Name": "SEONI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480221,
      "TXT_PINCODE_LOCALITY": "AMARWARA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480222,
      "TXT_PINCODE_LOCALITY": "SURLAKHAPA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480223,
      "TXT_PINCODE_LOCALITY": "SINGODI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480224,
      "TXT_PINCODE_LOCALITY": "HARRIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480225,
      "TXT_PINCODE_LOCALITY": "CHANHIA KALAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480228,
      "TXT_PINCODE_LOCALITY": "MOKHED (CHHINDWADA)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480331,
      "TXT_PINCODE_LOCALITY": "SAORI BAZAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480332,
      "TXT_PINCODE_LOCALITY": "GURAIYA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480333,
      "TXT_PINCODE_LOCALITY": "ROHNA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480334,
      "TXT_PINCODE_LOCALITY": "PANDURNA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480336,
      "TXT_PINCODE_LOCALITY": "SIRATHA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480337,
      "TXT_PINCODE_LOCALITY": "SEONI SAUSAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480338,
      "TXT_PINCODE_LOCALITY": "TEEGAON",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480339,
      "TXT_PINCODE_LOCALITY": "MARUD (CHHINDWARD)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480340,
      "TXT_PINCODE_LOCALITY": "RAJIM",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 440630,
      "City District Name": "RAJIM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480341,
      "TXT_PINCODE_LOCALITY": "CHICHOLIVAD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480357,
      "TXT_PINCODE_LOCALITY": "KHAMRA JETHU",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 442826,
      "City District Name": "TAMIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480441,
      "TXT_PINCODE_LOCALITY": "PARASIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480442,
      "TXT_PINCODE_LOCALITY": "UMRETH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480443,
      "TXT_PINCODE_LOCALITY": "DEEGHOWANI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480444,
      "TXT_PINCODE_LOCALITY": "RAKHIKOL - 2115",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480447,
      "TXT_PINCODE_LOCALITY": "CHAND META",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480449,
      "TXT_PINCODE_LOCALITY": "BHAMODI - 358",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480484,
      "TXT_PINCODE_LOCALITY": "CHHAPARA *",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480551,
      "TXT_PINCODE_LOCALITY": "JUNNARDEO",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480553,
      "TXT_PINCODE_LOCALITY": "DUNGARIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480554,
      "TXT_PINCODE_LOCALITY": "NANDAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480555,
      "TXT_PINCODE_LOCALITY": "DAMUA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480556,
      "TXT_PINCODE_LOCALITY": "PAGARA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480557,
      "TXT_PINCODE_LOCALITY": "NEWTON CHIKLI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480559,
      "TXT_PINCODE_LOCALITY": "TAMIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480661,
      "TXT_PINCODE_LOCALITY": "SEPONI MATHWARD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 6351,
      "City District Name": "SEONI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480664,
      "TXT_PINCODE_LOCALITY": "GOPALGANJ",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 6351,
      "City District Name": "SEONI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480667,
      "TXT_PINCODE_LOCALITY": "BARGHAT",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 6351,
      "City District Name": "SEONI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480669,
      "TXT_PINCODE_LOCALITY": "DHAMRNA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 6351,
      "City District Name": "SEONI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480771,
      "TXT_PINCODE_LOCALITY": "ARI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 6351,
      "City District Name": "SEONI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480778,
      "TXT_PINCODE_LOCALITY": "AMAGARH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480880,
      "TXT_PINCODE_LOCALITY": "KORAI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 6351,
      "City District Name": "SEONI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480881,
      "TXT_PINCODE_LOCALITY": "KHAWASA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 6351,
      "City District Name": "SEONI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480882,
      "TXT_PINCODE_LOCALITY": "BANDOL",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 6351,
      "City District Name": "SEONI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480884,
      "TXT_PINCODE_LOCALITY": "CHHAPARA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 6351,
      "City District Name": "SEONI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480885,
      "TXT_PINCODE_LOCALITY": "GANESH GANJ",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 6351,
      "City District Name": "SEONI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480886,
      "TXT_PINCODE_LOCALITY": "LAKHANDON",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 6351,
      "City District Name": "SEONI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480887,
      "TXT_PINCODE_LOCALITY": "ADELGAON",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 6351,
      "City District Name": "SEONI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480888,
      "TXT_PINCODE_LOCALITY": "KHUT-KHAMARIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 6351,
      "City District Name": "SEONI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480890,
      "TXT_PINCODE_LOCALITY": "DHANKAKI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 6351,
      "City District Name": "SEONI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480898,
      "TXT_PINCODE_LOCALITY": "SUNWARA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 6351,
      "City District Name": "SEONI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480990,
      "TXT_PINCODE_LOCALITY": "KANHIWARA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 6351,
      "City District Name": "SEONI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480991,
      "TXT_PINCODE_LOCALITY": "KHAIRA PALRI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 6351,
      "City District Name": "SEONI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480992,
      "TXT_PINCODE_LOCALITY": "BHOMA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 6351,
      "City District Name": "SEONI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480994,
      "TXT_PINCODE_LOCALITY": "KEOLARI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 6351,
      "City District Name": "SEONI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480996,
      "TXT_PINCODE_LOCALITY": "UGLI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 6351,
      "City District Name": "SEONI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480997,
      "TXT_PINCODE_LOCALITY": "GHANSORE",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 6351,
      "City District Name": "SEONI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 480999,
      "TXT_PINCODE_LOCALITY": "KAHANI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 6351,
      "City District Name": "SEONI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481001,
      "TXT_PINCODE_LOCALITY": "BALAGHAT R S",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1550,
      "City District Name": "BALAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481002,
      "TXT_PINCODE_LOCALITY": "AMEDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1550,
      "City District Name": "BALAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481015,
      "TXT_PINCODE_LOCALITY": "MOHGAON BHIMLAT",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1550,
      "City District Name": "BALAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481022,
      "TXT_PINCODE_LOCALITY": "KUMHARI (BALAGHAT)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1550,
      "City District Name": "BALAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481051,
      "TXT_PINCODE_LOCALITY": "BIRSA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1550,
      "City District Name": "BALAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481101,
      "TXT_PINCODE_LOCALITY": "MOHGAON DHAHERA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1550,
      "City District Name": "BALAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481102,
      "TXT_PINCODE_LOCALITY": "BHARVELI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1550,
      "City District Name": "BALAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481105,
      "TXT_PINCODE_LOCALITY": "UKWA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1550,
      "City District Name": "BALAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481106,
      "TXT_PINCODE_LOCALITY": "BHANDERI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1550,
      "City District Name": "BALAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481111,
      "TXT_PINCODE_LOCALITY": "BAIHAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1550,
      "City District Name": "BALAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481115,
      "TXT_PINCODE_LOCALITY": "KIRNAPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1550,
      "City District Name": "BALAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481116,
      "TXT_PINCODE_LOCALITY": "MALANJKHAND",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1550,
      "City District Name": "BALAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481117,
      "TXT_PINCODE_LOCALITY": "GARHI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1550,
      "City District Name": "BALAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481118,
      "TXT_PINCODE_LOCALITY": "MOHAGAON CB",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1550,
      "City District Name": "BALAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481125,
      "TXT_PINCODE_LOCALITY": "DAMOH (BALAGHAT)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1550,
      "City District Name": "BALAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481220,
      "TXT_PINCODE_LOCALITY": "RAJEGAON",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1550,
      "City District Name": "BALAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481222,
      "TXT_PINCODE_LOCALITY": "LANJI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1550,
      "City District Name": "BALAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481224,
      "TXT_PINCODE_LOCALITY": "KARANJA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1550,
      "City District Name": "BALAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481226,
      "TXT_PINCODE_LOCALITY": "HATTA (MANDLA)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1550,
      "City District Name": "BALAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481228,
      "TXT_PINCODE_LOCALITY": "HATTA ROAD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1550,
      "City District Name": "BALAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481302,
      "TXT_PINCODE_LOCALITY": "MEHDIWADA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1550,
      "City District Name": "BALAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481331,
      "TXT_PINCODE_LOCALITY": "WARA SEONI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1550,
      "City District Name": "BALAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481332,
      "TXT_PINCODE_LOCALITY": "BUDBUDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1550,
      "City District Name": "BALAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481333,
      "TXT_PINCODE_LOCALITY": "JARRAH MOHGAON",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1550,
      "City District Name": "BALAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481335,
      "TXT_PINCODE_LOCALITY": "RAMPAILI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1550,
      "City District Name": "BALAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481337,
      "TXT_PINCODE_LOCALITY": "KHAIR LANJI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1550,
      "City District Name": "BALAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481338,
      "TXT_PINCODE_LOCALITY": "ARAMBHA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1550,
      "City District Name": "BALAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481339,
      "TXT_PINCODE_LOCALITY": "AMAI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1550,
      "City District Name": "BALAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481340,
      "TXT_PINCODE_LOCALITY": "BHANDERA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1550,
      "City District Name": "BALAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481343,
      "TXT_PINCODE_LOCALITY": "KATANGJHARI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1550,
      "City District Name": "BALAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481347,
      "TXT_PINCODE_LOCALITY": "KHAIRLANGI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1550,
      "City District Name": "BALAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481440,
      "TXT_PINCODE_LOCALITY": "MEHKEPAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1550,
      "City District Name": "BALAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481441,
      "TXT_PINCODE_LOCALITY": "LALBURRA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1550,
      "City District Name": "BALAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481445,
      "TXT_PINCODE_LOCALITY": "KATANGI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1550,
      "City District Name": "BALAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481449,
      "TXT_PINCODE_LOCALITY": "TIRODI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1550,
      "City District Name": "BALAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481483,
      "TXT_PINCODE_LOCALITY": "BAJAG",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 3036,
      "City District Name": "DINDORI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481551,
      "TXT_PINCODE_LOCALITY": "LAMTA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1550,
      "City District Name": "BALAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481554,
      "TXT_PINCODE_LOCALITY": "NAGARWADA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1550,
      "City District Name": "BALAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481556,
      "TXT_PINCODE_LOCALITY": "PARASWARA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1550,
      "City District Name": "BALAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481584,
      "TXT_PINCODE_LOCALITY": "GORAKHPUR (MANDLA )",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4400,
      "City District Name": "MANDLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481661,
      "TXT_PINCODE_LOCALITY": "MAWAI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4400,
      "City District Name": "MANDLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481662,
      "TXT_PINCODE_LOCALITY": "PADARIA NARAYANGANJ",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4400,
      "City District Name": "MANDLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481663,
      "TXT_PINCODE_LOCALITY": "MOHGAON (MANDLA)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4400,
      "City District Name": "MANDLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481664,
      "TXT_PINCODE_LOCALITY": "GHUGRI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4400,
      "City District Name": "MANDLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481665,
      "TXT_PINCODE_LOCALITY": "MANDLA MAHARAJPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4400,
      "City District Name": "MANDLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481666,
      "TXT_PINCODE_LOCALITY": "AMARPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 3036,
      "City District Name": "DINDORI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481668,
      "TXT_PINCODE_LOCALITY": "PINDRAI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4400,
      "City District Name": "MANDLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481672,
      "TXT_PINCODE_LOCALITY": "CHABI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4400,
      "City District Name": "MANDLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481674,
      "TXT_PINCODE_LOCALITY": "BIJADNAI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4400,
      "City District Name": "MANDLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481676,
      "TXT_PINCODE_LOCALITY": "SEMAR KHAPA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4400,
      "City District Name": "MANDLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481678,
      "TXT_PINCODE_LOCALITY": "MEHDWANI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 3036,
      "City District Name": "DINDORI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481768,
      "TXT_PINCODE_LOCALITY": "CHIRAI DONGRI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4400,
      "City District Name": "MANDLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481771,
      "TXT_PINCODE_LOCALITY": "BAMNI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4400,
      "City District Name": "MANDLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481774,
      "TXT_PINCODE_LOCALITY": "KISLI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4400,
      "City District Name": "MANDLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481776,
      "TXT_PINCODE_LOCALITY": "NAISARAI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4400,
      "City District Name": "MANDLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481778,
      "TXT_PINCODE_LOCALITY": "SAMNAPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4400,
      "City District Name": "MANDLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481879,
      "TXT_PINCODE_LOCALITY": "SHAHPUR (MANDLA)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 3036,
      "City District Name": "DINDORI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481880,
      "TXT_PINCODE_LOCALITY": "DINDORI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 3036,
      "City District Name": "DINDORI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481881,
      "TXT_PINCODE_LOCALITY": "KAKURRAMATH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 3036,
      "City District Name": "DINDORI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481882,
      "TXT_PINCODE_LOCALITY": "GADASARI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 3036,
      "City District Name": "DINDORI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481883,
      "TXT_PINCODE_LOCALITY": "DINDORI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 3036,
      "City District Name": "DINDORI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481884,
      "TXT_PINCODE_LOCALITY": "PARSEL",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 3036,
      "City District Name": "DINDORI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481885,
      "TXT_PINCODE_LOCALITY": "NIWAS",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4400,
      "City District Name": "MANDLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481886,
      "TXT_PINCODE_LOCALITY": "KARANJIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4400,
      "City District Name": "MANDLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481892,
      "TXT_PINCODE_LOCALITY": "BICHHIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 3036,
      "City District Name": "DINDORI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481980,
      "TXT_PINCODE_LOCALITY": "MAWAI (MANDLA)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4400,
      "City District Name": "MANDLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481990,
      "TXT_PINCODE_LOCALITY": "SHAHPURA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 3036,
      "City District Name": "DINDORI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481993,
      "TXT_PINCODE_LOCALITY": "ANJANIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4400,
      "City District Name": "MANDLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481995,
      "TXT_PINCODE_LOCALITY": "BHUABICHHIYA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4400,
      "City District Name": "MANDLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481996,
      "TXT_PINCODE_LOCALITY": "BHIMDONGARI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4400,
      "City District Name": "MANDLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 481998,
      "TXT_PINCODE_LOCALITY": "AURAI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4400,
      "City District Name": "MANDLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 482001,
      "TXT_PINCODE_LOCALITY": "JABALPUR RIDGE",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 464,
      "City District Name": "JABALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 482002,
      "TXT_PINCODE_LOCALITY": "JABALPUR NAGAR NIGAM",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 464,
      "City District Name": "JABALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 482003,
      "TXT_PINCODE_LOCALITY": "JABALPUR GARHA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 464,
      "City District Name": "JABALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 482004,
      "TXT_PINCODE_LOCALITY": "JABALPUR ADHARTAL",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 464,
      "City District Name": "JABALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 482005,
      "TXT_PINCODE_LOCALITY": "JABALPUR KHAMARIA MARKET",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 464,
      "City District Name": "JABALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 482006,
      "TXT_PINCODE_LOCALITY": "JABALPUR TELECOM TRG CENT",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 464,
      "City District Name": "JABALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 482007,
      "TXT_PINCODE_LOCALITY": "JABALPUR HIGH COURT",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 464,
      "City District Name": "JABALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 482008,
      "TXT_PINCODE_LOCALITY": "SHAH NAGAR (VIDYUTNAGAR)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 464,
      "City District Name": "JABALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 482009,
      "TXT_PINCODE_LOCALITY": "JABALPUR VEHICLE FACTORY",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 464,
      "City District Name": "JABALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 482010,
      "TXT_PINCODE_LOCALITY": "JABALPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 464,
      "City District Name": "JABALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 482011,
      "TXT_PINCODE_LOCALITY": "JABALPUR FACTORY",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 464,
      "City District Name": "JABALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 482020,
      "TXT_PINCODE_LOCALITY": "CARAVS COMPLEX",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 464,
      "City District Name": "JABALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 482021,
      "TXT_PINCODE_LOCALITY": "JABALPUR REGIONAL FOREST",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 464,
      "City District Name": "JABALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 482050,
      "TXT_PINCODE_LOCALITY": "JABALPUR BARGI IRRIGATION",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 464,
      "City District Name": "JABALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 482051,
      "TXT_PINCODE_LOCALITY": "JABALPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 464,
      "City District Name": "JABALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 482053,
      "TXT_PINCODE_LOCALITY": "JABALPUR BHEDAGHAT",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 464,
      "City District Name": "JABALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 482056,
      "TXT_PINCODE_LOCALITY": "JABALPUR BARGI NAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 464,
      "City District Name": "JABALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 482110,
      "TXT_PINCODE_LOCALITY": "CHAURAIKALA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 464,
      "City District Name": "JABALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 483001,
      "TXT_PINCODE_LOCALITY": "BARELA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 464,
      "City District Name": "JABALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 483003,
      "TXT_PINCODE_LOCALITY": "AMAHINOTA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 464,
      "City District Name": "JABALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 483050,
      "TXT_PINCODE_LOCALITY": "BARGI IPC",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 464,
      "City District Name": "JABALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 483053,
      "TXT_PINCODE_LOCALITY": "BHERAGHAT",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 464,
      "City District Name": "JABALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 483105,
      "TXT_PINCODE_LOCALITY": "KATANGI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 464,
      "City District Name": "JABALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 483110,
      "TXT_PINCODE_LOCALITY": "KUNDAM",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 464,
      "City District Name": "JABALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 483113,
      "TXT_PINCODE_LOCALITY": "PATAN (JABALPUR)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 464,
      "City District Name": "JABALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 483119,
      "TXT_PINCODE_LOCALITY": "SHAHPURA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 464,
      "City District Name": "JABALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 483220,
      "TXT_PINCODE_LOCALITY": "PANAGAR (JABALPUR)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 464,
      "City District Name": "JABALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 483222,
      "TXT_PINCODE_LOCALITY": "GOSALPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 464,
      "City District Name": "JABALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 483225,
      "TXT_PINCODE_LOCALITY": "SIHORA KHITOLA BAZAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 464,
      "City District Name": "JABALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 483330,
      "TXT_PINCODE_LOCALITY": "BAHORIBAND",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1361,
      "City District Name": "KATNI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 483331,
      "TXT_PINCODE_LOCALITY": "BAKAL",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1361,
      "City District Name": "KATNI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 483332,
      "TXT_PINCODE_LOCALITY": "UMARIAPAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1361,
      "City District Name": "KATNI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 483333,
      "TXT_PINCODE_LOCALITY": "DHEEMAR KHEDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1361,
      "City District Name": "KATNI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 483334,
      "TXT_PINCODE_LOCALITY": "SAROLI MAJHGAWAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 464,
      "City District Name": "JABALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 483336,
      "TXT_PINCODE_LOCALITY": "MAJHOLI (JABALPUR)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 464,
      "City District Name": "JABALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 483440,
      "TXT_PINCODE_LOCALITY": "SLEEMNABAD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1361,
      "City District Name": "KATNI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 483442,
      "TXT_PINCODE_LOCALITY": "NIWAR (JABALPUR)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1361,
      "City District Name": "KATNI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 483445,
      "TXT_PINCODE_LOCALITY": "JABALPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 464,
      "City District Name": "JABALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 483446,
      "TXT_PINCODE_LOCALITY": "MAJHOLA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 464,
      "City District Name": "JABALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 483501,
      "TXT_PINCODE_LOCALITY": "KATNI BAZAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 464,
      "City District Name": "JABALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 483503,
      "TXT_PINCODE_LOCALITY": "KATNI ORDNANCE FACTORY",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1361,
      "City District Name": "KATNI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 483504,
      "TXT_PINCODE_LOCALITY": "KATNI CEMENT FACTORY",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1361,
      "City District Name": "KATNI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 483507,
      "TXT_PINCODE_LOCALITY": "KATNI REFUGEE CAMP",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1361,
      "City District Name": "KATNI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 483770,
      "TXT_PINCODE_LOCALITY": "BARHI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1361,
      "City District Name": "KATNI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 483773,
      "TXT_PINCODE_LOCALITY": "BARWARA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1361,
      "City District Name": "KATNI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 483775,
      "TXT_PINCODE_LOCALITY": "BIJERAGHOGHAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 464,
      "City District Name": "JABALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 483778,
      "TXT_PINCODE_LOCALITY": "KAHANGAON",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1361,
      "City District Name": "KATNI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 483880,
      "TXT_PINCODE_LOCALITY": "KYMARE",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1361,
      "City District Name": "KATNI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 483886,
      "TXT_PINCODE_LOCALITY": "KYMORE",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 464,
      "City District Name": "JABALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 483990,
      "TXT_PINCODE_LOCALITY": "RITHI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1361,
      "City District Name": "KATNI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 484001,
      "TXT_PINCODE_LOCALITY": "SHAHDOL G G",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 475,
      "City District Name": "SHAHDOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 484110,
      "TXT_PINCODE_LOCALITY": "BURHAR - 869",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 475,
      "City District Name": "SHAHDOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 484112,
      "TXT_PINCODE_LOCALITY": "MADHOGARH",
      "NUM_STATE_CD": 65,
      "State Name": "UTTAR PRADESH",
      "NUM_CITYDISTRICT_CD": 4286,
      "City District Name": "MADHOGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 484113,
      "TXT_PINCODE_LOCALITY": "VENKATNAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 475,
      "City District Name": "SHAHDOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 484114,
      "TXT_PINCODE_LOCALITY": "DHANPURI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 475,
      "City District Name": "SHAHDOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 484116,
      "TXT_PINCODE_LOCALITY": "AMLAI COLLIERY",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 475,
      "City District Name": "SHAHDOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 484117,
      "TXT_PINCODE_LOCALITY": "AMLAI PAPER MILL",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 475,
      "City District Name": "SHAHDOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 484120,
      "TXT_PINCODE_LOCALITY": "S.K. NAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 475,
      "City District Name": "SHAHDOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 484220,
      "TXT_PINCODE_LOCALITY": "CHACHAI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 475,
      "City District Name": "SHAHDOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 484224,
      "TXT_PINCODE_LOCALITY": "ANNUPPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 475,
      "City District Name": "SHAHDOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 484330,
      "TXT_PINCODE_LOCALITY": "JAITHARI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 475,
      "City District Name": "SHAHDOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 484334,
      "TXT_PINCODE_LOCALITY": "KOTMA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 475,
      "City District Name": "SHAHDOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 484336,
      "TXT_PINCODE_LOCALITY": "KOTMA COLLIERY - 3668",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 475,
      "City District Name": "SHAHDOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 484440,
      "TXT_PINCODE_LOCALITY": "BIJURI - 471",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 475,
      "City District Name": "SHAHDOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 484444,
      "TXT_PINCODE_LOCALITY": "JAMUNA COLLIERY",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 6050,
      "City District Name": "UMARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 484446,
      "TXT_PINCODE_LOCALITY": "BEEDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 475,
      "City District Name": "SHAHDOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 484447,
      "TXT_PINCODE_LOCALITY": "ANUPPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2087,
      "City District Name": "ANUPPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 484551,
      "TXT_PINCODE_LOCALITY": "BIRSHINHPUR PALI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 6050,
      "City District Name": "UMARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 484552,
      "TXT_PINCODE_LOCALITY": "PALI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 6050,
      "City District Name": "UMARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 484555,
      "TXT_PINCODE_LOCALITY": "NOWROZABAD - 3462",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 6050,
      "City District Name": "UMARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 484660,
      "TXT_PINCODE_LOCALITY": "CHANDIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 6050,
      "City District Name": "UMARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 484661,
      "TXT_PINCODE_LOCALITY": "CHANSURA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 6050,
      "City District Name": "UMARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 484664,
      "TXT_PINCODE_LOCALITY": "MARDARI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4431,
      "City District Name": "MANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 484665,
      "TXT_PINCODE_LOCALITY": "MANPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 475,
      "City District Name": "SHAHDOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 484669,
      "TXT_PINCODE_LOCALITY": "JAITPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 475,
      "City District Name": "SHAHDOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 484770,
      "TXT_PINCODE_LOCALITY": "GOHAPARU",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 475,
      "City District Name": "SHAHDOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 484771,
      "TXT_PINCODE_LOCALITY": "JAISING NAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 475,
      "City District Name": "SHAHDOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 484774,
      "TXT_PINCODE_LOCALITY": "BEOHARI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 475,
      "City District Name": "SHAHDOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 484776,
      "TXT_PINCODE_LOCALITY": "BANSAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 475,
      "City District Name": "SHAHDOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 484881,
      "TXT_PINCODE_LOCALITY": "RAJENDRAGRAM",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 475,
      "City District Name": "SHAHDOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 484886,
      "TXT_PINCODE_LOCALITY": "AMARKANTAK",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2087,
      "City District Name": "ANUPPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 484887,
      "TXT_PINCODE_LOCALITY": "SHAHDOL H.O",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2087,
      "City District Name": "ANUPPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 485001,
      "TXT_PINCODE_LOCALITY": "SANTA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1166,
      "City District Name": "SATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 485002,
      "TXT_PINCODE_LOCALITY": "SATNA JAWAHAR NAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1166,
      "City District Name": "SATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 485005,
      "TXT_PINCODE_LOCALITY": "SATNA BIRLA COLONY",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1166,
      "City District Name": "SATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 485111,
      "TXT_PINCODE_LOCALITY": "RAMVAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1166,
      "City District Name": "SATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 485112,
      "TXT_PINCODE_LOCALITY": "BABUPUR S.O",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1166,
      "City District Name": "SATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 485113,
      "TXT_PINCODE_LOCALITY": "RAGHURAJNAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1166,
      "City District Name": "SATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 485114,
      "TXT_PINCODE_LOCALITY": "SEGAMANIYA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1166,
      "City District Name": "SATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 485115,
      "TXT_PINCODE_LOCALITY": "RAMPUR BAGHELAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1166,
      "City District Name": "SATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 485116,
      "TXT_PINCODE_LOCALITY": "BELA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1166,
      "City District Name": "SATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 485122,
      "TXT_PINCODE_LOCALITY": "KRIPALPUR HARJANTOLA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1166,
      "City District Name": "SATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 485133,
      "TXT_PINCODE_LOCALITY": "MADHOGARH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1166,
      "City District Name": "SATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 485221,
      "TXT_PINCODE_LOCALITY": "JAITWARA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1166,
      "City District Name": "SATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 485226,
      "TXT_PINCODE_LOCALITY": "BIRSINGHPUR - 801",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1166,
      "City District Name": "SATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 485331,
      "TXT_PINCODE_LOCALITY": "MAJGHAWAN (SATNA)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1166,
      "City District Name": "SATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 485334,
      "TXT_PINCODE_LOCALITY": "KAILASHPUR ASHRAM",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1166,
      "City District Name": "SATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 485336,
      "TXT_PINCODE_LOCALITY": "SATNA S. O",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1166,
      "City District Name": "SATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 485344,
      "TXT_PINCODE_LOCALITY": "NAYAGAON",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1166,
      "City District Name": "SATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 485414,
      "TXT_PINCODE_LOCALITY": "ATRA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1166,
      "City District Name": "SATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 485416,
      "TXT_PINCODE_LOCALITY": "BELAHATA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1166,
      "City District Name": "SATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 485441,
      "TXT_PINCODE_LOCALITY": "SOHAWAL",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1166,
      "City District Name": "SATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 485446,
      "TXT_PINCODE_LOCALITY": "NAGOD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1166,
      "City District Name": "SATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 485447,
      "TXT_PINCODE_LOCALITY": "SINGHPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1166,
      "City District Name": "SATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 485551,
      "TXT_PINCODE_LOCALITY": "JASO",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1166,
      "City District Name": "SATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 485661,
      "TXT_PINCODE_LOCALITY": "UNCHEHRA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1166,
      "City District Name": "SATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 485665,
      "TXT_PINCODE_LOCALITY": "BHAINSWAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1166,
      "City District Name": "SATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 485666,
      "TXT_PINCODE_LOCALITY": "KOTHI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1166,
      "City District Name": "SATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 485744,
      "TXT_PINCODE_LOCALITY": "NARWAR KALAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4335,
      "City District Name": "MAIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 485771,
      "TXT_PINCODE_LOCALITY": "MAIHAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1166,
      "City District Name": "SATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 485772,
      "TXT_PINCODE_LOCALITY": "SARLANAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1166,
      "City District Name": "SATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 485773,
      "TXT_PINCODE_LOCALITY": "AMDARA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1166,
      "City District Name": "SATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 485774,
      "TXT_PINCODE_LOCALITY": "NADAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1166,
      "City District Name": "SATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 485775,
      "TXT_PINCODE_LOCALITY": "AMARPATAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1166,
      "City District Name": "SATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 485778,
      "TXT_PINCODE_LOCALITY": "KATHAHA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1166,
      "City District Name": "SATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 485780,
      "TXT_PINCODE_LOCALITY": "POSRAHA.",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 442823,
      "City District Name": "MAJHGAWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 485881,
      "TXT_PINCODE_LOCALITY": "RAMNAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1166,
      "City District Name": "SATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486001,
      "TXT_PINCODE_LOCALITY": "REWA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486002,
      "TXT_PINCODE_LOCALITY": "REWA ENGINEERING COLLEGE",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486003,
      "TXT_PINCODE_LOCALITY": "REWA APS UNIVERSITY",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486004,
      "TXT_PINCODE_LOCALITY": "REWA A P S UNIVERSITY",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486005,
      "TXT_PINCODE_LOCALITY": "REWA BAN SAGAR COLONY",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486006,
      "TXT_PINCODE_LOCALITY": "REWA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486111,
      "TXT_PINCODE_LOCALITY": "MANGAWAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486112,
      "TXT_PINCODE_LOCALITY": "TEONI (REWA)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486113,
      "TXT_PINCODE_LOCALITY": "AMLAHA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486114,
      "TXT_PINCODE_LOCALITY": "RAIPUR (REWAL)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486115,
      "TXT_PINCODE_LOCALITY": "GARH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486117,
      "TXT_PINCODE_LOCALITY": "SONVERSA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486123,
      "TXT_PINCODE_LOCALITY": "MANIKWAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486220,
      "TXT_PINCODE_LOCALITY": "TEONTHAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486223,
      "TXT_PINCODE_LOCALITY": "JAWA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486226,
      "TXT_PINCODE_LOCALITY": "CHAK",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486228,
      "TXT_PINCODE_LOCALITY": "SAHAGI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486331,
      "TXT_PINCODE_LOCALITY": "MAUGANJ (REWA)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486333,
      "TXT_PINCODE_LOCALITY": "DHERA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486335,
      "TXT_PINCODE_LOCALITY": "HANUMANA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486338,
      "TXT_PINCODE_LOCALITY": "RAGHUNATHGANJ",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486340,
      "TXT_PINCODE_LOCALITY": "NAIGARH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486341,
      "TXT_PINCODE_LOCALITY": "DEETALB(REWA)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486342,
      "TXT_PINCODE_LOCALITY": "GAURI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486343,
      "TXT_PINCODE_LOCALITY": "KHAT KHARI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486355,
      "TXT_PINCODE_LOCALITY": "HANUMANA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486440,
      "TXT_PINCODE_LOCALITY": "LAXMANPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486441,
      "TXT_PINCODE_LOCALITY": "BAIKUNTHPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486442,
      "TXT_PINCODE_LOCALITY": "TILKHAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486443,
      "TXT_PINCODE_LOCALITY": "BARON",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486444,
      "TXT_PINCODE_LOCALITY": "MAJGHAWAN (REWE)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486445,
      "TXT_PINCODE_LOCALITY": "SEMARIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486446,
      "TXT_PINCODE_LOCALITY": "BEEDA (REWA)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486447,
      "TXT_PINCODE_LOCALITY": "PATEHRA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486448,
      "TXT_PINCODE_LOCALITY": "SIRMAUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486449,
      "TXT_PINCODE_LOCALITY": "CHACHAI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486450,
      "TXT_PINCODE_LOCALITY": "JAYPEENAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486451,
      "TXT_PINCODE_LOCALITY": "THP SIRMOUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486491,
      "TXT_PINCODE_LOCALITY": "DABRAUHI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5574,
      "City District Name": "SEMARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486497,
      "TXT_PINCODE_LOCALITY": "PATEHARA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486536,
      "TXT_PINCODE_LOCALITY": "MAHASON",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486550,
      "TXT_PINCODE_LOCALITY": "GOVINDGARH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486551,
      "TXT_PINCODE_LOCALITY": "TIKAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486552,
      "TXT_PINCODE_LOCALITY": "KHAJUHAKALAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486553,
      "TXT_PINCODE_LOCALITY": "GURH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486554,
      "TXT_PINCODE_LOCALITY": "DWARI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486556,
      "TXT_PINCODE_LOCALITY": "REWA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486661,
      "TXT_PINCODE_LOCALITY": "SIDDI H O",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 476,
      "City District Name": "SIDHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486662,
      "TXT_PINCODE_LOCALITY": "BELWA BADGAIN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486666,
      "TXT_PINCODE_LOCALITY": "MAJHOLI (SINDHI)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 476,
      "City District Name": "SIDHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486669,
      "TXT_PINCODE_LOCALITY": "MADWAS",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 476,
      "City District Name": "SIDHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486670,
      "TXT_PINCODE_LOCALITY": "AMILIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 476,
      "City District Name": "SIDHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486675,
      "TXT_PINCODE_LOCALITY": "SHAHDOL",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 476,
      "City District Name": "SIDHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486771,
      "TXT_PINCODE_LOCALITY": "CHURHAT",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 476,
      "City District Name": "SIDHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486775,
      "TXT_PINCODE_LOCALITY": "RAMPUR NAIKIN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 476,
      "City District Name": "SIDHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486776,
      "TXT_PINCODE_LOCALITY": "BHARATPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 476,
      "City District Name": "SIDHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486881,
      "TXT_PINCODE_LOCALITY": "JIAWAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 476,
      "City District Name": "SIDHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486882,
      "TXT_PINCODE_LOCALITY": "CHITRANGI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 476,
      "City District Name": "SIDHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486884,
      "TXT_PINCODE_LOCALITY": "NIGAHI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 476,
      "City District Name": "SIDHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486885,
      "TXT_PINCODE_LOCALITY": "VINDHEYA NAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486886,
      "TXT_PINCODE_LOCALITY": "WAIDHAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 476,
      "City District Name": "SIDHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486887,
      "TXT_PINCODE_LOCALITY": "AMLORI PROJECT S.O",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5692,
      "City District Name": "SINGRAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486888,
      "TXT_PINCODE_LOCALITY": "DUDHICHUA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 476,
      "City District Name": "SIDHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486889,
      "TXT_PINCODE_LOCALITY": "JHINGURDAH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5692,
      "City District Name": "SINGRAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486890,
      "TXT_PINCODE_LOCALITY": "JAYANT PROJECT COLLIERY",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 476,
      "City District Name": "SIDHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 486892,
      "TXT_PINCODE_LOCALITY": "GORBI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 476,
      "City District Name": "SIDHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 487001,
      "TXT_PINCODE_LOCALITY": "NARSINGHPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 6288,
      "City District Name": "NARSINGHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 487061,
      "TXT_PINCODE_LOCALITY": "KHURPA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4749,
      "City District Name": "NARSIMHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 487081,
      "TXT_PINCODE_LOCALITY": "MUNGWANI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4749,
      "City District Name": "NARSIMHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 487095,
      "TXT_PINCODE_LOCALITY": "BARETHA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4749,
      "City District Name": "NARSIMHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 487110,
      "TXT_PINCODE_LOCALITY": "SINGHPUR (NARSINGHPUR)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4749,
      "City District Name": "NARSIMHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 487113,
      "TXT_PINCODE_LOCALITY": "THEMI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4749,
      "City District Name": "NARSIMHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 487114,
      "TXT_PINCODE_LOCALITY": "KARAKBEL",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4749,
      "City District Name": "NARSIMHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 487118,
      "TXT_PINCODE_LOCALITY": "GOTEGAON",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4749,
      "City District Name": "NARSIMHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 487141,
      "TXT_PINCODE_LOCALITY": "BAGASPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4749,
      "City District Name": "NARSIMHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 487210,
      "TXT_PINCODE_LOCALITY": "KATHORIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4749,
      "City District Name": "NARSIMHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 487221,
      "TXT_PINCODE_LOCALITY": "KARELI (NARSINGHPUR)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4749,
      "City District Name": "NARSIMHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 487225,
      "TXT_PINCODE_LOCALITY": "AMGAON BADA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4749,
      "City District Name": "NARSIMHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 487315,
      "TXT_PINCODE_LOCALITY": "SUTALA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4749,
      "City District Name": "NARSIMHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 487330,
      "TXT_PINCODE_LOCALITY": "BARMAN S.O",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 6288,
      "City District Name": "NARSINGHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 487332,
      "TXT_PINCODE_LOCALITY": "CHAWAR PATHA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4749,
      "City District Name": "NARSIMHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 487334,
      "TXT_PINCODE_LOCALITY": "DOBHI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4749,
      "City District Name": "NARSIMHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 487337,
      "TXT_PINCODE_LOCALITY": "SAGONI TENDUKHEDA S.O",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 6288,
      "City District Name": "NARSINGHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 487440,
      "TXT_PINCODE_LOCALITY": "BOHANI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4749,
      "City District Name": "NARSIMHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 487441,
      "TXT_PINCODE_LOCALITY": "SIHORA (NARSINGHPUR)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4749,
      "City District Name": "NARSIMHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 487551,
      "TXT_PINCODE_LOCALITY": "GADARWARA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4749,
      "City District Name": "NARSIMHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 487555,
      "TXT_PINCODE_LOCALITY": "KAUDIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4749,
      "City District Name": "NARSIMHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 487558,
      "TXT_PINCODE_LOCALITY": "PALOHA BADA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4749,
      "City District Name": "NARSIMHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 487661,
      "TXT_PINCODE_LOCALITY": "BANWARI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 6288,
      "City District Name": "NARSINGHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 487761,
      "TXT_PINCODE_LOCALITY": "BAMERI KALAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4749,
      "City District Name": "NARSIMHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 487770,
      "TXT_PINCODE_LOCALITY": "CHICHLI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4749,
      "City District Name": "NARSIMHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 487771,
      "TXT_PINCODE_LOCALITY": "BARHABADA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4750,
      "City District Name": "NARSINGHGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 487823,
      "TXT_PINCODE_LOCALITY": "BASURIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4749,
      "City District Name": "NARSIMHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 487881,
      "TXT_PINCODE_LOCALITY": "SALICHAUKA ROAD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4749,
      "City District Name": "NARSIMHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 487885,
      "TXT_PINCODE_LOCALITY": "BARAHBADA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4749,
      "City District Name": "NARSIMHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 488001,
      "TXT_PINCODE_LOCALITY": "PANNA GANDHI CHOWK",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4989,
      "City District Name": "PANNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 488050,
      "TXT_PINCODE_LOCALITY": "KAKRAHTI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4989,
      "City District Name": "PANNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 488051,
      "TXT_PINCODE_LOCALITY": "BARACHH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4989,
      "City District Name": "PANNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 488058,
      "TXT_PINCODE_LOCALITY": "PANNA.",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4989,
      "City District Name": "PANNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 488059,
      "TXT_PINCODE_LOCALITY": "SILI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 442818,
      "City District Name": "GUNNOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 488101,
      "TXT_PINCODE_LOCALITY": "MAIGHAWAN DIAMOND MINES",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4989,
      "City District Name": "PANNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 488102,
      "TXT_PINCODE_LOCALITY": "KHORA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4989,
      "City District Name": "PANNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 488220,
      "TXT_PINCODE_LOCALITY": "AJAIGARH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4989,
      "City District Name": "PANNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 488221,
      "TXT_PINCODE_LOCALITY": "BRIJPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4989,
      "City District Name": "PANNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 488222,
      "TXT_PINCODE_LOCALITY": "NAYAGAON",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4989,
      "City District Name": "PANNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 488333,
      "TXT_PINCODE_LOCALITY": "DHARAMPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4989,
      "City District Name": "PANNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 488441,
      "TXT_PINCODE_LOCALITY": "AMANGANJ",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4989,
      "City District Name": "PANNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 488442,
      "TXT_PINCODE_LOCALITY": "SIMARIYA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4989,
      "City District Name": "PANNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 488443,
      "TXT_PINCODE_LOCALITY": "MADHOPURA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 442825,
      "City District Name": "RAIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 488446,
      "TXT_PINCODE_LOCALITY": "PAWAI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4989,
      "City District Name": "PANNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 488447,
      "TXT_PINCODE_LOCALITY": "HARDUA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4989,
      "City District Name": "PANNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 488448,
      "TXT_PINCODE_LOCALITY": "LAMTARA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4989,
      "City District Name": "PANNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331001,
      "TXT_PINCODE_LOCALITY": "BAGLA SCHOOL ROAD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 246,
      "City District Name": "CHURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331002,
      "TXT_PINCODE_LOCALITY": "MEHRASAR UPADHIYAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 5522,
      "City District Name": "SARDARSHAHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331020,
      "TXT_PINCODE_LOCALITY": "KHASOLI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 246,
      "City District Name": "CHURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331021,
      "TXT_PINCODE_LOCALITY": "RATANNAGAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 246,
      "City District Name": "CHURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331022,
      "TXT_PINCODE_LOCALITY": "ADARSH NAGAR RATANGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 246,
      "City District Name": "CHURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331023,
      "TXT_PINCODE_LOCALITY": "SADULPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 246,
      "City District Name": "CHURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331024,
      "TXT_PINCODE_LOCALITY": "KAYAMSHER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331025,
      "TXT_PINCODE_LOCALITY": "ALSISAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331026,
      "TXT_PINCODE_LOCALITY": "BISHANGARH TAMKORE",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1157,
      "City District Name": "JHUNJHUNU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331027,
      "TXT_PINCODE_LOCALITY": "BISSAU BAZAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 246,
      "City District Name": "CHURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331028,
      "TXT_PINCODE_LOCALITY": "MALSISAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331029,
      "TXT_PINCODE_LOCALITY": "DUDHWA KHARA VILLAGE",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 246,
      "City District Name": "CHURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331030,
      "TXT_PINCODE_LOCALITY": "MAHANSAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331031,
      "TXT_PINCODE_LOCALITY": "SATRA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 246,
      "City District Name": "CHURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331032,
      "TXT_PINCODE_LOCALITY": "CHURU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 246,
      "City District Name": "CHURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331033,
      "TXT_PINCODE_LOCALITY": "SIKAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331034,
      "TXT_PINCODE_LOCALITY": "DUDHWA R S",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 246,
      "City District Name": "CHURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331035,
      "TXT_PINCODE_LOCALITY": "BHORUGRAM",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 246,
      "City District Name": "CHURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331301,
      "TXT_PINCODE_LOCALITY": "RAMPURA BERI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 246,
      "City District Name": "CHURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331302,
      "TXT_PINCODE_LOCALITY": "DHIRWAS",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 246,
      "City District Name": "CHURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331303,
      "TXT_PINCODE_LOCALITY": "SANKHU FORT",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 246,
      "City District Name": "CHURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331304,
      "TXT_PINCODE_LOCALITY": "TARANAGAR BAZAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 246,
      "City District Name": "CHURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331305,
      "TXT_PINCODE_LOCALITY": "HANSPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 246,
      "City District Name": "CHURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331306,
      "TXT_PINCODE_LOCALITY": "BUCHAWAS",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 246,
      "City District Name": "CHURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331307,
      "TXT_PINCODE_LOCALITY": "CHURU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 246,
      "City District Name": "CHURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331402,
      "TXT_PINCODE_LOCALITY": "PULASAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 246,
      "City District Name": "CHURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331403,
      "TXT_PINCODE_LOCALITY": "MOTI CHOWK",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 246,
      "City District Name": "CHURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331404,
      "TXT_PINCODE_LOCALITY": "BHILWARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331411,
      "TXT_PINCODE_LOCALITY": "BHANIPURA CHURU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 246,
      "City District Name": "CHURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331501,
      "TXT_PINCODE_LOCALITY": "BIDASAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 246,
      "City District Name": "CHURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331502,
      "TXT_PINCODE_LOCALITY": "CHHAPAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 246,
      "City District Name": "CHURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331503,
      "TXT_PINCODE_LOCALITY": "CHARWAS",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 246,
      "City District Name": "CHURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331504,
      "TXT_PINCODE_LOCALITY": "GOGASAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 246,
      "City District Name": "CHURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331505,
      "TXT_PINCODE_LOCALITY": "PARIHARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 246,
      "City District Name": "CHURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331506,
      "TXT_PINCODE_LOCALITY": "SALASAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 246,
      "City District Name": "CHURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331507,
      "TXT_PINCODE_LOCALITY": "NAYA BAS SUJANGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 246,
      "City District Name": "CHURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331508,
      "TXT_PINCODE_LOCALITY": "CHURU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 246,
      "City District Name": "CHURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331509,
      "TXT_PINCODE_LOCALITY": "SOBHASAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 246,
      "City District Name": "CHURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331510,
      "TXT_PINCODE_LOCALITY": "LODSAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 246,
      "City District Name": "CHURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331517,
      "TXT_PINCODE_LOCALITY": "SANDWA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 246,
      "City District Name": "CHURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331518,
      "TXT_PINCODE_LOCALITY": "LALGARH CHURU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 246,
      "City District Name": "CHURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331701,
      "TXT_PINCODE_LOCALITY": "SIDHMUKH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 246,
      "City District Name": "CHURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331801,
      "TXT_PINCODE_LOCALITY": "MOMASAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 246,
      "City District Name": "CHURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331802,
      "TXT_PINCODE_LOCALITY": "RAJALDESAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 246,
      "City District Name": "CHURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331803,
      "TXT_PINCODE_LOCALITY": "SRI DUNGARGAH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 246,
      "City District Name": "CHURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331804,
      "TXT_PINCODE_LOCALITY": "BIGGA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 246,
      "City District Name": "CHURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331805,
      "TXT_PINCODE_LOCALITY": "LACHHARASAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 246,
      "City District Name": "CHURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 331811,
      "TXT_PINCODE_LOCALITY": "SUDSAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 246,
      "City District Name": "CHURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332001,
      "TXT_PINCODE_LOCALITY": "SIKAR GANDHINAGAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332002,
      "TXT_PINCODE_LOCALITY": "NETARWAS",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332021,
      "TXT_PINCODE_LOCALITY": "BAJAJGRAM SIKAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332022,
      "TXT_PINCODE_LOCALITY": "DHOD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332023,
      "TXT_PINCODE_LOCALITY": "KHOOR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332024,
      "TXT_PINCODE_LOCALITY": "KATRATHAL",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332025,
      "TXT_PINCODE_LOCALITY": "LOSAL",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332026,
      "TXT_PINCODE_LOCALITY": "NECHWA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332027,
      "TXT_PINCODE_LOCALITY": "RAGHUNATHGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332028,
      "TXT_PINCODE_LOCALITY": "SIHOT BADI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332029,
      "TXT_PINCODE_LOCALITY": "GANERI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332030,
      "TXT_PINCODE_LOCALITY": "SINGRAWAT",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332031,
      "TXT_PINCODE_LOCALITY": "KUDAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332032,
      "TXT_PINCODE_LOCALITY": "DUJOD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332033,
      "TXT_PINCODE_LOCALITY": "SIHOT CHHOTI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332034,
      "TXT_PINCODE_LOCALITY": "R C P COLONY",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332035,
      "TXT_PINCODE_LOCALITY": "HARASH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332036,
      "TXT_PINCODE_LOCALITY": "DADIA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332037,
      "TXT_PINCODE_LOCALITY": "BANURA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332038,
      "TXT_PINCODE_LOCALITY": "GUNGARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332039,
      "TXT_PINCODE_LOCALITY": "KASLI SIKAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332040,
      "TXT_PINCODE_LOCALITY": "KOLIDA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332041,
      "TXT_PINCODE_LOCALITY": "PHAGALWA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332042,
      "TXT_PINCODE_LOCALITY": "TATONWA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332043,
      "TXT_PINCODE_LOCALITY": "GARODA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332044,
      "TXT_PINCODE_LOCALITY": "MANGLOONA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332045,
      "TXT_PINCODE_LOCALITY": "BERI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332301,
      "TXT_PINCODE_LOCALITY": "FATEHPUR H O SEKHAWATI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332302,
      "TXT_PINCODE_LOCALITY": "DHANDHAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332303,
      "TXT_PINCODE_LOCALITY": "BESAWA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332304,
      "TXT_PINCODE_LOCALITY": "ROLSABSAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332305,
      "TXT_PINCODE_LOCALITY": "SADINSAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332306,
      "TXT_PINCODE_LOCALITY": "NABIPURA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332307,
      "TXT_PINCODE_LOCALITY": "TIHAWALI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332308,
      "TXT_PINCODE_LOCALITY": "SEKHISAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332311,
      "TXT_PINCODE_LOCALITY": "LACHHMANGARH BAZAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332312,
      "TXT_PINCODE_LOCALITY": "PATODA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332313,
      "TXT_PINCODE_LOCALITY": "KHURI BADI SIKAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332314,
      "TXT_PINCODE_LOCALITY": "DHALIAWAS",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332315,
      "TXT_PINCODE_LOCALITY": "JANKIPURA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332316,
      "TXT_PINCODE_LOCALITY": "DOODWA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332317,
      "TXT_PINCODE_LOCALITY": "RORUBADI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332318,
      "TXT_PINCODE_LOCALITY": "JAJOD FATEHPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332333,
      "TXT_PINCODE_LOCALITY": "CITY",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332401,
      "TXT_PINCODE_LOCALITY": "BALARAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332402,
      "TXT_PINCODE_LOCALITY": "GOVINDPURA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332403,
      "TXT_PINCODE_LOCALITY": "RANOLI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332404,
      "TXT_PINCODE_LOCALITY": "RINGAS",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332405,
      "TXT_PINCODE_LOCALITY": "SHISHU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332406,
      "TXT_PINCODE_LOCALITY": "KOCHHOR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332407,
      "TXT_PINCODE_LOCALITY": "JETUSAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332408,
      "TXT_PINCODE_LOCALITY": "MALIKPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332409,
      "TXT_PINCODE_LOCALITY": "LAKHNI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332410,
      "TXT_PINCODE_LOCALITY": "BHOPATPURA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332411,
      "TXT_PINCODE_LOCALITY": "BAORI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332412,
      "TXT_PINCODE_LOCALITY": "CHURI MIYAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332413,
      "TXT_PINCODE_LOCALITY": "DADIA RAMPURA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332601,
      "TXT_PINCODE_LOCALITY": "BAYA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332602,
      "TXT_PINCODE_LOCALITY": "KHATU SHYAMJI KI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332603,
      "TXT_PINCODE_LOCALITY": "MEHRAULI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332604,
      "TXT_PINCODE_LOCALITY": "SIKAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332605,
      "TXT_PINCODE_LOCALITY": "SIKAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332701,
      "TXT_PINCODE_LOCALITY": "AJMERI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332702,
      "TXT_PINCODE_LOCALITY": "DANTA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332703,
      "TXT_PINCODE_LOCALITY": "DANTA RAMGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332704,
      "TXT_PINCODE_LOCALITY": "SIKAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332705,
      "TXT_PINCODE_LOCALITY": "GANWARI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332706,
      "TXT_PINCODE_LOCALITY": "CHALA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332707,
      "TXT_PINCODE_LOCALITY": "JHARLI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332708,
      "TXT_PINCODE_LOCALITY": "KANWAT TOWN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332709,
      "TXT_PINCODE_LOCALITY": "NEHRU PARK KHANDELA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332710,
      "TXT_PINCODE_LOCALITY": "KARAD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332711,
      "TXT_PINCODE_LOCALITY": "MONDA R S",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332712,
      "TXT_PINCODE_LOCALITY": "NATHUSAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332713,
      "TXT_PINCODE_LOCALITY": "NIM KA THANA TOWN H O",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332714,
      "TXT_PINCODE_LOCALITY": "SIROHI SIKAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332715,
      "TXT_PINCODE_LOCALITY": "SRI MADHOPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332716,
      "TXT_PINCODE_LOCALITY": "SEFRAGUWAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1157,
      "City District Name": "JHUNJHUNU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332717,
      "TXT_PINCODE_LOCALITY": "JHUNJHUNU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1157,
      "City District Name": "JHUNJHUNU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332718,
      "TXT_PINCODE_LOCALITY": "PATAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332719,
      "TXT_PINCODE_LOCALITY": "THOI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332721,
      "TXT_PINCODE_LOCALITY": "GURARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332722,
      "TXT_PINCODE_LOCALITY": "JANKIPURA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332723,
      "TXT_PINCODE_LOCALITY": "SIKAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332724,
      "TXT_PINCODE_LOCALITY": "KARAULI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3800,
      "City District Name": "KARAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332725,
      "TXT_PINCODE_LOCALITY": "SIKAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332726,
      "TXT_PINCODE_LOCALITY": "SIKAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332727,
      "TXT_PINCODE_LOCALITY": "SIKAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332728,
      "TXT_PINCODE_LOCALITY": "SIKAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332730,
      "TXT_PINCODE_LOCALITY": "JALPALI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 245,
      "City District Name": "BIKANER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332731,
      "TXT_PINCODE_LOCALITY": "SIKAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332732,
      "TXT_PINCODE_LOCALITY": "PURANI CHHAWNI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332733,
      "TXT_PINCODE_LOCALITY": "SIKAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332734,
      "TXT_PINCODE_LOCALITY": "SIKAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332735,
      "TXT_PINCODE_LOCALITY": "SIKAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332736,
      "TXT_PINCODE_LOCALITY": "SIKAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332737,
      "TXT_PINCODE_LOCALITY": "SIKAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332738,
      "TXT_PINCODE_LOCALITY": "CHAUKARI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332739,
      "TXT_PINCODE_LOCALITY": "SIKAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332740,
      "TXT_PINCODE_LOCALITY": "KOTRI SIKAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332741,
      "TXT_PINCODE_LOCALITY": "SIKAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332742,
      "TXT_PINCODE_LOCALITY": "MANDHA SURERA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332743,
      "TXT_PINCODE_LOCALITY": "HASSAMPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332744,
      "TXT_PINCODE_LOCALITY": "JEELO",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332745,
      "TXT_PINCODE_LOCALITY": "SIKAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332746,
      "TXT_PINCODE_LOCALITY": "SHIMALA S.O",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 440305,
      "City District Name": "JHUJHUNU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332747,
      "TXT_PINCODE_LOCALITY": "SIKAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 332748,
      "TXT_PINCODE_LOCALITY": "GORIR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333001,
      "TXT_PINCODE_LOCALITY": "JHUNJHUNU H O",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333011,
      "TXT_PINCODE_LOCALITY": "DHANURI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333012,
      "TXT_PINCODE_LOCALITY": "TITANWAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333014,
      "TXT_PINCODE_LOCALITY": "BAKHTAWARPURA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333015,
      "TXT_PINCODE_LOCALITY": "JHUNJHUNU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1157,
      "City District Name": "JHUNJHUNU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333016,
      "TXT_PINCODE_LOCALITY": "JHUNJHUNU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1157,
      "City District Name": "JHUNJHUNU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333021,
      "TXT_PINCODE_LOCALITY": "BINJUSAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333022,
      "TXT_PINCODE_LOCALITY": "GUDHA GORJI KA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333023,
      "TXT_PINCODE_LOCALITY": "BAGAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333024,
      "TXT_PINCODE_LOCALITY": "ISLAMPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333025,
      "TXT_PINCODE_LOCALITY": "MANDRELLA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333026,
      "TXT_PINCODE_LOCALITY": "BARAU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333027,
      "TXT_PINCODE_LOCALITY": "ARDAWATA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333028,
      "TXT_PINCODE_LOCALITY": "SULTANA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333029,
      "TXT_PINCODE_LOCALITY": "SURAJGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333030,
      "TXT_PINCODE_LOCALITY": "KAJARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333031,
      "TXT_PINCODE_LOCALITY": "V. V. PILANI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333032,
      "TXT_PINCODE_LOCALITY": "BHORKI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333033,
      "TXT_PINCODE_LOCALITY": "JAKHOD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333034,
      "TXT_PINCODE_LOCALITY": "KUHARWAS",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333035,
      "TXT_PINCODE_LOCALITY": "NARHAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333036,
      "TXT_PINCODE_LOCALITY": "MEHAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 182,
      "City District Name": "UDAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333037,
      "TXT_PINCODE_LOCALITY": "JHUNJHUNU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1157,
      "City District Name": "JHUNJHUNU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333038,
      "TXT_PINCODE_LOCALITY": "ADUKA S. O",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1157,
      "City District Name": "JHUNJHUNU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333039,
      "TXT_PINCODE_LOCALITY": "JHUNJHUNU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1157,
      "City District Name": "JHUNJHUNU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333040,
      "TXT_PINCODE_LOCALITY": "JHUNJHUNU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1157,
      "City District Name": "JHUNJHUNU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333041,
      "TXT_PINCODE_LOCALITY": "NUA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333042,
      "TXT_PINCODE_LOCALITY": "NANSAGATE NAVALGAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1157,
      "City District Name": "JHUNJHUNU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333043,
      "TXT_PINCODE_LOCALITY": "TARPURA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333044,
      "TXT_PINCODE_LOCALITY": "JHUNJHUNU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1157,
      "City District Name": "JHUNJHUNU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333045,
      "TXT_PINCODE_LOCALITY": "JHUNJHUNU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1157,
      "City District Name": "JHUNJHUNU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333046,
      "TXT_PINCODE_LOCALITY": "JHUNJHUNU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1157,
      "City District Name": "JHUNJHUNU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333047,
      "TXT_PINCODE_LOCALITY": "JHUNJHUNU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1157,
      "City District Name": "JHUNJHUNU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333048,
      "TXT_PINCODE_LOCALITY": "BAI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1157,
      "City District Name": "JHUNJHUNU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333052,
      "TXT_PINCODE_LOCALITY": "JHUNJHUNU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1157,
      "City District Name": "JHUNJHUNU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333053,
      "TXT_PINCODE_LOCALITY": "PONKH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333301,
      "TXT_PINCODE_LOCALITY": "JHUNJHUNU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1157,
      "City District Name": "JHUNJHUNU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333302,
      "TXT_PINCODE_LOCALITY": "CHHAPOLI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333303,
      "TXT_PINCODE_LOCALITY": "CHIRANA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333304,
      "TXT_PINCODE_LOCALITY": "JHAJHAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333305,
      "TXT_PINCODE_LOCALITY": "KARI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333306,
      "TXT_PINCODE_LOCALITY": "JHUNJHUNU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1157,
      "City District Name": "JHUNJHUNU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333307,
      "TXT_PINCODE_LOCALITY": "INDERPURA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333308,
      "TXT_PINCODE_LOCALITY": "NAYA PARASRAMPURA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1157,
      "City District Name": "JHUNJHUNU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333309,
      "TXT_PINCODE_LOCALITY": "JHUNJHUNU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1157,
      "City District Name": "JHUNJHUNU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333310,
      "TXT_PINCODE_LOCALITY": "JHUNJHUNU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1157,
      "City District Name": "JHUNJHUNU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333311,
      "TXT_PINCODE_LOCALITY": "JHUNJHUNU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1157,
      "City District Name": "JHUNJHUNU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333312,
      "TXT_PINCODE_LOCALITY": "BUGALA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333313,
      "TXT_PINCODE_LOCALITY": "DHAMORA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333314,
      "TXT_PINCODE_LOCALITY": "SIGNORE",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333315,
      "TXT_PINCODE_LOCALITY": "JHUNJHUNU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1157,
      "City District Name": "JHUNJHUNU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333412,
      "TXT_PINCODE_LOCALITY": "CHURI MIYAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1012,
      "City District Name": "SIKAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333501,
      "TXT_PINCODE_LOCALITY": "BABAI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333502,
      "TXT_PINCODE_LOCALITY": "BUHANA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333503,
      "TXT_PINCODE_LOCALITY": "KHETRI BAZAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1157,
      "City District Name": "JHUNJHUNU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333504,
      "TXT_PINCODE_LOCALITY": "KHETRINAGAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333514,
      "TXT_PINCODE_LOCALITY": "JASRAPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333515,
      "TXT_PINCODE_LOCALITY": "PACHERI BADI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333516,
      "TXT_PINCODE_LOCALITY": "SINGHANA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333517,
      "TXT_PINCODE_LOCALITY": "JHUNJHUNU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1157,
      "City District Name": "JHUNJHUNU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333701,
      "TXT_PINCODE_LOCALITY": "CHURI AJITGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333702,
      "TXT_PINCODE_LOCALITY": "DUNDLOD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333703,
      "TXT_PINCODE_LOCALITY": "JHUNJHUNU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1157,
      "City District Name": "JHUNJHUNU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333704,
      "TXT_PINCODE_LOCALITY": "MANDAWA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333705,
      "TXT_PINCODE_LOCALITY": "MUKANDGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333706,
      "TXT_PINCODE_LOCALITY": "JHUNJHUNU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1157,
      "City District Name": "JHUNJHUNU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333707,
      "TXT_PINCODE_LOCALITY": "JEJUSAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3597,
      "City District Name": "JHUNJHUNUN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333709,
      "TXT_PINCODE_LOCALITY": "JHUNJHUNU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1157,
      "City District Name": "JHUNJHUNU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 333801,
      "TXT_PINCODE_LOCALITY": "JHUJHUNU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 440305,
      "City District Name": "JHUJHUNU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 334001,
      "TXT_PINCODE_LOCALITY": "SITLA GATE BIKANER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 245,
      "City District Name": "BIKANER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 334002,
      "TXT_PINCODE_LOCALITY": "BIKANER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 245,
      "City District Name": "BIKANER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 334003,
      "TXT_PINCODE_LOCALITY": "PAWANPURI S.O (BIKANER)",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 245,
      "City District Name": "BIKANER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 334004,
      "TXT_PINCODE_LOCALITY": "BANGALANAGAR S.O",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 245,
      "City District Name": "BIKANER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 334005,
      "TXT_PINCODE_LOCALITY": "CHAK GARBI (RURAL)",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 245,
      "City District Name": "BIKANER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 334006,
      "TXT_PINCODE_LOCALITY": "BICHHWAL",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 245,
      "City District Name": "BIKANER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 334021,
      "TXT_PINCODE_LOCALITY": "CHHATARGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 245,
      "City District Name": "BIKANER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 334022,
      "TXT_PINCODE_LOCALITY": "UDASAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 245,
      "City District Name": "BIKANER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 334023,
      "TXT_PINCODE_LOCALITY": "KHAJUWALA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 245,
      "City District Name": "BIKANER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 334201,
      "TXT_PINCODE_LOCALITY": "NAPASAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 245,
      "City District Name": "BIKANER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 334202,
      "TXT_PINCODE_LOCALITY": "SINTHAL",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 245,
      "City District Name": "BIKANER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 334301,
      "TXT_PINCODE_LOCALITY": "BIKANER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 245,
      "City District Name": "BIKANER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 334302,
      "TXT_PINCODE_LOCALITY": "SRI KOLAYATJI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 245,
      "City District Name": "BIKANER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 334303,
      "TXT_PINCODE_LOCALITY": "DIATRA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 245,
      "City District Name": "BIKANER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 334304,
      "TXT_PINCODE_LOCALITY": "BIKANER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 245,
      "City District Name": "BIKANER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 334305,
      "TXT_PINCODE_LOCALITY": "BAJJU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 245,
      "City District Name": "BIKANER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 334401,
      "TXT_PINCODE_LOCALITY": "NAI LINE GANGASHAHAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 245,
      "City District Name": "BIKANER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 334402,
      "TXT_PINCODE_LOCALITY": "PALANA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 245,
      "City District Name": "BIKANER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 334403,
      "TXT_PINCODE_LOCALITY": "BHINASAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 245,
      "City District Name": "BIKANER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 334404,
      "TXT_PINCODE_LOCALITY": "BIKANER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 245,
      "City District Name": "BIKANER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 334601,
      "TXT_PINCODE_LOCALITY": "JAMSAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 245,
      "City District Name": "BIKANER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 334602,
      "TXT_PINCODE_LOCALITY": "KALU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 245,
      "City District Name": "BIKANER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 334603,
      "TXT_PINCODE_LOCALITY": "LUNKARANSAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 245,
      "City District Name": "BIKANER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 334604,
      "TXT_PINCODE_LOCALITY": "MAHAJAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 245,
      "City District Name": "BIKANER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 334801,
      "TXT_PINCODE_LOCALITY": "DESHNOK",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 245,
      "City District Name": "BIKANER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 334802,
      "TXT_PINCODE_LOCALITY": "HIMATSAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 245,
      "City District Name": "BIKANER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 334803,
      "TXT_PINCODE_LOCALITY": "NOKHA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 245,
      "City District Name": "BIKANER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 334804,
      "TXT_PINCODE_LOCALITY": "PANCHU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 245,
      "City District Name": "BIKANER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 334808,
      "TXT_PINCODE_LOCALITY": "PUGAL",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 245,
      "City District Name": "BIKANER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335001,
      "TXT_PINCODE_LOCALITY": "SRIGANGANAGAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1354,
      "City District Name": "SRIGANGANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335002,
      "TXT_PINCODE_LOCALITY": "MAHIYANWALI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3184,
      "City District Name": "GANGANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335009,
      "TXT_PINCODE_LOCALITY": "CHAK 19 PS",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3184,
      "City District Name": "GANGANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335021,
      "TXT_PINCODE_LOCALITY": "BISHANPURA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3184,
      "City District Name": "GANGANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335022,
      "TXT_PINCODE_LOCALITY": "CHUNAWADH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3184,
      "City District Name": "GANGANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335023,
      "TXT_PINCODE_LOCALITY": "DALPATSINGHPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1584,
      "City District Name": "HANUMANGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335024,
      "TXT_PINCODE_LOCALITY": "GAJSINGHPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3184,
      "City District Name": "GANGANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335025,
      "TXT_PINCODE_LOCALITY": "GANESHGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3184,
      "City District Name": "GANGANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335026,
      "TXT_PINCODE_LOCALITY": "GANGUWALA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3184,
      "City District Name": "GANGANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335027,
      "TXT_PINCODE_LOCALITY": "KESHRISINGHPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3184,
      "City District Name": "GANGANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335037,
      "TXT_PINCODE_LOCALITY": "LALGARH JATAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3184,
      "City District Name": "GANGANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335038,
      "TXT_PINCODE_LOCALITY": "MIRZAWALA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3184,
      "City District Name": "GANGANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335039,
      "TXT_PINCODE_LOCALITY": "MUK LAWA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3184,
      "City District Name": "GANGANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335040,
      "TXT_PINCODE_LOCALITY": "NIZAMPURA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3184,
      "City District Name": "GANGANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335041,
      "TXT_PINCODE_LOCALITY": "PADAMPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3184,
      "City District Name": "GANGANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335042,
      "TXT_PINCODE_LOCALITY": "DABLA SRI GANGANAGAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3184,
      "City District Name": "GANGANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335043,
      "TXT_PINCODE_LOCALITY": "MATILI RATHAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3184,
      "City District Name": "GANGANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335051,
      "TXT_PINCODE_LOCALITY": "RAISINGHANGAR CITY",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3184,
      "City District Name": "GANGANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335061,
      "TXT_PINCODE_LOCALITY": "RIDMALSAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3184,
      "City District Name": "GANGANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335062,
      "TXT_PINCODE_LOCALITY": "SADULSHAHAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3184,
      "City District Name": "GANGANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335063,
      "TXT_PINCODE_LOCALITY": "AMARPURA JALUKHAT",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 819,
      "City District Name": "BANSWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335064,
      "TXT_PINCODE_LOCALITY": "DHOLIPAL",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3184,
      "City District Name": "GANGANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335065,
      "TXT_PINCODE_LOCALITY": "MANAKSAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3184,
      "City District Name": "GANGANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335073,
      "TXT_PINCODE_LOCALITY": "SRI KARANPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3184,
      "City District Name": "GANGANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335074,
      "TXT_PINCODE_LOCALITY": "NAGGI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3184,
      "City District Name": "GANGANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335107,
      "TXT_PINCODE_LOCALITY": "CHAK 13 MD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1584,
      "City District Name": "HANUMANGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335404,
      "TXT_PINCODE_LOCALITY": "GHARSANA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1584,
      "City District Name": "HANUMANGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335501,
      "TXT_PINCODE_LOCALITY": "BAHADRA BAZAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3184,
      "City District Name": "GANGANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335502,
      "TXT_PINCODE_LOCALITY": "KALANA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1584,
      "City District Name": "HANUMANGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335503,
      "TXT_PINCODE_LOCALITY": "DABRI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1584,
      "City District Name": "HANUMANGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335504,
      "TXT_PINCODE_LOCALITY": "GOGAMERI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1584,
      "City District Name": "HANUMANGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335511,
      "TXT_PINCODE_LOCALITY": "CHHANI BARI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1584,
      "City District Name": "HANUMANGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335512,
      "TXT_PINCODE_LOCALITY": "HANUMANGARH JN COURT",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3184,
      "City District Name": "GANGANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335513,
      "TXT_PINCODE_LOCALITY": "HANUMANGARH S. O",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1584,
      "City District Name": "HANUMANGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335514,
      "TXT_PINCODE_LOCALITY": "MALKHERA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1584,
      "City District Name": "HANUMANGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335515,
      "TXT_PINCODE_LOCALITY": "GANGANAGAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3184,
      "City District Name": "GANGANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335523,
      "TXT_PINCODE_LOCALITY": "NOHAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1584,
      "City District Name": "HANUMANGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335524,
      "TXT_PINCODE_LOCALITY": "RAWATSAR SRI GANGANAGAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1584,
      "City District Name": "HANUMANGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335525,
      "TXT_PINCODE_LOCALITY": "TALWARA JHIL",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1584,
      "City District Name": "HANUMANGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335526,
      "TXT_PINCODE_LOCALITY": "TIBI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1584,
      "City District Name": "HANUMANGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335527,
      "TXT_PINCODE_LOCALITY": "PHEPHANA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1584,
      "City District Name": "HANUMANGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335528,
      "TXT_PINCODE_LOCALITY": "JASANA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1584,
      "City District Name": "HANUMANGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335701,
      "TXT_PINCODE_LOCALITY": "ANUPGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3184,
      "City District Name": "GANGANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335702,
      "TXT_PINCODE_LOCALITY": "JETSAR FARM",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3184,
      "City District Name": "GANGANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335703,
      "TXT_PINCODE_LOCALITY": "RAMSINGHPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1584,
      "City District Name": "HANUMANGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335704,
      "TXT_PINCODE_LOCALITY": "SRI BIJEYNAGAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3184,
      "City District Name": "GANGANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335705,
      "TXT_PINCODE_LOCALITY": "SARDARGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1584,
      "City District Name": "HANUMANGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335706,
      "TXT_PINCODE_LOCALITY": "GANGANAGAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3184,
      "City District Name": "GANGANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335707,
      "TXT_PINCODE_LOCALITY": "GHARSANA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3184,
      "City District Name": "GANGANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335708,
      "TXT_PINCODE_LOCALITY": "BAJUWALA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3184,
      "City District Name": "GANGANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335710,
      "TXT_PINCODE_LOCALITY": "SARDARPURA BIKA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1584,
      "City District Name": "HANUMANGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335711,
      "TXT_PINCODE_LOCALITY": "SATRANA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3184,
      "City District Name": "GANGANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335771,
      "TXT_PINCODE_LOCALITY": "7 DD-A",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 442957,
      "City District Name": "GHARSANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335801,
      "TXT_PINCODE_LOCALITY": "DABALI RATHAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3184,
      "City District Name": "GANGANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335802,
      "TXT_PINCODE_LOCALITY": "GOLUWALA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1584,
      "City District Name": "HANUMANGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335803,
      "TXT_PINCODE_LOCALITY": "PILIBANGA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1584,
      "City District Name": "HANUMANGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335804,
      "TXT_PINCODE_LOCALITY": "SURATGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 249,
      "City District Name": "SURATGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335805,
      "TXT_PINCODE_LOCALITY": "BIRADHWAL HEAD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3184,
      "City District Name": "GANGANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335806,
      "TXT_PINCODE_LOCALITY": "PANDITANWALII",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1584,
      "City District Name": "HANUMANGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335807,
      "TXT_PINCODE_LOCALITY": "RAJIASAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3184,
      "City District Name": "GANGANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335901,
      "TXT_PINCODE_LOCALITY": "HINDUMALKOT",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3184,
      "City District Name": "GANGANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 335902,
      "TXT_PINCODE_LOCALITY": "MADERAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3184,
      "City District Name": "GANGANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341001,
      "TXT_PINCODE_LOCALITY": "NAGAUR BANSHIWALA TEMPLE",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341002,
      "TXT_PINCODE_LOCALITY": "PALANIPET",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341010,
      "TXT_PINCODE_LOCALITY": "BHADWA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341011,
      "TXT_PINCODE_LOCALITY": "GOGALAO",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341012,
      "TXT_PINCODE_LOCALITY": "ALAI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341013,
      "TXT_PINCODE_LOCALITY": "BHADWASI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341015,
      "TXT_PINCODE_LOCALITY": "BEETAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341021,
      "TXT_PINCODE_LOCALITY": "BASNI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341022,
      "TXT_PINCODE_LOCALITY": "DEH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341023,
      "TXT_PINCODE_LOCALITY": "RAJOD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341024,
      "TXT_PINCODE_LOCALITY": "KUCHERA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341025,
      "TXT_PINCODE_LOCALITY": "KHINWSAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341026,
      "TXT_PINCODE_LOCALITY": "MARWAR MUNDWA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341027,
      "TXT_PINCODE_LOCALITY": "ROL",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341028,
      "TXT_PINCODE_LOCALITY": "KHAJWANA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341029,
      "TXT_PINCODE_LOCALITY": "SRI BALAJI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341030,
      "TXT_PINCODE_LOCALITY": "TARNAU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341031,
      "TXT_PINCODE_LOCALITY": "PADU KHURD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341032,
      "TXT_PINCODE_LOCALITY": "KATHOTI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341033,
      "TXT_PINCODE_LOCALITY": "NAGAUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341034,
      "TXT_PINCODE_LOCALITY": "NAGAUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341035,
      "TXT_PINCODE_LOCALITY": "NAGAUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341246,
      "TXT_PINCODE_LOCALITY": "DUBAI INTERNET CITY",
      "NUM_STATE_CD": 927,
      "State Name": "DUBAI",
      "NUM_CITYDISTRICT_CD": 444240,
      "City District Name": "SHARJAH",
      "NUM_COUNTRY_CD": 142
    },
    {
      "NUM_PINCODE": 341301,
      "TXT_PINCODE_LOCALITY": "BARI KHATU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341302,
      "TXT_PINCODE_LOCALITY": "CHHOTI KHATU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341303,
      "TXT_PINCODE_LOCALITY": "DIDWANA H O",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341304,
      "TXT_PINCODE_LOCALITY": "JASWANTGARH NAGAUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341305,
      "TXT_PINCODE_LOCALITY": "KOLIYA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341306,
      "TXT_PINCODE_LOCALITY": "LADNUN RAHUGATE",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341313,
      "TXT_PINCODE_LOCALITY": "NAGAUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341316,
      "TXT_PINCODE_LOCALITY": "NIMBI JODHAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341317,
      "TXT_PINCODE_LOCALITY": "RATAU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341318,
      "TXT_PINCODE_LOCALITY": "KHUNKHUNA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341319,
      "TXT_PINCODE_LOCALITY": "TOSHINA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341501,
      "TXT_PINCODE_LOCALITY": "BADU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341502,
      "TXT_PINCODE_LOCALITY": "MANANA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341503,
      "TXT_PINCODE_LOCALITY": "IDWA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341504,
      "TXT_PINCODE_LOCALITY": "GACHHIPURA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341505,
      "TXT_PINCODE_LOCALITY": "MAKRANA INDUSTRIAL AREA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341506,
      "TXT_PINCODE_LOCALITY": "NIMOD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341507,
      "TXT_PINCODE_LOCALITY": "MAROTH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341508,
      "TXT_PINCODE_LOCALITY": "NARAINPURA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341509,
      "TXT_PINCODE_LOCALITY": "MINDHA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341510,
      "TXT_PINCODE_LOCALITY": "MERTA KACHERI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341511,
      "TXT_PINCODE_LOCALITY": "MERTA ROAD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341512,
      "TXT_PINCODE_LOCALITY": "PARBATSAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341513,
      "TXT_PINCODE_LOCALITY": "RIYAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341514,
      "TXT_PINCODE_LOCALITY": "REN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341515,
      "TXT_PINCODE_LOCALITY": "HARSORE",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341516,
      "TXT_PINCODE_LOCALITY": "DHANKOLI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341517,
      "TXT_PINCODE_LOCALITY": "LADARIYA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341518,
      "TXT_PINCODE_LOCALITY": "JASNAGAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341519,
      "TXT_PINCODE_LOCALITY": "KUKANWALI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341520,
      "TXT_PINCODE_LOCALITY": "BESROLI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341521,
      "TXT_PINCODE_LOCALITY": "NAGAUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341522,
      "TXT_PINCODE_LOCALITY": "BHAKRI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341523,
      "TXT_PINCODE_LOCALITY": "NAGAUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341524,
      "TXT_PINCODE_LOCALITY": "NAGAUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341525,
      "TXT_PINCODE_LOCALITY": "NAGAUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341526,
      "TXT_PINCODE_LOCALITY": "NAGAUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341527,
      "TXT_PINCODE_LOCALITY": "NAGAUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341528,
      "TXT_PINCODE_LOCALITY": "NAGAUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341529,
      "TXT_PINCODE_LOCALITY": "NAGAUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341530,
      "TXT_PINCODE_LOCALITY": "CHANDARUN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341531,
      "TXT_PINCODE_LOCALITY": "RID",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341532,
      "TXT_PINCODE_LOCALITY": "PEELWA NAGAUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341533,
      "TXT_PINCODE_LOCALITY": "MITHKI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341534,
      "TXT_PINCODE_LOCALITY": "JAWLA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341535,
      "TXT_PINCODE_LOCALITY": "NAGAUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341536,
      "TXT_PINCODE_LOCALITY": "BHAGWANPURA NAGAUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341537,
      "TXT_PINCODE_LOCALITY": "NAGAUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341538,
      "TXT_PINCODE_LOCALITY": "NAGAUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341539,
      "TXT_PINCODE_LOCALITY": "NAGAUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341540,
      "TXT_PINCODE_LOCALITY": "NAGAUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341541,
      "TXT_PINCODE_LOCALITY": "NAGAUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341542,
      "TXT_PINCODE_LOCALITY": "I E BIDIAD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341544,
      "TXT_PINCODE_LOCALITY": "NAGAUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341545,
      "TXT_PINCODE_LOCALITY": "LAMPOLAI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341546,
      "TXT_PINCODE_LOCALITY": "NAGAUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341547,
      "TXT_PINCODE_LOCALITY": "KATYASANI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341548,
      "TXT_PINCODE_LOCALITY": "KALWA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341549,
      "TXT_PINCODE_LOCALITY": "TILANESH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341550,
      "TXT_PINCODE_LOCALITY": "BARWALA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 341551,
      "TXT_PINCODE_LOCALITY": "SUDARASAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342001,
      "TXT_PINCODE_LOCALITY": "KHANDA FALSA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342002,
      "TXT_PINCODE_LOCALITY": "2ND B ROAD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342003,
      "TXT_PINCODE_LOCALITY": "JODHPUR 3 SARDARPURA H O",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342004,
      "TXT_PINCODE_LOCALITY": "JODHPUR 4",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342005,
      "TXT_PINCODE_LOCALITY": "JODHPUR 5",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342006,
      "TXT_PINCODE_LOCALITY": "JODHPUR KACHERI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342007,
      "TXT_PINCODE_LOCALITY": "KRISHI UPAJ MANDI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342008,
      "TXT_PINCODE_LOCALITY": "NANDANWAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342010,
      "TXT_PINCODE_LOCALITY": "RATANADA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342011,
      "TXT_PINCODE_LOCALITY": "BHERU VILAS SARDAR CLUB GOLF COURSE ROAD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342012,
      "TXT_PINCODE_LOCALITY": "BORANADA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342013,
      "TXT_PINCODE_LOCALITY": "MANGAL NAGAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 442960,
      "City District Name": "LUNI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342014,
      "TXT_PINCODE_LOCALITY": "JHANWAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342015,
      "TXT_PINCODE_LOCALITY": "SARAN NAGAR S.O",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342022,
      "TXT_PINCODE_LOCALITY": "SAI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342023,
      "TXT_PINCODE_LOCALITY": "BALESAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342024,
      "TXT_PINCODE_LOCALITY": "SOORSAGAR JODHPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342025,
      "TXT_PINCODE_LOCALITY": "SETRAWA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342026,
      "TXT_PINCODE_LOCALITY": "B S F TRAINING CENTRE JOD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342027,
      "TXT_PINCODE_LOCALITY": "BANAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342028,
      "TXT_PINCODE_LOCALITY": "TENA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342031,
      "TXT_PINCODE_LOCALITY": "KERU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342033,
      "TXT_PINCODE_LOCALITY": "BELWA BAGAWATAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342037,
      "TXT_PINCODE_LOCALITY": "LAVERA BAORI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342301,
      "TXT_PINCODE_LOCALITY": "PHALODI SADAR BAZAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342302,
      "TXT_PINCODE_LOCALITY": "LOHAWAT",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342303,
      "TXT_PINCODE_LOCALITY": "OSIAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342304,
      "TXT_PINCODE_LOCALITY": "MANDORE B.O",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342305,
      "TXT_PINCODE_LOCALITY": "MATHANIA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342306,
      "TXT_PINCODE_LOCALITY": "TEORI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342307,
      "TXT_PINCODE_LOCALITY": "BAP",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342308,
      "TXT_PINCODE_LOCALITY": "DENOK",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342309,
      "TXT_PINCODE_LOCALITY": "PEELWA JODHPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342310,
      "TXT_PINCODE_LOCALITY": "NOKH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342311,
      "TXT_PINCODE_LOCALITY": "AAU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342312,
      "TXT_PINCODE_LOCALITY": "CHADI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342314,
      "TXT_PINCODE_LOCALITY": "DECHU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342601,
      "TXT_PINCODE_LOCALITY": "PIPAR CHOPATA BAZAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342602,
      "TXT_PINCODE_LOCALITY": "KHARIA MITHAPUR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342603,
      "TXT_PINCODE_LOCALITY": "BHOPALGARH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342604,
      "TXT_PINCODE_LOCALITY": "BORUNDA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342605,
      "TXT_PINCODE_LOCALITY": "BHAWI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342606,
      "TXT_PINCODE_LOCALITY": "PIPAR ROAD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342608,
      "TXT_PINCODE_LOCALITY": "SATHIN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342609,
      "TXT_PINCODE_LOCALITY": "KOSANA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342611,
      "TXT_PINCODE_LOCALITY": "KHEJARLA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342801,
      "TXT_PINCODE_LOCALITY": "DUNARA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342802,
      "TXT_PINCODE_LOCALITY": "LUNI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342803,
      "TXT_PINCODE_LOCALITY": "AJIT",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342804,
      "TXT_PINCODE_LOCALITY": "SALAWAS",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342901,
      "TXT_PINCODE_LOCALITY": "ASOP",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 153,
      "City District Name": "JODHPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 342902,
      "TXT_PINCODE_LOCALITY": "GOTAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1448,
      "City District Name": "NAGAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 343001,
      "TXT_PINCODE_LOCALITY": "JALORE COLLECTORATE",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1229,
      "City District Name": "JALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 343002,
      "TXT_PINCODE_LOCALITY": "I.A.JALORE",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3525,
      "City District Name": "JALOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 343011,
      "TXT_PINCODE_LOCALITY": "BADANWARI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3525,
      "City District Name": "JALOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 343021,
      "TXT_PINCODE_LOCALITY": "UMEDABAD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3525,
      "City District Name": "JALOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 343022,
      "TXT_PINCODE_LOCALITY": "SAYALA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3525,
      "City District Name": "JALOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 343023,
      "TXT_PINCODE_LOCALITY": "DHANSA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3525,
      "City District Name": "JALOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 343024,
      "TXT_PINCODE_LOCALITY": "SIANA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3525,
      "City District Name": "JALOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 343025,
      "TXT_PINCODE_LOCALITY": "BAGRA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3525,
      "City District Name": "JALOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 343026,
      "TXT_PINCODE_LOCALITY": "SANTHU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3525,
      "City District Name": "JALOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 343027,
      "TXT_PINCODE_LOCALITY": "HADECHA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3525,
      "City District Name": "JALOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 343028,
      "TXT_PINCODE_LOCALITY": "MODRAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3525,
      "City District Name": "JALOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 343029,
      "TXT_PINCODE_LOCALITY": "BHINMAL",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3525,
      "City District Name": "JALOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 343030,
      "TXT_PINCODE_LOCALITY": "JUNJHANI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3525,
      "City District Name": "JALOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 343031,
      "TXT_PINCODE_LOCALITY": "DASPAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3525,
      "City District Name": "JALOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 343032,
      "TXT_PINCODE_LOCALITY": "BAGODA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3525,
      "City District Name": "JALOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 343033,
      "TXT_PINCODE_LOCALITY": "SARWANA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3525,
      "City District Name": "JALOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 343034,
      "TXT_PINCODE_LOCALITY": "NASOLI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3525,
      "City District Name": "JALOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 343039,
      "TXT_PINCODE_LOCALITY": "AKHRABHATA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 933,
      "City District Name": "SIROHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 343040,
      "TXT_PINCODE_LOCALITY": "AJODAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3525,
      "City District Name": "JALOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 343041,
      "TXT_PINCODE_LOCALITY": "SANCHORE",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3525,
      "City District Name": "JALOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 343042,
      "TXT_PINCODE_LOCALITY": "MANDAWALA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3525,
      "City District Name": "JALOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 343043,
      "TXT_PINCODE_LOCALITY": "MOKALSAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 343044,
      "TXT_PINCODE_LOCALITY": "SIWANA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3525,
      "City District Name": "JALOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 343045,
      "TXT_PINCODE_LOCALITY": "THIKHI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3525,
      "City District Name": "JALOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 343046,
      "TXT_PINCODE_LOCALITY": "BISHANGARH JALORE",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3525,
      "City District Name": "JALOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 343047,
      "TXT_PINCODE_LOCALITY": "RAMNIYA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1229,
      "City District Name": "JALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 343048,
      "TXT_PINCODE_LOCALITY": "VADGAON",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3525,
      "City District Name": "JALOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 343049,
      "TXT_PINCODE_LOCALITY": "KAGMALA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 3525,
      "City District Name": "JALOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 343322,
      "TXT_PINCODE_LOCALITY": "MENGALWA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 442966,
      "City District Name": "SAYLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 344001,
      "TXT_PINCODE_LOCALITY": "BARMER COLLECTORATE",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 344011,
      "TXT_PINCODE_LOCALITY": "BISALA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 344012,
      "TXT_PINCODE_LOCALITY": "RANI GAON",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 344013,
      "TXT_PINCODE_LOCALITY": "HARSANI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 344014,
      "TXT_PINCODE_LOCALITY": "NAND",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 344015,
      "TXT_PINCODE_LOCALITY": "MAHABAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 344021,
      "TXT_PINCODE_LOCALITY": "SAMDARI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 344022,
      "TXT_PINCODE_LOCALITY": "BALOTRA SADAR BAZAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 344023,
      "TXT_PINCODE_LOCALITY": "BARMER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 344024,
      "TXT_PINCODE_LOCALITY": "JASOL",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 344025,
      "TXT_PINCODE_LOCALITY": "MEWANAGAR",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 344026,
      "TXT_PINCODE_LOCALITY": "KALYANPUR BARMER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 344027,
      "TXT_PINCODE_LOCALITY": "KANANA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 344028,
      "TXT_PINCODE_LOCALITY": "BARMER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 344029,
      "TXT_PINCODE_LOCALITY": "MAJAL",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 344030,
      "TXT_PINCODE_LOCALITY": "BARMER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 344031,
      "TXT_PINCODE_LOCALITY": "GUDA MALANI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 344032,
      "TXT_PINCODE_LOCALITY": "PACHPADRA CITY",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 344033,
      "TXT_PINCODE_LOCALITY": "SINDHARI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 344034,
      "TXT_PINCODE_LOCALITY": "BAITU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 344035,
      "TXT_PINCODE_LOCALITY": "KAVAS",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 344036,
      "TXT_PINCODE_LOCALITY": "SAMDARI R S",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 344037,
      "TXT_PINCODE_LOCALITY": "SAWAU PADMSINGH",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 344038,
      "TXT_PINCODE_LOCALITY": "BARMER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 344039,
      "TXT_PINCODE_LOCALITY": "ASOTRA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 344043,
      "TXT_PINCODE_LOCALITY": "BHAGWA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 344044,
      "TXT_PINCODE_LOCALITY": "THANMATA HINGLAJ",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 442967,
      "City District Name": "SIWANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 344112,
      "TXT_PINCODE_LOCALITY": "ARTHANDI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 344221,
      "TXT_PINCODE_LOCALITY": "PANDESARA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 344501,
      "TXT_PINCODE_LOCALITY": "GADRA ROAD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 344502,
      "TXT_PINCODE_LOCALITY": "RAMSAR BARMER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 344503,
      "TXT_PINCODE_LOCALITY": "MUNABAO",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 344602,
      "TXT_PINCODE_LOCALITY": "BEEKAMPURA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 252,
      "City District Name": "BHILWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 344701,
      "TXT_PINCODE_LOCALITY": "SHIV",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 344702,
      "TXT_PINCODE_LOCALITY": "CHOHTAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 344703,
      "TXT_PINCODE_LOCALITY": "RAWATSAR BARMER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 344704,
      "TXT_PINCODE_LOCALITY": "DHORIMANA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 344705,
      "TXT_PINCODE_LOCALITY": "GUNGA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 344706,
      "TXT_PINCODE_LOCALITY": "SEDWA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 344708,
      "TXT_PINCODE_LOCALITY": "SANAWADA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 344709,
      "TXT_PINCODE_LOCALITY": "KOTRA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 344801,
      "TXT_PINCODE_LOCALITY": "PADRU",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 243,
      "City District Name": "BARMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 345001,
      "TXT_PINCODE_LOCALITY": "JAISALMER H O",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1591,
      "City District Name": "JAISALMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 345002,
      "TXT_PINCODE_LOCALITY": "JAISALMER B S F",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1591,
      "City District Name": "JAISALMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 345021,
      "TXT_PINCODE_LOCALITY": "POKARAN R S",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1591,
      "City District Name": "JAISALMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 345022,
      "TXT_PINCODE_LOCALITY": "RAMGRH JAISALMER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1591,
      "City District Name": "JAISALMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 345023,
      "TXT_PINCODE_LOCALITY": "RAMDEORA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1591,
      "City District Name": "JAISALMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 345024,
      "TXT_PINCODE_LOCALITY": "BHANIANA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1591,
      "City District Name": "JAISALMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 345025,
      "TXT_PINCODE_LOCALITY": "PHALSUND",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1591,
      "City District Name": "JAISALMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 345026,
      "TXT_PINCODE_LOCALITY": "SANKRA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1591,
      "City District Name": "JAISALMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 345027,
      "TXT_PINCODE_LOCALITY": "SANGAD",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1591,
      "City District Name": "JAISALMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 345028,
      "TXT_PINCODE_LOCALITY": "NACHNA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1591,
      "City District Name": "JAISALMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 345029,
      "TXT_PINCODE_LOCALITY": "CHANDAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1591,
      "City District Name": "JAISALMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 345030,
      "TXT_PINCODE_LOCALITY": "SAM",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1591,
      "City District Name": "JAISALMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 345031,
      "TXT_PINCODE_LOCALITY": "LATHI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1591,
      "City District Name": "JAISALMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 345032,
      "TXT_PINCODE_LOCALITY": "KHUHRI JAISALMER",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1591,
      "City District Name": "JAISALMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 345033,
      "TXT_PINCODE_LOCALITY": "KANOD RAWALIYA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1591,
      "City District Name": "JAISALMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 345034,
      "TXT_PINCODE_LOCALITY": "KHURI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 1591,
      "City District Name": "JAISALMER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 348002,
      "TXT_PINCODE_LOCALITY": "MEHSANA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 681,
      "City District Name": "MEHSANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302044,
      "TXT_PINCODE_LOCALITY": "GOVINDPURA",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302045,
      "TXT_PINCODE_LOCALITY": "SIRSI",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 302046,
      "TXT_PINCODE_LOCALITY": "BHANPUR KALAN",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 303124,
      "TXT_PINCODE_LOCALITY": "BOBAS",
      "NUM_STATE_CD": 56,
      "State Name": "RAJASTHAN",
      "NUM_CITYDISTRICT_CD": 152,
      "City District Name": "JAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400001,
      "TXT_PINCODE_LOCALITY": "SINDHWA MARG",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400002,
      "TXT_PINCODE_LOCALITY": "WAGH WADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400003,
      "TXT_PINCODE_LOCALITY": "SADAIA BAZAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400004,
      "TXT_PINCODE_LOCALITY": "VITHALBHAI PATEL ROAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400005,
      "TXT_PINCODE_LOCALITY": "WODEHOUSE ROAD 48 TO 138",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400006,
      "TXT_PINCODE_LOCALITY": "PHIROZSHAH MEHTA GARDEN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400007,
      "TXT_PINCODE_LOCALITY": "TEJPAL ROAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400008,
      "TXT_PINCODE_LOCALITY": "PANDU MALI ADDA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400009,
      "TXT_PINCODE_LOCALITY": "SANT TUKARAM ROAD ARGYL R",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400010,
      "TXT_PINCODE_LOCALITY": "SURYA KUNJ",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400011,
      "TXT_PINCODE_LOCALITY": "SHIVSHAKTI INDL ESTATE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400012,
      "TXT_PINCODE_LOCALITY": "WAGHESHWAR WADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400013,
      "TXT_PINCODE_LOCALITY": "LOWER PAREL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400014,
      "TXT_PINCODE_LOCALITY": "UMRIGAN WADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400015,
      "TXT_PINCODE_LOCALITY": "SEWRI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400016,
      "TXT_PINCODE_LOCALITY": "WOOLEN MILLS LANE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400017,
      "TXT_PINCODE_LOCALITY": "STATION ROAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400018,
      "TXT_PINCODE_LOCALITY": "WORLI COLONY",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400019,
      "TXT_PINCODE_LOCALITY": "SIR BHALCHANDRA RD PLOT",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400020,
      "TXT_PINCODE_LOCALITY": "N S RD 103 TO 159 M DRIVE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400021,
      "TXT_PINCODE_LOCALITY": "SURUCHI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400022,
      "TXT_PINCODE_LOCALITY": "SOUBHANI CO OP HSG SOC",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400023,
      "TXT_PINCODE_LOCALITY": "VEER NARIMAN RD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400024,
      "TXT_PINCODE_LOCALITY": "NEHRUNAGAR KURLA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400025,
      "TXT_PINCODE_LOCALITY": "SWATANTRYA VEERSAVARKAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400026,
      "TXT_PINCODE_LOCALITY": "SOFIA COLLEGE ROAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400027,
      "TXT_PINCODE_LOCALITY": "NEW 52 CHAWLS V GARDENS",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400028,
      "TXT_PINCODE_LOCALITY": "UPENDRA NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400029,
      "TXT_PINCODE_LOCALITY": "KALINA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400030,
      "TXT_PINCODE_LOCALITY": "WORLI EAST",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400031,
      "TXT_PINCODE_LOCALITY": "TILAK ROAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400032,
      "TXT_PINCODE_LOCALITY": "NEW ADMINISTRATIVE BLDG",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400033,
      "TXT_PINCODE_LOCALITY": "TANK ROAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400034,
      "TXT_PINCODE_LOCALITY": "VATSALABAI DESAI CHOWK",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400035,
      "TXT_PINCODE_LOCALITY": "RAJ BJAVAN COMPOUND",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400036,
      "TXT_PINCODE_LOCALITY": "PAPANAS WADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400037,
      "TXT_PINCODE_LOCALITY": "MUKUNDRAO AMBEDKAR MARG",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400038,
      "TXT_PINCODE_LOCALITY": "SURJ VALLABHDAS MARG",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400039,
      "TXT_PINCODE_LOCALITY": "TAJMAHAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400040,
      "TXT_PINCODE_LOCALITY": "NAVJIVAN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400042,
      "TXT_PINCODE_LOCALITY": "KANJURMARG",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400043,
      "TXT_PINCODE_LOCALITY": "SHIVAJI NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400044,
      "TXT_PINCODE_LOCALITY": "KHAR EAST",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400046,
      "TXT_PINCODE_LOCALITY": "VIVEKANANDNAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400047,
      "TXT_PINCODE_LOCALITY": "VILEPARLE R S",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400048,
      "TXT_PINCODE_LOCALITY": "IRLA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400049,
      "TXT_PINCODE_LOCALITY": "SANGEETA APARTMENTS",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400050,
      "TXT_PINCODE_LOCALITY": "STATION ROAD BANDRA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400051,
      "TXT_PINCODE_LOCALITY": "NIRMALNAGAR ROAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400052,
      "TXT_PINCODE_LOCALITY": "MARI AAI TEMPLE ROAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400053,
      "TXT_PINCODE_LOCALITY": "STATE BANK COLONY",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400054,
      "TXT_PINCODE_LOCALITY": "WELLINGTON COLONY",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400055,
      "TXT_PINCODE_LOCALITY": "VAISHALI CAMP",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400056,
      "TXT_PINCODE_LOCALITY": "VILEPARLE IRLA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400057,
      "TXT_PINCODE_LOCALITY": "VIDYANAGARI MARG",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400058,
      "TXT_PINCODE_LOCALITY": "VITHALBHAI PATEL ROAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400059,
      "TXT_PINCODE_LOCALITY": "SHARDDHANAND ROAD &EXTENS",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400060,
      "TXT_PINCODE_LOCALITY": "TILAKWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400061,
      "TXT_PINCODE_LOCALITY": "VARSOVA KAKORI CAMP",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400062,
      "TXT_PINCODE_LOCALITY": "ZANJERIA COLONY GOREGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400063,
      "TXT_PINCODE_LOCALITY": "SAHAKAR LANE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400064,
      "TXT_PINCODE_LOCALITY": "UNDRAI ROAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400065,
      "TXT_PINCODE_LOCALITY": "CALF FARM",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400066,
      "TXT_PINCODE_LOCALITY": "NATIONAL HIGH WAY BORIVAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400067,
      "TXT_PINCODE_LOCALITY": "SITABAI PATIL ROAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400068,
      "TXT_PINCODE_LOCALITY": "SHYAMSUNDER WADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400069,
      "TXT_PINCODE_LOCALITY": "PRABHATNAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400070,
      "TXT_PINCODE_LOCALITY": "VILLAGE ROAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400071,
      "TXT_PINCODE_LOCALITY": "SHANTINAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400072,
      "TXT_PINCODE_LOCALITY": "TUNGWA VILLAGE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400073,
      "TXT_PINCODE_LOCALITY": "TROMBAY",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400074,
      "TXT_PINCODE_LOCALITY": "DEVNAR VILLAGE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400075,
      "TXT_PINCODE_LOCALITY": "UPASRAYA ROAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400076,
      "TXT_PINCODE_LOCALITY": "HIRANANDANI BUSINESS PARK",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400077,
      "TXT_PINCODE_LOCALITY": "RAMABAI SAHKAR NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400078,
      "TXT_PINCODE_LOCALITY": "TATA POWER HOUSE ROAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400079,
      "TXT_PINCODE_LOCALITY": "PARKSIDE ROAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400080,
      "TXT_PINCODE_LOCALITY": "ZAVER ROAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400081,
      "TXT_PINCODE_LOCALITY": "VIJAYWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400082,
      "TXT_PINCODE_LOCALITY": "AGRA ROAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400083,
      "TXT_PINCODE_LOCALITY": "HARIALI VLIIAGE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400084,
      "TXT_PINCODE_LOCALITY": "BHATWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400085,
      "TXT_PINCODE_LOCALITY": "B A R C BOMBAY",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400086,
      "TXT_PINCODE_LOCALITY": "SARVODAYA HOSPITAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400087,
      "TXT_PINCODE_LOCALITY": "SAKI VIHAR ROAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400088,
      "TXT_PINCODE_LOCALITY": "VITHAL NARAYAN PURAO MARG",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400089,
      "TXT_PINCODE_LOCALITY": "MALEKAR WADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400090,
      "TXT_PINCODE_LOCALITY": "BANGUR NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400091,
      "TXT_PINCODE_LOCALITY": "SHIVAJINAGAR (GOVANDI)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400092,
      "TXT_PINCODE_LOCALITY": "VALLABH NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400093,
      "TXT_PINCODE_LOCALITY": "SHARAN SINGH COLONY",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400094,
      "TXT_PINCODE_LOCALITY": "ANUSHAKTI NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400095,
      "TXT_PINCODE_LOCALITY": "KHARODI INS HAMLA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400096,
      "TXT_PINCODE_LOCALITY": "SEEPZ",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400097,
      "TXT_PINCODE_LOCALITY": "RANI SATI MARG",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400098,
      "TXT_PINCODE_LOCALITY": "SUNDER NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400099,
      "TXT_PINCODE_LOCALITY": "SHANTINAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400100,
      "TXT_PINCODE_LOCALITY": "KANDIVALI EAST",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400101,
      "TXT_PINCODE_LOCALITY": "MAHENDRA AND MAHENDRA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400102,
      "TXT_PINCODE_LOCALITY": "H WARD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400103,
      "TXT_PINCODE_LOCALITY": "GOKUL DHAM",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400104,
      "TXT_PINCODE_LOCALITY": "MOTILAL NAGAR (GOREGAON)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400105,
      "TXT_PINCODE_LOCALITY": "AIR COMPLEX",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400106,
      "TXT_PINCODE_LOCALITY": "THANE.",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400107,
      "TXT_PINCODE_LOCALITY": "MUMBAI S. O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400125,
      "TXT_PINCODE_LOCALITY": "SANT SANTAJI MAHARAJ J C",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400201,
      "TXT_PINCODE_LOCALITY": "RAILWAY RECRUITMENT BOARD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400207,
      "TXT_PINCODE_LOCALITY": "URAN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1092,
      "City District Name": "URAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400208,
      "TXT_PINCODE_LOCALITY": "THANE B.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400218,
      "TXT_PINCODE_LOCALITY": "KALAMBOLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400547,
      "TXT_PINCODE_LOCALITY": "SAKSENA WADI JUHU",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400601,
      "TXT_PINCODE_LOCALITY": "THANE R S",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400602,
      "TXT_PINCODE_LOCALITY": "NANPADA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400603,
      "TXT_PINCODE_LOCALITY": "KOPRI COLONY",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400604,
      "TXT_PINCODE_LOCALITY": "TEEN HAATH NAKA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400605,
      "TXT_PINCODE_LOCALITY": "KALWA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400606,
      "TXT_PINCODE_LOCALITY": "J K GRAM",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400607,
      "TXT_PINCODE_LOCALITY": "GHODBUNDER",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400608,
      "TXT_PINCODE_LOCALITY": "BALKUM",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400609,
      "TXT_PINCODE_LOCALITY": "MAJIWADA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400610,
      "TXT_PINCODE_LOCALITY": "POKHARAN ROAD NO 2",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400611,
      "TXT_PINCODE_LOCALITY": "BELAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400612,
      "TXT_PINCODE_LOCALITY": "MUMBRA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400613,
      "TXT_PINCODE_LOCALITY": "NAVI MUMBAI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400614,
      "TXT_PINCODE_LOCALITY": "CBD BELAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400615,
      "TXT_PINCODE_LOCALITY": "BELAPUR NODE SECTOT III",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400617,
      "TXT_PINCODE_LOCALITY": "THANE WEST",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400701,
      "TXT_PINCODE_LOCALITY": "NHAVA SHEVA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400702,
      "TXT_PINCODE_LOCALITY": "URAN - 4925",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400703,
      "TXT_PINCODE_LOCALITY": "VASHI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400704,
      "TXT_PINCODE_LOCALITY": "NAVI MUMBAI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 462,
      "City District Name": "NAVI MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400705,
      "TXT_PINCODE_LOCALITY": "TTC INDUSTRIAL AREA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400706,
      "TXT_PINCODE_LOCALITY": "VASHI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400707,
      "TXT_PINCODE_LOCALITY": "SHIRVANE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400708,
      "TXT_PINCODE_LOCALITY": "PANVEL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 462,
      "City District Name": "NAVI MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400709,
      "TXT_PINCODE_LOCALITY": "KOPARKHAIRANE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 462,
      "City District Name": "NAVI MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 400710,
      "TXT_PINCODE_LOCALITY": "MHAPE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401003,
      "TXT_PINCODE_LOCALITY": "BAWADA S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401101,
      "TXT_PINCODE_LOCALITY": "BHAYANDER EAST",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401102,
      "TXT_PINCODE_LOCALITY": "UMBARPADA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401103,
      "TXT_PINCODE_LOCALITY": "VANGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401104,
      "TXT_PINCODE_LOCALITY": "MIRA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401105,
      "TXT_PINCODE_LOCALITY": "BHAYANDAR EAST",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401106,
      "TXT_PINCODE_LOCALITY": "UTTAN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401107,
      "TXT_PINCODE_LOCALITY": "THANE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401110,
      "TXT_PINCODE_LOCALITY": "KAPSHI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1238,
      "City District Name": "DAHANU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401201,
      "TXT_PINCODE_LOCALITY": "THANE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401202,
      "TXT_PINCODE_LOCALITY": "VASAI WEST",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 811,
      "City District Name": "PALGHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401203,
      "TXT_PINCODE_LOCALITY": "SOPARA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401204,
      "TXT_PINCODE_LOCALITY": "VAJRESHWARI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401205,
      "TXT_PINCODE_LOCALITY": "GOKHIVARE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401206,
      "TXT_PINCODE_LOCALITY": "GANESHPURI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401207,
      "TXT_PINCODE_LOCALITY": "PAPDI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401208,
      "TXT_PINCODE_LOCALITY": "VASAI LNDUSTRIAI ESTATE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401209,
      "TXT_PINCODE_LOCALITY": "NALLASOPA EAST",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401210,
      "TXT_PINCODE_LOCALITY": "NAVGHAR ROAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401301,
      "TXT_PINCODE_LOCALITY": "AGASHI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401302,
      "TXT_PINCODE_LOCALITY": "ARNALA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401303,
      "TXT_PINCODE_LOCALITY": "VIRAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401304,
      "TXT_PINCODE_LOCALITY": "NIRMAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401305,
      "TXT_PINCODE_LOCALITY": "VIRAR EAST S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401401,
      "TXT_PINCODE_LOCALITY": "KELWA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401402,
      "TXT_PINCODE_LOCALITY": "KELWA MAHIM",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401403,
      "TXT_PINCODE_LOCALITY": "PALGHAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 811,
      "City District Name": "PALGHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401404,
      "TXT_PINCODE_LOCALITY": "PALGHAR EAST",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401405,
      "TXT_PINCODE_LOCALITY": "SATPATI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401406,
      "TXT_PINCODE_LOCALITY": "GORHE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401407,
      "TXT_PINCODE_LOCALITY": "SHIRGAON (THANE)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401409,
      "TXT_PINCODE_LOCALITY": "JAYSHET",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 811,
      "City District Name": "PALGHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401410,
      "TXT_PINCODE_LOCALITY": "ASWALI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1238,
      "City District Name": "DAHANU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401501,
      "TXT_PINCODE_LOCALITY": "BOISAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401502,
      "TXT_PINCODE_LOCALITY": "TARAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401503,
      "TXT_PINCODE_LOCALITY": "CHINCHANI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401504,
      "TXT_PINCODE_LOCALITY": "TARAPUR A P P",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401505,
      "TXT_PINCODE_LOCALITY": "AKARPATTI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401506,
      "TXT_PINCODE_LOCALITY": "MIDC",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401601,
      "TXT_PINCODE_LOCALITY": "DAHANU",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401602,
      "TXT_PINCODE_LOCALITY": "DAHANU ROAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401603,
      "TXT_PINCODE_LOCALITY": "JAWHAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401604,
      "TXT_PINCODE_LOCALITY": "MOKHADA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401605,
      "TXT_PINCODE_LOCALITY": "VIKRAMGAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401606,
      "TXT_PINCODE_LOCALITY": "TALASARI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401607,
      "TXT_PINCODE_LOCALITY": "THANE (WEST)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401608,
      "TXT_PINCODE_LOCALITY": "GANJAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401609,
      "TXT_PINCODE_LOCALITY": "SURYANAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401610,
      "TXT_PINCODE_LOCALITY": "RAIGAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 563,
      "City District Name": "RAIGAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401623,
      "TXT_PINCODE_LOCALITY": "WINWAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 3565,
      "City District Name": "JAWHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401630,
      "TXT_PINCODE_LOCALITY": "UMBARKHEDA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 3565,
      "City District Name": "JAWHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401701,
      "TXT_PINCODE_LOCALITY": "BORDI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401702,
      "TXT_PINCODE_LOCALITY": "GHOLVAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401703,
      "TXT_PINCODE_LOCALITY": "PALI (KOLABA)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 401709,
      "TXT_PINCODE_LOCALITY": "ZARALI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1238,
      "City District Name": "DAHANU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402101,
      "TXT_PINCODE_LOCALITY": "AMBAT",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 529,
      "City District Name": "ALIBAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402102,
      "TXT_PINCODE_LOCALITY": "URAN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402103,
      "TXT_PINCODE_LOCALITY": "GOREGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402104,
      "TXT_PINCODE_LOCALITY": "MANGAON (ALIBAG)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402105,
      "TXT_PINCODE_LOCALITY": "MHASLA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402106,
      "TXT_PINCODE_LOCALITY": "NAGOTHANA - 3249",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402107,
      "TXT_PINCODE_LOCALITY": "RAVE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402108,
      "TXT_PINCODE_LOCALITY": "POYNAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402109,
      "TXT_PINCODE_LOCALITY": "ROHA - 4104",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402110,
      "TXT_PINCODE_LOCALITY": "JASAVLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402111,
      "TXT_PINCODE_LOCALITY": "TALE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402112,
      "TXT_PINCODE_LOCALITY": "TALASHET",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402113,
      "TXT_PINCODE_LOCALITY": "WALWATI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402114,
      "TXT_PINCODE_LOCALITY": "BAGMANDLA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402115,
      "TXT_PINCODE_LOCALITY": "UPPER TUDIL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402116,
      "TXT_PINCODE_LOCALITY": "DHATAV",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402117,
      "TXT_PINCODE_LOCALITY": "MORBA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402118,
      "TXT_PINCODE_LOCALITY": "ALIBAUG",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 529,
      "City District Name": "ALIBAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402120,
      "TXT_PINCODE_LOCALITY": "NIZAMPUR (ALIBAG)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402121,
      "TXT_PINCODE_LOCALITY": "PABHANE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402122,
      "TXT_PINCODE_LOCALITY": "RAIGARH(MH)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402124,
      "TXT_PINCODE_LOCALITY": "DADAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402125,
      "TXT_PINCODE_LOCALITY": "PETROCHEMICAL TOWNSHIP ((NAGOTHANE)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402126,
      "TXT_PINCODE_LOCALITY": "R.S.PIPE NAGAR NAGOTHANA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402201,
      "TXT_PINCODE_LOCALITY": "PALI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402202,
      "TXT_PINCODE_LOCALITY": "REVDANDA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402203,
      "TXT_PINCODE_LOCALITY": "CHAUL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402204,
      "TXT_PINCODE_LOCALITY": "NAGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402205,
      "TXT_PINCODE_LOCALITY": "AVAS",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402207,
      "TXT_PINCODE_LOCALITY": "THAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402208,
      "TXT_PINCODE_LOCALITY": "RCF THAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402209,
      "TXT_PINCODE_LOCALITY": "KURUL RCF COLONY S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402212,
      "TXT_PINCODE_LOCALITY": "WAGHAV",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4514,
      "City District Name": "MHASLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402301,
      "TXT_PINCODE_LOCALITY": "MAHAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402302,
      "TXT_PINCODE_LOCALITY": "BIRWAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402303,
      "TXT_PINCODE_LOCALITY": "POLADPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402304,
      "TXT_PINCODE_LOCALITY": "KOLAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402305,
      "TXT_PINCODE_LOCALITY": "NATA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402306,
      "TXT_PINCODE_LOCALITY": "VARANDH",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402307,
      "TXT_PINCODE_LOCALITY": "PANGARI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402308,
      "TXT_PINCODE_LOCALITY": "BHIRA S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402309,
      "TXT_PINCODE_LOCALITY": "MIDC MAHAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402362,
      "TXT_PINCODE_LOCALITY": "SHELTOLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 554,
      "City District Name": "MAHAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402401,
      "TXT_PINCODE_LOCALITY": "MURUD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402402,
      "TXT_PINCODE_LOCALITY": "DIGHI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402403,
      "TXT_PINCODE_LOCALITY": "BORLAI PANCHAYATAN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402404,
      "TXT_PINCODE_LOCALITY": "DIVEAGAL(ALIBAG)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402405,
      "TXT_PINCODE_LOCALITY": "RAJPURI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 402406,
      "TXT_PINCODE_LOCALITY": "NANDGAON (KULABA)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403001,
      "TXT_PINCODE_LOCALITY": "PANAJI ALTIME",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 6319,
      "City District Name": "TISWADI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403002,
      "TXT_PINCODE_LOCALITY": "CARANZALEM",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 425478,
      "City District Name": "NORTH GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403003,
      "TXT_PINCODE_LOCALITY": "TALEIGAO",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 6319,
      "City District Name": "TISWADI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403004,
      "TXT_PINCODE_LOCALITY": "TALEIGAO",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 425478,
      "City District Name": "NORTH GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403005,
      "TXT_PINCODE_LOCALITY": "LIHAS",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 425478,
      "City District Name": "NORTH GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403006,
      "TXT_PINCODE_LOCALITY": "PANAJI",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 425478,
      "City District Name": "NORTH GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403101,
      "TXT_PINCODE_LOCALITY": "BETIM",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 1008,
      "City District Name": "BARDEZ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403102,
      "TXT_PINCODE_LOCALITY": "CHORAO",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 6319,
      "City District Name": "TISWADI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403103,
      "TXT_PINCODE_LOCALITY": "SHIRODA",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 425478,
      "City District Name": "NORTH GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403104,
      "TXT_PINCODE_LOCALITY": "NEURA",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 6319,
      "City District Name": "TISWADI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403105,
      "TXT_PINCODE_LOCALITY": "PALE",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 1077,
      "City District Name": "BICHOLIM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403106,
      "TXT_PINCODE_LOCALITY": "SANTA ESTEVAM",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 6319,
      "City District Name": "TISWADI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403107,
      "TXT_PINCODE_LOCALITY": "MAREELA",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 663,
      "City District Name": "PONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403108,
      "TXT_PINCODE_LOCALITY": "GOA VELHA",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 6319,
      "City District Name": "TISWADI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403109,
      "TXT_PINCODE_LOCALITY": "SAVOI-VEREM",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 663,
      "City District Name": "PONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403110,
      "TXT_PINCODE_LOCALITY": "CORLIM INDUSTRIAL ESTATE",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 6319,
      "City District Name": "TISWADI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403111,
      "TXT_PINCODE_LOCALITY": "SIRDAE",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 654,
      "City District Name": "GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403112,
      "TXT_PINCODE_LOCALITY": "ALTC BETIM",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 654,
      "City District Name": "GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403113,
      "TXT_PINCODE_LOCALITY": "CUMBARJNA",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 654,
      "City District Name": "GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403114,
      "TXT_PINCODE_LOCALITY": "NAVAL BASE VERAN",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 654,
      "City District Name": "GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403115,
      "TXT_PINCODE_LOCALITY": "KUNDIAM INDUSTRIAL ESTATE",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 425479,
      "City District Name": "SOUTH GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403201,
      "TXT_PINCODE_LOCALITY": "BAMBOLLM",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 425478,
      "City District Name": "NORTH GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403202,
      "TXT_PINCODE_LOCALITY": "BAMBOLIM COMPLEX",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 425478,
      "City District Name": "NORTH GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403203,
      "TXT_PINCODE_LOCALITY": "PILAR",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 425478,
      "City District Name": "NORTH GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403204,
      "TXT_PINCODE_LOCALITY": "S LOURANCO",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 6319,
      "City District Name": "TISWADI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403206,
      "TXT_PINCODE_LOCALITY": "GOA UNIVERSITY",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 425478,
      "City District Name": "NORTH GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403305,
      "TXT_PINCODE_LOCALITY": "MANGALVEDHA",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 654,
      "City District Name": "GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403325,
      "TXT_PINCODE_LOCALITY": "MAPUCA IND ESTATE (NEW)",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 654,
      "City District Name": "GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403401,
      "TXT_PINCODE_LOCALITY": "CURTI",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 654,
      "City District Name": "GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403402,
      "TXT_PINCODE_LOCALITY": "VELHAGAO",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 654,
      "City District Name": "GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403403,
      "TXT_PINCODE_LOCALITY": "PIEDADE",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 425478,
      "City District Name": "NORTH GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403404,
      "TXT_PINCODE_LOCALITY": "MARDOL",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 425479,
      "City District Name": "SOUTH GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403405,
      "TXT_PINCODE_LOCALITY": "SOUTH GOA.",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 425479,
      "City District Name": "SOUTH GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403406,
      "TXT_PINCODE_LOCALITY": "TISEA",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 425479,
      "City District Name": "SOUTH GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403407,
      "TXT_PINCODE_LOCALITY": "USGAON",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 425478,
      "City District Name": "NORTH GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403408,
      "TXT_PINCODE_LOCALITY": "QUEULA",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 425479,
      "City District Name": "SOUTH GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403409,
      "TXT_PINCODE_LOCALITY": "BETORA I.E. S.O",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 425479,
      "City District Name": "SOUTH GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403410,
      "TXT_PINCODE_LOCALITY": "COLLEM",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 425479,
      "City District Name": "SOUTH GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403501,
      "TXT_PINCODE_LOCALITY": "PORVORIM",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 654,
      "City District Name": "GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403502,
      "TXT_PINCODE_LOCALITY": "TIVIM",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 1008,
      "City District Name": "BARDEZ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403503,
      "TXT_PINCODE_LOCALITY": "ASSONORA S. O",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 442150,
      "City District Name": "ASSONORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403504,
      "TXT_PINCODE_LOCALITY": "BHICHOLIM",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 425478,
      "City District Name": "NORTH GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403505,
      "TXT_PINCODE_LOCALITY": "SANQUELIM",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 425478,
      "City District Name": "NORTH GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403506,
      "TXT_PINCODE_LOCALITY": "VALPOI",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 1077,
      "City District Name": "BICHOLIM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403507,
      "TXT_PINCODE_LOCALITY": "GUIRIM",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 654,
      "City District Name": "GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403508,
      "TXT_PINCODE_LOCALITY": "ALDANA",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 425478,
      "City District Name": "NORTH GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403509,
      "TXT_PINCODE_LOCALITY": "ANJUNA S. O",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 442151,
      "City District Name": "ANJUNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403510,
      "TXT_PINCODE_LOCALITY": "PARRA",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 1008,
      "City District Name": "BARDEZ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403511,
      "TXT_PINCODE_LOCALITY": "SALIGAO",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 1008,
      "City District Name": "BARDEZ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403512,
      "TXT_PINCODE_LOCALITY": "PERNEM",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 5097,
      "City District Name": "PERNEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403513,
      "TXT_PINCODE_LOCALITY": "COLVALE",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 1008,
      "City District Name": "BARDEZ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403514,
      "TXT_PINCODE_LOCALITY": "MOIRA",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 1008,
      "City District Name": "BARDEZ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403515,
      "TXT_PINCODE_LOCALITY": "CANDOLIM",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 425478,
      "City District Name": "NORTH GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403516,
      "TXT_PINCODE_LOCALITY": "CALANGUTE",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 425478,
      "City District Name": "NORTH GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403517,
      "TXT_PINCODE_LOCALITY": "SIOLIM",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 1008,
      "City District Name": "BARDEZ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403518,
      "TXT_PINCODE_LOCALITY": "AVPARA",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 666,
      "City District Name": "VASCO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403519,
      "TXT_PINCODE_LOCALITY": "SINQUERIM",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 1008,
      "City District Name": "BARDEZ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403520,
      "TXT_PINCODE_LOCALITY": "ASSAGAON",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 654,
      "City District Name": "GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403521,
      "TXT_PINCODE_LOCALITY": "ALTO PROVIRIM",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 1008,
      "City District Name": "BARDEZ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403523,
      "TXT_PINCODE_LOCALITY": "CARONA(ALDONA)",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 654,
      "City District Name": "GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403524,
      "TXT_PINCODE_LOCALITY": "ARAMBOL S.O",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 425478,
      "City District Name": "NORTH GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403526,
      "TXT_PINCODE_LOCALITY": "TIRIM INDL ESTATE",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 654,
      "City District Name": "GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403527,
      "TXT_PINCODE_LOCALITY": "MANDREM",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 425478,
      "City District Name": "NORTH GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403529,
      "TXT_PINCODE_LOCALITY": "CORLIN",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 654,
      "City District Name": "GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403530,
      "TXT_PINCODE_LOCALITY": "ONDA",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 425478,
      "City District Name": "NORTH GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403601,
      "TXT_PINCODE_LOCALITY": "MARGAO",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 659,
      "City District Name": "MARGAO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403602,
      "TXT_PINCODE_LOCALITY": "FATORDA",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 664,
      "City District Name": "SALCETE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403603,
      "TXT_PINCODE_LOCALITY": "BELUI",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 654,
      "City District Name": "GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403604,
      "TXT_PINCODE_LOCALITY": "NUVEM",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 664,
      "City District Name": "SALCETE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403607,
      "TXT_PINCODE_LOCALITY": "GOA.",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 654,
      "City District Name": "GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403701,
      "TXT_PINCODE_LOCALITY": "ASSOLNA",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 659,
      "City District Name": "MARGAO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403702,
      "TXT_PINCODE_LOCALITY": "CANACONA",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 2648,
      "City District Name": "CANACONA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403703,
      "TXT_PINCODE_LOCALITY": "CUNCOLIM S.O",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 425479,
      "City District Name": "SOUTH GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403704,
      "TXT_PINCODE_LOCALITY": "SANQUEM",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 5493,
      "City District Name": "SANGUEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403705,
      "TXT_PINCODE_LOCALITY": "QUPEM",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 425479,
      "City District Name": "SOUTH GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403706,
      "TXT_PINCODE_LOCALITY": "CURCHOREM",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 664,
      "City District Name": "SALCETE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403707,
      "TXT_PINCODE_LOCALITY": "NAVELIM",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 664,
      "City District Name": "SALCETE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403708,
      "TXT_PINCODE_LOCALITY": "COLVA",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 664,
      "City District Name": "SALCETE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403709,
      "TXT_PINCODE_LOCALITY": "CUROTORIM",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 664,
      "City District Name": "SALCETE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403710,
      "TXT_PINCODE_LOCALITY": "SANCOALE",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 425479,
      "City District Name": "SOUTH GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403711,
      "TXT_PINCODE_LOCALITY": "CHICALIM",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 4562,
      "City District Name": "MORMUGAO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403712,
      "TXT_PINCODE_LOCALITY": "CAN SANLIM",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 664,
      "City District Name": "SALCETE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403713,
      "TXT_PINCODE_LOCALITY": "MAJORDA",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 664,
      "City District Name": "SALCETE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403714,
      "TXT_PINCODE_LOCALITY": "CHANDOR",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 664,
      "City District Name": "SALCETE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403715,
      "TXT_PINCODE_LOCALITY": "CHINCHINIM CHIOCHIN",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 4562,
      "City District Name": "MORMUGAO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403716,
      "TXT_PINCODE_LOCALITY": "BENAVLIM",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 659,
      "City District Name": "MARGAO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403717,
      "TXT_PINCODE_LOCALITY": "CARMONA",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 664,
      "City District Name": "SALCETE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403718,
      "TXT_PINCODE_LOCALITY": "LOUTULIM",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 425479,
      "City District Name": "SOUTH GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403719,
      "TXT_PINCODE_LOCALITY": "SEM RACHOL",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 664,
      "City District Name": "SALCETE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403720,
      "TXT_PINCODE_LOCALITY": "RAJA",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 664,
      "City District Name": "SALCETE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403721,
      "TXT_PINCODE_LOCALITY": "VARCA",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 664,
      "City District Name": "SALCETE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403722,
      "TXT_PINCODE_LOCALITY": "CAVELLOSSIM",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 425479,
      "City District Name": "SOUTH GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403723,
      "TXT_PINCODE_LOCALITY": "VELIM",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 654,
      "City District Name": "GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403724,
      "TXT_PINCODE_LOCALITY": "ORELM",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 6319,
      "City District Name": "TISWADI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403725,
      "TXT_PINCODE_LOCALITY": "DRAMPUR",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 425478,
      "City District Name": "NORTH GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403726,
      "TXT_PINCODE_LOCALITY": "ZUARI NAGAR (MARGAON)",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 4562,
      "City District Name": "MORMUGAO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403727,
      "TXT_PINCODE_LOCALITY": "SANVOEDEM",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 654,
      "City District Name": "GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403728,
      "TXT_PINCODE_LOCALITY": "LOLIEN",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 425479,
      "City District Name": "SOUTH GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403729,
      "TXT_PINCODE_LOCALITY": "NAVELIM CAMP",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 654,
      "City District Name": "GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403730,
      "TXT_PINCODE_LOCALITY": "ST JOSEDE AZEAL",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 654,
      "City District Name": "GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403731,
      "TXT_PINCODE_LOCALITY": "SALCETE",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 425479,
      "City District Name": "SOUTH GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403801,
      "TXT_PINCODE_LOCALITY": "AIR PORT DABOLIM",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 425479,
      "City District Name": "SOUTH GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403802,
      "TXT_PINCODE_LOCALITY": "VASCO VADEM",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 654,
      "City District Name": "GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403803,
      "TXT_PINCODE_LOCALITY": "MARMUGAO",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 425479,
      "City District Name": "SOUTH GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403804,
      "TXT_PINCODE_LOCALITY": "SADA",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 425479,
      "City District Name": "SOUTH GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 403806,
      "TXT_PINCODE_LOCALITY": "BOGMALO",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 425479,
      "City District Name": "SOUTH GOA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 406703,
      "TXT_PINCODE_LOCALITY": "RUMBREM",
      "NUM_STATE_CD": 145,
      "State Name": "GOA",
      "NUM_CITYDISTRICT_CD": 5493,
      "City District Name": "SANGUEM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410001,
      "TXT_PINCODE_LOCALITY": "PIMPALGAON LANDGA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442859,
      "City District Name": "NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410011,
      "TXT_PINCODE_LOCALITY": "KASBA PETH",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410015,
      "TXT_PINCODE_LOCALITY": "VIVEKANAND NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410041,
      "TXT_PINCODE_LOCALITY": "AHMED NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 522,
      "City District Name": "AHMEDNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410051,
      "TXT_PINCODE_LOCALITY": "PUNE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410101,
      "TXT_PINCODE_LOCALITY": "NERAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410102,
      "TXT_PINCODE_LOCALITY": "MATHERAN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410105,
      "TXT_PINCODE_LOCALITY": "MARKAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1298,
      "City District Name": "KHED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410106,
      "TXT_PINCODE_LOCALITY": "PANVEL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 563,
      "City District Name": "RAIGAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410120,
      "TXT_PINCODE_LOCALITY": "UMROLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 559,
      "City District Name": "PANVEL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410131,
      "TXT_PINCODE_LOCALITY": "KADUS",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1298,
      "City District Name": "KHED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410200,
      "TXT_PINCODE_LOCALITY": "MORBE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 559,
      "City District Name": "PANVEL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410201,
      "TXT_PINCODE_LOCALITY": "KARJAT (KOLABA)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410202,
      "TXT_PINCODE_LOCALITY": "KARJAT",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410203,
      "TXT_PINCODE_LOCALITY": "SHILLPHATE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410204,
      "TXT_PINCODE_LOCALITY": "KHOPOLI POWER HOUSE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410205,
      "TXT_PINCODE_LOCALITY": "PALI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410206,
      "TXT_PINCODE_LOCALITY": "PANVEL WEST",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410207,
      "TXT_PINCODE_LOCALITY": "CHAMBERLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410208,
      "TXT_PINCODE_LOCALITY": "TALOJA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410209,
      "TXT_PINCODE_LOCALITY": "JCI KAMOTHE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 462,
      "City District Name": "NAVI MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410210,
      "TXT_PINCODE_LOCALITY": "AJIVALI S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 462,
      "City District Name": "NAVI MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410213,
      "TXT_PINCODE_LOCALITY": "VASHENI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410216,
      "TXT_PINCODE_LOCALITY": "HOUSE NO 907",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 559,
      "City District Name": "PANVEL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410217,
      "TXT_PINCODE_LOCALITY": "NEW PANVEL SECTOR 18",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410218,
      "TXT_PINCODE_LOCALITY": "KALAMBOLI.",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 462,
      "City District Name": "NAVI MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410220,
      "TXT_PINCODE_LOCALITY": "ANAND NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410221,
      "TXT_PINCODE_LOCALITY": "ONGC COMPLEX, PANVEL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410222,
      "TXT_PINCODE_LOCALITY": "ALIBAUG",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410301,
      "TXT_PINCODE_LOCALITY": "KHANDALA (BAVDA)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410302,
      "TXT_PINCODE_LOCALITY": "RPTS KHANDALA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410401,
      "TXT_PINCODE_LOCALITY": "LONAVALA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410402,
      "TXT_PINCODE_LOCALITY": "INS SHIVAJINAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410403,
      "TXT_PINCODE_LOCALITY": "KAIWALYADHAM",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410404,
      "TXT_PINCODE_LOCALITY": "GADAKWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1298,
      "City District Name": "KHED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410405,
      "TXT_PINCODE_LOCALITY": "KAMSHET",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410406,
      "TXT_PINCODE_LOCALITY": "PAWANANAGAR S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410407,
      "TXT_PINCODE_LOCALITY": "NANOLI TARF CHAKAN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442857,
      "City District Name": "MAWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410409,
      "TXT_PINCODE_LOCALITY": "POKHARKARWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442174,
      "City District Name": "AMBEGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410410,
      "TXT_PINCODE_LOCALITY": "VATEGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410412,
      "TXT_PINCODE_LOCALITY": "INS SHIVAJI LONAVALA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410417,
      "TXT_PINCODE_LOCALITY": "SHIVANE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442857,
      "City District Name": "MAWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410476,
      "TXT_PINCODE_LOCALITY": "MANGARUL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442857,
      "City District Name": "MAWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410501,
      "TXT_PINCODE_LOCALITY": "CHAKAN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410502,
      "TXT_PINCODE_LOCALITY": "JUNNAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410503,
      "TXT_PINCODE_LOCALITY": "MANCHAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410504,
      "TXT_PINCODE_LOCALITY": "NARAYANGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410505,
      "TXT_PINCODE_LOCALITY": "RAJ GURU NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410506,
      "TXT_PINCODE_LOCALITY": "TALEGAON ORD FACTORY",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410507,
      "TXT_PINCODE_LOCALITY": "TALEGAON GEN HOSPITAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410508,
      "TXT_PINCODE_LOCALITY": "DHAMNI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410509,
      "TXT_PINCODE_LOCALITY": "DIMBHE COLONY",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410510,
      "TXT_PINCODE_LOCALITY": "LONI DHAMANI S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410511,
      "TXT_PINCODE_LOCALITY": "SIZALI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410512,
      "TXT_PINCODE_LOCALITY": "PETH (PUNE)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410513,
      "TXT_PINCODE_LOCALITY": "CHAS S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410514,
      "TXT_PINCODE_LOCALITY": "VAFGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410515,
      "TXT_PINCODE_LOCALITY": "SAKORE B.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410516,
      "TXT_PINCODE_LOCALITY": "SHINOJI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410629,
      "TXT_PINCODE_LOCALITY": "KHAPAREWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442835,
      "City District Name": "BHUDARGAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410662,
      "TXT_PINCODE_LOCALITY": "AYANAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1480,
      "City District Name": "KANKAVLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410702,
      "TXT_PINCODE_LOCALITY": "TITHAVALI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1696,
      "City District Name": "RAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 410706,
      "TXT_PINCODE_LOCALITY": "GAVHAN VILLAGE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 559,
      "City District Name": "PANVEL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411001,
      "TXT_PINCODE_LOCALITY": "PUNE CDA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411002,
      "TXT_PINCODE_LOCALITY": "PUNE SUKRAWARPETH",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411003,
      "TXT_PINCODE_LOCALITY": "KHADKI EAST",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411004,
      "TXT_PINCODE_LOCALITY": "DECCA FILM INSTITUTE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411005,
      "TXT_PINCODE_LOCALITY": "SHIVAJINAGAR H O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411006,
      "TXT_PINCODE_LOCALITY": "YERWADA TOWN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411007,
      "TXT_PINCODE_LOCALITY": "GANESHKHIND",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411008,
      "TXT_PINCODE_LOCALITY": "NATIONAL CHEM LABORATORY",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411009,
      "TXT_PINCODE_LOCALITY": "PARVATI TILAK V NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411010,
      "TXT_PINCODE_LOCALITY": "MG ROAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411011,
      "TXT_PINCODE_LOCALITY": "KASBAPETH (PUNE)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411012,
      "TXT_PINCODE_LOCALITY": "DAPODI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411013,
      "TXT_PINCODE_LOCALITY": "HADAPSAR I F",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411014,
      "TXT_PINCODE_LOCALITY": "NAGAR ROAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411015,
      "TXT_PINCODE_LOCALITY": "DIGHI CAMP",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411016,
      "TXT_PINCODE_LOCALITY": "MODEL COLONY",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411017,
      "TXT_PINCODE_LOCALITY": "PIMPRI COLONY",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411018,
      "TXT_PINCODE_LOCALITY": "PIMPRI P F",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411019,
      "TXT_PINCODE_LOCALITY": "HINJEWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411020,
      "TXT_PINCODE_LOCALITY": "RANGE HILLS",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411021,
      "TXT_PINCODE_LOCALITY": "ARMAMENT",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411022,
      "TXT_PINCODE_LOCALITY": "S S C EXAM BOARD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411023,
      "TXT_PINCODE_LOCALITY": "KHADAKWASALA NC",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411024,
      "TXT_PINCODE_LOCALITY": "KHADA KWASLA R S",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411025,
      "TXT_PINCODE_LOCALITY": "I A T PUNE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411026,
      "TXT_PINCODE_LOCALITY": "BHOSARI I E",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411027,
      "TXT_PINCODE_LOCALITY": "AUNDH CAMP",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411028,
      "TXT_PINCODE_LOCALITY": "MOHAMMED WADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411029,
      "TXT_PINCODE_LOCALITY": "PAUD ROAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411030,
      "TXT_PINCODE_LOCALITY": "S P NARAYANPETH",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411031,
      "TXT_PINCODE_LOCALITY": "C M E PUNE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411032,
      "TXT_PINCODE_LOCALITY": "I A F STATION PUNE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411033,
      "TXT_PINCODE_LOCALITY": "CHINCHWADNGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411034,
      "TXT_PINCODE_LOCALITY": "KASARVADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411035,
      "TXT_PINCODE_LOCALITY": "AKURDI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411036,
      "TXT_PINCODE_LOCALITY": "MUNDWA A V",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411037,
      "TXT_PINCODE_LOCALITY": "MARKET YARD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411038,
      "TXT_PINCODE_LOCALITY": "KOTHRUD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411039,
      "TXT_PINCODE_LOCALITY": "BHOSARIGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411040,
      "TXT_PINCODE_LOCALITY": "WANAVADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411041,
      "TXT_PINCODE_LOCALITY": "VADGAON BK",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411042,
      "TXT_PINCODE_LOCALITY": "VITHALWADI RD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411043,
      "TXT_PINCODE_LOCALITY": "DHANK WADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411044,
      "TXT_PINCODE_LOCALITY": "NEW TOWN SHIP",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411045,
      "TXT_PINCODE_LOCALITY": "PASHAN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411046,
      "TXT_PINCODE_LOCALITY": "AMBEGAON BUDRUK",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411047,
      "TXT_PINCODE_LOCALITY": "LOHOGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411048,
      "TXT_PINCODE_LOCALITY": "HAVELI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411049,
      "TXT_PINCODE_LOCALITY": "GHORPADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411051,
      "TXT_PINCODE_LOCALITY": "ANAND NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411052,
      "TXT_PINCODE_LOCALITY": "WARJE JAKAT NAKA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411053,
      "TXT_PINCODE_LOCALITY": "GOKHALENAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411057,
      "TXT_PINCODE_LOCALITY": "MULASHI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411058,
      "TXT_PINCODE_LOCALITY": "PUNE S. O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411060,
      "TXT_PINCODE_LOCALITY": "MOHAMADWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411061,
      "TXT_PINCODE_LOCALITY": "PIMPLE GURAV S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411062,
      "TXT_PINCODE_LOCALITY": "BHOSARI S. O.",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411067,
      "TXT_PINCODE_LOCALITY": "AUNDH",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411068,
      "TXT_PINCODE_LOCALITY": "HAVELI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411069,
      "TXT_PINCODE_LOCALITY": "BANER DSO (PUNE)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411105,
      "TXT_PINCODE_LOCALITY": "SONAI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411125,
      "TXT_PINCODE_LOCALITY": "BELPUKUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411210,
      "TXT_PINCODE_LOCALITY": "BELAVADE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411402,
      "TXT_PINCODE_LOCALITY": "KONDHUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442858,
      "City District Name": "MULSHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411412,
      "TXT_PINCODE_LOCALITY": "SULTANPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 3630,
      "City District Name": "JUNNAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411501,
      "TXT_PINCODE_LOCALITY": "PUNE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411520,
      "TXT_PINCODE_LOCALITY": "TERDI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442854,
      "City District Name": "MANDANGAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411606,
      "TXT_PINCODE_LOCALITY": "AKIWAT",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 425817,
      "City District Name": "SHIROL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 411925,
      "TXT_PINCODE_LOCALITY": "MUNDHRI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412001,
      "TXT_PINCODE_LOCALITY": "KIVALE SHALEENYA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412011,
      "TXT_PINCODE_LOCALITY": "PUNE.",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412014,
      "TXT_PINCODE_LOCALITY": "VIMAN NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412050,
      "TXT_PINCODE_LOCALITY": "VARHADJAMBHULPADA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1557,
      "City District Name": "SUDHAGAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412100,
      "TXT_PINCODE_LOCALITY": "SAI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442857,
      "City District Name": "MAWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412101,
      "TXT_PINCODE_LOCALITY": "DEHU ROAD CANTT",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412102,
      "TXT_PINCODE_LOCALITY": "NIRA R S",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412103,
      "TXT_PINCODE_LOCALITY": "NIMABALKAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412104,
      "TXT_PINCODE_LOCALITY": "RAJEWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412105,
      "TXT_PINCODE_LOCALITY": "ALANDI DEVACHI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412106,
      "TXT_PINCODE_LOCALITY": "CHAKAN PHATA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412107,
      "TXT_PINCODE_LOCALITY": "PANSHET",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412108,
      "TXT_PINCODE_LOCALITY": "PAUD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412109,
      "TXT_PINCODE_LOCALITY": "DEHU ROAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412110,
      "TXT_PINCODE_LOCALITY": "THEUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412111,
      "TXT_PINCODE_LOCALITY": "MULSHI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412112,
      "TXT_PINCODE_LOCALITY": "CHARKOLI B K",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412113,
      "TXT_PINCODE_LOCALITY": "DEHU ROAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412114,
      "TXT_PINCODE_LOCALITY": "TALAWADE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412115,
      "TXT_PINCODE_LOCALITY": "URAVADE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412167,
      "TXT_PINCODE_LOCALITY": "MOSE BK",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442263,
      "City District Name": "VELHE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412201,
      "TXT_PINCODE_LOCALITY": "LONI KALBHOR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412202,
      "TXT_PINCODE_LOCALITY": "URULI KANCHAN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412203,
      "TXT_PINCODE_LOCALITY": "KEDGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412204,
      "TXT_PINCODE_LOCALITY": "SUPA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412205,
      "TXT_PINCODE_LOCALITY": "KHED SHIVAPUR (BANG)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412206,
      "TXT_PINCODE_LOCALITY": "BHOR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412207,
      "TXT_PINCODE_LOCALITY": "WAGHOLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412208,
      "TXT_PINCODE_LOCALITY": "ROHINJAN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412209,
      "TXT_PINCODE_LOCALITY": "KARANJA (KOKAN)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412210,
      "TXT_PINCODE_LOCALITY": "GHODNADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412211,
      "TXT_PINCODE_LOCALITY": "NHAVARA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412212,
      "TXT_PINCODE_LOCALITY": "VELHE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412213,
      "TXT_PINCODE_LOCALITY": "NARSARAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412214,
      "TXT_PINCODE_LOCALITY": "YAWAT",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412215,
      "TXT_PINCODE_LOCALITY": "VARVAND",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412216,
      "TXT_PINCODE_LOCALITY": "LONIKAND",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412217,
      "TXT_PINCODE_LOCALITY": "WAGHOLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412218,
      "TXT_PINCODE_LOCALITY": "MALTHAN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412219,
      "TXT_PINCODE_LOCALITY": "MADHUKARNAGAR PATAS",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412220,
      "TXT_PINCODE_LOCALITY": "KAREGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412292,
      "TXT_PINCODE_LOCALITY": "PUNE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412296,
      "TXT_PINCODE_LOCALITY": "BURKEGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442342,
      "City District Name": "HAVELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412301,
      "TXT_PINCODE_LOCALITY": "SASWAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412302,
      "TXT_PINCODE_LOCALITY": "PURANDHAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412303,
      "TXT_PINCODE_LOCALITY": "JEJURI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412304,
      "TXT_PINCODE_LOCALITY": "MORGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412305,
      "TXT_PINCODE_LOCALITY": "VALHE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412306,
      "TXT_PINCODE_LOCALITY": "SOMESHWARNAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412307,
      "TXT_PINCODE_LOCALITY": "MANJARI FARM",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412308,
      "TXT_PINCODE_LOCALITY": "WADKI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412309,
      "TXT_PINCODE_LOCALITY": "KARANJA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412310,
      "TXT_PINCODE_LOCALITY": "PUNE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412311,
      "TXT_PINCODE_LOCALITY": "PARINCHE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412312,
      "TXT_PINCODE_LOCALITY": "VEER",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412401,
      "TXT_PINCODE_LOCALITY": "ARVI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412402,
      "TXT_PINCODE_LOCALITY": "VADA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412403,
      "TXT_PINCODE_LOCALITY": "PABAI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412404,
      "TXT_PINCODE_LOCALITY": "KADUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412405,
      "TXT_PINCODE_LOCALITY": "AVSARI BUDRUK",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412406,
      "TXT_PINCODE_LOCALITY": "AVSARI BK",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412407,
      "TXT_PINCODE_LOCALITY": "AMBEGAON  S. O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412408,
      "TXT_PINCODE_LOCALITY": "GODEGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412409,
      "TXT_PINCODE_LOCALITY": "OTUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412410,
      "TXT_PINCODE_LOCALITY": "BELHA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412411,
      "TXT_PINCODE_LOCALITY": "ALA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412412,
      "TXT_PINCODE_LOCALITY": "PIMPALWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412413,
      "TXT_PINCODE_LOCALITY": "KENDNE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412415,
      "TXT_PINCODE_LOCALITY": "EARTH STN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412416,
      "TXT_PINCODE_LOCALITY": "NALAWANE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 3630,
      "City District Name": "JUNNAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412417,
      "TXT_PINCODE_LOCALITY": "LATUR S. O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 553,
      "City District Name": "LATUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412501,
      "TXT_PINCODE_LOCALITY": "BORHADEWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412508,
      "TXT_PINCODE_LOCALITY": "AHUPE, ZPPS BALVEERWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412509,
      "TXT_PINCODE_LOCALITY": "PUNE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412513,
      "TXT_PINCODE_LOCALITY": "LATUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 553,
      "City District Name": "LATUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412561,
      "TXT_PINCODE_LOCALITY": "DHAMANSE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412600,
      "TXT_PINCODE_LOCALITY": "NAKINDA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1201,
      "City District Name": "MAHABALESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412602,
      "TXT_PINCODE_LOCALITY": "VARODI KH.",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 2524,
      "City District Name": "BHOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412603,
      "TXT_PINCODE_LOCALITY": "KHAVALI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1274,
      "City District Name": "WAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412624,
      "TXT_PINCODE_LOCALITY": "SAWARGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1695,
      "City District Name": "TULJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412800,
      "TXT_PINCODE_LOCALITY": "SOUNDARI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1201,
      "City District Name": "MAHABALESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412801,
      "TXT_PINCODE_LOCALITY": "SHIRVAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412802,
      "TXT_PINCODE_LOCALITY": "KHANDALA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412803,
      "TXT_PINCODE_LOCALITY": "WAI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412804,
      "TXT_PINCODE_LOCALITY": "BAVDHAN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412805,
      "TXT_PINCODE_LOCALITY": "PANCHAGANI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412806,
      "TXT_PINCODE_LOCALITY": "MAHABALESHWAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412832,
      "TXT_PINCODE_LOCALITY": "GURDEHALLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442875,
      "City District Name": "SOLAPUR SOUTH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 412907,
      "TXT_PINCODE_LOCALITY": "KHANU",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442263,
      "City District Name": "VELHE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413000,
      "TXT_PINCODE_LOCALITY": "SHELAGI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413001,
      "TXT_PINCODE_LOCALITY": "SHOLAPUR SHANIWAR PETH",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413002,
      "TXT_PINCODE_LOCALITY": "SHOLAPUR DAYANAND COLLEGE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413003,
      "TXT_PINCODE_LOCALITY": "SHOLAPUR MEDICAL COLLEGE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413004,
      "TXT_PINCODE_LOCALITY": "INDIRA NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413005,
      "TXT_PINCODE_LOCALITY": "DAJIPETH",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413006,
      "TXT_PINCODE_LOCALITY": "SHOLAPUR ASHOK CHOWK",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413007,
      "TXT_PINCODE_LOCALITY": "SHOLAPUR CITY",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413008,
      "TXT_PINCODE_LOCALITY": "JULE SOLAPUR S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413013,
      "TXT_PINCODE_LOCALITY": "KAMATHI T. PARALI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413031,
      "TXT_PINCODE_LOCALITY": "POUNDHAWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1568,
      "City District Name": "INDAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413039,
      "TXT_PINCODE_LOCALITY": "SHETPHALGADHE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1568,
      "City District Name": "INDAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413101,
      "TXT_PINCODE_LOCALITY": "AKLUJ",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413102,
      "TXT_PINCODE_LOCALITY": "SONGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413103,
      "TXT_PINCODE_LOCALITY": "BAWADA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413104,
      "TXT_PINCODE_LOCALITY": "RAJURI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 522,
      "City District Name": "AHMEDNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413105,
      "TXT_PINCODE_LOCALITY": "BHIGWAN R S",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413106,
      "TXT_PINCODE_LOCALITY": "INDAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413107,
      "TXT_PINCODE_LOCALITY": "MALSIRAS",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413108,
      "TXT_PINCODE_LOCALITY": "MALINAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413109,
      "TXT_PINCODE_LOCALITY": "DAHIGAON(SOLAPUR)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413110,
      "TXT_PINCODE_LOCALITY": "LONE BHAPKAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413111,
      "TXT_PINCODE_LOCALITY": "SADASHIVAANGAI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413112,
      "TXT_PINCODE_LOCALITY": "SHREEPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413113,
      "TXT_PINCODE_LOCALITY": "VELAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413114,
      "TXT_PINCODE_LOCALITY": "WALCHANDNAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413115,
      "TXT_PINCODE_LOCALITY": "MALEGAON B K",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413116,
      "TXT_PINCODE_LOCALITY": "SHIVNAPAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413117,
      "TXT_PINCODE_LOCALITY": "PUNE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413118,
      "TXT_PINCODE_LOCALITY": "YESHWANTNAGAR PANDHARPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413119,
      "TXT_PINCODE_LOCALITY": "ANTHURNA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413120,
      "TXT_PINCODE_LOCALITY": "NIMBGAON KETKI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413121,
      "TXT_PINCODE_LOCALITY": "SOLAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413122,
      "TXT_PINCODE_LOCALITY": "DAHIGAON(SOLAPUR)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413124,
      "TXT_PINCODE_LOCALITY": "SHELGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413125,
      "TXT_PINCODE_LOCALITY": "SANGVI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413128,
      "TXT_PINCODE_LOCALITY": "PARANDAVADE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413130,
      "TXT_PINCODE_LOCALITY": "BHIGWAN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413132,
      "TXT_PINCODE_LOCALITY": "PALASDEO",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413133,
      "TXT_PINCODE_LOCALITY": "BARAMATI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413153,
      "TXT_PINCODE_LOCALITY": "TANDULJA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 553,
      "City District Name": "LATUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413173,
      "TXT_PINCODE_LOCALITY": "DHANGARWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442868,
      "City District Name": "RAHTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413192,
      "TXT_PINCODE_LOCALITY": "KALTHAN NO.2",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1568,
      "City District Name": "INDAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413201,
      "TXT_PINCODE_LOCALITY": "JAMKHED",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413202,
      "TXT_PINCODE_LOCALITY": "JEUR (PANDHARPUR)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413203,
      "TXT_PINCODE_LOCALITY": "KARMALA TOWN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413204,
      "TXT_PINCODE_LOCALITY": "KHARDA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413205,
      "TXT_PINCODE_LOCALITY": "NANAJ (AHMEDANAGAR)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413206,
      "TXT_PINCODE_LOCALITY": "POMALWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413207,
      "TXT_PINCODE_LOCALITY": "AMALNER (BHIR)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 534,
      "City District Name": "BEED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413208,
      "TXT_PINCODE_LOCALITY": "KURUNDWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413209,
      "TXT_PINCODE_LOCALITY": "MADHA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413210,
      "TXT_PINCODE_LOCALITY": "PIMPALNER (SOLAPUR)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413211,
      "TXT_PINCODE_LOCALITY": "TEMBHURNI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413212,
      "TXT_PINCODE_LOCALITY": "BHIMANAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413213,
      "TXT_PINCODE_LOCALITY": "MOHOL BAZARPETH",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413214,
      "TXT_PINCODE_LOCALITY": "ANGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413215,
      "TXT_PINCODE_LOCALITY": "HOTGI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413216,
      "TXT_PINCODE_LOCALITY": "SALGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413217,
      "TXT_PINCODE_LOCALITY": "MAINDARGI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413218,
      "TXT_PINCODE_LOCALITY": "VADHADARI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413219,
      "TXT_PINCODE_LOCALITY": "MANGRUL (SHOLAPUR)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413220,
      "TXT_PINCODE_LOCALITY": "DUDHANI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413221,
      "TXT_PINCODE_LOCALITY": "MANDRUP",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413222,
      "TXT_PINCODE_LOCALITY": "NANAJ (SHOLAPUR)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413223,
      "TXT_PINCODE_LOCALITY": "KEM",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413224,
      "TXT_PINCODE_LOCALITY": "TIKEKARWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413225,
      "TXT_PINCODE_LOCALITY": "SOLAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413226,
      "TXT_PINCODE_LOCALITY": "CHAPALGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413227,
      "TXT_PINCODE_LOCALITY": "NAGAMSUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413228,
      "TXT_PINCODE_LOCALITY": "VALSANG",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413229,
      "TXT_PINCODE_LOCALITY": "PIMPALWANDI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413230,
      "TXT_PINCODE_LOCALITY": "SOLAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413231,
      "TXT_PINCODE_LOCALITY": "SOLAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413232,
      "TXT_PINCODE_LOCALITY": "SOLAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413233,
      "TXT_PINCODE_LOCALITY": "SOLAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413234,
      "TXT_PINCODE_LOCALITY": "SOLAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413235,
      "TXT_PINCODE_LOCALITY": "JEUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413237,
      "TXT_PINCODE_LOCALITY": "SOLAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413240,
      "TXT_PINCODE_LOCALITY": "SOLAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413241,
      "TXT_PINCODE_LOCALITY": "SOLAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413242,
      "TXT_PINCODE_LOCALITY": "PAKNI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413243,
      "TXT_PINCODE_LOCALITY": "DEOGAON(SHOLAPUR)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413244,
      "TXT_PINCODE_LOCALITY": "HIRAJ",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413245,
      "TXT_PINCODE_LOCALITY": "SOLAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413246,
      "TXT_PINCODE_LOCALITY": "VADALA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413247,
      "TXT_PINCODE_LOCALITY": "HINGEWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442873,
      "City District Name": "SHIRUR (KASAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413248,
      "TXT_PINCODE_LOCALITY": "PENNAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413249,
      "TXT_PINCODE_LOCALITY": "SHIRUR S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413250,
      "TXT_PINCODE_LOCALITY": "MAHISGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413251,
      "TXT_PINCODE_LOCALITY": "SADA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413252,
      "TXT_PINCODE_LOCALITY": "KAVHE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413253,
      "TXT_PINCODE_LOCALITY": "KAMATHI (KHURD)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413255,
      "TXT_PINCODE_LOCALITY": "PAKANI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413300,
      "TXT_PINCODE_LOCALITY": "RAJUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442875,
      "City District Name": "SOLAPUR SOUTH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413301,
      "TXT_PINCODE_LOCALITY": "MODNIMB",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413302,
      "TXT_PINCODE_LOCALITY": "KARKAMB",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413303,
      "TXT_PINCODE_LOCALITY": "ASHTI (SHOLAPUR)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413304,
      "TXT_PINCODE_LOCALITY": "PANDHARPUR BHAJI MKT",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413305,
      "TXT_PINCODE_LOCALITY": "MANGALVEDHA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413306,
      "TXT_PINCODE_LOCALITY": "MAHARASHTRA E R I",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413307,
      "TXT_PINCODE_LOCALITY": "CHINCHOLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413308,
      "TXT_PINCODE_LOCALITY": "NAZANE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413309,
      "TXT_PINCODE_LOCALITY": "JAVALA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413310,
      "TXT_PINCODE_LOCALITY": "PILAV",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413311,
      "TXT_PINCODE_LOCALITY": "KASEGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413312,
      "TXT_PINCODE_LOCALITY": "SOLAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413313,
      "TXT_PINCODE_LOCALITY": "ROPLA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413314,
      "TXT_PINCODE_LOCALITY": "KOLE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413315,
      "TXT_PINCODE_LOCALITY": "RANJNI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413316,
      "TXT_PINCODE_LOCALITY": "SOLAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413317,
      "TXT_PINCODE_LOCALITY": "KHARDI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413318,
      "TXT_PINCODE_LOCALITY": "SANGLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413319,
      "TXT_PINCODE_LOCALITY": "MARWADE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413322,
      "TXT_PINCODE_LOCALITY": "BHOSA (M)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413324,
      "TXT_PINCODE_LOCALITY": "SHETPHUL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413332,
      "TXT_PINCODE_LOCALITY": "MALWADI.",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 3815,
      "City District Name": "KARMALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413335,
      "TXT_PINCODE_LOCALITY": "LENDAVE CHICHALE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4410,
      "City District Name": "MANGALVEDHE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413355,
      "TXT_PINCODE_LOCALITY": "NIMBONI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4410,
      "City District Name": "MANGALVEDHE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413401,
      "TXT_PINCODE_LOCALITY": "BARSHI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413402,
      "TXT_PINCODE_LOCALITY": "VAIRAG",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413403,
      "TXT_PINCODE_LOCALITY": "PANGAON (OSMANABAD)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413404,
      "TXT_PINCODE_LOCALITY": "PANGRI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413405,
      "TXT_PINCODE_LOCALITY": "YEDSHI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413406,
      "TXT_PINCODE_LOCALITY": "GANDGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413407,
      "TXT_PINCODE_LOCALITY": "SHINGOLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413409,
      "TXT_PINCODE_LOCALITY": "AGALGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413410,
      "TXT_PINCODE_LOCALITY": "MANEGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413411,
      "TXT_PINCODE_LOCALITY": "K.V.NAGAR BARSHI S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413412,
      "TXT_PINCODE_LOCALITY": "TULSIDASNAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413424,
      "TXT_PINCODE_LOCALITY": "PHATATEWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442875,
      "City District Name": "SOLAPUR SOUTH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413500,
      "TXT_PINCODE_LOCALITY": "JANKAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442878,
      "City District Name": "WASHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413501,
      "TXT_PINCODE_LOCALITY": "PARA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413502,
      "TXT_PINCODE_LOCALITY": "SHIRALA (OSMANABAD)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413503,
      "TXT_PINCODE_LOCALITY": "VASI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413504,
      "TXT_PINCODE_LOCALITY": "WALWAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413505,
      "TXT_PINCODE_LOCALITY": "TANDULWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413506,
      "TXT_PINCODE_LOCALITY": "BEMBLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413507,
      "TXT_PINCODE_LOCALITY": "ITKUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413508,
      "TXT_PINCODE_LOCALITY": "DHOKI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413509,
      "TXT_PINCODE_LOCALITY": "TER",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413510,
      "TXT_PINCODE_LOCALITY": "MURUD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 553,
      "City District Name": "LATUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413511,
      "TXT_PINCODE_LOCALITY": "GATEGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 553,
      "City District Name": "LATUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413512,
      "TXT_PINCODE_LOCALITY": "LATUR H O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 553,
      "City District Name": "LATUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413513,
      "TXT_PINCODE_LOCALITY": "CHAKUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 553,
      "City District Name": "LATUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413514,
      "TXT_PINCODE_LOCALITY": "SIZPUR TAJBAND",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413515,
      "TXT_PINCODE_LOCALITY": "KHANDALI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 553,
      "City District Name": "LATUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413516,
      "TXT_PINCODE_LOCALITY": "KILLARI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 553,
      "City District Name": "LATUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413517,
      "TXT_PINCODE_LOCALITY": "UDGIR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 553,
      "City District Name": "LATUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413518,
      "TXT_PINCODE_LOCALITY": "WADHONA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413519,
      "TXT_PINCODE_LOCALITY": "BORAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413520,
      "TXT_PINCODE_LOCALITY": "NAGARSOGA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 553,
      "City District Name": "LATUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413521,
      "TXT_PINCODE_LOCALITY": "NILANJA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 553,
      "City District Name": "LATUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413522,
      "TXT_PINCODE_LOCALITY": "AURAD SHAHAJANI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 553,
      "City District Name": "LATUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413523,
      "TXT_PINCODE_LOCALITY": "KINGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 553,
      "City District Name": "LATUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413524,
      "TXT_PINCODE_LOCALITY": "NALEGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 553,
      "City District Name": "LATUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413525,
      "TXT_PINCODE_LOCALITY": "YERMALA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413526,
      "TXT_PINCODE_LOCALITY": "PARGAON (OSMANABAD)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413527,
      "TXT_PINCODE_LOCALITY": "RENAPUR S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 553,
      "City District Name": "LATUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413528,
      "TXT_PINCODE_LOCALITY": "SHIRADHON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413529,
      "TXT_PINCODE_LOCALITY": "WADALA NAGNATH",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413530,
      "TXT_PINCODE_LOCALITY": "NITTUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 553,
      "City District Name": "LATUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413531,
      "TXT_PINCODE_LOCALITY": "KAMDAR ROAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 553,
      "City District Name": "LATUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413532,
      "TXT_PINCODE_LOCALITY": "MAL HIPPERGA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 553,
      "City District Name": "LATUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413534,
      "TXT_PINCODE_LOCALITY": "LEET",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413536,
      "TXT_PINCODE_LOCALITY": "SHIRALA (OSMANABAD)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413537,
      "TXT_PINCODE_LOCALITY": "CHAPOLI S. O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 553,
      "City District Name": "LATUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413539,
      "TXT_PINCODE_LOCALITY": "KHAMSA WADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 553,
      "City District Name": "LATUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413541,
      "TXT_PINCODE_LOCALITY": "OSMANABAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413542,
      "TXT_PINCODE_LOCALITY": "JEWALI (LATUR)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 553,
      "City District Name": "LATUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413543,
      "TXT_PINCODE_LOCALITY": "BORI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413544,
      "TXT_PINCODE_LOCALITY": "SIZUR ANANTPAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 553,
      "City District Name": "LATUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413546,
      "TXT_PINCODE_LOCALITY": "JALKOL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413547,
      "TXT_PINCODE_LOCALITY": "LOHARA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413553,
      "TXT_PINCODE_LOCALITY": "MADANSURI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 553,
      "City District Name": "LATUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413554,
      "TXT_PINCODE_LOCALITY": "SHELGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413558,
      "TXT_PINCODE_LOCALITY": "LATUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 553,
      "City District Name": "LATUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413563,
      "TXT_PINCODE_LOCALITY": "TONDON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 553,
      "City District Name": "LATUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413567,
      "TXT_PINCODE_LOCALITY": "PADOLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413569,
      "TXT_PINCODE_LOCALITY": "OSMANABAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413575,
      "TXT_PINCODE_LOCALITY": "SOLAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413576,
      "TXT_PINCODE_LOCALITY": "ULPOOP",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413577,
      "TXT_PINCODE_LOCALITY": "WAGHOLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413580,
      "TXT_PINCODE_LOCALITY": "TERNANAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413581,
      "TXT_PINCODE_LOCALITY": "MAHALANGRA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 553,
      "City District Name": "LATUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413582,
      "TXT_PINCODE_LOCALITY": "WADGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413601,
      "TXT_PINCODE_LOCALITY": "TULJAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413602,
      "TXT_PINCODE_LOCALITY": "NALDURG",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413603,
      "TXT_PINCODE_LOCALITY": "ANDORA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413604,
      "TXT_PINCODE_LOCALITY": "DALIM",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413605,
      "TXT_PINCODE_LOCALITY": "MARAM",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413606,
      "TXT_PINCODE_LOCALITY": "GUNJOTI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413607,
      "TXT_PINCODE_LOCALITY": "KASAR BALKUND",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 553,
      "City District Name": "LATUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413608,
      "TXT_PINCODE_LOCALITY": "LOHARA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413612,
      "TXT_PINCODE_LOCALITY": "JEWALI (OSMANABAD)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413616,
      "TXT_PINCODE_LOCALITY": "MANGRUL (OSMANBAD)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413617,
      "TXT_PINCODE_LOCALITY": "JANEKUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413619,
      "TXT_PINCODE_LOCALITY": "KAVATHA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413623,
      "TXT_PINCODE_LOCALITY": "MALUMBRA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413624,
      "TXT_PINCODE_LOCALITY": "KATI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 558,
      "City District Name": "OSMANABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413701,
      "TXT_PINCODE_LOCALITY": "AHMEDNAGAR S. O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 522,
      "City District Name": "AHMEDNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413702,
      "TXT_PINCODE_LOCALITY": "BELVANDI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413703,
      "TXT_PINCODE_LOCALITY": "VISAPUR (AHMEDNAGAR)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413704,
      "TXT_PINCODE_LOCALITY": "VAMBORI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413705,
      "TXT_PINCODE_LOCALITY": "RAHURI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413706,
      "TXT_PINCODE_LOCALITY": "RAHURI FACTORY",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413707,
      "TXT_PINCODE_LOCALITY": "PUNTAMBHA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 522,
      "City District Name": "AHMEDNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413708,
      "TXT_PINCODE_LOCALITY": "SAKHARWADI (AHMEDNAGAR)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413709,
      "TXT_PINCODE_LOCALITY": "MULA PRAVARA (SHRIRAMPUR)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413710,
      "TXT_PINCODE_LOCALITY": "KOLHAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 522,
      "City District Name": "AHMEDNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413711,
      "TXT_PINCODE_LOCALITY": "SONGAON S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413712,
      "TXT_PINCODE_LOCALITY": "PRAVARANAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 522,
      "City District Name": "AHMEDNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413713,
      "TXT_PINCODE_LOCALITY": "LONI KHURD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413714,
      "TXT_PINCODE_LOCALITY": "ASHVI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 522,
      "City District Name": "AHMEDNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413715,
      "TXT_PINCODE_LOCALITY": "BELAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 522,
      "City District Name": "AHMEDNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413716,
      "TXT_PINCODE_LOCALITY": "DEVLAI PRAVARA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 522,
      "City District Name": "AHMEDNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413717,
      "TXT_PINCODE_LOCALITY": "KAREGAON FACTORY",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 522,
      "City District Name": "AHMEDNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413718,
      "TXT_PINCODE_LOCALITY": "SHRIRAMPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 522,
      "City District Name": "AHMEDNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413719,
      "TXT_PINCODE_LOCALITY": "RAMJAMGAON KHURD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 522,
      "City District Name": "AHMEDNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413720,
      "TXT_PINCODE_LOCALITY": "TILAKNAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 522,
      "City District Name": "AHMEDNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413721,
      "TXT_PINCODE_LOCALITY": "PADHEGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 522,
      "City District Name": "AHMEDNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413722,
      "TXT_PINCODE_LOCALITY": "MAHATMA PHULE KRISHI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413723,
      "TXT_PINCODE_LOCALITY": "CHANGDEV NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 522,
      "City District Name": "AHMEDNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413724,
      "TXT_PINCODE_LOCALITY": "PIMPALGAON PISA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413725,
      "TXT_PINCODE_LOCALITY": "TAKLI BHOR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 522,
      "City District Name": "AHMEDNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413726,
      "TXT_PINCODE_LOCALITY": "SHRIGONDA S K",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413727,
      "TXT_PINCODE_LOCALITY": "ADHASGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413728,
      "TXT_PINCODE_LOCALITY": "KALGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 522,
      "City District Name": "AHMEDNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413731,
      "TXT_PINCODE_LOCALITY": "TAKALIBHAN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1383,
      "City District Name": "SHRIRAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413732,
      "TXT_PINCODE_LOCALITY": "NIPANI VADGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413736,
      "TXT_PINCODE_LOCALITY": "LONI B K",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413737,
      "TXT_PINCODE_LOCALITY": "BABHALESHWER",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413738,
      "TXT_PINCODE_LOCALITY": "PANWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 522,
      "City District Name": "AHMEDNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413739,
      "TXT_PINCODE_LOCALITY": "VADALA MAHADEO",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 522,
      "City District Name": "AHMEDNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413801,
      "TXT_PINCODE_LOCALITY": "DAUND",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 413802,
      "TXT_PINCODE_LOCALITY": "PUNE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 181,
      "City District Name": "PUNE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414001,
      "TXT_PINCODE_LOCALITY": "SHIRDI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 522,
      "City District Name": "AHMEDNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414002,
      "TXT_PINCODE_LOCALITY": "AHMEDNAGAR CAMP - 3238",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414003,
      "TXT_PINCODE_LOCALITY": "GULMOHOR ROAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 522,
      "City District Name": "AHMEDNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414004,
      "TXT_PINCODE_LOCALITY": "MAHATMA GANDHI UDYAN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414005,
      "TXT_PINCODE_LOCALITY": "AHMEDNAGAR R S",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414006,
      "TXT_PINCODE_LOCALITY": "VAHANNAGAR S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 522,
      "City District Name": "AHMEDNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414090,
      "TXT_PINCODE_LOCALITY": "WALUNJ",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442859,
      "City District Name": "NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414101,
      "TXT_PINCODE_LOCALITY": "MANDAVGAN FARATA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 522,
      "City District Name": "AHMEDNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414102,
      "TXT_PINCODE_LOCALITY": "PATHARDI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414103,
      "TXT_PINCODE_LOCALITY": "JAMGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414104,
      "TXT_PINCODE_LOCALITY": "PATHARE KH.",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 5259,
      "City District Name": "RAHURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414105,
      "TXT_PINCODE_LOCALITY": "UMBRA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414106,
      "TXT_PINCODE_LOCALITY": "TISGAON (AHMEDNAGAR)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414107,
      "TXT_PINCODE_LOCALITY": "AKOLNER",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414108,
      "TXT_PINCODE_LOCALITY": "KEDGAON (A)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414109,
      "TXT_PINCODE_LOCALITY": "MARILK DAUNDI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414110,
      "TXT_PINCODE_LOCALITY": "MIRC AHMEDNAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414111,
      "TXT_PINCODE_LOCALITY": "AHMEDNAGAR M I D C",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414112,
      "TXT_PINCODE_LOCALITY": "WALKI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414113,
      "TXT_PINCODE_LOCALITY": "KHARWANDI KASAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414143,
      "TXT_PINCODE_LOCALITY": "BONDARWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1299,
      "City District Name": "PATHARDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414200,
      "TXT_PINCODE_LOCALITY": "BHATODI PARGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442859,
      "City District Name": "NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414201,
      "TXT_PINCODE_LOCALITY": "CHICHONDI PATIL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414202,
      "TXT_PINCODE_LOCALITY": "KADA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 534,
      "City District Name": "BEED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414203,
      "TXT_PINCODE_LOCALITY": "ASHTI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 534,
      "City District Name": "BEED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414204,
      "TXT_PINCODE_LOCALITY": "PATODA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 534,
      "City District Name": "BEED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414205,
      "TXT_PINCODE_LOCALITY": "RAJORI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 534,
      "City District Name": "BEED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414208,
      "TXT_PINCODE_LOCALITY": "KATKHANA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 534,
      "City District Name": "BEED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414301,
      "TXT_PINCODE_LOCALITY": "SUPA (AHMEDNAGAR)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414302,
      "TXT_PINCODE_LOCALITY": "PARNER",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414303,
      "TXT_PINCODE_LOCALITY": "KANHUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414304,
      "TXT_PINCODE_LOCALITY": "TAKLI DHOKESHWAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414305,
      "TXT_PINCODE_LOCALITY": "ALKUTI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414306,
      "TXT_PINCODE_LOCALITY": "NIGHOJ",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414307,
      "TXT_PINCODE_LOCALITY": "PARNER S.S.K.",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414401,
      "TXT_PINCODE_LOCALITY": "MIRAJGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414402,
      "TXT_PINCODE_LOCALITY": "KARJAT (AHMEDNAGAR)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414403,
      "TXT_PINCODE_LOCALITY": "RASIN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 522,
      "City District Name": "AHMEDNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414404,
      "TXT_PINCODE_LOCALITY": "KOREGAON (A)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414405,
      "TXT_PINCODE_LOCALITY": "JAGDAMBA S K",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 522,
      "City District Name": "AHMEDNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414410,
      "TXT_PINCODE_LOCALITY": "M I R C AHMEDNAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414501,
      "TXT_PINCODE_LOCALITY": "MIRI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414502,
      "TXT_PINCODE_LOCALITY": "SHEVGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414503,
      "TXT_PINCODE_LOCALITY": "BODHEGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414504,
      "TXT_PINCODE_LOCALITY": "BALAM TAKLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414505,
      "TXT_PINCODE_LOCALITY": "VIRDHYESHWAR S K",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 522,
      "City District Name": "AHMEDNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414506,
      "TXT_PINCODE_LOCALITY": "JAWAKHEDE KHALSA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1299,
      "City District Name": "PATHARDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414601,
      "TXT_PINCODE_LOCALITY": "JEUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 522,
      "City District Name": "AHMEDNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414602,
      "TXT_PINCODE_LOCALITY": "VADALA MISSION",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 522,
      "City District Name": "AHMEDNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414603,
      "TXT_PINCODE_LOCALITY": "NEVASA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414604,
      "TXT_PINCODE_LOCALITY": "KUKANE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414605,
      "TXT_PINCODE_LOCALITY": "BHENDE S K",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414606,
      "TXT_PINCODE_LOCALITY": "CHANDA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414607,
      "TXT_PINCODE_LOCALITY": "GHODEGAON (ANAGON)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414609,
      "TXT_PINCODE_LOCALITY": "BHANASHIVARA S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 522,
      "City District Name": "AHMEDNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414641,
      "TXT_PINCODE_LOCALITY": "SIDDHEWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1417,
      "City District Name": "MIRAJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414701,
      "TXT_PINCODE_LOCALITY": "KASHTI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414702,
      "TXT_PINCODE_LOCALITY": "CHIMBHARLE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 522,
      "City District Name": "AHMEDNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414703,
      "TXT_PINCODE_LOCALITY": "AHMEDNAGAR S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 522,
      "City District Name": "AHMEDNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 414804,
      "TXT_PINCODE_LOCALITY": "TAKALI DHOKESHWAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442865,
      "City District Name": "PARNER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415000,
      "TXT_PINCODE_LOCALITY": "OZARDE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415001,
      "TXT_PINCODE_LOCALITY": "SATARA MALHAR PETH",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415002,
      "TXT_PINCODE_LOCALITY": "SATARA CITY",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415003,
      "TXT_PINCODE_LOCALITY": "SANGAMNAGAR SATARA S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415004,
      "TXT_PINCODE_LOCALITY": "NIVADE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415005,
      "TXT_PINCODE_LOCALITY": "VITHAL NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415010,
      "TXT_PINCODE_LOCALITY": "SATARA ROAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415011,
      "TXT_PINCODE_LOCALITY": "VADUTH",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415012,
      "TXT_PINCODE_LOCALITY": "MEDHA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415013,
      "TXT_PINCODE_LOCALITY": "PARALI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415014,
      "TXT_PINCODE_LOCALITY": "TARALA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415015,
      "TXT_PINCODE_LOCALITY": "LIMBGAVE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415017,
      "TXT_PINCODE_LOCALITY": "KELGH-AR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415018,
      "TXT_PINCODE_LOCALITY": "OZARDE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415019,
      "TXT_PINCODE_LOCALITY": "ANGAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415020,
      "TXT_PINCODE_LOCALITY": "ANEWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415021,
      "TXT_PINCODE_LOCALITY": "KINHAI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415022,
      "TXT_PINCODE_LOCALITY": "BHANANG",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415023,
      "TXT_PINCODE_LOCALITY": "MAHIMANGAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415050,
      "TXT_PINCODE_LOCALITY": "MENDHOLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1957,
      "City District Name": "AJRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415101,
      "TXT_PINCODE_LOCALITY": "KAULAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415102,
      "TXT_PINCODE_LOCALITY": "MAYANI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415103,
      "TXT_PINCODE_LOCALITY": "KOLE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415104,
      "TXT_PINCODE_LOCALITY": "KALE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415105,
      "TXT_PINCODE_LOCALITY": "OGLEWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415106,
      "TXT_PINCODE_LOCALITY": "MASUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415107,
      "TXT_PINCODE_LOCALITY": "TARGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415108,
      "TXT_PINCODE_LOCALITY": "SHIVNAGAR (SATARA)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415109,
      "TXT_PINCODE_LOCALITY": "UMBRAJ",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415110,
      "TXT_PINCODE_LOCALITY": "KARAD VIDYA NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415111,
      "TXT_PINCODE_LOCALITY": "OND",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415112,
      "TXT_PINCODE_LOCALITY": "DHEBEWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415114,
      "TXT_PINCODE_LOCALITY": "SUPNE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415115,
      "TXT_PINCODE_LOCALITY": "YESHWANTNAGAR (KARAD)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415116,
      "TXT_PINCODE_LOCALITY": "VATHAR KIZDI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415117,
      "TXT_PINCODE_LOCALITY": "VIHE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415118,
      "TXT_PINCODE_LOCALITY": "UNDALE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415119,
      "TXT_PINCODE_LOCALITY": "TAMBAVE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415120,
      "TXT_PINCODE_LOCALITY": "CHARIGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415122,
      "TXT_PINCODE_LOCALITY": "WING",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415124,
      "TXT_PINCODE_LOCALITY": "VIDYANAGAR KARAD S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415157,
      "TXT_PINCODE_LOCALITY": "LONVADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 542,
      "City District Name": "DAPOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415201,
      "TXT_PINCODE_LOCALITY": "RATNAGIRI S. O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415202,
      "TXT_PINCODE_LOCALITY": "LATWAN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415203,
      "TXT_PINCODE_LOCALITY": "MANDANGAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415204,
      "TXT_PINCODE_LOCALITY": "MHARPRAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415205,
      "TXT_PINCODE_LOCALITY": "MALHARPETH KARAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415206,
      "TXT_PINCODE_LOCALITY": "PATAN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415207,
      "TXT_PINCODE_LOCALITY": "KOYNA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415208,
      "TXT_PINCODE_LOCALITY": "BANKOT",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415209,
      "TXT_PINCODE_LOCALITY": "BAHULE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415210,
      "TXT_PINCODE_LOCALITY": "MORGIRI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415211,
      "TXT_PINCODE_LOCALITY": "DAULAT NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415212,
      "TXT_PINCODE_LOCALITY": "MARALHARELI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415213,
      "TXT_PINCODE_LOCALITY": "CHIMBHARE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415214,
      "TXT_PINCODE_LOCALITY": "PANDERI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415216,
      "TXT_PINCODE_LOCALITY": "AMBARDE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442872,
      "City District Name": "SHAHUWADI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415286,
      "TXT_PINCODE_LOCALITY": "BHANDARA B.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 535,
      "City District Name": "BHANDARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415301,
      "TXT_PINCODE_LOCALITY": "ATPADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415302,
      "TXT_PINCODE_LOCALITY": "BHAVANI NAGAR (SANGLI)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415303,
      "TXT_PINCODE_LOCALITY": "DEVRASHTRA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415304,
      "TXT_PINCODE_LOCALITY": "KADEGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415305,
      "TXT_PINCODE_LOCALITY": "KADEPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415306,
      "TXT_PINCODE_LOCALITY": "TADAWALE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415307,
      "TXT_PINCODE_LOCALITY": "KHANAPUR (SANGLI)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415308,
      "TXT_PINCODE_LOCALITY": "KHARSUNDI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415309,
      "TXT_PINCODE_LOCALITY": "LANGANE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415310,
      "TXT_PINCODE_LOCALITY": "MAHULI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415311,
      "TXT_PINCODE_LOCALITY": "VITE CITY",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415312,
      "TXT_PINCODE_LOCALITY": "KALEDHON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415313,
      "TXT_PINCODE_LOCALITY": "TAKARI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415314,
      "TXT_PINCODE_LOCALITY": "ALSUND",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415315,
      "TXT_PINCODE_LOCALITY": "DIGHANCHI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415401,
      "TXT_PINCODE_LOCALITY": "AITWADE BUDRUK",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415402,
      "TXT_PINCODE_LOCALITY": "BILASHI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415403,
      "TXT_PINCODE_LOCALITY": "KAMERI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415404,
      "TXT_PINCODE_LOCALITY": "KASEGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415405,
      "TXT_PINCODE_LOCALITY": "KOKRUD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 468,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415406,
      "TXT_PINCODE_LOCALITY": "NERLA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415407,
      "TXT_PINCODE_LOCALITY": "PETH (SANGLI)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415408,
      "TXT_PINCODE_LOCALITY": "SHIRALA (SANGLI)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415409,
      "TXT_PINCODE_LOCALITY": "URAN TILAK PETH",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415410,
      "TXT_PINCODE_LOCALITY": "VATEGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415411,
      "TXT_PINCODE_LOCALITY": "YELUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415412,
      "TXT_PINCODE_LOCALITY": "CHIKURDE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415413,
      "TXT_PINCODE_LOCALITY": "BARGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415414,
      "TXT_PINCODE_LOCALITY": "SAKHARALE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415415,
      "TXT_PINCODE_LOCALITY": "WARNA VATI VASAHAT",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415416,
      "TXT_PINCODE_LOCALITY": "KANDE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415417,
      "TXT_PINCODE_LOCALITY": "KURTAP",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415418,
      "TXT_PINCODE_LOCALITY": "MANGLE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415501,
      "TXT_PINCODE_LOCALITY": "KOREGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415502,
      "TXT_PINCODE_LOCALITY": "PUSEGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415503,
      "TXT_PINCODE_LOCALITY": "BUDH",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415504,
      "TXT_PINCODE_LOCALITY": "DIKSAL MOL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415505,
      "TXT_PINCODE_LOCALITY": "KHATAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415506,
      "TXT_PINCODE_LOCALITY": "VADUJ",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415507,
      "TXT_PINCODE_LOCALITY": "KATAR KHATAV",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415508,
      "TXT_PINCODE_LOCALITY": "DAHIVADI S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415509,
      "TXT_PINCODE_LOCALITY": "MHASVAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415510,
      "TXT_PINCODE_LOCALITY": "SARAWATI COLONY",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415511,
      "TXT_PINCODE_LOCALITY": "RAHIMATPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415512,
      "TXT_PINCODE_LOCALITY": "PUSESAVALI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415513,
      "TXT_PINCODE_LOCALITY": "PACHVAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415514,
      "TXT_PINCODE_LOCALITY": "KUDAL (SATARA)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415515,
      "TXT_PINCODE_LOCALITY": "BHUINJ",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415516,
      "TXT_PINCODE_LOCALITY": "KAVATHE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415517,
      "TXT_PINCODE_LOCALITY": "SURUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415518,
      "TXT_PINCODE_LOCALITY": "APSHINGE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415519,
      "TXT_PINCODE_LOCALITY": "AATIT",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415520,
      "TXT_PINCODE_LOCALITY": "CHAPHAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415521,
      "TXT_PINCODE_LOCALITY": "LONAND",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415522,
      "TXT_PINCODE_LOCALITY": "SAKHARWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415523,
      "TXT_PINCODE_LOCALITY": "PHALTAN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415524,
      "TXT_PINCODE_LOCALITY": "VATHAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415525,
      "TXT_PINCODE_LOCALITY": "PIMPODE (BK)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415526,
      "TXT_PINCODE_LOCALITY": "VATHAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415527,
      "TXT_PINCODE_LOCALITY": "KURULISIDHESHWAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415528,
      "TXT_PINCODE_LOCALITY": "TARADGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415529,
      "TXT_PINCODE_LOCALITY": "OZARDA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415530,
      "TXT_PINCODE_LOCALITY": "KISAN VEER NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415531,
      "TXT_PINCODE_LOCALITY": "GUNAVARE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415532,
      "TXT_PINCODE_LOCALITY": "NIMBLAK",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415533,
      "TXT_PINCODE_LOCALITY": "GONDAVALE BK",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415535,
      "TXT_PINCODE_LOCALITY": "CHANDAK",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415536,
      "TXT_PINCODE_LOCALITY": "KENJAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415537,
      "TXT_PINCODE_LOCALITY": "ADARKI BK S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415538,
      "TXT_PINCODE_LOCALITY": "CHORADE B.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415539,
      "TXT_PINCODE_LOCALITY": "MALAKAPUR KARAD S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415540,
      "TXT_PINCODE_LOCALITY": "GONDAWALE BK S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415554,
      "TXT_PINCODE_LOCALITY": "BHOGAVALI T.KUDAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442841,
      "City District Name": "JAOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415556,
      "TXT_PINCODE_LOCALITY": "GULUMB",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1274,
      "City District Name": "WAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415570,
      "TXT_PINCODE_LOCALITY": "VIDYANAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415572,
      "TXT_PINCODE_LOCALITY": "KAWANKARWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 3315,
      "City District Name": "GUHAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415574,
      "TXT_PINCODE_LOCALITY": "GHOTEGHAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442841,
      "City District Name": "JAOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415601,
      "TXT_PINCODE_LOCALITY": "POPHALI (RATNAGIRI)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415602,
      "TXT_PINCODE_LOCALITY": "SHIRGAON (CHIPLUN)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415603,
      "TXT_PINCODE_LOCALITY": "ALORE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415604,
      "TXT_PINCODE_LOCALITY": "KHERDI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415605,
      "TXT_PINCODE_LOCALITY": "KHERDI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415606,
      "TXT_PINCODE_LOCALITY": "SAWARDA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415607,
      "TXT_PINCODE_LOCALITY": "NANDGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415608,
      "TXT_PINCODE_LOCALITY": "MAKHJAN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415609,
      "TXT_PINCODE_LOCALITY": "KADWAI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415610,
      "TXT_PINCODE_LOCALITY": "KASBA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415611,
      "TXT_PINCODE_LOCALITY": "SANGAMESHWAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415612,
      "TXT_PINCODE_LOCALITY": "RATNAGIRI H O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415613,
      "TXT_PINCODE_LOCALITY": "SAITVADA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415614,
      "TXT_PINCODE_LOCALITY": "JAIGAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415615,
      "TXT_PINCODE_LOCALITY": "MALGUND",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415616,
      "TXT_PINCODE_LOCALITY": "PAVAS",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415617,
      "TXT_PINCODE_LOCALITY": "KOTHAVADE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415618,
      "TXT_PINCODE_LOCALITY": "KALUSTA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415619,
      "TXT_PINCODE_LOCALITY": "HATHAKAMBA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415620,
      "TXT_PINCODE_LOCALITY": "KHALGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415621,
      "TXT_PINCODE_LOCALITY": "BHARNE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415622,
      "TXT_PINCODE_LOCALITY": "GANAPATIPULE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415623,
      "TXT_PINCODE_LOCALITY": "WARAVADA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415624,
      "TXT_PINCODE_LOCALITY": "NIVENDI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415625,
      "TXT_PINCODE_LOCALITY": "GOTAP",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415626,
      "TXT_PINCODE_LOCALITY": "GAVKHADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415627,
      "TXT_PINCODE_LOCALITY": "NEVERE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415628,
      "TXT_PINCODE_LOCALITY": "SHIRAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 527,
      "City District Name": "SATARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415629,
      "TXT_PINCODE_LOCALITY": "SHIRGAON (RTG)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415630,
      "TXT_PINCODE_LOCALITY": "KARLE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415631,
      "TXT_PINCODE_LOCALITY": "NACHNE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415633,
      "TXT_PINCODE_LOCALITY": "PADHAMBE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415634,
      "TXT_PINCODE_LOCALITY": "WATAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415635,
      "TXT_PINCODE_LOCALITY": "KALAMBSTE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415636,
      "TXT_PINCODE_LOCALITY": "DINGANI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415637,
      "TXT_PINCODE_LOCALITY": "AMBEDEBADRAK",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415639,
      "TXT_PINCODE_LOCALITY": "MIDC RATNAGIRI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415640,
      "TXT_PINCODE_LOCALITY": "UDHALE BUDRUK",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415641,
      "TXT_PINCODE_LOCALITY": "NIWALI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415643,
      "TXT_PINCODE_LOCALITY": "WAKED",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415701,
      "TXT_PINCODE_LOCALITY": "RAMPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415702,
      "TXT_PINCODE_LOCALITY": "MARG TAMBHANE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415703,
      "TXT_PINCODE_LOCALITY": "GUHAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415704,
      "TXT_PINCODE_LOCALITY": "PALSHET",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415705,
      "TXT_PINCODE_LOCALITY": "ADUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415706,
      "TXT_PINCODE_LOCALITY": "DABHOL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415707,
      "TXT_PINCODE_LOCALITY": "RATNAGIRI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415708,
      "TXT_PINCODE_LOCALITY": "LAVEL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415709,
      "TXT_PINCODE_LOCALITY": "KHED (RATNAGIRI)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415710,
      "TXT_PINCODE_LOCALITY": "FURUS",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415711,
      "TXT_PINCODE_LOCALITY": "VAKAVLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415712,
      "TXT_PINCODE_LOCALITY": "DAPOLI - 1151",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415713,
      "TXT_PINCODE_LOCALITY": "HARNAI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415714,
      "TXT_PINCODE_LOCALITY": "ANJARLA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415715,
      "TXT_PINCODE_LOCALITY": "KHOPI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415716,
      "TXT_PINCODE_LOCALITY": "PALGAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415717,
      "TXT_PINCODE_LOCALITY": "KELSHI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415718,
      "TXT_PINCODE_LOCALITY": "DHAMANAND",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415719,
      "TXT_PINCODE_LOCALITY": "TALAVALI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415720,
      "TXT_PINCODE_LOCALITY": "BURONDI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415721,
      "TXT_PINCODE_LOCALITY": "MURUD (H)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415722,
      "TXT_PINCODE_LOCALITY": "KHED",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415723,
      "TXT_PINCODE_LOCALITY": "KADAVALI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415724,
      "TXT_PINCODE_LOCALITY": "SHRINAGAR TALI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415725,
      "TXT_PINCODE_LOCALITY": "ONANEVASE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415726,
      "TXT_PINCODE_LOCALITY": "ABLDI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415727,
      "TXT_PINCODE_LOCALITY": "KARJI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415728,
      "TXT_PINCODE_LOCALITY": "HEDVI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415729,
      "TXT_PINCODE_LOCALITY": "VELNESHWARD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415730,
      "TXT_PINCODE_LOCALITY": "MOHANE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415801,
      "TXT_PINCODE_LOCALITY": "SAKHARPA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415802,
      "TXT_PINCODE_LOCALITY": "SHIPOSHI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415803,
      "TXT_PINCODE_LOCALITY": "PALI (RATNAGIRI)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415804,
      "TXT_PINCODE_LOCALITY": "DEVRUKH",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415805,
      "TXT_PINCODE_LOCALITY": "JAITHAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415806,
      "TXT_PINCODE_LOCALITY": "NATA (RATNAGIRI)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415807,
      "TXT_PINCODE_LOCALITY": "DEOLE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 415939,
      "TXT_PINCODE_LOCALITY": "RANPAT",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416000,
      "TXT_PINCODE_LOCALITY": "KOLHAPUR RS",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416001,
      "TXT_PINCODE_LOCALITY": "KOLHAPUR SHIVAJI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416002,
      "TXT_PINCODE_LOCALITY": "KOLHAPUR SHUKRA PETH",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416003,
      "TXT_PINCODE_LOCALITY": "KOLHAPUR NEW PALACE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416004,
      "TXT_PINCODE_LOCALITY": "SHIVAJI UNIVERSITY",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416005,
      "TXT_PINCODE_LOCALITY": "HERLA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416006,
      "TXT_PINCODE_LOCALITY": "KASVA BAVDA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416007,
      "TXT_PINCODE_LOCALITY": "KALAMBA (KOLHAPUR)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416008,
      "TXT_PINCODE_LOCALITY": "RAJARAMPURI (KOLHAPUR)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416009,
      "TXT_PINCODE_LOCALITY": "PACHGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442844,
      "City District Name": "KARVIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416010,
      "TXT_PINCODE_LOCALITY": "PHALENWADI (KOLHAPUR)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416011,
      "TXT_PINCODE_LOCALITY": "KOLHAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416012,
      "TXT_PINCODE_LOCALITY": "KOLHAPUR SHIVAJI PETH",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416013,
      "TXT_PINCODE_LOCALITY": "R K NAGAR S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416101,
      "TXT_PINCODE_LOCALITY": "JAYSINGPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416102,
      "TXT_PINCODE_LOCALITY": "NANDANI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416103,
      "TXT_PINCODE_LOCALITY": "SHIROL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416104,
      "TXT_PINCODE_LOCALITY": "NARSOBAVADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416105,
      "TXT_PINCODE_LOCALITY": "GANESHWADI (KOLHAPUR)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416106,
      "TXT_PINCODE_LOCALITY": "KURUNDVAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416107,
      "TXT_PINCODE_LOCALITY": "DATTAWAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416108,
      "TXT_PINCODE_LOCALITY": "TAKLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416109,
      "TXT_PINCODE_LOCALITY": "HATKANANGALA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416110,
      "TXT_PINCODE_LOCALITY": "BAHUBALI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416111,
      "TXT_PINCODE_LOCALITY": "KUMBHAJ",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416112,
      "TXT_PINCODE_LOCALITY": "VADGAON (KOLHAPUR)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416113,
      "TXT_PINCODE_LOCALITY": "PARGAON (KOLHAPUR)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416114,
      "TXT_PINCODE_LOCALITY": "MALE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416115,
      "TXT_PINCODE_LOCALITY": "ICHALKARANJI RUI NAKA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416116,
      "TXT_PINCODE_LOCALITY": "KABNUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416117,
      "TXT_PINCODE_LOCALITY": "JAWAHAR NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416118,
      "TXT_PINCODE_LOCALITY": "MALE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416119,
      "TXT_PINCODE_LOCALITY": "RUKADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416120,
      "TXT_PINCODE_LOCALITY": "DATTA NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416121,
      "TXT_PINCODE_LOCALITY": "RATNPAKUMBHAR NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416122,
      "TXT_PINCODE_LOCALITY": "M I D C KOLHAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416123,
      "TXT_PINCODE_LOCALITY": "ALATE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416124,
      "TXT_PINCODE_LOCALITY": "BHADOLE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416125,
      "TXT_PINCODE_LOCALITY": "CHANDUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416129,
      "TXT_PINCODE_LOCALITY": "KOLHAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416130,
      "TXT_PINCODE_LOCALITY": "LAT",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416134,
      "TXT_PINCODE_LOCALITY": "KOLHAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416136,
      "TXT_PINCODE_LOCALITY": "MANGAON (RUKDI)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416137,
      "TXT_PINCODE_LOCALITY": "PARGAON (KOLHAPUR)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416138,
      "TXT_PINCODE_LOCALITY": "TILWANI S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416139,
      "TXT_PINCODE_LOCALITY": "SATVE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416143,
      "TXT_PINCODE_LOCALITY": "SHIRADWAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416144,
      "TXT_PINCODE_LOCALITY": "JAYASINGPUR IND ESTATE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416145,
      "TXT_PINCODE_LOCALITY": "YADRAV",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 425817,
      "City District Name": "SHIROL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416146,
      "TXT_PINCODE_LOCALITY": "PARVATI INDUSTRIAL ESTATE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416201,
      "TXT_PINCODE_LOCALITY": "PANHALA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416202,
      "TXT_PINCODE_LOCALITY": "PATAN KODOLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416203,
      "TXT_PINCODE_LOCALITY": "HUTATMA CHOWK",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416204,
      "TXT_PINCODE_LOCALITY": "KUDITRE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416205,
      "TXT_PINCODE_LOCALITY": "KALE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416206,
      "TXT_PINCODE_LOCALITY": "GANGA BAWADA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416207,
      "TXT_PINCODE_LOCALITY": "ISPURLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416208,
      "TXT_PINCODE_LOCALITY": "BIDRI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416209,
      "TXT_PINCODE_LOCALITY": "GARGOTI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416210,
      "TXT_PINCODE_LOCALITY": "VESARDE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416211,
      "TXT_PINCODE_LOCALITY": "RASHIVADE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416212,
      "TXT_PINCODE_LOCALITY": "RADHANAGARI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416213,
      "TXT_PINCODE_LOCALITY": "PANHALA.",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416214,
      "TXT_PINCODE_LOCALITY": "SARUD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416215,
      "TXT_PINCODE_LOCALITY": "KASARI DAM S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416216,
      "TXT_PINCODE_LOCALITY": "KOLHAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416218,
      "TXT_PINCODE_LOCALITY": "KASARI DAM",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416219,
      "TXT_PINCODE_LOCALITY": "MURGUD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416220,
      "TXT_PINCODE_LOCALITY": "UTTUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416221,
      "TXT_PINCODE_LOCALITY": "KOLHAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416223,
      "TXT_PINCODE_LOCALITY": "KOLHAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416224,
      "TXT_PINCODE_LOCALITY": "RASHIVADE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416227,
      "TXT_PINCODE_LOCALITY": "AMBEWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416229,
      "TXT_PINCODE_LOCALITY": "VADANAGE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416230,
      "TXT_PINCODE_LOCALITY": "WAGHAVE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416231,
      "TXT_PINCODE_LOCALITY": "DUDHGANGA NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416232,
      "TXT_PINCODE_LOCALITY": "SIDHNERLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416234,
      "TXT_PINCODE_LOCALITY": "MIDC GOKUL SHIRGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416235,
      "TXT_PINCODE_LOCALITY": "CHIKHALI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416236,
      "TXT_PINCODE_LOCALITY": "5 STAR MIDC KAGAL S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416278,
      "TXT_PINCODE_LOCALITY": "ARJUNWADA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 3655,
      "City District Name": "KAGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416293,
      "TXT_PINCODE_LOCALITY": "WAREWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442872,
      "City District Name": "SHAHUWADI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416301,
      "TXT_PINCODE_LOCALITY": "ASHTE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416302,
      "TXT_PINCODE_LOCALITY": "BAGNI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416303,
      "TXT_PINCODE_LOCALITY": "BHILWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416304,
      "TXT_PINCODE_LOCALITY": "BUDHGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416305,
      "TXT_PINCODE_LOCALITY": "DIGRAJ KASBA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416306,
      "TXT_PINCODE_LOCALITY": "KAVALAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416307,
      "TXT_PINCODE_LOCALITY": "KAVATHE EKHAND",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416308,
      "TXT_PINCODE_LOCALITY": "KIRLOSKARWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416309,
      "TXT_PINCODE_LOCALITY": "KUNDAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416310,
      "TXT_PINCODE_LOCALITY": "PALUS",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416311,
      "TXT_PINCODE_LOCALITY": "SAVLAJ",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416312,
      "TXT_PINCODE_LOCALITY": "TASGOAN VARCHEGALLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416313,
      "TXT_PINCODE_LOCALITY": "WALWA S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416314,
      "TXT_PINCODE_LOCALITY": "VISAPUR (SANGLI)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416315,
      "TXT_PINCODE_LOCALITY": "DUDHGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416316,
      "TXT_PINCODE_LOCALITY": "ANKALKHOP",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416317,
      "TXT_PINCODE_LOCALITY": "MANJARDA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416318,
      "TXT_PINCODE_LOCALITY": "BURLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416319,
      "TXT_PINCODE_LOCALITY": "YELAVA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416320,
      "TXT_PINCODE_LOCALITY": "ADUMBA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416321,
      "TXT_PINCODE_LOCALITY": "PED",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416322,
      "TXT_PINCODE_LOCALITY": "TUNG",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416323,
      "TXT_PINCODE_LOCALITY": "KHARKATWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416392,
      "TXT_PINCODE_LOCALITY": "YAMGARWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 5855,
      "City District Name": "TASGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416401,
      "TXT_PINCODE_LOCALITY": "ARAG",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416402,
      "TXT_PINCODE_LOCALITY": "DADHALPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416403,
      "TXT_PINCODE_LOCALITY": "NAGAJ",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 574,
      "City District Name": "SOLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416404,
      "TXT_PINCODE_LOCALITY": "JAT",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416405,
      "TXT_PINCODE_LOCALITY": "KAVATHE MAHANKAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416406,
      "TXT_PINCODE_LOCALITY": "MADHVNAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416407,
      "TXT_PINCODE_LOCALITY": "MALGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416408,
      "TXT_PINCODE_LOCALITY": "MANERAJURI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416409,
      "TXT_PINCODE_LOCALITY": "MHAISAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416410,
      "TXT_PINCODE_LOCALITY": "MIRAJ BRAMHANPURI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416411,
      "TXT_PINCODE_LOCALITY": "RANJANI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416412,
      "TXT_PINCODE_LOCALITY": "SANKH",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416413,
      "TXT_PINCODE_LOCALITY": "SONYAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416414,
      "TXT_PINCODE_LOCALITY": "WANLESSWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416415,
      "TXT_PINCODE_LOCALITY": "WILLINGTON COLLEGE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416416,
      "TXT_PINCODE_LOCALITY": "SANGLI GANPATI PETH",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416417,
      "TXT_PINCODE_LOCALITY": "KAVATHE PIRAN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416418,
      "TXT_PINCODE_LOCALITY": "SALAGARE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416419,
      "TXT_PINCODE_LOCALITY": "SHIRDON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416420,
      "TXT_PINCODE_LOCALITY": "SONI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416421,
      "TXT_PINCODE_LOCALITY": "BEDAS",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416422,
      "TXT_PINCODE_LOCALITY": "BHOSE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416423,
      "TXT_PINCODE_LOCALITY": "NANDRE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416425,
      "TXT_PINCODE_LOCALITY": "KUPWADA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416426,
      "TXT_PINCODE_LOCALITY": "KHANDE-RAJURI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416427,
      "TXT_PINCODE_LOCALITY": "SAMDOLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416428,
      "TXT_PINCODE_LOCALITY": "BELANKI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416429,
      "TXT_PINCODE_LOCALITY": "BILLUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416434,
      "TXT_PINCODE_LOCALITY": "DESHING",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416436,
      "TXT_PINCODE_LOCALITY": "MIDC KUPWAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416437,
      "TXT_PINCODE_LOCALITY": "YESHVANT NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1417,
      "City District Name": "MIRAJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416444,
      "TXT_PINCODE_LOCALITY": "JATH MIDC",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442842,
      "City District Name": "JAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416492,
      "TXT_PINCODE_LOCALITY": "PARADHI WASTI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442842,
      "City District Name": "JAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416501,
      "TXT_PINCODE_LOCALITY": "DUNDGE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416502,
      "TXT_PINCODE_LOCALITY": "GANDHINGLAJ",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416503,
      "TXT_PINCODE_LOCALITY": "MAHAGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416504,
      "TXT_PINCODE_LOCALITY": "NESARI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416505,
      "TXT_PINCODE_LOCALITY": "AJRA (KOP)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416506,
      "TXT_PINCODE_LOCALITY": "HALKARNI (KOP)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416507,
      "TXT_PINCODE_LOCALITY": "KARVE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416508,
      "TXT_PINCODE_LOCALITY": "KOWAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416509,
      "TXT_PINCODE_LOCALITY": "CHANDGAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416510,
      "TXT_PINCODE_LOCALITY": "SAWANTWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1702,
      "City District Name": "SINDHUDURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416511,
      "TXT_PINCODE_LOCALITY": "SAWANTWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1702,
      "City District Name": "SINDHUDURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416512,
      "TXT_PINCODE_LOCALITY": "DADA MARG",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1702,
      "City District Name": "SINDHUDURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416513,
      "TXT_PINCODE_LOCALITY": "ARONDA S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1702,
      "City District Name": "SINDHUDURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416514,
      "TXT_PINCODE_LOCALITY": "MADHUKAR SAHAKARI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1702,
      "City District Name": "SINDHUDURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416515,
      "TXT_PINCODE_LOCALITY": "TULAS",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416516,
      "TXT_PINCODE_LOCALITY": "VENGURLA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1702,
      "City District Name": "SINDHUDURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416517,
      "TXT_PINCODE_LOCALITY": "REDI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 440420,
      "City District Name": "SINDHUDURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416518,
      "TXT_PINCODE_LOCALITY": "SINDHUDURG",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1702,
      "City District Name": "SINDHUDURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416519,
      "TXT_PINCODE_LOCALITY": "MANGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1702,
      "City District Name": "SINDHUDURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416520,
      "TXT_PINCODE_LOCALITY": "KUDAAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1702,
      "City District Name": "SINDHUDURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416521,
      "TXT_PINCODE_LOCALITY": "KADASAHKARI SAKHER",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1702,
      "City District Name": "SINDHUDURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416522,
      "TXT_PINCODE_LOCALITY": "SALGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1702,
      "City District Name": "SINDHUDURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416523,
      "TXT_PINCODE_LOCALITY": "SHELAPI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 914,
      "City District Name": "VENGURLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416524,
      "TXT_PINCODE_LOCALITY": "VALAVAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416525,
      "TXT_PINCODE_LOCALITY": "DEULWADA NERUR S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1702,
      "City District Name": "SINDHUDURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416526,
      "TXT_PINCODE_LOCALITY": "KOULGE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416527,
      "TXT_PINCODE_LOCALITY": "TILARI NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416528,
      "TXT_PINCODE_LOCALITY": "KUDAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1702,
      "City District Name": "SINDHUDURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416529,
      "TXT_PINCODE_LOCALITY": "TULAS",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 914,
      "City District Name": "VENGURLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416530,
      "TXT_PINCODE_LOCALITY": "SINDHUDURG S. O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1702,
      "City District Name": "SINDHUDURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416531,
      "TXT_PINCODE_LOCALITY": "KALMI STREET",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416532,
      "TXT_PINCODE_LOCALITY": "DATE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 425581,
      "City District Name": "CHANDGAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416534,
      "TXT_PINCODE_LOCALITY": "SUKALWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1702,
      "City District Name": "SINDHUDURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416535,
      "TXT_PINCODE_LOCALITY": "BHADGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416536,
      "TXT_PINCODE_LOCALITY": "KADGAON (KOLHAPUR)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416537,
      "TXT_PINCODE_LOCALITY": "ARAVTI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1702,
      "City District Name": "SINDHUDURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416538,
      "TXT_PINCODE_LOCALITY": "AJGAON SAWANTWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1702,
      "City District Name": "SINDHUDURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416539,
      "TXT_PINCODE_LOCALITY": "RATNAGIRI S. O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416540,
      "TXT_PINCODE_LOCALITY": "PINGALI KH.",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442853,
      "City District Name": "MAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416541,
      "TXT_PINCODE_LOCALITY": "MAJGAON.",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1565,
      "City District Name": "SAWANTWADI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416544,
      "TXT_PINCODE_LOCALITY": "THANE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416549,
      "TXT_PINCODE_LOCALITY": "KONALKATTA COLONY",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1702,
      "City District Name": "SINDHUDURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416550,
      "TXT_PINCODE_LOCALITY": "BADA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1565,
      "City District Name": "SAWANTWADI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416551,
      "TXT_PINCODE_LOCALITY": "KHANDAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416552,
      "TXT_PINCODE_LOCALITY": "DATE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416595,
      "TXT_PINCODE_LOCALITY": "YAMEHATTI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 3160,
      "City District Name": "GADHINGLAJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416601,
      "TXT_PINCODE_LOCALITY": "BELNEKHURD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1702,
      "City District Name": "SINDHUDURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416602,
      "TXT_PINCODE_LOCALITY": "KANKAWALI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1702,
      "City District Name": "SINDHUDURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416603,
      "TXT_PINCODE_LOCALITY": "KASAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1702,
      "City District Name": "SINDHUDURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416604,
      "TXT_PINCODE_LOCALITY": "TIRAVADE B.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1702,
      "City District Name": "SINDHUDURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416605,
      "TXT_PINCODE_LOCALITY": "CHONKE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1702,
      "City District Name": "SINDHUDURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416606,
      "TXT_PINCODE_LOCALITY": "MALVAN CITY",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1702,
      "City District Name": "SINDHUDURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416607,
      "TXT_PINCODE_LOCALITY": "KANDALGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 572,
      "City District Name": "SINDHUDURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416608,
      "TXT_PINCODE_LOCALITY": "WADACHAPAT",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4373,
      "City District Name": "MALWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416609,
      "TXT_PINCODE_LOCALITY": "NAVANAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1480,
      "City District Name": "KANKAVLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416610,
      "TXT_PINCODE_LOCALITY": "KOLOSHI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1480,
      "City District Name": "KANKAVLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416611,
      "TXT_PINCODE_LOCALITY": "TALAVADE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 543,
      "City District Name": "DEVGAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416612,
      "TXT_PINCODE_LOCALITY": "KATVAN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 543,
      "City District Name": "DEVGAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416613,
      "TXT_PINCODE_LOCALITY": "DEVGAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1702,
      "City District Name": "SINDHUDURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416614,
      "TXT_PINCODE_LOCALITY": "ACHARA S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1702,
      "City District Name": "SINDHUDURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416615,
      "TXT_PINCODE_LOCALITY": "BAGAMALA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 543,
      "City District Name": "DEVGAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416616,
      "TXT_PINCODE_LOCALITY": "MATH BK.",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4373,
      "City District Name": "MALWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416618,
      "TXT_PINCODE_LOCALITY": "NANDGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 572,
      "City District Name": "SINDHUDURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416620,
      "TXT_PINCODE_LOCALITY": "TALAVADE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1480,
      "City District Name": "KANKAVLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416622,
      "TXT_PINCODE_LOCALITY": "PEIP",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1702,
      "City District Name": "SINDHUDURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416623,
      "TXT_PINCODE_LOCALITY": "SINDHUDURG",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1702,
      "City District Name": "SINDHUDURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416624,
      "TXT_PINCODE_LOCALITY": "ORAS",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 444210,
      "City District Name": "ORAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416626,
      "TXT_PINCODE_LOCALITY": "JUVA PANKHOL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4373,
      "City District Name": "MALWAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416628,
      "TXT_PINCODE_LOCALITY": "SINDHUDURG",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1702,
      "City District Name": "SINDHUDURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416630,
      "TXT_PINCODE_LOCALITY": "DAHIBAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 543,
      "City District Name": "DEVGAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416632,
      "TXT_PINCODE_LOCALITY": "SINDHUDURG S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1702,
      "City District Name": "SINDHUDURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416661,
      "TXT_PINCODE_LOCALITY": "UTTAR BAJAR PETH",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1480,
      "City District Name": "KANKAVLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416662,
      "TXT_PINCODE_LOCALITY": "YEVTESHWARGAON.",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1480,
      "City District Name": "KANKAVLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416701,
      "TXT_PINCODE_LOCALITY": "RATNAGIRI MIDC",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416702,
      "TXT_PINCODE_LOCALITY": "RAJAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416703,
      "TXT_PINCODE_LOCALITY": "NANIVADE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442264,
      "City District Name": "VAIBHAVVADI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416704,
      "TXT_PINCODE_LOCALITY": "PACHAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416705,
      "TXT_PINCODE_LOCALITY": "ONI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416707,
      "TXT_PINCODE_LOCALITY": "ADIVRI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416709,
      "TXT_PINCODE_LOCALITY": "SAGAVE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416710,
      "TXT_PINCODE_LOCALITY": "RATNAGIRI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416711,
      "TXT_PINCODE_LOCALITY": "TAMBVE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 568,
      "City District Name": "SANGLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416712,
      "TXT_PINCODE_LOCALITY": "DEODHE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416713,
      "TXT_PINCODE_LOCALITY": "BHOO",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416718,
      "TXT_PINCODE_LOCALITY": "TERWAN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1696,
      "City District Name": "RAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416726,
      "TXT_PINCODE_LOCALITY": "BHATGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 3315,
      "City District Name": "GUHAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416750,
      "TXT_PINCODE_LOCALITY": "NIVASAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1149,
      "City District Name": "LANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416760,
      "TXT_PINCODE_LOCALITY": "ANJANARI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1149,
      "City District Name": "LANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416801,
      "TXT_PINCODE_LOCALITY": "TALERA S.O (SINDHUDURG)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1702,
      "City District Name": "SINDHUDURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416803,
      "TXT_PINCODE_LOCALITY": "MUTAT S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1702,
      "City District Name": "SINDHUDURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416804,
      "TXT_PINCODE_LOCALITY": "THAKURWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 543,
      "City District Name": "DEVGAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416805,
      "TXT_PINCODE_LOCALITY": "VADA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1702,
      "City District Name": "SINDHUDURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416806,
      "TXT_PINCODE_LOCALITY": "VIJAY DURG",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416807,
      "TXT_PINCODE_LOCALITY": "SINDHUDURG",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1702,
      "City District Name": "SINDHUDURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416810,
      "TXT_PINCODE_LOCALITY": "KOKISRA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416811,
      "TXT_PINCODE_LOCALITY": "PHANASGAON S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1702,
      "City District Name": "SINDHUDURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416812,
      "TXT_PINCODE_LOCALITY": "OROS",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1702,
      "City District Name": "SINDHUDURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416813,
      "TXT_PINCODE_LOCALITY": "RINGEWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442264,
      "City District Name": "VAIBHAVVADI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416850,
      "TXT_PINCODE_LOCALITY": "YERAVANDE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1149,
      "City District Name": "LANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416880,
      "TXT_PINCODE_LOCALITY": "VIRGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1149,
      "City District Name": "LANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416892,
      "TXT_PINCODE_LOCALITY": "TIKONDI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442842,
      "City District Name": "JAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416902,
      "TXT_PINCODE_LOCALITY": "SOLGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1696,
      "City District Name": "RAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416904,
      "TXT_PINCODE_LOCALITY": "RATNAGIRI S. O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 416905,
      "TXT_PINCODE_LOCALITY": "MARALE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442872,
      "City District Name": "SHAHUWADI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 417515,
      "TXT_PINCODE_LOCALITY": "PARALE.",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442872,
      "City District Name": "SHAHUWADI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 417517,
      "TXT_PINCODE_LOCALITY": "SHIVAJINAGAR TANDA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442840,
      "City District Name": "JALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 418010,
      "TXT_PINCODE_LOCALITY": "VORAVA KALLU",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1702,
      "City District Name": "SINDHUDURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 418604,
      "TXT_PINCODE_LOCALITY": "WADAKER POI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 543,
      "City District Name": "DEVGAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 419202,
      "TXT_PINCODE_LOCALITY": "KANADI BK.",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442831,
      "City District Name": "ASHTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 419503,
      "TXT_PINCODE_LOCALITY": "KONOSHI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1419,
      "City District Name": "SHEVGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 419739,
      "TXT_PINCODE_LOCALITY": "AHMED NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 420003,
      "TXT_PINCODE_LOCALITY": "SAGAR VILLAGE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 420007,
      "TXT_PINCODE_LOCALITY": "KHIRMAN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 5804,
      "City District Name": "SURGANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 420021,
      "TXT_PINCODE_LOCALITY": "SHIRGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 556,
      "City District Name": "MURBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 420201,
      "TXT_PINCODE_LOCALITY": "RAJURI KH.",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1320,
      "City District Name": "PACHORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 420403,
      "TXT_PINCODE_LOCALITY": "NAGARSUL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 6268,
      "City District Name": "YEVLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 420405,
      "TXT_PINCODE_LOCALITY": "SUKWAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442265,
      "City District Name": "SINDKHEDE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 420503,
      "TXT_PINCODE_LOCALITY": "BADLAPUR E.D.",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 420554,
      "TXT_PINCODE_LOCALITY": "CHANDPURI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 570,
      "City District Name": "SHIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421001,
      "TXT_PINCODE_LOCALITY": "ULHASNAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421002,
      "TXT_PINCODE_LOCALITY": "ULHAS NAGAR 2",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421003,
      "TXT_PINCODE_LOCALITY": "ULHAS NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421004,
      "TXT_PINCODE_LOCALITY": "ULHAS NAGAR 4",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421005,
      "TXT_PINCODE_LOCALITY": "ULHAS NAGAR 5",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421101,
      "TXT_PINCODE_LOCALITY": "PADAGHA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421102,
      "TXT_PINCODE_LOCALITY": "MOHANE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421103,
      "TXT_PINCODE_LOCALITY": "SHAHAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421201,
      "TXT_PINCODE_LOCALITY": "DOMBIVALI EAST",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421202,
      "TXT_PINCODE_LOCALITY": "VISHNUNAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421203,
      "TXT_PINCODE_LOCALITY": "MIDC 1",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421204,
      "TXT_PINCODE_LOCALITY": "MANPADA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421205,
      "TXT_PINCODE_LOCALITY": "RAMNAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421206,
      "TXT_PINCODE_LOCALITY": "THAKURLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421301,
      "TXT_PINCODE_LOCALITY": "THANE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421302,
      "TXT_PINCODE_LOCALITY": "SARAVALI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421303,
      "TXT_PINCODE_LOCALITY": "VADA (THANE)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421304,
      "TXT_PINCODE_LOCALITY": "KALYAN S O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421305,
      "TXT_PINCODE_LOCALITY": "VIDYASHRAM",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421306,
      "TXT_PINCODE_LOCALITY": "VASHI RAILWAY STATION COMPLEX",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421308,
      "TXT_PINCODE_LOCALITY": "BHIWANDI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421311,
      "TXT_PINCODE_LOCALITY": "SARAWALI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421312,
      "TXT_PINCODE_LOCALITY": "VADAVALI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421317,
      "TXT_PINCODE_LOCALITY": "VEHELE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1482,
      "City District Name": "BHIWANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421321,
      "TXT_PINCODE_LOCALITY": "DANDEKERWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421356,
      "TXT_PINCODE_LOCALITY": "SECTOR-12",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 683,
      "City District Name": "NAVSARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421401,
      "TXT_PINCODE_LOCALITY": "MURBAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421402,
      "TXT_PINCODE_LOCALITY": "DHASAI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421403,
      "TXT_PINCODE_LOCALITY": "KINHOLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421405,
      "TXT_PINCODE_LOCALITY": "SHELWALI (BANGAR)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 569,
      "City District Name": "SHAHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421406,
      "TXT_PINCODE_LOCALITY": "MURBAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421501,
      "TXT_PINCODE_LOCALITY": "AMBERNATH",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421502,
      "TXT_PINCODE_LOCALITY": "O E AMBARNATH",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421503,
      "TXT_PINCODE_LOCALITY": "KULGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421504,
      "TXT_PINCODE_LOCALITY": "BADLAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421505,
      "TXT_PINCODE_LOCALITY": "NETAJI BAZAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421506,
      "TXT_PINCODE_LOCALITY": "ADDITIONAL AMBARNATH",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421601,
      "TXT_PINCODE_LOCALITY": "SHAHAPUR (THANE)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421602,
      "TXT_PINCODE_LOCALITY": "KASARA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421603,
      "TXT_PINCODE_LOCALITY": "BHATSA NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421604,
      "TXT_PINCODE_LOCALITY": "WASHIND",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421605,
      "TXT_PINCODE_LOCALITY": "SHAHAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 421705,
      "TXT_PINCODE_LOCALITY": "BADLAPUR S. O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 2214,
      "City District Name": "BADLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422000,
      "TXT_PINCODE_LOCALITY": "MANDI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422001,
      "TXT_PINCODE_LOCALITY": "NASIK H O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422002,
      "TXT_PINCODE_LOCALITY": "NASIK S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 164,
      "City District Name": "NASIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422003,
      "TXT_PINCODE_LOCALITY": "NASIK PANCHAVATI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422004,
      "TXT_PINCODE_LOCALITY": "DINDORI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422005,
      "TXT_PINCODE_LOCALITY": "COLLEGE ROAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422006,
      "TXT_PINCODE_LOCALITY": "TAGORE NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422007,
      "TXT_PINCODE_LOCALITY": "SATPUR TOWNSHIP",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422008,
      "TXT_PINCODE_LOCALITY": "SATPUR TOWNSHIP",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422009,
      "TXT_PINCODE_LOCALITY": "NASIK CIDCO COLONY",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422010,
      "TXT_PINCODE_LOCALITY": "AMBAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422011,
      "TXT_PINCODE_LOCALITY": "WADALA ROAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422012,
      "TXT_PINCODE_LOCALITY": "ASHOK NAGAR S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422013,
      "TXT_PINCODE_LOCALITY": "PIMPALGAON BAHULA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422015,
      "TXT_PINCODE_LOCALITY": "DAGADPIMPRI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442838,
      "City District Name": "DINDORI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422035,
      "TXT_PINCODE_LOCALITY": "NASHIK OJHAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422043,
      "TXT_PINCODE_LOCALITY": "KALWAN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422100,
      "TXT_PINCODE_LOCALITY": "VAVANJE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 559,
      "City District Name": "PANVEL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422101,
      "TXT_PINCODE_LOCALITY": "NASIK ROAD I S P",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422102,
      "TXT_PINCODE_LOCALITY": "NASIK ROAD CAMP",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422103,
      "TXT_PINCODE_LOCALITY": "SINNAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422104,
      "TXT_PINCODE_LOCALITY": "WAVI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422105,
      "TXT_PINCODE_LOCALITY": "THERMAL POWER STATION",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422106,
      "TXT_PINCODE_LOCALITY": "JANORI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422107,
      "TXT_PINCODE_LOCALITY": "MOHADI (NASIK)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422108,
      "TXT_PINCODE_LOCALITY": "ADGAON (NASIK)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422109,
      "TXT_PINCODE_LOCALITY": "NAYGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422110,
      "TXT_PINCODE_LOCALITY": "NANSHI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422111,
      "TXT_PINCODE_LOCALITY": "PIMPRI SAYYAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422112,
      "TXT_PINCODE_LOCALITY": "SINNAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422113,
      "TXT_PINCODE_LOCALITY": "MALEGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422201,
      "TXT_PINCODE_LOCALITY": "CHANDORI*",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422202,
      "TXT_PINCODE_LOCALITY": "DINDORI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422203,
      "TXT_PINCODE_LOCALITY": "KOCHARGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422204,
      "TXT_PINCODE_LOCALITY": "HARSUL (NASIK)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422205,
      "TXT_PINCODE_LOCALITY": "KHEDGAON (NASIK)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422206,
      "TXT_PINCODE_LOCALITY": "OZAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422207,
      "TXT_PINCODE_LOCALITY": "AMBA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 164,
      "City District Name": "NASIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422208,
      "TXT_PINCODE_LOCALITY": "PEINT",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422209,
      "TXT_PINCODE_LOCALITY": "PIMPALGAON (NASIK)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422210,
      "TXT_PINCODE_LOCALITY": "SAYKHEDA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422211,
      "TXT_PINCODE_LOCALITY": "SURGANA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422212,
      "TXT_PINCODE_LOCALITY": "TRIMBAK",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422213,
      "TXT_PINCODE_LOCALITY": "TRIMBAK VIDYA MANDIR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422214,
      "TXT_PINCODE_LOCALITY": "VAITARNANAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422215,
      "TXT_PINCODE_LOCALITY": "VANI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422216,
      "TXT_PINCODE_LOCALITY": "MAHARASHTRA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422217,
      "TXT_PINCODE_LOCALITY": "CHOTORI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422219,
      "TXT_PINCODE_LOCALITY": "NALWANDI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422221,
      "TXT_PINCODE_LOCALITY": "OZAR A F CAMP",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422222,
      "TXT_PINCODE_LOCALITY": "YCM OPEN UNIVESITY S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422235,
      "TXT_PINCODE_LOCALITY": "SANGAMNER",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 567,
      "City District Name": "SANGAMNER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422240,
      "TXT_PINCODE_LOCALITY": "KUSEGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 549,
      "City District Name": "IGATPURI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422301,
      "TXT_PINCODE_LOCALITY": "BHAUSAHED NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422302,
      "TXT_PINCODE_LOCALITY": "SUKENA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422303,
      "TXT_PINCODE_LOCALITY": "NIPHAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422304,
      "TXT_PINCODE_LOCALITY": "UGAON S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422305,
      "TXT_PINCODE_LOCALITY": "DEOGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422306,
      "TXT_PINCODE_LOCALITY": "LASALGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422307,
      "TXT_PINCODE_LOCALITY": "DEOGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422308,
      "TXT_PINCODE_LOCALITY": "RANWAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422310,
      "TXT_PINCODE_LOCALITY": "HIWARGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 573,
      "City District Name": "SINNAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422401,
      "TXT_PINCODE_LOCALITY": "DEOLALI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422402,
      "TXT_PINCODE_LOCALITY": "GHOTI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422403,
      "TXT_PINCODE_LOCALITY": "IGATPURI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422405,
      "TXT_PINCODE_LOCALITY": "NANDGAON B K",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422501,
      "TXT_PINCODE_LOCALITY": "DEOLALI BAZAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422502,
      "TXT_PINCODE_LOCALITY": "BHAGUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422503,
      "TXT_PINCODE_LOCALITY": "NASHIK",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422560,
      "TXT_PINCODE_LOCALITY": "SHENIT",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 528,
      "City District Name": "AKOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422601,
      "TXT_PINCODE_LOCALITY": "AKOLA (AHMEDNAGAR)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422602,
      "TXT_PINCODE_LOCALITY": "BOTA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422603,
      "TXT_PINCODE_LOCALITY": "DHANDALPHAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422604,
      "TXT_PINCODE_LOCALITY": "RAJUR (ANR)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422605,
      "TXT_PINCODE_LOCALITY": "NANDUR SHINGOTA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 522,
      "City District Name": "AHMEDNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422606,
      "TXT_PINCODE_LOCALITY": "NANDUR SHINGOTE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422607,
      "TXT_PINCODE_LOCALITY": "TALEGAON DIGHE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422608,
      "TXT_PINCODE_LOCALITY": "SANGAMNER SAKAR KARKHANA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422609,
      "TXT_PINCODE_LOCALITY": "GANORE - 1574",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422610,
      "TXT_PINCODE_LOCALITY": "KOTUL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 522,
      "City District Name": "AHMEDNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422611,
      "TXT_PINCODE_LOCALITY": "NANAJ DUMALA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422612,
      "TXT_PINCODE_LOCALITY": "DEVTHAN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422613,
      "TXT_PINCODE_LOCALITY": "CHANDNPURI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422614,
      "TXT_PINCODE_LOCALITY": "SAMNAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 567,
      "City District Name": "SANGAMNER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422615,
      "TXT_PINCODE_LOCALITY": "NIMON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422616,
      "TXT_PINCODE_LOCALITY": "RAJAPUR (S)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422617,
      "TXT_PINCODE_LOCALITY": "JOWE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422618,
      "TXT_PINCODE_LOCALITY": "SASHERPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422619,
      "TXT_PINCODE_LOCALITY": "SAKUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422620,
      "TXT_PINCODE_LOCALITY": "GHARGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422622,
      "TXT_PINCODE_LOCALITY": "AHMEDNAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 522,
      "City District Name": "AHMEDNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422640,
      "TXT_PINCODE_LOCALITY": "JAWALE BALESHWAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 567,
      "City District Name": "SANGAMNER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422665,
      "TXT_PINCODE_LOCALITY": "HIWARGAON PAWASA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 567,
      "City District Name": "SANGAMNER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 422687,
      "TXT_PINCODE_LOCALITY": "MALEGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423101,
      "TXT_PINCODE_LOCALITY": "CHANDRAPUR ORDINANCE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423102,
      "TXT_PINCODE_LOCALITY": "RAMESHWAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423103,
      "TXT_PINCODE_LOCALITY": "LAXMIWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 522,
      "City District Name": "AHMEDNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423104,
      "TXT_PINCODE_LOCALITY": "MANMAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423105,
      "TXT_PINCODE_LOCALITY": "MALEGAON CAMP",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423106,
      "TXT_PINCODE_LOCALITY": "NANDGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423107,
      "TXT_PINCODE_LOCALITY": "RAHATA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 522,
      "City District Name": "AHMEDNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423108,
      "TXT_PINCODE_LOCALITY": "RAVALGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423109,
      "TXT_PINCODE_LOCALITY": "SHIRDI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423110,
      "TXT_PINCODE_LOCALITY": "UMRANE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423111,
      "TXT_PINCODE_LOCALITY": "VADNER (BHAIRAV)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423113,
      "TXT_PINCODE_LOCALITY": "ASTAGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423114,
      "TXT_PINCODE_LOCALITY": "SAKOZE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423116,
      "TXT_PINCODE_LOCALITY": "DAHIWAD(NASIK)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423117,
      "TXT_PINCODE_LOCALITY": "VADALIBHOI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423201,
      "TXT_PINCODE_LOCALITY": "AGHAR B K",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423202,
      "TXT_PINCODE_LOCALITY": "GIRNA S S K DABHADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423203,
      "TXT_PINCODE_LOCALITY": "MALEGAON MARKET YARD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423204,
      "TXT_PINCODE_LOCALITY": "NAMPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423205,
      "TXT_PINCODE_LOCALITY": "ZODGA S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423206,
      "TXT_PINCODE_LOCALITY": "VADEL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423208,
      "TXT_PINCODE_LOCALITY": "SAUDANE (DONG)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423209,
      "TXT_PINCODE_LOCALITY": "BRAMHANGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423210,
      "TXT_PINCODE_LOCALITY": "NASHIK",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423211,
      "TXT_PINCODE_LOCALITY": "NASHIK",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423212,
      "TXT_PINCODE_LOCALITY": "NIMBGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423213,
      "TXT_PINCODE_LOCALITY": "LAKHAMPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423301,
      "TXT_PINCODE_LOCALITY": "LOHONER",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423302,
      "TXT_PINCODE_LOCALITY": "TAHARABAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423303,
      "TXT_PINCODE_LOCALITY": "JAIKHEDA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423304,
      "TXT_PINCODE_LOCALITY": "NASHIK",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423305,
      "TXT_PINCODE_LOCALITY": "JALGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423335,
      "TXT_PINCODE_LOCALITY": "VASOL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1176,
      "City District Name": "DEOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423340,
      "TXT_PINCODE_LOCALITY": "BHULEGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 6268,
      "City District Name": "YEVLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423401,
      "TXT_PINCODE_LOCALITY": "YEOLA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423402,
      "TXT_PINCODE_LOCALITY": "ANDARSAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423403,
      "TXT_PINCODE_LOCALITY": "NAGARSUL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423404,
      "TXT_PINCODE_LOCALITY": "DHAMANGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 6268,
      "City District Name": "YEVLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423405,
      "TXT_PINCODE_LOCALITY": "BALAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 6268,
      "City District Name": "YEVLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423413,
      "TXT_PINCODE_LOCALITY": "NYAHARKHEDE KH.",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 6268,
      "City District Name": "YEVLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423501,
      "TXT_PINCODE_LOCALITY": "KALVAN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423502,
      "TXT_PINCODE_LOCALITY": "OZAR TOWNSHIP",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423505,
      "TXT_PINCODE_LOCALITY": "GUGULWAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 555,
      "City District Name": "MALEGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423601,
      "TXT_PINCODE_LOCALITY": "KOPARGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423602,
      "TXT_PINCODE_LOCALITY": "KOLPEWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423603,
      "TXT_PINCODE_LOCALITY": "SHINGNAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423604,
      "TXT_PINCODE_LOCALITY": "CHASNALI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 522,
      "City District Name": "AHMEDNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423605,
      "TXT_PINCODE_LOCALITY": "POHEGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423606,
      "TXT_PINCODE_LOCALITY": "SAMVATAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423607,
      "TXT_PINCODE_LOCALITY": "DHAMARI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1945,
      "City District Name": "AHMADNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423701,
      "TXT_PINCODE_LOCALITY": "VAIJAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423702,
      "TXT_PINCODE_LOCALITY": "LASUR STN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423703,
      "TXT_PINCODE_LOCALITY": "PARSODA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 423704,
      "TXT_PINCODE_LOCALITY": "KHANDALY",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424001,
      "TXT_PINCODE_LOCALITY": "DHULE STATION ROA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424002,
      "TXT_PINCODE_LOCALITY": "DHULE DEVPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424003,
      "TXT_PINCODE_LOCALITY": "DHULE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424004,
      "TXT_PINCODE_LOCALITY": "DHULE MARKET YARD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424005,
      "TXT_PINCODE_LOCALITY": "VIDYANAGARI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424006,
      "TXT_PINCODE_LOCALITY": "DHULE MIDC",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424027,
      "TXT_PINCODE_LOCALITY": "NIHAALOD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424071,
      "TXT_PINCODE_LOCALITY": "BORMALI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 541,
      "City District Name": "CHOPDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424074,
      "TXT_PINCODE_LOCALITY": "BOR AJANTI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 541,
      "City District Name": "CHOPDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424101,
      "TXT_PINCODE_LOCALITY": "CHALISGAON TOWN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424102,
      "TXT_PINCODE_LOCALITY": "PATONDA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424103,
      "TXT_PINCODE_LOCALITY": "KAIGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424104,
      "TXT_PINCODE_LOCALITY": "NAGOU DEDA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424105,
      "TXT_PINCODE_LOCALITY": "BHADGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424106,
      "TXT_PINCODE_LOCALITY": "MEHUNBARA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424107,
      "TXT_PINCODE_LOCALITY": "KHEDGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424108,
      "TXT_PINCODE_LOCALITY": "HIRAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424109,
      "TXT_PINCODE_LOCALITY": "NAYDONGRI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424111,
      "TXT_PINCODE_LOCALITY": "AMDADE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424116,
      "TXT_PINCODE_LOCALITY": "KARKHANA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424117,
      "TXT_PINCODE_LOCALITY": "JALGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424119,
      "TXT_PINCODE_LOCALITY": "SUVARNATAI NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 538,
      "City District Name": "CHALISGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424200,
      "TXT_PINCODE_LOCALITY": "BORADKHEDA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424201,
      "TXT_PINCODE_LOCALITY": "PACHORA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424202,
      "TXT_PINCODE_LOCALITY": "VARKHEDI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424203,
      "TXT_PINCODE_LOCALITY": "PIMPALGAON B K",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424204,
      "TXT_PINCODE_LOCALITY": "SHENDURDI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424205,
      "TXT_PINCODE_LOCALITY": "PAHUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424206,
      "TXT_PINCODE_LOCALITY": "JAMNER",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424207,
      "TXT_PINCODE_LOCALITY": "VAKADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424208,
      "TXT_PINCODE_LOCALITY": "FATTEPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424209,
      "TXT_PINCODE_LOCALITY": "LOHARE.",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424210,
      "TXT_PINCODE_LOCALITY": "SHAHAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424216,
      "TXT_PINCODE_LOCALITY": "WAKI KH.",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 550,
      "City District Name": "JAMNER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424301,
      "TXT_PINCODE_LOCALITY": "MUKTI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424302,
      "TXT_PINCODE_LOCALITY": "KUSUMBA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424303,
      "TXT_PINCODE_LOCALITY": "NER",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424304,
      "TXT_PINCODE_LOCALITY": "SAKRI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424305,
      "TXT_PINCODE_LOCALITY": "NIZAMPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424306,
      "TXT_PINCODE_LOCALITY": "PIMPALNER",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424307,
      "TXT_PINCODE_LOCALITY": "KAPADNA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424308,
      "TXT_PINCODE_LOCALITY": "SHIRUD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424309,
      "TXT_PINCODE_LOCALITY": "SONGIR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424310,
      "TXT_PINCODE_LOCALITY": "KASARA (DHULE)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424311,
      "TXT_PINCODE_LOCALITY": "MOHADI LALING",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424313,
      "TXT_PINCODE_LOCALITY": "BOZIS",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424315,
      "TXT_PINCODE_LOCALITY": "DHULE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424316,
      "TXT_PINCODE_LOCALITY": "MOHODI DANGRI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424318,
      "TXT_PINCODE_LOCALITY": "SHIRDANE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424425,
      "TXT_PINCODE_LOCALITY": "PURUSHOTTAMNAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 5600,
      "City District Name": "SHAHADE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424606,
      "TXT_PINCODE_LOCALITY": "VALWHE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1285,
      "City District Name": "SAKRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 424804,
      "TXT_PINCODE_LOCALITY": "TORANKUDI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1285,
      "City District Name": "SAKRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425001,
      "TXT_PINCODE_LOCALITY": "JALGAON H O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425002,
      "TXT_PINCODE_LOCALITY": "MEHARUN SHIVAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425003,
      "TXT_PINCODE_LOCALITY": "AUDOGIK VASAHAT JALGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425004,
      "TXT_PINCODE_LOCALITY": "TAKARKHEDE.",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1385,
      "City District Name": "ERANDOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425006,
      "TXT_PINCODE_LOCALITY": "WARPADE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442265,
      "City District Name": "SINDKHEDE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425017,
      "TXT_PINCODE_LOCALITY": "HATEDE BK.",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 541,
      "City District Name": "CHOPDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425041,
      "TXT_PINCODE_LOCALITY": "VADGAON PR. AMALNER",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1082,
      "City District Name": "PAROLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425071,
      "TXT_PINCODE_LOCALITY": "KURVEL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 541,
      "City District Name": "CHOPDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425101,
      "TXT_PINCODE_LOCALITY": "ASODA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425102,
      "TXT_PINCODE_LOCALITY": "BHADLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425103,
      "TXT_PINCODE_LOCALITY": "PALDHI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425104,
      "TXT_PINCODE_LOCALITY": "PIMPRI KHURD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425105,
      "TXT_PINCODE_LOCALITY": "SALVE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425106,
      "TXT_PINCODE_LOCALITY": "NANDED",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425107,
      "TXT_PINCODE_LOCALITY": "CHAHARDI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425108,
      "TXT_PINCODE_LOCALITY": "AKULKHEDA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425109,
      "TXT_PINCODE_LOCALITY": "UTRAN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425110,
      "TXT_PINCODE_LOCALITY": "ADGAON JALGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425111,
      "TXT_PINCODE_LOCALITY": "PAROLA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425112,
      "TXT_PINCODE_LOCALITY": "JALGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425113,
      "TXT_PINCODE_LOCALITY": "BAHADARPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425114,
      "TXT_PINCODE_LOCALITY": "NERI BUDRUK",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425115,
      "TXT_PINCODE_LOCALITY": "NANDRA PACHORA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425116,
      "TXT_PINCODE_LOCALITY": "MHASVAD (KH)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425119,
      "TXT_PINCODE_LOCALITY": "JALGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425120,
      "TXT_PINCODE_LOCALITY": "JALGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425121,
      "TXT_PINCODE_LOCALITY": "JALGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425122,
      "TXT_PINCODE_LOCALITY": "JALGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425124,
      "TXT_PINCODE_LOCALITY": "JALGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425125,
      "TXT_PINCODE_LOCALITY": "KADGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425126,
      "TXT_PINCODE_LOCALITY": "JALGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425127,
      "TXT_PINCODE_LOCALITY": "JALGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425130,
      "TXT_PINCODE_LOCALITY": "VIRAWADA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425131,
      "TXT_PINCODE_LOCALITY": "AVANE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425133,
      "TXT_PINCODE_LOCALITY": "GHADGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425152,
      "TXT_PINCODE_LOCALITY": "SAVKHEDE HOL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1082,
      "City District Name": "PAROLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425201,
      "TXT_PINCODE_LOCALITY": "TAPI NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 536,
      "City District Name": "BHUSAWAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425203,
      "TXT_PINCODE_LOCALITY": "BHUSAWAL ORDINANCE FACTORY",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425204,
      "TXT_PINCODE_LOCALITY": "JALGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425205,
      "TXT_PINCODE_LOCALITY": "PALADHI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 550,
      "City District Name": "JAMNER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425211,
      "TXT_PINCODE_LOCALITY": "JALGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425301,
      "TXT_PINCODE_LOCALITY": "ATRAVAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425302,
      "TXT_PINCODE_LOCALITY": "SAKLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425303,
      "TXT_PINCODE_LOCALITY": "ADVAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425304,
      "TXT_PINCODE_LOCALITY": "BHALOD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425305,
      "TXT_PINCODE_LOCALITY": "TALVEL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425306,
      "TXT_PINCODE_LOCALITY": "EDALABAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425307,
      "TXT_PINCODE_LOCALITY": "DEEP NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425308,
      "TXT_PINCODE_LOCALITY": "VARANGAON ORDNANCE FACT",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425309,
      "TXT_PINCODE_LOCALITY": "NASHIRABAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425310,
      "TXT_PINCODE_LOCALITY": "SALSINGI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425311,
      "TXT_PINCODE_LOCALITY": "VARADSIM",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425315,
      "TXT_PINCODE_LOCALITY": "JALGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425317,
      "TXT_PINCODE_LOCALITY": "KATHARA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 815,
      "City District Name": "NANDURBAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425318,
      "TXT_PINCODE_LOCALITY": "KIRGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425320,
      "TXT_PINCODE_LOCALITY": "JALGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425322,
      "TXT_PINCODE_LOCALITY": "JALGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425323,
      "TXT_PINCODE_LOCALITY": "DAHIGAON(JALGAON)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425325,
      "TXT_PINCODE_LOCALITY": "DHANORA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 815,
      "City District Name": "NANDURBAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425326,
      "TXT_PINCODE_LOCALITY": "SANGVI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425327,
      "TXT_PINCODE_LOCALITY": "MORZIRA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 425734,
      "City District Name": "MUKTAINAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425336,
      "TXT_PINCODE_LOCALITY": "PATONDA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 530,
      "City District Name": "AMALNER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425400,
      "TXT_PINCODE_LOCALITY": "WADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 5600,
      "City District Name": "SHAHADE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425401,
      "TXT_PINCODE_LOCALITY": "SHAHAPUR (JAMNER)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425402,
      "TXT_PINCODE_LOCALITY": "MARWAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4755,
      "City District Name": "NASHIK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425403,
      "TXT_PINCODE_LOCALITY": "DHULE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425404,
      "TXT_PINCODE_LOCALITY": "NANDANA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425405,
      "TXT_PINCODE_LOCALITY": "SHIRPUR (DHULE)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425406,
      "TXT_PINCODE_LOCALITY": "SINKHEDA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425407,
      "TXT_PINCODE_LOCALITY": "CHIMTHAN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425408,
      "TXT_PINCODE_LOCALITY": "METHI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425409,
      "TXT_PINCODE_LOCALITY": "SHAHDE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 815,
      "City District Name": "NANDURBAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425410,
      "TXT_PINCODE_LOCALITY": "SARANKHEDA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 815,
      "City District Name": "NANDURBAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425411,
      "TXT_PINCODE_LOCALITY": "RANALA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 815,
      "City District Name": "NANDURBAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425412,
      "TXT_PINCODE_LOCALITY": "KHONDAMALI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 815,
      "City District Name": "NANDURBAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425413,
      "TXT_PINCODE_LOCALITY": "TALODE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 815,
      "City District Name": "NANDURBAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425414,
      "TXT_PINCODE_LOCALITY": "DHAGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 815,
      "City District Name": "NANDURBAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425415,
      "TXT_PINCODE_LOCALITY": "AKKA LKUWA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425416,
      "TXT_PINCODE_LOCALITY": "SRAWANI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 815,
      "City District Name": "NANDURBAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425417,
      "TXT_PINCODE_LOCALITY": "CHINCHPADA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 815,
      "City District Name": "NANDURBAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425418,
      "TXT_PINCODE_LOCALITY": "NAVAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 815,
      "City District Name": "NANDURBAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425419,
      "TXT_PINCODE_LOCALITY": "KHAPAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 815,
      "City District Name": "NANDURBAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425420,
      "TXT_PINCODE_LOCALITY": "AMALGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425421,
      "TXT_PINCODE_LOCALITY": "THALNER",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425422,
      "TXT_PINCODE_LOCALITY": "PRAKASHA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 815,
      "City District Name": "NANDURBAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425423,
      "TXT_PINCODE_LOCALITY": "VADLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425424,
      "TXT_PINCODE_LOCALITY": "PURUSHOTTAM NAGAR SHAHADA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425426,
      "TXT_PINCODE_LOCALITY": "VISARWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 815,
      "City District Name": "NANDURBAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425427,
      "TXT_PINCODE_LOCALITY": "TEKVADE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425428,
      "TXT_PINCODE_LOCALITY": "ZENDE-ANJAN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425432,
      "TXT_PINCODE_LOCALITY": "MHASVAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 815,
      "City District Name": "NANDURBAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425433,
      "TXT_PINCODE_LOCALITY": "DAMARHHEDA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 815,
      "City District Name": "NANDURBAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425434,
      "TXT_PINCODE_LOCALITY": "JALGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425435,
      "TXT_PINCODE_LOCALITY": "JUHAVE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425436,
      "TXT_PINCODE_LOCALITY": "PATRODA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425437,
      "TXT_PINCODE_LOCALITY": "MANGRUL (JALGAON)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425438,
      "TXT_PINCODE_LOCALITY": "DAHIWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425439,
      "TXT_PINCODE_LOCALITY": "MANDAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425440,
      "TXT_PINCODE_LOCALITY": "KALM SAREE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425442,
      "TXT_PINCODE_LOCALITY": "BARAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425444,
      "TXT_PINCODE_LOCALITY": "MONDANE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425448,
      "TXT_PINCODE_LOCALITY": "BORADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425450,
      "TXT_PINCODE_LOCALITY": "DHANARA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425451,
      "TXT_PINCODE_LOCALITY": "BHADVAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 815,
      "City District Name": "NANDURBAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425452,
      "TXT_PINCODE_LOCALITY": "VADFALI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 815,
      "City District Name": "NANDURBAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425501,
      "TXT_PINCODE_LOCALITY": "THARGAVAN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425502,
      "TXT_PINCODE_LOCALITY": "WAGHODA B K",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425503,
      "TXT_PINCODE_LOCALITY": "PADALSA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425504,
      "TXT_PINCODE_LOCALITY": "SAVKHEDA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425505,
      "TXT_PINCODE_LOCALITY": "CHINAVAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425506,
      "TXT_PINCODE_LOCALITY": "NIMBHARA (JALGAON)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425507,
      "TXT_PINCODE_LOCALITY": "AINPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425508,
      "TXT_PINCODE_LOCALITY": "RASALPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425509,
      "TXT_PINCODE_LOCALITY": "JALGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425511,
      "TXT_PINCODE_LOCALITY": "NISAVI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425512,
      "TXT_PINCODE_LOCALITY": "JALGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425513,
      "TXT_PINCODE_LOCALITY": "UTAKHEDA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425515,
      "TXT_PINCODE_LOCALITY": "JALGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425519,
      "TXT_PINCODE_LOCALITY": "JALGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425521,
      "TXT_PINCODE_LOCALITY": "JALGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425522,
      "TXT_PINCODE_LOCALITY": "AMODE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 815,
      "City District Name": "NANDURBAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425523,
      "TXT_PINCODE_LOCALITY": "JALGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425524,
      "TXT_PINCODE_LOCALITY": "SAKHAR KARKHANA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425540,
      "TXT_PINCODE_LOCALITY": "DASWEL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442265,
      "City District Name": "SINDKHEDE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425541,
      "TXT_PINCODE_LOCALITY": "KADHEL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 5833,
      "City District Name": "TALODE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425711,
      "TXT_PINCODE_LOCALITY": "PIMPALKOTHA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1082,
      "City District Name": "PAROLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425914,
      "TXT_PINCODE_LOCALITY": "VARKHEDI KH",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442167,
      "City District Name": "AKRANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425965,
      "TXT_PINCODE_LOCALITY": "BORGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 570,
      "City District Name": "SHIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 426407,
      "TXT_PINCODE_LOCALITY": "KADANE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442265,
      "City District Name": "SINDKHEDE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 427302,
      "TXT_PINCODE_LOCALITY": "SAGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1482,
      "City District Name": "BHIWANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 428101,
      "TXT_PINCODE_LOCALITY": "KAZISANGVI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442837,
      "City District Name": "CHANDVAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 428601,
      "TXT_PINCODE_LOCALITY": "SAI NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4067,
      "City District Name": "KOPARGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 429302,
      "TXT_PINCODE_LOCALITY": "VEHALE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 745,
      "City District Name": "KALYAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 429318,
      "TXT_PINCODE_LOCALITY": "NAWRI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 430805,
      "TXT_PINCODE_LOCALITY": "VAZRA BK",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4001,
      "City District Name": "KINWAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431001,
      "TXT_PINCODE_LOCALITY": "SAMARTH NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431002,
      "TXT_PINCODE_LOCALITY": "AURANGABAD CANTT",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431003,
      "TXT_PINCODE_LOCALITY": "CIDCO COLONY",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431004,
      "TXT_PINCODE_LOCALITY": "MARATHWADA VIDYAPEETH",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431005,
      "TXT_PINCODE_LOCALITY": "KRANTI CHOWK",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431006,
      "TXT_PINCODE_LOCALITY": "CHIKALTHANA INDUSTRIAL AREA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431007,
      "TXT_PINCODE_LOCALITY": "KARMAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431008,
      "TXT_PINCODE_LOCALITY": "BILDA VILLAGE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431009,
      "TXT_PINCODE_LOCALITY": "GARKHEDA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431010,
      "TXT_PINCODE_LOCALITY": "SATARA PARISAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431011,
      "TXT_PINCODE_LOCALITY": "SHIVAJI NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1029,
      "City District Name": "KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431021,
      "TXT_PINCODE_LOCALITY": "KAUCHALWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 531,
      "City District Name": "AMBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431022,
      "TXT_PINCODE_LOCALITY": "MANJRAM",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442861,
      "City District Name": "NAIGAON (KHAIRGAON)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431101,
      "TXT_PINCODE_LOCALITY": "KHULDABAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431102,
      "TXT_PINCODE_LOCALITY": "VELUR (ELLORA)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431103,
      "TXT_PINCODE_LOCALITY": "KANNAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431104,
      "TXT_PINCODE_LOCALITY": "PISHORE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431105,
      "TXT_PINCODE_LOCALITY": "PAITHAN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431106,
      "TXT_PINCODE_LOCALITY": "NATHNAGAR NORTH",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431107,
      "TXT_PINCODE_LOCALITY": "PAITHAN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431108,
      "TXT_PINCODE_LOCALITY": "NATHNAGAR SOUTH",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431109,
      "TXT_PINCODE_LOCALITY": "GANGAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431110,
      "TXT_PINCODE_LOCALITY": "RAGHUNATHNAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431111,
      "TXT_PINCODE_LOCALITY": "PHULAMRAI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431112,
      "TXT_PINCODE_LOCALITY": "SILLOD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431113,
      "TXT_PINCODE_LOCALITY": "BHANADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431114,
      "TXT_PINCODE_LOCALITY": "BHOKARDAN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 526,
      "City District Name": "JALNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431115,
      "TXT_PINCODE_LOCALITY": "DEOGAON RANIAN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431116,
      "TXT_PINCODE_LOCALITY": "TALWADA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431117,
      "TXT_PINCODE_LOCALITY": "AJINTHA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431118,
      "TXT_PINCODE_LOCALITY": "PHARDAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431119,
      "TXT_PINCODE_LOCALITY": "HARSOOL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431120,
      "TXT_PINCODE_LOCALITY": "SILLOD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431121,
      "TXT_PINCODE_LOCALITY": "PACHOD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431122,
      "TXT_PINCODE_LOCALITY": "BHIR RAJURI VES",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 534,
      "City District Name": "BEED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431123,
      "TXT_PINCODE_LOCALITY": "KAIJ",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 534,
      "City District Name": "BEED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431124,
      "TXT_PINCODE_LOCALITY": "FATEHA BAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431125,
      "TXT_PINCODE_LOCALITY": "YELLAM GHAT",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 534,
      "City District Name": "BEED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431126,
      "TXT_PINCODE_LOCALITY": "CHANSALA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 534,
      "City District Name": "BEED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431127,
      "TXT_PINCODE_LOCALITY": "DHONDRAI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 534,
      "City District Name": "BEED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431128,
      "TXT_PINCODE_LOCALITY": "DINDROAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 534,
      "City District Name": "BEED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431129,
      "TXT_PINCODE_LOCALITY": "TALKHED",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 534,
      "City District Name": "BEED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431130,
      "TXT_PINCODE_LOCALITY": "CHAKLAMBA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 534,
      "City District Name": "BEED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431131,
      "TXT_PINCODE_LOCALITY": "MANJLALEGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 534,
      "City District Name": "BEED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431132,
      "TXT_PINCODE_LOCALITY": "SHIVNA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431133,
      "TXT_PINCODE_LOCALITY": "WALUJ",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431134,
      "TXT_PINCODE_LOCALITY": "BADOD BAZAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431135,
      "TXT_PINCODE_LOCALITY": "SILLOD SAKHAR KARKHANA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431136,
      "TXT_PINCODE_LOCALITY": "SIZSILLA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431137,
      "TXT_PINCODE_LOCALITY": "BIHMANWA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431138,
      "TXT_PINCODE_LOCALITY": "AJANTA CAVES",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431139,
      "TXT_PINCODE_LOCALITY": "CHIKHA THANA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431140,
      "TXT_PINCODE_LOCALITY": "KHANDALA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431142,
      "TXT_PINCODE_LOCALITY": "KESAPURI CAMP",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 534,
      "City District Name": "BEED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431143,
      "TXT_PINCODE_LOCALITY": "BEED",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 534,
      "City District Name": "BEED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431144,
      "TXT_PINCODE_LOCALITY": "BEED",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 534,
      "City District Name": "BEED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431145,
      "TXT_PINCODE_LOCALITY": "BEED",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 534,
      "City District Name": "BEED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431146,
      "TXT_PINCODE_LOCALITY": "NAGAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431147,
      "TXT_PINCODE_LOCALITY": "CHINCHOLI LIMBAJI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431148,
      "TXT_PINCODE_LOCALITY": "PAITHAN SUGAR FACTORY",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431149,
      "TXT_PINCODE_LOCALITY": "WANGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442874,
      "City District Name": "SOEGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431150,
      "TXT_PINCODE_LOCALITY": "BANOTI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431151,
      "TXT_PINCODE_LOCALITY": "ANDHARI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431152,
      "TXT_PINCODE_LOCALITY": "AURANGABAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431153,
      "TXT_PINCODE_LOCALITY": "MUGGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 425570,
      "City District Name": "BID",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431154,
      "TXT_PINCODE_LOCALITY": "SHENDRABAN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431157,
      "TXT_PINCODE_LOCALITY": "NAIGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 424289,
      "City District Name": "PHULAMBRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431171,
      "TXT_PINCODE_LOCALITY": "PIMPALGAON (K)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 2568,
      "City District Name": "BILOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431200,
      "TXT_PINCODE_LOCALITY": "SAHASTRAMULI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431201,
      "TXT_PINCODE_LOCALITY": "CHITE PIMPALGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431202,
      "TXT_PINCODE_LOCALITY": "BADNAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 526,
      "City District Name": "JALNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431203,
      "TXT_PINCODE_LOCALITY": "JALNA RAKHIV POLICE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431204,
      "TXT_PINCODE_LOCALITY": "AMBAD BAZAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431205,
      "TXT_PINCODE_LOCALITY": "WADIGODRI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 526,
      "City District Name": "JALNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431206,
      "TXT_PINCODE_LOCALITY": "JAFFARABAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 526,
      "City District Name": "JALNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431207,
      "TXT_PINCODE_LOCALITY": "RANJANI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431208,
      "TXT_PINCODE_LOCALITY": "TEMBHURNI (AURANGABAD)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 526,
      "City District Name": "JALNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431209,
      "TXT_PINCODE_LOCALITY": "TIRTHPURI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 526,
      "City District Name": "JALNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431210,
      "TXT_PINCODE_LOCALITY": "CHIKHALTHANA I A",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431211,
      "TXT_PINCODE_LOCALITY": "KUMBHAR PIMPALGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 526,
      "City District Name": "JALNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431212,
      "TXT_PINCODE_LOCALITY": "KARMAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 526,
      "City District Name": "JALNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431213,
      "TXT_PINCODE_LOCALITY": "PATHRUD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431214,
      "TXT_PINCODE_LOCALITY": "WANADGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 526,
      "City District Name": "JALNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431215,
      "TXT_PINCODE_LOCALITY": "OLD JALNA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 526,
      "City District Name": "JALNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431221,
      "TXT_PINCODE_LOCALITY": "PANOLA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442852,
      "City District Name": "MAHOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431261,
      "TXT_PINCODE_LOCALITY": "THAPATI TANDA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1573,
      "City District Name": "PAITHAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431302,
      "TXT_PINCODE_LOCALITY": "THANE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 576,
      "City District Name": "THANE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431314,
      "TXT_PINCODE_LOCALITY": "VAKIL COLONY",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 3183,
      "City District Name": "GANGAKHED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431400,
      "TXT_PINCODE_LOCALITY": "SANPURI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 561,
      "City District Name": "PARBHANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431401,
      "TXT_PINCODE_LOCALITY": "PARBHANI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431402,
      "TXT_PINCODE_LOCALITY": "PARBHANI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 561,
      "City District Name": "PARBHANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431408,
      "TXT_PINCODE_LOCALITY": "OHAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431416,
      "TXT_PINCODE_LOCALITY": "KHANDALA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 6120,
      "City District Name": "VAIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431501,
      "TXT_PINCODE_LOCALITY": "PARTUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 526,
      "City District Name": "JALNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431502,
      "TXT_PINCODE_LOCALITY": "SATONA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 526,
      "City District Name": "JALNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431503,
      "TXT_PINCODE_LOCALITY": "CHIKHALTHANA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 561,
      "City District Name": "PARBHANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431504,
      "TXT_PINCODE_LOCALITY": "MANTHA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 526,
      "City District Name": "JALNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431505,
      "TXT_PINCODE_LOCALITY": "BILOLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431506,
      "TXT_PINCODE_LOCALITY": "PATHRI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 561,
      "City District Name": "PARBHANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431507,
      "TXT_PINCODE_LOCALITY": "ASHTI (PARABHANI)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 526,
      "City District Name": "JALNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431508,
      "TXT_PINCODE_LOCALITY": "BORI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 561,
      "City District Name": "PARBHANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431509,
      "TXT_PINCODE_LOCALITY": "JINTUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 561,
      "City District Name": "PARBHANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431510,
      "TXT_PINCODE_LOCALITY": "JINTUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 561,
      "City District Name": "PARBHANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431511,
      "TXT_PINCODE_LOCALITY": "PURNA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 561,
      "City District Name": "PARBHANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431512,
      "TXT_PINCODE_LOCALITY": "BASMATA NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1050,
      "City District Name": "HINGOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431513,
      "TXT_PINCODE_LOCALITY": "HINGOLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1050,
      "City District Name": "HINGOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431514,
      "TXT_PINCODE_LOCALITY": "GANGAKHED",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 561,
      "City District Name": "PARBHANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431515,
      "TXT_PINCODE_LOCALITY": "PARLI VAIJANATH",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 534,
      "City District Name": "BEED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431516,
      "TXT_PINCODE_LOCALITY": "SONPETH",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 561,
      "City District Name": "PARBHANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431517,
      "TXT_PINCODE_LOCALITY": "AMBEJOGAI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 534,
      "City District Name": "BEED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431518,
      "TXT_PINCODE_LOCALITY": "BANSAROLA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 534,
      "City District Name": "BEED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431519,
      "TXT_PINCODE_LOCALITY": "GHATNANDUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 534,
      "City District Name": "BEED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431520,
      "TXT_PINCODE_LOCALITY": "PARLI THERMAL POWER STATI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 534,
      "City District Name": "BEED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431521,
      "TXT_PINCODE_LOCALITY": "DAITHANA S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 561,
      "City District Name": "PARBHANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431522,
      "TXT_PINCODE_LOCALITY": "PANGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 553,
      "City District Name": "LATUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431523,
      "TXT_PINCODE_LOCALITY": "AMBA SAKHAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 534,
      "City District Name": "BEED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431524,
      "TXT_PINCODE_LOCALITY": "BEED",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 534,
      "City District Name": "BEED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431525,
      "TXT_PINCODE_LOCALITY": "PARDI BK.",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 2386,
      "City District Name": "BASMATH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431526,
      "TXT_PINCODE_LOCALITY": "BALLUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1050,
      "City District Name": "HINGOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431527,
      "TXT_PINCODE_LOCALITY": "KOUSADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 553,
      "City District Name": "LATUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431528,
      "TXT_PINCODE_LOCALITY": "BAVDAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 534,
      "City District Name": "BEED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431530,
      "TXT_PINCODE_LOCALITY": "VSSK PANGRI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 534,
      "City District Name": "BEED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431531,
      "TXT_PINCODE_LOCALITY": "BRAMAPURA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431535,
      "TXT_PINCODE_LOCALITY": "GIRGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 2386,
      "City District Name": "BASMATH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431536,
      "TXT_PINCODE_LOCALITY": "RANI SAWARGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 561,
      "City District Name": "PARBHANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431537,
      "TXT_PINCODE_LOCALITY": "PEDGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 561,
      "City District Name": "PARBHANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431540,
      "TXT_PINCODE_LOCALITY": "ZARI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 561,
      "City District Name": "PARBHANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431541,
      "TXT_PINCODE_LOCALITY": "RAMPURI B K",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 561,
      "City District Name": "PARBHANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431542,
      "TXT_PINCODE_LOCALITY": "SENGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1050,
      "City District Name": "HINGOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431545,
      "TXT_PINCODE_LOCALITY": "KINHOLA BK",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4437,
      "City District Name": "MANWATH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431546,
      "TXT_PINCODE_LOCALITY": "LOHARA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4437,
      "City District Name": "MANWATH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431553,
      "TXT_PINCODE_LOCALITY": "DABI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 5025,
      "City District Name": "PARLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431572,
      "TXT_PINCODE_LOCALITY": "BANDARWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442864,
      "City District Name": "PALAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431601,
      "TXT_PINCODE_LOCALITY": "NANDED J P MARG",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431602,
      "TXT_PINCODE_LOCALITY": "SHIVAJINAGAR (NANDED)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431603,
      "TXT_PINCODE_LOCALITY": "CIDCO NANDED",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431604,
      "TXT_PINCODE_LOCALITY": "NANDED SARAFA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431605,
      "TXT_PINCODE_LOCALITY": "ASHOKNAGAR S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431606,
      "TXT_PINCODE_LOCALITY": "SRTMU NANDED S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431608,
      "TXT_PINCODE_LOCALITY": "VANZIRGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442861,
      "City District Name": "NAIGAON (KHAIRGAON)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431630,
      "TXT_PINCODE_LOCALITY": "KAUDGAON GHODA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 5025,
      "City District Name": "PARLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431646,
      "TXT_PINCODE_LOCALITY": "BARUL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431700,
      "TXT_PINCODE_LOCALITY": "ALGEGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431701,
      "TXT_PINCODE_LOCALITY": "AKHADA BALAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1050,
      "City District Name": "HINGOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431702,
      "TXT_PINCODE_LOCALITY": "KALAMNURI S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1050,
      "City District Name": "HINGOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431703,
      "TXT_PINCODE_LOCALITY": "GOREGAON (PARBHANI)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 561,
      "City District Name": "PARBHANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431704,
      "TXT_PINCODE_LOCALITY": "ARDHAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431705,
      "TXT_PINCODE_LOCALITY": "AUNDHA NAGNATH",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1050,
      "City District Name": "HINGOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431706,
      "TXT_PINCODE_LOCALITY": "GANDHI NAGAR (NANDED)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431707,
      "TXT_PINCODE_LOCALITY": "OSMANNAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431708,
      "TXT_PINCODE_LOCALITY": "LOHA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431709,
      "TXT_PINCODE_LOCALITY": "NAIGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431710,
      "TXT_PINCODE_LOCALITY": "BILOLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431711,
      "TXT_PINCODE_LOCALITY": "KUNDALWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431712,
      "TXT_PINCODE_LOCALITY": "HADGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431713,
      "TXT_PINCODE_LOCALITY": "TAMSA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431714,
      "TXT_PINCODE_LOCALITY": "KANDHAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431715,
      "TXT_PINCODE_LOCALITY": "P. SAWARGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431716,
      "TXT_PINCODE_LOCALITY": "JAMB B K S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431717,
      "TXT_PINCODE_LOCALITY": "DEGLOOR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431718,
      "TXT_PINCODE_LOCALITY": "KARADKHED",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431719,
      "TXT_PINCODE_LOCALITY": "MUKRAMABAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431720,
      "TXT_PINCODE_LOCALITY": "PALAM",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 561,
      "City District Name": "PARBHANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431721,
      "TXT_PINCODE_LOCALITY": "MAHORE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431722,
      "TXT_PINCODE_LOCALITY": "GADGA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431723,
      "TXT_PINCODE_LOCALITY": "SHAHAPUR (NANDED)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431724,
      "TXT_PINCODE_LOCALITY": "MULAKLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431725,
      "TXT_PINCODE_LOCALITY": "NANDED",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431726,
      "TXT_PINCODE_LOCALITY": "KUTTUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431730,
      "TXT_PINCODE_LOCALITY": "SIDHESHWAR CAMP",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1050,
      "City District Name": "HINGOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431731,
      "TXT_PINCODE_LOCALITY": "SAGROLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431732,
      "TXT_PINCODE_LOCALITY": "NANDED",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431733,
      "TXT_PINCODE_LOCALITY": "BADBA DA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431734,
      "TXT_PINCODE_LOCALITY": "WAI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431735,
      "TXT_PINCODE_LOCALITY": "LIMBGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431736,
      "TXT_PINCODE_LOCALITY": "RAMPUR THADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 2568,
      "City District Name": "BILOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431737,
      "TXT_PINCODE_LOCALITY": "SHIRAD SHOLAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1050,
      "City District Name": "HINGOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431738,
      "TXT_PINCODE_LOCALITY": "HATTA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1050,
      "City District Name": "HINGOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431739,
      "TXT_PINCODE_LOCALITY": "WAKUDI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1050,
      "City District Name": "HINGOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431740,
      "TXT_PINCODE_LOCALITY": "NANDED",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431741,
      "TXT_PINCODE_LOCALITY": "HANEGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431742,
      "TXT_PINCODE_LOCALITY": "KURLA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431743,
      "TXT_PINCODE_LOCALITY": "NIWGHA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431744,
      "TXT_PINCODE_LOCALITY": "PETHWADAJ",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431745,
      "TXT_PINCODE_LOCALITY": "BARAD (NANDED)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431746,
      "TXT_PINCODE_LOCALITY": "BARUL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431750,
      "TXT_PINCODE_LOCALITY": "KASARKHEDA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431771,
      "TXT_PINCODE_LOCALITY": "RAMTAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 2911,
      "City District Name": "DEGLUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431790,
      "TXT_PINCODE_LOCALITY": "YESGI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 2568,
      "City District Name": "BILOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431801,
      "TXT_PINCODE_LOCALITY": "BHOKAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431802,
      "TXT_PINCODE_LOCALITY": "HIMAYAT NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431803,
      "TXT_PINCODE_LOCALITY": "KARKHELI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431804,
      "TXT_PINCODE_LOCALITY": "KINWAT",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431805,
      "TXT_PINCODE_LOCALITY": "MANDVI (NANDED)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431806,
      "TXT_PINCODE_LOCALITY": "MUDKHED STN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431807,
      "TXT_PINCODE_LOCALITY": "UMRI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431808,
      "TXT_PINCODE_LOCALITY": "KARKHELI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431809,
      "TXT_PINCODE_LOCALITY": "DHARMABAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431810,
      "TXT_PINCODE_LOCALITY": "CODHADI *",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431811,
      "TXT_PINCODE_LOCALITY": "UMRI (M) (NANDED)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1050,
      "City District Name": "HINGOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431812,
      "TXT_PINCODE_LOCALITY": "MUGAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431813,
      "TXT_PINCODE_LOCALITY": "DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 2915,
      "City District Name": "DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431814,
      "TXT_PINCODE_LOCALITY": "NANDED",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431815,
      "TXT_PINCODE_LOCALITY": "NANDED",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431865,
      "TXT_PINCODE_LOCALITY": "TEMBI TANDA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4001,
      "City District Name": "KINWAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 431903,
      "TXT_PINCODE_LOCALITY": "TRIMBALPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 3185,
      "City District Name": "GANGAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 433113,
      "TXT_PINCODE_LOCALITY": "VARANGALWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442873,
      "City District Name": "SHIRUR (KASAR)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 434180,
      "TXT_PINCODE_LOCALITY": "WAI TANDA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442839,
      "City District Name": "HIMAYATNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 434716,
      "TXT_PINCODE_LOCALITY": "MASALAGA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1574,
      "City District Name": "KANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 434746,
      "TXT_PINCODE_LOCALITY": "SHIRUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1574,
      "City District Name": "KANDHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 434802,
      "TXT_PINCODE_LOCALITY": "WAI PANA KH.",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 3343,
      "City District Name": "HADGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 436130,
      "TXT_PINCODE_LOCALITY": "PERUMBAVOOR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 5101,
      "City District Name": "PERUMBAVOOR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 436160,
      "TXT_PINCODE_LOCALITY": "SOUTH CENTRAL RAILWAY COLONY",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 436447,
      "TXT_PINCODE_LOCALITY": "ATRAILA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 875,
      "City District Name": "REWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 436916,
      "TXT_PINCODE_LOCALITY": "TANJONG RHU,KATONG",
      "NUM_STATE_CD": 292,
      "State Name": "SINGAPORE",
      "NUM_CITYDISTRICT_CD": 440970,
      "City District Name": "SINGAPORE",
      "NUM_COUNTRY_CD": 151
    },
    {
      "NUM_PINCODE": 437401,
      "TXT_PINCODE_LOCALITY": "KAKDE NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 561,
      "City District Name": "PARBHANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 437804,
      "TXT_PINCODE_LOCALITY": "SINDGI (MO)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4001,
      "City District Name": "KINWAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 490001,
      "TXT_PINCODE_LOCALITY": "BHILIA I",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 490002,
      "TXT_PINCODE_LOCALITY": "KUMHAR (BHILAI)",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 490006,
      "TXT_PINCODE_LOCALITY": "BHILIA CIVIL CENTRE",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 490008,
      "TXT_PINCODE_LOCALITY": "DURG",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 490009,
      "TXT_PINCODE_LOCALITY": "DURG",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 490010,
      "TXT_PINCODE_LOCALITY": "BHILAI NAWA GAON",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 490011,
      "TXT_PINCODE_LOCALITY": "BHILAI KHURSIPUR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 490012,
      "TXT_PINCODE_LOCALITY": "BHILAI NEW KHURSIPUR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 490020,
      "TXT_PINCODE_LOCALITY": "MOTILAL NEHRU NAGR BHILAI S.O",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 490021,
      "TXT_PINCODE_LOCALITY": "BHILIA EAST",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 490022,
      "TXT_PINCODE_LOCALITY": "BHILAI SP ARMED FORCE LIN",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 490023,
      "TXT_PINCODE_LOCALITY": "BHILAI SUPELA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 490024,
      "TXT_PINCODE_LOCALITY": "KHUMARI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 490025,
      "TXT_PINCODE_LOCALITY": "BHILAI MARSHALLING YARD",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 490026,
      "TXT_PINCODE_LOCALITY": "BHILIA INDUEST",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 490027,
      "TXT_PINCODE_LOCALITY": "BHILIA VAISHALI NAGAR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 490028,
      "TXT_PINCODE_LOCALITY": "BHILIA KOHKA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 490036,
      "TXT_PINCODE_LOCALITY": "BHILAI NANDNI MINES",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 490037,
      "TXT_PINCODE_LOCALITY": "AHIWARA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 490042,
      "TXT_PINCODE_LOCALITY": "KUMHARI (BHILAI)",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491001,
      "TXT_PINCODE_LOCALITY": "DURG H O",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491002,
      "TXT_PINCODE_LOCALITY": "DURG R S",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491003,
      "TXT_PINCODE_LOCALITY": "DURG KASRADIH",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491004,
      "TXT_PINCODE_LOCALITY": "DURG PADNABHPUR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491050,
      "TXT_PINCODE_LOCALITY": "CHANDRAKHURI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491107,
      "TXT_PINCODE_LOCALITY": "RISALI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491111,
      "TXT_PINCODE_LOCALITY": "PATAN",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491113,
      "TXT_PINCODE_LOCALITY": "NANDKATHI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491125,
      "TXT_PINCODE_LOCALITY": "TIRGA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491221,
      "TXT_PINCODE_LOCALITY": "ANDA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491222,
      "TXT_PINCODE_LOCALITY": "SIKOSA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491223,
      "TXT_PINCODE_LOCALITY": "GUNDERDEHI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491224,
      "TXT_PINCODE_LOCALITY": "RANCHIRAI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491225,
      "TXT_PINCODE_LOCALITY": "ARJUNDA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491226,
      "TXT_PINCODE_LOCALITY": "BALOD TOWN",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491227,
      "TXT_PINCODE_LOCALITY": "BALOD",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2284,
      "City District Name": "BALOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491228,
      "TXT_PINCODE_LOCALITY": "DALLIRAJAHARA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491229,
      "TXT_PINCODE_LOCALITY": "MANPURA (DURG)",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491230,
      "TXT_PINCODE_LOCALITY": "DONDI AWARI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491321,
      "TXT_PINCODE_LOCALITY": "TIHARIPALI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 5515,
      "City District Name": "SARAIPALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491331,
      "TXT_PINCODE_LOCALITY": "DHAMDAH",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491332,
      "TXT_PINCODE_LOCALITY": "BERLA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491333,
      "TXT_PINCODE_LOCALITY": "BODELA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 5283,
      "City District Name": "RAJNANDGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491335,
      "TXT_PINCODE_LOCALITY": "KARESARA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 441920,
      "City District Name": "BEMETARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491336,
      "TXT_PINCODE_LOCALITY": "DASHRANGPUR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 5283,
      "City District Name": "RAJNANDGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491337,
      "TXT_PINCODE_LOCALITY": "NAWANAGAR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491338,
      "TXT_PINCODE_LOCALITY": "KHAMARIA (DURG)",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491339,
      "TXT_PINCODE_LOCALITY": "MARROW",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491340,
      "TXT_PINCODE_LOCALITY": "NANDGHAT",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491341,
      "TXT_PINCODE_LOCALITY": "DEEKAR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491441,
      "TXT_PINCODE_LOCALITY": "RAJNANDGAON H O",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 5283,
      "City District Name": "RAJNANDGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491443,
      "TXT_PINCODE_LOCALITY": "BAJRANGPUR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 5283,
      "City District Name": "RAJNANDGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491444,
      "TXT_PINCODE_LOCALITY": "GHUMKA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 5283,
      "City District Name": "RAJNANDGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491445,
      "TXT_PINCODE_LOCALITY": "DONGARGARH",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 5283,
      "City District Name": "RAJNANDGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491557,
      "TXT_PINCODE_LOCALITY": "CHICHOLA (DURG)",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 5283,
      "City District Name": "RAJNANDGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491558,
      "TXT_PINCODE_LOCALITY": "CHHURIA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 5283,
      "City District Name": "RAJNANDGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491559,
      "TXT_PINCODE_LOCALITY": "PANDARIA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3868,
      "City District Name": "KAWARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491661,
      "TXT_PINCODE_LOCALITY": "DONGARGAON",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 5283,
      "City District Name": "RAJNANDGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491665,
      "TXT_PINCODE_LOCALITY": "AMBAGARH CHOWKI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 5283,
      "City District Name": "RAJNANDGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491666,
      "TXT_PINCODE_LOCALITY": "MOHALLA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 5283,
      "City District Name": "RAJNANDGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491667,
      "TXT_PINCODE_LOCALITY": "KORBA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 442282,
      "City District Name": "MOHLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491668,
      "TXT_PINCODE_LOCALITY": "CHIRCHADI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 5283,
      "City District Name": "RAJNANDGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491669,
      "TXT_PINCODE_LOCALITY": "KALMITAR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 425683,
      "City District Name": "KOTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491670,
      "TXT_PINCODE_LOCALITY": "MOHTARAI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491671,
      "TXT_PINCODE_LOCALITY": "BAKALI TOLA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 442278,
      "City District Name": "DONDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491672,
      "TXT_PINCODE_LOCALITY": "PARROMETA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 442656,
      "City District Name": "AMBAGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491673,
      "TXT_PINCODE_LOCALITY": "SANKARA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 442279,
      "City District Name": "CHHURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491674,
      "TXT_PINCODE_LOCALITY": "RANIPARTEWA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 442279,
      "City District Name": "CHHURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491771,
      "TXT_PINCODE_LOCALITY": "DONDILOHARA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3868,
      "City District Name": "KAWARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491881,
      "TXT_PINCODE_LOCALITY": "KHAIRAQARH RAJ (RAJNANADG",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 5283,
      "City District Name": "RAJNANDGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491885,
      "TXT_PINCODE_LOCALITY": "CHHUI KHADAN",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 5283,
      "City District Name": "RAJNANDGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491888,
      "TXT_PINCODE_LOCALITY": "GANDAI PADERIA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 5283,
      "City District Name": "RAJNANDGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491988,
      "TXT_PINCODE_LOCALITY": "SONPURI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 442288,
      "City District Name": "SAHASPUR LOHARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491991,
      "TXT_PINCODE_LOCALITY": "SAHASPUR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 5283,
      "City District Name": "RAJNANDGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491993,
      "TXT_PINCODE_LOCALITY": "PARASBOD",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 441920,
      "City District Name": "BEMETARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491995,
      "TXT_PINCODE_LOCALITY": "KAWARDHA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3868,
      "City District Name": "KAWARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 491996,
      "TXT_PINCODE_LOCALITY": "SAHASPUR LOHARA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 442288,
      "City District Name": "SAHASPUR LOHARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 492001,
      "TXT_PINCODE_LOCALITY": "CHOWBE COLONY",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 492002,
      "TXT_PINCODE_LOCALITY": "RAIPUR SCIENCE COLLEGE",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 492003,
      "TXT_PINCODE_LOCALITY": "RAIPUR RAJKUMAR COLLEGE",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 492004,
      "TXT_PINCODE_LOCALITY": "RAIPUR PANDRI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 492005,
      "TXT_PINCODE_LOCALITY": "RAIPUR KAPA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 492006,
      "TXT_PINCODE_LOCALITY": "RAVIGRAM",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 492007,
      "TXT_PINCODE_LOCALITY": "RAIPUR SHANKER COLLEGE",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 492008,
      "TXT_PINCODE_LOCALITY": "RAIPUR W R S COLONY",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 492009,
      "TXT_PINCODE_LOCALITY": "RAIPUR GUDIHARI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 492010,
      "TXT_PINCODE_LOCALITY": "RAIPUR RAVISHANKAR UNIVER",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 492011,
      "TXT_PINCODE_LOCALITY": "GUDHIYARI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 492012,
      "TXT_PINCODE_LOCALITY": "RAIPUR KRISHAK NAGAR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 492013,
      "TXT_PINCODE_LOCALITY": "SUNDAR NAGAR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 492014,
      "TXT_PINCODE_LOCALITY": "SADDU S.O",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 492015,
      "TXT_PINCODE_LOCALITY": "RAIPUR MANA CAMP",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 492016,
      "TXT_PINCODE_LOCALITY": "MANA BASTI S.O",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 492017,
      "TXT_PINCODE_LOCALITY": "RAIPUR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 492018,
      "TXT_PINCODE_LOCALITY": "SECTOR 27 NAYA RAIPUR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 492033,
      "TXT_PINCODE_LOCALITY": "YASH VIHAR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 492099,
      "TXT_PINCODE_LOCALITY": "MOHABA BAZAR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 492101,
      "TXT_PINCODE_LOCALITY": "MANDIR HASAD",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 492102,
      "TXT_PINCODE_LOCALITY": "MATHPURENA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 492103,
      "TXT_PINCODE_LOCALITY": "MAROD",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2962,
      "City District Name": "DHAMTARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 492104,
      "TXT_PINCODE_LOCALITY": "CHARMUDIA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 492105,
      "TXT_PINCODE_LOCALITY": "AMDI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2962,
      "City District Name": "DHAMTARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 492106,
      "TXT_PINCODE_LOCALITY": "MAGARLOD",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2962,
      "City District Name": "DHAMTARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 492107,
      "TXT_PINCODE_LOCALITY": "CHAMPARAN",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 492108,
      "TXT_PINCODE_LOCALITY": "KOPRA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 492109,
      "TXT_PINCODE_LOCALITY": "PANDUKA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 492110,
      "TXT_PINCODE_LOCALITY": "TUMGAON",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 4307,
      "City District Name": "MAHASAMUND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 492111,
      "TXT_PINCODE_LOCALITY": "JHALLAP",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 4307,
      "City District Name": "MAHASAMUND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 492112,
      "TXT_PINCODE_LOCALITY": "SAKARA (BASNA)",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 4307,
      "City District Name": "MAHASAMUND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 492409,
      "TXT_PINCODE_LOCALITY": "ATARMARA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 492441,
      "TXT_PINCODE_LOCALITY": "PALAUD-1 (PALAUD)",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2097,
      "City District Name": "ARANG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493101,
      "TXT_PINCODE_LOCALITY": "SIMGA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493110,
      "TXT_PINCODE_LOCALITY": "SILYARI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493111,
      "TXT_PINCODE_LOCALITY": "MANDHAR CEMENT FACTORY",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493113,
      "TXT_PINCODE_LOCALITY": "HATHBAND",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493114,
      "TXT_PINCODE_LOCALITY": "NEORA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493116,
      "TXT_PINCODE_LOCALITY": "BAIKUNTHPUR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493118,
      "TXT_PINCODE_LOCALITY": "BHATAPARA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493125,
      "TXT_PINCODE_LOCALITY": "TARENGA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493126,
      "TXT_PINCODE_LOCALITY": "DAMAKHEDA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493127,
      "TXT_PINCODE_LOCALITY": "DHARSIWA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493128,
      "TXT_PINCODE_LOCALITY": "KURRA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2962,
      "City District Name": "DHAMTARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493195,
      "TXT_PINCODE_LOCALITY": "HCW TOWN SHIP",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493196,
      "TXT_PINCODE_LOCALITY": "GADABHATA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 442291,
      "City District Name": "PALARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493211,
      "TXT_PINCODE_LOCALITY": "SILTARA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493221,
      "TXT_PINCODE_LOCALITY": "RAIPUR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493222,
      "TXT_PINCODE_LOCALITY": "BHATGAON",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493225,
      "TXT_PINCODE_LOCALITY": "KHARORA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493228,
      "TXT_PINCODE_LOCALITY": "PALLARI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493229,
      "TXT_PINCODE_LOCALITY": "CHARODA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 4307,
      "City District Name": "MAHASAMUND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493230,
      "TXT_PINCODE_LOCALITY": "KOSMANDI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493326,
      "TXT_PINCODE_LOCALITY": "TUNDRA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493328,
      "TXT_PINCODE_LOCALITY": "BHINOLI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493330,
      "TXT_PINCODE_LOCALITY": "ARJUNI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493331,
      "TXT_PINCODE_LOCALITY": "MALDI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493332,
      "TXT_PINCODE_LOCALITY": "BALODA BAZAR B.O",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2286,
      "City District Name": "BALODA BAZAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493334,
      "TXT_PINCODE_LOCALITY": "LATUWA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493335,
      "TXT_PINCODE_LOCALITY": "KASDOL",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493338,
      "TXT_PINCODE_LOCALITY": "BILAIGARH",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493341,
      "TXT_PINCODE_LOCALITY": "DHAMTARI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2962,
      "City District Name": "DHAMTARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493344,
      "TXT_PINCODE_LOCALITY": "KATGI (RAIPUR)",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493354,
      "TXT_PINCODE_LOCALITY": "BASANA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493441,
      "TXT_PINCODE_LOCALITY": "ARANG",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493443,
      "TXT_PINCODE_LOCALITY": "BELONDA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 4307,
      "City District Name": "MAHASAMUND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493445,
      "TXT_PINCODE_LOCALITY": "MAHASAMUND",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 4307,
      "City District Name": "MAHASAMUND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493446,
      "TXT_PINCODE_LOCALITY": "MAHASAMUND",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 4307,
      "City District Name": "MAHASAMUND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493448,
      "TXT_PINCODE_LOCALITY": "GP VILLAGE OF NARRA",
      "NUM_STATE_CD": 829,
      "State Name": "CHATTISGARH",
      "NUM_CITYDISTRICT_CD": 444175,
      "City District Name": "MAHASAMUND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493449,
      "TXT_PINCODE_LOCALITY": "BAG BAHARA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 4307,
      "City District Name": "MAHASAMUND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493526,
      "TXT_PINCODE_LOCALITY": "LAWAN",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493551,
      "TXT_PINCODE_LOCALITY": "PITHOA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493554,
      "TXT_PINCODE_LOCALITY": "BASNA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 4307,
      "City District Name": "MAHASAMUND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493555,
      "TXT_PINCODE_LOCALITY": "JAGDISHPUR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 4307,
      "City District Name": "MAHASAMUND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493558,
      "TXT_PINCODE_LOCALITY": "SARAI PILLI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 4307,
      "City District Name": "MAHASAMUND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493559,
      "TXT_PINCODE_LOCALITY": "SARSIVA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493562,
      "TXT_PINCODE_LOCALITY": "BANSKOTE",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 4307,
      "City District Name": "MAHASAMUND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493645,
      "TXT_PINCODE_LOCALITY": "BHAKHARA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2962,
      "City District Name": "DHAMTARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493650,
      "TXT_PINCODE_LOCALITY": "SEMRA (BHAKIA)",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2962,
      "City District Name": "DHAMTARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493661,
      "TXT_PINCODE_LOCALITY": "ABHANPUR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493662,
      "TXT_PINCODE_LOCALITY": "JEOJAMGAON",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493663,
      "TXT_PINCODE_LOCALITY": "KURUD",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2962,
      "City District Name": "DHAMTARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493770,
      "TXT_PINCODE_LOCALITY": "BHAKHARA S. O.",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2962,
      "City District Name": "DHAMTARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493773,
      "TXT_PINCODE_LOCALITY": "DHAMTARI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2962,
      "City District Name": "DHAMTARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493776,
      "TXT_PINCODE_LOCALITY": "RUDRI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2962,
      "City District Name": "DHAMTARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493778,
      "TXT_PINCODE_LOCALITY": "NAGRI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2962,
      "City District Name": "DHAMTARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493779,
      "TXT_PINCODE_LOCALITY": "SANKARA (NAGRI)",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2962,
      "City District Name": "DHAMTARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493780,
      "TXT_PINCODE_LOCALITY": "SIHAWA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2962,
      "City District Name": "DHAMTARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493781,
      "TXT_PINCODE_LOCALITY": "BELARBAZAR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2962,
      "City District Name": "DHAMTARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493782,
      "TXT_PINCODE_LOCALITY": "SEMRA (NAGAR)",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2962,
      "City District Name": "DHAMTARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493881,
      "TXT_PINCODE_LOCALITY": "NAWA PARA RAJINI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493885,
      "TXT_PINCODE_LOCALITY": "GOBRANAWAPARA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493887,
      "TXT_PINCODE_LOCALITY": "KOPRA S.O",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 441355,
      "City District Name": "GARIABAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493888,
      "TXT_PINCODE_LOCALITY": "UDANTI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 442272,
      "City District Name": "MAINPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493889,
      "TXT_PINCODE_LOCALITY": "GARIBAND",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493890,
      "TXT_PINCODE_LOCALITY": "DEOBHOG",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493891,
      "TXT_PINCODE_LOCALITY": "BEHARBUDA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 441355,
      "City District Name": "GARIABAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493895,
      "TXT_PINCODE_LOCALITY": "MAINPUR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493985,
      "TXT_PINCODE_LOCALITY": "KIRWAI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493992,
      "TXT_PINCODE_LOCALITY": "PHINGESHWAR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493996,
      "TXT_PINCODE_LOCALITY": "CHHURRA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 493998,
      "TXT_PINCODE_LOCALITY": "KHADMA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2962,
      "City District Name": "DHAMTARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494001,
      "TXT_PINCODE_LOCALITY": "JAGDALPUR HO",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424423,
      "City District Name": "BASTAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494002,
      "TXT_PINCODE_LOCALITY": "JAGDALPUR RS",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424423,
      "City District Name": "BASTAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494005,
      "TXT_PINCODE_LOCALITY": "DHARAMPURA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424423,
      "City District Name": "BASTAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494010,
      "TXT_PINCODE_LOCALITY": "JAGDALPUR LOHANDIGUDA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424423,
      "City District Name": "BASTAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494034,
      "TXT_PINCODE_LOCALITY": "AMODA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3757,
      "City District Name": "KANKER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494111,
      "TXT_PINCODE_LOCALITY": "SUKMA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2880,
      "City District Name": "DANTEWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494112,
      "TXT_PINCODE_LOCALITY": "DARBHA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2880,
      "City District Name": "DANTEWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494113,
      "TXT_PINCODE_LOCALITY": "CHINDGARH",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2880,
      "City District Name": "DANTEWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494114,
      "TXT_PINCODE_LOCALITY": "KONTA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2880,
      "City District Name": "DANTEWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494115,
      "TXT_PINCODE_LOCALITY": "TONGAPAL",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2880,
      "City District Name": "DANTEWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494119,
      "TXT_PINCODE_LOCALITY": "KERLAPAL",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2880,
      "City District Name": "DANTEWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494121,
      "TXT_PINCODE_LOCALITY": "SIRSATTI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 442159,
      "City District Name": "SUKMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494122,
      "TXT_PINCODE_LOCALITY": "DORNAPAL",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2880,
      "City District Name": "DANTEWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494126,
      "TXT_PINCODE_LOCALITY": "KUKANAR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424423,
      "City District Name": "BASTAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494221,
      "TXT_PINCODE_LOCALITY": "ASNA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424423,
      "City District Name": "BASTAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494222,
      "TXT_PINCODE_LOCALITY": "BAKAVAN",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424423,
      "City District Name": "BASTAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494223,
      "TXT_PINCODE_LOCALITY": "BASTAR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424423,
      "City District Name": "BASTAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494224,
      "TXT_PINCODE_LOCALITY": "GOGAON",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 1853,
      "City District Name": "RAIPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494225,
      "TXT_PINCODE_LOCALITY": "SONARPAL",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424423,
      "City District Name": "BASTAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494226,
      "TXT_PINCODE_LOCALITY": "KONDAGAON S.O",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424423,
      "City District Name": "BASTAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494227,
      "TXT_PINCODE_LOCALITY": "BANIYAGAM",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424423,
      "City District Name": "BASTAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494228,
      "TXT_PINCODE_LOCALITY": "PHARASGAON",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424423,
      "City District Name": "BASTAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494229,
      "TXT_PINCODE_LOCALITY": "PHARASGAON CAMP",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424423,
      "City District Name": "BASTAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494230,
      "TXT_PINCODE_LOCALITY": "PIPRA BAHIGAON",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424423,
      "City District Name": "BASTAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494232,
      "TXT_PINCODE_LOCALITY": "KONDAGAON SADAR BAZAR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424423,
      "City District Name": "BASTAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494235,
      "TXT_PINCODE_LOCALITY": "LANJODO",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424423,
      "City District Name": "BASTAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494237,
      "TXT_PINCODE_LOCALITY": "MAKDI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424423,
      "City District Name": "BASTAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494331,
      "TXT_PINCODE_LOCALITY": "KESHKAL",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424423,
      "City District Name": "BASTAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494332,
      "TXT_PINCODE_LOCALITY": "SINGRAPUR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424423,
      "City District Name": "BASTAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494333,
      "TXT_PINCODE_LOCALITY": "NARHARPUR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424423,
      "City District Name": "BASTAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494334,
      "TXT_PINCODE_LOCALITY": "KANKER H O",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3757,
      "City District Name": "KANKER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494335,
      "TXT_PINCODE_LOCALITY": "SARONA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3757,
      "City District Name": "KANKER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494336,
      "TXT_PINCODE_LOCALITY": "LAKHANPURI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3757,
      "City District Name": "KANKER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494337,
      "TXT_PINCODE_LOCALITY": "CHARMA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3757,
      "City District Name": "KANKER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494347,
      "TXT_PINCODE_LOCALITY": "VISHRAMPURI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424423,
      "City District Name": "BASTAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494435,
      "TXT_PINCODE_LOCALITY": "BASTANAR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424423,
      "City District Name": "BASTAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494441,
      "TXT_PINCODE_LOCALITY": "GEEDAM",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2880,
      "City District Name": "DANTEWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494442,
      "TXT_PINCODE_LOCALITY": "TOKAPAL",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424423,
      "City District Name": "BASTAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494443,
      "TXT_PINCODE_LOCALITY": "BHAIRAMGARH",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2880,
      "City District Name": "DANTEWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494444,
      "TXT_PINCODE_LOCALITY": "BIJAIPUR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424423,
      "City District Name": "BASTAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494445,
      "TXT_PINCODE_LOCALITY": "BADEKILOPAL",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424423,
      "City District Name": "BASTAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494446,
      "TXT_PINCODE_LOCALITY": "BHOPAL PATNAM",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2880,
      "City District Name": "DANTEWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494447,
      "TXT_PINCODE_LOCALITY": "AWAPALLI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2880,
      "City District Name": "DANTEWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494448,
      "TXT_PINCODE_LOCALITY": "MADDED",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424423,
      "City District Name": "BASTAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494449,
      "TXT_PINCODE_LOCALITY": "DANTEWARA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2880,
      "City District Name": "DANTEWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494450,
      "TXT_PINCODE_LOCALITY": "BHAIRAMGARH",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 442124,
      "City District Name": "BIJAPUR.",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494452,
      "TXT_PINCODE_LOCALITY": "GANGLOOR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2880,
      "City District Name": "DANTEWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494454,
      "TXT_PINCODE_LOCALITY": "BODEQUDRA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2880,
      "City District Name": "DANTEWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494456,
      "TXT_PINCODE_LOCALITY": "KATKALYAN",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2880,
      "City District Name": "DANTEWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494551,
      "TXT_PINCODE_LOCALITY": "BHANSI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424423,
      "City District Name": "BASTAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494552,
      "TXT_PINCODE_LOCALITY": "NAKULNAR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2880,
      "City District Name": "DANTEWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494553,
      "TXT_PINCODE_LOCALITY": "BACHELI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424423,
      "City District Name": "BASTAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494554,
      "TXT_PINCODE_LOCALITY": "AKASH NAGAR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424423,
      "City District Name": "BASTAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494555,
      "TXT_PINCODE_LOCALITY": "LAWA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 442273,
      "City District Name": "KUAKONDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494556,
      "TXT_PINCODE_LOCALITY": "KIRANDUL",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2880,
      "City District Name": "DANTEWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494559,
      "TXT_PINCODE_LOCALITY": "METAPAL",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2880,
      "City District Name": "DANTEWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494572,
      "TXT_PINCODE_LOCALITY": "GADIRAS",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2880,
      "City District Name": "DANTEWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494635,
      "TXT_PINCODE_LOCALITY": "SAMBALPUR S. O",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3757,
      "City District Name": "KANKER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494653,
      "TXT_PINCODE_LOCALITY": "PARBHELI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 442276,
      "City District Name": "DURGKONDAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494661,
      "TXT_PINCODE_LOCALITY": "NARAINPUR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3757,
      "City District Name": "KANKER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494663,
      "TXT_PINCODE_LOCALITY": "KIBAIBALANG",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424423,
      "City District Name": "BASTAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494665,
      "TXT_PINCODE_LOCALITY": "ANTAGARH",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3757,
      "City District Name": "KANKER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494669,
      "TXT_PINCODE_LOCALITY": "BHANU PRATAP NAGAR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424423,
      "City District Name": "BASTAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494670,
      "TXT_PINCODE_LOCALITY": "KORAR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3757,
      "City District Name": "KANKER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494671,
      "TXT_PINCODE_LOCALITY": "BENOOR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424423,
      "City District Name": "BASTAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494771,
      "TXT_PINCODE_LOCALITY": "CHHOTE KAPASI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424423,
      "City District Name": "BASTAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494774,
      "TXT_PINCODE_LOCALITY": "BEOHARI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2434,
      "City District Name": "BEOHARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494775,
      "TXT_PINCODE_LOCALITY": "DURG",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3078,
      "City District Name": "DURG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494776,
      "TXT_PINCODE_LOCALITY": "PAKHANJORE CAMP",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3757,
      "City District Name": "KANKER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 494777,
      "TXT_PINCODE_LOCALITY": "BANDE COLONY",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3757,
      "City District Name": "KANKER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495001,
      "TXT_PINCODE_LOCALITY": "MAGARPARA ROAD",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495002,
      "TXT_PINCODE_LOCALITY": "KATHAKONI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 5819,
      "City District Name": "TAKHATPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495003,
      "TXT_PINCODE_LOCALITY": "SAKRI S. O",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495004,
      "TXT_PINCODE_LOCALITY": "DEORI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495005,
      "TXT_PINCODE_LOCALITY": "BILASPUR BIRKONA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495006,
      "TXT_PINCODE_LOCALITY": "SEEPAT",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495007,
      "TXT_PINCODE_LOCALITY": "BILASPUR SPINNING MILLS",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495009,
      "TXT_PINCODE_LOCALITY": "KONI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495010,
      "TXT_PINCODE_LOCALITY": "BILASPUR PAUNSARA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495011,
      "TXT_PINCODE_LOCALITY": "MOPKA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495012,
      "TXT_PINCODE_LOCALITY": "GHUTKU (BILASPUR)",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495029,
      "TXT_PINCODE_LOCALITY": "ADBHAR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495111,
      "TXT_PINCODE_LOCALITY": "SAKRI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495112,
      "TXT_PINCODE_LOCALITY": "GANIYARI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495113,
      "TXT_PINCODE_LOCALITY": "KARGI ROAD",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495114,
      "TXT_PINCODE_LOCALITY": "PENDRA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495115,
      "TXT_PINCODE_LOCALITY": "LORMI - 2773",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495116,
      "TXT_PINCODE_LOCALITY": "BELGHANA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495117,
      "TXT_PINCODE_LOCALITY": "PENDRA R.S.",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495118,
      "TXT_PINCODE_LOCALITY": "MARWAHI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495119,
      "TXT_PINCODE_LOCALITY": "PENDRA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495121,
      "TXT_PINCODE_LOCALITY": "PASAN",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 4076,
      "City District Name": "KORBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495220,
      "TXT_PINCODE_LOCALITY": "CHAKRABHATA CAMP",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495221,
      "TXT_PINCODE_LOCALITY": "SARGAON (BILASPUR)",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495222,
      "TXT_PINCODE_LOCALITY": "HIRR MINES (BILASPUR)",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2676,
      "City District Name": "CHAMPA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495223,
      "TXT_PINCODE_LOCALITY": "BILASPUR INDUSTRIL EST",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495224,
      "TXT_PINCODE_LOCALITY": "BILHA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495330,
      "TXT_PINCODE_LOCALITY": "TAKHTAPUR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495331,
      "TXT_PINCODE_LOCALITY": "JARHANGAON",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495334,
      "TXT_PINCODE_LOCALITY": "MUNGELI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495335,
      "TXT_PINCODE_LOCALITY": "PATHARIA (BILASPUR)",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495336,
      "TXT_PINCODE_LOCALITY": "KUNDA (BILASPUR)",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3868,
      "City District Name": "KAWARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495337,
      "TXT_PINCODE_LOCALITY": "PANDARIA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3868,
      "City District Name": "KAWARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495338,
      "TXT_PINCODE_LOCALITY": "PANDATARAI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3868,
      "City District Name": "KAWARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495422,
      "TXT_PINCODE_LOCALITY": "RATANPUR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495442,
      "TXT_PINCODE_LOCALITY": "RATANPUR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495444,
      "TXT_PINCODE_LOCALITY": "PONDIUPRORA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 4076,
      "City District Name": "KORBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495445,
      "TXT_PINCODE_LOCALITY": "KATGHORA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 4076,
      "City District Name": "KORBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495446,
      "TXT_PINCODE_LOCALITY": "HARDI BAZAR (BILASPUR)",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 4076,
      "City District Name": "KORBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495447,
      "TXT_PINCODE_LOCALITY": "BANKI MONGRA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495448,
      "TXT_PINCODE_LOCALITY": "RAMDEI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 425683,
      "City District Name": "KOTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495449,
      "TXT_PINCODE_LOCALITY": "PALI (RATANPUR)",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495450,
      "TXT_PINCODE_LOCALITY": "JAMNIPALI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 4076,
      "City District Name": "KORBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495452,
      "TXT_PINCODE_LOCALITY": "WCL GEVRA PROJECT",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 4076,
      "City District Name": "KORBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495453,
      "TXT_PINCODE_LOCALITY": "MACHADOLI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495454,
      "TXT_PINCODE_LOCALITY": "KORBA COLLIERY",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 4076,
      "City District Name": "KORBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495455,
      "TXT_PINCODE_LOCALITY": "BALGI PROJECT KORBA S.O",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 4076,
      "City District Name": "KORBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495494,
      "TXT_PINCODE_LOCALITY": "USKALED",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 442286,
      "City District Name": "BHOPALPATTNAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495548,
      "TXT_PINCODE_LOCALITY": "GATORA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495549,
      "TXT_PINCODE_LOCALITY": "AKALTARA C C I",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2676,
      "City District Name": "CHAMPA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495550,
      "TXT_PINCODE_LOCALITY": "JAIRAM NAGAR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495551,
      "TXT_PINCODE_LOCALITY": "MASTURI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495552,
      "TXT_PINCODE_LOCALITY": "AKALTARA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2676,
      "City District Name": "CHAMPA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495553,
      "TXT_PINCODE_LOCALITY": "NARIYARA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2676,
      "City District Name": "CHAMPA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495554,
      "TXT_PINCODE_LOCALITY": "PAMGARH (BILASPUR)",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2676,
      "City District Name": "CHAMPA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495555,
      "TXT_PINCODE_LOCALITY": "LOHARSI SONE",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495556,
      "TXT_PINCODE_LOCALITY": "KHAROD (RAIPUR)",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2676,
      "City District Name": "CHAMPA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495557,
      "TXT_PINCODE_LOCALITY": "SEORI NARAYAN",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2676,
      "City District Name": "CHAMPA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495558,
      "TXT_PINCODE_LOCALITY": "SEEPAT",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495559,
      "TXT_PINCODE_LOCALITY": "BALODA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2676,
      "City District Name": "CHAMPA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495560,
      "TXT_PINCODE_LOCALITY": "RAHOD",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2676,
      "City District Name": "CHAMPA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495561,
      "TXT_PINCODE_LOCALITY": "KOTMI SONER",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2676,
      "City District Name": "CHAMPA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495617,
      "TXT_PINCODE_LOCALITY": "CHAMPA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2676,
      "City District Name": "CHAMPA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495634,
      "TXT_PINCODE_LOCALITY": "ALLUMINIUM BHUWAN",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 4076,
      "City District Name": "KORBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495650,
      "TXT_PINCODE_LOCALITY": "KOSA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2676,
      "City District Name": "CHAMPA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495652,
      "TXT_PINCODE_LOCALITY": "HARDI (BILASPUR)",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495660,
      "TXT_PINCODE_LOCALITY": "BILASPUR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495661,
      "TXT_PINCODE_LOCALITY": "BIRRA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2676,
      "City District Name": "CHAMPA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495663,
      "TXT_PINCODE_LOCALITY": "GOPAL NAGAR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495665,
      "TXT_PINCODE_LOCALITY": "NAILA R S",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2676,
      "City District Name": "CHAMPA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495667,
      "TXT_PINCODE_LOCALITY": "KORBA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 4076,
      "City District Name": "KORBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495668,
      "TXT_PINCODE_LOCALITY": "JANJGIR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2676,
      "City District Name": "CHAMPA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495669,
      "TXT_PINCODE_LOCALITY": "BHAISTARA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495671,
      "TXT_PINCODE_LOCALITY": "BIRGAHNI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 441019,
      "City District Name": "JANJGIR CHAMPA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495674,
      "TXT_PINCODE_LOCALITY": "BHAISMA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2676,
      "City District Name": "CHAMPA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495677,
      "TXT_PINCODE_LOCALITY": "KORBA H O",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 4076,
      "City District Name": "KORBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495678,
      "TXT_PINCODE_LOCALITY": "KORBA TOWN S.O",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 4076,
      "City District Name": "KORBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495679,
      "TXT_PINCODE_LOCALITY": "KORBA COLLIERY",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 4076,
      "City District Name": "KORBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495680,
      "TXT_PINCODE_LOCALITY": "KORBA PRAGATI NAGAR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495681,
      "TXT_PINCODE_LOCALITY": "KORBA THERMAL STATION",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 4076,
      "City District Name": "KORBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495682,
      "TXT_PINCODE_LOCALITY": "MANIKPUR COLLIERY",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495683,
      "TXT_PINCODE_LOCALITY": "RAJGAMAR COLLIERY",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 4076,
      "City District Name": "KORBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495684,
      "TXT_PINCODE_LOCALITY": "BALCO NAGAR KOBRA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6287,
      "City District Name": "BILASPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495685,
      "TXT_PINCODE_LOCALITY": "CHAURIA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2676,
      "City District Name": "CHAMPA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495686,
      "TXT_PINCODE_LOCALITY": "KORBA HASDEO PROJECT",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 4076,
      "City District Name": "KORBA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495687,
      "TXT_PINCODE_LOCALITY": "BARADUAR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2676,
      "City District Name": "CHAMPA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495688,
      "TXT_PINCODE_LOCALITY": "DABHRA (BILASPUR)",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2676,
      "City District Name": "CHAMPA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495689,
      "TXT_PINCODE_LOCALITY": "SAKTI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2676,
      "City District Name": "CHAMPA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495690,
      "TXT_PINCODE_LOCALITY": "JAIJAIPUR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2676,
      "City District Name": "CHAMPA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495691,
      "TXT_PINCODE_LOCALITY": "MALKHARODA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2676,
      "City District Name": "CHAMPA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495692,
      "TXT_PINCODE_LOCALITY": "CHANDRAPUR (BILASPUR)",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2676,
      "City District Name": "CHAMPA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 495695,
      "TXT_PINCODE_LOCALITY": "ABHAR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2676,
      "City District Name": "CHAMPA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496001,
      "TXT_PINCODE_LOCALITY": "MOHAPALLI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6350,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496004,
      "TXT_PINCODE_LOCALITY": "RAIGARH JUTE MILLS",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 4307,
      "City District Name": "MAHASAMUND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496005,
      "TXT_PINCODE_LOCALITY": "HILL VIEW COLONY",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6350,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496051,
      "TXT_PINCODE_LOCALITY": "PATAL PALI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6350,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496094,
      "TXT_PINCODE_LOCALITY": "SURESHPUR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3551,
      "City District Name": "JASHPUR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496100,
      "TXT_PINCODE_LOCALITY": "KONDATARAI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6350,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496106,
      "TXT_PINCODE_LOCALITY": "SAMRUMA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6350,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496107,
      "TXT_PINCODE_LOCALITY": "TAMNAR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6350,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496108,
      "TXT_PINCODE_LOCALITY": "URJANAGAR TELIRAMPUR S.O",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6350,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496109,
      "TXT_PINCODE_LOCALITY": "GHARGHODA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6350,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496110,
      "TXT_PINCODE_LOCALITY": "TERAM",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6350,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496111,
      "TXT_PINCODE_LOCALITY": "GHARGHODA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6350,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496112,
      "TXT_PINCODE_LOCALITY": "KUDAM KELA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6350,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496113,
      "TXT_PINCODE_LOCALITY": "LAILUNGA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6350,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496114,
      "TXT_PINCODE_LOCALITY": "KOTBA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3551,
      "City District Name": "JASHPUR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496115,
      "TXT_PINCODE_LOCALITY": "RAJPUR (RAIGARH)",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6350,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496116,
      "TXT_PINCODE_LOCALITY": "DHARAMJAIGARH",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6350,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496117,
      "TXT_PINCODE_LOCALITY": "KUDEKELA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6350,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496118,
      "TXT_PINCODE_LOCALITY": "PATHALGAON",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3551,
      "City District Name": "JASHPUR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496119,
      "TXT_PINCODE_LOCALITY": "KAPU",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6350,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496120,
      "TXT_PINCODE_LOCALITY": "VIJAINAGAR (RAIGARH)",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6350,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496122,
      "TXT_PINCODE_LOCALITY": "SISRINGA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6350,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496124,
      "TXT_PINCODE_LOCALITY": "GERSA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6350,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496197,
      "TXT_PINCODE_LOCALITY": "JANJGIR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 442281,
      "City District Name": "TAMNAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496220,
      "TXT_PINCODE_LOCALITY": "BULDEGA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6350,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496221,
      "TXT_PINCODE_LOCALITY": "KADRO",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3551,
      "City District Name": "JASHPUR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496223,
      "TXT_PINCODE_LOCALITY": "KANSABEL",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3551,
      "City District Name": "JASHPUR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496224,
      "TXT_PINCODE_LOCALITY": "BAGICHA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3551,
      "City District Name": "JASHPUR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496225,
      "TXT_PINCODE_LOCALITY": "KUNKRUAI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6350,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496226,
      "TXT_PINCODE_LOCALITY": "DOKRA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3551,
      "City District Name": "JASHPUR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496227,
      "TXT_PINCODE_LOCALITY": "TAPKARA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3551,
      "City District Name": "JASHPUR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496228,
      "TXT_PINCODE_LOCALITY": "BANDARCHUA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3551,
      "City District Name": "JASHPUR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496230,
      "TXT_PINCODE_LOCALITY": "CHETHA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3551,
      "City District Name": "JASHPUR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496232,
      "TXT_PINCODE_LOCALITY": "KERSAI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3551,
      "City District Name": "JASHPUR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496235,
      "TXT_PINCODE_LOCALITY": "NAKBAR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3551,
      "City District Name": "JASHPUR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496236,
      "TXT_PINCODE_LOCALITY": "BAG BAHAR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3551,
      "City District Name": "JASHPUR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496242,
      "TXT_PINCODE_LOCALITY": "FARSABAHAR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3551,
      "City District Name": "JASHPUR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496245,
      "TXT_PINCODE_LOCALITY": "ANKIRA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3551,
      "City District Name": "JASHPUR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496324,
      "TXT_PINCODE_LOCALITY": "BATAIKELA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3551,
      "City District Name": "JASHPUR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496330,
      "TXT_PINCODE_LOCALITY": "MANORA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3551,
      "City District Name": "JASHPUR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496331,
      "TXT_PINCODE_LOCALITY": "JASPUR NAGAR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6350,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496334,
      "TXT_PINCODE_LOCALITY": "DULDULA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3551,
      "City District Name": "JASHPUR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496336,
      "TXT_PINCODE_LOCALITY": "SANNA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3551,
      "City District Name": "JASHPUR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496338,
      "TXT_PINCODE_LOCALITY": "GHLENG",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3551,
      "City District Name": "JASHPUR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496340,
      "TXT_PINCODE_LOCALITY": "ARRA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3551,
      "City District Name": "JASHPUR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496342,
      "TXT_PINCODE_LOCALITY": "LODAM",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 3551,
      "City District Name": "JASHPUR NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496400,
      "TXT_PINCODE_LOCALITY": "THENGAGUDI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 442289,
      "City District Name": "PUSOUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496440,
      "TXT_PINCODE_LOCALITY": "PUSSORE",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6350,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496445,
      "TXT_PINCODE_LOCALITY": "SARANGARTH",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6350,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496450,
      "TXT_PINCODE_LOCALITY": "HARDI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6350,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496460,
      "TXT_PINCODE_LOCALITY": "KOSIR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6350,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496494,
      "TXT_PINCODE_LOCALITY": "DANSARA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6350,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496551,
      "TXT_PINCODE_LOCALITY": "BARAMKELA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6350,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496554,
      "TXT_PINCODE_LOCALITY": "SARIA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6350,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496555,
      "TXT_PINCODE_LOCALITY": "SANKARA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6350,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496656,
      "TXT_PINCODE_LOCALITY": "BANSAJHAR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6350,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496658,
      "TXT_PINCODE_LOCALITY": "BADEDEOGAON",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6350,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496661,
      "TXT_PINCODE_LOCALITY": "KHARSIA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6350,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496665,
      "TXT_PINCODE_LOCALITY": "CHALL",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6350,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496666,
      "TXT_PINCODE_LOCALITY": "HATI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6350,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496672,
      "TXT_PINCODE_LOCALITY": "BARRA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6350,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 496688,
      "TXT_PINCODE_LOCALITY": "KHADGAON",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6350,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497001,
      "TXT_PINCODE_LOCALITY": "AMBIKAPUR HO",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424432,
      "City District Name": "SURGUJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497101,
      "TXT_PINCODE_LOCALITY": "BATAULI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424432,
      "City District Name": "SURGUJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497102,
      "TXT_PINCODE_LOCALITY": "DHAURPUR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424432,
      "City District Name": "SURGUJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497105,
      "TXT_PINCODE_LOCALITY": "LAMGAON",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424432,
      "City District Name": "SURGUJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497107,
      "TXT_PINCODE_LOCALITY": "CHANDO",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424432,
      "City District Name": "SURGUJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497111,
      "TXT_PINCODE_LOCALITY": "SITAPUR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424432,
      "City District Name": "SURGUJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497112,
      "TXT_PINCODE_LOCALITY": "PHUNDERDIHARI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424432,
      "City District Name": "SURGUJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497113,
      "TXT_PINCODE_LOCALITY": "ULKIYA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424432,
      "City District Name": "SURGUJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497114,
      "TXT_PINCODE_LOCALITY": "PRATAPGARH (AMBIKAPUR)",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424432,
      "City District Name": "SURGUJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497116,
      "TXT_PINCODE_LOCALITY": "LAKHANPURA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424432,
      "City District Name": "SURGUJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497117,
      "TXT_PINCODE_LOCALITY": "KAKNA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424432,
      "City District Name": "SURGUJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497118,
      "TXT_PINCODE_LOCALITY": "RAJPUR (SARGUJA)",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424432,
      "City District Name": "SURGUJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497119,
      "TXT_PINCODE_LOCALITY": "BALRAMPUR",
      "NUM_STATE_CD": 821,
      "State Name": "CHATTISGARH.",
      "NUM_CITYDISTRICT_CD": 442089,
      "City District Name": "BALRAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497121,
      "TXT_PINCODE_LOCALITY": "BAGHIMA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424432,
      "City District Name": "SURGUJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497125,
      "TXT_PINCODE_LOCALITY": "NARBADAPUR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424432,
      "City District Name": "SURGUJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497127,
      "TXT_PINCODE_LOCALITY": "KAMLESHWARPUR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424432,
      "City District Name": "SURGUJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497210,
      "TXT_PINCODE_LOCALITY": "SHANKARGARH",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424430,
      "City District Name": "KONYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497216,
      "TXT_PINCODE_LOCALITY": "REHRA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424432,
      "City District Name": "SURGUJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497220,
      "TXT_PINCODE_LOCALITY": "RAMANUJGANJ",
      "NUM_STATE_CD": 829,
      "State Name": "CHATTISGARH",
      "NUM_CITYDISTRICT_CD": 444154,
      "City District Name": "BALRAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497222,
      "TXT_PINCODE_LOCALITY": "KUSMI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424432,
      "City District Name": "SURGUJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497223,
      "TXT_PINCODE_LOCALITY": "PRATAPPUR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424432,
      "City District Name": "SURGUJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497224,
      "TXT_PINCODE_LOCALITY": "JAWAHAR NAGAR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424432,
      "City District Name": "SURGUJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497225,
      "TXT_PINCODE_LOCALITY": "WADRAFNAGAR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424432,
      "City District Name": "SURGUJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497226,
      "TXT_PINCODE_LOCALITY": "BISHRAMPUR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 5800,
      "City District Name": "SURAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497229,
      "TXT_PINCODE_LOCALITY": "SURAIPUR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424432,
      "City District Name": "SURGUJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497231,
      "TXT_PINCODE_LOCALITY": "BHAIYATHAN",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424432,
      "City District Name": "SURGUJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497235,
      "TXT_PINCODE_LOCALITY": "TELGAON",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424432,
      "City District Name": "SURGUJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497331,
      "TXT_PINCODE_LOCALITY": "PATNA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424465,
      "City District Name": "KOREA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497333,
      "TXT_PINCODE_LOCALITY": "RAMANUJ NAGAR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424432,
      "City District Name": "SURGUJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497335,
      "TXT_PINCODE_LOCALITY": "BAIKUNTHPUR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 425515,
      "City District Name": "KORIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497339,
      "TXT_PINCODE_LOCALITY": "CHURCHA COLLY",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 425515,
      "City District Name": "KORIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497346,
      "TXT_PINCODE_LOCALITY": "SONHAT",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424430,
      "City District Name": "KONYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497437,
      "TXT_PINCODE_LOCALITY": "SURGUJA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424432,
      "City District Name": "SURGUJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497442,
      "TXT_PINCODE_LOCALITY": "MANENDRAGARH - 2940",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424430,
      "City District Name": "KONYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497445,
      "TXT_PINCODE_LOCALITY": "SALAR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 5516,
      "City District Name": "SARANGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497446,
      "TXT_PINCODE_LOCALITY": "JHAGRAKHAND COLLIERY",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424432,
      "City District Name": "SURGUJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497447,
      "TXT_PINCODE_LOCALITY": "JHAGRAKHAND COLLIERY WEST",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424432,
      "City District Name": "SURGUJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497448,
      "TXT_PINCODE_LOCALITY": "JHAGRAKHAND COLLIERY SOUTH",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424432,
      "City District Name": "SURGUJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497449,
      "TXT_PINCODE_LOCALITY": "CHIRIMIRI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424465,
      "City District Name": "KOREA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497450,
      "TXT_PINCODE_LOCALITY": "KHADGAWAN",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 425515,
      "City District Name": "KORIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497451,
      "TXT_PINCODE_LOCALITY": "HALDIBADI (CHIRIMIRI)",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424430,
      "City District Name": "KONYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497553,
      "TXT_PINCODE_LOCALITY": "KURASIA COLLIERY",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424430,
      "City District Name": "KONYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497555,
      "TXT_PINCODE_LOCALITY": "GODRI PARA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424432,
      "City District Name": "SURGUJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497557,
      "TXT_PINCODE_LOCALITY": "SONAWA NI COLLERY",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424432,
      "City District Name": "SURGUJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497559,
      "TXT_PINCODE_LOCALITY": "KOREA COLLIERY (SURGUJA)",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424432,
      "City District Name": "SURGUJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497773,
      "TXT_PINCODE_LOCALITY": "CHIRIMIRI COLLIERRY",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424432,
      "City District Name": "SURGUJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 497778,
      "TXT_PINCODE_LOCALITY": "JANAKPUR",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 424465,
      "City District Name": "KOREA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 499115,
      "TXT_PINCODE_LOCALITY": "SUNDRA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2284,
      "City District Name": "BALOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 443901,
      "TXT_PINCODE_LOCALITY": "VITTHALAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444001,
      "TXT_PINCODE_LOCALITY": "AKOLA TAJNA PETH",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 528,
      "City District Name": "AKOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444002,
      "TXT_PINCODE_LOCALITY": "AKOLA CITY S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 528,
      "City District Name": "AKOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444003,
      "TXT_PINCODE_LOCALITY": "ASHOK NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 528,
      "City District Name": "AKOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444004,
      "TXT_PINCODE_LOCALITY": "GANDHI NAGAR (AKOLA)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 528,
      "City District Name": "AKOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444005,
      "TXT_PINCODE_LOCALITY": "VANASADA (AKOLA)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 528,
      "City District Name": "AKOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444006,
      "TXT_PINCODE_LOCALITY": "GANDHI GRAM",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 528,
      "City District Name": "AKOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444007,
      "TXT_PINCODE_LOCALITY": "BAPORI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4624,
      "City District Name": "MURTIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444101,
      "TXT_PINCODE_LOCALITY": "CHOHOTTA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 528,
      "City District Name": "AKOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444102,
      "TXT_PINCODE_LOCALITY": "BORGAON MANJU",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 528,
      "City District Name": "AKOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444103,
      "TXT_PINCODE_LOCALITY": "HIWARKHED RUPRAO",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 528,
      "City District Name": "AKOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444104,
      "TXT_PINCODE_LOCALITY": "KRISHNAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 528,
      "City District Name": "AKOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444105,
      "TXT_PINCODE_LOCALITY": "KARANJA (AKOLA)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1700,
      "City District Name": "WASHIM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444106,
      "TXT_PINCODE_LOCALITY": "MANA (AKOLA)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 528,
      "City District Name": "AKOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444107,
      "TXT_PINCODE_LOCALITY": "DURGWADA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 528,
      "City District Name": "AKOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444108,
      "TXT_PINCODE_LOCALITY": "TELHARA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 528,
      "City District Name": "AKOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444109,
      "TXT_PINCODE_LOCALITY": "AKOLA BAZAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 528,
      "City District Name": "AKOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444110,
      "TXT_PINCODE_LOCALITY": "KAMARGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1700,
      "City District Name": "WASHIM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444111,
      "TXT_PINCODE_LOCALITY": "DAHIHANDA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 528,
      "City District Name": "AKOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444112,
      "TXT_PINCODE_LOCALITY": "AKOLA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 528,
      "City District Name": "AKOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444115,
      "TXT_PINCODE_LOCALITY": "KUVUM",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 528,
      "City District Name": "AKOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444117,
      "TXT_PINCODE_LOCALITY": "MUNDGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 528,
      "City District Name": "AKOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444118,
      "TXT_PINCODE_LOCALITY": "AKOLA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 528,
      "City District Name": "AKOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444120,
      "TXT_PINCODE_LOCALITY": "DHULE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 544,
      "City District Name": "DHULE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444121,
      "TXT_PINCODE_LOCALITY": "UMRA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444122,
      "TXT_PINCODE_LOCALITY": "AKOLA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 528,
      "City District Name": "AKOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444123,
      "TXT_PINCODE_LOCALITY": "KAPSHI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 528,
      "City District Name": "AKOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444124,
      "TXT_PINCODE_LOCALITY": "SAVARA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 528,
      "City District Name": "AKOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444125,
      "TXT_PINCODE_LOCALITY": "AKOLA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 528,
      "City District Name": "AKOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444126,
      "TXT_PINCODE_LOCALITY": "ADGAON B K",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 528,
      "City District Name": "AKOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444129,
      "TXT_PINCODE_LOCALITY": "BALHADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442870,
      "City District Name": "SANGRAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444150,
      "TXT_PINCODE_LOCALITY": "KANKADAJODI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444171,
      "TXT_PINCODE_LOCALITY": "SAWARPANI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 2779,
      "City District Name": "CHIKHALDARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444178,
      "TXT_PINCODE_LOCALITY": "AMRAVATI S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444200,
      "TXT_PINCODE_LOCALITY": "MAHAKAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 580,
      "City District Name": "WARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444201,
      "TXT_PINCODE_LOCALITY": "PATURDA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444202,
      "TXT_PINCODE_LOCALITY": "BODKHA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 537,
      "City District Name": "BULDHANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444203,
      "TXT_PINCODE_LOCALITY": "SHEGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444204,
      "TXT_PINCODE_LOCALITY": "SONALA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444208,
      "TXT_PINCODE_LOCALITY": "WAKODI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 5868,
      "City District Name": "TELHARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444260,
      "TXT_PINCODE_LOCALITY": "DHUNDE SHIVNI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1786,
      "City District Name": "GADCHIROLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444301,
      "TXT_PINCODE_LOCALITY": "AMRAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444302,
      "TXT_PINCODE_LOCALITY": "BALPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 528,
      "City District Name": "AKOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444303,
      "TXT_PINCODE_LOCALITY": "KHAMGAON.",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 523,
      "City District Name": "AURANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444304,
      "TXT_PINCODE_LOCALITY": "MATERGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444305,
      "TXT_PINCODE_LOCALITY": "CHITODA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444306,
      "TXT_PINCODE_LOCALITY": "PIMPALGAON RAJA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444307,
      "TXT_PINCODE_LOCALITY": "UNDRI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444308,
      "TXT_PINCODE_LOCALITY": "JALAMB",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444311,
      "TXT_PINCODE_LOCALITY": "NIMBHAZA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 528,
      "City District Name": "AKOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444312,
      "TXT_PINCODE_LOCALITY": "G S COLLEGE KHAMGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444363,
      "TXT_PINCODE_LOCALITY": "ANRAJ",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444401,
      "TXT_PINCODE_LOCALITY": "BARSI TAKLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 528,
      "City District Name": "AKOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444402,
      "TXT_PINCODE_LOCALITY": "SHELU BAZAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 528,
      "City District Name": "AKOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444403,
      "TXT_PINCODE_LOCALITY": "MANGRUKPIR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1700,
      "City District Name": "WASHIM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444404,
      "TXT_PINCODE_LOCALITY": "MANORA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1700,
      "City District Name": "WASHIM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444405,
      "TXT_PINCODE_LOCALITY": "MAHAN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 528,
      "City District Name": "AKOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444407,
      "TXT_PINCODE_LOCALITY": "PINJAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 528,
      "City District Name": "AKOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444408,
      "TXT_PINCODE_LOCALITY": "GIROLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444409,
      "TXT_PINCODE_LOCALITY": "ASEGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 528,
      "City District Name": "AKOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444490,
      "TXT_PINCODE_LOCALITY": "TEMBHURNI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442836,
      "City District Name": "CHANDUR RAILWAY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444501,
      "TXT_PINCODE_LOCALITY": "PATUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 528,
      "City District Name": "AKOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444502,
      "TXT_PINCODE_LOCALITY": "VADEGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 528,
      "City District Name": "AKOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444503,
      "TXT_PINCODE_LOCALITY": "MALEGAON (AKOLA)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 528,
      "City District Name": "AKOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444504,
      "TXT_PINCODE_LOCALITY": "SIZPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 528,
      "City District Name": "AKOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444505,
      "TXT_PINCODE_LOCALITY": "WASHIM MKT YARD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 528,
      "City District Name": "AKOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444506,
      "TXT_PINCODE_LOCALITY": "RISOD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1700,
      "City District Name": "WASHIM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444507,
      "TXT_PINCODE_LOCALITY": "ANSING",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1700,
      "City District Name": "WASHIM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444508,
      "TXT_PINCODE_LOCALITY": "AKOLA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 528,
      "City District Name": "AKOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444510,
      "TXT_PINCODE_LOCALITY": "RITHAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1700,
      "City District Name": "WASHIM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444511,
      "TXT_PINCODE_LOCALITY": "ALEGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 528,
      "City District Name": "AKOLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444512,
      "TXT_PINCODE_LOCALITY": "UMARWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 555,
      "City District Name": "MALEGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444586,
      "TXT_PINCODE_LOCALITY": "EKALASPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 5381,
      "City District Name": "RISOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444601,
      "TXT_PINCODE_LOCALITY": "AMRAOTI BHAJIBAZAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444602,
      "TXT_PINCODE_LOCALITY": "RAHATGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444603,
      "TXT_PINCODE_LOCALITY": "SHIVAJINAGAR HAMRAOTI)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444604,
      "TXT_PINCODE_LOCALITY": "VIDYALATA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444605,
      "TXT_PINCODE_LOCALITY": "SALURNA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444606,
      "TXT_PINCODE_LOCALITY": "CONGRESS NAGAR S.O (AMRAVATI)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444607,
      "TXT_PINCODE_LOCALITY": "GOPAL NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444608,
      "TXT_PINCODE_LOCALITY": "SHRIHARI NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444609,
      "TXT_PINCODE_LOCALITY": "GORALA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4563,
      "City District Name": "MORSHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444700,
      "TXT_PINCODE_LOCALITY": "SASAN BK.",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1558,
      "City District Name": "DARYAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444701,
      "TXT_PINCODE_LOCALITY": "HIWARA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444702,
      "TXT_PINCODE_LOCALITY": "DHARNI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444703,
      "TXT_PINCODE_LOCALITY": "TAPOVAN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1700,
      "City District Name": "WASHIM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444704,
      "TXT_PINCODE_LOCALITY": "RITHAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444705,
      "TXT_PINCODE_LOCALITY": "SURJI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444706,
      "TXT_PINCODE_LOCALITY": "JAINPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444707,
      "TXT_PINCODE_LOCALITY": "DHAMANGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444708,
      "TXT_PINCODE_LOCALITY": "NANDGAON KAZI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444709,
      "TXT_PINCODE_LOCALITY": "GADHI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444710,
      "TXT_PINCODE_LOCALITY": "TALEGAON DASHASAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444711,
      "TXT_PINCODE_LOCALITY": "MANGRUL DASTAGIR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444712,
      "TXT_PINCODE_LOCALITY": "DANGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444713,
      "TXT_PINCODE_LOCALITY": "AMRAVATI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444716,
      "TXT_PINCODE_LOCALITY": "AMRAVATI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444717,
      "TXT_PINCODE_LOCALITY": "AMRAVATI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444718,
      "TXT_PINCODE_LOCALITY": "BELORA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444719,
      "TXT_PINCODE_LOCALITY": "AMRAVATI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444720,
      "TXT_PINCODE_LOCALITY": "BRAMANANWADA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444721,
      "TXT_PINCODE_LOCALITY": "AMRAVATI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444723,
      "TXT_PINCODE_LOCALITY": "SIZASGAON BAND",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444727,
      "TXT_PINCODE_LOCALITY": "AMRAVATI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444728,
      "TXT_PINCODE_LOCALITY": "SURJI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444801,
      "TXT_PINCODE_LOCALITY": "PUSDA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444802,
      "TXT_PINCODE_LOCALITY": "DARAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444803,
      "TXT_PINCODE_LOCALITY": "DARYAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444804,
      "TXT_PINCODE_LOCALITY": "PURNANAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444805,
      "TXT_PINCODE_LOCALITY": "PARATWADA H O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444806,
      "TXT_PINCODE_LOCALITY": "ASEGAON (PARATWADA H O)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444807,
      "TXT_PINCODE_LOCALITY": "CHIKHALDARA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444808,
      "TXT_PINCODE_LOCALITY": "PATHAOT",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444809,
      "TXT_PINCODE_LOCALITY": "KARAIGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444810,
      "TXT_PINCODE_LOCALITY": "SHIRASGAON KASABA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444811,
      "TXT_PINCODE_LOCALITY": "AMRAVATI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444812,
      "TXT_PINCODE_LOCALITY": "BEHODA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444813,
      "TXT_PINCODE_LOCALITY": "SEMADOH",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444814,
      "TXT_PINCODE_LOCALITY": "AMRAVATI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444815,
      "TXT_PINCODE_LOCALITY": "AMRAVATI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444816,
      "TXT_PINCODE_LOCALITY": "AMRAVATI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444818,
      "TXT_PINCODE_LOCALITY": "AMRAVATI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444819,
      "TXT_PINCODE_LOCALITY": "AMRAVATI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444821,
      "TXT_PINCODE_LOCALITY": "SHIRALA (AMRAOTI)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444825,
      "TXT_PINCODE_LOCALITY": "ASHRA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444826,
      "TXT_PINCODE_LOCALITY": "AMRAVATI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444827,
      "TXT_PINCODE_LOCALITY": "ASEGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444832,
      "TXT_PINCODE_LOCALITY": "HARTOTI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442834,
      "City District Name": "BHATKULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444901,
      "TXT_PINCODE_LOCALITY": "NANDGAON PETH",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444902,
      "TXT_PINCODE_LOCALITY": "GURUKUNJ ASHRAM",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444903,
      "TXT_PINCODE_LOCALITY": "TLOSA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 535,
      "City District Name": "BHANDARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444904,
      "TXT_PINCODE_LOCALITY": "CHANDUR(AMRAVATI)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444905,
      "TXT_PINCODE_LOCALITY": "MORSHI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444906,
      "TXT_PINCODE_LOCALITY": "LONI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444907,
      "TXT_PINCODE_LOCALITY": "SHENDURJANAGHAT",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444908,
      "TXT_PINCODE_LOCALITY": "JARUD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444909,
      "TXT_PINCODE_LOCALITY": "KURHA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444910,
      "TXT_PINCODE_LOCALITY": "AMBODE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1700,
      "City District Name": "WASHIM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444914,
      "TXT_PINCODE_LOCALITY": "AMRAVATI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444915,
      "TXT_PINCODE_LOCALITY": "YAVALI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444921,
      "TXT_PINCODE_LOCALITY": "ROHAN KHEDA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 149,
      "City District Name": "AMRAVATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444923,
      "TXT_PINCODE_LOCALITY": "SHENDANI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442862,
      "City District Name": "NANDGAON-KHANDESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 444999,
      "TXT_PINCODE_LOCALITY": "KHANAPUR BK.",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1456,
      "City District Name": "AKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445001,
      "TXT_PINCODE_LOCALITY": "YEOTMAL AWDHUTWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445002,
      "TXT_PINCODE_LOCALITY": "UMARSARA - 4896",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445003,
      "TXT_PINCODE_LOCALITY": "YAVATMAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445101,
      "TXT_PINCODE_LOCALITY": "BABHUIGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1050,
      "City District Name": "HINGOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445102,
      "TXT_PINCODE_LOCALITY": "NERPARSOPANT",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445103,
      "TXT_PINCODE_LOCALITY": "ARNI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445105,
      "TXT_PINCODE_LOCALITY": "BHAM (RAJA)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445106,
      "TXT_PINCODE_LOCALITY": "SADI SADOBA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445109,
      "TXT_PINCODE_LOCALITY": "AKOLA BAZAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445110,
      "TXT_PINCODE_LOCALITY": "MAHAGAON KASBA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445115,
      "TXT_PINCODE_LOCALITY": "SAKUR.",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445116,
      "TXT_PINCODE_LOCALITY": "PALODI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 5223,
      "City District Name": "PUSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445201,
      "TXT_PINCODE_LOCALITY": "BORI ARAB",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445202,
      "TXT_PINCODE_LOCALITY": "DARWHA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445203,
      "TXT_PINCODE_LOCALITY": "DIGRAS",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445204,
      "TXT_PINCODE_LOCALITY": "POPHALI (YEOTMAL)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445205,
      "TXT_PINCODE_LOCALITY": "MAHAGAON (YEOTMAL)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445206,
      "TXT_PINCODE_LOCALITY": "VIDUL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445207,
      "TXT_PINCODE_LOCALITY": "DHANKI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445208,
      "TXT_PINCODE_LOCALITY": "YAVATMAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445209,
      "TXT_PINCODE_LOCALITY": "ISPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445210,
      "TXT_PINCODE_LOCALITY": "LOHI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445211,
      "TXT_PINCODE_LOCALITY": "MULAWA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445212,
      "TXT_PINCODE_LOCALITY": "YAVATMAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445213,
      "TXT_PINCODE_LOCALITY": "POPHALI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445214,
      "TXT_PINCODE_LOCALITY": "YAVATMAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445215,
      "TXT_PINCODE_LOCALITY": "SAHAKARI SMT GIRANI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445216,
      "TXT_PINCODE_LOCALITY": "FUL SINGH NAIK",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445217,
      "TXT_PINCODE_LOCALITY": "KHANDALA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445218,
      "TXT_PINCODE_LOCALITY": "YAVATMAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445230,
      "TXT_PINCODE_LOCALITY": "NINGNOOR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445232,
      "TXT_PINCODE_LOCALITY": "BRAHMANGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445301,
      "TXT_PINCODE_LOCALITY": "GHATNAJI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445302,
      "TXT_PINCODE_LOCALITY": "PANDHAR KAWDA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445303,
      "TXT_PINCODE_LOCALITY": "MANEGAON ROAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445304,
      "TXT_PINCODE_LOCALITY": "WANI BAZAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445305,
      "TXT_PINCODE_LOCALITY": "PATANBOR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445306,
      "TXT_PINCODE_LOCALITY": "PARWA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445307,
      "TXT_PINCODE_LOCALITY": "SINDOLA MINES",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445308,
      "TXT_PINCODE_LOCALITY": "WADKI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445309,
      "TXT_PINCODE_LOCALITY": "RAJUR (YEOTMAL)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445310,
      "TXT_PINCODE_LOCALITY": "SHIRPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 570,
      "City District Name": "SHIRPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445311,
      "TXT_PINCODE_LOCALITY": "UMRI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 580,
      "City District Name": "WARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445323,
      "TXT_PINCODE_LOCALITY": "MOHADA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445324,
      "TXT_PINCODE_LOCALITY": "YAVATMAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445401,
      "TXT_PINCODE_LOCALITY": "KALAMB (YEOTMAL)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445402,
      "TXT_PINCODE_LOCALITY": "RALEGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 581,
      "City District Name": "YAVATMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445403,
      "TXT_PINCODE_LOCALITY": "SHIVPURI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 3676,
      "City District Name": "KALAMB",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445501,
      "TXT_PINCODE_LOCALITY": "WANDLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 3676,
      "City District Name": "KALAMB",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445510,
      "TXT_PINCODE_LOCALITY": "MAHALUNGI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 424287,
      "City District Name": "ARNI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445520,
      "TXT_PINCODE_LOCALITY": "NAVIN WALTUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 6051,
      "City District Name": "UMARKHED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445530,
      "TXT_PINCODE_LOCALITY": "TEMBHI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442846,
      "City District Name": "KELAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445804,
      "TXT_PINCODE_LOCALITY": "KRISHNANPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442879,
      "City District Name": "ZARI-JAMANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 445807,
      "TXT_PINCODE_LOCALITY": "YENAK.",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1108,
      "City District Name": "WANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 446104,
      "TXT_PINCODE_LOCALITY": "WARAMBHA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1562,
      "City District Name": "KAMPTEE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 446204,
      "TXT_PINCODE_LOCALITY": "MALKAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 424388,
      "City District Name": "MAHAGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 447701,
      "TXT_PINCODE_LOCALITY": "BHAGI (RITHI)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442829,
      "City District Name": "ARJUNI MORGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 448104,
      "TXT_PINCODE_LOCALITY": "ARVI (LAHAN)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442871,
      "City District Name": "SELOO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 448449,
      "TXT_PINCODE_LOCALITY": "BHAMODI - 656",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 597,
      "City District Name": "CHHINDWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 520001,
      "TXT_PINCODE_LOCALITY": "VIJAYADA RAILWAY",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 520002,
      "TXT_PINCODE_LOCALITY": "BUCKINGHAMPET H O (VJA)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 520003,
      "TXT_PINCODE_LOCALITY": "DURGAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 520004,
      "TXT_PINCODE_LOCALITY": "MARUTHINAGAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 520005,
      "TXT_PINCODE_LOCALITY": "GUNADALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 520006,
      "TXT_PINCODE_LOCALITY": "PATAMATA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 520007,
      "TXT_PINCODE_LOCALITY": "SIRINAGAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 520008,
      "TXT_PINCODE_LOCALITY": "POLYTECHNIC (VJA)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 520009,
      "TXT_PINCODE_LOCALITY": "CHITTINAGAR (VJA)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 520010,
      "TXT_PINCODE_LOCALITY": "PATAMATA (VJA)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 520011,
      "TXT_PINCODE_LOCALITY": "SATYANARAYANAPURAM (VJA)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 520012,
      "TXT_PINCODE_LOCALITY": "KAMAKOTI NAGAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 520013,
      "TXT_PINCODE_LOCALITY": "NEHRUNAGAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 520014,
      "TXT_PINCODE_LOCALITY": "PATAMATA LANKA (VJA)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 520015,
      "TXT_PINCODE_LOCALITY": "AJIT NAGAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 520016,
      "TXT_PINCODE_LOCALITY": "PURNANDAM POT",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 520040,
      "TXT_PINCODE_LOCALITY": "BHIMANNAVARIPETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442352,
      "City District Name": "VIJAYAWADA (URBAN)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 520050,
      "TXT_PINCODE_LOCALITY": "DEP VIJAYAWADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 520301,
      "TXT_PINCODE_LOCALITY": "ENAMADALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442244,
      "City District Name": "YEDDANA PUDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 520306,
      "TXT_PINCODE_LOCALITY": "KALIGIRI KONDURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442253,
      "City District Name": "SANGAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521001,
      "TXT_PINCODE_LOCALITY": "CHINNA PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521002,
      "TXT_PINCODE_LOCALITY": "KEERPANDHARPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521003,
      "TXT_PINCODE_LOCALITY": "MACHILIPATNAM PORT",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521004,
      "TXT_PINCODE_LOCALITY": "KRISHNA UNIVERSITY",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4274,
      "City District Name": "MACHILIPATNAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521101,
      "TXT_PINCODE_LOCALITY": "ROYA NAGAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521102,
      "TXT_PINCODE_LOCALITY": "BACON FACTORY",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521104,
      "TXT_PINCODE_LOCALITY": "NADAMANURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521105,
      "TXT_PINCODE_LOCALITY": "MEDICHERLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521106,
      "TXT_PINCODE_LOCALITY": "KAKULAPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521107,
      "TXT_PINCODE_LOCALITY": "MUSTABADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521108,
      "TXT_PINCODE_LOCALITY": "PRASADAMPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521109,
      "TXT_PINCODE_LOCALITY": "AMPAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521110,
      "TXT_PINCODE_LOCALITY": "REMALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521111,
      "TXT_PINCODE_LOCALITY": "GOLLA PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521112,
      "TXT_PINCODE_LOCALITY": "KRISHNA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521113,
      "TXT_PINCODE_LOCALITY": "KRISHNA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521114,
      "TXT_PINCODE_LOCALITY": "ARUGOLANU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521116,
      "TXT_PINCODE_LOCALITY": "NANGIGADDA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521117,
      "TXT_PINCODE_LOCALITY": "KRISHNA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521118,
      "TXT_PINCODE_LOCALITY": "BHAVADEVARA PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521120,
      "TXT_PINCODE_LOCALITY": "NAGAYALANKA EAST",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521121,
      "TXT_PINCODE_LOCALITY": "PULIGADDA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521122,
      "TXT_PINCODE_LOCALITY": "VISAWANADHA PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521123,
      "TXT_PINCODE_LOCALITY": "GANDHI KESHTRAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521124,
      "TXT_PINCODE_LOCALITY": "V KOTHAPALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521125,
      "TXT_PINCODE_LOCALITY": "MOPIDEVI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521126,
      "TXT_PINCODE_LOCALITY": "CHALLAPALLI.",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521128,
      "TXT_PINCODE_LOCALITY": "CHIRUVOLU LANKA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521129,
      "TXT_PINCODE_LOCALITY": "KRISHNA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521130,
      "TXT_PINCODE_LOCALITY": "PEDA KALLE PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521131,
      "TXT_PINCODE_LOCALITY": "LAXMIPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521132,
      "TXT_PINCODE_LOCALITY": "SRIKAKULAM (K)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521133,
      "TXT_PINCODE_LOCALITY": "GHANTASALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521134,
      "TXT_PINCODE_LOCALITY": "SRIKAKULAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521135,
      "TXT_PINCODE_LOCALITY": "MOWA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521136,
      "TXT_PINCODE_LOCALITY": "KUCHIPUDI CENTRE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521137,
      "TXT_PINCODE_LOCALITY": "PORANKI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521138,
      "TXT_PINCODE_LOCALITY": "BHATLA PENU MARRU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521139,
      "TXT_PINCODE_LOCALITY": "PENAMALURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521140,
      "TXT_PINCODE_LOCALITY": "KRISHNA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521141,
      "TXT_PINCODE_LOCALITY": "CHINA MUTHEVI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521142,
      "TXT_PINCODE_LOCALITY": "PEDAMUTHEVI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521144,
      "TXT_PINCODE_LOCALITY": "KRISHNA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521148,
      "TXT_PINCODE_LOCALITY": "ELAMMARRU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521149,
      "TXT_PINCODE_LOCALITY": "GUDUR (KRISHNA)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521150,
      "TXT_PINCODE_LOCALITY": "KAZA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521151,
      "TXT_PINCODE_LOCALITY": "KANKI PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521153,
      "TXT_PINCODE_LOCALITY": "KOLAVENNU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521155,
      "TXT_PINCODE_LOCALITY": "KRISHNA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521156,
      "TXT_PINCODE_LOCALITY": "NIDUMOLU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521157,
      "TXT_PINCODE_LOCALITY": "PAMARRU (K)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521158,
      "TXT_PINCODE_LOCALITY": "JUJHAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521159,
      "TXT_PINCODE_LOCALITY": "PASUMARRU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521160,
      "TXT_PINCODE_LOCALITY": "RAYAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521161,
      "TXT_PINCODE_LOCALITY": "MALLAVOLU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521162,
      "TXT_PINCODE_LOCALITY": "GUDURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521163,
      "TXT_PINCODE_LOCALITY": "THOTLA VALLUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521164,
      "TXT_PINCODE_LOCALITY": "KATUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521165,
      "TXT_PINCODE_LOCALITY": "VUYYURU EAST",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521166,
      "TXT_PINCODE_LOCALITY": "KALVA PAMULA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521167,
      "TXT_PINCODE_LOCALITY": "CHAGANTIPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521168,
      "TXT_PINCODE_LOCALITY": "VALLUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521169,
      "TXT_PINCODE_LOCALITY": "PENAMAKURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521170,
      "TXT_PINCODE_LOCALITY": "JAYANTHI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521171,
      "TXT_PINCODE_LOCALITY": "KRISHNA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521175,
      "TXT_PINCODE_LOCALITY": "JGP BAZAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521176,
      "TXT_PINCODE_LOCALITY": "ANUMANCHIPALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521177,
      "TXT_PINCODE_LOCALITY": "BALUSUPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521178,
      "TXT_PINCODE_LOCALITY": "CHILLA KALLU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521179,
      "TXT_PINCODE_LOCALITY": "BANDI PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521180,
      "TXT_PINCODE_LOCALITY": "CHEVITIKALLU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521181,
      "TXT_PINCODE_LOCALITY": "JUZZURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521182,
      "TXT_PINCODE_LOCALITY": "CHANDARLA PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521183,
      "TXT_PINCODE_LOCALITY": "THOTARAVULA PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521184,
      "TXT_PINCODE_LOCALITY": "MULAKALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521185,
      "TXT_PINCODE_LOCALITY": "RAMI REDDI PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521186,
      "TXT_PINCODE_LOCALITY": "GUDIMETLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521187,
      "TXT_PINCODE_LOCALITY": "KONAYA PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521190,
      "TXT_PINCODE_LOCALITY": "PENU GANCHI PROLU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521191,
      "TXT_PINCODE_LOCALITY": "ANIGANDLAPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521192,
      "TXT_PINCODE_LOCALITY": "GUMMADI DURRU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521194,
      "TXT_PINCODE_LOCALITY": "MUNDLAPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521195,
      "TXT_PINCODE_LOCALITY": "BHIMAVARAM (CHILLAKALLU)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521196,
      "TXT_PINCODE_LOCALITY": "GOWRAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521197,
      "TXT_PINCODE_LOCALITY": "KONAKANCHI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521198,
      "TXT_PINCODE_LOCALITY": "MUCHINTALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521201,
      "TXT_PINCODE_LOCALITY": "NUZVID",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6204,
      "City District Name": "VIJAYAWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521202,
      "TXT_PINCODE_LOCALITY": "DUMDUM GARDEN (NUZVID)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521205,
      "TXT_PINCODE_LOCALITY": "GOPAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521206,
      "TXT_PINCODE_LOCALITY": "KATERENI PADU (NZD)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521207,
      "TXT_PINCODE_LOCALITY": "CHINTALAVALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521210,
      "TXT_PINCODE_LOCALITY": "REMALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442243,
      "City District Name": "BAPULAPADU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521211,
      "TXT_PINCODE_LOCALITY": "AUKIRIPALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521212,
      "TXT_PINCODE_LOCALITY": "NUNNA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521213,
      "TXT_PINCODE_LOCALITY": "CHITTAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521214,
      "TXT_PINCODE_LOCALITY": "T SANUBANDA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521215,
      "TXT_PINCODE_LOCALITY": "TELLADEVARA PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521216,
      "TXT_PINCODE_LOCALITY": "CHEKKA PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521218,
      "TXT_PINCODE_LOCALITY": "DIGAVALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521219,
      "TXT_PINCODE_LOCALITY": "KONDA PARVA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521221,
      "TXT_PINCODE_LOCALITY": "CHATRAI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521223,
      "TXT_PINCODE_LOCALITY": "EDARA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521224,
      "TXT_PINCODE_LOCALITY": "VATTIGUDI PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521225,
      "TXT_PINCODE_LOCALITY": "GOLLA PUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521226,
      "TXT_PINCODE_LOCALITY": "IBRAHIMPATNAM (KRISHNA)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521227,
      "TXT_PINCODE_LOCALITY": "PUTRELA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521228,
      "TXT_PINCODE_LOCALITY": "KATTUBADIPALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521229,
      "TXT_PINCODE_LOCALITY": "G KONDURU S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521230,
      "TXT_PINCODE_LOCALITY": "NAGULURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521231,
      "TXT_PINCODE_LOCALITY": "BHAWAN PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521235,
      "TXT_PINCODE_LOCALITY": "MALLELA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521236,
      "TXT_PINCODE_LOCALITY": "ERRAMANDU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521238,
      "TXT_PINCODE_LOCALITY": "ROLU PUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521239,
      "TXT_PINCODE_LOCALITY": "VAMAKUNTLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521241,
      "TXT_PINCODE_LOCALITY": "RAYANA PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521245,
      "TXT_PINCODE_LOCALITY": "PEDAOGIRALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521246,
      "TXT_PINCODE_LOCALITY": "KAPILESWARA PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521247,
      "TXT_PINCODE_LOCALITY": "MADHURAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521250,
      "TXT_PINCODE_LOCALITY": "CHORAGUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521251,
      "TXT_PINCODE_LOCALITY": "KRISHNA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521252,
      "TXT_PINCODE_LOCALITY": "ILLURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521254,
      "TXT_PINCODE_LOCALITY": "KRISHNAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521255,
      "TXT_PINCODE_LOCALITY": "KRISHNA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521256,
      "TXT_PINCODE_LOCALITY": "KANUMURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521260,
      "TXT_PINCODE_LOCALITY": "MANIKONDA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521261,
      "TXT_PINCODE_LOCALITY": "MUDUNURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521263,
      "TXT_PINCODE_LOCALITY": "VENKATRAPRAGEDA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521264,
      "TXT_PINCODE_LOCALITY": "VANAPAMULA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521265,
      "TXT_PINCODE_LOCALITY": "KRISHNA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521284,
      "TXT_PINCODE_LOCALITY": "ATKUR CANNAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521286,
      "TXT_PINCODE_LOCALITY": "PEDAUTA PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521288,
      "TXT_PINCODE_LOCALITY": "CHIRI VADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521291,
      "TXT_PINCODE_LOCALITY": "VELURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521292,
      "TXT_PINCODE_LOCALITY": "CHINAPURUPUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521301,
      "TXT_PINCODE_LOCALITY": "RAJENDRANAGAR COLONY",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521311,
      "TXT_PINCODE_LOCALITY": "INDUPALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521312,
      "TXT_PINCODE_LOCALITY": "PONUKUMADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521318,
      "TXT_PINCODE_LOCALITY": "YELAKURRU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521320,
      "TXT_PINCODE_LOCALITY": "KRUTHIVENNU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 3312,
      "City District Name": "GUDIVADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521321,
      "TXT_PINCODE_LOCALITY": "PEDAVIRIVADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521322,
      "TXT_PINCODE_LOCALITY": "GOLVE PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521323,
      "TXT_PINCODE_LOCALITY": "MOTURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521324,
      "TXT_PINCODE_LOCALITY": "BANTU MILL",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521325,
      "TXT_PINCODE_LOCALITY": "SATYANARAYANAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521326,
      "TXT_PINCODE_LOCALITY": "RUDRA PAKA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521327,
      "TXT_PINCODE_LOCALITY": "TAMRISA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521328,
      "TXT_PINCODE_LOCALITY": "ULLIPALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521329,
      "TXT_PINCODE_LOCALITY": "PEDAGONNUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521330,
      "TXT_PINCODE_LOCALITY": "CHANDRALA (ANGALURU)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521331,
      "TXT_PINCODE_LOCALITY": "VADLA MANNADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521332,
      "TXT_PINCODE_LOCALITY": "DOKI PARRU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521333,
      "TXT_PINCODE_LOCALITY": "KALLKLUR H O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521338,
      "TXT_PINCODE_LOCALITY": "AGRAHARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521339,
      "TXT_PINCODE_LOCALITY": "VENUTURU MILLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521340,
      "TXT_PINCODE_LOCALITY": "PALLEVADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521341,
      "TXT_PINCODE_LOCALITY": "BHAJABALA PATNAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521343,
      "TXT_PINCODE_LOCALITY": "KORUKOLLU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521344,
      "TXT_PINCODE_LOCALITY": "KALI DINDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521345,
      "TXT_PINCODE_LOCALITY": "MANDA VALLI STATION ROAD",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521349,
      "TXT_PINCODE_LOCALITY": "PASALAPUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442258,
      "City District Name": "MANDAVALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521356,
      "TXT_PINCODE_LOCALITY": "GUDLAVALLERU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521366,
      "TXT_PINCODE_LOCALITY": "PEDANA BAZAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521369,
      "TXT_PINCODE_LOCALITY": "MUNJULURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521370,
      "TXT_PINCODE_LOCALITY": "BRAHMA PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521372,
      "TXT_PINCODE_LOCALITY": "KRISHNA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521373,
      "TXT_PINCODE_LOCALITY": "SERI KALVA PUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521374,
      "TXT_PINCODE_LOCALITY": "SIDHANTAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521377,
      "TXT_PINCODE_LOCALITY": "KONIKI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521379,
      "TXT_PINCODE_LOCALITY": "TUMMALA PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521380,
      "TXT_PINCODE_LOCALITY": "DONDA PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521382,
      "TXT_PINCODE_LOCALITY": "KRUTTIVENNU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521384,
      "TXT_PINCODE_LOCALITY": "DANDI CANAPUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521390,
      "TXT_PINCODE_LOCALITY": "PEDAMADDALI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521391,
      "TXT_PINCODE_LOCALITY": "MALAPARRU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521397,
      "TXT_PINCODE_LOCALITY": "KALLA PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521399,
      "TXT_PINCODE_LOCALITY": "VARAHAPURA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521401,
      "TXT_PINCODE_LOCALITY": "PENU GOLANU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521402,
      "TXT_PINCODE_LOCALITY": "VATSAVAI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521403,
      "TXT_PINCODE_LOCALITY": "GAMPALAGUDEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521404,
      "TXT_PINCODE_LOCALITY": "GOSA VEEDU",
      "NUM_STATE_CD": 434,
      "State Name": "TELANGANA",
      "NUM_CITYDISTRICT_CD": 441346,
      "City District Name": "KHAMMAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521405,
      "TXT_PINCODE_LOCALITY": "GULLA PUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521417,
      "TXT_PINCODE_LOCALITY": "CHIMALA PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521418,
      "TXT_PINCODE_LOCALITY": "GANUGA PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521421,
      "TXT_PINCODE_LOCALITY": "KONDUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521424,
      "TXT_PINCODE_LOCALITY": "KRISHNA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521425,
      "TXT_PINCODE_LOCALITY": "RUDRAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521429,
      "TXT_PINCODE_LOCALITY": "KRISHNA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521431,
      "TXT_PINCODE_LOCALITY": "GANDEPALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521435,
      "TXT_PINCODE_LOCALITY": "PENDYALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521436,
      "TXT_PINCODE_LOCALITY": "KRISHNA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521440,
      "TXT_PINCODE_LOCALITY": "ATKUR KONDAPALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521443,
      "TXT_PINCODE_LOCALITY": "KAVULURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521444,
      "TXT_PINCODE_LOCALITY": "RAMAVARAPUMODI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442242,
      "City District Name": "BANTUMILLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521445,
      "TXT_PINCODE_LOCALITY": "CHANDRALA (MYLAVARAM)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521447,
      "TXT_PINCODE_LOCALITY": "KODUR (MVLAVARAM)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521448,
      "TXT_PINCODE_LOCALITY": "MORUSUMILLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521450,
      "TXT_PINCODE_LOCALITY": "PULLURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521451,
      "TXT_PINCODE_LOCALITY": "VELVADAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521452,
      "TXT_PINCODE_LOCALITY": "CHILUKURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521453,
      "TXT_PINCODE_LOCALITY": "VELAGALERU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521456,
      "TXT_PINCODE_LOCALITY": "VIJAYAWADA THERMAL STATIO",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521457,
      "TXT_PINCODE_LOCALITY": "MUKTYALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 521541,
      "TXT_PINCODE_LOCALITY": "KRISHNA.",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 425883,
      "City District Name": "VIJAYWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522001,
      "TXT_PINCODE_LOCALITY": "GUNTUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522002,
      "TXT_PINCODE_LOCALITY": "DONKA ROAD",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522003,
      "TXT_PINCODE_LOCALITY": "S K P TEMPLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522004,
      "TXT_PINCODE_LOCALITY": "KANNAVARI THOTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522005,
      "TXT_PINCODE_LOCALITY": "NALLAPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522006,
      "TXT_PINCODE_LOCALITY": "SRINIVASANAGAR COLONY",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522007,
      "TXT_PINCODE_LOCALITY": "KORITE PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522008,
      "TXT_PINCODE_LOCALITY": "LANKALA KODERU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522009,
      "TXT_PINCODE_LOCALITY": "PERECHERLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522010,
      "TXT_PINCODE_LOCALITY": "BUS STAND GUNTUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522011,
      "TXT_PINCODE_LOCALITY": "DURGAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6204,
      "City District Name": "VIJAYAWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522012,
      "TXT_PINCODE_LOCALITY": "KRISHNA.",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522013,
      "TXT_PINCODE_LOCALITY": "BUDAM PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522014,
      "TXT_PINCODE_LOCALITY": "ANANTAVARAPPADU GUNTUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522015,
      "TXT_PINCODE_LOCALITY": "ABBINENIGUNTA PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522016,
      "TXT_PINCODE_LOCALITY": "LEMALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522017,
      "TXT_PINCODE_LOCALITY": "ETKUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522018,
      "TXT_PINCODE_LOCALITY": "PONNEKALLU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522019,
      "TXT_PINCODE_LOCALITY": "PRATHI PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522020,
      "TXT_PINCODE_LOCALITY": "AMARAVATHI GUNTUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522021,
      "TXT_PINCODE_LOCALITY": "BANDARUPALLE (GTR)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522022,
      "TXT_PINCODE_LOCALITY": "KORENE PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522023,
      "TXT_PINCODE_LOCALITY": "RAVELA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522024,
      "TXT_PINCODE_LOCALITY": "NIDUMUKKALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522025,
      "TXT_PINCODE_LOCALITY": "ENDROYI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522026,
      "TXT_PINCODE_LOCALITY": "DHARANIKOTA (GTR)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522027,
      "TXT_PINCODE_LOCALITY": "HARI CHANDRAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522028,
      "TXT_PINCODE_LOCALITY": "NADIMPALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522033,
      "TXT_PINCODE_LOCALITY": "GUNTUR (R)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522034,
      "TXT_PINCODE_LOCALITY": "INDUSTRIAL ESTATE GTR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522035,
      "TXT_PINCODE_LOCALITY": "GOTANTLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522036,
      "TXT_PINCODE_LOCALITY": "GUNTUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522050,
      "TXT_PINCODE_LOCALITY": "DET GUNTUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522101,
      "TXT_PINCODE_LOCALITY": "RANGARAUTHOTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522102,
      "TXT_PINCODE_LOCALITY": "MARUPROLUVARIPALEM (R)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2332,
      "City District Name": "BAPATLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522110,
      "TXT_PINCODE_LOCALITY": "YAZALI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522111,
      "TXT_PINCODE_LOCALITY": "CHINTAYAPALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522112,
      "TXT_PINCODE_LOCALITY": "KAKUMANU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522113,
      "TXT_PINCODE_LOCALITY": "RAMNAGAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522114,
      "TXT_PINCODE_LOCALITY": "JILLELLAMUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522115,
      "TXT_PINCODE_LOCALITY": "RETURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522116,
      "TXT_PINCODE_LOCALITY": "NARASAYAPALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522117,
      "TXT_PINCODE_LOCALITY": "BUDDAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522118,
      "TXT_PINCODE_LOCALITY": "CHEERUKURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522120,
      "TXT_PINCODE_LOCALITY": "NANDI RAJU THOTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522121,
      "TXT_PINCODE_LOCALITY": "APPAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522122,
      "TXT_PINCODE_LOCALITY": "PERALI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522123,
      "TXT_PINCODE_LOCALITY": "NIDUBROLU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522124,
      "TXT_PINCODE_LOCALITY": "PONNUR BAZAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522125,
      "TXT_PINCODE_LOCALITY": "VALLABHARAO PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522126,
      "TXT_PINCODE_LOCALITY": "PANDRAPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522169,
      "TXT_PINCODE_LOCALITY": "GANAPAVARAM (GUNTUR)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522180,
      "TXT_PINCODE_LOCALITY": "KANCHIKA CHERLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424333,
      "City District Name": "KRISHNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522201,
      "TXT_PINCODE_LOCALITY": "GANDHI NAGAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522202,
      "TXT_PINCODE_LOCALITY": "MORRISPET",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522203,
      "TXT_PINCODE_LOCALITY": "GUNTUR S. O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522208,
      "TXT_PINCODE_LOCALITY": "BURRI PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522210,
      "TXT_PINCODE_LOCALITY": "MANCHALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522211,
      "TXT_PINCODE_LOCALITY": "ANGALA KUDURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522212,
      "TXT_PINCODE_LOCALITY": "CHEBROLE (BAPATLAL)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522213,
      "TXT_PINCODE_LOCALITY": "SANGAM JAGARLAMUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522214,
      "TXT_PINCODE_LOCALITY": "BRAHMANA KODUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522215,
      "TXT_PINCODE_LOCALITY": "MUTLURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522216,
      "TXT_PINCODE_LOCALITY": "SEKURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522217,
      "TXT_PINCODE_LOCALITY": "SELAPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522218,
      "TXT_PINCODE_LOCALITY": "VATTICHERUKURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522219,
      "TXT_PINCODE_LOCALITY": "VENKATAPALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522220,
      "TXT_PINCODE_LOCALITY": "SRI RANGA PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522221,
      "TXT_PINCODE_LOCALITY": "SUDDA PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522222,
      "TXT_PINCODE_LOCALITY": "NARAKODURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522223,
      "TXT_PINCODE_LOCALITY": "KOTHAREDDI PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522231,
      "TXT_PINCODE_LOCALITY": "CHIRUMAMILLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522232,
      "TXT_PINCODE_LOCALITY": "JAGGAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522233,
      "TXT_PINCODE_LOCALITY": "EDLA PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522234,
      "TXT_PINCODE_LOCALITY": "NADENDLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522235,
      "TXT_PINCODE_LOCALITY": "PUSULURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522236,
      "TXT_PINCODE_LOCALITY": "TADIKONDA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522237,
      "TXT_PINCODE_LOCALITY": "THULLURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522238,
      "TXT_PINCODE_LOCALITY": "TUBADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522239,
      "TXT_PINCODE_LOCALITY": "RAYAPUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522240,
      "TXT_PINCODE_LOCALITY": "PARIMI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522241,
      "TXT_PINCODE_LOCALITY": "DONDA PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522242,
      "TXT_PINCODE_LOCALITY": "VADDAMANU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522244,
      "TXT_PINCODE_LOCALITY": "NAGULAPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522246,
      "TXT_PINCODE_LOCALITY": "NEKKALLU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522247,
      "TXT_PINCODE_LOCALITY": "JALADI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522248,
      "TXT_PINCODE_LOCALITY": "NELAPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522252,
      "TXT_PINCODE_LOCALITY": "RAJA VOLU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522253,
      "TXT_PINCODE_LOCALITY": "NADIMPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522254,
      "TXT_PINCODE_LOCALITY": "KARUMURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522255,
      "TXT_PINCODE_LOCALITY": "PALLEKONA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522256,
      "TXT_PINCODE_LOCALITY": "BHATTI PROLU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522257,
      "TXT_PINCODE_LOCALITY": "VELLATUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522258,
      "TXT_PINCODE_LOCALITY": "DHULIPUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522259,
      "TXT_PINCODE_LOCALITY": "KANAGALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522260,
      "TXT_PINCODE_LOCALITY": "GUNTUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522261,
      "TXT_PINCODE_LOCALITY": "VEMUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522262,
      "TXT_PINCODE_LOCALITY": "PALLAPATLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522263,
      "TXT_PINCODE_LOCALITY": "ADVULA DIVI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522264,
      "TXT_PINCODE_LOCALITY": "SINGUPALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522265,
      "TXT_PINCODE_LOCALITY": "REPALLE H O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522266,
      "TXT_PINCODE_LOCALITY": "JAMPANI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522267,
      "TXT_PINCODE_LOCALITY": "PETERU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522268,
      "TXT_PINCODE_LOCALITY": "NAGARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522269,
      "TXT_PINCODE_LOCALITY": "GUNTUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522270,
      "TXT_PINCODE_LOCALITY": "AGRAHARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522271,
      "TXT_PINCODE_LOCALITY": "GANGADI PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522272,
      "TXT_PINCODE_LOCALITY": "POTHU MERAKA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522273,
      "TXT_PINCODE_LOCALITY": "KAITE PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522274,
      "TXT_PINCODE_LOCALITY": "CHATRAGADDA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522275,
      "TXT_PINCODE_LOCALITY": "THOTA PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522276,
      "TXT_PINCODE_LOCALITY": "DINDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522277,
      "TXT_PINCODE_LOCALITY": "GUNTUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522278,
      "TXT_PINCODE_LOCALITY": "VULLI PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522279,
      "TXT_PINCODE_LOCALITY": "BETHAPUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522280,
      "TXT_PINCODE_LOCALITY": "NALLURU PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522281,
      "TXT_PINCODE_LOCALITY": "GURUNADHAR NAGAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522282,
      "TXT_PINCODE_LOCALITY": "VOLERU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522290,
      "TXT_PINCODE_LOCALITY": "VALLABHAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522291,
      "TXT_PINCODE_LOCALITY": "GUNTUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522292,
      "TXT_PINCODE_LOCALITY": "TUMMULURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522293,
      "TXT_PINCODE_LOCALITY": "PIDA PARRU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522294,
      "TXT_PINCODE_LOCALITY": "MUNNANGI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522295,
      "TXT_PINCODE_LOCALITY": "GUNTUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522296,
      "TXT_PINCODE_LOCALITY": "IPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522297,
      "TXT_PINCODE_LOCALITY": "GUNTUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522298,
      "TXT_PINCODE_LOCALITY": "GUNTUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522299,
      "TXT_PINCODE_LOCALITY": "GUNTUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522301,
      "TXT_PINCODE_LOCALITY": "ANANTAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522302,
      "TXT_PINCODE_LOCALITY": "REVENDRA PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522303,
      "TXT_PINCODE_LOCALITY": "NUTAKKI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522304,
      "TXT_PINCODE_LOCALITY": "KOLLIPARA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522305,
      "TXT_PINCODE_LOCALITY": "PEDDA PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522306,
      "TXT_PINCODE_LOCALITY": "DANTALUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522307,
      "TXT_PINCODE_LOCALITY": "KOLAKALUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522308,
      "TXT_PINCODE_LOCALITY": "EMANI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522309,
      "TXT_PINCODE_LOCALITY": "CHERUKU PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522310,
      "TXT_PINCODE_LOCALITY": "APPI KATLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522311,
      "TXT_PINCODE_LOCALITY": "CHANDOLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522312,
      "TXT_PINCODE_LOCALITY": "TURIMELA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522313,
      "TXT_PINCODE_LOCALITY": "KUCHIPUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522314,
      "TXT_PINCODE_LOCALITY": "NIZAMPATNAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522315,
      "TXT_PINCODE_LOCALITY": "MACHAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522316,
      "TXT_PINCODE_LOCALITY": "PATCHALA TADI PARRU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522317,
      "TXT_PINCODE_LOCALITY": "STUARTPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522318,
      "TXT_PINCODE_LOCALITY": "T SUNDURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522319,
      "TXT_PINCODE_LOCALITY": "CHINAGADELAVARRU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522320,
      "TXT_PINCODE_LOCALITY": "MANDURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522321,
      "TXT_PINCODE_LOCALITY": "MODUKURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522322,
      "TXT_PINCODE_LOCALITY": "MULPURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522323,
      "TXT_PINCODE_LOCALITY": "KRAPA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522324,
      "TXT_PINCODE_LOCALITY": "KOLLURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522325,
      "TXT_PINCODE_LOCALITY": "AMRUTALUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522326,
      "TXT_PINCODE_LOCALITY": "GOVADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522327,
      "TXT_PINCODE_LOCALITY": "PEDAPUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522328,
      "TXT_PINCODE_LOCALITY": "MORAMPUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522329,
      "TXT_PINCODE_LOCALITY": "MANCHIKALAPUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522330,
      "TXT_PINCODE_LOCALITY": "DUGGIRALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522331,
      "TXT_PINCODE_LOCALITY": "PERA KALAPUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522332,
      "TXT_PINCODE_LOCALITY": "CHILUVUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522333,
      "TXT_PINCODE_LOCALITY": "THUMMAPUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522334,
      "TXT_PINCODE_LOCALITY": "PENUMULI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522340,
      "TXT_PINCODE_LOCALITY": "EDLA PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522341,
      "TXT_PINCODE_LOCALITY": "INTUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522346,
      "TXT_PINCODE_LOCALITY": "ATTOTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522347,
      "TXT_PINCODE_LOCALITY": "KAVUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522351,
      "TXT_PINCODE_LOCALITY": "MUNIPALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522352,
      "TXT_PINCODE_LOCALITY": "MAMILLAPALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522353,
      "TXT_PINCODE_LOCALITY": "PEDAGADELAVARRU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522354,
      "TXT_PINCODE_LOCALITY": "BODI PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522356,
      "TXT_PINCODE_LOCALITY": "PEDA KONDURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522401,
      "TXT_PINCODE_LOCALITY": "SIRIPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522402,
      "TXT_PINCODE_LOCALITY": "PEDAKURAPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522403,
      "TXT_PINCODE_LOCALITY": "SATTENAPALLE BUS STAND",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522404,
      "TXT_PINCODE_LOCALITY": "PONUGUPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522405,
      "TXT_PINCODE_LOCALITY": "RENTA PALLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522406,
      "TXT_PINCODE_LOCALITY": "MANDA PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522407,
      "TXT_PINCODE_LOCALITY": "LINGAMGUNTLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522408,
      "TXT_PINCODE_LOCALITY": "MUPPALLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522409,
      "TXT_PINCODE_LOCALITY": "ATCHAMPETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522410,
      "TXT_PINCODE_LOCALITY": "KROSUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522411,
      "TXT_PINCODE_LOCALITY": "BELLAM KONDA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522412,
      "TXT_PINCODE_LOCALITY": "GANAPAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522413,
      "TXT_PINCODE_LOCALITY": "PIDUGU RALLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522414,
      "TXT_PINCODE_LOCALITY": "DACHEPALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522415,
      "TXT_PINCODE_LOCALITY": "GURAZALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522416,
      "TXT_PINCODE_LOCALITY": "MADALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522417,
      "TXT_PINCODE_LOCALITY": "DODLERU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522419,
      "TXT_PINCODE_LOCALITY": "VELPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522421,
      "TXT_PINCODE_LOCALITY": "RENTACHINALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522426,
      "TXT_PINCODE_LOCALITY": "OLD PARK CENTRE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522428,
      "TXT_PINCODE_LOCALITY": "MANDADI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522435,
      "TXT_PINCODE_LOCALITY": "KOTHAPALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522436,
      "TXT_PINCODE_LOCALITY": "PARASTYALLUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522437,
      "TXT_PINCODE_LOCALITY": "BRAHMANAPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522438,
      "TXT_PINCODE_LOCALITY": "MEDIKONDURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522439,
      "TXT_PINCODE_LOCALITY": "VIJAYAPURI SOUTH",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522440,
      "TXT_PINCODE_LOCALITY": "MACHAVARAM (NARSARAOPETA)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522451,
      "TXT_PINCODE_LOCALITY": "PATI BANDA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522452,
      "TXT_PINCODE_LOCALITY": "PANI DAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522461,
      "TXT_PINCODE_LOCALITY": "JANAPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522462,
      "TXT_PINCODE_LOCALITY": "NADIKUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522501,
      "TXT_PINCODE_LOCALITY": "TADEPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522502,
      "TXT_PINCODE_LOCALITY": "KRISHNA CEMENT WORKS",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522503,
      "TXT_PINCODE_LOCALITY": "APSP COLONY",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522505,
      "TXT_PINCODE_LOCALITY": "MANDADAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522506,
      "TXT_PINCODE_LOCALITY": "VELAGAPUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522507,
      "TXT_PINCODE_LOCALITY": "VENKATAPALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522508,
      "TXT_PINCODE_LOCALITY": "NAMBURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522509,
      "TXT_PINCODE_LOCALITY": "PATHUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522510,
      "TXT_PINCODE_LOCALITY": "NAGARJUNANAGAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522511,
      "TXT_PINCODE_LOCALITY": "OLD MANGALA GIRI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522512,
      "TXT_PINCODE_LOCALITY": "NEERU KONDA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522513,
      "TXT_PINCODE_LOCALITY": "TAKKELLAPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522514,
      "TXT_PINCODE_LOCALITY": "NIDAMARRU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522515,
      "TXT_PINCODE_LOCALITY": "POLAKAMPADU IND AREA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522529,
      "TXT_PINCODE_LOCALITY": "PHIRANGI PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522530,
      "TXT_PINCODE_LOCALITY": "VEMAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522531,
      "TXT_PINCODE_LOCALITY": "NUDURPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522532,
      "TXT_PINCODE_LOCALITY": "AMINABAD",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522549,
      "TXT_PINCODE_LOCALITY": "SATULURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522550,
      "TXT_PINCODE_LOCALITY": "KANAPARRU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522551,
      "TXT_PINCODE_LOCALITY": "MERIKAPUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522601,
      "TXT_PINCODE_LOCALITY": "BURNPET",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522602,
      "TXT_PINCODE_LOCALITY": "PRAKASHNAGAR (NSV)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522603,
      "TXT_PINCODE_LOCALITY": "RAVI PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522604,
      "TXT_PINCODE_LOCALITY": "ANANTAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522605,
      "TXT_PINCODE_LOCALITY": "ELLAMANDA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522607,
      "TXT_PINCODE_LOCALITY": "MULAKALUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522608,
      "TXT_PINCODE_LOCALITY": "VIZIANAGARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522609,
      "TXT_PINCODE_LOCALITY": "IKKURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522610,
      "TXT_PINCODE_LOCALITY": "JONNALAGADDA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522611,
      "TXT_PINCODE_LOCALITY": "KAVUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522612,
      "TXT_PINCODE_LOCALITY": "DURGI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522613,
      "TXT_PINCODE_LOCALITY": "VELDURTHI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522614,
      "TXT_PINCODE_LOCALITY": "KAREMPUDI NSC COLONY",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522615,
      "TXT_PINCODE_LOCALITY": "NAKARIKALLU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522616,
      "TXT_PINCODE_LOCALITY": "CHILAKALURIPETA H O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522617,
      "TXT_PINCODE_LOCALITY": "VELUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522618,
      "TXT_PINCODE_LOCALITY": "EDDANAPUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522619,
      "TXT_PINCODE_LOCALITY": "GANAPAVARAM S O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522620,
      "TXT_PINCODE_LOCALITY": "CHINTA PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522621,
      "TXT_PINCODE_LOCALITY": "PURUSHOTTA PATNAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522622,
      "TXT_PINCODE_LOCALITY": "GUNDLA PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522623,
      "TXT_PINCODE_LOCALITY": "REMIDI CHERLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522626,
      "TXT_PINCODE_LOCALITY": "MURIKIPUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522627,
      "TXT_PINCODE_LOCALITY": "TATHAPUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522628,
      "TXT_PINCODE_LOCALITY": "GOPALAM VARI PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522630,
      "TXT_PINCODE_LOCALITY": "RAJU PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522631,
      "TXT_PINCODE_LOCALITY": "KONIDENA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522632,
      "TXT_PINCODE_LOCALITY": "BOPPUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522646,
      "TXT_PINCODE_LOCALITY": "SAVALYAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522647,
      "TXT_PINCODE_LOCALITY": "KOTHAPETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522648,
      "TXT_PINCODE_LOCALITY": "KARUMANCHI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522649,
      "TXT_PINCODE_LOCALITY": "PEDA KANCHERLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522654,
      "TXT_PINCODE_LOCALITY": "PERSARLANKA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522655,
      "TXT_PINCODE_LOCALITY": "PEDAPULI PARRU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522656,
      "TXT_PINCODE_LOCALITY": "KISHKINDA PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522657,
      "TXT_PINCODE_LOCALITY": "VELLATUR VKD",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522658,
      "TXT_PINCODE_LOCALITY": "IPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522659,
      "TXT_PINCODE_LOCALITY": "AINAVOLU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522660,
      "TXT_PINCODE_LOCALITY": "NUZENDLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522661,
      "TXT_PINCODE_LOCALITY": "MUPPALLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522663,
      "TXT_PINCODE_LOCALITY": "BANDLA MOTTU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522664,
      "TXT_PINCODE_LOCALITY": "BOMMARAJU PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 522665,
      "TXT_PINCODE_LOCALITY": "BOLLAPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523001,
      "TXT_PINCODE_LOCALITY": "PRAKASHAMBHAVAN",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523002,
      "TXT_PINCODE_LOCALITY": "KOTHAPETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523026,
      "TXT_PINCODE_LOCALITY": "MADHAVANI PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442256,
      "City District Name": "TRIPURANTHAKAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523101,
      "TXT_PINCODE_LOCALITY": "SINGARAYA KONDA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523102,
      "TXT_PINCODE_LOCALITY": "VELLALA CHERUVU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442255,
      "City District Name": "SANTHAMAGULURU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523104,
      "TXT_PINCODE_LOCALITY": "BINGINE PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523105,
      "TXT_PINCODE_LOCALITY": "KANDUKURU H O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523106,
      "TXT_PINCODE_LOCALITY": "PRAKASAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523108,
      "TXT_PINCODE_LOCALITY": "PAMURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523109,
      "TXT_PINCODE_LOCALITY": "PONNALORE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523110,
      "TXT_PINCODE_LOCALITY": "BOTLA GUDUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523111,
      "TXT_PINCODE_LOCALITY": "PEDA ALAVALLE PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523112,
      "TXT_PINCODE_LOCALITY": "PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523113,
      "TXT_PINCODE_LOCALITY": "POKURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523114,
      "TXT_PINCODE_LOCALITY": "LINGASAMUDRAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523115,
      "TXT_PINCODE_LOCALITY": "PEDDA PAVANI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523116,
      "TXT_PINCODE_LOCALITY": "POLINENICHERUVU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523117,
      "TXT_PINCODE_LOCALITY": "MUDDAPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442254,
      "City District Name": "PEDACHERLO PALLE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523121,
      "TXT_PINCODE_LOCALITY": "NANDANAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523124,
      "TXT_PINCODE_LOCALITY": "CHIRIKURA PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523125,
      "TXT_PINCODE_LOCALITY": "PALUKUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523135,
      "TXT_PINCODE_LOCALITY": "CHINA GANJAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523153,
      "TXT_PINCODE_LOCALITY": "VIJAY NAGAR COLONY",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2805,
      "City District Name": "CHIRALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523155,
      "TXT_PINCODE_LOCALITY": "BOSENAGAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523156,
      "TXT_PINCODE_LOCALITY": "MAHATMA GANDHI CLOTH MKT",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523157,
      "TXT_PINCODE_LOCALITY": "VITTALNAGAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523165,
      "TXT_PINCODE_LOCALITY": "JANDRAPET",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523166,
      "TXT_PINCODE_LOCALITY": "IPURUPALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523167,
      "TXT_PINCODE_LOCALITY": "INKOLLU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523168,
      "TXT_PINCODE_LOCALITY": "KARAM CHEDU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523169,
      "TXT_PINCODE_LOCALITY": "PARCHURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523170,
      "TXT_PINCODE_LOCALITY": "SWARNA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523171,
      "TXT_PINCODE_LOCALITY": "A.B.V.PALEM S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523173,
      "TXT_PINCODE_LOCALITY": "GANGAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523175,
      "TXT_PINCODE_LOCALITY": "VEERANNAPALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523177,
      "TXT_PINCODE_LOCALITY": "CHANDALUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523178,
      "TXT_PINCODE_LOCALITY": "NAGULA PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523180,
      "TXT_PINCODE_LOCALITY": "AMMANA BROLU RAPARLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523181,
      "TXT_PINCODE_LOCALITY": "KADAVA KUDURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523182,
      "TXT_PINCODE_LOCALITY": "ALURU VILLAGE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4886,
      "City District Name": "ONGOLE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523183,
      "TXT_PINCODE_LOCALITY": "PRASANGULA PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523184,
      "TXT_PINCODE_LOCALITY": "PANDILLA PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523185,
      "TXT_PINCODE_LOCALITY": "SANTHARAVUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523186,
      "TXT_PINCODE_LOCALITY": "DUDDUKUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523187,
      "TXT_PINCODE_LOCALITY": "RAURR PETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523188,
      "TXT_PINCODE_LOCALITY": "CHAVATA PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523189,
      "TXT_PINCODE_LOCALITY": "THIMMASAMDRAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523190,
      "TXT_PINCODE_LOCALITY": "SUDIVARIPALAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523194,
      "TXT_PINCODE_LOCALITY": "IDUMUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523195,
      "TXT_PINCODE_LOCALITY": "PRAKASAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523196,
      "TXT_PINCODE_LOCALITY": "NIDAMANOOR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523201,
      "TXT_PINCODE_LOCALITY": "ADDANKI H O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523211,
      "TXT_PINCODE_LOCALITY": "MADDI PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523212,
      "TXT_PINCODE_LOCALITY": "DAIVALA RAVURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523213,
      "TXT_PINCODE_LOCALITY": "PAIDIPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523214,
      "TXT_PINCODE_LOCALITY": "PRAKASAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523215,
      "TXT_PINCODE_LOCALITY": "DAIVALA RAVURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523216,
      "TXT_PINCODE_LOCALITY": "PRAKASAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523218,
      "TXT_PINCODE_LOCALITY": "BASAVANNA PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 608,
      "City District Name": "GUNTUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523219,
      "TXT_PINCODE_LOCALITY": "PRAKASAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523222,
      "TXT_PINCODE_LOCALITY": "MYNAMPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523223,
      "TXT_PINCODE_LOCALITY": "PRAKASAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523224,
      "TXT_PINCODE_LOCALITY": "VELI GANDLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523225,
      "TXT_PINCODE_LOCALITY": "SANTHANUTHALA PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523226,
      "TXT_PINCODE_LOCALITY": "N S C COLONY",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523227,
      "TXT_PINCODE_LOCALITY": "HANUMANTHUNI PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523228,
      "TXT_PINCODE_LOCALITY": "PEDA GOLLA PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523230,
      "TXT_PINCODE_LOCALITY": "KANIGIRI BAZAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523235,
      "TXT_PINCODE_LOCALITY": "RUDRAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523238,
      "TXT_PINCODE_LOCALITY": "ANNADA PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442266,
      "City District Name": "HANUMANTHUNI PADU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523240,
      "TXT_PINCODE_LOCALITY": "KOTHURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523241,
      "TXT_PINCODE_LOCALITY": "KONAKANA METLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523243,
      "TXT_PINCODE_LOCALITY": "GUNDLA SAMUDRAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523245,
      "TXT_PINCODE_LOCALITY": "PEDARIKATLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523246,
      "TXT_PINCODE_LOCALITY": "GOTLA GATTU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523247,
      "TXT_PINCODE_LOCALITY": "DARSI NSC COLONY",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523252,
      "TXT_PINCODE_LOCALITY": "POTHAKAMUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523253,
      "TXT_PINCODE_LOCALITY": "UPPALAPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523254,
      "TXT_PINCODE_LOCALITY": "SANKHAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523260,
      "TXT_PINCODE_LOCALITY": "VALAPARLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523261,
      "TXT_PINCODE_LOCALITY": "MUPPAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523262,
      "TXT_PINCODE_LOCALITY": "THROVAGUNTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523263,
      "TXT_PINCODE_LOCALITY": "TELLAPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523264,
      "TXT_PINCODE_LOCALITY": "TALLURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523265,
      "TXT_PINCODE_LOCALITY": "MANDUVARI PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523267,
      "TXT_PINCODE_LOCALITY": "PRAKASAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523269,
      "TXT_PINCODE_LOCALITY": "PRAKASAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523270,
      "TXT_PINCODE_LOCALITY": "ANKARLAPUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523271,
      "TXT_PINCODE_LOCALITY": "KAMI PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523272,
      "TXT_PINCODE_LOCALITY": "VALLURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523273,
      "TXT_PINCODE_LOCALITY": "PERIDEPI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523274,
      "TXT_PINCODE_LOCALITY": "DEVAGUDUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523277,
      "TXT_PINCODE_LOCALITY": "PRAKASAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523279,
      "TXT_PINCODE_LOCALITY": "UPPALAPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523280,
      "TXT_PINCODE_LOCALITY": "MADANUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523281,
      "TXT_PINCODE_LOCALITY": "GUDLURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523284,
      "TXT_PINCODE_LOCALITY": "NIDAMALUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523285,
      "TXT_PINCODE_LOCALITY": "PRAKASAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523286,
      "TXT_PINCODE_LOCALITY": "KOTHAPATNAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523288,
      "TXT_PINCODE_LOCALITY": "ALLURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523290,
      "TXT_PINCODE_LOCALITY": "PRAKASAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523291,
      "TXT_PINCODE_LOCALITY": "RAMA PATNAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523292,
      "TXT_PINCODE_LOCALITY": "UALAVAPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523297,
      "TXT_PINCODE_LOCALITY": "PRAKASAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523301,
      "TXT_PINCODE_LOCALITY": "MARUTURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523302,
      "TXT_PINCODE_LOCALITY": "SANTHA MAGALUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523303,
      "TXT_PINCODE_LOCALITY": "KAMMALA PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523304,
      "TXT_PINCODE_LOCALITY": "NSC COLONY",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523305,
      "TXT_PINCODE_LOCALITY": "MARKAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523315,
      "TXT_PINCODE_LOCALITY": "GAJJALA KONDA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523316,
      "TXT_PINCODE_LOCALITY": "MARKAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523320,
      "TXT_PINCODE_LOCALITY": "TOKAPALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523324,
      "TXT_PINCODE_LOCALITY": "GANESUNI PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442246,
      "City District Name": "VELIGANDLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523326,
      "TXT_PINCODE_LOCALITY": "TRIPURANTAKAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523327,
      "TXT_PINCODE_LOCALITY": "ERRAGONDA PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523328,
      "TXT_PINCODE_LOCALITY": "PULLALA CHERUVU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523329,
      "TXT_PINCODE_LOCALITY": "VEMULA KOTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523330,
      "TXT_PINCODE_LOCALITY": "DUPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523331,
      "TXT_PINCODE_LOCALITY": "PEDDA DORNAL",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523332,
      "TXT_PINCODE_LOCALITY": "TARLAPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523333,
      "TXT_PINCODE_LOCALITY": "NEHRU NAGAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523334,
      "TXT_PINCODE_LOCALITY": "BESTAVARAPUPET",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523335,
      "TXT_PINCODE_LOCALITY": "ARTHAVIDU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523336,
      "TXT_PINCODE_LOCALITY": "ANDHRA PRADESH",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523338,
      "TXT_PINCODE_LOCALITY": "MOHIDDIN PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523345,
      "TXT_PINCODE_LOCALITY": "KRISHNAM SHETTIPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523346,
      "TXT_PINCODE_LOCALITY": "PETIKAYA GULLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523356,
      "TXT_PINCODE_LOCALITY": "EDAVALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523357,
      "TXT_PINCODE_LOCALITY": "GIDDALUR BAZAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523367,
      "TXT_PINCODE_LOCALITY": "MUNDLAPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523368,
      "TXT_PINCODE_LOCALITY": "RACHERLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523369,
      "TXT_PINCODE_LOCALITY": "RAJU PALEM (ONGOLE)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523370,
      "TXT_PINCODE_LOCALITY": "RAVI PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523371,
      "TXT_PINCODE_LOCALITY": "TUMMALA CHERUVU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523372,
      "TXT_PINCODE_LOCALITY": "TURMELLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523373,
      "TXT_PINCODE_LOCALITY": "KOMAROLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523417,
      "TXT_PINCODE_LOCALITY": "CHANDRASEKARAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523502,
      "TXT_PINCODE_LOCALITY": "PANGULUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 523503,
      "TXT_PINCODE_LOCALITY": "RAMAKUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424401,
      "City District Name": "PRAKASAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524001,
      "TXT_PINCODE_LOCALITY": "NELLORE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524002,
      "TXT_PINCODE_LOCALITY": "BALAJI NAGAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524003,
      "TXT_PINCODE_LOCALITY": "BUILDINGS COMPOUND",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524004,
      "TXT_PINCODE_LOCALITY": "ANDHRA KESARI NAGAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524005,
      "TXT_PINCODE_LOCALITY": "MILITARY COLONY",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524020,
      "TXT_PINCODE_LOCALITY": "CHINNACHERUKUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524021,
      "TXT_PINCODE_LOCALITY": "MAHALXMIPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524101,
      "TXT_PINCODE_LOCALITY": "G D R CHOWK",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524102,
      "TXT_PINCODE_LOCALITY": "MALAVYANAGAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524103,
      "TXT_PINCODE_LOCALITY": "NELLAMURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524117,
      "TXT_PINCODE_LOCALITY": "KONDA GUNTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524119,
      "TXT_PINCODE_LOCALITY": "AREMANIPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524121,
      "TXT_PINCODE_LOCALITY": "SULURPET",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524123,
      "TXT_PINCODE_LOCALITY": "KOTAPOLUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524124,
      "TXT_PINCODE_LOCALITY": "SRI HARI KOTA RANGE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524126,
      "TXT_PINCODE_LOCALITY": "NAYUDUPET H O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524127,
      "TXT_PINCODE_LOCALITY": "YELLA SIRI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524128,
      "TXT_PINCODE_LOCALITY": "VINNAMALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524129,
      "TXT_PINCODE_LOCALITY": "NELLORE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524130,
      "TXT_PINCODE_LOCALITY": "VAJAVARIPALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524131,
      "TXT_PINCODE_LOCALITY": "JAYAMPU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524132,
      "TXT_PINCODE_LOCALITY": "V G T BAZAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524134,
      "TXT_PINCODE_LOCALITY": "VEMBULURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442248,
      "City District Name": "DAKKILI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524135,
      "TXT_PINCODE_LOCALITY": "PUDUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524137,
      "TXT_PINCODE_LOCALITY": "KOVUR CUTCHERY",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524142,
      "TXT_PINCODE_LOCALITY": "MUNGAMUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524152,
      "TXT_PINCODE_LOCALITY": "DAMAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524162,
      "TXT_PINCODE_LOCALITY": "YAKSIRI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524201,
      "TXT_PINCODE_LOCALITY": "BUDAM GUNTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524202,
      "TXT_PINCODE_LOCALITY": "JANATHAPET",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524203,
      "TXT_PINCODE_LOCALITY": "ANEMADUGU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524204,
      "TXT_PINCODE_LOCALITY": "TUMMALA PENTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524218,
      "TXT_PINCODE_LOCALITY": "NELLORE.",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524221,
      "TXT_PINCODE_LOCALITY": "CHAKALAKONDA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524222,
      "TXT_PINCODE_LOCALITY": "DUTHALUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524223,
      "TXT_PINCODE_LOCALITY": "JALADANKI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524224,
      "TXT_PINCODE_LOCALITY": "KALIGIRI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524225,
      "TXT_PINCODE_LOCALITY": "SRDDANA KONDURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524226,
      "TXT_PINCODE_LOCALITY": "UDAYAGIRI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524227,
      "TXT_PINCODE_LOCALITY": "VARI KUNTA PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524228,
      "TXT_PINCODE_LOCALITY": "VINJAMUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524230,
      "TXT_PINCODE_LOCALITY": "NANDI PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524231,
      "TXT_PINCODE_LOCALITY": "CHINAKRAKA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524233,
      "TXT_PINCODE_LOCALITY": "SONALA REGADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524234,
      "TXT_PINCODE_LOCALITY": "GUMPERLA PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524235,
      "TXT_PINCODE_LOCALITY": "NARRAWADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524236,
      "TXT_PINCODE_LOCALITY": "GANDI PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524239,
      "TXT_PINCODE_LOCALITY": "KONDAPURAM S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524240,
      "TXT_PINCODE_LOCALITY": "DAGADARTHI S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524290,
      "TXT_PINCODE_LOCALITY": "VUTUKURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524291,
      "TXT_PINCODE_LOCALITY": "RAMATHIRTHAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524292,
      "TXT_PINCODE_LOCALITY": "PADMANABHUNI SATRAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524293,
      "TXT_PINCODE_LOCALITY": "PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524297,
      "TXT_PINCODE_LOCALITY": "VARA KAVI PUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524299,
      "TXT_PINCODE_LOCALITY": "ISUKAPALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524300,
      "TXT_PINCODE_LOCALITY": "SOMASILA HILL COLONY",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524301,
      "TXT_PINCODE_LOCALITY": "SOMASILA PROJECT",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524302,
      "TXT_PINCODE_LOCALITY": "ANANTASAGARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524303,
      "TXT_PINCODE_LOCALITY": "REVURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524304,
      "TXT_PINCODE_LOCALITY": "ANNUMA SAMUDRMPETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524305,
      "TXT_PINCODE_LOCALITY": "JONNAVADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524306,
      "TXT_PINCODE_LOCALITY": "MAKTHAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524307,
      "TXT_PINCODE_LOCALITY": "KARATAMPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524308,
      "TXT_PINCODE_LOCALITY": "VASILA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524309,
      "TXT_PINCODE_LOCALITY": "VIRUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524310,
      "TXT_PINCODE_LOCALITY": "SITARAMPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524311,
      "TXT_PINCODE_LOCALITY": "VARI GONDA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524312,
      "TXT_PINCODE_LOCALITY": "MARRI PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524313,
      "TXT_PINCODE_LOCALITY": "MYPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524314,
      "TXT_PINCODE_LOCALITY": "INDUKURPET",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524315,
      "TXT_PINCODE_LOCALITY": "ALLUR (NLR)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524316,
      "TXT_PINCODE_LOCALITY": "KODAVLUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524317,
      "TXT_PINCODE_LOCALITY": "GANDAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524318,
      "TXT_PINCODE_LOCALITY": "ANNAREDDYPALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524319,
      "TXT_PINCODE_LOCALITY": "YELLAYA PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524320,
      "TXT_PINCODE_LOCALITY": "KASUMURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524321,
      "TXT_PINCODE_LOCALITY": "SURVEPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524322,
      "TXT_PINCODE_LOCALITY": "LAXMANRAOPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524323,
      "TXT_PINCODE_LOCALITY": "NIDIGUNTA PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524324,
      "TXT_PINCODE_LOCALITY": "ISKAPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524329,
      "TXT_PINCODE_LOCALITY": "PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524332,
      "TXT_PINCODE_LOCALITY": "JAGADEVIPETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524333,
      "TXT_PINCODE_LOCALITY": "KOLAGATLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524334,
      "TXT_PINCODE_LOCALITY": "AYYAVARI PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524335,
      "TXT_PINCODE_LOCALITY": "NANDAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524336,
      "TXT_PINCODE_LOCALITY": "PANTAPALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524337,
      "TXT_PINCODE_LOCALITY": "UPPALPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524338,
      "TXT_PINCODE_LOCALITY": "KOTHAVANGALLU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524339,
      "TXT_PINCODE_LOCALITY": "THOPUGUNTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524340,
      "TXT_PINCODE_LOCALITY": "CHINTALATMAKUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524341,
      "TXT_PINCODE_LOCALITY": "CHEJERLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524342,
      "TXT_PINCODE_LOCALITY": "DACHUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524343,
      "TXT_PINCODE_LOCALITY": "CHIPINAPI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524344,
      "TXT_PINCODE_LOCALITY": "MUTHUKURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524345,
      "TXT_PINCODE_LOCALITY": "MARUPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524346,
      "TXT_PINCODE_LOCALITY": "BRAHMA DEVAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524347,
      "TXT_PINCODE_LOCALITY": "NELLORE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524348,
      "TXT_PINCODE_LOCALITY": "MADIRAJU GUDUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524353,
      "TXT_PINCODE_LOCALITY": "BIRADA VOLU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524356,
      "TXT_PINCODE_LOCALITY": "ANNAVARIPALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524364,
      "TXT_PINCODE_LOCALITY": "TALAMANCHI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524365,
      "TXT_PINCODE_LOCALITY": "PEDA PUTHEDU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524366,
      "TXT_PINCODE_LOCALITY": "NORTH RAJU PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524399,
      "TXT_PINCODE_LOCALITY": "PEDDA PARIYA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524401,
      "TXT_PINCODE_LOCALITY": "TADA MANDAL",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524402,
      "TXT_PINCODE_LOCALITY": "GUNUPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524403,
      "TXT_PINCODE_LOCALITY": "DUGARAJA PATNAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524404,
      "TXT_PINCODE_LOCALITY": "BALAYA PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524405,
      "TXT_PINCODE_LOCALITY": "MANU BOLU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524406,
      "TXT_PINCODE_LOCALITY": "CHENNUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524407,
      "TXT_PINCODE_LOCALITY": "SYADAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524408,
      "TXT_PINCODE_LOCALITY": "RAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524409,
      "TXT_PINCODE_LOCALITY": "TURIMERLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524410,
      "TXT_PINCODE_LOCALITY": "BUDANAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524411,
      "TXT_PINCODE_LOCALITY": "MOLAGANURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524412,
      "TXT_PINCODE_LOCALITY": "MOMIDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524413,
      "TXT_PINCODE_LOCALITY": "KOTHAPALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524414,
      "TXT_PINCODE_LOCALITY": "GUNDA VOLU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524415,
      "TXT_PINCODE_LOCALITY": "VALAMEDU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524416,
      "TXT_PINCODE_LOCALITY": "SANTHAVELURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524421,
      "TXT_PINCODE_LOCALITY": "MENAKUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524425,
      "TXT_PINCODE_LOCALITY": "TALAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524430,
      "TXT_PINCODE_LOCALITY": "CHAGANAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524434,
      "TXT_PINCODE_LOCALITY": "KESAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524436,
      "TXT_PINCODE_LOCALITY": "TIRMUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524441,
      "TXT_PINCODE_LOCALITY": "KALLURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524442,
      "TXT_PINCODE_LOCALITY": "GURRAM KONDA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 524444,
      "TXT_PINCODE_LOCALITY": "KATTUVA PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530001,
      "TXT_PINCODE_LOCALITY": "KURUPAM MARKET",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530002,
      "TXT_PINCODE_LOCALITY": "MUPPIDI COLONY",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530003,
      "TXT_PINCODE_LOCALITY": "SIRIPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530004,
      "TXT_PINCODE_LOCALITY": "WALTAIR RS H O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530005,
      "TXT_PINCODE_LOCALITY": "GANDHI GRAM (VISAKHA)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530006,
      "TXT_PINCODE_LOCALITY": "BUTCHI RAJU PALEM VISAKHA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530007,
      "TXT_PINCODE_LOCALITY": "INDUSTRIAL ESTATE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530008,
      "TXT_PINCODE_LOCALITY": "KANCHARA PALEM VISAKHA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530009,
      "TXT_PINCODE_LOCALITY": "DEPOT",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530010,
      "TXT_PINCODE_LOCALITY": "ISAKATHOTA VISAKHA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530011,
      "TXT_PINCODE_LOCALITY": "SRIHARIPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530012,
      "TXT_PINCODE_LOCALITY": "AUTONAGAR (VISAKHA)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530013,
      "TXT_PINCODE_LOCALITY": "P & T COLONY (VISAKHA)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530014,
      "TXT_PINCODE_LOCALITY": "NAVAL DOCK YARD",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530015,
      "TXT_PINCODE_LOCALITY": "VISAKHAPATNAM ZINC",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530016,
      "TXT_PINCODE_LOCALITY": "SANKAR MATTAM ROAD",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530017,
      "TXT_PINCODE_LOCALITY": "TRAVELLERS BUNGLOW ROAD",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530018,
      "TXT_PINCODE_LOCALITY": "MARRI PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530019,
      "TXT_PINCODE_LOCALITY": "VISHAKHAPATNAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530020,
      "TXT_PINCODE_LOCALITY": "DABA GARDENS",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530021,
      "TXT_PINCODE_LOCALITY": "NEHRU NAGAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530022,
      "TXT_PINCODE_LOCALITY": "HOUSING BOARD COLONY",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530023,
      "TXT_PINCODE_LOCALITY": "CHINA WALTAIR (VISAKHA)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530024,
      "TXT_PINCODE_LOCALITY": "SALAGRAMAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530025,
      "TXT_PINCODE_LOCALITY": "KANITHI VISAKHA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530026,
      "TXT_PINCODE_LOCALITY": "GAJUVAKA (VISAKHA)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530027,
      "TXT_PINCODE_LOCALITY": "YELLAPUVANIPALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530028,
      "TXT_PINCODE_LOCALITY": "SIMHACHALAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530029,
      "TXT_PINCODE_LOCALITY": "PURAM (VISAKHA)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530030,
      "TXT_PINCODE_LOCALITY": "VIZIANAGARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530031,
      "TXT_PINCODE_LOCALITY": "VISAKHAPATNAM STEEL PROJE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530032,
      "TXT_PINCODE_LOCALITY": "UKKUNAGARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530035,
      "TXT_PINCODE_LOCALITY": "VISAKHAPATNAM PORT",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530037,
      "TXT_PINCODE_LOCALITY": "SIMHACHALAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442353,
      "City District Name": "VISAKHAPATNAM (URBAN)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530039,
      "TXT_PINCODE_LOCALITY": "YENDADA (VISAKHA)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530040,
      "TXT_PINCODE_LOCALITY": "GOVT DIARY FARM (VISAKHA)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530041,
      "TXT_PINCODE_LOCALITY": "ANANDNAGAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530042,
      "TXT_PINCODE_LOCALITY": "ANANDAPURAM (VISAKHA)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530043,
      "TXT_PINCODE_LOCALITY": "ARILOVA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530044,
      "TXT_PINCODE_LOCALITY": "PEDDGUNTIYADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530045,
      "TXT_PINCODE_LOCALITY": "YENDADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530046,
      "TXT_PINCODE_LOCALITY": "DUVVADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530047,
      "TXT_PINCODE_LOCALITY": "VEPAGUNTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530048,
      "TXT_PINCODE_LOCALITY": "MADHURAWADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530049,
      "TXT_PINCODE_LOCALITY": "SEZ S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530051,
      "TXT_PINCODE_LOCALITY": "SUJATHA NAGAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530052,
      "TXT_PINCODE_LOCALITY": "ANANDAPURAM S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530053,
      "TXT_PINCODE_LOCALITY": "VEDULLANARAVA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442338,
      "City District Name": "SABBAVARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530085,
      "TXT_PINCODE_LOCALITY": "MAHARANI PETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442245,
      "City District Name": "VISAKHAPATNAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 530183,
      "TXT_PINCODE_LOCALITY": "VISAKHAPATNAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 425910,
      "City District Name": "VISHAKHAPATNAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531001,
      "TXT_PINCODE_LOCALITY": "GANDHI NAGARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531002,
      "TXT_PINCODE_LOCALITY": "NADANAM DODDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531007,
      "TXT_PINCODE_LOCALITY": "NAGULA PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531011,
      "TXT_PINCODE_LOCALITY": "ATCHUTAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531019,
      "TXT_PINCODE_LOCALITY": "JAWAHARLALNEHRU PHARMACITY",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531020,
      "TXT_PINCODE_LOCALITY": "VISHAKAPATNAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 425910,
      "City District Name": "VISHAKHAPATNAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531021,
      "TXT_PINCODE_LOCALITY": "PARVADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531022,
      "TXT_PINCODE_LOCALITY": "KODURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531023,
      "TXT_PINCODE_LOCALITY": "GOVADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531024,
      "TXT_PINCODE_LOCALITY": "PADEROO",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531025,
      "TXT_PINCODE_LOCALITY": "MEDIVADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531026,
      "TXT_PINCODE_LOCALITY": "VADDADI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531027,
      "TXT_PINCODE_LOCALITY": "MADUGULA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531028,
      "TXT_PINCODE_LOCALITY": "KASPAJAGANNADHAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531029,
      "TXT_PINCODE_LOCALITY": "GANGARAJ MADUGULA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531030,
      "TXT_PINCODE_LOCALITY": "DEVARA PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531031,
      "TXT_PINCODE_LOCALITY": "KASIMKOTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531032,
      "TXT_PINCODE_LOCALITY": "TUMMA PALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531033,
      "TXT_PINCODE_LOCALITY": "MANAGAPAKA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531034,
      "TXT_PINCODE_LOCALITY": "KINTADA KOTA PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531035,
      "TXT_PINCODE_LOCALITY": "SUBBAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531036,
      "TXT_PINCODE_LOCALITY": "KOTHURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531037,
      "TXT_PINCODE_LOCALITY": "TALLA PALLEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531038,
      "TXT_PINCODE_LOCALITY": "THIMMARAJU PETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531039,
      "TXT_PINCODE_LOCALITY": "KONDAKERLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531040,
      "TXT_PINCODE_LOCALITY": "PEDA BAYULU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531041,
      "TXT_PINCODE_LOCALITY": "JUTTADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531043,
      "TXT_PINCODE_LOCALITY": "APPALARAJUPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531044,
      "TXT_PINCODE_LOCALITY": "SEETHAYYAPETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531045,
      "TXT_PINCODE_LOCALITY": "GONDU PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531046,
      "TXT_PINCODE_LOCALITY": "KANNURU PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531047,
      "TXT_PINCODE_LOCALITY": "BAYYAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531048,
      "TXT_PINCODE_LOCALITY": "UGGINAPALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531049,
      "TXT_PINCODE_LOCALITY": "NAGARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531050,
      "TXT_PINCODE_LOCALITY": "GOMPA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531051,
      "TXT_PINCODE_LOCALITY": "MINUMULRU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531054,
      "TXT_PINCODE_LOCALITY": "GULLEPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531055,
      "TXT_PINCODE_LOCALITY": "YELLAMANCHILI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531056,
      "TXT_PINCODE_LOCALITY": "SOMALINGAPALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531057,
      "TXT_PINCODE_LOCALITY": "REGU PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531058,
      "TXT_PINCODE_LOCALITY": "NARASINGU BILLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531059,
      "TXT_PINCODE_LOCALITY": "PULA PARTHI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531060,
      "TXT_PINCODE_LOCALITY": "S RAYAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531061,
      "TXT_PINCODE_LOCALITY": "DIMLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531062,
      "TXT_PINCODE_LOCALITY": "SARVASIDDHI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531063,
      "TXT_PINCODE_LOCALITY": "DHARA PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531064,
      "TXT_PINCODE_LOCALITY": "PENU GOLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531066,
      "TXT_PINCODE_LOCALITY": "PURUSHOTTAM PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531067,
      "TXT_PINCODE_LOCALITY": "PETASUDIPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531068,
      "TXT_PINCODE_LOCALITY": "MELUPAKA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531070,
      "TXT_PINCODE_LOCALITY": "CHINA MADINA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531071,
      "TXT_PINCODE_LOCALITY": "DIBBIDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531072,
      "TXT_PINCODE_LOCALITY": "VIJAYARAMA RAJU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531073,
      "TXT_PINCODE_LOCALITY": "KODURU (MADUGULA)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531075,
      "TXT_PINCODE_LOCALITY": "LAKKAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531076,
      "TXT_PINCODE_LOCALITY": "CHOWDUVADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531077,
      "TXT_PINCODE_LOCALITY": "KUKUMPET",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531078,
      "TXT_PINCODE_LOCALITY": "BANDAVEEDHI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531081,
      "TXT_PINCODE_LOCALITY": "NAKKAPALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531082,
      "TXT_PINCODE_LOCALITY": "ETIKOPPAKA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531083,
      "TXT_PINCODE_LOCALITY": "PEDAGUMMULURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531084,
      "TXT_PINCODE_LOCALITY": "KRISHNADEVI PETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531085,
      "TXT_PINCODE_LOCALITY": "KOTAVRATLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531086,
      "TXT_PINCODE_LOCALITY": "KORUPROLU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531087,
      "TXT_PINCODE_LOCALITY": "KOYYURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531088,
      "TXT_PINCODE_LOCALITY": "DARLAPUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531089,
      "TXT_PINCODE_LOCALITY": "JOGAMPETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531090,
      "TXT_PINCODE_LOCALITY": "VEMPADAU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531091,
      "TXT_PINCODE_LOCALITY": "VIZIANAGARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531092,
      "TXT_PINCODE_LOCALITY": "REVU POLAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531093,
      "TXT_PINCODE_LOCALITY": "GEDDA PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531094,
      "TXT_PINCODE_LOCALITY": "RAJAYYAPET",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531095,
      "TXT_PINCODE_LOCALITY": "GUDIVADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531097,
      "TXT_PINCODE_LOCALITY": "SUNKAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531099,
      "TXT_PINCODE_LOCALITY": "KAKARA PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531101,
      "TXT_PINCODE_LOCALITY": "CHODYAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531102,
      "TXT_PINCODE_LOCALITY": "CHINA DODDIGALLU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531103,
      "TXT_PINCODE_LOCALITY": "UPAMKA AGRAHARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531105,
      "TXT_PINCODE_LOCALITY": "SILERU PROJECT",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531110,
      "TXT_PINCODE_LOCALITY": "DONKA RAYI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531111,
      "TXT_PINCODE_LOCALITY": "CHINTA PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531113,
      "TXT_PINCODE_LOCALITY": "MAKAVARAPALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531114,
      "TXT_PINCODE_LOCALITY": "KOTHA KOTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531115,
      "TXT_PINCODE_LOCALITY": "NATIONAL POLICE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531116,
      "TXT_PINCODE_LOCALITY": "NARSIPATNAM H O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531117,
      "TXT_PINCODE_LOCALITY": "VEMULA PUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531118,
      "TXT_PINCODE_LOCALITY": "CHEEDIDUGUMMALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531121,
      "TXT_PINCODE_LOCALITY": "BUCHAM PETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531122,
      "TXT_PINCODE_LOCALITY": "GIDUTURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531123,
      "TXT_PINCODE_LOCALITY": "LOTHU GADDA JUNCTION",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531124,
      "TXT_PINCODE_LOCALITY": "ROLUGUNTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531125,
      "TXT_PINCODE_LOCALITY": "DOWNURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531126,
      "TXT_PINCODE_LOCALITY": "PAYAKARAOPET",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531127,
      "TXT_PINCODE_LOCALITY": "SATYAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531131,
      "TXT_PINCODE_LOCALITY": "RAJU PAKALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531133,
      "TXT_PINCODE_LOCALITY": "RAGHAVENDRANAGAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531135,
      "TXT_PINCODE_LOCALITY": "DONDAPUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531137,
      "TXT_PINCODE_LOCALITY": "TADIGADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 425910,
      "City District Name": "VISHAKHAPATNAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531145,
      "TXT_PINCODE_LOCALITY": "SRUNGAVARAPU KOTA BUS SD",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531146,
      "TXT_PINCODE_LOCALITY": "KASIPATNAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531147,
      "TXT_PINCODE_LOCALITY": "BORRAGETU VALSA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531148,
      "TXT_PINCODE_LOCALITY": "DHARMAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531149,
      "TXT_PINCODE_LOCALITY": "ARAKU COLONY",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531150,
      "TXT_PINCODE_LOCALITY": "ANANTA GIRI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531151,
      "TXT_PINCODE_LOCALITY": "ARAKU R S",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531152,
      "TXT_PINCODE_LOCALITY": "SUNKARI METTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531153,
      "TXT_PINCODE_LOCALITY": "BUSIKONDA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442271,
      "City District Name": "GUDEM KOTHA VEEDHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531154,
      "TXT_PINCODE_LOCALITY": "DUMBRIGUDA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531155,
      "TXT_PINCODE_LOCALITY": "TENUBODDAVARA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531156,
      "TXT_PINCODE_LOCALITY": "KOTHURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531157,
      "TXT_PINCODE_LOCALITY": "PEDAKHANDE PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531160,
      "TXT_PINCODE_LOCALITY": "BONANGI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531161,
      "TXT_PINCODE_LOCALITY": "LAKKAVARAPU KOTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531162,
      "TXT_PINCODE_LOCALITY": "CHITTI VALASA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531163,
      "TXT_PINCODE_LOCALITY": "BHEEMUNIPATNAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531164,
      "TXT_PINCODE_LOCALITY": "GOPALA PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531165,
      "TXT_PINCODE_LOCALITY": "GUDIVADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531166,
      "TXT_PINCODE_LOCALITY": "POLI PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531167,
      "TXT_PINCODE_LOCALITY": "TALLA VALASA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531168,
      "TXT_PINCODE_LOCALITY": "ANANDAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531170,
      "TXT_PINCODE_LOCALITY": "KOTTAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531171,
      "TXT_PINCODE_LOCALITY": "GOLZAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531172,
      "TXT_PINCODE_LOCALITY": "REVIDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531173,
      "TXT_PINCODE_LOCALITY": "KAKINADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531183,
      "TXT_PINCODE_LOCALITY": "KOTHA VALASA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531184,
      "TXT_PINCODE_LOCALITY": "VIYYAMPETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531201,
      "TXT_PINCODE_LOCALITY": "GAJULA REGA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531202,
      "TXT_PINCODE_LOCALITY": "REWARANI PATE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531203,
      "TXT_PINCODE_LOCALITY": "VIZANAGARAM CANTT",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531204,
      "TXT_PINCODE_LOCALITY": "PUSAPATI REGA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531210,
      "TXT_PINCODE_LOCALITY": "RELLI VALASA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531211,
      "TXT_PINCODE_LOCALITY": "INGALA PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531212,
      "TXT_PINCODE_LOCALITY": "DENKADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531213,
      "TXT_PINCODE_LOCALITY": "KONADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531214,
      "TXT_PINCODE_LOCALITY": "SAINIK SCHOOL (KORU KONDA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531215,
      "TXT_PINCODE_LOCALITY": "GANTYADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531216,
      "TXT_PINCODE_LOCALITY": "BHOGAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531217,
      "TXT_PINCODE_LOCALITY": "NELLI MARLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531218,
      "TXT_PINCODE_LOCALITY": "RAMATHIRTHAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531219,
      "TXT_PINCODE_LOCALITY": "REDDI PALL AGRAHARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531220,
      "TXT_PINCODE_LOCALITY": "KOTA GANDREDU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531221,
      "TXT_PINCODE_LOCALITY": "PEDAMAJJIPALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531222,
      "TXT_PINCODE_LOCALITY": "VEDURU VADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531223,
      "TXT_PINCODE_LOCALITY": "PENTA SRIRAMAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531224,
      "TXT_PINCODE_LOCALITY": "TETTANGI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531225,
      "TXT_PINCODE_LOCALITY": "JARJAPUPETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531226,
      "TXT_PINCODE_LOCALITY": "VASADI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531227,
      "TXT_PINCODE_LOCALITY": "RAMA BAHADRA PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531229,
      "TXT_PINCODE_LOCALITY": "MUNJERU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531230,
      "TXT_PINCODE_LOCALITY": "APSP QUARTERS (VZM)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531231,
      "TXT_PINCODE_LOCALITY": "PEDATADI VADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531232,
      "TXT_PINCODE_LOCALITY": "PINA TADI VADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531234,
      "TXT_PINCODE_LOCALITY": "KUMILI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531235,
      "TXT_PINCODE_LOCALITY": "VEMPADAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531236,
      "TXT_PINCODE_LOCALITY": "PERAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531237,
      "TXT_PINCODE_LOCALITY": "KANAPAKA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531238,
      "TXT_PINCODE_LOCALITY": "JONNA VALASA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531239,
      "TXT_PINCODE_LOCALITY": "GOVINDA PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531240,
      "TXT_PINCODE_LOCALITY": "ALAMANDA R S",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531241,
      "TXT_PINCODE_LOCALITY": "BHEE MALI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531242,
      "TXT_PINCODE_LOCALITY": "KANTAKA PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531250,
      "TXT_PINCODE_LOCALITY": "JAMI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531251,
      "TXT_PINCODE_LOCALITY": "JAGARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531252,
      "TXT_PINCODE_LOCALITY": "CHINTADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531253,
      "TXT_PINCODE_LOCALITY": "VASSANTHA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531260,
      "TXT_PINCODE_LOCALITY": "BONDAPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531261,
      "TXT_PINCODE_LOCALITY": "GARUDU PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531262,
      "TXT_PINCODE_LOCALITY": "DEVUPALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531263,
      "TXT_PINCODE_LOCALITY": "BUDATHANA PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531270,
      "TXT_PINCODE_LOCALITY": "GAJAPATI NAGARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531271,
      "TXT_PINCODE_LOCALITY": "ANDRA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531272,
      "TXT_PINCODE_LOCALITY": "KONISA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531273,
      "TXT_PINCODE_LOCALITY": "MENTADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531274,
      "TXT_PINCODE_LOCALITY": "PITTADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531275,
      "TXT_PINCODE_LOCALITY": "PURITI PENTA AGRAHARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531276,
      "TXT_PINCODE_LOCALITY": "MARRU PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531277,
      "TXT_PINCODE_LOCALITY": "LOGISA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531280,
      "TXT_PINCODE_LOCALITY": "MOIDA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531281,
      "TXT_PINCODE_LOCALITY": "VEPADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531291,
      "TXT_PINCODE_LOCALITY": "LINGALAVALASA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531292,
      "TXT_PINCODE_LOCALITY": "GANGA CHOLLA PENTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531301,
      "TXT_PINCODE_LOCALITY": "CHINTA PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531302,
      "TXT_PINCODE_LOCALITY": "KOPPERLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531303,
      "TXT_PINCODE_LOCALITY": "GURLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531321,
      "TXT_PINCODE_LOCALITY": "TANAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4802,
      "City District Name": "NELLORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531322,
      "TXT_PINCODE_LOCALITY": "TADI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531323,
      "TXT_PINCODE_LOCALITY": "ARIPAKA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531324,
      "TXT_PINCODE_LOCALITY": "MALLUNAIDU PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531325,
      "TXT_PINCODE_LOCALITY": "BANGARAM PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 531449,
      "TXT_PINCODE_LOCALITY": "BENDAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532001,
      "TXT_PINCODE_LOCALITY": "ZILLA PARISHAT",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532005,
      "TXT_PINCODE_LOCALITY": "GUJRATIPETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532015,
      "TXT_PINCODE_LOCALITY": "AMPOLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532101,
      "TXT_PINCODE_LOCALITY": "S R NAGAR I E COLONY",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532102,
      "TXT_PINCODE_LOCALITY": "GARBHAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532103,
      "TXT_PINCODE_LOCALITY": "BHAIRI PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532104,
      "TXT_PINCODE_LOCALITY": "PADA BANTU PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532122,
      "TXT_PINCODE_LOCALITY": "VUNUKURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532123,
      "TXT_PINCODE_LOCALITY": "SEETHARAM PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532124,
      "TXT_PINCODE_LOCALITY": "UTTARVALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532125,
      "TXT_PINCODE_LOCALITY": "METTA PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532126,
      "TXT_PINCODE_LOCALITY": "THERLAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532127,
      "TXT_PINCODE_LOCALITY": "RAJAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532128,
      "TXT_PINCODE_LOCALITY": "CHIPURU PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532129,
      "TXT_PINCODE_LOCALITY": "AMITI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532130,
      "TXT_PINCODE_LOCALITY": "BODDURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532131,
      "TXT_PINCODE_LOCALITY": "ROUTH PETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532132,
      "TXT_PINCODE_LOCALITY": "SANTHAKAVITI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532133,
      "TXT_PINCODE_LOCALITY": "ARASADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532134,
      "TXT_PINCODE_LOCALITY": "GALAVALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532135,
      "TXT_PINCODE_LOCALITY": "LOCHERLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532136,
      "TXT_PINCODE_LOCALITY": "NEMALAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532137,
      "TXT_PINCODE_LOCALITY": "VADADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532138,
      "TXT_PINCODE_LOCALITY": "RAJERU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532140,
      "TXT_PINCODE_LOCALITY": "PERUMALI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532141,
      "TXT_PINCODE_LOCALITY": "KAGAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532142,
      "TXT_PINCODE_LOCALITY": "MARIVALASA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532148,
      "TXT_PINCODE_LOCALITY": "SINGADAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532168,
      "TXT_PINCODE_LOCALITY": "PONDURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532170,
      "TXT_PINCODE_LOCALITY": "LOLUGU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532185,
      "TXT_PINCODE_LOCALITY": "AMADALAVALASA GATE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532186,
      "TXT_PINCODE_LOCALITY": "VANJANGI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2001,
      "City District Name": "AMADALAVALASA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532187,
      "TXT_PINCODE_LOCALITY": "BHAIRI SINGAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532188,
      "TXT_PINCODE_LOCALITY": "CHINTADA (ALS)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532189,
      "TXT_PINCODE_LOCALITY": "KORLA KOTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532190,
      "TXT_PINCODE_LOCALITY": "PURUSHOTTA PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532195,
      "TXT_PINCODE_LOCALITY": "KOTA BOMMALI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532197,
      "TXT_PINCODE_LOCALITY": "JARJANGI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532198,
      "TXT_PINCODE_LOCALITY": "SANTHA BOMMALI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532201,
      "TXT_PINCODE_LOCALITY": "TEKKALI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532202,
      "TXT_PINCODE_LOCALITY": "TEKKALI MARKET",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532203,
      "TXT_PINCODE_LOCALITY": "VIKRAMPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442326,
      "City District Name": "TEKKALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532205,
      "TXT_PINCODE_LOCALITY": "SASANAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532206,
      "TXT_PINCODE_LOCALITY": "NANDIGAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532207,
      "TXT_PINCODE_LOCALITY": "NARASINGA PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532208,
      "TXT_PINCODE_LOCALITY": "TEMBURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532209,
      "TXT_PINCODE_LOCALITY": "KOTHAPALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532211,
      "TXT_PINCODE_LOCALITY": "NAUPADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532212,
      "TXT_PINCODE_LOCALITY": "NAUPADA R S",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532213,
      "TXT_PINCODE_LOCALITY": "PATHA PATNAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532214,
      "TXT_PINCODE_LOCALITY": "KORASAWADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532215,
      "TXT_PINCODE_LOCALITY": "MELLAPUTTI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532216,
      "TXT_PINCODE_LOCALITY": "CHAPARA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532217,
      "TXT_PINCODE_LOCALITY": "PUNDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532218,
      "TXT_PINCODE_LOCALITY": "PUNDI R S",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532219,
      "TXT_PINCODE_LOCALITY": "AKKUPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532220,
      "TXT_PINCODE_LOCALITY": "BRAHMANTARLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532221,
      "TXT_PINCODE_LOCALITY": "PALASA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532222,
      "TXT_PINCODE_LOCALITY": "KASI BUGGA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532223,
      "TXT_PINCODE_LOCALITY": "AMALAPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532224,
      "TXT_PINCODE_LOCALITY": "LAXMI DEVI PETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532225,
      "TXT_PINCODE_LOCALITY": "PANDU GOPALA PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532226,
      "TXT_PINCODE_LOCALITY": "HARIDASPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532227,
      "TXT_PINCODE_LOCALITY": "PATHA TEKKALI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532229,
      "TXT_PINCODE_LOCALITY": "VENKATAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532242,
      "TXT_PINCODE_LOCALITY": "MANDASA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532243,
      "TXT_PINCODE_LOCALITY": "HARI PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532244,
      "TXT_PINCODE_LOCALITY": "SONDI PUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532263,
      "TXT_PINCODE_LOCALITY": "BARUVA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532264,
      "TXT_PINCODE_LOCALITY": "BARUVA R S",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532265,
      "TXT_PINCODE_LOCALITY": "PALAVALASA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532266,
      "TXT_PINCODE_LOCALITY": "BESI RAMACHANDRA PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532270,
      "TXT_PINCODE_LOCALITY": "KONDAPI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532284,
      "TXT_PINCODE_LOCALITY": "SOMPETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532290,
      "TXT_PINCODE_LOCALITY": "KANCHILI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532291,
      "TXT_PINCODE_LOCALITY": "MATTAM SARI PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532292,
      "TXT_PINCODE_LOCALITY": "MANIKYAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532293,
      "TXT_PINCODE_LOCALITY": "BELAGAM (KANCHILLI)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532294,
      "TXT_PINCODE_LOCALITY": "BORIVANKA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532296,
      "TXT_PINCODE_LOCALITY": "MAKHARAMPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532312,
      "TXT_PINCODE_LOCALITY": "ICHAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532313,
      "TXT_PINCODE_LOCALITY": "KOLIGAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532314,
      "TXT_PINCODE_LOCALITY": "HARIPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532322,
      "TXT_PINCODE_LOCALITY": "KAVITI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532323,
      "TXT_PINCODE_LOCALITY": "PURUSHOTTAPUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532324,
      "TXT_PINCODE_LOCALITY": "RAJA RATNAM VENKATA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532325,
      "TXT_PINCODE_LOCALITY": "JAGATHI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532392,
      "TXT_PINCODE_LOCALITY": "KOKKILIPUTTUGA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442322,
      "City District Name": "KANCHILI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532401,
      "TXT_PINCODE_LOCALITY": "SRIKAKULAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532402,
      "TXT_PINCODE_LOCALITY": "SHER MOHAMMAD PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532403,
      "TXT_PINCODE_LOCALITY": "MURAPAKA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532404,
      "TXT_PINCODE_LOCALITY": "SRI KURMAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532405,
      "TXT_PINCODE_LOCALITY": "GARA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532406,
      "TXT_PINCODE_LOCALITY": "CCI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532407,
      "TXT_PINCODE_LOCALITY": "RAJA PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532408,
      "TXT_PINCODE_LOCALITY": "BONTHALA KODURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532409,
      "TXT_PINCODE_LOCALITY": "PYDI BHIMAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532410,
      "TXT_PINCODE_LOCALITY": "ALLINAGARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532411,
      "TXT_PINCODE_LOCALITY": "KINTALI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532412,
      "TXT_PINCODE_LOCALITY": "BUDUMURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532413,
      "TXT_PINCODE_LOCALITY": "THOLAPI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532414,
      "TXT_PINCODE_LOCALITY": "PEDDA PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532415,
      "TXT_PINCODE_LOCALITY": "VOMARAVILLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532416,
      "TXT_PINCODE_LOCALITY": "KUPPLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532417,
      "TXT_PINCODE_LOCALITY": "BURAVILLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532418,
      "TXT_PINCODE_LOCALITY": "ETCHERLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532421,
      "TXT_PINCODE_LOCALITY": "NARASANNAPETA MARKET",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532422,
      "TXT_PINCODE_LOCALITY": "DEVADI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532423,
      "TXT_PINCODE_LOCALITY": "MAKIVALASA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532425,
      "TXT_PINCODE_LOCALITY": "URLAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532426,
      "TXT_PINCODE_LOCALITY": "SARVAKOTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532427,
      "TXT_PINCODE_LOCALITY": "BUDITHI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532428,
      "TXT_PINCODE_LOCALITY": "MUKHALINGAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532429,
      "TXT_PINCODE_LOCALITY": "POLAKI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532430,
      "TXT_PINCODE_LOCALITY": "PRIYA AGRAHARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532431,
      "TXT_PINCODE_LOCALITY": "NIMMADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532432,
      "TXT_PINCODE_LOCALITY": "JALUM URU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532433,
      "TXT_PINCODE_LOCALITY": "DOLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532434,
      "TXT_PINCODE_LOCALITY": "BHADRI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532435,
      "TXT_PINCODE_LOCALITY": "NADAGAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532437,
      "TXT_PINCODE_LOCALITY": "HARI CHANDRAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532438,
      "TXT_PINCODE_LOCALITY": "CHALLAVANIPETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532439,
      "TXT_PINCODE_LOCALITY": "MABAGAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532440,
      "TXT_PINCODE_LOCALITY": "PALAKONDA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532443,
      "TXT_PINCODE_LOCALITY": "SEETHAMPETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532445,
      "TXT_PINCODE_LOCALITY": "BURJA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532446,
      "TXT_PINCODE_LOCALITY": "GUTTAVALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532447,
      "TXT_PINCODE_LOCALITY": "THOTA VADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532448,
      "TXT_PINCODE_LOCALITY": "MAMIDIVALASA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532455,
      "TXT_PINCODE_LOCALITY": "KOTTURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532456,
      "TXT_PINCODE_LOCALITY": "BATTILLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532457,
      "TXT_PINCODE_LOCALITY": "NIVGAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532458,
      "TXT_PINCODE_LOCALITY": "LAXMINARASUPETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532459,
      "TXT_PINCODE_LOCALITY": "HIRAMANDALAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532460,
      "TXT_PINCODE_LOCALITY": "VEERAGHATTAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532461,
      "TXT_PINCODE_LOCALITY": "SEETHARAMPURAM INAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532462,
      "TXT_PINCODE_LOCALITY": "NEELANAGARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532463,
      "TXT_PINCODE_LOCALITY": "GARUGU BILLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532465,
      "TXT_PINCODE_LOCALITY": "SARU BUJJILI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532467,
      "TXT_PINCODE_LOCALITY": "MOKHASA RAJAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532468,
      "TXT_PINCODE_LOCALITY": "TALAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532469,
      "TXT_PINCODE_LOCALITY": "BALERU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532470,
      "TXT_PINCODE_LOCALITY": "RUSHINGI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532472,
      "TXT_PINCODE_LOCALITY": "PARAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532474,
      "TXT_PINCODE_LOCALITY": "TILARU R S",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532475,
      "TXT_PINCODE_LOCALITY": "PEDDA BAMMIDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532479,
      "TXT_PINCODE_LOCALITY": "PADALI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532480,
      "TXT_PINCODE_LOCALITY": "TULAGAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532484,
      "TXT_PINCODE_LOCALITY": "DUSI R S",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532485,
      "TXT_PINCODE_LOCALITY": "KANUGULA VALASA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532486,
      "TXT_PINCODE_LOCALITY": "RAGOLU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532487,
      "TXT_PINCODE_LOCALITY": "THOGARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532492,
      "TXT_PINCODE_LOCALITY": "AMPALAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532501,
      "TXT_PINCODE_LOCALITY": "KOTTABELAGAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532502,
      "TXT_PINCODE_LOCALITY": "PARVATHIPURAM TOWN",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532521,
      "TXT_PINCODE_LOCALITY": "KOMMARADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532522,
      "TXT_PINCODE_LOCALITY": "MRUTYUNJAYANAGARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532523,
      "TXT_PINCODE_LOCALITY": "GUMMALAXMIPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532524,
      "TXT_PINCODE_LOCALITY": "KURUPAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532525,
      "TXT_PINCODE_LOCALITY": "RAVI VALASA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532526,
      "TXT_PINCODE_LOCALITY": "MERANGI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532527,
      "TXT_PINCODE_LOCALITY": "PEDA BONDA PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532528,
      "TXT_PINCODE_LOCALITY": "ELVINPETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532529,
      "TXT_PINCODE_LOCALITY": "THOTAPALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532530,
      "TXT_PINCODE_LOCALITY": "PEDAMERANGI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532531,
      "TXT_PINCODE_LOCALITY": "NAGURU AGRAHARAN",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532532,
      "TXT_PINCODE_LOCALITY": "NARSIPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532534,
      "TXT_PINCODE_LOCALITY": "MONDEMKHALLU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532546,
      "TXT_PINCODE_LOCALITY": "SEETHANAGARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532547,
      "TXT_PINCODE_LOCALITY": "MAKKUVA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532548,
      "TXT_PINCODE_LOCALITY": "VENKATABHYRI PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532549,
      "TXT_PINCODE_LOCALITY": "GEDDALUPPE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532550,
      "TXT_PINCODE_LOCALITY": "HARIDIVALASA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532551,
      "TXT_PINCODE_LOCALITY": "BURJA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532552,
      "TXT_PINCODE_LOCALITY": "AJJADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532553,
      "TXT_PINCODE_LOCALITY": "KRISHNARAYAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532557,
      "TXT_PINCODE_LOCALITY": "BALIJI PETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532558,
      "TXT_PINCODE_LOCALITY": "BOBBLLI H O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532560,
      "TXT_PINCODE_LOCALITY": "PEDA PENKI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532562,
      "TXT_PINCODE_LOCALITY": "BARLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532563,
      "TXT_PINCODE_LOCALITY": "CHILAKALA PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532564,
      "TXT_PINCODE_LOCALITY": "CHINTADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532565,
      "TXT_PINCODE_LOCALITY": "KUMATI PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532566,
      "TXT_PINCODE_LOCALITY": "PARADI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532568,
      "TXT_PINCODE_LOCALITY": "PIRIDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532569,
      "TXT_PINCODE_LOCALITY": "PINA LANKI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532573,
      "TXT_PINCODE_LOCALITY": "BOBBILI CHURCH ROAD",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532578,
      "TXT_PINCODE_LOCALITY": "DONKINA VALASA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532579,
      "TXT_PINCODE_LOCALITY": "RAMA BHADRA PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532580,
      "TXT_PINCODE_LOCALITY": "PEDAMANA PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532581,
      "TXT_PINCODE_LOCALITY": "CHALLAPETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532582,
      "TXT_PINCODE_LOCALITY": "ARIKATHOTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532583,
      "TXT_PINCODE_LOCALITY": "ROMPALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532584,
      "TXT_PINCODE_LOCALITY": "BANDAGI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532585,
      "TXT_PINCODE_LOCALITY": "MUGADE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532586,
      "TXT_PINCODE_LOCALITY": "KORAPU KOTHAVALASA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532588,
      "TXT_PINCODE_LOCALITY": "MALLAM PETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532591,
      "TXT_PINCODE_LOCALITY": "SALURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532592,
      "TXT_PINCODE_LOCALITY": "PACHIPENTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532593,
      "TXT_PINCODE_LOCALITY": "MAMIDI PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532595,
      "TXT_PINCODE_LOCALITY": "P KONA VALASA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532598,
      "TXT_PINCODE_LOCALITY": "PEDAKADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532599,
      "TXT_PINCODE_LOCALITY": "AGRAHARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532601,
      "TXT_PINCODE_LOCALITY": "GOLLADI AGRAHARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532602,
      "TXT_PINCODE_LOCALITY": "GAJARAYUNI VALASA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532604,
      "TXT_PINCODE_LOCALITY": "CHOWDANTI VALASA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532612,
      "TXT_PINCODE_LOCALITY": "VIZIANAGARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532613,
      "TXT_PINCODE_LOCALITY": "GOLLA PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532616,
      "TXT_PINCODE_LOCALITY": "JAKKUVA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532617,
      "TXT_PINCODE_LOCALITY": "AGRHARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532618,
      "TXT_PINCODE_LOCALITY": "GORLA SEETHA RAM PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532619,
      "TXT_PINCODE_LOCALITY": "KOTA SEETHARAM PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532620,
      "TXT_PINCODE_LOCALITY": "PAKKI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532661,
      "TXT_PINCODE_LOCALITY": "PEDAMEDA PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532662,
      "TXT_PINCODE_LOCALITY": "MARADAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532663,
      "TXT_PINCODE_LOCALITY": "VIZIANAGARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532664,
      "TXT_PINCODE_LOCALITY": "JAYATI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 532665,
      "TXT_PINCODE_LOCALITY": "TARAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533001,
      "TXT_PINCODE_LOCALITY": "KAKINADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533002,
      "TXT_PINCODE_LOCALITY": "M S N CHARITIES",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533003,
      "TXT_PINCODE_LOCALITY": "BHASKAR NAGAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533004,
      "TXT_PINCODE_LOCALITY": "GANDHI NAGAR (KDA)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533005,
      "TXT_PINCODE_LOCALITY": "KAKINADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533006,
      "TXT_PINCODE_LOCALITY": "PEDA PUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533007,
      "TXT_PINCODE_LOCALITY": "KAKINADA PORT",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533008,
      "TXT_PINCODE_LOCALITY": "GOVT. GENERAL HOSPITAL",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533009,
      "TXT_PINCODE_LOCALITY": "A. P. I. I. C *",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533014,
      "TXT_PINCODE_LOCALITY": "GURZANAPALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533015,
      "TXT_PINCODE_LOCALITY": "THIMMAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533016,
      "TXT_PINCODE_LOCALITY": "TURANGI (KDA)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533101,
      "TXT_PINCODE_LOCALITY": "SWARAJYANAGAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533102,
      "TXT_PINCODE_LOCALITY": "KONTHAMURU S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533103,
      "TXT_PINCODE_LOCALITY": "RTC BUS COMPLEX CENTRE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533104,
      "TXT_PINCODE_LOCALITY": "SEETHAMPETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533105,
      "TXT_PINCODE_LOCALITY": "SRI RAMNAGAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533106,
      "TXT_PINCODE_LOCALITY": "SPINNING MILLS CENTRE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533107,
      "TXT_PINCODE_LOCALITY": "SATELLITE CITY S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533108,
      "TXT_PINCODE_LOCALITY": "TADITHOTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442350,
      "City District Name": "RAJAHMUNDRY (URBAN)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533109,
      "TXT_PINCODE_LOCALITY": "ALCOT GARDENS",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442350,
      "City District Name": "RAJAHMUNDRY (URBAN)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533110,
      "TXT_PINCODE_LOCALITY": "RAJAHMUNDRY",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 440152,
      "City District Name": "RAJAHMUNDHARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533124,
      "TXT_PINCODE_LOCALITY": "IND ESTATE D W E G DT",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533125,
      "TXT_PINCODE_LOCALITY": "DOWLISWARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533126,
      "TXT_PINCODE_LOCALITY": "KADIUM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533131,
      "TXT_PINCODE_LOCALITY": "MADIKI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533132,
      "TXT_PINCODE_LOCALITY": "JEGURU PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533133,
      "TXT_PINCODE_LOCALITY": "MURAMANDA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533134,
      "TXT_PINCODE_LOCALITY": "VEERA VARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533151,
      "TXT_PINCODE_LOCALITY": "KOLAMURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533201,
      "TXT_PINCODE_LOCALITY": "NEW COLONY",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533202,
      "TXT_PINCODE_LOCALITY": "R D OFFICE ROAOD",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533203,
      "TXT_PINCODE_LOCALITY": "COLLEGE (AMALAPURAM)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533210,
      "TXT_PINCODE_LOCALITY": "ALLAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533211,
      "TXT_PINCODE_LOCALITY": "MOOLAPOLAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533212,
      "TXT_PINCODE_LOCALITY": "PALLAMKURRU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533213,
      "TXT_PINCODE_LOCALITY": "CHALLA PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533214,
      "TXT_PINCODE_LOCALITY": "AMBAJIPETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533215,
      "TXT_PINCODE_LOCALITY": "BODASAKURRU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533216,
      "TXT_PINCODE_LOCALITY": "TALUK OFFICE ROAD",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533217,
      "TXT_PINCODE_LOCALITY": "ALLAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533218,
      "TXT_PINCODE_LOCALITY": "PERURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533219,
      "TXT_PINCODE_LOCALITY": "ISLAND POLVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533220,
      "TXT_PINCODE_LOCALITY": "KESANAKURRU PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533221,
      "TXT_PINCODE_LOCALITY": "BANDAR LANKA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533222,
      "TXT_PINCODE_LOCALITY": "ANANTAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533223,
      "TXT_PINCODE_LOCALITY": "KOTHAPETA H O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533224,
      "TXT_PINCODE_LOCALITY": "MUNGANDA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533225,
      "TXT_PINCODE_LOCALITY": "CHEYYERU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533226,
      "TXT_PINCODE_LOCALITY": "N KOTHA PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533227,
      "TXT_PINCODE_LOCALITY": "UPPALAGUPTAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533228,
      "TXT_PINCODE_LOCALITY": "VANAPALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533229,
      "TXT_PINCODE_LOCALITY": "PALIVELA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533230,
      "TXT_PINCODE_LOCALITY": "AVIDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533231,
      "TXT_PINCODE_LOCALITY": "NARENDRAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533232,
      "TXT_PINCODE_LOCALITY": "CHINTALURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533233,
      "TXT_PINCODE_LOCALITY": "JONNADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533234,
      "TXT_PINCODE_LOCALITY": "YEDITHA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533235,
      "TXT_PINCODE_LOCALITY": "ATREYAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533236,
      "TXT_PINCODE_LOCALITY": "RYALI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533237,
      "TXT_PINCODE_LOCALITY": "VUBALANKA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533238,
      "TXT_PINCODE_LOCALITY": "RAVULA PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533239,
      "TXT_PINCODE_LOCALITY": "PULLETI KURRU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533240,
      "TXT_PINCODE_LOCALITY": "DELTA GANNAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533241,
      "TXT_PINCODE_LOCALITY": "NEDUNURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533242,
      "TXT_PINCODE_LOCALITY": "BAZOLE H O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533243,
      "TXT_PINCODE_LOCALITY": "EAST GODAVARI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533244,
      "TXT_PINCODE_LOCALITY": "SIVA KODU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533245,
      "TXT_PINCODE_LOCALITY": "GUDIMELLANKA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533247,
      "TXT_PINCODE_LOCALITY": "NAGARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533248,
      "TXT_PINCODE_LOCALITY": "PONNAMANDA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533249,
      "TXT_PINCODE_LOCALITY": "TATIPAKA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533250,
      "TXT_PINCODE_LOCALITY": "MORI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533251,
      "TXT_PINCODE_LOCALITY": "SAKHINETI PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533252,
      "TXT_PINCODE_LOCALITY": "ANTARVEDIPALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533253,
      "TXT_PINCODE_LOCALITY": "MALLIKIPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533254,
      "TXT_PINCODE_LOCALITY": "ANTERVEDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533255,
      "TXT_PINCODE_LOCALITY": "RAMA CHANDRA PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533256,
      "TXT_PINCODE_LOCALITY": "RAMA RAJULANKA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533258,
      "TXT_PINCODE_LOCALITY": "KATKRENI PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533260,
      "TXT_PINCODE_LOCALITY": "VELANGI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533261,
      "TXT_PINCODE_LOCALITY": "CHELLURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533262,
      "TXT_PINCODE_LOCALITY": "VELLAPALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533263,
      "TXT_PINCODE_LOCALITY": "DANGERU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533264,
      "TXT_PINCODE_LOCALITY": "KUTUKULURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533265,
      "TXT_PINCODE_LOCALITY": "MATSAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533266,
      "TXT_PINCODE_LOCALITY": "PULAGURTHA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533268,
      "TXT_PINCODE_LOCALITY": "VADREVU PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533270,
      "TXT_PINCODE_LOCALITY": "SANKARA GUPTAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533271,
      "TXT_PINCODE_LOCALITY": "PASALA PUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533272,
      "TXT_PINCODE_LOCALITY": "PENUMALLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533273,
      "TXT_PINCODE_LOCALITY": "KAJALURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533274,
      "TXT_PINCODE_LOCALITY": "NORTH GOPALA PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533275,
      "TXT_PINCODE_LOCALITY": "EAST GODAVARI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533277,
      "TXT_PINCODE_LOCALITY": "VUDUMUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533278,
      "TXT_PINCODE_LOCALITY": "SOMESWARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533281,
      "TXT_PINCODE_LOCALITY": "GADALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533282,
      "TXT_PINCODE_LOCALITY": "MALLAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442270,
      "City District Name": "GOKAVARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533284,
      "TXT_PINCODE_LOCALITY": "KOTHARAMAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442268,
      "City District Name": "GANGAVARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533285,
      "TXT_PINCODE_LOCALITY": "G KOTHAPALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533286,
      "TXT_PINCODE_LOCALITY": "GOKARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533287,
      "TXT_PINCODE_LOCALITY": "SEETHANAGARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533288,
      "TXT_PINCODE_LOCALITY": "GEDDADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533289,
      "TXT_PINCODE_LOCALITY": "GADARADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533290,
      "TXT_PINCODE_LOCALITY": "KOTI KESAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533291,
      "TXT_PINCODE_LOCALITY": "RANGAMPETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533292,
      "TXT_PINCODE_LOCALITY": "DOSAKAYALA PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533293,
      "TXT_PINCODE_LOCALITY": "TORREDU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533294,
      "TXT_PINCODE_LOCALITY": "RAJANAGARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533295,
      "TXT_PINCODE_LOCALITY": "MAREDUMILLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533296,
      "TXT_PINCODE_LOCALITY": "KANAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533297,
      "TXT_PINCODE_LOCALITY": "MUGGULLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533298,
      "TXT_PINCODE_LOCALITY": "GUMMAULA DODDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533299,
      "TXT_PINCODE_LOCALITY": "PALIVELA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533301,
      "TXT_PINCODE_LOCALITY": "JAMBUPATNAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533304,
      "TXT_PINCODE_LOCALITY": "BUCHAM PETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533305,
      "TXT_PINCODE_LOCALITY": "PAMMARRU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533306,
      "TXT_PINCODE_LOCALITY": "KOTI PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533307,
      "TXT_PINCODE_LOCALITY": "ANGARA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533308,
      "TXT_PINCODE_LOCALITY": "MANDA PETA H O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533309,
      "TXT_PINCODE_LOCALITY": "KAPILESWARA PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533310,
      "TXT_PINCODE_LOCALITY": "NALLURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533311,
      "TXT_PINCODE_LOCALITY": "YENDAAGANDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533312,
      "TXT_PINCODE_LOCALITY": "TEKI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533314,
      "TXT_PINCODE_LOCALITY": "KORUMILLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533315,
      "TXT_PINCODE_LOCALITY": "ATCHUTAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533316,
      "TXT_PINCODE_LOCALITY": "GANGAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533317,
      "TXT_PINCODE_LOCALITY": "MACHARA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533318,
      "TXT_PINCODE_LOCALITY": "SIVALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533319,
      "TXT_PINCODE_LOCALITY": "RAMPAYERRAM PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533320,
      "TXT_PINCODE_LOCALITY": "MIRTHIPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533322,
      "TXT_PINCODE_LOCALITY": "MULAKALLANKA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533323,
      "TXT_PINCODE_LOCALITY": "MUNI KODALI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533324,
      "TXT_PINCODE_LOCALITY": "SITAPALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533325,
      "TXT_PINCODE_LOCALITY": "PURUSHOTTA PATNAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533326,
      "TXT_PINCODE_LOCALITY": "GANDEPALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533327,
      "TXT_PINCODE_LOCALITY": "VADIALERU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533328,
      "TXT_PINCODE_LOCALITY": "PALLAKADIYAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533330,
      "TXT_PINCODE_LOCALITY": "VELUGU BANDA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533333,
      "TXT_PINCODE_LOCALITY": "RAGHUDEVAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533334,
      "TXT_PINCODE_LOCALITY": "EAST GONAGUDEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533335,
      "TXT_PINCODE_LOCALITY": "NARENDRAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533338,
      "TXT_PINCODE_LOCALITY": "TANTI KONDA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533339,
      "TXT_PINCODE_LOCALITY": "DEVIPATNAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533340,
      "TXT_PINCODE_LOCALITY": "TAPESWARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533341,
      "TXT_PINCODE_LOCALITY": "DWARA PUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533342,
      "TXT_PINCODE_LOCALITY": "POLAMURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533343,
      "TXT_PINCODE_LOCALITY": "BICCAVOLU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533344,
      "TXT_PINCODE_LOCALITY": "GOLLALA MAMIDADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533345,
      "TXT_PINCODE_LOCALITY": "PANDALA PAKA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533346,
      "TXT_PINCODE_LOCALITY": "RAYAVARAM (EG)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533347,
      "TXT_PINCODE_LOCALITY": "EAST GODAVARI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533348,
      "TXT_PINCODE_LOCALITY": "EAST GODAVARI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533349,
      "TXT_PINCODE_LOCALITY": "EAST GODAVARI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533350,
      "TXT_PINCODE_LOCALITY": "RAMCHANDRAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533351,
      "TXT_PINCODE_LOCALITY": "EAST GODAVARI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533352,
      "TXT_PINCODE_LOCALITY": "VELAGA THODU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533354,
      "TXT_PINCODE_LOCALITY": "PALATHODU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533355,
      "TXT_PINCODE_LOCALITY": "VENDURU PAKA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533356,
      "TXT_PINCODE_LOCALITY": "KESAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533358,
      "TXT_PINCODE_LOCALITY": "VENDURU PAKA SAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533363,
      "TXT_PINCODE_LOCALITY": "RAMANAYYAPETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533364,
      "TXT_PINCODE_LOCALITY": "KOPPAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533365,
      "TXT_PINCODE_LOCALITY": "MUKKINADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533366,
      "TXT_PINCODE_LOCALITY": "RAJU PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533368,
      "TXT_PINCODE_LOCALITY": "SRI KRISHNA PATNAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533369,
      "TXT_PINCODE_LOCALITY": "MUSURUMILLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533370,
      "TXT_PINCODE_LOCALITY": "KURADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533371,
      "TXT_PINCODE_LOCALITY": "YANUMADLA",
      "NUM_STATE_CD": 164,
      "State Name": "PONDICHERRY",
      "NUM_CITYDISTRICT_CD": 424443,
      "City District Name": "YANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533372,
      "TXT_PINCODE_LOCALITY": "KOTHURU (KDA)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533373,
      "TXT_PINCODE_LOCALITY": "KURMA PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533374,
      "TXT_PINCODE_LOCALITY": "KOYYERU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533375,
      "TXT_PINCODE_LOCALITY": "VANAGALAPUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533381,
      "TXT_PINCODE_LOCALITY": "PENIKERU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533382,
      "TXT_PINCODE_LOCALITY": "VADDI PARRU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533383,
      "TXT_PINCODE_LOCALITY": "PERAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533387,
      "TXT_PINCODE_LOCALITY": "KOMARAJU LANKA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533400,
      "TXT_PINCODE_LOCALITY": "PYDIKONDA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442325,
      "City District Name": "THONDANGI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533401,
      "TXT_PINCODE_LOCALITY": "TUNI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533402,
      "TXT_PINCODE_LOCALITY": "HAMSAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533406,
      "TXT_PINCODE_LOCALITY": "ANNAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533407,
      "TXT_PINCODE_LOCALITY": "KOTA NANDURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533408,
      "TXT_PINCODE_LOCALITY": "THONDANGI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533409,
      "TXT_PINCODE_LOCALITY": "BENDA PUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533410,
      "TXT_PINCODE_LOCALITY": "BHIMAVARAPU KOTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533428,
      "TXT_PINCODE_LOCALITY": "ADDATIGALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533429,
      "TXT_PINCODE_LOCALITY": "YELESRARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533430,
      "TXT_PINCODE_LOCALITY": "DHARMAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533431,
      "TXT_PINCODE_LOCALITY": "CHILLANGI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533432,
      "TXT_PINCODE_LOCALITY": "PRATHI PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533433,
      "TXT_PINCODE_LOCALITY": "MALLAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533434,
      "TXT_PINCODE_LOCALITY": "VETLA PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533435,
      "TXT_PINCODE_LOCALITY": "JAGGAMPETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533436,
      "TXT_PINCODE_LOCALITY": "KONDA PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533437,
      "TXT_PINCODE_LOCALITY": "PEDDAPURAM TOWN",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533439,
      "TXT_PINCODE_LOCALITY": "MALLEPALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533440,
      "TXT_PINCODE_LOCALITY": "PENSION LINES",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533441,
      "TXT_PINCODE_LOCALITY": "ATCHAMPETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533442,
      "TXT_PINCODE_LOCALITY": "VADLA MARRU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533443,
      "TXT_PINCODE_LOCALITY": "LINGAMPARTHI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533444,
      "TXT_PINCODE_LOCALITY": "PEDA BRAHMA DEVAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533445,
      "TXT_PINCODE_LOCALITY": "GOLLA PROLU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533446,
      "TXT_PINCODE_LOCALITY": "SANKHAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533447,
      "TXT_PINCODE_LOCALITY": "U KOTHAPALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533448,
      "TXT_PINCODE_LOCALITY": "UPPADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533449,
      "TXT_PINCODE_LOCALITY": "CHEBROLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533450,
      "TXT_PINCODE_LOCALITY": "PITHAPURAM TOWN",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533451,
      "TXT_PINCODE_LOCALITY": "KONDEVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533452,
      "TXT_PINCODE_LOCALITY": "SRUNGAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533454,
      "TXT_PINCODE_LOCALITY": "NAGULA PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533455,
      "TXT_PINCODE_LOCALITY": "KATHIPUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533456,
      "TXT_PINCODE_LOCALITY": "CHINTALURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533458,
      "TXT_PINCODE_LOCALITY": "SANTIASHRAMAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533459,
      "TXT_PINCODE_LOCALITY": "S PYDIPALA (SANTHA PYDIPA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533461,
      "TXT_PINCODE_LOCALITY": "CHORINGA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533462,
      "TXT_PINCODE_LOCALITY": "KARAPA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533463,
      "TXT_PINCODE_LOCALITY": "TALLA REVU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533464,
      "TXT_PINCODE_LOCALITY": "NEELAPALLE S. O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533465,
      "TXT_PINCODE_LOCALITY": "INJARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533466,
      "TXT_PINCODE_LOCALITY": "KOTHAPALLI (YANAM)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533467,
      "TXT_PINCODE_LOCALITY": "GUTTENA DEVI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533468,
      "TXT_PINCODE_LOCALITY": "GOLLA PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533469,
      "TXT_PINCODE_LOCALITY": "PEDDA PURRA PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533471,
      "TXT_PINCODE_LOCALITY": "KANDREGULA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533473,
      "TXT_PINCODE_LOCALITY": "KOLANKA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533474,
      "TXT_PINCODE_LOCALITY": "YEDURULANKA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533475,
      "TXT_PINCODE_LOCALITY": "PILLANKA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533477,
      "TXT_PINCODE_LOCALITY": "PATAVALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533480,
      "TXT_PINCODE_LOCALITY": "RAMANYYAPETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533481,
      "TXT_PINCODE_LOCALITY": "KATTAMURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533482,
      "TXT_PINCODE_LOCALITY": "MEDAPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533483,
      "TXT_PINCODE_LOCALITY": "RACHA PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533485,
      "TXT_PINCODE_LOCALITY": "VEERA VARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533486,
      "TXT_PINCODE_LOCALITY": "J BURUGUPUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533487,
      "TXT_PINCODE_LOCALITY": "JALLURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533488,
      "TXT_PINCODE_LOCALITY": "VOMMANGI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533490,
      "TXT_PINCODE_LOCALITY": "RAYA BHUPALA PATNAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533491,
      "TXT_PINCODE_LOCALITY": "J ANNAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533493,
      "TXT_PINCODE_LOCALITY": "DIVILI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533494,
      "TXT_PINCODE_LOCALITY": "MALLISALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533495,
      "TXT_PINCODE_LOCALITY": "RAMAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533496,
      "TXT_PINCODE_LOCALITY": "JADDANGI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533498,
      "TXT_PINCODE_LOCALITY": "LAGARAYI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533499,
      "TXT_PINCODE_LOCALITY": "YELLAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533501,
      "TXT_PINCODE_LOCALITY": "KANDI KUPPA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533503,
      "TXT_PINCODE_LOCALITY": "EAST GODAVARI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533506,
      "TXT_PINCODE_LOCALITY": "KUNAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533509,
      "TXT_PINCODE_LOCALITY": "AINAVILLILANKA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533512,
      "TXT_PINCODE_LOCALITY": "UPPUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533514,
      "TXT_PINCODE_LOCALITY": "BHEEMANA PALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533515,
      "TXT_PINCODE_LOCALITY": "GUNNE PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533519,
      "TXT_PINCODE_LOCALITY": "VELI CHERU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533521,
      "TXT_PINCODE_LOCALITY": "ITHAKOTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533522,
      "TXT_PINCODE_LOCALITY": "DEVARA PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533523,
      "TXT_PINCODE_LOCALITY": "NARSIPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533529,
      "TXT_PINCODE_LOCALITY": "K PEDA PUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533536,
      "TXT_PINCODE_LOCALITY": "PASARLAPUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533538,
      "TXT_PINCODE_LOCALITY": "SIRI PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533541,
      "TXT_PINCODE_LOCALITY": "LAKKAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533544,
      "TXT_PINCODE_LOCALITY": "TANELANKA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533545,
      "TXT_PINCODE_LOCALITY": "KOTHA LANKA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533546,
      "TXT_PINCODE_LOCALITY": "AINAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533548,
      "TXT_PINCODE_LOCALITY": "GANGALA KURRU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533549,
      "TXT_PINCODE_LOCALITY": "RAJAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533551,
      "TXT_PINCODE_LOCALITY": "EAST GODAVARI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533554,
      "TXT_PINCODE_LOCALITY": "EAST GODAVARI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533555,
      "TXT_PINCODE_LOCALITY": "CHINA KOTHALANKA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533557,
      "TXT_PINCODE_LOCALITY": "APPANNAPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533559,
      "TXT_PINCODE_LOCALITY": "AINAVILLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533560,
      "TXT_PINCODE_LOCALITY": "ROUTHULAPUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533561,
      "TXT_PINCODE_LOCALITY": "DURGADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533562,
      "TXT_PINCODE_LOCALITY": "A MALLAVARUM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533563,
      "TXT_PINCODE_LOCALITY": "CHITRADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533564,
      "TXT_PINCODE_LOCALITY": "KOMMARAGIRI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533566,
      "TXT_PINCODE_LOCALITY": "PEDA PATNAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533567,
      "TXT_PINCODE_LOCALITY": "VELLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533577,
      "TXT_PINCODE_LOCALITY": "VILASA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533581,
      "TXT_PINCODE_LOCALITY": "TAMARADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533583,
      "TXT_PINCODE_LOCALITY": "Y RAMAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533592,
      "TXT_PINCODE_LOCALITY": "VETAGANIVALASA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442312,
      "City District Name": "PACHIPENTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533621,
      "TXT_PINCODE_LOCALITY": "RAMA CHANDRA PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533623,
      "TXT_PINCODE_LOCALITY": "KATAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533640,
      "TXT_PINCODE_LOCALITY": "KULLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533646,
      "TXT_PINCODE_LOCALITY": "GODAVARI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 425614,
      "City District Name": "GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533647,
      "TXT_PINCODE_LOCALITY": "KOTHAPETA (VKD)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 533691,
      "TXT_PINCODE_LOCALITY": "KATRAVULA PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534001,
      "TXT_PINCODE_LOCALITY": "BENARJIPETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534002,
      "TXT_PINCODE_LOCALITY": "RAMACHANDERRAOPETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534003,
      "TXT_PINCODE_LOCALITY": "SANIVARAPU PETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534004,
      "TXT_PINCODE_LOCALITY": "JALI PUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534005,
      "TXT_PINCODE_LOCALITY": "TANGELLAMUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534006,
      "TXT_PINCODE_LOCALITY": "NARASIMHARAO PETA (ELURU)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534007,
      "TXT_PINCODE_LOCALITY": "ELURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 440188,
      "City District Name": "WEST  GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534015,
      "TXT_PINCODE_LOCALITY": "WEST GODAVARI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534101,
      "TXT_PINCODE_LOCALITY": "PRAKASAM MUNICIPAL MARKET",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534102,
      "TXT_PINCODE_LOCALITY": "RAMACHANDRARAOPETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534108,
      "TXT_PINCODE_LOCALITY": "VENKATA RAMANNA GUDEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534111,
      "TXT_PINCODE_LOCALITY": "ANANTAPALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534112,
      "TXT_PINCODE_LOCALITY": "PULALA PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534114,
      "TXT_PINCODE_LOCALITY": "PRAKASA RAO PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534115,
      "TXT_PINCODE_LOCALITY": "TELIKI CHERLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534116,
      "TXT_PINCODE_LOCALITY": "GUNDE PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534122,
      "TXT_PINCODE_LOCALITY": "PANDITHA VILLURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534123,
      "TXT_PINCODE_LOCALITY": "ACHANTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534124,
      "TXT_PINCODE_LOCALITY": "PENUMANTRA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534125,
      "TXT_PINCODE_LOCALITY": "DEVA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534126,
      "TXT_PINCODE_LOCALITY": "ALAMURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534127,
      "TXT_PINCODE_LOCALITY": "MULAPARRU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534129,
      "TXT_PINCODE_LOCALITY": "NELAMURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534131,
      "TXT_PINCODE_LOCALITY": "MANCHILI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424361,
      "City District Name": "ATTILI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534134,
      "TXT_PINCODE_LOCALITY": "VENDODU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534135,
      "TXT_PINCODE_LOCALITY": "KOMMARRU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534136,
      "TXT_PINCODE_LOCALITY": "PALI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534137,
      "TXT_PINCODE_LOCALITY": "MANCHILI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534145,
      "TXT_PINCODE_LOCALITY": "MADHAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534146,
      "TXT_PINCODE_LOCALITY": "NAWABU PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534147,
      "TXT_PINCODE_LOCALITY": "JAGANNADHAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534150,
      "TXT_PINCODE_LOCALITY": "ALAMPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534152,
      "TXT_PINCODE_LOCALITY": "ARUGOLANU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534153,
      "TXT_PINCODE_LOCALITY": "KONALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534154,
      "TXT_PINCODE_LOCALITY": "NANDA MURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534155,
      "TXT_PINCODE_LOCALITY": "VEERAM PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534156,
      "TXT_PINCODE_LOCALITY": "PASALPUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534157,
      "TXT_PINCODE_LOCALITY": "RAVI PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534160,
      "TXT_PINCODE_LOCALITY": "WEST GODAVARI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 440188,
      "City District Name": "WEST  GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534165,
      "TXT_PINCODE_LOCALITY": "WEST VIPPARRU S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 440188,
      "City District Name": "WEST  GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534166,
      "TXT_PINCODE_LOCALITY": "PENTA PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534167,
      "TXT_PINCODE_LOCALITY": "PARIMELLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534168,
      "TXT_PINCODE_LOCALITY": "JATLAPALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534176,
      "TXT_PINCODE_LOCALITY": "POTHA VARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534186,
      "TXT_PINCODE_LOCALITY": "YENDAGANDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534187,
      "TXT_PINCODE_LOCALITY": "UNDURRU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534189,
      "TXT_PINCODE_LOCALITY": "KOTHAPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442267,
      "City District Name": "GANAPAVARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534195,
      "TXT_PINCODE_LOCALITY": "PEDANINDRE KOLANU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534196,
      "TXT_PINCODE_LOCALITY": "CH AGRAHARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534197,
      "TXT_PINCODE_LOCALITY": "PIPPARA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534198,
      "TXT_PINCODE_LOCALITY": "VELAGA PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534199,
      "TXT_PINCODE_LOCALITY": "UNDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534201,
      "TXT_PINCODE_LOCALITY": "BHIMAVARAM S. O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 440188,
      "City District Name": "WEST  GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534202,
      "TXT_PINCODE_LOCALITY": "SURYANARAYANAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534203,
      "TXT_PINCODE_LOCALITY": "BHIMAVARAM RAILWAY",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534204,
      "TXT_PINCODE_LOCALITY": "S.R.K.R .ENGG.COLLEGE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 440188,
      "City District Name": "WEST  GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534206,
      "TXT_PINCODE_LOCALITY": "BONDADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534207,
      "TXT_PINCODE_LOCALITY": "BHIMAVARAM MANDALAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 440188,
      "City District Name": "WEST  GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534208,
      "TXT_PINCODE_LOCALITY": "RAYALAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534209,
      "TXT_PINCODE_LOCALITY": "MOGALLU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534210,
      "TXT_PINCODE_LOCALITY": "PALAKODERU S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 440188,
      "City District Name": "WEST  GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534211,
      "TXT_PINCODE_LOCALITY": "RTC BUS COMPLEX",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534215,
      "TXT_PINCODE_LOCALITY": "VENKATRAYA PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534216,
      "TXT_PINCODE_LOCALITY": "VUNDRAJAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534217,
      "TXT_PINCODE_LOCALITY": "IRAGAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534218,
      "TXT_PINCODE_LOCALITY": "MANDA PAKA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534221,
      "TXT_PINCODE_LOCALITY": "KOTHA PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534222,
      "TXT_PINCODE_LOCALITY": "VELPURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534224,
      "TXT_PINCODE_LOCALITY": "SATYAWADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534225,
      "TXT_PINCODE_LOCALITY": "RELANGI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534227,
      "TXT_PINCODE_LOCALITY": "MORTHA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534229,
      "TXT_PINCODE_LOCALITY": "PENDYALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4823,
      "City District Name": "NIDADAVOLE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534230,
      "TXT_PINCODE_LOCALITY": "ARAVALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534231,
      "TXT_PINCODE_LOCALITY": "VENDRA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534232,
      "TXT_PINCODE_LOCALITY": "KONDEPUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534233,
      "TXT_PINCODE_LOCALITY": "CHORUKU MILLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534234,
      "TXT_PINCODE_LOCALITY": "UNKILI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534235,
      "TXT_PINCODE_LOCALITY": "AKIVIDU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534236,
      "TXT_PINCODE_LOCALITY": "KALVAPUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534237,
      "TXT_PINCODE_LOCALITY": "KOLANAPALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534238,
      "TXT_PINCODE_LOCALITY": "WEST GODAVARI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 440188,
      "City District Name": "WEST  GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534239,
      "TXT_PINCODE_LOCALITY": "DIRUSU MARRU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534240,
      "TXT_PINCODE_LOCALITY": "KONITIWADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534242,
      "TXT_PINCODE_LOCALITY": "CHINA KAPAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534243,
      "TXT_PINCODE_LOCALITY": "SRUNGA VRUKSHAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534244,
      "TXT_PINCODE_LOCALITY": "VISS KODERU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534245,
      "TXT_PINCODE_LOCALITY": "VEERA VASARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534247,
      "TXT_PINCODE_LOCALITY": "RAYA KUDURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534248,
      "TXT_PINCODE_LOCALITY": "ENAMADURRU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534249,
      "TXT_PINCODE_LOCALITY": "MADUGUPOLAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534250,
      "TXT_PINCODE_LOCALITY": "LANKALA KODERU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534251,
      "TXT_PINCODE_LOCALITY": "WEST GODAVARI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 440188,
      "City District Name": "WEST  GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534260,
      "TXT_PINCODE_LOCALITY": "GOPURAM STREET",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534261,
      "TXT_PINCODE_LOCALITY": "WEST GODAVARI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 440188,
      "City District Name": "WEST  GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534265,
      "TXT_PINCODE_LOCALITY": "JINNURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534266,
      "TXT_PINCODE_LOCALITY": "DODDI PATLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534267,
      "TXT_PINCODE_LOCALITY": "GUMMALURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534268,
      "TXT_PINCODE_LOCALITY": "ELLAMANCHILI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534269,
      "TXT_PINCODE_LOCALITY": "KODAMANCHILI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534270,
      "TXT_PINCODE_LOCALITY": "VALLURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534271,
      "TXT_PINCODE_LOCALITY": "RUSTUMBADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534272,
      "TXT_PINCODE_LOCALITY": "VEMAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534273,
      "TXT_PINCODE_LOCALITY": "PENUMADAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534274,
      "TXT_PINCODE_LOCALITY": "ILLAPA KURRU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534275,
      "TXT_PINCODE_LOCALITY": "SIVALAYAN STREET",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534277,
      "TXT_PINCODE_LOCALITY": "KOPPARRU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534279,
      "TXT_PINCODE_LOCALITY": "MALAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534280,
      "TXT_PINCODE_LOCALITY": "SITA RAM PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534281,
      "TXT_PINCODE_LOCALITY": "MOGALTHUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534282,
      "TXT_PINCODE_LOCALITY": "KALI PATNAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534283,
      "TXT_PINCODE_LOCALITY": "PERUPALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534284,
      "TXT_PINCODE_LOCALITY": "TURUPUTALLU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534291,
      "TXT_PINCODE_LOCALITY": "L B CHERLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534292,
      "TXT_PINCODE_LOCALITY": "VEDANGI PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534294,
      "TXT_PINCODE_LOCALITY": "LAXMANESWARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534295,
      "TXT_PINCODE_LOCALITY": "VEMULA DEEVI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534300,
      "TXT_PINCODE_LOCALITY": "UNAKARAMILLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4823,
      "City District Name": "NIDADAVOLE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534301,
      "TXT_PINCODE_LOCALITY": "WEDNESDAY MARKET",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534302,
      "TXT_PINCODE_LOCALITY": "SAMSRA GUDEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534303,
      "TXT_PINCODE_LOCALITY": "RAMNAGAR (NDVLE)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534304,
      "TXT_PINCODE_LOCALITY": "MARKONDAPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534305,
      "TXT_PINCODE_LOCALITY": "TADIMALLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534306,
      "TXT_PINCODE_LOCALITY": "BRAHMANA GUDEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534307,
      "TXT_PINCODE_LOCALITY": "DARAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534310,
      "TXT_PINCODE_LOCALITY": "SINGAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534311,
      "TXT_PINCODE_LOCALITY": "KANNAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534312,
      "TXT_PINCODE_LOCALITY": "DIPPAKAYALAPUDU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534313,
      "TXT_PINCODE_LOCALITY": "DEVARA PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534314,
      "TXT_PINCODE_LOCALITY": "WEST GODAVARI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 440188,
      "City District Name": "WEST  GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534315,
      "TXT_PINCODE_LOCALITY": "PATTISAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534316,
      "TXT_PINCODE_LOCALITY": "GOPALA PURAM (W G)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534318,
      "TXT_PINCODE_LOCALITY": "GOPALAPURAM MANDALAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 440188,
      "City District Name": "WEST  GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534319,
      "TXT_PINCODE_LOCALITY": "CHITYALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534320,
      "TXT_PINCODE_LOCALITY": "PENU GONDA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534321,
      "TXT_PINCODE_LOCALITY": "PAKERU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534322,
      "TXT_PINCODE_LOCALITY": "KAKI LERU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534323,
      "TXT_PINCODE_LOCALITY": "KOTHALA PARRU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534324,
      "TXT_PINCODE_LOCALITY": "MALLESWARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424380,
      "City District Name": "PERAVALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534326,
      "TXT_PINCODE_LOCALITY": "SIDHANTAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534327,
      "TXT_PINCODE_LOCALITY": "PODURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534328,
      "TXT_PINCODE_LOCALITY": "PERAVALI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534329,
      "TXT_PINCODE_LOCALITY": "KANURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534330,
      "TXT_PINCODE_LOCALITY": "KHANDA VALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534331,
      "TXT_PINCODE_LOCALITY": "KAKARA PARRU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534332,
      "TXT_PINCODE_LOCALITY": "EAST VIPPARRU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534334,
      "TXT_PINCODE_LOCALITY": "TIPARRU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534335,
      "TXT_PINCODE_LOCALITY": "KAPAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534336,
      "TXT_PINCODE_LOCALITY": "MUKKAMALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534337,
      "TXT_PINCODE_LOCALITY": "KANURU AGRAHARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534338,
      "TXT_PINCODE_LOCALITY": "KAVITAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534339,
      "TXT_PINCODE_LOCALITY": "AJJARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534340,
      "TXT_PINCODE_LOCALITY": "VEGESWARA PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534341,
      "TXT_PINCODE_LOCALITY": "TALLA PUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534342,
      "TXT_PINCODE_LOCALITY": "CHAGALLU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534343,
      "TXT_PINCODE_LOCALITY": "GUTALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534344,
      "TXT_PINCODE_LOCALITY": "ANNADEVARAPETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534345,
      "TXT_PINCODE_LOCALITY": "MALAKAPALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534346,
      "TXT_PINCODE_LOCALITY": "PASI VEDALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534350,
      "TXT_PINCODE_LOCALITY": "DOMMERU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534351,
      "TXT_PINCODE_LOCALITY": "WEST GODAVARI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 440188,
      "City District Name": "WEST  GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534352,
      "TXT_PINCODE_LOCALITY": "KUMARADEVAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534354,
      "TXT_PINCODE_LOCALITY": "CHIDIPI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534355,
      "TXT_PINCODE_LOCALITY": "VELI VENNU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534356,
      "TXT_PINCODE_LOCALITY": "KALDHARI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534357,
      "TXT_PINCODE_LOCALITY": "SETTI PETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534360,
      "TXT_PINCODE_LOCALITY": "KOVVUR HO.",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534361,
      "TXT_PINCODE_LOCALITY": "LOSARI GUTLA PADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534362,
      "TXT_PINCODE_LOCALITY": "GOLLAVANI TIPPA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534364,
      "TXT_PINCODE_LOCALITY": "AL BHIMAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534366,
      "TXT_PINCODE_LOCALITY": "PEDA KAPAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534371,
      "TXT_PINCODE_LOCALITY": "MUDUNURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534372,
      "TXT_PINCODE_LOCALITY": "VALLURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534373,
      "TXT_PINCODE_LOCALITY": "CHERUKUWADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534374,
      "TXT_PINCODE_LOCALITY": "N R P AGRAHARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534375,
      "TXT_PINCODE_LOCALITY": "AGRAHARA COPAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534377,
      "TXT_PINCODE_LOCALITY": "CHINTAPALLI (AGRAHARAM)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534378,
      "TXT_PINCODE_LOCALITY": "KASIPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534389,
      "TXT_PINCODE_LOCALITY": "KORUMAMIDI (TADIMELLA)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534390,
      "TXT_PINCODE_LOCALITY": "D MUPPAVARAM DRONAMRAJU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534391,
      "TXT_PINCODE_LOCALITY": "PENDYALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534392,
      "TXT_PINCODE_LOCALITY": "PURSHOTHAPALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534393,
      "TXT_PINCODE_LOCALITY": "MALLESWARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534394,
      "TXT_PINCODE_LOCALITY": "GOPAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534401,
      "TXT_PINCODE_LOCALITY": "MM PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534402,
      "TXT_PINCODE_LOCALITY": "D.MUPPAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4823,
      "City District Name": "NIDADAVOLE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534406,
      "TXT_PINCODE_LOCALITY": "RACHURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 440188,
      "City District Name": "WEST  GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534407,
      "TXT_PINCODE_LOCALITY": "NARAYANA PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534408,
      "TXT_PINCODE_LOCALITY": "CHINANINDRA KOLANU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534409,
      "TXT_PINCODE_LOCALITY": "DUBECHERLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534410,
      "TXT_PINCODE_LOCALITY": "GOLLA GUDEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534411,
      "TXT_PINCODE_LOCALITY": "BADAM PUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534413,
      "TXT_PINCODE_LOCALITY": "BOMMIDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534416,
      "TXT_PINCODE_LOCALITY": "KAIKARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534425,
      "TXT_PINCODE_LOCALITY": "BHIMA DOLU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534426,
      "TXT_PINCODE_LOCALITY": "DWARAKA TIRUMALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534427,
      "TXT_PINCODE_LOCALITY": "CHETTUNNAPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534428,
      "TXT_PINCODE_LOCALITY": "PANGIDI GUDEM (R)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534429,
      "TXT_PINCODE_LOCALITY": "WEST GODAVARI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 440188,
      "City District Name": "WEST  GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534430,
      "TXT_PINCODE_LOCALITY": "GOPALA PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534432,
      "TXT_PINCODE_LOCALITY": "DENDULURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534435,
      "TXT_PINCODE_LOCALITY": "AGADALLANKA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534436,
      "TXT_PINCODE_LOCALITY": "TIRUMALA PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534437,
      "TXT_PINCODE_LOCALITY": "GUDIPADU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534438,
      "TXT_PINCODE_LOCALITY": "PUNUKOLLU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534439,
      "TXT_PINCODE_LOCALITY": "GOGUNTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534442,
      "TXT_PINCODE_LOCALITY": "KOVVALI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534444,
      "TXT_PINCODE_LOCALITY": "WEST GODAVARI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 440188,
      "City District Name": "WEST  GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534445,
      "TXT_PINCODE_LOCALITY": "VAKALAPUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442351,
      "City District Name": "T.NARASAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534447,
      "TXT_PINCODE_LOCALITY": "JANGA REDDI GUDEM H O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534448,
      "TXT_PINCODE_LOCALITY": "RACHUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534449,
      "TXT_PINCODE_LOCALITY": "KAMAVARAPU KOTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534450,
      "TXT_PINCODE_LOCALITY": "GOPANNA PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534451,
      "TXT_PINCODE_LOCALITY": "LAKKAVARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534452,
      "TXT_PINCODE_LOCALITY": "WEST GODAVARI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 440188,
      "City District Name": "WEST  GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534454,
      "TXT_PINCODE_LOCALITY": "PANGIDI GUDEM (G)",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534455,
      "TXT_PINCODE_LOCALITY": "DORAMAMIDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534456,
      "TXT_PINCODE_LOCALITY": "JEELUGU MILLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534457,
      "TXT_PINCODE_LOCALITY": "SRINIVASA PURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534458,
      "TXT_PINCODE_LOCALITY": "BORRAM PALEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534459,
      "TXT_PINCODE_LOCALITY": "VELAGALAPALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534460,
      "TXT_PINCODE_LOCALITY": "WEST GODAVARI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 440188,
      "City District Name": "WEST  GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534461,
      "TXT_PINCODE_LOCALITY": "PRAGADA VARAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534462,
      "TXT_PINCODE_LOCALITY": "WEST GODAVARI S. O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 440188,
      "City District Name": "WEST  GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534463,
      "TXT_PINCODE_LOCALITY": "SINGAGUDEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534465,
      "TXT_PINCODE_LOCALITY": "YERRAGUNTA PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534467,
      "TXT_PINCODE_LOCALITY": "WEST GODAVARI.",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 440188,
      "City District Name": "WEST  GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534468,
      "TXT_PINCODE_LOCALITY": "MATHAM GUDEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534469,
      "TXT_PINCODE_LOCALITY": "BHOGALE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534471,
      "TXT_PINCODE_LOCALITY": "PINAKADIMI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534473,
      "TXT_PINCODE_LOCALITY": "KOPPAKA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534475,
      "TXT_PINCODE_LOCALITY": "WEST GODAVARI.",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 440188,
      "City District Name": "WEST  GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534481,
      "TXT_PINCODE_LOCALITY": "BAYYANA GUDEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534482,
      "TXT_PINCODE_LOCALITY": "PALLANTLA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534483,
      "TXT_PINCODE_LOCALITY": "KONDRUKOTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534486,
      "TXT_PINCODE_LOCALITY": "CHINNAVA GUDEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534487,
      "TXT_PINCODE_LOCALITY": "YADAVOLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534489,
      "TXT_PINCODE_LOCALITY": "YERNAGUDEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534490,
      "TXT_PINCODE_LOCALITY": "DUDDUKURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534491,
      "TXT_PINCODE_LOCALITY": "PRAGADA PALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534493,
      "TXT_PINCODE_LOCALITY": "TADI PUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534494,
      "TXT_PINCODE_LOCALITY": "VELLA CHINTALA CUDEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534495,
      "TXT_PINCODE_LOCALITY": "DONDAPUDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534501,
      "TXT_PINCODE_LOCALITY": "DARBHA GUDEM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 534551,
      "TXT_PINCODE_LOCALITY": "POOLUGUDA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442239,
      "City District Name": "ANANTHAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535001,
      "TXT_PINCODE_LOCALITY": "VIZIANAGARAM CITY S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535002,
      "TXT_PINCODE_LOCALITY": "HUKUMPETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535003,
      "TXT_PINCODE_LOCALITY": "BALAJI NAGAR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535004,
      "TXT_PINCODE_LOCALITY": "INDUSTRIAL ESTATE S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535005,
      "TXT_PINCODE_LOCALITY": "VIZIANAGARAM S. O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535006,
      "TXT_PINCODE_LOCALITY": "DENKADA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535101,
      "TXT_PINCODE_LOCALITY": "GARIVIDI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535102,
      "TXT_PINCODE_LOCALITY": "GARBHAM S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535107,
      "TXT_PINCODE_LOCALITY": "VIZIARAMA GAJAPATHI RAJAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442249,
      "City District Name": "DATTIRAJERU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535122,
      "TXT_PINCODE_LOCALITY": "NEELAYYAVALASA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442306,
      "City District Name": "VANGARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535124,
      "TXT_PINCODE_LOCALITY": "BADANGI S. O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535125,
      "TXT_PINCODE_LOCALITY": "GANGUVARI SIGADAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5756,
      "City District Name": "SRIKAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535126,
      "TXT_PINCODE_LOCALITY": "THERLAM S. O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535127,
      "TXT_PINCODE_LOCALITY": "PANUKUVALASA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442316,
      "City District Name": "THERLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535128,
      "TXT_PINCODE_LOCALITY": "CHIPURUPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 425888,
      "City District Name": "VIZAYANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535136,
      "TXT_PINCODE_LOCALITY": "VIZIARAMPURAM (NEAR) KORTAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442316,
      "City District Name": "THERLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535145,
      "TXT_PINCODE_LOCALITY": "S KOTA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535146,
      "TXT_PINCODE_LOCALITY": "VINAYAKAPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442328,
      "City District Name": "SRUNGAVARAPUKOTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535148,
      "TXT_PINCODE_LOCALITY": "DHARMAVARAM S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535160,
      "TXT_PINCODE_LOCALITY": "BONANGI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 425910,
      "City District Name": "VISHAKHAPATNAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535161,
      "TXT_PINCODE_LOCALITY": "VISHAKAPATNAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535163,
      "TXT_PINCODE_LOCALITY": "SIVARAMRAJAPURAM (NEAR) PEDDURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442269,
      "City District Name": "GARUGUBILLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535183,
      "TXT_PINCODE_LOCALITY": "KOTHAVALASA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535204,
      "TXT_PINCODE_LOCALITY": "PUSAPATIREGA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535213,
      "TXT_PINCODE_LOCALITY": "PASUPAM B.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 425888,
      "City District Name": "VIZAYANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535214,
      "TXT_PINCODE_LOCALITY": "SS KORUKONDA S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535215,
      "TXT_PINCODE_LOCALITY": "GANTYADA S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535216,
      "TXT_PINCODE_LOCALITY": "MUNJERU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535217,
      "TXT_PINCODE_LOCALITY": "JARAJAPUPETA B.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535218,
      "TXT_PINCODE_LOCALITY": "GUSHINI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535220,
      "TXT_PINCODE_LOCALITY": "KOTAGANDREDU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535221,
      "TXT_PINCODE_LOCALITY": "PEDAMAJJIPALEM S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535240,
      "TXT_PINCODE_LOCALITY": "KANTAKAPALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535250,
      "TXT_PINCODE_LOCALITY": "JAMI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535260,
      "TXT_PINCODE_LOCALITY": "BONDAPALLI S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535270,
      "TXT_PINCODE_LOCALITY": "GAJAPATHINAGARAM S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535273,
      "TXT_PINCODE_LOCALITY": "MENTADA S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535280,
      "TXT_PINCODE_LOCALITY": "KONDAGUMPAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535281,
      "TXT_PINCODE_LOCALITY": "VEPADA S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535350,
      "TXT_PINCODE_LOCALITY": "DOMMERU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 4109,
      "City District Name": "KOVVUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535377,
      "TXT_PINCODE_LOCALITY": "AINAVILLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 424272,
      "City District Name": "EAST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535455,
      "TXT_PINCODE_LOCALITY": "PEDARAMA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442329,
      "City District Name": "SEETHAMPETA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535463,
      "TXT_PINCODE_LOCALITY": "GARUGUBILLI S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535501,
      "TXT_PINCODE_LOCALITY": "PARVATIPURAM H.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535502,
      "TXT_PINCODE_LOCALITY": "PARVATIPURAM BUS STAND S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1728,
      "City District Name": "VISAKHAPATANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535514,
      "TXT_PINCODE_LOCALITY": "CHINAKHANDEPALLE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442328,
      "City District Name": "SRUNGAVARAPUKOTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535519,
      "TXT_PINCODE_LOCALITY": "PAPPALA LINGALAVALASA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442249,
      "City District Name": "DATTIRAJERU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535520,
      "TXT_PINCODE_LOCALITY": "VIZIARAMAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442249,
      "City District Name": "DATTIRAJERU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535521,
      "TXT_PINCODE_LOCALITY": "KOMARADA S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535522,
      "TXT_PINCODE_LOCALITY": "MRUTYUNJAYARAYANAGARAM S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535523,
      "TXT_PINCODE_LOCALITY": "GUMMALAKSHMIPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535524,
      "TXT_PINCODE_LOCALITY": "KURUPAM S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535525,
      "TXT_PINCODE_LOCALITY": "RAVIVALASA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535526,
      "TXT_PINCODE_LOCALITY": "JIYYAMMAVALASA.",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535527,
      "TXT_PINCODE_LOCALITY": "PEDABONDAPALLI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535530,
      "TXT_PINCODE_LOCALITY": "GUTCHIMI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442249,
      "City District Name": "DATTIRAJERU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535532,
      "TXT_PINCODE_LOCALITY": "URIDI @ URIDINIVIDE",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442318,
      "City District Name": "KURUPAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535534,
      "TXT_PINCODE_LOCALITY": "PEDAGOTTILI B.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535546,
      "TXT_PINCODE_LOCALITY": "VIZIANAGARAM.",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535547,
      "TXT_PINCODE_LOCALITY": "MAKKUVA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535551,
      "TXT_PINCODE_LOCALITY": "SINGARBHA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442239,
      "City District Name": "ANANTHAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535552,
      "TXT_PINCODE_LOCALITY": "KEDARIPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442324,
      "City District Name": "GUMMALAKSHMIPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535557,
      "TXT_PINCODE_LOCALITY": "BALIJIPETA S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535558,
      "TXT_PINCODE_LOCALITY": "BOBBILI H.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535559,
      "TXT_PINCODE_LOCALITY": "JAGANNADHAPURAM (NEAR) BOBBILI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2599,
      "City District Name": "BOBBILI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535568,
      "TXT_PINCODE_LOCALITY": "BOBBILI S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535570,
      "TXT_PINCODE_LOCALITY": "SIVVAMDORAVALASA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442314,
      "City District Name": "MERAKAMUDIDAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535572,
      "TXT_PINCODE_LOCALITY": "THOTAVALASA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442312,
      "City District Name": "PACHIPENTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535573,
      "TXT_PINCODE_LOCALITY": "BOBBILI",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535576,
      "TXT_PINCODE_LOCALITY": "VIJAYARAMPURAM (NEAR) METTAVALASA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 2599,
      "City District Name": "BOBBILI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535578,
      "TXT_PINCODE_LOCALITY": "DONKINAVALASA S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535579,
      "TXT_PINCODE_LOCALITY": "RAMABHADRAPURAM S.O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535580,
      "TXT_PINCODE_LOCALITY": "GADASAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535581,
      "TXT_PINCODE_LOCALITY": "CHALLAPETA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 425910,
      "City District Name": "VISHAKHAPATNAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535582,
      "TXT_PINCODE_LOCALITY": "DATTIRAJERU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535591,
      "TXT_PINCODE_LOCALITY": "SALUR",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535592,
      "TXT_PINCODE_LOCALITY": "PARTHAPURAM",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 442312,
      "City District Name": "PACHIPENTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535593,
      "TXT_PINCODE_LOCALITY": "SALUR S. O",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535594,
      "TXT_PINCODE_LOCALITY": "JIYYAMMAVALASA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 6228,
      "City District Name": "VIZIANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 535595,
      "TXT_PINCODE_LOCALITY": "POYIMALA",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 5466,
      "City District Name": "SALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 543002,
      "TXT_PINCODE_LOCALITY": "ELURU",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 1895,
      "City District Name": "WEST GODAVARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 556121,
      "TXT_PINCODE_LOCALITY": "THE SUNNYDALE",
      "NUM_STATE_CD": 181,
      "State Name": "SINGAPORE",
      "NUM_CITYDISTRICT_CD": 424366,
      "City District Name": "SINGAPORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560001,
      "TXT_PINCODE_LOCALITY": "QUADRANT SQUARE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560002,
      "TXT_PINCODE_LOCALITY": "KALASIPALYAM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560003,
      "TXT_PINCODE_LOCALITY": "MALLESWARAM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560004,
      "TXT_PINCODE_LOCALITY": "PAMPAMAHAKAVI ROAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560005,
      "TXT_PINCODE_LOCALITY": "PILLANNA GARDENS",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560006,
      "TXT_PINCODE_LOCALITY": "TRG COMMAND I A F",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560007,
      "TXT_PINCODE_LOCALITY": "AGARAM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560008,
      "TXT_PINCODE_LOCALITY": "ULSOOR H O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560009,
      "TXT_PINCODE_LOCALITY": "N S BUREAU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560010,
      "TXT_PINCODE_LOCALITY": "SINDHNUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560011,
      "TXT_PINCODE_LOCALITY": "JAYANAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560012,
      "TXT_PINCODE_LOCALITY": "SCIENCE INSTITUTE S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560013,
      "TXT_PINCODE_LOCALITY": "JALAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560014,
      "TXT_PINCODE_LOCALITY": "JALAHALLI EAST",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560015,
      "TXT_PINCODE_LOCALITY": "JALAHALLI WEST",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560016,
      "TXT_PINCODE_LOCALITY": "DORRVANINAGAR EAST",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560017,
      "TXT_PINCODE_LOCALITY": "BANGALORE AIRPORT H A L",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560018,
      "TXT_PINCODE_LOCALITY": "VITTALNAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560019,
      "TXT_PINCODE_LOCALITY": "GAVIPURM EXTN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560020,
      "TXT_PINCODE_LOCALITY": "PALACE GUTTAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560021,
      "TXT_PINCODE_LOCALITY": "SRIRAMPURAM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560022,
      "TXT_PINCODE_LOCALITY": "YESHWANTPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560023,
      "TXT_PINCODE_LOCALITY": "MAGADI ROAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560024,
      "TXT_PINCODE_LOCALITY": "RAJMAHALVILAS",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560025,
      "TXT_PINCODE_LOCALITY": "LANGFORD TOWN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560026,
      "TXT_PINCODE_LOCALITY": "BYATARAYANAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560027,
      "TXT_PINCODE_LOCALITY": "SAMPANGIRAMNAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560028,
      "TXT_PINCODE_LOCALITY": "TYAGARAJANAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560029,
      "TXT_PINCODE_LOCALITY": "S D S SANITORIM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560030,
      "TXT_PINCODE_LOCALITY": "HOSUR ROAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560031,
      "TXT_PINCODE_LOCALITY": "HMT WATCH FACTORY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560032,
      "TXT_PINCODE_LOCALITY": "RAVINDRANATH TAGORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560033,
      "TXT_PINCODE_LOCALITY": "MALKAND LINES",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560034,
      "TXT_PINCODE_LOCALITY": "ST JOHNS MEDICAL COLLEGE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560035,
      "TXT_PINCODE_LOCALITY": "CARMELARAM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560036,
      "TXT_PINCODE_LOCALITY": "KRISNARAJAPURAM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560037,
      "TXT_PINCODE_LOCALITY": "RAMESH NAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560038,
      "TXT_PINCODE_LOCALITY": "INDIRA NAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560039,
      "TXT_PINCODE_LOCALITY": "ALANGUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560040,
      "TXT_PINCODE_LOCALITY": "VIJAYANAGAR NORTH",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560041,
      "TXT_PINCODE_LOCALITY": "JAYANAGAR T BLOCK",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560042,
      "TXT_PINCODE_LOCALITY": "SIVANCHETTY GARDEN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560043,
      "TXT_PINCODE_LOCALITY": "BANGALORE SOUTH",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560044,
      "TXT_PINCODE_LOCALITY": "RAJAJI NAGAR INDL.EST.",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560045,
      "TXT_PINCODE_LOCALITY": "DEVARJEEVANALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560046,
      "TXT_PINCODE_LOCALITY": "JAYAMAHAL EXTN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560047,
      "TXT_PINCODE_LOCALITY": "OOKADAPALYAM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560048,
      "TXT_PINCODE_LOCALITY": "MALLANAIKANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560049,
      "TXT_PINCODE_LOCALITY": "VIRGONAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560050,
      "TXT_PINCODE_LOCALITY": "BANASHANKARI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560051,
      "TXT_PINCODE_LOCALITY": "LALMASJID STREET",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560052,
      "TXT_PINCODE_LOCALITY": "VASANTANAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560053,
      "TXT_PINCODE_LOCALITY": "COTTONPET",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560054,
      "TXT_PINCODE_LOCALITY": "GOKULA EXTN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560055,
      "TXT_PINCODE_LOCALITY": "MILK COLONY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560056,
      "TXT_PINCODE_LOCALITY": "VISHWAVIDYALAYA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560057,
      "TXT_PINCODE_LOCALITY": "BATTA HALSOOR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560058,
      "TXT_PINCODE_LOCALITY": "PEENYA II STAGE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560059,
      "TXT_PINCODE_LOCALITY": "R V NIKETAN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560060,
      "TXT_PINCODE_LOCALITY": "BOMMANHALLI HOSUR ROAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560061,
      "TXT_PINCODE_LOCALITY": "SUBRAMANYAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560062,
      "TXT_PINCODE_LOCALITY": "DODDAKALLASANDRA - 4954",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560063,
      "TXT_PINCODE_LOCALITY": "BSF CAMPUS YELAHANKA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560064,
      "TXT_PINCODE_LOCALITY": "YELAHANKA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560065,
      "TXT_PINCODE_LOCALITY": "G.K.V.K. S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560066,
      "TXT_PINCODE_LOCALITY": "WHITEFIELD ROAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560067,
      "TXT_PINCODE_LOCALITY": "KADUGODI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560068,
      "TXT_PINCODE_LOCALITY": "PEENYA  INDL ESTATE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560069,
      "TXT_PINCODE_LOCALITY": "JAYANAGAR EAST",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560070,
      "TXT_PINCODE_LOCALITY": "KATGAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 441051,
      "City District Name": "UTTARA KANNADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560071,
      "TXT_PINCODE_LOCALITY": "DOMLUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560072,
      "TXT_PINCODE_LOCALITY": "JAYANAGAR EXTN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560073,
      "TXT_PINCODE_LOCALITY": "HUSKAR ROAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560074,
      "TXT_PINCODE_LOCALITY": "BANGLORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560075,
      "TXT_PINCODE_LOCALITY": "METAGALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 156,
      "City District Name": "MYSORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560076,
      "TXT_PINCODE_LOCALITY": "BANNERGHATA ROAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560077,
      "TXT_PINCODE_LOCALITY": "KOTHANUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560078,
      "TXT_PINCODE_LOCALITY": "JAYAPRAKASH NARYAN NAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560079,
      "TXT_PINCODE_LOCALITY": "AGRAHARA DASARAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560080,
      "TXT_PINCODE_LOCALITY": "PALACE ORCHARDS",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560081,
      "TXT_PINCODE_LOCALITY": "CHANDAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560082,
      "TXT_PINCODE_LOCALITY": "JAYANAGAR WEST",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560083,
      "TXT_PINCODE_LOCALITY": "BANNERGHATTA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560084,
      "TXT_PINCODE_LOCALITY": "ST THOMAS TOWN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560085,
      "TXT_PINCODE_LOCALITY": "BANASHANKJARI IIIST",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560086,
      "TXT_PINCODE_LOCALITY": "BASAVESHWAR NAGAR III STAG",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560087,
      "TXT_PINCODE_LOCALITY": "VARTHUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560088,
      "TXT_PINCODE_LOCALITY": "HESSARGHATTA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560089,
      "TXT_PINCODE_LOCALITY": "HESSARGHATTALAKE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560090,
      "TXT_PINCODE_LOCALITY": "CHIKKABANAVARA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560091,
      "TXT_PINCODE_LOCALITY": "VISWANEEDAM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560092,
      "TXT_PINCODE_LOCALITY": "BYATARAYANAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560093,
      "TXT_PINCODE_LOCALITY": "C.V. RAMAN NAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560094,
      "TXT_PINCODE_LOCALITY": "R.M. VILAS II STAGE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560095,
      "TXT_PINCODE_LOCALITY": "KORAMANGALA VI BLOCK",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560096,
      "TXT_PINCODE_LOCALITY": "NANDINI EXTENSION",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560097,
      "TXT_PINCODE_LOCALITY": "VIDYARANYAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560098,
      "TXT_PINCODE_LOCALITY": "BANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560099,
      "TXT_PINCODE_LOCALITY": "MUNESHWARA TEMPLE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560100,
      "TXT_PINCODE_LOCALITY": "ELECTRONIC CITY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560101,
      "TXT_PINCODE_LOCALITY": "BANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560102,
      "TXT_PINCODE_LOCALITY": "SARJAPUR ROAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560103,
      "TXT_PINCODE_LOCALITY": "BELLENDUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560104,
      "TXT_PINCODE_LOCALITY": "HAMPINAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560105,
      "TXT_PINCODE_LOCALITY": "JIGNI INDUSTRIAL AREA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560106,
      "TXT_PINCODE_LOCALITY": "ANEKAL TALUK",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560107,
      "TXT_PINCODE_LOCALITY": "ACHITNAGAR S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 441052,
      "City District Name": "BANGALORE RURAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560108,
      "TXT_PINCODE_LOCALITY": "ANJANAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560109,
      "TXT_PINCODE_LOCALITY": "THALAGHATTAPURA S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560110,
      "TXT_PINCODE_LOCALITY": "ULLALU UPANAGAR S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560111,
      "TXT_PINCODE_LOCALITY": "KUMARASWAMY LAYOUT",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560112,
      "TXT_PINCODE_LOCALITY": "BANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560113,
      "TXT_PINCODE_LOCALITY": "HORAMAVU S. O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560114,
      "TXT_PINCODE_LOCALITY": "BEGUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560115,
      "TXT_PINCODE_LOCALITY": "BANGALORE SOUTH",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560116,
      "TXT_PINCODE_LOCALITY": "BENGALURU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560117,
      "TXT_PINCODE_LOCALITY": "HOSAKOTE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560123,
      "TXT_PINCODE_LOCALITY": "BANGLORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 441052,
      "City District Name": "BANGALORE RURAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560130,
      "TXT_PINCODE_LOCALITY": "BANGALORE.",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560135,
      "TXT_PINCODE_LOCALITY": "ELECTRONICS CITY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560242,
      "TXT_PINCODE_LOCALITY": "NAGARBHAVI STAGE 4",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 442800,
      "City District Name": "BANGALORE NORTH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560300,
      "TXT_PINCODE_LOCALITY": "DEVANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 560500,
      "TXT_PINCODE_LOCALITY": "BENGALURU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 561001,
      "TXT_PINCODE_LOCALITY": "CUDDAPAH",
      "NUM_STATE_CD": 124,
      "State Name": "ANDHRA PRADESH",
      "NUM_CITYDISTRICT_CD": 444058,
      "City District Name": "Y.S.R.",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 561101,
      "TXT_PINCODE_LOCALITY": "KUDUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 561157,
      "TXT_PINCODE_LOCALITY": "YERTHIGANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 442800,
      "City District Name": "BANGALORE NORTH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 561201,
      "TXT_PINCODE_LOCALITY": "V G DODDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 561202,
      "TXT_PINCODE_LOCALITY": "PAVAGADA FORT",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 484,
      "City District Name": "TUMKUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 561203,
      "TXT_PINCODE_LOCALITY": "KONGADIAPPA ROAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 561204,
      "TXT_PINCODE_LOCALITY": "DODDABELAVANGALA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 561205,
      "TXT_PINCODE_LOCALITY": "DODDABALLAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 561206,
      "TXT_PINCODE_LOCALITY": "DARINAYAKANAPALYA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 561207,
      "TXT_PINCODE_LOCALITY": "BAGEPALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 561208,
      "TXT_PINCODE_LOCALITY": "GAURIBIDANUR BZR S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 441323,
      "City District Name": "CHIKKABALLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 561209,
      "TXT_PINCODE_LOCALITY": "GUDIBANDA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 561210,
      "TXT_PINCODE_LOCALITY": "HOSSUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 561211,
      "TXT_PINCODE_LOCALITY": "MANCHENATHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 561212,
      "TXT_PINCODE_LOCALITY": "PATHAPALYA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 561213,
      "TXT_PINCODE_LOCALITY": "THONDEBHAVI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 561214,
      "TXT_PINCODE_LOCALITY": "BYADANUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 561215,
      "TXT_PINCODE_LOCALITY": "BANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 561217,
      "TXT_PINCODE_LOCALITY": "VIDURASWATHA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 561218,
      "TXT_PINCODE_LOCALITY": "GULUR KOLAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 561219,
      "TXT_PINCODE_LOCALITY": "KALLUDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 561220,
      "TXT_PINCODE_LOCALITY": "POSETTIHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 561221,
      "TXT_PINCODE_LOCALITY": "THOLAPALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 561222,
      "TXT_PINCODE_LOCALITY": "BALAREDDIPALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 561223,
      "TXT_PINCODE_LOCALITY": "SOMANATHAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 561224,
      "TXT_PINCODE_LOCALITY": "ALIPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 561225,
      "TXT_PINCODE_LOCALITY": "S S GHATI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 561226,
      "TXT_PINCODE_LOCALITY": "TUBAGERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 561227,
      "TXT_PINCODE_LOCALITY": "SAILAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 561228,
      "TXT_PINCODE_LOCALITY": "VATADAHOSAHALLI S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 561229,
      "TXT_PINCODE_LOCALITY": "ELECTRONIC CITY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 561232,
      "TXT_PINCODE_LOCALITY": "BELAGAVI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 561234,
      "TXT_PINCODE_LOCALITY": "BELAGAVI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 561303,
      "TXT_PINCODE_LOCALITY": "ATHANI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 444209,
      "City District Name": "BELAGAVI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 561316,
      "TXT_PINCODE_LOCALITY": "BELAGAVI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562021,
      "TXT_PINCODE_LOCALITY": "MATHA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 4297,
      "City District Name": "MAGADI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562100,
      "TXT_PINCODE_LOCALITY": "UPPARAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 442804,
      "City District Name": "DEVANAHALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562101,
      "TXT_PINCODE_LOCALITY": "CHIKBALLAPUR H O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 441323,
      "City District Name": "CHIKKABALLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562102,
      "TXT_PINCODE_LOCALITY": "SIDLAGHATTA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 441323,
      "City District Name": "CHIKKABALLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562103,
      "TXT_PINCODE_LOCALITY": "NANDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562104,
      "TXT_PINCODE_LOCALITY": "PERESANDRA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562105,
      "TXT_PINCODE_LOCALITY": "ABLODU B.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 441323,
      "City District Name": "CHIKKABALLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562106,
      "TXT_PINCODE_LOCALITY": "THIMMAIAH ROAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562107,
      "TXT_PINCODE_LOCALITY": "ATTIBELE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562108,
      "TXT_PINCODE_LOCALITY": "BEVUAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562109,
      "TXT_PINCODE_LOCALITY": "HEJJALLA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562110,
      "TXT_PINCODE_LOCALITY": "DEVANALLI FORT",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562111,
      "TXT_PINCODE_LOCALITY": "DOBESPET",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562112,
      "TXT_PINCODE_LOCALITY": "RAMANAGARAM S. O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 5294,
      "City District Name": "RAMANAGARAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562114,
      "TXT_PINCODE_LOCALITY": "KIADB INDUSTRIAL AREA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562115,
      "TXT_PINCODE_LOCALITY": "DIBBURAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562116,
      "TXT_PINCODE_LOCALITY": "MALLUR (KOLAR)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562117,
      "TXT_PINCODE_LOCALITY": "KANAKAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562118,
      "TXT_PINCODE_LOCALITY": "NANDI HILLS",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562119,
      "TXT_PINCODE_LOCALITY": "KODIHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562120,
      "TXT_PINCODE_LOCALITY": "MAGADI S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 441238,
      "City District Name": "RAMANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562121,
      "TXT_PINCODE_LOCALITY": "MARALAVADI S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 441238,
      "City District Name": "RAMANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562122,
      "TXT_PINCODE_LOCALITY": "BYLANARASAPURA B.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562123,
      "TXT_PINCODE_LOCALITY": "GOLDER PALMS ROAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562124,
      "TXT_PINCODE_LOCALITY": "AKKUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562125,
      "TXT_PINCODE_LOCALITY": "SARJAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562126,
      "TXT_PINCODE_LOCALITY": "SATHANUR (CHANNAPATNA)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562127,
      "TXT_PINCODE_LOCALITY": "MAGADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562128,
      "TXT_PINCODE_LOCALITY": "SUGGANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562129,
      "TXT_PINCODE_LOCALITY": "SULEBELE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 441052,
      "City District Name": "BANGALORE RURAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562130,
      "TXT_PINCODE_LOCALITY": "HARTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562131,
      "TXT_PINCODE_LOCALITY": "THIPPASANDRA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562132,
      "TXT_PINCODE_LOCALITY": "TYAMAGONDLU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562134,
      "TXT_PINCODE_LOCALITY": "VIDYANAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562135,
      "TXT_PINCODE_LOCALITY": "MANDIBELE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 441052,
      "City District Name": "BANGALORE RURAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562136,
      "TXT_PINCODE_LOCALITY": "AVATHI (CHANNAPATNA)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562137,
      "TXT_PINCODE_LOCALITY": "CPT",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562138,
      "TXT_PINCODE_LOCALITY": "HONGANUR CHANNAPATNA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562139,
      "TXT_PINCODE_LOCALITY": "BANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562140,
      "TXT_PINCODE_LOCALITY": "BANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562141,
      "TXT_PINCODE_LOCALITY": "JODIDASALRAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562142,
      "TXT_PINCODE_LOCALITY": "BYRAMAGALA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562143,
      "TXT_PINCODE_LOCALITY": "CHIKKMUDUVADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562144,
      "TXT_PINCODE_LOCALITY": "JANGAMKOTE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562145,
      "TXT_PINCODE_LOCALITY": "CHANDPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562147,
      "TXT_PINCODE_LOCALITY": "DIBBURAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562148,
      "TXT_PINCODE_LOCALITY": "DOMMASANDRA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562149,
      "TXT_PINCODE_LOCALITY": "BAGAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562150,
      "TXT_PINCODE_LOCALITY": "KANVA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562151,
      "TXT_PINCODE_LOCALITY": "CHAMARAJASAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562152,
      "TXT_PINCODE_LOCALITY": "CHIKKAJALA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562153,
      "TXT_PINCODE_LOCALITY": "GUDEMARANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562154,
      "TXT_PINCODE_LOCALITY": "BANGALORE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562156,
      "TXT_PINCODE_LOCALITY": "BEECHANGANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562157,
      "TXT_PINCODE_LOCALITY": "KODAMBALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562158,
      "TXT_PINCODE_LOCALITY": "BOMMASANDRA INDUSTRIAL ESTATE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562159,
      "TXT_PINCODE_LOCALITY": "RAMANAGARAM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 441354,
      "City District Name": "RAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562160,
      "TXT_PINCODE_LOCALITY": "CHANNAPATNA B.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 441238,
      "City District Name": "RAMANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562161,
      "TXT_PINCODE_LOCALITY": "KENGAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2703,
      "City District Name": "CHANNAPATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562162,
      "TXT_PINCODE_LOCALITY": "HUSKUR B.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 441052,
      "City District Name": "BANGALORE RURAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562163,
      "TXT_PINCODE_LOCALITY": "BANGALORE RURAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 155,
      "City District Name": "BANGALORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562164,
      "TXT_PINCODE_LOCALITY": "DEVANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 442804,
      "City District Name": "DEVANAHALLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562165,
      "TXT_PINCODE_LOCALITY": "BUDIGERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2703,
      "City District Name": "CHANNAPATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562208,
      "TXT_PINCODE_LOCALITY": "NAYIDOLLE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2703,
      "City District Name": "CHANNAPATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562212,
      "TXT_PINCODE_LOCALITY": "CHIKKAMARALAVADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 3729,
      "City District Name": "KANAKAPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562411,
      "TXT_PINCODE_LOCALITY": "PALYADAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 4297,
      "City District Name": "MAGADI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 562460,
      "TXT_PINCODE_LOCALITY": "CHOTTANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 346,
      "City District Name": "MANDYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563101,
      "TXT_PINCODE_LOCALITY": "KOLAR GANDHINAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563102,
      "TXT_PINCODE_LOCALITY": "KOLAR EXTENSION",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563103,
      "TXT_PINCODE_LOCALITY": "CHAMARAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563104,
      "TXT_PINCODE_LOCALITY": "THORADEVANDAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563113,
      "TXT_PINCODE_LOCALITY": "ANERSONPET S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563114,
      "TXT_PINCODE_LOCALITY": "BANGARPET BAZAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563115,
      "TXT_PINCODE_LOCALITY": "B E M L NAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563116,
      "TXT_PINCODE_LOCALITY": "BETHAMANGALA S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563117,
      "TXT_PINCODE_LOCALITY": "CHAMPION REEFS",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563118,
      "TXT_PINCODE_LOCALITY": "COROMANDEL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563119,
      "TXT_PINCODE_LOCALITY": "V O C NAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563120,
      "TXT_PINCODE_LOCALITY": "OORGAUM EAST",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563121,
      "TXT_PINCODE_LOCALITY": "OORGAUMPET S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563122,
      "TXT_PINCODE_LOCALITY": "ROBERTSONAPET VI CROSS",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563123,
      "TXT_PINCODE_LOCALITY": "BATLAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563124,
      "TXT_PINCODE_LOCALITY": "CHELUR (KOLAR)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563125,
      "TXT_PINCODE_LOCALITY": "CHINTAMANI BAZAR S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 441323,
      "City District Name": "CHIKKABALLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563126,
      "TXT_PINCODE_LOCALITY": "DALASNUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563127,
      "TXT_PINCODE_LOCALITY": "DEVARAYASUMUDRA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563128,
      "TXT_PINCODE_LOCALITY": "KAIWARA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563129,
      "TXT_PINCODE_LOCALITY": "KAMASAMUDRAM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563130,
      "TXT_PINCODE_LOCALITY": "MALUR BAZAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563131,
      "TXT_PINCODE_LOCALITY": "PALYA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563132,
      "TXT_PINCODE_LOCALITY": "RAJENDRAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563133,
      "TXT_PINCODE_LOCALITY": "NARASPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563134,
      "TXT_PINCODE_LOCALITY": "RAYALPADU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563135,
      "TXT_PINCODE_LOCALITY": "SRINIVASAPURA BAZAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563136,
      "TXT_PINCODE_LOCALITY": "TAYALUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563137,
      "TXT_PINCODE_LOCALITY": "TEKAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563138,
      "TXT_PINCODE_LOCALITY": "YELDUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563139,
      "TXT_PINCODE_LOCALITY": "MASTHI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563140,
      "TXT_PINCODE_LOCALITY": "ALANGUR B.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563141,
      "TXT_PINCODE_LOCALITY": "MULBAGAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 4596,
      "City District Name": "MULBAGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563142,
      "TXT_PINCODE_LOCALITY": "HULKUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563143,
      "TXT_PINCODE_LOCALITY": "MINDIGAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563144,
      "TXT_PINCODE_LOCALITY": "SOMESHWARAPALYA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563145,
      "TXT_PINCODE_LOCALITY": "AMBLICAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563146,
      "TXT_PINCODE_LOCALITY": "MURUGAMALA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563147,
      "TXT_PINCODE_LOCALITY": "BUDIKOTE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563148,
      "TXT_PINCODE_LOCALITY": "DOD BALLAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 3044,
      "City District Name": "DOD BALLAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563149,
      "TXT_PINCODE_LOCALITY": "MULBAGAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 4596,
      "City District Name": "MULBAGAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563150,
      "TXT_PINCODE_LOCALITY": "KENCHARLAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563151,
      "TXT_PINCODE_LOCALITY": "KYALANUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563152,
      "TXT_PINCODE_LOCALITY": "KURUDAMALE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563153,
      "TXT_PINCODE_LOCALITY": "CHILAKALANERPU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563154,
      "TXT_PINCODE_LOCALITY": "HEBBANI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563156,
      "TXT_PINCODE_LOCALITY": "KOLAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563157,
      "TXT_PINCODE_LOCALITY": "VEMGAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563158,
      "TXT_PINCODE_LOCALITY": "CHIKKATIRUPATHI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563159,
      "TXT_PINCODE_LOCALITY": "BURUDUGUNTE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563160,
      "TXT_PINCODE_LOCALITY": "MALUR RAILWAY STATION S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563161,
      "TXT_PINCODE_LOCALITY": "GOWNIPALLI S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563162,
      "TXT_PINCODE_LOCALITY": "DESHIHALLI BANGARPET S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563163,
      "TXT_PINCODE_LOCALITY": "JAYAMANGALA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563203,
      "TXT_PINCODE_LOCALITY": "SOMAMBUDI AMANIKERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563219,
      "TXT_PINCODE_LOCALITY": "VATTIGAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2309,
      "City District Name": "BANGARAPET",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563301,
      "TXT_PINCODE_LOCALITY": "KOLAR.",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563410,
      "TXT_PINCODE_LOCALITY": "KAMADHENUHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 563939,
      "TXT_PINCODE_LOCALITY": "KARAGAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 4372,
      "City District Name": "MALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 565277,
      "TXT_PINCODE_LOCALITY": "PAHWADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1791,
      "City District Name": "KOLAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 566214,
      "TXT_PINCODE_LOCALITY": "BIJAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 991001,
      "TXT_PINCODE_LOCALITY": "CHANDIGARH",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 993001,
      "TXT_PINCODE_LOCALITY": "DAMAN",
      "NUM_STATE_CD": 161,
      "State Name": "DAMAN & DIU",
      "NUM_CITYDISTRICT_CD": 524,
      "City District Name": "DAMAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 993002,
      "TXT_PINCODE_LOCALITY": "DIU",
      "NUM_STATE_CD": 161,
      "State Name": "DAMAN & DIU",
      "NUM_CITYDISTRICT_CD": 3042,
      "City District Name": "DIU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 994001,
      "TXT_PINCODE_LOCALITY": "LAKSHADWEEP",
      "NUM_STATE_CD": 163,
      "State Name": "LAKSHADWEEP",
      "NUM_CITYDISTRICT_CD": 6283,
      "City District Name": "LAKSHADWEEP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 995001,
      "TXT_PINCODE_LOCALITY": "KARAIKAL",
      "NUM_STATE_CD": 164,
      "State Name": "PONDICHERRY",
      "NUM_CITYDISTRICT_CD": 3790,
      "City District Name": "KARAIKAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 995002,
      "TXT_PINCODE_LOCALITY": "MAHE",
      "NUM_STATE_CD": 164,
      "State Name": "PONDICHERRY",
      "NUM_CITYDISTRICT_CD": 4310,
      "City District Name": "MAHE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 995003,
      "TXT_PINCODE_LOCALITY": "PONDICHERRY",
      "NUM_STATE_CD": 164,
      "State Name": "PONDICHERRY",
      "NUM_CITYDISTRICT_CD": 424444,
      "City District Name": "PONDICHERRY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 995004,
      "TXT_PINCODE_LOCALITY": "YANAM",
      "NUM_STATE_CD": 164,
      "State Name": "PONDICHERRY",
      "NUM_CITYDISTRICT_CD": 424443,
      "City District Name": "YANAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 440001,
      "TXT_PINCODE_LOCALITY": "SADAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 440002,
      "TXT_PINCODE_LOCALITY": "NAGPUR MASKA SUTH",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 440003,
      "TXT_PINCODE_LOCALITY": "AJNI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 440004,
      "TXT_PINCODE_LOCALITY": "INDORE.",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 440005,
      "TXT_PINCODE_LOCALITY": "AIR PORT NAGPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 440006,
      "TXT_PINCODE_LOCALITY": "SEMINARY HILLS",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 440007,
      "TXT_PINCODE_LOCALITY": "VAYUSENANAGAR (NAGPUR)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 440008,
      "TXT_PINCODE_LOCALITY": "BAGADGANI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 440009,
      "TXT_PINCODE_LOCALITY": "HANUMAN NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 440010,
      "TXT_PINCODE_LOCALITY": "DHARMPETH",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 440011,
      "TXT_PINCODE_LOCALITY": "ENGINEERING COLLEGE NAG",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 440012,
      "TXT_PINCODE_LOCALITY": "WARDHA ROAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 440013,
      "TXT_PINCODE_LOCALITY": "HINGNA ROAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 440014,
      "TXT_PINCODE_LOCALITY": "INDORA CHOWK",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 440015,
      "TXT_PINCODE_LOCALITY": "NARENDRA NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 440016,
      "TXT_PINCODE_LOCALITY": "INDUSTRIAL AREA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 440017,
      "TXT_PINCODE_LOCALITY": "NAGPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 440018,
      "TXT_PINCODE_LOCALITY": "IMAMBADA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 440019,
      "TXT_PINCODE_LOCALITY": "CRPF NAGPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 440020,
      "TXT_PINCODE_LOCALITY": "N E E R I",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 440021,
      "TXT_PINCODE_LOCALITY": "AMBAZARI DEB PROJECT",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 440022,
      "TXT_PINCODE_LOCALITY": "GOPAL NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 440023,
      "TXT_PINCODE_LOCALITY": "WADI - 1317",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 440024,
      "TXT_PINCODE_LOCALITY": "AYODHYA NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 440025,
      "TXT_PINCODE_LOCALITY": "KHAMLA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 440026,
      "TXT_PINCODE_LOCALITY": "INDUSTRIAL ESTATE NAGPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 440027,
      "TXT_PINCODE_LOCALITY": "PARVATI NAGAR (NAGPUR)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 440028,
      "TXT_PINCODE_LOCALITY": "NAGPUR MIDC",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 440029,
      "TXT_PINCODE_LOCALITY": "N I O DONAPAULA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 440030,
      "TXT_PINCODE_LOCALITY": "MANKAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 440032,
      "TXT_PINCODE_LOCALITY": "MAHAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 440033,
      "TXT_PINCODE_LOCALITY": "AMBAZARI ROAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 440034,
      "TXT_PINCODE_LOCALITY": "NAGPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4658,
      "City District Name": "NAGAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 440035,
      "TXT_PINCODE_LOCALITY": "DEPUTY SIGNAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 440036,
      "TXT_PINCODE_LOCALITY": "JAITALA S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 440037,
      "TXT_PINCODE_LOCALITY": "BESA ROAD S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 440100,
      "TXT_PINCODE_LOCALITY": "SEVANAGAR (N.V)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442833,
      "City District Name": "BARSHITAKLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 440101,
      "TXT_PINCODE_LOCALITY": "KHERDA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1456,
      "City District Name": "AKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 440216,
      "TXT_PINCODE_LOCALITY": "DHARAMWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 5223,
      "City District Name": "PUSAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 440311,
      "TXT_PINCODE_LOCALITY": "WARKHED",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 425589,
      "City District Name": "CHIKHLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441001,
      "TXT_PINCODE_LOCALITY": "KAMPTEE GUARDS TRG CENTRE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441002,
      "TXT_PINCODE_LOCALITY": "KAMPTEE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441005,
      "TXT_PINCODE_LOCALITY": "MUNDIKOTA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 3287,
      "City District Name": "GONDIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441015,
      "TXT_PINCODE_LOCALITY": "BORGAON KH.",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 3679,
      "City District Name": "KALAMESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441100,
      "TXT_PINCODE_LOCALITY": "AMGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442860,
      "City District Name": "NAGPUR (RURAL)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441101,
      "TXT_PINCODE_LOCALITY": "KHAPA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441102,
      "TXT_PINCODE_LOCALITY": "KHAPAR KHED",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441103,
      "TXT_PINCODE_LOCALITY": "KONDHALI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441104,
      "TXT_PINCODE_LOCALITY": "MANDA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441105,
      "TXT_PINCODE_LOCALITY": "PARSEONI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441106,
      "TXT_PINCODE_LOCALITY": "RAMTEK - 1031",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441107,
      "TXT_PINCODE_LOCALITY": "SAVNER",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441108,
      "TXT_PINCODE_LOCALITY": "BUTIBORI INDUSTRIAL AREA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441109,
      "TXT_PINCODE_LOCALITY": "SILLEWARG",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441110,
      "TXT_PINCODE_LOCALITY": "HINGNA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441111,
      "TXT_PINCODE_LOCALITY": "KARODI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441112,
      "TXT_PINCODE_LOCALITY": "KELAVAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441113,
      "TXT_PINCODE_LOCALITY": "PATANSAONGI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441114,
      "TXT_PINCODE_LOCALITY": "GUMGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441115,
      "TXT_PINCODE_LOCALITY": "BELA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441116,
      "TXT_PINCODE_LOCALITY": "GAIMUCH NANDE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441117,
      "TXT_PINCODE_LOCALITY": "UMRI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441119,
      "TXT_PINCODE_LOCALITY": "SARANDI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 975,
      "City District Name": "TIRORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441122,
      "TXT_PINCODE_LOCALITY": "BUTIBORI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441123,
      "TXT_PINCODE_LOCALITY": "GODHANI S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4658,
      "City District Name": "NAGAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441130,
      "TXT_PINCODE_LOCALITY": "UTARA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4746,
      "City District Name": "NARKHED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441131,
      "TXT_PINCODE_LOCALITY": "SAKHARKHEDA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4746,
      "City District Name": "NARKHED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441133,
      "TXT_PINCODE_LOCALITY": "CHAMPA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441135,
      "TXT_PINCODE_LOCALITY": "WARJALI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 4746,
      "City District Name": "NARKHED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441136,
      "TXT_PINCODE_LOCALITY": "RAJNI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 3852,
      "City District Name": "KATOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441201,
      "TXT_PINCODE_LOCALITY": "BHIWAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441202,
      "TXT_PINCODE_LOCALITY": "KUHI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441203,
      "TXT_PINCODE_LOCALITY": "UMRED",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441204,
      "TXT_PINCODE_LOCALITY": "UMRED PROJECT",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441205,
      "TXT_PINCODE_LOCALITY": "NAGBHIR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441206,
      "TXT_PINCODE_LOCALITY": "BRAHAMPURI (CHANDA)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441207,
      "TXT_PINCODE_LOCALITY": "DESAIGUNJ",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1786,
      "City District Name": "GADCHIROLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441208,
      "TXT_PINCODE_LOCALITY": "ARMORI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1786,
      "City District Name": "GADCHIROLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441209,
      "TXT_PINCODE_LOCALITY": "KURKHODA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1786,
      "City District Name": "GADCHIROLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441210,
      "TXT_PINCODE_LOCALITY": "MANDHAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441211,
      "TXT_PINCODE_LOCALITY": "YEDMEPAR (RITHI)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442850,
      "City District Name": "KUHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441212,
      "TXT_PINCODE_LOCALITY": "SARADPAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441213,
      "TXT_PINCODE_LOCALITY": "RAJOLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 3287,
      "City District Name": "GONDIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441214,
      "TXT_PINCODE_LOCALITY": "SIRSI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441215,
      "TXT_PINCODE_LOCALITY": "PATHRI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441216,
      "TXT_PINCODE_LOCALITY": "NAND",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441217,
      "TXT_PINCODE_LOCALITY": "VAIRAGAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1786,
      "City District Name": "GADCHIROLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441221,
      "TXT_PINCODE_LOCALITY": "TALOJE AUDYOGIK",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441222,
      "TXT_PINCODE_LOCALITY": "SINDE WAHI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441223,
      "TXT_PINCODE_LOCALITY": "NAVARGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441224,
      "TXT_PINCODE_LOCALITY": "MUL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441225,
      "TXT_PINCODE_LOCALITY": "VYAHAD KHURD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441226,
      "TXT_PINCODE_LOCALITY": "BEMBALA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441228,
      "TXT_PINCODE_LOCALITY": "BEMBAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441270,
      "TXT_PINCODE_LOCALITY": "CHAUDANPALLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441273,
      "TXT_PINCODE_LOCALITY": "ANTARGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441301,
      "TXT_PINCODE_LOCALITY": "JALALKHEDA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441302,
      "TXT_PINCODE_LOCALITY": "KATOL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441303,
      "TXT_PINCODE_LOCALITY": "MOWAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441304,
      "TXT_PINCODE_LOCALITY": "BELONA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441305,
      "TXT_PINCODE_LOCALITY": "PARADSINGA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441306,
      "TXT_PINCODE_LOCALITY": "SAWARGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441401,
      "TXT_PINCODE_LOCALITY": "DEOLAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441402,
      "TXT_PINCODE_LOCALITY": "KHANDELWAL NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441403,
      "TXT_PINCODE_LOCALITY": "TOTALDOH",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441404,
      "TXT_PINCODE_LOCALITY": "KAMTHI COLLIERY",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441405,
      "TXT_PINCODE_LOCALITY": "KHAT R S",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441408,
      "TXT_PINCODE_LOCALITY": "NAGPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441409,
      "TXT_PINCODE_LOCALITY": "PANNI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441501,
      "TXT_PINCODE_LOCALITY": "KALMESHWAR - 2102",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441502,
      "TXT_PINCODE_LOCALITY": "MOHPA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441503,
      "TXT_PINCODE_LOCALITY": "DHAPEWADA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441504,
      "TXT_PINCODE_LOCALITY": "SAWARMENDHA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 5561,
      "City District Name": "SAVNER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441570,
      "TXT_PINCODE_LOCALITY": "ADUR *",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 565,
      "City District Name": "RATNAGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441600,
      "TXT_PINCODE_LOCALITY": "NAHARTOLA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 975,
      "City District Name": "TIRORA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441601,
      "TXT_PINCODE_LOCALITY": "GONDIA SINDHI COLONY",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 535,
      "City District Name": "BHANDARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441602,
      "TXT_PINCODE_LOCALITY": "KOMATHA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 3287,
      "City District Name": "GONDIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441603,
      "TXT_PINCODE_LOCALITY": "GANGA ZARI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 3287,
      "City District Name": "GONDIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441604,
      "TXT_PINCODE_LOCALITY": "DAVANIWADA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 3287,
      "City District Name": "GONDIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441605,
      "TXT_PINCODE_LOCALITY": "MUNDIKOTA R S",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 3287,
      "City District Name": "GONDIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441606,
      "TXT_PINCODE_LOCALITY": "YEKODI (SAKODI)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 535,
      "City District Name": "BHANDARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441607,
      "TXT_PINCODE_LOCALITY": "ASOLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 3287,
      "City District Name": "GONDIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441608,
      "TXT_PINCODE_LOCALITY": "RAWANWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 535,
      "City District Name": "BHANDARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441609,
      "TXT_PINCODE_LOCALITY": "NAGPARA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 3287,
      "City District Name": "GONDIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441613,
      "TXT_PINCODE_LOCALITY": "KHAMARI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 535,
      "City District Name": "BHANDARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441614,
      "TXT_PINCODE_LOCALITY": "GONDIA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441618,
      "TXT_PINCODE_LOCALITY": "RAMPURI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 3287,
      "City District Name": "GONDIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441701,
      "TXT_PINCODE_LOCALITY": "ARJUNI MOREGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 3287,
      "City District Name": "GONDIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441702,
      "TXT_PINCODE_LOCALITY": "NAWEGAON BANDH",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 3287,
      "City District Name": "GONDIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441703,
      "TXT_PINCODE_LOCALITY": "GOTHANGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 3287,
      "City District Name": "GONDIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441704,
      "TXT_PINCODE_LOCALITY": "NAVEGAON BAND",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 3287,
      "City District Name": "GONDIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441801,
      "TXT_PINCODE_LOCALITY": "GOREGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 546,
      "City District Name": "GONDIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441802,
      "TXT_PINCODE_LOCALITY": "SAKOLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 535,
      "City District Name": "BHANDARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441803,
      "TXT_PINCODE_LOCALITY": "LAKHANDUL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 535,
      "City District Name": "BHANDARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441804,
      "TXT_PINCODE_LOCALITY": "LAKHANI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 535,
      "City District Name": "BHANDARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441805,
      "TXT_PINCODE_LOCALITY": "DIGHORI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 535,
      "City District Name": "BHANDARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441806,
      "TXT_PINCODE_LOCALITY": "SAUNDUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 535,
      "City District Name": "BHANDARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441807,
      "TXT_PINCODE_LOCALITY": "SADAKARJUNI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 3287,
      "City District Name": "GONDIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441808,
      "TXT_PINCODE_LOCALITY": "SANGADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 535,
      "City District Name": "BHANDARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441809,
      "TXT_PINCODE_LOCALITY": "PHRA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 535,
      "City District Name": "BHANDARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441810,
      "TXT_PINCODE_LOCALITY": "KUDEGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 535,
      "City District Name": "BHANDARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441811,
      "TXT_PINCODE_LOCALITY": "PALANDUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 3287,
      "City District Name": "GONDIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441814,
      "TXT_PINCODE_LOCALITY": "GANGAZARI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 3287,
      "City District Name": "GONDIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441901,
      "TXT_PINCODE_LOCALITY": "DEORI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 3287,
      "City District Name": "GONDIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441902,
      "TXT_PINCODE_LOCALITY": "AMGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 3287,
      "City District Name": "GONDIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441903,
      "TXT_PINCODE_LOCALITY": "ADYAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 535,
      "City District Name": "BHANDARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441904,
      "TXT_PINCODE_LOCALITY": "BHANDARA TOWN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 535,
      "City District Name": "BHANDARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441905,
      "TXT_PINCODE_LOCALITY": "BHANDARA ROAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 535,
      "City District Name": "BHANDARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441906,
      "TXT_PINCODE_LOCALITY": "BHANDARA ORDINANCE FACTORY",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 535,
      "City District Name": "BHANDARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441907,
      "TXT_PINCODE_LOCALITY": "GOBARWAHI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 535,
      "City District Name": "BHANDARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441908,
      "TXT_PINCODE_LOCALITY": "KONDHA KOSRA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 535,
      "City District Name": "BHANDARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441909,
      "TXT_PINCODE_LOCALITY": "MOHADI S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 535,
      "City District Name": "BHANDARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441910,
      "TXT_PINCODE_LOCALITY": "PAVANI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 535,
      "City District Name": "BHANDARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441911,
      "TXT_PINCODE_LOCALITY": "TIRORA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 3287,
      "City District Name": "GONDIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441912,
      "TXT_PINCODE_LOCALITY": "TUMSAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 535,
      "City District Name": "BHANDARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441913,
      "TXT_PINCODE_LOCALITY": "TUMSAR ROAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 535,
      "City District Name": "BHANDARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441914,
      "TXT_PINCODE_LOCALITY": "ANDHALGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 535,
      "City District Name": "BHANDARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441915,
      "TXT_PINCODE_LOCALITY": "SEHAZA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441916,
      "TXT_PINCODE_LOCALITY": "SALEKASA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 3287,
      "City District Name": "GONDIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441917,
      "TXT_PINCODE_LOCALITY": "KARDI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 3287,
      "City District Name": "GONDIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441918,
      "TXT_PINCODE_LOCALITY": "NAKA DONGRI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 535,
      "City District Name": "BHANDARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441919,
      "TXT_PINCODE_LOCALITY": "WADEGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 3287,
      "City District Name": "GONDIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441920,
      "TXT_PINCODE_LOCALITY": "SHAHPUR (BHANDARA)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 535,
      "City District Name": "BHANDARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441921,
      "TXT_PINCODE_LOCALITY": "SATGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 3287,
      "City District Name": "GONDIYA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441922,
      "TXT_PINCODE_LOCALITY": "AMGAON DIGHORI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 535,
      "City District Name": "BHANDARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441923,
      "TXT_PINCODE_LOCALITY": "GHANGLA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 535,
      "City District Name": "BHANDARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441924,
      "TXT_PINCODE_LOCALITY": "PAHELA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 535,
      "City District Name": "BHANDARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441928,
      "TXT_PINCODE_LOCALITY": "DHARGAN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 535,
      "City District Name": "BHANDARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441930,
      "TXT_PINCODE_LOCALITY": "LOHARA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 535,
      "City District Name": "BHANDARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 441931,
      "TXT_PINCODE_LOCALITY": "ASEGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 535,
      "City District Name": "BHANDARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442001,
      "TXT_PINCODE_LOCALITY": "WARDHA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 203,
      "City District Name": "NAGPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442002,
      "TXT_PINCODE_LOCALITY": "COTTOM MARKET",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 580,
      "City District Name": "WARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442003,
      "TXT_PINCODE_LOCALITY": "HINDI NAGAR (WARDHA)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 580,
      "City District Name": "WARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442004,
      "TXT_PINCODE_LOCALITY": "COTTON MARKET WARDHA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 580,
      "City District Name": "WARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442005,
      "TXT_PINCODE_LOCALITY": "MANAS MANDIR WARDHA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 580,
      "City District Name": "WARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442006,
      "TXT_PINCODE_LOCALITY": "M I D C WARDHA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 580,
      "City District Name": "WARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442020,
      "TXT_PINCODE_LOCALITY": "SONEGAON.",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 3795,
      "City District Name": "KARANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442079,
      "TXT_PINCODE_LOCALITY": "SINDA M",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442163,
      "City District Name": "AHERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442101,
      "TXT_PINCODE_LOCALITY": "DEOLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 580,
      "City District Name": "WARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442102,
      "TXT_PINCODE_LOCALITY": "SEVAGRAM",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 580,
      "City District Name": "WARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442103,
      "TXT_PINCODE_LOCALITY": "ANJI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 580,
      "City District Name": "WARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442104,
      "TXT_PINCODE_LOCALITY": "SELU",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 580,
      "City District Name": "WARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442105,
      "TXT_PINCODE_LOCALITY": "SINDI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 580,
      "City District Name": "WARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442106,
      "TXT_PINCODE_LOCALITY": "KHARANGANA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 580,
      "City District Name": "WARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442107,
      "TXT_PINCODE_LOCALITY": "SAWANGI MEGHE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 580,
      "City District Name": "WARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442109,
      "TXT_PINCODE_LOCALITY": "HINGANI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 580,
      "City District Name": "WARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442110,
      "TXT_PINCODE_LOCALITY": "DAHEGAON GOSAVI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 580,
      "City District Name": "WARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442111,
      "TXT_PINCODE_LOCALITY": "PAUNAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 580,
      "City District Name": "WARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442115,
      "TXT_PINCODE_LOCALITY": "DORLI.",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442871,
      "City District Name": "SELOO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442116,
      "TXT_PINCODE_LOCALITY": "RAMPUR.",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 580,
      "City District Name": "WARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442140,
      "TXT_PINCODE_LOCALITY": "GOHDA (KALA)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442871,
      "City District Name": "SELOO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442201,
      "TXT_PINCODE_LOCALITY": "ARVI (WARDHA)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 580,
      "City District Name": "WARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442202,
      "TXT_PINCODE_LOCALITY": "ASHTI (WARDHA)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 580,
      "City District Name": "WARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442203,
      "TXT_PINCODE_LOCALITY": "KARANJA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 580,
      "City District Name": "WARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442204,
      "TXT_PINCODE_LOCALITY": "TALEGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 580,
      "City District Name": "WARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442205,
      "TXT_PINCODE_LOCALITY": "WARDHA MANERI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 580,
      "City District Name": "WARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442206,
      "TXT_PINCODE_LOCALITY": "SARWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 580,
      "City District Name": "WARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442207,
      "TXT_PINCODE_LOCALITY": "WADHONA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 580,
      "City District Name": "WARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442209,
      "TXT_PINCODE_LOCALITY": "YEDASKUHI MASEHAT.",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442851,
      "City District Name": "KURKHEDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442212,
      "TXT_PINCODE_LOCALITY": "PALORA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 3795,
      "City District Name": "KARANJA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442260,
      "TXT_PINCODE_LOCALITY": "NAGRI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1786,
      "City District Name": "GADCHIROLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442290,
      "TXT_PINCODE_LOCALITY": "PITIGUDA NO.1",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442843,
      "City District Name": "JIWATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442301,
      "TXT_PINCODE_LOCALITY": "WAGHOLI (WARDHA)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442302,
      "TXT_PINCODE_LOCALITY": "PULGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 580,
      "City District Name": "WARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442303,
      "TXT_PINCODE_LOCALITY": "PULGAON CAMP",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 580,
      "City District Name": "WARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442304,
      "TXT_PINCODE_LOCALITY": "ALLIPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 580,
      "City District Name": "WARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442305,
      "TXT_PINCODE_LOCALITY": "SAMUDRAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 580,
      "City District Name": "WARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442306,
      "TXT_PINCODE_LOCALITY": "NACHANGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 580,
      "City District Name": "WARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442307,
      "TXT_PINCODE_LOCALITY": "WADNER",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 580,
      "City District Name": "WARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442308,
      "TXT_PINCODE_LOCALITY": "WAGHOLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 580,
      "City District Name": "WARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442309,
      "TXT_PINCODE_LOCALITY": "ROHNA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 580,
      "City District Name": "WARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442310,
      "TXT_PINCODE_LOCALITY": "GIRAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 580,
      "City District Name": "WARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442311,
      "TXT_PINCODE_LOCALITY": "RASULABAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 580,
      "City District Name": "WARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442312,
      "TXT_PINCODE_LOCALITY": "VIRUL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 580,
      "City District Name": "WARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442316,
      "TXT_PINCODE_LOCALITY": "ANTARGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442401,
      "TXT_PINCODE_LOCALITY": "CHANDRAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442402,
      "TXT_PINCODE_LOCALITY": "CHANDRAPUR CITY",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442403,
      "TXT_PINCODE_LOCALITY": "CHANDRAPUR BAPU PETH",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442404,
      "TXT_PINCODE_LOCALITY": "DURGAPUR - 2545",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442406,
      "TXT_PINCODE_LOCALITY": "PADOLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442501,
      "TXT_PINCODE_LOCALITY": "CHANDRAPUR ORDINANCE FACTORY",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442502,
      "TXT_PINCODE_LOCALITY": "CEMENT NAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442503,
      "TXT_PINCODE_LOCALITY": "SHIVAJINAGAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442504,
      "TXT_PINCODE_LOCALITY": "SIRONCHA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1786,
      "City District Name": "GADCHIROLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442505,
      "TXT_PINCODE_LOCALITY": "GHUGHS",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442506,
      "TXT_PINCODE_LOCALITY": "PAMBHURNA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442507,
      "TXT_PINCODE_LOCALITY": "H.L.C. S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442603,
      "TXT_PINCODE_LOCALITY": "CHAMORSHI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1786,
      "City District Name": "GADCHIROLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442604,
      "TXT_PINCODE_LOCALITY": "GHOT",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1786,
      "City District Name": "GADCHIROLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442605,
      "TXT_PINCODE_LOCALITY": "GADCHIROLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1786,
      "City District Name": "GADCHIROLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442606,
      "TXT_PINCODE_LOCALITY": "DHANORA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1786,
      "City District Name": "GADCHIROLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442607,
      "TXT_PINCODE_LOCALITY": "MARDA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 5288,
      "City District Name": "RAJURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442608,
      "TXT_PINCODE_LOCALITY": "DHANORE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 580,
      "City District Name": "WARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442701,
      "TXT_PINCODE_LOCALITY": "KOTHARI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442702,
      "TXT_PINCODE_LOCALITY": "GONDPIPRI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442703,
      "TXT_PINCODE_LOCALITY": "ALLAPALLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1786,
      "City District Name": "GADCHIROLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442704,
      "TXT_PINCODE_LOCALITY": "ETAPALLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1786,
      "City District Name": "GADCHIROLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442705,
      "TXT_PINCODE_LOCALITY": "ADERI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1786,
      "City District Name": "GADCHIROLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442706,
      "TXT_PINCODE_LOCALITY": "CHANDRAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 5540,
      "City District Name": "SASTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442707,
      "TXT_PINCODE_LOCALITY": "ASHTI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1786,
      "City District Name": "GADCHIROLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442709,
      "TXT_PINCODE_LOCALITY": "DHAMRANCHA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1786,
      "City District Name": "GADCHIROLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442710,
      "TXT_PINCODE_LOCALITY": "BHAMRAGARH",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1786,
      "City District Name": "GADCHIROLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442729,
      "TXT_PINCODE_LOCALITY": "KARWAHI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442849,
      "City District Name": "KORPANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442806,
      "TXT_PINCODE_LOCALITY": "DATTAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 6383,
      "City District Name": "DEOLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442901,
      "TXT_PINCODE_LOCALITY": "BALLARPUR PAPER MILLS",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442902,
      "TXT_PINCODE_LOCALITY": "BHADRAVATI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442903,
      "TXT_PINCODE_LOCALITY": "BHISI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442904,
      "TXT_PINCODE_LOCALITY": "NERI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442905,
      "TXT_PINCODE_LOCALITY": "AHERI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442906,
      "TXT_PINCODE_LOCALITY": "SEGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442907,
      "TXT_PINCODE_LOCALITY": "DONGARGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442908,
      "TXT_PINCODE_LOCALITY": "NANDGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442910,
      "TXT_PINCODE_LOCALITY": "MADHELI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442912,
      "TXT_PINCODE_LOCALITY": "NAGRI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 580,
      "City District Name": "WARDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442913,
      "TXT_PINCODE_LOCALITY": "CHANDRAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442914,
      "TXT_PINCODE_LOCALITY": "ANANDWAN",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442915,
      "TXT_PINCODE_LOCALITY": "WIRUR R S",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442916,
      "TXT_PINCODE_LOCALITY": "WARSADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442917,
      "TXT_PINCODE_LOCALITY": "AWALPUR CEMENT PROJECT S.O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 539,
      "City District Name": "CHANDRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442918,
      "TXT_PINCODE_LOCALITY": "RAMPUR ZADIKAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 442867,
      "City District Name": "POMBHURNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 442919,
      "TXT_PINCODE_LOCALITY": "GADCHIROLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1786,
      "City District Name": "GADCHIROLI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 443001,
      "TXT_PINCODE_LOCALITY": "BULDANA H O",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 443002,
      "TXT_PINCODE_LOCALITY": "TANDULWADI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 443101,
      "TXT_PINCODE_LOCALITY": "MALKAPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 443102,
      "TXT_PINCODE_LOCALITY": "DATALA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 443103,
      "TXT_PINCODE_LOCALITY": "MOTHALA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 443104,
      "TXT_PINCODE_LOCALITY": "DHAMANGAON BADA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 443105,
      "TXT_PINCODE_LOCALITY": "DEUL GAON GHAI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 443106,
      "TXT_PINCODE_LOCALITY": "DHAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 443107,
      "TXT_PINCODE_LOCALITY": "PADLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 443108,
      "TXT_PINCODE_LOCALITY": "SAKLI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 443109,
      "TXT_PINCODE_LOCALITY": "SHELSUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 443110,
      "TXT_PINCODE_LOCALITY": "SHIRPUR (BULDANA)",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 443111,
      "TXT_PINCODE_LOCALITY": "NARVEL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 443112,
      "TXT_PINCODE_LOCALITY": "DHARANGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 443201,
      "TXT_PINCODE_LOCALITY": "CHIKHLI BULDANA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 443202,
      "TXT_PINCODE_LOCALITY": "SAKHAR KHERDA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 443203,
      "TXT_PINCODE_LOCALITY": "SINDKHED RAJA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 443204,
      "TXT_PINCODE_LOCALITY": "DEOLGAON RAJA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 443205,
      "TXT_PINCODE_LOCALITY": "ANDHERA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 443206,
      "TXT_PINCODE_LOCALITY": "DEUL GAON MAHI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 443207,
      "TXT_PINCODE_LOCALITY": "SAWANA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 443208,
      "TXT_PINCODE_LOCALITY": "SAWARGAON DURKE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 443209,
      "TXT_PINCODE_LOCALITY": "BULDHANA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 443301,
      "TXT_PINCODE_LOCALITY": "MEHEKAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 443302,
      "TXT_PINCODE_LOCALITY": "SULTANPUR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 443303,
      "TXT_PINCODE_LOCALITY": "DONGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 443304,
      "TXT_PINCODE_LOCALITY": "JANEPHAL",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 443305,
      "TXT_PINCODE_LOCALITY": "AMRAOTI WALGAON ROAD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 443306,
      "TXT_PINCODE_LOCALITY": "DED GAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 443307,
      "TXT_PINCODE_LOCALITY": "BULDHANA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 537,
      "City District Name": "BULDHANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 443308,
      "TXT_PINCODE_LOCALITY": "DUSARBID",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 443401,
      "TXT_PINCODE_LOCALITY": "CHANDUR BISWA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 443402,
      "TXT_PINCODE_LOCALITY": "JALGAON BULDANA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 443403,
      "TXT_PINCODE_LOCALITY": "PIMPALGAON KALE",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 443404,
      "TXT_PINCODE_LOCALITY": "NANDURA",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 443405,
      "TXT_PINCODE_LOCALITY": "WADNER BHOLAJI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 443406,
      "TXT_PINCODE_LOCALITY": "JAMOD",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 443407,
      "TXT_PINCODE_LOCALITY": "MADAKHED",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 1813,
      "City District Name": "BULDANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360001,
      "TXT_PINCODE_LOCALITY": "RAJPUTPARA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360002,
      "TXT_PINCODE_LOCALITY": "KALAVAD ROAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360003,
      "TXT_PINCODE_LOCALITY": "MARKETING YARD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360004,
      "TXT_PINCODE_LOCALITY": "RAJKOT SAURASTRA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360005,
      "TXT_PINCODE_LOCALITY": "KALAVAD ROAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360006,
      "TXT_PINCODE_LOCALITY": "RAJKOT BHOMESHWAR PLOT",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360007,
      "TXT_PINCODE_LOCALITY": "RING ROAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360008,
      "TXT_PINCODE_LOCALITY": "VIMAL NAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360009,
      "TXT_PINCODE_LOCALITY": "STATION PLOT",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360011,
      "TXT_PINCODE_LOCALITY": "MAVDI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360018,
      "TXT_PINCODE_LOCALITY": "MADHAPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360019,
      "TXT_PINCODE_LOCALITY": "RATALA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360020,
      "TXT_PINCODE_LOCALITY": "KASTURBADHAM",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360021,
      "TXT_PINCODE_LOCALITY": "METODA GIDC",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360022,
      "TXT_PINCODE_LOCALITY": "RAJKOT S. O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360023,
      "TXT_PINCODE_LOCALITY": "RAJKOT.",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360024,
      "TXT_PINCODE_LOCALITY": "BHUPGADH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360025,
      "TXT_PINCODE_LOCALITY": "SARDHAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360026,
      "TXT_PINCODE_LOCALITY": "KHARACHIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360030,
      "TXT_PINCODE_LOCALITY": "KOTDA SANGANI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360032,
      "TXT_PINCODE_LOCALITY": "HALENDA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360035,
      "TXT_PINCODE_LOCALITY": "LODHIKA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360036,
      "TXT_PINCODE_LOCALITY": "KOTADA SAGANI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360040,
      "TXT_PINCODE_LOCALITY": "ATKOT",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360045,
      "TXT_PINCODE_LOCALITY": "DADVAMOTA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360050,
      "TXT_PINCODE_LOCALITY": "VINCHHIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360055,
      "TXT_PINCODE_LOCALITY": "AMRAPAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360060,
      "TXT_PINCODE_LOCALITY": "VIRNAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360070,
      "TXT_PINCODE_LOCALITY": "MENGANI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360075,
      "TXT_PINCODE_LOCALITY": "RIBDA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360080,
      "TXT_PINCODE_LOCALITY": "BHADLA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360110,
      "TXT_PINCODE_LOCALITY": "PADDHARI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360230,
      "TXT_PINCODE_LOCALITY": "PORBANDAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 685,
      "City District Name": "PORBANDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360311,
      "TXT_PINCODE_LOCALITY": "BHUVNESHWARI PITH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360320,
      "TXT_PINCODE_LOCALITY": "MOVIYA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360330,
      "TXT_PINCODE_LOCALITY": "MOVIYA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360335,
      "TXT_PINCODE_LOCALITY": "SHIVARAJGADH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360360,
      "TXT_PINCODE_LOCALITY": "JETALSAR JN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360370,
      "TXT_PINCODE_LOCALITY": "KAPAD BAZAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360375,
      "TXT_PINCODE_LOCALITY": "NAVAGADH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360380,
      "TXT_PINCODE_LOCALITY": "VIRPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360405,
      "TXT_PINCODE_LOCALITY": "JAM KANDORNA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360410,
      "TXT_PINCODE_LOCALITY": "DHAROJI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360411,
      "TXT_PINCODE_LOCALITY": "DHORAJI SUGAR FACTORY",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360421,
      "TXT_PINCODE_LOCALITY": "MOTI MURAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360430,
      "TXT_PINCODE_LOCALITY": "PATANVAV",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360440,
      "TXT_PINCODE_LOCALITY": "SUPEDI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360450,
      "TXT_PINCODE_LOCALITY": "BHAYAVADAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360452,
      "TXT_PINCODE_LOCALITY": "CHITRAVAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360460,
      "TXT_PINCODE_LOCALITY": "DHANK",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360470,
      "TXT_PINCODE_LOCALITY": "KOLKI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360480,
      "TXT_PINCODE_LOCALITY": "PANELI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360490,
      "TXT_PINCODE_LOCALITY": "PANCH HATDI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360510,
      "TXT_PINCODE_LOCALITY": "RANJITPURA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360515,
      "TXT_PINCODE_LOCALITY": "VERAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360520,
      "TXT_PINCODE_LOCALITY": "DHRAPHA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360521,
      "TXT_PINCODE_LOCALITY": "SAMANA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360530,
      "TXT_PINCODE_LOCALITY": "JAM JODHPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360531,
      "TXT_PINCODE_LOCALITY": "VANSJALIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360540,
      "TXT_PINCODE_LOCALITY": "KHAREDI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360545,
      "TXT_PINCODE_LOCALITY": "ADITYANA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 685,
      "City District Name": "PORBANDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360550,
      "TXT_PINCODE_LOCALITY": "RANAVAV",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 685,
      "City District Name": "PORBANDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360560,
      "TXT_PINCODE_LOCALITY": "RANAVA RS",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 685,
      "City District Name": "PORBANDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360570,
      "TXT_PINCODE_LOCALITY": "RANAKANDORNA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 685,
      "City District Name": "PORBANDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360571,
      "TXT_PINCODE_LOCALITY": "MOKAL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 685,
      "City District Name": "PORBANDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360572,
      "TXT_PINCODE_LOCALITY": "VADDLA RANA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 685,
      "City District Name": "PORBANDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360575,
      "TXT_PINCODE_LOCALITY": "MANEK CHOWK",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360576,
      "TXT_PINCODE_LOCALITY": "PORBANDAR BIRLASAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 685,
      "City District Name": "PORBANDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360577,
      "TXT_PINCODE_LOCALITY": "PORBANDAR G ID C",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 685,
      "City District Name": "PORBANDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360578,
      "TXT_PINCODE_LOCALITY": "CHHAYA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 685,
      "City District Name": "PORBANDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360579,
      "TXT_PINCODE_LOCALITY": "BOKHIRA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 685,
      "City District Name": "PORBANDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360583,
      "TXT_PINCODE_LOCALITY": "BAKHARLA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 685,
      "City District Name": "PORBANDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360585,
      "TXT_PINCODE_LOCALITY": "NAVIBUNDAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 685,
      "City District Name": "PORBANDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360590,
      "TXT_PINCODE_LOCALITY": "BAGVADAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 685,
      "City District Name": "PORBANDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360592,
      "TXT_PINCODE_LOCALITY": "FATANA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 685,
      "City District Name": "PORBANDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 360595,
      "TXT_PINCODE_LOCALITY": "SODHANA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 685,
      "City District Name": "PORBANDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361001,
      "TXT_PINCODE_LOCALITY": "NEW SUPER MARKET",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361002,
      "TXT_PINCODE_LOCALITY": "JAMNAGAR BEDESHWAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361003,
      "TXT_PINCODE_LOCALITY": "JAMNAGAR AERODROME",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361004,
      "TXT_PINCODE_LOCALITY": "NAGAR ESTATE",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361005,
      "TXT_PINCODE_LOCALITY": "PLOT",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361006,
      "TXT_PINCODE_LOCALITY": "JAMNAGAR KHODIYAR COLONY",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361007,
      "TXT_PINCODE_LOCALITY": "JAMNAGAR GULABNAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361008,
      "TXT_PINCODE_LOCALITY": "VADINAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361009,
      "TXT_PINCODE_LOCALITY": "JAMNAGAR UDYOG",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361010,
      "TXT_PINCODE_LOCALITY": "VADINAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361011,
      "TXT_PINCODE_LOCALITY": "HADIANA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361012,
      "TXT_PINCODE_LOCALITY": "CHELA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361013,
      "TXT_PINCODE_LOCALITY": "KHANDHERA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361110,
      "TXT_PINCODE_LOCALITY": "ALIABADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361120,
      "TXT_PINCODE_LOCALITY": "DHUVAV",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361130,
      "TXT_PINCODE_LOCALITY": "JAM VENTHLI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361140,
      "TXT_PINCODE_LOCALITY": "DIGVIJAYGRAM",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361141,
      "TXT_PINCODE_LOCALITY": "SIKKA TPS",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361142,
      "TXT_PINCODE_LOCALITY": "NAGARJUN PETROL PUMP",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361150,
      "TXT_PINCODE_LOCALITY": "VALSURA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361160,
      "TXT_PINCODE_LOCALITY": "KALAVAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361162,
      "TXT_PINCODE_LOCALITY": "NIKAVA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361170,
      "TXT_PINCODE_LOCALITY": "LALPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361210,
      "TXT_PINCODE_LOCALITY": "DHROL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361220,
      "TXT_PINCODE_LOCALITY": "LATIPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361230,
      "TXT_PINCODE_LOCALITY": "BALACHADI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361240,
      "TXT_PINCODE_LOCALITY": "BALAMBHA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361250,
      "TXT_PINCODE_LOCALITY": "JODIYA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361280,
      "TXT_PINCODE_LOCALITY": "KANALUS",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361285,
      "TXT_PINCODE_LOCALITY": "KENEDI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361290,
      "TXT_PINCODE_LOCALITY": "BHOGAT",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361295,
      "TXT_PINCODE_LOCALITY": "JAM GADHKA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361301,
      "TXT_PINCODE_LOCALITY": "NADANA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361305,
      "TXT_PINCODE_LOCALITY": "KHAMBHALIA H O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361306,
      "TXT_PINCODE_LOCALITY": "BHADTHAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361310,
      "TXT_PINCODE_LOCALITY": "SALAYA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361315,
      "TXT_PINCODE_LOCALITY": "BHATIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361316,
      "TXT_PINCODE_LOCALITY": "RAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361320,
      "TXT_PINCODE_LOCALITY": "JAM KALYANPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361321,
      "TXT_PINCODE_LOCALITY": "JAM DEVALIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361322,
      "TXT_PINCODE_LOCALITY": "LAMBA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361325,
      "TXT_PINCODE_LOCALITY": "RAVAL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361330,
      "TXT_PINCODE_LOCALITY": "BET",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361335,
      "TXT_PINCODE_LOCALITY": "TOWN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361336,
      "TXT_PINCODE_LOCALITY": "DWARKA CEMENT FACTORY",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361345,
      "TXT_PINCODE_LOCALITY": "MITHAPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361347,
      "TXT_PINCODE_LOCALITY": "SURAJ KARADI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361350,
      "TXT_PINCODE_LOCALITY": "BAZAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 361540,
      "TXT_PINCODE_LOCALITY": "VIDYALAYA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362001,
      "TXT_PINCODE_LOCALITY": "DIWAN CHOWK",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362002,
      "TXT_PINCODE_LOCALITY": "JUNAGADH JOSHIPURA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362003,
      "TXT_PINCODE_LOCALITY": "JUNAGADH UDYOGNAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362004,
      "TXT_PINCODE_LOCALITY": "JUNAGADH BHAVNATH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362011,
      "TXT_PINCODE_LOCALITY": "MAJEVADI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362012,
      "TXT_PINCODE_LOCALITY": "DHANDHUKA KHAND",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362013,
      "TXT_PINCODE_LOCALITY": "KHADIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362014,
      "TXT_PINCODE_LOCALITY": "MAKHIALA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362015,
      "TXT_PINCODE_LOCALITY": "TIMBAVADI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362020,
      "TXT_PINCODE_LOCALITY": "BHESAN (S)",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362021,
      "TXT_PINCODE_LOCALITY": "CHUDA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362030,
      "TXT_PINCODE_LOCALITY": "RANPUR SORATH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362036,
      "TXT_PINCODE_LOCALITY": "CHHODVADI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362037,
      "TXT_PINCODE_LOCALITY": "DOLATPURA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362110,
      "TXT_PINCODE_LOCALITY": "BILKHA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362111,
      "TXT_PINCODE_LOCALITY": "MANGNATH PIPLI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362120,
      "TXT_PINCODE_LOCALITY": "SARSAI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362130,
      "TXT_PINCODE_LOCALITY": "VISAVADAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362132,
      "TXT_PINCODE_LOCALITY": "KALSARI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362133,
      "TXT_PINCODE_LOCALITY": "MOTI MONPURI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362135,
      "TXT_PINCODE_LOCALITY": "SASANGIR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362136,
      "TXT_PINCODE_LOCALITY": "BORVAV",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362140,
      "TXT_PINCODE_LOCALITY": "AKOLWADI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362142,
      "TXT_PINCODE_LOCALITY": "PAVTHI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362150,
      "TXT_PINCODE_LOCALITY": "JUNAGADH S.O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 442333,
      "City District Name": "TALALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362151,
      "TXT_PINCODE_LOCALITY": "GHUNSIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362152,
      "TXT_PINCODE_LOCALITY": "AMBLAS",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362154,
      "TXT_PINCODE_LOCALITY": "KHIRDHAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 442333,
      "City District Name": "TALALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362155,
      "TXT_PINCODE_LOCALITY": "CHITRAVAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362168,
      "TXT_PINCODE_LOCALITY": "JUNAGADH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362205,
      "TXT_PINCODE_LOCALITY": "POWER HOUSE",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362215,
      "TXT_PINCODE_LOCALITY": "LUSHALA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362216,
      "TXT_PINCODE_LOCALITY": "THANA PIPLI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362220,
      "TXT_PINCODE_LOCALITY": "AERODRAMME ROAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362221,
      "TXT_PINCODE_LOCALITY": "BALAGAM",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362222,
      "TXT_PINCODE_LOCALITY": "AGATRAI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362223,
      "TXT_PINCODE_LOCALITY": "MESHVAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362224,
      "TXT_PINCODE_LOCALITY": "KHIRASRAGHED",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362225,
      "TXT_PINCODE_LOCALITY": "MANGROL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362226,
      "TXT_PINCODE_LOCALITY": "LOEJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362227,
      "TXT_PINCODE_LOCALITY": "KEVADRA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362228,
      "TXT_PINCODE_LOCALITY": "AJAB",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362229,
      "TXT_PINCODE_LOCALITY": "AJAB",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362230,
      "TXT_PINCODE_LOCALITY": "MADHAVPUR GHED",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 685,
      "City District Name": "PORBANDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362231,
      "TXT_PINCODE_LOCALITY": "MANDER",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 685,
      "City District Name": "PORBANDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362235,
      "TXT_PINCODE_LOCALITY": "SHARDAGRAM",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362238,
      "TXT_PINCODE_LOCALITY": "NAVAGAM",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 442332,
      "City District Name": "SUTRAPADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362240,
      "TXT_PINCODE_LOCALITY": "SHIL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362242,
      "TXT_PINCODE_LOCALITY": "MEKHADI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362243,
      "TXT_PINCODE_LOCALITY": "EKLERA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 828,
      "City District Name": "KESHOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362245,
      "TXT_PINCODE_LOCALITY": "MALIYA HATINA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362246,
      "TXT_PINCODE_LOCALITY": "AMRAPUR GIR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362247,
      "TXT_PINCODE_LOCALITY": "JUTHAL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362248,
      "TXT_PINCODE_LOCALITY": "BHANDURI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362249,
      "TXT_PINCODE_LOCALITY": "SHERGADH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362250,
      "TXT_PINCODE_LOCALITY": "CHORVAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362255,
      "TXT_PINCODE_LOCALITY": "SHERBAUG",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362256,
      "TXT_PINCODE_LOCALITY": "VADODARA DODIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362257,
      "TXT_PINCODE_LOCALITY": "ADRI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362258,
      "TXT_PINCODE_LOCALITY": "KHORASA GIR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362259,
      "TXT_PINCODE_LOCALITY": "DARI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 685,
      "City District Name": "PORBANDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362260,
      "TXT_PINCODE_LOCALITY": "MEDARDA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362263,
      "TXT_PINCODE_LOCALITY": "BAGDU",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362265,
      "TXT_PINCODE_LOCALITY": "KUTIYANA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362266,
      "TXT_PINCODE_LOCALITY": "VERABLE RAYON FACTORY",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362267,
      "TXT_PINCODE_LOCALITY": "VERAVAL BHIDIYA PLOT",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362268,
      "TXT_PINCODE_LOCALITY": "PRABHAS PATAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362269,
      "TXT_PINCODE_LOCALITY": "VERAVAL U. NAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362271,
      "TXT_PINCODE_LOCALITY": "LODHAVA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362275,
      "TXT_PINCODE_LOCALITY": "SUTRAPADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362276,
      "TXT_PINCODE_LOCALITY": "DHAMLEJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362278,
      "TXT_PINCODE_LOCALITY": "PRASNAVADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362290,
      "TXT_PINCODE_LOCALITY": "DEVGADH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 442302,
      "City District Name": "MENDARDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362310,
      "TXT_PINCODE_LOCALITY": "VADAL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362315,
      "TXT_PINCODE_LOCALITY": "CHOKI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362340,
      "TXT_PINCODE_LOCALITY": "JUNAGADH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362425,
      "TXT_PINCODE_LOCALITY": "SURENDRANAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362435,
      "TXT_PINCODE_LOCALITY": "SURENDRANAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362442,
      "TXT_PINCODE_LOCALITY": "RANI SATI COLONY",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 442659,
      "City District Name": "JUNAGADH (URBAN)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362460,
      "TXT_PINCODE_LOCALITY": "MAHOBATPARA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 442297,
      "City District Name": "GIR GADHDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362510,
      "TXT_PINCODE_LOCALITY": "DELVADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362515,
      "TXT_PINCODE_LOCALITY": "NAVABUNDER",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362520,
      "TXT_PINCODE_LOCALITY": "DABHEL",
      "NUM_STATE_CD": 161,
      "State Name": "DAMAN & DIU",
      "NUM_CITYDISTRICT_CD": 3042,
      "City District Name": "DIU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362530,
      "TXT_PINCODE_LOCALITY": "SAIYAD RAJPURA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362540,
      "TXT_PINCODE_LOCALITY": "GHOGHLA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424445,
      "City District Name": "THE DANGS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362550,
      "TXT_PINCODE_LOCALITY": "SANAKHADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362551,
      "TXT_PINCODE_LOCALITY": "SIMAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362552,
      "TXT_PINCODE_LOCALITY": "SAYAD RAJPARA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362560,
      "TXT_PINCODE_LOCALITY": "UNA SORATH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362565,
      "TXT_PINCODE_LOCALITY": "BAZAR DHOKADAVA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362570,
      "TXT_PINCODE_LOCALITY": "VANAKBARA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362571,
      "TXT_PINCODE_LOCALITY": "BHUCHARVADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362585,
      "TXT_PINCODE_LOCALITY": "AMBADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362610,
      "TXT_PINCODE_LOCALITY": "BAZAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362615,
      "TXT_PINCODE_LOCALITY": "KANJHA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362618,
      "TXT_PINCODE_LOCALITY": "VADHAVI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362620,
      "TXT_PINCODE_LOCALITY": "BANTWA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362621,
      "TXT_PINCODE_LOCALITY": "LIMBUDA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362623,
      "TXT_PINCODE_LOCALITY": "MAHIARI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 685,
      "City District Name": "PORBANDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362624,
      "TXT_PINCODE_LOCALITY": "PAJOD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362625,
      "TXT_PINCODE_LOCALITY": "NANADIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362626,
      "TXT_PINCODE_LOCALITY": "GHED BAMNASA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362627,
      "TXT_PINCODE_LOCALITY": "MATIYANA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362630,
      "TXT_PINCODE_LOCALITY": "MANAVADAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362633,
      "TXT_PINCODE_LOCALITY": "KOYLANA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362634,
      "TXT_PINCODE_LOCALITY": "SANOSRA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362640,
      "TXT_PINCODE_LOCALITY": "SARDARGADH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362650,
      "TXT_PINCODE_LOCALITY": "CHAUTA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 685,
      "City District Name": "PORBANDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362652,
      "TXT_PINCODE_LOCALITY": "DEVDA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 685,
      "City District Name": "PORBANDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362653,
      "TXT_PINCODE_LOCALITY": "KHAGESHRI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 685,
      "City District Name": "PORBANDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362710,
      "TXT_PINCODE_LOCALITY": "GHANTVAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362715,
      "TXT_PINCODE_LOCALITY": "JUNAGADH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362720,
      "TXT_PINCODE_LOCALITY": "KODINAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362721,
      "TXT_PINCODE_LOCALITY": "HARMADIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362722,
      "TXT_PINCODE_LOCALITY": "VELAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362725,
      "TXT_PINCODE_LOCALITY": "KODINAR K U",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362726,
      "TXT_PINCODE_LOCALITY": "ARNEJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362727,
      "TXT_PINCODE_LOCALITY": "VADNAGAR KODINAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362730,
      "TXT_PINCODE_LOCALITY": "TIMBI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362733,
      "TXT_PINCODE_LOCALITY": "CHHARA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362777,
      "TXT_PINCODE_LOCALITY": "ALIDAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362820,
      "TXT_PINCODE_LOCALITY": "DEVALPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4027,
      "City District Name": "KODINAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 362939,
      "TXT_PINCODE_LOCALITY": "RANPUR (SORATH)",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363001,
      "TXT_PINCODE_LOCALITY": "SURENDRANAGAR H O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363002,
      "TXT_PINCODE_LOCALITY": "SURENDRA NAGAR STN ROAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363003,
      "TXT_PINCODE_LOCALITY": "SURENDRANAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363020,
      "TXT_PINCODE_LOCALITY": "JORAVARNAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363023,
      "TXT_PINCODE_LOCALITY": "KHERALI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363030,
      "TXT_PINCODE_LOCALITY": "WADHWAN CITY",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363035,
      "TXT_PINCODE_LOCALITY": "WADWAN CITY IE",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363040,
      "TXT_PINCODE_LOCALITY": "DUDHREJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363041,
      "TXT_PINCODE_LOCALITY": "KHODU",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363045,
      "TXT_PINCODE_LOCALITY": "GUJARWADI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363110,
      "TXT_PINCODE_LOCALITY": "VANA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363115,
      "TXT_PINCODE_LOCALITY": "DHANKI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363310,
      "TXT_PINCODE_LOCALITY": "DHRANGADHRA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363312,
      "TXT_PINCODE_LOCALITY": "KONDH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363315,
      "TXT_PINCODE_LOCALITY": "DHRANGADHRA C W",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363320,
      "TXT_PINCODE_LOCALITY": "RAJSITAPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363330,
      "TXT_PINCODE_LOCALITY": "HALVAD BAZAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363331,
      "TXT_PINCODE_LOCALITY": "CHARADAVA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363332,
      "TXT_PINCODE_LOCALITY": "DEVALIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363333,
      "TXT_PINCODE_LOCALITY": "TIKAR RANA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363334,
      "TXT_PINCODE_LOCALITY": "SARA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363351,
      "TXT_PINCODE_LOCALITY": "MAYUR NAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363410,
      "TXT_PINCODE_LOCALITY": "CHUDA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363411,
      "TXT_PINCODE_LOCALITY": "MOJIDAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363412,
      "TXT_PINCODE_LOCALITY": "CHOKDI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363415,
      "TXT_PINCODE_LOCALITY": "BHRUGHUPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363416,
      "TXT_PINCODE_LOCALITY": "NAGNESH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363420,
      "TXT_PINCODE_LOCALITY": "SURENDRANAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363421,
      "TXT_PINCODE_LOCALITY": "D B CHOWK",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363423,
      "TXT_PINCODE_LOCALITY": "PANSINA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363424,
      "TXT_PINCODE_LOCALITY": "JAMBU",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363425,
      "TXT_PINCODE_LOCALITY": "HADALA BHAL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363426,
      "TXT_PINCODE_LOCALITY": "BHOIKA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363427,
      "TXT_PINCODE_LOCALITY": "SHIYANI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363430,
      "TXT_PINCODE_LOCALITY": "SAYLA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363435,
      "TXT_PINCODE_LOCALITY": "RAMPURA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363440,
      "TXT_PINCODE_LOCALITY": "SUDAMADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363451,
      "TXT_PINCODE_LOCALITY": "MAYURNAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363504,
      "TXT_PINCODE_LOCALITY": "BHADRESHI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363510,
      "TXT_PINCODE_LOCALITY": "MULI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363520,
      "TXT_PINCODE_LOCALITY": "CHOTILA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363530,
      "TXT_PINCODE_LOCALITY": "THANGADH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363535,
      "TXT_PINCODE_LOCALITY": "LUNSAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363610,
      "TXT_PINCODE_LOCALITY": "RANPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 173,
      "City District Name": "AHMEDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363621,
      "TXT_PINCODE_LOCALITY": "WANKANER",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363622,
      "TXT_PINCODE_LOCALITY": "WANKANER",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363626,
      "TXT_PINCODE_LOCALITY": "DALDI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363627,
      "TXT_PINCODE_LOCALITY": "MAHIKA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363628,
      "TXT_PINCODE_LOCALITY": "TITHVA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363630,
      "TXT_PINCODE_LOCALITY": "JETPUR MORBI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363635,
      "TXT_PINCODE_LOCALITY": "RAJKOT",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363636,
      "TXT_PINCODE_LOCALITY": "SHRI SARDANAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363641,
      "TXT_PINCODE_LOCALITY": "GREEN CHOWK",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363642,
      "TXT_PINCODE_LOCALITY": "TIMBADI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 911,
      "City District Name": "MORBI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363643,
      "TXT_PINCODE_LOCALITY": "RAJKOT",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363645,
      "TXT_PINCODE_LOCALITY": "PIPLIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363646,
      "TXT_PINCODE_LOCALITY": "KHAREDA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363647,
      "TXT_PINCODE_LOCALITY": "NICHIMANDAL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363650,
      "TXT_PINCODE_LOCALITY": "MORBI S. O.",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 911,
      "City District Name": "MORBI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363651,
      "TXT_PINCODE_LOCALITY": "NEKNAM",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363652,
      "TXT_PINCODE_LOCALITY": "SAVADI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363653,
      "TXT_PINCODE_LOCALITY": "MITANA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363655,
      "TXT_PINCODE_LOCALITY": "AMRAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363660,
      "TXT_PINCODE_LOCALITY": "DAHISARA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363665,
      "TXT_PINCODE_LOCALITY": "SAKATSANALA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363666,
      "TXT_PINCODE_LOCALITY": "KUNTASI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363667,
      "TXT_PINCODE_LOCALITY": "NANI BAZAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363668,
      "TXT_PINCODE_LOCALITY": "VAVANIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 678,
      "City District Name": "KACHHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363669,
      "TXT_PINCODE_LOCALITY": "PANCHASAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363670,
      "TXT_PINCODE_LOCALITY": "MALIYA MIYANA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363680,
      "TXT_PINCODE_LOCALITY": "NAVALAKHI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 651,
      "City District Name": "JAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363685,
      "TXT_PINCODE_LOCALITY": "LAJAI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363687,
      "TXT_PINCODE_LOCALITY": "RANGPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363689,
      "TXT_PINCODE_LOCALITY": "GHUNTU",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363691,
      "TXT_PINCODE_LOCALITY": "GUNDA S",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363693,
      "TXT_PINCODE_LOCALITY": "GUNDA K",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363695,
      "TXT_PINCODE_LOCALITY": "KHANPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363697,
      "TXT_PINCODE_LOCALITY": "MANEKVADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363701,
      "TXT_PINCODE_LOCALITY": "RAJPAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 687,
      "City District Name": "SURENDRANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363703,
      "TXT_PINCODE_LOCALITY": "CHACHAPAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3048,
      "City District Name": "DOHAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363705,
      "TXT_PINCODE_LOCALITY": "BAGTHALA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363707,
      "TXT_PINCODE_LOCALITY": "BARWALA C",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363709,
      "TXT_PINCODE_LOCALITY": "PIPALIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363711,
      "TXT_PINCODE_LOCALITY": "SARVAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363713,
      "TXT_PINCODE_LOCALITY": "MAKANSAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363715,
      "TXT_PINCODE_LOCALITY": "JUNA GHATILA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363717,
      "TXT_PINCODE_LOCALITY": "KHAKHRECHI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363719,
      "TXT_PINCODE_LOCALITY": "SADULKA NAVA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 363721,
      "TXT_PINCODE_LOCALITY": "VEJALPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364001,
      "TXT_PINCODE_LOCALITY": "MAMAKOTHA ROAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364002,
      "TXT_PINCODE_LOCALITY": "BHAVNAGAR TAKHTESWAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364003,
      "TXT_PINCODE_LOCALITY": "BHAVNAGAR PARA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364004,
      "TXT_PINCODE_LOCALITY": "BHAVNAGAR CHITRA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364005,
      "TXT_PINCODE_LOCALITY": "BHAV ANAND NAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364006,
      "TXT_PINCODE_LOCALITY": "BHAV STATION RD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364021,
      "TXT_PINCODE_LOCALITY": "VALUKAD G",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364022,
      "TXT_PINCODE_LOCALITY": "BUDHEL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364050,
      "TXT_PINCODE_LOCALITY": "BHANDARIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364055,
      "TXT_PINCODE_LOCALITY": "NARI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364060,
      "TXT_PINCODE_LOCALITY": "VARTEJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364061,
      "TXT_PINCODE_LOCALITY": "KARDEJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364070,
      "TXT_PINCODE_LOCALITY": "KOLIYAK",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364071,
      "TXT_PINCODE_LOCALITY": "HATHAB",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364081,
      "TXT_PINCODE_LOCALITY": "ALAND SHIP BREAKING YARD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364110,
      "TXT_PINCODE_LOCALITY": "GOGHA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364120,
      "TXT_PINCODE_LOCALITY": "TANSA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364130,
      "TXT_PINCODE_LOCALITY": "DATHA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364131,
      "TXT_PINCODE_LOCALITY": "KALSAR BHAV",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364135,
      "TXT_PINCODE_LOCALITY": "PITHALPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364140,
      "TXT_PINCODE_LOCALITY": "TALAJA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364145,
      "TXT_PINCODE_LOCALITY": "THALIYA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364146,
      "TXT_PINCODE_LOCALITY": "BAGDANA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364150,
      "TXT_PINCODE_LOCALITY": "TRAPAJ",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364151,
      "TXT_PINCODE_LOCALITY": "DIHOR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364210,
      "TXT_PINCODE_LOCALITY": "AMBALA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364215,
      "TXT_PINCODE_LOCALITY": "BHAVNAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364225,
      "TXT_PINCODE_LOCALITY": "BHAVNAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364230,
      "TXT_PINCODE_LOCALITY": "SANOSRA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364231,
      "TXT_PINCODE_LOCALITY": "RANGHOLA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364235,
      "TXT_PINCODE_LOCALITY": "BODA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364240,
      "TXT_PINCODE_LOCALITY": "MADHADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364241,
      "TXT_PINCODE_LOCALITY": "GHANGHLI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364245,
      "TXT_PINCODE_LOCALITY": "BHAVNAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364250,
      "TXT_PINCODE_LOCALITY": "SONGADH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364251,
      "TXT_PINCODE_LOCALITY": "VALUKAD S",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364260,
      "TXT_PINCODE_LOCALITY": "TANA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364261,
      "TXT_PINCODE_LOCALITY": "VARAL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364262,
      "TXT_PINCODE_LOCALITY": "DEVGANA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364265,
      "TXT_PINCODE_LOCALITY": "GHETI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364270,
      "TXT_PINCODE_LOCALITY": "PALITANA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364275,
      "TXT_PINCODE_LOCALITY": "PARVADI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364276,
      "TXT_PINCODE_LOCALITY": "VAVDIMOTI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424440,
      "City District Name": "BANAS KANTHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364280,
      "TXT_PINCODE_LOCALITY": "MOTA KHUNTAVADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364290,
      "TXT_PINCODE_LOCALITY": "MAHUVA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364292,
      "TXT_PINCODE_LOCALITY": "NANA JADRA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364293,
      "TXT_PINCODE_LOCALITY": "TALGAJARDA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364295,
      "TXT_PINCODE_LOCALITY": "BHADROD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364296,
      "TXT_PINCODE_LOCALITY": "GUNDARNA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364297,
      "TXT_PINCODE_LOCALITY": "VAGHNAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364298,
      "TXT_PINCODE_LOCALITY": "KUMBHAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364310,
      "TXT_PINCODE_LOCALITY": "VALLABHIPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364311,
      "TXT_PINCODE_LOCALITY": "BHAVNAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364312,
      "TXT_PINCODE_LOCALITY": "PACHHEGAM",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364313,
      "TXT_PINCODE_LOCALITY": "RATANPUR BHAL",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364315,
      "TXT_PINCODE_LOCALITY": "CHAMARDI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364320,
      "TXT_PINCODE_LOCALITY": "DHOLA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364330,
      "TXT_PINCODE_LOCALITY": "UMRALA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364335,
      "TXT_PINCODE_LOCALITY": "CHOGATH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364365,
      "TXT_PINCODE_LOCALITY": "DOLVAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1742,
      "City District Name": "SURAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364410,
      "TXT_PINCODE_LOCALITY": "CHAMARDI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364421,
      "TXT_PINCODE_LOCALITY": "BABRA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 4312,
      "City District Name": "MAHESANA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364430,
      "TXT_PINCODE_LOCALITY": "LATHI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364431,
      "TXT_PINCODE_LOCALITY": "AMRELI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364435,
      "TXT_PINCODE_LOCALITY": "CHAVAND",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364445,
      "TXT_PINCODE_LOCALITY": "HAMAPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364450,
      "TXT_PINCODE_LOCALITY": "RAJKOT",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364455,
      "TXT_PINCODE_LOCALITY": "AMRELI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364456,
      "TXT_PINCODE_LOCALITY": "VAGHANIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364457,
      "TXT_PINCODE_LOCALITY": "AMRAPUR (D)",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364458,
      "TXT_PINCODE_LOCALITY": "MAVJINJVA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364460,
      "TXT_PINCODE_LOCALITY": "AMRELI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364465,
      "TXT_PINCODE_LOCALITY": "DERDIKUMBHAJI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364470,
      "TXT_PINCODE_LOCALITY": "SULTANPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364480,
      "TXT_PINCODE_LOCALITY": "VADIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 424442,
      "City District Name": "NARMADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364485,
      "TXT_PINCODE_LOCALITY": "AMAR NAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364490,
      "TXT_PINCODE_LOCALITY": "VASAVAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364491,
      "TXT_PINCODE_LOCALITY": "VASAVAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364505,
      "TXT_PINCODE_LOCALITY": "GARIYADHAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364506,
      "TXT_PINCODE_LOCALITY": "VELAVADAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364507,
      "TXT_PINCODE_LOCALITY": "NONGHANVADAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 671,
      "City District Name": "RAJKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364508,
      "TXT_PINCODE_LOCALITY": "MOTA CHARODIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364510,
      "TXT_PINCODE_LOCALITY": "JESAR S.O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364515,
      "TXT_PINCODE_LOCALITY": "SAVAR CITY",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364517,
      "TXT_PINCODE_LOCALITY": "MOTA BHAMODRA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364518,
      "TXT_PINCODE_LOCALITY": "BHAVNAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364519,
      "TXT_PINCODE_LOCALITY": "BHAVNAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364521,
      "TXT_PINCODE_LOCALITY": "BORALA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364522,
      "TXT_PINCODE_LOCALITY": "BADHADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364523,
      "TXT_PINCODE_LOCALITY": "BHAVNAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364524,
      "TXT_PINCODE_LOCALITY": "BHAVNAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364525,
      "TXT_PINCODE_LOCALITY": "VANDA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364526,
      "TXT_PINCODE_LOCALITY": "BHAVNAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364530,
      "TXT_PINCODE_LOCALITY": "VIJPADI S O",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364535,
      "TXT_PINCODE_LOCALITY": "AMRELI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364536,
      "TXT_PINCODE_LOCALITY": "AMRELI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364537,
      "TXT_PINCODE_LOCALITY": "DOHAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3048,
      "City District Name": "DOHAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364545,
      "TXT_PINCODE_LOCALITY": "NAGESHRI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364550,
      "TXT_PINCODE_LOCALITY": "AMRELI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364555,
      "TXT_PINCODE_LOCALITY": "DOHAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3048,
      "City District Name": "DOHAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364561,
      "TXT_PINCODE_LOCALITY": "BHAVNAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364565,
      "TXT_PINCODE_LOCALITY": "KRANKACH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364601,
      "TXT_PINCODE_LOCALITY": "TOWER ROAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364610,
      "TXT_PINCODE_LOCALITY": "BABAPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364615,
      "TXT_PINCODE_LOCALITY": "VANKIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364616,
      "TXT_PINCODE_LOCALITY": "JALIA AMRELI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364620,
      "TXT_PINCODE_LOCALITY": "AMRELI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364622,
      "TXT_PINCODE_LOCALITY": "AMRELI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364632,
      "TXT_PINCODE_LOCALITY": "AMRELI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364633,
      "TXT_PINCODE_LOCALITY": "AMRELI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364635,
      "TXT_PINCODE_LOCALITY": "MOTA SAMADHIALA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364640,
      "TXT_PINCODE_LOCALITY": "STATION ROAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364641,
      "TXT_PINCODE_LOCALITY": "AMRELI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364645,
      "TXT_PINCODE_LOCALITY": "AMRELI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364650,
      "TXT_PINCODE_LOCALITY": "AMRELI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364660,
      "TXT_PINCODE_LOCALITY": "AMRELI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364710,
      "TXT_PINCODE_LOCALITY": "R S",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364712,
      "TXT_PINCODE_LOCALITY": "LATHIDAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364713,
      "TXT_PINCODE_LOCALITY": "BHIMDAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364714,
      "TXT_PINCODE_LOCALITY": "TURKHA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364720,
      "TXT_PINCODE_LOCALITY": "PALIYAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364730,
      "TXT_PINCODE_LOCALITY": "DHASAGAM",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364740,
      "TXT_PINCODE_LOCALITY": "JALALPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364741,
      "TXT_PINCODE_LOCALITY": "BHAVNAGAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364750,
      "TXT_PINCODE_LOCALITY": "GADHDA S N",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364760,
      "TXT_PINCODE_LOCALITY": "NINGALA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 364765,
      "TXT_PINCODE_LOCALITY": "UGAMEDI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 673,
      "City District Name": "BHAVNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 365001,
      "TXT_PINCODE_LOCALITY": "AMRELI HO.",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 365220,
      "TXT_PINCODE_LOCALITY": "AMBARDI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 365265,
      "TXT_PINCODE_LOCALITY": "TURAK CHOWK",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 442660,
      "City District Name": "PATAN-VERAVAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 365410,
      "TXT_PINCODE_LOCALITY": "CHAMARDI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 365421,
      "TXT_PINCODE_LOCALITY": "BABRA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 365430,
      "TXT_PINCODE_LOCALITY": "AKALA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 365435,
      "TXT_PINCODE_LOCALITY": "CHAVAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 365440,
      "TXT_PINCODE_LOCALITY": "BAGASRA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 365444,
      "TXT_PINCODE_LOCALITY": "SAMADHIYALA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 2222,
      "City District Name": "BAGASARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 365450,
      "TXT_PINCODE_LOCALITY": "KUNKAVAV",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 365455,
      "TXT_PINCODE_LOCALITY": "MOTA ANKADIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 365456,
      "TXT_PINCODE_LOCALITY": "PIPALVA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 442299,
      "City District Name": "LILIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 365460,
      "TXT_PINCODE_LOCALITY": "LUNIDHAR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 365480,
      "TXT_PINCODE_LOCALITY": "TORI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 365516,
      "TXT_PINCODE_LOCALITY": "JALIA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 365535,
      "TXT_PINCODE_LOCALITY": "AMBA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 365540,
      "TXT_PINCODE_LOCALITY": "JAFRABAD",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3268,
      "City District Name": "GODHRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 365541,
      "TXT_PINCODE_LOCALITY": "UTCL KOVAYA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 365545,
      "TXT_PINCODE_LOCALITY": "PATI MANSA (NANA)",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3485,
      "City District Name": "JAFRABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 365550,
      "TXT_PINCODE_LOCALITY": "DEDAN",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 365555,
      "TXT_PINCODE_LOCALITY": "P.A.VICTOR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 365560,
      "TXT_PINCODE_LOCALITY": "BHERAI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 365565,
      "TXT_PINCODE_LOCALITY": "NESDI NO -1",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 5286,
      "City District Name": "RAJULA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 365601,
      "TXT_PINCODE_LOCALITY": "AMRELI",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 365610,
      "TXT_PINCODE_LOCALITY": "VADERA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 365616,
      "TXT_PINCODE_LOCALITY": "KAMIGADH",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 365620,
      "TXT_PINCODE_LOCALITY": "MONPUR",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 365630,
      "TXT_PINCODE_LOCALITY": "SARAMBHADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 365632,
      "TXT_PINCODE_LOCALITY": "GOPALGRAM",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1508,
      "City District Name": "DHARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 365635,
      "TXT_PINCODE_LOCALITY": "MOTA SAMDHIALA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 365640,
      "TXT_PINCODE_LOCALITY": "DHARA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 365641,
      "TXT_PINCODE_LOCALITY": "KRANGSA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 1508,
      "City District Name": "DHARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 365645,
      "TXT_PINCODE_LOCALITY": "BHADER",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 365650,
      "TXT_PINCODE_LOCALITY": "KHAMBHA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 365660,
      "TXT_PINCODE_LOCALITY": "JIRA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 827,
      "City District Name": "AMRELI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 365665,
      "TXT_PINCODE_LOCALITY": "VAGHANIYA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 442299,
      "City District Name": "LILIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 365730,
      "TXT_PINCODE_LOCALITY": "BHADA",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 3628,
      "City District Name": "JUNAGADH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 369155,
      "TXT_PINCODE_LOCALITY": "SARIGAM INDL. ESTATE",
      "NUM_STATE_CD": 67,
      "State Name": "GUJARAT",
      "NUM_CITYDISTRICT_CD": 779,
      "City District Name": "BULSAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450001,
      "TXT_PINCODE_LOCALITY": "KHANDWA VIDHYA NAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450002,
      "TXT_PINCODE_LOCALITY": "KHANDWA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1137,
      "City District Name": "KHANDWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450006,
      "TXT_PINCODE_LOCALITY": "KHANDWA RATAGARH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450031,
      "TXT_PINCODE_LOCALITY": "HAMIDPURA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1135,
      "City District Name": "BURHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450033,
      "TXT_PINCODE_LOCALITY": "TITGAON KALAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4806,
      "City District Name": "NEPANAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450051,
      "TXT_PINCODE_LOCALITY": "KHARKALAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450052,
      "TXT_PINCODE_LOCALITY": "BADAGAON (GUGAR)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450053,
      "TXT_PINCODE_LOCALITY": "BAGMAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450054,
      "TXT_PINCODE_LOCALITY": "BHAGAWA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450066,
      "TXT_PINCODE_LOCALITY": "BANJARI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1137,
      "City District Name": "KHANDWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450110,
      "TXT_PINCODE_LOCALITY": "BIR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450111,
      "TXT_PINCODE_LOCALITY": "BORKHEDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450112,
      "TXT_PINCODE_LOCALITY": "MUNDI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450114,
      "TXT_PINCODE_LOCALITY": "PUNASA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450115,
      "TXT_PINCODE_LOCALITY": "DHANGAON",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450116,
      "TXT_PINCODE_LOCALITY": "HARSUD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450117,
      "TXT_PINCODE_LOCALITY": "KHALWA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450118,
      "TXT_PINCODE_LOCALITY": "BALDI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450119,
      "TXT_PINCODE_LOCALITY": "NARMADA NAGAR S.O",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450124,
      "TXT_PINCODE_LOCALITY": "ASHAPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450220,
      "TXT_PINCODE_LOCALITY": "KOHADAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450221,
      "TXT_PINCODE_LOCALITY": "NEPANAGAR L C LINES",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450223,
      "TXT_PINCODE_LOCALITY": "NIMBOLA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450322,
      "TXT_PINCODE_LOCALITY": "RASTIPURA COLONY",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1135,
      "City District Name": "BURHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450327,
      "TXT_PINCODE_LOCALITY": "TUKAITHED",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450331,
      "TXT_PINCODE_LOCALITY": "MAHAJAN PETH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450332,
      "TXT_PINCODE_LOCALITY": "KHAKHAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450333,
      "TXT_PINCODE_LOCALITY": "BAHADURPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450334,
      "TXT_PINCODE_LOCALITY": "LONI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450335,
      "TXT_PINCODE_LOCALITY": "BURHANPUR R S",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450337,
      "TXT_PINCODE_LOCALITY": "RUSTAMPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450338,
      "TXT_PINCODE_LOCALITY": "KUMTHI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450339,
      "TXT_PINCODE_LOCALITY": "BORGAON GUJAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450340,
      "TXT_PINCODE_LOCALITY": "BIRODA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450341,
      "TXT_PINCODE_LOCALITY": "DEDTALAI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450342,
      "TXT_PINCODE_LOCALITY": "DOIPHODIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450343,
      "TXT_PINCODE_LOCALITY": "SIRPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450346,
      "TXT_PINCODE_LOCALITY": "ZAINBAD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450391,
      "TXT_PINCODE_LOCALITY": "PIPALGAON RYT",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1135,
      "City District Name": "BURHANPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450441,
      "TXT_PINCODE_LOCALITY": "ICHHAPUR (KHANDWA)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450445,
      "TXT_PINCODE_LOCALITY": "SHAHPUR (NIMAR)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450448,
      "TXT_PINCODE_LOCALITY": "KHAMNI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450550,
      "TXT_PINCODE_LOCALITY": "BHARUKHEDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450551,
      "TXT_PINCODE_LOCALITY": "NIMARKHEDI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450552,
      "TXT_PINCODE_LOCALITY": "ATTAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450554,
      "TXT_PINCODE_LOCALITY": "MANDHATA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450555,
      "TXT_PINCODE_LOCALITY": "MORTAKKA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450661,
      "TXT_PINCODE_LOCALITY": "PANDHANA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450771,
      "TXT_PINCODE_LOCALITY": "CHHEGAON MAKHAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450772,
      "TXT_PINCODE_LOCALITY": "AHMEDPUR KHEGAON",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450773,
      "TXT_PINCODE_LOCALITY": "BARUD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450881,
      "TXT_PINCODE_LOCALITY": "JASWADI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450991,
      "TXT_PINCODE_LOCALITY": "JAWAR (KHANDWA)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 450992,
      "TXT_PINCODE_LOCALITY": "SIHARA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424460,
      "City District Name": "EAST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451001,
      "TXT_PINCODE_LOCALITY": "KHARGAONE H O",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424462,
      "City District Name": "WEST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451110,
      "TXT_PINCODE_LOCALITY": "DHAKALGAON",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424462,
      "City District Name": "WEST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451111,
      "TXT_PINCODE_LOCALITY": "SANAVAD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424462,
      "City District Name": "WEST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451112,
      "TXT_PINCODE_LOCALITY": "BODUD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424462,
      "City District Name": "WEST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451113,
      "TXT_PINCODE_LOCALITY": "BEDIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424462,
      "City District Name": "WEST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451115,
      "TXT_PINCODE_LOCALITY": "JHANDA CHOWK",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424462,
      "City District Name": "WEST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451117,
      "TXT_PINCODE_LOCALITY": "BALWADA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424462,
      "City District Name": "WEST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451220,
      "TXT_PINCODE_LOCALITY": "KARHI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424462,
      "City District Name": "WEST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451221,
      "TXT_PINCODE_LOCALITY": "MANDLESHWAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424462,
      "City District Name": "WEST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451222,
      "TXT_PINCODE_LOCALITY": "DHARGAON",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424462,
      "City District Name": "WEST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451223,
      "TXT_PINCODE_LOCALITY": "CHOLI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424462,
      "City District Name": "WEST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451224,
      "TXT_PINCODE_LOCALITY": "MEHESHWAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424462,
      "City District Name": "WEST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451225,
      "TXT_PINCODE_LOCALITY": "PIPLIA BUZURG",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1780,
      "City District Name": "BARWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451228,
      "TXT_PINCODE_LOCALITY": "KASRAWOD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424462,
      "City District Name": "WEST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451229,
      "TXT_PINCODE_LOCALITY": "BALSAMUND",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424462,
      "City District Name": "WEST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451239,
      "TXT_PINCODE_LOCALITY": "PATHROT KALAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424462,
      "City District Name": "WEST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451327,
      "TXT_PINCODE_LOCALITY": "BIRUL",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424462,
      "City District Name": "WEST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451330,
      "TXT_PINCODE_LOCALITY": "CHAINPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1780,
      "City District Name": "BARWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451331,
      "TXT_PINCODE_LOCALITY": "BHIKHANGAON",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424462,
      "City District Name": "WEST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451332,
      "TXT_PINCODE_LOCALITY": "HELAPADAWA B.O",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1548,
      "City District Name": "KHARGONE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451335,
      "TXT_PINCODE_LOCALITY": "GOGAWAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424462,
      "City District Name": "WEST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451337,
      "TXT_PINCODE_LOCALITY": "BAMNALA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424462,
      "City District Name": "WEST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451338,
      "TXT_PINCODE_LOCALITY": "MOHAMADPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424462,
      "City District Name": "WEST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451341,
      "TXT_PINCODE_LOCALITY": "NAGJHIRI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424462,
      "City District Name": "WEST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451376,
      "TXT_PINCODE_LOCALITY": "CHAINPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1780,
      "City District Name": "BARWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451381,
      "TXT_PINCODE_LOCALITY": "GORADIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424462,
      "City District Name": "WEST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451411,
      "TXT_PINCODE_LOCALITY": "BISTHAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424462,
      "City District Name": "WEST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451435,
      "TXT_PINCODE_LOCALITY": "PALSUD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424462,
      "City District Name": "WEST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451440,
      "TXT_PINCODE_LOCALITY": "UN (KHARGONE)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424462,
      "City District Name": "WEST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451441,
      "TXT_PINCODE_LOCALITY": "BHAGYAPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424462,
      "City District Name": "WEST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451442,
      "TXT_PINCODE_LOCALITY": "SEGAON",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424462,
      "City District Name": "WEST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451443,
      "TXT_PINCODE_LOCALITY": "KHARGONE",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1548,
      "City District Name": "KHARGONE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451444,
      "TXT_PINCODE_LOCALITY": "BHAGWANPURA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424462,
      "City District Name": "WEST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451447,
      "TXT_PINCODE_LOCALITY": "RAJPUR (BARWANI)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1780,
      "City District Name": "BARWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451448,
      "TXT_PINCODE_LOCALITY": "BARUFATAK",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424462,
      "City District Name": "WEST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451449,
      "TXT_PINCODE_LOCALITY": "JULWANIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1780,
      "City District Name": "BARWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451451,
      "TXT_PINCODE_LOCALITY": "NAGALWADI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1780,
      "City District Name": "BARWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451534,
      "TXT_PINCODE_LOCALITY": "ANJAD *",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1780,
      "City District Name": "BARWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451550,
      "TXT_PINCODE_LOCALITY": "OZHARA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424462,
      "City District Name": "WEST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451551,
      "TXT_PINCODE_LOCALITY": "BARWANI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1780,
      "City District Name": "BARWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451552,
      "TXT_PINCODE_LOCALITY": "SILAWAD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1780,
      "City District Name": "BARWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451555,
      "TXT_PINCODE_LOCALITY": "MANDWADA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1780,
      "City District Name": "BARWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451556,
      "TXT_PINCODE_LOCALITY": "ANJAOI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1780,
      "City District Name": "BARWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451557,
      "TXT_PINCODE_LOCALITY": "TALWADA BUZURG",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1780,
      "City District Name": "BARWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451558,
      "TXT_PINCODE_LOCALITY": "DAWANA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1780,
      "City District Name": "BARWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451559,
      "TXT_PINCODE_LOCALITY": "BALKUAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1780,
      "City District Name": "BARWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451563,
      "TXT_PINCODE_LOCALITY": "THIBGAON",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424462,
      "City District Name": "WEST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451659,
      "TXT_PINCODE_LOCALITY": "NIMRANI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424462,
      "City District Name": "WEST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451660,
      "TXT_PINCODE_LOCALITY": "THIKRI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1780,
      "City District Name": "BARWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451661,
      "TXT_PINCODE_LOCALITY": "PATI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1780,
      "City District Name": "BARWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451663,
      "TXT_PINCODE_LOCALITY": "KHURRAMPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1780,
      "City District Name": "BARWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451664,
      "TXT_PINCODE_LOCALITY": "ZOPALI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1780,
      "City District Name": "BARWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451665,
      "TXT_PINCODE_LOCALITY": "BALWADI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424462,
      "City District Name": "WEST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451666,
      "TXT_PINCODE_LOCALITY": "SENDHWA (KHARGON)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1780,
      "City District Name": "BARWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451667,
      "TXT_PINCODE_LOCALITY": "NIWALI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1780,
      "City District Name": "BARWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451669,
      "TXT_PINCODE_LOCALITY": "CHATLI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1780,
      "City District Name": "BARWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451695,
      "TXT_PINCODE_LOCALITY": "MOYDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1780,
      "City District Name": "BARWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451770,
      "TXT_PINCODE_LOCALITY": "PANSEMAL",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1780,
      "City District Name": "BARWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451877,
      "TXT_PINCODE_LOCALITY": "MORTALAL",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1780,
      "City District Name": "BARWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 451881,
      "TXT_PINCODE_LOCALITY": "KHETIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1780,
      "City District Name": "BARWANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 452001,
      "TXT_PINCODE_LOCALITY": "M G ROAD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 452002,
      "TXT_PINCODE_LOCALITY": "JABALPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 452003,
      "TXT_PINCODE_LOCALITY": "SOUTH TUKOGUNJ",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 452004,
      "TXT_PINCODE_LOCALITY": "INDORE YESHWANT ROAD H O",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 452005,
      "TXT_PINCODE_LOCALITY": "INDORE BIJASEN ROAD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 452006,
      "TXT_PINCODE_LOCALITY": "INDORE BADA SARAFA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 452007,
      "TXT_PINCODE_LOCALITY": "INDORE TOPKHANA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 452008,
      "TXT_PINCODE_LOCALITY": "CHANDRA NAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 452009,
      "TXT_PINCODE_LOCALITY": "INDORE SUDAMANAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 452010,
      "TXT_PINCODE_LOCALITY": "KAILOD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 452011,
      "TXT_PINCODE_LOCALITY": "NANDA NAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 452012,
      "TXT_PINCODE_LOCALITY": "INDORE RAJENDRANAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 452013,
      "TXT_PINCODE_LOCALITY": "INDORE CANTT.",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 452014,
      "TXT_PINCODE_LOCALITY": "KHATIWALA TANK",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 452015,
      "TXT_PINCODE_LOCALITY": "INDUSTRIAL ESTATE S.O",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 452016,
      "TXT_PINCODE_LOCALITY": "INDORE KANADIA ROAD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 452017,
      "TXT_PINCODE_LOCALITY": "INDRAPURI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 452018,
      "TXT_PINCODE_LOCALITY": "INDORE TILLAKNAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 452019,
      "TXT_PINCODE_LOCALITY": "SBI MAIN BRANCH INDORE",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 452020,
      "TXT_PINCODE_LOCALITY": "INDORE KASTURBA NAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 452043,
      "TXT_PINCODE_LOCALITY": "BHANDARI MARG",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 452056,
      "TXT_PINCODE_LOCALITY": "INDORE",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 452444,
      "TXT_PINCODE_LOCALITY": "BETMA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 452771,
      "TXT_PINCODE_LOCALITY": "MANGALIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 453001,
      "TXT_PINCODE_LOCALITY": "BETMA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 453111,
      "TXT_PINCODE_LOCALITY": "HOTOD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 453112,
      "TXT_PINCODE_LOCALITY": "PALAKHEDI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 453115,
      "TXT_PINCODE_LOCALITY": "DEPALPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 453116,
      "TXT_PINCODE_LOCALITY": "BANEDIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 453220,
      "TXT_PINCODE_LOCALITY": "GAUTAMPURA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 453235,
      "TXT_PINCODE_LOCALITY": "FATAHABAD CHANDRAWANTIGAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 453236,
      "TXT_PINCODE_LOCALITY": "POTLOD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5564,
      "City District Name": "SAWER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 453246,
      "TXT_PINCODE_LOCALITY": "AJNOD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 453331,
      "TXT_PINCODE_LOCALITY": "PIGDAMBER",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 453332,
      "TXT_PINCODE_LOCALITY": "SONWAY BHENSOLAY AUDYGIK KSHET",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 453351,
      "TXT_PINCODE_LOCALITY": "SANWER",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 453441,
      "TXT_PINCODE_LOCALITY": "MHOW IS",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 453444,
      "TXT_PINCODE_LOCALITY": "AURANGAPURA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 453446,
      "TXT_PINCODE_LOCALITY": "RASALPURA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 453463,
      "TXT_PINCODE_LOCALITY": "HARSOLA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 453477,
      "TXT_PINCODE_LOCALITY": "TILLORE",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 453488,
      "TXT_PINCODE_LOCALITY": "MHOWGAON",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 453495,
      "TXT_PINCODE_LOCALITY": "DATORDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 453530,
      "TXT_PINCODE_LOCALITY": "KAMPEL",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 453545,
      "TXT_PINCODE_LOCALITY": "HASALPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 453551,
      "TXT_PINCODE_LOCALITY": "SAWER",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 453552,
      "TXT_PINCODE_LOCALITY": "INDORE",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 453555,
      "TXT_PINCODE_LOCALITY": "PADARIYA BAJRANG",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 5564,
      "City District Name": "SAWER",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 453556,
      "TXT_PINCODE_LOCALITY": "IIM CAMPUS RAO S.O",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 453562,
      "TXT_PINCODE_LOCALITY": "SOLSINDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 453576,
      "TXT_PINCODE_LOCALITY": "KHUDEL",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 453643,
      "TXT_PINCODE_LOCALITY": "JAMLY",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 453652,
      "TXT_PINCODE_LOCALITY": "DAKACHA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 453661,
      "TXT_PINCODE_LOCALITY": "MANPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 453771,
      "TXT_PINCODE_LOCALITY": "A.B. ROAD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 453777,
      "TXT_PINCODE_LOCALITY": "BURANA KHEDI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 590,
      "City District Name": "INDORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454001,
      "TXT_PINCODE_LOCALITY": "MORENA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1773,
      "City District Name": "MORENA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454010,
      "TXT_PINCODE_LOCALITY": "MANDU",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 599,
      "City District Name": "DHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454020,
      "TXT_PINCODE_LOCALITY": "DHAL",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 599,
      "City District Name": "DHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454051,
      "TXT_PINCODE_LOCALITY": "NALCHA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 599,
      "City District Name": "DHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454102,
      "TXT_PINCODE_LOCALITY": "SALKANPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 599,
      "City District Name": "DHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454111,
      "TXT_PINCODE_LOCALITY": "SARDARPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 599,
      "City District Name": "DHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454112,
      "TXT_PINCODE_LOCALITY": "DASAI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 599,
      "City District Name": "DHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454116,
      "TXT_PINCODE_LOCALITY": "RAJGARH (DHAR)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 599,
      "City District Name": "DHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454119,
      "TXT_PINCODE_LOCALITY": "BHANGARH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 599,
      "City District Name": "DHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454129,
      "TXT_PINCODE_LOCALITY": "TIRLA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 599,
      "City District Name": "DHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454221,
      "TXT_PINCODE_LOCALITY": "BAGH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 599,
      "City District Name": "DHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454276,
      "TXT_PINCODE_LOCALITY": "TANDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 599,
      "City District Name": "DHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454280,
      "TXT_PINCODE_LOCALITY": "RINGNOD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 599,
      "City District Name": "DHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454331,
      "TXT_PINCODE_LOCALITY": "KUKSHI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 599,
      "City District Name": "DHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454333,
      "TXT_PINCODE_LOCALITY": "SUSARI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 599,
      "City District Name": "DHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454334,
      "TXT_PINCODE_LOCALITY": "PADIYAL",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 599,
      "City District Name": "DHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454335,
      "TXT_PINCODE_LOCALITY": "NISRAPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 599,
      "City District Name": "DHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454341,
      "TXT_PINCODE_LOCALITY": "CHIKHALDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 599,
      "City District Name": "DHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454345,
      "TXT_PINCODE_LOCALITY": "SINGHANA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 599,
      "City District Name": "DHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454350,
      "TXT_PINCODE_LOCALITY": "GANDHWANI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 599,
      "City District Name": "DHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454365,
      "TXT_PINCODE_LOCALITY": "DAHI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 599,
      "City District Name": "DHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454435,
      "TXT_PINCODE_LOCALITY": "GANGALI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4386,
      "City District Name": "MANAWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454441,
      "TXT_PINCODE_LOCALITY": "AMJHERA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 599,
      "City District Name": "DHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454446,
      "TXT_PINCODE_LOCALITY": "MANAWAR (DHAR)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 599,
      "City District Name": "DHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454448,
      "TXT_PINCODE_LOCALITY": "BAKNER",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 599,
      "City District Name": "DHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454449,
      "TXT_PINCODE_LOCALITY": "DHARAMPURI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 599,
      "City District Name": "DHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454456,
      "TXT_PINCODE_LOCALITY": "VASHI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 147,
      "City District Name": "MUMBAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454552,
      "TXT_PINCODE_LOCALITY": "DHAMNOD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 599,
      "City District Name": "DHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454554,
      "TXT_PINCODE_LOCALITY": "SUNDREL (DHAR)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 599,
      "City District Name": "DHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454571,
      "TXT_PINCODE_LOCALITY": "GUJRI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 599,
      "City District Name": "DHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454648,
      "TXT_PINCODE_LOCALITY": "RAJOD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 599,
      "City District Name": "DHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454660,
      "TXT_PINCODE_LOCALITY": "BADNAWAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 599,
      "City District Name": "DHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454665,
      "TXT_PINCODE_LOCALITY": "KANWAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 599,
      "City District Name": "DHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454666,
      "TXT_PINCODE_LOCALITY": "SADALPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 599,
      "City District Name": "DHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454667,
      "TXT_PINCODE_LOCALITY": "KESURI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 599,
      "City District Name": "DHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454668,
      "TXT_PINCODE_LOCALITY": "BAKHATGARH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 599,
      "City District Name": "DHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454669,
      "TXT_PINCODE_LOCALITY": "KOD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 599,
      "City District Name": "DHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454678,
      "TXT_PINCODE_LOCALITY": "MULTHAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 599,
      "City District Name": "DHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454729,
      "TXT_PINCODE_LOCALITY": "DIGTHAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 599,
      "City District Name": "DHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454730,
      "TXT_PINCODE_LOCALITY": "GUNAWAD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 599,
      "City District Name": "DHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454749,
      "TXT_PINCODE_LOCALITY": "BAGDI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 599,
      "City District Name": "DHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454773,
      "TXT_PINCODE_LOCALITY": "DEHRIGHATGA BILLOD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 599,
      "City District Name": "DHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454774,
      "TXT_PINCODE_LOCALITY": "GHATABILLOD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 599,
      "City District Name": "DHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 454775,
      "TXT_PINCODE_LOCALITY": "PITHAMPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 599,
      "City District Name": "DHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455001,
      "TXT_PINCODE_LOCALITY": "DEWAS RADHAGANJ",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455003,
      "TXT_PINCODE_LOCALITY": "DEWAS BANK NOTE",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455048,
      "TXT_PINCODE_LOCALITY": "SHIPRA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455111,
      "TXT_PINCODE_LOCALITY": "DEWAS BALGARH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455112,
      "TXT_PINCODE_LOCALITY": "BAROTHA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455113,
      "TXT_PINCODE_LOCALITY": "MANKUND",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455114,
      "TXT_PINCODE_LOCALITY": "NEORI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455115,
      "TXT_PINCODE_LOCALITY": "BHONRASA NEORI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455116,
      "TXT_PINCODE_LOCALITY": "TONK KHURD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455117,
      "TXT_PINCODE_LOCALITY": "TONK KALAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455118,
      "TXT_PINCODE_LOCALITY": "SONKETCH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455119,
      "TXT_PINCODE_LOCALITY": "GANDHARVPURI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455120,
      "TXT_PINCODE_LOCALITY": "SIYA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455133,
      "TXT_PINCODE_LOCALITY": "SINGAWADA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455178,
      "TXT_PINCODE_LOCALITY": "NAGDA (DEWAS)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455179,
      "TXT_PINCODE_LOCALITY": "DOUBLE CHAWKI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455180,
      "TXT_PINCODE_LOCALITY": "CHAPDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455221,
      "TXT_PINCODE_LOCALITY": "KARNAWAD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455223,
      "TXT_PINCODE_LOCALITY": "HAT PIPALIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455224,
      "TXT_PINCODE_LOCALITY": "BARKHEDA SOMA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455225,
      "TXT_PINCODE_LOCALITY": "ZIKHRAKHEDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455227,
      "TXT_PINCODE_LOCALITY": "BAGLI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455236,
      "TXT_PINCODE_LOCALITY": "BHANDARIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455302,
      "TXT_PINCODE_LOCALITY": "KAMLAPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455305,
      "TXT_PINCODE_LOCALITY": "BIJWAD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455308,
      "TXT_PINCODE_LOCALITY": "PANIGAON",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455311,
      "TXT_PINCODE_LOCALITY": "KALWAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455332,
      "TXT_PINCODE_LOCALITY": "KANNOD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455334,
      "TXT_PINCODE_LOCALITY": "NANASA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455336,
      "TXT_PINCODE_LOCALITY": "KHATEGAON",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455337,
      "TXT_PINCODE_LOCALITY": "SANDALPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455339,
      "TXT_PINCODE_LOCALITY": "NEMAWAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455342,
      "TXT_PINCODE_LOCALITY": "TIWDIYA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455344,
      "TXT_PINCODE_LOCALITY": "KUSMANIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455348,
      "TXT_PINCODE_LOCALITY": "JIYAGAON",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455409,
      "TXT_PINCODE_LOCALITY": "NANDURBAR",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 557,
      "City District Name": "NANDED",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455440,
      "TXT_PINCODE_LOCALITY": "KANTAPHORE",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455445,
      "TXT_PINCODE_LOCALITY": "AJNAS",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455459,
      "TXT_PINCODE_LOCALITY": "SATWAS",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455463,
      "TXT_PINCODE_LOCALITY": "LOHARDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455467,
      "TXT_PINCODE_LOCALITY": "SUNDREL (DEWAS)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455480,
      "TXT_PINCODE_LOCALITY": "BEDGAON",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 455538,
      "TXT_PINCODE_LOCALITY": "AMLA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 598,
      "City District Name": "DEWAS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 456001,
      "TXT_PINCODE_LOCALITY": "UJJAIN H O",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 477,
      "City District Name": "UJJAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 456003,
      "TXT_PINCODE_LOCALITY": "UJJAN BHERONGARH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 477,
      "City District Name": "UJJAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 456006,
      "TXT_PINCODE_LOCALITY": "UJJAIN NAYAPURA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 477,
      "City District Name": "UJJAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 456008,
      "TXT_PINCODE_LOCALITY": "UJJAIN RISHINAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 477,
      "City District Name": "UJJAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 456009,
      "TXT_PINCODE_LOCALITY": "UJJAIN GOVT ENGINEERING C",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 477,
      "City District Name": "UJJAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 456010,
      "TXT_PINCODE_LOCALITY": "UJJAIN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 477,
      "City District Name": "UJJAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 456221,
      "TXT_PINCODE_LOCALITY": "UNHEL",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 477,
      "City District Name": "UJJAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 456222,
      "TXT_PINCODE_LOCALITY": "BALEDI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 477,
      "City District Name": "UJJAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 456224,
      "TXT_PINCODE_LOCALITY": "KHACHROD TOWN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 477,
      "City District Name": "UJJAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 456311,
      "TXT_PINCODE_LOCALITY": "BIRLAGRAM",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4664,
      "City District Name": "NAGDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 456313,
      "TXT_PINCODE_LOCALITY": "BEDAWAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 719,
      "City District Name": "RAJGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 456331,
      "TXT_PINCODE_LOCALITY": "BIRLAGRAM NAGDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 477,
      "City District Name": "UJJAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 456335,
      "TXT_PINCODE_LOCALITY": "NAGDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 477,
      "City District Name": "UJJAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 456337,
      "TXT_PINCODE_LOCALITY": "PIPLODA BAGLA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 477,
      "City District Name": "UJJAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 456440,
      "TXT_PINCODE_LOCALITY": "MEHIDPUR ROAD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 477,
      "City District Name": "UJJAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 456441,
      "TXT_PINCODE_LOCALITY": "JHARDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 477,
      "City District Name": "UJJAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 456443,
      "TXT_PINCODE_LOCALITY": "MAHIDPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 477,
      "City District Name": "UJJAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 456444,
      "TXT_PINCODE_LOCALITY": "NAZARPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 477,
      "City District Name": "UJJAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 456445,
      "TXT_PINCODE_LOCALITY": "JAGOTI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 477,
      "City District Name": "UJJAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 456446,
      "TXT_PINCODE_LOCALITY": "LONARA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 477,
      "City District Name": "UJJAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 456447,
      "TXT_PINCODE_LOCALITY": "SUSNER",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 836,
      "City District Name": "SHAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 456450,
      "TXT_PINCODE_LOCALITY": "DONGARGAON",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 477,
      "City District Name": "UJJAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 456452,
      "TXT_PINCODE_LOCALITY": "DHABLA HARDU",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 477,
      "City District Name": "UJJAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 456457,
      "TXT_PINCODE_LOCALITY": "BHATPACHALANA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 477,
      "City District Name": "UJJAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 456550,
      "TXT_PINCODE_LOCALITY": "GHATTIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 477,
      "City District Name": "UJJAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 456551,
      "TXT_PINCODE_LOCALITY": "GHOSLA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 477,
      "City District Name": "UJJAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 456552,
      "TXT_PINCODE_LOCALITY": "PAT",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 477,
      "City District Name": "UJJAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 456661,
      "TXT_PINCODE_LOCALITY": "TAJPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 477,
      "City District Name": "UJJAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 456663,
      "TXT_PINCODE_LOCALITY": "KAYTHA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 477,
      "City District Name": "UJJAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 456664,
      "TXT_PINCODE_LOCALITY": "NARWAR (UJJAIN)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 477,
      "City District Name": "UJJAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 456665,
      "TXT_PINCODE_LOCALITY": "TARANA TOWN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 477,
      "City District Name": "UJJAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 456666,
      "TXT_PINCODE_LOCALITY": "VIJAIGANJ MANDI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 477,
      "City District Name": "UJJAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 456668,
      "TXT_PINCODE_LOCALITY": "MAKDONE",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 477,
      "City District Name": "UJJAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 456673,
      "TXT_PINCODE_LOCALITY": "SUMERKHEDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 477,
      "City District Name": "UJJAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 456770,
      "TXT_PINCODE_LOCALITY": "BERCHI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 477,
      "City District Name": "UJJAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 456771,
      "TXT_PINCODE_LOCALITY": "BARNAGAR BUNGLOW",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 477,
      "City District Name": "UJJAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 456773,
      "TXT_PINCODE_LOCALITY": "LAKODO",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 477,
      "City District Name": "UJJAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 456776,
      "TXT_PINCODE_LOCALITY": "RUNIJA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 477,
      "City District Name": "UJJAIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457001,
      "TXT_PINCODE_LOCALITY": "RATLAM RAILWAY COLONY",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 470,
      "City District Name": "RATLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457002,
      "TXT_PINCODE_LOCALITY": "JAWAHAR NAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 470,
      "City District Name": "RATLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457111,
      "TXT_PINCODE_LOCALITY": "BANGROD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 470,
      "City District Name": "RATLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457112,
      "TXT_PINCODE_LOCALITY": "SEJAWATA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 470,
      "City District Name": "RATLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457114,
      "TXT_PINCODE_LOCALITY": "ALETE NAYA BAZAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 470,
      "City District Name": "RATLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457115,
      "TXT_PINCODE_LOCALITY": "SHIVPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 470,
      "City District Name": "RATLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457118,
      "TXT_PINCODE_LOCALITY": "TAL",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 470,
      "City District Name": "RATLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457119,
      "TXT_PINCODE_LOCALITY": "KHARWA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 470,
      "City District Name": "RATLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457222,
      "TXT_PINCODE_LOCALITY": "NAMLI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 470,
      "City District Name": "RATLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457223,
      "TXT_PINCODE_LOCALITY": "PANCHED",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 470,
      "City District Name": "RATLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457226,
      "TXT_PINCODE_LOCALITY": "JAORA TOWER",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 470,
      "City District Name": "RATLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457227,
      "TXT_PINCODE_LOCALITY": "BORODA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 470,
      "City District Name": "RATLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457228,
      "TXT_PINCODE_LOCALITY": "MAMTAKHEDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 470,
      "City District Name": "RATLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457330,
      "TXT_PINCODE_LOCALITY": "PANCHEWA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 470,
      "City District Name": "RATLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457331,
      "TXT_PINCODE_LOCALITY": "PIPLODA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 470,
      "City District Name": "RATLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457332,
      "TXT_PINCODE_LOCALITY": "HATHNARA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 470,
      "City District Name": "RATLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457333,
      "TXT_PINCODE_LOCALITY": "SUKHEDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 470,
      "City District Name": "RATLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457334,
      "TXT_PINCODE_LOCALITY": "BADAYALA MALAJI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 470,
      "City District Name": "RATLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457335,
      "TXT_PINCODE_LOCALITY": "SUJAPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 470,
      "City District Name": "RATLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457336,
      "TXT_PINCODE_LOCALITY": "RINGNOD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 470,
      "City District Name": "RATLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457337,
      "TXT_PINCODE_LOCALITY": "KALALIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 470,
      "City District Name": "RATLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457339,
      "TXT_PINCODE_LOCALITY": "DHODHAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 470,
      "City District Name": "RATLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457340,
      "TXT_PINCODE_LOCALITY": "KALUKHEDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 470,
      "City District Name": "RATLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457426,
      "TXT_PINCODE_LOCALITY": "DHARAD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 470,
      "City District Name": "RATLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457440,
      "TXT_PINCODE_LOCALITY": "BIRMAWAL",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 470,
      "City District Name": "RATLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457441,
      "TXT_PINCODE_LOCALITY": "BILPANK",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 470,
      "City District Name": "RATLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457550,
      "TXT_PINCODE_LOCALITY": "SAILANA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 470,
      "City District Name": "RATLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457551,
      "TXT_PINCODE_LOCALITY": "SARWAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 470,
      "City District Name": "RATLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457552,
      "TXT_PINCODE_LOCALITY": "DHAMNOD (RATLAM)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 470,
      "City District Name": "RATLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457553,
      "TXT_PINCODE_LOCALITY": "PALSODA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 470,
      "City District Name": "RATLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457555,
      "TXT_PINCODE_LOCALITY": "BAJANA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 470,
      "City District Name": "RATLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457556,
      "TXT_PINCODE_LOCALITY": "SHIVGARH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 470,
      "City District Name": "RATLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457583,
      "TXT_PINCODE_LOCALITY": "AMKHUT",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1243,
      "City District Name": "JHABUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457661,
      "TXT_PINCODE_LOCALITY": "JHABUA H O",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1243,
      "City District Name": "JHABUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457662,
      "TXT_PINCODE_LOCALITY": "RAMA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1243,
      "City District Name": "JHABUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457664,
      "TXT_PINCODE_LOCALITY": "PITOL",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1243,
      "City District Name": "JHABUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457665,
      "TXT_PINCODE_LOCALITY": "PARA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1243,
      "City District Name": "JHABUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457769,
      "TXT_PINCODE_LOCALITY": "RAOTI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1243,
      "City District Name": "JHABUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457770,
      "TXT_PINCODE_LOCALITY": "BAMNIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1243,
      "City District Name": "JHABUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457771,
      "TXT_PINCODE_LOCALITY": "AMARGARH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1243,
      "City District Name": "JHABUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457772,
      "TXT_PINCODE_LOCALITY": "NARELA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1243,
      "City District Name": "JHABUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457773,
      "TXT_PINCODE_LOCALITY": "PETLAWAD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1243,
      "City District Name": "JHABUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457774,
      "TXT_PINCODE_LOCALITY": "KARDAWAD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1243,
      "City District Name": "JHABUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457775,
      "TXT_PINCODE_LOCALITY": "RAIPUR (RATLAM)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1243,
      "City District Name": "JHABUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457776,
      "TXT_PINCODE_LOCALITY": "SARANGI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1243,
      "City District Name": "JHABUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457777,
      "TXT_PINCODE_LOCALITY": "THANDLA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1243,
      "City District Name": "JHABUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457778,
      "TXT_PINCODE_LOCALITY": "UDAIGARH R S",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1243,
      "City District Name": "JHABUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457779,
      "TXT_PINCODE_LOCALITY": "MEGHNAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1243,
      "City District Name": "JHABUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457780,
      "TXT_PINCODE_LOCALITY": "KALYANPURA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 470,
      "City District Name": "RATLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457880,
      "TXT_PINCODE_LOCALITY": "JHIRAM",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1243,
      "City District Name": "JHABUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457881,
      "TXT_PINCODE_LOCALITY": "RINGOL",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1243,
      "City District Name": "JHABUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457882,
      "TXT_PINCODE_LOCALITY": "BHABRA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1243,
      "City District Name": "JHABUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457883,
      "TXT_PINCODE_LOCALITY": "BARJHAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 424462,
      "City District Name": "WEST NIMAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457884,
      "TXT_PINCODE_LOCALITY": "BORTHAD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1243,
      "City District Name": "JHABUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457885,
      "TXT_PINCODE_LOCALITY": "KHATHIWADA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1243,
      "City District Name": "JHABUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457886,
      "TXT_PINCODE_LOCALITY": "NANPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1243,
      "City District Name": "JHABUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457887,
      "TXT_PINCODE_LOCALITY": "ALIRAJPUR BAZAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1243,
      "City District Name": "JHABUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457888,
      "TXT_PINCODE_LOCALITY": "SONDWA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1243,
      "City District Name": "JHABUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457889,
      "TXT_PINCODE_LOCALITY": "UMRALI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1243,
      "City District Name": "JHABUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457890,
      "TXT_PINCODE_LOCALITY": "BARDALA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1243,
      "City District Name": "JHABUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457891,
      "TXT_PINCODE_LOCALITY": "SORWA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1243,
      "City District Name": "JHABUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457892,
      "TXT_PINCODE_LOCALITY": "CHANDPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1243,
      "City District Name": "JHABUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457893,
      "TXT_PINCODE_LOCALITY": "CHHOTAMAOTA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1243,
      "City District Name": "JHABUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457990,
      "TXT_PINCODE_LOCALITY": "JOBAT S.O",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1988,
      "City District Name": "ALIRAJPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457993,
      "TXT_PINCODE_LOCALITY": "RANAPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1243,
      "City District Name": "JHABUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457994,
      "TXT_PINCODE_LOCALITY": "UDAIGARH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1243,
      "City District Name": "JHABUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457995,
      "TXT_PINCODE_LOCALITY": "BORI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1243,
      "City District Name": "JHABUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 457996,
      "TXT_PINCODE_LOCALITY": "KHATTLI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1243,
      "City District Name": "JHABUA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458001,
      "TXT_PINCODE_LOCALITY": "MANDSAUR H O",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458002,
      "TXT_PINCODE_LOCALITY": "MANDSAUR FORT",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458110,
      "TXT_PINCODE_LOCALITY": "MANASA BAZAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 793,
      "City District Name": "NEEMUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458113,
      "TXT_PINCODE_LOCALITY": "MALGARH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 793,
      "City District Name": "NEEMUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458116,
      "TXT_PINCODE_LOCALITY": "KUKRESHWAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 793,
      "City District Name": "NEEMUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458118,
      "TXT_PINCODE_LOCALITY": "RAMPURE",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 793,
      "City District Name": "NEEMUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458220,
      "TXT_PINCODE_LOCALITY": "SARWANA (MANDSAURI)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 793,
      "City District Name": "NEEMUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458224,
      "TXT_PINCODE_LOCALITY": "DEEKEN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 793,
      "City District Name": "NEEMUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458226,
      "TXT_PINCODE_LOCALITY": "RATANGARH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 793,
      "City District Name": "NEEMUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458228,
      "TXT_PINCODE_LOCALITY": "SINGALI (MANDSAUR)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 793,
      "City District Name": "NEEMUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458232,
      "TXT_PINCODE_LOCALITY": "KANJARDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 793,
      "City District Name": "NEEMUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458237,
      "TXT_PINCODE_LOCALITY": "AFZALPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458254,
      "TXT_PINCODE_LOCALITY": "DHUDHARKA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458265,
      "TXT_PINCODE_LOCALITY": "DHAMNAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458272,
      "TXT_PINCODE_LOCALITY": "MANDASUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458330,
      "TXT_PINCODE_LOCALITY": "JAWAD (MANDSAUR)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 793,
      "City District Name": "NEEMUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458331,
      "TXT_PINCODE_LOCALITY": "BIRLAGRAM",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4664,
      "City District Name": "NAGDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458336,
      "TXT_PINCODE_LOCALITY": "JEERAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 793,
      "City District Name": "NEEMUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458339,
      "TXT_PINCODE_LOCALITY": "MALHARGARH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458348,
      "TXT_PINCODE_LOCALITY": "KUCHROD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458354,
      "TXT_PINCODE_LOCALITY": "KARADIYA MAHARAJ",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 793,
      "City District Name": "NEEMUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458376,
      "TXT_PINCODE_LOCALITY": "MALKHEDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 793,
      "City District Name": "NEEMUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458382,
      "TXT_PINCODE_LOCALITY": "BOLIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458385,
      "TXT_PINCODE_LOCALITY": "BARDI AMRA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458389,
      "TXT_PINCODE_LOCALITY": "LADUNA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458393,
      "TXT_PINCODE_LOCALITY": "PAOTI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458441,
      "TXT_PINCODE_LOCALITY": "NEEMUCH H O",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 793,
      "City District Name": "NEEMUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458442,
      "TXT_PINCODE_LOCALITY": "NEEMUCH MANDI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 793,
      "City District Name": "NEEMUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458445,
      "TXT_PINCODE_LOCALITY": "NEEMUCH VICTORIA BARRACKS",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 793,
      "City District Name": "NEEMUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458468,
      "TXT_PINCODE_LOCALITY": "NAYAGAON CEMENT FACTORY N",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 793,
      "City District Name": "NEEMUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458470,
      "TXT_PINCODE_LOCALITY": "KHOR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 793,
      "City District Name": "NEEMUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458474,
      "TXT_PINCODE_LOCALITY": "ATHANA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 793,
      "City District Name": "NEEMUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458485,
      "TXT_PINCODE_LOCALITY": "MORWAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 793,
      "City District Name": "NEEMUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458491,
      "TXT_PINCODE_LOCALITY": "SAWAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458493,
      "TXT_PINCODE_LOCALITY": "JAVI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 793,
      "City District Name": "NEEMUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458495,
      "TXT_PINCODE_LOCALITY": "PARDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 793,
      "City District Name": "NEEMUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458499,
      "TXT_PINCODE_LOCALITY": "BHATKHEDI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 793,
      "City District Name": "NEEMUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458521,
      "TXT_PINCODE_LOCALITY": "CHITALKHEDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458525,
      "TXT_PINCODE_LOCALITY": "KANGETI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458538,
      "TXT_PINCODE_LOCALITY": "MULTANPURA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458553,
      "TXT_PINCODE_LOCALITY": "NARAYANGARH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458556,
      "TXT_PINCODE_LOCALITY": "BUDHA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458558,
      "TXT_PINCODE_LOCALITY": "NAHARGARH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458576,
      "TXT_PINCODE_LOCALITY": "NAVELI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458581,
      "TXT_PINCODE_LOCALITY": "LOTKHEDI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458588,
      "TXT_PINCODE_LOCALITY": "SANDHARA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458595,
      "TXT_PINCODE_LOCALITY": "BHENSODA TOWN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458660,
      "TXT_PINCODE_LOCALITY": "PANPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1413,
      "City District Name": "SITAMAU",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458664,
      "TXT_PINCODE_LOCALITY": "PIPLIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458667,
      "TXT_PINCODE_LOCALITY": "DALAUDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 793,
      "City District Name": "NEEMUCH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458668,
      "TXT_PINCODE_LOCALITY": "ELCHI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458669,
      "TXT_PINCODE_LOCALITY": "KACHNARA FLAG",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458676,
      "TXT_PINCODE_LOCALITY": "KACHNARA (NAHARGARH)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458688,
      "TXT_PINCODE_LOCALITY": "SUSAWARA MANDI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458771,
      "TXT_PINCODE_LOCALITY": "GANDHI SAGAR COLONY",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458772,
      "TXT_PINCODE_LOCALITY": "SARLA NAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458775,
      "TXT_PINCODE_LOCALITY": "BANPURA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458777,
      "TXT_PINCODE_LOCALITY": "BABULA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458778,
      "TXT_PINCODE_LOCALITY": "BHENSODA MANDI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458880,
      "TXT_PINCODE_LOCALITY": "GAROTH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458883,
      "TXT_PINCODE_LOCALITY": "SHAMGARH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458886,
      "TXT_PINCODE_LOCALITY": "CHANDWASA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458888,
      "TXT_PINCODE_LOCALITY": "SUWASARA MANDI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458895,
      "TXT_PINCODE_LOCALITY": "DEGAON MALI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458976,
      "TXT_PINCODE_LOCALITY": "RUNIJA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458982,
      "TXT_PINCODE_LOCALITY": "GHASOR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458989,
      "TXT_PINCODE_LOCALITY": "BHAGOR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 458990,
      "TXT_PINCODE_LOCALITY": "SITAMAN TOWN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 460001,
      "TXT_PINCODE_LOCALITY": "BETUL H O",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 596,
      "City District Name": "BETUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 460002,
      "TXT_PINCODE_LOCALITY": "BETULGANJ",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 596,
      "City District Name": "BETUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 460004,
      "TXT_PINCODE_LOCALITY": "BETUL BAZAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 596,
      "City District Name": "BETUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 460005,
      "TXT_PINCODE_LOCALITY": "BETULNIMPANI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 596,
      "City District Name": "BETUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 460050,
      "TXT_PINCODE_LOCALITY": "DR BABASAHEB AMBEDKAR ROAD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 596,
      "City District Name": "BETUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 460061,
      "TXT_PINCODE_LOCALITY": "CHAUTHIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 596,
      "City District Name": "BETUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 460110,
      "TXT_PINCODE_LOCALITY": "ATHNAIR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 596,
      "City District Name": "BETUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 460111,
      "TXT_PINCODE_LOCALITY": "SEHRA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 596,
      "City District Name": "BETUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 460220,
      "TXT_PINCODE_LOCALITY": "BHAINDEHI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 596,
      "City District Name": "BETUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 460225,
      "TXT_PINCODE_LOCALITY": "KHEDI SAOLIGARH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 596,
      "City District Name": "BETUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 460226,
      "TXT_PINCODE_LOCALITY": "JHALLAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 596,
      "City District Name": "BETUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 460330,
      "TXT_PINCODE_LOCALITY": "CHICHOLI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 596,
      "City District Name": "BETUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 460440,
      "TXT_PINCODE_LOCALITY": "SHAHPURA (BETUL)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 596,
      "City District Name": "BETUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 460443,
      "TXT_PINCODE_LOCALITY": "GHORADONGRI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 596,
      "City District Name": "BETUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 460444,
      "TXT_PINCODE_LOCALITY": "RANIPUR (BETUL)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 596,
      "City District Name": "BETUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 460447,
      "TXT_PINCODE_LOCALITY": "SARNI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 596,
      "City District Name": "BETUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 460449,
      "TXT_PINCODE_LOCALITY": "PATHARKHEDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 596,
      "City District Name": "BETUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 460450,
      "TXT_PINCODE_LOCALITY": "SHAHPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 596,
      "City District Name": "BETUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 460551,
      "TXT_PINCODE_LOCALITY": "AMLA TOWN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 596,
      "City District Name": "BETUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 460553,
      "TXT_PINCODE_LOCALITY": "AMLA DEPOT",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 596,
      "City District Name": "BETUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 460554,
      "TXT_PINCODE_LOCALITY": "BORDEHI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 596,
      "City District Name": "BETUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 460557,
      "TXT_PINCODE_LOCALITY": "SAIKHEDA (NARSINGHPUR)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 596,
      "City District Name": "BETUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 460660,
      "TXT_PINCODE_LOCALITY": "YENAS",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4601,
      "City District Name": "MULTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 460661,
      "TXT_PINCODE_LOCALITY": "MULTAI BAZAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 596,
      "City District Name": "BETUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 460663,
      "TXT_PINCODE_LOCALITY": "DUNAWA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 596,
      "City District Name": "BETUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 460665,
      "TXT_PINCODE_LOCALITY": "PRABHAT PATTAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 596,
      "City District Name": "BETUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 460666,
      "TXT_PINCODE_LOCALITY": "RAI AMLA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 596,
      "City District Name": "BETUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 460667,
      "TXT_PINCODE_LOCALITY": "BIRUL",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 596,
      "City District Name": "BETUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 460668,
      "TXT_PINCODE_LOCALITY": "MASOD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 596,
      "City District Name": "BETUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 460669,
      "TXT_PINCODE_LOCALITY": "SAWARI (GADRA)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4601,
      "City District Name": "MULTAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461001,
      "TXT_PINCODE_LOCALITY": "HOSHANGABAD CITY",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 602,
      "City District Name": "HOSHANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461005,
      "TXT_PINCODE_LOCALITY": "HOSHANGABAD SECURITY PAPE",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 602,
      "City District Name": "HOSHANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461110,
      "TXT_PINCODE_LOCALITY": "POWARKHEDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 602,
      "City District Name": "HOSHANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461111,
      "TXT_PINCODE_LOCALITY": "ITARSI PURANI BASTI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 602,
      "City District Name": "HOSHANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461112,
      "TXT_PINCODE_LOCALITY": "ITARSI ORDINANCE FACTORY ESTATE",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 602,
      "City District Name": "HOSHANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461114,
      "TXT_PINCODE_LOCALITY": "ITARSI CENTRAL PROJECT",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 602,
      "City District Name": "HOSHANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461115,
      "TXT_PINCODE_LOCALITY": "ITARSI NEW YARD COLONY",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 602,
      "City District Name": "HOSHANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461116,
      "TXT_PINCODE_LOCALITY": "DOLARIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 602,
      "City District Name": "HOSHANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461118,
      "TXT_PINCODE_LOCALITY": "SEMRI KHURD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 602,
      "City District Name": "HOSHANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461122,
      "TXT_PINCODE_LOCALITY": "ITARSI OFF ESTATE",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 602,
      "City District Name": "HOSHANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461166,
      "TXT_PINCODE_LOCALITY": "GUJARWARA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 602,
      "City District Name": "HOSHANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461221,
      "TXT_PINCODE_LOCALITY": "BANAPURA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 602,
      "City District Name": "HOSHANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461222,
      "TXT_PINCODE_LOCALITY": "SONASAWARI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 332,
      "City District Name": "ITARSI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461223,
      "TXT_PINCODE_LOCALITY": "SEONI MALWA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 602,
      "City District Name": "HOSHANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461224,
      "TXT_PINCODE_LOCALITY": "HOSHANGABAD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 602,
      "City District Name": "HOSHANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461225,
      "TXT_PINCODE_LOCALITY": "HOSHANGABAD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 602,
      "City District Name": "HOSHANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461227,
      "TXT_PINCODE_LOCALITY": "CHHIDGAON",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 910,
      "City District Name": "HARDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461228,
      "TXT_PINCODE_LOCALITY": "TIMARNI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 910,
      "City District Name": "HARDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461248,
      "TXT_PINCODE_LOCALITY": "SODALPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 910,
      "City District Name": "HARDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461251,
      "TXT_PINCODE_LOCALITY": "RAHATGAON",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 910,
      "City District Name": "HARDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461281,
      "TXT_PINCODE_LOCALITY": "CHARKHEDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 910,
      "City District Name": "HARDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461331,
      "TXT_PINCODE_LOCALITY": "HARDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 910,
      "City District Name": "HARDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461335,
      "TXT_PINCODE_LOCALITY": "SIVALI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 602,
      "City District Name": "HOSHANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461338,
      "TXT_PINCODE_LOCALITY": "MAKRAI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 910,
      "City District Name": "HARDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461381,
      "TXT_PINCODE_LOCALITY": "HANDIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 910,
      "City District Name": "HARDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461427,
      "TXT_PINCODE_LOCALITY": "HOSHANGABAD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 602,
      "City District Name": "HOSHANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461440,
      "TXT_PINCODE_LOCALITY": "ITARSI ORDINANCE FACTORY",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 602,
      "City District Name": "HOSHANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461441,
      "TXT_PINCODE_LOCALITY": "KHIRKIM R S",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 910,
      "City District Name": "HARDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461444,
      "TXT_PINCODE_LOCALITY": "CHARUWA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 910,
      "City District Name": "HARDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461445,
      "TXT_PINCODE_LOCALITY": "PATHROTA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 602,
      "City District Name": "HOSHANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461446,
      "TXT_PINCODE_LOCALITY": "ITARSI ORDINANCE FACTORY",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 602,
      "City District Name": "HOSHANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461550,
      "TXT_PINCODE_LOCALITY": "KESLA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 602,
      "City District Name": "HOSHANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461551,
      "TXT_PINCODE_LOCALITY": "TAWA NAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 602,
      "City District Name": "HOSHANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461553,
      "TXT_PINCODE_LOCALITY": "SUKHTAWA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 602,
      "City District Name": "HOSHANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461555,
      "TXT_PINCODE_LOCALITY": "RAIPUR (HOSHANGABAD)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 602,
      "City District Name": "HOSHANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461558,
      "TXT_PINCODE_LOCALITY": "NIMSANDIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 602,
      "City District Name": "HOSHANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461561,
      "TXT_PINCODE_LOCALITY": "ANCHALKHEDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 602,
      "City District Name": "HOSHANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461661,
      "TXT_PINCODE_LOCALITY": "BABAI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 602,
      "City District Name": "HOSHANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461668,
      "TXT_PINCODE_LOCALITY": "ITARSI H.O.",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 602,
      "City District Name": "HOSHANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461771,
      "TXT_PINCODE_LOCALITY": "SOHAGPUR R S",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 602,
      "City District Name": "HOSHANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461772,
      "TXT_PINCODE_LOCALITY": "SHOBHAPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 602,
      "City District Name": "HOSHANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461775,
      "TXT_PINCODE_LOCALITY": "PIPARIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 602,
      "City District Name": "HOSHANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461778,
      "TXT_PINCODE_LOCALITY": "MATKULI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 602,
      "City District Name": "HOSHANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461785,
      "TXT_PINCODE_LOCALITY": "KHAPAR KHEDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 602,
      "City District Name": "HOSHANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461881,
      "TXT_PINCODE_LOCALITY": "PACHMARHI BAZAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 602,
      "City District Name": "HOSHANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461951,
      "TXT_PINCODE_LOCALITY": "UMARDHA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 602,
      "City District Name": "HOSHANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 461990,
      "TXT_PINCODE_LOCALITY": "BANKHERI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 602,
      "City District Name": "HOSHANGABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462001,
      "TXT_PINCODE_LOCALITY": "TRILOCHAN NAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462002,
      "TXT_PINCODE_LOCALITY": "BHOPAL VIDYAVIHAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462003,
      "TXT_PINCODE_LOCALITY": "BHOPAL ROSHANPURA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462004,
      "TXT_PINCODE_LOCALITY": "BHOPAL SATPUDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462006,
      "TXT_PINCODE_LOCALITY": "BHOPAL V V GRAH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462007,
      "TXT_PINCODE_LOCALITY": "BHOPAL MACT COLLEGE",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462008,
      "TXT_PINCODE_LOCALITY": "BHOPAL JAHAGIRABAD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462010,
      "TXT_PINCODE_LOCALITY": "BHOPAL SIKANDRISARIAI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462011,
      "TXT_PINCODE_LOCALITY": "ARERA HILLS",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462012,
      "TXT_PINCODE_LOCALITY": "BHOPAL PM G OFFICE",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462013,
      "TXT_PINCODE_LOCALITY": "BHOPAL REGIONAL COLLEGE",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462015,
      "TXT_PINCODE_LOCALITY": "BHOPAL GMT",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462016,
      "TXT_PINCODE_LOCALITY": "KASTURBA NAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462017,
      "TXT_PINCODE_LOCALITY": "CHAR IMLI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 442820,
      "City District Name": "HUZUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462018,
      "TXT_PINCODE_LOCALITY": "BHOPAL GREENPARK CLY",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462020,
      "TXT_PINCODE_LOCALITY": "HABIBGANJ",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 442820,
      "City District Name": "HUZUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462021,
      "TXT_PINCODE_LOCALITY": "BHOPAL SONAGIRI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462022,
      "TXT_PINCODE_LOCALITY": "BHOPAL AOBHEL",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462023,
      "TXT_PINCODE_LOCALITY": "BHOPAL C SECTOR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462024,
      "TXT_PINCODE_LOCALITY": "BHOPAL HE HOSPITAL",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462026,
      "TXT_PINCODE_LOCALITY": "BHOPAL SHASTRI NAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462027,
      "TXT_PINCODE_LOCALITY": "BHOPAL DAK BHAVAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462030,
      "TXT_PINCODE_LOCALITY": "BHOPAL BHEL S B",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462031,
      "TXT_PINCODE_LOCALITY": "BHOPAL EME CENTRE",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462032,
      "TXT_PINCODE_LOCALITY": "BHOPAL NAYAPURA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462033,
      "TXT_PINCODE_LOCALITY": "RGPV",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462036,
      "TXT_PINCODE_LOCALITY": "BHOPAL GANDHI NAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462037,
      "TXT_PINCODE_LOCALITY": "PEOPLES CAMPUS S.O",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462038,
      "TXT_PINCODE_LOCALITY": "KAROND SQUARE",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462039,
      "TXT_PINCODE_LOCALITY": "TRILANGA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462040,
      "TXT_PINCODE_LOCALITY": "MANDIDEEP",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 871,
      "City District Name": "RAISEN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462041,
      "TXT_PINCODE_LOCALITY": "AYODHAYA NAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462042,
      "TXT_PINCODE_LOCALITY": "KOLAR ROAD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462043,
      "TXT_PINCODE_LOCALITY": "BAG MUNGALIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462044,
      "TXT_PINCODE_LOCALITY": "RATIBAD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462045,
      "TXT_PINCODE_LOCALITY": "BANGRASIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462046,
      "TXT_PINCODE_LOCALITY": "MANDIDEEP",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 871,
      "City District Name": "RAISEN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462047,
      "TXT_PINCODE_LOCALITY": "BABADIA KALAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462049,
      "TXT_PINCODE_LOCALITY": "BHOPAL",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462051,
      "TXT_PINCODE_LOCALITY": "VAISHALI NAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 442820,
      "City District Name": "HUZUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462066,
      "TXT_PINCODE_LOCALITY": "BHOPAL S. O",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462100,
      "TXT_PINCODE_LOCALITY": "BHOPAL",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462101,
      "TXT_PINCODE_LOCALITY": "GUNGA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462120,
      "TXT_PINCODE_LOCALITY": "JAJANKHEDI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 2435,
      "City District Name": "BERASIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 462420,
      "TXT_PINCODE_LOCALITY": "BILAKHO",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 463101,
      "TXT_PINCODE_LOCALITY": "DILLOD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 463106,
      "TXT_PINCODE_LOCALITY": "BERASIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 463111,
      "TXT_PINCODE_LOCALITY": "LALARIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 463116,
      "TXT_PINCODE_LOCALITY": "LALARIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 463120,
      "TXT_PINCODE_LOCALITY": "NAZIRABAD (BHOPAL)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 463128,
      "TXT_PINCODE_LOCALITY": "GUNGA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 589,
      "City District Name": "BHOPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 463321,
      "TXT_PINCODE_LOCALITY": "TALWAI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 3682,
      "City District Name": "KALANAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464001,
      "TXT_PINCODE_LOCALITY": "VIDISHA H O",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 478,
      "City District Name": "VIDISHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464002,
      "TXT_PINCODE_LOCALITY": "VIDISHA KRISHI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 478,
      "City District Name": "VIDISHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464111,
      "TXT_PINCODE_LOCALITY": "SHAMSHABAD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 478,
      "City District Name": "VIDISHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464112,
      "TXT_PINCODE_LOCALITY": "ANANDPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 478,
      "City District Name": "VIDISHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464113,
      "TXT_PINCODE_LOCALITY": "PIPAL KHEDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 478,
      "City District Name": "VIDISHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464114,
      "TXT_PINCODE_LOCALITY": "LATERI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 478,
      "City District Name": "VIDISHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464128,
      "TXT_PINCODE_LOCALITY": "MURWAS",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 478,
      "City District Name": "VIDISHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464132,
      "TXT_PINCODE_LOCALITY": "MURARIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 478,
      "City District Name": "VIDISHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464213,
      "TXT_PINCODE_LOCALITY": "BARETH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 478,
      "City District Name": "VIDISHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464220,
      "TXT_PINCODE_LOCALITY": "GULABGANJ",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 478,
      "City District Name": "VIDISHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464221,
      "TXT_PINCODE_LOCALITY": "GANJ BASODA STATION",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 478,
      "City District Name": "VIDISHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464224,
      "TXT_PINCODE_LOCALITY": "KURWAI STATE",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 478,
      "City District Name": "VIDISHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464225,
      "TXT_PINCODE_LOCALITY": "PABAI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 478,
      "City District Name": "VIDISHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464226,
      "TXT_PINCODE_LOCALITY": "KHAMKHEDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 478,
      "City District Name": "VIDISHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464228,
      "TXT_PINCODE_LOCALITY": "SIRONJ",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 478,
      "City District Name": "VIDISHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464230,
      "TXT_PINCODE_LOCALITY": "UNARSI KHURD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 478,
      "City District Name": "VIDISHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464232,
      "TXT_PINCODE_LOCALITY": "SUMER R S",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 478,
      "City District Name": "VIDISHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464240,
      "TXT_PINCODE_LOCALITY": "SANAI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 478,
      "City District Name": "VIDISHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464258,
      "TXT_PINCODE_LOCALITY": "NATARAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 478,
      "City District Name": "VIDISHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464331,
      "TXT_PINCODE_LOCALITY": "GYARASPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 478,
      "City District Name": "VIDISHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464334,
      "TXT_PINCODE_LOCALITY": "RAISEN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 871,
      "City District Name": "RAISEN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464335,
      "TXT_PINCODE_LOCALITY": "NISARPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 871,
      "City District Name": "RAISEN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464337,
      "TXT_PINCODE_LOCALITY": "PATHARI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 478,
      "City District Name": "VIDISHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464339,
      "TXT_PINCODE_LOCALITY": "ANKALPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 871,
      "City District Name": "RAISEN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464340,
      "TXT_PINCODE_LOCALITY": "UDAIPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 478,
      "City District Name": "VIDISHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464490,
      "TXT_PINCODE_LOCALITY": "BHARKATCH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 478,
      "City District Name": "VIDISHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464551,
      "TXT_PINCODE_LOCALITY": "RAISEN H O",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 871,
      "City District Name": "RAISEN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464552,
      "TXT_PINCODE_LOCALITY": "UMRAOGANJ",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 871,
      "City District Name": "RAISEN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464553,
      "TXT_PINCODE_LOCALITY": "GUDAWAL",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 871,
      "City District Name": "RAISEN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464556,
      "TXT_PINCODE_LOCALITY": "RAISEN ARJUN NAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 871,
      "City District Name": "RAISEN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464570,
      "TXT_PINCODE_LOCALITY": "RAISEN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 871,
      "City District Name": "RAISEN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464573,
      "TXT_PINCODE_LOCALITY": "DEHGAON",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 871,
      "City District Name": "RAISEN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464651,
      "TXT_PINCODE_LOCALITY": "SALAMATPUR MANDI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 871,
      "City District Name": "RAISEN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464661,
      "TXT_PINCODE_LOCALITY": "SANCHI - 4213",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 871,
      "City District Name": "RAISEN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464665,
      "TXT_PINCODE_LOCALITY": "BARI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 871,
      "City District Name": "RAISEN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464667,
      "TXT_PINCODE_LOCALITY": "BARI KALAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 871,
      "City District Name": "RAISEN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464668,
      "TXT_PINCODE_LOCALITY": "BARELI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 871,
      "City District Name": "RAISEN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464669,
      "TXT_PINCODE_LOCALITY": "UNTIA KALAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 871,
      "City District Name": "RAISEN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464670,
      "TXT_PINCODE_LOCALITY": "MAGARDHA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 871,
      "City District Name": "RAISEN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464671,
      "TXT_PINCODE_LOCALITY": "KHARGAON",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 871,
      "City District Name": "RAISEN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464672,
      "TXT_PINCODE_LOCALITY": "BAMORI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 478,
      "City District Name": "VIDISHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464676,
      "TXT_PINCODE_LOCALITY": "VIDISHA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 478,
      "City District Name": "VIDISHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464770,
      "TXT_PINCODE_LOCALITY": "UDAIPURA (RAISEN)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 871,
      "City District Name": "RAISEN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464774,
      "TXT_PINCODE_LOCALITY": "DEORI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 871,
      "City District Name": "RAISEN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464776,
      "TXT_PINCODE_LOCALITY": "PANJRA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 871,
      "City District Name": "RAISEN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464778,
      "TXT_PINCODE_LOCALITY": "THALA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 871,
      "City District Name": "RAISEN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464881,
      "TXT_PINCODE_LOCALITY": "BEGAM GANJ",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 871,
      "City District Name": "RAISEN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464884,
      "TXT_PINCODE_LOCALITY": "GAIRATGANJ",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 871,
      "City District Name": "RAISEN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464886,
      "TXT_PINCODE_LOCALITY": "SILWANI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 871,
      "City District Name": "RAISEN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464986,
      "TXT_PINCODE_LOCALITY": "SULTANPUR (RAISEN)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 871,
      "City District Name": "RAISEN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464990,
      "TXT_PINCODE_LOCALITY": "GOHARGANJ",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 871,
      "City District Name": "RAISEN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464991,
      "TXT_PINCODE_LOCALITY": "TAMOT",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 871,
      "City District Name": "RAISEN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464993,
      "TXT_PINCODE_LOCALITY": "OBAIDULLAGANJ",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 871,
      "City District Name": "RAISEN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464995,
      "TXT_PINCODE_LOCALITY": "BARKHERA R S",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 871,
      "City District Name": "RAISEN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464996,
      "TXT_PINCODE_LOCALITY": "DAHOD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 871,
      "City District Name": "RAISEN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 464997,
      "TXT_PINCODE_LOCALITY": "NOORGANJ",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 871,
      "City District Name": "RAISEN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465001,
      "TXT_PINCODE_LOCALITY": "SHAJAPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 836,
      "City District Name": "SHAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465106,
      "TXT_PINCODE_LOCALITY": "MAKSI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 836,
      "City District Name": "SHAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465110,
      "TXT_PINCODE_LOCALITY": "BERCCHA TOWN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 836,
      "City District Name": "SHAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465111,
      "TXT_PINCODE_LOCALITY": "TILAWAD GOVIND",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 836,
      "City District Name": "SHAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465112,
      "TXT_PINCODE_LOCALITY": "RANTH BHANWAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 836,
      "City District Name": "SHAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465113,
      "TXT_PINCODE_LOCALITY": "SUNDERSI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 836,
      "City District Name": "SHAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465116,
      "TXT_PINCODE_LOCALITY": "POLAI KALAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 836,
      "City District Name": "SHAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465118,
      "TXT_PINCODE_LOCALITY": "AWANTIPUR BARDIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 836,
      "City District Name": "SHAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465125,
      "TXT_PINCODE_LOCALITY": "RANAYAL",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 836,
      "City District Name": "SHAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465220,
      "TXT_PINCODE_LOCALITY": "KALISINGH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 836,
      "City District Name": "SHAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465221,
      "TXT_PINCODE_LOCALITY": "SALSLAI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 836,
      "City District Name": "SHAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465222,
      "TXT_PINCODE_LOCALITY": "GULANA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 836,
      "City District Name": "SHAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465223,
      "TXT_PINCODE_LOCALITY": "AKODIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 836,
      "City District Name": "SHAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465224,
      "TXT_PINCODE_LOCALITY": "BOLAI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 836,
      "City District Name": "SHAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465226,
      "TXT_PINCODE_LOCALITY": "BARODIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 836,
      "City District Name": "SHAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465227,
      "TXT_PINCODE_LOCALITY": "MADANA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 836,
      "City District Name": "SHAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465228,
      "TXT_PINCODE_LOCALITY": "DUPADA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 836,
      "City District Name": "SHAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465229,
      "TXT_PINCODE_LOCALITY": "SUNERA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 836,
      "City District Name": "SHAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465230,
      "TXT_PINCODE_LOCALITY": "KANAD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 836,
      "City District Name": "SHAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465231,
      "TXT_PINCODE_LOCALITY": "MOHANA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 836,
      "City District Name": "SHAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465331,
      "TXT_PINCODE_LOCALITY": "SHAJAPUR CITY",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 836,
      "City District Name": "SHAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465333,
      "TXT_PINCODE_LOCALITY": "SHUJALPUR STATION RD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 836,
      "City District Name": "SHAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465335,
      "TXT_PINCODE_LOCALITY": "JAMNER",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 836,
      "City District Name": "SHAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465336,
      "TXT_PINCODE_LOCALITY": "ARNIA KALAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 836,
      "City District Name": "SHAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465337,
      "TXT_PINCODE_LOCALITY": "KALA PIPAL",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 836,
      "City District Name": "SHAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465338,
      "TXT_PINCODE_LOCALITY": "TILAWAD MEENA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 836,
      "City District Name": "SHAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465339,
      "TXT_PINCODE_LOCALITY": "KHOKRA KALAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 836,
      "City District Name": "SHAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465382,
      "TXT_PINCODE_LOCALITY": "CHAKROD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 836,
      "City District Name": "SHAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465418,
      "TXT_PINCODE_LOCALITY": "SHAJAPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 836,
      "City District Name": "SHAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465440,
      "TXT_PINCODE_LOCALITY": "SOYAT KALAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 836,
      "City District Name": "SHAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465441,
      "TXT_PINCODE_LOCALITY": "BARODH MALWA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 1263,
      "City District Name": "AGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465442,
      "TXT_PINCODE_LOCALITY": "BHENSOLA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 821,
      "City District Name": "MANDSAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465443,
      "TXT_PINCODE_LOCALITY": "TANODIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 836,
      "City District Name": "SHAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465444,
      "TXT_PINCODE_LOCALITY": "PIPLONAKALAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 836,
      "City District Name": "SHAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465445,
      "TXT_PINCODE_LOCALITY": "NALKHEDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 836,
      "City District Name": "SHAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465447,
      "TXT_PINCODE_LOCALITY": "SUANER",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 836,
      "City District Name": "SHAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465448,
      "TXT_PINCODE_LOCALITY": "DHAROLA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 836,
      "City District Name": "SHAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465449,
      "TXT_PINCODE_LOCALITY": "SOYAT KALAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 836,
      "City District Name": "SHAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465516,
      "TXT_PINCODE_LOCALITY": "MURAWAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 3564,
      "City District Name": "JAWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465550,
      "TXT_PINCODE_LOCALITY": "BARODE",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 719,
      "City District Name": "RAJGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465585,
      "TXT_PINCODE_LOCALITY": "BHAISANA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 719,
      "City District Name": "RAJGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465593,
      "TXT_PINCODE_LOCALITY": "BHANPURA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 719,
      "City District Name": "RAJGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465597,
      "TXT_PINCODE_LOCALITY": "ASARETA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 719,
      "City District Name": "RAJGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465619,
      "TXT_PINCODE_LOCALITY": "KHILCHIPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 719,
      "City District Name": "RAJGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465661,
      "TXT_PINCODE_LOCALITY": "RAJGARH (BIORA) H O",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 719,
      "City District Name": "RAJGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465662,
      "TXT_PINCODE_LOCALITY": "KALIPETH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 719,
      "City District Name": "RAJGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465665,
      "TXT_PINCODE_LOCALITY": "RAJGARH TOWN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 719,
      "City District Name": "RAJGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465667,
      "TXT_PINCODE_LOCALITY": "MOYALIKHURD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 4750,
      "City District Name": "NARSINGHGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465669,
      "TXT_PINCODE_LOCALITY": "NARSINGH GARH",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 719,
      "City District Name": "RAJGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465670,
      "TXT_PINCODE_LOCALITY": "KOTRA (RAIGARH)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 719,
      "City District Name": "RAJGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465672,
      "TXT_PINCODE_LOCALITY": "MAU (RAJGARH)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 719,
      "City District Name": "RAJGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465674,
      "TXT_PINCODE_LOCALITY": "BIAORA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 719,
      "City District Name": "RAJGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465677,
      "TXT_PINCODE_LOCALITY": "SUTHALIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 719,
      "City District Name": "RAJGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465679,
      "TXT_PINCODE_LOCALITY": "KHILCHIPUR S.O",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 719,
      "City District Name": "RAJGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465680,
      "TXT_PINCODE_LOCALITY": "TALEN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 719,
      "City District Name": "RAJGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465682,
      "TXT_PINCODE_LOCALITY": "BHOJPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 719,
      "City District Name": "RAJGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465683,
      "TXT_PINCODE_LOCALITY": "PACHORE",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 719,
      "City District Name": "RAJGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465684,
      "TXT_PINCODE_LOCALITY": "PADANA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 836,
      "City District Name": "SHAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465685,
      "TXT_PINCODE_LOCALITY": "BODA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 719,
      "City District Name": "RAJGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465686,
      "TXT_PINCODE_LOCALITY": "BARODIA KUMARIA",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 6350,
      "City District Name": "RAIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465687,
      "TXT_PINCODE_LOCALITY": "KHUJNER",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 719,
      "City District Name": "RAJGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465689,
      "TXT_PINCODE_LOCALITY": "CHHAPINODA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 719,
      "City District Name": "RAJGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465691,
      "TXT_PINCODE_LOCALITY": "ZIRAPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 719,
      "City District Name": "RAJGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465693,
      "TXT_PINCODE_LOCALITY": "MACHALPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 719,
      "City District Name": "RAJGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465695,
      "TXT_PINCODE_LOCALITY": "NARAYANPURA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 3603,
      "City District Name": "JIRAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465697,
      "TXT_PINCODE_LOCALITY": "SARANGPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 719,
      "City District Name": "RAJGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465698,
      "TXT_PINCODE_LOCALITY": "ABHAYPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 719,
      "City District Name": "RAJGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 465889,
      "TXT_PINCODE_LOCALITY": "KHOKRA KALAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 836,
      "City District Name": "SHAJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 466001,
      "TXT_PINCODE_LOCALITY": "SEHORE",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 474,
      "City District Name": "SEHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 466002,
      "TXT_PINCODE_LOCALITY": "SEHORE",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 474,
      "City District Name": "SEHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 466003,
      "TXT_PINCODE_LOCALITY": "SEHORE SUGAR FACTORY",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 474,
      "City District Name": "SEHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 466107,
      "TXT_PINCODE_LOCALITY": "DHAMANDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 474,
      "City District Name": "SEHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 466108,
      "TXT_PINCODE_LOCALITY": "MOGARAM",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 474,
      "City District Name": "SEHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 466109,
      "TXT_PINCODE_LOCALITY": "BHAUKHEDI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 474,
      "City District Name": "SEHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 466110,
      "TXT_PINCODE_LOCALITY": "DIWADIA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 474,
      "City District Name": "SEHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 466111,
      "TXT_PINCODE_LOCALITY": "BIKISGANJ",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 474,
      "City District Name": "SEHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 466112,
      "TXT_PINCODE_LOCALITY": "BORKHEDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 474,
      "City District Name": "SEHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 466113,
      "TXT_PINCODE_LOCALITY": "AMLAHA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 474,
      "City District Name": "SEHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 466114,
      "TXT_PINCODE_LOCALITY": "KOTRI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 474,
      "City District Name": "SEHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 466115,
      "TXT_PINCODE_LOCALITY": "ICHAWAR (SEHORE)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 474,
      "City District Name": "SEHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 466116,
      "TXT_PINCODE_LOCALITY": "ASHTA BUDHWAS",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 474,
      "City District Name": "SEHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 466117,
      "TXT_PINCODE_LOCALITY": "KACHRAUD",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 474,
      "City District Name": "SEHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 466118,
      "TXT_PINCODE_LOCALITY": "METWARA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 474,
      "City District Name": "SEHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 466119,
      "TXT_PINCODE_LOCALITY": "KHADI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 474,
      "City District Name": "SEHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 466120,
      "TXT_PINCODE_LOCALITY": "SIDDIGGANJ",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 474,
      "City District Name": "SEHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 466121,
      "TXT_PINCODE_LOCALITY": "BRIJSNAGA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 474,
      "City District Name": "SEHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 466122,
      "TXT_PINCODE_LOCALITY": "MOLUKHEDI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 474,
      "City District Name": "SEHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 466123,
      "TXT_PINCODE_LOCALITY": "BAMANIA BHATI",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 474,
      "City District Name": "SEHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 466124,
      "TXT_PINCODE_LOCALITY": "SEVDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 474,
      "City District Name": "SEHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 466125,
      "TXT_PINCODE_LOCALITY": "MAINA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 474,
      "City District Name": "SEHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 466126,
      "TXT_PINCODE_LOCALITY": "KURAWA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 474,
      "City District Name": "SEHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 466221,
      "TXT_PINCODE_LOCALITY": "JAVER (SEHORE)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 474,
      "City District Name": "SEHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 466222,
      "TXT_PINCODE_LOCALITY": "KAJLAS",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 474,
      "City District Name": "SEHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 466232,
      "TXT_PINCODE_LOCALITY": "BORKEDA KALAN",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 474,
      "City District Name": "SEHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 466331,
      "TXT_PINCODE_LOCALITY": "NASURLAGANJ",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 474,
      "City District Name": "SEHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 466441,
      "TXT_PINCODE_LOCALITY": "BUDNI R S",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 474,
      "City District Name": "SEHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 466445,
      "TXT_PINCODE_LOCALITY": "BUDNI TRACTA NAGAR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 474,
      "City District Name": "SEHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 466446,
      "TXT_PINCODE_LOCALITY": "REHTI (SEHORE)",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 474,
      "City District Name": "SEHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 466448,
      "TXT_PINCODE_LOCALITY": "BAKTARA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 474,
      "City District Name": "SEHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 466554,
      "TXT_PINCODE_LOCALITY": "SHAHGANJ",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 474,
      "City District Name": "SEHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 466651,
      "TXT_PINCODE_LOCALITY": "SHYAMPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 474,
      "City District Name": "SEHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 466661,
      "TXT_PINCODE_LOCALITY": "DORAHA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 474,
      "City District Name": "SEHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 466665,
      "TXT_PINCODE_LOCALITY": "AHMEDPUR",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 474,
      "City District Name": "SEHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 467333,
      "TXT_PINCODE_LOCALITY": "SUNKHEDA",
      "NUM_STATE_CD": 64,
      "State Name": "MADHYA PRADESH",
      "NUM_CITYDISTRICT_CD": 474,
      "City District Name": "SEHORE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 469522,
      "TXT_PINCODE_LOCALITY": "DHAMTARI",
      "NUM_STATE_CD": 144,
      "State Name": "CHHATTISGARH",
      "NUM_CITYDISTRICT_CD": 2962,
      "City District Name": "DHAMTARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851101,
      "TXT_PINCODE_LOCALITY": "BEGUSARAI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2411,
      "City District Name": "BEGUSARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851102,
      "TXT_PINCODE_LOCALITY": "GOGRI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3903,
      "City District Name": "KHAGARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851103,
      "TXT_PINCODE_LOCALITY": "TULSI CHAK",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442464,
      "City District Name": "BAKHRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851111,
      "TXT_PINCODE_LOCALITY": "BACHWARA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2411,
      "City District Name": "BEGUSARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851112,
      "TXT_PINCODE_LOCALITY": "BARAUNI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2411,
      "City District Name": "BEGUSARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851113,
      "TXT_PINCODE_LOCALITY": "BARAUNI DEORHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2411,
      "City District Name": "BEGUSARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851114,
      "TXT_PINCODE_LOCALITY": "BARAUNI OIL REFINERY",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2411,
      "City District Name": "BEGUSARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851115,
      "TXT_PINCODE_LOCALITY": "BARAUNI URVARAKNAGAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2411,
      "City District Name": "BEGUSARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851116,
      "TXT_PINCODE_LOCALITY": "BARAUNI THERMAL POWER PROJECT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2411,
      "City District Name": "BEGUSARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851117,
      "TXT_PINCODE_LOCALITY": "REFINARY TOWNSHIP",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2411,
      "City District Name": "BEGUSARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851118,
      "TXT_PINCODE_LOCALITY": "BARO",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2411,
      "City District Name": "BEGUSARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851119,
      "TXT_PINCODE_LOCALITY": "MEHDAULI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2411,
      "City District Name": "BEGUSARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851120,
      "TXT_PINCODE_LOCALITY": "NEOLA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2411,
      "City District Name": "BEGUSARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851121,
      "TXT_PINCODE_LOCALITY": "RARHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2411,
      "City District Name": "BEGUSARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851122,
      "TXT_PINCODE_LOCALITY": "TILRATH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2411,
      "City District Name": "BEGUSARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851124,
      "TXT_PINCODE_LOCALITY": "TEKANPURA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442588,
      "City District Name": "NAOKOTHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851126,
      "TXT_PINCODE_LOCALITY": "GARHARA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2411,
      "City District Name": "BEGUSARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851127,
      "TXT_PINCODE_LOCALITY": "MANJHOR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 6307,
      "City District Name": "CHAMPARAN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851128,
      "TXT_PINCODE_LOCALITY": "MANSOORCHAK",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2411,
      "City District Name": "BEGUSARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851129,
      "TXT_PINCODE_LOCALITY": "MIRZAPUR BANDUAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2411,
      "City District Name": "BEGUSARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851130,
      "TXT_PINCODE_LOCALITY": "NAOKOTHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2411,
      "City District Name": "BEGUSARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851131,
      "TXT_PINCODE_LOCALITY": "RAJAURA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2411,
      "City District Name": "BEGUSARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851132,
      "TXT_PINCODE_LOCALITY": "SIRIPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2411,
      "City District Name": "BEGUSARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851133,
      "TXT_PINCODE_LOCALITY": "TEGHRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2411,
      "City District Name": "BEGUSARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851134,
      "TXT_PINCODE_LOCALITY": "ULAO",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2411,
      "City District Name": "BEGUSARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851135,
      "TXT_PINCODE_LOCALITY": "BIHAT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2411,
      "City District Name": "BEGUSARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851136,
      "TXT_PINCODE_LOCALITY": "CHANDPURA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2411,
      "City District Name": "BEGUSARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851137,
      "TXT_PINCODE_LOCALITY": "KATARMALA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2411,
      "City District Name": "BEGUSARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851138,
      "TXT_PINCODE_LOCALITY": "RAMDIRI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2411,
      "City District Name": "BEGUSARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851139,
      "TXT_PINCODE_LOCALITY": "MATIHANI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2411,
      "City District Name": "BEGUSARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851201,
      "TXT_PINCODE_LOCALITY": "CHAUTHAM",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3903,
      "City District Name": "KHAGARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851202,
      "TXT_PINCODE_LOCALITY": "GOGRI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3903,
      "City District Name": "KHAGARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851203,
      "TXT_PINCODE_LOCALITY": "JAMALPUR GOGRI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3903,
      "City District Name": "KHAGARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851204,
      "TXT_PINCODE_LOCALITY": "KHAGARIA BAZAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3903,
      "City District Name": "KHAGARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851205,
      "TXT_PINCODE_LOCALITY": "KHAGARIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3903,
      "City District Name": "KHAGARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851206,
      "TXT_PINCODE_LOCALITY": "RAJAURA ARAZI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442630,
      "City District Name": "SAHEBPUR KAMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851207,
      "TXT_PINCODE_LOCALITY": "PARMANPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3903,
      "City District Name": "KHAGARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851210,
      "TXT_PINCODE_LOCALITY": "MAHNA S.O",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2411,
      "City District Name": "BEGUSARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851211,
      "TXT_PINCODE_LOCALITY": "LAKHIMNIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2411,
      "City District Name": "BEGUSARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851212,
      "TXT_PINCODE_LOCALITY": "MOHADDIPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3903,
      "City District Name": "KHAGARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851213,
      "TXT_PINCODE_LOCALITY": "MAHESHKHUNT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3903,
      "City District Name": "KHAGARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851214,
      "TXT_PINCODE_LOCALITY": "MANSI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3903,
      "City District Name": "KHAGARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851215,
      "TXT_PINCODE_LOCALITY": "NAYAGAON",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851216,
      "TXT_PINCODE_LOCALITY": "PARBATTA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3903,
      "City District Name": "KHAGARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851217,
      "TXT_PINCODE_LOCALITY": "SAHEBPUR KAMAL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3903,
      "City District Name": "KHAGARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851218,
      "TXT_PINCODE_LOCALITY": "SUHRIDNAGAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3903,
      "City District Name": "KHAGARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851219,
      "TXT_PINCODE_LOCALITY": "BALHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3903,
      "City District Name": "KHAGARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851220,
      "TXT_PINCODE_LOCALITY": "MANSI P.O",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3903,
      "City District Name": "KHAGARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851221,
      "TXT_PINCODE_LOCALITY": "BHADAS",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3903,
      "City District Name": "KHAGARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851222,
      "TXT_PINCODE_LOCALITY": "JALKAURA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3903,
      "City District Name": "KHAGARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851223,
      "TXT_PINCODE_LOCALITY": "KULHARIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3903,
      "City District Name": "KHAGARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851224,
      "TXT_PINCODE_LOCALITY": "MARAR(BEGUSARAI)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3903,
      "City District Name": "KHAGARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851225,
      "TXT_PINCODE_LOCALITY": "PARIHARA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2411,
      "City District Name": "BEGUSARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851226,
      "TXT_PINCODE_LOCALITY": "PASRAHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3903,
      "City District Name": "KHAGARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851227,
      "TXT_PINCODE_LOCALITY": "PIPRA LATIF",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3903,
      "City District Name": "KHAGARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851228,
      "TXT_PINCODE_LOCALITY": "RAHIMPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3903,
      "City District Name": "KHAGARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851315,
      "TXT_PINCODE_LOCALITY": "SURIGAON",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442462,
      "City District Name": "BAISI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851412,
      "TXT_PINCODE_LOCALITY": "BHARWAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2411,
      "City District Name": "BEGUSARAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 851503,
      "TXT_PINCODE_LOCALITY": "BUCHAI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442606,
      "City District Name": "PARBATTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852101,
      "TXT_PINCODE_LOCALITY": "BIRI RAMPAL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4284,
      "City District Name": "MADHEPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852105,
      "TXT_PINCODE_LOCALITY": "BHAPTIAHI BAZAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5432,
      "City District Name": "SAHARSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852106,
      "TXT_PINCODE_LOCALITY": "CHUPRAON",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5432,
      "City District Name": "SAHARSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852107,
      "TXT_PINCODE_LOCALITY": "DHABAULI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5432,
      "City District Name": "SAHARSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852108,
      "TXT_PINCODE_LOCALITY": "GAMHARIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5432,
      "City District Name": "SAHARSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852109,
      "TXT_PINCODE_LOCALITY": "GANPATGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5796,
      "City District Name": "SUPAUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852110,
      "TXT_PINCODE_LOCALITY": "GARH BARUAI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5432,
      "City District Name": "SAHARSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852111,
      "TXT_PINCODE_LOCALITY": "JIARAM RAGHOPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5796,
      "City District Name": "SUPAUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852112,
      "TXT_PINCODE_LOCALITY": "M.BAGHAILI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4284,
      "City District Name": "MADHEPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852113,
      "TXT_PINCODE_LOCALITY": "MALIYA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4284,
      "City District Name": "MADHEPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852114,
      "TXT_PINCODE_LOCALITY": "POKHRAM",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4284,
      "City District Name": "MADHEPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852115,
      "TXT_PINCODE_LOCALITY": "GWALPARA S.O",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4284,
      "City District Name": "MADHEPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852116,
      "TXT_PINCODE_LOCALITY": "RAOTA.",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442616,
      "City District Name": "PURAINI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852117,
      "TXT_PINCODE_LOCALITY": "CHIKNI DAKHINWARI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442636,
      "City District Name": "SAUR BAZAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852119,
      "TXT_PINCODE_LOCALITY": "BELAHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442485,
      "City District Name": "BIHARIGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852121,
      "TXT_PINCODE_LOCALITY": "B.TEKHTI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4284,
      "City District Name": "MADHEPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852122,
      "TXT_PINCODE_LOCALITY": "MURLIGANJ BAZAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4284,
      "City District Name": "MADHEPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852123,
      "TXT_PINCODE_LOCALITY": "NAUHATTA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5432,
      "City District Name": "SAHARSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852124,
      "TXT_PINCODE_LOCALITY": "KAMALPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4284,
      "City District Name": "MADHEPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852125,
      "TXT_PINCODE_LOCALITY": "PRATAPGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5432,
      "City District Name": "SAHARSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852126,
      "TXT_PINCODE_LOCALITY": "SALKHUA BAZAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5432,
      "City District Name": "SAHARSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852127,
      "TXT_PINCODE_LOCALITY": "SIMRI BAKHTIARPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5432,
      "City District Name": "SAHARSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852128,
      "TXT_PINCODE_LOCALITY": "MAHESHUA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4284,
      "City District Name": "MADHEPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852129,
      "TXT_PINCODE_LOCALITY": "SONBARSA RAJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5432,
      "City District Name": "SAHARSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852130,
      "TXT_PINCODE_LOCALITY": "SUKHPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5796,
      "City District Name": "SUPAUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852131,
      "TXT_PINCODE_LOCALITY": "SUPAUL BAZAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5432,
      "City District Name": "SAHARSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852132,
      "TXT_PINCODE_LOCALITY": "LATRAHI.",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442641,
      "City District Name": "SINGHESHWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852133,
      "TXT_PINCODE_LOCALITY": "SUPAUL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5796,
      "City District Name": "SUPAUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852137,
      "TXT_PINCODE_LOCALITY": "SURPATGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4284,
      "City District Name": "MADHEPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852138,
      "TXT_PINCODE_LOCALITY": "THARBITTA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5432,
      "City District Name": "SAHARSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852139,
      "TXT_PINCODE_LOCALITY": "TRIBENIGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5796,
      "City District Name": "SUPAUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852140,
      "TXT_PINCODE_LOCALITY": "JARAILA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5796,
      "City District Name": "SUPAUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852141,
      "TXT_PINCODE_LOCALITY": "GOLMA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5432,
      "City District Name": "SAHARSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852142,
      "TXT_PINCODE_LOCALITY": "ISRAIN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4284,
      "City District Name": "MADHEPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852143,
      "TXT_PINCODE_LOCALITY": "BHIRKHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5432,
      "City District Name": "SAHARSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852144,
      "TXT_PINCODE_LOCALITY": "GAMAIL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4284,
      "City District Name": "MADHEPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852145,
      "TXT_PINCODE_LOCALITY": "DAHARIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5432,
      "City District Name": "SAHARSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852146,
      "TXT_PINCODE_LOCALITY": "SARBELA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5432,
      "City District Name": "SAHARSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852147,
      "TXT_PINCODE_LOCALITY": "RAJNI BABHANGAWA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5432,
      "City District Name": "SAHARSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852148,
      "TXT_PINCODE_LOCALITY": "BINA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5796,
      "City District Name": "SUPAUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852149,
      "TXT_PINCODE_LOCALITY": "KHAMAUTI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5432,
      "City District Name": "SAHARSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852150,
      "TXT_PINCODE_LOCALITY": "LOKAHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5796,
      "City District Name": "SUPAUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852151,
      "TXT_PINCODE_LOCALITY": "BELARI (SAHARSA)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5432,
      "City District Name": "SAHARSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852152,
      "TXT_PINCODE_LOCALITY": "TAMAUL PARSA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5432,
      "City District Name": "SAHARSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852153,
      "TXT_PINCODE_LOCALITY": "JIRWA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5432,
      "City District Name": "SAHARSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852154,
      "TXT_PINCODE_LOCALITY": "BABHNI BHELWA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4284,
      "City District Name": "MADHEPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852155,
      "TXT_PINCODE_LOCALITY": "GAHUMANI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4284,
      "City District Name": "MADHEPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852156,
      "TXT_PINCODE_LOCALITY": "PIPRAHI(SAHARSA)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4284,
      "City District Name": "MADHEPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852157,
      "TXT_PINCODE_LOCALITY": "SUKHASAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5796,
      "City District Name": "SUPAUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852158,
      "TXT_PINCODE_LOCALITY": "DAPARKHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5796,
      "City District Name": "SUPAUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852159,
      "TXT_PINCODE_LOCALITY": "THUMHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5796,
      "City District Name": "SUPAUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852160,
      "TXT_PINCODE_LOCALITY": "BASBITTI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5796,
      "City District Name": "SUPAUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852161,
      "TXT_PINCODE_LOCALITY": "BELARI MILKI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852162,
      "TXT_PINCODE_LOCALITY": "SIMRAHI BAZAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5796,
      "City District Name": "SUPAUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852188,
      "TXT_PINCODE_LOCALITY": "SHANKARPUR DOULAT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442619,
      "City District Name": "RAGHOPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852201,
      "TXT_PINCODE_LOCALITY": "SAHARSA DISTT BD",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5432,
      "City District Name": "SAHARSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852202,
      "TXT_PINCODE_LOCALITY": "DIGHIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442540,
      "City District Name": "KAHARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852204,
      "TXT_PINCODE_LOCALITY": "MILAKI.",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4770,
      "City District Name": "NAUGACHHIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852210,
      "TXT_PINCODE_LOCALITY": "MADHEPURA.",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4284,
      "City District Name": "MADHEPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852212,
      "TXT_PINCODE_LOCALITY": "BANGAON",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5432,
      "City District Name": "SAHARSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852213,
      "TXT_PINCODE_LOCALITY": "ABHIRAMPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4284,
      "City District Name": "MADHEPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852214,
      "TXT_PINCODE_LOCALITY": "JADIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5796,
      "City District Name": "SUPAUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852215,
      "TXT_PINCODE_LOCALITY": "SUPAUL S. O",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5796,
      "City District Name": "SUPAUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852216,
      "TXT_PINCODE_LOCALITY": "MAHISI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5432,
      "City District Name": "SAHARSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852217,
      "TXT_PINCODE_LOCALITY": "MANGWAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5432,
      "City District Name": "SAHARSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852218,
      "TXT_PINCODE_LOCALITY": "PIPRA BAZAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4284,
      "City District Name": "MADHEPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852219,
      "TXT_PINCODE_LOCALITY": "SHAH ALAMNAGAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4284,
      "City District Name": "MADHEPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852220,
      "TXT_PINCODE_LOCALITY": "S.MADHELI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4284,
      "City District Name": "MADHEPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852221,
      "TXT_PINCODE_LOCALITY": "SOUR BAZAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5432,
      "City District Name": "SAHARSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852222,
      "TXT_PINCODE_LOCALITY": "BARIAHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5432,
      "City District Name": "SAHARSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852223,
      "TXT_PINCODE_LOCALITY": "DHURIA KALASAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5432,
      "City District Name": "SAHARSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852224,
      "TXT_PINCODE_LOCALITY": "SITANABAD",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5432,
      "City District Name": "SAHARSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852225,
      "TXT_PINCODE_LOCALITY": "BHADDI DURGAPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5432,
      "City District Name": "SAHARSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852227,
      "TXT_PINCODE_LOCALITY": "MAHESHPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5796,
      "City District Name": "SUPAUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852228,
      "TXT_PINCODE_LOCALITY": "PAINA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4284,
      "City District Name": "MADHEPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852229,
      "TXT_PINCODE_LOCALITY": "PARARI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5796,
      "City District Name": "SUPAUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852230,
      "TXT_PINCODE_LOCALITY": "SULINDABAD",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5432,
      "City District Name": "SAHARSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852231,
      "TXT_PINCODE_LOCALITY": "NARIAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5432,
      "City District Name": "SAHARSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852232,
      "TXT_PINCODE_LOCALITY": "KAHRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5432,
      "City District Name": "SAHARSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852233,
      "TXT_PINCODE_LOCALITY": "GANESHPUR (SAHARSA)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4284,
      "City District Name": "MADHEPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852234,
      "TXT_PINCODE_LOCALITY": "MANGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5796,
      "City District Name": "SUPAUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852235,
      "TXT_PINCODE_LOCALITY": "HARDI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5432,
      "City District Name": "SAHARSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852236,
      "TXT_PINCODE_LOCALITY": "JITAPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4284,
      "City District Name": "MADHEPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852238,
      "TXT_PINCODE_LOCALITY": "KORIAPATTI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5796,
      "City District Name": "SUPAUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852241,
      "TXT_PINCODE_LOCALITY": "PATARGHAT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5432,
      "City District Name": "SAHARSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852242,
      "TXT_PINCODE_LOCALITY": "SATYANARAIN SIMRAHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5432,
      "City District Name": "SAHARSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852243,
      "TXT_PINCODE_LOCALITY": "BHAN TEKTHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5432,
      "City District Name": "SAHARSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 852246,
      "TXT_PINCODE_LOCALITY": "PAHARPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5432,
      "City District Name": "SAHARSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 853004,
      "TXT_PINCODE_LOCALITY": "CHAPAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 853201,
      "TXT_PINCODE_LOCALITY": "BIHPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 853202,
      "TXT_PINCODE_LOCALITY": "KHARIK BAZAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 853203,
      "TXT_PINCODE_LOCALITY": "NARAYANPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 853204,
      "TXT_PINCODE_LOCALITY": "NAUGACHIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 853205,
      "TXT_PINCODE_LOCALITY": "SAIDPUR TOLA GORIAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 853206,
      "TXT_PINCODE_LOCALITY": "BHAWANIPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 853207,
      "TXT_PINCODE_LOCALITY": "PANHGACHIA BAZAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 853208,
      "TXT_PINCODE_LOCALITY": "PRATAPNAGAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 2455,
      "City District Name": "BHAGALPUR (M.CORP)",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854101,
      "TXT_PINCODE_LOCALITY": "AYODHYAGANJ BAZAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3850,
      "City District Name": "KATIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854102,
      "TXT_PINCODE_LOCALITY": "CHOPRA RAM NAGAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854103,
      "TXT_PINCODE_LOCALITY": "DAHARIA MILLS",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3850,
      "City District Name": "KATIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854104,
      "TXT_PINCODE_LOCALITY": "GURU BAZAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3850,
      "City District Name": "KATIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854105,
      "TXT_PINCODE_LOCALITY": "DURGAPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4010,
      "City District Name": "KISHANGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854106,
      "TXT_PINCODE_LOCALITY": "KATIHAR BMP CAMP",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3850,
      "City District Name": "KATIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854107,
      "TXT_PINCODE_LOCALITY": "KATIHAR MILLS",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3850,
      "City District Name": "KATIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854108,
      "TXT_PINCODE_LOCALITY": "KORHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3850,
      "City District Name": "KATIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854109,
      "TXT_PINCODE_LOCALITY": "HARDIYA GUNJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3850,
      "City District Name": "KATIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854112,
      "TXT_PINCODE_LOCALITY": "PAR DIARA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442175,
      "City District Name": "AMDABAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854113,
      "TXT_PINCODE_LOCALITY": "MANIHARI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3850,
      "City District Name": "KATIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854114,
      "TXT_PINCODE_LOCALITY": "POTHIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3850,
      "City District Name": "KATIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854115,
      "TXT_PINCODE_LOCALITY": "SEMAPUR FACTORY",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3850,
      "City District Name": "KATIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854116,
      "TXT_PINCODE_LOCALITY": "MAHADEOPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3850,
      "City District Name": "KATIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854117,
      "TXT_PINCODE_LOCALITY": "ROSHNA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3850,
      "City District Name": "KATIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854118,
      "TXT_PINCODE_LOCALITY": "FALKA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3850,
      "City District Name": "KATIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854119,
      "TXT_PINCODE_LOCALITY": "CHANDPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3850,
      "City District Name": "KATIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854120,
      "TXT_PINCODE_LOCALITY": "GORIAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854121,
      "TXT_PINCODE_LOCALITY": "MUSAPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1904,
      "City District Name": "ARARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854122,
      "TXT_PINCODE_LOCALITY": "KANTAKOSH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3850,
      "City District Name": "KATIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854123,
      "TXT_PINCODE_LOCALITY": "AMDABAD",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3850,
      "City District Name": "KATIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854124,
      "TXT_PINCODE_LOCALITY": "PRANPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3850,
      "City District Name": "KATIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854125,
      "TXT_PINCODE_LOCALITY": "BHANDARTAL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3850,
      "City District Name": "KATIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854126,
      "TXT_PINCODE_LOCALITY": "SIRNIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854127,
      "TXT_PINCODE_LOCALITY": "DALAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3850,
      "City District Name": "KATIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854201,
      "TXT_PINCODE_LOCALITY": "BANAILI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854202,
      "TXT_PINCODE_LOCALITY": "BANMANKHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854203,
      "TXT_PINCODE_LOCALITY": "BARHARA KOTHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854204,
      "TXT_PINCODE_LOCALITY": "RAJDHAM",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854205,
      "TXT_PINCODE_LOCALITY": "DHAMDAHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854206,
      "TXT_PINCODE_LOCALITY": "SARSI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854207,
      "TXT_PINCODE_LOCALITY": "BISHUNPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1904,
      "City District Name": "ARARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854208,
      "TXT_PINCODE_LOCALITY": "BALLIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854209,
      "TXT_PINCODE_LOCALITY": "RUPOULI(PURNEA)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854301,
      "TXT_PINCODE_LOCALITY": "PURNEA DISTT BD",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854302,
      "TXT_PINCODE_LOCALITY": "PURNEA CITY",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854303,
      "TXT_PINCODE_LOCALITY": "PURNEA POLYTECHNIC",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854304,
      "TXT_PINCODE_LOCALITY": "THAKURBARI.",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442556,
      "City District Name": "KRITYANAND NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854305,
      "TXT_PINCODE_LOCALITY": "KHUSKIBAGH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854306,
      "TXT_PINCODE_LOCALITY": "POTHIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442556,
      "City District Name": "KRITYANAND NAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854311,
      "TXT_PINCODE_LOCALITY": "ARARIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1904,
      "City District Name": "ARARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854312,
      "TXT_PINCODE_LOCALITY": "ARARIA R S",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1904,
      "City District Name": "ARARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854313,
      "TXT_PINCODE_LOCALITY": "BATURBARI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1904,
      "City District Name": "ARARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854314,
      "TXT_PINCODE_LOCALITY": "DUBA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1904,
      "City District Name": "ARARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854315,
      "TXT_PINCODE_LOCALITY": "BAISI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854316,
      "TXT_PINCODE_LOCALITY": "BATHNAHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854317,
      "TXT_PINCODE_LOCALITY": "BARSOIGHAT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3850,
      "City District Name": "KATIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854318,
      "TXT_PINCODE_LOCALITY": "STATION RD FORBESGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854319,
      "TXT_PINCODE_LOCALITY": "AMOUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854320,
      "TXT_PINCODE_LOCALITY": "KHEMCHAND",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854321,
      "TXT_PINCODE_LOCALITY": "HARDA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3850,
      "City District Name": "KATIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854322,
      "TXT_PINCODE_LOCALITY": "PALASI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1904,
      "City District Name": "ARARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854323,
      "TXT_PINCODE_LOCALITY": "SIKTI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1904,
      "City District Name": "ARARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854325,
      "TXT_PINCODE_LOCALITY": "GARH BANAILI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854326,
      "TXT_PINCODE_LOCALITY": "GULABBAGH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854327,
      "TXT_PINCODE_LOCALITY": "JALALGARH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854328,
      "TXT_PINCODE_LOCALITY": "JOGBANI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1904,
      "City District Name": "ARARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854329,
      "TXT_PINCODE_LOCALITY": "JOKIHAT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1904,
      "City District Name": "ARARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854330,
      "TXT_PINCODE_LOCALITY": "KASBA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854331,
      "TXT_PINCODE_LOCALITY": "KURSA KANTA S.O",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1904,
      "City District Name": "ARARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854332,
      "TXT_PINCODE_LOCALITY": "KUARI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1904,
      "City District Name": "ARARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854333,
      "TXT_PINCODE_LOCALITY": "MADANPUR PURNIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1904,
      "City District Name": "ARARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854334,
      "TXT_PINCODE_LOCALITY": "MARYGANJ S.O",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1904,
      "City District Name": "ARARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854335,
      "TXT_PINCODE_LOCALITY": "NARPATGANJ S.O",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1904,
      "City District Name": "ARARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854336,
      "TXT_PINCODE_LOCALITY": "NAWADA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4782,
      "City District Name": "NAWADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854337,
      "TXT_PINCODE_LOCALITY": "RANIPATRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854338,
      "TXT_PINCODE_LOCALITY": "BHIMNAGAR BARRAGE",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5432,
      "City District Name": "SAHARSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854339,
      "TXT_PINCODE_LOCALITY": "BALUA BAZAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854340,
      "TXT_PINCODE_LOCALITY": "BIRPUR S.O",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5796,
      "City District Name": "SUPAUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854341,
      "TXT_PINCODE_LOCALITY": "KURSAIL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1904,
      "City District Name": "ARARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854342,
      "TXT_PINCODE_LOCALITY": "CHAKAI HAT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1904,
      "City District Name": "ARARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854343,
      "TXT_PINCODE_LOCALITY": "KHAGARAH",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854344,
      "TXT_PINCODE_LOCALITY": "DHOLBAZZA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1904,
      "City District Name": "ARARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854345,
      "TXT_PINCODE_LOCALITY": "KHAIRKHAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1904,
      "City District Name": "ARARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854346,
      "TXT_PINCODE_LOCALITY": "AURAHIHINJUA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854347,
      "TXT_PINCODE_LOCALITY": "RAMPUR(PURNEA)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3850,
      "City District Name": "KATIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854349,
      "TXT_PINCODE_LOCALITY": "PALSICHAKARDAHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854350,
      "TXT_PINCODE_LOCALITY": "KOSKAPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1904,
      "City District Name": "ARARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854351,
      "TXT_PINCODE_LOCALITY": "TAMGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1904,
      "City District Name": "ARARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854352,
      "TXT_PINCODE_LOCALITY": "MAJHUA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1904,
      "City District Name": "ARARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854353,
      "TXT_PINCODE_LOCALITY": "HARIPURDAK",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854354,
      "TXT_PINCODE_LOCALITY": "BHANGAHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1904,
      "City District Name": "ARARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854355,
      "TXT_PINCODE_LOCALITY": "RAMPUR KODARKATI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1904,
      "City District Name": "ARARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854356,
      "TXT_PINCODE_LOCALITY": "MIRZAPUR KOTHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854357,
      "TXT_PINCODE_LOCALITY": "AMHARA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854358,
      "TXT_PINCODE_LOCALITY": "MADARGHAT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854359,
      "TXT_PINCODE_LOCALITY": "PIPRA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1904,
      "City District Name": "ARARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854360,
      "TXT_PINCODE_LOCALITY": "SONAPUR BAZAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854362,
      "TXT_PINCODE_LOCALITY": "KHAWASPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1904,
      "City District Name": "ARARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854364,
      "TXT_PINCODE_LOCALITY": "GANESHPUR (PURNEA)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854365,
      "TXT_PINCODE_LOCALITY": "KAJHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854366,
      "TXT_PINCODE_LOCALITY": "KAKAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1904,
      "City District Name": "ARARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854367,
      "TXT_PINCODE_LOCALITY": "BELWA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 1904,
      "City District Name": "ARARIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854368,
      "TXT_PINCODE_LOCALITY": "RAUTAHAT",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854369,
      "TXT_PINCODE_LOCALITY": "BASANTPUR(SAHARSA)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5796,
      "City District Name": "SUPAUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854414,
      "TXT_PINCODE_LOCALITY": "SATMI.",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442507,
      "City District Name": "DHAMDAHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854501,
      "TXT_PINCODE_LOCALITY": "BITHNAULI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854511,
      "TXT_PINCODE_LOCALITY": "ARARIA BAIRGACHHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854613,
      "TXT_PINCODE_LOCALITY": "SAMAUL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3148,
      "City District Name": "FORBESGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854817,
      "TXT_PINCODE_LOCALITY": "SUTATOR.",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442472,
      "City District Name": "BARSOI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 854818,
      "TXT_PINCODE_LOCALITY": "DIPAUL",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3148,
      "City District Name": "FORBESGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 855101,
      "TXT_PINCODE_LOCALITY": "BAHADURGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4010,
      "City District Name": "KISHANGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 855102,
      "TXT_PINCODE_LOCALITY": "BARSOI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 855104,
      "TXT_PINCODE_LOCALITY": "BHAINSDIARA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3850,
      "City District Name": "KATIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 855105,
      "TXT_PINCODE_LOCALITY": "DURGAGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4010,
      "City District Name": "KISHANGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 855106,
      "TXT_PINCODE_LOCALITY": "GALGALIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4010,
      "City District Name": "KISHANGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 855107,
      "TXT_PINCODE_LOCALITY": "KISHANGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4010,
      "City District Name": "KISHANGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 855108,
      "TXT_PINCODE_LOCALITY": "POWAKHALI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4010,
      "City District Name": "KISHANGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 855110,
      "TXT_PINCODE_LOCALITY": "TAJKHAND",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442458,
      "City District Name": "AZAMNAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 855112,
      "TXT_PINCODE_LOCALITY": "TEMUHAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442459,
      "City District Name": "BACHHWARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 855113,
      "TXT_PINCODE_LOCALITY": "SALMARI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3850,
      "City District Name": "KATIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 855114,
      "TXT_PINCODE_LOCALITY": "SONALI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3850,
      "City District Name": "KATIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 855115,
      "TXT_PINCODE_LOCALITY": "SONTHA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 855116,
      "TXT_PINCODE_LOCALITY": "THAKURGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4010,
      "City District Name": "KISHANGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 855117,
      "TXT_PINCODE_LOCALITY": "TAIYABPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4010,
      "City District Name": "KISHANGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 855118,
      "TXT_PINCODE_LOCALITY": "KOCHA DHAMAN",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4010,
      "City District Name": "KISHANGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 855119,
      "TXT_PINCODE_LOCALITY": "TERHAGACHI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4010,
      "City District Name": "KISHANGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 855120,
      "TXT_PINCODE_LOCALITY": "DIGHAL BANK",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4010,
      "City District Name": "KISHANGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 855121,
      "TXT_PINCODE_LOCALITY": "BALARAMPUR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 855122,
      "TXT_PINCODE_LOCALITY": "AZAM NAGAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5217,
      "City District Name": "PURNIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 855123,
      "TXT_PINCODE_LOCALITY": "GANGI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4010,
      "City District Name": "KISHANGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 855124,
      "TXT_PINCODE_LOCALITY": "SINGHIA(KATIHAR)",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4010,
      "City District Name": "KISHANGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 855125,
      "TXT_PINCODE_LOCALITY": "CHURLI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 855126,
      "TXT_PINCODE_LOCALITY": "TULSIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4010,
      "City District Name": "KISHANGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 855127,
      "TXT_PINCODE_LOCALITY": "DHANTOLA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4010,
      "City District Name": "KISHANGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 855128,
      "TXT_PINCODE_LOCALITY": "POTHIA R S",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3850,
      "City District Name": "KATIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 855217,
      "TXT_PINCODE_LOCALITY": "PANIGHATTA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442612,
      "City District Name": "POTHIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 855317,
      "TXT_PINCODE_LOCALITY": "BHALAGANJ",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 3850,
      "City District Name": "KATIHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 855456,
      "TXT_PINCODE_LOCALITY": "SUGAULI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5773,
      "City District Name": "SUGAULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 855710,
      "TXT_PINCODE_LOCALITY": "TAISA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4010,
      "City District Name": "KISHANGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 855717,
      "TXT_PINCODE_LOCALITY": "MAMUBHAGINA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442612,
      "City District Name": "POTHIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 855810,
      "TXT_PINCODE_LOCALITY": "PETBHARI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 5884,
      "City District Name": "THAKURGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 856121,
      "TXT_PINCODE_LOCALITY": "CHORHLI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442478,
      "City District Name": "BELDAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 857225,
      "TXT_PINCODE_LOCALITY": "BASOPATTI",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4287,
      "City District Name": "MADHUBANI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 858107,
      "TXT_PINCODE_LOCALITY": "SATKHAMHAR",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 4010,
      "City District Name": "KISHANGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 859316,
      "TXT_PINCODE_LOCALITY": "CHAKORWA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442592,
      "City District Name": "NARPATGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 859325,
      "TXT_PINCODE_LOCALITY": "CHILHANIA",
      "NUM_STATE_CD": 147,
      "State Name": "BIHAR",
      "NUM_CITYDISTRICT_CD": 442539,
      "City District Name": "JOKIHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 862037,
      "TXT_PINCODE_LOCALITY": "COCHIN",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 194,
      "City District Name": "COCHIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 760001,
      "TXT_PINCODE_LOCALITY": "MILITARY LINES",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 760002,
      "TXT_PINCODE_LOCALITY": "SRIRAM NAGAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 760003,
      "TXT_PINCODE_LOCALITY": "GOSANI NUAGAON",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 760004,
      "TXT_PINCODE_LOCALITY": "GOILUNDI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 760005,
      "TXT_PINCODE_LOCALITY": "HILPATNA (BERHAMPUR5)",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 760006,
      "TXT_PINCODE_LOCALITY": "HARIDAKHANDI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 760007,
      "TXT_PINCODE_LOCALITY": "UNIVERSITY",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 760008,
      "TXT_PINCODE_LOCALITY": "LANJIPALLI (BERHAMPUR-8)",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 760009,
      "TXT_PINCODE_LOCALITY": "NABEEN (BERHAMPUR-9)",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 760010,
      "TXT_PINCODE_LOCALITY": "ENGINEERING SCHOOL BERHAM",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 760011,
      "TXT_PINCODE_LOCALITY": "GANJAM",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 760012,
      "TXT_PINCODE_LOCALITY": "BHUBANESHWAR S. O.",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2529,
      "City District Name": "BHUBANESWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 760013,
      "TXT_PINCODE_LOCALITY": "HANDIGHARA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5221,
      "City District Name": "PURUSOTTAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 760017,
      "TXT_PINCODE_LOCALITY": "SUNDERGARH",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 760020,
      "TXT_PINCODE_LOCALITY": "RUNDIMAHAL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6477,
      "City District Name": "BOUDH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 760024,
      "TXT_PINCODE_LOCALITY": "DHAMANAPADAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3019,
      "City District Name": "DIGAPAHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 760031,
      "TXT_PINCODE_LOCALITY": "INDRAKHI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 760032,
      "TXT_PINCODE_LOCALITY": "BAHADURPETTA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 760033,
      "TXT_PINCODE_LOCALITY": "KONISI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 760034,
      "TXT_PINCODE_LOCALITY": "PADMAPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 760830,
      "TXT_PINCODE_LOCALITY": "KHARMUNDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 442908,
      "City District Name": "ATTABIRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761001,
      "TXT_PINCODE_LOCALITY": "NIMAKHANDI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761002,
      "TXT_PINCODE_LOCALITY": "GOPALPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761003,
      "TXT_PINCODE_LOCALITY": "BHATKUMARDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761004,
      "TXT_PINCODE_LOCALITY": "PATRAPUR (GM)",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761005,
      "TXT_PINCODE_LOCALITY": "JARADAGAD",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761006,
      "TXT_PINCODE_LOCALITY": "JAYANTIPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761007,
      "TXT_PINCODE_LOCALITY": "PADMANBHAPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761008,
      "TXT_PINCODE_LOCALITY": "GOLANTHRA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761009,
      "TXT_PINCODE_LOCALITY": "GIRISOLA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761010,
      "TXT_PINCODE_LOCALITY": "CHIKATI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761011,
      "TXT_PINCODE_LOCALITY": "NUAPADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761012,
      "TXT_PINCODE_LOCALITY": "DIGAPAHANDI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761013,
      "TXT_PINCODE_LOCALITY": "PATTOPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761014,
      "TXT_PINCODE_LOCALITY": "PUDAMARI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761015,
      "TXT_PINCODE_LOCALITY": "MOHANA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 424446,
      "City District Name": "GAJAPATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761016,
      "TXT_PINCODE_LOCALITY": "R UDAIGIRI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 424446,
      "City District Name": "GAJAPATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761017,
      "TXT_PINCODE_LOCALITY": "CHANDRAGIRI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 424446,
      "City District Name": "GAJAPATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761018,
      "TXT_PINCODE_LOCALITY": "PURUSOTHAMPUR NEW",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761019,
      "TXT_PINCODE_LOCALITY": "PRATAPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761020,
      "TXT_PINCODE_LOCALITY": "SITARAMPALLI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761021,
      "TXT_PINCODE_LOCALITY": "DIGAPAHANDI TAHASIL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761022,
      "TXT_PINCODE_LOCALITY": "BERUANBADI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3980,
      "City District Name": "KHORDHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761025,
      "TXT_PINCODE_LOCALITY": "JAYASREE",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761026,
      "TXT_PINCODE_LOCALITY": "GANJAM",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761027,
      "TXT_PINCODE_LOCALITY": "HUMMA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761028,
      "TXT_PINCODE_LOCALITY": "MINCHINPATNA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761029,
      "TXT_PINCODE_LOCALITY": "KHALLIKOTE R S",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761030,
      "TXT_PINCODE_LOCALITY": "KHALLIKOTE",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761031,
      "TXT_PINCODE_LOCALITY": "BEGUNIAPADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761032,
      "TXT_PINCODE_LOCALITY": "KODALA TAHASIL COLONY",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761033,
      "TXT_PINCODE_LOCALITY": "GONDALA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761034,
      "TXT_PINCODE_LOCALITY": "MAHENDRAGADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 424446,
      "City District Name": "GAJAPATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761035,
      "TXT_PINCODE_LOCALITY": "SUMANDALO",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761036,
      "TXT_PINCODE_LOCALITY": "SABULIA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761037,
      "TXT_PINCODE_LOCALITY": "SURANGI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 424446,
      "City District Name": "GAJAPATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761038,
      "TXT_PINCODE_LOCALITY": "PHASI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761039,
      "TXT_PINCODE_LOCALITY": "KANHAIPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761040,
      "TXT_PINCODE_LOCALITY": "NUAGAM CHIKITI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761041,
      "TXT_PINCODE_LOCALITY": "BADAKHARIDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761042,
      "TXT_PINCODE_LOCALITY": "BOMKEI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 424446,
      "City District Name": "GAJAPATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761043,
      "TXT_PINCODE_LOCALITY": "PANDIA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761044,
      "TXT_PINCODE_LOCALITY": "ATHAGARH PATNA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761045,
      "TXT_PINCODE_LOCALITY": "MATIKHAL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761046,
      "TXT_PINCODE_LOCALITY": "MUNSHIPENTHA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761051,
      "TXT_PINCODE_LOCALITY": "NARENDRAPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761052,
      "TXT_PINCODE_LOCALITY": "GOLABANDH",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761053,
      "TXT_PINCODE_LOCALITY": "SAMANTIPALLI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761054,
      "TXT_PINCODE_LOCALITY": "BALLIPADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761055,
      "TXT_PINCODE_LOCALITY": "BISSAMGIRI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761056,
      "TXT_PINCODE_LOCALITY": "SAHAPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761096,
      "TXT_PINCODE_LOCALITY": "SUNAPARBAT",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 442938,
      "City District Name": "TANGARAPALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761100,
      "TXT_PINCODE_LOCALITY": "KUKUDAKHANDI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761101,
      "TXT_PINCODE_LOCALITY": "KANCHURU",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761102,
      "TXT_PINCODE_LOCALITY": "HINJILICUT COLLEGE ROAD",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761103,
      "TXT_PINCODE_LOCALITY": "PITALA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761104,
      "TXT_PINCODE_LOCALITY": "KAVISURYANGAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761105,
      "TXT_PINCODE_LOCALITY": "POLSARA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761106,
      "TXT_PINCODE_LOCALITY": "SERAGADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761107,
      "TXT_PINCODE_LOCALITY": "DHARAKOTE",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761108,
      "TXT_PINCODE_LOCALITY": "SURUDA BAZAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761109,
      "TXT_PINCODE_LOCALITY": "BODAGADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761110,
      "TXT_PINCODE_LOCALITY": "ASKA JUNCTION",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761111,
      "TXT_PINCODE_LOCALITY": "NUAGAM ASKA COLLEGE",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761112,
      "TXT_PINCODE_LOCALITY": "MATHURA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761113,
      "TXT_PINCODE_LOCALITY": "KASPAKARADAKONA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761114,
      "TXT_PINCODE_LOCALITY": "MUNDAMARAI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761115,
      "TXT_PINCODE_LOCALITY": "GUNTHAPADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761116,
      "TXT_PINCODE_LOCALITY": "BHETNOI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761117,
      "TXT_PINCODE_LOCALITY": "BALLIPADAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761118,
      "TXT_PINCODE_LOCALITY": "BUGUDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761119,
      "TXT_PINCODE_LOCALITY": "BELLGUNTHA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761120,
      "TXT_PINCODE_LOCALITY": "BARGAON",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761121,
      "TXT_PINCODE_LOCALITY": "JAGANATHPRASAD",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761122,
      "TXT_PINCODE_LOCALITY": "NIMINA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761123,
      "TXT_PINCODE_LOCALITY": "GANGAPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761124,
      "TXT_PINCODE_LOCALITY": "GOBARA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761125,
      "TXT_PINCODE_LOCALITY": "BADAKODANDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761126,
      "TXT_PINCODE_LOCALITY": "BHANJA NAGAR H O",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761127,
      "TXT_PINCODE_LOCALITY": "BHANJNAGAR COLLEGE",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761128,
      "TXT_PINCODE_LOCALITY": "KHUKUNDIA NUAGADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761131,
      "TXT_PINCODE_LOCALITY": "KULLADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761132,
      "TXT_PINCODE_LOCALITY": "MUJJAGADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761133,
      "TXT_PINCODE_LOCALITY": "JULLUNDI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761134,
      "TXT_PINCODE_LOCALITY": "BURUPADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761135,
      "TXT_PINCODE_LOCALITY": "DEBABHUMI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761136,
      "TXT_PINCODE_LOCALITY": "SUBALAYA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761137,
      "TXT_PINCODE_LOCALITY": "BARAGAON (ASKA)",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761138,
      "TXT_PINCODE_LOCALITY": "TARASINIGI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761139,
      "TXT_PINCODE_LOCALITY": "PANCHABHUTI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761140,
      "TXT_PINCODE_LOCALITY": "TANARADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761141,
      "TXT_PINCODE_LOCALITY": "GALLERI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761142,
      "TXT_PINCODE_LOCALITY": "JHADABHUMI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761143,
      "TXT_PINCODE_LOCALITY": "KARACHULI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761144,
      "TXT_PINCODE_LOCALITY": "KONKARADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761145,
      "TXT_PINCODE_LOCALITY": "KHAMBESWARIPATNA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761146,
      "TXT_PINCODE_LOCALITY": "BENGAPADAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761151,
      "TXT_PINCODE_LOCALITY": "ASURABANDHA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761200,
      "TXT_PINCODE_LOCALITY": "PARLAKHEMUNDI HO.",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 424446,
      "City District Name": "GAJAPATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761201,
      "TXT_PINCODE_LOCALITY": "PARALAKHEMUNDI-2",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761202,
      "TXT_PINCODE_LOCALITY": "UPARALOGU",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5024,
      "City District Name": "PARLAKHEMUNDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761206,
      "TXT_PINCODE_LOCALITY": "KASHINAGAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 424446,
      "City District Name": "GAJAPATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761207,
      "TXT_PINCODE_LOCALITY": "GUMMA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761208,
      "TXT_PINCODE_LOCALITY": "KHARADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 424446,
      "City District Name": "GAJAPATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761209,
      "TXT_PINCODE_LOCALITY": "KHARIAGUDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761210,
      "TXT_PINCODE_LOCALITY": "GURANDI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 424446,
      "City District Name": "GAJAPATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761211,
      "TXT_PINCODE_LOCALITY": "UPALADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 424446,
      "City District Name": "GAJAPATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761212,
      "TXT_PINCODE_LOCALITY": "NARAYANPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 424446,
      "City District Name": "GAJAPATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761213,
      "TXT_PINCODE_LOCALITY": "RAYAGADA (GM)",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 424446,
      "City District Name": "GAJAPATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761214,
      "TXT_PINCODE_LOCALITY": "NUAGADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1878,
      "City District Name": "GANJAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761215,
      "TXT_PINCODE_LOCALITY": "GARABANDHA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 424446,
      "City District Name": "GAJAPATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761216,
      "TXT_PINCODE_LOCALITY": "SERANGO",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 424446,
      "City District Name": "GAJAPATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 761217,
      "TXT_PINCODE_LOCALITY": "ODABA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 424446,
      "City District Name": "GAJAPATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 762001,
      "TXT_PINCODE_LOCALITY": "O B NAGAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6459,
      "City District Name": "KANDHAMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 762002,
      "TXT_PINCODE_LOCALITY": "KATRINGIA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6459,
      "City District Name": "KANDHAMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 762003,
      "TXT_PINCODE_LOCALITY": "SODALONDONPADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 442923,
      "City District Name": "KANTAMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 762010,
      "TXT_PINCODE_LOCALITY": "TIKKABALI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6459,
      "City District Name": "KANDHAMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 762011,
      "TXT_PINCODE_LOCALITY": "PHIRINGIA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6459,
      "City District Name": "KANDHAMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 762012,
      "TXT_PINCODE_LOCALITY": "KHAJURIPADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 424448,
      "City District Name": "BAUDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 762013,
      "TXT_PINCODE_LOCALITY": "PURUNAKATAK",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 424448,
      "City District Name": "BAUDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 762014,
      "TXT_PINCODE_LOCALITY": "MALISAHI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6477,
      "City District Name": "BOUDH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 762015,
      "TXT_PINCODE_LOCALITY": "BAUNSUNI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 424448,
      "City District Name": "BAUDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 762016,
      "TXT_PINCODE_LOCALITY": "MANMUNDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 424448,
      "City District Name": "BAUDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 762017,
      "TXT_PINCODE_LOCALITY": "KANTAMAL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 424448,
      "City District Name": "BAUDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 762018,
      "TXT_PINCODE_LOCALITY": "GHANTAPARA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 424448,
      "City District Name": "BAUDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 762019,
      "TXT_PINCODE_LOCALITY": "SANKARKHOLE",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6459,
      "City District Name": "KANDHAMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 762020,
      "TXT_PINCODE_LOCALITY": "HARBHANGA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 424448,
      "City District Name": "BAUDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 762021,
      "TXT_PINCODE_LOCALITY": "BAMUNIGAON",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6459,
      "City District Name": "KANDHAMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 762022,
      "TXT_PINCODE_LOCALITY": "KALINGA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6459,
      "City District Name": "KANDHAMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 762023,
      "TXT_PINCODE_LOCALITY": "JHADARAGINGI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6459,
      "City District Name": "KANDHAMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 762024,
      "TXT_PINCODE_LOCALITY": "BIRANARSINGHPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 424448,
      "City District Name": "BAUDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 762025,
      "TXT_PINCODE_LOCALITY": "BASTINGIA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6459,
      "City District Name": "KANDHAMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 762026,
      "TXT_PINCODE_LOCALITY": "SARSARA B.O",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6477,
      "City District Name": "BOUDH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 762027,
      "TXT_PINCODE_LOCALITY": "LINEPADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6459,
      "City District Name": "KANDHAMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 762028,
      "TXT_PINCODE_LOCALITY": "CHAKAPADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6459,
      "City District Name": "KANDHAMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 762029,
      "TXT_PINCODE_LOCALITY": "GUMAGARH",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6459,
      "City District Name": "KANDHAMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 762030,
      "TXT_PINCODE_LOCALITY": "BAGHIABAHAL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 424448,
      "City District Name": "BAUDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 762100,
      "TXT_PINCODE_LOCALITY": "UDAYAGIRI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6459,
      "City District Name": "KANDHAMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 762101,
      "TXT_PINCODE_LOCALITY": "RAIKIA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6459,
      "City District Name": "KANDHAMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 762102,
      "TXT_PINCODE_LOCALITY": "NUAGAON (PHULBANI)",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6459,
      "City District Name": "KANDHAMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 762103,
      "TXT_PINCODE_LOCALITY": "NUASAHI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6459,
      "City District Name": "KANDHAMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 762104,
      "TXT_PINCODE_LOCALITY": "DARINGABADI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6459,
      "City District Name": "KANDHAMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 762105,
      "TXT_PINCODE_LOCALITY": "KOTHARH",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6459,
      "City District Name": "KANDHAMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 762106,
      "TXT_PINCODE_LOCALITY": "SARANGADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6459,
      "City District Name": "KANDHAMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 762107,
      "TXT_PINCODE_LOCALITY": "TUMRIBANDH",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6459,
      "City District Name": "KANDHAMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 762108,
      "TXT_PINCODE_LOCALITY": "RUDANGIA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6459,
      "City District Name": "KANDHAMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 762109,
      "TXT_PINCODE_LOCALITY": "KURTUMGARH",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6459,
      "City District Name": "KANDHAMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 762110,
      "TXT_PINCODE_LOCALITY": "BARKHAMA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6459,
      "City District Name": "KANDHAMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 762111,
      "TXT_PINCODE_LOCALITY": "MALLIKAPODI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6459,
      "City District Name": "KANDHAMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 762112,
      "TXT_PINCODE_LOCALITY": "PABURIA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 6459,
      "City District Name": "KANDHAMAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 763001,
      "TXT_PINCODE_LOCALITY": "SUNABEDA I",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 763002,
      "TXT_PINCODE_LOCALITY": "SECTOR-VII SUNABEDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 763003,
      "TXT_PINCODE_LOCALITY": "D P CAMP SUNABEDA-3",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 763004,
      "TXT_PINCODE_LOCALITY": "NAD, SUNABEDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 763008,
      "TXT_PINCODE_LOCALITY": "DAMANJODI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764001,
      "TXT_PINCODE_LOCALITY": "JEYPORE",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764002,
      "TXT_PINCODE_LOCALITY": "M G ROAD",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764003,
      "TXT_PINCODE_LOCALITY": "JEYPORE (K)3",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764004,
      "TXT_PINCODE_LOCALITY": "JEYPORE (K) IRRIGATION",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764005,
      "TXT_PINCODE_LOCALITY": "JAYANAGAR (JAYPORE 5)",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764006,
      "TXT_PINCODE_LOCALITY": "BARINIPUT",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764008,
      "TXT_PINCODE_LOCALITY": "TARAGAON",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764011,
      "TXT_PINCODE_LOCALITY": "KOLABNAGAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764012,
      "TXT_PINCODE_LOCALITY": "KUNDRA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764014,
      "TXT_PINCODE_LOCALITY": "NARAYANPATNA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764020,
      "TXT_PINCODE_LOCALITY": "KORAPUT R S",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764021,
      "TXT_PINCODE_LOCALITY": "O M P LINE KORAPUT",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764022,
      "TXT_PINCODE_LOCALITY": "KORAPUT COLLECTORATE (KOR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764024,
      "TXT_PINCODE_LOCALITY": "PATRAPUT",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 3329,
      "City District Name": "GUNUPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764027,
      "TXT_PINCODE_LOCALITY": "BANDHUGAON",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 218,
      "City District Name": "CUTTACK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764028,
      "TXT_PINCODE_LOCALITY": "PINDAPADAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764036,
      "TXT_PINCODE_LOCALITY": "SIMILIGUDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764037,
      "TXT_PINCODE_LOCALITY": "NANDAPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764038,
      "TXT_PINCODE_LOCALITY": "PADWA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764039,
      "TXT_PINCODE_LOCALITY": "POTTANGI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764040,
      "TXT_PINCODE_LOCALITY": "MACHKUND",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764041,
      "TXT_PINCODE_LOCALITY": "JALPUT",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764042,
      "TXT_PINCODE_LOCALITY": "ONUKADELI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764043,
      "TXT_PINCODE_LOCALITY": "BOIPARIGUDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764044,
      "TXT_PINCODE_LOCALITY": "MATHILI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 4362,
      "City District Name": "MALKANGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764045,
      "TXT_PINCODE_LOCALITY": "MALKANGIRI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 4362,
      "City District Name": "MALKANGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764046,
      "TXT_PINCODE_LOCALITY": "PANCHABATI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764047,
      "TXT_PINCODE_LOCALITY": "KALIMELA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 4362,
      "City District Name": "MALKANGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764048,
      "TXT_PINCODE_LOCALITY": "MALKANGIRI COLONY",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764049,
      "TXT_PINCODE_LOCALITY": "LACHIPETA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 4362,
      "City District Name": "MALKANGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764051,
      "TXT_PINCODE_LOCALITY": "BALIMELA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 4362,
      "City District Name": "MALKANGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764052,
      "TXT_PINCODE_LOCALITY": "CHITRAKONDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 4362,
      "City District Name": "MALKANGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764053,
      "TXT_PINCODE_LOCALITY": "DYKE III",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764054,
      "TXT_PINCODE_LOCALITY": "DYKE I",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764055,
      "TXT_PINCODE_LOCALITY": "AMBAGUDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764056,
      "TXT_PINCODE_LOCALITY": "BARIGUMMA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764057,
      "TXT_PINCODE_LOCALITY": "BHAIRABASINGHPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764058,
      "TXT_PINCODE_LOCALITY": "KHATIGUDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5357,
      "City District Name": "RAYAGADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764059,
      "TXT_PINCODE_LOCALITY": "NOWRANGPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 4644,
      "City District Name": "NABARANGAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764060,
      "TXT_PINCODE_LOCALITY": "BATASANA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764061,
      "TXT_PINCODE_LOCALITY": "KOSAGUMUDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764062,
      "TXT_PINCODE_LOCALITY": "BIKRAMPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764063,
      "TXT_PINCODE_LOCALITY": "MIRGANIGUDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764069,
      "TXT_PINCODE_LOCALITY": "ANCHALGUMMA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 4644,
      "City District Name": "NABARANGAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764070,
      "TXT_PINCODE_LOCALITY": "TENTULIKHUNTI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 4644,
      "City District Name": "NABARANGAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764071,
      "TXT_PINCODE_LOCALITY": "PAPADAHANDI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 4644,
      "City District Name": "NABARANGAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764072,
      "TXT_PINCODE_LOCALITY": "DABUGAON",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 4644,
      "City District Name": "NABARANGAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764073,
      "TXT_PINCODE_LOCALITY": "UMERKOTE BAZAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764074,
      "TXT_PINCODE_LOCALITY": "RAIGHAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 4644,
      "City District Name": "NABARANGAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764075,
      "TXT_PINCODE_LOCALITY": "KODINGA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 4644,
      "City District Name": "NABARANGAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764076,
      "TXT_PINCODE_LOCALITY": "JHARIGAM",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 4644,
      "City District Name": "NABARANGAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764077,
      "TXT_PINCODE_LOCALITY": "CHANDAHANDI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 4644,
      "City District Name": "NABARANGAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764078,
      "TXT_PINCODE_LOCALITY": "DANGANERBHEJA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 4644,
      "City District Name": "NABARANGAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764079,
      "TXT_PINCODE_LOCALITY": "JHARIGAM",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 4644,
      "City District Name": "NABARANGAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764081,
      "TXT_PINCODE_LOCALITY": "LAMPTAPUT",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764082,
      "TXT_PINCODE_LOCALITY": "KORKONDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 4362,
      "City District Name": "MALKANGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764083,
      "TXT_PINCODE_LOCALITY": "CHANDRA SEKHAR PRASAD",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764084,
      "TXT_PINCODE_LOCALITY": "HARDOLI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 4644,
      "City District Name": "NABARANGAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764085,
      "TXT_PINCODE_LOCALITY": "INDRAPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 4644,
      "City District Name": "NABARANGAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764086,
      "TXT_PINCODE_LOCALITY": "METAPOKA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 4362,
      "City District Name": "MALKANGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764087,
      "TXT_PINCODE_LOCALITY": "MOTU",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 4362,
      "City District Name": "MALKANGIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764088,
      "TXT_PINCODE_LOCALITY": "KERADIHI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 442926,
      "City District Name": "KUNDEI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764093,
      "TXT_PINCODE_LOCALITY": "AMPAVALLI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 764977,
      "TXT_PINCODE_LOCALITY": "CHANDAHANDI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 4644,
      "City District Name": "NABARANGAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 765001,
      "TXT_PINCODE_LOCALITY": "RAYAGADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 424446,
      "City District Name": "GAJAPATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 765002,
      "TXT_PINCODE_LOCALITY": "RAYAGADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 765003,
      "TXT_PINCODE_LOCALITY": "UPARATITIJHOLA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5357,
      "City District Name": "RAYAGADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 765012,
      "TXT_PINCODE_LOCALITY": "KOLANARA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5357,
      "City District Name": "RAYAGADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 765013,
      "TXT_PINCODE_LOCALITY": "LAKHMIPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 765014,
      "TXT_PINCODE_LOCALITY": "NARAYANPATNA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 765015,
      "TXT_PINCODE_LOCALITY": "KASHIPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5357,
      "City District Name": "RAYAGADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 765016,
      "TXT_PINCODE_LOCALITY": "KALYANSINGHPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5357,
      "City District Name": "RAYAGADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 765017,
      "TXT_PINCODE_LOCALITY": "RAYAGADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5357,
      "City District Name": "RAYAGADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 765018,
      "TXT_PINCODE_LOCALITY": "THERUBALI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5357,
      "City District Name": "RAYAGADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 765019,
      "TXT_PINCODE_LOCALITY": "BISSAM CUTTACK",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5357,
      "City District Name": "RAYAGADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 765020,
      "TXT_PINCODE_LOCALITY": "MUNIGUDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5357,
      "City District Name": "RAYAGADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 765021,
      "TXT_PINCODE_LOCALITY": "AMBADOLA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 4644,
      "City District Name": "NABARANGAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 765022,
      "TXT_PINCODE_LOCALITY": "GUNUPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5357,
      "City District Name": "RAYAGADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 765023,
      "TXT_PINCODE_LOCALITY": "KUJENDRI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5357,
      "City District Name": "RAYAGADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 765024,
      "TXT_PINCODE_LOCALITY": "UKKAMBA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5357,
      "City District Name": "RAYAGADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 765025,
      "TXT_PINCODE_LOCALITY": "PADMAPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 765026,
      "TXT_PINCODE_LOCALITY": "GUDARI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5357,
      "City District Name": "RAYAGADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 765027,
      "TXT_PINCODE_LOCALITY": "SANMATHUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 442911,
      "City District Name": "BANDHUGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 765028,
      "TXT_PINCODE_LOCALITY": "DASMANTHUPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5357,
      "City District Name": "RAYAGADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 765029,
      "TXT_PINCODE_LOCALITY": "RAMANAGUDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5357,
      "City District Name": "RAYAGADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 765030,
      "TXT_PINCODE_LOCALITY": "KAKIRIGUMMA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 765031,
      "TXT_PINCODE_LOCALITY": "JEMADEIPENTHA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1485,
      "City District Name": "KORAPUT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 765032,
      "TXT_PINCODE_LOCALITY": "NAIRA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5357,
      "City District Name": "RAYAGADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 765033,
      "TXT_PINCODE_LOCALITY": "DAMBASORA S.O",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5357,
      "City District Name": "RAYAGADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 765034,
      "TXT_PINCODE_LOCALITY": "JAGANATHPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5357,
      "City District Name": "RAYAGADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766001,
      "TXT_PINCODE_LOCALITY": "NAKTIGUDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 222,
      "City District Name": "KALAHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766002,
      "TXT_PINCODE_LOCALITY": "COLLECTORATE",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 222,
      "City District Name": "KALAHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766003,
      "TXT_PINCODE_LOCALITY": "BHAWANIPATNA ENGINEERING COLLEGE",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 222,
      "City District Name": "KALAHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766004,
      "TXT_PINCODE_LOCALITY": "AMPANI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 222,
      "City District Name": "KALAHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766011,
      "TXT_PINCODE_LOCALITY": "UTKELA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 222,
      "City District Name": "KALAHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766012,
      "TXT_PINCODE_LOCALITY": "JAGANNATHPARA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 222,
      "City District Name": "KALAHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766013,
      "TXT_PINCODE_LOCALITY": "KALAMPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 222,
      "City District Name": "KALAHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766014,
      "TXT_PINCODE_LOCALITY": "JUNAGARH",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 222,
      "City District Name": "KALAHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766015,
      "TXT_PINCODE_LOCALITY": "DHARAMGARH",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 222,
      "City District Name": "KALAHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766016,
      "TXT_PINCODE_LOCALITY": "DEOBHOG",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 222,
      "City District Name": "KALAHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766017,
      "TXT_PINCODE_LOCALITY": "CHARBAHAL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 222,
      "City District Name": "KALAHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766018,
      "TXT_PINCODE_LOCALITY": "JAIPATNA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 222,
      "City District Name": "KALAHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766019,
      "TXT_PINCODE_LOCALITY": "KOKSARA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 222,
      "City District Name": "KALAHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766020,
      "TXT_PINCODE_LOCALITY": "BISWANATHPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 222,
      "City District Name": "KALAHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766021,
      "TXT_PINCODE_LOCALITY": "PASTIKUDI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 222,
      "City District Name": "KALAHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766022,
      "TXT_PINCODE_LOCALITY": "GOLAMUNDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 222,
      "City District Name": "KALAHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766023,
      "TXT_PINCODE_LOCALITY": "MAHICHALA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 222,
      "City District Name": "KALAHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766024,
      "TXT_PINCODE_LOCALITY": "LADUGAON",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 222,
      "City District Name": "KALAHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766025,
      "TXT_PINCODE_LOCALITY": "KANDEL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 222,
      "City District Name": "KALAHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766026,
      "TXT_PINCODE_LOCALITY": "MUKHIGUDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 222,
      "City District Name": "KALAHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766027,
      "TXT_PINCODE_LOCALITY": "LANJIGARH",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 222,
      "City District Name": "KALAHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766028,
      "TXT_PINCODE_LOCALITY": "AMPANI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 222,
      "City District Name": "KALAHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766029,
      "TXT_PINCODE_LOCALITY": "SOSIA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 222,
      "City District Name": "KALAHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766031,
      "TXT_PINCODE_LOCALITY": "RISIDA S.O",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 222,
      "City District Name": "KALAHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766032,
      "TXT_PINCODE_LOCALITY": "BHOIRPADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 442928,
      "City District Name": "MADANPUR RAMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766036,
      "TXT_PINCODE_LOCALITY": "ARTAL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 222,
      "City District Name": "KALAHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766037,
      "TXT_PINCODE_LOCALITY": "KATTKURA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 222,
      "City District Name": "KALAHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766041,
      "TXT_PINCODE_LOCALITY": "KHANDIDANGRIGUDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 442920,
      "City District Name": "JAYAPATNA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766100,
      "TXT_PINCODE_LOCALITY": "NARLA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 222,
      "City District Name": "KALAHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766101,
      "TXT_PINCODE_LOCALITY": "RUPRA ROAD",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 222,
      "City District Name": "KALAHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766102,
      "TXT_PINCODE_LOCALITY": "MADANPUR RAMPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 222,
      "City District Name": "KALAHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766103,
      "TXT_PINCODE_LOCALITY": "PARLA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 222,
      "City District Name": "KALAHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766104,
      "TXT_PINCODE_LOCALITY": "KHARIAR ROAD TOWN",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 4857,
      "City District Name": "NUAPADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766105,
      "TXT_PINCODE_LOCALITY": "NAWAPARA TANWAT",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 222,
      "City District Name": "KALAHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766106,
      "TXT_PINCODE_LOCALITY": "KOMNA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 4857,
      "City District Name": "NUAPADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766107,
      "TXT_PINCODE_LOCALITY": "KHARIAR PATTNAIKPARA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 222,
      "City District Name": "KALAHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766108,
      "TXT_PINCODE_LOCALITY": "SINHAPALLI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 4857,
      "City District Name": "NUAPADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766109,
      "TXT_PINCODE_LOCALITY": "AMSENA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 4857,
      "City District Name": "NUAPADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766110,
      "TXT_PINCODE_LOCALITY": "NARLA ROAD",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 222,
      "City District Name": "KALAHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766111,
      "TXT_PINCODE_LOCALITY": "BODEN",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 4857,
      "City District Name": "NUAPADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766112,
      "TXT_PINCODE_LOCALITY": "MADANPUR RAMPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 222,
      "City District Name": "KALAHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766113,
      "TXT_PINCODE_LOCALITY": "TUKLA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 4857,
      "City District Name": "NUAPADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766114,
      "TXT_PINCODE_LOCALITY": "LAKHNA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 4857,
      "City District Name": "NUAPADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766115,
      "TXT_PINCODE_LOCALITY": "BEHERA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 222,
      "City District Name": "KALAHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766116,
      "TXT_PINCODE_LOCALITY": "KARLAMUNDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 222,
      "City District Name": "KALAHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766117,
      "TXT_PINCODE_LOCALITY": "BARGAON",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 222,
      "City District Name": "KALAHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766118,
      "TXT_PINCODE_LOCALITY": "DUAJHAT",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 222,
      "City District Name": "KALAHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 766717,
      "TXT_PINCODE_LOCALITY": "BARGAON (BHAWANIPATNA)",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 4857,
      "City District Name": "NUAPADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767001,
      "TXT_PINCODE_LOCALITY": "SUDPARA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 760,
      "City District Name": "BOLANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767002,
      "TXT_PINCODE_LOCALITY": "RAJENDRA COLLEGE (BALAN2)",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2265,
      "City District Name": "BALANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767016,
      "TXT_PINCODE_LOCALITY": "TARDHA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 760,
      "City District Name": "BOLANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767017,
      "TXT_PINCODE_LOCALITY": "GHODAGHAT PARA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 760,
      "City District Name": "BOLANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767018,
      "TXT_PINCODE_LOCALITY": "BIRMAHARAJPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5740,
      "City District Name": "SONEPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767019,
      "TXT_PINCODE_LOCALITY": "BINKA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5740,
      "City District Name": "SONEPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767020,
      "TXT_PINCODE_LOCALITY": "LOISINGA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2265,
      "City District Name": "BALANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767021,
      "TXT_PINCODE_LOCALITY": "SALEBHATA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2265,
      "City District Name": "BALANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767022,
      "TXT_PINCODE_LOCALITY": "AGALPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2265,
      "City District Name": "BALANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767023,
      "TXT_PINCODE_LOCALITY": "DUNGURUPALLI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5740,
      "City District Name": "SONEPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767024,
      "TXT_PINCODE_LOCALITY": "CHURAPALLI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 760,
      "City District Name": "BOLANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767025,
      "TXT_PINCODE_LOCALITY": "PATNAGARH",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2265,
      "City District Name": "BALANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767026,
      "TXT_PINCODE_LOCALITY": "BELPARA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2265,
      "City District Name": "BALANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767027,
      "TXT_PINCODE_LOCALITY": "JOGIMUNDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2265,
      "City District Name": "BALANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767028,
      "TXT_PINCODE_LOCALITY": "KHAPRAKHOL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2265,
      "City District Name": "BALANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767029,
      "TXT_PINCODE_LOCALITY": "DEOGAON",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2265,
      "City District Name": "BALANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767030,
      "TXT_PINCODE_LOCALITY": "TUSRA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2265,
      "City District Name": "BALANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767031,
      "TXT_PINCODE_LOCALITY": "RISIDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2265,
      "City District Name": "BALANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767032,
      "TXT_PINCODE_LOCALITY": "SAINTALA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2265,
      "City District Name": "BALANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767033,
      "TXT_PINCODE_LOCALITY": "TITILAGARH COURT",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 760,
      "City District Name": "BOLANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767034,
      "TXT_PINCODE_LOCALITY": "ULLUNDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5740,
      "City District Name": "SONEPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767035,
      "TXT_PINCODE_LOCALITY": "SINDHEKELA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2265,
      "City District Name": "BALANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767036,
      "TXT_PINCODE_LOCALITY": "BORDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2265,
      "City District Name": "BALANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767037,
      "TXT_PINCODE_LOCALITY": "MUNIBAHAL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2265,
      "City District Name": "BALANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767038,
      "TXT_PINCODE_LOCALITY": "HARISANKAR ROAD",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2265,
      "City District Name": "BALANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767039,
      "TXT_PINCODE_LOCALITY": "KANTABANJI R S",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 760,
      "City District Name": "BOLANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767040,
      "TXT_PINCODE_LOCALITY": "BANGANMURA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2265,
      "City District Name": "BALANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767041,
      "TXT_PINCODE_LOCALITY": "PATNAGARH RAMPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2265,
      "City District Name": "BALANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767042,
      "TXT_PINCODE_LOCALITY": "BHATIPARA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 760,
      "City District Name": "BOLANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767043,
      "TXT_PINCODE_LOCALITY": "TUREKELLA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2265,
      "City District Name": "BALANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767044,
      "TXT_PINCODE_LOCALITY": "MAHARAPADAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2265,
      "City District Name": "BALANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767045,
      "TXT_PINCODE_LOCALITY": "SONEPUR RAMPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2265,
      "City District Name": "BALANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767046,
      "TXT_PINCODE_LOCALITY": "GUDEVELLA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2265,
      "City District Name": "BALANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767047,
      "TXT_PINCODE_LOCALITY": "BELGAON",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2265,
      "City District Name": "BALANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767048,
      "TXT_PINCODE_LOCALITY": "BHAINSA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2265,
      "City District Name": "BALANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767049,
      "TXT_PINCODE_LOCALITY": "ICHHAPARA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2265,
      "City District Name": "BALANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767050,
      "TXT_PINCODE_LOCALITY": "DUMABHATA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2265,
      "City District Name": "BALANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767060,
      "TXT_PINCODE_LOCALITY": "BADBANKI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2265,
      "City District Name": "BALANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767061,
      "TXT_PINCODE_LOCALITY": "DUDUKA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2265,
      "City District Name": "BALANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767062,
      "TXT_PINCODE_LOCALITY": "SABULIA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 760,
      "City District Name": "BOLANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767063,
      "TXT_PINCODE_LOCALITY": "MENDHA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5740,
      "City District Name": "SONEPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767064,
      "TXT_PINCODE_LOCALITY": "SUKHA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5740,
      "City District Name": "SONEPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767065,
      "TXT_PINCODE_LOCALITY": "SINDURBAHAL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 760,
      "City District Name": "BOLANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767066,
      "TXT_PINCODE_LOCALITY": "KHOLAN",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2265,
      "City District Name": "BALANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767067,
      "TXT_PINCODE_LOCALITY": "JARASINGA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2265,
      "City District Name": "BALANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767068,
      "TXT_PINCODE_LOCALITY": "LACHHIPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5740,
      "City District Name": "SONEPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767070,
      "TXT_PINCODE_LOCALITY": "OF BADMAL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2265,
      "City District Name": "BALANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767071,
      "TXT_PINCODE_LOCALITY": "BALANGIR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2265,
      "City District Name": "BALANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 767077,
      "TXT_PINCODE_LOCALITY": "ADABAHAL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 2265,
      "City District Name": "BALANGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768001,
      "TXT_PINCODE_LOCALITY": "JHARUAPARA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768002,
      "TXT_PINCODE_LOCALITY": "MODIPARA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768003,
      "TXT_PINCODE_LOCALITY": "KHETRAJPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768004,
      "TXT_PINCODE_LOCALITY": "AINTHAPALI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768005,
      "TXT_PINCODE_LOCALITY": "SAMBHALPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768006,
      "TXT_PINCODE_LOCALITY": "REMED",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768016,
      "TXT_PINCODE_LOCALITY": "ALIND COLONY HIRAKUD",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768017,
      "TXT_PINCODE_LOCALITY": "MEDICAL COLLEGE BURLA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768018,
      "TXT_PINCODE_LOCALITY": "ENGINEERING COLLEGE",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768019,
      "TXT_PINCODE_LOCALITY": "JYOTI VIHAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768020,
      "TXT_PINCODE_LOCALITY": "BARAIPALI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768025,
      "TXT_PINCODE_LOCALITY": "GOSALA CHOWK",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768026,
      "TXT_PINCODE_LOCALITY": "CHIPLIMA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768027,
      "TXT_PINCODE_LOCALITY": "ATTABIRA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1371,
      "City District Name": "BARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768028,
      "TXT_PINCODE_LOCALITY": "BARAGARH ROAD R S",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768029,
      "TXT_PINCODE_LOCALITY": "BARPALI BAZAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1371,
      "City District Name": "BARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768030,
      "TXT_PINCODE_LOCALITY": "BHATLI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1371,
      "City District Name": "BARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768031,
      "TXT_PINCODE_LOCALITY": "AMBABHONA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1371,
      "City District Name": "BARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768032,
      "TXT_PINCODE_LOCALITY": "BIJAYPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1371,
      "City District Name": "BARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768033,
      "TXT_PINCODE_LOCALITY": "SOHELLA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1371,
      "City District Name": "BARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768034,
      "TXT_PINCODE_LOCALITY": "GHESS",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1371,
      "City District Name": "BARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768035,
      "TXT_PINCODE_LOCALITY": "MELCHHAMUNDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1371,
      "City District Name": "BARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768036,
      "TXT_PINCODE_LOCALITY": "PADAMPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1371,
      "City District Name": "BARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768037,
      "TXT_PINCODE_LOCALITY": "GAISILET",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1371,
      "City District Name": "BARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768038,
      "TXT_PINCODE_LOCALITY": "BARDOL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1371,
      "City District Name": "BARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768039,
      "TXT_PINCODE_LOCALITY": "PAIKAMAL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768040,
      "TXT_PINCODE_LOCALITY": "TORA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1371,
      "City District Name": "BARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768041,
      "TXT_PINCODE_LOCALITY": "DUNGRI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1371,
      "City District Name": "BARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768042,
      "TXT_PINCODE_LOCALITY": "JHARBANDH",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1371,
      "City District Name": "BARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768043,
      "TXT_PINCODE_LOCALITY": "KUMBHARI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1371,
      "City District Name": "BARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768044,
      "TXT_PINCODE_LOCALITY": "KADOBAHAL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1371,
      "City District Name": "BARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768045,
      "TXT_PINCODE_LOCALITY": "BHUKTA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1371,
      "City District Name": "BARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768046,
      "TXT_PINCODE_LOCALITY": "BARAGARH KATAPALI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1371,
      "City District Name": "BARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768047,
      "TXT_PINCODE_LOCALITY": "AGALPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1371,
      "City District Name": "BARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768048,
      "TXT_PINCODE_LOCALITY": "LAUMUNDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1371,
      "City District Name": "BARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768049,
      "TXT_PINCODE_LOCALITY": "JAMLA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1371,
      "City District Name": "BARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768050,
      "TXT_PINCODE_LOCALITY": "MANDOSIL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 4857,
      "City District Name": "NUAPADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768051,
      "TXT_PINCODE_LOCALITY": "JAGADALPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1371,
      "City District Name": "BARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768052,
      "TXT_PINCODE_LOCALITY": "STORE QUARRY",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768053,
      "TXT_PINCODE_LOCALITY": "LAKHANPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1371,
      "City District Name": "BARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768054,
      "TXT_PINCODE_LOCALITY": "DEOGAON",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1371,
      "City District Name": "BARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768100,
      "TXT_PINCODE_LOCALITY": "DHANDUPALI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1371,
      "City District Name": "BARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768101,
      "TXT_PINCODE_LOCALITY": "REMED",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1775,
      "City District Name": "JHARSUGUDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768102,
      "TXT_PINCODE_LOCALITY": "LARAMBHA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1371,
      "City District Name": "BARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768103,
      "TXT_PINCODE_LOCALITY": "REMUNDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1371,
      "City District Name": "BARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768104,
      "TXT_PINCODE_LOCALITY": "BHEDEN",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1371,
      "City District Name": "BARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768105,
      "TXT_PINCODE_LOCALITY": "JUJUMURA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768106,
      "TXT_PINCODE_LOCALITY": "RAIRAKHOLE",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768107,
      "TXT_PINCODE_LOCALITY": "JAMANKIRA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768108,
      "TXT_PINCODE_LOCALITY": "DEOGARH",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 424301,
      "City District Name": "DEOGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768109,
      "TXT_PINCODE_LOCALITY": "REAMAL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 424301,
      "City District Name": "DEOGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768110,
      "TXT_PINCODE_LOCALITY": "BARKOTE",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 424301,
      "City District Name": "DEOGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768111,
      "TXT_PINCODE_LOCALITY": "GODBHAGA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768112,
      "TXT_PINCODE_LOCALITY": "GOURPATI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768113,
      "TXT_PINCODE_LOCALITY": "DHAMA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768114,
      "TXT_PINCODE_LOCALITY": "GOGUA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 424301,
      "City District Name": "DEOGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768115,
      "TXT_PINCODE_LOCALITY": "GONDTURUM",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5740,
      "City District Name": "SONEPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768116,
      "TXT_PINCODE_LOCALITY": "MALATIGUNDERPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768117,
      "TXT_PINCODE_LOCALITY": "CHARMAL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768118,
      "TXT_PINCODE_LOCALITY": "NAKTIDEUL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768119,
      "TXT_PINCODE_LOCALITY": "PURUNAGARH",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768120,
      "TXT_PINCODE_LOCALITY": "MANESWAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768121,
      "TXT_PINCODE_LOCALITY": "GOHIRA DAMSITE",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768130,
      "TXT_PINCODE_LOCALITY": "THEMERA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 442935,
      "City District Name": "SADAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768150,
      "TXT_PINCODE_LOCALITY": "BARAIPALI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1371,
      "City District Name": "BARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768151,
      "TXT_PINCODE_LOCALITY": "R R I GARMUNDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768200,
      "TXT_PINCODE_LOCALITY": "PARMANPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768201,
      "TXT_PINCODE_LOCALITY": "JHARSUGUDA H O",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1775,
      "City District Name": "JHARSUGUDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768202,
      "TXT_PINCODE_LOCALITY": "ARDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1371,
      "City District Name": "BARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768203,
      "TXT_PINCODE_LOCALITY": "KALIMANDIR ROAD (JHARSU-3",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1775,
      "City District Name": "JHARSUGUDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768204,
      "TXT_PINCODE_LOCALITY": "O M P LINE (JHARSUGUDA-4)",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1775,
      "City District Name": "JHARSUGUDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768205,
      "TXT_PINCODE_LOCALITY": "SAMBALPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768210,
      "TXT_PINCODE_LOCALITY": "RENGALI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 425802,
      "City District Name": "SAMABALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768211,
      "TXT_PINCODE_LOCALITY": "BANDBAHAL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1775,
      "City District Name": "JHARSUGUDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768212,
      "TXT_PINCODE_LOCALITY": "RENGALI (SASON)",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768213,
      "TXT_PINCODE_LOCALITY": "KOLABIRA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1775,
      "City District Name": "JHARSUGUDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768214,
      "TXT_PINCODE_LOCALITY": "LOIDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768215,
      "TXT_PINCODE_LOCALITY": "LAIKERA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1775,
      "City District Name": "JHARSUGUDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768216,
      "TXT_PINCODE_LOCALITY": "STN ROAD BRAJARAJ NAGAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768217,
      "TXT_PINCODE_LOCALITY": "BELPAHAR R S",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1775,
      "City District Name": "JHARSUGUDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768218,
      "TXT_PINCODE_LOCALITY": "BELPAHAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1775,
      "City District Name": "JHARSUGUDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768219,
      "TXT_PINCODE_LOCALITY": "AINLAPADA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768220,
      "TXT_PINCODE_LOCALITY": "BAGDEHI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1775,
      "City District Name": "JHARSUGUDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768221,
      "TXT_PINCODE_LOCALITY": "BAMRA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768222,
      "TXT_PINCODE_LOCALITY": "KUCHINDA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768223,
      "TXT_PINCODE_LOCALITY": "BHOJPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768224,
      "TXT_PINCODE_LOCALITY": "GARPOSH",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768225,
      "TXT_PINCODE_LOCALITY": "RAMPUR COLLIERY",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1775,
      "City District Name": "JHARSUGUDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768226,
      "TXT_PINCODE_LOCALITY": "PANCHAGAON",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1775,
      "City District Name": "JHARSUGUDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768227,
      "TXT_PINCODE_LOCALITY": "KUSUMI (SAMBALPUR)",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768228,
      "TXT_PINCODE_LOCALITY": "KESIABAHAL",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768229,
      "TXT_PINCODE_LOCALITY": "RAJPUR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1775,
      "City District Name": "JHARSUGUDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768230,
      "TXT_PINCODE_LOCALITY": "LAMPTIBAHAL P.O",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1775,
      "City District Name": "JHARSUGUDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768231,
      "TXT_PINCODE_LOCALITY": "MAHULPALLI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1371,
      "City District Name": "BARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768232,
      "TXT_PINCODE_LOCALITY": "LAPANGA",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 592,
      "City District Name": "SAMBALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768233,
      "TXT_PINCODE_LOCALITY": "ORIENT COLLIERY BRAJARAJNAGAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1775,
      "City District Name": "JHARSUGUDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 768234,
      "TXT_PINCODE_LOCALITY": "BANHARPALI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1775,
      "City District Name": "JHARSUGUDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 769001,
      "TXT_PINCODE_LOCALITY": "ROURKELA H O",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 769002,
      "TXT_PINCODE_LOCALITY": "SECTOR-6",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 769003,
      "TXT_PINCODE_LOCALITY": "SECTOR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 219,
      "City District Name": "ROURKELA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 769004,
      "TXT_PINCODE_LOCALITY": "INDUSTRIAL ESTATE",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 769005,
      "TXT_PINCODE_LOCALITY": "SECTOR XIX",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 219,
      "City District Name": "ROURKELA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 769006,
      "TXT_PINCODE_LOCALITY": "SECTOR-2",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 769007,
      "TXT_PINCODE_LOCALITY": "ROURKELA-7",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 769008,
      "TXT_PINCODE_LOCALITY": "ROURKELA-8",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 769009,
      "TXT_PINCODE_LOCALITY": "ROURKELA-9",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 769010,
      "TXT_PINCODE_LOCALITY": "O M P LINE (ROURKELA-10)",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 769011,
      "TXT_PINCODE_LOCALITY": "ROURKELA - 11",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 441048,
      "City District Name": "SUNDERGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 769012,
      "TXT_PINCODE_LOCALITY": "UDITNAGAR",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 769013,
      "TXT_PINCODE_LOCALITY": "RAILWAY COLONY (ROURKELA1",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 769014,
      "TXT_PINCODE_LOCALITY": "SAKTINAGAR (ROURKELA-14)",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 769015,
      "TXT_PINCODE_LOCALITY": "CHHEND COLONY",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 219,
      "City District Name": "ROURKELA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 769016,
      "TXT_PINCODE_LOCALITY": "SONARPARBAT",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 769017,
      "TXT_PINCODE_LOCALITY": "KHALIAPALI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 1371,
      "City District Name": "BARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 769041,
      "TXT_PINCODE_LOCALITY": "VEDVYAS",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 769042,
      "TXT_PINCODE_LOCALITY": "JHIRPANI",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 769043,
      "TXT_PINCODE_LOCALITY": "JALDA - 1915",
      "NUM_STATE_CD": 132,
      "State Name": "ORISSA",
      "NUM_CITYDISTRICT_CD": 5793,
      "City District Name": "SUNDARGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 971001,
      "TXT_PINCODE_LOCALITY": "BOKARO",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 736,
      "City District Name": "BOKARO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 971002,
      "TXT_PINCODE_LOCALITY": "CHATRA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 2721,
      "City District Name": "CHATRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 971003,
      "TXT_PINCODE_LOCALITY": "DEOGHAR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 2919,
      "City District Name": "DEOGHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 971004,
      "TXT_PINCODE_LOCALITY": "DHANBAD",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 737,
      "City District Name": "DHANBAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 971006,
      "TXT_PINCODE_LOCALITY": "GARHWA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3205,
      "City District Name": "GARHWA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 971007,
      "TXT_PINCODE_LOCALITY": "GIRIDIH",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3255,
      "City District Name": "GIRIDIH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 971008,
      "TXT_PINCODE_LOCALITY": "GODDA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3266,
      "City District Name": "GODDA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 971009,
      "TXT_PINCODE_LOCALITY": "GUMLA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 3324,
      "City District Name": "GUMLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 971012,
      "TXT_PINCODE_LOCALITY": "LOHARDAGA",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 4256,
      "City District Name": "LOHARDAGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 971013,
      "TXT_PINCODE_LOCALITY": "PAKAUR",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 4924,
      "City District Name": "PAKAUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 971015,
      "TXT_PINCODE_LOCALITY": "RANCHI",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 740,
      "City District Name": "RANCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 971016,
      "TXT_PINCODE_LOCALITY": "SAHIBGANJ",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 5438,
      "City District Name": "SAHIBGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 971017,
      "TXT_PINCODE_LOCALITY": "SINGHBHUM - EAST",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 971018,
      "TXT_PINCODE_LOCALITY": "SINGHBHUM - WEST",
      "NUM_STATE_CD": 138,
      "State Name": "JHARKHAND",
      "NUM_CITYDISTRICT_CD": 1273,
      "City District Name": "SINGHBHUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 666213,
      "TXT_PINCODE_LOCALITY": "PALLAGACHERY",
      "NUM_STATE_CD": 58,
      "State Name": "TAMIL NADU",
      "NUM_CITYDISTRICT_CD": 6084,
      "City District Name": "UTHAMAPALAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 425522,
      "TXT_PINCODE_LOCALITY": "JALGAON",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 525,
      "City District Name": "JALGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 872,
      "TXT_PINCODE_LOCALITY": "WARSAW",
      "NUM_STATE_CD": 834,
      "State Name": "WARSAW",
      "NUM_CITYDISTRICT_CD": 442262,
      "City District Name": "WARSAW",
      "NUM_COUNTRY_CD": 160
    },
    {
      "NUM_PINCODE": 2052,
      "TXT_PINCODE_LOCALITY": "UNSW SYDNEY",
      "NUM_STATE_CD": 316,
      "State Name": "NEW SOUTH WALES",
      "NUM_CITYDISTRICT_CD": 441070,
      "City District Name": "SYDNEY",
      "NUM_COUNTRY_CD": 145
    },
    {
      "NUM_PINCODE": 16421,
      "TXT_PINCODE_LOCALITY": "KISTA",
      "NUM_STATE_CD": 832,
      "State Name": "KISTA",
      "NUM_CITYDISTRICT_CD": 442260,
      "City District Name": "KISTA",
      "NUM_COUNTRY_CD": 138
    },
    {
      "NUM_PINCODE": 44600,
      "TXT_PINCODE_LOCALITY": "KATHMANDU",
      "NUM_STATE_CD": 256,
      "State Name": "KATHMANDU",
      "NUM_CITYDISTRICT_CD": 440919,
      "City District Name": "KATHMANDU",
      "NUM_COUNTRY_CD": 124
    },
    {
      "NUM_PINCODE": 75008,
      "TXT_PINCODE_LOCALITY": "PARIS",
      "NUM_STATE_CD": 831,
      "State Name": "PARIS",
      "NUM_CITYDISTRICT_CD": 442259,
      "City District Name": "PARIS",
      "NUM_COUNTRY_CD": 147
    },
    {
      "NUM_PINCODE": 416217,
      "TXT_PINCODE_LOCALITY": "DHANGAR GALI",
      "NUM_STATE_CD": 55,
      "State Name": "MAHARASHTRA",
      "NUM_CITYDISTRICT_CD": 148,
      "City District Name": "KOLHAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 580001,
      "TXT_PINCODE_LOCALITY": "DHARWAD H O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 580002,
      "TXT_PINCODE_LOCALITY": "DHARWAD S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 580003,
      "TXT_PINCODE_LOCALITY": "DHARWAD K V V",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 580004,
      "TXT_PINCODE_LOCALITY": "DHARWAD VIDYAGIRI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 580005,
      "TXT_PINCODE_LOCALITY": "DHARWAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 580006,
      "TXT_PINCODE_LOCALITY": "MRUTYANJAYANAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 580007,
      "TXT_PINCODE_LOCALITY": "KELGERI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 580008,
      "TXT_PINCODE_LOCALITY": "DHARWAD K C PARK",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 580009,
      "TXT_PINCODE_LOCALITY": "NAVALUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 580010,
      "TXT_PINCODE_LOCALITY": "SATTUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 580020,
      "TXT_PINCODE_LOCALITY": "GIRIRAJ ANNEX",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 580021,
      "TXT_PINCODE_LOCALITY": "HUBLI KMC S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 580022,
      "TXT_PINCODE_LOCALITY": "MEDICAL COLLEGE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 580023,
      "TXT_PINCODE_LOCALITY": "KOLIWAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 580024,
      "TXT_PINCODE_LOCALITY": "OLD HUBLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 580025,
      "TXT_PINCODE_LOCALITY": "HUBLI NAVANAGAR S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 580028,
      "TXT_PINCODE_LOCALITY": "HUBLI MAHAVEERAGALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 580029,
      "TXT_PINCODE_LOCALITY": "HUBLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 580030,
      "TXT_PINCODE_LOCALITY": "HUBLI UDYAM NAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 580031,
      "TXT_PINCODE_LOCALITY": "HUBLI ENGG COLLEGE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 580032,
      "TXT_PINCODE_LOCALITY": "HUBLI VIJAYANAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 580110,
      "TXT_PINCODE_LOCALITY": "KELGERI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 580112,
      "TXT_PINCODE_LOCALITY": "MORAB",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 580113,
      "TXT_PINCODE_LOCALITY": "NARENDRA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 580114,
      "TXT_PINCODE_LOCALITY": "DHUMWAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 580115,
      "TXT_PINCODE_LOCALITY": "MANGUNDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 580117,
      "TXT_PINCODE_LOCALITY": "HEBLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 580118,
      "TXT_PINCODE_LOCALITY": "HULKOPPA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 580211,
      "TXT_PINCODE_LOCALITY": "HALLIHAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 580212,
      "TXT_PINCODE_LOCALITY": "ADARGUNCHI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 580213,
      "TXT_PINCODE_LOCALITY": "DHARWAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 580214,
      "TXT_PINCODE_LOCALITY": "NULVI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 580215,
      "TXT_PINCODE_LOCALITY": "YARGUPPI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 580216,
      "TXT_PINCODE_LOCALITY": "BADAMI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2199,
      "City District Name": "BADAMI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581102,
      "TXT_PINCODE_LOCALITY": "HAVERI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581103,
      "TXT_PINCODE_LOCALITY": "ALNAVAR S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581104,
      "TXT_PINCODE_LOCALITY": "HANGAL P. O.",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581105,
      "TXT_PINCODE_LOCALITY": "GARAG",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581106,
      "TXT_PINCODE_LOCALITY": "HAVERI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581107,
      "TXT_PINCODE_LOCALITY": "DHARWAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581108,
      "TXT_PINCODE_LOCALITY": "GUTTAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581109,
      "TXT_PINCODE_LOCALITY": "HAVERI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581110,
      "TXT_PINCODE_LOCALITY": "HAVERI BAZAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581111,
      "TXT_PINCODE_LOCALITY": "HIREKERUR S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581113,
      "TXT_PINCODE_LOCALITY": "KUNDARGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581115,
      "TXT_PINCODE_LOCALITY": "RANEBENNUR H O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581117,
      "TXT_PINCODE_LOCALITY": "SAUNSHI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581118,
      "TXT_PINCODE_LOCALITY": "SAVANUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581119,
      "TXT_PINCODE_LOCALITY": "TUMMINKATTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581120,
      "TXT_PINCODE_LOCALITY": "TILVALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581121,
      "TXT_PINCODE_LOCALITY": "CASTLE ROCK",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581122,
      "TXT_PINCODE_LOCALITY": "SUPA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581123,
      "TXT_PINCODE_LOCALITY": "KUMARAPATNAM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581124,
      "TXT_PINCODE_LOCALITY": "BYAHATTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581125,
      "TXT_PINCODE_LOCALITY": "CHIKKERUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581126,
      "TXT_PINCODE_LOCALITY": "HULGUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581127,
      "TXT_PINCODE_LOCALITY": "BELGALPETH",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581128,
      "TXT_PINCODE_LOCALITY": "AGADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581129,
      "TXT_PINCODE_LOCALITY": "JAGABET",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581130,
      "TXT_PINCODE_LOCALITY": "KOD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581131,
      "TXT_PINCODE_LOCALITY": "DEVARGUDDA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581132,
      "TXT_PINCODE_LOCALITY": "DEVAGIRI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581133,
      "TXT_PINCODE_LOCALITY": "HAVANUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581134,
      "TXT_PINCODE_LOCALITY": "KABBUR (DHARWAD)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581135,
      "TXT_PINCODE_LOCALITY": "HANUMANMATTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581136,
      "TXT_PINCODE_LOCALITY": "MOTEBENNUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581137,
      "TXT_PINCODE_LOCALITY": "YELGACH",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581138,
      "TXT_PINCODE_LOCALITY": "SHESHANGERI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581140,
      "TXT_PINCODE_LOCALITY": "KUSUGAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581141,
      "TXT_PINCODE_LOCALITY": "HONNATTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581142,
      "TXT_PINCODE_LOCALITY": "ITAGI RBN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581143,
      "TXT_PINCODE_LOCALITY": "KARUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581144,
      "TXT_PINCODE_LOCALITY": "KUPPELUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581145,
      "TXT_PINCODE_LOCALITY": "CHALGERI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581146,
      "TXT_PINCODE_LOCALITY": "KADUR (DHARWAD)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581147,
      "TXT_PINCODE_LOCALITY": "KUDUPALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581148,
      "TXT_PINCODE_LOCALITY": "SANGUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581149,
      "TXT_PINCODE_LOCALITY": "KUSNUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581150,
      "TXT_PINCODE_LOCALITY": "ARALESHWAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581151,
      "TXT_PINCODE_LOCALITY": "ALADKATTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581152,
      "TXT_PINCODE_LOCALITY": "DEVI HOSUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581153,
      "TXT_PINCODE_LOCALITY": "KAGINELLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581155,
      "TXT_PINCODE_LOCALITY": "NEGALUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581156,
      "TXT_PINCODE_LOCALITY": "KADARMANDALGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581157,
      "TXT_PINCODE_LOCALITY": "SAIDENUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581158,
      "TXT_PINCODE_LOCALITY": "SHIRGUPPI (DWD)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581159,
      "TXT_PINCODE_LOCALITY": "HATTIMATTUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581160,
      "TXT_PINCODE_LOCALITY": "YALIVIGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581161,
      "TXT_PINCODE_LOCALITY": "KALAS",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581162,
      "TXT_PINCODE_LOCALITY": "YEREBUDIHAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581163,
      "TXT_PINCODE_LOCALITY": "MAKNOOR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581164,
      "TXT_PINCODE_LOCALITY": "KARADGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581165,
      "TXT_PINCODE_LOCALITY": "NAREGAL (DWD)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581166,
      "TXT_PINCODE_LOCALITY": "KARGUDRI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581167,
      "TXT_PINCODE_LOCALITY": "KOPPARSHIKOPPA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581168,
      "TXT_PINCODE_LOCALITY": "HIDIGONA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581169,
      "TXT_PINCODE_LOCALITY": "CHINMULGUND",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581170,
      "TXT_PINCODE_LOCALITY": "MALLUR (DWD)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581171,
      "TXT_PINCODE_LOCALITY": "ATTIGERE (DHARWAD)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581172,
      "TXT_PINCODE_LOCALITY": "KANAVALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581173,
      "TXT_PINCODE_LOCALITY": "KATENHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581174,
      "TXT_PINCODE_LOCALITY": "KADKOL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581175,
      "TXT_PINCODE_LOCALITY": "TADKOD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581176,
      "TXT_PINCODE_LOCALITY": "GONDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581177,
      "TXT_PINCODE_LOCALITY": "ASUNDI (DHARWAD)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581178,
      "TXT_PINCODE_LOCALITY": "KAKOL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581179,
      "TXT_PINCODE_LOCALITY": "KAJJARI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581180,
      "TXT_PINCODE_LOCALITY": "KAMDOLLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581181,
      "TXT_PINCODE_LOCALITY": "HURLIKUPI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581182,
      "TXT_PINCODE_LOCALITY": "KALLIHAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581183,
      "TXT_PINCODE_LOCALITY": "IJARILAKHAMAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581184,
      "TXT_PINCODE_LOCALITY": "BORI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581185,
      "TXT_PINCODE_LOCALITY": "MARUKERI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581186,
      "TXT_PINCODE_LOCALITY": "JOIDA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581187,
      "TXT_PINCODE_LOCALITY": "KUMBARWADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581188,
      "TXT_PINCODE_LOCALITY": "BASANAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581189,
      "TXT_PINCODE_LOCALITY": "KANCHINEGALUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581190,
      "TXT_PINCODE_LOCALITY": "MAKARI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581191,
      "TXT_PINCODE_LOCALITY": "HIREHARKUNI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581192,
      "TXT_PINCODE_LOCALITY": "SHIRGOD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581193,
      "TXT_PINCODE_LOCALITY": "DHUNDSI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581201,
      "TXT_PINCODE_LOCALITY": "AMINBHAVI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581202,
      "TXT_PINCODE_LOCALITY": "BANKAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581204,
      "TXT_PINCODE_LOCALITY": "MISHRIKOTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581205,
      "TXT_PINCODE_LOCALITY": "SHIGGAON",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581206,
      "TXT_PINCODE_LOCALITY": "UPPINBETGERI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581207,
      "TXT_PINCODE_LOCALITY": "ARALIKATTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581208,
      "TXT_PINCODE_LOCALITY": "HALGERI (DWD)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581209,
      "TXT_PINCODE_LOCALITY": "HEBSUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581210,
      "TXT_PINCODE_LOCALITY": "MASUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581211,
      "TXT_PINCODE_LOCALITY": "MEDLERI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581212,
      "TXT_PINCODE_LOCALITY": "TADAS",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581213,
      "TXT_PINCODE_LOCALITY": "HOSARITTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581215,
      "TXT_PINCODE_LOCALITY": "HAROBELVADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581216,
      "TXT_PINCODE_LOCALITY": "KARADIGUDDA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581217,
      "TXT_PINCODE_LOCALITY": "MEDUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581219,
      "TXT_PINCODE_LOCALITY": "YELIWAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581220,
      "TXT_PINCODE_LOCALITY": "ALABUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581221,
      "TXT_PINCODE_LOCALITY": "BENAKANKONDA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581222,
      "TXT_PINCODE_LOCALITY": "SUNKALBIDRI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581223,
      "TXT_PINCODE_LOCALITY": "BAMMIGATTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581224,
      "TXT_PINCODE_LOCALITY": "DEVIKOPPA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581225,
      "TXT_PINCODE_LOCALITY": "HIREHONNIHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581227,
      "TXT_PINCODE_LOCALITY": "BAICHABALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581228,
      "TXT_PINCODE_LOCALITY": "INGALGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581229,
      "TXT_PINCODE_LOCALITY": "AREMALLAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581230,
      "TXT_PINCODE_LOCALITY": "GANJIGATTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581231,
      "TXT_PINCODE_LOCALITY": "INGALHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581232,
      "TXT_PINCODE_LOCALITY": "DHARWAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581233,
      "TXT_PINCODE_LOCALITY": "CHABBI (HUBLI)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581234,
      "TXT_PINCODE_LOCALITY": "WARURI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581236,
      "TXT_PINCODE_LOCALITY": "GUDDADHULIKATTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581301,
      "TXT_PINCODE_LOCALITY": "KARWAR H O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 441051,
      "City District Name": "UTTARA KANNADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581302,
      "TXT_PINCODE_LOCALITY": "BAITKHOL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581304,
      "TXT_PINCODE_LOCALITY": "NANDANGADDA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581305,
      "TXT_PINCODE_LOCALITY": "KATHINKON",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581306,
      "TXT_PINCODE_LOCALITY": "HABBUVADA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581307,
      "TXT_PINCODE_LOCALITY": "BINAGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 441051,
      "City District Name": "UTTARA KANNADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581314,
      "TXT_PINCODE_LOCALITY": "ANKOLA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581315,
      "TXT_PINCODE_LOCALITY": "HAGARIR S",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581316,
      "TXT_PINCODE_LOCALITY": "AVERSA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581317,
      "TXT_PINCODE_LOCALITY": "ASNOTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581318,
      "TXT_PINCODE_LOCALITY": "KEMMANNUGUNDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581319,
      "TXT_PINCODE_LOCALITY": "BANKIKODLA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581320,
      "TXT_PINCODE_LOCALITY": "MALLANDUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581322,
      "TXT_PINCODE_LOCALITY": "BILGI (SIRSI)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581323,
      "TXT_PINCODE_LOCALITY": "MANTUR (DWD)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581324,
      "TXT_PINCODE_LOCALITY": "CHENDIGE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581325,
      "TXT_PINCODE_LOCALITY": "DANDELI PAPER MILLS",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 441051,
      "City District Name": "UTTARA KANNADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581326,
      "TXT_PINCODE_LOCALITY": "AKKI ALUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581327,
      "TXT_PINCODE_LOCALITY": "HALDIPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581328,
      "TXT_PINCODE_LOCALITY": "KARWAR ROAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581329,
      "TXT_PINCODE_LOCALITY": "HANGAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581330,
      "TXT_PINCODE_LOCALITY": "HEGDE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581331,
      "TXT_PINCODE_LOCALITY": "HEGGARNI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581332,
      "TXT_PINCODE_LOCALITY": "LAKKUNDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581334,
      "TXT_PINCODE_LOCALITY": "MUGBE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 441051,
      "City District Name": "UTTARA KANNADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581335,
      "TXT_PINCODE_LOCALITY": "HOSAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581336,
      "TXT_PINCODE_LOCALITY": "KALASAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581337,
      "TXT_PINCODE_LOCALITY": "HIREKERUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581338,
      "TXT_PINCODE_LOCALITY": "KODTOKA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581339,
      "TXT_PINCODE_LOCALITY": "VASANA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581340,
      "TXT_PINCODE_LOCALITY": "KANSUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581341,
      "TXT_PINCODE_LOCALITY": "PAVINKURVE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 441051,
      "City District Name": "UTTARA KANNADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581342,
      "TXT_PINCODE_LOCALITY": "KASAROD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581343,
      "TXT_PINCODE_LOCALITY": "KUMTA H O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581344,
      "TXT_PINCODE_LOCALITY": "MADANGERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581345,
      "TXT_PINCODE_LOCALITY": "MAJALI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581346,
      "TXT_PINCODE_LOCALITY": "MALGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581347,
      "TXT_PINCODE_LOCALITY": "MANCHIKERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581348,
      "TXT_PINCODE_LOCALITY": "MANKI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581349,
      "TXT_PINCODE_LOCALITY": "BACHANKI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 441051,
      "City District Name": "UTTARA KANNADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581350,
      "TXT_PINCODE_LOCALITY": "MURDESHWAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581351,
      "TXT_PINCODE_LOCALITY": "LAKKAVALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581352,
      "TXT_PINCODE_LOCALITY": "SADASHIVGAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581353,
      "TXT_PINCODE_LOCALITY": "SHETGERI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581354,
      "TXT_PINCODE_LOCALITY": "SHIRALI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581355,
      "TXT_PINCODE_LOCALITY": "SIDDAPUR (UK)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581356,
      "TXT_PINCODE_LOCALITY": "HALIYAL S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 441051,
      "City District Name": "UTTARA KANNADA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581357,
      "TXT_PINCODE_LOCALITY": "VANDIGE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581358,
      "TXT_PINCODE_LOCALITY": "SINGATAGERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1387,
      "City District Name": "CHIKMAGALUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581359,
      "TXT_PINCODE_LOCALITY": "MASKAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 340,
      "City District Name": "CHITRADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581360,
      "TXT_PINCODE_LOCALITY": "ANGADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581362,
      "TXT_PINCODE_LOCALITY": "HARIHAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581363,
      "TXT_PINCODE_LOCALITY": "AMBIKA NAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581365,
      "TXT_PINCODE_LOCALITY": "GANESHGUDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581367,
      "TXT_PINCODE_LOCALITY": "KANGAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581368,
      "TXT_PINCODE_LOCALITY": "SALGOL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581369,
      "TXT_PINCODE_LOCALITY": "BENGRE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581370,
      "TXT_PINCODE_LOCALITY": "MUNDALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581371,
      "TXT_PINCODE_LOCALITY": "KALBAG",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581372,
      "TXT_PINCODE_LOCALITY": "MAGOD COLONY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581373,
      "TXT_PINCODE_LOCALITY": "BHAGAVATHI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581374,
      "TXT_PINCODE_LOCALITY": "GANDHI NAGAR KUMTA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581375,
      "TXT_PINCODE_LOCALITY": "PAVINKURVE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581376,
      "TXT_PINCODE_LOCALITY": "HALGERI (SIRSI)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581377,
      "TXT_PINCODE_LOCALITY": "MUDGERI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581378,
      "TXT_PINCODE_LOCALITY": "BHASKERI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581379,
      "TXT_PINCODE_LOCALITY": "YADAGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581380,
      "TXT_PINCODE_LOCALITY": "ANGOD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581381,
      "TXT_PINCODE_LOCALITY": "BACHANKI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581382,
      "TXT_PINCODE_LOCALITY": "MANGALVAAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581383,
      "TXT_PINCODE_LOCALITY": "AMBEWADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581384,
      "TXT_PINCODE_LOCALITY": "GERSOPPA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581387,
      "TXT_PINCODE_LOCALITY": "KANGOD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581388,
      "TXT_PINCODE_LOCALITY": "MUTTOLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581389,
      "TXT_PINCODE_LOCALITY": "AJJIBAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581390,
      "TXT_PINCODE_LOCALITY": "VANDUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581391,
      "TXT_PINCODE_LOCALITY": "HADINBAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581392,
      "TXT_PINCODE_LOCALITY": "AGHANSASHINI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581393,
      "TXT_PINCODE_LOCALITY": "NAGRE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581394,
      "TXT_PINCODE_LOCALITY": "VALGALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581395,
      "TXT_PINCODE_LOCALITY": "HONAVAR PORT",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581396,
      "TXT_PINCODE_LOCALITY": "KADRA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581400,
      "TXT_PINCODE_LOCALITY": "KAIGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581401,
      "TXT_PINCODE_LOCALITY": "SIRSI H O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581402,
      "TXT_PINCODE_LOCALITY": "SIRSI MARKET",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581403,
      "TXT_PINCODE_LOCALITY": "NILEKANI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581405,
      "TXT_PINCODE_LOCALITY": "BHAIRUMBE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581406,
      "TXT_PINCODE_LOCALITY": "TARGOD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581411,
      "TXT_PINCODE_LOCALITY": "TIBETIAN COLONY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581412,
      "TXT_PINCODE_LOCALITY": "KIRWATTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581413,
      "TXT_PINCODE_LOCALITY": "AGSUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581414,
      "TXT_PINCODE_LOCALITY": "BELAMBAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581416,
      "TXT_PINCODE_LOCALITY": "AMBALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581417,
      "TXT_PINCODE_LOCALITY": "KINNAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581418,
      "TXT_PINCODE_LOCALITY": "BHAVIKERI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581419,
      "TXT_PINCODE_LOCALITY": "ALVEKODI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581420,
      "TXT_PINCODE_LOCALITY": "HEBLE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581421,
      "TXT_PINCODE_LOCALITY": "KAIKINI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581422,
      "TXT_PINCODE_LOCALITY": "BAILUR (KARWAR)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581423,
      "TXT_PINCODE_LOCALITY": "MELINIDGUNJI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581424,
      "TXT_PINCODE_LOCALITY": "KARVA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581425,
      "TXT_PINCODE_LOCALITY": "NAVILAGAON",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581426,
      "TXT_PINCODE_LOCALITY": "KUNDARGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581427,
      "TXT_PINCODE_LOCALITY": "BISGOD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581428,
      "TXT_PINCODE_LOCALITY": "ISLOOR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581429,
      "TXT_PINCODE_LOCALITY": "DASANKOP",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581430,
      "TXT_PINCODE_LOCALITY": "AREANGADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581431,
      "TXT_PINCODE_LOCALITY": "BALKUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581432,
      "TXT_PINCODE_LOCALITY": "MAVINKURVE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581434,
      "TXT_PINCODE_LOCALITY": "HOSAKULI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581435,
      "TXT_PINCODE_LOCALITY": "GULLAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581436,
      "TXT_PINCODE_LOCALITY": "VAJRALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581437,
      "TXT_PINCODE_LOCALITY": "TADRI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581439,
      "TXT_PINCODE_LOCALITY": "KODKANI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581440,
      "TXT_PINCODE_LOCALITY": "SIRGERI (HARIHAR)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581441,
      "TXT_PINCODE_LOCALITY": "KARWAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 862,
      "City District Name": "KARWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581442,
      "TXT_PINCODE_LOCALITY": "DHARESHWAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581443,
      "TXT_PINCODE_LOCALITY": "KAGAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581444,
      "TXT_PINCODE_LOCALITY": "KATGAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581445,
      "TXT_PINCODE_LOCALITY": "MARTUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581446,
      "TXT_PINCODE_LOCALITY": "SUGAVI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581447,
      "TXT_PINCODE_LOCALITY": "SONDA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581449,
      "TXT_PINCODE_LOCALITY": "INDOOR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581450,
      "TXT_PINCODE_LOCALITY": "NEGGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581451,
      "TXT_PINCODE_LOCALITY": "BARGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 581452,
      "TXT_PINCODE_LOCALITY": "HEGDEKOTE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582101,
      "TXT_PINCODE_LOCALITY": "GADAG H O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582102,
      "TXT_PINCODE_LOCALITY": "BETGERI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582111,
      "TXT_PINCODE_LOCALITY": "ABBIGERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582112,
      "TXT_PINCODE_LOCALITY": "BELLATTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582113,
      "TXT_PINCODE_LOCALITY": "DAMBAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582114,
      "TXT_PINCODE_LOCALITY": "GAJENDRAGAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582115,
      "TXT_PINCODE_LOCALITY": "LAKKUNDI S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582117,
      "TXT_PINCODE_LOCALITY": "MULGUND",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582118,
      "TXT_PINCODE_LOCALITY": "MUNDARGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582119,
      "TXT_PINCODE_LOCALITY": "NAREGAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582120,
      "TXT_PINCODE_LOCALITY": "SHIRAHATTI (GDG)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582121,
      "TXT_PINCODE_LOCALITY": "DONI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582122,
      "TXT_PINCODE_LOCALITY": "BELAVANKI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582123,
      "TXT_PINCODE_LOCALITY": "GADAG",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582124,
      "TXT_PINCODE_LOCALITY": "NAGAVI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582125,
      "TXT_PINCODE_LOCALITY": "NEELGUND",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582126,
      "TXT_PINCODE_LOCALITY": "HARIAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582127,
      "TXT_PINCODE_LOCALITY": "JAKKALI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582129,
      "TXT_PINCODE_LOCALITY": "KOTUMANCHAGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582131,
      "TXT_PINCODE_LOCALITY": "KANAGINHAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582132,
      "TXT_PINCODE_LOCALITY": "MARASANBASRI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582135,
      "TXT_PINCODE_LOCALITY": "TIRLAPUR (GADAG)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582136,
      "TXT_PINCODE_LOCALITY": "BANNIKOPPA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582137,
      "TXT_PINCODE_LOCALITY": "HEBBAL (GADAG)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582138,
      "TXT_PINCODE_LOCALITY": "ITAGI GADAG",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582139,
      "TXT_PINCODE_LOCALITY": "SHIRHATTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 5630,
      "City District Name": "SHIRHATTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582140,
      "TXT_PINCODE_LOCALITY": "CHINCHLI (GADAG)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582141,
      "TXT_PINCODE_LOCALITY": "KALKERI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582143,
      "TXT_PINCODE_LOCALITY": "MAGADI (GADAG)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582144,
      "TXT_PINCODE_LOCALITY": "YALVATTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582145,
      "TXT_PINCODE_LOCALITY": "KOGNUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582146,
      "TXT_PINCODE_LOCALITY": "VADAVI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582158,
      "TXT_PINCODE_LOCALITY": "BOMMANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582201,
      "TXT_PINCODE_LOCALITY": "ANNIGERI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582203,
      "TXT_PINCODE_LOCALITY": "HOLEALUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582204,
      "TXT_PINCODE_LOCALITY": "HOMBAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582205,
      "TXT_PINCODE_LOCALITY": "HULKOTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582206,
      "TXT_PINCODE_LOCALITY": "KONNUR (GADAG)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582207,
      "TXT_PINCODE_LOCALITY": "NAGRUND H O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582208,
      "TXT_PINCODE_LOCALITY": "NAVALGUND",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582209,
      "TXT_PINCODE_LOCALITY": "RON",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582210,
      "TXT_PINCODE_LOCALITY": "SHIGLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582211,
      "TXT_PINCODE_LOCALITY": "SUDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582212,
      "TXT_PINCODE_LOCALITY": "SHIROL (GDG)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582213,
      "TXT_PINCODE_LOCALITY": "SORTUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582214,
      "TXT_PINCODE_LOCALITY": "KURTHKOTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582215,
      "TXT_PINCODE_LOCALITY": "SURANGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582216,
      "TXT_PINCODE_LOCALITY": "YAVGAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582217,
      "TXT_PINCODE_LOCALITY": "HIREHAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582218,
      "TXT_PINCODE_LOCALITY": "MALLAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 862,
      "City District Name": "KARWAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582219,
      "TXT_PINCODE_LOCALITY": "SAVADI (GADAG)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582220,
      "TXT_PINCODE_LOCALITY": "HULLUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582221,
      "TXT_PINCODE_LOCALITY": "TIRLAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582222,
      "TXT_PINCODE_LOCALITY": "HIREWADDATTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582224,
      "TXT_PINCODE_LOCALITY": "BALEHOSUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582225,
      "TXT_PINCODE_LOCALITY": "BHADRAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582226,
      "TXT_PINCODE_LOCALITY": "KOLIWAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582227,
      "TXT_PINCODE_LOCALITY": "HALLIKERI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582228,
      "TXT_PINCODE_LOCALITY": "HADLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582229,
      "TXT_PINCODE_LOCALITY": "KOUJAGERI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582230,
      "TXT_PINCODE_LOCALITY": "RAJUR (NARGUND)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582231,
      "TXT_PINCODE_LOCALITY": "BALAGANUR (GADAG)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 894,
      "City District Name": "HAVERI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582232,
      "TXT_PINCODE_LOCALITY": "MENASGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582233,
      "TXT_PINCODE_LOCALITY": "KODIKOPPA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582234,
      "TXT_PINCODE_LOCALITY": "BHAIRANHATTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582236,
      "TXT_PINCODE_LOCALITY": "JIGALUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582237,
      "TXT_PINCODE_LOCALITY": "ALAGAWADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582238,
      "TXT_PINCODE_LOCALITY": "SHALAVADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582239,
      "TXT_PINCODE_LOCALITY": "ASUTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582240,
      "TXT_PINCODE_LOCALITY": "CHIKMANNUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582241,
      "TXT_PINCODE_LOCALITY": "ITAGI NARGUND",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582243,
      "TXT_PINCODE_LOCALITY": "JAKNUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 582351,
      "TXT_PINCODE_LOCALITY": "AGHANASHINI *",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424457,
      "City District Name": "UTTAR KANNAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583101,
      "TXT_PINCODE_LOCALITY": "BELLARY JAIN CLOTH MARKET",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583102,
      "TXT_PINCODE_LOCALITY": "TEMPLE STREET",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583103,
      "TXT_PINCODE_LOCALITY": "BELLARY NEHRU COLONY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583104,
      "TXT_PINCODE_LOCALITY": "BELLARY CONTONMENT",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583105,
      "TXT_PINCODE_LOCALITY": "BELLARY JNANASAGARA S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583111,
      "TXT_PINCODE_LOCALITY": "CHALLAKURKI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583112,
      "TXT_PINCODE_LOCALITY": "DEOGERI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583113,
      "TXT_PINCODE_LOCALITY": "EMMIGANUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583114,
      "TXT_PINCODE_LOCALITY": "HATCHOLLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583115,
      "TXT_PINCODE_LOCALITY": "KUDITHINI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583116,
      "TXT_PINCODE_LOCALITY": "KURUGODU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583117,
      "TXT_PINCODE_LOCALITY": "MOKA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583118,
      "TXT_PINCODE_LOCALITY": "DONIOMALAI TOWNSHIP",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583119,
      "TXT_PINCODE_LOCALITY": "LAKSHMI PURAM SANDUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583120,
      "TXT_PINCODE_LOCALITY": "SIRIGERI (BELLARY)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583121,
      "TXT_PINCODE_LOCALITY": "SIRUGUPPA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583122,
      "TXT_PINCODE_LOCALITY": "TEKKALAKOTA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583123,
      "TXT_PINCODE_LOCALITY": "TORNAGALLU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583124,
      "TXT_PINCODE_LOCALITY": "YESHWANT NAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583125,
      "TXT_PINCODE_LOCALITY": "ARASIKERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583126,
      "TXT_PINCODE_LOCALITY": "CHIKJOGIHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583127,
      "TXT_PINCODE_LOCALITY": "CHIGATERI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583128,
      "TXT_PINCODE_LOCALITY": "CHORANUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583129,
      "TXT_PINCODE_LOCALITY": "DEVALAPURA (BELLARY)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583130,
      "TXT_PINCODE_LOCALITY": "GUDDEKOTE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583131,
      "TXT_PINCODE_LOCALITY": "HARAPANAHALLI JOSHI STREE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 744,
      "City District Name": "DAVANGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583132,
      "TXT_PINCODE_LOCALITY": "KAMPLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583133,
      "TXT_PINCODE_LOCALITY": "KAMPLI SUGAR FACTORY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583134,
      "TXT_PINCODE_LOCALITY": "KOTTURU TEMPLE STREET",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583135,
      "TXT_PINCODE_LOCALITY": "KULDIGI BAZAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583136,
      "TXT_PINCODE_LOCALITY": "OOJEIN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583137,
      "TXT_PINCODE_LOCALITY": "TELGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583138,
      "TXT_PINCODE_LOCALITY": "HOSPET",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 345,
      "City District Name": "HOSPET",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583139,
      "TXT_PINCODE_LOCALITY": "KARUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583140,
      "TXT_PINCODE_LOCALITY": "DESNUR SUGAR FACTORY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583141,
      "TXT_PINCODE_LOCALITY": "BHUJANGA NAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583142,
      "TXT_PINCODE_LOCALITY": "BHAIRADEVANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583143,
      "TXT_PINCODE_LOCALITY": "KUREKUPPA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583144,
      "TXT_PINCODE_LOCALITY": "BUKKASAGARA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583145,
      "TXT_PINCODE_LOCALITY": "HIREMYAGALAGERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583146,
      "TXT_PINCODE_LOCALITY": "UCHANGIDURGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583147,
      "TXT_PINCODE_LOCALITY": "RAMASSAGARA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583148,
      "TXT_PINCODE_LOCALITY": "BAGALI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583149,
      "TXT_PINCODE_LOCALITY": "SIDDAMMANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583150,
      "TXT_PINCODE_LOCALITY": "JOSHI STREET",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583151,
      "TXT_PINCODE_LOCALITY": "NEW DAROJI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583201,
      "TXT_PINCODE_LOCALITY": "HOSPET RANIPET",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583202,
      "TXT_PINCODE_LOCALITY": "HOSPET",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 345,
      "City District Name": "HOSPET",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583203,
      "TXT_PINCODE_LOCALITY": "HOSPET NEHRU CO-OP COLLEGE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583211,
      "TXT_PINCODE_LOCALITY": "CHITWADGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583212,
      "TXT_PINCODE_LOCALITY": "HAGRI BOMMANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583213,
      "TXT_PINCODE_LOCALITY": "HALAVAGALU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2903,
      "City District Name": "DAVANAGERE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583214,
      "TXT_PINCODE_LOCALITY": "HAMPASAGARA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583215,
      "TXT_PINCODE_LOCALITY": "HAMPI POWER HOUSE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583216,
      "TXT_PINCODE_LOCALITY": "HIREHADAGALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583217,
      "TXT_PINCODE_LOCALITY": "HOLALU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583218,
      "TXT_PINCODE_LOCALITY": "HOSAHALLI (BELLARY)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583219,
      "TXT_PINCODE_LOCALITY": "BELLARY S. O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583220,
      "TXT_PINCODE_LOCALITY": "ITTIGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583221,
      "TXT_PINCODE_LOCALITY": "KAMALAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583222,
      "TXT_PINCODE_LOCALITY": "MARIAMMANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583223,
      "TXT_PINCODE_LOCALITY": "PAPINAYAKANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583225,
      "TXT_PINCODE_LOCALITY": "TUNGABHADRA DAM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583226,
      "TXT_PINCODE_LOCALITY": "ALWANDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583227,
      "TXT_PINCODE_LOCALITY": "GANGAVTI JULYNAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583228,
      "TXT_PINCODE_LOCALITY": "GINIGERA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583229,
      "TXT_PINCODE_LOCALITY": "KARATGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583230,
      "TXT_PINCODE_LOCALITY": "KINHAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583231,
      "TXT_PINCODE_LOCALITY": "KOPPAL H O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583232,
      "TXT_PINCODE_LOCALITY": "KUKNOOR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583233,
      "TXT_PINCODE_LOCALITY": "MUNIRABAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583234,
      "TXT_PINCODE_LOCALITY": "MUNIRABAD R S",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583235,
      "TXT_PINCODE_LOCALITY": "WADDARHATTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583237,
      "TXT_PINCODE_LOCALITY": "BEWOOR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583238,
      "TXT_PINCODE_LOCALITY": "KOPPAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583239,
      "TXT_PINCODE_LOCALITY": "HAMPI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583240,
      "TXT_PINCODE_LOCALITY": "HAMPAPATNA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583241,
      "TXT_PINCODE_LOCALITY": "HOLAGUNDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583242,
      "TXT_PINCODE_LOCALITY": "MALVI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583243,
      "TXT_PINCODE_LOCALITY": "MOVIGERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583244,
      "TXT_PINCODE_LOCALITY": "SOGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583245,
      "TXT_PINCODE_LOCALITY": "GUDIGANUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583246,
      "TXT_PINCODE_LOCALITY": "ANEGUNDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583247,
      "TXT_PINCODE_LOCALITY": "HEROOR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583249,
      "TXT_PINCODE_LOCALITY": "BHANAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583250,
      "TXT_PINCODE_LOCALITY": "BHAGYA NAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583251,
      "TXT_PINCODE_LOCALITY": "HALGERA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583252,
      "TXT_PINCODE_LOCALITY": "BASAPATTAN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583253,
      "TXT_PINCODE_LOCALITY": "BETGERA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583254,
      "TXT_PINCODE_LOCALITY": "KAWLOOR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583255,
      "TXT_PINCODE_LOCALITY": "HITNAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583256,
      "TXT_PINCODE_LOCALITY": "TADKAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583257,
      "TXT_PINCODE_LOCALITY": "MANGALORE KOPPAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583258,
      "TXT_PINCODE_LOCALITY": "RAJOOR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583259,
      "TXT_PINCODE_LOCALITY": "MUDHOL (KOPPAL)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583260,
      "TXT_PINCODE_LOCALITY": "AGALKERA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583261,
      "TXT_PINCODE_LOCALITY": "BISARAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583262,
      "TXT_PINCODE_LOCALITY": "HIREMYAGERI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583263,
      "TXT_PINCODE_LOCALITY": "HIRESINDOGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583264,
      "TXT_PINCODE_LOCALITY": "HOSAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583265,
      "TXT_PINCODE_LOCALITY": "RAICHUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583266,
      "TXT_PINCODE_LOCALITY": "NOWLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583267,
      "TXT_PINCODE_LOCALITY": "BELLARY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583269,
      "TXT_PINCODE_LOCALITY": "DASMPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583270,
      "TXT_PINCODE_LOCALITY": "GANGAWATI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1370,
      "City District Name": "GANGAWATHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583271,
      "TXT_PINCODE_LOCALITY": "CHICKJANTAKAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583272,
      "TXT_PINCODE_LOCALITY": "HEBBAL (BELLARY)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583273,
      "TXT_PINCODE_LOCALITY": "YERDONA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583274,
      "TXT_PINCODE_LOCALITY": "NANVAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 583275,
      "TXT_PINCODE_LOCALITY": "VIDYANAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 336,
      "City District Name": "BELLARY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584101,
      "TXT_PINCODE_LOCALITY": "RAICHUR HO",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584102,
      "TXT_PINCODE_LOCALITY": "RAICHUR MUKKARAMGANJ",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584110,
      "TXT_PINCODE_LOCALITY": "RAICHUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584111,
      "TXT_PINCODE_LOCALITY": "ARKERA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584112,
      "TXT_PINCODE_LOCALITY": "DOTIHAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584113,
      "TXT_PINCODE_LOCALITY": "GABBUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584114,
      "TXT_PINCODE_LOCALITY": "HANAMSAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584115,
      "TXT_PINCODE_LOCALITY": "SIDDAPUR (KOPPAL)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584116,
      "TXT_PINCODE_LOCALITY": "GALAG",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584117,
      "TXT_PINCODE_LOCALITY": "JAWALGERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584118,
      "TXT_PINCODE_LOCALITY": "KALLUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584119,
      "TXT_PINCODE_LOCALITY": "KANAKAGIRI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584120,
      "TXT_PINCODE_LOCALITY": "BAGALWAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584121,
      "TXT_PINCODE_LOCALITY": "KOPPAL S. O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584122,
      "TXT_PINCODE_LOCALITY": "LINGASUGUR KUTCHERY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584123,
      "TXT_PINCODE_LOCALITY": "LINGASUGUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584124,
      "TXT_PINCODE_LOCALITY": "MANVI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584125,
      "TXT_PINCODE_LOCALITY": "MASKI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584126,
      "TXT_PINCODE_LOCALITY": "MUDGAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584127,
      "TXT_PINCODE_LOCALITY": "GOREBAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584128,
      "TXT_PINCODE_LOCALITY": "SINDHNUR CAMP",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584129,
      "TXT_PINCODE_LOCALITY": "SIRWAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584131,
      "TXT_PINCODE_LOCALITY": "KOPPAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584132,
      "TXT_PINCODE_LOCALITY": "TURVIHAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584133,
      "TXT_PINCODE_LOCALITY": "YERGIRA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584134,
      "TXT_PINCODE_LOCALITY": "YERMARAS",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584135,
      "TXT_PINCODE_LOCALITY": "YERMARAS CAMP",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584136,
      "TXT_PINCODE_LOCALITY": "KALMALA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584138,
      "TXT_PINCODE_LOCALITY": "BELAGANUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584139,
      "TXT_PINCODE_LOCALITY": "GURGUNTA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584140,
      "TXT_PINCODE_LOCALITY": "GUNJAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584141,
      "TXT_PINCODE_LOCALITY": "LINGSUGUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 4249,
      "City District Name": "LINGSUGUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584142,
      "TXT_PINCODE_LOCALITY": "BELGAUM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584143,
      "TXT_PINCODE_LOCALITY": "JAVALGERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584144,
      "TXT_PINCODE_LOCALITY": "RABBANKAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584145,
      "TXT_PINCODE_LOCALITY": "KOPPURU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584146,
      "TXT_PINCODE_LOCALITY": "KOPPAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584147,
      "TXT_PINCODE_LOCALITY": "MANVI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 4436,
      "City District Name": "MANVI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584148,
      "TXT_PINCODE_LOCALITY": "RAMDURGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584149,
      "TXT_PINCODE_LOCALITY": "SIDDAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584150,
      "TXT_PINCODE_LOCALITY": "YERDONA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584151,
      "TXT_PINCODE_LOCALITY": "ANE HOSUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584153,
      "TXT_PINCODE_LOCALITY": "DEVADURGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2939,
      "City District Name": "DEVADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584154,
      "TXT_PINCODE_LOCALITY": "MANVI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 4436,
      "City District Name": "MANVI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584155,
      "TXT_PINCODE_LOCALITY": "RAICHUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584156,
      "TXT_PINCODE_LOCALITY": "DEVADURGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2939,
      "City District Name": "DEVADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584157,
      "TXT_PINCODE_LOCALITY": "HIREMANNAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584158,
      "TXT_PINCODE_LOCALITY": "HULAGERA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584159,
      "TXT_PINCODE_LOCALITY": "HOSAKERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584161,
      "TXT_PINCODE_LOCALITY": "KASBALINGSUGUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584162,
      "TXT_PINCODE_LOCALITY": "MADIGERA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584164,
      "TXT_PINCODE_LOCALITY": "RODKONDA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584165,
      "TXT_PINCODE_LOCALITY": "SINDHNUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 5677,
      "City District Name": "SINDHNUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584166,
      "TXT_PINCODE_LOCALITY": "HANUMANHAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584167,
      "TXT_PINCODE_LOCALITY": "SALGUNDA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584169,
      "TXT_PINCODE_LOCALITY": "HULYHYDER",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 887,
      "City District Name": "KOPPAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584170,
      "TXT_PINCODE_LOCALITY": "SHAKTINAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584201,
      "TXT_PINCODE_LOCALITY": "RAICHUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584202,
      "TXT_PINCODE_LOCALITY": "MATMARI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 584203,
      "TXT_PINCODE_LOCALITY": "KURDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585101,
      "TXT_PINCODE_LOCALITY": "GULBARGA SHAHABAZAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585102,
      "TXT_PINCODE_LOCALITY": "GULBARG J R COLLEGE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585103,
      "TXT_PINCODE_LOCALITY": "BUS STAND",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585104,
      "TXT_PINCODE_LOCALITY": "SHIVAJINAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585105,
      "TXT_PINCODE_LOCALITY": "GULBARGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585106,
      "TXT_PINCODE_LOCALITY": "KALABURAGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 424322,
      "City District Name": "KALAMUNDHUNA DODDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585109,
      "TXT_PINCODE_LOCALITY": "GENERAL HOSPITAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585151,
      "TXT_PINCODE_LOCALITY": "HARSUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585152,
      "TXT_PINCODE_LOCALITY": "SAVALGI (GULBARGA)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585153,
      "TXT_PINCODE_LOCALITY": "GULBARGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585154,
      "TXT_PINCODE_LOCALITY": "HIRAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585201,
      "TXT_PINCODE_LOCALITY": "YADGIRI TOWN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585202,
      "TXT_PINCODE_LOCALITY": "YADGIRI STATION S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 6255,
      "City District Name": "YADGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585210,
      "TXT_PINCODE_LOCALITY": "KURKUNTA CEMENT FACTORY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585211,
      "TXT_PINCODE_LOCALITY": "CHITTAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585212,
      "TXT_PINCODE_LOCALITY": "GANGAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585213,
      "TXT_PINCODE_LOCALITY": "STATION GANAGAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585214,
      "TXT_PINCODE_LOCALITY": "CHANDRAKI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 6255,
      "City District Name": "YADGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585215,
      "TXT_PINCODE_LOCALITY": "SHORAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 425892,
      "City District Name": "YADAGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585216,
      "TXT_PINCODE_LOCALITY": "YADGIR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 425892,
      "City District Name": "YADAGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585217,
      "TXT_PINCODE_LOCALITY": "MASHAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585218,
      "TXT_PINCODE_LOCALITY": "NALWAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585219,
      "TXT_PINCODE_LOCALITY": "NARAYANAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585220,
      "TXT_PINCODE_LOCALITY": "SHORAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 6255,
      "City District Name": "YADGIR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585221,
      "TXT_PINCODE_LOCALITY": "SAIDAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585222,
      "TXT_PINCODE_LOCALITY": "SEDAM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585223,
      "TXT_PINCODE_LOCALITY": "SHAHAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585224,
      "TXT_PINCODE_LOCALITY": "SHORAPUR H O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585225,
      "TXT_PINCODE_LOCALITY": "WADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585226,
      "TXT_PINCODE_LOCALITY": "BIDAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585227,
      "TXT_PINCODE_LOCALITY": "HONGUNTA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585228,
      "TXT_PINCODE_LOCALITY": "SHAHABAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585229,
      "TXT_PINCODE_LOCALITY": "SHAHABAD A C C",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585237,
      "TXT_PINCODE_LOCALITY": "KODEKAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585238,
      "TXT_PINCODE_LOCALITY": "GULBARGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585239,
      "TXT_PINCODE_LOCALITY": "BHAGODI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585240,
      "TXT_PINCODE_LOCALITY": "DEGAON",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585241,
      "TXT_PINCODE_LOCALITY": "MARGOL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585242,
      "TXT_PINCODE_LOCALITY": "BHAIRAMADGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585243,
      "TXT_PINCODE_LOCALITY": "NILOOR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585244,
      "TXT_PINCODE_LOCALITY": "NIMBARGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585245,
      "TXT_PINCODE_LOCALITY": "KARAJGI (GULBARGA)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585246,
      "TXT_PINCODE_LOCALITY": "AFZALPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1939,
      "City District Name": "AFZALPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585247,
      "TXT_PINCODE_LOCALITY": "KOLLUR (YADGERI)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585248,
      "TXT_PINCODE_LOCALITY": "YEGOL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585249,
      "TXT_PINCODE_LOCALITY": "KADACHUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585250,
      "TXT_PINCODE_LOCALITY": "GULBARGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585251,
      "TXT_PINCODE_LOCALITY": "SEDAM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 5569,
      "City District Name": "SEDAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585252,
      "TXT_PINCODE_LOCALITY": "DORNAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585253,
      "TXT_PINCODE_LOCALITY": "RUKMAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585254,
      "TXT_PINCODE_LOCALITY": "GULBARGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585255,
      "TXT_PINCODE_LOCALITY": "HALKATTA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585256,
      "TXT_PINCODE_LOCALITY": "BEVOOR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585257,
      "TXT_PINCODE_LOCALITY": "MANHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585258,
      "TXT_PINCODE_LOCALITY": "SIRSI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585260,
      "TXT_PINCODE_LOCALITY": "BAGADA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585261,
      "TXT_PINCODE_LOCALITY": "BEMALKHEDA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585262,
      "TXT_PINCODE_LOCALITY": "MORKONDA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585263,
      "TXT_PINCODE_LOCALITY": "NIRNA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585264,
      "TXT_PINCODE_LOCALITY": "REKULGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585265,
      "TXT_PINCODE_LOCALITY": "BANKUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585266,
      "TXT_PINCODE_LOCALITY": "HIRENANDUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585267,
      "TXT_PINCODE_LOCALITY": "MARTUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585268,
      "TXT_PINCODE_LOCALITY": "BHOOSNOORU",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585269,
      "TXT_PINCODE_LOCALITY": "HATTIKUNJ",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585270,
      "TXT_PINCODE_LOCALITY": "MUDNAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585271,
      "TXT_PINCODE_LOCALITY": "YELASINGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585272,
      "TXT_PINCODE_LOCALITY": "BHANKUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585273,
      "TXT_PINCODE_LOCALITY": "HALLISAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585274,
      "TXT_PINCODE_LOCALITY": "MADIYAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585275,
      "TXT_PINCODE_LOCALITY": "BILICHARKAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585276,
      "TXT_PINCODE_LOCALITY": "CHANDARKI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585277,
      "TXT_PINCODE_LOCALITY": "HONGUNTA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585278,
      "TXT_PINCODE_LOCALITY": "UDACHAN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585279,
      "TXT_PINCODE_LOCALITY": "PUTPAK",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585280,
      "TXT_PINCODE_LOCALITY": "ITKAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585281,
      "TXT_PINCODE_LOCALITY": "BHEEMANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585282,
      "TXT_PINCODE_LOCALITY": "MADANHIPPARGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585283,
      "TXT_PINCODE_LOCALITY": "YALGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585284,
      "TXT_PINCODE_LOCALITY": "GAJARKOT",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585285,
      "TXT_PINCODE_LOCALITY": "KAKKERA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585286,
      "TXT_PINCODE_LOCALITY": "SRINIVASA SARADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585287,
      "TXT_PINCODE_LOCALITY": "BHEMMARAYANGUDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585288,
      "TXT_PINCODE_LOCALITY": "HIREMUDNUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585289,
      "TXT_PINCODE_LOCALITY": "SRINIVAS SARADGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585290,
      "TXT_PINCODE_LOCALITY": "KUMBARPETH",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585291,
      "TXT_PINCODE_LOCALITY": "RAJANKOLLUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585292,
      "TXT_PINCODE_LOCALITY": "MALKHED ROAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585301,
      "TXT_PINCODE_LOCALITY": "AFZALPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585302,
      "TXT_PINCODE_LOCALITY": "ALUND",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585303,
      "TXT_PINCODE_LOCALITY": "ANDOLA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585304,
      "TXT_PINCODE_LOCALITY": "BIJASPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585305,
      "TXT_PINCODE_LOCALITY": "CHANDAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585306,
      "TXT_PINCODE_LOCALITY": "CHIMMANCHODE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585307,
      "TXT_PINCODE_LOCALITY": "CHINCHOLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585308,
      "TXT_PINCODE_LOCALITY": "FARHATABAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585309,
      "TXT_PINCODE_LOCALITY": "GOGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585310,
      "TXT_PINCODE_LOCALITY": "JEWARGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585311,
      "TXT_PINCODE_LOCALITY": "KADGUNCHI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585312,
      "TXT_PINCODE_LOCALITY": "KALGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585313,
      "TXT_PINCODE_LOCALITY": "KAMALAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585314,
      "TXT_PINCODE_LOCALITY": "KHAJURI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585315,
      "TXT_PINCODE_LOCALITY": "MADHWAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585316,
      "TXT_PINCODE_LOCALITY": "MAHAGAON",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585317,
      "TXT_PINCODE_LOCALITY": "MALKHED",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585318,
      "TXT_PINCODE_LOCALITY": "MUDHOL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585319,
      "TXT_PINCODE_LOCALITY": "NAIKAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585320,
      "TXT_PINCODE_LOCALITY": "NIDAGUNDA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585321,
      "TXT_PINCODE_LOCALITY": "RAMASAMUDRA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585322,
      "TXT_PINCODE_LOCALITY": "RATKAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585323,
      "TXT_PINCODE_LOCALITY": "SAGAR (GULBARGE)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585324,
      "TXT_PINCODE_LOCALITY": "SULAHPET",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585325,
      "TXT_PINCODE_LOCALITY": "YEDRAMI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585326,
      "TXT_PINCODE_LOCALITY": "AURD BARAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585327,
      "TXT_PINCODE_LOCALITY": "BASAVAKALYANA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585328,
      "TXT_PINCODE_LOCALITY": "BHALKI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585329,
      "TXT_PINCODE_LOCALITY": "HOODGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585330,
      "TXT_PINCODE_LOCALITY": "DHANNURA B.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585331,
      "TXT_PINCODE_LOCALITY": "RAJESHWAR S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585332,
      "TXT_PINCODE_LOCALITY": "ATNOOR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585333,
      "TXT_PINCODE_LOCALITY": "REVOOR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585334,
      "TXT_PINCODE_LOCALITY": "KORALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585335,
      "TXT_PINCODE_LOCALITY": "GANWAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585336,
      "TXT_PINCODE_LOCALITY": "ENOLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585337,
      "TXT_PINCODE_LOCALITY": "FIROZABAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585338,
      "TXT_PINCODE_LOCALITY": "KIRNI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585339,
      "TXT_PINCODE_LOCALITY": "NILOGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585340,
      "TXT_PINCODE_LOCALITY": "DHUTTARGAON",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585341,
      "TXT_PINCODE_LOCALITY": "NARAYANPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585342,
      "TXT_PINCODE_LOCALITY": "NORONA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585343,
      "TXT_PINCODE_LOCALITY": "TADKAL (GULBARGA)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585344,
      "TXT_PINCODE_LOCALITY": "DANDOTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585345,
      "TXT_PINCODE_LOCALITY": "TENGLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585346,
      "TXT_PINCODE_LOCALITY": "BELURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585347,
      "TXT_PINCODE_LOCALITY": "MUCHALAM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585348,
      "TXT_PINCODE_LOCALITY": "NARAYANPUR (BIDAR)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585349,
      "TXT_PINCODE_LOCALITY": "PARTAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585350,
      "TXT_PINCODE_LOCALITY": "SASTAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585351,
      "TXT_PINCODE_LOCALITY": "RANJOLKHENI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585352,
      "TXT_PINCODE_LOCALITY": "HALLIKHED (K)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585353,
      "TXT_PINCODE_LOCALITY": "BIDAR S. O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585354,
      "TXT_PINCODE_LOCALITY": "RAJOLA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585355,
      "TXT_PINCODE_LOCALITY": "CHENNUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585356,
      "TXT_PINCODE_LOCALITY": "KODLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585357,
      "TXT_PINCODE_LOCALITY": "CHINCHANSUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585358,
      "TXT_PINCODE_LOCALITY": "BADDAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585359,
      "TXT_PINCODE_LOCALITY": "SONTH",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585360,
      "TXT_PINCODE_LOCALITY": "HIPPARG",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585361,
      "TXT_PINCODE_LOCALITY": "SARSAMBA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585362,
      "TXT_PINCODE_LOCALITY": "MANDEWAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585363,
      "TXT_PINCODE_LOCALITY": "PADASVALI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585364,
      "TXT_PINCODE_LOCALITY": "MUDHOL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585365,
      "TXT_PINCODE_LOCALITY": "KOLKUNDA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585366,
      "TXT_PINCODE_LOCALITY": "AJJERI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585368,
      "TXT_PINCODE_LOCALITY": "WADGERA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585369,
      "TXT_PINCODE_LOCALITY": "MALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585370,
      "TXT_PINCODE_LOCALITY": "YELHAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585371,
      "TXT_PINCODE_LOCALITY": "YEVOOR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585372,
      "TXT_PINCODE_LOCALITY": "NAGNUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585373,
      "TXT_PINCODE_LOCALITY": "HIRALI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585374,
      "TXT_PINCODE_LOCALITY": "SALGERA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585375,
      "TXT_PINCODE_LOCALITY": "KONKAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585377,
      "TXT_PINCODE_LOCALITY": "CHANDANKERA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585378,
      "TXT_PINCODE_LOCALITY": "PATTAN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585401,
      "TXT_PINCODE_LOCALITY": "BIDAR SHAHGANJ",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585402,
      "TXT_PINCODE_LOCALITY": "ANDUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585403,
      "TXT_PINCODE_LOCALITY": "GANDHI GANJ",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585411,
      "TXT_PINCODE_LOCALITY": "BHATAMBRA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585413,
      "TXT_PINCODE_LOCALITY": "AHMAEDABAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585414,
      "TXT_PINCODE_LOCALITY": "HALLIKHED",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585415,
      "TXT_PINCODE_LOCALITY": "HALLIKHED S F",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585416,
      "TXT_PINCODE_LOCALITY": "HULSUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585417,
      "TXT_PINCODE_LOCALITY": "KAMALNAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585418,
      "TXT_PINCODE_LOCALITY": "KARIMABAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585419,
      "TXT_PINCODE_LOCALITY": "MATALA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585421,
      "TXT_PINCODE_LOCALITY": "SANTPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585422,
      "TXT_PINCODE_LOCALITY": "ANDURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585423,
      "TXT_PINCODE_LOCALITY": "JANWADA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585424,
      "TXT_PINCODE_LOCALITY": "KODAMBAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585425,
      "TXT_PINCODE_LOCALITY": "MUSTARI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585427,
      "TXT_PINCODE_LOCALITY": "KANJI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585428,
      "TXT_PINCODE_LOCALITY": "NITTUR (BIDAR)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585429,
      "TXT_PINCODE_LOCALITY": "KATACHINCHOLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585430,
      "TXT_PINCODE_LOCALITY": "MEHKAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585431,
      "TXT_PINCODE_LOCALITY": "SAIGAON",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585432,
      "TXT_PINCODE_LOCALITY": "HOLASAMUNDER",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585433,
      "TXT_PINCODE_LOCALITY": "HULSURKHED",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585434,
      "TXT_PINCODE_LOCALITY": "GHATBORAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585435,
      "TXT_PINCODE_LOCALITY": "BIDAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585436,
      "TXT_PINCODE_LOCALITY": "THANAKUSHNOOR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585437,
      "TXT_PINCODE_LOCALITY": "MUDBI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585439,
      "TXT_PINCODE_LOCALITY": "MURKI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585440,
      "TXT_PINCODE_LOCALITY": "TORNA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585441,
      "TXT_PINCODE_LOCALITY": "HALSITUGAON",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585442,
      "TXT_PINCODE_LOCALITY": "LAKHANGAON",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585443,
      "TXT_PINCODE_LOCALITY": "MUDHOL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 585480,
      "TXT_PINCODE_LOCALITY": "MEHKAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 338,
      "City District Name": "BIDAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586101,
      "TXT_PINCODE_LOCALITY": "BIJAPUR KUTCHERY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586102,
      "TXT_PINCODE_LOCALITY": "SAINIK SCHOOL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586103,
      "TXT_PINCODE_LOCALITY": "BIJAPUR VIJAYA COLLEGE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586105,
      "TXT_PINCODE_LOCALITY": "BIJAPUR MAMADPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586106,
      "TXT_PINCODE_LOCALITY": "BIJAPUR TORVI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586107,
      "TXT_PINCODE_LOCALITY": "BIJAPUR DARGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586108,
      "TXT_PINCODE_LOCALITY": "MINCHANAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586111,
      "TXT_PINCODE_LOCALITY": "AGARKHED S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586112,
      "TXT_PINCODE_LOCALITY": "ATHARGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586113,
      "TXT_PINCODE_LOCALITY": "BABLESHWAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586114,
      "TXT_PINCODE_LOCALITY": "BIJJARGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586115,
      "TXT_PINCODE_LOCALITY": "VIJAYAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 6201,
      "City District Name": "VIJAYAPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586116,
      "TXT_PINCODE_LOCALITY": "VIJAYAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 6201,
      "City District Name": "VIJAYAPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586117,
      "TXT_PINCODE_LOCALITY": "HORTI S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586118,
      "TXT_PINCODE_LOCALITY": "KALKERI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586119,
      "TXT_PINCODE_LOCALITY": "KANNUR (BIJAPUR)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586120,
      "TXT_PINCODE_LOCALITY": "VIJAYAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 6201,
      "City District Name": "VIJAYAPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586121,
      "TXT_PINCODE_LOCALITY": "KUDGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586122,
      "TXT_PINCODE_LOCALITY": "MANGOLI S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586123,
      "TXT_PINCODE_LOCALITY": "VIJAYAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 6201,
      "City District Name": "VIJAYAPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586124,
      "TXT_PINCODE_LOCALITY": "VIJAYAPURA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 6201,
      "City District Name": "VIJAYAPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586125,
      "TXT_PINCODE_LOCALITY": "SARWAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586126,
      "TXT_PINCODE_LOCALITY": "GOTHE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586127,
      "TXT_PINCODE_LOCALITY": "SHIVANGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586128,
      "TXT_PINCODE_LOCALITY": "SINDGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586129,
      "TXT_PINCODE_LOCALITY": "TANGADGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586130,
      "TXT_PINCODE_LOCALITY": "TIKOTA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586131,
      "TXT_PINCODE_LOCALITY": "ARJUNGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586132,
      "TXT_PINCODE_LOCALITY": "KAKHANDHI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586133,
      "TXT_PINCODE_LOCALITY": "NIDONI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586134,
      "TXT_PINCODE_LOCALITY": "BHALKI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2462,
      "City District Name": "BHALKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586135,
      "TXT_PINCODE_LOCALITY": "ARKERI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586136,
      "TXT_PINCODE_LOCALITY": "SIDDAPURA (BJP)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586137,
      "TXT_PINCODE_LOCALITY": "BIJAPURINDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586138,
      "TXT_PINCODE_LOCALITY": "KANMADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586139,
      "TXT_PINCODE_LOCALITY": "BASARKOD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586140,
      "TXT_PINCODE_LOCALITY": "JAMKHANDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 3533,
      "City District Name": "JAMKHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586141,
      "TXT_PINCODE_LOCALITY": "HALAGANI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586142,
      "TXT_PINCODE_LOCALITY": "BALAGANUR (BIJAPUR)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586143,
      "TXT_PINCODE_LOCALITY": "CHANDKAVTHE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586144,
      "TXT_PINCODE_LOCALITY": "YANKANCHI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586145,
      "TXT_PINCODE_LOCALITY": "JAMKHANDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 3533,
      "City District Name": "JAMKHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586146,
      "TXT_PINCODE_LOCALITY": "JAMKHANDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 3533,
      "City District Name": "JAMKHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586147,
      "TXT_PINCODE_LOCALITY": "HIREBEVANUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586148,
      "TXT_PINCODE_LOCALITY": "HONAGONAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586149,
      "TXT_PINCODE_LOCALITY": "BHAIRANJE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586150,
      "TXT_PINCODE_LOCALITY": "BASAVANA BAGEVADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2382,
      "City District Name": "BASAVANA BAGEVADI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586151,
      "TXT_PINCODE_LOCALITY": "HITTINAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586152,
      "TXT_PINCODE_LOCALITY": "UKLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586153,
      "TXT_PINCODE_LOCALITY": "BIJAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586154,
      "TXT_PINCODE_LOCALITY": "MADABHAVI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586155,
      "TXT_PINCODE_LOCALITY": "MULSAVALGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586156,
      "TXT_PINCODE_LOCALITY": "HONVAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586157,
      "TXT_PINCODE_LOCALITY": "BIJAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586158,
      "TXT_PINCODE_LOCALITY": "YARGAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586159,
      "TXT_PINCODE_LOCALITY": "KANNOLLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586160,
      "TXT_PINCODE_LOCALITY": "KOLUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586161,
      "TXT_PINCODE_LOCALITY": "NAGTHAN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586162,
      "TXT_PINCODE_LOCALITY": "TADVALGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586163,
      "TXT_PINCODE_LOCALITY": "JALWAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586164,
      "TXT_PINCODE_LOCALITY": "YALWAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586165,
      "TXT_PINCODE_LOCALITY": "TILGUL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586166,
      "TXT_PINCODE_LOCALITY": "ASKI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586168,
      "TXT_PINCODE_LOCALITY": "BEKINAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586169,
      "TXT_PINCODE_LOCALITY": "SINDGI TOWN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586180,
      "TXT_PINCODE_LOCALITY": "TIKOTA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586201,
      "TXT_PINCODE_LOCALITY": "ALMATTI DAM SITE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586202,
      "TXT_PINCODE_LOCALITY": "ALMEL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586203,
      "TXT_PINCODE_LOCALITY": "BASAVAN BAGEWADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586204,
      "TXT_PINCODE_LOCALITY": "BARDOL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586205,
      "TXT_PINCODE_LOCALITY": "CHANDCHAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586206,
      "TXT_PINCODE_LOCALITY": "DEVANGAON",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586207,
      "TXT_PINCODE_LOCALITY": "HALSANGI S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586208,
      "TXT_PINCODE_LOCALITY": "HUVINHIPPARGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586209,
      "TXT_PINCODE_LOCALITY": "INDI H O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586210,
      "TXT_PINCODE_LOCALITY": "KOLHAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586211,
      "TXT_PINCODE_LOCALITY": "LACHYAN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586212,
      "TXT_PINCODE_LOCALITY": "MUDDEBIHAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586213,
      "TXT_PINCODE_LOCALITY": "NIDGUNDI (GADAG)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586214,
      "TXT_PINCODE_LOCALITY": "TALLIKOTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586215,
      "TXT_PINCODE_LOCALITY": "TAMBA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586216,
      "TXT_PINCODE_LOCALITY": "TELGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586217,
      "TXT_PINCODE_LOCALITY": "SALOTGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586218,
      "TXT_PINCODE_LOCALITY": "ALMATTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586219,
      "TXT_PINCODE_LOCALITY": "CHIMMALGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586220,
      "TXT_PINCODE_LOCALITY": "VANDAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586221,
      "TXT_PINCODE_LOCALITY": "ASANGI KHURD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586222,
      "TXT_PINCODE_LOCALITY": "AHIRSANG",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586223,
      "TXT_PINCODE_LOCALITY": "HANJAGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586224,
      "TXT_PINCODE_LOCALITY": "ANJUTGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586225,
      "TXT_PINCODE_LOCALITY": "CHICKBEVANUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586226,
      "TXT_PINCODE_LOCALITY": "RUGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586227,
      "TXT_PINCODE_LOCALITY": "BHATGUNKI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586228,
      "TXT_PINCODE_LOCALITY": "BABUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586229,
      "TXT_PINCODE_LOCALITY": "KAVADIMATTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586230,
      "TXT_PINCODE_LOCALITY": "BANDAPPANSALWEDGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586231,
      "TXT_PINCODE_LOCALITY": "GOLSANGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586232,
      "TXT_PINCODE_LOCALITY": "MUTTSALAVADIKEBE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586233,
      "TXT_PINCODE_LOCALITY": "HIREMASALI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586234,
      "TXT_PINCODE_LOCALITY": "HUNISHYAL (BIJAPUR)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586235,
      "TXT_PINCODE_LOCALITY": "INGLESHWAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586236,
      "TXT_PINCODE_LOCALITY": "JAGJIVAN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586237,
      "TXT_PINCODE_LOCALITY": "LONIBUDRUK",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586238,
      "TXT_PINCODE_LOCALITY": "NIMBARGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586239,
      "TXT_PINCODE_LOCALITY": "KUDRESALWADGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586240,
      "TXT_PINCODE_LOCALITY": "WADWADGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586241,
      "TXT_PINCODE_LOCALITY": "MASBINAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586242,
      "TXT_PINCODE_LOCALITY": "NIVARGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586243,
      "TXT_PINCODE_LOCALITY": "SHIRSHYAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 586247,
      "TXT_PINCODE_LOCALITY": "JAMDADDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587101,
      "TXT_PINCODE_LOCALITY": "BAGALKOT BAZAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587102,
      "TXT_PINCODE_LOCALITY": "HERKAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587103,
      "TXT_PINCODE_LOCALITY": "BAGALKOT DC COMPLEX",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587111,
      "TXT_PINCODE_LOCALITY": "ALOK UDYOG",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587112,
      "TXT_PINCODE_LOCALITY": "AMINGOD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587113,
      "TXT_PINCODE_LOCALITY": "BELAGALI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587114,
      "TXT_PINCODE_LOCALITY": "BELUR BAGALKOT",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587115,
      "TXT_PINCODE_LOCALITY": "BHAGAWATI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587116,
      "TXT_PINCODE_LOCALITY": "BELAMBGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587117,
      "TXT_PINCODE_LOCALITY": "GALGALI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587118,
      "TXT_PINCODE_LOCALITY": "CHITAWADGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587119,
      "TXT_PINCODE_LOCALITY": "NARAYANAPUR (GUL)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587120,
      "TXT_PINCODE_LOCALITY": "KAMATGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587121,
      "TXT_PINCODE_LOCALITY": "MANTUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587122,
      "TXT_PINCODE_LOCALITY": "LOKAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587123,
      "TXT_PINCODE_LOCALITY": "BIJAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587124,
      "TXT_PINCODE_LOCALITY": "KELUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587125,
      "TXT_PINCODE_LOCALITY": "IIKAL TOWN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587126,
      "TXT_PINCODE_LOCALITY": "BIJAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587127,
      "TXT_PINCODE_LOCALITY": "BILGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2563,
      "City District Name": "BILGI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587128,
      "TXT_PINCODE_LOCALITY": "BIJAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587129,
      "TXT_PINCODE_LOCALITY": "BILGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2563,
      "City District Name": "BILGI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587131,
      "TXT_PINCODE_LOCALITY": "ALAND",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 1971,
      "City District Name": "ALAND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587132,
      "TXT_PINCODE_LOCALITY": "BIJAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587133,
      "TXT_PINCODE_LOCALITY": "DEVADURGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2939,
      "City District Name": "DEVADURGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587134,
      "TXT_PINCODE_LOCALITY": "MUDHOL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 4578,
      "City District Name": "MUDHOL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587135,
      "TXT_PINCODE_LOCALITY": "HYLYAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587136,
      "TXT_PINCODE_LOCALITY": "MANTUR (BAGALKOT)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587137,
      "TXT_PINCODE_LOCALITY": "MELLIGERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587138,
      "TXT_PINCODE_LOCALITY": "BIJAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587139,
      "TXT_PINCODE_LOCALITY": "HUNGUND",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 3444,
      "City District Name": "HUNGUND",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587141,
      "TXT_PINCODE_LOCALITY": "SANGAM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587142,
      "TXT_PINCODE_LOCALITY": "HOSUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587143,
      "TXT_PINCODE_LOCALITY": "BAGALKOT",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587144,
      "TXT_PINCODE_LOCALITY": "JALIHAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 342,
      "City District Name": "GADAG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587145,
      "TXT_PINCODE_LOCALITY": "KANDGAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587146,
      "TXT_PINCODE_LOCALITY": "KARADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587147,
      "TXT_PINCODE_LOCALITY": "KODIHAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 347,
      "City District Name": "RAICHUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587148,
      "TXT_PINCODE_LOCALITY": "NANDAWADGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587149,
      "TXT_PINCODE_LOCALITY": "BADAMI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2199,
      "City District Name": "BADAMI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587150,
      "TXT_PINCODE_LOCALITY": "MURNAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587151,
      "TXT_PINCODE_LOCALITY": "SIDDAPUR (BGT)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587152,
      "TXT_PINCODE_LOCALITY": "SHIRUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587153,
      "TXT_PINCODE_LOCALITY": "BIJAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587154,
      "TXT_PINCODE_LOCALITY": "ALAMPURPETH",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587155,
      "TXT_PINCODE_LOCALITY": "KULGERI CROSS",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587166,
      "TXT_PINCODE_LOCALITY": "BILGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587201,
      "TXT_PINCODE_LOCALITY": "BADAMI EXTENSION",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587202,
      "TXT_PINCODE_LOCALITY": "GUDUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587203,
      "TXT_PINCODE_LOCALITY": "GULEDGUDD EAST",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587204,
      "TXT_PINCODE_LOCALITY": "KALADGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587205,
      "TXT_PINCODE_LOCALITY": "BAGALKOT S. O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587206,
      "TXT_PINCODE_LOCALITY": "KERUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587207,
      "TXT_PINCODE_LOCALITY": "HOSUR (BAGALKOT)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587208,
      "TXT_PINCODE_LOCALITY": "BIJAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587209,
      "TXT_PINCODE_LOCALITY": "MUTTALGERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587210,
      "TXT_PINCODE_LOCALITY": "NANDIKESHWAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587211,
      "TXT_PINCODE_LOCALITY": "PATTADAKAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587212,
      "TXT_PINCODE_LOCALITY": "SHIVAYOGA MANDIR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587213,
      "TXT_PINCODE_LOCALITY": "KHAJJIDONI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587214,
      "TXT_PINCODE_LOCALITY": "KUNDARGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587215,
      "TXT_PINCODE_LOCALITY": "BIJAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587242,
      "TXT_PINCODE_LOCALITY": "SHIVAYOGA MANDIR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587301,
      "TXT_PINCODE_LOCALITY": "JAMKHANDI H O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587302,
      "TXT_PINCODE_LOCALITY": "JAMKHANDI GIRI SHNAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587311,
      "TXT_PINCODE_LOCALITY": "BANAHATTI SOMWARPET",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587312,
      "TXT_PINCODE_LOCALITY": "BILGI (BAGALKOT)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587313,
      "TXT_PINCODE_LOCALITY": "MUDHOL (BIJAPUR)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587314,
      "TXT_PINCODE_LOCALITY": "RABKHAVI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587315,
      "TXT_PINCODE_LOCALITY": "TERDAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587316,
      "TXT_PINCODE_LOCALITY": "BELWADGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 343,
      "City District Name": "GULBARGA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587317,
      "TXT_PINCODE_LOCALITY": "BADAMI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 2199,
      "City District Name": "BADAMI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587318,
      "TXT_PINCODE_LOCALITY": "HIPPARGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587319,
      "TXT_PINCODE_LOCALITY": "KULHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587320,
      "TXT_PINCODE_LOCALITY": "NAVALGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587321,
      "TXT_PINCODE_LOCALITY": "ALGUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587322,
      "TXT_PINCODE_LOCALITY": "TOGALBAGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 339,
      "City District Name": "BIJAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587323,
      "TXT_PINCODE_LOCALITY": "HIREPADSALGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587324,
      "TXT_PINCODE_LOCALITY": "JAMKHANDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 3533,
      "City District Name": "JAMKHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587325,
      "TXT_PINCODE_LOCALITY": "SAIDAPUR (BAGALKOT)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587326,
      "TXT_PINCODE_LOCALITY": "JAMKHANDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 3533,
      "City District Name": "JAMKHANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587327,
      "TXT_PINCODE_LOCALITY": "SASALATTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587328,
      "TXT_PINCODE_LOCALITY": "SHIROL (BGT)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 587329,
      "TXT_PINCODE_LOCALITY": "YADHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 590001,
      "TXT_PINCODE_LOCALITY": "VISWESWARAYA NAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 590002,
      "TXT_PINCODE_LOCALITY": "KODOLKARGALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 590003,
      "TXT_PINCODE_LOCALITY": "BELGAUM BHARAT NAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 590004,
      "TXT_PINCODE_LOCALITY": "BELGAUM KHAS BAG",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 590005,
      "TXT_PINCODE_LOCALITY": "MADHAVAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 590006,
      "TXT_PINCODE_LOCALITY": "BELGAUM VACCINE INSTITUTE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 590007,
      "TXT_PINCODE_LOCALITY": "BELGUM ANGOL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 590008,
      "TXT_PINCODE_LOCALITY": "BELGAUM UDYAMBAG.",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 590009,
      "TXT_PINCODE_LOCALITY": "BELAGAVI RECORDS MLI S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 590010,
      "TXT_PINCODE_LOCALITY": "NEHRUNAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 590011,
      "TXT_PINCODE_LOCALITY": "BELGAUM HINDWADI S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 590012,
      "TXT_PINCODE_LOCALITY": "BELGAUM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 590013,
      "TXT_PINCODE_LOCALITY": "BELGAUM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 590014,
      "TXT_PINCODE_LOCALITY": "BAILUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 590015,
      "TXT_PINCODE_LOCALITY": "KANABARGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 590016,
      "TXT_PINCODE_LOCALITY": "BM DHARWAD ROAD *",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 590543,
      "TXT_PINCODE_LOCALITY": "KARALI JN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591101,
      "TXT_PINCODE_LOCALITY": "ANKALGI (GOKAK)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591102,
      "TXT_PINCODE_LOCALITY": "BAILHONGAL BAZAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591103,
      "TXT_PINCODE_LOCALITY": "BALEKUNDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591104,
      "TXT_PINCODE_LOCALITY": "BELVADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591106,
      "TXT_PINCODE_LOCALITY": "BIDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591107,
      "TXT_PINCODE_LOCALITY": "BELGAUM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591108,
      "TXT_PINCODE_LOCALITY": "VIJAYANAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591109,
      "TXT_PINCODE_LOCALITY": "ANAKALGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591110,
      "TXT_PINCODE_LOCALITY": "HIREKUMBI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591111,
      "TXT_PINCODE_LOCALITY": "HOSUR (BALGAUM)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591112,
      "TXT_PINCODE_LOCALITY": "ITGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591113,
      "TXT_PINCODE_LOCALITY": "KAKTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591114,
      "TXT_PINCODE_LOCALITY": "KATKOL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591115,
      "TXT_PINCODE_LOCALITY": "KITTUR SOMWARPETH",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591116,
      "TXT_PINCODE_LOCALITY": "MALAPRABHA DAM SITE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591117,
      "TXT_PINCODE_LOCALITY": "MANOLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591118,
      "TXT_PINCODE_LOCALITY": "MUGUTKHANHUBLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591119,
      "TXT_PINCODE_LOCALITY": "MURGOD S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591120,
      "TXT_PINCODE_LOCALITY": "NANDGAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591121,
      "TXT_PINCODE_LOCALITY": "NESARGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591122,
      "TXT_PINCODE_LOCALITY": "FACHHAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591123,
      "TXT_PINCODE_LOCALITY": "RAMDURG",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591124,
      "TXT_PINCODE_LOCALITY": "SAMBRA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591125,
      "TXT_PINCODE_LOCALITY": "SAMPGAON",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591126,
      "TXT_PINCODE_LOCALITY": "SAUNDATTI CHIDAMBARNAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591127,
      "TXT_PINCODE_LOCALITY": "SUREBAN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591128,
      "TXT_PINCODE_LOCALITY": "UCHGAON",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591129,
      "TXT_PINCODE_LOCALITY": "YARGATTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591130,
      "TXT_PINCODE_LOCALITY": "SALAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591131,
      "TXT_PINCODE_LOCALITY": "PARISHWAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591132,
      "TXT_PINCODE_LOCALITY": "BHENDIGERI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591134,
      "TXT_PINCODE_LOCALITY": "SULEBHAVI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591135,
      "TXT_PINCODE_LOCALITY": "BELGAUM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591136,
      "TXT_PINCODE_LOCALITY": "YADWAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591137,
      "TXT_PINCODE_LOCALITY": "AMBADGATTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591138,
      "TXT_PINCODE_LOCALITY": "ANAKALGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591139,
      "TXT_PINCODE_LOCALITY": "BELAGUNDA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591140,
      "TXT_PINCODE_LOCALITY": "DEVALAPUR (BELGAUM)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591141,
      "TXT_PINCODE_LOCALITY": "BELGAUM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591142,
      "TXT_PINCODE_LOCALITY": "HALASHI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591144,
      "TXT_PINCODE_LOCALITY": "KAKKERI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591145,
      "TXT_PINCODE_LOCALITY": "BELGAUM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591146,
      "TXT_PINCODE_LOCALITY": "YALLUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591148,
      "TXT_PINCODE_LOCALITY": "HUDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591149,
      "TXT_PINCODE_LOCALITY": "BAILUR (BELGAUM)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591150,
      "TXT_PINCODE_LOCALITY": "BELGAUM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591151,
      "TXT_PINCODE_LOCALITY": "BELGAUM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591152,
      "TXT_PINCODE_LOCALITY": "BELGAUM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591155,
      "TXT_PINCODE_LOCALITY": "HALGATTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591157,
      "TXT_PINCODE_LOCALITY": "HANCHINAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591158,
      "TXT_PINCODE_LOCALITY": "HOLEHOSUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591159,
      "TXT_PINCODE_LOCALITY": "BELGAUM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591160,
      "TXT_PINCODE_LOCALITY": "HULKUND",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591161,
      "TXT_PINCODE_LOCALITY": "BELGAUM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591162,
      "TXT_PINCODE_LOCALITY": "INAMHANGAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 341,
      "City District Name": "DHARWAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591163,
      "TXT_PINCODE_LOCALITY": "BELGAUM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591164,
      "TXT_PINCODE_LOCALITY": "KADROLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591166,
      "TXT_PINCODE_LOCALITY": "KEDNUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591168,
      "TXT_PINCODE_LOCALITY": "MUDKAVI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591169,
      "TXT_PINCODE_LOCALITY": "BELGAUM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591170,
      "TXT_PINCODE_LOCALITY": "TALLUR (BELGAUM)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591171,
      "TXT_PINCODE_LOCALITY": "TIGADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591172,
      "TXT_PINCODE_LOCALITY": "VANNUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591173,
      "TXT_PINCODE_LOCALITY": "YELLAMMA HILLS",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591175,
      "TXT_PINCODE_LOCALITY": "DHAMNE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591176,
      "TXT_PINCODE_LOCALITY": "HALGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591177,
      "TXT_PINCODE_LOCALITY": "BELGAUM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591178,
      "TXT_PINCODE_LOCALITY": "BELGAUM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591179,
      "TXT_PINCODE_LOCALITY": "MADVAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591180,
      "TXT_PINCODE_LOCALITY": "ASUND I (BELGAUM)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591181,
      "TXT_PINCODE_LOCALITY": "MACHHE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591182,
      "TXT_PINCODE_LOCALITY": "AMBEWADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591183,
      "TXT_PINCODE_LOCALITY": "BETSUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591184,
      "TXT_PINCODE_LOCALITY": "DODWAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591185,
      "TXT_PINCODE_LOCALITY": "HANABARATTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591186,
      "TXT_PINCODE_LOCALITY": "HANNIKERI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591187,
      "TXT_PINCODE_LOCALITY": "HOSKOTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591188,
      "TXT_PINCODE_LOCALITY": "KARIKATTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591189,
      "TXT_PINCODE_LOCALITY": "MUTGE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591190,
      "TXT_PINCODE_LOCALITY": "NEGINHAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591191,
      "TXT_PINCODE_LOCALITY": "SANGOLLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591192,
      "TXT_PINCODE_LOCALITY": "SATTIGERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591193,
      "TXT_PINCODE_LOCALITY": "SOPADIA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591194,
      "TXT_PINCODE_LOCALITY": "TIGADOLLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591195,
      "TXT_PINCODE_LOCALITY": "TURMARI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591196,
      "TXT_PINCODE_LOCALITY": "UGARGOL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591197,
      "TXT_PINCODE_LOCALITY": "KANGRALIKHURD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591198,
      "TXT_PINCODE_LOCALITY": "MUCHANDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591201,
      "TXT_PINCODE_LOCALITY": "CHIKODI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591211,
      "TXT_PINCODE_LOCALITY": "AKOL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591212,
      "TXT_PINCODE_LOCALITY": "ANANTPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591213,
      "TXT_PINCODE_LOCALITY": "ANKALGI (GOKAK)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591214,
      "TXT_PINCODE_LOCALITY": "BEDAKIHAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591215,
      "TXT_PINCODE_LOCALITY": "BENADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591216,
      "TXT_PINCODE_LOCALITY": "BORGAON",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591217,
      "TXT_PINCODE_LOCALITY": "BELGAUM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591218,
      "TXT_PINCODE_LOCALITY": "ARBHAVIAMATH",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591219,
      "TXT_PINCODE_LOCALITY": "GALATAGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591220,
      "TXT_PINCODE_LOCALITY": "HARUGERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591221,
      "TXT_PINCODE_LOCALITY": "HEBBAL (BELGAUM)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591222,
      "TXT_PINCODE_LOCALITY": "KABBUR (BELGAUM)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591223,
      "TXT_PINCODE_LOCALITY": "KAGWAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591224,
      "TXT_PINCODE_LOCALITY": "KALLOLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591225,
      "TXT_PINCODE_LOCALITY": "KANAGALE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591226,
      "TXT_PINCODE_LOCALITY": "KAROSHI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591227,
      "TXT_PINCODE_LOCALITY": "MANOLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591228,
      "TXT_PINCODE_LOCALITY": "KHADAKLAT",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591229,
      "TXT_PINCODE_LOCALITY": "KOGNOLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591230,
      "TXT_PINCODE_LOCALITY": "KOKATNUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591231,
      "TXT_PINCODE_LOCALITY": "KONNUR (BELGAUM)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591232,
      "TXT_PINCODE_LOCALITY": "MADBHAVI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591233,
      "TXT_PINCODE_LOCALITY": "MAMADAPUR (BELGAUM)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591234,
      "TXT_PINCODE_LOCALITY": "MANGASULLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591235,
      "TXT_PINCODE_LOCALITY": "MUGALKHED",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591236,
      "TXT_PINCODE_LOCALITY": "NIDSOSHI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591237,
      "TXT_PINCODE_LOCALITY": "NIPANI N C",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591238,
      "TXT_PINCODE_LOCALITY": "PATTANKUDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591239,
      "TXT_PINCODE_LOCALITY": "SADALGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591240,
      "TXT_PINCODE_LOCALITY": "SATTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591241,
      "TXT_PINCODE_LOCALITY": "SAUNDALGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591242,
      "TXT_PINCODE_LOCALITY": "SHIRGUPPI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591243,
      "TXT_PINCODE_LOCALITY": "THANAHATTARGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591244,
      "TXT_PINCODE_LOCALITY": "YAKSAMBA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591245,
      "TXT_PINCODE_LOCALITY": "YAMAKANMARDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591246,
      "TXT_PINCODE_LOCALITY": "KARADGA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591247,
      "TXT_PINCODE_LOCALITY": "CHIKODI SUGAR FACTORY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591248,
      "TXT_PINCODE_LOCALITY": "ADHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591249,
      "TXT_PINCODE_LOCALITY": "INGALGOAN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591250,
      "TXT_PINCODE_LOCALITY": "KULGOD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591251,
      "TXT_PINCODE_LOCALITY": "GHODERI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591252,
      "TXT_PINCODE_LOCALITY": "JUGAI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591253,
      "TXT_PINCODE_LOCALITY": "YADGUD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591254,
      "TXT_PINCODE_LOCALITY": "DADDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591255,
      "TXT_PINCODE_LOCALITY": "HIDKAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591256,
      "TXT_PINCODE_LOCALITY": "CHAMAKERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591257,
      "TXT_PINCODE_LOCALITY": "KOHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591258,
      "TXT_PINCODE_LOCALITY": "KEMPWAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591259,
      "TXT_PINCODE_LOCALITY": "MURGUNDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591260,
      "TXT_PINCODE_LOCALITY": "SAVADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591261,
      "TXT_PINCODE_LOCALITY": "DARUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591262,
      "TXT_PINCODE_LOCALITY": "GUNDEWADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591263,
      "TXT_PINCODE_LOCALITY": "BHOJ",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591264,
      "TXT_PINCODE_LOCALITY": "MANJARI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591265,
      "TXT_PINCODE_LOCALITY": "TELSANG",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591266,
      "TXT_PINCODE_LOCALITY": "ADHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591267,
      "TXT_PINCODE_LOCALITY": "BELGAUM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591268,
      "TXT_PINCODE_LOCALITY": "BELGAUM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591269,
      "TXT_PINCODE_LOCALITY": "BELGAUM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591270,
      "TXT_PINCODE_LOCALITY": "BELKAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591271,
      "TXT_PINCODE_LOCALITY": "BHIRDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591272,
      "TXT_PINCODE_LOCALITY": "CHINCHANI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591273,
      "TXT_PINCODE_LOCALITY": "CHANDUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591274,
      "TXT_PINCODE_LOCALITY": "HIREKUDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591275,
      "TXT_PINCODE_LOCALITY": "HUNNARGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591276,
      "TXT_PINCODE_LOCALITY": "HUNSHYAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591277,
      "TXT_PINCODE_LOCALITY": "INGLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591278,
      "TXT_PINCODE_LOCALITY": "JAMBAGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591279,
      "TXT_PINCODE_LOCALITY": "JANWAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591280,
      "TXT_PINCODE_LOCALITY": "JATRAT",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591281,
      "TXT_PINCODE_LOCALITY": "KADAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591282,
      "TXT_PINCODE_LOCALITY": "KALLOL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591283,
      "TXT_PINCODE_LOCALITY": "KERUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591284,
      "TXT_PINCODE_LOCALITY": "KODNI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591285,
      "TXT_PINCODE_LOCALITY": "KURLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591286,
      "TXT_PINCODE_LOCALITY": "BELGAUM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591287,
      "TXT_PINCODE_LOCALITY": "KOTHALI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591288,
      "TXT_PINCODE_LOCALITY": "KUNNUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591289,
      "TXT_PINCODE_LOCALITY": "MALLIKWAD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591290,
      "TXT_PINCODE_LOCALITY": "MANGUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591291,
      "TXT_PINCODE_LOCALITY": "MANGUTTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591292,
      "TXT_PINCODE_LOCALITY": "MASAGUPPI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591293,
      "TXT_PINCODE_LOCALITY": "NAGAR MUNOLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591294,
      "TXT_PINCODE_LOCALITY": "NAINGALAJ",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591296,
      "TXT_PINCODE_LOCALITY": "NEJ",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591297,
      "TXT_PINCODE_LOCALITY": "NEW DIGGEWADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591298,
      "TXT_PINCODE_LOCALITY": "NILAGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591299,
      "TXT_PINCODE_LOCALITY": "NASALAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591301,
      "TXT_PINCODE_LOCALITY": "LONDA",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591302,
      "TXT_PINCODE_LOCALITY": "KHANAPUR TOWN",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591303,
      "TXT_PINCODE_LOCALITY": "AIR FORCE STATION",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591304,
      "TXT_PINCODE_LOCALITY": "ATHNI BUS STAND",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591305,
      "TXT_PINCODE_LOCALITY": "BELLAD BAGEWADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591307,
      "TXT_PINCODE_LOCALITY": "GOKAK RAVIWARPET",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591308,
      "TXT_PINCODE_LOCALITY": "GOKAK FALIS",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591309,
      "TXT_PINCODE_LOCALITY": "HUKERI BAZAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591310,
      "TXT_PINCODE_LOCALITY": "KARNATAK HEALTH INSTITUTE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591311,
      "TXT_PINCODE_LOCALITY": "KUDCHI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591312,
      "TXT_PINCODE_LOCALITY": "MUDALAGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591313,
      "TXT_PINCODE_LOCALITY": "SANKESHWAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591314,
      "TXT_PINCODE_LOCALITY": "SANKESHWAR SUGAR FACTORY",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591315,
      "TXT_PINCODE_LOCALITY": "SHEDBAI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591316,
      "TXT_PINCODE_LOCALITY": "UGARKHURD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591317,
      "TXT_PINCODE_LOCALITY": "RAIBAG",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591318,
      "TXT_PINCODE_LOCALITY": "BELGAUM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591320,
      "TXT_PINCODE_LOCALITY": "UGARBUDRUK",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591321,
      "TXT_PINCODE_LOCALITY": "GULBALGUD",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591322,
      "TXT_PINCODE_LOCALITY": "MOLE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591323,
      "TXT_PINCODE_LOCALITY": "HULAGBABI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591324,
      "TXT_PINCODE_LOCALITY": "KAKAMARI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591325,
      "TXT_PINCODE_LOCALITY": "NANDAGAON",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591326,
      "TXT_PINCODE_LOCALITY": "KOTTALGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591327,
      "TXT_PINCODE_LOCALITY": "PARTHANAHALLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591328,
      "TXT_PINCODE_LOCALITY": "SHEGUNSHI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 335,
      "City District Name": "BAGALKOT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591329,
      "TXT_PINCODE_LOCALITY": "PARAMANANDAWADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591330,
      "TXT_PINCODE_LOCALITY": "SHIRGUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591331,
      "TXT_PINCODE_LOCALITY": "GARLGUNGJI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591332,
      "TXT_PINCODE_LOCALITY": "BEKKERE",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591334,
      "TXT_PINCODE_LOCALITY": "HULLOLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591335,
      "TXT_PINCODE_LOCALITY": "ITNAL",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591336,
      "TXT_PINCODE_LOCALITY": "KOLVI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591337,
      "TXT_PINCODE_LOCALITY": "BELGAUM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591338,
      "TXT_PINCODE_LOCALITY": "MORAB (BGM)",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591339,
      "TXT_PINCODE_LOCALITY": "NANDI KURLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591340,
      "TXT_PINCODE_LOCALITY": "NERLI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591342,
      "TXT_PINCODE_LOCALITY": "TARIKERE TOWN TAVAG",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591343,
      "TXT_PINCODE_LOCALITY": "BELGAUM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591344,
      "TXT_PINCODE_LOCALITY": "KHANGAON S.O",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591501,
      "TXT_PINCODE_LOCALITY": "SAMBARGI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591502,
      "TXT_PINCODE_LOCALITY": "SAUNBUDDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591503,
      "TXT_PINCODE_LOCALITY": "SAPTASAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591504,
      "TXT_PINCODE_LOCALITY": "SAUNDATTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591505,
      "TXT_PINCODE_LOCALITY": "BELGAUM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591506,
      "TXT_PINCODE_LOCALITY": "BELGAUM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591507,
      "TXT_PINCODE_LOCALITY": "SOLAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591508,
      "TXT_PINCODE_LOCALITY": "BELGAUM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591509,
      "TXT_PINCODE_LOCALITY": "BELGAUM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591510,
      "TXT_PINCODE_LOCALITY": "ULLAGADDI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591511,
      "TXT_PINCODE_LOCALITY": "WALKI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591512,
      "TXT_PINCODE_LOCALITY": "YADUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591513,
      "TXT_PINCODE_LOCALITY": "WADERHATTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591514,
      "TXT_PINCODE_LOCALITY": "HARAGAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591515,
      "TXT_PINCODE_LOCALITY": "KANKANWADI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591516,
      "TXT_PINCODE_LOCALITY": "YABARATTI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591517,
      "TXT_PINCODE_LOCALITY": "MANKAPUR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591701,
      "TXT_PINCODE_LOCALITY": "VAKKUND",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591702,
      "TXT_PINCODE_LOCALITY": "KADOLOI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591703,
      "TXT_PINCODE_LOCALITY": "BELGAUM",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591802,
      "TXT_PINCODE_LOCALITY": "CHIDAMBARNAGAR",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 591812,
      "TXT_PINCODE_LOCALITY": "NEKARPET RAMDURG",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 592336,
      "TXT_PINCODE_LOCALITY": "KOLVI",
      "NUM_STATE_CD": 59,
      "State Name": "KARNATAKA",
      "NUM_CITYDISTRICT_CD": 519,
      "City District Name": "BELGAUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680001,
      "TXT_PINCODE_LOCALITY": "THRISSUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680002,
      "TXT_PINCODE_LOCALITY": "PUNKUNNU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680003,
      "TXT_PINCODE_LOCALITY": "CHUNGAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680004,
      "TXT_PINCODE_LOCALITY": "POOTHOLE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680005,
      "TXT_PINCODE_LOCALITY": "THRISSUR EAST",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680006,
      "TXT_PINCODE_LOCALITY": "KURIACHIRA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680007,
      "TXT_PINCODE_LOCALITY": "KURKANCHERRI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680008,
      "TXT_PINCODE_LOCALITY": "CHERUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680009,
      "TXT_PINCODE_LOCALITY": "THRISSUR ENGG.COLLEGE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680010,
      "TXT_PINCODE_LOCALITY": "KOLAZHI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680011,
      "TXT_PINCODE_LOCALITY": "KANATTUKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680012,
      "TXT_PINCODE_LOCALITY": "THIRUMUKKULAM B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680013,
      "TXT_PINCODE_LOCALITY": "KUTTUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680014,
      "TXT_PINCODE_LOCALITY": "VETTUKAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680015,
      "TXT_PINCODE_LOCALITY": "NEDUPUZHA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680016,
      "TXT_PINCODE_LOCALITY": "VELUTHUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680017,
      "TXT_PINCODE_LOCALITY": "MANAKODI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680018,
      "TXT_PINCODE_LOCALITY": "PERINGAVU EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680020,
      "TXT_PINCODE_LOCALITY": "THRISSUR CITY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680021,
      "TXT_PINCODE_LOCALITY": "THRISSUR R.S",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680026,
      "TXT_PINCODE_LOCALITY": "CHIYYARAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680027,
      "TXT_PINCODE_LOCALITY": "KANIMANGALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680028,
      "TXT_PINCODE_LOCALITY": "KURICHIKKARA.",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680101,
      "TXT_PINCODE_LOCALITY": "THIRUVENKITAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680102,
      "TXT_PINCODE_LOCALITY": "KANDANASEERI B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680103,
      "TXT_PINCODE_LOCALITY": "PUTHENPALLI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680104,
      "TXT_PINCODE_LOCALITY": "NEMMINI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680121,
      "TXT_PINCODE_LOCALITY": "IRINJALAKUDA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680122,
      "TXT_PINCODE_LOCALITY": "EDATHIRINJI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680123,
      "TXT_PINCODE_LOCALITY": "KONATHUKUNNU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680124,
      "TXT_PINCODE_LOCALITY": "VALLIVATTAM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680125,
      "TXT_PINCODE_LOCALITY": "IRINJALAKUDA NORTH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680301,
      "TXT_PINCODE_LOCALITY": "PAZHAYI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680302,
      "TXT_PINCODE_LOCALITY": "VATTANATHARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680303,
      "TXT_PINCODE_LOCALITY": "VELUPADAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680304,
      "TXT_PINCODE_LOCALITY": "CHIMONI DAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680305,
      "TXT_PINCODE_LOCALITY": "NELLAI-TRICHUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680306,
      "TXT_PINCODE_LOCALITY": "THALORE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680307,
      "TXT_PINCODE_LOCALITY": "CHALAKUDI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680308,
      "TXT_PINCODE_LOCALITY": "MAMBRA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680309,
      "TXT_PINCODE_LOCALITY": "KHANNA NAGAR KORATTY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680310,
      "TXT_PINCODE_LOCALITY": "THOTTIPPAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680311,
      "TXT_PINCODE_LOCALITY": "MELUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680312,
      "TXT_PINCODE_LOCALITY": "CHENGALLUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680313,
      "TXT_PINCODE_LOCALITY": "AVINISSERY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680314,
      "TXT_PINCODE_LOCALITY": "TRIKKUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680315,
      "TXT_PINCODE_LOCALITY": "KADUKUTTY EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680316,
      "TXT_PINCODE_LOCALITY": "MURINGUR VADAKKUMMURI EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680317,
      "TXT_PINCODE_LOCALITY": "MUTTITHADI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680319,
      "TXT_PINCODE_LOCALITY": "NANDIPULAM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680320,
      "TXT_PINCODE_LOCALITY": "MARATHAKARATRICHUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680321,
      "TXT_PINCODE_LOCALITY": "CHERUVALOOR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680322,
      "TXT_PINCODE_LOCALITY": "OLLUR THAIKATTUSSERI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680323,
      "TXT_PINCODE_LOCALITY": "ANANDHAPURAM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680324,
      "TXT_PINCODE_LOCALITY": "ANNANAD EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680325,
      "TXT_PINCODE_LOCALITY": "VARAKARA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680383,
      "TXT_PINCODE_LOCALITY": "AVINISSERY - 1002",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680501,
      "TXT_PINCODE_LOCALITY": "THALAKOTTUKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680502,
      "TXT_PINCODE_LOCALITY": "CHOONDAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680503,
      "TXT_PINCODE_LOCALITY": "KUNNAMKULAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1259,
      "City District Name": "KUNNAMKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680504,
      "TXT_PINCODE_LOCALITY": "KOONAMOOCHI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680505,
      "TXT_PINCODE_LOCALITY": "KOTTAPADI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680506,
      "TXT_PINCODE_LOCALITY": "MUDUVATTUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680507,
      "TXT_PINCODE_LOCALITY": "VEMMANAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680508,
      "TXT_PINCODE_LOCALITY": "PUVATHUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680509,
      "TXT_PINCODE_LOCALITY": "MULLASSERI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680510,
      "TXT_PINCODE_LOCALITY": "ENAMAKKAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680511,
      "TXT_PINCODE_LOCALITY": "ELAVALLY SOUTH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680512,
      "TXT_PINCODE_LOCALITY": "MADU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680513,
      "TXT_PINCODE_LOCALITY": "TOYAKAVU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680514,
      "TXT_PINCODE_LOCALITY": "KADAPPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680515,
      "TXT_PINCODE_LOCALITY": "EDAKAILYUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680516,
      "TXT_PINCODE_LOCALITY": "THIRUVATRA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680517,
      "TXT_PINCODE_LOCALITY": "PORKOLANGAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680518,
      "TXT_PINCODE_LOCALITY": "MANNALAMKUNNU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680519,
      "TXT_PINCODE_LOCALITY": "THIPPILLISSERI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680520,
      "TXT_PINCODE_LOCALITY": "THOZHIYUR TOLUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680521,
      "TXT_PINCODE_LOCALITY": "ARTHAT",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680522,
      "TXT_PINCODE_LOCALITY": "CHAKKUMKANDAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680523,
      "TXT_PINCODE_LOCALITY": "KIZHUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680524,
      "TXT_PINCODE_LOCALITY": "PADOOR CHAVAKKAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680541,
      "TXT_PINCODE_LOCALITY": "MUNDUR TRICHUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680542,
      "TXT_PINCODE_LOCALITY": "PAZHANJI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680543,
      "TXT_PINCODE_LOCALITY": "KORATTIKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680544,
      "TXT_PINCODE_LOCALITY": "PENGAMUKKU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680545,
      "TXT_PINCODE_LOCALITY": "PERAMANGALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680546,
      "TXT_PINCODE_LOCALITY": "KAIPARAMBA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680547,
      "TXT_PINCODE_LOCALITY": "AVANUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680548,
      "TXT_PINCODE_LOCALITY": "CHOOLISSERRY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680549,
      "TXT_PINCODE_LOCALITY": "ANJUR MUNDUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680551,
      "TXT_PINCODE_LOCALITY": "PURANATTUKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680552,
      "TXT_PINCODE_LOCALITY": "PONNORE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680553,
      "TXT_PINCODE_LOCALITY": "PUZHAKKAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680554,
      "TXT_PINCODE_LOCALITY": "ADAT",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680561,
      "TXT_PINCODE_LOCALITY": "CHERPU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680562,
      "TXT_PINCODE_LOCALITY": "VALLACHIRA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680563,
      "TXT_PINCODE_LOCALITY": "AMMADAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680564,
      "TXT_PINCODE_LOCALITY": "PAZHUVIL WEST",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680565,
      "TXT_PINCODE_LOCALITY": "THIRANNIKAVU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680566,
      "TXT_PINCODE_LOCALITY": "NATTIKA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680567,
      "TXT_PINCODE_LOCALITY": "VALAPAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680568,
      "TXT_PINCODE_LOCALITY": "KALIMBRAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680569,
      "TXT_PINCODE_LOCALITY": "EDASSERI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680570,
      "TXT_PINCODE_LOCALITY": "VADAKKUMURI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680571,
      "TXT_PINCODE_LOCALITY": "CHAZHUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680572,
      "TXT_PINCODE_LOCALITY": "NATTIKA BEACH EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680573,
      "TXT_PINCODE_LOCALITY": "CHERPU PANDINJATTUMMURI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680574,
      "TXT_PINCODE_LOCALITY": "KODANNUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680575,
      "TXT_PINCODE_LOCALITY": "PARALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680581,
      "TXT_PINCODE_LOCALITY": "MULAGUNNATHUCAVU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680582,
      "TXT_PINCODE_LOCALITY": "VADAKANCHERRY(TCR)",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680583,
      "TXT_PINCODE_LOCALITY": "MULLURKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680584,
      "TXT_PINCODE_LOCALITY": "KATUNGODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680585,
      "TXT_PINCODE_LOCALITY": "PULAKKAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680586,
      "TXT_PINCODE_LOCALITY": "PULACODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680587,
      "TXT_PINCODE_LOCALITY": "KUMBALAKODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680588,
      "TXT_PINCODE_LOCALITY": "PATTIPARAMBA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680589,
      "TXT_PINCODE_LOCALITY": "VAZHANI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680590,
      "TXT_PINCODE_LOCALITY": "KUMARANALLUR TCR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680591,
      "TXT_PINCODE_LOCALITY": "KILLIMANGALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680592,
      "TXT_PINCODE_LOCALITY": "ATTUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680593,
      "TXT_PINCODE_LOCALITY": "ARANGOTTUKARA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680594,
      "TXT_PINCODE_LOCALITY": "KANIYARKODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680595,
      "TXT_PINCODE_LOCALITY": "MUNDATHICODE EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680596,
      "TXT_PINCODE_LOCALITY": "VELAPPAYA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680599,
      "TXT_PINCODE_LOCALITY": "TALI (TRICHUR)",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680600,
      "TXT_PINCODE_LOCALITY": "CHITTANDA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680601,
      "TXT_PINCODE_LOCALITY": "VELUR BAZAR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680602,
      "TXT_PINCODE_LOCALITY": "NAMBAZHIKAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680603,
      "TXT_PINCODE_LOCALITY": "ALURMATTOM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680604,
      "TXT_PINCODE_LOCALITY": "MARUTHANCODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680608,
      "TXT_PINCODE_LOCALITY": "THEKKUMKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680609,
      "TXT_PINCODE_LOCALITY": "THICHUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 508,
      "City District Name": "PALAKKAD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680610,
      "TXT_PINCODE_LOCALITY": "THONNURKARA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680611,
      "TXT_PINCODE_LOCALITY": "ELTHURUTH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680612,
      "TXT_PINCODE_LOCALITY": "KANJANY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680613,
      "TXT_PINCODE_LOCALITY": "KANDASANKADAVU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680614,
      "TXT_PINCODE_LOCALITY": "NADUVILKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680615,
      "TXT_PINCODE_LOCALITY": "ENGANDIYOOR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680616,
      "TXT_PINCODE_LOCALITY": "KUNDALIYUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680617,
      "TXT_PINCODE_LOCALITY": "MANALUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680618,
      "TXT_PINCODE_LOCALITY": "ARANATTUKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680619,
      "TXT_PINCODE_LOCALITY": "TRITHALLUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680620,
      "TXT_PINCODE_LOCALITY": "ARIMPUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680621,
      "TXT_PINCODE_LOCALITY": "CHETTUPUZHA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680623,
      "TXT_PINCODE_LOCALITY": "PANANGAUTTAKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680631,
      "TXT_PINCODE_LOCALITY": "KATTILAPOOVAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680641,
      "TXT_PINCODE_LOCALITY": "ANTHIKAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680642,
      "TXT_PINCODE_LOCALITY": "PUTHENPEEDIAKA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680651,
      "TXT_PINCODE_LOCALITY": "MADAKKATHARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680652,
      "TXT_PINCODE_LOCALITY": "KANNARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680653,
      "TXT_PINCODE_LOCALITY": "CHENNAYAPARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680654,
      "TXT_PINCODE_LOCALITY": "VELLANIKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680655,
      "TXT_PINCODE_LOCALITY": "OLLUR THAIKKATTUSSERI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680656,
      "TXT_PINCODE_LOCALITY": "KERALA AGRI UNIVERSITY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680657,
      "TXT_PINCODE_LOCALITY": "NETTISSERI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680661,
      "TXT_PINCODE_LOCALITY": "NADAVARAMBA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680662,
      "TXT_PINCODE_LOCALITY": "VELLANGALLUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680663,
      "TXT_PINCODE_LOCALITY": "PULLUT NORTH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680664,
      "TXT_PINCODE_LOCALITY": "THIRUVANCHIKULAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680665,
      "TXT_PINCODE_LOCALITY": "PANANGAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680666,
      "TXT_PINCODE_LOCALITY": "AZHIKODE JETTY B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680667,
      "TXT_PINCODE_LOCALITY": "THURITHIPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680668,
      "TXT_PINCODE_LOCALITY": "SANTHIPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680669,
      "TXT_PINCODE_LOCALITY": "METHALA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680670,
      "TXT_PINCODE_LOCALITY": "KARUPADANNA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680671,
      "TXT_PINCODE_LOCALITY": "EDAVILANGU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680672,
      "TXT_PINCODE_LOCALITY": "KOTTANALLUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680673,
      "TXT_PINCODE_LOCALITY": "AZHIKODE TRICHUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680680,
      "TXT_PINCODE_LOCALITY": "NELLIKAMON",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680681,
      "TXT_PINCODE_LOCALITY": "KOORIKUZHY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680682,
      "TXT_PINCODE_LOCALITY": "PUTHENCHIRA-THEKKUMURI B.",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680683,
      "TXT_PINCODE_LOCALITY": "KUZHIKATTUSSERI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680684,
      "TXT_PINCODE_LOCALITY": "MANAKULANGARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680685,
      "TXT_PINCODE_LOCALITY": "MATHILAKAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680686,
      "TXT_PINCODE_LOCALITY": "CHAKKARAPADAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680687,
      "TXT_PINCODE_LOCALITY": "CHENTRAPPINNI EAST",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680688,
      "TXT_PINCODE_LOCALITY": "ARIPALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680689,
      "TXT_PINCODE_LOCALITY": "KANAKAMALA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680691,
      "TXT_PINCODE_LOCALITY": "KOOLIMUTTAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680692,
      "TXT_PINCODE_LOCALITY": "MATTATHUR TRICHUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680693,
      "TXT_PINCODE_LOCALITY": "VELLIKULANGARA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 3831,
      "City District Name": "KASARAGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680694,
      "TXT_PINCODE_LOCALITY": "MURIYAD EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680695,
      "TXT_PINCODE_LOCALITY": "PADIYAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680698,
      "TXT_PINCODE_LOCALITY": "KADUPPASSERRY EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680699,
      "TXT_PINCODE_LOCALITY": "PADY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 3831,
      "City District Name": "KASARAGOD",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680701,
      "TXT_PINCODE_LOCALITY": "THANISSERRY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680702,
      "TXT_PINCODE_LOCALITY": "KARANCHIRA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680703,
      "TXT_PINCODE_LOCALITY": "EDATHURUTHI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680704,
      "TXT_PINCODE_LOCALITY": "KIZHUPILLIKKARA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680711,
      "TXT_PINCODE_LOCALITY": "MURKANAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680712,
      "TXT_PINCODE_LOCALITY": "MADAYIKKONAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680721,
      "TXT_PINCODE_LOCALITY": "CHATTIKULAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680722,
      "TXT_PINCODE_LOCALITY": "PORINGALKUTHU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680723,
      "TXT_PINCODE_LOCALITY": "AMBALAPARA TCR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680724,
      "TXT_PINCODE_LOCALITY": "MALAKKAPPARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680725,
      "TXT_PINCODE_LOCALITY": "KODASSERI EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680731,
      "TXT_PINCODE_LOCALITY": "ASHTAMICHIRA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680732,
      "TXT_PINCODE_LOCALITY": "MALA THRISUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680733,
      "TXT_PINCODE_LOCALITY": "KRISHNAGIRI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680734,
      "TXT_PINCODE_LOCALITY": "IRANIKULAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680735,
      "TXT_PINCODE_LOCALITY": "KURUVILASSERY EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680736,
      "TXT_PINCODE_LOCALITY": "VADAMA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680741,
      "TXT_PINCODE_LOCALITY": "ANNAMANADA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680751,
      "TXT_PINCODE_LOCALITY": "MULAYAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680752,
      "TXT_PINCODE_LOCALITY": "KOZHUKULLY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680753,
      "TXT_PINCODE_LOCALITY": "MULLASSERI - 5050",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 680771,
      "TXT_PINCODE_LOCALITY": "ATHANI TRICHUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 515,
      "City District Name": "THRISSUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682001,
      "TXT_PINCODE_LOCALITY": "KOCHI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682002,
      "TXT_PINCODE_LOCALITY": "FORT COCHIN",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682003,
      "TXT_PINCODE_LOCALITY": "WILLINGDON ISLAND",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682004,
      "TXT_PINCODE_LOCALITY": "KOCHI NAVAL BASE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682005,
      "TXT_PINCODE_LOCALITY": "THOPPUMPADY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682006,
      "TXT_PINCODE_LOCALITY": "PALLURUTHY INDUSTRIAL ESTATE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682007,
      "TXT_PINCODE_LOCALITY": "KUMBALANGHI SOUTH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682008,
      "TXT_PINCODE_LOCALITY": "ANDIKADAVU.",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682009,
      "TXT_PINCODE_LOCALITY": "NORTH END",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682011,
      "TXT_PINCODE_LOCALITY": "ERNAKULAM HIGH COURT",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682012,
      "TXT_PINCODE_LOCALITY": "PACHALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682014,
      "TXT_PINCODE_LOCALITY": "TATAPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682015,
      "TXT_PINCODE_LOCALITY": "PERUMANNUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682016,
      "TXT_PINCODE_LOCALITY": "ERNAKULAM SOUTH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682017,
      "TXT_PINCODE_LOCALITY": "KOLLAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682018,
      "TXT_PINCODE_LOCALITY": "ERNAKULAM NORTH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682019,
      "TXT_PINCODE_LOCALITY": "VYTHILA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682020,
      "TXT_PINCODE_LOCALITY": "KADAVANTHARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682021,
      "TXT_PINCODE_LOCALITY": "THRIKKAKARA S.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 502,
      "City District Name": "KOCHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682022,
      "TXT_PINCODE_LOCALITY": "KOCHI UNIVERSITY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682023,
      "TXT_PINCODE_LOCALITY": "VADUTHALA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682024,
      "TXT_PINCODE_LOCALITY": "EDAPALLY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682025,
      "TXT_PINCODE_LOCALITY": "PALARIVATTOM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682026,
      "TXT_PINCODE_LOCALITY": "DESHABHIMANI ROAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682027,
      "TXT_PINCODE_LOCALITY": "PIZHALA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682028,
      "TXT_PINCODE_LOCALITY": "THRIKAKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682029,
      "TXT_PINCODE_LOCALITY": "SOUTH ENT",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682030,
      "TXT_PINCODE_LOCALITY": "KAKKANAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682031,
      "TXT_PINCODE_LOCALITY": "ERNAKULAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 194,
      "City District Name": "COCHIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682032,
      "TXT_PINCODE_LOCALITY": "KATHRIKADAVU ROAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682033,
      "TXT_PINCODE_LOCALITY": "CHENGAMPUZHA NAGAR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682034,
      "TXT_PINCODE_LOCALITY": "CHERANALLUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682035,
      "TXT_PINCODE_LOCALITY": "CONVENT ROAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682036,
      "TXT_PINCODE_LOCALITY": "PANAMPILLY NAGAR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682037,
      "TXT_PINCODE_LOCALITY": "CHITTETHUKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682038,
      "TXT_PINCODE_LOCALITY": "POONITHURA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682123,
      "TXT_PINCODE_LOCALITY": "THIDANAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682301,
      "TXT_PINCODE_LOCALITY": "TRIPUNITHURA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682302,
      "TXT_PINCODE_LOCALITY": "VYTILLA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 194,
      "City District Name": "COCHIN",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682303,
      "TXT_PINCODE_LOCALITY": "AMBALAMEDU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682304,
      "TXT_PINCODE_LOCALITY": "NETTTUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682305,
      "TXT_PINCODE_LOCALITY": "KOKKAPPILLY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682306,
      "TXT_PINCODE_LOCALITY": "EROOR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682307,
      "TXT_PINCODE_LOCALITY": "POTHOTTA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682308,
      "TXT_PINCODE_LOCALITY": "THRUVANIYUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682309,
      "TXT_PINCODE_LOCALITY": "IRMPANAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682310,
      "TXT_PINCODE_LOCALITY": "VADAVUCODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682311,
      "TXT_PINCODE_LOCALITY": "KINGINIMATTOM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682312,
      "TXT_PINCODE_LOCALITY": "KUZHIYARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682313,
      "TXT_PINCODE_LOCALITY": "ARAKUNNAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682314,
      "TXT_PINCODE_LOCALITY": "PAINGARAPALLY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682315,
      "TXT_PINCODE_LOCALITY": "KULAYETTIKKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682316,
      "TXT_PINCODE_LOCALITY": "KUNNACKAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682317,
      "TXT_PINCODE_LOCALITY": "ERNAKULAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682319,
      "TXT_PINCODE_LOCALITY": "VELIYANADU EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682320,
      "TXT_PINCODE_LOCALITY": "SOUTH PARAVOOR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682501,
      "TXT_PINCODE_LOCALITY": "AYYAMPILLY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682502,
      "TXT_PINCODE_LOCALITY": "EDAVANAKAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682503,
      "TXT_PINCODE_LOCALITY": "ELAMKUNNAPUZHA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682504,
      "TXT_PINCODE_LOCALITY": "MULAVUKAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682505,
      "TXT_PINCODE_LOCALITY": "NARAKKAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682506,
      "TXT_PINCODE_LOCALITY": "PANAGAD THEVARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682507,
      "TXT_PINCODE_LOCALITY": "MUNDENVELI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682508,
      "TXT_PINCODE_LOCALITY": "OCHANATHURUTHU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682509,
      "TXT_PINCODE_LOCALITY": "NEDUMANGAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682510,
      "TXT_PINCODE_LOCALITY": "AZHIKKAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682511,
      "TXT_PINCODE_LOCALITY": "MALIPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682551,
      "TXT_PINCODE_LOCALITY": "ANDROTH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682552,
      "TXT_PINCODE_LOCALITY": "AMINI (LAK)",
      "NUM_STATE_CD": 163,
      "State Name": "LAKSHADWEEP",
      "NUM_CITYDISTRICT_CD": 6283,
      "City District Name": "LAKSHADWEEP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682553,
      "TXT_PINCODE_LOCALITY": "AGATHI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682554,
      "TXT_PINCODE_LOCALITY": "CHETLAT",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682555,
      "TXT_PINCODE_LOCALITY": "KAVARATTI",
      "NUM_STATE_CD": 163,
      "State Name": "LAKSHADWEEP",
      "NUM_CITYDISTRICT_CD": 6283,
      "City District Name": "LAKSHADWEEP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682557,
      "TXT_PINCODE_LOCALITY": "KALPENI",
      "NUM_STATE_CD": 163,
      "State Name": "LAKSHADWEEP",
      "NUM_CITYDISTRICT_CD": 6283,
      "City District Name": "LAKSHADWEEP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682558,
      "TXT_PINCODE_LOCALITY": "KILTAN",
      "NUM_STATE_CD": 163,
      "State Name": "LAKSHADWEEP",
      "NUM_CITYDISTRICT_CD": 6283,
      "City District Name": "LAKSHADWEEP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 682559,
      "TXT_PINCODE_LOCALITY": "MINICOY (LAK)",
      "NUM_STATE_CD": 163,
      "State Name": "LAKSHADWEEP",
      "NUM_CITYDISTRICT_CD": 6283,
      "City District Name": "LAKSHADWEEP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683101,
      "TXT_PINCODE_LOCALITY": "ERNAKULAM NORTH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683102,
      "TXT_PINCODE_LOCALITY": "WEST KADUNGALLUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683103,
      "TXT_PINCODE_LOCALITY": "DESOM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683104,
      "TXT_PINCODE_LOCALITY": "NORTH KALAMASSERY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683105,
      "TXT_PINCODE_LOCALITY": "SOUTH VAZHAKULAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683106,
      "TXT_PINCODE_LOCALITY": "THAIKKATTUKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683107,
      "TXT_PINCODE_LOCALITY": "MARAMPALLY EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683108,
      "TXT_PINCODE_LOCALITY": "THOTTAKKATTUKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683109,
      "TXT_PINCODE_LOCALITY": "KALAMESSERI DEVELOPMENT",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683110,
      "TXT_PINCODE_LOCALITY": "MUPPATHADAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683111,
      "TXT_PINCODE_LOCALITY": "KOCHI AIRPORT",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683112,
      "TXT_PINCODE_LOCALITY": "VAZHAKULAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683501,
      "TXT_PINCODE_LOCALITY": "MANJUMMEL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683502,
      "TXT_PINCODE_LOCALITY": "BINANIPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683503,
      "TXT_PINCODE_LOCALITY": "HMT ROAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683504,
      "TXT_PINCODE_LOCALITY": "KUTTIKATTUKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683511,
      "TXT_PINCODE_LOCALITY": "ALANGAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683512,
      "TXT_PINCODE_LOCALITY": "CHENDAMANGALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683513,
      "TXT_PINCODE_LOCALITY": "NANTHIATTUKUNNAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683514,
      "TXT_PINCODE_LOCALITY": "CHERAI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683515,
      "TXT_PINCODE_LOCALITY": "PALLIPORT",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683516,
      "TXT_PINCODE_LOCALITY": "MOOTHAKUNNAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683517,
      "TXT_PINCODE_LOCALITY": "VARAPUZHA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683518,
      "TXT_PINCODE_LOCALITY": "KOONAMMAVU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683519,
      "TXT_PINCODE_LOCALITY": "KAITHARAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683520,
      "TXT_PINCODE_LOCALITY": "MANNAM PARUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683521,
      "TXT_PINCODE_LOCALITY": "VADAKKUMPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683522,
      "TXT_PINCODE_LOCALITY": "KUNJITHAI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683523,
      "TXT_PINCODE_LOCALITY": "GOTHURUTHY EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683524,
      "TXT_PINCODE_LOCALITY": "KUNNUKARA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683525,
      "TXT_PINCODE_LOCALITY": "KONGORPILLY EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683541,
      "TXT_PINCODE_LOCALITY": "PULLUVAZHI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683542,
      "TXT_PINCODE_LOCALITY": "PERUMBAVOOR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683543,
      "TXT_PINCODE_LOCALITY": "RAYONPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683544,
      "TXT_PINCODE_LOCALITY": "ILAMBAKAPALLY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683545,
      "TXT_PINCODE_LOCALITY": "METHALA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683546,
      "TXT_PINCODE_LOCALITY": "CHOONDAKUZHY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683547,
      "TXT_PINCODE_LOCALITY": "PONJASSERY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683548,
      "TXT_PINCODE_LOCALITY": "IRINGOLE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683549,
      "TXT_PINCODE_LOCALITY": "VAIKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683550,
      "TXT_PINCODE_LOCALITY": "OKKAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683553,
      "TXT_PINCODE_LOCALITY": "ALLAPRA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683554,
      "TXT_PINCODE_LOCALITY": "VENGOLA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683555,
      "TXT_PINCODE_LOCALITY": "KOMBANAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683556,
      "TXT_PINCODE_LOCALITY": "VENGOLA WEST",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683557,
      "TXT_PINCODE_LOCALITY": "TRIKALATHUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683561,
      "TXT_PINCODE_LOCALITY": "VILANGU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683562,
      "TXT_PINCODE_LOCALITY": "PATTIMATTOM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683563,
      "TXT_PINCODE_LOCALITY": "NAVAL ARMAMENT DEPOT.",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683564,
      "TXT_PINCODE_LOCALITY": "EDATHALA NORTH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683565,
      "TXT_PINCODE_LOCALITY": "PERINGALA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683571,
      "TXT_PINCODE_LOCALITY": "CHOWWERA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683572,
      "TXT_PINCODE_LOCALITY": "VAPPALASSERY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683573,
      "TXT_PINCODE_LOCALITY": "ANGAMALLY SOUTH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683574,
      "TXT_PINCODE_LOCALITY": "MANICKAMANGALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683575,
      "TXT_PINCODE_LOCALITY": "KANJOOR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683576,
      "TXT_PINCODE_LOCALITY": "KARUKUTTY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683577,
      "TXT_PINCODE_LOCALITY": "THABORE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683578,
      "TXT_PINCODE_LOCALITY": "CHENGAMANAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683579,
      "TXT_PINCODE_LOCALITY": "VATTAPARAMPU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683580,
      "TXT_PINCODE_LOCALITY": "VELLARAPPILLY SOUTH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683581,
      "TXT_PINCODE_LOCALITY": "MANJAPPARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683582,
      "TXT_PINCODE_LOCALITY": "PADUVAPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683583,
      "TXT_PINCODE_LOCALITY": "KALADI PLANTATION",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683584,
      "TXT_PINCODE_LOCALITY": "NEELESHWARAM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683585,
      "TXT_PINCODE_LOCALITY": "NEDUMBASSERI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683586,
      "TXT_PINCODE_LOCALITY": "THURAVOOR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683587,
      "TXT_PINCODE_LOCALITY": "MALAYATTUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683589,
      "TXT_PINCODE_LOCALITY": "MEKKAD EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683591,
      "TXT_PINCODE_LOCALITY": "KIDANGOOR ANGAMALLY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683592,
      "TXT_PINCODE_LOCALITY": "NAYATHODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683593,
      "TXT_PINCODE_LOCALITY": "PARAPURAM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 683594,
      "TXT_PINCODE_LOCALITY": "PUTHENVELI EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685005,
      "TXT_PINCODE_LOCALITY": "THAZHATHANGADI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685014,
      "TXT_PINCODE_LOCALITY": "VAZHUTHACAUD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685501,
      "TXT_PINCODE_LOCALITY": "ELAPPARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685502,
      "TXT_PINCODE_LOCALITY": "PASUPARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685503,
      "TXT_PINCODE_LOCALITY": "VAGAMON",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685504,
      "TXT_PINCODE_LOCALITY": "FAIRFIELD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685505,
      "TXT_PINCODE_LOCALITY": "UPPUTHARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685506,
      "TXT_PINCODE_LOCALITY": "KARIMKULAM CHAPATH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685507,
      "TXT_PINCODE_LOCALITY": "AYYAPPANCOIL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685508,
      "TXT_PINCODE_LOCALITY": "KATTAPANA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685509,
      "TXT_PINCODE_LOCALITY": "KUMILY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685510,
      "TXT_PINCODE_LOCALITY": "IDINJAMALA B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685511,
      "TXT_PINCODE_LOCALITY": "KANCHIYAR P.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685512,
      "TXT_PINCODE_LOCALITY": "ANNAKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685521,
      "TXT_PINCODE_LOCALITY": "ADIMALI *",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685531,
      "TXT_PINCODE_LOCALITY": "PEERMADE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685532,
      "TXT_PINCODE_LOCALITY": "PERUVANTHANAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685533,
      "TXT_PINCODE_LOCALITY": "VANDIPERIYAR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685535,
      "TXT_PINCODE_LOCALITY": "MURUKADY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685536,
      "TXT_PINCODE_LOCALITY": "THEKKADY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685537,
      "TXT_PINCODE_LOCALITY": "PAMBANAR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685538,
      "TXT_PINCODE_LOCALITY": "THENGAKAL EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685539,
      "TXT_PINCODE_LOCALITY": "CHENKARA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685551,
      "TXT_PINCODE_LOCALITY": "VANNAPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685552,
      "TXT_PINCODE_LOCALITY": "KOOTTAR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685553,
      "TXT_PINCODE_LOCALITY": "NEDUMKANDAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685556,
      "TXT_PINCODE_LOCALITY": "PAMPADUMPARA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685561,
      "TXT_PINCODE_LOCALITY": "ADIMALY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685562,
      "TXT_PINCODE_LOCALITY": "KALLARKUTTY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685563,
      "TXT_PINCODE_LOCALITY": "VELLATHOOVAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685564,
      "TXT_PINCODE_LOCALITY": "PONMUDI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685565,
      "TXT_PINCODE_LOCALITY": "PALLIVASAL B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685566,
      "TXT_PINCODE_LOCALITY": "RAJAKAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685569,
      "TXT_PINCODE_LOCALITY": "POTTENKAD EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685571,
      "TXT_PINCODE_LOCALITY": "PARATHODE IDUKKY EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685581,
      "TXT_PINCODE_LOCALITY": "THOMMANKUTHU B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685582,
      "TXT_PINCODE_LOCALITY": "VANNAPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685583,
      "TXT_PINCODE_LOCALITY": "VAZHITHALA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685584,
      "TXT_PINCODE_LOCALITY": "THODUPUZHA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685585,
      "TXT_PINCODE_LOCALITY": "THODUPUZHA EAST",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685586,
      "TXT_PINCODE_LOCALITY": "KARIMKUNNAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685587,
      "TXT_PINCODE_LOCALITY": "MUTTOM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685588,
      "TXT_PINCODE_LOCALITY": "KALAYANTHANI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685589,
      "TXT_PINCODE_LOCALITY": "MOOLAMATTOM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685590,
      "TXT_PINCODE_LOCALITY": "KOOVAPALLY KUDAYATHUR B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685591,
      "TXT_PINCODE_LOCALITY": "ARAKULAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685592,
      "TXT_PINCODE_LOCALITY": "KANJAR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685595,
      "TXT_PINCODE_LOCALITY": "UDUMBANNUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685597,
      "TXT_PINCODE_LOCALITY": "KUMARAMANGALAM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685598,
      "TXT_PINCODE_LOCALITY": "KOLANI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685602,
      "TXT_PINCODE_LOCALITY": "IDUKKI COLONY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685604,
      "TXT_PINCODE_LOCALITY": "MURIKKASSERY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685605,
      "TXT_PINCODE_LOCALITY": "MUTHALAKODAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685608,
      "TXT_PINCODE_LOCALITY": "VENGALLUR S.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685611,
      "TXT_PINCODE_LOCALITY": "VATTIAR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685612,
      "TXT_PINCODE_LOCALITY": "MUNNAR KERALA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685613,
      "TXT_PINCODE_LOCALITY": "DEVICOLAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685614,
      "TXT_PINCODE_LOCALITY": "TALLIAR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685615,
      "TXT_PINCODE_LOCALITY": "ELLAPATTI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685616,
      "TXT_PINCODE_LOCALITY": "MATTUPATTI INDO SWISS PROJECT",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685617,
      "TXT_PINCODE_LOCALITY": "SETHUPARVATHIPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685618,
      "TXT_PINCODE_LOCALITY": "SURIANALLE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685619,
      "TXT_PINCODE_LOCALITY": "SANTHANPARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685620,
      "TXT_PINCODE_LOCALITY": "MARAIYUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 685621,
      "TXT_PINCODE_LOCALITY": "PANNIAR ESTATE EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686001,
      "TXT_PINCODE_LOCALITY": "KOTTAYAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686002,
      "TXT_PINCODE_LOCALITY": "KOTTAYAM COLLECTORATE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686003,
      "TXT_PINCODE_LOCALITY": "KOTTAYAM WEST",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686004,
      "TXT_PINCODE_LOCALITY": "MUTTAMBALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686005,
      "TXT_PINCODE_LOCALITY": "THAZHATHANGADI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686006,
      "TXT_PINCODE_LOCALITY": "NATTASSERY S.H.MOUNT",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686007,
      "TXT_PINCODE_LOCALITY": "PALLOM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686008,
      "TXT_PINCODE_LOCALITY": "GANDHI NAGAR KOTTAYAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686009,
      "TXT_PINCODE_LOCALITY": "RUBBER BOARD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686010,
      "TXT_PINCODE_LOCALITY": "VADAVATHOOR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686011,
      "TXT_PINCODE_LOCALITY": "THRIKOTHAMANGALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686012,
      "TXT_PINCODE_LOCALITY": "POOVANTHURUTHU EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686013,
      "TXT_PINCODE_LOCALITY": "NATTAKAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686014,
      "TXT_PINCODE_LOCALITY": "OLASSA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686015,
      "TXT_PINCODE_LOCALITY": "PULIKUTTISSERI B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686016,
      "TXT_PINCODE_LOCALITY": "THELLAKAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686017,
      "TXT_PINCODE_LOCALITY": "KUDAMALOOR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686018,
      "TXT_PINCODE_LOCALITY": "MANGANAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686019,
      "TXT_PINCODE_LOCALITY": "MANARCAUD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686020,
      "TXT_PINCODE_LOCALITY": "KILIROOR NORTH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686021,
      "TXT_PINCODE_LOCALITY": "PARIYARAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686022,
      "TXT_PINCODE_LOCALITY": "CHENGALAM SOUTH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686023,
      "TXT_PINCODE_LOCALITY": "MARIAPALLY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686024,
      "TXT_PINCODE_LOCALITY": "PARIPPU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686025,
      "TXT_PINCODE_LOCALITY": "AMAYANNUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686026,
      "TXT_PINCODE_LOCALITY": "MOOLAVATTOM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686027,
      "TXT_PINCODE_LOCALITY": "MARIATHURUTHU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686028,
      "TXT_PINCODE_LOCALITY": "PERUMBAIKAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686029,
      "TXT_PINCODE_LOCALITY": "KOLLAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686030,
      "TXT_PINCODE_LOCALITY": "KANJIRAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686031,
      "TXT_PINCODE_LOCALITY": "MALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686032,
      "TXT_PINCODE_LOCALITY": "PARAMPUZHA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686033,
      "TXT_PINCODE_LOCALITY": "THIRUVARPU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686034,
      "TXT_PINCODE_LOCALITY": "PAYYAPADY EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686035,
      "TXT_PINCODE_LOCALITY": "KUMMANAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686036,
      "TXT_PINCODE_LOCALITY": "AMALAGIRI EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686037,
      "TXT_PINCODE_LOCALITY": "THIRUVANCHOOR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686038,
      "TXT_PINCODE_LOCALITY": "DEVALOKOM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686041,
      "TXT_PINCODE_LOCALITY": "MALLOOSERY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686100,
      "TXT_PINCODE_LOCALITY": "CHANGANACHEERY INDUS.NGR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686101,
      "TXT_PINCODE_LOCALITY": "CHANGANACHERRY HO.",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686102,
      "TXT_PINCODE_LOCALITY": "PANACHIKAVU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686103,
      "TXT_PINCODE_LOCALITY": "VAZHAPALLY WEST",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686104,
      "TXT_PINCODE_LOCALITY": "VEROOR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686105,
      "TXT_PINCODE_LOCALITY": "KOTTAMURICKAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686106,
      "TXT_PINCODE_LOCALITY": "KALATHIPADY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686107,
      "TXT_PINCODE_LOCALITY": "KIDANGARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686121,
      "TXT_PINCODE_LOCALITY": "ERATTUPETTAH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686122,
      "TXT_PINCODE_LOCALITY": "ARUVITHURA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686123,
      "TXT_PINCODE_LOCALITY": "THIDANAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686124,
      "TXT_PINCODE_LOCALITY": "NADACKAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686141,
      "TXT_PINCODE_LOCALITY": "VAIKOM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686142,
      "TXT_PINCODE_LOCALITY": "VAIKAPRAYAR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686143,
      "TXT_PINCODE_LOCALITY": "AKKARAPADAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686144,
      "TXT_PINCODE_LOCALITY": "VECHOOR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686145,
      "TXT_PINCODE_LOCALITY": "THOTTAKOM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686146,
      "TXT_PINCODE_LOCALITY": "PADINJARAKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686501,
      "TXT_PINCODE_LOCALITY": "VELLOOR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686502,
      "TXT_PINCODE_LOCALITY": "POTHENPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686503,
      "TXT_PINCODE_LOCALITY": "MOZHOOR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686504,
      "TXT_PINCODE_LOCALITY": "VAZHOOR EAST",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686505,
      "TXT_PINCODE_LOCALITY": "THEERTHAPADAPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686506,
      "TXT_PINCODE_LOCALITY": "PONKUNNAM KOTTAYAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686507,
      "TXT_PINCODE_LOCALITY": "MANNARKAYAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686508,
      "TXT_PINCODE_LOCALITY": "VANCHIMALA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686509,
      "TXT_PINCODE_LOCALITY": "KANNIMALA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686510,
      "TXT_PINCODE_LOCALITY": "KANAMALA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686511,
      "TXT_PINCODE_LOCALITY": "KUNNAM VEECHOOCHIRA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686512,
      "TXT_PINCODE_LOCALITY": "URAKANADU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686513,
      "TXT_PINCODE_LOCALITY": "MUNDAKAYAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686514,
      "TXT_PINCODE_LOCALITY": "THALUNKAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686515,
      "TXT_PINCODE_LOCALITY": "KANAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686516,
      "TXT_PINCODE_LOCALITY": "VATTAKUNNU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686517,
      "TXT_PINCODE_LOCALITY": "CHAKOMPATHAI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686518,
      "TXT_PINCODE_LOCALITY": "PANACHAPPALLY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686519,
      "TXT_PINCODE_LOCALITY": "CHIRAKADAVU CENTRE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686520,
      "TXT_PINCODE_LOCALITY": "CHIRAKADAVU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686521,
      "TXT_PINCODE_LOCALITY": "POOTHAKUZHY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686522,
      "TXT_PINCODE_LOCALITY": "PANAMKUTTY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686523,
      "TXT_PINCODE_LOCALITY": "KOZHUVANAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686524,
      "TXT_PINCODE_LOCALITY": "CHITTADI EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686525,
      "TXT_PINCODE_LOCALITY": "KOZHUVANAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686527,
      "TXT_PINCODE_LOCALITY": "VIZHIKKITHODE EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686529,
      "TXT_PINCODE_LOCALITY": "CHEMMALAMATTOM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686531,
      "TXT_PINCODE_LOCALITY": "CHENGAVANAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686532,
      "TXT_PINCODE_LOCALITY": "SACHIVOTHAMAPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686533,
      "TXT_PINCODE_LOCALITY": "KUZHIMATTOM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686534,
      "TXT_PINCODE_LOCALITY": "ERA NORTH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686535,
      "TXT_PINCODE_LOCALITY": "ITHITHANAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686536,
      "TXT_PINCODE_LOCALITY": "KURUMBANADOM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686537,
      "TXT_PINCODE_LOCALITY": "PALLICKACHIRAKVALA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686538,
      "TXT_PINCODE_LOCALITY": "VAKATHANAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686539,
      "TXT_PINCODE_LOCALITY": "UMBIDI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686540,
      "TXT_PINCODE_LOCALITY": "CHELACOMBU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686541,
      "TXT_PINCODE_LOCALITY": "THAZHATTHUVADKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686542,
      "TXT_PINCODE_LOCALITY": "MANTHURUTHY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686543,
      "TXT_PINCODE_LOCALITY": "MANIMALA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686544,
      "TXT_PINCODE_LOCALITY": "PULIKALLU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686545,
      "TXT_PINCODE_LOCALITY": "SANTHIPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686546,
      "TXT_PINCODE_LOCALITY": "MADAPPALLY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686547,
      "TXT_PINCODE_LOCALITY": "KOTTANGAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686548,
      "TXT_PINCODE_LOCALITY": "NALUKODY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686549,
      "TXT_PINCODE_LOCALITY": "KURICHI EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686550,
      "TXT_PINCODE_LOCALITY": "KADAYANIKAD EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686552,
      "TXT_PINCODE_LOCALITY": "MALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686553,
      "TXT_PINCODE_LOCALITY": "MAMMOODU EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686555,
      "TXT_PINCODE_LOCALITY": "KANJIRAPARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686560,
      "TXT_PINCODE_LOCALITY": "PRIYADARSHINI HILLS",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686561,
      "TXT_PINCODE_LOCALITY": "MANNAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686562,
      "TXT_PINCODE_LOCALITY": "MUKKUDAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686563,
      "TXT_PINCODE_LOCALITY": "KUMARAKOM EAST",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686564,
      "TXT_PINCODE_LOCALITY": "KARIMPANI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686565,
      "TXT_PINCODE_LOCALITY": "KARIMPANI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686568,
      "TXT_PINCODE_LOCALITY": "ARUMANUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686571,
      "TXT_PINCODE_LOCALITY": "KADAPLAMATTOM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686572,
      "TXT_PINCODE_LOCALITY": "KIDANGOOR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686573,
      "TXT_PINCODE_LOCALITY": "THODANAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686574,
      "TXT_PINCODE_LOCALITY": "MUNDANKAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686575,
      "TXT_PINCODE_LOCALITY": "PALAI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686576,
      "TXT_PINCODE_LOCALITY": "MEDIRI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686577,
      "TXT_PINCODE_LOCALITY": "POOVARANI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686578,
      "TXT_PINCODE_LOCALITY": "BHARANAGANAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686579,
      "TXT_PINCODE_LOCALITY": "ANJOOTTIMANGALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686580,
      "TXT_PINCODE_LOCALITY": "ADUKOM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686581,
      "TXT_PINCODE_LOCALITY": "POONJAR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686582,
      "TXT_PINCODE_LOCALITY": "CHOLATHADAM P.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686583,
      "TXT_PINCODE_LOCALITY": "KIDANGOOR SOUTH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686584,
      "TXT_PINCODE_LOCALITY": "CHEMPILAVU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686585,
      "TXT_PINCODE_LOCALITY": "CHENGALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686586,
      "TXT_PINCODE_LOCALITY": "CHOVVOOR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686587,
      "TXT_PINCODE_LOCALITY": "VAYALA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686588,
      "TXT_PINCODE_LOCALITY": "IDAMATTOM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686589,
      "TXT_PINCODE_LOCALITY": "MEENACHIL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686590,
      "TXT_PINCODE_LOCALITY": "PONAD KARUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686591,
      "TXT_PINCODE_LOCALITY": "RAMAPURAM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686592,
      "TXT_PINCODE_LOCALITY": "VALLICHIRA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686594,
      "TXT_PINCODE_LOCALITY": "MELAMPARA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686595,
      "TXT_PINCODE_LOCALITY": "KOODALLUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686596,
      "TXT_PINCODE_LOCALITY": "VATTUKULAM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686597,
      "TXT_PINCODE_LOCALITY": "MUTHOLY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686600,
      "TXT_PINCODE_LOCALITY": "PATHAMPUZHA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686601,
      "TXT_PINCODE_LOCALITY": "NEENDOOR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686602,
      "TXT_PINCODE_LOCALITY": "KAIPUZHA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686603,
      "TXT_PINCODE_LOCALITY": "MANJOOR SOUTH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686604,
      "TXT_PINCODE_LOCALITY": "PUZHIKOL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686605,
      "TXT_PINCODE_LOCALITY": "VADAYAR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686606,
      "TXT_PINCODE_LOCALITY": "THIRUMONIVENKITAPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686607,
      "TXT_PINCODE_LOCALITY": "KOTHAVARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686608,
      "TXT_PINCODE_LOCALITY": "MARAVANTHURUTHU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686609,
      "TXT_PINCODE_LOCALITY": "MEVALLOOR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686610,
      "TXT_PINCODE_LOCALITY": "MULAKULAM SOUTH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686611,
      "TXT_PINCODE_LOCALITY": "PERUMTHURUTHU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686612,
      "TXT_PINCODE_LOCALITY": "NJIZHOOR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686613,
      "TXT_PINCODE_LOCALITY": "MUTTUCHIRA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686614,
      "TXT_PINCODE_LOCALITY": "BRAHMAMANGALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686615,
      "TXT_PINCODE_LOCALITY": "CHEMPU EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686616,
      "TXT_PINCODE_LOCALITY": "NEWSPRINT NAGAR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686617,
      "TXT_PINCODE_LOCALITY": "MEMURI EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686618,
      "TXT_PINCODE_LOCALITY": "MARANGOLI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686620,
      "TXT_PINCODE_LOCALITY": "MEVADA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686631,
      "TXT_PINCODE_LOCALITY": "ETTUMANOOR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686632,
      "TXT_PINCODE_LOCALITY": "KURUMALLUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686633,
      "TXT_PINCODE_LOCALITY": "NAZARETH HILL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686634,
      "TXT_PINCODE_LOCALITY": "OZHAVUR EAST",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686635,
      "TXT_PINCODE_LOCALITY": "PALAKKATTUMALA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686636,
      "TXT_PINCODE_LOCALITY": "KURIANAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686638,
      "TXT_PINCODE_LOCALITY": "VELIYANNUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686640,
      "TXT_PINCODE_LOCALITY": "KOZHA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686641,
      "TXT_PINCODE_LOCALITY": "PUTHUVELI EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686642,
      "TXT_PINCODE_LOCALITY": "PAKALOMATTOM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686651,
      "TXT_PINCODE_LOCALITY": "MATTATHIPARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686652,
      "TXT_PINCODE_LOCALITY": "MELUKAVUMATTOM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686653,
      "TXT_PINCODE_LOCALITY": "KADANAD MEENACHIL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686654,
      "TXT_PINCODE_LOCALITY": "KURUMANNU EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686655,
      "TXT_PINCODE_LOCALITY": "PIZHAKU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686659,
      "TXT_PINCODE_LOCALITY": "VALLICODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686661,
      "TXT_PINCODE_LOCALITY": "MINICOY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686662,
      "TXT_PINCODE_LOCALITY": "EDAYAR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686663,
      "TXT_PINCODE_LOCALITY": "MAMALASSERY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686664,
      "TXT_PINCODE_LOCALITY": "PIRAVOM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686665,
      "TXT_PINCODE_LOCALITY": "MUTHOLAPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686666,
      "TXT_PINCODE_LOCALITY": "KOTHAMANGALAM COLLEGE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686667,
      "TXT_PINCODE_LOCALITY": "AVOLI EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686668,
      "TXT_PINCODE_LOCALITY": "KAVAKKAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686669,
      "TXT_PINCODE_LOCALITY": "MAZHUVAVANNUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686670,
      "TXT_PINCODE_LOCALITY": "VAZHAKULAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686671,
      "TXT_PINCODE_LOCALITY": "PAINGATTOOR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686672,
      "TXT_PINCODE_LOCALITY": "MEMADANGU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686673,
      "TXT_PINCODE_LOCALITY": "MUVATTUPUZHA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1152,
      "City District Name": "MUVATTUPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686674,
      "TXT_PINCODE_LOCALITY": "PEZHAKAPALLY EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686676,
      "TXT_PINCODE_LOCALITY": "AYAVANA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686679,
      "TXT_PINCODE_LOCALITY": "OLIYAPURAM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686681,
      "TXT_PINCODE_LOCALITY": "CHELAD JN. EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686686,
      "TXT_PINCODE_LOCALITY": "PALAKUZHA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686687,
      "TXT_PINCODE_LOCALITY": "THIRUMARADI EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686689,
      "TXT_PINCODE_LOCALITY": "MAZHUVANNUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686691,
      "TXT_PINCODE_LOCALITY": "KOHIPPILLY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686692,
      "TXT_PINCODE_LOCALITY": "PANIPRA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686693,
      "TXT_PINCODE_LOCALITY": "PERUMANOOR-KOTHAMANGALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686694,
      "TXT_PINCODE_LOCALITY": "IDAMALYAR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 499,
      "City District Name": "IDUKKI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686695,
      "TXT_PINCODE_LOCALITY": "KOTTAPADI EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686696,
      "TXT_PINCODE_LOCALITY": "NJAYAPALLY B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 498,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686697,
      "TXT_PINCODE_LOCALITY": "THAZHEKKAD EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686698,
      "TXT_PINCODE_LOCALITY": "PINDIMANA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686721,
      "TXT_PINCODE_LOCALITY": "NELLAD EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686723,
      "TXT_PINCODE_LOCALITY": "MANNATHUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686726,
      "TXT_PINCODE_LOCALITY": "MANIDU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 686730,
      "TXT_PINCODE_LOCALITY": "OORAMANA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688001,
      "TXT_PINCODE_LOCALITY": "ALAPPUZHA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688002,
      "TXT_PINCODE_LOCALITY": "THIRUVAMBADI JN",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688003,
      "TXT_PINCODE_LOCALITY": "VADACKAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688004,
      "TXT_PINCODE_LOCALITY": "PUNNAPARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688005,
      "TXT_PINCODE_LOCALITY": "KANJIPADAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688006,
      "TXT_PINCODE_LOCALITY": "VEMBANAD KAYAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688008,
      "TXT_PINCODE_LOCALITY": "THUMPOLY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688009,
      "TXT_PINCODE_LOCALITY": "PAZHAVEEDU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688010,
      "TXT_PINCODE_LOCALITY": "ALLEPPEY MULLAKKA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688011,
      "TXT_PINCODE_LOCALITY": "ALAPPUZHA IRON BRIDGE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688012,
      "TXT_PINCODE_LOCALITY": "ALAPPUZHA BAZAR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688013,
      "TXT_PINCODE_LOCALITY": "THATHAPALLI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688014,
      "TXT_PINCODE_LOCALITY": "PUNNAPRA NORTH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688501,
      "TXT_PINCODE_LOCALITY": "KAINAKARY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688502,
      "TXT_PINCODE_LOCALITY": "MONCOMBU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688503,
      "TXT_PINCODE_LOCALITY": "MONCOMBU THEKKEKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688504,
      "TXT_PINCODE_LOCALITY": "KANNADI WEST",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688505,
      "TXT_PINCODE_LOCALITY": "VYSAMBHAGOM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688506,
      "TXT_PINCODE_LOCALITY": "KAVALAM NORTH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688507,
      "TXT_PINCODE_LOCALITY": "KANNADI EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688508,
      "TXT_PINCODE_LOCALITY": "NEDUMUDI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688511,
      "TXT_PINCODE_LOCALITY": "MALIPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688512,
      "TXT_PINCODE_LOCALITY": "PONGA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688521,
      "TXT_PINCODE_LOCALITY": "PATHIRAPALLY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688522,
      "TXT_PINCODE_LOCALITY": "KALAVOOR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688523,
      "TXT_PINCODE_LOCALITY": "SETHULEKSHMIPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688524,
      "TXT_PINCODE_LOCALITY": "CHERTHALA SOUTH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688525,
      "TXT_PINCODE_LOCALITY": "MUHAMMA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688526,
      "TXT_PINCODE_LOCALITY": "OLAVAIPU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688527,
      "TXT_PINCODE_LOCALITY": "KANNANKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688528,
      "TXT_PINCODE_LOCALITY": "THYCATTUSSERY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688529,
      "TXT_PINCODE_LOCALITY": "KADAKKARAPALLY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688530,
      "TXT_PINCODE_LOCALITY": "CHETHY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688531,
      "TXT_PINCODE_LOCALITY": "ANDHAKARANAZHI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688532,
      "TXT_PINCODE_LOCALITY": "THURAVOOR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688533,
      "TXT_PINCODE_LOCALITY": "KUTHIATHODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688534,
      "TXT_PINCODE_LOCALITY": "AROOR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688535,
      "TXT_PINCODE_LOCALITY": "VADUTHALA JETTY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688536,
      "TXT_PINCODE_LOCALITY": "VAYALAR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688537,
      "TXT_PINCODE_LOCALITY": "IRAMALLUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688538,
      "TXT_PINCODE_LOCALITY": "MANNAMCHERY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688539,
      "TXT_PINCODE_LOCALITY": "MAYITHARA MARKET",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688540,
      "TXT_PINCODE_LOCALITY": "PARAYAKAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688541,
      "TXT_PINCODE_LOCALITY": "PALLIPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688542,
      "TXT_PINCODE_LOCALITY": "ARYAD NORTH EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688543,
      "TXT_PINCODE_LOCALITY": "VARANAD EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688544,
      "TXT_PINCODE_LOCALITY": "KANICHUKULANGARA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688545,
      "TXT_PINCODE_LOCALITY": "MARUTHOVATTOM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688546,
      "TXT_PINCODE_LOCALITY": "KATTOOR KALAVOOR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688547,
      "TXT_PINCODE_LOCALITY": "CHANDIROOR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688548,
      "TXT_PINCODE_LOCALITY": "EZHUPUNNA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688549,
      "TXT_PINCODE_LOCALITY": "MARARIKULAM NORTH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688550,
      "TXT_PINCODE_LOCALITY": "EZHUPUNNA SOUTH EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688552,
      "TXT_PINCODE_LOCALITY": "SHERTALLY SOUTH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688553,
      "TXT_PINCODE_LOCALITY": "KOCHURAMPAURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688554,
      "TXT_PINCODE_LOCALITY": "THAICKAL EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688555,
      "TXT_PINCODE_LOCALITY": "VARANAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688556,
      "TXT_PINCODE_LOCALITY": "KOCHURAMAPURAM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688557,
      "TXT_PINCODE_LOCALITY": "TIRUNALLUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688558,
      "TXT_PINCODE_LOCALITY": "MANAPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688559,
      "TXT_PINCODE_LOCALITY": "NADUVATHUNAGAR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688561,
      "TXT_PINCODE_LOCALITY": "ILLICHIRA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688562,
      "TXT_PINCODE_LOCALITY": "THAKAZHY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688563,
      "TXT_PINCODE_LOCALITY": "THOTTAPALY EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688564,
      "TXT_PINCODE_LOCALITY": "KARUMADI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688566,
      "TXT_PINCODE_LOCALITY": "PANAVALLY EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688567,
      "TXT_PINCODE_LOCALITY": "POLLATHAI EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688570,
      "TXT_PINCODE_LOCALITY": "PERUMBALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688581,
      "TXT_PINCODE_LOCALITY": "TRICHATTUKULAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688582,
      "TXT_PINCODE_LOCALITY": "SREENARAYANAPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688583,
      "TXT_PINCODE_LOCALITY": "KOKOTHAMANGALAM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688584,
      "TXT_PINCODE_LOCALITY": "PALLITHODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688585,
      "TXT_PINCODE_LOCALITY": "PARAYAKAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688586,
      "TXT_PINCODE_LOCALITY": "PALLITHODE EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 688587,
      "TXT_PINCODE_LOCALITY": "VETTACKAL EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689039,
      "TXT_PINCODE_LOCALITY": "KOTTAYAM SOUTH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689101,
      "TXT_PINCODE_LOCALITY": "THIRUVALLA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1102,
      "City District Name": "THIRUVALLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689102,
      "TXT_PINCODE_LOCALITY": "KALLUMKAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689103,
      "TXT_PINCODE_LOCALITY": "PATHANAMTHITTA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689104,
      "TXT_PINCODE_LOCALITY": "VALANJAVATTOM EAST",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689105,
      "TXT_PINCODE_LOCALITY": "MANJADI JN.",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689106,
      "TXT_PINCODE_LOCALITY": "THENGELY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689107,
      "TXT_PINCODE_LOCALITY": "PERUMTHURUTHY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689108,
      "TXT_PINCODE_LOCALITY": "PERINGARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689109,
      "TXT_PINCODE_LOCALITY": "THIRUNVANVANDOOR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689110,
      "TXT_PINCODE_LOCALITY": "PODIYADI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689111,
      "TXT_PINCODE_LOCALITY": "TIRUVALLA R S",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689112,
      "TXT_PINCODE_LOCALITY": "CHATHANKARI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689113,
      "TXT_PINCODE_LOCALITY": "ALAMTHURUTHI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689114,
      "TXT_PINCODE_LOCALITY": "VEMPALA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689115,
      "TXT_PINCODE_LOCALITY": "THIRUMOOLAPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689121,
      "TXT_PINCODE_LOCALITY": "CHENGANNUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689122,
      "TXT_PINCODE_LOCALITY": "ANGADICAL CHENGANNUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689123,
      "TXT_PINCODE_LOCALITY": "PUTHENCAVE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689124,
      "TXT_PINCODE_LOCALITY": "KALLISSERI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689125,
      "TXT_PINCODE_LOCALITY": "VAZHAMANGALAM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689126,
      "TXT_PINCODE_LOCALITY": "ALA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689501,
      "TXT_PINCODE_LOCALITY": "PANDALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689502,
      "TXT_PINCODE_LOCALITY": "MUTTOM THUMPAMON",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689503,
      "TXT_PINCODE_LOCALITY": "ULLANNUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689504,
      "TXT_PINCODE_LOCALITY": "KARAKKAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689505,
      "TXT_PINCODE_LOCALITY": "PERINGALA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689506,
      "TXT_PINCODE_LOCALITY": "PANDANAD WEST",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689507,
      "TXT_PINCODE_LOCALITY": "KARITHOTTA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689508,
      "TXT_PINCODE_LOCALITY": "NEDUVARANCODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689509,
      "TXT_PINCODE_LOCALITY": "VENMONY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689510,
      "TXT_PINCODE_LOCALITY": "BHUDHANOOR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689511,
      "TXT_PINCODE_LOCALITY": "ELANJIMEL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689512,
      "TXT_PINCODE_LOCALITY": "KUDASSANAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689513,
      "TXT_PINCODE_LOCALITY": "NAIYAPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689514,
      "TXT_PINCODE_LOCALITY": "KIDANGANNUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689515,
      "TXT_PINCODE_LOCALITY": "AYIRANIKUDI EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689516,
      "TXT_PINCODE_LOCALITY": "MUDIYOORKONAM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689517,
      "TXT_PINCODE_LOCALITY": "CHENNEERKARA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689518,
      "TXT_PINCODE_LOCALITY": "PARANTHAL EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689519,
      "TXT_PINCODE_LOCALITY": "MANTHUKA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689520,
      "TXT_PINCODE_LOCALITY": "PENNUKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689521,
      "TXT_PINCODE_LOCALITY": "KOZHUVALLUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689531,
      "TXT_PINCODE_LOCALITY": "POOVATHOOR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689532,
      "TXT_PINCODE_LOCALITY": "MALAKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689533,
      "TXT_PINCODE_LOCALITY": "NALKALIKAL EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689541,
      "TXT_PINCODE_LOCALITY": "VALLAMKULAM EAST",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689542,
      "TXT_PINCODE_LOCALITY": "NELLIMALA B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1102,
      "City District Name": "THIRUVALLA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689543,
      "TXT_PINCODE_LOCALITY": "MADATHUMBHAGOM NORTH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689544,
      "TXT_PINCODE_LOCALITY": "NARAKATHANI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689545,
      "TXT_PINCODE_LOCALITY": "THADIYOOR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689546,
      "TXT_PINCODE_LOCALITY": "OTHARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689547,
      "TXT_PINCODE_LOCALITY": "KUMBANAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689548,
      "TXT_PINCODE_LOCALITY": "KURUNGAZHABHAGOM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689549,
      "TXT_PINCODE_LOCALITY": "MARAMON",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689550,
      "TXT_PINCODE_LOCALITY": "KURIANOOR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689551,
      "TXT_PINCODE_LOCALITY": "OTHARA WEST",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689552,
      "TXT_PINCODE_LOCALITY": "VARAYANNUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689571,
      "TXT_PINCODE_LOCALITY": "AMICHAKARI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689572,
      "TXT_PINCODE_LOCALITY": "THALAVADY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689573,
      "TXT_PINCODE_LOCALITY": "EDATHUA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689574,
      "TXT_PINCODE_LOCALITY": "MUTTAR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689575,
      "TXT_PINCODE_LOCALITY": "KUNTHIRICKAL EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689577,
      "TXT_PINCODE_LOCALITY": "ANAPPARAMBAL NORTH EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689578,
      "TXT_PINCODE_LOCALITY": "NEDUMAPRAM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689579,
      "TXT_PINCODE_LOCALITY": "CHANGANKARAI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689580,
      "TXT_PINCODE_LOCALITY": "CHEKKIDIKAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689581,
      "TXT_PINCODE_LOCALITY": "PALACKAL THAKADY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689582,
      "TXT_PINCODE_LOCALITY": "ANJILITHANAM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689583,
      "TXT_PINCODE_LOCALITY": "KALLOOPARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689584,
      "TXT_PINCODE_LOCALITY": "MALLAPALLY EAST",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689585,
      "TXT_PINCODE_LOCALITY": "MALLAPALLY WEST",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689586,
      "TXT_PINCODE_LOCALITY": "CHALAPALLY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689587,
      "TXT_PINCODE_LOCALITY": "KEEZHVAIPUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689588,
      "TXT_PINCODE_LOCALITY": "KULATHUR TLA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689589,
      "TXT_PINCODE_LOCALITY": "NOOROMMAVU EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689590,
      "TXT_PINCODE_LOCALITY": "VELIYANAD WEST",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689591,
      "TXT_PINCODE_LOCALITY": "MEPRAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689592,
      "TXT_PINCODE_LOCALITY": "PERUMPATTY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689593,
      "TXT_PINCODE_LOCALITY": "MUNDIAPPALLI EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689594,
      "TXT_PINCODE_LOCALITY": "MALLAPALLY NORTH B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689595,
      "TXT_PINCODE_LOCALITY": "RMANKARY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689596,
      "TXT_PINCODE_LOCALITY": "PARIYARAM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689597,
      "TXT_PINCODE_LOCALITY": "THURUTHICAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689598,
      "TXT_PINCODE_LOCALITY": "MITHRAKARI EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689600,
      "TXT_PINCODE_LOCALITY": "VEZHAPRA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689603,
      "TXT_PINCODE_LOCALITY": "KADAMANKULAM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689611,
      "TXT_PINCODE_LOCALITY": "AYROOR SOUTH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689612,
      "TXT_PINCODE_LOCALITY": "VELLIYARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689613,
      "TXT_PINCODE_LOCALITY": "THEODICAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689614,
      "TXT_PINCODE_LOCALITY": "THEKKUMKAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689615,
      "TXT_PINCODE_LOCALITY": "KARIYAMPLAVU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689616,
      "TXT_PINCODE_LOCALITY": "KANJETTUKARA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689620,
      "TXT_PINCODE_LOCALITY": "KIZHAKKUMBHAGOM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689621,
      "TXT_PINCODE_LOCALITY": "NIRANAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689622,
      "TXT_PINCODE_LOCALITY": "ERAMATHOOR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689623,
      "TXT_PINCODE_LOCALITY": "KUTTAMPEROOR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689624,
      "TXT_PINCODE_LOCALITY": "ENNAKAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689625,
      "TXT_PINCODE_LOCALITY": "THUMPAMON NORTH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689627,
      "TXT_PINCODE_LOCALITY": "MELPADAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689628,
      "TXT_PINCODE_LOCALITY": "KIZHAKKUMBHAGAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689629,
      "TXT_PINCODE_LOCALITY": "NIRNAM WEST EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689630,
      "TXT_PINCODE_LOCALITY": "KADAPRA MANNAR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689632,
      "TXT_PINCODE_LOCALITY": "THUMPAMONTHAZHAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689633,
      "TXT_PINCODE_LOCALITY": "PAVUKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689641,
      "TXT_PINCODE_LOCALITY": "KOZHENCHERRY EAST B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689642,
      "TXT_PINCODE_LOCALITY": "NARANGANAM WEST",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689643,
      "TXT_PINCODE_LOCALITY": "ELANTHUR PARIYARAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689644,
      "TXT_PINCODE_LOCALITY": "KUZHIKALA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689645,
      "TXT_PINCODE_LOCALITY": "ANGAMOOZHI EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689646,
      "TXT_PINCODE_LOCALITY": "THENGUMKAVU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689647,
      "TXT_PINCODE_LOCALITY": "OONUKAL QUILON",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689648,
      "TXT_PINCODE_LOCALITY": "KAIPATTUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689649,
      "TXT_PINCODE_LOCALITY": "ANTHIYALANKAVU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689650,
      "TXT_PINCODE_LOCALITY": "KATTOOR KOZHENCHERRY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689652,
      "TXT_PINCODE_LOCALITY": "PUNNAKKAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689653,
      "TXT_PINCODE_LOCALITY": "VETTUR KUMHAZHA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689654,
      "TXT_PINCODE_LOCALITY": "KOZHENCHERRY THEKKEMALA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689656,
      "TXT_PINCODE_LOCALITY": "NJAKKUNNILAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689657,
      "TXT_PINCODE_LOCALITY": "MATHUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689659,
      "TXT_PINCODE_LOCALITY": "VALLICODE EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689660,
      "TXT_PINCODE_LOCALITY": "VAYALATHALA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689661,
      "TXT_PINCODE_LOCALITY": "KUMBALAMPOIKA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689662,
      "TXT_PINCODE_LOCALITY": "PAMBA TRIVENI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689663,
      "TXT_PINCODE_LOCALITY": "NEELIPLAVU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689664,
      "TXT_PINCODE_LOCALITY": "THALACHIRA ERAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689665,
      "TXT_PINCODE_LOCALITY": "MADAMON EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689666,
      "TXT_PINCODE_LOCALITY": "MALAYALAPUZHA THAZHAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689667,
      "TXT_PINCODE_LOCALITY": "GURUNATHAMANNU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689668,
      "TXT_PINCODE_LOCALITY": "THONNIAMALA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689671,
      "TXT_PINCODE_LOCALITY": "MYLAPRA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689672,
      "TXT_PINCODE_LOCALITY": "PUTHUSSERIMALA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689673,
      "TXT_PINCODE_LOCALITY": "RANNY PAZHAVANGADI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689674,
      "TXT_PINCODE_LOCALITY": "ANGADI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689675,
      "TXT_PINCODE_LOCALITY": "VALIAKAVU B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689676,
      "TXT_PINCODE_LOCALITY": "MAKKAPUZHA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689677,
      "TXT_PINCODE_LOCALITY": "CHETHAKKAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689678,
      "TXT_PINCODE_LOCALITY": "MYLAPPA TOWN",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689680,
      "TXT_PINCODE_LOCALITY": "NELLIKAMON EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689681,
      "TXT_PINCODE_LOCALITY": "RANNY EDAMON EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689682,
      "TXT_PINCODE_LOCALITY": "KARIKULAM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689691,
      "TXT_PINCODE_LOCALITY": "OTTUPURA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689692,
      "TXT_PINCODE_LOCALITY": "PAYYANAMON",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689693,
      "TXT_PINCODE_LOCALITY": "NIRATHUPARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689694,
      "TXT_PINCODE_LOCALITY": "KALANJOOR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689695,
      "TXT_PINCODE_LOCALITY": "PATHIRICKAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689696,
      "TXT_PINCODE_LOCALITY": "KUMARAMKUDY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689698,
      "TXT_PINCODE_LOCALITY": "VAKAYAR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689699,
      "TXT_PINCODE_LOCALITY": "THANNOTHODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689702,
      "TXT_PINCODE_LOCALITY": "ADUPANKULAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689703,
      "TXT_PINCODE_LOCALITY": "ILLAKOLLUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689705,
      "TXT_PINCODE_LOCALITY": "VELLAPPARA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689706,
      "TXT_PINCODE_LOCALITY": "PUNNALA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689711,
      "TXT_PINCODE_LOCALITY": "NARANAMOOZHI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689713,
      "TXT_PINCODE_LOCALITY": "SABARIMALA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689721,
      "TXT_PINCODE_LOCALITY": "PANDANKARY EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689722,
      "TXT_PINCODE_LOCALITY": "THAYAMKARI EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689723,
      "TXT_PINCODE_LOCALITY": "THALAVADY SOUTH EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 689969,
      "TXT_PINCODE_LOCALITY": "VAKAYAR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690101,
      "TXT_PINCODE_LOCALITY": "MAVELIKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690102,
      "TXT_PINCODE_LOCALITY": "THAZHAKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690103,
      "TXT_PINCODE_LOCALITY": "THATTARAMBALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690104,
      "TXT_PINCODE_LOCALITY": "VALIAKULANGARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690105,
      "TXT_PINCODE_LOCALITY": "TRIPERUMTHURA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690106,
      "TXT_PINCODE_LOCALITY": "KUNNAMANGALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690107,
      "TXT_PINCODE_LOCALITY": "THEKKEKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690108,
      "TXT_PINCODE_LOCALITY": "KUNNAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690109,
      "TXT_PINCODE_LOCALITY": "KARIPUZHA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690110,
      "TXT_PINCODE_LOCALITY": "KALLUMALA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690501,
      "TXT_PINCODE_LOCALITY": "VALLIKUNNAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690502,
      "TXT_PINCODE_LOCALITY": "KAYAMKULAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690503,
      "TXT_PINCODE_LOCALITY": "VETTIKODE EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690504,
      "TXT_PINCODE_LOCALITY": "ATHIKATTUKULANGARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690505,
      "TXT_PINCODE_LOCALITY": "VADARAPLAVU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690506,
      "TXT_PINCODE_LOCALITY": "MUTHUKULAM SOUTH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690507,
      "TXT_PINCODE_LOCALITY": "CHEPPAUD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690508,
      "TXT_PINCODE_LOCALITY": "KEERIKAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690509,
      "TXT_PINCODE_LOCALITY": "ALARAMAPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690510,
      "TXT_PINCODE_LOCALITY": "OLATTIAMBALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690511,
      "TXT_PINCODE_LOCALITY": "MUTTOM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690512,
      "TXT_PINCODE_LOCALITY": "PALLIPAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690513,
      "TXT_PINCODE_LOCALITY": "NANGIARKULANGARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690514,
      "TXT_PINCODE_LOCALITY": "THAMALAKKAL NORTH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690515,
      "TXT_PINCODE_LOCALITY": "PALLANA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690516,
      "TXT_PINCODE_LOCALITY": "KARTHIKAPALLY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690517,
      "TXT_PINCODE_LOCALITY": "KARUVATTA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690518,
      "TXT_PINCODE_LOCALITY": "KARUNAGAPALLY NORTH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690519,
      "TXT_PINCODE_LOCALITY": "MYNAGAPPALLY NORTH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690520,
      "TXT_PINCODE_LOCALITY": "THURUTHIKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690521,
      "TXT_PINCODE_LOCALITY": "SASTHAMCOTTAH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690522,
      "TXT_PINCODE_LOCALITY": "THENGAMOM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690523,
      "TXT_PINCODE_LOCALITY": "KUTHIRAPANTHI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690524,
      "TXT_PINCODE_LOCALITY": "THEVALAKKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690525,
      "TXT_PINCODE_LOCALITY": "CLAPPANA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690526,
      "TXT_PINCODE_LOCALITY": "OCHIRA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690527,
      "TXT_PINCODE_LOCALITY": "GOVINDAMUTTAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690528,
      "TXT_PINCODE_LOCALITY": "VARAVILA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690529,
      "TXT_PINCODE_LOCALITY": "PADANILAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690530,
      "TXT_PINCODE_LOCALITY": "THAMARAKULAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690531,
      "TXT_PINCODE_LOCALITY": "PUTHIYAVILA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690532,
      "TXT_PINCODE_LOCALITY": "CHINGOLI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690533,
      "TXT_PINCODE_LOCALITY": "NJAKANAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690534,
      "TXT_PINCODE_LOCALITY": "CHUNAKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690535,
      "TXT_PINCODE_LOCALITY": "KANDALLUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690536,
      "TXT_PINCODE_LOCALITY": "KOLLAKA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690537,
      "TXT_PINCODE_LOCALITY": "PULLIKANAKKU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690538,
      "TXT_PINCODE_LOCALITY": "PERUNGALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690539,
      "TXT_PINCODE_LOCALITY": "SREE RAMAPURAM MARKET",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690540,
      "TXT_PINCODE_LOCALITY": "PALLICKAL NOORANAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690541,
      "TXT_PINCODE_LOCALITY": "BHARANICAVU EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690542,
      "TXT_PINCODE_LOCALITY": "ATHINAD NORTH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690543,
      "TXT_PINCODE_LOCALITY": "KARALI JN.EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690544,
      "TXT_PINCODE_LOCALITY": "KULASEKHARAPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690546,
      "TXT_PINCODE_LOCALITY": "KALLELIBHAGOM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690547,
      "TXT_PINCODE_LOCALITY": "ALUMPEEDIKA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690548,
      "TXT_PINCODE_LOCALITY": "KUMARAPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690549,
      "TXT_PINCODE_LOCALITY": "THAMALLAKKAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690550,
      "TXT_PINCODE_LOCALITY": "MANNARASALA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690551,
      "TXT_PINCODE_LOCALITY": "PURAKAD EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690552,
      "TXT_PINCODE_LOCALITY": "PATHYOOR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690553,
      "TXT_PINCODE_LOCALITY": "EVUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690554,
      "TXT_PINCODE_LOCALITY": "KARUVATTA NORTH EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690555,
      "TXT_PINCODE_LOCALITY": "MAHADEVICAUD EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690556,
      "TXT_PINCODE_LOCALITY": "PAYIPAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690557,
      "TXT_PINCODE_LOCALITY": "VETTIYAR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690558,
      "TXT_PINCODE_LOCALITY": "MANKAMKUZHY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690559,
      "TXT_PINCODE_LOCALITY": "PERUNGALA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690560,
      "TXT_PINCODE_LOCALITY": "MUTHUKULAM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690561,
      "TXT_PINCODE_LOCALITY": "SOORNAD NORTH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690562,
      "TXT_PINCODE_LOCALITY": "EDAKULANGARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690563,
      "TXT_PINCODE_LOCALITY": "PALLICKAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690564,
      "TXT_PINCODE_LOCALITY": "ERUVA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690566,
      "TXT_PINCODE_LOCALITY": "VALIAPARAMBU EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690567,
      "TXT_PINCODE_LOCALITY": "PATHARAM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690568,
      "TXT_PINCODE_LOCALITY": "THODIYOOR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690569,
      "TXT_PINCODE_LOCALITY": "VENGA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690570,
      "TXT_PINCODE_LOCALITY": "KALLIMEL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690571,
      "TXT_PINCODE_LOCALITY": "NOORNAD SANATORIUM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690572,
      "TXT_PINCODE_LOCALITY": "KAREELAKULANGARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 1981,
      "City District Name": "ALAPPUZHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690573,
      "TXT_PINCODE_LOCALITY": "CHERIYAZHEEKAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 690574,
      "TXT_PINCODE_LOCALITY": "MANAPALLY NORTH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691001,
      "TXT_PINCODE_LOCALITY": "KOLLAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691002,
      "TXT_PINCODE_LOCALITY": "ASRAMOM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691003,
      "TXT_PINCODE_LOCALITY": "KAVANAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691004,
      "TXT_PINCODE_LOCALITY": "KILIKOLLUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691005,
      "TXT_PINCODE_LOCALITY": "THATTARKONAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691006,
      "TXT_PINCODE_LOCALITY": "PALLITHOTTAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691007,
      "TXT_PINCODE_LOCALITY": "THANGASSERI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691008,
      "TXT_PINCODE_LOCALITY": "KADAPPAKADA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691009,
      "TXT_PINCODE_LOCALITY": "THEVALLY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691010,
      "TXT_PINCODE_LOCALITY": "VADAKKEVILA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691011,
      "TXT_PINCODE_LOCALITY": "ERAVIPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691012,
      "TXT_PINCODE_LOCALITY": "TIRUMULLAVARAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691013,
      "TXT_PINCODE_LOCALITY": "KOLLAM CUTCHERRY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691014,
      "TXT_PINCODE_LOCALITY": "CHANDANATHOPE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691015,
      "TXT_PINCODE_LOCALITY": "MANGAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691017,
      "TXT_PINCODE_LOCALITY": "AYATHIL EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691018,
      "TXT_PINCODE_LOCALITY": "VALATHUNGAL EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691019,
      "TXT_PINCODE_LOCALITY": "ULIYAKOVIL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691020,
      "TXT_PINCODE_LOCALITY": "THATTAMALA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691021,
      "TXT_PINCODE_LOCALITY": "POLAYATHODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691301,
      "TXT_PINCODE_LOCALITY": "PARAVUR KOLLAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691302,
      "TXT_PINCODE_LOCALITY": "KALAKODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691303,
      "TXT_PINCODE_LOCALITY": "MAYYANAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691304,
      "TXT_PINCODE_LOCALITY": "PULLICHIRA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691305,
      "TXT_PINCODE_LOCALITY": "PUNALUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691306,
      "TXT_PINCODE_LOCALITY": "CHERUKULAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691307,
      "TXT_PINCODE_LOCALITY": "AYIRANALLUR B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691308,
      "TXT_PINCODE_LOCALITY": "THENMALA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691309,
      "TXT_PINCODE_LOCALITY": "VENTURE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691310,
      "TXT_PINCODE_LOCALITY": "THINGALKARIKOM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691311,
      "TXT_PINCODE_LOCALITY": "MANNUR ANCHAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691312,
      "TXT_PINCODE_LOCALITY": "AYILARA B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691314,
      "TXT_PINCODE_LOCALITY": "KOLLAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691315,
      "TXT_PINCODE_LOCALITY": "KOLLAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691316,
      "TXT_PINCODE_LOCALITY": "ARYANCAVE EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691317,
      "TXT_PINCODE_LOCALITY": "CHOZHIAKODE EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691319,
      "TXT_PINCODE_LOCALITY": "THEKKUMBHAGAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691320,
      "TXT_PINCODE_LOCALITY": "ALAYAMON EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691321,
      "TXT_PINCODE_LOCALITY": "EDAMULAKKAI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691322,
      "TXT_PINCODE_LOCALITY": "ELAMPAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691323,
      "TXT_PINCODE_LOCALITY": "KALLADA IRR. PROJECT",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691324,
      "TXT_PINCODE_LOCALITY": "KARUKONE EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691325,
      "TXT_PINCODE_LOCALITY": "PATHANAMTHITTA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691326,
      "TXT_PINCODE_LOCALITY": "NARIKKAL EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691329,
      "TXT_PINCODE_LOCALITY": "URUKUNNU ESDO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691330,
      "TXT_PINCODE_LOCALITY": "BHARATHEEPURAM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691331,
      "TXT_PINCODE_LOCALITY": "VALACODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691332,
      "TXT_PINCODE_LOCALITY": "KARIYARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691333,
      "TXT_PINCODE_LOCALITY": "MATHRA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691334,
      "TXT_PINCODE_LOCALITY": "POLACHIRA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691335,
      "TXT_PINCODE_LOCALITY": "PUTHENKULAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691500,
      "TXT_PINCODE_LOCALITY": "WEST KALLADA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691501,
      "TXT_PINCODE_LOCALITY": "AMBIPOIKA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691502,
      "TXT_PINCODE_LOCALITY": "MUNROETHURUTHU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691503,
      "TXT_PINCODE_LOCALITY": "MULAVANA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691504,
      "TXT_PINCODE_LOCALITY": "PERUMPUZHA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691505,
      "TXT_PINCODE_LOCALITY": "IRUMPANAGAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691506,
      "TXT_PINCODE_LOCALITY": "PALLIKAL KOTTARAKARA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691507,
      "TXT_PINCODE_LOCALITY": "PUTHUR-QUILON",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691508,
      "TXT_PINCODE_LOCALITY": "MELILA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691509,
      "TXT_PINCODE_LOCALITY": "THRIPALAZHIKOM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691510,
      "TXT_PINCODE_LOCALITY": "MEEYANA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691511,
      "TXT_PINCODE_LOCALITY": "VELLIMON",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691512,
      "TXT_PINCODE_LOCALITY": "KUDAVATTOR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691513,
      "TXT_PINCODE_LOCALITY": "KARIMPINPUZHA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691514,
      "TXT_PINCODE_LOCALITY": "THALAVOOR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691515,
      "TXT_PINCODE_LOCALITY": "NALLILA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691516,
      "TXT_PINCODE_LOCALITY": "KARINGANNUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691517,
      "TXT_PINCODE_LOCALITY": "AVANEESWARAM R.S EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691518,
      "TXT_PINCODE_LOCALITY": "KOTTATHALA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691519,
      "TXT_PINCODE_LOCALITY": "KOTTUKAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691521,
      "TXT_PINCODE_LOCALITY": "KULAKADA EAST",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691522,
      "TXT_PINCODE_LOCALITY": "PANATHAPLAVU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691523,
      "TXT_PINCODE_LOCALITY": "MELOOD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691524,
      "TXT_PINCODE_LOCALITY": "MARUR B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691525,
      "TXT_PINCODE_LOCALITY": "THATTAYIL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691526,
      "TXT_PINCODE_LOCALITY": "KAITHAPARAMBU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691527,
      "TXT_PINCODE_LOCALITY": "PAZHAKULAM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691528,
      "TXT_PINCODE_LOCALITY": "KOLLAM S. O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691529,
      "TXT_PINCODE_LOCALITY": "VADAKKADATHUKAVU JN.EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691530,
      "TXT_PINCODE_LOCALITY": "MANNADY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691531,
      "TXT_PINCODE_LOCALITY": "KARIKOM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691532,
      "TXT_PINCODE_LOCALITY": "ANDOOR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691533,
      "TXT_PINCODE_LOCALITY": "VAYYANAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691534,
      "TXT_PINCODE_LOCALITY": "CHADAYAMANGALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691535,
      "TXT_PINCODE_LOCALITY": "NILAMEL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691536,
      "TXT_PINCODE_LOCALITY": "PULIPPARA JN",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691537,
      "TXT_PINCODE_LOCALITY": "MIYANNUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691538,
      "TXT_PINCODE_LOCALITY": "VETTIKAVALA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691539,
      "TXT_PINCODE_LOCALITY": "ELAMAD EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691540,
      "TXT_PINCODE_LOCALITY": "VELIYAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691541,
      "TXT_PINCODE_LOCALITY": "KOLLAYIL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691542,
      "TXT_PINCODE_LOCALITY": "KIZHAKKETHERUVU JN. EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691543,
      "TXT_PINCODE_LOCALITY": "KAITHAKODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691544,
      "TXT_PINCODE_LOCALITY": "PANAVELI EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691545,
      "TXT_PINCODE_LOCALITY": "PERUMANNUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 491,
      "City District Name": "KOTTAYAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691546,
      "TXT_PINCODE_LOCALITY": "THALACHIRA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691547,
      "TXT_PINCODE_LOCALITY": "UMMANNUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691548,
      "TXT_PINCODE_LOCALITY": "VAYAKKAL EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691550,
      "TXT_PINCODE_LOCALITY": "SADANANDAPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691551,
      "TXT_PINCODE_LOCALITY": "NELLIMUKAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691552,
      "TXT_PINCODE_LOCALITY": "PUTHENNABALAM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691553,
      "TXT_PINCODE_LOCALITY": "MANAMPUZHA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691554,
      "TXT_PINCODE_LOCALITY": "VAYALA PARAKODE EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691555,
      "TXT_PINCODE_LOCALITY": "ANGADICAL SOUTH EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691556,
      "TXT_PINCODE_LOCALITY": "CHELIKUZHI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 510,
      "City District Name": "PATHANAMTHITTA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691557,
      "TXT_PINCODE_LOCALITY": "CHENGAMANAD JN",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691558,
      "TXT_PINCODE_LOCALITY": "KUNDARA EAST",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691559,
      "TXT_PINCODE_LOCALITY": "AYIRAKUZHI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691560,
      "TXT_PINCODE_LOCALITY": "THAMARAKUDI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691566,
      "TXT_PINCODE_LOCALITY": "PALLIKKAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691571,
      "TXT_PINCODE_LOCALITY": "MYLAKAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691572,
      "TXT_PINCODE_LOCALITY": "CHATHANNUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691573,
      "TXT_PINCODE_LOCALITY": "ADICHANALLUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691574,
      "TXT_PINCODE_LOCALITY": "PARIPALLY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691576,
      "TXT_PINCODE_LOCALITY": "PALLIMON",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691577,
      "TXT_PINCODE_LOCALITY": "MUKHATHALA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691578,
      "TXT_PINCODE_LOCALITY": "KALLUVATHUKKAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691579,
      "TXT_PINCODE_LOCALITY": "KARAMCODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691580,
      "TXT_PINCODE_LOCALITY": "NEDUMPANA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691581,
      "TXT_PINCODE_LOCALITY": "SAKTHIKULANGARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691582,
      "TXT_PINCODE_LOCALITY": "NEENDAKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691583,
      "TXT_PINCODE_LOCALITY": "PANMANA PUTHENCHANTHA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691584,
      "TXT_PINCODE_LOCALITY": "CHAVARA SOUTH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691585,
      "TXT_PINCODE_LOCALITY": "MUKUNDAPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691586,
      "TXT_PINCODE_LOCALITY": "CHAVARA BRIDGE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691588,
      "TXT_PINCODE_LOCALITY": "PUTHENTHURA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691589,
      "TXT_PINCODE_LOCALITY": "UMAYANALLUR IND ESTATE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691590,
      "TXT_PINCODE_LOCALITY": "KOIVILA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691601,
      "TXT_PINCODE_LOCALITY": "PERUMKULAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691602,
      "TXT_PINCODE_LOCALITY": "ASHTAMUDI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691603,
      "TXT_PINCODE_LOCALITY": "CHEMMAKKAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691604,
      "TXT_PINCODE_LOCALITY": "KOLLAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691623,
      "TXT_PINCODE_LOCALITY": "NELLIKUNNAM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691624,
      "TXT_PINCODE_LOCALITY": "PAVITHREESWARAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691625,
      "TXT_PINCODE_LOCALITY": "PIDAVOOR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 691653,
      "TXT_PINCODE_LOCALITY": "KUMMALLUR B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695001,
      "TXT_PINCODE_LOCALITY": "STATUE JUNCTION",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 205,
      "City District Name": "TRIVENDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695002,
      "TXT_PINCODE_LOCALITY": "KARUMAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695003,
      "TXT_PINCODE_LOCALITY": "KAUDIAR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695004,
      "TXT_PINCODE_LOCALITY": "PATTOM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695005,
      "TXT_PINCODE_LOCALITY": "OOLAMPARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695006,
      "TXT_PINCODE_LOCALITY": "TIRUMALA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695007,
      "TXT_PINCODE_LOCALITY": "TVM BEACH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695008,
      "TXT_PINCODE_LOCALITY": "BEEMAPALLY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695009,
      "TXT_PINCODE_LOCALITY": "MANACAUD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695010,
      "TXT_PINCODE_LOCALITY": "SHASTHAMANGALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 205,
      "City District Name": "TRIVENDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695011,
      "TXT_PINCODE_LOCALITY": "TVM MEDICAL COLLEGE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695012,
      "TXT_PINCODE_LOCALITY": "THAMALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695013,
      "TXT_PINCODE_LOCALITY": "MANIKANTESWARAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695014,
      "TXT_PINCODE_LOCALITY": "VAZHUTHACADU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695015,
      "TXT_PINCODE_LOCALITY": "NALANCHIRA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695016,
      "TXT_PINCODE_LOCALITY": "TVM ENGG COLLEGE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695017,
      "TXT_PINCODE_LOCALITY": "SREEKARIYAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695018,
      "TXT_PINCODE_LOCALITY": "PAPPANAMCODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695019,
      "TXT_PINCODE_LOCALITY": "INDUSTRIAL ESTATE TVM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695020,
      "TXT_PINCODE_LOCALITY": "PRAVACHAMBALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695021,
      "TXT_PINCODE_LOCALITY": "VALIAVELI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695022,
      "TXT_PINCODE_LOCALITY": "TVM ISRO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695023,
      "TXT_PINCODE_LOCALITY": "TVM FORT",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695024,
      "TXT_PINCODE_LOCALITY": "TVM PETTAH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695025,
      "TXT_PINCODE_LOCALITY": "MUTTADA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695026,
      "TXT_PINCODE_LOCALITY": "POONTHURA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695027,
      "TXT_PINCODE_LOCALITY": "THIRUVALLAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695028,
      "TXT_PINCODE_LOCALITY": "VATTAPARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695029,
      "TXT_PINCODE_LOCALITY": "ANAYARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695030,
      "TXT_PINCODE_LOCALITY": "KANJIRAMPARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695031,
      "TXT_PINCODE_LOCALITY": "THURUVIKKAL EDSO S. O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695032,
      "TXT_PINCODE_LOCALITY": "ARAMADA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695033,
      "TXT_PINCODE_LOCALITY": "VIKAS BHAVAN",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695034,
      "TXT_PINCODE_LOCALITY": "TVM UNIVERSITY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695035,
      "TXT_PINCODE_LOCALITY": "VANCHIYUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695036,
      "TXT_PINCODE_LOCALITY": "TVM CHALAI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695037,
      "TXT_PINCODE_LOCALITY": "KUNNUKUZHI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695038,
      "TXT_PINCODE_LOCALITY": "P.T.P NAGAR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695039,
      "TXT_PINCODE_LOCALITY": "TVM AG'S OFFICE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695040,
      "TXT_PINCODE_LOCALITY": "KAIMANAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695041,
      "TXT_PINCODE_LOCALITY": "JAWAHAR NAGAR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695042,
      "TXT_PINCODE_LOCALITY": "KALLIYUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695043,
      "TXT_PINCODE_LOCALITY": "KUDAPPANAKUNNU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695044,
      "TXT_PINCODE_LOCALITY": "MUKKOLAKKAL S.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695099,
      "TXT_PINCODE_LOCALITY": "KERALA GOVERNOR'S CAMP",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695101,
      "TXT_PINCODE_LOCALITY": "KEEZHATTHUNGAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695102,
      "TXT_PINCODE_LOCALITY": "ALTHARAMOODU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695103,
      "TXT_PINCODE_LOCALITY": "AYILAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695104,
      "TXT_PINCODE_LOCALITY": "KIZHUVALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695121,
      "TXT_PINCODE_LOCALITY": "NEYYATTINKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695122,
      "TXT_PINCODE_LOCALITY": "PLAMOOTTUKADA EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695123,
      "TXT_PINCODE_LOCALITY": "ARALUMMOODU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695124,
      "TXT_PINCODE_LOCALITY": "PERUMKADAVILA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695125,
      "TXT_PINCODE_LOCALITY": "VAZHICHAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695126,
      "TXT_PINCODE_LOCALITY": "ARUVIPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695127,
      "TXT_PINCODE_LOCALITY": "TRIVANDRUM.",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695128,
      "TXT_PINCODE_LOCALITY": "TRIVANDRUM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695130,
      "TXT_PINCODE_LOCALITY": "TRIVANDRUM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695131,
      "TXT_PINCODE_LOCALITY": "TRIVANDRUM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695132,
      "TXT_PINCODE_LOCALITY": "CHENKAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695133,
      "TXT_PINCODE_LOCALITY": "TIRUPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695141,
      "TXT_PINCODE_LOCALITY": "VARKALA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695142,
      "TXT_PINCODE_LOCALITY": "CHERUNNIYUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695143,
      "TXT_PINCODE_LOCALITY": "THOKKAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695144,
      "TXT_PINCODE_LOCALITY": "MOONGODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695145,
      "TXT_PINCODE_LOCALITY": "MUTTAPPALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695146,
      "TXT_PINCODE_LOCALITY": "PALAYAMKUNNU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695173,
      "TXT_PINCODE_LOCALITY": "ADHICHANALLUR *",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 503,
      "City District Name": "KOLLAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695301,
      "TXT_PINCODE_LOCALITY": "KANIYAPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695302,
      "TXT_PINCODE_LOCALITY": "MURUKKUMPUZHA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695303,
      "TXT_PINCODE_LOCALITY": "PERUMATHURA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695304,
      "TXT_PINCODE_LOCALITY": "CHIRANYINKEEZH",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695305,
      "TXT_PINCODE_LOCALITY": "AZHUR MARKET",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695306,
      "TXT_PINCODE_LOCALITY": "KADAKKAVUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695307,
      "TXT_PINCODE_LOCALITY": "KAIKKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695308,
      "TXT_PINCODE_LOCALITY": "VAKKOM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695309,
      "TXT_PINCODE_LOCALITY": "POOTHURA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695310,
      "TXT_PINCODE_LOCALITY": "HARITHARAPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695311,
      "TXT_PINCODE_LOCALITY": "KAPPIL EDAVA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695312,
      "TXT_PINCODE_LOCALITY": "VETTOOR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695313,
      "TXT_PINCODE_LOCALITY": "KUDAVOOR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695314,
      "TXT_PINCODE_LOCALITY": "MUDAPPURAM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695315,
      "TXT_PINCODE_LOCALITY": "CHANNANKARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695316,
      "TXT_PINCODE_LOCALITY": "PALLIPURAM TVM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695317,
      "TXT_PINCODE_LOCALITY": "THONNAKKAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695318,
      "TXT_PINCODE_LOCALITY": "VENNICODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695323,
      "TXT_PINCODE_LOCALITY": "BADAJE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695501,
      "TXT_PINCODE_LOCALITY": "KOTTUKAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695502,
      "TXT_PINCODE_LOCALITY": "AYIRA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695503,
      "TXT_PINCODE_LOCALITY": "DHANUVACHAPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695504,
      "TXT_PINCODE_LOCALITY": "ELLUVILLA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695505,
      "TXT_PINCODE_LOCALITY": "VELLARADA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695506,
      "TXT_PINCODE_LOCALITY": "KAKKAVILA B,O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695507,
      "TXT_PINCODE_LOCALITY": "OORUTTAMBALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695508,
      "TXT_PINCODE_LOCALITY": "PARASUVAIKAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695509,
      "TXT_PINCODE_LOCALITY": "TRIVANDRUM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695510,
      "TXT_PINCODE_LOCALITY": "TRIVANDRUM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695512,
      "TXT_PINCODE_LOCALITY": "KANDAKAI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695513,
      "TXT_PINCODE_LOCALITY": "POZHIYUR EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695521,
      "TXT_PINCODE_LOCALITY": "KOTTAPURAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695522,
      "TXT_PINCODE_LOCALITY": "VELLAYANI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695523,
      "TXT_PINCODE_LOCALITY": "VENGANUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695524,
      "TXT_PINCODE_LOCALITY": "KANJIRAMKULAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695525,
      "TXT_PINCODE_LOCALITY": "KANJAMPAZHANJI",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695526,
      "TXT_PINCODE_LOCALITY": "PUTHIYATHURA B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695527,
      "TXT_PINCODE_LOCALITY": "KOVALAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695541,
      "TXT_PINCODE_LOCALITY": "MANCHA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695542,
      "TXT_PINCODE_LOCALITY": "VINIBHANIKETHEN",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695543,
      "TXT_PINCODE_LOCALITY": "URIAKODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695544,
      "TXT_PINCODE_LOCALITY": "TRIVANDRUM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695547,
      "TXT_PINCODE_LOCALITY": "VALIAMALA PSLV PROJECT",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695551,
      "TXT_PINCODE_LOCALITY": "MARUTHAMALA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695561,
      "TXT_PINCODE_LOCALITY": "PAZHAKUTTY",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695562,
      "TXT_PINCODE_LOCALITY": "KARIMANCODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695563,
      "TXT_PINCODE_LOCALITY": "DAIVAPURA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695564,
      "TXT_PINCODE_LOCALITY": "CHEKKAKONAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695568,
      "TXT_PINCODE_LOCALITY": "PANAVOOR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695571,
      "TXT_PINCODE_LOCALITY": "MALAYAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 424277,
      "City District Name": "ERNAKULAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695572,
      "TXT_PINCODE_LOCALITY": "KATTACODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695573,
      "TXT_PINCODE_LOCALITY": "VILAPPILSALA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695574,
      "TXT_PINCODE_LOCALITY": "KUTTICHAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695575,
      "TXT_PINCODE_LOCALITY": "PUNALAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695581,
      "TXT_PINCODE_LOCALITY": "KARIYAVATTOM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695582,
      "TXT_PINCODE_LOCALITY": "KAZHAKUTTOM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695583,
      "TXT_PINCODE_LOCALITY": "KULATHUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695584,
      "TXT_PINCODE_LOCALITY": "IROOPARA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695585,
      "TXT_PINCODE_LOCALITY": "SAINIK SCHOOL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695586,
      "TXT_PINCODE_LOCALITY": "ST.XAVIER'S COLLEGE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695587,
      "TXT_PINCODE_LOCALITY": "POWDIKONAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695588,
      "TXT_PINCODE_LOCALITY": "POWDIKONAM B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695601,
      "TXT_PINCODE_LOCALITY": "VELLALUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695602,
      "TXT_PINCODE_LOCALITY": "MALAKKAL",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695603,
      "TXT_PINCODE_LOCALITY": "PARAKUNNU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695604,
      "TXT_PINCODE_LOCALITY": "MOOTHALA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695605,
      "TXT_PINCODE_LOCALITY": "THOTTAKKAD",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695606,
      "TXT_PINCODE_LOCALITY": "VALLUVAMBRAM EDSO",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695607,
      "TXT_PINCODE_LOCALITY": "PULLAPARA B.O",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695608,
      "TXT_PINCODE_LOCALITY": "KODITHOOKIYAKUNNU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695609,
      "TXT_PINCODE_LOCALITY": "MYLAMODE",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695610,
      "TXT_PINCODE_LOCALITY": "NEERAMANKADAVU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695611,
      "TXT_PINCODE_LOCALITY": "MANAMBUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695612,
      "TXT_PINCODE_LOCALITY": "KODUVAZHANNUR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695614,
      "TXT_PINCODE_LOCALITY": "TULAKKALATHUKAVU",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695615,
      "TXT_PINCODE_LOCALITY": "KUTHIRAKULAM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695616,
      "TXT_PINCODE_LOCALITY": "TRIVANDRUM",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 517,
      "City District Name": "TRIVANDRUM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 695618,
      "TXT_PINCODE_LOCALITY": "NAGAROOR",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 696302,
      "TXT_PINCODE_LOCALITY": "MURUKKUMPUZHA",
      "NUM_STATE_CD": 62,
      "State Name": "KERALA",
      "NUM_CITYDISTRICT_CD": 747,
      "City District Name": "THIRUVANANTHAPURAM",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781001,
      "TXT_PINCODE_LOCALITY": "GUWAHATI H O",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781003,
      "TXT_PINCODE_LOCALITY": "ASSAM TRIBUNE",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781004,
      "TXT_PINCODE_LOCALITY": "GUWAHATI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 145,
      "City District Name": "GUWAHATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781005,
      "TXT_PINCODE_LOCALITY": "GUWAHATI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 145,
      "City District Name": "GUWAHATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781006,
      "TXT_PINCODE_LOCALITY": "ASSAM SACHIVALAYA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781007,
      "TXT_PINCODE_LOCALITY": "ULUBARI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781008,
      "TXT_PINCODE_LOCALITY": "REHABARI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781009,
      "TXT_PINCODE_LOCALITY": "FATASIL",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 145,
      "City District Name": "GUWAHATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781010,
      "TXT_PINCODE_LOCALITY": "KAMAKHYA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781011,
      "TXT_PINCODE_LOCALITY": "MALIGAON RLY HD QRS",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781012,
      "TXT_PINCODE_LOCALITY": "PANDU",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781013,
      "TXT_PINCODE_LOCALITY": "JHALUKBARI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781014,
      "TXT_PINCODE_LOCALITY": "GUWAHATI UNIVERSITY H O",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781015,
      "TXT_PINCODE_LOCALITY": "GUWAHATI AIRPORT",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781016,
      "TXT_PINCODE_LOCALITY": "GOPINATH NAGAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781017,
      "TXT_PINCODE_LOCALITY": "AZARA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781018,
      "TXT_PINCODE_LOCALITY": "BINOVA NAGAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781019,
      "TXT_PINCODE_LOCALITY": "KAHILIPARA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781020,
      "TXT_PINCODE_LOCALITY": "GUWAHATI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 145,
      "City District Name": "GUWAHATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781021,
      "TXT_PINCODE_LOCALITY": "BAMUNIMAIDAN",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781022,
      "TXT_PINCODE_LOCALITY": "BHAGADUTTAPUR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781023,
      "TXT_PINCODE_LOCALITY": "AMERIGOG",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781024,
      "TXT_PINCODE_LOCALITY": "ZOO ROAD",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781025,
      "TXT_PINCODE_LOCALITY": "AMBASI FATESIL",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781026,
      "TXT_PINCODE_LOCALITY": "NARANGI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781027,
      "TXT_PINCODE_LOCALITY": "SATGAON",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781028,
      "TXT_PINCODE_LOCALITY": "BELTALA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781030,
      "TXT_PINCODE_LOCALITY": "NORTH GUWAHATI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781031,
      "TXT_PINCODE_LOCALITY": "AMINGAON",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781032,
      "TXT_PINCODE_LOCALITY": "BHANGAGARH",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 145,
      "City District Name": "GUWAHATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781034,
      "TXT_PINCODE_LOCALITY": "LOKHRA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781035,
      "TXT_PINCODE_LOCALITY": "GARCHUK S.O",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781036,
      "TXT_PINCODE_LOCALITY": "HATHIGAON",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781037,
      "TXT_PINCODE_LOCALITY": "PANJABARI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781038,
      "TXT_PINCODE_LOCALITY": "VETA PARA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781101,
      "TXT_PINCODE_LOCALITY": "CHANGSARI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781102,
      "TXT_PINCODE_LOCALITY": "NALBARI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4691,
      "City District Name": "NALBARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781103,
      "TXT_PINCODE_LOCALITY": "NALBARI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4691,
      "City District Name": "NALBARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781104,
      "TXT_PINCODE_LOCALITY": "KULHATI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781120,
      "TXT_PINCODE_LOCALITY": "BARDUAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781121,
      "TXT_PINCODE_LOCALITY": "BEZARA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781122,
      "TXT_PINCODE_LOCALITY": "BIJOY NAGAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781123,
      "TXT_PINCODE_LOCALITY": "BOKO",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781124,
      "TXT_PINCODE_LOCALITY": "CHHAYGAON",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781125,
      "TXT_PINCODE_LOCALITY": "MIRZA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781126,
      "TXT_PINCODE_LOCALITY": "MUKALMUA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4691,
      "City District Name": "NALBARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781127,
      "TXT_PINCODE_LOCALITY": "NAGARBERA S O",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781128,
      "TXT_PINCODE_LOCALITY": "PALASBARI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781129,
      "TXT_PINCODE_LOCALITY": "HAHIM BAZAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781130,
      "TXT_PINCODE_LOCALITY": "AMRANGA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781131,
      "TXT_PINCODE_LOCALITY": "RANI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781132,
      "TXT_PINCODE_LOCALITY": "RAMPUR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781133,
      "TXT_PINCODE_LOCALITY": "DHARAPUR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781134,
      "TXT_PINCODE_LOCALITY": "KUKURMARA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781135,
      "TXT_PINCODE_LOCALITY": "SINGRA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781136,
      "TXT_PINCODE_LOCALITY": "PASCHIMSAMARIA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781137,
      "TXT_PINCODE_LOCALITY": "AGGUMI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781138,
      "TXT_PINCODE_LOCALITY": "BARTOLA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4691,
      "City District Name": "NALBARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781141,
      "TXT_PINCODE_LOCALITY": "BAMUNI GAON",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781150,
      "TXT_PINCODE_LOCALITY": "CHANDRAPUR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781171,
      "TXT_PINCODE_LOCALITY": "MAUKHULI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781301,
      "TXT_PINCODE_LOCALITY": "SIMLAGURI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 2367,
      "City District Name": "BARPETA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781302,
      "TXT_PINCODE_LOCALITY": "BAHARIHAT",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 2367,
      "City District Name": "BARPETA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781303,
      "TXT_PINCODE_LOCALITY": "BANAGRAM",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4691,
      "City District Name": "NALBARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781304,
      "TXT_PINCODE_LOCALITY": "BELSORE",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4691,
      "City District Name": "NALBARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781305,
      "TXT_PINCODE_LOCALITY": "CHENGA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4691,
      "City District Name": "NALBARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781306,
      "TXT_PINCODE_LOCALITY": "CHAMATA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4691,
      "City District Name": "NALBARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781307,
      "TXT_PINCODE_LOCALITY": "SARTHEBARI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 2367,
      "City District Name": "BARPETA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781308,
      "TXT_PINCODE_LOCALITY": "MANDIA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 2367,
      "City District Name": "BARPETA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781309,
      "TXT_PINCODE_LOCALITY": "BHELLA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 2367,
      "City District Name": "BARPETA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781310,
      "TXT_PINCODE_LOCALITY": "JAGARA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4691,
      "City District Name": "NALBARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781312,
      "TXT_PINCODE_LOCALITY": "BARPETA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 2367,
      "City District Name": "BARPETA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781313,
      "TXT_PINCODE_LOCALITY": "SIMLAGURI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 2367,
      "City District Name": "BARPETA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781314,
      "TXT_PINCODE_LOCALITY": "BARPETA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 2367,
      "City District Name": "BARPETA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781315,
      "TXT_PINCODE_LOCALITY": "BARPETA ROAD",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 2367,
      "City District Name": "BARPETA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781316,
      "TXT_PINCODE_LOCALITY": "HOWLI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 2367,
      "City District Name": "BARPETA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781317,
      "TXT_PINCODE_LOCALITY": "SORBHOG",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 2367,
      "City District Name": "BARPETA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781318,
      "TXT_PINCODE_LOCALITY": "BAKSA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 441060,
      "City District Name": "BAKSA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781319,
      "TXT_PINCODE_LOCALITY": "KALGACHIA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 2367,
      "City District Name": "BARPETA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781320,
      "TXT_PINCODE_LOCALITY": "CHAKCHAKI BAZAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 2367,
      "City District Name": "BARPETA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781321,
      "TXT_PINCODE_LOCALITY": "MAINBARI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4450,
      "City District Name": "MARIGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781325,
      "TXT_PINCODE_LOCALITY": "PATHSALA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 2367,
      "City District Name": "BARPETA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781326,
      "TXT_PINCODE_LOCALITY": "PATACHARKUCHI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781327,
      "TXT_PINCODE_LOCALITY": "JALAGHAT",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 2367,
      "City District Name": "BARPETA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781328,
      "TXT_PINCODE_LOCALITY": "BAGMARA BAZAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 2367,
      "City District Name": "BARPETA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781329,
      "TXT_PINCODE_LOCALITY": "NITYANANDA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 2367,
      "City District Name": "BARPETA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781330,
      "TXT_PINCODE_LOCALITY": "KAKILABARI FARM",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 2367,
      "City District Name": "BARPETA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781333,
      "TXT_PINCODE_LOCALITY": "BARIMOKHA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4691,
      "City District Name": "NALBARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781335,
      "TXT_PINCODE_LOCALITY": "BIDYAPUR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4691,
      "City District Name": "NALBARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781336,
      "TXT_PINCODE_LOCALITY": "CHANDKUCHI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4691,
      "City District Name": "NALBARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781337,
      "TXT_PINCODE_LOCALITY": "MILONPUR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4691,
      "City District Name": "NALBARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781338,
      "TXT_PINCODE_LOCALITY": "BARKHETRI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4691,
      "City District Name": "NALBARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781339,
      "TXT_PINCODE_LOCALITY": "KORIA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 2367,
      "City District Name": "BARPETA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781340,
      "TXT_PINCODE_LOCALITY": "BANEKUCHI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 2367,
      "City District Name": "BARPETA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781341,
      "TXT_PINCODE_LOCALITY": "KAZIPARA S.O",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4691,
      "City District Name": "NALBARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781342,
      "TXT_PINCODE_LOCALITY": "BAGANPARA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4691,
      "City District Name": "NALBARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781345,
      "TXT_PINCODE_LOCALITY": "BALILESSA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4691,
      "City District Name": "NALBARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781346,
      "TXT_PINCODE_LOCALITY": "BARAMA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4691,
      "City District Name": "NALBARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781347,
      "TXT_PINCODE_LOCALITY": "KAMARKUCHI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781348,
      "TXT_PINCODE_LOCALITY": "MOROWA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781349,
      "TXT_PINCODE_LOCALITY": "NIZDHAMDHAMA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4691,
      "City District Name": "NALBARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781350,
      "TXT_PINCODE_LOCALITY": "SANEKUCHI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4691,
      "City District Name": "NALBARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781351,
      "TXT_PINCODE_LOCALITY": "KALAG",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4691,
      "City District Name": "NALBARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781352,
      "TXT_PINCODE_LOCALITY": "BHOWANIPUR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 2367,
      "City District Name": "BARPETA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781353,
      "TXT_PINCODE_LOCALITY": "GOPAL BAZAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4691,
      "City District Name": "NALBARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781354,
      "TXT_PINCODE_LOCALITY": "RANGIA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781357,
      "TXT_PINCODE_LOCALITY": "BARBARI (AMC AREA)",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781360,
      "TXT_PINCODE_LOCALITY": "KUMARIKATA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4691,
      "City District Name": "NALBARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781364,
      "TXT_PINCODE_LOCALITY": "MAHARIPARA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781365,
      "TXT_PINCODE_LOCALITY": "RANGIA R S",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781366,
      "TXT_PINCODE_LOCALITY": "GORES WAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781367,
      "TXT_PINCODE_LOCALITY": "TAMBULPUR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4691,
      "City District Name": "NALBARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781368,
      "TXT_PINCODE_LOCALITY": "NAGRIJULI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4691,
      "City District Name": "NALBARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781369,
      "TXT_PINCODE_LOCALITY": "GOGRAPAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4691,
      "City District Name": "NALBARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781370,
      "TXT_PINCODE_LOCALITY": "KAITHALKUCHI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4691,
      "City District Name": "NALBARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781371,
      "TXT_PINCODE_LOCALITY": "TIHU",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4691,
      "City District Name": "NALBARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781372,
      "TXT_PINCODE_LOCALITY": "MUSSALPUR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4691,
      "City District Name": "NALBARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781373,
      "TXT_PINCODE_LOCALITY": "DOOMNI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4691,
      "City District Name": "NALBARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781374,
      "TXT_PINCODE_LOCALITY": "MAKHIBAHA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4691,
      "City District Name": "NALBARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781375,
      "TXT_PINCODE_LOCALITY": "BANGAON",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781376,
      "TXT_PINCODE_LOCALITY": "DARKUCHI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4691,
      "City District Name": "NALBARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781377,
      "TXT_PINCODE_LOCALITY": "THAMANA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4691,
      "City District Name": "NALBARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781378,
      "TXT_PINCODE_LOCALITY": "HARIBHANGA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4691,
      "City District Name": "NALBARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781380,
      "TXT_PINCODE_LOCALITY": "DOBAK",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781381,
      "TXT_PINCODE_LOCALITY": "BAIHATA CHARALI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781382,
      "TXT_PINCODE_LOCALITY": "KARARA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781385,
      "TXT_PINCODE_LOCALITY": "KANIHA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 781403,
      "TXT_PINCODE_LOCALITY": "SONAPUR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4691,
      "City District Name": "NALBARI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782001,
      "TXT_PINCODE_LOCALITY": "MARWARI PATTY",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 1778,
      "City District Name": "NAGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782002,
      "TXT_PINCODE_LOCALITY": "HAIBARGAON",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 1778,
      "City District Name": "NAGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782010,
      "TXT_PINCODE_LOCALITY": "SENCHOWA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 146,
      "City District Name": "JORHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782101,
      "TXT_PINCODE_LOCALITY": "NANOI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 1778,
      "City District Name": "NAGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782102,
      "TXT_PINCODE_LOCALITY": "BERHAMPUR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 1778,
      "City District Name": "NAGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782103,
      "TXT_PINCODE_LOCALITY": "GARUKHUTI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4450,
      "City District Name": "MARIGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782104,
      "TXT_PINCODE_LOCALITY": "JALUGUTI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4450,
      "City District Name": "MARIGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782105,
      "TXT_PINCODE_LOCALITY": "MARIGAON",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4450,
      "City District Name": "MARIGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782107,
      "TXT_PINCODE_LOCALITY": "BARAPUJIA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4450,
      "City District Name": "MARIGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782108,
      "TXT_PINCODE_LOCALITY": "MIKIRBHETA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 1778,
      "City District Name": "NAGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782120,
      "TXT_PINCODE_LOCALITY": "AMBAGAN",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 1778,
      "City District Name": "NAGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782121,
      "TXT_PINCODE_LOCALITY": "BHURAGAON",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 1778,
      "City District Name": "NAGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782122,
      "TXT_PINCODE_LOCALITY": "BATADRAVA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 1778,
      "City District Name": "NAGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782123,
      "TXT_PINCODE_LOCALITY": "DHING",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 1778,
      "City District Name": "NAGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782124,
      "TXT_PINCODE_LOCALITY": "JURIA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 1778,
      "City District Name": "NAGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782125,
      "TXT_PINCODE_LOCALITY": "RUPAHI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 1778,
      "City District Name": "NAGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782126,
      "TXT_PINCODE_LOCALITY": "MOIRABARI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 1778,
      "City District Name": "NAGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782127,
      "TXT_PINCODE_LOCALITY": "LAHORIGHAT",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4450,
      "City District Name": "MARIGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782130,
      "TXT_PINCODE_LOCALITY": "SINGIMARI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 1778,
      "City District Name": "NAGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782135,
      "TXT_PINCODE_LOCALITY": "CHAPANALLA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 1778,
      "City District Name": "NAGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782136,
      "TXT_PINCODE_LOCALITY": "JAKHALBANDHA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 1778,
      "City District Name": "NAGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782137,
      "TXT_PINCODE_LOCALITY": "KWARITOL",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 1778,
      "City District Name": "NAGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782138,
      "TXT_PINCODE_LOCALITY": "MISSA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 1778,
      "City District Name": "NAGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782139,
      "TXT_PINCODE_LOCALITY": "SALANA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 1778,
      "City District Name": "NAGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782140,
      "TXT_PINCODE_LOCALITY": "SAMAGURI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 1778,
      "City District Name": "NAGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782141,
      "TXT_PINCODE_LOCALITY": "PURANIGUDAM",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 1778,
      "City District Name": "NAGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782142,
      "TXT_PINCODE_LOCALITY": "BEBEJIA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 1778,
      "City District Name": "NAGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782143,
      "TXT_PINCODE_LOCALITY": "SILGHAT",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 1778,
      "City District Name": "NAGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782144,
      "TXT_PINCODE_LOCALITY": "BALIGAON",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 1778,
      "City District Name": "NAGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782401,
      "TXT_PINCODE_LOCALITY": "DIGARU",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782402,
      "TXT_PINCODE_LOCALITY": "SUNAPUR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782403,
      "TXT_PINCODE_LOCALITY": "KHETRI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6328,
      "City District Name": "KAMRUP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782410,
      "TXT_PINCODE_LOCALITY": "JAGI ROAD",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4450,
      "City District Name": "MARIGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782411,
      "TXT_PINCODE_LOCALITY": "JAGIBHAKATAGON",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 1778,
      "City District Name": "NAGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782412,
      "TXT_PINCODE_LOCALITY": "DHARAMTUL",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 1778,
      "City District Name": "NAGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782420,
      "TXT_PINCODE_LOCALITY": "JUGIJAN BAZAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 1778,
      "City District Name": "NAGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782425,
      "TXT_PINCODE_LOCALITY": "CHAPARMUKH",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 1778,
      "City District Name": "NAGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782426,
      "TXT_PINCODE_LOCALITY": "KAMPUR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 1778,
      "City District Name": "NAGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782427,
      "TXT_PINCODE_LOCALITY": "KATHIATALI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 1778,
      "City District Name": "NAGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782428,
      "TXT_PINCODE_LOCALITY": "JAMUNAMUKH",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6461,
      "City District Name": "KARBI ANGLONG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782429,
      "TXT_PINCODE_LOCALITY": "JUGIJANBAZAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 1778,
      "City District Name": "NAGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782435,
      "TXT_PINCODE_LOCALITY": "HOJAI BAZAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 1778,
      "City District Name": "NAGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782439,
      "TXT_PINCODE_LOCALITY": "MURAJAR BAZAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6461,
      "City District Name": "KARBI ANGLONG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782440,
      "TXT_PINCODE_LOCALITY": "DABOKA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 1778,
      "City District Name": "NAGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782441,
      "TXT_PINCODE_LOCALITY": "DAKMOKA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6461,
      "City District Name": "KARBI ANGLONG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782445,
      "TXT_PINCODE_LOCALITY": "NILBAGAN",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 1778,
      "City District Name": "NAGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782446,
      "TXT_PINCODE_LOCALITY": "LANKA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 1778,
      "City District Name": "NAGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782447,
      "TXT_PINCODE_LOCALITY": "LUMDING - 2779",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 1778,
      "City District Name": "NAGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782448,
      "TXT_PINCODE_LOCALITY": "KHERONI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6461,
      "City District Name": "KARBI ANGLONG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782450,
      "TXT_PINCODE_LOCALITY": "BAITHALANGSHU",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6461,
      "City District Name": "KARBI ANGLONG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782460,
      "TXT_PINCODE_LOCALITY": "DIPHU",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6461,
      "City District Name": "KARBI ANGLONG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782461,
      "TXT_PINCODE_LOCALITY": "MANJA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6461,
      "City District Name": "KARBI ANGLONG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782470,
      "TXT_PINCODE_LOCALITY": "DHANSIRIPAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6461,
      "City District Name": "KARBI ANGLONG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782475,
      "TXT_PINCODE_LOCALITY": "CHOKIHOLA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6461,
      "City District Name": "KARBI ANGLONG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782480,
      "TXT_PINCODE_LOCALITY": "BOKAJAN",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6461,
      "City District Name": "KARBI ANGLONG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782481,
      "TXT_PINCODE_LOCALITY": "HOWRAGHAT",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6461,
      "City District Name": "KARBI ANGLONG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782482,
      "TXT_PINCODE_LOCALITY": "BAKOLIAGHAT",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6461,
      "City District Name": "KARBI ANGLONG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782485,
      "TXT_PINCODE_LOCALITY": "DONKAMUKAM",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6461,
      "City District Name": "KARBI ANGLONG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782486,
      "TXT_PINCODE_LOCALITY": "HAMREN",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6461,
      "City District Name": "KARBI ANGLONG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 782490,
      "TXT_PINCODE_LOCALITY": "BOKAJAN CEMENT FACTORY",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6461,
      "City District Name": "KARBI ANGLONG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783101,
      "TXT_PINCODE_LOCALITY": "GOALPARA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3258,
      "City District Name": "GOALPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783120,
      "TXT_PINCODE_LOCALITY": "AGIA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3258,
      "City District Name": "GOALPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783121,
      "TXT_PINCODE_LOCALITY": "BALADMARI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3258,
      "City District Name": "GOALPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783122,
      "TXT_PINCODE_LOCALITY": "DAMRU",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3009,
      "City District Name": "DHUBRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783123,
      "TXT_PINCODE_LOCALITY": "DHUPDHARA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3258,
      "City District Name": "GOALPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783124,
      "TXT_PINCODE_LOCALITY": "DUDNAI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3009,
      "City District Name": "DHUBRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783125,
      "TXT_PINCODE_LOCALITY": "MATIA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3258,
      "City District Name": "GOALPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783126,
      "TXT_PINCODE_LOCALITY": "KRISHNAI S.O",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3258,
      "City District Name": "GOALPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783127,
      "TXT_PINCODE_LOCALITY": "SALMARA SOUTH",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 2615,
      "City District Name": "BONGAIGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783128,
      "TXT_PINCODE_LOCALITY": "SUKCHAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3009,
      "City District Name": "DHUBRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783129,
      "TXT_PINCODE_LOCALITY": "LAKHIPUR GOALPARA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3258,
      "City District Name": "GOALPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783130,
      "TXT_PINCODE_LOCALITY": "RONGJULI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4034,
      "City District Name": "KOKRAJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783131,
      "TXT_PINCODE_LOCALITY": "MANKACHAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3009,
      "City District Name": "DHUBRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783132,
      "TXT_PINCODE_LOCALITY": "JALESWAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4034,
      "City District Name": "KOKRAJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783133,
      "TXT_PINCODE_LOCALITY": "RAJAPARA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3258,
      "City District Name": "GOALPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783134,
      "TXT_PINCODE_LOCALITY": "DARRANGIRI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3009,
      "City District Name": "DHUBRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783135,
      "TXT_PINCODE_LOCALITY": "FAKAMARI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3009,
      "City District Name": "DHUBRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783301,
      "TXT_PINCODE_LOCALITY": "DHUBRI BALUCHAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3009,
      "City District Name": "DHUBRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783324,
      "TXT_PINCODE_LOCALITY": "BIDYAPARA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3009,
      "City District Name": "DHUBRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783325,
      "TXT_PINCODE_LOCALITY": "DHARAMSALA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3009,
      "City District Name": "DHUBRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783330,
      "TXT_PINCODE_LOCALITY": "FAKIRGANJ",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3258,
      "City District Name": "GOALPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783331,
      "TXT_PINCODE_LOCALITY": "GOURI PUR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3009,
      "City District Name": "DHUBRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783332,
      "TXT_PINCODE_LOCALITY": "TAMARHAT",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3009,
      "City District Name": "DHUBRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783333,
      "TXT_PINCODE_LOCALITY": "HATIDHURA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4034,
      "City District Name": "KOKRAJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783334,
      "TXT_PINCODE_LOCALITY": "GOLAKGANJ",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3009,
      "City District Name": "DHUBRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783335,
      "TXT_PINCODE_LOCALITY": "AGAMANI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3009,
      "City District Name": "DHUBRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783336,
      "TXT_PINCODE_LOCALITY": "BHOWRAGURI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4034,
      "City District Name": "KOKRAJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783337,
      "TXT_PINCODE_LOCALITY": "TULSIBIL",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4034,
      "City District Name": "KOKRAJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783338,
      "TXT_PINCODE_LOCALITY": "SATRASAL",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 2615,
      "City District Name": "BONGAIGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783339,
      "TXT_PINCODE_LOCALITY": "ALAMGANJ",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3009,
      "City District Name": "DHUBRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783344,
      "TXT_PINCODE_LOCALITY": "HOSPITAL ROAD RANIGANJ",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3009,
      "City District Name": "DHUBRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783345,
      "TXT_PINCODE_LOCALITY": "FAKIRAGRAM",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4034,
      "City District Name": "KOKRAJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783346,
      "TXT_PINCODE_LOCALITY": "SARFANGURI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4034,
      "City District Name": "KOKRAJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783347,
      "TXT_PINCODE_LOCALITY": "DATMA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4034,
      "City District Name": "KOKRAJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783348,
      "TXT_PINCODE_LOCALITY": "BILASHIPARA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3009,
      "City District Name": "DHUBRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783349,
      "TXT_PINCODE_LOCALITY": "BOGRIBARI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4034,
      "City District Name": "KOKRAJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783350,
      "TXT_PINCODE_LOCALITY": "KACHUGAON",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4034,
      "City District Name": "KOKRAJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783351,
      "TXT_PINCODE_LOCALITY": "LAKHIGANJ BAZAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3009,
      "City District Name": "DHUBRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783360,
      "TXT_PINCODE_LOCALITY": "GOSSAIN GAON",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4034,
      "City District Name": "KOKRAJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783361,
      "TXT_PINCODE_LOCALITY": "SRIRAMPUR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3258,
      "City District Name": "GOALPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783362,
      "TXT_PINCODE_LOCALITY": "SALAKATI PROJECT",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4034,
      "City District Name": "KOKRAJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783369,
      "TXT_PINCODE_LOCALITY": "SALAKATI PROJECT",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3009,
      "City District Name": "DHUBRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783370,
      "TXT_PINCODE_LOCALITY": "KOKRAJHAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4034,
      "City District Name": "KOKRAJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783371,
      "TXT_PINCODE_LOCALITY": "CHAPAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3009,
      "City District Name": "DHUBRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783372,
      "TXT_PINCODE_LOCALITY": "BASUGAON",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4034,
      "City District Name": "KOKRAJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783373,
      "TXT_PINCODE_LOCALITY": "SIDLI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 2615,
      "City District Name": "BONGAIGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783374,
      "TXT_PINCODE_LOCALITY": "SAKTI ASHRAM",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 4034,
      "City District Name": "KOKRAJHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783380,
      "TXT_PINCODE_LOCALITY": "BONGAIGAON - 837",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 2615,
      "City District Name": "BONGAIGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783381,
      "TXT_PINCODE_LOCALITY": "NEW BONGAIGAON",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 2615,
      "City District Name": "BONGAIGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783382,
      "TXT_PINCODE_LOCALITY": "JOGIGHOPA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3258,
      "City District Name": "GOALPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783383,
      "TXT_PINCODE_LOCALITY": "BAITAMARI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 2615,
      "City District Name": "BONGAIGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783384,
      "TXT_PINCODE_LOCALITY": "ABHAYAPURI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 2615,
      "City District Name": "BONGAIGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783385,
      "TXT_PINCODE_LOCALITY": "DHALIGAON S.O",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 425477,
      "City District Name": "CHIRANG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783386,
      "TXT_PINCODE_LOCALITY": "BARGHOLA SRIJANGRAM",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3009,
      "City District Name": "DHUBRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783387,
      "TXT_PINCODE_LOCALITY": "BARABAZAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3009,
      "City District Name": "DHUBRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783388,
      "TXT_PINCODE_LOCALITY": "ASHOK PAPER MILLS",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3009,
      "City District Name": "DHUBRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783389,
      "TXT_PINCODE_LOCALITY": "BOITAMARI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3258,
      "City District Name": "GOALPARA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783390,
      "TXT_PINCODE_LOCALITY": "BIJNI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 425477,
      "City District Name": "CHIRANG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783391,
      "TXT_PINCODE_LOCALITY": "PATILADAH",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 2615,
      "City District Name": "BONGAIGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783392,
      "TXT_PINCODE_LOCALITY": "MANIKPUR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 2615,
      "City District Name": "BONGAIGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 783393,
      "TXT_PINCODE_LOCALITY": "MALIPARA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 2615,
      "City District Name": "BONGAIGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784001,
      "TXT_PINCODE_LOCALITY": "KACHARIGAON",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784025,
      "TXT_PINCODE_LOCALITY": "PANCHMILE",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784026,
      "TXT_PINCODE_LOCALITY": "KHALIHAMARI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784101,
      "TXT_PINCODE_LOCALITY": "BALIPARA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784102,
      "TXT_PINCODE_LOCALITY": "LOKRA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784103,
      "TXT_PINCODE_LOCALITY": "CHARDUAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784104,
      "TXT_PINCODE_LOCALITY": "SALONIBARI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784105,
      "TXT_PINCODE_LOCALITY": "GHORAMARI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784107,
      "TXT_PINCODE_LOCALITY": "GHORAMARI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784110,
      "TXT_PINCODE_LOCALITY": "DHEKIAJULI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784111,
      "TXT_PINCODE_LOCALITY": "DARRANGPANBARI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784112,
      "TXT_PINCODE_LOCALITY": "ALISINGA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784113,
      "TXT_PINCODE_LOCALITY": "KOPATI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 770,
      "City District Name": "DARRANG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784114,
      "TXT_PINCODE_LOCALITY": "ORANG",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784115,
      "TXT_PINCODE_LOCALITY": "KHARUPATIAGHAT",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 770,
      "City District Name": "DARRANG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784116,
      "TXT_PINCODE_LOCALITY": "DARRANG S. O",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 770,
      "City District Name": "DARRANG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784117,
      "TXT_PINCODE_LOCALITY": "SIRAJULI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784118,
      "TXT_PINCODE_LOCALITY": "BARSOLA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784119,
      "TXT_PINCODE_LOCALITY": "BATASIPUR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784120,
      "TXT_PINCODE_LOCALITY": "SINGRI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784125,
      "TXT_PINCODE_LOCALITY": "MANGALDAI H O",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 770,
      "City District Name": "DARRANG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784144,
      "TXT_PINCODE_LOCALITY": "PATHARIGHAT",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 770,
      "City District Name": "DARRANG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784145,
      "TXT_PINCODE_LOCALITY": "SIPAJHAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 770,
      "City District Name": "DARRANG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784146,
      "TXT_PINCODE_LOCALITY": "DHULA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 770,
      "City District Name": "DARRANG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784147,
      "TXT_PINCODE_LOCALITY": "UDALGURI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 770,
      "City District Name": "DARRANG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784148,
      "TXT_PINCODE_LOCALITY": "DUNI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 770,
      "City District Name": "DARRANG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784149,
      "TXT_PINCODE_LOCALITY": "THELAMARA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784150,
      "TXT_PINCODE_LOCALITY": "BESSERIA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784151,
      "TXT_PINCODE_LOCALITY": "BANDARMARI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784152,
      "TXT_PINCODE_LOCALITY": "KHELMATI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784154,
      "TXT_PINCODE_LOCALITY": "KETEKIBARI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784160,
      "TXT_PINCODE_LOCALITY": "LALUK",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 441086,
      "City District Name": "LAKHIMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784161,
      "TXT_PINCODE_LOCALITY": "BIHUPURIA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 441086,
      "City District Name": "LAKHIMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784162,
      "TXT_PINCODE_LOCALITY": "BADATI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 441086,
      "City District Name": "LAKHIMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784163,
      "TXT_PINCODE_LOCALITY": "PATHALIPAHAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 441086,
      "City District Name": "LAKHIMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784164,
      "TXT_PINCODE_LOCALITY": "DIKRONG",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 441086,
      "City District Name": "LAKHIMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784165,
      "TXT_PINCODE_LOCALITY": "DHOLEPUR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 441086,
      "City District Name": "LAKHIMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784166,
      "TXT_PINCODE_LOCALITY": "BEHALI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784167,
      "TXT_PINCODE_LOCALITY": "NORTH BONGAIGAON BORGONG",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784168,
      "TXT_PINCODE_LOCALITY": "GOHPUR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784170,
      "TXT_PINCODE_LOCALITY": "HALEM",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784171,
      "TXT_PINCODE_LOCALITY": "TETONBARI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784172,
      "TXT_PINCODE_LOCALITY": "BARANGABARI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784173,
      "TXT_PINCODE_LOCALITY": "MIJIKAJAN",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784174,
      "TXT_PINCODE_LOCALITY": "PABOI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784175,
      "TXT_PINCODE_LOCALITY": "SOOTEA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784176,
      "TXT_PINCODE_LOCALITY": "CHARALI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784177,
      "TXT_PINCODE_LOCALITY": "BISWANATHGHAT",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784178,
      "TXT_PINCODE_LOCALITY": "KALABARI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784179,
      "TXT_PINCODE_LOCALITY": "BEDETI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784180,
      "TXT_PINCODE_LOCALITY": "JAMUGURIHAT",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784181,
      "TXT_PINCODE_LOCALITY": "CHOWKI DENGI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784182,
      "TXT_PINCODE_LOCALITY": "NAHARBARI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784183,
      "TXT_PINCODE_LOCALITY": "NAGSANKAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784184,
      "TXT_PINCODE_LOCALITY": "GINGIA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784185,
      "TXT_PINCODE_LOCALITY": "RANGACHAKUA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784189,
      "TXT_PINCODE_LOCALITY": "KARSONTALA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784501,
      "TXT_PINCODE_LOCALITY": "DEKARGAON",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784502,
      "TXT_PINCODE_LOCALITY": "BINDUKURI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 622,
      "City District Name": "TINSUKIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784503,
      "TXT_PINCODE_LOCALITY": "THAKURBARI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784504,
      "TXT_PINCODE_LOCALITY": "BARJULI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784505,
      "TXT_PINCODE_LOCALITY": "RANGAPARA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784506,
      "TXT_PINCODE_LOCALITY": "MISSAMARI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784507,
      "TXT_PINCODE_LOCALITY": "MAZBAT",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 770,
      "City District Name": "DARRANG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784508,
      "TXT_PINCODE_LOCALITY": "ROWTA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 770,
      "City District Name": "DARRANG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784509,
      "TXT_PINCODE_LOCALITY": "UDALGURI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 770,
      "City District Name": "DARRANG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784510,
      "TXT_PINCODE_LOCALITY": "HARISINGA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 770,
      "City District Name": "DARRANG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784513,
      "TXT_PINCODE_LOCALITY": "BHAIRAB KUNDA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 770,
      "City District Name": "DARRANG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784514,
      "TXT_PINCODE_LOCALITY": "LALPUR BAZAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 770,
      "City District Name": "DARRANG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784521,
      "TXT_PINCODE_LOCALITY": "TANGLA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 770,
      "City District Name": "DARRANG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784522,
      "TXT_PINCODE_LOCALITY": "KHOIRABARI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784523,
      "TXT_PINCODE_LOCALITY": "PANERIHAT",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 770,
      "City District Name": "DARRANG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784524,
      "TXT_PINCODE_LOCALITY": "HATIGARH",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 770,
      "City District Name": "DARRANG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784525,
      "TXT_PINCODE_LOCALITY": "KALAIGAON",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 770,
      "City District Name": "DARRANG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784526,
      "TXT_PINCODE_LOCALITY": "DIMAKUCHI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 770,
      "City District Name": "DARRANG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 784527,
      "TXT_PINCODE_LOCALITY": "ATTERIKHAT",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 770,
      "City District Name": "DARRANG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785001,
      "TXT_PINCODE_LOCALITY": "JORHAT",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 146,
      "City District Name": "JORHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785004,
      "TXT_PINCODE_LOCALITY": "BARBHETA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3283,
      "City District Name": "GOLAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785005,
      "TXT_PINCODE_LOCALITY": "JORHAT AIR FIELD",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 146,
      "City District Name": "JORHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785006,
      "TXT_PINCODE_LOCALITY": "JORHAT RESEARCH LABORATOR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 146,
      "City District Name": "JORHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785007,
      "TXT_PINCODE_LOCALITY": "JORHAT ENGINEERING COLLEG",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 146,
      "City District Name": "JORHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785008,
      "TXT_PINCODE_LOCALITY": "CHINNAMARA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 146,
      "City District Name": "JORHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785009,
      "TXT_PINCODE_LOCALITY": "NO-ALI DHEKIAJULI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785010,
      "TXT_PINCODE_LOCALITY": "CHENGLIGAON",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 146,
      "City District Name": "JORHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785011,
      "TXT_PINCODE_LOCALITY": "KONWARIPUKHURI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3283,
      "City District Name": "GOLAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785013,
      "TXT_PINCODE_LOCALITY": "ASSAM AGRICULTURAL UNVER",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 146,
      "City District Name": "JORHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785014,
      "TXT_PINCODE_LOCALITY": "RAJABARI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 146,
      "City District Name": "JORHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785015,
      "TXT_PINCODE_LOCALITY": "KOROKATOLI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 146,
      "City District Name": "JORHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785101,
      "TXT_PINCODE_LOCALITY": "BAHANA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 620,
      "City District Name": "SIBSAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785102,
      "TXT_PINCODE_LOCALITY": "DAKHINPATSATRA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 146,
      "City District Name": "JORHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785103,
      "TXT_PINCODE_LOCALITY": "FULANIBARI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 620,
      "City District Name": "SIBSAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785104,
      "TXT_PINCODE_LOCALITY": "GARMURSATRA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 146,
      "City District Name": "JORHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785105,
      "TXT_PINCODE_LOCALITY": "JENGRAIMUKH",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 146,
      "City District Name": "JORHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785106,
      "TXT_PINCODE_LOCALITY": "KAMALABARI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 146,
      "City District Name": "JORHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785107,
      "TXT_PINCODE_LOCALITY": "KAKAJAN",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 146,
      "City District Name": "JORHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785108,
      "TXT_PINCODE_LOCALITY": "KOKILAMUKH",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 146,
      "City District Name": "JORHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785109,
      "TXT_PINCODE_LOCALITY": "KAZIRANGA SANCTUARY",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3283,
      "City District Name": "GOLAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785110,
      "TXT_PINCODE_LOCALITY": "MAJULIBANGAON",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 146,
      "City District Name": "JORHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785111,
      "TXT_PINCODE_LOCALITY": "NEAMATIGHAT",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 146,
      "City District Name": "JORHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785112,
      "TXT_PINCODE_LOCALITY": "TEOK",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 620,
      "City District Name": "SIBSAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785113,
      "TXT_PINCODE_LOCALITY": "DHEKIAKHOWA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 146,
      "City District Name": "JORHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785601,
      "TXT_PINCODE_LOCALITY": "SARUPATHAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3283,
      "City District Name": "GOLAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785602,
      "TXT_PINCODE_LOCALITY": "BARPATHAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3283,
      "City District Name": "GOLAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785603,
      "TXT_PINCODE_LOCALITY": "OATING",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3283,
      "City District Name": "GOLAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785604,
      "TXT_PINCODE_LOCALITY": "NAOJAN",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3283,
      "City District Name": "GOLAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785610,
      "TXT_PINCODE_LOCALITY": "FURKATING",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3283,
      "City District Name": "GOLAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785611,
      "TXT_PINCODE_LOCALITY": "BADULIPAAR S. O",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3283,
      "City District Name": "GOLAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785612,
      "TXT_PINCODE_LOCALITY": "BOKAKHAT",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3283,
      "City District Name": "GOLAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785613,
      "TXT_PINCODE_LOCALITY": "LETEKUJAN",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 146,
      "City District Name": "JORHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785614,
      "TXT_PINCODE_LOCALITY": "DERGAON",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3283,
      "City District Name": "GOLAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785615,
      "TXT_PINCODE_LOCALITY": "NUMALIGARH",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3283,
      "City District Name": "GOLAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785616,
      "TXT_PINCODE_LOCALITY": "GATONGA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 146,
      "City District Name": "JORHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785617,
      "TXT_PINCODE_LOCALITY": "DOHATIA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 146,
      "City District Name": "JORHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785618,
      "TXT_PINCODE_LOCALITY": "BARUABAMUNGAON",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3283,
      "City District Name": "GOLAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785619,
      "TXT_PINCODE_LOCALITY": "KAMARGAON CHARALI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 146,
      "City District Name": "JORHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785620,
      "TXT_PINCODE_LOCALITY": "NEGHERITING",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3283,
      "City District Name": "GOLAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785621,
      "TXT_PINCODE_LOCALITY": "BENGENAKHOWA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 146,
      "City District Name": "JORHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785622,
      "TXT_PINCODE_LOCALITY": "DHEKHIAL",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 620,
      "City District Name": "SIBSAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785625,
      "TXT_PINCODE_LOCALITY": "KAMARBANDHA ALI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 146,
      "City District Name": "JORHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785626,
      "TXT_PINCODE_LOCALITY": "MOHEEMA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 146,
      "City District Name": "JORHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785630,
      "TXT_PINCODE_LOCALITY": "TITABAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 146,
      "City District Name": "JORHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785631,
      "TXT_PINCODE_LOCALITY": "BARHOLA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 146,
      "City District Name": "JORHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785632,
      "TXT_PINCODE_LOCALITY": "PURANAITAMBAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 146,
      "City District Name": "JORHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785633,
      "TXT_PINCODE_LOCALITY": "KHARIKATIA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 146,
      "City District Name": "JORHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785634,
      "TXT_PINCODE_LOCALITY": "MARIANI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 146,
      "City District Name": "JORHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785635,
      "TXT_PINCODE_LOCALITY": "NAKACHARI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 620,
      "City District Name": "SIBSAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785636,
      "TXT_PINCODE_LOCALITY": "SELENGHAT",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 146,
      "City District Name": "JORHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785640,
      "TXT_PINCODE_LOCALITY": "SIBSAGAR H O",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 620,
      "City District Name": "SIBSAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785660,
      "TXT_PINCODE_LOCALITY": "BETBARI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 620,
      "City District Name": "SIBSAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785661,
      "TXT_PINCODE_LOCALITY": "CHARING",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 146,
      "City District Name": "JORHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785662,
      "TXT_PINCODE_LOCALITY": "DEMOW",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 620,
      "City District Name": "SIBSAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785663,
      "TXT_PINCODE_LOCALITY": "DESANGMUKH",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3283,
      "City District Name": "GOLAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785664,
      "TXT_PINCODE_LOCALITY": "GAURI SAGAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 620,
      "City District Name": "SIBSAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785665,
      "TXT_PINCODE_LOCALITY": "JOYNAGAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 620,
      "City District Name": "SIBSAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785666,
      "TXT_PINCODE_LOCALITY": "KALOOGAON",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 620,
      "City District Name": "SIBSAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785667,
      "TXT_PINCODE_LOCALITY": "KOWERPUR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 620,
      "City District Name": "SIBSAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785668,
      "TXT_PINCODE_LOCALITY": "MECHAGARH",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 620,
      "City District Name": "SIBSAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785669,
      "TXT_PINCODE_LOCALITY": "MORAN",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785670,
      "TXT_PINCODE_LOCALITY": "DIBRUGARH",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785671,
      "TXT_PINCODE_LOCALITY": "NETAIPUKHURI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 620,
      "City District Name": "SIBSAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785672,
      "TXT_PINCODE_LOCALITY": "RAJMAI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 620,
      "City District Name": "SIBSAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785673,
      "TXT_PINCODE_LOCALITY": "SEPON",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 620,
      "City District Name": "SIBSAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785674,
      "TXT_PINCODE_LOCALITY": "NEMUGURI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 620,
      "City District Name": "SIBSAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785675,
      "TXT_PINCODE_LOCALITY": "KHOWANG",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785676,
      "TXT_PINCODE_LOCALITY": "KHOWANGGHAT",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785680,
      "TXT_PINCODE_LOCALITY": "AMGURI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 620,
      "City District Name": "SIBSAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785681,
      "TXT_PINCODE_LOCALITY": "AMGURI HALOATING",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 620,
      "City District Name": "SIBSAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785682,
      "TXT_PINCODE_LOCALITY": "TAMULICHIGA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 620,
      "City District Name": "SIBSAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785683,
      "TXT_PINCODE_LOCALITY": "JHANJI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 620,
      "City District Name": "SIBSAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785684,
      "TXT_PINCODE_LOCALITY": "NAMTIDOL",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 620,
      "City District Name": "SIBSAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785685,
      "TXT_PINCODE_LOCALITY": "NAZIRA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 620,
      "City District Name": "SIBSAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785686,
      "TXT_PINCODE_LOCALITY": "SIMALUGURI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 146,
      "City District Name": "JORHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785687,
      "TXT_PINCODE_LOCALITY": "SANTAK",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 620,
      "City District Name": "SIBSAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785688,
      "TXT_PINCODE_LOCALITY": "LAKWAH",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 620,
      "City District Name": "SIBSAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785689,
      "TXT_PINCODE_LOCALITY": "SUFFRY",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 620,
      "City District Name": "SIBSAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785690,
      "TXT_PINCODE_LOCALITY": "SONARI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 620,
      "City District Name": "SIBSAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785691,
      "TXT_PINCODE_LOCALITY": "BHOJO",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 620,
      "City District Name": "SIBSAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785692,
      "TXT_PINCODE_LOCALITY": "SAPEKHATI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 620,
      "City District Name": "SIBSAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785693,
      "TXT_PINCODE_LOCALITY": "BORHAT",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 146,
      "City District Name": "JORHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785694,
      "TXT_PINCODE_LOCALITY": "TEOKGHAT",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 620,
      "City District Name": "SIBSAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785695,
      "TXT_PINCODE_LOCALITY": "TOWKOK",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 620,
      "City District Name": "SIBSAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785696,
      "TXT_PINCODE_LOCALITY": "GALEKI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 620,
      "City District Name": "SIBSAGAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785699,
      "TXT_PINCODE_LOCALITY": "KANAIGHAT",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3283,
      "City District Name": "GOLAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785701,
      "TXT_PINCODE_LOCALITY": "HAHCHARA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3283,
      "City District Name": "GOLAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 785702,
      "TXT_PINCODE_LOCALITY": "BAMBORAHI S. O",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3283,
      "City District Name": "GOLAGHAT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786001,
      "TXT_PINCODE_LOCALITY": "GRAHAM BAZAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786002,
      "TXT_PINCODE_LOCALITY": "ASSAM MEDICAL COLLEGE",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786003,
      "TXT_PINCODE_LOCALITY": "REVENUE BUILDING",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786004,
      "TXT_PINCODE_LOCALITY": "DIBRUGARH UNIVERSITY",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786005,
      "TXT_PINCODE_LOCALITY": "JALAN NAGAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786007,
      "TXT_PINCODE_LOCALITY": "BARBARUA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786008,
      "TXT_PINCODE_LOCALITY": "MOHANAGHAT",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786010,
      "TXT_PINCODE_LOCALITY": "LAHOAL",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786012,
      "TXT_PINCODE_LOCALITY": "MOHANBARI AIR FIELD",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786051,
      "TXT_PINCODE_LOCALITY": "NAHAJAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786052,
      "TXT_PINCODE_LOCALITY": "TINTHENGIA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786053,
      "TXT_PINCODE_LOCALITY": "KOTONA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786054,
      "TXT_PINCODE_LOCALITY": "LEZAI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786101,
      "TXT_PINCODE_LOCALITY": "DIKOM",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786102,
      "TXT_PINCODE_LOCALITY": "CHABUA AIR FIELD",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786103,
      "TXT_PINCODE_LOCALITY": "TENGAKHAT",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786110,
      "TXT_PINCODE_LOCALITY": "SISSIBARGAON",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 441086,
      "City District Name": "LAKHIMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786125,
      "TXT_PINCODE_LOCALITY": "TINSUKIA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786145,
      "TXT_PINCODE_LOCALITY": "SRIPURIA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 622,
      "City District Name": "TINSUKIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786146,
      "TXT_PINCODE_LOCALITY": "SUKHANPUKHURI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786147,
      "TXT_PINCODE_LOCALITY": "RANGAGORAH",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786148,
      "TXT_PINCODE_LOCALITY": "MANKHOOLI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 622,
      "City District Name": "TINSUKIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786150,
      "TXT_PINCODE_LOCALITY": "BARHAPJAN",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 622,
      "City District Name": "TINSUKIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786151,
      "TXT_PINCODE_LOCALITY": "NETAJI ROAD",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786152,
      "TXT_PINCODE_LOCALITY": "KAKAPATHAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 622,
      "City District Name": "TINSUKIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786153,
      "TXT_PINCODE_LOCALITY": "RUPAI SIDING",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 622,
      "City District Name": "TINSUKIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786154,
      "TXT_PINCODE_LOCALITY": "SAIKHOWAGHAT",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 622,
      "City District Name": "TINSUKIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786155,
      "TXT_PINCODE_LOCALITY": "SADIYA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 622,
      "City District Name": "TINSUKIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786156,
      "TXT_PINCODE_LOCALITY": "TALAB",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 622,
      "City District Name": "TINSUKIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786157,
      "TXT_PINCODE_LOCALITY": "CHAPAKHOWA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 622,
      "City District Name": "TINSUKIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786158,
      "TXT_PINCODE_LOCALITY": "KUNDIL",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 622,
      "City District Name": "TINSUKIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786159,
      "TXT_PINCODE_LOCALITY": "SUKRETING",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 622,
      "City District Name": "TINSUKIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786170,
      "TXT_PINCODE_LOCALITY": "MAKUM JUNCTION",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 622,
      "City District Name": "TINSUKIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786171,
      "TXT_PINCODE_LOCALITY": "DIGBOI CHARALI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786172,
      "TXT_PINCODE_LOCALITY": "POWAI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 1778,
      "City District Name": "NAGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786173,
      "TXT_PINCODE_LOCALITY": "JUBILEE DIGBOI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 1778,
      "City District Name": "NAGAON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786180,
      "TXT_PINCODE_LOCALITY": "LEKHAPANI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 622,
      "City District Name": "TINSUKIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786181,
      "TXT_PINCODE_LOCALITY": "MARGHERITA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 622,
      "City District Name": "TINSUKIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786182,
      "TXT_PINCODE_LOCALITY": "LEDO - 2752",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 622,
      "City District Name": "TINSUKIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786183,
      "TXT_PINCODE_LOCALITY": "PANITOLA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786184,
      "TXT_PINCODE_LOCALITY": "CHABUA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786185,
      "TXT_PINCODE_LOCALITY": "DINJAN",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 622,
      "City District Name": "TINSUKIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786186,
      "TXT_PINCODE_LOCALITY": "HATIBANDHA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786601,
      "TXT_PINCODE_LOCALITY": "HOOGRIJAN",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 622,
      "City District Name": "TINSUKIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786602,
      "TXT_PINCODE_LOCALITY": "DIGBOI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786610,
      "TXT_PINCODE_LOCALITY": "PREMTOLA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786611,
      "TXT_PINCODE_LOCALITY": "RAJGARH",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786612,
      "TXT_PINCODE_LOCALITY": "TINGKONG",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 622,
      "City District Name": "TINSUKIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786613,
      "TXT_PINCODE_LOCALITY": "BAMUNBARI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786614,
      "TXT_PINCODE_LOCALITY": "JEYPORE",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786615,
      "TXT_PINCODE_LOCALITY": "KOWARIGAON",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786616,
      "TXT_PINCODE_LOCALITY": "LENGRI BAZAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786621,
      "TXT_PINCODE_LOCALITY": "NAMRUP",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786622,
      "TXT_PINCODE_LOCALITY": "NAMRUP T P S",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786623,
      "TXT_PINCODE_LOCALITY": "PARBATPUR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786624,
      "TXT_PINCODE_LOCALITY": "BARBAM",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786629,
      "TXT_PINCODE_LOCALITY": "DEOMALI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786630,
      "TXT_PINCODE_LOCALITY": "KHONSA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 786631,
      "TXT_PINCODE_LOCALITY": "LONGDING",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 787001,
      "TXT_PINCODE_LOCALITY": "NORTH LAKHIMPUR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 441086,
      "City District Name": "LAKHIMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 787026,
      "TXT_PINCODE_LOCALITY": "BORDOLONI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 967,
      "City District Name": "DIBRUGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 787027,
      "TXT_PINCODE_LOCALITY": "DOOLAHAT",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 441086,
      "City District Name": "LAKHIMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 787028,
      "TXT_PINCODE_LOCALITY": "NOWBOICHA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 441086,
      "City District Name": "LAKHIMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 787029,
      "TXT_PINCODE_LOCALITY": "MOINAPARA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 441086,
      "City District Name": "LAKHIMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 787030,
      "TXT_PINCODE_LOCALITY": "BAGINADI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 441086,
      "City District Name": "LAKHIMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 787031,
      "TXT_PINCODE_LOCALITY": "KOWADONGA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 441086,
      "City District Name": "LAKHIMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 787032,
      "TXT_PINCODE_LOCALITY": "MOIDUMIA S.O",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 441086,
      "City District Name": "LAKHIMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 787035,
      "TXT_PINCODE_LOCALITY": "GERUKAMUKH",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3001,
      "City District Name": "DHEMAJI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 787051,
      "TXT_PINCODE_LOCALITY": "LILABARI AIR FIELD",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 441086,
      "City District Name": "LAKHIMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 787052,
      "TXT_PINCODE_LOCALITY": "PANIGAON",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 441086,
      "City District Name": "LAKHIMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 787053,
      "TXT_PINCODE_LOCALITY": "GHILAMARA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 441086,
      "City District Name": "LAKHIMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 787054,
      "TXT_PINCODE_LOCALITY": "ISLAMGAON",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 441086,
      "City District Name": "LAKHIMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 787055,
      "TXT_PINCODE_LOCALITY": "DHAKUAKHANA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 441086,
      "City District Name": "LAKHIMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 787056,
      "TXT_PINCODE_LOCALITY": "PATHALIPAM",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 441086,
      "City District Name": "LAKHIMPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 787057,
      "TXT_PINCODE_LOCALITY": "DHEMAJI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3001,
      "City District Name": "DHEMAJI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 787058,
      "TXT_PINCODE_LOCALITY": "MACHKHOWA S. O",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3001,
      "City District Name": "DHEMAJI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 787059,
      "TXT_PINCODE_LOCALITY": "SILAPATHAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3001,
      "City District Name": "DHEMAJI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 787060,
      "TXT_PINCODE_LOCALITY": "JONAI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3001,
      "City District Name": "DHEMAJI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 787086,
      "TXT_PINCODE_LOCALITY": "BORDOLONI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 622,
      "City District Name": "TINSUKIA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788001,
      "TXT_PINCODE_LOCALITY": "SILCHAR H O",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788002,
      "TXT_PINCODE_LOCALITY": "MALUGRAM",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788003,
      "TXT_PINCODE_LOCALITY": "TARAPUR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788004,
      "TXT_PINCODE_LOCALITY": "GURUCHARAN COLLEGE - 4949",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788005,
      "TXT_PINCODE_LOCALITY": "RANGIRKHARI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788006,
      "TXT_PINCODE_LOCALITY": "LINK ROAD",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788007,
      "TXT_PINCODE_LOCALITY": "VIVEKANANDA ROAD",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788009,
      "TXT_PINCODE_LOCALITY": "RANGPUR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788011,
      "TXT_PINCODE_LOCALITY": "CACHAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788013,
      "TXT_PINCODE_LOCALITY": "SONABARIGHAT",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788014,
      "TXT_PINCODE_LOCALITY": "SILCHAR REGIONAL",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788015,
      "TXT_PINCODE_LOCALITY": "MEHERPUR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788019,
      "TXT_PINCODE_LOCALITY": "BARKHOLA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788025,
      "TXT_PINCODE_LOCALITY": "ARUNACHAL",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788026,
      "TXT_PINCODE_LOCALITY": "SRIKONA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788030,
      "TXT_PINCODE_LOCALITY": "UDARBOND",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788098,
      "TXT_PINCODE_LOCALITY": "PAILAPOOL",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788101,
      "TXT_PINCODE_LOCALITY": "BANKANDI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788102,
      "TXT_PINCODE_LOCALITY": "DEWAN",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788103,
      "TXT_PINCODE_LOCALITY": "LAKHIPUR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788104,
      "TXT_PINCODE_LOCALITY": "JIRIGHAT",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788106,
      "TXT_PINCODE_LOCALITY": "FULERTAL",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788107,
      "TXT_PINCODE_LOCALITY": "JOYPUR RAJA BAZAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788108,
      "TXT_PINCODE_LOCALITY": "KUMBHIR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788109,
      "TXT_PINCODE_LOCALITY": "KUMBHIRGRAM AIR PORT",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788110,
      "TXT_PINCODE_LOCALITY": "BORKHOLA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788112,
      "TXT_PINCODE_LOCALITY": "DERBY",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788114,
      "TXT_PINCODE_LOCALITY": "DHOLAI BAZAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788115,
      "TXT_PINCODE_LOCALITY": "NARSINGPUR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788116,
      "TXT_PINCODE_LOCALITY": "PALONGHAT",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788117,
      "TXT_PINCODE_LOCALITY": "ROSEKANDI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788118,
      "TXT_PINCODE_LOCALITY": "SILCURI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788119,
      "TXT_PINCODE_LOCALITY": "SONAIMUKH",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788120,
      "TXT_PINCODE_LOCALITY": "BHAGABAZAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788121,
      "TXT_PINCODE_LOCALITY": "KABUGANJBAZAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788122,
      "TXT_PINCODE_LOCALITY": "MANIERKHAL",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788123,
      "TXT_PINCODE_LOCALITY": "BEKIRPAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788124,
      "TXT_PINCODE_LOCALITY": "IRONGMARA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788125,
      "TXT_PINCODE_LOCALITY": "LABOC",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788126,
      "TXT_PINCODE_LOCALITY": "BINNAKAND",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788127,
      "TXT_PINCODE_LOCALITY": "JARAILTALA BAZAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788130,
      "TXT_PINCODE_LOCALITY": "BINNAKANDIGHAT",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788145,
      "TXT_PINCODE_LOCALITY": "CHIPARSANGAM",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3347,
      "City District Name": "HAILAKANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788150,
      "TXT_PINCODE_LOCALITY": "KALIBARI BAZAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 769,
      "City District Name": "SONITPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788151,
      "TXT_PINCODE_LOCALITY": "HAILAKANDI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3347,
      "City District Name": "HAILAKANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788153,
      "TXT_PINCODE_LOCALITY": "HAILAKANDI ROAD",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3347,
      "City District Name": "HAILAKANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788155,
      "TXT_PINCODE_LOCALITY": "RATANPUR ROAD",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3347,
      "City District Name": "HAILAKANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788156,
      "TXT_PINCODE_LOCALITY": "ANILGARH",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3810,
      "City District Name": "KARIMGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788158,
      "TXT_PINCODE_LOCALITY": "UMRANGSO",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788160,
      "TXT_PINCODE_LOCALITY": "JAFFIRBAND",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788161,
      "TXT_PINCODE_LOCALITY": "KATLACHERRA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3347,
      "City District Name": "HAILAKANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788162,
      "TXT_PINCODE_LOCALITY": "KUKICHERRA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3347,
      "City District Name": "HAILAKANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788163,
      "TXT_PINCODE_LOCALITY": "LALA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3347,
      "City District Name": "HAILAKANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788164,
      "TXT_PINCODE_LOCALITY": "MONACHERRA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788165,
      "TXT_PINCODE_LOCALITY": "MANIPURBAGAN",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788166,
      "TXT_PINCODE_LOCALITY": "RAMKRISHNANAGAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3810,
      "City District Name": "KARIMGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788167,
      "TXT_PINCODE_LOCALITY": "VERNERPUR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788169,
      "TXT_PINCODE_LOCALITY": "LALAMUKH",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3347,
      "City District Name": "HAILAKANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788188,
      "TXT_PINCODE_LOCALITY": "SILCURI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788701,
      "TXT_PINCODE_LOCALITY": "BHANGABAZAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3810,
      "City District Name": "KARIMGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788702,
      "TXT_PINCODE_LOCALITY": "SRIGAURI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3810,
      "City District Name": "KARIMGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788705,
      "TXT_PINCODE_LOCALITY": "RAJARTILLA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3810,
      "City District Name": "KARIMGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788706,
      "TXT_PINCODE_LOCALITY": "MAHAKHAL",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3810,
      "City District Name": "KARIMGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788708,
      "TXT_PINCODE_LOCALITY": "CHARGOLA BAZAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3810,
      "City District Name": "KARIMGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788710,
      "TXT_PINCODE_LOCALITY": "KARIMGANJ STATION ROAD",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3810,
      "City District Name": "KARIMGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788711,
      "TXT_PINCODE_LOCALITY": "KANISHAIL B.O",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3810,
      "City District Name": "KARIMGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788716,
      "TXT_PINCODE_LOCALITY": "SADARASI",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3810,
      "City District Name": "KARIMGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788719,
      "TXT_PINCODE_LOCALITY": "TILLABZAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3810,
      "City District Name": "KARIMGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788720,
      "TXT_PINCODE_LOCALITY": "JANKALYAN BAZAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3810,
      "City District Name": "KARIMGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788721,
      "TXT_PINCODE_LOCALITY": "LATU",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3810,
      "City District Name": "KARIMGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788722,
      "TXT_PINCODE_LOCALITY": "NILAMBAZAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3810,
      "City District Name": "KARIMGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788723,
      "TXT_PINCODE_LOCALITY": "BAROIGRAM",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3810,
      "City District Name": "KARIMGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788724,
      "TXT_PINCODE_LOCALITY": "TINOKHAL",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3810,
      "City District Name": "KARIMGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788725,
      "TXT_PINCODE_LOCALITY": "CHANDKHIRA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3810,
      "City District Name": "KARIMGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788726,
      "TXT_PINCODE_LOCALITY": "HATIKHIRA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788727,
      "TXT_PINCODE_LOCALITY": "BAZARICHERRA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3810,
      "City District Name": "KARIMGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788729,
      "TXT_PINCODE_LOCALITY": "LAKHI CHERRA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788731,
      "TXT_PINCODE_LOCALITY": "KANAI BAZAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3810,
      "City District Name": "KARIMGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788733,
      "TXT_PINCODE_LOCALITY": "BAZARGHAT",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3810,
      "City District Name": "KARIMGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788734,
      "TXT_PINCODE_LOCALITY": "ANIPUR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3810,
      "City District Name": "KARIMGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788735,
      "TXT_PINCODE_LOCALITY": "CHARGULA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3810,
      "City District Name": "KARIMGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788736,
      "TXT_PINCODE_LOCALITY": "DULLAVCHERRA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3347,
      "City District Name": "HAILAKANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788737,
      "TXT_PINCODE_LOCALITY": "OLIVACHERRA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3810,
      "City District Name": "KARIMGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788740,
      "TXT_PINCODE_LOCALITY": "BRAHMANSHASAN",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3810,
      "City District Name": "KARIMGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788781,
      "TXT_PINCODE_LOCALITY": "LATU B.O",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3810,
      "City District Name": "KARIMGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788790,
      "TXT_PINCODE_LOCALITY": "MANIKGANJ",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788801,
      "TXT_PINCODE_LOCALITY": "KALINAGAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3347,
      "City District Name": "HAILAKANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788802,
      "TXT_PINCODE_LOCALITY": "PANCHGRAM",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3347,
      "City District Name": "HAILAKANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788803,
      "TXT_PINCODE_LOCALITY": "BADARPURGHAT S.O",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3810,
      "City District Name": "KARIMGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788805,
      "TXT_PINCODE_LOCALITY": "KATIGORAH",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788806,
      "TXT_PINCODE_LOCALITY": "SRIGOURI B.O",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3810,
      "City District Name": "KARIMGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788808,
      "TXT_PINCODE_LOCALITY": "HILARA COLONY",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3810,
      "City District Name": "KARIMGANJ",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788812,
      "TXT_PINCODE_LOCALITY": "KALAINCHERRA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788814,
      "TXT_PINCODE_LOCALITY": "SALCHOPRA",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788816,
      "TXT_PINCODE_LOCALITY": "JALALPUR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 3347,
      "City District Name": "HAILAKANDI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788817,
      "TXT_PINCODE_LOCALITY": "BEHARA BAZAR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788818,
      "TXT_PINCODE_LOCALITY": "HARANGAJAO",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788819,
      "TXT_PINCODE_LOCALITY": "HAFLONG",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788830,
      "TXT_PINCODE_LOCALITY": "MAHUR",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788831,
      "TXT_PINCODE_LOCALITY": "MAIBONG",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788832,
      "TXT_PINCODE_LOCALITY": "LANGTING",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788834,
      "TXT_PINCODE_LOCALITY": "KHEJURBOND",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 617,
      "City District Name": "CACHAR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 788931,
      "TXT_PINCODE_LOCALITY": "NORTH CACHAR HILLS",
      "NUM_STATE_CD": 54,
      "State Name": "ASSAM",
      "NUM_CITYDISTRICT_CD": 6056,
      "City District Name": "UMRANGSO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 790001,
      "TXT_PINCODE_LOCALITY": "BOMDILA",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 424409,
      "City District Name": "WEST KAMENG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 790002,
      "TXT_PINCODE_LOCALITY": "KALAKTANG",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 424409,
      "City District Name": "WEST KAMENG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 790018,
      "TXT_PINCODE_LOCALITY": "PARMAAR",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6492,
      "City District Name": "YAZALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 790061,
      "TXT_PINCODE_LOCALITY": "TANIO",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 442388,
      "City District Name": "YACHULI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 790101,
      "TXT_PINCODE_LOCALITY": "DIRANG",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 424409,
      "City District Name": "WEST KAMENG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 790102,
      "TXT_PINCODE_LOCALITY": "SEPPA",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 424403,
      "City District Name": "EAST KAMENG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 790103,
      "TXT_PINCODE_LOCALITY": "SEIJUSSA",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 424406,
      "City District Name": "PAPUM PARE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 790104,
      "TXT_PINCODE_LOCALITY": "TOWANG",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 5859,
      "City District Name": "TAWANG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 790114,
      "TXT_PINCODE_LOCALITY": "BHALUKPONG",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 424406,
      "City District Name": "PAPUM PARE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 790115,
      "TXT_PINCODE_LOCALITY": "WEST KAMENG",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 424409,
      "City District Name": "WEST KAMENG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 791001,
      "TXT_PINCODE_LOCALITY": "ALONG",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 424411,
      "City District Name": "WEST SIANG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 791002,
      "TXT_PINCODE_LOCALITY": "YINGKIONG",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 424408,
      "City District Name": "UPPER SIANG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 791101,
      "TXT_PINCODE_LOCALITY": "BASAR",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 424411,
      "City District Name": "WEST SIANG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 791102,
      "TXT_PINCODE_LOCALITY": "PASIGHAT",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 424404,
      "City District Name": "EAST SIANG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 791103,
      "TXT_PINCODE_LOCALITY": "HILL TOP",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 424406,
      "City District Name": "PAPUM PARE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 791104,
      "TXT_PINCODE_LOCALITY": "MEBO",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 424404,
      "City District Name": "EAST SIANG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 791105,
      "TXT_PINCODE_LOCALITY": "LIKABALI",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 424411,
      "City District Name": "WEST SIANG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 791110,
      "TXT_PINCODE_LOCALITY": "NAHARLAGAN",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 424406,
      "City District Name": "PAPUM PARE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 791111,
      "TXT_PINCODE_LOCALITY": "ITANAGAR H.O",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 424406,
      "City District Name": "PAPUM PARE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 791112,
      "TXT_PINCODE_LOCALITY": "DOIMUKH",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 424406,
      "City District Name": "PAPUM PARE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 791113,
      "TXT_PINCODE_LOCALITY": "BANDERDEWA",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 424406,
      "City District Name": "PAPUM PARE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 791120,
      "TXT_PINCODE_LOCALITY": "ZERO",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6472,
      "City District Name": "SUBANSIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 791121,
      "TXT_PINCODE_LOCALITY": "KIMIN",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 424406,
      "City District Name": "PAPUM PARE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 791122,
      "TXT_PINCODE_LOCALITY": "UPPER SUBANSIRI",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 6472,
      "City District Name": "SUBANSIRI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 791123,
      "TXT_PINCODE_LOCALITY": "BANDAR DEWA",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 424406,
      "City District Name": "PAPUM PARE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 791125,
      "TXT_PINCODE_LOCALITY": "LIKABILI",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 424411,
      "City District Name": "WEST SIANG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 792001,
      "TXT_PINCODE_LOCALITY": "TEZU",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 424405,
      "City District Name": "LOHIT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 792051,
      "TXT_PINCODE_LOCALITY": "MECHUKA",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 424406,
      "City District Name": "PAPUM PARE",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 792055,
      "TXT_PINCODE_LOCALITY": "TUTING",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 424407,
      "City District Name": "TIRAP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 792061,
      "TXT_PINCODE_LOCALITY": "TUTING",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 424411,
      "City District Name": "WEST SIANG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 792101,
      "TXT_PINCODE_LOCALITY": "ANINI",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 424402,
      "City District Name": "DIBANG VALLEY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 792102,
      "TXT_PINCODE_LOCALITY": "HAYULIANG",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 424405,
      "City District Name": "LOHIT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 792103,
      "TXT_PINCODE_LOCALITY": "NAMSAI",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 424405,
      "City District Name": "LOHIT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 792104,
      "TXT_PINCODE_LOCALITY": "CHOUKHAM",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 424405,
      "City District Name": "LOHIT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 792110,
      "TXT_PINCODE_LOCALITY": "ROING",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 424402,
      "City District Name": "DIBANG VALLEY",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 792111,
      "TXT_PINCODE_LOCALITY": "SUNPURA",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 424405,
      "City District Name": "LOHIT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 792120,
      "TXT_PINCODE_LOCALITY": "TIRAP",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 424407,
      "City District Name": "TIRAP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 792121,
      "TXT_PINCODE_LOCALITY": "JAIRAMPUR",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 2701,
      "City District Name": "CHANGLANG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 792122,
      "TXT_PINCODE_LOCALITY": "MIAO",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 2701,
      "City District Name": "CHANGLANG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 792123,
      "TXT_PINCODE_LOCALITY": "NAMPONG",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 424407,
      "City District Name": "TIRAP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 793001,
      "TXT_PINCODE_LOCALITY": "SHILLONG",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 1885,
      "City District Name": "SHILLONG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 793002,
      "TXT_PINCODE_LOCALITY": "HINDUMISSIONROAD",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424455,
      "City District Name": "EAST KHASI HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 793003,
      "TXT_PINCODE_LOCALITY": "LAITUMKHRAH",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424455,
      "City District Name": "EAST KHASI HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 793004,
      "TXT_PINCODE_LOCALITY": "LABAN",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424455,
      "City District Name": "EAST KHASI HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 793005,
      "TXT_PINCODE_LOCALITY": "UPPER SHILLONG",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424455,
      "City District Name": "EAST KHASI HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 793006,
      "TXT_PINCODE_LOCALITY": "UMPLING",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424455,
      "City District Name": "EAST KHASI HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 793007,
      "TXT_PINCODE_LOCALITY": "HAPPY VALLEY",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424455,
      "City District Name": "EAST KHASI HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 793008,
      "TXT_PINCODE_LOCALITY": "PHUDMAWRI MAWLAI",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424455,
      "City District Name": "EAST KHASI HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 793009,
      "TXT_PINCODE_LOCALITY": "NONGLYER",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424455,
      "City District Name": "EAST KHASI HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 793010,
      "TXT_PINCODE_LOCALITY": "LAITKOR PEAK",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424455,
      "City District Name": "EAST KHASI HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 793011,
      "TXT_PINCODE_LOCALITY": "ASSAM RIFLES",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424455,
      "City District Name": "EAST KHASI HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 793012,
      "TXT_PINCODE_LOCALITY": "MAWPAT",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424455,
      "City District Name": "EAST KHASI HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 793013,
      "TXT_PINCODE_LOCALITY": "RILBONG",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424455,
      "City District Name": "EAST KHASI HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 793014,
      "TXT_PINCODE_LOCALITY": "NONGTHYMMAI",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 1885,
      "City District Name": "SHILLONG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 793016,
      "TXT_PINCODE_LOCALITY": "TSS ROAD",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424455,
      "City District Name": "EAST KHASI HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 793018,
      "TXT_PINCODE_LOCALITY": "NEIGRIHMS",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424455,
      "City District Name": "EAST KHASI HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 793021,
      "TXT_PINCODE_LOCALITY": "MADAN RITTING",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424455,
      "City District Name": "EAST KHASI HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 793022,
      "TXT_PINCODE_LOCALITY": "NEHU S.O",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424455,
      "City District Name": "EAST KHASI HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 793025,
      "TXT_PINCODE_LOCALITY": "GOVERNORSCAMP",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424455,
      "City District Name": "EAST KHASI HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 793101,
      "TXT_PINCODE_LOCALITY": "BURNIHAT",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424343,
      "City District Name": "RI BHOI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 793102,
      "TXT_PINCODE_LOCALITY": "NONGPOH",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424343,
      "City District Name": "RI BHOI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 793103,
      "TXT_PINCODE_LOCALITY": "BARAPANI",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424455,
      "City District Name": "EAST KHASI HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 793104,
      "TXT_PINCODE_LOCALITY": "SUMER",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424343,
      "City District Name": "RI BHOI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 793105,
      "TXT_PINCODE_LOCALITY": "NAYABUNGLOW",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424455,
      "City District Name": "EAST KHASI HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 793106,
      "TXT_PINCODE_LOCALITY": "BALAT",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424455,
      "City District Name": "EAST KHASI HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 793107,
      "TXT_PINCODE_LOCALITY": "BHOLAGANJBAZAR",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424455,
      "City District Name": "EAST KHASI HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 793108,
      "TXT_PINCODE_LOCALITY": "CHERRAPUNJEE",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424455,
      "City District Name": "EAST KHASI HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 793109,
      "TXT_PINCODE_LOCALITY": "DAWKI",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 1799,
      "City District Name": "JAINTIA HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 793110,
      "TXT_PINCODE_LOCALITY": "PYNURSLA",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424455,
      "City District Name": "EAST KHASI HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 793111,
      "TXT_PINCODE_LOCALITY": "CHERRABAZAR",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424455,
      "City District Name": "EAST KHASI HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 793112,
      "TXT_PINCODE_LOCALITY": "SHELLA",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424455,
      "City District Name": "EAST KHASI HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 793113,
      "TXT_PINCODE_LOCALITY": "MAWSYNRAM",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424450,
      "City District Name": "WEST KHASI HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 793114,
      "TXT_PINCODE_LOCALITY": "MAWAKYRWAT",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424450,
      "City District Name": "WEST KHASI HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 793119,
      "TXT_PINCODE_LOCALITY": "NONGSTOIN",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424450,
      "City District Name": "WEST KHASI HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 793120,
      "TXT_PINCODE_LOCALITY": "MOIRANG",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424450,
      "City District Name": "WEST KHASI HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 793121,
      "TXT_PINCODE_LOCALITY": "MAWPHLANG",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424455,
      "City District Name": "EAST KHASI HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 793122,
      "TXT_PINCODE_LOCALITY": "UNIONCHRISTIANCOLLEGE",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424455,
      "City District Name": "EAST KHASI HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 793123,
      "TXT_PINCODE_LOCALITY": "UMSAIN",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424455,
      "City District Name": "EAST KHASI HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 793150,
      "TXT_PINCODE_LOCALITY": "JOWAI",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 1799,
      "City District Name": "JAINTIA HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 793151,
      "TXT_PINCODE_LOCALITY": "THADLAKEIN",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 1799,
      "City District Name": "JAINTIA HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 793158,
      "TXT_PINCODE_LOCALITY": "UMRANGSO",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 1799,
      "City District Name": "JAINTIA HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 793200,
      "TXT_PINCODE_LOCALITY": "KHLIAEHRIAT",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 1799,
      "City District Name": "JAINTIA HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 794001,
      "TXT_PINCODE_LOCALITY": "TURA H O",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424451,
      "City District Name": "WEST GARO HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 794002,
      "TXT_PINCODE_LOCALITY": "LOWER CHANDMARI",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424451,
      "City District Name": "WEST GARO HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 794005,
      "TXT_PINCODE_LOCALITY": "DOBASIPARA",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424451,
      "City District Name": "WEST GARO HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 794101,
      "TXT_PINCODE_LOCALITY": "ARAIMAIL",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424451,
      "City District Name": "WEST GARO HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 794102,
      "TXT_PINCODE_LOCALITY": "BAGHMARA",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424453,
      "City District Name": "SOUTH GARO HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 794103,
      "TXT_PINCODE_LOCALITY": "BARENGAPARA",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424451,
      "City District Name": "WEST GARO HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 794104,
      "TXT_PINCODE_LOCALITY": "FULBARI",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424451,
      "City District Name": "WEST GARO HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 794105,
      "TXT_PINCODE_LOCALITY": "GAROBANDHA",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424451,
      "City District Name": "WEST GARO HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 794106,
      "TXT_PINCODE_LOCALITY": "MAHENDRAGANJ",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424451,
      "City District Name": "WEST GARO HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 794107,
      "TXT_PINCODE_LOCALITY": "NANGALBIBRA",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424453,
      "City District Name": "SOUTH GARO HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 794108,
      "TXT_PINCODE_LOCALITY": "RESUBELPARA",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424456,
      "City District Name": "EAST GARO HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 794109,
      "TXT_PINCODE_LOCALITY": "TIKRIKILLA",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424451,
      "City District Name": "WEST GARO HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 794110,
      "TXT_PINCODE_LOCALITY": "RONGJENG",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424456,
      "City District Name": "EAST GARO HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 794111,
      "TXT_PINCODE_LOCALITY": "SIMSANGIRI",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424456,
      "City District Name": "EAST GARO HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 794112,
      "TXT_PINCODE_LOCALITY": "WILLIAMNAGAR",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424456,
      "City District Name": "EAST GARO HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 794114,
      "TXT_PINCODE_LOCALITY": "RONGRA",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424453,
      "City District Name": "SOUTH GARO HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 794115,
      "TXT_PINCODE_LOCALITY": "AMPATI",
      "NUM_STATE_CD": 151,
      "State Name": "MEGHALAYA",
      "NUM_CITYDISTRICT_CD": 424451,
      "City District Name": "WEST GARO HILLS",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795001,
      "TXT_PINCODE_LOCALITY": "IMPHAL",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 1884,
      "City District Name": "IMPHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795002,
      "TXT_PINCODE_LOCALITY": "MANTRIPUKHRI",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 1884,
      "City District Name": "IMPHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795003,
      "TXT_PINCODE_LOCALITY": "J N UNIVERSITY",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 1884,
      "City District Name": "IMPHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795004,
      "TXT_PINCODE_LOCALITY": "LAMPHELPAT",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 1884,
      "City District Name": "IMPHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795010,
      "TXT_PINCODE_LOCALITY": "CHINGMEIRONG.",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 441050,
      "City District Name": "IMPHAL EAST",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795011,
      "TXT_PINCODE_LOCALITY": "SWOMBUNG",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 424458,
      "City District Name": "UKHRUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795101,
      "TXT_PINCODE_LOCALITY": "SUGNU S. O",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 6374,
      "City District Name": "BISHNUPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795102,
      "TXT_PINCODE_LOCALITY": "CHAKPIKARONG",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 5952,
      "City District Name": "THOUBAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795103,
      "TXT_PINCODE_LOCALITY": "KAKCHING BAZAR - 2088",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 5952,
      "City District Name": "THOUBAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795104,
      "TXT_PINCODE_LOCALITY": "TADUBI",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 424461,
      "City District Name": "SENAPATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795105,
      "TXT_PINCODE_LOCALITY": "MARAM",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 5952,
      "City District Name": "THOUBAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795106,
      "TXT_PINCODE_LOCALITY": "KARONG",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 5952,
      "City District Name": "THOUBAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795115,
      "TXT_PINCODE_LOCALITY": "GULERTHAL",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 1884,
      "City District Name": "IMPHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795116,
      "TXT_PINCODE_LOCALITY": "JIRIBAM",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 1884,
      "City District Name": "IMPHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795119,
      "TXT_PINCODE_LOCALITY": "CHERAPUT",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 5952,
      "City District Name": "THOUBAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795120,
      "TXT_PINCODE_LOCALITY": "HIYANGTHANG",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 1884,
      "City District Name": "IMPHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795121,
      "TXT_PINCODE_LOCALITY": "K K PRAMPAT",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 1884,
      "City District Name": "IMPHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795122,
      "TXT_PINCODE_LOCALITY": "KALAPAHAR",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 1884,
      "City District Name": "IMPHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795123,
      "TXT_PINCODE_LOCALITY": "WONGOI",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 6374,
      "City District Name": "BISHNUPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795124,
      "TXT_PINCODE_LOCALITY": "LOKTAK PROJECT S. O",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 424464,
      "City District Name": "CHURACHANDPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795125,
      "TXT_PINCODE_LOCALITY": "TAMEL",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 424459,
      "City District Name": "TAMENGLONG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795126,
      "TXT_PINCODE_LOCALITY": "BISHENPUR - 810",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 6374,
      "City District Name": "BISHNUPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795127,
      "TXT_PINCODE_LOCALITY": "CHENDEL",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 424463,
      "City District Name": "CHANDEL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795128,
      "TXT_PINCODE_LOCALITY": "CHURACHANDPUR",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 424464,
      "City District Name": "CHURACHANDPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795129,
      "TXT_PINCODE_LOCALITY": "KONGPOKPI",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 5952,
      "City District Name": "THOUBAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795130,
      "TXT_PINCODE_LOCALITY": "LILONG - 2753",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 1884,
      "City District Name": "IMPHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795131,
      "TXT_PINCODE_LOCALITY": "MOREH",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 424463,
      "City District Name": "CHANDEL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795132,
      "TXT_PINCODE_LOCALITY": "MAYANG IMPHAL",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 1884,
      "City District Name": "IMPHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795133,
      "TXT_PINCODE_LOCALITY": "TORBAN (KHETRI LEIKAI) - 2454",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 1884,
      "City District Name": "IMPHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795134,
      "TXT_PINCODE_LOCALITY": "BAMDIAR",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 441049,
      "City District Name": "IMPHAL WEST",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795135,
      "TXT_PINCODE_LOCALITY": "PARREL",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 1884,
      "City District Name": "IMPHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795136,
      "TXT_PINCODE_LOCALITY": "SEKMAI",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 5952,
      "City District Name": "THOUBAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795137,
      "TXT_PINCODE_LOCALITY": "SINGJAMAIBAZAR",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 5952,
      "City District Name": "THOUBAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795138,
      "TXT_PINCODE_LOCALITY": "THOUBAL",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 5952,
      "City District Name": "THOUBAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795139,
      "TXT_PINCODE_LOCALITY": "THINGAT",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 424464,
      "City District Name": "CHURACHANDPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795140,
      "TXT_PINCODE_LOCALITY": "TULIHAL",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 1884,
      "City District Name": "IMPHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795141,
      "TXT_PINCODE_LOCALITY": "BHALOK",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 424459,
      "City District Name": "TAMENGLONG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795142,
      "TXT_PINCODE_LOCALITY": "UKHRUL",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 424458,
      "City District Name": "UKHRUL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795143,
      "TXT_PINCODE_LOCALITY": "THANLON",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 424464,
      "City District Name": "CHURACHANDPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795144,
      "TXT_PINCODE_LOCALITY": "TENGNOUPAL",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 424463,
      "City District Name": "CHANDEL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795145,
      "TXT_PINCODE_LOCALITY": "LAIKOT VILLAGE",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 424464,
      "City District Name": "CHURACHANDPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795146,
      "TXT_PINCODE_LOCALITY": "LUMSUNG",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 1884,
      "City District Name": "IMPHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795147,
      "TXT_PINCODE_LOCALITY": "NUNGBA",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 424459,
      "City District Name": "TAMENGLONG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795150,
      "TXT_PINCODE_LOCALITY": "MAO",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 1884,
      "City District Name": "IMPHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795151,
      "TXT_PINCODE_LOCALITY": "KANGLA TONGBI",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 1884,
      "City District Name": "IMPHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 795158,
      "TXT_PINCODE_LOCALITY": "CHING KOMPONG",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 1884,
      "City District Name": "IMPHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 796001,
      "TXT_PINCODE_LOCALITY": "AIZAWL H O",
      "NUM_STATE_CD": 152,
      "State Name": "MIZORAM",
      "NUM_CITYDISTRICT_CD": 1953,
      "City District Name": "AIZAWL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 796005,
      "TXT_PINCODE_LOCALITY": "KULIKAWN",
      "NUM_STATE_CD": 152,
      "State Name": "MIZORAM",
      "NUM_CITYDISTRICT_CD": 5588,
      "City District Name": "SERCHHIP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 796007,
      "TXT_PINCODE_LOCALITY": "CHANDMARI",
      "NUM_STATE_CD": 152,
      "State Name": "MIZORAM",
      "NUM_CITYDISTRICT_CD": 1953,
      "City District Name": "AIZAWL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 796009,
      "TXT_PINCODE_LOCALITY": "VAIVAKAAN",
      "NUM_STATE_CD": 152,
      "State Name": "MIZORAM",
      "NUM_CITYDISTRICT_CD": 1953,
      "City District Name": "AIZAWL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 796010,
      "TXT_PINCODE_LOCALITY": "LAHOAL",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 424407,
      "City District Name": "TIRAP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 796012,
      "TXT_PINCODE_LOCALITY": "RAMHLUN",
      "NUM_STATE_CD": 152,
      "State Name": "MIZORAM",
      "NUM_CITYDISTRICT_CD": 5588,
      "City District Name": "SERCHHIP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 796014,
      "TXT_PINCODE_LOCALITY": "KHAMRANG",
      "NUM_STATE_CD": 152,
      "State Name": "MIZORAM",
      "NUM_CITYDISTRICT_CD": 1953,
      "City District Name": "AIZAWL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 796015,
      "TXT_PINCODE_LOCALITY": "TLAINGNUAM",
      "NUM_STATE_CD": 152,
      "State Name": "MIZORAM",
      "NUM_CITYDISTRICT_CD": 1953,
      "City District Name": "AIZAWL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 796017,
      "TXT_PINCODE_LOCALITY": "ZEMABAWK",
      "NUM_STATE_CD": 152,
      "State Name": "MIZORAM",
      "NUM_CITYDISTRICT_CD": 1953,
      "City District Name": "AIZAWL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 796025,
      "TXT_PINCODE_LOCALITY": "DUSTLANG",
      "NUM_STATE_CD": 152,
      "State Name": "MIZORAM",
      "NUM_CITYDISTRICT_CD": 2679,
      "City District Name": "CHAMPHAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 796031,
      "TXT_PINCODE_LOCALITY": "SIHPHIR",
      "NUM_STATE_CD": 152,
      "State Name": "MIZORAM",
      "NUM_CITYDISTRICT_CD": 1953,
      "City District Name": "AIZAWL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 796070,
      "TXT_PINCODE_LOCALITY": "KAWNPUR",
      "NUM_STATE_CD": 152,
      "State Name": "MIZORAM",
      "NUM_CITYDISTRICT_CD": 4039,
      "City District Name": "KOLASIB",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 796075,
      "TXT_PINCODE_LOCALITY": "THINGDAWAL",
      "NUM_STATE_CD": 152,
      "State Name": "MIZORAM",
      "NUM_CITYDISTRICT_CD": 4039,
      "City District Name": "KOLASIB",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 796081,
      "TXT_PINCODE_LOCALITY": "KOLASIB",
      "NUM_STATE_CD": 152,
      "State Name": "MIZORAM",
      "NUM_CITYDISTRICT_CD": 4039,
      "City District Name": "KOLASIB",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 796101,
      "TXT_PINCODE_LOCALITY": "VAIRENGTE",
      "NUM_STATE_CD": 152,
      "State Name": "MIZORAM",
      "NUM_CITYDISTRICT_CD": 4039,
      "City District Name": "KOLASIB",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 796111,
      "TXT_PINCODE_LOCALITY": "DARLAWN",
      "NUM_STATE_CD": 152,
      "State Name": "MIZORAM",
      "NUM_CITYDISTRICT_CD": 1953,
      "City District Name": "AIZAWL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 796161,
      "TXT_PINCODE_LOCALITY": "THINGSULTHLIAH",
      "NUM_STATE_CD": 152,
      "State Name": "MIZORAM",
      "NUM_CITYDISTRICT_CD": 1953,
      "City District Name": "AIZAWL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 796181,
      "TXT_PINCODE_LOCALITY": "SERCHHIP - 4334",
      "NUM_STATE_CD": 152,
      "State Name": "MIZORAM",
      "NUM_CITYDISTRICT_CD": 5588,
      "City District Name": "SERCHHIP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 796190,
      "TXT_PINCODE_LOCALITY": "AIBAWK",
      "NUM_STATE_CD": 152,
      "State Name": "MIZORAM",
      "NUM_CITYDISTRICT_CD": 1953,
      "City District Name": "AIZAWL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 796230,
      "TXT_PINCODE_LOCALITY": "SIALSUK",
      "NUM_STATE_CD": 152,
      "State Name": "MIZORAM",
      "NUM_CITYDISTRICT_CD": 1953,
      "City District Name": "AIZAWL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 796261,
      "TXT_PINCODE_LOCALITY": "SAITUAL",
      "NUM_STATE_CD": 152,
      "State Name": "MIZORAM",
      "NUM_CITYDISTRICT_CD": 1953,
      "City District Name": "AIZAWL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 796290,
      "TXT_PINCODE_LOCALITY": "NGOPA",
      "NUM_STATE_CD": 152,
      "State Name": "MIZORAM",
      "NUM_CITYDISTRICT_CD": 1953,
      "City District Name": "AIZAWL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 796310,
      "TXT_PINCODE_LOCALITY": "KHAWZAWL",
      "NUM_STATE_CD": 152,
      "State Name": "MIZORAM",
      "NUM_CITYDISTRICT_CD": 2679,
      "City District Name": "CHAMPHAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 796321,
      "TXT_PINCODE_LOCALITY": "CHAMPAI",
      "NUM_STATE_CD": 152,
      "State Name": "MIZORAM",
      "NUM_CITYDISTRICT_CD": 2679,
      "City District Name": "CHAMPHAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 796370,
      "TXT_PINCODE_LOCALITY": "LUNGDAR - 745",
      "NUM_STATE_CD": 152,
      "State Name": "MIZORAM",
      "NUM_CITYDISTRICT_CD": 5588,
      "City District Name": "SERCHHIP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 796410,
      "TXT_PINCODE_LOCALITY": "SAIRANG",
      "NUM_STATE_CD": 152,
      "State Name": "MIZORAM",
      "NUM_CITYDISTRICT_CD": 1953,
      "City District Name": "AIZAWL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 796441,
      "TXT_PINCODE_LOCALITY": "ZAWLNUAM (MUMIT)",
      "NUM_STATE_CD": 152,
      "State Name": "MIZORAM",
      "NUM_CITYDISTRICT_CD": 4375,
      "City District Name": "MAMIT",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 796471,
      "TXT_PINCODE_LOCALITY": "LAKHICHERRA",
      "NUM_STATE_CD": 152,
      "State Name": "MIZORAM",
      "NUM_CITYDISTRICT_CD": 1953,
      "City District Name": "AIZAWL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 796571,
      "TXT_PINCODE_LOCALITY": "HNAHTHIAL",
      "NUM_STATE_CD": 152,
      "State Name": "MIZORAM",
      "NUM_CITYDISTRICT_CD": 4270,
      "City District Name": "LUNGLEI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 796629,
      "TXT_PINCODE_LOCALITY": "DEOMALI",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 424407,
      "City District Name": "TIRAP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 796630,
      "TXT_PINCODE_LOCALITY": "KHONSA",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 424407,
      "City District Name": "TIRAP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 796631,
      "TXT_PINCODE_LOCALITY": "LONGDING",
      "NUM_STATE_CD": 146,
      "State Name": "ARUNACHAL PRADESH",
      "NUM_CITYDISTRICT_CD": 424407,
      "City District Name": "TIRAP",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 796691,
      "TXT_PINCODE_LOCALITY": "ZOTLANG",
      "NUM_STATE_CD": 152,
      "State Name": "MIZORAM",
      "NUM_CITYDISTRICT_CD": 4270,
      "City District Name": "LUNGLEI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 796701,
      "TXT_PINCODE_LOCALITY": "LUNGLEI",
      "NUM_STATE_CD": 152,
      "State Name": "MIZORAM",
      "NUM_CITYDISTRICT_CD": 4270,
      "City District Name": "LUNGLEI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 796710,
      "TXT_PINCODE_LOCALITY": "BUNGHMUN",
      "NUM_STATE_CD": 152,
      "State Name": "MIZORAM",
      "NUM_CITYDISTRICT_CD": 1953,
      "City District Name": "AIZAWL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 796730,
      "TXT_PINCODE_LOCALITY": "LUNGSEN",
      "NUM_STATE_CD": 152,
      "State Name": "MIZORAM",
      "NUM_CITYDISTRICT_CD": 4270,
      "City District Name": "LUNGLEI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 796751,
      "TXT_PINCODE_LOCALITY": "DEMAGIRI",
      "NUM_STATE_CD": 152,
      "State Name": "MIZORAM",
      "NUM_CITYDISTRICT_CD": 4270,
      "City District Name": "LUNGLEI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 796770,
      "TXT_PINCODE_LOCALITY": "CHAWNGTE",
      "NUM_STATE_CD": 152,
      "State Name": "MIZORAM",
      "NUM_CITYDISTRICT_CD": 4270,
      "City District Name": "LUNGLEI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 796891,
      "TXT_PINCODE_LOCALITY": "LAWNGTLAI",
      "NUM_STATE_CD": 152,
      "State Name": "MIZORAM",
      "NUM_CITYDISTRICT_CD": 424449,
      "City District Name": "LAWNGTLAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 796901,
      "TXT_PINCODE_LOCALITY": "SAIHA",
      "NUM_STATE_CD": 152,
      "State Name": "MIZORAM",
      "NUM_CITYDISTRICT_CD": 5445,
      "City District Name": "SAIHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 796911,
      "TXT_PINCODE_LOCALITY": "TUIPANG",
      "NUM_STATE_CD": 152,
      "State Name": "MIZORAM",
      "NUM_CITYDISTRICT_CD": 5445,
      "City District Name": "SAIHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 797001,
      "TXT_PINCODE_LOCALITY": "KOHIMA PWD",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 1887,
      "City District Name": "KOHIMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 797100,
      "TXT_PINCODE_LOCALITY": "BHANDESI",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 6253,
      "City District Name": "WOKHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 797101,
      "TXT_PINCODE_LOCALITY": "BIREMA",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 1887,
      "City District Name": "KOHIMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 797102,
      "TXT_PINCODE_LOCALITY": "CHIZAMI",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 5118,
      "City District Name": "PHEK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 797103,
      "TXT_PINCODE_LOCALITY": "CHUMUKHEDIMA",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 3030,
      "City District Name": "DIMAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 797104,
      "TXT_PINCODE_LOCALITY": "CHAZOUBA",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 1887,
      "City District Name": "KOHIMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 797105,
      "TXT_PINCODE_LOCALITY": "CHEICHEMA",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 1887,
      "City District Name": "KOHIMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 797106,
      "TXT_PINCODE_LOCALITY": "GHASPANI",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 3030,
      "City District Name": "DIMAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 797107,
      "TXT_PINCODE_LOCALITY": "PFUTSERRO",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 5118,
      "City District Name": "PHEK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 797108,
      "TXT_PINCODE_LOCALITY": "PHEK",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 5118,
      "City District Name": "PHEK",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 797109,
      "TXT_PINCODE_LOCALITY": "TSEMINYU",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 1887,
      "City District Name": "KOHIMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 797110,
      "TXT_PINCODE_LOCALITY": "ZALUKEI",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 1887,
      "City District Name": "KOHIMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 797111,
      "TXT_PINCODE_LOCALITY": "WOKHA",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 6253,
      "City District Name": "WOKHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 797112,
      "TXT_PINCODE_LOCALITY": "CIRCULAR ROAD",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 3030,
      "City District Name": "DIMAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 797121,
      "TXT_PINCODE_LOCALITY": "PIPHEMA",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 3030,
      "City District Name": "DIMAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 797122,
      "TXT_PINCODE_LOCALITY": "PHERIMA",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 3030,
      "City District Name": "DIMAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 797123,
      "TXT_PINCODE_LOCALITY": "RANGAPAHAR",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 3030,
      "City District Name": "DIMAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 797124,
      "TXT_PINCODE_LOCALITY": "SUGAR MILL PROJECT",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 3030,
      "City District Name": "DIMAPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 797125,
      "TXT_PINCODE_LOCALITY": "ZAKHAMA",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 1887,
      "City District Name": "KOHIMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 797126,
      "TXT_PINCODE_LOCALITY": "ZUBZA",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 1887,
      "City District Name": "KOHIMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 797127,
      "TXT_PINCODE_LOCALITY": "VISWAMA",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 1887,
      "City District Name": "KOHIMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 798001,
      "TXT_PINCODE_LOCALITY": "AIZAWL",
      "NUM_STATE_CD": 152,
      "State Name": "MIZORAM",
      "NUM_CITYDISTRICT_CD": 1953,
      "City District Name": "AIZAWL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 798105,
      "TXT_PINCODE_LOCALITY": "GIRIBAM",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 424459,
      "City District Name": "TAMENGLONG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 798126,
      "TXT_PINCODE_LOCALITY": "CHEJANG",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 424464,
      "City District Name": "CHURACHANDPUR",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 798130,
      "TXT_PINCODE_LOCALITY": "BUNGTECHIRU",
      "NUM_STATE_CD": 150,
      "State Name": "MANIPUR",
      "NUM_CITYDISTRICT_CD": 1884,
      "City District Name": "IMPHAL",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 798601,
      "TXT_PINCODE_LOCALITY": "MOKOKCHUNG",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 4549,
      "City District Name": "MOKOKCHUNG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 798611,
      "TXT_PINCODE_LOCALITY": "KIPHIRA",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 6014,
      "City District Name": "TUENSANG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 798612,
      "TXT_PINCODE_LOCALITY": "TUENSANG",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 6014,
      "City District Name": "TUENSANG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 798613,
      "TXT_PINCODE_LOCALITY": "CHANGTONGIA",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 4549,
      "City District Name": "MOKOKCHUNG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 798614,
      "TXT_PINCODE_LOCALITY": "CHUCHYEMLONG",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 4549,
      "City District Name": "MOKOKCHUNG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 798615,
      "TXT_PINCODE_LOCALITY": "IMPUR",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 4549,
      "City District Name": "MOKOKCHUNG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 798616,
      "TXT_PINCODE_LOCALITY": "LONGKHIM",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 6014,
      "City District Name": "TUENSANG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 798617,
      "TXT_PINCODE_LOCALITY": "ONGPANGKONG",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 4549,
      "City District Name": "MOKOKCHUNG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 798618,
      "TXT_PINCODE_LOCALITY": "TULI",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 4549,
      "City District Name": "MOKOKCHUNG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 798619,
      "TXT_PINCODE_LOCALITY": "AKOLATO",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 1887,
      "City District Name": "KOHIMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 798620,
      "TXT_PINCODE_LOCALITY": "ZUNHEBOTO",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 6278,
      "City District Name": "ZUNHEBOTO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 798621,
      "TXT_PINCODE_LOCALITY": "MON",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 637,
      "City District Name": "MON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 798622,
      "TXT_PINCODE_LOCALITY": "NAGINAMARA",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 637,
      "City District Name": "MON",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 798623,
      "TXT_PINCODE_LOCALITY": "PAPER NAGAR TULI",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 1887,
      "City District Name": "KOHIMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 798626,
      "TXT_PINCODE_LOCALITY": "AGHUNOTO",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 6278,
      "City District Name": "ZUNHEBOTO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 798627,
      "TXT_PINCODE_LOCALITY": "ATOIZU",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 6278,
      "City District Name": "ZUNHEBOTO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 798628,
      "TXT_PINCODE_LOCALITY": "MERARING",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 4549,
      "City District Name": "MOKOKCHUNG",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 798629,
      "TXT_PINCODE_LOCALITY": "SUTAKHA",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 6278,
      "City District Name": "ZUNHEBOTO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 798630,
      "TXT_PINCODE_LOCALITY": "KOBULONG",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 1887,
      "City District Name": "KOHIMA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 798631,
      "TXT_PINCODE_LOCALITY": "CHUKITONG",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 6253,
      "City District Name": "WOKHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 798632,
      "TXT_PINCODE_LOCALITY": "SUNGRA",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 6253,
      "City District Name": "WOKHA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 798679,
      "TXT_PINCODE_LOCALITY": "AIZUTO",
      "NUM_STATE_CD": 153,
      "State Name": "NAGALAND",
      "NUM_CITYDISTRICT_CD": 6278,
      "City District Name": "ZUNHEBOTO",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799001,
      "TXT_PINCODE_LOCALITY": "AGARTALA SECRETARIAT",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424426,
      "City District Name": "NORTH TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799002,
      "TXT_PINCODE_LOCALITY": "RAMNAGAR",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424424,
      "City District Name": "WEST TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799003,
      "TXT_PINCODE_LOCALITY": "BORDOWALI",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424424,
      "City District Name": "WEST TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799004,
      "TXT_PINCODE_LOCALITY": "AGARTALA",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424424,
      "City District Name": "WEST TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799005,
      "TXT_PINCODE_LOCALITY": "ABHOYNAGAR",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424424,
      "City District Name": "WEST TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799006,
      "TXT_PINCODE_LOCALITY": "KUNJABAN",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424424,
      "City District Name": "WEST TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799007,
      "TXT_PINCODE_LOCALITY": "DHALESWAR",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424424,
      "City District Name": "WEST TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799008,
      "TXT_PINCODE_LOCALITY": "KHAYASPUR",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424424,
      "City District Name": "WEST TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799009,
      "TXT_PINCODE_LOCALITY": "AGARTALA AERODROME",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424424,
      "City District Name": "WEST TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799010,
      "TXT_PINCODE_LOCALITY": "JOGENDRANAGAR",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424424,
      "City District Name": "WEST TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799011,
      "TXT_PINCODE_LOCALITY": "BARJALA",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424424,
      "City District Name": "WEST TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799012,
      "TXT_PINCODE_LOCALITY": "SALBAGAN - 1496",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424424,
      "City District Name": "WEST TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799014,
      "TXT_PINCODE_LOCALITY": "AGARTALA ONGC",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424424,
      "City District Name": "WEST TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799015,
      "TXT_PINCODE_LOCALITY": "BIMANGARH",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424424,
      "City District Name": "WEST TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799035,
      "TXT_PINCODE_LOCALITY": "RANIRBAZAR",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424424,
      "City District Name": "WEST TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799045,
      "TXT_PINCODE_LOCALITY": "BIRENDRA NAGAR",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424424,
      "City District Name": "WEST TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799055,
      "TXT_PINCODE_LOCALITY": "TRIPURA ENGG COLLEGE",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424424,
      "City District Name": "WEST TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799101,
      "TXT_PINCODE_LOCALITY": "NATUN BAZAR",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 444168,
      "City District Name": "GOMATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799102,
      "TXT_PINCODE_LOCALITY": "BISHALGARH",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 442157,
      "City District Name": "SIPAHIJALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799103,
      "TXT_PINCODE_LOCALITY": "BISRAMAGANJ",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 442157,
      "City District Name": "SIPAHIJALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799104,
      "TXT_PINCODE_LOCALITY": "SILACHARI S.O",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 444168,
      "City District Name": "GOMATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799105,
      "TXT_PINCODE_LOCALITY": "KHILPARA",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 444168,
      "City District Name": "GOMATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799106,
      "TXT_PINCODE_LOCALITY": "BOXA NAGAR",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 442157,
      "City District Name": "SIPAHIJALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799107,
      "TXT_PINCODE_LOCALITY": "CHARILAM",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 442157,
      "City District Name": "SIPAHIJALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799108,
      "TXT_PINCODE_LOCALITY": "AMPINAGAR",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424425,
      "City District Name": "SOUTH TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799109,
      "TXT_PINCODE_LOCALITY": "ZIRJHAMUKHA",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 444168,
      "City District Name": "GOMATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799115,
      "TXT_PINCODE_LOCALITY": "MELAGARH",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 442157,
      "City District Name": "SIPAHIJALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799119,
      "TXT_PINCODE_LOCALITY": "BAGMA",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424425,
      "City District Name": "SOUTH TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799120,
      "TXT_PINCODE_LOCALITY": "RADHAKISHOREPUR H O",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424425,
      "City District Name": "SOUTH TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799121,
      "TXT_PINCODE_LOCALITY": "MAHARANI",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 444168,
      "City District Name": "GOMATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799122,
      "TXT_PINCODE_LOCALITY": "MATABARI",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424425,
      "City District Name": "SOUTH TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799123,
      "TXT_PINCODE_LOCALITY": "JAMJURI",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424425,
      "City District Name": "SOUTH TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799125,
      "TXT_PINCODE_LOCALITY": "GARJEE S.O",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 444168,
      "City District Name": "GOMATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799130,
      "TXT_PINCODE_LOCALITY": "SEKERKOTE",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 442157,
      "City District Name": "SIPAHIJALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799131,
      "TXT_PINCODE_LOCALITY": "MELAGHAR",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 442157,
      "City District Name": "SIPAHIJALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799132,
      "TXT_PINCODE_LOCALITY": "KATHALIA",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 442157,
      "City District Name": "SIPAHIJALA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799141,
      "TXT_PINCODE_LOCALITY": "JOLAIBARI",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424425,
      "City District Name": "SOUTH TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799142,
      "TXT_PINCODE_LOCALITY": "MUHURIPUR",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424425,
      "City District Name": "SOUTH TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799143,
      "TXT_PINCODE_LOCALITY": "MANUBAZAR",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424425,
      "City District Name": "SOUTH TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799144,
      "TXT_PINCODE_LOCALITY": "KARBOOK S.O",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 444168,
      "City District Name": "GOMATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799145,
      "TXT_PINCODE_LOCALITY": "SABROOM",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424425,
      "City District Name": "SOUTH TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799146,
      "TXT_PINCODE_LOCALITY": "KALSIBAZAR",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424425,
      "City District Name": "SOUTH TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799147,
      "TXT_PINCODE_LOCALITY": "JALEFA BAZAR",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424425,
      "City District Name": "SOUTH TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799148,
      "TXT_PINCODE_LOCALITY": "BAIKARA",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424425,
      "City District Name": "SOUTH TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799149,
      "TXT_PINCODE_LOCALITY": "SATCHAND",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424425,
      "City District Name": "SOUTH TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799153,
      "TXT_PINCODE_LOCALITY": "RADHANAGAR",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424425,
      "City District Name": "SOUTH TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799155,
      "TXT_PINCODE_LOCALITY": "BELONIYA",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424425,
      "City District Name": "SOUTH TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799156,
      "TXT_PINCODE_LOCALITY": "RISHYAMUKH",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424425,
      "City District Name": "SOUTH TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799157,
      "TXT_PINCODE_LOCALITY": "ANANDAPUR S.O",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424425,
      "City District Name": "SOUTH TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799158,
      "TXT_PINCODE_LOCALITY": "RAMNAGAR",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424425,
      "City District Name": "SOUTH TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799159,
      "TXT_PINCODE_LOCALITY": "MATAI",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424425,
      "City District Name": "SOUTH TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799201,
      "TXT_PINCODE_LOCALITY": "KHOWAI",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 3982,
      "City District Name": "KHOWAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799202,
      "TXT_PINCODE_LOCALITY": "KHOWAI COURT",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 3982,
      "City District Name": "KHOWAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799203,
      "TXT_PINCODE_LOCALITY": "KALYANPUR",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 3982,
      "City District Name": "KHOWAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799204,
      "TXT_PINCODE_LOCALITY": "KULAIBAZAR",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424427,
      "City District Name": "DHALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799205,
      "TXT_PINCODE_LOCALITY": "TELIAMURA",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 3982,
      "City District Name": "KHOWAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799206,
      "TXT_PINCODE_LOCALITY": "BACHAIBARI",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 3982,
      "City District Name": "KHOWAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799210,
      "TXT_PINCODE_LOCALITY": "KAMALGHAT",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424424,
      "City District Name": "WEST TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799211,
      "TXT_PINCODE_LOCALITY": "MOHANPUR",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424424,
      "City District Name": "WEST TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799212,
      "TXT_PINCODE_LOCALITY": "SIDHAI",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424424,
      "City District Name": "WEST TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799213,
      "TXT_PINCODE_LOCALITY": "SIMNA",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424424,
      "City District Name": "WEST TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799214,
      "TXT_PINCODE_LOCALITY": "FATIKCHERRA",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424424,
      "City District Name": "WEST TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799250,
      "TXT_PINCODE_LOCALITY": "DHARMA NAGAR H O",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424426,
      "City District Name": "NORTH TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799251,
      "TXT_PINCODE_LOCALITY": "CHANDRAPUR",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 444168,
      "City District Name": "GOMATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799252,
      "TXT_PINCODE_LOCALITY": "BAGBASSA",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424426,
      "City District Name": "NORTH TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799253,
      "TXT_PINCODE_LOCALITY": "RAJABARI",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424426,
      "City District Name": "NORTH TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799258,
      "TXT_PINCODE_LOCALITY": "APARESHAKR",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424427,
      "City District Name": "DHALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799260,
      "TXT_PINCODE_LOCALITY": "PANISAGAR",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424426,
      "City District Name": "NORTH TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799261,
      "TXT_PINCODE_LOCALITY": "KADAMTALA",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424426,
      "City District Name": "NORTH TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799262,
      "TXT_PINCODE_LOCALITY": "CHURAIB",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424426,
      "City District Name": "NORTH TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799263,
      "TXT_PINCODE_LOCALITY": "PECHARTHAL",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 444124,
      "City District Name": "UNAKOTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799264,
      "TXT_PINCODE_LOCALITY": "KUMARGHAT",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 444124,
      "City District Name": "UNAKOTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799265,
      "TXT_PINCODE_LOCALITY": "MACHMARA",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 444124,
      "City District Name": "UNAKOTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799269,
      "TXT_PINCODE_LOCALITY": "JAMPURI",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424425,
      "City District Name": "SOUTH TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799270,
      "TXT_PINCODE_LOCALITY": "KANCHANPUR S. O",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424426,
      "City District Name": "NORTH TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799271,
      "TXT_PINCODE_LOCALITY": "DASDABAZAR",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424426,
      "City District Name": "NORTH TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799273,
      "TXT_PINCODE_LOCALITY": "TULAMURA",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 444168,
      "City District Name": "GOMATI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799275,
      "TXT_PINCODE_LOCALITY": "MANUGHAT",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424427,
      "City District Name": "DHALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799277,
      "TXT_PINCODE_LOCALITY": "KAILASHAHAR S.O",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 444124,
      "City District Name": "UNAKOTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799278,
      "TXT_PINCODE_LOCALITY": "SALEMA",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424427,
      "City District Name": "DHALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799279,
      "TXT_PINCODE_LOCALITY": "PAITURBAZAR",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424426,
      "City District Name": "NORTH TRIPURA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799285,
      "TXT_PINCODE_LOCALITY": "KAMALPUR",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424427,
      "City District Name": "DHALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799286,
      "TXT_PINCODE_LOCALITY": "HALAHALI",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424427,
      "City District Name": "DHALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799287,
      "TXT_PINCODE_LOCALITY": "MANIKBHANDAR",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424427,
      "City District Name": "DHALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799288,
      "TXT_PINCODE_LOCALITY": "KANCHANBARI",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 444124,
      "City District Name": "UNAKOTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799289,
      "TXT_PINCODE_LOCALITY": "AMBASSA",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 424427,
      "City District Name": "DHALAI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 799290,
      "TXT_PINCODE_LOCALITY": "FATIKROY",
      "NUM_STATE_CD": 155,
      "State Name": "TRIPURA",
      "NUM_CITYDISTRICT_CD": 444124,
      "City District Name": "UNAKOTI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110101,
      "TXT_PINCODE_LOCALITY": "NEW DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110102,
      "TXT_PINCODE_LOCALITY": "NEW DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110103,
      "TXT_PINCODE_LOCALITY": "NEW DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110104,
      "TXT_PINCODE_LOCALITY": "NEW DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110105,
      "TXT_PINCODE_LOCALITY": "NEW DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110106,
      "TXT_PINCODE_LOCALITY": "NEW DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110107,
      "TXT_PINCODE_LOCALITY": "NEW DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110108,
      "TXT_PINCODE_LOCALITY": "NEW DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110109,
      "TXT_PINCODE_LOCALITY": "NEW DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110110,
      "TXT_PINCODE_LOCALITY": "NODAL DELIVERY CENTRE(PARCEL), ROHINI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 441057,
      "City District Name": "NORTH DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110111,
      "TXT_PINCODE_LOCALITY": "NEW DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110112,
      "TXT_PINCODE_LOCALITY": "NEW DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110113,
      "TXT_PINCODE_LOCALITY": "NEW DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110114,
      "TXT_PINCODE_LOCALITY": "NEW DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110115,
      "TXT_PINCODE_LOCALITY": "NEW DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110116,
      "TXT_PINCODE_LOCALITY": "NEW DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110117,
      "TXT_PINCODE_LOCALITY": "NEW DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110118,
      "TXT_PINCODE_LOCALITY": "NEW DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110119,
      "TXT_PINCODE_LOCALITY": "NEW DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110120,
      "TXT_PINCODE_LOCALITY": "NEW DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110121,
      "TXT_PINCODE_LOCALITY": "NEW DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110122,
      "TXT_PINCODE_LOCALITY": "NEW DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110123,
      "TXT_PINCODE_LOCALITY": "NEW DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110125,
      "TXT_PINCODE_LOCALITY": "NEW DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110301,
      "TXT_PINCODE_LOCALITY": "NEW DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110302,
      "TXT_PINCODE_LOCALITY": "NEW DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110501,
      "TXT_PINCODE_LOCALITY": "NEW DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110502,
      "TXT_PINCODE_LOCALITY": "NEW DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110503,
      "TXT_PINCODE_LOCALITY": "NEW DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110504,
      "TXT_PINCODE_LOCALITY": "NEW DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110505,
      "TXT_PINCODE_LOCALITY": "NEW DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110510,
      "TXT_PINCODE_LOCALITY": "NEW DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110511,
      "TXT_PINCODE_LOCALITY": "NEW DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110512,
      "TXT_PINCODE_LOCALITY": "NEW DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110601,
      "TXT_PINCODE_LOCALITY": "NEW DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110602,
      "TXT_PINCODE_LOCALITY": "NEW DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110603,
      "TXT_PINCODE_LOCALITY": "NEW DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110604,
      "TXT_PINCODE_LOCALITY": "NEW DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110606,
      "TXT_PINCODE_LOCALITY": "NEW DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110608,
      "TXT_PINCODE_LOCALITY": "NEW DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 110609,
      "TXT_PINCODE_LOCALITY": "NEW DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 116005,
      "TXT_PINCODE_LOCALITY": "NEW DELHI",
      "NUM_STATE_CD": 74,
      "State Name": "DELHI",
      "NUM_CITYDISTRICT_CD": 179,
      "City District Name": "NEW DELHI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 136134,
      "TXT_PINCODE_LOCALITY": "MOHRI",
      "NUM_STATE_CD": 127,
      "State Name": "HARYANA",
      "NUM_CITYDISTRICT_CD": 705,
      "City District Name": "KURUKSHETRA",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140016,
      "TXT_PINCODE_LOCALITY": "RUPNAGAR",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140035,
      "TXT_PINCODE_LOCALITY": "CHANDIGARH",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140055,
      "TXT_PINCODE_LOCALITY": "MOHALI",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 228,
      "City District Name": "MOHALI",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 140127,
      "TXT_PINCODE_LOCALITY": "BHALLAN",
      "NUM_STATE_CD": 69,
      "State Name": "PUNJAB",
      "NUM_CITYDISTRICT_CD": 424328,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160013,
      "TXT_PINCODE_LOCALITY": "CHANDIGARH",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160052,
      "TXT_PINCODE_LOCALITY": "MOHALI",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160053,
      "TXT_PINCODE_LOCALITY": "MOHALI",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160073,
      "TXT_PINCODE_LOCALITY": "CHANDIGARH",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160075,
      "TXT_PINCODE_LOCALITY": "CHANDIGARH",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    },
    {
      "NUM_PINCODE": 160121,
      "TXT_PINCODE_LOCALITY": "CHANDIGARH",
      "NUM_STATE_CD": 160,
      "State Name": "CHANDIGARH",
      "NUM_CITYDISTRICT_CD": 99999060,
      "City District Name": "CHANDIGARH",
      "NUM_COUNTRY_CD": 100
    }
  ]