export const goDigitQuestionList = [
    {
        "DiseaseID": "RPALC",
        "question_title": "Do you consume alcohol?",
        "Answer_Type": "BOOLEAN",
        "Answer_Code": null,
        "Answer_Description": null,
        "showSubQuestion": false,
        "subQuestions": [
          {
            "DiseaseID": "RSAL1",
            "question_title": "How often do you consume alcohol?",
            "Answer_Type": "DOMAIN",
            "Answer_Code": "AL1C",
            "Answer_Description": "Occasional or Social"
          },
          {
            "DiseaseID": "RSAL1",
            "question_title": "How often do you consume alcohol?",
            "Answer_Type": "DOMAIN",
            "Answer_Code": "AL1B",
            "Answer_Description": "Once in a week"
          },
          {
            "DiseaseID": "RSAL1",
            "question_title": "How often do you consume alcohol?",
            "Answer_Type": "DOMAIN",
            "Answer_Code": "AL1A",
            "Answer_Description": "Daily"
          }
        ]
      },
      {
        "DiseaseID": "RPAST",
        "question_title": "Do you have Asthma?",
        "Answer_Type": "BOOLEAN",
        "Answer_Code": null,
        "Answer_Description": null,
        "showSubQuestion": false,
        "subQuestions": [
          {
            "DiseaseID": "RSAS1",
            "question_title": "When was it diagnosed?",
            "Answer_Type": "DOMAIN",
            "Answer_Code": "AS1A",
            "Answer_Description": "Upto 10yrs"
          },
          {
            "DiseaseID": "RSAS1",
            "question_title": "When was it diagnosed?",
            "Answer_Type": "DOMAIN",
            "Answer_Code": "AS1B",
            "Answer_Description": "More than 10yrs"
          },
          {
            "DiseaseID": "RSAS2",
            "question_title": "Current Medication",
            "Answer_Type": "DOMAIN",
            "Answer_Code": "AS2C",
            "Answer_Description": "None"
          },
          {
            "DiseaseID": "RSAS2",
            "question_title": "Current Medication",
            "Answer_Type": "DOMAIN",
            "Answer_Code": "AS2B",
            "Answer_Description": "Non Steroids"
          },
          {
            "DiseaseID": "RSAS2",
            "question_title": "Current Medication",
            "Answer_Type": "DOMAIN",
            "Answer_Code": "AS2A",
            "Answer_Description": "Steroids"
          },
          {
            "DiseaseID": "RSAS3",
            "question_title": "How many times a year have you suffered with Asthma",
            "Answer_Type": "DOMAIN",
            "Answer_Code": "AS3A",
            "Answer_Description": "Less than 2"
          },
          {
            "DiseaseID": "RSAS3",
            "question_title": "How many times a year have you suffered with Asthma",
            "Answer_Type": "DOMAIN",
            "Answer_Code": "AS3C",
            "Answer_Description": "More than 5"
          },
          {
            "DiseaseID": "RSAS3",
            "question_title": "How many times a year have you suffered with Asthma",
            "Answer_Type": "DOMAIN",
            "Answer_Code": "AS3B",
            "Answer_Description": "2 to 5"
          },
          {
            "DiseaseID": "RSAS4",
            "question_title": "Were you hospitalized due to Asthma?",
            "Answer_Type": "BOOLEAN",
            "Answer_Code": null,
            "Answer_Description": null
          },
          {
            "DiseaseID": "RSAS5",
            "question_title": "Do you have records of hospitalization, if any?",
            "Answer_Type": "BOOLEAN",
            "Answer_Code": null,
            "Answer_Description": null
          },
          {
            "DiseaseID": "RSAS6",
            "question_title": "Any complications due to Asthma?",
            "Answer_Type": "BOOLEAN",
            "Answer_Code": null,
            "Answer_Description": null
          },
          {
            "DiseaseID": "RSAS7",
            "question_title": "Do you have records of complications, if any?",
            "Answer_Type": "BOOLEAN",
            "Answer_Code": null,
            "Answer_Description": null
          }
        ]
      },
      {
        "DiseaseID": "RPDBE",
        "question_title": "Do you have Diabetes?",
        "Answer_Type": "BOOLEAN",
        "Answer_Code": null,
        "Answer_Description": null,
        "showSubQuestion": false,
        "subQuestions": [
          {
            "DiseaseID": "RSDB1",
            "question_title": "Type of Diabetes",
            "Answer_Type": "DOMAIN",
            "Answer_Code": "DB1A",
            "Answer_Description": "Insulin dependant"
          },
          {
            "DiseaseID": "RSDB1",
            "question_title": "Type of Diabetes",
            "Answer_Type": "DOMAIN",
            "Answer_Code": "DB1B",
            "Answer_Description": "Non Insulin dependant"
          },
          {
            "DiseaseID": "RSDB10",
            "question_title": "What was FBS value?",
            "Answer_Type": "DOMAIN",
            "Answer_Code": "DB10B",
            "Answer_Description": "Abnormal"
          },
          {
            "DiseaseID": "RSDB10",
            "question_title": "What was FBS value?",
            "Answer_Type": "DOMAIN",
            "Answer_Code": "DB10A",
            "Answer_Description": "Within normal limits"
          },
          {
            "DiseaseID": "RSDB10",
            "question_title": "What was FBS value?",
            "Answer_Type": "DOMAIN",
            "Answer_Code": "DB10C",
            "Answer_Description": "Not listed"
          },
          {
            "DiseaseID": "RSDB2",
            "question_title": "Current Medication",
            "Answer_Type": "DOMAIN",
            "Answer_Code": "DB2A",
            "Answer_Description": "Insulin"
          },
          {
            "DiseaseID": "RSDB2",
            "question_title": "Current Medication",
            "Answer_Type": "DOMAIN",
            "Answer_Code": "DB2B",
            "Answer_Description": "Oral medication"
          },
          {
            "DiseaseID": "RSDB3",
            "question_title": "When was it diagnosed?",
            "Answer_Type": "DOMAIN",
            "Answer_Code": "DB3B",
            "Answer_Description": "More than 10yrs"
          },
          {
            "DiseaseID": "RSDB3",
            "question_title": "When was it diagnosed?",
            "Answer_Type": "DOMAIN",
            "Answer_Code": "DB3A",
            "Answer_Description": "Upto 10yrs"
          },
          {
            "DiseaseID": "RSDB4",
            "question_title": "When was your last Blood sugar tests done",
            "Answer_Type": "DOMAIN",
            "Answer_Code": "DB4C",
            "Answer_Description": "Not done in last 6 months"
          },
          {
            "DiseaseID": "RSDB4",
            "question_title": "When was your last Blood sugar tests done",
            "Answer_Type": "DOMAIN",
            "Answer_Code": "DB4A",
            "Answer_Description": "Less than 3 months"
          },
          {
            "DiseaseID": "RSDB4",
            "question_title": "When was your last Blood sugar tests done",
            "Answer_Type": "DOMAIN",
            "Answer_Code": "DB4B",
            "Answer_Description": "More than 3 months"
          },
          {
            "DiseaseID": "RSDB5",
            "question_title": "Were you hospitalized due to Diabetes?",
            "Answer_Type": "BOOLEAN",
            "Answer_Code": null,
            "Answer_Description": null
          },
          {
            "DiseaseID": "RSDB6",
            "question_title": "Do you have records of hospitalization, if any?",
            "Answer_Type": "BOOLEAN",
            "Answer_Code": null,
            "Answer_Description": null
          },
          {
            "DiseaseID": "RSDB7",
            "question_title": "Any complications for Diabetes",
            "Answer_Type": "BOOLEAN",
            "Answer_Code": null,
            "Answer_Description": null
          },
          {
            "DiseaseID": "RSDB8",
            "question_title": "Do you have records of complications, if any?",
            "Answer_Type": "BOOLEAN",
            "Answer_Code": null,
            "Answer_Description": null
          },
          {
            "DiseaseID": "RSDB9",
            "question_title": "What was HbA1c value?",
            "Answer_Type": "DOMAIN",
            "Answer_Code": "DB9A",
            "Answer_Description": "Upto 7.5%"
          },
          {
            "DiseaseID": "RSDB9",
            "question_title": "What was HbA1c value?",
            "Answer_Type": "DOMAIN",
            "Answer_Code": "DB9B",
            "Answer_Description": "More than 7.5%"
          }
        ]
      },

      {
        "DiseaseID": "RPDIG",
        "question_title": "Do you face any symptoms like chest pain, fatigue, weight loss, dizziness, joint pain, change in bowel habit, difficulty in breathing, pain in abdomen, bleeding/pain while passing stools etc?",
        "Answer_Type": "BOOLEAN",
        "Answer_Code": null,
        "Answer_Description": null,
        "showSubQuestion": false,
        "subQuestions": [
          {
            "DiseaseID": "RSDG1",
            "question_title": "Details of your undiagnosed symptoms?",
            "Answer_Type": "Text",
            "Answer_Code": null,
            "Answer_Description": null
          }
        ]
      },
      {
        "DiseaseID": "RPGYN",
        "question_title": "Do you have any Gynaecological problem?",
        "Answer_Type": "BOOLEAN",
        "Answer_Code": null,
        "Answer_Description": null,
        "showSubQuestion": false,
        "subQuestions": [
          {
            "DiseaseID": "RSGN1",
            "question_title": "What is the name of your condition?",
            "Answer_Type": "Text",
            "Answer_Code": null,
            "Answer_Description": null
          }
        ]
      },
      {
        "DiseaseID": "RPOGM",
        "question_title": "Is any member going through any medications currently?",
        "Answer_Type": "BOOLEAN",
        "Answer_Code": null,
        "Answer_Description": null,
        "showSubQuestion": false,
        "subQuestions": [
          {
            "DiseaseID": "RSGM1",
            "question_title": "Name of the medicines taken?",
            "Answer_Type": "Text",
            "Answer_Code": null,
            "Answer_Description": null
          }
        ]
      },
      {
        "DiseaseID": "RPTBC",
        "question_title": "Do you consume tobacco?",
        "Answer_Type": "BOOLEAN",
        "Answer_Code": null,
        "Answer_Description": null,
        "showSubQuestion": false,
        "subQuestions": [
          {
            "DiseaseID": "RSTB1",
            "question_title": "In which form do you consume tobacco?",
            "Answer_Type": "DOMAIN",
            "Answer_Code": "TB1B",
            "Answer_Description": "Chewable"
          },
          {
            "DiseaseID": "RSTB1",
            "question_title": "In which form do you consume tobacco?",
            "Answer_Type": "DOMAIN",
            "Answer_Code": "TB1A",
            "Answer_Description": "Smoke"
          },
          {
            "DiseaseID": "RSTB1",
            "question_title": "In which form do you consume tobacco?",
            "Answer_Type": "DOMAIN",
            "Answer_Code": "TB1C",
            "Answer_Description": "Both"
          }
        ]
      },
      {
        "DiseaseID": "RPTHR",
        "question_title": "Do you have Thyroid?",
        "Answer_Type": "BOOLEAN",
        "Answer_Code": null,
        "Answer_Description": null,
        "showSubQuestion": false,
        "subQuestions": [
          {
            "DiseaseID": "RSTH1",
            "question_title": "Type of Thyroid dysfunction?",
            "Answer_Type": "DOMAIN",
            "Answer_Code": "TH1A",
            "Answer_Description": "Hyperthyroidism"
          },
          {
            "DiseaseID": "RSTH1",
            "question_title": "Type of Thyroid dysfunction?",
            "Answer_Type": "DOMAIN",
            "Answer_Code": "TH1B",
            "Answer_Description": "Hypothyroidism"
          },
          {
            "DiseaseID": "RSTH1",
            "question_title": "Type of Thyroid dysfunction?",
            "Answer_Type": "DOMAIN",
            "Answer_Code": "TH1C",
            "Answer_Description": "Not sure"
          },
          {
            "DiseaseID": "RSTH2",
            "question_title": "Current Medication",
            "Answer_Type": "DOMAIN",
            "Answer_Code": "TH2B",
            "Answer_Description": "Thyroxine"
          },
          {
            "DiseaseID": "RSTH2",
            "question_title": "Current Medication",
            "Answer_Type": "DOMAIN",
            "Answer_Code": "TH2A",
            "Answer_Description": "Eltroxine"
          },
          {
            "DiseaseID": "RSTH2",
            "question_title": "Current Medication",
            "Answer_Type": "DOMAIN",
            "Answer_Code": "TH2C",
            "Answer_Description": "Not listed"
          },
          {
            "DiseaseID": "RSTH3",
            "question_title": "When was it diagnosed?",
            "Answer_Type": "DOMAIN",
            "Answer_Code": "TH3C",
            "Answer_Description": "10yrs or more"
          },
          {
            "DiseaseID": "RSTH3",
            "question_title": "When was it diagnosed?",
            "Answer_Type": "DOMAIN",
            "Answer_Code": "TH3A",
            "Answer_Description": "Less than 5yrs"
          },
          {
            "DiseaseID": "RSTH3",
            "question_title": "When was it diagnosed?",
            "Answer_Type": "DOMAIN",
            "Answer_Code": "TH3B",
            "Answer_Description": "5 to 9yrs"
          },
          {
            "DiseaseID": "RSTH4",
            "question_title": "Any associated complications?",
            "Answer_Type": "BOOLEAN",
            "Answer_Code": null,
            "Answer_Description": null
          }
        ]
      },
      {
        "DiseaseID": "RPHLD",
        "question_title": "Do you have Hyperlipidemia?",
        "Answer_Type": "BOOLEAN",
        "Answer_Code": null,
        "Answer_Description": null,
        "showSubQuestion": false,
        "subQuestions": [
          {
            "DiseaseID": "RSLD1",
            "question_title": "On medication for High lipids?",
            "Answer_Type": "BOOLEAN",
            "Answer_Code": null,
            "Answer_Description": null
          },
          {
            "DiseaseID": "RSLD2",
            "question_title": "Any complications with high lipids?",
            "Answer_Type": "BOOLEAN",
            "Answer_Code": null,
            "Answer_Description": null
          },
          {
            "DiseaseID": "RSLD3",
            "question_title": "When was it diagnosed?",
            "Answer_Type": "DOMAIN",
            "Answer_Code": "DL3B",
            "Answer_Description": "5 to 9yrs"
          },
          {
            "DiseaseID": "RSLD3",
            "question_title": "When was it diagnosed?",
            "Answer_Type": "DOMAIN",
            "Answer_Code": "DL3A",
            "Answer_Description": "Less than 5yrs"
          },
          {
            "DiseaseID": "RSLD3",
            "question_title": "When was it diagnosed?",
            "Answer_Type": "DOMAIN",
            "Answer_Code": "DL3C",
            "Answer_Description": "10yrs or more"
          }
        ]
      },
      {
        "DiseaseID": "RMPRE",
        "question_title": "Do you have any existing condition or disease for which they are being treated and/or and evaluated currently or in the past or have been diagnosed with? Other than common cold or viral fever?",
        "Answer_Type": "BOOLEAN",
        "Answer_Code": null,
        "Answer_Description": null,
        "showSubQuestion": false,
        "subQuestions": [
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR1",
            "Answer_Description": "Diabetes"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR12",
            "Answer_Description": "COPD"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR30",
            "Answer_Description": "Alzheimers disease"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR18",
            "Answer_Description": "Systemic Lupus Erythmatous"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR19",
            "Answer_Description": "Kidney failure"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR5",
            "Answer_Description": "Thyroid problem"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR50",
            "Answer_Description": "Kidney Stone"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR3",
            "Answer_Description": "Cancer"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR39",
            "Answer_Description": "Myasthenia gravis"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR22",
            "Answer_Description": "High Cholesterol"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR15",
            "Answer_Description": "Epilepsy"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR24",
            "Answer_Description": "Muscular dystrophy"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR42",
            "Answer_Description": "Anemia"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR17",
            "Answer_Description": "Rheumatoid Arthritis"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR7",
            "Answer_Description": "Asthma"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR20",
            "Answer_Description": "Liver disease"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR37",
            "Answer_Description": "Neurofibromatosis"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR41",
            "Answer_Description": "Mental retardation"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR26",
            "Answer_Description": "Crohns disease"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR6",
            "Answer_Description": "Multiple sclerosis"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR47",
            "Answer_Description": "Ankylosing Spondylitis"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR29",
            "Answer_Description": "Parkinsons disease"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR2",
            "Answer_Description": "Hypertension"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR46",
            "Answer_Description": "Rheumatic fever"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR32",
            "Answer_Description": "Ulcerative colitis"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR49",
            "Answer_Description": "Gynea disorder"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR4",
            "Answer_Description": "Heart disease"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR28",
            "Answer_Description": "HIV"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR31",
            "Answer_Description": "Downs syndrome"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR23",
            "Answer_Description": "Gout"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR35",
            "Answer_Description": "Accident involving fractures"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR9",
            "Answer_Description": "Hernia or hydrocele"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR33",
            "Answer_Description": "Physical disability or amputation"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR27",
            "Answer_Description": "Hepatitis"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR40",
            "Answer_Description": "Congenital defect"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR44",
            "Answer_Description": "Not listed"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR14",
            "Answer_Description": "Gastritis"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR48",
            "Answer_Description": "ENT related"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR25",
            "Answer_Description": "Psychiatric illness"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR10",
            "Answer_Description": "Anal fissure"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR43",
            "Answer_Description": "Blood disorder"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR34",
            "Answer_Description": "Blindness"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR36",
            "Answer_Description": "Deafness"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR38",
            "Answer_Description": "Thalessemia"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR11",
            "Answer_Description": "Neuropathy or Neurological disorder"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR8",
            "Answer_Description": "Arthritis"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR21",
            "Answer_Description": "Gall bladder disease"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR13",
            "Answer_Description": "Cataract"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR45",
            "Answer_Description": "Dengue"
          },
          {
            "DiseaseID": "RSPR1",
            "question_title": "What are Your current and past medical conditions or disease?",
            "Answer_Type": "Text",
            "Answer_Code": "SPR16",
            "Answer_Description": "Migraine"
          },
          {
            "DiseaseID": "RICOV",
            "question_title": "Have you been infected with Covid-19 in the last 15 days?",
            "Answer_Type": "BOOLEAN",
            "Answer_Code": null,
            "Answer_Description": null
          }
        ]
      }
      
      

      
      
]