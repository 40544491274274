export const DeductibleSI = [25000, 50000, 100000, 200000, 300000, 500000 ]
export const CriticalIllnessMinMaxRange = {
  "minValue": 100000,
  "maxValue": 20000000,
  "intvl" : 100000
}
export const CriticalIllnessPlanType =[
    {
      "PLANCode": "PLAN1",
      "PLAN_Description": "9  CriticalIllnesses covered",
      "AddonCover Type": "Critical Illness",
      "SumInsured": "Sum Insured should be  between 1Lakh to 2 Crore in multiple of 1000."
    },
    {
      "PLANCode": "PLAN2",
      "PLAN_Description": "12 CriticalIllnesses covered",
      "AddonCover Type": "Critical Illness",
      "SumInsured": "Sum Insured should be  between 1Lakh to 2 Crore in multiple of 1000."
    },
    {
      "PLANCode": "PLAN3",
      "PLAN_Description": "15 CriticalIllnesses covered",
      "AddonCover Type": "Critical Illness",
      "SumInsured": "Sum Insured should be  between 1Lakh to 2 Crore in multiple of 1000."
    },
    {
      "PLANCode": "PLAN4",
      "PLAN_Description": "18 CriticalIllnesses covered",
      "AddonCover Type": "Critical Illness",
      "SumInsured": "Sum Insured should be  between 1Lakh to 2 Crore in multiple of 1000."
    },
    {
      "PLANCode": "PLAN5",
      "PLAN_Description": "25 CriticalIllnesses covered",
      "AddonCover Type": "Critical Illness",
      "SumInsured": "Sum Insured should be  between 1Lakh to 2 Crore in multiple of 1000."
    },
    {
      "PLANCode": "PLAN6",
      "PLAN_Description": "40 CriticalIllnesses covered",
      "AddonCover Type": "Critical Illness",
      "SumInsured": "Sum Insured should be  between 1Lakh to 2 Crore in multiple of 1000."
    },
    {
      "PLANCode": "PLAN7",
      "PLAN_Description": "51 CriticalIllnesses covered",
      "AddonCover Type": "Critical Illness",
      "SumInsured": "Sum Insured should be  between 1Lakh to 2 Crore in multiple of 1000."
    }
  ]

  export const HospitalCashAddonCover_SumInsured = [500,1000 , 1500 , 2000 ]