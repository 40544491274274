export const careHeartQuestion = [
    {
      "question_set_code": "PEDSUP1",
      "question_code": 114,
      "question_title": "Cancer,Tumor,Polyp or Cyst",
      "type": "Question",
      "Response": "yes/no"
    },
    {
      "question_set_code": "PEDSUP1",
      "question_code": "ES52",
      "question_description": "Existing Since-MM/YYYY",
      "type": "subquestion",
      "Response": "date"
    },
    {
      "question_set_code": "PEDSUP10",
      "question_code": 105,
      "question_title": "HIV/SLE/ Arthiritis/ Scleroderma / Psoriasis/ bleeding or clotting disorders or any other diseases of Blood, Bone marrow/ Immunity or Skin",
      "type": "Question",
      "Response": "yes/no"
    },
    {
      "question_set_code": "PEDSUP10",
      "question_code": "ES52",
      "question_description": "Existing Since-MM/YYYY",
      "type": "subquestion",
      "Response": "date"
    },
    {
      "question_set_code": "PEDSUP13",
      "question_code": 214,
      "question_title": "Asthma / Tuberculosis / COPD/ Pleural effusion / Bronchitis / Emphysema or any other disease of Lungs, Pleura and airway or Respiratory disease",
      "type": "Question",
      "Response": "yes/no"
    },
    {
      "question_set_code": "PEDSUP13",
      "question_code": "ES52",
      "question_description": "Existing Since-MM/YYYY",
      "type": "subquestion",
      "Response": "date"
    },
    {
      "question_set_code": "PEDSUP4",
      "question_code": 222,
      "question_title": "Thyroid disease/ Cushing's disease/ Parathyroid Disease/ Addison's disease / Pituitary tumor/ disease or any other disorder of Endocrine system",
      "type": "Question",
      "Response": "yes/no"
    },
    {
      "question_set_code": "PEDSUP4",
      "question_code": "ES52",
      "question_description": "Existing Since-MM/YYYY",
      "type": "subquestion",
      "Response": "date"
    },
    {
      "question_set_code": "PEDSUP8",
      "question_code": 232,
      "question_title": "Cirrhosis/Hepatitis/Wilson disease/Pancreatitis/Liver, Crohn disease/Ulcerative Colitis/Piles/Gall bladder,Stomach/Intestine/Digestive system disease",
      "type": "Question",
      "Response": "yes/no"
    },
    {
      "question_set_code": "PEDSUP8",
      "question_code": "ES52",
      "question_description": "Existing Since-MM/YYYY",
      "type": "subquestion",
      "Response": "date"
    },
    {
      "question_set_code": "PEDSUP16",
      "question_code": 210,
      "question_title": "Any other disease / health adversity / injury/ condition / treatment not mentioned above",
      "type": "Question",
      "Response": "yes/no"
    },
    {
      "question_set_code": "PEDSUP16",
      "question_code": "ES52",
      "question_description": "Existing Since-MM/YYYY",
      "type": "subquestion",
      "Response": "date"
    },
    {
      "question_set_code": "PEDSUP16",
      "question_code": 714,
      "question_description": "Other Dieases Description",
      "type": "subquestion",
      "Response": "free text"
    },
    {
      "question_set_code": "HEDCAR27",
      "question_code": "H047",
      "question_title": "Smoke,consume alcohol,chew tobacco,ghutka or paan or use any recreational drugs? If Yes then please provide the frequency &amp; amount consumed",
      "type": "Question",
      "Response": "yes/no"
    },
    {
      "question_set_code": "HEDCAR27",
      "question_code": "H048",
      "question_description": "Hard Liquor- No.of Pegs in 30 ml per week",
      "type": "subquestion",
      "Response": "integer"
    },
    {
      "question_set_code": "HEDCAR27",
      "question_code": "H049",
      "question_description": "Beer-Bottles/ml per week",
      "type": "subquestion",
      "Response": "integer"
    },
    {
      "question_set_code": "HEDCAR27",
      "question_code": "H050",
      "question_description": "Wine-Glasses/ml per week",
      "type": "subquestion",
      "Response": "integer"
    },
    {
      "question_set_code": "HEDCAR27",
      "question_code": "H051",
      "question_description": "Smoking- No.of sticks per day",
      "type": "subquestion",
      "Response": "integer"
    },
    {
      "question_set_code": "HEDCAR27",
      "question_code": "H052",
      "question_description": "Gutka/Pan Masala/Chewing Tobacco etc- Grams per day",
      "type": "subquestion",
      "Response": "integer"
    },
    {
      "question_set_code": "HEDCH010",
      "question_code": "A010",
      "question_title": "Have you been advised for any other/repeat procedure or admission? If yes please share details",
      "type": "Question",
      "Response": "yes/no"
    },
    {
      "question_set_code": "HEDCH011",
      "question_code": "A011",
      "question_description": "Please share following documents, wherever applicable: Discharge summary/Investigation reports /Follow up records/Angiography report/CD/Latest ECHO, ECG, Stress test",
      "type": "Remove-no document upload functionality",
      "Response": ""
    },
    {
      "question_set_code": "HEDCH007",
      "question_code": "A007",
      "question_title": "Have you undergone any procedure or surgery for any cardiac ailment?",
      "type": "Question",
      "Response": "yes/no"
    },
    {
      "question_set_code": "PEDCH109",
      "question_code": 109,
      "question_title": "Are you or anyone of your family member 1st blood relationship suffering from any of the following conditions: Downs Syndrome/Turners Syndrome/Sickle Cell Anaemia/ Thalassemia Major/G6PD deficiency",
      "type": "Question",
      "Response": "yes/no"
    },
    {
      "question_set_code": "PEDCH109",
      "question_code": "SW001",
      "question_description": "Since When",
      "type": "subquestion",
      "Response": "date"
    },
    {
      "question_set_code": "HEDCH013",
      "question_code": "A013",
      "question_title": "Diabetes Mellitus / High Blood Sugar / Diabetes on Insulin or medication",
      "type": "Question",
      "Response": "yes/no"
    },
    {
      "question_set_code": "HEDCH015",
      "question_code": "A015",
      "question_title": "Has any of the Proposed to be Insured been hospitalized/recommended to take investigation/medication or has been under any prolonged treatment/undergone surgery for any illness/injury other than for childbirth/minor injuries",
      "type": "Question",
      "Response": "yes/no"
    },
    {
      "question_set_code": "PEDSUP51",
      "question_code": 152,
      "question_title": "Kidney stone/Renal Failure/ Dialysis/ Chronic Kidney Disease/ Prostate Disease or any other disease of Kidney, Urinary Tract or reproductive organs",
      "type": "Question",
      "Response": "yes/no"
    },
    {
      "question_set_code": "PEDSUP51",
      "question_code": "ES52",
      "Question Description": "Exisiting Since- MM/YYYY",
      "type": "subquestion",
      "Response": "date"
    },
    {
      "question_set_code": "PEDCH234",
      "question_code": 234,
      "question_title": "Motor Neuron Disease/ Muscular dystrophies/ Myasthenia Gravis or any other disease of Neuromuscular system muscles and/or nervous system",
      "type": "Question",
      "Response": "yes/no"
    },
    {
      "question_set_code": "PEDCH234",
      "question_code": "SW001",
      "question_description": "Since When",
      "type": "subquestion",
      "Response": "date"
    },
    {
      "question_set_code": "HEDCH009",
      "question_code": "A009",
      "question_title": "Have you experienced any below mentioned symptoms post undergoing above mentioned surgery/procedure",
      "type": "Question",
      "Response": "yes/no"
    },
    {
      "question_set_code": "HEDCH009",
      "question_code": "A014",
      "question_description": "Chest heaviness or Pain",
      "type": "subquestion",
      "Response": "yes/no"
    },
    {
      "question_set_code": "HEDCH009",
      "question_code": "A016",
      "question_description": "Difficulty in breathing",
      "type": "subquestion",
      "Response": "yes/no"
    },
    {
      "question_set_code": "HEDCH009",
      "question_code": "A017",
      "question_description": "Palpitations",
      "type": "subquestion",
      "Response": "yes/no"
    },
    {
      "question_set_code": "HEDCH009",
      "question_code": "A018",
      "question_description": "Loss of consciousness",
      "type": "subquestion",
      "Response": "yes/no"
    },
    {
      "question_set_code": "HEDCH009",
      "question_code": "A019",
      "question_description": "Weakness or dizziness",
      "type": "subquestion",
      "Response": "yes/no"
    },
    {
      "question_set_code": "PEDSUP23",
      "question_code": 164,
      "question_title": "Stroke/Paralysis/TransientIschemicAttack/MultipleSclerosis/Epilepsy/Mental-Psychiatricillness/Parkinson/Alzeihmer/Depression/Dementia/NervousSystem",
      "type": "Question",
      "Response": "yes/no"
    },
    {
      "question_set_code": "PEDSUP23",
      "question_code": "ES52",
      "question_description": "Exisiting Since- MM/YYYY",
      "type": "subquestion",
      "Response": "date"
    },
    {
      "question_set_code": "PEDCH224",
      "question_code": 224,
      "question_title": "Disease or disorder of eye, ear, nose or throat except any sight related problems corrected by prescription lenses",
      "type": "Question",
      "Response": "yes/no"
    },
    {
      "question_set_code": "PEDCH224",
      "question_code": "SW001",
      "question_description": "Since When",
      "type": "subquestion",
      "Response": "date"
    },
    {
      "question_set_code": "HEDCH006",
      "question_code": "A006",
      "question_title": "Have you ever been diagnosed for any cardiac ailment /disorder?",
      "type": "Question",
      "Response": "yes/no"
    },
    {
      "question_set_code": "HEDCH012",
      "question_code": "A012",
      "question_title": "Hypertension / High Blood Pressure/ High Cholesterol",
      "type": "Question",
      "Response": "yes/no"
    },
    {
      "question_set_code": "HEDCH008",
      "question_code": "A008",
      "question_title": "Please specify the type of cardiac ailment you have been operated for",
      "type": "Question",
      "Response": "yes/no"
    },
    {
      "question_set_code": "HEDCH008",
      "question_code": "H023",
      "question_description": "Have you undergone PTCA",
      "type": "subquestion",
      "Response": "yes/no"
    },
    {
      "question_set_code": "HEDCH008",
      "question_code": "H024",
      "question_description": "CABG",
      "type": "subquestion",
      "Response": "yes/no"
    },
    {
      "question_set_code": "HEDCH008",
      "question_code": "H025",
      "question_description": "Septal defect surgery-ASD/VSD",
      "type": "subquestion",
      "Response": "yes/no"
    },
    {
      "question_set_code": "HEDCH008",
      "question_code": "H026",
      "question_description": "Radiofrequency ablation-RFA",
      "type": "subquestion",
      "Response": "yes/no"
    },
    {
      "question_set_code": "HEDCH008",
      "question_code": "H027",
      "question_description": "Others cardiac surgery",
      "type": "subquestion",
      "Response": "yes/no"
    }
  ]