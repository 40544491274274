import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError, Observable, from } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
//import { environment } from '../../environments/environment';
import { icici_member_count, relationships } from '../iciciconfig/iciciconfig'
import { constant } from '../constant';
import { $ } from 'protractor';

@Injectable({
  providedIn: 'root',
})
export class CommonService {

  constructor(
    private _http: HttpClient
  ) { }

  gettoken() {
    return !!localStorage.getItem("token");
  }


  setHeader() {
    const token = localStorage.getItem('token');
    const httpOptions = {
      headers: new HttpHeaders({
        'X-XSS-Protection': '1; mode=block',
        'X-Frame-Options': 'deny',
        'AUTHORIZATION': `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImNiMDIyNzQwLTEzNjYtMTFlYi1iMzlmLTg5Njg5ZDIzN2I5ZiIsImlhdCI6MTYwNTY3ODUyOSwiZXhwIjoxNjA2MjgzMzI5fQ.msjC-51lx-2uXmcN3cw2RmOT8_CEfwxWCoaQSqr-pic`
      })
    };
    return httpOptions;
  }
  

  private handleError(error: HttpErrorResponse) {
    return throwError(error);
  };

  get(url): Observable<any> {
    return this._http.get(
      constant.api_endpoint + url, this.setHeader()
    ).pipe(
      retry(3),// retry a failed request up to 3 times
      catchError(this.handleError) // then handle the error
    );
  }

  post(url, data): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this._http.post<any>( constant.api_endpoint+url, data, { headers })
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }

  createbitlyshortlink(data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Authorization": `Bearer ${constant.bitly_access_token}`,
        "Content-Type": 'application/json'
      })
    };
    return this._http.post(
      constant.bitly_endpoint, data, httpOptions
    )
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }

  postSTAR(url, data): Observable<any> {
    return this._http.post(
      constant.api_endpoint + url, data
    )
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }

  postdirect(url, data): Observable<any> {
    return this._http.post(
      url, data
    )
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }

  religareCreatePolicy(data): Observable<any> {
    const url = constant.religare_endpoint + '/relinterfacerestful/religare/restful/createPolicy';
    const httpOptions = {
      headers: new HttpHeaders({
        'appID': '554940',
        'signature': 'VLwAATi/myXGqlG9C14DVIKsLgFjEUAZIizPSIbVdJw=',
        'timestamp': '1545391069685'
      })
    };
    return this._http.post(
      url, data, httpOptions
    )
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }


  adityaCreatePolicy(data): Observable<any> {
    const url = constant.aditya_endpoint + '/ABHICL_FullQuoteNSTP/Service1.svc/GenericFullQuote';
    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'AppID': '554940',
    //     'Signature': 'VLwAATi/myXGqlG9C14DVIKsLgFjEUAZIizPSIbVdJw=',
    //     'TimeStamp': '1545391069685'
    //   })
    // };
    return this._http.post(
      url, data,
    )
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }

  put(url, data): Observable<any> {
    return this._http.put(
      constant.api_endpoint + url, data, this.setHeader()
    )
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }

  delete(url): Observable<any> {
    return this._http.delete(
      constant.api_endpoint + url, this.setHeader()
    )
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }

  photoUpload(url, data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-XSS-Protection': '1; mode=block',
        'AUTHORIZATION': `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImNiMDIyNzQwLTEzNjYtMTFlYi1iMzlmLTg5Njg5ZDIzN2I5ZiIsImlhdCI6MTYwNTY3ODUyOSwiZXhwIjoxNjA2MjgzMzI5fQ.msjC-51lx-2uXmcN3cw2RmOT8_CEfwxWCoaQSqr-pic`
      })
    };
    return this._http.post(
      constant.api_endpoint + url, data, httpOptions
    )
      .pipe(
        catchError(this.handleError) // then handle the error
      );
  }

  ADULT_RELATIONS = [
    { key: 'self', value: 'Self' },
    { key: 'spouse', value: 'Spouse' },
    { key: 'father', value: 'Father' },
    { key: 'mother', value: 'Mother' },
    { key: 'brother', value: 'Brother' },
    { key: 'sister', value: 'Sister' },
    { key: 'grand-father', value: 'Grand Father' },
    { key: 'grand-mother', value: 'Grand Mother' },
    { key: 'father-in-law', value: 'Father-In-Law' },
    { key: 'mother-in-law', value: 'Mother-In-Law' },
    { key: 'sister-in-law', value: 'Sister-In-Law' },
    { key: 'brother-in-law', value: 'Brother-In-Law' },
    { key: 'son-in-law', value: 'Son-In-Law' },
    { key: 'daughter-in-law', value: 'Daughter-In-Law' }
  ];
  
  CHILD_RELATIONS = [
    { key: 'son', value: 'Son' },
    { key: 'daughter', value: 'Daughter' },
    { key: 'grand-son', value: 'Grand Son' },
    { key: 'grand-daughter', value: 'Grand Daughter' }
  ];
  
  generateMembersConfig(maxAdults:number, maxChildren:number, maxTotal:number) {
    const configs = [];
  
    for (let adults = 1; adults <= maxAdults; adults++) {
      for (let children = 0; children <= maxChildren; children++) {
        if (adults + children > maxTotal) break;
  
        const members = [];
        for (let i = 0; i < adults; i++) {
          members.push({
            membertype: `1a`,
            "DateOfBirth":'',
            memberTypeDef: `Insured Member ${i + 1}`,
            relations: this.ADULT_RELATIONS
          });
        }
        for (let i = 0; i < children; i++) {
          members.push({
            membertype: `1c`,
            "DateOfBirth":'',
            memberTypeDef: `Dependent Child ${i + 1}`,
            relations: this.CHILD_RELATIONS
          });
        }
  
        configs.push({
          key: `${adults} Adult${adults > 1 ? 's' : ''} + ${children} Child${children > 1 ? 'ren' : ''}`,
          value: `${adults}a-${children}c`,
          totalmember: adults + children,
          nochild: children,
        
          form: members
        });
      }
    }
  
    return configs;
  }
  
  relations() {
    return this.generateMembersConfig(icici_member_count.maxAdults, icici_member_count.maxChildren, icici_member_count.maxTotalMembers);
  }
  

  relationValue(a, c) {
    if (parseInt(a) + parseInt(c) > 12) {
      return 'Invalid combination'; 
    }
  
    if (a >= 1 && a <= 6 && c >= 0 && c <= 5) {
      if (c === 0) {
        return `${a}a`;
      } else {
        return `${a}a-${c}c`;
      }
    } else {
      return 'Invalid combination';
    }
  }

  nomineeRelations() {
    return relationships
  // return [
    //   {
    //     key: '324',
    //     value: 'Brother'
    //   }, {
    //     key: '2671',
    //     value: 'Brother-in-law'
    //   }, {
    //     key: '322',
    //     value: 'Daughter'
    //   }, {
    //     key: '2060',
    //     value: 'Daughter-in-law'
    //   },
    //   {
    //     key: '319',
    //     value: 'Father'
    //   },
    //   {
    //     key: '2058',
    //     value: 'Father-in-law'
    //   },
    //   {
    //     key: '2062',
    //     value: 'Granddaughter'
    //   }, {
    //     key: '2063',
    //     value: 'Grandfather'
    //   },
    //   {
    //     key: '2064',
    //     value: 'Grandmother'
    //   },
    //   {
    //     key: 'Grandson',
    //     value: 'Grandson'
    //   },
    //   {
    //     key: 'Mother',
    //     value: 'Mother'
    //   },
    //   {
    //     key: 'Sister',
    //     value: 'Sister'
    //   },
    //   {
    //     key: 'Sister-in-law',
    //     value: 'Sister-in-law'
    //   }, {
    //     key: 'Son',
    //     value: 'Son'
    //   }, {
    //     key: 'Son-in-law',
    //     value: 'Son-in-law'
    //   }, {
    //     key: 'Spouse',
    //     value: 'Spouse'
    //   }, {
    //     key: 'Other',
    //     value: 'Other'
    //   }
    // ]
  }

  abhiNomineeRel() {
    return [
      {
        key: 'R001',
        value: 'Self'
      }, {
        key: 'R002',
        value: 'Spouse'
      }, {
        key: 'R003',
        value: 'Dependent Son'
      }, {
        key: 'R004',
        value: 'Dependent Daughter'
      },
      {
        key: 'R005',
        value: 'Mother'
      },
      {
        key: 'R006',
        value: 'Father'
      },
      {
        key: 'R007',
        value: 'Mother-in-law'
      },
      {
        key: 'R008',
        value: 'Father-in-law'
      },
      {
        key: 'R009',
        value: 'Brother'
      },
      {
        key: 'R010',
        value: 'Sister'
      },
      {
        key: 'R011',
        value: 'Grandfather'
      },
      {
        key: 'R012',
        value: 'Grandmother'
      },
      {
        key: 'R013',
        value: 'Grandson'
      }, {
        key: 'R014',
        value: 'Granddaughter'
      },
      {
        key: 'R015',
        value: 'Son in-law'
      },
      {
        key: 'R016',
        value: 'Daughter in-law'
      },
      {
        key: 'R017',
        value: 'Brother in-law'
      },
      {
        key: 'R018',
        value: 'Sister in-law'
      },
      {
        key: 'R019',
        value: 'Nephew'
      },
      {
        key: 'R020',
        value: 'Niece'
      },
      {
        key: 'R021',
        value: 'Partnership'
      },
      {
        key: 'R022',
        value: 'Proprietorship'
      },
      {
        key: 'R023',
        value: 'HUF'
      },
      {
        key: 'R024',
        value: 'Employer-Employee'
      },
      {
        key: 'R025',
        value: 'Uncle'
      },
    ]
  }

  relationshipWithProposer() {
    return [
      {
        key: 'self',
        value: 'Self'
      }, {
        key: 'spouse',
        value: 'Spouse'
      },
      {
        key: 'father',
        value: 'Father'
      },
      {
        key: 'mother',
        value: 'Mother'
      },
      {
        key: 'father-in-law',
        value: 'Father-in-law'
      }, {
        key: 'mother-in-law',
        value: 'Mother-in-law'
      }
    ];
  }



  // getLocation(coords) {
  //   const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coords.lat},${coords.lng}&key=${environment.GOOGLE_API_KEY}`;
  //   return this._http.get(url).pipe(
  //     catchError(this.handleError) // then handle the error
  //   );
  // }

  private apiUrl = 'https://api.postalpincode.in/pincode/'; // Update with your API endpoint

  getPincode(pincode: string): Observable<any> {
    const url = `${this.apiUrl}${pincode}`;

    //console.log(url);

    return this._http.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }

  calculatePolicyEndDate(startDate: string, tenure: number): string {
    const tenureInt = typeof tenure === 'string' ? parseInt(tenure, 10) : tenure;
    const [year, month, day] = startDate.split('-').map(Number);  // Correct order: year, month, day

    // Create start date object (month is 0-indexed in JavaScript Date)
    const startDateObj = new Date(year, month - 1, day);

    // Create end date object by adding tenure years
    const endDateObj = new Date(startDateObj);
    endDateObj.setFullYear(startDateObj.getFullYear() + tenureInt);

    // Move the end date forward by one day
    endDateObj.setDate(endDateObj.getDate() + 1);

    // Format end date parts
    const endDay = endDateObj.getDate().toString().padStart(2, '0');
    const endMonth = (endDateObj.getMonth() + 1).toString().padStart(2, '0');
    const endYear = endDateObj.getFullYear().toString();

    return `${endYear}-${endMonth}-${endDay}`;
}



  calculatePolicyStartDate(): string {
    const currentDate = new Date();
    const nextDate = new Date(currentDate);
    nextDate.setDate(currentDate.getDate() + 1);

    const day = nextDate.getDate().toString().padStart(2, '0');
    const month = (nextDate.getMonth() + 1).toString().padStart(2, '0');
    const year = nextDate.getFullYear();

    return `${year}-${month}-${day}`;
  }
  
  calculateAge(dateOfBirth) { 
    let today = new Date();
    let birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    let monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  heightToCms(feet: number, inches: number): any {
    const feetToCm = feet * 30.48;  // 1 foot = 30.48 cm
    const inchesToCm = inches * 2.54;  // 1 inch = 2.54 cm
    const totalCms = feetToCm + inchesToCm;
    return Math.round(totalCms);  // You can also use Math.floor or Math.ceil
  }  
  

}